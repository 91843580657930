<div class="row">
    <div class="col-lg-12 table_section" [ngClass]="{'col-lg-8' : showForm}">
        <!-- <nz-card class="details_card"> -->
        <div class="row m-b-30">
            <div class="col-12 top_heading">
                <nz-card class="contacts_top_card">
                    <div class="contacts_top_bar">
                        <div class="contacts_top_bar_left">
                            <h3><span>Settings > </span>Users & Role</h3>
                        </div>

                        <div class="contacts_top_bar_right">
                            <div class="search_add_div">
                                <div class="d-flex align-items-center">
                                    <a [disabled]="mainUserData?.accessTo?.isExpired" (click)="showAddForm()" nz-button
                                        nzType="primary" class="create-button setting_add_button">
                                        <i nz-icon nzType="user-add" theme="outline"></i>
                                        <span>Add Member</span>
                                    </a>
                                </div>
                            </div>

                        </div>
                    </div>
                </nz-card>
            </div>

            <!-- <div class="col-12 mt-3">
                <div class="d-flex justify-content-between div_links">
                    <div class="toggle_options">
                        <p (click)="switchTable('users')" [ngClass]="{'selected_tab' : selectedTab == 'users'}">
                            Users
                        </p>
                        <p (click)="switchTable('invite')" [ngClass]="{'selected_tab' : selectedTab == 'invite'}">
                                Invite
                            </p>
                            <p (click)="switchTable('deletedUser')"
                                [ngClass]="{'selected_tab' : selectedTab == 'deletedUser'}">
                                Deleted User
                            </p>
                    </div>
                    <a [disabled]="mainUserData?.accessTo?.isExpired" (click)="showAddForm()" nz-button nzType="primary"
                        class="create-button">
                        <i nz-icon nzType="user-add" theme="outline"></i>
                        <span>Add Member</span>
                    </a>
                    <nz-dropdown-menu #menu="nzDropdownMenu">
                            <ul class="drop_down_menu" nz-menu>
                                <li (click)="toggleForm()" nz-menu-item>
                                    <i nz-icon nzType="plus" theme="outline"></i> New
                                </li>
                            </ul>
                        </nz-dropdown-menu>
                </div>
            </div> -->

            <div class="col-12 top_heading">
                <nz-card class="mb-2 assignee_nz_card">
                    <div class="assignee_card">
                        <div class="w-45">
                            <h4>Conversation Management</h4>
                            <p class="mb-0">
                                Please select the individual or bot who should handle the conversation when a customer
                                initiates a Conversation.
                            </p>
                        </div>
                        <div class="w-45">
                            <form [formGroup]="assignForm" (ngSubmit)="assignConversation()" nz-form>
                                <div class="assignee_form">
                                    <nz-form-item class="mb-0">
                                        <nz-form-label [nzSm]="24" [nzXs]="24" nzRequired class="large_label">
                                            Choose Conversation Manager
                                        </nz-form-label>
                                        <nz-form-control [nzSm]="24" nzErrorTip="Please Choose Conversation Manager!">
                                            <nz-select nzShowSearch formControlName="assignee"
                                                class="w-100 select_dropdown"
                                                nzPlaceHolder="Choose Conversation Manager">
                                                <nz-option *ngFor="let user of assignUsersList"
                                                    [nzLabel]="user?.firstName + ' ' + user.lastName"
                                                    [nzValue]="user?._id">
                                                </nz-option>
                                                <nz-option [nzLabel]="'Bot'" [nzValue]="'Flow'"></nz-option>
                                            </nz-select>
                                        </nz-form-control>
                                    </nz-form-item>

                                    <nz-form-item class="submit_section text-right mb-0">
                                        <nz-form-control [nzSm]="24" [nzXs]="24">
                                            <div class="account_details_add_cancel">
                                                <button [nzLoading]="updating" type="submit"
                                                    class="sighup_button white_btn" nz-button nzType="primary">
                                                    Update
                                                </button>
                                            </div>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                            </form>
                        </div>
                    </div>
                </nz-card>
            </div>

            <div class="col-12">
                <nz-card class="tags_new_table customers_table_card">
                    <div class="total_records">
                        <h6>
                            Total Records ({{ userList?.length }})
                        </h6>
                        <!-- <button class="customized_btn" [ngStyle]="setMyStyles()" nzTrigger="click" nz-dropdown
                            [nzDropdownMenu]="paginationMenu" [nzPlacement]="'bottomLeft'">
                            {{ pageSize + " records per page" }}
                        </button>

                        <nz-dropdown-menu #paginationMenu="nzDropdownMenu">
                            <ul nz-menu>
                                <li (click)="changePageSize((n + 1) * 10)"
                                    *ngFor="let page of [].constructor(5); let n = index" nz-menu-item>
                                    {{ (n + 1) * 10 + " records per page" }}
                                </li>
                            </ul>
                        </nz-dropdown-menu> -->
                    </div>
                    <nz-table class="contacts_table main_contacts_table" #userListTable [nzData]="userList"
                        [nzPageSize]="10" [nzScroll]="{ y: tableHeight, x : '500px' }"
                        (nzQueryParams)="onPageNumberChange('categoryTable')" [nzPaginationPosition]="'top'"
                        [nzSimple]="true">
                        <thead>
                            <tr>
                                <th *ngFor="let column of orderColumn" [nzWidth]="column.width">{{ column.title }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr id="categoryTable" *ngFor="let item of userListTable.data ;  let i=index">
                                <td>
                                    <div (click)="showUpdateForm(item)" class="edit_link">
                                        {{item?.firstName + " " + (item?.lastName ? item?.lastName : '')}}
                                    </div>
                                </td>
                                <td>
                                    {{(item?.countryCode ? item?.countryCode : '') + item?.phoneNo}}
                                </td>
                                <td>
                                    {{item?.email}}
                                </td>
                                <td>
                                    {{item?.role}}
                                </td>
                            </tr>
                        </tbody>
                        <ngx-spinner bdColor="rgb(255,255,255)" size="medium" color="#000000"
                            type="ball-clip-rotate-pulse" [fullScreen]="false">
                        </ngx-spinner>
                    </nz-table>
                </nz-card>
            </div>
        </div>
        <!-- </nz-card> -->
    </div>

    <div *ngIf="showForm" class="col-lg-4 form_section">
        <nz-card class="details_card">
            <div class="form_heading_section mb-3">
                <h5>{{addProduct ? 'Add' : 'Update'}} Users & Role</h5>
                <span class="close_icon" (click)="toggleForm()" nz-icon nzType="close" nzTheme="outline"></span>
            </div>

            <form [formGroup]="singleCustomerForm" (ngSubmit)="createCustomer()" nz-form>
                <div class="update_form_div">
                    <nz-form-item>
                        <nz-form-label nzRequired class="large_label" nzFor="" [nzSm]="24" [nzXs]="24">First Name
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please Enter First Name!">
                            <nz-input-group>
                                <input autocomplete="off" formControlName="firstName" type="text" nz-input
                                    id="first__name" placeholder="Enter First Name" required>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item>
                        <nz-form-label nzRequired class="large_label" nzFor="" [nzSm]="24" [nzXs]="24">Last Name
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please Enter Last Name!">
                            <nz-input-group>
                                <input autocomplete="off" formControlName="lastName" type="text" nz-input
                                    id="last__name" placeholder="Enter Last Name" required>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item>
                        <nz-form-label nzRequired class="large_label" nzFor="" [nzSm]="24" [nzXs]="24">Email Id
                        </nz-form-label>
                        <nz-form-control nzErrorTip="Please Enter Mail Id!">
                            <nz-input-group>
                                <input autocomplete="off" formControlName="mailId" nz-input placeholder="Enter Mail Id"
                                    type="email">
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item>
                        <nz-form-label nzRequired class="large_label" nzFor="" [nzSm]="24" [nzXs]="24">Mobile Number
                        </nz-form-label>
                        <nz-form-control nzErrorTip="Please Enter Mobile Number!">
                            <nz-input-group nzCompact>
                                <nz-select class="select_group" nzShowSearch formControlName="countryCode"
                                    style="width:35%;">
                                    <nz-option *ngFor="let code of countryCodes" [nzLabel]="code?.countryCode"
                                        [nzValue]="code?.countryCode"></nz-option>
                                    <!-- <nz-option [nzLabel]="'+65'" [nzValue]="'+65'"></nz-option> -->
                                </nz-select>
                                <input autocomplete="off" formControlName="mobileNumber" type="number" nz-input
                                    id="mobile--number" placeholder="Mobile Number" required style="width:65%;">
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item>
                        <nz-form-label nzRequired class="large_label" nzFor="" [nzSm]="24" [nzXs]="24">Role
                        </nz-form-label>
                        <nz-form-control nzErrorTip="Please Select role!">
                            <nz-input-group nzCompact>
                                <nz-select class="w-100" formControlName="role" nzPlaceHolder="Select role">
                                    <nz-option [nzLabel]="'Owner'" [nzValue]="'owner'"></nz-option>
                                    <nz-option [nzLabel]="'Admin'" [nzValue]="'admin'"></nz-option>
                                    <nz-option [nzLabel]="'Team Member'" [nzValue]="'teammate'"></nz-option>
                                </nz-select>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item class="user_create">
                        <button [nzLoading]="creatingCustomer" type="submit" class="sighup_button" nz-button
                            nzType="primary">
                            {{addProduct ? 'Create' : 'Update'}}
                        </button>
                    </nz-form-item>
                </div>
            </form>

            <!-- <form *ngIf="!addProduct" [formGroup]="updateCustomerForm" (ngSubmit)="updateCustomer()" nz-form>
                <div class="update_form_div">
                    <nz-form-item>
                        <nz-form-control nzErrorTip="Please Enter customer Name!">
                            <nz-input-group>
                                <input autocomplete="off" formControlName="cusName" type="text" nz-input id="cus_name"
                                    placeholder="Enter customer Name" required>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item>
                        <nz-form-control nzErrorTip="Please Enter Mail Id!">
                            <nz-input-group>
                                <input autocomplete="off" formControlName="cusMail" nz-input placeholder="Enter Mail Id"
                                    type="email">
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item>
                        <nz-form-control>
                            <nz-input-group>
                                <label nz-checkbox formControlName="cusBroadCast">Allow Broadcast</label>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item class="user_create">
                        <button [nzLoading]="creatingCustomer" type="submit" class="sighup_button" nz-button
                            nzType="primary">
                            Update
                        </button>
                    </nz-form-item>
                </div>
            </form> -->
        </nz-card>
    </div>
</div>



<!-- BULK UPLOAD MODAL SECTION STARTS -->

<!-- <nz-modal [(nzVisible)]="bulkUploadModal" nzTitle="Bulk Upload" nzCentered (nzOnCancel)="closeOtpModal()">
    <ng-container *nzModalContent>
        <div class="">
            <h5>Upload a .CSV file to bulk upload</h5>
            <ul>
                <li>Max 50 MB file allowed. (upto 500000 contacts)</li>
                <li>CSV should mandatorily contain Phone Number & Country Code in any 2 columns.</li>
                <li>If 2 (or more) contacts in CSV have the same phone number, they might not be added.</li>
                <li>If a contact in the CSV already exists in your account, the existing contact's traits will
                    be
                    updated as per the traits given in the CSV. (matching of contacts is done based on Phone
                    Number)
                </li>
            </ul>
        </div>
        <div class="download_excel">
            <a (click)="downloadSampleFile()">
                <span nz-icon nzType="download" nzTheme="outline"></span> Download Sample Excel
            </a>
        </div>

        <div class="drag_drop_container mt-5" appDnd (files)="filesDropped($event)">
            <div class="drag_drop_section">
                <p>Drag & Drop your files here</p>
                <span>OR</span>
                <div class="upload-button save-button mt-3">
                    <label for='input-file'>Upload File</label>
                    <input (change)="uploadFile($event)" class="upload_input" id='input-file' type='file'>
                </div>
            </div>
        </div>

    </ng-container>
    <ng-template class="modal_footer" *nzModalFooter></ng-template>
</nz-modal> -->

<!-- BULK UPLOAD MODAL SECTION ENDS -->