import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import { Component, EventEmitter, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-kanban-board",
  templateUrl: "./kanban-board.component.html",
  styleUrls: ["./kanban-board.component.css"],
})
export class KanbanBoardComponent implements OnInit {
  lanes: any[] = [
    {
      id: "Open",
      title: "Open Chat",
      issues: [
        {
          id: "6424133e6f747e264f824403",
          phoneNo: "917448592071",
          name: "Balaji Karthik",
        },
        {
          id: "6424133e6f747e264f824404",
          phoneNo: "917448592071",
          name: "sales ieyal",
        },
      ],
    },
    {
      id: "Closed",
      title: "Closed Chat",
      issues: [
        {
          id: "6424133e6f747e264f824405",
          phoneNo: "917448592071",
          name: "Dinesh kumar",
        },
      ],
    },
    {
      id: "Test 1",
      title: "Test 1",
      issues: [
        {
          id: "6424133e6f747e264f824405",
          phoneNo: "917448592071",
          name: "Dinesh kumar",
        },
      ],
    },
    {
      id: "Test 2",
      title: "Test 2",
      issues: [
        {
          id: "6424133e6f747e264f824405",
          phoneNo: "917448592071",
          name: "Dinesh kumar",
        },
      ],
    },
    {
      id: "Test 3",
      title: "Test 3",
      issues: [
        {
          id: "6424133e6f747e264f824405",
          phoneNo: "917448592071",
          name: "Dinesh kumar",
        },
      ],
    },
  ];

  searchKey: string = "";
  @Output() disableBoardView = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  onDragDropped(draggedItem: any, event: CdkDragDrop<any[]>) {
    // Handle drag dropped
    console.log("Dragged Item:", draggedItem);
    const targetLaneId = event.container.id;
    console.log("Target Lane ID:", targetLaneId);

    // You can use the draggedItem and targetLaneId as needed.
    // For example, if you want to update the data structure, you can do so here.
  }

  drop(event: CdkDragDrop<any[]>) {
    // console.log(event);
    let isMovingInsideTheSameList = event.previousContainer === event.container;
    if (isMovingInsideTheSameList) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
    // console.log(this.lanes);
  }

  showChatScreen() {
    this.disableBoardView.emit();
  }
}
