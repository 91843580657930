<div class="main_chat_container not_chat_screen">
    <div class="row main_chat_row" [ngClass]="{'justify-content-center' : !showEdit}">

        <div *ngIf="!showEdit || !mobileDevice" class="col-xxl-12 col-lg-12 user_column"
            [ngClass]="{'col-xxl-8 col-lg-8' : showEdit }">
            <!-- <nz-card class=""> -->
            <div class="mt-1">
                <div class="scroll_div">
                    <ul class="conversation-list px-0 h-100">
                        <ng-container>

                            <li class="clearfix">
                                <div class="conversation-text ms-0">
                                    <div class="outer_div">

                                        <!-- trigger messages -->
                                        <div class="ctext-wrap">
                                            <div class="">
                                                <div class="cart_detail_div">
                                                    <div class="">
                                                        <img nz-image class="img-fluid cart_image"
                                                            nzSrc="../../../../assets/images/chat/sample.jpeg" alt="">
                                                    </div>
                                                    <div class="cart_price">
                                                        <h6>4 items</h6>
                                                        <span>₹ 12,000.00 (estimated total)</span>
                                                    </div>
                                                </div>
                                                <div class="cart_description">
                                                    <p>Hey I love these products. Would like to buy them</p>
                                                    <h6 class="view_details_text">View sent cart</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li class="clearfix w-100 " *ngFor="let item of displayFlow; let j = index">

                                <!-- send message -->
                                <div *ngIf="!item?.message?.customTemplate" class="odd">
                                    <div class="conversation-text mb-3 justify-content-end ms-0">
                                        <div class="outer_div justify-content-end">

                                            <!-- send messages -->
                                            <div class="ctext-wrap">
                                                <div class="continue_order_main_div">
                                                    <div class="continue_text">
                                                        <div *ngIf="!item?.message?.noEdit"
                                                            class="text-right w-100 edit_template">
                                                            <div class="edit_options">
                                                                <span nz-tooltip nzTooltipTitle="Edit"
                                                                    (click)="editTemplate(item)" nz-icon nzType="edit"
                                                                    nzTheme="outline"></span>
                                                                <span nz-tooltip nzTooltipTitle="Reset"
                                                                    (click)="resetTemplate(item)" nz-icon nzType="undo"
                                                                    nzTheme="outline"></span>
                                                            </div>
                                                        </div>
                                                        <p [innerHTML]="item?.message?.text"></p>
                                                    </div>
                                                    <div *ngIf="item?.message?.buttons?.hasButtons"
                                                        class="continue_order_buttons">
                                                        <div *ngFor="let button of item?.message?.buttons?.values"
                                                            class="custom_button">
                                                            <p>{{button.text}}</p>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="item?.message?.list?.hasList" class="list_view">
                                                        <p><span nz-icon nzType="unordered-list"
                                                                nzTheme="outline"></span> View</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- possible options -->
                                <div *ngIf="item?.message?.buttons?.hasButtons">
                                    <div class="mt-3  options_div w-100">
                                        <hr class="dotted_line">
                                        <div class="dropdown_section">
                                            <a [nzPlacement]="'bottomCenter'" nz-dropdown nzTrigger="click"
                                                [nzDropdownMenu]="menu">
                                                {{item?.reply?.text}}
                                                <span nz-icon nzType="down"></span>
                                            </a>

                                            <nz-dropdown-menu #menu="nzDropdownMenu">
                                                <ul class="dropdown_open" nz-menu>
                                                    <li class="text-center">
                                                        <p>Customer Response</p>
                                                    </li>

                                                    <li class="response_div">
                                                        <div *ngFor="let button of item?.message?.buttons?.values ; let k = index"
                                                            class="user_options_div">
                                                            <div (click)="changeStatus(button , j , item)" nz-menu-item
                                                                class="user_option mb-1">
                                                                <h5> {{button.text}} </h5>
                                                            </div>
                                                            <!-- <span> Customer Response : </span>
                                                                <p>{{button.text}}</p> -->
                                                        </div>
                                                    </li>
                                                </ul>
                                            </nz-dropdown-menu>
                                        </div>
                                        <hr class="dotted_line">
                                    </div>
                                </div>

                                <!-- customer reply -->
                                <div *ngIf="item?.reply && !item?.message?.customTemplate"
                                    class="conversation-text ms-0">
                                    <div class="outer_div">
                                        <div class="ctext-wrap">
                                            <div class="">
                                                <p>{{item?.reply?.text}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="item?.message?.expectedAnswer && !item?.message?.customTemplate" class="
                                        conversation-text ms-0">
                                    <div class="outer_div">
                                        <div class="ctext-wrap">
                                            <div class="">
                                                <p>{{item?.message?.expectedAnswer}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ng-container>
                        <!-- </ng-container> -->
                    </ul>
                </div>
            </div>
            <!-- End card-body -->
            <!-- </nz-card> -->

            <ngx-spinner bdColor="rgb(255,255,255)" size="medium" color="#000000" type="ball-clip-rotate-pulse"
                [fullScreen]="false">
            </ngx-spinner>
        </div>


        <div *ngIf="showEdit && !showSetting" class="col-xxl-4 col-lg-4 message_column">
            <nz-card>
                <div class="w-100 update_title">
                    <h5><span (click)="closeEditBox()" class="back_arrow" nz-icon nzType="arrow-left"
                            nzTheme="outline"></span> Edit Message</h5>
                    <a [nzLoading]="updateLoading" (click)="updateTemplate()" [disabled]="!htmlContent" nz-button
                        nzType="primary" class="create-button">
                        <span>Update</span>
                    </a>
                </div>

                <div class="">
                    <h6>Message Body</h6>

                    <div class="">
                        <angular-editor class="automation_editor" [(ngModel)]="htmlContent" [config]="config">
                        </angular-editor>
                    </div>
                </div>
            </nz-card>
        </div>

        <!-- <div *ngIf="showSetting && !showEdit" class="col-xxl-4 col-lg-4 message_column">
            <nz-card>
                <nz-collapse nzAccordion>
                    <nz-collapse-panel nzHeader="1. Shipping Cost" [nzActive]="true">
                        <div class="">
                            <nz-form-item>
                                <nz-form-control [nzSm]="24" [nzXs]="24">
                                    <nz-input-group>
                                        <nz-radio-group [(ngModel)]="shippingCost">
                                            <label nz-radio [nzValue]="'free'">
                                                Free
                                            </label>
                                            <label nz-radio [nzValue]="'add-shipping'">
                                                Add Shipping
                                            </label>
                                        </nz-radio-group>
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                    </nz-collapse-panel>
                    <nz-collapse-panel nzHeader="2. Discount">

                    </nz-collapse-panel>
                    <nz-collapse-panel nzHeader="3. Payment Option">

                    </nz-collapse-panel>
                </nz-collapse>
            </nz-card>
        </div> -->

    </div>
</div>