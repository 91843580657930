<div class="header">
    <div class="logo logo-dark">
        <a routerLink="/" style="background-color: #181C2E;">
            <img src="../../../../assets/images/logo/ownChat_logo.png" alt="Logo">
            <img class="logo-fold" src="../../../../assets/images/logo/own_cart_mini_logo.png" alt="Logo">
        </a>
    </div>


    <div class="nav-wrap">
        <ul class="nav-left">
            <li class="desktop-toggle">
                <a (click)="toggleFold()">
                    <i nz-icon [nzType]="isFolded? 'menu-unfold':'menu-fold'" theme="outline"></i>
                </a>
            </li>
            <li class="mobile-toggle">
                <a (click)="toggleExpand()">
                    <i nz-icon [nzType]="isExpand? 'menu-fold': 'menu-unfold'" theme="outline"></i>
                </a>
            </li>
            <li>
                <img class="mobile_logo" src="../../../../assets/images/logo/ownChat_logo.png" alt="your logo">
            </li>
            <!-- <div class=" m-r-20 search-section">
                <nz-input-group [nzPrefix]="prefixTemplate">
                    <input autocomplete="off" [(ngModel)]="searchKey" (keyup)="keyUp()" type="text" nz-input
                        [placeholder]="'Search ' + placeholder" nzTrigger="click" nz-dropdown
                        [nzDropdownMenu]="filteredValue">
                </nz-input-group>
                <ng-template #prefixTemplate>
                    <i nz-icon nzType="search" class="opacity-05"></i>
                    <a class="search-options" nzTrigger="click" nz-dropdown [nzDropdownMenu]="category">{{placeholder+ '
                        '}}<i nz-icon nzType="down" nzTheme="outline"></i></a>
                    <i class="mobile-search-option" nzTrigger="click" nz-dropdown [nzDropdownMenu]="category" nz-icon
                        nzType="down" nzTheme="outline"></i>

                </ng-template>
            </div> -->
        </ul>

        <ul class="nav-right">
            <li>
                <div class="user-section">
                    <!-- <div class="mr-3 user-column">
                        <a nz-dropdown [nzDropdownMenu]="logout">
                            <img [src]="liveUrl + userData?.profileImage" alt="profile">
                        </a>

                    </div>
                    <div class="profile-detail">
                        <h5>{{userData?.firstName + ' ' + (userData?.lastName ? userData?.lastName : '')}}</h5>
                        <p>{{userData?.role}}</p>
                    </div> -->
                    <a class="profile_btn" nzTrigger="click" nz-dropdown [nzDropdownMenu]="menu">
                        <img class="profile_image"
                            [src]="userData?.profileImage ? liveUrl + userData?.profileImage: '../../../assets/images/logo/profile.png'"
                            alt="">
                        <span class="user_name_span">
                            <!-- {{userData?.firstName + ' ' + (userData?.lastName ? userData?.lastName : '') }} -->
                            {{(userData?.firstName + ' ' + (userData?.lastName ? userData?.lastName : '')).length > 16 ?
                            ((userData?.firstName + ' ' + (userData?.lastName ? userData?.lastName : '')).slice(0,16) +
                            '...') : (userData?.firstName + ' ' + (userData?.lastName ? userData?.lastName : ''))}}
                            <span nz-icon nzType="caret-down" nzTheme="outline"></span>
                        </span>
                    </a>
                    <nz-dropdown-menu #menu="nzDropdownMenu">
                        <ul class="profile_options_list" nz-menu nzSelectable>
                            <li routerLink="/setting/profile" nz-menu-item>
                                <span nz-icon nzType="user" nzTheme="outline"></span>
                                My Profile
                            </li>
                            <!-- <li (click)="toSettingPage()" nz-menu-item>
                                <span nz-icon nzType="setting" nzTheme="outline"></span>
                                Setting
                            </li> -->
                            <li (click)="logoutAlert()" nz-menu-item>
                                <span nz-icon nzType="logout" nzTheme="outline"></span>
                                Logout
                            </li>
                        </ul>
                    </nz-dropdown-menu>
                </div>
            </li>
        </ul>
    </div>
</div>

<nz-dropdown-menu #logout="nzDropdownMenu" nzPlacement="bottomLeft">
    <ul nz-menu class="logout-option">
        <li (click)="logoutAlert()" nz-menu-item> <i class="mr-3" nz-icon nzType="logout" nzTheme="outline"></i> logout
        </li>
        <!-- <li class="color_change" (click)="colorChange()" nz-menu-item></li> -->
    </ul>
</nz-dropdown-menu>

<!-- <nz-dropdown-menu #category="nzDropdownMenu" nzPlacement="bottomLeft">
    <ul nz-menu>
        <li (click)="searchBy('customer')" nz-menu-item>Customer</li>
        <li (click)="searchBy('vendor')" nz-menu-item>Vendor</li>
    </ul>
</nz-dropdown-menu>


<nz-dropdown-menu #filteredValue="nzDropdownMenu" nzPlacement="bottomCenter">

    <ul *ngIf="noProducts" nz-menu class="search-menu">
        <li class="search-result" nz-menu-item>
            No Matching Result Found
        </li>
    </ul>

    <ul *ngIf="!noProducts" nz-menu class="search-menu">
        <li class="search-result" *ngFor="let value of Filtered" (click)="toSelectedDetails(value)" nz-menu-item>
            <h5>{{value.name}}</h5>
            <h6>{{value.email}}</h6>
            <p>{{value.phoneNo}}</p>
        </li>
    </ul>
</nz-dropdown-menu> -->