<div class="row" [ngClass]="{'main_tags_row' : tagsPage}">
    <div *ngIf="tagsPage" class="col-12 main_outer_heading d_mobile_none">
        <h3>
            <span class="cursor_pointer" routerLink="/contacts" nz-icon nzType="left" nzTheme="outline"></span>
            Field collection
        </h3>
    </div>

    <div class="col-lg-12 table_section" [ngClass]="{'col-lg-8' : showForm}">
        <div class="row m-b-30">
            <div *ngIf="!tagsPage" class="col-12 top_heading">
                <nz-card class="contacts_top_card">
                    <div class="contacts_top_bar">
                        <div class="contacts_top_bar_left">
                            <h3><span>Settings > </span>Field Collection</h3>
                        </div>

                        <div class="contacts_top_bar_right">
                            <div class="search_add_div">
                                <div class="toggle_options">
                                    <nz-input-group class="search_input_section small_input_sec"
                                        [nzPrefix]="suffixIconSearch">
                                        <input autocomplete="off" [(ngModel)]="searchKey" (keyup)="searchContacts()"
                                            type="text" nz-input placeholder="Search field name" />
                                    </nz-input-group>
                                    <ng-template #suffixIconSearch>
                                        <span nz-icon nzType="search"></span>
                                    </ng-template>
                                </div>
                                <div class="d-flex align-items-center">
                                    <a [disabled]="mainUserData?.accessTo?.isExpired" nz-button (click)="toggleForm()"
                                        nzType="primary" class="create-button setting_add_button">
                                        <i nz-icon nzType="plus" theme="outline"></i>
                                        <span>Add</span>
                                    </a>

                                    <span [ngClass]="{'reload_btn_loading' : pageReloading}" (click)="reloadPage()"
                                        class="reload_btn create-button" nzTooltipTitle="reload"
                                        nzTooltipPlacement="top" nz-tooltip nz-icon nzType="sync"
                                        nzTheme="outline"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </nz-card>
            </div>

            <div class="col-12">
                <nz-card class="tags_new_table customers_table_card">
                    <div class="total_records">
                        <h6>
                            Total Records ({{ fieldData?.length }})
                        </h6>

                        <div *ngIf="tagsPage" class="search_add_div">
                            <div class="toggle_options">
                                <nz-input-group class="search_input_section small_input_sec"
                                    [nzPrefix]="suffixIconSearch">
                                    <input autocomplete="off" [(ngModel)]="searchKey" (keyup)="searchContacts()"
                                        type="text" nz-input placeholder="Search field name" />
                                </nz-input-group>
                                <ng-template #suffixIconSearch>
                                    <span nz-icon nzType="search"></span>
                                </ng-template>
                            </div>
                            <div class="d-flex align-items-center">
                                <a [disabled]="mainUserData?.accessTo?.isExpired" nz-button (click)="toggleForm()"
                                    nzType="primary" class="create-button setting_add_button">
                                    <i nz-icon nzType="plus" theme="outline"></i>
                                    <span>Add</span>
                                </a>

                                <span [ngClass]="{'reload_btn_loading' : pageReloading}" (click)="reloadPage()"
                                    class="reload_btn create-button" nzTooltipTitle="reload" nzTooltipPlacement="top"
                                    nz-tooltip nz-icon nzType="sync" nzTheme="outline"></span>
                            </div>
                        </div>
                    </div>
                    <nz-table class="contacts_table main_contacts_table" #fieldDataTable [nzShowPagination]="false"
                        [nzData]="fieldData" [nzPageSize]="9999" [nzScroll]="{ y: tableHeight, x : '500px'}"
                        [nzPaginationPosition]="'top'" [nzSimple]="true">
                        <thead>
                            <tr>
                                <th nzWidth="15px"></th>
                                <th *ngFor="let column of orderColumn" [nzWidth]="column.width">{{ column.title }}</th>
                            </tr>
                        </thead>
                        <tbody cdkDropList (cdkDropListDropped)="drop($event)">
                            <tr cdkDrag id="catalogueTable"
                                *ngFor="let item of fieldData | filter : searchKey;  let i=index">
                                <td (mousedown)="enableDrag()" class="grabber_div"
                                    [ngClass]="{'dragging' : isDragging}">
                                    <img src="../../../../assets/images/icons/grabber_icon.png" alt="">
                                </td>
                                <td>
                                    <div class="">
                                        {{item.label}}
                                    </div>
                                </td>
                                <!-- <td>
                                    <div class="">
                                        {{item?.name}}
                                    </div>
                                </td> -->
                                <td> {{item?.dataType}} </td>
                                <td>
                                    <nz-switch [(ngModel)]="item.isActive" (ngModelChange)="directUpdate(item , $event)"
                                        [nzDisabled]="item.fieldType == 'predefined'|| mainUserData?.accessTo?.isExpired">
                                    </nz-switch>
                                </td>
                                <td>
                                    <nz-switch [nzLoading]="fieldAdding" [(ngModel)]="item.isMandatory"
                                        (ngModelChange)="directUpdate(item , $event)"
                                        [nzDisabled]="item?.fieldType == 'predefined' || mainUserData?.accessTo?.isExpired"></nz-switch>
                                </td>
                                <td>
                                    <div *ngIf="item.fieldType != 'predefined'" class="edit_delete_options">
                                        <span (click)="showUpdateFieldForm(item)" nz-icon nzType="edit"
                                            nzTheme="outline"></span>
                                        <span (click)="showDeleteAlert(item)" nz-icon nzType="delete"
                                            nzTheme="outline"></span>
                                    </div>
                                </td>
                            </tr>

                        </tbody>
                        <ngx-spinner bdColor="rgb(255,255,255)" size="medium" color="#000000"
                            type="ball-clip-rotate-pulse" [fullScreen]="false">
                        </ngx-spinner>

                    </nz-table>
                </nz-card>
            </div>

        </div>
        <!-- </nz-card> -->
    </div>

    <div *ngIf="showForm" class="col-lg-4 form_section">
        <nz-card class="details_card">
            <div class="form_heading_section mb-3">
                <h5>{{editOption ? 'Edit' : 'Add'}} Field Collection</h5>
                <span class="close_icon" (click)="toggleForm()" nz-icon nzType="close" nzTheme="outline"></span>
            </div>

            <form class="create_form" *ngIf="!editOption" [formGroup]="addFieldForm" (ngSubmit)="createField()" nz-form>
                <nz-form-item>
                    <nz-form-label nzRequired class="large_label" nzFor="" [nzSm]="24" [nzXs]="24">Label
                    </nz-form-label>
                    <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please Enter Label!"
                        nzExtra="display field name across ownchat">
                        <nz-input-group>
                            <input autocomplete="off" formControlName="label" type="text" nz-input id=""
                                placeholder="Enter Label" required>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <!-- <nz-form-item>
                    <nz-form-label nzRequired class="large_label" nzFor="" [nzSm]="24" [nzXs]="24">Name
                    </nz-form-label>
                    <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please Enter Name!"
                        nzExtra="Actual field name to access the field value externally">
                        <nz-input-group>
                            <input autocomplete="off" formControlName="name" type="text" nz-input id=""
                                placeholder="Enter Name" required>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item> -->

                <nz-form-item>
                    <nz-form-label nzRequired class="large_label" nzFor="" [nzSm]="24" [nzXs]="24">Hint
                    </nz-form-label>
                    <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please Enter Hint!"
                        nzExtra="Hint about this field">
                        <nz-input-group>
                            <input autocomplete="off" formControlName="hint" type="text" nz-input id=""
                                placeholder="Enter Hint">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label nzRequired class="large_label" nzFor="" [nzSm]="24" [nzXs]="24">
                        Type
                    </nz-form-label>
                    <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please Select Data Type!">
                        <nz-input-group>
                            <nz-select nzPlaceHolder="Select Data Type" class="w-100" formControlName="type">
                                <nz-option *ngFor="let type of dataTypes" [nzLabel]="type.label" [nzValue]="type.value">
                                </nz-option>
                            </nz-select>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item *ngIf="addFieldForm.value.type == 'select' || addFieldForm.value.type == 'multi-select'">
                    <nz-form-label nzRequired class="large_label" nzFor="" [nzSm]="24" [nzXs]="24">
                        Accepted Values
                    </nz-form-label>
                    <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please Accepted Values!">
                        <nz-input-group>
                            <nz-select nzMode="tags" nzPlaceHolder="Enter Accepted Values" class="w-100"
                                formControlName="acceptedValues">
                            </nz-select>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item class="switch_form_item">
                    <nz-form-label nzRequired class="large_label" nzFor="">
                        Is Mandatory
                    </nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <nz-switch formControlName="isMandatory"></nz-switch>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item class="submit_section justify-content-center mt-3">
                    <nz-form-control [nzSm]="20" [nzXs]="24">
                        <button [nzLoading]="fieldAdding" type="submit" class="sighup_button setting_add_button w-100"
                            nz-button nzType="primary">
                            Save</button>
                    </nz-form-control>
                </nz-form-item>
            </form>

            <form *ngIf="editOption" [formGroup]="updateFieldForm" (ngSubmit)="updateField()" nz-form>
                <nz-form-item>
                    <nz-form-label nzRequired class="large_label" nzFor="" [nzSm]="24" [nzXs]="24">Label
                    </nz-form-label>
                    <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please Enter Label!"
                        nzExtra="display field name across ownchat">
                        <nz-input-group>
                            <input autocomplete="off" formControlName="label" type="text" nz-input id=""
                                placeholder="Enter Label" required>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label nzRequired class="large_label" nzFor="" [nzSm]="24" [nzXs]="24">Hint
                    </nz-form-label>
                    <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please Enter Hint!"
                        nzExtra="Hint about this field">
                        <nz-input-group>
                            <input autocomplete="off" formControlName="hint" type="text" nz-input id=""
                                placeholder="Enter Hint">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item *ngIf="updateFieldForm?.value?.acceptedValues">
                    <nz-form-label nzRequired class="large_label" nzFor="" [nzSm]="24" [nzXs]="24">
                        Accepted Values
                    </nz-form-label>
                    <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please Enter Accepted Values!">
                        <nz-input-group>
                            <nz-select nzMode="tags" nzPlaceHolder="Enter Accepted Values" class="w-100"
                                formControlName="acceptedValues">
                            </nz-select>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item class="switch_form_item">
                    <nz-form-label nzRequired class="large_label" nzFor="">
                        Is Mandatory
                    </nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <nz-switch formControlName="isMandatory"></nz-switch>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item class="switch_form_item">
                    <nz-form-label nzRequired class="large_label" nzFor="">
                        Is Active
                    </nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <nz-switch formControlName="isActive"></nz-switch>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item class="submit_section justify-content-center mt-5">
                    <nz-form-control [nzSm]="20" [nzXs]="24">
                        <button [nzLoading]="fieldAdding" type="submit" class="sighup_button setting_add_button w-100"
                            nz-button nzType="primary">
                            Update</button>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </nz-card>
    </div>
</div>

<!-- cdkDropList (cdkDropListDropped)="drop($event)" line 89 -->

<!-- cdkDrag line 90 -->