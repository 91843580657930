<div *ngIf="!createForm" class="main_outer_heading not_chat_screen">
    <h3>Catalogues</h3>
</div>

<div class="row">
    <div class="col-12">
        <nz-card *ngIf="!createForm">
            <div class="row">
                <!-- <div class="col-lg-8">
            <h3>Catalogues</h3>
        </div> -->
                <div class="col-lg-12 mb-3 text-right add-button-section">
                    <a [disabled]="mainUserData?.accessTo?.isExpired" (click)="showCatalogueForm()" nz-button
                        nzType="primary" class="create-button">
                        <i nz-icon nzType="plus-circle" theme="outline"></i>
                        <span>Add Catalogue </span>
                    </a>
                </div>

                <div class="col-12">
                    <nz-table #catalogueListTable [nzData]="catalogueData" [nzFrontPagination]="false"
                        [nzTotal]="totalCount" [nzPageSize]="pageSize" [nzPageIndex]="pageIndex"
                        (nzQueryParams)="onPageNumberChange('categoryTable' , $event)"
                        [nzScroll]="{ y: tableHeight, x : '900px' }">
                        <thead>
                            <tr>
                                <th *ngFor="let column of orderColumn" [nzWidth]="column.width">{{ column.title }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr id="catalogueTable" *ngFor="let item of catalogueListTable.data ;  let i=index">
                                <!-- <td>{{i+1}}</td> -->
                                <td>
                                    <div (click)="editCatalogue(item)" class="edit_link">
                                        {{item.name}}
                                    </div>
                                </td>
                                <td>
                                    <nz-avatar nzShape="square" [nzSize]="50" nzIcon="picture"
                                        [nzSrc]="liveUrl + item.image_url"></nz-avatar>
                                </td>
                                <td>
                                    {{item.brand ? item.brand : '-'}}
                                </td>
                                <td>{{item.category ? item.category : '-'}}</td>
                                <td>₹ {{item.price}}</td>
                                <td>
                                    <a target="_blank" [href]="item.url">{{item.url}}</a>
                                </td>
                            </tr>

                        </tbody>
                        <!-- <div *ngIf="catalogueData.next" class="text-center load_more_div mt-3">
            <a (click)="loadMore()">Load more..</a>
        </div> -->
                        <ngx-spinner bdColor="rgb(255,255,255)" size="medium" color="#000000"
                            type="ball-clip-rotate-pulse" [fullScreen]="false">
                        </ngx-spinner>

                    </nz-table>
                </div>
            </div>

        </nz-card>

        <div *ngIf="createForm">
            <div class="main_outer_heading not_chat_screen">
                <h3><span class="back_icon" (click)="showCatalogueTable('catalogue')" nz-icon nzType="left"
                        nzTheme="outline"></span>
                    {{ editOption == "edit" ? 'Update' : 'Create'}} Catalogues
                </h3>
            </div>

            <!-- <nz-card> -->
            <app-create-catalogue [editCatalogue]="editOption" [editdata]="editCatalogueData"
                (showTable)="showCatalogueTable('create')"></app-create-catalogue>
            <!-- </nz-card> -->
        </div>
    </div>
</div>



<!-- (nzQueryParams)="onPageNumberChange('catalogueTable')" -->