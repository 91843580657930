import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { Subscription } from "rxjs";
import { ChatUser } from "./shared/chat.model";
// import { USERS } from "./shared/data";
import { WebSocketServiceService } from "./shared/web-socket-service.service";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-chat",
  templateUrl: "./chat.component.html",
  styleUrls: ["./chat.component.css"],
})
export class ChatComponent implements OnInit, OnDestroy {
  selectedUser: any;

  mobileDevice = false;
  chatArea: boolean;
  userDetail: boolean;

  showCatalogue: boolean;

  subscription: Subscription;
  socketResponse: any;

  catalogueData: any;

  closeChat: boolean;
  search: boolean;

  searchResult: any = null;

  customCatalogue: any;

  tagsScroll: any = "";
  tagsReset: boolean = false;

  @Output() socketData: EventEmitter<any> = new EventEmitter();

  // filter section (to maintain the current status of filters)

  customFilter: any = "all";
  statusText: any = "all";
  filterText: any = "all";

  currentRoute: string = "";
  @Input() chatData: any;

  routeSubscription!: Subscription;
  kanbanBoard: boolean = false;

  constructor(
    private webSocketService: WebSocketServiceService,
    private route: ActivatedRoute,
    private router: Router,
    private ref: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.webSocketService.listen("chat-page").subscribe((data) => {
      // console.log(data);
      this.socketResponse = data;
    });

    this.currentRoute = this.router.url;

    this.routeSubscription = this.route.params.subscribe((data) => {
      // console.log(data);
      if (data) {
        if (data["page"] == "area") {
          this.socketResponse = null;
          this.selectedUser = { _id: data["id"], name: data["name"] };
          this.userDetail = false;
          this.chatArea = true;
          this.search = false;
          this.showCatalogue = false;
        } else if (data["page"] == "details") {
          this.selectedUser = { _id: data["id"], name: data["name"] };
          this.chatArea = false;
          this.userDetail = true;
          this.search = false;
          this.showCatalogue = false;
        } else if (data["page"] == "search") {
          this.selectedUser = { _id: data["id"], name: data["name"] };
          this.chatArea = false;
          this.userDetail = true;
          this.search = true;
          this.showCatalogue = false;
        } else if (data["page"] == "catalogue") {
          this.catalogueData = { type: "catalogue" };
          this.selectedUser = { _id: data["id"], name: data["name"] };
          this.chatArea = false;
          this.userDetail = true;
          this.search = false;
          this.showCatalogue = true;
        } else if (data["page"] == "mobile") {
          this.socketResponse = null;
          this.chatArea = false;
          this.userDetail = false;
          this.search = false;
          this.showCatalogue = false;
        } else if (
          !data["page"] &&
          window.innerWidth < 992 &&
          this.router.url == "/chat"
        ) {
          this.router.navigate(["/chat/mobile", "id", "name"]);
        }
      }
    });

    if (window.innerWidth < 992) {
      this.mobileDevice = true;
    } else {
      this.mobileDevice = false;
    }
  }

  ngOnChanges() {
    if (!this.currentRoute.includes("/chat")) {
      console.log(this.chatData);
      this.onSelectedUser(this.chatData);
    }
  }

  ngAfterContentChecked() {
    this.ref.detectChanges();
  }

  ngOnDestroy() {
    // console.log("destroy");
    this.webSocketService.offSocket("chat-page");
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

  /**
   * changes chat user
   * @param user selected user
   */
  onSelectedUser(user: ChatUser): void {
    this.search = false;
    this.selectedUser = user;
    this.showCatalogue = false;
    this.showTagBox("user_detail_main");
    this.socketResponse = null;
    // console.log(user);
  }

  initialUser(user: any) {
    this.selectedUser = user;
    // console.log(user);
  }

  showUsersList(option) {
    // console.log(option);
    if (option.type == "catalogue") {
      // console.log(option);
      this.showCatalogue = true;
      this.catalogueData = option;
    }
  }

  showChats() {
    this.showCatalogue = false;
    this.catalogueData = [];
  }

  fetchUserList() {
    this.closeChat = true;
    setTimeout(() => {
      this.closeChat = false;
    }, 1000);
  }

  chatSearchToggle(event: any) {
    // console.log(event);
    this.search = true;
  }

  toggle() {
    this.search = false;
  }

  shareSearch(event: any) {
    // console.log(event);
    this.searchResult = event;
  }

  customMessage(event: any) {
    // console.log(event);
    this.customCatalogue = event;
  }

  showTagBox(event: any) {
    // console.log(event);
    this.tagsScroll = event;
    setTimeout(() => {
      this.tagsScroll = null;
    }, 1000);
  }

  reloadTags(event: boolean) {
    this.tagsReset = event;
    // console.log(event);
    setTimeout(() => {
      this.tagsReset = null;
    }, 1000);
  }

  setFilterData(event: any) {
    // console.log(event);
    this.customFilter = event.custom;
    this.filterText = event.text;
    this.statusText = event.status;
  }

  toggleBoardView(option: boolean) {
    this.kanbanBoard = option;
  }
}
