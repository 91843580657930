import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { CommonApiService } from "src/app/services/common-api.service";
import { NavService } from "src/app/services/nav.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.css"],
})
export class ChangePasswordComponent implements OnInit {
  resetPasswordForm!: FormGroup;
  currentPasswordVisible: boolean = false;
  confirmPasswordVisible: boolean = false;
  newPasswordVisible: boolean = false;

  passwordIncorrect: boolean = false;

  userData: any = {};
  submitting: boolean = false;
  @Input() mainUserData: any;

  private subscription: Subscription;

  constructor(
    private fb: FormBuilder,
    private apiService: CommonApiService,
    private toastr: ToastrService,
    private navService: NavService
  ) {}

  ngOnInit(): void {
    this.resetPasswordForm = this.fb.group({
      currentPassword: ["", Validators.required],
      newPassword: ["", Validators.required],
      confirmPassword: ["", Validators.required],
    });

    this.getUserData();
  }

  submitForm() {
    if (!this.mainUserData?.accessTo?.isExpired) {
      if (this.resetPasswordForm.valid) {
        if (
          this.resetPasswordForm.value.newPassword !=
          this.resetPasswordForm.value.confirmPassword
        ) {
          this.passwordIncorrect = true;
        } else {
          this.passwordIncorrect = false;
          this.submitting = true;

          let options = {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
            },
          };

          var data: any = {
            oldPassword: this.resetPasswordForm.value.currentPassword,
            password: this.resetPasswordForm.value.newPassword,
          };

          this.apiService
            .commonPostMethod(
              environment.url + "auth/change-password",
              data,
              options
            )
            .subscribe(
              (response: any) => {
                // console.log(response);
                this.submitting = false;
                this.toastr.success(response?.message, "", {
                  timeOut: 2500,
                });
                this.resetPasswordForm.reset();
              },
              (error: any) => {
                this.submitting = false;
                console.log(error);
                if (error?.error?.message) {
                  this.toastr.error(error?.error?.message, "", {
                    timeOut: 2500,
                  });
                } else {
                  this.toastr.error(error?.message, "", {
                    timeOut: 2500,
                  });
                }
              }
            );
        }
        // console.log(this.resetPasswordForm.value);
      } else {
        Object.values(this.resetPasswordForm.controls).forEach((control) => {
          if (control.invalid) {
            control.markAsDirty();
            control.updateValueAndValidity({ onlySelf: true });
          }
        });
      }
    }
  }

  getUserData() {
    this.subscription = this.navService.mainUserData.subscribe(
      (response: any) => {
        if (response) {
          // console.log(response);
          this.mainUserData = response.data;
        }
      }
    );
    // let options = {
    //   headers: {
    //     Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
    //   },
    // };

    // this.apiService
    //   .commonGetMethod(environment.url + "auth/get-user-data", options)
    //   .subscribe(
    //     (response: any) => {
    //       this.userData = response.data;
    //       console.log(this.userData);
    //     },
    //     (error: any) => {
    //       console.log(error);
    //       this.toastr.error(error.error.message, "", {
    //         timeOut: 2500,
    //       });
    //     }
    //   );
  }

  ngOnDestroy(): void {
    // console.log("api key destroy..");
    this.subscription.unsubscribe();
  }
}
