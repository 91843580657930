import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import html2canvas from "html2canvas";
// import * as $ from "jquery";
// declare var jsPDF;
import { jsPDF } from "jspdf";
import { ToastrService } from "ngx-toastr";
import { AlertServiceService } from "src/app/services/alert-service.service";
import { CommonApiService } from "src/app/services/common-api.service";
import { NavService } from "src/app/services/nav.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-setting-page",
  templateUrl: "./setting-page.component.html",
  styleUrls: ["./setting-page.component.css"],
})
export class SettingPageComponent implements OnInit {
  menus: any = [
    // plateforms
    // {
    //   name: "PLATFORMS",
    //   role: "admin-owner",
    //   options: [
    //     {
    //       name: "Whatsapp",
    //       icon: "whats-app",
    //       role: "admin-owner",
    //     },
    //     {
    //       name: "Webchat",
    //       icon: "wechat",
    //       role: "admin-owner",
    //     },
    //   ],
    // },
    // configures
    {
      name: "CONFIGURATION",
      role: "admin-owner",
      options: [
        {
          name: "Whatsapp",
          icon: "whats-app",
          role: "admin-owner",
          availFor: "both",
        },
        {
          name: "Account Details",
          icon: "user",
          role: "admin-owner",
          availFor: "both",
        },
        {
          name: "Working Hours",
          icon: "clock-circle",
          role: "admin-owner",
          availFor: "both",
        },
        {
          name: "Users & Role",
          icon: "usergroup-add",
          role: "admin-owner",
          availFor: "web",
        },
        {
          name: "Field Collection",
          icon: "disconnect",
          role: "admin-owner",
          availFor: "web",
        },
        {
          name: "Tags",
          icon: "tag",
          role: "admin-owner",
          availFor: "web",
        },
        {
          name: "Quick Message",
          icon: "file-text",
          role: "admin-owner",
          availFor: "web",
        },
        {
          name: "WhatsApp Template",
          icon: "layout",
          role: "admin-owner",
          availFor: "web",
        },
        // {
        //   name: "Conversation",
        //   icon: "compass",
        //   role: "admin-owner",
        //   availFor: "web",
        // },
        // {
        //   name: "Integration",
        //   icon: "partition",
        //   role: "admin-owner",
        //   availFor: "web",
        // },
      ],
    },
    // profile
    {
      name: "USER",
      role: "admin-owner-teammate",
      options: [
        {
          name: "Profile",
          icon: "user",
          role: "admin-owner-teammate",
          availFor: "web",
        },
        {
          name: "Change Password",
          icon: "lock",
          role: "admin-owner-teammate",
          availFor: "both",
        },
        // {
        //   name: "API Keys",
        //   icon: "key",
        //   role: "admin-owner",
        // },
      ],
    },
  ];

  paths: any = [
    "whatsapp",
    "webchat",
    "account-details",
    "working-hours",
    "users-&-role",
    "field-collection",
    "tags",
    "quick-message",
    "whatsapp-template",
    "integration",
    "conversation",
  ];

  allPaths: any = [
    "whatsapp",
    "webchat",
    "account-details",
    "working-hours",
    "users-&-role",
    "field-collection",
    "tags",
    "quick-message",
    "whatsapp-template",
    "integration",
    "profile",
    "change-password",
    "conversation",
  ];

  selectedTab: any = "";
  mainUserData: any = {};

  menuPage: boolean = true;

  imageUrl: any = environment.imageUrl;

  screenWidth: any = window.innerWidth;

  assignUsersList: any = [];
  assignForm: FormGroup;
  updating: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: CommonApiService,
    private navService: NavService,
    private fb: FormBuilder,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.assignForm = this.fb.group({
      assignee: ["", Validators.required],
    });
    this.getMainUserData();
    // this.getAssignUserList();
    // this.getSetting();
  }

  getUrl() {
    this.route.params.subscribe((params) => {
      // console.log(params["id"]);
      if (window.innerWidth > 992) {
        if (params["id"]) {
          this.selectedTab = params["id"];
          if (
            (this.mainUserData.role == "teammate" &&
              this.paths.indexOf(params["id"]) > -1) ||
            this.allPaths.indexOf(params["id"]) == -1
          ) {
            this.router.navigate(["/setting/profile"]);
          }
        } else {
          this.selectedTab = "whatsapp";
        }
      } else {
        if (
          (this.mainUserData.role == "teammate" &&
            this.paths.indexOf(params["id"]) > -1) ||
          this.allPaths.indexOf(params["id"]) == -1
        ) {
          this.router.navigate(["/setting/profile"]);
        }
        this.selectedTab = params["id"];
        this.menuPage = params["id"] == "profile";
      }
    });
  }

  switchPage(data: any) {
    this.updating = false;
    if (window.innerWidth < 992) {
      this.menuPage = false;
    }
    this.router.navigate([
      "/setting",
      data.name.toLowerCase().replaceAll(" ", "-"),
    ]);

    // this.selectedTab = data.name;
  }

  showDefault(menu: any) {
    if (menu.name == "PLATFORMS") {
      this.router.navigate(["/setting/whatsapp"]);
    }
  }

  saveAsPdf() {
    html2canvas(document.querySelector("#main-section")).then((canvas) => {
      var doc = new jsPDF("p", "mm");
      var imgData = canvas.toDataURL("image/png");
      var imgWidth = 210;
      var pageHeight = 297;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;

      var position = 0; // give some top padding to first page

      doc.addImage(imgData, "PNG", -1.5, position, 0, 0);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position += heightLeft - imgHeight; // top padding for other pages
        doc.addPage();
        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      doc.save("A6-A7.pdf");
    });
  }

  getMainUserData() {
    // this.navService.mainUserData.subscribe((response: any) => {
    //   if (response) {
    //     console.log(response);
    //     this.mainUserData = response.data.userData;
    //   }
    // });
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonGetMethod(environment.url + "auth/get-user-data", options)
      .subscribe(
        (response: any) => {
          this.mainUserData = response.data.userData;
          // console.log(this.mainUserData);
          this.getUrl();
        },
        (error: any) => {
          console.log(error);
          if (
            error?.error?.message == "jwt expired" ||
            error?.error?.message == "jwt must be provided" ||
            error?.error?.message == "jwt malformed" ||
            error?.error?.message == "invalid signature" ||
            error?.error?.message == "Given token user does not exist." ||
            error?.error?.message == "invalid token"
          ) {
            this.navService.tokenExpired();
          }
        }
      );
  }

  getAssignUserList() {
    var options: any = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };
    this.apiService
      .commonGetMethod(
        environment.url + "auth/get-all-user-data?projection=name",
        options
      )
      .subscribe(
        (response: any) => {
          this.assignUsersList = response.data.users;
        },
        (error) => {
          console.log(error);
        }
      );
  }

  getSetting() {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(environment.url + "settings/get", {}, options)
      .subscribe(
        (response: any) => {
          console.log(response);
          var res = response.data.settings?.ch;
          if (res?.type == "Flow") {
            this.assignForm.get("assignee").setValue("Flow");
          } else if (res?.type == "User") {
            this.assignForm.get("assignee").setValue(res.assignee);
          }
        },
        (error: any) => {
          console.log(error);
        }
      );
  }

  // takePicture() {
  //   const canvas = document.createElement("canvas");
  //   canvas.width = this.video.nativeElement.videoWidth;
  //   canvas.height = this.video.nativeElement.videoHeight;
  //   const context = canvas.getContext("2d");
  //   context.drawImage(this.video.nativeElement, 0, 0);
  //   const image = canvas.toDataURL("image/png");
  //   console.log(image);
  // }

  editProfile() {
    this.menuPage = false;
    this.selectedTab = "profile";
  }

  assignConversation() {
    if (this.assignForm.valid) {
      this.updating = true;
      let options = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
        },
      };

      var req: any = {
        type: "Flow",
        assignee: "63bd0f59c4c6de4831b29419",
      };

      if (this.assignForm.value.assignee == "Flow") {
        req = {
          type: "Flow",
        };
      } else {
        req = {
          type: "User",
          assignee: this.assignForm.value.assignee,
        };
      }

      this.apiService
        .commonPostMethod(
          environment.url + "settings/update-conversation-handler",
          req,
          options
        )
        .subscribe(
          (response: any) => {
            console.log(response);
            this.toastr.success(response.message, "", {
              timeOut: 2500,
            });
            this.updating = false;
          },
          (error) => {
            console.log(error);
            this.updating = false;
          }
        );
    } else {
      this.updating = false;
      Object.values(this.assignForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }
}
