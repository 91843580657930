<div class="row overflow_row_mobile">
    <div class="col-12 main_outer_heading">
        <h3>
            <span routerLink="/contacts" nz-icon nzType="left" nzTheme="outline"></span>
            Upload History
        </h3>
    </div>

    <!-- <div class="col-12">
        <nz-card class="contacts_top_card">
        </nz-card>
    </div> -->

    <div class="col-12 mt-2" id="main-section">
        <nz-card class="customer_right_card customers_table_card">
            <nz-table class="contacts_table main_contacts_table" #campaignsListTable [nzData]="uploadHistory"
                [nzScroll]="{ y: tableHeight, x: '600px' }" [nzFrontPagination]="false">
                <thead>
                    <tr>
                        <th *ngFor="let column of orderColumn" [nzWidth]="column.width">
                            {{ column.title }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr id="categoryTable" *ngFor="let item of campaignsListTable.data; let i = index">
                        <td>{{item?.name ? item?.name : '-'}}</td>
                        <td>{{item?.createdCount ? item?.createdCount : '-'}}</td>
                        <td>{{item?.updatedCount ? item?.updatedCount : '-'}}</td>
                        <td>{{item?.duplicateRemovedCount ? item?.duplicateRemovedCount : '-'}}</td>
                        <td>{{item?.isFinished ? 'Finished' : 'Running'}}</td>
                        <td>
                            <a [href]="imageUrl + item.sourceUrl" target="_blank">
                                Download report
                            </a>
                        </td>
                        <td>
                            <span>
                                {{item?.errorMessage ? item?.errorMessage : '-'}}
                            </span>
                        </td>
                    </tr>
                </tbody>
                <ngx-spinner bdColor="rgb(255,255,255)" size="medium" color="#000000" type="ball-clip-rotate-pulse"
                    [fullScreen]="false"></ngx-spinner>
            </nz-table>
        </nz-card>
    </div>
</div>