<div class="main_chat_container">

    <div *ngIf="!kanbanBoard" class="row main_chat_row">

        <div *ngIf="currentRoute.includes('/chat')" class="col-12 main_outer_heading d_mobile_none">
            <h3>Chats</h3>
        </div>

        <div *ngIf="(!chatArea && !userDetail && currentRoute.includes('/chat')) || (!mobileDevice && currentRoute.includes('/chat'))"
            class="col-xxl-3 col-lg-3 user_column">
            <app-chat-users (stateManagementData)="setFilterData($event)" [customFilter]="customFilter"
                [statusText]="statusText" [filterText]="filterText" [socketData]="socketResponse"
                [closeChat]="closeChat" [selectedUser]="selectedUser" (initUser)="initialUser($event)"
                (selectUser)="onSelectedUser($event)"></app-chat-users>
        </div>
        <!-- End col -->

        <div *ngIf="chatArea && !userDetail || !mobileDevice" class="col-xxl-6 col-lg-6 message_column"
            [ngClass]="{'col-xxl-8 col-lg-8' : currentRoute != '/chat'}">
            <app-chat-area [tagsReset]="tagsReset" [customCatalogue]="customCatalogue" [searchChat]="searchResult"
                [socketData]="socketResponse" (switchChatSearch)="chatSearchToggle($event)"
                (enableBoardView)="toggleBoardView(true)" (switchPage)="showUsersList($event)"
                [selectedUser]="selectedUser" (reloadUserList)="fetchUserList()" (showTags)="showTagBox($event)">
            </app-chat-area>
        </div> <!-- End col -->

        <div *ngIf="userDetail || !mobileDevice" class="col-xxl-3 col-lg-3 message_column"
            [ngClass]="{'col-xxl-4 col-lg-4' : currentRoute != '/chat'}">
            <app-chat-user-detail (reloadTags)="reloadTags($event)" (shareSearchResult)="shareSearch($event)"
                [showSearch]="search" [catalogueData]="catalogueData" [socketData]="socketResponse"
                [showCatalogue]="showCatalogue" (switchPage)="showChats()" (searchToggle)="toggle()"
                [tagsScroll]="tagsScroll" [userData]="selectedUser" (customMessage)="customMessage($event)">
            </app-chat-user-detail>
        </div>

    </div>

    <div *ngIf="kanbanBoard" class="">
        <app-kanban-board (disableBoardView)="toggleBoardView(false)"></app-kanban-board>
    </div>
</div>