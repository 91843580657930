import { Injectable } from "@angular/core";
import * as moment from "moment";
import * as RecordRTC from "recordrtc";

import { Observable, Subject } from "rxjs";
import { CommonApiService } from "./common-api.service";

// import { opusRecorder } from "opus-recorder";

interface RecordedAudioOutput {
  blob: File;
  title: string;
}

@Injectable({
  providedIn: "root",
})
export class AudioRecordingService {
  moment: any = moment;

  private stream;
  private recorder;
  private interval;
  private startTime;
  private _recorded = new Subject<any>();
  private _recordingTime = new Subject<string>();
  private _recordingFailed = new Subject<string>();

  constructor(private apiService: CommonApiService) {}

  getRecordedBlob(): Observable<RecordedAudioOutput> {
    return this._recorded.asObservable();
  }

  getRecordedTime(): Observable<string> {
    return this._recordingTime.asObservable();
  }

  recordingFailed(): Observable<string> {
    return this._recordingFailed.asObservable();
  }

  startRecording() {
    if (this.recorder) {
      // It means recording is already started or it is already recording something
      return;
    }

    // this.opusRecorder = new OpusRecorder();

    this._recordingTime.next("00:00");
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((s) => {
        console.log("main", s);
        this.stream = s;
        this.record();
      })
      .catch((error) => {
        this._recordingFailed.next();
      });
  }

  abortRecording() {
    this.stopMedia();
  }

  private record() {
    this.recorder = new RecordRTC.StereoAudioRecorder(this.stream, {
      type: "audio",
      mimeType: "audio/opus",
      audioBitsPerSecond: 128000,
    });

    this.recorder.record();
    this.startTime = moment();
    this.interval = setInterval(() => {
      // console.log(this.recorder);
      const currentTime = moment();
      const diffTime = moment.duration(currentTime.diff(this.startTime));
      const time =
        this.toString(diffTime.minutes()) +
        ":" +
        this.toString(diffTime.seconds());
      this._recordingTime.next(time);
    }, 1000);
  }

  private toString(value) {
    let val = value;
    if (!value) val = "00";
    if (value < 10) val = "0" + value;
    return val;
  }

  stopRecording() {
    console.log(this.stream);
    if (this.recorder) {
      this.recorder.stop(
        (file) => {
          // console.log(await file.arrayBuffer());
          // this.test(await file.arrayBuffer());
          if (this.startTime) {
            const mp3Name = encodeURIComponent(
              "audio_" + new Date().getTime() + ".opus"
            );
            this.stopMedia();
            // Convert the recorded audio to Blob with the correct MIME type and codecs
            const audioBlob = new Blob([file], {
              type: "audio/ogg; codecs=opus",
            });

            // Create a File object with the desired MIME type
            const audioFile = new File([audioBlob], mp3Name, {
              type: "audio/ogg",
            });
            this._recorded.next(audioFile);
            // this._recorded.next({ blob: file, title: mp3Name });
          }
        },
        () => {
          this.stopMedia();
          this._recordingFailed.next();
        }
      );
    }
  }

  test(value: any) {
    this.apiService
      .commonPostMethod(
        "https://api-test.ownchat.app/integrations/pet-pooja/get-store-status",
        { test: value },
        {}
      )
      .subscribe((response: any) => {
        console.log(response);
      });
  }

  private stopMedia() {
    if (this.recorder) {
      this.recorder = null;
      clearInterval(this.interval);
      this.startTime = null;
      if (this.stream) {
        this.stream.getAudioTracks().forEach((track) => track.stop());
        this.stream = null;
      }
    }
  }
}
