<div class="row top_row overflow_row_mobile">
    <!-- <div class="col-lg-11 main_outer_heading">
        <h3>
            Dashboard
        </h3>
    </div> -->

    <div class="col-lg-11 content_col">
        <div class="row">
            <div class="col-lg-11 main_outer_heading">
                <h3>
                    Dashboard
                </h3>
            </div>

            <ng-template #planCard let-name="sample">
                <div class="col-4">
                    <nz-card class="plan_balance_card ">
                        <div class="plan_balance dashboard_top_card">
                            <div class="plan_detail">
                                <div class="">
                                    <p>{{name}}</p>
                                    <h6>PREPAID</h6>
                                </div>
                                <span nzTrigger="click" nz-dropdown [nzDropdownMenu]="planOptions" class="more_icon"
                                    nz-icon nzType="more" nzTheme="outline"></span>
                                <nz-dropdown-menu #planOptions="nzDropdownMenu">
                                    <ul class="drop_down_menu" nz-menu>
                                        <!-- <li routerLink="/recharge-history" nz-menu-item>Recharge History</li> -->
                                        <li routerLink="/dashboard/conversation-history" nz-menu-item>
                                            Conversation History
                                        </li>
                                    </ul>
                                </nz-dropdown-menu>
                            </div>
                            <div class="balance_detail">
                                <p>Balance</p>
                                <h5>
                                    <span>{{userData?.accessTo?.country == 'India' ? 'Rs. ' : 'USD. '}}</span>
                                    {{userData?.accessTo?.credits?.toFixed(2)}}
                                </h5>
                            </div>
                            <div class="btn_section mt-1">
                                <a (click)="showAddMoneyModal()" nz-button nzType="primary"
                                    class="create-button setting_add_button">
                                    <span>Add Money</span>
                                </a>
                            </div>
                        </div>
                    </nz-card>
                </div>
            </ng-template>

            <ng-container [ngTemplateOutlet]="planCard" [ngTemplateOutletContext]="{ sample: 'Plan' }"></ng-container>

            <div class="col-4">
                <nz-card class="">
                    <div class="conn_num_main dashboard_top_card">
                        <div class="conn_num_top">
                            <div class="conn_num_top_left">
                                <img src="../../../assets/images/chat/whatsapp_icon.png" alt="">
                                <div class="">
                                    <p>Connected</p>
                                    <h6>{{userData?.accessTo?.phoneNumber}}</h6>
                                </div>
                            </div>
                            <span (click)="showVideo()" class="video_icon" nz-icon nzType="youtube"
                                nzTheme="outline"></span>
                        </div>
                        <div class="link_copy_section">
                            <p>Whatsapp Link</p>
                            <nz-form-item>
                                <nz-input-group [nzSuffix]="copySecret">
                                    <input readonly [value]="'https://wa.me/+' + userData?.accessTo?.phoneNumber"
                                        autocomplete="off" type="text" nz-input placeholder="whatsapp link">
                                </nz-input-group>
                                <ng-template #copySecret>
                                    <span (click)="copyWhatsappLink()" class="key_copy" nz-icon nzType="copy"
                                        nzTheme="outline"></span>
                                </ng-template>
                            </nz-form-item>
                        </div>
                        <div class="link_section">
                            <a href="https://ownchat.app/whatsapp-link-generator-page" target="_blank">
                                <img class="link_img" src="../../../assets/images/icons/open-link.svg" alt="">
                                Create a Custom Whatsapp Link
                            </a>
                        </div>
                    </div>
                </nz-card>
            </div>

            <div class="col-4">
                <nz-card class="">
                    <div class="conn_num_main dashboard_top_card">
                        <div class="conn_num_top">
                            <div class="conn_num_top_left">
                                <div class="">
                                    <h5>WhatsApp Chat Widget</h5>
                                    <span class="widget_description">
                                        Add a Whatsapp Chat widget to your website and Allow customers to reach you
                                        easily
                                        and
                                        enhancing their overall experience.
                                    </span>
                                </div>
                            </div>
                            <span (click)="showVideo()" class="video_icon" nz-icon nzType="youtube"
                                nzTheme="outline"></span>
                        </div>
                        <div class="link_section">
                            <a href="https://ownchat.app/whatsapp-chatwidget" target="_blank">
                                <img class="link_img" src="../../../assets/images/icons/open-link.svg" alt="">
                                Create WhatsApp chat Widget
                            </a>
                        </div>
                    </div>
                </nz-card>
            </div>

            <div class="col-md-6">
                <nz-card class="chart_card">
                    <canvas id="lineCostChart"></canvas>
                </nz-card>
            </div>

            <div class="col-md-6">
                <nz-card class="chart_card">
                    <canvas id="lineCountChart"></canvas>
                </nz-card>
            </div>

            <ngx-spinner bdColor="rgb(255,255,255)" size="medium" color="#000000" type="ball-clip-rotate-pulse"
                [fullScreen]="false">
            </ngx-spinner>
        </div>
    </div>


</div>



<nz-modal [(nzVisible)]="addMoneyModal" [nzFooter]="null" nzTitle="Add Money" nzCentered
    (nzOnCancel)="closeAddMoneyModal()">
    <ng-container *nzModalContent>
        <div class="">
            <nz-form-item>
                <nz-form-label class="large_label" [nzSm]="24" [nzXs]="24">
                    Whatsapp Number
                </nz-form-label>
                <ng-container>
                    <nz-form-control [nzSm]="24" [nzXs]="24">
                        <nz-input-group>
                            <input readonly [value]="userData?.accessTo?.phoneNumber" autocomplete="off" nz-input
                                [placeholder]="'Whatsapp Number'" type="text" />
                        </nz-input-group>
                    </nz-form-control>
                </ng-container>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label class="large_label" [nzSm]="24" [nzXs]="24">
                    Recipient Country
                </nz-form-label>
                <ng-container>
                    <nz-form-control [nzSm]="24" [nzXs]="24">
                        <nz-input-group>
                            <nz-select [(ngModel)]="selectedCountry" (ngModelChange)="countryChange()" nzShowSearch
                                class="w-100 select_dropdown" nzPlaceHolder="Select Country">
                                <nz-option *ngFor="let item of countries" [nzLabel]="item" [nzValue]="item">
                                </nz-option>
                                <nz-option [nzLabel]="'Other'" [nzValue]="'Other'">
                                </nz-option>
                            </nz-select>
                        </nz-input-group>
                    </nz-form-control>
                </ng-container>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label class="large_label" [nzSm]="24" [nzXs]="24">
                    Business initiated conversation planning to create.
                </nz-form-label>
                <ng-container>
                    <nz-form-control [nzSm]="24" [nzXs]="24">
                        <nz-input-group>
                            <nz-slider (nzOnAfterChange)="sliderRelease()" [nzMarks]="businessMarks" [nzMin]="0"
                                [nzMax]="10000" [(ngModel)]="businessValue"></nz-slider>
                        </nz-input-group>
                    </nz-form-control>
                </ng-container>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label class="large_label" [nzSm]="24" [nzXs]="24">
                    User initiated conversation planning to create.
                </nz-form-label>
                <ng-container>
                    <nz-form-control [nzSm]="24" [nzXs]="24">
                        <nz-input-group>
                            <nz-slider (nzOnAfterChange)="sliderRelease()" [nzMarks]="serviceMarks" [nzMin]="0"
                                [nzMax]="10000" [(ngModel)]="serviceValue"></nz-slider>
                        </nz-input-group>
                    </nz-form-control>
                </ng-container>
            </nz-form-item>

            <div class="transaction_fee price_div price_div_space mt-5">
                <span>Estimated conversation costs</span>
                <p>{{estimation?.cost?.toFixed(2)}}</p>
            </div>
            <div class="price_section price_div price_div_space">
                <span>GST</span>
                <p>{{estimation?.gst?.toFixed(2)}}</p>
            </div>
            <div class="transaction_fee price_div price_div_right">
                <span>Subtotal</span>
                <p>{{(estimation?.totalPrice)?.toFixed(2)}}</p>
            </div>

            <div class="submit_btn_section mt-3">
                <a (click)="addMoney()" nz-button nzType="primary" [nzLoading]="addingMoney"
                    class="create-button setting_add_button">
                    <span>Add Money</span>
                </a>
            </div>
        </div>
    </ng-container>
</nz-modal>

<!-- video modal -->

<nz-modal [nzBodyStyle]="{'padding' : '0px'}" [(nzVisible)]="videoModal" nzTitle="" nzCentered nzWidth="auto"
    [nzClosable]="false" [nzFooter]="null" (nzOnCancel)="closeVideoModal()">
    <ng-container *nzModalContent>

        <div class="video_cover">
            <iframe width="560" height="315" [src]="videoLink" title="YouTube video player" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen></iframe>
        </div>

    </ng-container>
</nz-modal>