import { Component, Input, OnInit } from "@angular/core";
import { CommonApiService } from "src/app/services/common-api.service";
import { environment } from "src/environments/environment";
import * as moment from "moment";
import { ExportExcelService } from "src/app/services/export-excel.service";
import * as XLSX from "xlsx";
import { ToastrService } from "ngx-toastr";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { AlertServiceService } from "src/app/services/alert-service.service";
import { countryCodes } from "../chat/shared/messageData";

@Component({
  selector: "app-user-page",
  templateUrl: "./user-page.component.html",
  styleUrls: ["./user-page.component.css"],
})
export class UserPageComponent implements OnInit {
  moment: any = moment;
  orderColumn = [
    {
      title: "Name",
      width: "60px",
    },
    {
      title: "Phone No",
      width: "70px",
    },
    {
      title: "E-mail",
      width: "120px",
    },
    {
      title: "Role",
      width: "60px",
    },
  ];

  userList: any = [];
  bulkUploadModal = false;
  singleModal = false;
  uploadedJSONFile = [];
  singleCustomerForm!: FormGroup;
  updateCustomerForm!: FormGroup;
  creatingCustomer = false;
  updateModal = false;
  updateCustomerData: any = {};

  selectedTab = "users";

  showForm = false;
  addProduct: boolean = false;

  countryCodes: any = countryCodes;

  tableHeight: any = "";

  @Input() mainUserData: any;

  assignUsersList: any = [];
  assignForm: FormGroup;
  updating: boolean = false;

  constructor(
    private apiService: CommonApiService,
    public ete: ExportExcelService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private alertService: AlertServiceService
  ) {}

  ngOnInit(): void {
    this.assignForm = this.fb.group({
      assignee: ["", Validators.required],
    });
    this.fetchData();
    this.getAssignUserList();
    this.getSetting();

    if (this.mainUserData?.accessTo?.sandboxId && window.innerWidth > 992) {
      this.tableHeight = "58vh";
    } else {
      this.tableHeight = "67vh";
    }

    this.singleCustomerForm = this.fb.group({
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      mailId: [null, [Validators.required, Validators.email]],
      countryCode: ["+91", Validators.required],
      mobileNumber: ["", Validators.required],
      role: ["", Validators.required],
    });

    this.updateCustomerForm = this.fb.group({
      cusName: ["", Validators.required],
      cusMail: [null, [Validators.email]],
      cusBroadCast: true,
    });
  }

  getAssignUserList() {
    var options: any = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };
    this.apiService
      .commonGetMethod(
        environment.url + "auth/get-all-user-data?projection=name",
        options
      )
      .subscribe(
        (response: any) => {
          this.assignUsersList = response.data.users;
        },
        (error) => {
          console.log(error);
        }
      );
  }

  getSetting() {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(environment.url + "settings/get", {}, options)
      .subscribe(
        (response: any) => {
          console.log(response);
          var res = response.data.settings?.ch;
          if (res?.type == "Flow") {
            this.assignForm.get("assignee").setValue("Flow");
          } else if (res?.type == "User") {
            this.assignForm.get("assignee").setValue(res.assignee);
          }
        },
        (error: any) => {
          console.log(error);
        }
      );
  }

  // user design

  switchTable(tabSelected: string) {
    this.selectedTab = tabSelected;
  }
  toggleForm() {
    this.showForm = !this.showForm;
  }

  fetchData() {
    this.spinner.show();
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonGetMethod(environment.url + "auth/get-all-user-data", options)
      .subscribe(
        (response: any) => {
          this.spinner.hide();
          this.userList = response.data.users;
          // console.log(this.userList);
        },
        (error: any) => {
          this.spinner.hide();
          console.log(error);
        }
      );
  }

  showAddForm() {
    // console.log(planName);
    if (!this.mainUserData?.accessTo?.isExpired) {
      if (!this?.mainUserData?.accessTo?.sandboxId) {
        var planName =
          this.mainUserData.accessTo.curSubsPlan.name.split(" ")[0];
        if (this.userList.length >= 3 && planName == "Standard") {
          var errHead: any = "You are using Standard Plan!";
          var errBody: any = "You can add maximum 3 members by this plan!..";
          this.alertService.freeTrialWarning(errHead, errBody);
        } else if (this.userList.length >= 5 && planName == "Advanced") {
          var errHead: any = "You are using Advanced Plan!";
          var errBody: any = "You can add maximum 5 members by this plan!..";
          this.alertService.freeTrialWarning(errHead, errBody);
        } else {
          this.singleCustomerForm.reset();
          this.singleCustomerForm.get("countryCode").setValue("+91");
          this.showForm = true;
          this.addProduct = true;
        }
      } else {
        var errHead: any = "You are using Sandbox account!";
        var errBody: any = "Please Subscribe any Plan to Add Members!..";
        this.alertService.freeTrialWarning(errHead, errBody);
      }
    }
  }

  showUpdateForm(cusData: any) {
    if (this.mainUserData?.accessTo?.isExpired) {
      this.alertService.warning();
    } else {
      this.singleCustomerForm.reset();
      // console.log(cusData);
      this.updateCustomerData = cusData;
      this.singleCustomerForm.get("firstName").setValue(cusData.firstName);
      this.singleCustomerForm.get("lastName").setValue(cusData.lastName);
      this.singleCustomerForm.get("mobileNumber").setValue(cusData.phoneNo);
      this.singleCustomerForm.get("mailId").setValue(cusData.email);
      this.singleCustomerForm.get("role").setValue(cusData.role);
      this.singleCustomerForm.get("countryCode").setValue(cusData.countryCode);
      this.addProduct = false;
      this.showForm = true;
    }
  }

  createCustomer() {
    if (this.singleCustomerForm.valid) {
      // console.log(this.singleCustomerForm.value);
      this.creatingCustomer = true;
      let options = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
        },
      };

      var url = "";
      var formData: any = {
        firstName: this.singleCustomerForm.value.firstName,
        lastName: this.singleCustomerForm.value.lastName,
        countryCode: this.singleCustomerForm.value.countryCode,
        phoneNo: this.singleCustomerForm.value.mobileNumber.toString(),
        // phoneNo: parseFloat(
        //   this.singleCustomerForm.value.countryCode.replaceAll("+", "") +
        //     this.singleCustomerForm.value.mobileNumber.toString()
        // ),
        email: this.singleCustomerForm.value.mailId,
        role: this.singleCustomerForm.value.role,
      };

      if (!this.addProduct) {
        formData.userId = this.updateCustomerData._id;
        url = "auth/update-user";
      } else {
        url = "auth/create-user";
      }

      this.apiService
        .commonPostMethod(environment.url + url, formData, options)
        .subscribe(
          (response: any) => {
            // console.log(response);
            if (response.status == "success") {
              this.toastr.success(response.message, "", {
                timeOut: 2500,
              });
              this.singleModal = false;
              this.showForm = false;
              this.fetchData();
              this.creatingCustomer = false;
            }
          },
          (error) => {
            console.log(error);
            this.creatingCustomer = false;
            if (error?.error?.message) {
              this.toastr.error(error?.error?.message, "", {
                timeOut: 2500,
              });
            } else {
              this.toastr.error(error?.message, "", {
                timeOut: 2500,
              });
            }
          }
        );
      // console.log(formData);
    } else {
      Object.values(this.singleCustomerForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  updateCustomer() {
    // console.log(this.updateCustomerForm.value);
    if (this.updateCustomerForm.valid) {
      let options = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
        },
      };

      var data = {
        customerId: this.updateCustomerData._id,
        name: this.updateCustomerForm.value.cusName,
        email: this.updateCustomerForm.value.cusMail,
        allowBroadcast: this.updateCustomerForm.value.cusBroadCast,
      };

      this.apiService
        .commonPostMethod(environment.url + "customers/update", data, options)
        .subscribe(
          (response: any) => {
            // console.log(response);
            this.toastr.success(response.message, "", {
              timeOut: 2500,
            });
            this.fetchData();
            this.updateCustomerData = {};
            this.updateModal = false;
          },
          (error: any) => {
            console.log(error);
            this.updateModal = false;
          }
        );
    } else {
      Object.values(this.updateCustomerForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  // customer design

  // BULK UPLOAD FORM MODAL

  // openBulkUploadModal() {
  //   this.bulkUploadModal = true;
  // }

  // closeOtpModal() {
  //   this.bulkUploadModal = false;
  // }

  // // UPLOAD FILE

  // filesDropped(files: any): void {
  //   // console.log(files);
  //   this.xlsxToJSON(files[0].file);
  // }

  // uploadFile(ev) {
  //   // console.log(ev.target.files[0].type);
  //   this.xlsxToJSON(ev.target.files[0]);
  //   ev.target.value = "";
  // }

  // xlsxToJSON(ev) {
  //   let workBook = null;
  //   let jsonData = null;
  //   const reader = new FileReader();
  //   const file = ev;
  //   reader.onload = (event) => {
  //     const data = reader.result;
  //     workBook = XLSX.read(data, { type: "binary" });
  //     jsonData = workBook.SheetNames.reduce((initial, name) => {
  //       const sheet = workBook.Sheets[name];
  //       initial[name] = XLSX.utils.sheet_to_json(sheet);
  //       return initial;
  //     }, {});
  //     const dataString = jsonData;
  //     var temp: any;
  //     var fileName;
  //     for (temp in dataString) {
  //       fileName = temp;
  //     }
  //     this.uploadedJSONFile = dataString[fileName];
  //     console.log(this.uploadedJSONFile);
  //     this.csvUpload();
  //   };
  //   reader.readAsBinaryString(file);
  // }

  // // UPLOAD CSV FILE

  // csvUpload() {
  //   var temp: any = {};
  //   var formData = [];
  //   for (let i = 0; i < this.uploadedJSONFile.length; i++) {
  //     // for(temp1 in this.uploadedJSONFile[i]){
  //     //   console.log(temp1);
  //     // }
  //     temp = {};
  //     temp.name = this.uploadedJSONFile[i].Name;
  //     temp.phoneNo = this.uploadedJSONFile[i]["Phone Number"];
  //     temp.allowBroadcast = this.uploadedJSONFile[i]["Allow Broadcast"];
  //     formData.push(temp);
  //   }
  //   console.log(formData);

  //   this.uploadCSVData(formData);
  // }

  // uploadCSVData(formData) {
  //   let options = {
  //     headers: {
  //       Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
  //     },
  //   };

  //   this.apiService
  //     .commonPostMethod(
  //       environment.url + "customers/csv-upload",
  //       formData,
  //       options
  //     )
  //     .subscribe(
  //       (response: any) => {
  //         console.log(response);
  //         if (response.status == "success") {
  //           this.toastr.success(response.message, "", {
  //             timeOut: 2500,
  //           });
  //           this.fetchData();
  //           this.bulkUploadModal = false;
  //         }
  //       },
  //       (error) => {
  //         console.log(error);
  //         this.toastr.error(
  //           "File Upload Failed ",
  //           "Please ReUpload Your File",
  //           {
  //             timeOut: 2500,
  //           }
  //         );
  //       }
  //     );
  // }

  // // DOWNLOAD SAMPLE EXCEL FILE

  // downloadSampleFile() {
  //   let sampleData = {
  //     title: "Receipts Report",
  //     data: [
  //       ["Sample name 1", "919384227587", "true"],
  //       ["Sample name 2", "916379908816", "false"],
  //     ],
  //     headers: ["Name", "Phone Number", "Allow Broadcast"],
  //   };

  //   this.ete.exportExcel(sampleData, "sample");
  // }

  // // SINGLE CUSTOMER MODAL

  // // TABLE SCROLL TOP ON PAGE CHANGE

  onPageNumberChange(event) {
    // document.getElementById(event).scrollIntoView();
    // setTimeout(() => {
    //   window.scroll(0, 0);
    // }, 300);
  }

  assignConversation() {
    if (this.assignForm.valid) {
      this.updating = true;
      let options = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
        },
      };

      var req: any = {
        type: "Flow",
        assignee: "63bd0f59c4c6de4831b29419",
      };

      if (this.assignForm.value.assignee == "Flow") {
        req = {
          type: "Flow",
        };
      } else {
        req = {
          type: "User",
          assignee: this.assignForm.value.assignee,
        };
      }

      this.apiService
        .commonPostMethod(
          environment.url + "settings/update-conversation-handler",
          req,
          options
        )
        .subscribe(
          (response: any) => {
            console.log(response);
            this.toastr.success(response.message, "", {
              timeOut: 2500,
            });
            this.updating = false;
          },
          (error) => {
            console.log(error);
            this.updating = false;
          }
        );
    } else {
      this.updating = false;
      Object.values(this.assignForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }
}
