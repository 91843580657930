<div class="failed_payment_div">
    <div class="text-center">
        <img src="../../../../assets/images/gif/payment-failed.gif" alt="">

        <h3>Payment Failed</h3>

        <a routerLink="/subscription" nz-button nzType="primary" class="create-button mt-5">
            <span>Retry</span>
        </a>
    </div>
</div>