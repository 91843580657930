import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CommonApiService } from "src/app/services/common-api.service";
import { environment } from "src/environments/environment";
import { WebSocketServiceService } from "../dashboard/chat/shared/web-socket-service.service";
import { NavService } from "src/app/services/nav.service";

@Component({
  selector: "app-user-journey",
  templateUrl: "./user-journey.component.html",
  styleUrls: ["./user-journey.component.css"],
})
export class UserJourneyComponent implements OnInit, OnDestroy {
  sandBoxCode: any = "";
  userData: any = {};

  constructor(
    private apiService: CommonApiService,
    private webSocketService: WebSocketServiceService,
    private router: Router,
    private navService: NavService
  ) {}

  ngOnInit(): void {
    this.getSandBoxCode();
  }

  ngOnDestroy() {
    console.log("destroy");
    this.webSocketService.offSocket("chat-page");
  }

  getSandBoxCode() {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonGetMethod(environment.url + "auth/get-user-data", options)
      .subscribe(
        (response: any) => {
          // console.log(response);
          this.webSocketService.listen("chat-page").subscribe((data: any) => {
            // console.log(data);
            if (data?.chat?.conversationType == "received") {
              if (window.innerWidth > 992) {
                this.router.navigate(["/chat"]);
              } else {
                this.router.navigate(["/chat/mobile", "id", "name"]);
              }
            }
          });
          this.sandBoxCode =
            response?.data?.userData?.accessTo?.sandboxId?.replaceAll(
              "#",
              "%23"
            );
          this.userData = response?.data?.userData;
          // console.log(this.userData);
        },
        (error: any) => {
          console.log(error);
          // if (
          //   error?.error?.message == "jwt expired" ||
          //   error?.error?.message == "jwt must be provided" ||
          //   error?.error?.message == "jwt malformed" ||
          //   error?.error?.message == "invalid signature" ||
          //   error?.error?.message == "Given token user does not exist." ||
          //   error?.error?.message == "invalid token"
          // ) {
          //   this.navService.tokenExpired();
          // }
        }
      );
  }

  arrangeMeeting() {
    window.open("https://booknow.ownchat.app/", "_blank");
  }

  ownNumber() {
    // if (this?.userData?.accessTo?.requestedNumber) {
    var planName = this?.userData?.accessTo?.curSubsPlan?.name.split(" ")[0];
    // console.log(planName);
    if (planName) {
      window.open(
        "https://hub.360dialog.com/dashboard/app/xAm2nuPA/permissions?name=" +
          this?.userData?.firstName +
          (this?.userData?.lastName ? "%20" + this?.userData?.lastName : "") +
          "&email=" +
          this?.userData?.email,
        "_blank"
      );
    } else {
      // window.open("https://booknow.ownchat.app/", "_blank");
      this.router.navigate(["/subscription"]);
    }
  }
}
