import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { CommonApiService } from "src/app/services/common-api.service";
import { environment } from "src/environments/environment";
import * as moment from "moment-timezone";

import { NzModalService } from "ng-zorro-antd/modal";
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";
import { AlertServiceService } from "src/app/services/alert-service.service";
import { NavService } from "src/app/services/nav.service";
import { contactBGs, messageFormats } from "../shared/messageData";
import { Subscription, interval } from "rxjs";

@Component({
  selector: "app-chat-user-detail",
  templateUrl: "./chat-user-detail.component.html",
  styleUrls: ["./chat-user-detail.component.css"],
})
export class ChatUserDetailComponent implements OnInit {
  @ViewChild("detailsDiv") detailsDiv: ElementRef;
  @ViewChildren("checkboxes") checkboxes: QueryList<ElementRef>;
  moment: any = moment;

  fetchingData: boolean = false;

  private userDataSubscription: Subscription;

  constructor(
    private apiService: CommonApiService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private modal: NzModalService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private alertService: AlertServiceService,
    private navService: NavService
  ) {
    this.now = moment().tz("Asia/kolkata").add(2, "hours").format();
    this.tomorrow = moment().tz("Asia/kolkata").add(1, "day").format();
    this.nextWeek = moment().tz("Asia/kolkata").add(1, "week").format();
    // setInterval(() => {
    //   this.now = moment(this.now).add(1, "minutes").format();
    //   this.tomorrow = moment(this.tomorrow).add(1, "minutes").format();
    //   this.nextWeek = moment(this.nextWeek).add(1, "minutes").format();
    // }, 60000);
  }

  @Input() userData: any;
  @Input() tagsScroll: any;
  @Input() socketData: any;
  @Output() switchPage = new EventEmitter();
  @Output() searchToggle = new EventEmitter();
  @Output() shareSearchResult = new EventEmitter();
  @Output() reloadTags = new EventEmitter();

  @Output() customMessage = new EventEmitter();
  tagsModal = false;

  tags: any = [];

  tagsSelected: any = [];
  addingTags: boolean;

  notesText = "";
  // notesData: any = [];

  selectedOption = "";
  viewDetails = false;
  viewEdit = false;
  showEditCustomer = false;

  notesAction = "";
  backTo = "";

  customerDetails: any;
  updateCustomerForm!: FormGroup;
  catalogueForm!: FormGroup;
  filterText: any = "";
  filteredData: any = [];

  notHome = false;
  selectedNotes: any;

  catalogSpinner: boolean;
  selectedCatalogues: any = [];

  @Input() catalogueData: any;

  @Input() showCatalogue: boolean;

  @Input() showSearch: boolean;

  activateSend: boolean;
  sendLoading: boolean;

  checked: boolean;

  initialLoad = true;

  pageIndex = 1;
  liveUrl = environment.imageUrl;
  formControlObj: any = {};
  mainUserData: any = {};

  // search
  searchText: any = "";
  searchResult: any = [];
  today = moment(new Date()).format("YYYY-MM-DD");
  searchHit: boolean = false;

  buttonLoader: boolean = false;
  searchSpinner: boolean = false;

  // custom message
  customMessages: any = messageFormats;

  contactBgs: any = contactBGs;

  assignedUser: any;

  conversion: any = "";
  formatOne = (): any => `23:45`;

  openRateData: any = [100, 100, 100];
  openRateLabels: any = ["Delivered", "Read", "Unread"];
  openRateColors: any = [
    {
      backgroundColor: ["#CDCDCD", "#4ba8f8", "#f59909"],
    },
  ];
  openRateChartOptions: any = {
    responsive: false,
  };

  showMoreDetails: boolean = false;

  assignUsersList: any = [];

  now: any;
  tomorrow: any;
  nextWeek: any;

  followUpModal: boolean = false;

  // time differences

  marketingTimeInSec: any = "";
  utilityTimeInSec: any = "";
  authenticationTimeInSec: any = "";
  serviceTimeInSec: any = "";
  referralTimeInSec: any = "";

  marketingDiffHours: any;
  utilityDiffHours: any;
  authenticationDiffHours: any;
  serviceDiffHours: any;
  referralDiffHours: any;

  marketingDiffPercentage: any;
  utilityDiffPercentage: any;
  authenticationDiffPercentage: any;
  serviceDiffPercentage: any;

  source = interval(60000);
  subscription: Subscription;

  followUpModalWidth: any = "45%";

  marketingRemaining = (): any => this.marketingDiffHours;
  utilityRemaining = (): any => this.utilityDiffHours;
  authenticationRemaining = (): any => this.authenticationDiffHours;
  serviceRemaining = (): any => this.serviceDiffHours;

  // tempArray: any = [];

  // updateNotesText = "";

  ngOnInit(): void {
    // this.updateCustomerForm = this.fb.group({
    //   name: ["", Validators.required],
    //   email: [null, [Validators.email]],
    //   allowBroadcast: true,
    // });
    this.getMainUserData();
    this.catalogueForm = this.fb.group({
      messageText: ["", [Validators.required]],
      headerText: [""],
    });

    if (window.innerWidth < 992) {
      this.followUpModalWidth = "100%";
    } else {
      this.followUpModalWidth = "45%";
    }
  }

  ngOnChanges() {
    // console.log(this.showCatalogue);
    // console.log(this.userData);
    if (this.userData) {
      if (!this.socketData) {
        if (
          this.userData._id != this.customerDetails?.customer?._id &&
          !this.fetchingData
        ) {
          // console.log(true);
          this.fetchData();
          this.getTags();
        }
        this.showMoreDetails = false;
        this.backFromDetails();
        if (this.tagsScroll) {
          this.scrollFn();
        }
      }
    } else {
      this.customerDetails = null;
      this.tags = [];
      this.additionalDetails = [];
    }

    if (this.showCatalogue) {
      // console.log(this.catalogueData);
      this.initialLoad = true;
      if (!this.catalogueData.data?.catalogues) {
        this.pageIndex = 1;
        this.getCatalogues();
      }
    }

    if (this.showSearch) {
      this.searchHit = false;
      this.searchResult = [];
      this.searchText = "";
    }

    if (this.socketData && window.innerWidth > 992) {
      // console.log(this.socketData);
      if (this.socketData.type == "conversation") {
        this.getTimeDiff(
          this.socketData.waConversation,
          Object.keys(this.socketData.waConversation)[0]
        );
      }

      if (this.socketData?.chat?.conversationType == "customer-assign-info") {
        if (this.socketData?.chat.customerAssignInfo.toRef == "Flow") {
          this.assignedUser = { firstName: "Bot", lastName: "", _id: "Flow" };
        } else {
          this.assignedUser = this.socketData?.chat.customerAssignInfo.to;
        }
      }

      if (
        this.socketData.type == "messages" &&
        this.socketData.chat.conversationType == "sent-as-notes" &&
        this.socketData.chat.responseBy == "bot" &&
        this.socketData?.chat?.with == this.customerDetails?.customer?._id
      ) {
        this.customerDetails.customer.followUp = null;
      }

      this.socketData = null;
    }

    // console.log(this.showSearch);
  }

  fetchData() {
    this.fetchingData = true;
    this.tagsSelected = [];
    var data = {
      customerId: this.userData._id,
    };

    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(environment.url + "customers/detail", data, options)
      .subscribe(
        (response: any) => {
          const analytics = response?.data?.analytics;

          this.openRateData[0] = analytics?.deliveredPercentage?.toFixed(0);
          this.openRateData[1] = analytics?.readPercentage?.toFixed(0);
          this.openRateData[2] = analytics?.unreadPercentage?.toFixed(0);

          this.customerDetails = response.data;
          // console.log(this.customerDetails);
          this.conversion = "";
          this.getTimeDiff(this.customerDetails.customer.waConversation, "all");
          this.userData.name = response?.data?.customer?.name;
          for (let i = 0; i < this.customerDetails.customer.tags.length; i++) {
            // this.tempArray.push(this.customerDetails.customer.tags[i]._id);
            this.tagsSelected.push(this.customerDetails.customer.tags[i]._id);
          }
          console.log(this.userData);
          this.getFieldData();
          this.getAssignUserList();
          this.fetchingData = false;
          // document
          //   .getElementById("user_detail_main")
          //   .scrollIntoView({ behavior: "smooth" });
        },
        (error: any) => {
          this.fetchingData = false;
          console.log(error);
        }
      );
  }

  getTimeDiff(customTimes: any, type: any) {
    // console.log(type);
    if (type == "all") {
      this.marketingTimeInSec = "";
      this.utilityTimeInSec = "";
      this.authenticationTimeInSec = "";
      this.serviceTimeInSec = "";
      this.referralTimeInSec = "";
    }

    this.marketingDiffHours = "";
    this.utilityDiffHours = "";
    this.authenticationDiffHours = "";
    this.serviceDiffHours = "";
    this.referralDiffHours = "";

    this.marketingDiffPercentage = "";
    this.utilityDiffPercentage = "";
    this.authenticationDiffPercentage = "";
    this.serviceDiffPercentage = "";

    this.subscription && this.subscription.unsubscribe();

    const currentTime = moment();
    // const customTimes = this.customerDetails.customer.waConversation;

    // all times into seconds
    if (type == "all" || type == "referral_conversion") {
      this.referralTimeInSec = currentTime.diff(
        customTimes.referral_conversion?.delivered?.time,
        "seconds"
      );
    }

    // Added +60 for showing timing 23:59 instead of 00:00 in case of 24:00 (only in websocket trigger)
    if (type == "all" || type == "marketing") {
      this.marketingTimeInSec =
        currentTime.diff(customTimes.marketing?.delivered?.time, "seconds") +
        (type == "marketing" ? 60 : 0);
    }

    if (type == "all" || type == "utility") {
      this.utilityTimeInSec =
        currentTime.diff(customTimes.utility?.delivered?.time, "seconds") +
        (type == "utility" ? 60 : 0);
    }

    // console.log(this.utilityTimeInSec);

    if (type == "all" || type == "authentication") {
      this.authenticationTimeInSec =
        currentTime.diff(
          customTimes.authentication?.delivered?.time,
          "seconds"
        ) + (type == "authentication" ? 60 : 0);
    }

    if (type == "all" || type == "service") {
      this.serviceTimeInSec =
        currentTime.diff(customTimes.service?.delivered?.time, "seconds") +
        (type == "service" ? 60 : 0);
    }

    // console.log(this.utilityTimeInSec);

    if (
      customTimes?.referral_conversion?.delivered?.time &&
      this.referralTimeInSec < 259200
    ) {
      // console.log(this.referralTimeInSec);

      if (this.referralTimeInSec > 259200) {
        this.referralDiffHours = "00:00";
      } else {
        const duration = moment.duration(
          259200 - this.referralTimeInSec,
          "seconds"
        );

        // Get the total number of hours and minutes
        const hours = Math.floor(duration.asHours());
        const minutes = duration.minutes();

        // Format the hours and minutes as "HH:mm"
        const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}`;

        this.referralDiffHours = formattedTime;
      }

      this.conversion = "free-convo";
      this.subscription = this.source.subscribe((val) => this.progressTime());
    } else if (
      (customTimes?.marketing?.delivered?.time &&
        this.marketingTimeInSec < 86400) ||
      (customTimes?.utility?.delivered?.time &&
        this.utilityTimeInSec < 86400) ||
      (customTimes?.authentication?.delivered?.time &&
        this.authenticationTimeInSec < 86400) ||
      (customTimes?.service?.delivered?.time && this.serviceTimeInSec < 86400)
    ) {
      this.marketingDiffHours =
        this.marketingTimeInSec > 86400
          ? "00:00"
          : moment()
              .startOf("day")
              .seconds(86400 - this.marketingTimeInSec)
              .format("HH:mm");

      this.utilityDiffHours =
        this.utilityTimeInSec > 86400
          ? "00:00"
          : moment()
              .startOf("day")
              .seconds(86400 - this.utilityTimeInSec)
              .format("HH:mm");

      this.authenticationDiffHours =
        this.authenticationTimeInSec > 86400
          ? "00:00"
          : moment()
              .startOf("day")
              .seconds(86400 - this.authenticationTimeInSec)
              .format("HH:mm");

      this.serviceDiffHours =
        this.serviceTimeInSec > 86400
          ? "00:00"
          : moment()
              .startOf("day")
              .seconds(86400 - this.serviceTimeInSec)
              .format("HH:mm");
      this.conversion = "default";

      this.marketingDiffPercentage =
        this.marketingTimeInSec > 86400 || !this.marketingTimeInSec
          ? 0
          : ((86400 - this.marketingTimeInSec) / 86400) * 100;

      this.utilityDiffPercentage =
        this.utilityTimeInSec > 86400 || !this.utilityTimeInSec
          ? 0
          : ((86400 - this.utilityTimeInSec) / 86400) * 100;

      this.authenticationDiffPercentage =
        this.authenticationTimeInSec > 86400 || !this.authenticationTimeInSec
          ? 0
          : ((86400 - this.authenticationTimeInSec) / 86400) * 100;

      this.serviceDiffPercentage =
        this.serviceTimeInSec > 86400 || !this.serviceTimeInSec
          ? 0
          : ((86400 - this.serviceTimeInSec) / 86400) * 100;

      this.conversion = "default";
      // console.log(this.marketingTimeInSec);
      this.subscription = this.source.subscribe((val) => this.progressTime());
    } else {
      this.conversion = "no-convo";
    }
  }

  getAssignUserList() {
    var options: any = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };
    this.apiService
      .commonGetMethod(
        environment.url + "auth/get-all-user-data?projection=name",
        options
      )
      .subscribe(
        (response: any) => {
          this.assignUsersList = response.data.users;
          if (
            this?.customerDetails?.customer?.predefinedFields?.responsiveRef ==
            "Flow"
          ) {
            this.assignedUser = { firstName: "Bot", lastName: "", _id: "Flow" };
          } else {
            var targetIndex = response.data.users.findIndex((e: any) => {
              return e._id === this?.customerDetails?.customer?.responsive;
            });

            if (targetIndex > -1) {
              this.assignedUser = response.data.users[targetIndex];
            } else {
              this.assignedUser = null;
            }
          }
          // console.log(this.assignedUser);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  getTags() {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(environment.url + "tag?noPagination", {}, options)
      .subscribe(
        (response: any) => {
          this.tags = response.data.tags;
          // console.log(this.tags);
        },
        (error: any) => {
          console.log(error);
        }
      );
  }

  closeTagsModal() {
    this.tagsModal = false;
  }

  editTags() {
    this.filterText = "";
    if (this.mainUserData?.accessTo?.isExpired) {
      this.alertService.warning();
    } else {
      this.tagsModal = true;
    }
  }

  onTagsChecked(selected: any, event: any) {
    if (event) {
      this.tagsSelected.push(selected._id);
    } else {
      this.tagsSelected.splice(this.tagsSelected.indexOf(selected._id), 1);
    }
    // console.log(this.tagsSelected);
  }

  removeTag(selected: any, e: any) {
    e.preventDefault();
    e.stopPropagation();
    if (this.mainUserData?.accessTo?.isExpired) {
      this.alertService.warning();
    } else {
      this.tagsSelected.splice(this.tagsSelected.indexOf(selected._id), 1);
      this.saveTags();
    }
  }

  saveTags() {
    this.addingTags = true;
    var data = {
      customerId: this.userData._id,
      tags: this.tagsSelected,
    };

    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    // console.log(data);

    this.apiService
      .commonPostMethod(environment.url + "customers/tags", data, options)
      .subscribe(
        (response: any) => {
          // console.log(response);
          this.toastr.success(response.message, "", {
            timeOut: 2500,
          });
          this.fetchData();
          this.reloadTags.emit(true);
          this.addingTags = false;
          this.tagsModal = false;
        },
        (error: any) => {
          console.log(error);
          this.addingTags = false;
          this.tagsModal = false;
        }
      );
  }

  addNotes() {
    // console.log(this.notesText);
    this.buttonLoader = true;
    var url = "";
    var newNoteArr: any = [];
    var data: any = {
      text: this.notesText,
    };

    if (this.notesAction == "add") {
      url = "notes/create";
      data.customerId = this.userData._id;
    } else if (this.notesAction == "edit") {
      url = "notes/update";
      data.notesId = this.selectedNotes._id;
    }

    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(environment.url + url, data, options)
      .subscribe(
        (response: any) => {
          // console.log(response);
          this.toastr.success("Notes Updated..", "", {
            timeOut: 2500,
          });
          if (this.notesAction == "add") {
            var targetIndex = this.customerDetails?.notes.findIndex(
              (e: any) => {
                return e._id == response.data.newNote._id;
              }
            );
            // this.notHome = false;
            newNoteArr = [response.data.newNote];
          } else if (this.notesAction == "edit") {
            var targetIndex = this.customerDetails?.notes.findIndex(
              (e: any) => {
                return e._id == response.data.updatedNote._id;
              }
            );
            newNoteArr = [response.data.updatedNote];
            this.customerDetails?.notes.splice(targetIndex, 1);
          }
          this.buttonLoader = false;

          this.customerDetails.notes = newNoteArr.concat(
            this.customerDetails.notes
          );
          // console.log(this.customerDetails);
          if (this.notHome) {
            // this.viewEdit = false;
            // this.viewDetails = false;
            this.backFromEdit();
          }
          this.notHome = false;
          this.notesText = "";
          this.notesAction = "";
        },
        (error: any) => {
          console.log(error);
          this.toastr.error(error?.error?.message, "", {
            timeOut: 2500,
          });
          this.buttonLoader = false;
        }
      );
  }

  showMore(option: string, el: any) {
    if (this.mainUserData?.accessTo?.isExpired) {
      this.alertService.warning();
    } else {
      if (el) {
        el.scrollTop = 0;
      }
      this.viewDetails = true;
      this.selectedOption = option;
    }
  }

  backFromDetails() {
    this.viewDetails = false;
    this.viewEdit = false;
    this.showEditCustomer = false;
    this.selectedOption = "";
  }

  backFromEdit() {
    this.notesText = "";
    if (this.notesAction == "edit") {
      if (this.backTo == "notes") {
        this.viewDetails = true;
        this.viewEdit = false;
      } else if (this.backTo == "customer") {
        this.viewDetails = false;
        this.viewEdit = false;
      }
    } else if (this.notesAction == "add") {
      if (this.backTo == "customer") {
        this.viewDetails = false;
        this.viewEdit = false;
      } else {
        this.viewDetails = true;
        this.viewEdit = false;
      }
    }
  }

  showChatArea() {
    this.switchPage.emit();
  }

  showEditNotes(action: string, notesData: any, backTo: string) {
    if (this.mainUserData?.accessTo?.isExpired) {
      this.alertService.warning();
    } else {
      if (this.selectedOption == "tags") {
        this.editTags();
      } else {
        this.notHome = true;
        this.viewEdit = true;
        this.viewDetails = false;
        this.notesAction = action;
        this.notesText = "";
        if (action == "edit") {
          this.notesText = notesData.text;
          this.selectedNotes = notesData;
        }

        this.backTo = backTo;
      }
    }
  }

  editCustomerOption() {
    // console.log(this.customerDetails);
    // this.showEditCustomer = true;
    if (this.mainUserData?.accessTo?.isExpired) {
      this.alertService.warning();
    } else {
      this.formControlObj.name = [null, [Validators.required]];
      // this.formControlObj.email = [null, [Validators.required]];

      for (let s = 0; s < this.fieldData.length; s++) {
        if (this.fieldData[s].dataType == "switch") {
          this.formControlObj[this.fieldData[s].name] = [true];
        } else if (this.fieldData[s].isMandatory) {
          this.formControlObj[this.fieldData[s].name] = [
            null,
            [Validators.required],
          ];
        } else {
          this.formControlObj[this.fieldData[s].name] = [null];
        }
      }
      this.updateCustomerForm = this.fb.group(this.formControlObj);
      var fieldKeysArr: any = Object.keys(this.formControlObj);
      var updateKeysArr: any = Object.keys(this.customerDetails.customer);

      for (let i = 0; i < updateKeysArr.length; i++) {
        for (let j = 0; j < fieldKeysArr.length; j++) {
          if (updateKeysArr[i] == fieldKeysArr[j]) {
            var targetIndex = this.fieldData.findIndex((data: any) => {
              return data.name == fieldKeysArr[j];
            });
            if (
              targetIndex > -1 &&
              this.fieldData[targetIndex].dataType == "multi-select"
            ) {
              // console.log(this.customerDetails.customer[updateKeysArr[i]]);
              if (this.customerDetails.customer[updateKeysArr[i]]) {
                this.updateCustomerForm.get(updateKeysArr[i]).setValue(
                  this.customerDetails.customer[updateKeysArr[i]]
                  // .includes(',')? this.customerDetails.customer[updateKeysArr[i]]
                  //   .split(",") :  this.customerDetails.customer[updateKeysArr[i]]
                  //   .map((item: any) => item.replace(/'/g, "").trim())
                );
              } else {
                this.updateCustomerForm.get(updateKeysArr[i]).setValue("-");
              }
            } else if (
              targetIndex > -1 &&
              this.fieldData[targetIndex].dataType == "date"
            ) {
              // console.log(this.updateCustomerData[updateKeysArr[i]]);
              this.updateCustomerForm
                .get(updateKeysArr[i])
                .setValue(
                  new Date(this.customerDetails.customer[updateKeysArr[i]])
                );
            } else if (updateKeysArr[i] == "tags") {
              var temp: any = [];
              for (
                let k = 0;
                k < this.customerDetails.customer[updateKeysArr[i]].length;
                k++
              ) {
                temp.push(
                  this.customerDetails.customer[updateKeysArr[i]][k].name
                );
              }
              this.updateCustomerForm.get(updateKeysArr[i]).setValue(temp);
            } else {
              this.updateCustomerForm
                .get(updateKeysArr[i])
                .setValue(this.customerDetails.customer[updateKeysArr[i]]);
            }
          }
        }
      }

      this.updateCustomerForm
        .get("name")
        .setValue(this.customerDetails.customer?.name);
      // this.updateCustomerForm
      //   .get("email")
      //   .setValue(this.customerDetails.customer?.email);
      this.showEditCustomer = true;
    }
  }

  updateCustomer() {
    if (this.updateCustomerForm.valid) {
      this.buttonLoader = true;
      let options = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
        },
      };

      var tempArray = Object.keys(this.updateCustomerForm.value);
      for (let n = 0; n < tempArray.length; n++) {
        if (this.updateCustomerForm.value[tempArray[n]] == null) {
          this.updateCustomerForm.value[tempArray[n]] = "";
        }
        if (Array.isArray(this.updateCustomerForm.value[tempArray[n]])) {
          // console.log(tempArray[n]);
          this.updateCustomerForm.value[tempArray[n]] =
            this.updateCustomerForm.value[tempArray[n]].toString();
        }
        if (this.updateCustomerForm.value[tempArray[n]] instanceof Date) {
          // console.log(tempArray[n]);
          this.updateCustomerForm.value[tempArray[n]] = moment(
            this.updateCustomerForm.value[tempArray[n]]
          ).format("DD-MM-YYYY hh:mm A");
        }
      }
      // console.log(this.updateCustomerForm.value);
      var data = this.updateCustomerForm.value;
      data.phoneNo = this.customerDetails.customer.phoneNo;
      // data.tags = ""; // data not updating without this tag
      data.tags = this?.customerDetails?.customer?.tags
        .map((e: any) => e.name)
        .toString();
      // var data = this.updateCustomerForm.value;
      // {
      //   customerId: this.userData._id,
      //   name: this.updateCustomerForm.value.name,
      //   email: this.updateCustomerForm.value.email,
      //   allowBroadcast: this.updateCustomerForm.value.allowBroadcast,
      // };

      this.apiService
        .commonPostMethod(environment.url + "customers/update", data, options)
        .subscribe(
          (response: any) => {
            // console.log(response);
            this.toastr.success(response.message, "", {
              timeOut: 2500,
            });
            this.fetchData();
            this.backFromDetails();
            this.buttonLoader = false;
          },
          (error: any) => {
            console.log(error);
            this.buttonLoader = false;
            if (error?.error?.message) {
              this.toastr.error(error?.error?.message, "", {
                timeOut: 2500,
              });
            } else {
              this.toastr.error(error?.message, "", {
                timeOut: 2500,
              });
            }
          }
        );
    } else {
      this.toastr.error(
        "Please provide all required information to continue.",
        "",
        {
          timeOut: 2500,
        }
      );
      Object.values(this.updateCustomerForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  filterTags() {
    // console.log(event.keyCode);
    this.filteredData = this.tags.filter((filtered: any) =>
      filtered.name
        .toString()
        .toLowerCase()
        .includes(this.filterText.toString().toLowerCase())
    );

    // console.log(this.filteredData);
  }

  addNewTag() {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    var data = {
      name: this.filterText,
    };

    this.apiService
      .commonPostMethod(environment.url + "tag/create", data, options)
      .subscribe(
        (response: any) => {
          // console.log(response);
          this.toastr.success(response.message, "", {
            timeOut: 2500,
          });
          this.filterText = "";
          this.filteredData = [];
          this.tags = response.data.tags;
          this.tagsSelected.push(this.tags[0]._id);
        },
        (error: any) => {
          console.log(error);
        }
      );
  }

  deleteNotesAlert(notesData: any) {
    if (this.mainUserData?.accessTo?.isExpired) {
      this.alertService.warning();
    } else {
      this.modal.confirm({
        nzOkText: "Yes",
        nzCancelText: "No",
        nzTitle: "<i>Sure you Want to delete this items?</i>",
        nzContent: "<b>Once deleted, the note cannot be recovered..</b>",
        nzOnOk: () => this.deleteNotes(notesData),
      });
    }
  }

  deleteNotes(notesData: any) {
    // console.log(notesData);

    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    var data: any = {
      notesId: notesData._id,
    };

    this.apiService
      .commonPostMethod(environment.url + "notes/delete", data, options)
      .subscribe(
        (response: any) => {
          // console.log(response);

          this.toastr.success("Notes Updated..", "", {
            timeOut: 2500,
          });

          var targetIndex = this.customerDetails?.notes.findIndex((e: any) => {
            return e._id == notesData._id;
          });
          this.customerDetails?.notes.splice(targetIndex, 1);
        },
        (error: any) => {
          console.log(error);
        }
      );
  }

  changeNotes() {
    this.notHome = true;
    this.addNotes();
  }

  directAdd() {
    this.notesAction = "add";
    this.backTo = "customer";
    this.addNotes();
  }

  showDetails() {
    if (window.innerWidth > 992) {
      this.showCatalogue = false;
      this.catalogueData = [];
      this.switchPage.emit();
    } else {
      this.router.navigate([
        "/chat/area",
        this.userData?._id,
        this.userData?.name,
      ]);
    }
  }

  onCatalogueChecked(selected: any, event: any) {
    // console.log(event.currentTarget.checked);
    if (event.currentTarget.checked == true) {
      this.selectedCatalogues.push(selected);
    } else if (event.currentTarget.checked == false) {
      var targetIndex = this.selectedCatalogues.findIndex((e: any) => {
        return e.id == selected.id;
      });
      this.selectedCatalogues.splice(targetIndex, 1);
    }
    // console.log(this.selectedCatalogues);

    if (this.selectedCatalogues.length > 0) {
      this.activateSend = true;
    } else {
      this.activateSend = false;
    }
  }

  sendCatalogue() {
    var self = this;
    // console.log(this.selectedCatalogues.length);
    if (this.selectedCatalogues.length <= 30) {
      self.sendLoading = true;
      let options = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
        },
      };

      var data: any = {
        customerId: self.userData._id,
        desc: self.catalogueForm.value.messageText,
        catalogueItemId: self.selectedCatalogues[0].wa_catalogue_id,
      };

      if (self.selectedCatalogues.length > 1) {
        this.setCustomMessage("product_list");
        data.type = "multi";
        data.headerText = "Awesome Products";
        var tempArray: any = [];
        var tempObj: any = {
          title: "All Products",
        };

        // console.log(self.catalogueForm.value);

        if (self.catalogueForm.value.headerText) {
          data.headerText = self.catalogueForm.value.headerText;
        }

        for (let i = 0; i < self.selectedCatalogues.length; i++) {
          let obj: any = {};
          obj.product_retailer_id = self.selectedCatalogues[i].retailer_id;
          tempArray.push(obj);
        }
        tempObj.product_items = tempArray;
        // console.log(tempObj);
        data.sections = [tempObj];
      } else if (self.selectedCatalogues.length == 1) {
        data.type = "single";
        this.setCustomMessage("product");
      }

      this.catalogueForm.reset();

      // console.log(this.selectedCatalogues);

      self.apiService
        .commonPostMethod(environment.url + "chat/send-product", data, options)
        .subscribe(
          (response: any) => {
            // console.log(response);
            if (window.innerWidth < 992) {
              self.showDetails();
            }
            this.checkboxes.forEach((element) => {
              element.nativeElement.checked = false;
            });
            // self.checked = false;
            // self.sendLoading = false;
            // self.catalogueForm.reset();
            self.selectedCatalogues = [];
          },
          (error: any) => {
            console.log(error);
            self.sendLoading = false;
            if (error?.error?.message) {
              this.toastr.error(error?.error?.message, "", {
                timeOut: 2500,
              });
            } else {
              this.toastr.error(error?.message, "", {
                timeOut: 2500,
              });
            }
          }
        );
    } else {
      this.toastr.error("You can maximum select 30 items.", "", {
        timeOut: 2500,
      });
    }
  }

  getCatalogues() {
    this.sendLoading = false;
    if (this.initialLoad && !this.catalogueData.items) {
      this.catalogSpinner = true;
      this.spinner.show();
    }

    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url + "catalogue-item/get?page=" + this.pageIndex,
        {
          availability: "in stock",
        },
        options
      )
      .subscribe(
        (response: any) => {
          if (this.initialLoad) {
            this.catalogueData = response;
          } else {
            // for (let a = 0; a < response.items.length; a++) {
            this.catalogueData.data.catalogues =
              this.catalogueData.data?.catalogues.concat(
                response.data?.catalogues
              );
            // }
            // this.catalogueData.next = response.next;
          }
          this.catalogueData.type = "catalogue";
          // console.log(this.catalogueData);
          this.catalogSpinner = false;
        },
        (err: any) => {
          console.log(err);
          this.catalogSpinner = false;
        }
      );
  }

  showAllOrders() {
    this.router.navigate(["/order-panel", this.userData._id]);
  }

  onScroll() {
    // console.log("scrolled");
    this.initialLoad = false;
    this.pageIndex = this.pageIndex + 1;
    this.getCatalogues();
  }

  closeSearch() {
    if (window.innerWidth > 992) {
      this.showSearch = false;
      this.searchToggle.emit();
    } else {
      this.router.navigate([
        "/chat/area",
        this.userData?._id,
        this.userData?.name,
      ]);
    }
  }

  // Additional details section
  fieldData: any = {};
  additionalDetails: any = [];

  getFieldData() {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url + "customer-field/get?filter=for-additional-fields",
        {},
        options
      )
      .subscribe(
        (response: any) => {
          this.fieldData = response.data.customerFields;
          // console.log(response);
          this.additionalDetails = [];
          // this.fieldData = response.data.customerFields;

          for (let n = 0; n < response.data.customerFields.length; n++) {
            // set the name and label of custom fields in a array fot html loop
            var temp: any = {};
            temp.name = response.data.customerFields[n].name;
            temp.label = response.data.customerFields[n].label;
            temp.dataType = response.data.customerFields[n].dataType;
            this.additionalDetails.push(temp);
            // this.fieldData[response.data.customerFields[n].name] = "";
          }

          // console.log(this.fieldData);

          // var fieldKeysArr: any = Object.keys(this.fieldData);
          var updateKeysArr: any = Object.keys(this.customerDetails.customer); // get all the keys in user details object

          // console.log(fieldKeysArr);
          // console.log(updateKeysArr);

          for (let i = 0; i < updateKeysArr.length; i++) {
            // for (let j = 0; j < fieldKeysArr.length; j++) {
            //   if (updateKeysArr[i] == fieldKeysArr[j]) {
            //     this.fieldData[fieldKeysArr[j]] =
            //       this.customerDetails.customer[updateKeysArr[i]];
            //   }
            // }

            for (let s = 0; s < this.additionalDetails.length; s++) {
              if (updateKeysArr[i] == this.additionalDetails[s].name) {
                var targetIndex = this.additionalDetails?.findIndex(
                  (e: any) => {
                    return e.name == updateKeysArr[i];
                  }
                );
                this.additionalDetails[targetIndex].value =
                  this.customerDetails.customer[updateKeysArr[i]]; // set value for the array created for html loop by comparing customer data keys and html array keys
              }
            }
          }

          // console.log(this.fieldData);
        },
        (error) => {
          console.log(error);
          if (error?.error?.message) {
            this.toastr.error(error?.error?.message, "", {
              timeOut: 2500,
            });
          } else {
            this.toastr.error(error?.message, "", {
              timeOut: 2500,
            });
          }
        }
      );
  }

  getMainUserData() {
    this.userDataSubscription = this.navService.mainUserData.subscribe(
      (response: any) => {
        if (response) {
          this.mainUserData = response.data.userData;
          // console.log(this.mainUserData);
        }
      }
    );
    // let options = {
    //   headers: {
    //     Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
    //   },
    // };

    // this.apiService
    //   .commonGetMethod(environment.url + "auth/get-user-data", options)
    //   .subscribe(
    //     (response: any) => {
    //       this.mainUserData = response.data.userData;
    //       // console.log(this.mainUserData);
    //     },
    //     (error: any) => {
    //       console.log(error);
    //     }
    //   );
  }

  searchMessages() {
    this.searchSpinner = true;
    this.spinner.show();
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    var data = {
      customerId: this?.userData?._id,
      searchText: this.searchText,
    };

    this.apiService
      .commonPostMethod(
        environment.url + "chat/search-in-chat-detail",
        data,
        options
      )
      .subscribe(
        (response: any) => {
          this.searchResult = response?.data?.chatResults;
          for (let i = 0; i < this?.searchResult?.length; i++) {
            this.searchResult[i].message.data = JSON.parse(
              this?.searchResult[i]?.message?.data
            );
          }
          console.log(this.searchResult);
          this.searchHit = true;
          this.searchSpinner = false;
          this.spinner.hide();
        },
        (error: any) => {
          console.log(error);
          this.searchSpinner = false;
          this.spinner.hide();
        }
      );
  }

  selectSearchChat(selected: any) {
    // console.log(selected);
    this.shareSearchResult.emit(selected);
    if (window.innerWidth < 992) {
      this.router.navigate([
        "/chat/area",
        this.userData?._id,
        this.userData?.name,
      ]);
    }
  }

  toChat() {
    this.router.navigate([
      "/chat/area",
      this.userData?._id,
      this.userData?.name,
    ]);
  }

  toCatalogue() {
    if (this.mainUserData.role != "teammate") {
      this.router.navigate(["/commerce", "items"]);
    }
  }

  setCustomMessage(type: string) {
    var targetIndex = this.customMessages?.findIndex((e: any) => {
      return e?.message?.msgType == "interactive";
    });

    var tempMsg: any = Object.assign(
      {},
      JSON.parse(JSON.stringify(this.customMessages[targetIndex]))
    );

    tempMsg.time = new Date();
    tempMsg.responseByUser.firstName = this.mainUserData?.firstName;
    tempMsg.responseByUser.lastName = this.mainUserData?.lastName;
    tempMsg.message.data.desc = this.catalogueForm.value.messageText;
    tempMsg.message.data.type = type;
    if (type == "product") {
      tempMsg.message.data.product = {
        name: this?.selectedCatalogues[0]?.name,
        image_url: this?.selectedCatalogues[0]?.image_url,
        sale_price: "₹" + this?.selectedCatalogues[0]?.sale_price,
      };
    } else if (type == "product_list") {
      tempMsg.message.data.productList = {
        image_url: this?.selectedCatalogues[0]?.image_url,
        count: this?.selectedCatalogues.length,
      };
    }
    this.customMessage.emit(tempMsg);
    this.checkboxes.forEach((element) => {
      element.nativeElement.checked = false;
    });
    this.checked = false;
    this.sendLoading = false;
    // this.catalogueForm.reset();
    // this.selectedCatalogues = [];
  }

  showAllContactDetails() {
    this.showMoreDetails = !this.showMoreDetails;
  }

  scrollFn() {
    if (this.tagsScroll == "add_tag") {
      this.editTags();
    } else if (
      !this.viewDetails &&
      !this.viewEdit &&
      !this.showEditCustomer &&
      !this.showCatalogue &&
      !this.showSearch &&
      document.getElementById(this.tagsScroll)
    ) {
      document
        .getElementById(this.tagsScroll)
        .scrollIntoView({ behavior: "smooth" });
    }
    this.tagsScroll = null;
  }

  assignChat(user: any) {
    if (this.mainUserData?.accessTo?.isExpired) {
      this.alertService.warning();
    } else {
      let options = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
        },
      };

      var data = {
        customerId: this?.userData?._id,
        responsive: user._id,
      };
      // console.log(data);

      this.apiService
        .commonPostMethod(
          environment.url + "customers/assign-user",
          data,
          options
        )
        .subscribe(
          (response: any) => {
            this.fetchData();
            // console.log(response);
            this.toastr.success(response.message, "", {
              timeOut: 2500,
            });
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }

  showFollowUpModal() {
    if (this.mainUserData?.accessTo?.isExpired) {
      this.alertService.warning();
    } else {
      this.now = moment().tz("Asia/kolkata").add(2, "hours").format();
      this.tomorrow = moment().tz("Asia/kolkata").add(1, "day").format();
      this.nextWeek = moment().tz("Asia/kolkata").add(1, "week").format();
      this.followUpModal = true;
    }
  }

  closeFollowUpModal() {
    this.followUpModal = false;
  }

  onOk(event: any) {
    // console.log(event);
    this.addFollowUp(event, "customDate");
  }

  addFollowUp(data: any, type: string) {
    this.followUpModal = false;
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };
    var reqObj: any = {
      customerId: this.userData._id,
    };

    var url = "";

    if (this?.customerDetails?.customer?.followUp) {
      url = "customers/update-follow-up";
    } else {
      url = "customers/create-follow-up";
    }

    if (type == "customDate") {
      reqObj.customDate = moment(data).format();
      reqObj.type = "custom";
    } else if (type == "predefined") {
      reqObj.type = data;
    }

    this.apiService
      .commonPostMethod(environment.url + url, reqObj, options)
      .subscribe(
        (response: any) => {
          // console.log(response);
          this.fetchData();
          this.toastr.success(response.message, "", {
            timeOut: 2500,
          });
        },
        (error) => {
          console.log(error);
          if (error?.error?.message) {
            this.toastr.error(error?.error?.message, "", {
              timeOut: 2500,
            });
          } else {
            this.toastr.error(error?.message, "", {
              timeOut: 2500,
            });
          }
        }
      );
  }

  deleteRemainderAlert() {
    this.modal.confirm({
      nzOkText: "Yes",
      nzCancelText: "No",
      nzTitle: "<b>Sure you Want to delete Remainder?</b>",
      // nzContent: "<b>Once deleted, the note cannot be recovered..</b>",
      nzOnOk: () => this.deleteFollowUp(),
    });
  }

  deleteFollowUp() {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };
    var reqObj: any = {
      customerId: this.userData._id,
    };

    this.apiService
      .commonPostMethod(
        environment.url + "customers/delete-follow-up",
        reqObj,
        options
      )
      .subscribe(
        (response: any) => {
          // console.log(response);
          this.fetchData();
          this.toastr.success(response.message, "", {
            timeOut: 2500,
          });
        },
        (error) => {
          console.log(error);
          if (error?.error?.message) {
            this.toastr.error(error?.error?.message, "", {
              timeOut: 2500,
            });
          } else {
            this.toastr.error(error?.message, "", {
              timeOut: 2500,
            });
          }
        }
      );
  }

  progressTime() {
    // console.log("subscription");
    if (this.conversion == "free-convo") {
      if (259200 - this.referralTimeInSec < 100) {
        this.subscription && this.subscription.unsubscribe();
      }

      this.referralTimeInSec = this.referralTimeInSec + 60;

      if (this.referralTimeInSec > 259200) {
        this.referralDiffHours = "00:00";
      } else {
        const duration = moment.duration(
          259200 - this.referralTimeInSec,
          "seconds"
        );

        // Get the total number of hours and minutes
        const hours = Math.floor(duration.asHours());
        const minutes = duration.minutes();

        // Format the hours and minutes as "HH:mm"
        const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}`;

        this.referralDiffHours = formattedTime;
      }
    } else if (this.conversion == "default") {
      if (
        (86400 - this.marketingTimeInSec < 60 &&
          86400 - this.utilityTimeInSec < 60 &&
          86400 - this.authenticationTimeInSec < 60 &&
          86400 - this.serviceTimeInSec < 60) ||
        (this.marketingTimeInSec < 60 &&
          this.utilityTimeInSec < 60 &&
          this.authenticationTimeInSec < 60 &&
          this.serviceTimeInSec < 60)
      ) {
        this.subscription && this.subscription.unsubscribe();
      }

      if (86400 - this.marketingTimeInSec > 60 && this.marketingTimeInSec) {
        this.marketingTimeInSec = this.marketingTimeInSec + 60;

        this.marketingDiffPercentage =
          this.marketingTimeInSec > 86400
            ? 0
            : ((86400 - this.marketingTimeInSec) / 86400) * 100;

        this.marketingDiffHours =
          this.marketingTimeInSec > 86400
            ? "00:00"
            : moment()
                .startOf("day")
                .seconds(86400 - this.marketingTimeInSec)
                .format("HH:mm");
      } else {
        this.marketingTimeInSec = 0;
        this.marketingDiffPercentage = 0;
      }

      if (86400 - this.utilityTimeInSec > 60 && this.utilityTimeInSec) {
        this.utilityTimeInSec = this.utilityTimeInSec + 60;

        this.utilityDiffPercentage =
          this.utilityTimeInSec > 86400
            ? 0
            : ((86400 - this.utilityTimeInSec) / 86400) * 100;

        this.utilityDiffHours =
          this.utilityTimeInSec > 86400
            ? "00:00"
            : moment()
                .startOf("day")
                .seconds(86400 - this.utilityTimeInSec)
                .format("HH:mm");
      } else {
        this.utilityTimeInSec = 0;
        this.utilityDiffPercentage = 0;
      }

      if (
        86400 - this.authenticationTimeInSec > 60 &&
        this.authenticationTimeInSec
      ) {
        this.authenticationTimeInSec = this.authenticationTimeInSec + 60;

        this.authenticationDiffPercentage =
          this.authenticationTimeInSec > 86400
            ? 0
            : ((86400 - this.authenticationTimeInSec) / 86400) * 100;

        this.authenticationDiffHours =
          this.authenticationTimeInSec > 86400
            ? "00:00"
            : moment()
                .startOf("day")
                .seconds(86400 - this.authenticationTimeInSec)
                .format("HH:mm");
      } else {
        this.authenticationTimeInSec = 0;
        this.authenticationDiffPercentage = 0;
      }

      if (86400 - this.serviceTimeInSec > 60 && this.serviceTimeInSec) {
        this.serviceTimeInSec = this.serviceTimeInSec + 60;
        this.serviceDiffPercentage =
          this.serviceTimeInSec > 86400
            ? 0
            : ((86400 - this.serviceTimeInSec) / 86400) * 100;

        // console.log(this.serviceDiffPercentage);

        this.serviceDiffHours =
          this.serviceTimeInSec > 86400
            ? "00:00"
            : moment()
                .startOf("day")
                .seconds(86400 - this.serviceTimeInSec)
                .format("HH:mm");
      } else {
        this.serviceTimeInSec = 0;
        this.serviceDiffPercentage = 0;
      }
    }
  }

  alertMessage() {
    if (this.mainUserData?.accessTo?.isExpired) {
      this.alertService.warning();
    }
  }

  ngOnDestroy(): void {
    // console.log("api key destroy..");
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.userDataSubscription) {
      this.userDataSubscription.unsubscribe();
    }
  }
}
