<div class="row overflow_row_mobile">
    <div *ngIf="contentPage" class="col-12 main_outer_heading d_mobile_none">
        <h3>Contents</h3>
    </div>

    <div *ngIf="contentPage" class="col-2 left_menu mt-2">
        <nz-card class="mobile_left_menu">
            <ng-container *ngFor="let menu of menus">
                <ul class="main_list" *ngIf="menu?.role?.includes(mainUserData?.role)">
                    <ng-container *ngFor="let option of menu.options">
                        <li *ngIf="option?.role?.includes(mainUserData?.role)" (click)="switchPage(option)"
                            class="list_options mb-3"
                            [ngClass]="{'selected_tab_setting' : selectedTab == option.name.toLowerCase().replaceAll(' ' , '-')}">
                            <span nz-icon [nzType]="option.icon" nzTheme="outline"></span>
                            {{option.name}}
                        </li>
                    </ng-container>
                </ul>
            </ng-container>
        </nz-card>
    </div>
    <div class="col-lg-10" id="main-section"
        [ngClass]="{'zero_padding' : selectedTab == 'whatsapp' , 'col-12 media_modal' : !contentPage} ">
        <div class="main_card">
            <!-- <nz-card *ngIf="selectedTab == 'quick-message'" class="details_card"> -->
            <app-canned-response *ngIf="selectedTab == 'quick-message'"
                [mainUserData]="mainUserData"></app-canned-response>
            <!-- </nz-card> -->

            <nz-card *ngIf="selectedTab == 'media'" class="media_card mt-2">
                <div class="sticky_top">
                    <div class="media_top">
                        <div class="toggle_options">
                            <nz-input-group class="search_input_section" [nzPrefix]="suffixIconSearch">
                                <input autocomplete="off" [(ngModel)]="searchKey" (keyup)="searchFiles()" type="text"
                                    nz-input placeholder="Search files.." />
                            </nz-input-group>
                            <ng-template #suffixIconSearch>
                                <span nz-icon nzType="search"></span>
                            </ng-template>
                        </div>

                        <div class="media_top_right">
                            <div class="progress_section">
                                <nz-progress [nzPercent]="storagePercentage" [nzFormat]="formatTwo"></nz-progress>
                            </div>
                            <div class="search_add_div">
                                <a [disabled]="mainUserData?.accessTo?.isExpired" (click)="openModal()" nz-button
                                    nzType="primary" class="create-button">
                                    <i nz-icon nzType="file-add" nzTheme="outline"></i>
                                    <span>Add Files</span>
                                </a>
                                <nz-dropdown-menu #menu="nzDropdownMenu">
                                    <ul class="drop_down_menu" nz-menu>
                                        <li nz-menu-item>
                                            <i nz-icon nzType="cloud-upload" nzTheme="outline"></i>
                                            Add URL
                                        </li>
                                        <li nz-menu-item>
                                            <label for='upload_media' class="">
                                                <i nz-icon nzType="folder-open" nzTheme="outline"></i>
                                                From Device
                                            </label>
                                        </li>
                                    </ul>
                                </nz-dropdown-menu>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="showMenu" class="media_filters">
                        <ul>
                            <li *ngFor="let filter of filters" (click)="applyFilter(filter)"
                                [ngClass]="{'selected_filter' : filterSelected == filter}">
                                {{filter}}
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- <nz-image-group> -->
                <div infinite-scroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="20"
                    (scrolled)="bottomScrolled()" [scrollWindow]="false" class="all_files row">
                    <div *ngFor="let data of allMedia ; let i = index" class="col-2 mb-2">
                        <label class="media_selection" *ngIf="!contentPage" nz-checkbox
                            [ngModel]="findMediaIndex(data._id) > -1"
                            (ngModelChange)="selectionChange($event , data)"></label>
                        <div class="files_div">
                            <div *ngIf="contentPage" class="image_options">
                                <span nz-tooltip nzTooltipTitle="copy url" nzTooltipPlacement="left"
                                    (click)="copyUrl(data.url)" class="delete_icon" nz-icon nzType="copy"
                                    nzTheme="outline"></span>
                                <!-- <span nz-tooltip nzTooltipTitle="delete" nzTooltipPlacement="left"
                                    (click)="delateMedia(data._id , true)" class="delete_icon" nz-icon nzType="delete"
                                    nzTheme="outline"></span> -->
                            </div>

                            <img *ngIf="data.mediaType == 'image' || data.mediaType == 'sticker'"
                                [nz-image]="contentPage"
                                [nzSrc]="data.url?.startsWith(imageUrl) ? data.url : (imageUrl + data.url)" alt="">

                            <video (play)="controlAudio($event.target)" *ngIf="data.mediaType == 'video'"
                                [src]="data.url?.startsWith(imageUrl) ? data.url : (imageUrl + data.url)" preload="none"
                                controls></video>

                            <a (click)="showDocPreview(data.url)">
                                <img *ngIf="data.mediaType == 'document'"
                                    src="../../../assets/images/chat/icons8-google-docs.svg" alt="">
                            </a>

                            <div *ngIf="data.mediaType == 'audio'" class="audio_tag">
                                <span nz-icon nzType="customer-service" nzTheme="outline"></span>
                                <audio (play)="controlAudio($event.target)" controls>
                                    <source [src]="data.url?.startsWith(imageUrl) ? data.url : (imageUrl + data.url)"
                                        type="audio/wav" />
                                    Your browser does not support the audio element.
                                </audio>
                            </div>

                            <p>
                                {{data?.fileName ? (data.fileName.length > 16 ? (data.fileName.slice(0,16) + '..') :
                                data.fileName) : 'Add file name'}}
                            </p>

                            <!-- <p *ngIf="selectedFile != i" (click)="showEditName(i , data?.fileName)">
                                {{data?.fileName ? (data.fileName.length > 16 ? (data.fileName.slice(0,16) + '..') :
                                data.fileName) : 'Add file name'}}
                            </p>
                            <input #inputElement nz-input *ngIf="selectedFile == i" [(ngModel)]="fileName"
                                (blur)="closeEdit()" (keyup.enter)="updateName()" type="text"> -->
                        </div>
                    </div>

                    <div *ngIf="allMedia?.length == 0 || !allMedia" class="no_data_div">
                        <img src="../../../assets/images/chat/not_found.gif" alt="">
                        <h5>No files were found..</h5>
                    </div>

                    <ngx-spinner *ngIf="!uploading" bdColor="rgb(255,255,255)" size="medium" color="#000000"
                        type="ball-clip-rotate-pulse" [fullScreen]="false">
                    </ngx-spinner>
                </div>

                <div *ngIf="!contentPage" class="send_selected_div">
                    <a (click)="sendSelected()" nz-button nzType="primary" class="create-button">
                        <span>Send selected files</span>
                    </a>
                </div>
                <!-- </nz-image-group> -->
                <!-- <div class="all_media">
                    <label for='upload_media' class="upload_section">
                        <span nz-icon nzType="cloud-upload" nzTheme="outline"></span>
                        <p>Upload Image</p>
                    </label>
                </div> -->
                <!-- <input (change)="getMediaFromPc($event)" class="upload_input" multiple id='upload_media' type='file'> -->
            </nz-card>
        </div>
    </div>
</div>


<!-- upload modal -->

<nz-modal [(nzVisible)]="uploadModal" [nzFooter]="null" nzTitle="Upload Media" nzCentered
    (nzOnCancel)="closeUploadModal()">
    <ng-container *nzModalContent>
        <div class="drag_drop_container" appDnd (files)="filesDropped($event)">
            <div class="drag_drop_section">
                <p>Drag & Drop your files here</p>
                <span>OR</span>
                <div class="upload_section">
                    <div [ngClass]="{'change_btn' : customerUploadFile}" class="upload-button save-button">
                        <label [nzLoading]="uploading" nz-button for="upload_media">Upload from pc</label>
                        <input (change)="uploadFile($event)" [accept]="acceptedFiles" class="upload_input"
                            id="upload_media" multiple type="file" />
                    </div>
                </div>
            </div>
        </div>
        <span class="upload_notes">Note : You can upload upto 10 files at once</span>
        <div class="text-center mt-2 mb-2">
            <span>(Or)</span>
        </div>

        <div class="url_upload_div">
            <nz-form-item>
                <nz-form-label class="" nzFor="">Enter Media Url</nz-form-label>
                <ng-container>
                    <nz-form-control [nzErrorTip]="'Please Enter Media Url'">
                        <nz-input-group>
                            <input [(ngModel)]="uploadUrl" autocomplete="off" nz-input [placeholder]="'Enter Media Url'"
                                type="text" />
                        </nz-input-group>
                    </nz-form-control>
                </ng-container>
            </nz-form-item>

            <div class="text-right">
                <a [nzLoading]="uploading" [disabled]="!uploadUrl" [ngClass]="{ 'disabled_tag': !uploadUrl }"
                    (click)="uploadMedia('url')" nz-button nzType="primary" class="create-button mt-1">
                    <span>Upload</span>
                </a>
            </div>
        </div>

        <ngx-spinner *ngIf="uploading" bdColor="rgb(255,255,255)" size="medium" color="#000000"
            type="ball-clip-rotate-pulse" [fullScreen]="false">
        </ngx-spinner>
    </ng-container>

</nz-modal>


<nz-modal [nzBodyStyle]="{'padding' : '0px'}" [(nzVisible)]="docPreview" nzTitle="" nzCentered nzWidth="90%"
    [nzClosable]="false" [nzFooter]="null" (nzOnCancel)="closeDocPreview()">
    <ng-container *nzModalContent>
        <ngx-doc-viewer [url]="docUrl" viewer="google" style="width:100%;height:90vh;">
        </ngx-doc-viewer>
    </ng-container>
</nz-modal>


<!-- doc preview [href]="data.url?.startsWith(imageUrl) ? data.url : (imageUrl + data.url)" target="_blank" -->