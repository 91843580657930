<nz-card class="card user_list_card main_chat_card"
    [ngClass]="{'main_chat_card-purchased' : mainUserData?.accessTo?.sandboxId}">
    <div class="card-body">

        <!-- <div class="d-flex pb-2 border-bottom align-items-center">
            <img [src]="loggedInUser.avatar" class="me-2 rounded-circle" height="48" [alt]="loggedInuser?.name" />
            <div>
                <h5 class="my-0 fs-14">{{loggedInuser?.name}}</h5>
            </div>
            <div class="flex-grow-1">
                <ul class="list-inline text-end mb-0">
                    <li class="list-inline-item fs-18">
                        <a href="javascript: void(0);" class="dropdown-toggle text-dark" nz-dropdown
                            [nzDropdownMenu]="menu">
                            <span nz-icon nzType="more" nzTheme="outline"></span>
                        </a>
                        <nz-dropdown-menu #menu="nzDropdownMenu">
                            <ul nz-menu nzSelectable>
                                <li nz-menu-item>New Group</li>
                                <li nz-menu-item>Contacts</li>
                                <li nz-menu-item>Saved Message</li>
                                <li nz-menu-item>Invite Friends</li>
                                <li nz-menu-item>Help</li>
                                <li nz-menu-item>Setting</li>
                            </ul>
                        </nz-dropdown-menu>

                    </li>
                </ul>
            </div>
        </div> -->

        <!-- start searchbar -->
        <div class="user_filter_section">
            <div class="row">
                <div class="col-8 search_column" [ngClass]="{'col-12' : mainUserData?.role == 'teammate'} ">
                    <form class="chat-search">
                        <div class="chat-search-box">
                            <div class="input-group">
                                <input autocomplete="off" nz-input type="search" placeholder="Search..."
                                    [(ngModel)]="searchUser" [ngModelOptions]="{standalone: true}"
                                    (keyup)="searchUsers()">
                            </div>
                        </div>
                    </form>
                </div>
                <div *ngIf="mainUserData?.role != 'teammate'" class="col-4 new_column square_button">
                    <button [disabled]="mainUserData?.accessTo?.isExpired" (click)="showAddUserModal()" nz-button
                        nzType="primary" class="new_button">
                        <i nz-icon nzType="plus" nzTheme="outline"></i>
                        <span>New</span>
                    </button>
                </div>
                <div class="col-12 bottom_column mt-3 mb-3"
                    [ngClass]="{'bottom_column_teammate' : mainUserData?.role == 'teammate'} ">
                    <ul class="filter_list">
                        <ng-container *ngFor="let filter of userFilters">
                            <li *ngIf="filter?.role?.includes(this.mainUserData?.role)"
                                [ngClass]="{'selected_filter' : filter?.value == customFilter}"
                                (click)="applyFilter(filter?.value)">
                                {{filter?.value}}</li>
                        </ng-container>
                        <!-- <li nz-popover nzPopoverTitle="Select Tags" nzPopoverTrigger="click"
                            [nzPopoverContent]="tagsTemplate"><span nz-icon nzType="tag" nzTheme="outline"></span>Tags
                        </li> -->
                    </ul>
                    <div class="filter_section filter_icon">
                        <span class="web_cursor" nz-popover nzPopoverTitle="Select Filter" nzPopoverTrigger="click"
                            [nzPopoverContent]="filterTemplate" nz-icon nzType="filter" nzTheme="outline"></span>
                        <!-- <span nz-dropdown [nzDropdownMenu]="filterOptions" nz-icon nzType="filter"
                            nzTheme="outline"></span>
                        <nz-dropdown-menu #filterOptions="nzDropdownMenu">
                            <ul nz-menu>
                                <li nz-menu-item>
                                    <label for="chatStatus">Assigned to :</label>
                                    <nz-select class="w-100 select_dropdown" [(ngModel)]="filterText"
                                        (ngModelChange)="filterUsers()">
                                        <nz-option nzValue="all" nzLabel="All"></nz-option>
                                        <nz-option nzValue="unassigned" nzLabel="Unassigned"></nz-option>
                                        <nz-option *ngFor="let user of assignUsersList" [nzValue]="user._id"
                                            [nzLabel]="user?.firstName + ' ' + user?.lastName"></nz-option>
                                    </nz-select>
                                </li>>
                                <li nz-menu-item>
                                    <label for="chatStatus">Status :</label>
                                    <nz-select id="chatStatus" class="w-100 select_dropdown" [(ngModel)]="statusText"
                                        (ngModelChange)="filterUsers()">
                                        <nz-option nzValue="all" nzLabel="All"></nz-option>
                                        <nz-option nzValue="open" nzLabel="Open"></nz-option>
                                        <nz-option nzValue="closed" nzLabel="Closed"></nz-option>
                                    </nz-select>
                                </li>
                            </ul>
                        </nz-dropdown-menu> -->
                        <ng-template #filterTemplate>
                            <div *ngIf="mainUserData?.role != 'teammate'" class="">
                                <label for="chatStatus">Assigned to :</label>
                                <nz-select class="w-100 select_dropdown" [(ngModel)]="filterText"
                                    (ngModelChange)="filterUsers()">
                                    <nz-option nzValue="all" nzLabel="All"></nz-option>
                                    <nz-option nzValue="unassigned" nzLabel="Unassigned"></nz-option>
                                    <nz-option *ngFor="let user of assignUsersList" [nzValue]="user._id"
                                        [nzLabel]="user?.firstName + ' ' + (user?.lastName ? user?.lastName : '')"></nz-option>
                                </nz-select>
                            </div>
                            <div class="mt-1">
                                <label for="chatStatus">Status :</label>
                                <nz-select id="chatStatus" class="w-100 select_dropdown" [(ngModel)]="statusText"
                                    (ngModelChange)="filterUsers()">
                                    <nz-option nzValue="all" nzLabel="All"></nz-option>
                                    <nz-option nzValue="open" nzLabel="Open"></nz-option>
                                    <nz-option nzValue="closed" nzLabel="Closed"></nz-option>
                                </nz-select>
                            </div>
                        </ng-template>

                        <ng-template #tagsTemplate>
                            <div class="">
                                <ul class="tags_list">
                                    <li *ngFor="let tag of allTags">
                                        <label nz-checkbox>{{tag.name}}</label>
                                    </li>
                                </ul>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
        <!-- End searchbar -->

        <!-- filter template -->



        <!-- start contact list -->
        <div class="pe-2 scroll_div" infinite-scroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="10"
            (scrolled)="scrolledDown()" [scrollWindow]="false"
            [ngClass]="{'no_contacts' : userList.length == 0 || !userList}">
            <div *ngIf="userList.length > 0" class="">
                <ng-container *ngFor="let user of userList, let i = index">
                    <a *ngIf="user?.responsive == mainUserData._id || mainUserData.role != 'teammate'" class="text-body"
                        (click)="activateUser(user , i)">
                        <div class="d-flex align-items-start p-2 user_list_main"
                            [ngClass]="{ 'selected_user' : selected?._id == user?._id}">
                            <div class="position-relative">
                                <p [ngStyle]="{ 'background-color':  contactBgs[(user?.name).charAt(0).toUpperCase()]?.['bg'] , 'color' : contactBgs[(user?.name).charAt(0).toUpperCase()]?.['color']}"
                                    class="user_profile_picture">{{(user?.name).charAt(0).toUpperCase()}}</p>
                                <!-- <span class="user-status"
                            [ngClass]="{'bg-success': user.userStatus==='online','bg-danger': user.userStatus==='busy','bg-warning': user.userStatus==='away'}"></span> -->
                                <!-- <img [src]="user.avatar" class="me-2 rounded-circle" height="48" [alt]="user?.name" /> -->
                            </div>
                            <div class="w-100 overflow-hidden">
                                <div class="user_data">
                                    <h5 class="mt-0 mb-0 fs-14">
                                        {{(user?.name)?.length > 12 ? (user?.name).slice(0, 12) + '...' : user?.name}}
                                    </h5>
                                    <p class="chat_time user_badge">
                                        <nz-badge
                                            *ngIf="user?.chats?.conversationType == 'received' && selected?._id != user?._id && user?.chats?.statuses?.read?.isRead == false"
                                            nzStatus="success">
                                        </nz-badge>
                                        <span class="chat_time_span"
                                            [ngClass]="{'new_message_alert' : user?.chats?.conversationType == 'received' && selected?._id != user?._id && user?.chats?.statuses?.read?.isRead == false}">
                                            {{user?.chats?.time ? today ==
                                            moment(user?.chats?.time)?.format("YYYY-MM-DD") ?
                                            moment(user?.chats?.time)?.format("h:mm a") :
                                            moment(user?.chats?.time)?.format("DD/MM/YY") : ''}}
                                            <span (click)="pinChat($event , user)" class="pin_icon" nz-icon
                                                nzType="pushpin"
                                                [nzTheme]="mainUserData?.pinnedCustomers?.indexOf(user?._id) > -1 ? 'fill' : 'outline'"></span>
                                        </span>
                                    </p>
                                </div>
                                <div [ngSwitch]="user?.chats?.message?.msgType" class="message_section"
                                    [ngClass]="{'notes_message' : user?.chats?.conversationType == 'sent-as-notes'}">
                                    <div *ngSwitchCase="'template'" class="">
                                        <div [ngSwitch]="user?.chats?.message?.data[0]?.type?.toLowerCase()" class="">
                                            <div *ngSwitchCase="'header'" class="">
                                                <span
                                                    [innerHTML]="user?.chats?.message?.data?.[0]?.text?.replaceAll('<br>' , '')"
                                                    *ngIf="user?.chats?.message?.data?.[0]?.format == 'TEXT'"
                                                    class="float-end text-muted fs-12"></span>
                                                <span [innerHTML]="user?.chats?.message?.data[1]?.text?.length
                                            > 30 ? (user?.chats?.message?.data[1]?.text)?.replaceAll('<br>' , '')?.slice(0, 25) + '...' :
                                            user?.chats?.message?.data[1]?.text?.replaceAll('<br>' , '')"
                                                    *ngIf="user?.chats?.message?.data[0]?.format != 'TEXT'"
                                                    class="float-end text-muted fs-12"></span>
                                            </div>
                                            <span [innerHTML]="user?.chats?.message?.data?.[0]?.text?.length > 30 ? 
                                                (user?.chats?.message?.data?.[0]?.text)?.replaceAll('<br>' , '')?.slice(0, 25) + '...' :
                                                user?.chats?.message?.data?.[0]?.text?.replaceAll('<br>' , '')"
                                                *ngSwitchCase="'body'" class="float-end text-muted fs-12"></span>
                                        </div>
                                    </div>

                                    <div *ngSwitchCase="'text'" class="">
                                        <span [innerHTML]="user?.chats?.message?.data?.body?.length
                                    > 30 ? (user?.chats?.message?.data?.body)?.replaceAll('<br>' , '')?.slice(0, 25) + '...' :
                                    user?.chats?.message?.data?.body?.replaceAll('<br>' , '  ')"
                                            *ngIf="user?.chats?.conversationType == 'received'"
                                            class="float-end text-muted fs-12">
                                        </span>
                                        <span [innerHTML]="user?.chats?.message?.data?.text ? user?.chats?.message?.data?.text?.length
                                    > 30 ? (user?.chats?.message?.data?.text)?.replaceAll('<br>' , '  ')?.slice(0, 25) + '...' :
                                    user?.chats?.message?.data?.text?.replaceAll('<br>' , '  ') : user?.chats?.message?.data?.body?.length
                                    > 30 ? (user?.chats?.message?.data?.body)?.replaceAll('<br>' , '  ')?.slice(0, 25) + '...' :
                                    user?.chats?.message?.data?.body?.replaceAll('<br>' , '  ')"
                                            *ngIf="user?.chats?.conversationType == 'sent' || user?.chats?.conversationType == 'sent-as-notes'"
                                            class="float-end text-muted fs-12">

                                        </span>
                                    </div>

                                    <div *ngSwitchCase="'interactive'" class="">
                                        <div [ngSwitch]="user?.chats?.message?.data?.type" class="">
                                            <span class="float-end text-muted fs-12" *ngSwitchCase="'product'">
                                                {{user?.chats?.message?.data?.product?.name}}
                                            </span>
                                            <span [innerHTML]="user?.chats?.message?.data?.desc"
                                                class="float-end text-muted fs-12" *ngSwitchCase="'product_list'">

                                            </span>
                                            <span [innerHTML]="user?.chats?.message?.data?.body?.text.length > 12 ?
                                            (user?.chats?.message?.data?.body?.text).slice(0, 12) + '...' :
                                            user?.chats?.message?.data?.body?.text" class="float-end text-muted fs-12"
                                                *ngSwitchCase="'button'">

                                            </span>
                                            <span [innerHTML]="user?.chats?.message?.data?.button_reply?.title"
                                                class="float-end text-muted fs-12" *ngSwitchCase="'button_reply'">

                                            </span>
                                            <span [innerHTML]="user?.chats?.message?.data?.list_reply?.title"
                                                class="float-end text-muted fs-12" *ngSwitchCase="'list_reply'">

                                            </span>
                                            <span [innerHTML]="user?.chats?.message?.data?.body?.text.length > 12 ?
                                            (user?.chats?.message?.data?.body?.text).slice(0, 12) + '...' :
                                            user?.chats?.message?.data?.body?.text"
                                                class="float-end bold_text text-muted fs-12" *ngSwitchCase="'list'">
                                                <b></b>
                                            </span>
                                            <span [innerHTML]="user?.chats?.message?.data?.nfm_reply?.body.length > 12 ?
                                                (user?.chats?.message?.data?.nfm_reply?.body).slice(0, 12) + '...' :
                                                user?.chats?.message?.data?.nfm_reply?.body"
                                                class="float-end bold_text text-muted fs-12"
                                                *ngSwitchCase="'nfm_reply'">
                                                <b></b>
                                            </span>
                                            <span [innerHTML]="user?.chats?.message?.data?.body?.text.length > 12 ?
                                                (user?.chats?.message?.data?.body?.text).slice(0, 12) + '...' :
                                                user?.chats?.message?.data?.body?.text"
                                                class="float-end bold_text text-muted fs-12"
                                                *ngSwitchCase="'address_message'">
                                                <b></b>
                                            </span>
                                        </div>
                                    </div>

                                    <span *ngSwitchCase="'audio'" class="float-end text-muted fs-12">
                                        <span nz-icon nzType="audio" nzTheme="outline"></span>
                                        Audio
                                    </span>
                                    <span *ngSwitchCase="'video'" class="float-end text-muted fs-12">
                                        <span nz-icon nzType="play-square" nzTheme="outline"></span>
                                        Video
                                    </span>
                                    <span *ngSwitchCase="'document'" class="float-end text-muted fs-12">
                                        <span nz-icon nzType="file-done" nzTheme="outline"></span>
                                        Document
                                    </span>
                                    <span *ngSwitchCase="'image'" class="float-end text-muted fs-12">
                                        <span nz-icon nzType="file-image" nzTheme="outline"></span>
                                        Image
                                    </span>
                                    <span *ngSwitchCase="'sticker'" class="float-end text-muted fs-12">
                                        Sticker
                                    </span>
                                    <span *ngSwitchCase="'contacts'" class="float-end text-muted fs-12">
                                        <span nz-icon nzType="user-add" nzTheme="outline"></span>
                                        Contact
                                    </span>
                                    <span *ngSwitchCase="'location'" class="float-end text-muted fs-12">
                                        <span nz-icon nzType="environment" nzTheme="outline"></span>
                                        Location
                                    </span>
                                    <span [innerHTML]="user?.chats?.message?.data?.text" *ngSwitchCase="'button'"
                                        class="float-end text-muted fs-12"></span>
                                    <span *ngSwitchCase="'order'" class="float-end text-muted fs-12">Order</span>
                                    <span class="float-end text-muted fs-12"
                                        *ngIf="user?.chats?.conversationType == 'customer-assign-info'">
                                        message assigned
                                    </span>
                                    <span class="float-end text-muted fs-12"
                                        *ngIf="user?.chats?.conversationType == 'inbox-status-info'">
                                        {{user?.chats?.inboxStatusInfo?.status == 'open' ? 'chat opened'
                                        : 'chat closed'}}
                                    </span>
                                </div>
                                <!-- <p class="mt-1 mb-0 text-muted fs-14">
                            <span class="w-75 text-dark">{{user.lastMessage}}</span>
                            <span class="float-end badge bg-danger text-white"
                                *ngIf="user.totalUnread!>0">{{user.totalUnread}}</span>
                        </p> -->
                            </div>
                        </div>
                    </a>
                </ng-container>
                <div class="text-center loader_div">
                    <nz-spin [nzSpinning]="contactsLoading" [nzSize]="'large'" [nzDelay]="50" nzSimple></nz-spin>
                </div>
            </div>

            <ng-container *ngIf="userList.length == 0 || !userList">
                <img class="no_contacts_image" src="../../../../../assets/images/chat/not_found.gif" alt="">
                <p class="text-center">No Contacts</p>
            </ng-container>

            <ngx-spinner *ngIf="showSpinner" bdColor="rgb(255,255,255)" size="medium" color="#000000"
                type="ball-clip-rotate-pulse" [fullScreen]="false">
            </ngx-spinner>
        </div>


    </div> <!-- End card-body -->


</nz-card> <!-- End card -->

<!-- add customer modal -->

<nz-modal [(nzVisible)]="addUserModal" [nzFooter]="null"
    [nzTitle]="addNewForm ? 'Add New Contact' : 'Initiate new conversation'" nzCentered
    (nzOnCancel)="closeAddUserModal()">
    <ng-container *nzModalContent>
        <div *ngIf="!addNewForm" class="">
            <nz-input-group [nzPrefix]="suffixIconSearch">
                <input autocomplete="off" [(ngModel)]="listSearch" (keyup)="searchUserList()" type="text" nz-input
                    placeholder="Search Chats.." />
            </nz-input-group>
            <ng-template #suffixIconSearch>
                <span nz-icon nzType="search"></span>
            </ng-template>

            <ul class="add_user_list" infinite-scroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="10"
                (scrolled)="userScrollPagination()" [scrollWindow]="false">
                <li class="new_contact">
                    <p class="user_profile_picture">
                        <span nz-icon nzType="user-add" nzTheme="outline"></span>
                    </p>
                    <div (click)="addNewContact()" class="">
                        <p class="user_name">Add new contact</p>
                    </div>
                </li>
                <li class="user_list_li" (click)="addUserToChat(user)" *ngFor="let user of allUsers">
                    <p [ngStyle]="{ 'background-color':  contactBgs[(user?.name).charAt(0).toUpperCase()]?.['bg'] , 'color' : contactBgs[(user?.name).charAt(0).toUpperCase()]?.['color']}"
                        class="user_profile_picture">{{(user?.name).charAt(0).toUpperCase()}}</p>
                    <div class="">
                        <p class="user_name">{{user?.name}}</p>
                        <span>{{user?.phoneNo}}</span>
                    </div>
                </li>

                <ngx-spinner *ngIf="customersSpinner" bdColor="rgb(255,255,255)" size="medium" color="#000000"
                    type="ball-clip-rotate-pulse" [fullScreen]="false">
                </ngx-spinner>
            </ul>
        </div>
        <div *ngIf="addNewForm" class="">
            <form *ngIf="addNewForm" [formGroup]="singleCustomerForm" (ngSubmit)="createCustomer()" nz-form>
                <div class="update_form_div">
                    <ng-container *ngFor="let field of fieldData">
                        <nz-form-item>
                            <nz-form-label [nzRequired]="field?.isMandatory"
                                [ngClass]="{'full_height' : field.dataType =='switch'}" class="large_label" nzFor=""
                                [nzSm]="field.dataType =='switch' ? '' : 24"
                                [nzXs]="field.dataType =='switch' ? '' : 24">
                                {{field.label}}
                            </nz-form-label>
                            <ng-container>
                                <nz-form-control [nzSm]="24" [nzXs]="24" [nzErrorTip]="'Please Enter ' + field.label">
                                    <nz-input-group [ngSwitch]="field.dataType">

                                        <input [formControlName]="field.name" *ngSwitchCase="'text'" autocomplete="off"
                                            nz-input [placeholder]="'Enter ' + field.label" type="text">

                                        <input [formControlName]="field.name" *ngSwitchCase="'email'" autocomplete="off"
                                            nz-input [placeholder]="'Enter ' + field.label" type="email">

                                        <input [formControlName]="field.name" *ngSwitchCase="'number'"
                                            autocomplete="off" nz-input [placeholder]="'Enter ' + field.label"
                                            type="number">

                                        <textarea [formControlName]="field.name" *ngSwitchCase="'textarea'" nz-input
                                            rows="2" [placeholder]="'write ' +  field.label +  ' here'"></textarea>

                                        <nz-select [nzPlaceHolder]="'Select ' + field.name"
                                            [formControlName]="field.name" class="w-100" *ngSwitchCase="'select'">
                                            <nz-option *ngFor="let option of field.acceptedValues" [nzLabel]="option"
                                                [nzValue]="option"></nz-option>
                                        </nz-select>

                                        <nz-select [nzPlaceHolder]="'Select ' + field.name"
                                            [formControlName]="field.name" class="w-100" *ngSwitchCase="'multi-select'"
                                            nzMode="multiple">
                                            <nz-option *ngFor="let option of field.acceptedValues" [nzLabel]="option"
                                                [nzValue]="option"></nz-option>
                                        </nz-select>

                                        <nz-select [nzPlaceHolder]="'Select ' + field.name"
                                            [formControlName]="field.name" class="w-100" *ngSwitchCase="'tags'"
                                            nzMode="tags">
                                            <nz-option *ngFor="let tag of allTags" [nzLabel]="tag?.name"
                                                [nzValue]="tag?.name">
                                            </nz-option>

                                        </nz-select>

                                        <nz-date-picker [formControlName]="field.name" class="w-100"
                                            *ngSwitchCase="'date'" nzShowToday nzFormat="dd-MM-yyyy"
                                            [nzRenderExtraFooter]="">
                                        </nz-date-picker>

                                        <nz-switch [formControlName]="field.name" *ngSwitchCase="'switch'"></nz-switch>
                                    </nz-input-group>

                                    <nz-input-group class="d-flex" nzCompact *ngIf="field.dataType == 'phone-number'">
                                        <nz-select nzShowSearch [(ngModel)]="dialCode"
                                            [ngModelOptions]="{standalone: true}" style="width:30%;">
                                            <nz-option *ngFor="let code of countryCodes"
                                                [nzLabel]="'+' + code?.countryCodeNormal + '-' + code?.Country"
                                                [nzValue]="code?.countryCodeNormal"></nz-option>
                                            <!-- <nz-option [nzLabel]="'+65'" [nzValue]="'+65'"></nz-option> -->
                                        </nz-select>
                                        <input nzRequired [formControlName]="field.name" autocomplete="off" nz-input
                                            [placeholder]="'Enter ' + field.label" type="number" style="width:70%;">
                                    </nz-input-group>
                                </nz-form-control>
                            </ng-container>
                        </nz-form-item>
                    </ng-container>

                    <nz-form-item class="user_create">
                        <button [nzLoading]="creatingCustomer" type="submit" class="sighup_button" nz-button
                            nzType="primary">
                            {{editCustomer ? 'Update' : 'Create'}}
                        </button>
                    </nz-form-item>
                </div>
            </form>
        </div>
    </ng-container>
</nz-modal>


<!-- nzAddOnBefore="+91" -->