<div class="row">
    <div class="col-12 main_outer_heading d_mobile_none">
        <h3>Settings</h3>
    </div>

    <div *ngIf="menuPage || screenWidth > 992" class="col-lg-2 col-12 left_menu">
        <nz-card class="profile_mobile_design">
            <div class="profile_top">
                <div class="">
                    <img [src]="mainUserData?.profileImage ? imageUrl + mainUserData?.profileImage : '../../../../assets/images/logo/profile.png'"
                        alt="">
                </div>
                <span class="edit_link" (click)="editProfile()">Edit</span>
            </div>
            <div class="profile_bottom">
                <div class="detail">
                    <h5>Email</h5>
                    <span>{{mainUserData?.email?.length > 28 ? (mainUserData?.email?.slice(0,28) + '..') :
                        mainUserData?.email}}</span>
                </div>
                <div class="detail">
                    <h5>Role</h5>
                    <span>{{mainUserData?.role}}</span>
                </div>
            </div>
        </nz-card>

        <div class="menu_container">
            <ng-container class="" *ngFor="let menu of menus">
                <ul class="main_list" *ngIf="menu?.role?.includes(mainUserData?.role)">
                    <li [ngClass]="{'platform' : menu.name == 'PLATFORMS'}" class="list_heading mb-3">{{menu.name}}</li>
                    <ng-container *ngFor="let option of menu.options">
                        <li *ngIf="(option?.role?.includes(mainUserData?.role) && option.availFor == 'web' && screenWidth > 992) || 
                            (option?.role?.includes(mainUserData?.role) && option.availFor == 'both' && screenWidth < 992) ||
                            (option?.role?.includes(mainUserData?.role) && option.availFor == 'both' && screenWidth > 992)"
                            (click)="switchPage(option)" class="list_options mb-3"
                            [ngClass]="{'selected_tab_setting' : selectedTab == option.name.toLowerCase().replaceAll(' ' , '-')}">
                            <span nz-icon [nzType]="option.icon" nzTheme="outline"></span>
                            {{option.name}}
                        </li>
                    </ng-container>
                </ul>
            </ng-container>
        </div>
    </div>

    <div *ngIf="!menuPage || screenWidth > 992" class="col-lg-10 col-12" id="main-section"
        [ngClass]="{'zero_padding' : selectedTab == 'whatsapp' && screenWidth > 992} ">
        <div class="main_card">
            <!-- <nz-card *ngIf="selectedTab == 'whatsapp'" class="details_card"> -->
            <app-whatsapp *ngIf="selectedTab == 'whatsapp'"></app-whatsapp>
            <!-- </nz-card> -->

            <!-- <nz-card  class="details_card account_details_card"> -->
            <app-account-details *ngIf="selectedTab == 'account-details'"></app-account-details>
            <!-- </nz-card> -->

            <!-- <nz-card *ngIf="selectedTab == 'working-hours'" class="details_card mobile_card"> -->
            <app-working-hours *ngIf="selectedTab == 'working-hours'"></app-working-hours>
            <!-- </nz-card> -->

            <app-field-collection [mainUserData]="mainUserData"
                *ngIf="selectedTab == 'field-collection'"></app-field-collection>

            <app-tags [mainUserData]="mainUserData" *ngIf="selectedTab == 'tags'"></app-tags>

            <!-- <nz-card *ngIf="selectedTab == 'quick-message'" class="details_card"> -->
            <app-canned-response *ngIf="selectedTab == 'quick-message'"
                [mainUserData]="mainUserData"></app-canned-response>
            <!-- </nz-card> -->

            <!-- <nz-card *ngIf="selectedTab == 'whatsapp-template'" class="details_card"> -->
            <app-whatsapp-template *ngIf="selectedTab == 'whatsapp-template'"></app-whatsapp-template>
            <!-- </nz-card> -->

            <nz-card *ngIf="selectedTab == 'conversation'" class="details_card mobile_card">
                <div class="row">
                    <div class="col-12 top_heading">
                        <h3>
                            Conversation Handler
                        </h3>
                    </div>

                    <div class="col-6 mt-5">
                        <form [formGroup]="assignForm" (ngSubmit)="assignConversation()" nz-form>
                            <nz-form-item>
                                <nz-form-label [nzSm]="24" [nzXs]="24" nzRequired class="large_label">
                                    Choose Assignee
                                </nz-form-label>
                                <nz-form-control [nzSm]="24" nzErrorTip="Please Select Assignee!">
                                    <nz-select nzShowSearch formControlName="assignee" class="w-100 select_dropdown"
                                        nzPlaceHolder="Select Assignee">
                                        <nz-option *ngFor="let user of assignUsersList"
                                            [nzLabel]="user?.firstName + ' ' + user.lastName" [nzValue]="user?._id">
                                        </nz-option>
                                        <nz-option [nzLabel]="'Bot'" [nzValue]="'Flow'"></nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>

                            <div class="submit_btn_div">
                                <nz-form-item class="submit_section text-right">
                                    <nz-form-control [nzSm]="24" [nzXs]="24">
                                        <div class="account_details_add_cancel">
                                            <button [nzLoading]="updating" type="submit" class="sighup_button" nz-button
                                                nzType="primary">
                                                Update
                                            </button>
                                        </div>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                        </form>
                    </div>
                </div>
            </nz-card>

            <nz-card *ngIf="selectedTab == 'change-password'" class="details_card mobile_card">
                <app-change-password [mainUserData]="mainUserData"></app-change-password>
            </nz-card>

            <nz-card *ngIf="selectedTab == 'profile'" class="details_card mobile_card">
                <app-profile></app-profile>
            </nz-card>

            <app-user-page [mainUserData]="mainUserData" *ngIf="selectedTab == 'users-&-role'"></app-user-page>

            <div *ngIf="selectedTab == 'webchat' || selectedTab == 'integration'" class="details_card card_design">
                <div class="coming_soon_div">
                    <div class="">
                        <img src="../../../assets/images/logo/coming-soon.gif" alt="">
                        <!-- <video #video></video>
                        <button (click)="takePicture()">Take Picture</button> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>