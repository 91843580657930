import { Component, OnInit } from "@angular/core";
import { CommonApiService } from "src/app/services/common-api.service";
import { environment } from "src/environments/environment";
import * as moment from "moment";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { AlertServiceService } from "src/app/services/alert-service.service";
import { NavService } from "src/app/services/nav.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-notification-page",
  templateUrl: "./notification-page.component.html",
  styleUrls: ["./notification-page.component.css"],
})
export class NotificationPageComponent implements OnInit {
  moment: any = moment;
  orderColumn = [
    // {
    //   title: "S.No",
    //   width: "50px",
    // },
    {
      title: "Name",
      width: "150px",
    },
    {
      title: "Created At",
      width: "100px",
    },
    {
      title: "Status",
      width: "60px",
    },
    // {
    //   title: "Sent %",
    //   width: "50px",
    // },
    // {
    //   title: "Delivered %",
    //   width: "50px",
    // },
    // {
    //   title: "Read %",
    //   width: "50px",
    // },
    {
      title: "Template Name",
      width: "110px",
    },
  ];

  campaignsList: any = [];

  pageReady: boolean;
  mainUserData: any = {};

  selectedTab = "All";
  campaignTypes: any = [
    "Completed",
    "Failed",
    // "Ongoing", "Scheduled"
  ];

  // search and pagination

  searchKey: any = "";

  pageIndex = 1;
  totalCount: number;
  pageSize: number = 10;

  tableHeight: any = "";

  pageReloading: boolean = false;

  private subscription: Subscription;

  constructor(
    private apiService: CommonApiService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private alertService: AlertServiceService,
    private navService: NavService
  ) {}

  ngOnInit(): void {
    this.getMainUserData();
    this.fetchData();
  }

  fetchData() {
    this.spinner.show();
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url +
          "campaigns?page=" +
          this.pageIndex +
          "&limit=" +
          this.pageSize,
        { filterValue: this.searchKey },
        options
      )
      .subscribe(
        (response: any) => {
          console.log(response);
          this.campaignsList = response?.data?.campaigns;
          this.totalCount = response?.data?.pagination?.totalCount;
          this.pageSize = response?.data?.pagination?.limit;
          this.pageReady = true;
          this.spinner.hide();
          this.pageReloading = false;
        },
        (err: any) => {
          if (
            err?.error?.message == "jwt expired" ||
            err?.error?.message == "jwt must be provided" ||
            err?.error?.message == "jwt malformed" ||
            err?.error?.message == "invalid signature" ||
            err?.error?.message == "Given token user does not exist." ||
            err?.error?.message == "invalid token"
          ) {
            this.navService.tokenExpired();
          }
          console.log(err);
          this.spinner.hide();
          this.pageReloading = false;
        }
      );
  }

  // ADD CAMPAIGN PAGE ROUTING

  addCampaign() {
    if (!this.mainUserData?.accessTo?.isExpired) {
      if (
        this.mainUserData?.accessTo?.sandboxId &&
        this.campaignsList.length >= 1
      ) {
        var errHead: any = "Maximum 1 campaign allowed in sandbox mode!";
        var errBody: any = "Please Subscribe any Plan to create campaign..!";
        this.alertService.freeTrialWarning(errHead, errBody);
      } else {
        this.router.navigate(["/campaign/create-campaign"]);
      }
    }
  }

  // CAMPAIGN DETAILS
  campaignDetails(campaignData: any) {
    // console.log(campaignData);
    if (this.mainUserData?.accessTo?.isExpired) {
      this.alertService.warning();
    } else {
      this.router.navigate(["/campaign/campaign-details", campaignData._id]);
    }
  }
  // TABLE SCROLL TOP ON PAGE CHANGE

  onPageNumberChange(id: any, event: any) {
    if (this.pageIndex != event.pageIndex) {
      this.pageIndex = event.pageIndex;
      this.fetchData();
      document.getElementById(id).scrollIntoView();
    }
  }

  getMainUserData() {
    this.subscription = this.navService.mainUserData.subscribe(
      (response: any) => {
        if (response) {
          // console.log(response);
          this.mainUserData = response.data.userData;
          if (
            response?.data?.userData?.accessTo?.sandboxId &&
            window.innerWidth > 992
          ) {
            this.tableHeight = "56vh";
          } else {
            this.tableHeight = "65vh";
          }
        }
      }
    );
    // let options = {
    //   headers: {
    //     Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
    //   },
    // };

    // this.apiService
    //   .commonGetMethod(environment.url + "auth/get-user-data", options)
    //   .subscribe(
    //     (response: any) => {
    //       this.mainUserData = response.data.userData;
    //       // console.log(this.mainUserData);
    //     },
    //     (error: any) => {
    //       console.log(error);
    //     }
    //   );
  }

  selectSegment(selected: any) {
    this.selectedTab = selected;
  }

  searchContacts() {
    this.pageIndex = 1;
    this.fetchData();
  }

  reloadPage() {
    this.pageReloading = true;
    this.pageIndex = 1;
    this.searchKey = "";
    this.fetchData();
  }

  changePageSize(size: any) {
    this.pageSize = size;
    this.pageIndex = 1;
    this.fetchData();
  }

  setMyStyles() {
    let styles = {
      "margin-right":
        135 + (this.totalCount / this.pageSize).toFixed(0).length * 5 + "px",
    };
    return styles;
  }

  ngOnDestroy(): void {
    // console.log("api key destroy..");
    this.subscription.unsubscribe();
  }
}
