<nz-card class="account_details_card">
    <div class="row">
        <div class="col-12 top_heading">
            <h3>
                <i routerLink="/setting/profile" nz-icon nzType="left" nzTheme="outline"></i>
                Set working hours
            </h3>
        </div>
    </div>

    <div class="row h-100">
        <div class="col-lg-12 col-12 mt-3 outer_column">
            <form [formGroup]="workingHoursForm" (ngSubmit)="validateInputs()" nz-form>
                <div class="update_form_div row">
                    <div class="col-lg-7 working_hours_form">
                        <nz-form-item>
                            <nz-form-label class="switch_label">
                                Holiday mode
                            </nz-form-label>
                            <nz-form-control>
                                <nz-input-group>
                                    <nz-switch formControlName="isHolidayMode"></nz-switch>
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>

                        <nz-form-item>
                            <nz-form-label class="">
                                Working hours
                            </nz-form-label>
                        </nz-form-item>

                        <nz-form-item *ngFor="let day of daysArr ; let i = index">
                            <nz-form-label class="switch_label time-picker-label">
                                {{day.name}}
                            </nz-form-label>
                            <nz-form-control class="working_hours_control">
                                <nz-input-group class="">
                                    <div class="days_group">
                                        <nz-switch [formControlName]="day.label + 'Active'"></nz-switch>
                                        <nz-time-picker [nzDisabled]="!workingHoursForm.value[day.label + 'Active']"
                                            [formControlName]="day.label + 'Start'" [nzUse12Hours]="true"
                                            nzFormat="h:mm a"></nz-time-picker>
                                        <p>to</p>
                                        <nz-time-picker [nzDisabled]="!workingHoursForm.value[day.label + 'Active']"
                                            [formControlName]="day.label + 'End'" [nzUse12Hours]="true"
                                            nzFormat="h:mm a"></nz-time-picker>
                                        <div (click)="addExtra(day , null , null)"
                                            [ngClass]="{ 'disable_tag' : (!workingHoursForm.value[day.label + 'Active'] || (extraFields.indexOf(day.label) > -1))}"
                                            class="add_icon_div">
                                            +
                                        </div>
                                    </div>
                                </nz-input-group>
                                <div *ngIf="extraFields.indexOf(day.label) > -1" class="days_group mt-3">
                                    <nz-time-picker [formControlName]="day.label + 'Start2'"
                                        [nzDisabled]="!workingHoursForm.value[day.label + 'Active']"
                                        [nzUse12Hours]="true" nzFormat="h:mm a"></nz-time-picker>
                                    <p>to</p>
                                    <nz-time-picker [formControlName]="day.label + 'End2'"
                                        [nzDisabled]="!workingHoursForm.value[day.label + 'Active']"
                                        [nzUse12Hours]="true" nzFormat="h:mm a"></nz-time-picker>
                                    <div (click)="deleteExtra(day)" class="delete_option">
                                        <span nz-icon nzType="delete" nzTheme="outline"></span>
                                    </div>
                                </div>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>

                <div class="submit_btn_div">
                    <nz-form-item class="submit_section text-right">
                        <nz-form-control [nzSm]="24" [nzXs]="24">
                            <div class="account_details_add_cancel">
                                <button type="button" class="sighup_button mr-3" nz-button
                                    nzType="default">Cancel</button>
                                <button [disabled]="mainUserData?.accessTo?.isExpired" [nzLoading]="updating"
                                    type="submit" class="sighup_button" nz-button nzType="primary">Save</button>
                            </div>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </form>

            <ngx-spinner bdColor="rgb(255,255,255)" size="medium" color="#000000" type="ball-clip-rotate-pulse"
                [fullScreen]="false">
            </ngx-spinner>
        </div>
    </div>
</nz-card>