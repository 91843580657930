import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { CommonApiService } from "src/app/services/common-api.service";
import { environment } from "src/environments/environment";
import * as moment from "moment";
import { ExportExcelService } from "src/app/services/export-excel.service";
import * as XLSX from "xlsx";
import { ToastrService } from "ngx-toastr";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";
import { AlertServiceService } from "src/app/services/alert-service.service";
import { NavService } from "src/app/services/nav.service";
import { NzModalService } from "ng-zorro-antd/modal";
import { countryCodes } from "../chat/shared/messageData";
import { Subscription } from "rxjs";

@Component({
  selector: "app-customer-page",
  templateUrl: "./customer-page.component.html",
  styleUrls: ["./customer-page.component.css"],
})
export class CustomerPageComponent implements OnInit {
  moment: any = moment;
  orderColumn: any = [];

  userList: any = [];
  bulkUploadModal = false;
  singleModal = false;
  uploadedJSONFile = [];
  singleCustomerForm!: FormGroup;
  updateCustomerForm!: FormGroup;
  creatingCustomer = false;
  updateModal = false;
  updateCustomerData: any;

  filterOption = "All Contacts";
  segmentsList: any = [];
  tableFilterArr: any = [];

  editCustomer: boolean = false;
  mainUserData: any = {};

  selectedTab: any = { name: "All Contacts", _id: "All Contacts" };

  dialCode: any = "91";

  countryCodes: any = countryCodes;

  importHistory: boolean = false;

  tableHeight: any = "";

  excelUploading: boolean = false;

  showFilter: boolean = true;

  appliedFilters: any = [];

  appliedFiltersObj: any = [];
  appliedFiltersSample: any = [];

  appliedPredefined: any = [];

  filterReq: any = [];

  filterApplied: boolean = false;

  errorObj: any = {
    filterType: [],
    values: [],
  };

  systemDefined: any = [
    { name: "Has WhatsApp", id: "6437a4c9524bde1b55a259de" },
    { name: "Doesn't have WhatsApp", id: "6437a4c9524bde1b55" },
    { name: "Recently created", id: "6437a4c9524bb55a259de" },
    { name: "Recently contacted", id: "6437a4c99de" },
    { name: "No reply yet", id: "6437a4c9b55a259de" },
    { name: "Opted in", id: "6437a4c9524bda259de" },
    { name: "Not opted in", id: "6437a4ca259de" },
  ];

  totalRecords: any;

  rightMenu: boolean = false;

  customerEvents: any = [
    {
      name: "G-Sheet Events",
      data: {
        time: "07-06-2023",
        description: "Data fields updated.",
      },
    },
    {
      name: "Added to cart",
      data: {
        time: "07-06-2023",
        description: "2 products",
      },
    },
    {
      name: "Order Placed",
      data: {
        time: "07-06-2023",
        description: "1 new order",
      },
    },
    {
      name: "Contacted support",
      data: {
        time: "07-06-2023",
        description: "try to contact support",
      },
    },
    {
      name: "G-Sheet Events",
      data: {
        time: "07-06-2023",
        description: "Data fields updated.",
      },
    },
  ];

  sliceCount: number = 300;

  drawerWidth: any = "40%";

  sortType: string = "";
  sortField: string = "";

  chatDrawer: boolean = false;

  private subscription: Subscription;

  constructor(
    private apiService: CommonApiService,
    public ete: ExportExcelService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private router: Router,
    private alertService: AlertServiceService,
    private navService: NavService,
    private modal: NzModalService
  ) {}

  formControlObj: any = {};

  customerUploadFile: any = "";
  importName: any = null;

  FilteredCustomers: any = [];

  allContactsCount: number;

  // search and pagination

  searchKey: any = "";

  pageIndex = 1;
  totalCount: number;
  pageSize: number = 20;

  fieldsForFilter: any;
  filterSuggestion: any;

  selectedContacts: any = [];
  userPreviewData: any;

  ngOnInit(): void {
    // this.tableFilterArr = this.orderColumn.map((e) => e.title);
    if (window.innerWidth > 768) {
      this.drawerWidth = "40%";
    } else {
      this.drawerWidth = "80%";
    }
    this.getMainUserData();
    this.fetchData();
    this.getFieldData();
    this.getSegments();
    this.getFieldsForFilter();

    this.updateCustomerForm = this.fb.group({
      cusName: ["", Validators.required],
      cusMail: [null, [Validators.email]],
      cusBroadCast: true,
    });
  }

  fetchData() {
    this.getAllTags();
    this.importHistory = false;
    this.spinner.show();
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    var request: any = {
      filterValue: this.searchKey,
    };

    if (this.sortField) {
      request.sortBy = {
        fieldName: this.sortField,
        type: this.sortType,
      };
    }

    if (this.appliedPredefined.length > 0) {
      request.predefined = this.appliedPredefined;
    }

    this.apiService
      .commonPostMethod(
        environment.url +
          "customers?page=" +
          this.pageIndex +
          "&limit=" +
          this.pageSize,
        request,
        options
      )
      .subscribe(
        (response: any) => {
          // console.log(response);
          if (this.editCustomer && this.updateCustomerData) {
            var targetIndex = response.data.customers.findIndex((e: any) => {
              return e._id == this.updateCustomerData._id;
            });
            this.updateCustomerData = response.data.customers[targetIndex];
          }
          this.userList = response.data.customers;
          if (!this.searchKey) {
            this.allContactsCount = response?.data?.pagination?.totalCount;
          }
          this.totalCount = response?.data?.pagination?.totalCount;
          this.totalRecords = response?.data?.pagination?.totalCount;
          this.pageSize = response?.data?.pagination?.limit;
          this.spinner.hide();
          // console.log(this.userList);
        },
        (err: any) => {
          if (
            err?.error?.message == "jwt expired" ||
            err?.error?.message == "jwt must be provided" ||
            err?.error?.message == "jwt malformed" ||
            err?.error?.message == "invalid signature" ||
            err?.error?.message == "Given token user does not exist." ||
            err?.error?.message == "invalid token"
          ) {
            this.navService.tokenExpired();
          }
          console.log(err);
          this.spinner.hide();
        }
      );
  }

  getSegments() {
    // this.importHistory = false
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonGetMethod(environment.url + "segments", options)
      .subscribe(
        (response: any) => {
          // console.log(response);
          this.segmentsList = response.data.segments;
        },
        (error: any) => {
          console.log(error);
        }
      );
  }

  // BULK UPLOAD FORM MODAL

  openBulkUploadModal() {
    if (this?.mainUserData?.accessTo?.sandboxId) {
      // if (this.allContactsCount >= 3) {
      var errHead: any = "You cannot upload contacts in Sandbox account!";
      var errBody: any = "Please Subscribe any Plan to Add more..!";
      this.alertService.freeTrialWarning(errHead, errBody);
      // }
    } else {
      this.importName = null;
      this.customerUploadFile = null;
      this.bulkUploadModal = true;
      this.excelUploading = false;
    }

    // if (this?.mainUserData?.accessTo?.sandboxId) {
    //   if (this.userList.length >= 10) {
    //     var errHead: any = "You can add maximum 10 Customers by Sandbox account!";
    //     var errBody: any = "Please Subscribe any Plan to Add more..!";
    //     this.alertService.freeTrialWarning(errHead, errBody);
    //   } else {
    //     this.importName = null;
    //     this.customerUploadFile = null;
    //     this.bulkUploadModal = true;
    //   }
    // } else {
    //   this.importName = null;
    //   this.customerUploadFile = null;
    //   this.bulkUploadModal = true;
    // }
  }

  closeOtpModal() {
    this.bulkUploadModal = false;
  }

  // UPLOAD FILE

  filesDropped(files: any): void {
    // console.log(files);
    // this.xlsxToJSON(files[0].file);
    this.customerUploadFile = files[0].file;
    // this.uploadDirectExcel(files[0].file);
  }

  uploadFile(ev) {
    // console.log(ev.target.files[0]);
    // this.xlsxToJSON(ev.target.files[0]);
    this.customerUploadFile = ev.target.files[0];
    // this.uploadDirectExcel(ev.target.files[0]);
    ev.target.value = "";
  }

  xlsxToJSON(ev) {
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = ev;
    reader.onload = (event) => {
      const data = reader.result;
      workBook = XLSX.read(data, { type: "binary" });
      jsonData = workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet);
        return initial;
      }, {});
      const dataString = jsonData;
      var temp: any;
      var fileName;
      for (temp in dataString) {
        fileName = temp;
      }
      this.uploadedJSONFile = dataString[fileName];
      // console.log(this.uploadedJSONFile);
      this.csvUpload();
    };
    reader.readAsBinaryString(file);
  }

  // UPLOAD CSV FILE

  csvUpload() {
    var temp: any = {};
    var formData = [];
    for (let i = 0; i < this.uploadedJSONFile.length; i++) {
      // for(temp1 in this.uploadedJSONFile[i]){
      //   console.log(temp1);
      // }
      temp = {};
      temp.name = this.uploadedJSONFile[i].Name;
      temp.phoneNo = this.uploadedJSONFile[i]["Phone Number"];
      temp.allowBroadcast = this.uploadedJSONFile[i]["Allow Broadcast"];
      formData.push(temp);
    }
    // console.log(this.uploadedJSONFile);

    // this.uploadCSVData(formData);
    this.uploadCSVData(this.uploadedJSONFile);
  }

  uploadCSVData(formData: any) {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url + "customers/csv-upload",
        formData,
        options
      )
      .subscribe(
        (response: any) => {
          // console.log(response);
          if (response.status == "success") {
            this.toastr.success(response.message, "", {
              timeOut: 2500,
            });
            this.fetchData();
            this.bulkUploadModal = false;
            this.importName = null;
            this.customerUploadFile = null;
          }
        },
        (error) => {
          console.log(error);
          this.toastr.error(
            "File Upload Failed ",
            "Please ReUpload Your File",
            {
              timeOut: 2500,
            }
          );
        }
      );
  }

  uploadDirectExcel() {
    if (this?.mainUserData?.accessTo?.sandboxId) {
      var errHead: any = "You cannot upload contacts in Sandbox account!";
      var errBody: any = "Please Subscribe any Plan to Upload contacts..!";
      this.alertService.freeTrialWarning(errHead, errBody);
    } else {
      if (this.customerUploadFile) {
        this.excelUploading = true;

        var formData = new FormData();

        formData.append("file", this.customerUploadFile);
        formData.append("name", this.importName);

        let options = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
          },
        };

        this.apiService
          .commonPostMethod(
            environment.url + "customers/csv-or-excel-upload",
            formData,
            options
          )
          .subscribe(
            (response: any) => {
              // console.log(response);
              if (response.status == "success") {
                this.toastr.success(response.message, "", {
                  timeOut: 2500,
                });
                this.excelUploading = false;
              } else if (response.status == "failed") {
                this.toastr.error(response.message, "", {
                  timeOut: 2500,
                });
                // this.downloadExcel(response?.data?.errors);
                this.excelUploading = false;
              }
              this.fetchData();
              this.customerUploadFile = null;
              this.importName = null;
              this.bulkUploadModal = false;
            },
            (error) => {
              console.log(error);
              this.excelUploading = false;
              if (error?.error?.message) {
                this.toastr.error(error?.error?.message, "", {
                  timeOut: 2500,
                });
              } else {
                this.toastr.error(error?.message, "", {
                  timeOut: 2500,
                });
              }
              this.customerUploadFile = null;
              // this.toastr.error(
              //   "File Upload Failed ",
              //   "Please ReUpload Your File",
              //   {
              //     timeOut: 2500,
              //   }
              // );
            }
          );
      } else if (!this.customerUploadFile) {
        this.toastr.error("Please Upload File", "", {
          timeOut: 2500,
        });
      }
    }
  }

  // download error files

  downloadExcel(data: any) {
    var errorData: any = [];
    const allKeys = Object.keys(data[0]);
    // console.log(allKeys);
    let orderData = [];
    if (data.length > 0) {
      for (let i = 0; i < data?.length; i++) {
        var temp = [i + 1];
        for (let k = 0; k < allKeys.length; k++) {
          temp.push(data[i][allKeys[k]]);
        }

        errorData.push(temp);
        temp = [];
      }
    }

    orderData = [
      {
        title: ["Check the errors"],
        data: errorData,
        headers: ["S.No"].concat(allKeys),
      },
    ];

    if (orderData.length > 0) {
      this.ete.exportExcel(orderData[0], "Error_Report");
      // console.log(orderData);
    }
  }

  // DOWNLOAD SAMPLE EXCEL FILE

  downloadSampleFile() {
    var headings: any = [];
    var sampleFields: any = [];
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url + "customer-field/get?filter=for-csv-excel-upload",
        {},
        options
      )
      .subscribe(
        (response: any) => {
          // console.log(response);
          const fields = response.data.customerFields;
          for (let i = 0; i < fields.length; i++) {
            // headings.push(
            //   fields[i].isMandatory
            //     ? `${fields[i].name} *`
            //     : `${fields[i].name}`
            // );
            headings.push(fields[i].name);
            switch (fields[i].dataType) {
              case "text":
                sampleFields.push("Text");
                break;
              case "email":
                sampleFields.push("sample@gmail.com");
                break;
              case "number":
                sampleFields.push("12");
                break;
              case "phone-number":
                sampleFields.push("919876543210");
                break;
              case "textarea":
                sampleFields.push("paragraph or description");
                break;
              case "select":
                sampleFields.push(fields[i].acceptedValues[0]);
                break;
              case "multi-select":
                sampleFields.push(
                  `${fields[i].acceptedValues[0]}, ${fields[i].acceptedValues?.[1]}`
                );
                break;
              case "tags":
                sampleFields.push("Good Customer , Responsive");
                break;
              case "date":
                sampleFields.push(moment().format("DD-MM-YYYY hh:mm A"));
                break;
              case "switch":
                sampleFields.push("true");
                break;
              default:
                break;
            }
          }

          let sampleData = {
            title: "sample",
            data: [sampleFields],
            // headers: ["Name", "Phone Number", "Allow Broadcast"],
            headers: headings,
          };

          this.ete.exportExcel(sampleData, "sample");
        },
        (error) => {
          console.log(error);
          if (error?.error?.message) {
            this.toastr.error(error?.error?.message, "", {
              timeOut: 2500,
            });
          } else {
            this.toastr.error(error?.message, "", {
              timeOut: 2500,
            });
          }
        }
      );
  }

  // SINGLE CUSTOMER MODAL

  closeCustomerModal() {
    if (this.singleCustomerForm.dirty) {
      this.saveChangesAlert();
    } else {
      this.singleModal = false;
      this.editCustomer = false;
    }
  }

  saveChangesAlert() {
    this.modal.confirm({
      nzOkText: "Keep Changes",
      nzCancelText: "Discard Changes",
      nzTitle: "<b>You have unsaved changes.</b>",
      nzContent: "Are you sure you want to close without saving?",
      nzOnOk: () => this.createCustomer(),
      nzOnCancel: () => {
        this.singleModal = false;
        this.editCustomer = false;
      },
    });
  }

  closeUpdateModal() {
    this.updateModal = false;
  }

  updateCustomer() {
    // console.log(this.updateCustomerForm.value);
    if (this.updateCustomerForm.valid) {
      let options = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
        },
      };

      var data = {
        customerId: this.updateCustomerData._id,
        name: this.updateCustomerForm.value.cusName,
        email: this.updateCustomerForm.value.cusMail,
        allowBroadcast: this.updateCustomerForm.value.cusBroadCast,
      };

      this.apiService
        .commonPostMethod(environment.url + "customers/update", data, options)
        .subscribe(
          (response: any) => {
            // console.log(response);
            this.toastr.success(response.message, "", {
              timeOut: 2500,
            });
            if (this.filterApplied) {
              this.applyFilter();
            } else if (this.selectedTab?._id == "All Contacts") {
              this.fetchData();
            } else if (this.selectedTab?._id != "All Contacts") {
              this.getCustomerBySegment();
            }

            // this.updateCustomerData = {};
            this.updateModal = false;
          },
          (error: any) => {
            console.log(error);
            this.updateModal = false;
          }
        );
    } else {
      Object.values(this.updateCustomerForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  uploadExcelFile(fileData) {
    var formData = new FormData();
    formData.append("file", fileData);
  }

  // TABLE SCROLL TOP ON PAGE CHANGE

  onPageNumberChange(id: any, event: any) {
    if (this.pageIndex != event.pageIndex) {
      this.viewMoreArr = [];
      this.textAreaArr = [];
      this.pageIndex = event.pageIndex;
      this.onAllChecked(false);
      this.hideRightMenu();
      if (this.filterApplied) {
        this.applyFilter();
      } else if (this.selectedTab?._id != "All Contacts") {
        this.getCustomerBySegment();
      } else if (this.selectedTab?._id == "All Contacts") {
        this.fetchData();
      }
      document.getElementById(id).scrollIntoView();
    }
  }

  filterChange(selected: any) {
    if (this.mainUserData?.accessTo?.isExpired) {
      this.alertService.warning();
    } else {
      this.filterOption = selected;
    }
  }

  addSegment() {
    if (this.mainUserData?.accessTo?.isExpired) {
      this.alertService.warning();
    } else {
      this.router.navigate(["/contacts/segment"]);
    }
  }

  // custom field section starts

  fieldData: any = [];
  allTags: any = [];

  submitData: any = {};

  getAllTags() {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(environment.url + "tag?noPagination", {}, options)
      .subscribe(
        (response: any) => {
          this.allTags = response.data.tags;
          // console.log(this.allTags);
        },
        (error: any) => {
          console.log(error);
        }
      );
  }

  setFormValue() {
    console.log(this.updateCustomerData);
    for (let s = 0; s < this.fieldData.length; s++) {
      // console.log(this.fieldData);
      if (this.fieldData[s].dataType == "switch") {
        this.formControlObj[this.fieldData[s].name] = [true];
      } else if (this.fieldData[s].isMandatory) {
        this.formControlObj[this.fieldData[s].name] = [
          null,
          [Validators.required],
        ];
      } else {
        this.formControlObj[this.fieldData[s].name] = [null];
      }
    }
    this.singleCustomerForm = this.fb.group(this.formControlObj);

    if (this.editCustomer) {
      // console.log(this.formControlObj);
      var fieldKeysArr: any = Object.keys(this.formControlObj);
      var updateKeysArr: any = Object.keys(this.updateCustomerData);

      for (let i = 0; i < updateKeysArr.length; i++) {
        for (let j = 0; j < fieldKeysArr.length; j++) {
          // console.log(this.formControlObj[fieldKeysArr[j]]);
          if (updateKeysArr[i] == fieldKeysArr[j]) {
            var targetIndex = this.fieldData.findIndex((data: any) => {
              return data.name == fieldKeysArr[j];
            });
            // console.log(this.fieldData[targetIndex]);
            if (
              targetIndex > -1 &&
              this.fieldData[targetIndex].dataType == "multi-select"
            ) {
              this.singleCustomerForm.get(updateKeysArr[i]).setValue(
                this.updateCustomerData[updateKeysArr[i]]
                // .split(",")
                // .map((item: any) => item.replace(/'/g, "").trim())
              );
            } else if (this.fieldData[targetIndex].dataType == "date") {
              // console.log(this.updateCustomerData[updateKeysArr[i]]);
              this.singleCustomerForm
                .get(updateKeysArr[i])
                .setValue(new Date(this.updateCustomerData[updateKeysArr[i]]));
            } else if (updateKeysArr[i] == "tags") {
              var temp: any = [];
              for (
                let k = 0;
                k < this.updateCustomerData[updateKeysArr[i]].length;
                k++
              ) {
                temp.push(this.updateCustomerData[updateKeysArr[i]][k].name);
              }
              this.singleCustomerForm.get(updateKeysArr[i]).setValue(temp);
            } else {
              // console.log(this.updateCustomerData[updateKeysArr[i]]);
              this.singleCustomerForm
                .get(updateKeysArr[i])
                .setValue(this.updateCustomerData[updateKeysArr[i]]);
            }
          }
          // else if (condition) {
          //   this.singleCustomerForm.get(updateKeysArr[i]).setValue("");
          // }
        }
      }
    }
    this.dialCode = "91";
    // console.log(this.fieldData);
    this.singleModal = true;
  }

  getFieldData() {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url + "customer-field/get?filter=for-csv-excel-upload",
        {},
        options
      )
      .subscribe(
        (response: any) => {
          console.log(response);
          this.fieldData = response.data.customerFields;
          for (let i = 0; i < this.fieldData.length; i++) {
            var temp: any = {};
            temp.label = this.fieldData[i].label;
            temp.name = this.fieldData[i].name;
            temp.dataType = this.fieldData[i].dataType;
            temp.fieldType = this.fieldData[i].fieldType;

            // temp.id = this.fieldData[i]._id;

            if (this.fieldData[i].name == "name") {
              temp.width = "130px";
            } else if (this.fieldData[i].name == "email") {
              temp.width = "120px";
            } else if (this.fieldData[i].name == "phoneNo") {
              temp.width = "100px";
            } else if (this.fieldData[i].name == "allowBroadcast") {
              temp.width = "100px";
            } else {
              temp.width =
                temp?.label?.toString()?.length < 10
                  ? temp?.label?.toString()?.length * 18 + "px"
                  : temp?.label?.toString()?.length * 8 + "px";
              // console.log(temp.label);
              // console.log(temp.width);
            }

            this.orderColumn.push(temp);
          }
          this.tableFilterArr = this.orderColumn.map((e: any) => e.label);
          // console.log(this?.orderColumn);

          // console.log(this.formControlObj);
          // this.singleModal = true;
        },
        (error) => {
          console.log(error);
          if (error?.error?.message) {
            this.toastr.error(error?.error?.message, "", {
              timeOut: 2500,
            });
          } else {
            this.toastr.error(error?.message, "", {
              timeOut: 2500,
            });
          }
        }
      );
  }

  // create and update

  singleCustomerModal() {
    if (this?.mainUserData?.accessTo?.sandboxId) {
      if (this.allContactsCount >= 3) {
        var errHead: any =
          "You can add maximum 3 Customers by Sandbox account!";
        var errBody: any = "Please Subscribe any Plan to Add more..!";
        this.alertService.freeTrialWarning(errHead, errBody);
      } else {
        this.editCustomer = false;
        this.setFormValue();
        // this.singleModal = true;
        // this.getFieldData();
      }
    } else {
      this.editCustomer = false;
      this.setFormValue();
      // this.singleModal = true;
      // this.getFieldData();
    }
    // this.singleCustomerForm.reset();
    // this.singleCustomerForm.get("allowBroadcast").setValue(true);
    // this.singleCustomerForm.get("countryCode").setValue("+91");
    // this.singleModal = true;
  }

  showUserData(cusData: any, id: any) {
    // console.log(cusData);
    if (cusData._id == this.updateCustomerData?._id) {
      this.hideRightMenu();
    } else {
      this.editCustomer = false;
      this.updateCustomerData = cusData;
      this.rightMenu = true;
      document.getElementById(id).scrollIntoView({ behavior: "smooth" });
      // console.log(this.updateCustomerData);
    }
    // console.log(this.fieldData);
  }

  hideRightMenu() {
    this.editCustomer = false;
    this.rightMenu = false;
    this.updateCustomerData = null;
  }

  showUpdateForm(cusData: any) {
    // console.log(cusData);
    if (this.mainUserData?.accessTo?.isExpired) {
      this.alertService.warning();
    } else {
      this.updateCustomerData = cusData;
      this.editCustomer = true;
      this.setFormValue();
      // this.singleModal = true;
      // this.getFieldData();
    }
    // this.updateCustomerForm.get("cusName").setValue(cusData.name);
    // this.updateCustomerForm.get("cusMail").setValue(cusData?.email);
    // this.updateModal = true;
  }

  createCustomer() {
    if (this.singleCustomerForm.valid) {
      var url = "";

      if (this.editCustomer) {
        url = "customers/update";
        this.singleCustomerForm
          .get("phoneNo")
          .setValue(this.updateCustomerData.phoneNo);
        // formData.customerId = this.updateCustomerData?._id;
      } else {
        url = "customers/create";
        this.singleCustomerForm.value.phoneNo = parseFloat(
          this.dialCode + this.singleCustomerForm.value.phoneNo
        );
      }

      // console.log(this.singleCustomerForm.value);
      this.creatingCustomer = true;
      let options = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
        },
      };

      var tempArray = Object.keys(this.singleCustomerForm.value);
      for (let n = 0; n < tempArray.length; n++) {
        if (this.singleCustomerForm.value[tempArray[n]] == null) {
          this.singleCustomerForm.value[tempArray[n]] = "";
        }
        if (Array.isArray(this.singleCustomerForm.value[tempArray[n]])) {
          // console.log(tempArray[n]);
          this.singleCustomerForm.value[tempArray[n]] =
            this.singleCustomerForm.value[tempArray[n]].toString();
        }

        if (this.singleCustomerForm.value[tempArray[n]] instanceof Date) {
          // console.log(tempArray[n]);
          this.singleCustomerForm.value[tempArray[n]] = moment(
            this.singleCustomerForm.value[tempArray[n]]
          ).format("DD-MM-YYYY hh:mm A");
        }
      }
      var formData = this.singleCustomerForm.value;

      this.apiService
        .commonPostMethod(environment.url + url, formData, options)
        .subscribe(
          (response: any) => {
            // console.log(response);
            if (response.status == "success") {
              if (
                response?.data?.newCustomer?.isContactHasWhatsapp ||
                this.editCustomer
              ) {
                this.toastr.success(response.message, "", {
                  timeOut: 2500,
                });
              }

              this.singleModal = false;
              if (!this.editCustomer) {
                this.pageIndex = 1;
                // if (!response?.data?.newCustomer?.isContactHasWhatsapp) {
                //   this.toastr.error(
                //     "There is No Whatsapp Account in given number",
                //     "",
                //     {
                //       timeOut: 2500,
                //     }
                //   );
                // }
              }
              this.viewMoreArr = [];
              this.textAreaArr = [];
              if (this.filterApplied) {
                this.applyFilter();
              } else if (this.selectedTab?._id != "All Contacts") {
                this.getCustomerBySegment();
              } else if (this.selectedTab?._id == "All Contacts") {
                this.fetchData();
              }
              this.getSegments();
            }
            this.creatingCustomer = false;
          },
          (error) => {
            console.log(error);
            if (error?.error?.message) {
              this.toastr.error(error?.error?.message, "", {
                timeOut: 2500,
              });
            } else {
              this.toastr.error(error?.message, "", {
                timeOut: 2500,
              });
            }
            this.creatingCustomer = false;
          }
        );
      // console.log(formData);
    } else {
      this.toastr.error(
        "Please provide all required information to continue.",
        "",
        {
          timeOut: 2500,
        }
      );
      Object.values(this.singleCustomerForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  getMainUserData() {
    this.subscription = this.navService.mainUserData.subscribe(
      (response: any) => {
        if (response) {
          // console.log(response);
          this.mainUserData = response.data.userData;
          if (
            response?.data?.userData?.accessTo?.sandboxId &&
            window.innerWidth > 992
          ) {
            this.tableHeight = "calc(81vh - 155px)";
          } else {
            this.tableHeight = "calc(87vh - 155px)";
          }
        }
      }
    );
    // let options = {
    //   headers: {
    //     Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
    //   },
    // };

    // this.apiService
    //   .commonGetMethod(environment.url + "auth/get-user-data", options)
    //   .subscribe(
    //     (response: any) => {
    //       this.mainUserData = response.data.userData;
    //       // console.log(this.mainUserData);
    //     },
    //     (error: any) => {
    //       console.log(error);
    //     }
    //   );
  }

  tableFilterChange(heading: any) {
    if (this.tableFilterArr.indexOf(heading) >= 0) {
      this.tableFilterArr.splice(this.tableFilterArr.indexOf(heading), 1);
    } else {
      this.tableFilterArr.push(heading);
    }
    // console.log(this.tableFilterArr);
  }

  selectSegment(selected: any) {
    // this.filterApplied = false;
    this.selectedTab = selected;
    this.onAllChecked(false);
    this.hideRightMenu();
    // console.log(selected);
    this.pageIndex = 1;
    this.viewMoreArr = [];
    this.textAreaArr = [];
    this.searchKey = "";
    if (this.filterApplied) {
      this.applyFilter();
    } else if (selected._id != "All Contacts") {
      this.getCustomerBySegment();
    } else if (selected._id == "All Contacts") {
      this.fetchData();
    }
  }

  getCustomerBySegment() {
    this.importHistory = false;
    this.spinner.show();
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    var request: any = {
      segmentId: this.selectedTab._id,
      filterValue: this.searchKey,
    };

    if (this.appliedPredefined.length > 0) {
      request.predefined = this.appliedPredefined;
    }

    this.apiService
      .commonPostMethod(
        environment.url +
          "segments/get-detail?page=" +
          this.pageIndex +
          "&limit=" +
          this.pageSize,
        request,
        options
      )
      .subscribe(
        (response: any) => {
          // console.log("The response");
          // console.log(response);
          if (this.editCustomer && this.updateCustomerData) {
            var targetIndex = response.data.customers.findIndex((e: any) => {
              return e._id == this.updateCustomerData._id;
            });
            this.updateCustomerData = response.data.customers[targetIndex];
          }
          this.totalCount = response?.data?.pagination?.totalCount;
          this.totalRecords = response?.data?.pagination?.totalCount;
          this.pageSize = response?.data?.pagination?.limit;
          this.FilteredCustomers = response.data.customers;
          this.spinner.hide();
          // console.log(this.userList);
        },
        (err: any) => {
          console.log(err);
          this.spinner.hide();
        }
      );
  }

  toEditSegment(segment: any) {
    this.router.navigate(["/contacts/segment", segment._id]);
  }

  // search nad pagination

  searchContacts() {
    this.pageIndex = 1;
    this.viewMoreArr = [];
    this.textAreaArr = [];
    this.onAllChecked(false);
    this.hideRightMenu();
    if (this.filterApplied) {
      this.applyFilter();
    } else if (this.selectedTab?._id != "All Contacts") {
      this.getCustomerBySegment();
    } else if (this.selectedTab?._id == "All Contacts") {
      this.fetchData();
    }
    // this.selectedTab = { name: "All Contacts", _id: "All Contacts" };
    // this.fetchData();
  }

  // delete segment

  showDeleteAlert(event: any, selected: any) {
    event.preventDefault();
    event.stopPropagation();

    this.modal.confirm({
      nzOkText: "Yes",
      nzCancelText: "No",
      nzTitle: "<b>Sure you Want to delete this Segment?</b>",
      nzContent: "<b>Once deleted, the Segment cannot be recovered..</b>",
      nzOnOk: () => this.deleteSegment(selected),
    });
  }

  deleteSegment(item: any) {
    // console.log(item);
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url + "segments/delete",
        { segmentId: item?._id },
        options
      )
      .subscribe(
        (response: any) => {
          // console.log(response);
          this.toastr.success(response.message, "", {
            timeOut: 2500,
          });
          this.getSegments();
          this.selectedTab = { name: "All Contacts", _id: "All Contacts" };
          this.pageIndex = 1;
          this.viewMoreArr = [];
          this.textAreaArr = [];
          this.searchKey = "";
          this.fetchData();
        },
        (err: any) => {
          console.log(err);
          if (err?.error?.message) {
            this.toastr.error(err?.error?.message, "", {
              timeOut: 2500,
            });
          } else {
            this.toastr.error(err?.message, "", {
              timeOut: 2500,
            });
          }
        }
      );
  }

  // new filter section starts

  getFieldsForFilter() {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url + "customer-field/get?filter=for-create-segment",
        {},
        options
      )
      .subscribe(
        (response: any) => {
          // console.log(response);
          this.fieldsForFilter = response?.data?.customerFields;
          this.filterSuggestion = response?.data?.getFilterOperatorData;
          for (let s = 0; s < this.fieldsForFilter.length; s++) {
            var temp = {
              dataType: "",
              filterType: "",
              key: "",
              values: [],
            };
            this.filterReq.push(temp);
          }
          console.log(this.filterReq);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  onFilterChecked(selected: any, index: any, event: any, filterFrom: string) {
    if (event) {
      this.appliedFilters.push(selected?.name);
      this.filterReq[index].dataType = selected?.dataType;
      this.filterReq[index].key = selected?.name;
    } else {
      this.appliedFilters.splice(
        this.appliedFilters.indexOf(selected?.name),
        1
      );

      this.appliedFiltersSample.splice(
        this.appliedFiltersSample.indexOf(selected?.name),
        1
      );

      this.filterReq[index].dataType = "";
      this.filterReq[index].key = "";
      this.filterReq[index].filterType = "";
      this.filterReq[index].values = [];
    }
    // console.log(this.appliedFilters);
    if (filterFrom == "top") {
      this.directApply();
    }
  }

  directApply() {
    this.hideRightMenu();
    this.pageIndex = 1;
    this.viewMoreArr = [];
    this.textAreaArr = [];
    this.applyFilter();
    this.onAllChecked(false);

    // document.getElementById("filterDiv").scrollIntoView();
  }

  applyFilter() {
    this.filterApplied = true;

    this.errorObj = {
      filterType: [],
      values: [],
    };

    var reqArray: any[] = this.filterReq.filter((obj) => obj.dataType !== "");

    for (let l = 0; l < reqArray.length; l++) {
      // console.log(reqArray[l].values.length);

      if (reqArray[l].filterType === "") {
        this.errorObj.filterType.push(reqArray[l].key);
      } else if (this.errorObj.filterType.indexOf(reqArray[l].key) > -1) {
        this.errorObj.filterType.splice(
          this.errorObj.filterType.indexOf(reqArray[l].key),
          1
        );
      }

      if (
        reqArray[l].values.length == 0 &&
        reqArray[l].filterType != "is empty" &&
        reqArray[l].filterType != "is not empty"
      ) {
        this.errorObj.values.push(reqArray[l].key);
      } else if (this.errorObj.values.indexOf(reqArray[l].key) > -1) {
        this.errorObj.values.splice(
          this.errorObj.values.indexOf(reqArray[l].key),
          1
        );
      }
    }

    if (this.selectedTab?._id != "All Contacts") {
      // console.log(JSON.parse(this.selectedTab.filterData));
      reqArray = reqArray.concat(JSON.parse(this.selectedTab.filterData));
      // console.log(reqArray);
    }

    // console.log(this.errorObj);

    if (
      this.errorObj.filterType.length == 0 &&
      this.errorObj.values.length == 0
    ) {
      this.appliedFiltersSample = JSON.parse(
        JSON.stringify(this.appliedFilters)
      );

      var request: any = {
        filter: {
          and: reqArray,
        },
        checkFilter: true,
        name: "contacts filter",
      };

      if (this.appliedPredefined.length > 0) {
        request.predefined = this.appliedPredefined;
      }

      let options = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
        },
      };

      var url =
        "segments/create?page=" + this.pageIndex + "&limit=" + this.pageSize;

      this.apiService
        .commonPostMethod(environment.url + url, request, options)
        .subscribe(
          (response: any) => {
            // console.log(response);
            if (this.editCustomer && this.updateCustomerData) {
              var targetIndex = response.data.customers.findIndex((e: any) => {
                return e._id == this.updateCustomerData._id;
              });
              this.updateCustomerData = response.data.customers[targetIndex];
            }
            if (this.selectedTab?._id != "All Contacts") {
              this.FilteredCustomers = response.data.customers;
            } else if (this.selectedTab?._id == "All Contacts") {
              this.userList = response.data.customers;
            }
            this.totalCount = response?.data?.totalCount;
            this.totalRecords = response?.data?.totalCount;
            this.pageSize = response?.data?.limit;
          },
          (error) => {
            console.log(error);
          }
        );
    } else {
      this.toastr.error(
        "Please provide all required information to continue.",
        "",
        {
          timeOut: 2500,
        }
      );
    }
  }

  clearFilter() {
    this.onAllChecked(false);
    this.filterApplied = false;
    this.appliedFilters = [];
    this.appliedFiltersSample = [];
    this.appliedPredefined = [];
    this.filterReq = [];
    for (let s = 0; s < this.fieldsForFilter.length; s++) {
      var temp = {
        dataType: "",
        filterType: "",
        key: "",
        values: [],
      };
      this.filterReq.push(temp);
    }
    // console.log(this.selectedTab);
    this.pageIndex = 1;
    this.viewMoreArr = [];
    this.textAreaArr = [];
    if (this.filterApplied) {
      this.applyFilter();
    } else if (this.selectedTab?._id != "All Contacts") {
      this.getCustomerBySegment();
    } else if (this.selectedTab?._id == "All Contacts") {
      this.fetchData();
    }
  }

  // change page size

  changePageSize(size: any) {
    this.pageSize = size;
    if (this.filterApplied) {
      this.applyFilter();
    } else if (this.selectedTab?._id != "All Contacts") {
      this.getCustomerBySegment();
    } else if (this.selectedTab?._id == "All Contacts") {
      this.fetchData();
    }
  }

  // checkbox section

  setOfCheckedId = new Set<number>();
  checked = false;
  updateMassModal: boolean = false;

  massUpdateField: any = {};
  contactsFilter: any;

  onAllChecked(value: boolean): void {
    this.userList.forEach((item) => this.updateCheckedSet(item._id, value));
    this.refreshCheckedStatus();
  }

  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  refreshCheckedStatus(): void {
    this.checked = this.userList.every((item) =>
      this.setOfCheckedId.has(item._id)
    );
    // this.indeterminate = this.listOfCurrentPageData.some(item => this.setOfCheckedId.has(item.id)) && !this.checked;
  }

  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }

    this.selectedContacts = Array.from(this.setOfCheckedId);

    // console.log(this.selectedContacts);
  }

  showMassUpdate() {
    this.updateMassModal = true;
  }

  cancelUpdateModal() {
    this.updateMassModal = false;
  }

  massUpdate() {
    this.updateMassModal = false;
  }

  // system defined filters

  applyPredefined(filter: any, event: any) {
    if (event) {
      this.appliedPredefined.push(filter?._id);
    } else {
      this.appliedPredefined.splice(
        this.appliedPredefined.indexOf(filter?._id),
        1
      );
    }

    this.pageIndex = 1;
    this.viewMoreArr = [];
    this.textAreaArr = [];
    this.hideRightMenu();
    if (this.filterApplied) {
      this.applyFilter();
    } else if (this.selectedTab?._id != "All Contacts") {
      this.getCustomerBySegment();
    } else if (this.selectedTab?._id == "All Contacts") {
      this.fetchData();
    }
    this.onAllChecked(false);
    this.checked = false;
  }

  setMyStyles() {
    let styles = {
      "margin-right":
        135 + (this.totalCount / this.pageSize).toFixed(0).length * 5 + "px",
    };
    return styles;
  }

  viewMoreArr: any = [];
  textAreaArr: any = [];

  viewMore(y: number) {
    if (this.viewMoreArr.indexOf(y) > -1) {
      this.viewMoreArr.splice(this.viewMoreArr.indexOf(y), 1);
    } else {
      this.viewMoreArr.push(y);
    }

    // console.log(this.viewMoreArr);
  }

  viewMoreArea(y: number) {
    if (this.textAreaArr.indexOf(y) > -1) {
      this.textAreaArr.splice(this.textAreaArr.indexOf(y), 1);
    } else {
      this.textAreaArr.push(y);
    }

    // console.log(this.textAreaArr);
  }

  getTypeOf(variable: any) {
    const type = Object.prototype.toString.call(variable).slice(8, -1);
    return type;
  }

  showAllContactDetails() {
    this.sliceCount = this.sliceCount == 3 ? 300 : 3;
  }

  exportContacts() {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(environment.url + "customers/export", {}, options)
      .subscribe(
        (response: any) => {
          // console.log(response);
          if (response.status == "success") {
            this.modal.success({
              nzTitle: response.message,
            });
          } else {
            this.modal.warning({
              nzTitle: response.message,
            });
          }
        },
        (err) => {
          console.log(err);
          if (err?.error?.message) {
            this.toastr.error(err?.error?.message, "", {
              timeOut: 2500,
            });
          } else {
            this.toastr.error(err?.message, "", {
              timeOut: 2500,
            });
          }
        }
      );
  }

  sortData(type: string, data: any) {
    console.log(data);
    this.pageIndex = 1;
    this.sortType = type;
    this.sortField = data.name;
    this.fetchData();
    // if (this.filterApplied) {
    //   this.applyFilter();
    // } else if (this.selectedTab?._id != "All Contacts") {
    //   this.getCustomerBySegment();
    // } else if (this.selectedTab?._id == "All Contacts") {
    //   this.fetchData();
    // }
  }

  chatData: any;

  openChatDrawer(chatData: any) {
    // console.log(chatData);
    this.chatData = chatData;
    this.chatDrawer = true;
  }

  closeChatDrawer() {
    this.chatDrawer = false;
  }

  ngOnDestroy(): void {
    // console.log("api key destroy..");
    this.subscription.unsubscribe();
  }
}

// {
//   firstName: ["", Validators.required],
//   lastName: ["", Validators.required],
//   mailId: [null, [Validators.required, Validators.email]],
//   countryCode: ["+91", Validators.required],
//   mobileNumber: ["", Validators.required],
//   allowBroadcast: true,
// }
