import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import { NzModalService } from "ng-zorro-antd/modal";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { CommonApiService } from "src/app/services/common-api.service";
import { NavService } from "src/app/services/nav.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-bot-flows",
  templateUrl: "./bot-flows.component.html",
  styleUrls: ["./bot-flows.component.css"],
})
export class BotFlowsComponent implements OnInit {
  moment: any = moment;
  orderColumn: Array<any> = [
    {
      title: "Name",
      width: "150px",
    },
    {
      title: "Created By",
      width: "100px",
    },
    {
      title: "Updated At",
      width: "100px",
    },
    // {
    //   title: "Status",
    //   width: "80px",
    // },
    {
      title: "Action",
      width: "80px",
    },
  ];

  tableHeight: any = "";
  mainUserData: any = {};

  // pagination section
  totalCount: number;
  pageIndex: number = 1;
  pageSize: number = 30;

  // search section
  searchKey: string = "";

  // create edit form section

  flowForm: FormGroup;
  flowFormDrawer: boolean = false;
  isEdit: boolean = false;

  botData: any = { name: "" };

  allFlows: Array<any> = [];

  editData: any;
  submitting: boolean = false;

  alertModal: boolean = false;

  private subscription: Subscription;

  constructor(
    private navService: NavService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private apiService: CommonApiService,
    private modal: NzModalService,
    private toastr: ToastrService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.flowForm = this.fb.group({
      flowName: ["", Validators.required],
      isThisStartPath: [true, Validators.required],
    });

    this.route.params.subscribe((e) => {
      this.botData._id = e["id"];
    });

    this.getMainUserData();
    this.fetchData();
  }

  fetchData() {
    this.spinner.show();
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url +
          "flow-paths?page=" +
          this.pageIndex +
          "&limit=" +
          this.pageSize,
        { flowId: this.botData._id, filterValue: this.searchKey },
        options
      )
      .subscribe(
        (response: any) => {
          console.log(response);
          this.allFlows = response.data.paths;
          this.botData = response.data.flow;
          this.totalCount = response?.data?.pagination?.totalCount;
          this.pageSize = response?.data?.pagination?.limit;
          this.spinner.hide();
        },
        (err: any) => {
          if (
            err?.error?.message == "jwt expired" ||
            err?.error?.message == "jwt must be provided" ||
            err?.error?.message == "jwt malformed" ||
            err?.error?.message == "invalid signature" ||
            err?.error?.message == "Given token user does not exist." ||
            err?.error?.message == "invalid token"
          ) {
            this.navService.tokenExpired();
          }
          console.log(err);
          this.spinner.hide();
        }
      );
  }

  getMainUserData() {
    this.subscription = this.navService.mainUserData.subscribe(
      (response: any) => {
        if (response) {
          // console.log(response);
          this.mainUserData = response.data.userData;
          if (response?.data?.userData?.accessTo?.sandboxId) {
            this.router.navigate(["/chat"]);
          }
          if (
            response?.data?.userData?.accessTo?.sandboxId &&
            window.innerWidth > 992
          ) {
            this.tableHeight = "56vh";
          } else {
            this.tableHeight = "65vh";
          }
        }
      }
    );
  }

  onPageNumberChange(id: any, event: any) {
    if (this.pageIndex != event.pageIndex) {
      this.pageIndex = event.pageIndex;
      this.fetchData();
      document.getElementById(id).scrollIntoView();
    }
  }

  changePageSize(size: any) {
    this.pageSize = size;
    this.pageIndex = 1;
    this.fetchData();
  }

  searchContacts() {
    this.pageIndex = 1;
    this.fetchData();
  }

  setMyStyles() {
    let styles = {
      "margin-right":
        135 + (this.totalCount / this.pageSize).toFixed(0).length * 5 + "px",
    };
    return styles;
  }

  // from create and edit section

  // create and edit section

  showFlowForm() {
    this.isEdit = false;
    this.flowForm.reset();
    this.flowForm.get("isThisStartPath").setValue(false);
    this.flowFormDrawer = true;
  }

  showEditFlowForm(editData: any) {
    this.editData = editData;
    this.isEdit = true;
    console.log(editData);
    this.flowForm.reset();
    this.flowForm.patchValue({
      flowName: editData.name,
      isThisStartPath: editData.isThisStartPath,
    });
    this.flowFormDrawer = true;
  }

  closeFlowFormDrawer() {
    this.flowFormDrawer = false;
  }

  createUpdateFlow() {
    if (this.flowForm.valid) {
      this.submitting = true;
      let options = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
        },
      };

      var url: string = "";

      var reqObj: any = {
        flowPathName: this.flowForm.value.flowName,
        isThisStartPath: this.flowForm.value.isThisStartPath,
        flowId: this.botData._id,
      };

      if (this.isEdit) {
        url = "flow-paths/update";
        reqObj.flowPathId = this.editData?._id;
      } else {
        url = "flow-paths/create";
      }

      this.apiService
        .commonPostMethod(environment.url + url, reqObj, options)
        .subscribe(
          (response: any) => {
            this.submitting = false;
            console.log(response);
            this.toastr.success(response.message, "", {
              timeOut: 2500,
            });
            this.flowFormDrawer = false;
            this.editData = null;
            this.fetchData();
          },
          (err: any) => {
            this.submitting = false;
            console.log(err);
            if (err?.error?.message) {
              this.toastr.error(err?.error?.message, "", {
                timeOut: 2500,
              });
            } else {
              this.toastr.error(err?.message, "", {
                timeOut: 2500,
              });
            }
          }
        );
    } else {
      Object.values(this.flowForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  selectedPath: any;

  deleteAlert(selected: any) {
    // console.log(selected.isThisStartPath);
    if (selected.isThisStartPath && this.allFlows.length > 1) {
      this.alertModal = true;
      this.selectedPath = selected;
    } else {
      this.modal.confirm({
        nzOkText: "Yes",
        nzCancelText: "No",
        nzTitle: "Path will be deleted Permanently.",
        nzContent: `Are you sure you want to delete?`,
        nzOnOk: () => this.deleteBot(selected._id, "normal"),
        nzOnCancel: () => {},
      });
    }
  }

  closeAlertModal() {
    this.alertModal = false;
    this.selectedPath = null;
    this.editData = null;
    this.isEdit = false;
    this.flowForm.reset();
  }

  deleteBot(botId: any, method: string) {
    if (method == "start") {
      this.isEdit = true;
      this.flowForm.reset();
      this.flowForm.patchValue({
        flowName: this.editData.name,
        isThisStartPath: true,
      });

      this.createUpdateFlow();
    }
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url + "flow-paths/delete",
        { flowPathId: botId },
        options
      )
      .subscribe(
        (response: any) => {
          console.log(response);
          this.toastr.success(response.message, "", {
            timeOut: 2500,
          });
          this.fetchData();
          this.closeAlertModal();
        },
        (err: any) => {
          console.log(err);
          if (err?.error?.message) {
            this.toastr.error(err?.error?.message, "", {
              timeOut: 2500,
            });
          } else {
            this.toastr.error(err?.message, "", {
              timeOut: 2500,
            });
          }
        }
      );
  }

  ngOnDestroy(): void {
    // console.log("api key destroy..");
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
