<div class="row top_main_row overflow_row_mobile simple_broadcast"
    [ngClass]="{'overflow_row_mobile_md' : campaignType}">
    <div class="col-lg-9 main_outer_heading">
        <h3>
            <span (click)="backBtn()" nz-icon nzType="left" nzTheme="outline"></span>
            {{ (!campaignType ? 'Create Campaign' : 'Simple Broadcast')}}
        </h3>
    </div>

    <div *ngIf="!campaignType" class="col-lg-9 campaign_type">
        <h4 class="mt-5 mb-3">What type of campaign would you like to create?</h4>
        <div class="row mt-3">
            <div class="col-lg-4 col-4">
                <nz-card class="">
                    <div class="campaign_type_card">
                        <div class="">
                            <img src="../../../../assets/images/icons/simple-broadcasr.svg" alt="">
                            <h5>Simple Broadcast</h5>
                            <p>Send customized messages to multiple recipients at once from your Contact List</p>
                        </div>
                        <a (click)="changeType('simpleBroadcast')" nz-button nzType="primary" class="create-button">
                            <span>Create</span>
                        </a>
                    </div>
                </nz-card>
            </div>

            <div class="col-lg-4 col-4">
                <nz-card class="">
                    <div class="campaign_type_card">
                        <div class="">
                            <img src="../../../../assets/images/icons/G-Sheet_campaign.svg" alt="">
                            <h5>Google Sheet Broadcast</h5>
                            <p>Run Campaign directly from Google Sheet.</p>
                        </div>
                        <a nz-button nzType="primary" class="create-button disable_btn">
                            <span>Coming soon</span>
                        </a>
                    </div>
                </nz-card>
            </div>

            <div class="col-lg-4 col-4">
                <nz-card class="">
                    <div class="campaign_type_card">
                        <div class="">
                            <img src="../../../../assets/images/icons/simple-broadcasr.svg" alt="">
                            <h5>API Broadcast</h5>
                            <p>Run Broadcast Using the powerful Ownchat Campaign API inside your Application.</p>
                        </div>
                        <a nz-button nzType="primary" class="create-button disable_btn">
                            <span>Coming soon</span>
                        </a>
                    </div>
                </nz-card>
            </div>
        </div>
    </div>

    <div *ngIf="campaignType == 'simpleBroadcast'" class="col-lg-9 simple_broadcast_col">
        <nz-card>
            <div class="campaign_name">
                <div class="text-left d-flex">
                    <h5> <span class="required_star">*</span>Campaign Name</h5>
                </div>
                <input [(ngModel)]="campaignName" placeholder="Enter Campaign Name" autocomplete="off" nz-input>
            </div>
        </nz-card>

        <nz-card>
            <div class="campaign_menu">
                <div class="text-left">
                    <div class="d-flex">
                        <h5><span class="required_star">*</span>Campaign Audience</h5>
                    </div>
                    <span class="description">Choose the contacts or segments you wish to send your campaign to.</span>
                    <nz-radio-group *ngIf="segmentSelected.length == 0 && selectedAudience.length == 0"
                        class="audience_type_radio" [(ngModel)]="audienceType" (ngModelChange)="selectAudienceType()">
                        <label nz-radio nzValue="direct-customers">Contacts</label>
                        <label nz-radio nzValue="segment">Segments</label>
                    </nz-radio-group>

                    <div *ngIf="selectedAudience.length > 0 || segmentSelected.length > 0" class="">
                        <h6 class="mt-2">Audience Selected :
                            <span>{{audienceType == 'segment' ? userList?.pagination?.totalCount
                                : selectedAudience.length}}
                            </span>
                        </h6>

                        <div class="change_delete_option">
                            <a (click)="resetAudienceAlert()" class="delete_link">Delete</a>
                            <a (click)="selectAudienceType()">Edit</a>
                        </div>
                    </div>
                </div>
            </div>
        </nz-card>

        <nz-card>
            <div class="campaign_menu">
                <div class="text-left">
                    <div class="d-flex">
                        <h5><span class="required_star">*</span>Campaign Content</h5>
                    </div>
                    <span class="description" *ngIf="!messageTemplateSelected">Select the Template Message you want to
                        Send</span>
                    <div *ngIf="!messageTemplateSelected" class="d-flex">
                        <a (click)="showTemplateModal()" class="select_template">Select Whatsapp Template</a>
                    </div>
                    <div *ngIf="messageTemplateSelected" class="">
                        <h6 class="mt-2">Selected Template : <span (click)="viewSelectedTemplate()"
                                class="template_name">{{messageTemplateSelected.name}}</span></h6>
                        <div class="change_delete_option">
                            <a (click)="resetTemplateAlert()" class="delete_link">Delete</a>
                            <a class="" (click)="showTemplateModal()">
                                Edit
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </nz-card>

        <nz-card>
            <div class="campaign_menu">
                <div class="text-left">
                    <div class="d-flex">
                        <h5><span class="required_star">*</span>Activate Options</h5>
                    </div>
                    <span class="description">Select the Template Message you want to Send</span>

                    <div class="campaign_timing">
                        <div (click)="changeSchedule('now')" class="schedule_time">
                            <a>Send Now</a>
                            <span>Send the campaign immediately to deliver to the recipient's inbox.</span>
                        </div>
                        <div (click)="changeSchedule('schedule')" class="schedule_time">
                            <a>Schedule Now</a>
                            <span>Send the campaign on the date and time you specify.</span>
                        </div>
                    </div>

                    <div *ngIf="scheduleType == 'schedule'" class="">
                        <nz-date-picker [(ngModel)]="scheduleTime" class="mt-3" nzShowToday
                            [nzDisabledDate]="disabledDate" [nzShowTime]="{ nzFormat: 'hh:mm a' }"
                            nzFormat="yyyy-MM-dd hh:mm a" [nzRenderExtraFooter]="">
                        </nz-date-picker>
                        <a class="create_link" (click)="createCampaign()">Create campaign</a>
                    </div>

                    <!-- new timer design -->
                    <!-- <div *ngIf="scheduleType == 'schedule'" class="mt-3 schedule_timers">
                        <nz-form-item class="date_picker">
                            <nz-form-label [nzSm]="24" [nzXs]="24" nzRequired class="large_label">
                                Date
                            </nz-form-label>
                            <nz-form-control [nzSm]="24" nzErrorTip="Please Select Date!">
                                <nz-input-group>
                                    <nz-date-picker [(ngModel)]="scheduleDate" class="" nzShowToday
                                        [nzDisabledDate]="disabledDate" nzFormat="yyyy-MM-dd" [nzRenderExtraFooter]="">
                                    </nz-date-picker>
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>

                        <nz-form-item class="date_picker">
                            <nz-form-label [nzSm]="24" [nzXs]="24" nzRequired class="large_label">
                                Time
                            </nz-form-label>
                            <nz-form-control [nzSm]="24" nzErrorTip="Please Select Time!">
                                <nz-input-group>
                                    <nz-time-picker #timePicker [nzPlacement]="'topLeft'" [(ngModel)]="scheduleTime"
                                        [nzUse12Hours]="true" nzFormat="h:mm a"></nz-time-picker>
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>

                        <nz-form-item class="">
                            <nz-form-label [nzSm]="24" [nzXs]="24" nzRequired class="large_label">
                                Time Zone
                            </nz-form-label>
                            <nz-form-control [nzSm]="24" nzErrorTip="Please Select Time Zone!">
                                <nz-input-group>
                                    <nz-select nzShowSearch class="w-100 select_dropdown"
                                        nzPlaceHolder="Select Time Zone">
                                        <nz-option *ngFor="let item of timeZone" [nzLabel]="item?.label"
                                            [nzValue]="item?.value">
                                        </nz-option>
                                    </nz-select>
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>

                        <a class="create_link" (click)="combineTime()">Create campaign</a>
                    </div> -->

                </div>
            </div>
        </nz-card>

        <ngx-spinner *ngIf="campaignCreating" bdColor="rgb(255,255,255)" size="medium" color="#000000"
            type="ball-clip-rotate-pulse" [fullScreen]="false">
        </ngx-spinner>
    </div>
</div>


<!-- MESSAGE TEMPLATE MODAL STARTS -->

<nz-modal [nzWidth]="modalWidth" [(nzVisible)]="templateModal" [nzFooter]="null" [nzTitle]="'Select Template'"
    nzCentered (nzOnCancel)="closeTemplateModal()">
    <ng-container *nzModalContent>
        <div *ngIf="!getVariableValue" class="">
            <div infinite-scroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="20"
                (scrolled)="bottomScrolled()" [scrollWindow]="false" class="row template_row template_preview_modal">

                <div class="preview_top_section col-12 mb-3">
                    <div class="filter">
                        <!-- <button nzTrigger="click" nz-dropdown [nzDropdownMenu]="filterOptions" type="button" nz-button
                            nzType="default">
                            <span nz-icon nzType="filter" nzTheme="outline"></span> Filters
                        </button> -->
                        <nz-dropdown-menu #filterOptions="nzDropdownMenu">
                            <ul class="filter_ul" nz-menu>
                                <li class="category_filter_options" *ngFor="let category of templateCategories">
                                    <label [nzChecked]="templateFilterArr.indexOf(category.value) >= 0"
                                        (nzCheckedChange)="templateCategoryFilter(category.value , $event)" nz-checkbox>
                                        {{category.label}}
                                    </label>
                                </li>
                            </ul>
                        </nz-dropdown-menu>
                    </div>
                    <div class="search">
                        <div class="toggle_options">
                            <nz-input-group class="" [nzPrefix]="suffixIconSearch">
                                <input [(ngModel)]="searchText" (keyup)="searchTemplates()"
                                    (keyup.enter)="searchTemplates()" autocomplete="off" type="text" nz-input
                                    placeholder="Search items.." />
                            </nz-input-group>
                            <ng-template #suffixIconSearch>
                                <span nz-icon nzType="search"></span>
                            </ng-template>
                        </div>
                    </div>
                </div>

                <ng-container *ngFor="let template of templateData">
                    <div *ngIf="templateData?.length > 0" class="col-lg-4 ">
                        <div class="template_col" (click)="showPreview(template)">
                            <div class="preview_heading">
                                <h5 [nzTooltipTitle]="template.name" nzTooltipPlacement="top" nz-tooltip>
                                    {{template.name.length > 23 ? template.name.slice(0,23) + '...' : template.name}}
                                </h5>
                                <label class="template_checkbox" nz-checkbox
                                    [nzChecked]="messageTemplateSelected?._id == template?._id"
                                    (nzCheckedChange)="showPreview(template)"></label>
                            </div>
                            <div class="preview_template">
                                <div class="inner_template">
                                    <div *ngFor="let message of template.components" class="mb-3">
                                        <!-- <h6>{{message?.type}} :</h6> -->
                                        <div [ngSwitch]="message?.type" class="">
                                            <div *ngSwitchCase="'HEADER'" class="">
                                                <div [ngSwitch]="message?.format" class="header_text">
                                                    <p *ngSwitchCase="'TEXT'" [innerHTML]="message.text"></p>
                                                    <p class="img_url" *ngSwitchCase="'IMAGE'">
                                                        <span nz-icon nzType="file-image" nzTheme="outline"></span>
                                                        IMAGE
                                                    </p>
                                                    <p class="img_url" *ngSwitchCase="'VIDEO'">
                                                        <span nz-icon nzType="video-camera" nzTheme="outline"></span>
                                                        VIDEO
                                                    </p>
                                                    <p class="img_url" *ngSwitchCase="'DOCUMENT'">
                                                        <span nz-icon nzType="file-done" nzTheme="outline"></span>
                                                        DOCUMENT
                                                    </p>
                                                </div>

                                            </div>
                                            <div *ngSwitchCase="'BODY'" class="">
                                                <p [innerHTML]="((message?.text))"></p>
                                            </div>
                                            <div *ngSwitchCase="'FOOTER'" class="">
                                                <p [innerHTML]="message?.text"></p>
                                            </div>
                                            <div *ngSwitchCase="'BUTTONS'" class="">
                                                <div *ngFor="let button of message?.buttons" class="template_buttons">
                                                    <h6>{{button?.text}}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="template_bottom">
                                <div class="bottom_left">
                                    <span class="template_lang">{{template?.language}}</span>
                                    <span class="template_lang">{{template?.category}}</span>
                                </div>
                                <span class="template_status">{{template?.status}}</span>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <div *ngIf="templateSpinner" class="col-12">
                    <nz-spin [nzSpinning]="templateSpinner" class="end_spinner" nzSimple></nz-spin>
                </div>

                <div *ngIf="templateData?.length == 0" class="empty_templates col-12">
                    <p>No Templates were available</p>
                </div>
            </div>

            <div class="choose_button mt-3">
                <a [disable]="syncing" (click)="syncTemplate()" class="sync_btn">
                    <span [ngClass]="{'sync_loader' : syncing}" nz-icon nzType="sync" nzTheme="outline"></span>
                    {{syncing ? 'Syncing' : 'Sync'}}
                </a>
                <button [disabled]="!messageTemplateSelected" [nzLoading]="templateLoading" (click)="selectTemplate()"
                    type="button" class="sighup_button " nz-button nzType="primary">
                    Next
                </button>
            </div>
        </div>

        <div *ngIf="getVariableValue" class="">
            <div class="main_variables_div">
                <div class="row variables_row">
                    <div class="col-lg-8 variable_values">
                        <div *ngIf="variableData?.mediaData?.hasMedia" class="upload-button save-button mb-3 mt-3">
                            <div class="upload_options">
                                <label (click)="changeMediaSource('file')" for='input-file'>
                                    Upload
                                    <span>{{variableData?.mediaData?.type}}</span>
                                    from pc
                                </label>
                                <p>Or</p>
                                <label (click)="changeMediaSource('url')">
                                    Enter Url
                                </label>
                            </div>

                            <div class="">
                                <div *ngIf="!templateMediaPreview  && mediaUrlField != 'useFallbackUrl'"
                                    class="preview_sample">
                                    <span nz-icon nzType="file-image" nzTheme="outline"></span>
                                </div>
                                <img *ngIf="(templateMediaPreview && variableData?.mediaData?.type == 'IMAGE') || (mediaSource == 'url' && mediaUrlField == 'useFallbackUrl' && variableData?.mediaData?.type == 'IMAGE')"
                                    class="uploaded_img"
                                    [src]="(mediaSource == 'url' && mediaUrlField == 'useFallbackUrl') ? mediaUrlFallback : templateMediaPreview"
                                    alt="">
                                <video
                                    *ngIf="(templateMediaPreview && variableData?.mediaData?.type == 'VIDEO') || (mediaSource == 'url' && mediaUrlField == 'useFallbackUrl' && variableData?.mediaData?.type == 'VIDEO')"
                                    class="uploaded_img uploaded_video"
                                    [src]="(mediaSource == 'url' && mediaUrlField == 'useFallbackUrl') ? mediaUrlFallback : templateMediaPreview"
                                    controls></video>
                                <span
                                    *ngIf="(templateMediaPreview && variableData?.mediaData?.type == 'DOCUMENT') || (mediaSource == 'url' && mediaUrlField == 'useFallbackUrl' && variableData?.mediaData?.type == 'DOCUMENT')">
                                    <img class="template_doc"
                                        src="../../../../../assets/images/chat/icons8-google-docs.svg" alt="pdf" />
                                    <p class="document_name">
                                        {{imageData?.name?.length > 30 ?
                                        (imageData?.name?.slice(0, 30) + '...') :
                                        imageData?.name}}
                                    </p>
                                </span>
                            </div>
                            <input (change)="getFile($event)" class="upload_input" id='input-file' type='file'
                                [accept]="acceptedTypes">
                        </div>

                        <div *ngIf="variableData?.variableTemplate?.length || (variableData?.mediaData?.hasMedia && mediaSource == 'url') || variableData?.mediaData?.type == 'DOCUMENT'"
                            class="mt-3 variable_data_div">
                            <nz-table class="" [nzShowPagination]="false" [nzScroll]="{ y: '500px', x : '500px' }"
                                #variableDataTable [nzData]="[{media : ''}]">
                                <thead>
                                    <tr>
                                        <th nzWidth="90px"></th>
                                        <th nzWidth="100px">Variable</th>
                                        <th nzWidth="200px">Value </th>
                                        <th nzWidth="200px">Fallback value </th>
                                    </tr>
                                </thead>
                                <tbody id="media_url_sec"
                                    *ngIf="(variableData?.mediaData?.hasMedia && mediaSource == 'url') || (variableData?.mediaData?.hasMedia && variableData?.mediaData?.type == 'DOCUMENT')">
                                    <tr *ngIf="mediaSource == 'url'">
                                        <td>MEDIA</td>
                                        <td>
                                            <input [disabled]="true" [readonly]="true" autocomplete="off"
                                                [value]="'media url'" nz-input>
                                        </td>
                                        <td>
                                            <nz-select [(ngModel)]="mediaUrlField" class="w-100 select_dropdown"
                                                nzPlaceHolder="select url field">
                                                <nz-option nzLabel="Use Fallback Url" nzValue="useFallbackUrl">
                                                </nz-option>
                                                <nz-option *ngFor="let key of fieldData" [nzLabel]="key?.label"
                                                    [nzValue]="key?.name">
                                                </nz-option>
                                            </nz-select>
                                        </td>
                                        <td>
                                            <input [(ngModel)]="mediaUrlFallback" (ngModelChange)="checkUrl()"
                                                autocomplete="off" nz-input>
                                        </td>
                                    </tr>
                                    <tr *ngIf="variableData?.mediaData?.type == 'DOCUMENT'">
                                        <td>DOCUMENT</td>
                                        <td>
                                            <input [disabled]="true" [readonly]="true" autocomplete="off"
                                                [value]="'file name'" nz-input>
                                        </td>
                                        <td>
                                            <nz-select [(ngModel)]="filenameUsageType" class="w-100 select_dropdown"
                                                nzPlaceHolder="select url field">
                                                <nz-option nzLabel="Default" nzValue="default"></nz-option>
                                                <nz-option nzLabel="Manual" nzValue="manual"></nz-option>
                                            </nz-select>
                                        </td>
                                        <td>
                                            <input *ngIf="filenameUsageType == 'manual'" [(ngModel)]="manualFilename"
                                                autocomplete="off" nz-input>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody *ngFor="let template of variableData.variableTemplate">
                                    <tr *ngFor="let variable of template.variables ; let i = index ">
                                        <td>{{i == 0 ? template.type : ''}}</td>
                                        <td>
                                            <input [disabled]="true" [readonly]="true" autocomplete="off"
                                                [value]="'{{' + (i+1) + '}}'" nz-input>
                                        </td>
                                        <td>
                                            <nz-select class="w-100 select_dropdown" nzPlaceHolder="Select Value"
                                                [(ngModel)]="variable.field">
                                                <nz-option nzLabel="Use Fallback Value" nzValue="useFallbackValue">
                                                </nz-option>
                                                <nz-option *ngFor="let key of fieldData" [nzLabel]="key?.label"
                                                    [nzValue]="key?.name">
                                                </nz-option>

                                            </nz-select>
                                        </td>
                                        <td>
                                            <input [(ngModel)]="variable.fallbackValue" autocomplete="off" nz-input>
                                        </td>
                                    </tr>
                                </tbody>
                            </nz-table>
                        </div>
                    </div>

                    <div class="col-lg-4">
                        <div class="template_col">
                            <div class="preview_heading">
                                <h5 [nzTooltipTitle]="messageTemplateSelected.name" nzTooltipPlacement="top" nz-tooltip>
                                    {{messageTemplateSelected.name.length > 23 ?
                                    messageTemplateSelected.name.slice(0,23) + '...' : messageTemplateSelected.name}}
                                </h5>
                            </div>
                            <div class="preview_template">
                                <div class="inner_template">
                                    <div *ngFor="let message of messageTemplateSelected.components" class="mb-3">
                                        <div [ngSwitch]="message?.type" class="">
                                            <div *ngSwitchCase="'HEADER'" class="">
                                                <div [ngSwitch]="message?.format" class="header_text">
                                                    <p *ngSwitchCase="'TEXT'" [innerHTML]="message.text"></p>
                                                    <p class="img_url" *ngSwitchCase="'IMAGE'">
                                                        <span nz-icon nzType="file-image" nzTheme="outline"></span>
                                                        IMAGE
                                                    </p>
                                                    <p class="img_url" *ngSwitchCase="'VIDEO'">
                                                        <span nz-icon nzType="video-camera" nzTheme="outline"></span>
                                                        VIDEO
                                                    </p>
                                                    <p class="img_url" *ngSwitchCase="'DOCUMENT'">
                                                        <span nz-icon nzType="file-done" nzTheme="outline"></span>
                                                        DOCUMENT
                                                    </p>
                                                </div>

                                            </div>
                                            <div *ngSwitchCase="'BODY'" class="">
                                                <p [innerHTML]="((message?.text))"></p>
                                            </div>
                                            <div *ngSwitchCase="'FOOTER'" class="">
                                                <p [innerHTML]="message?.text"></p>
                                            </div>
                                            <div *ngSwitchCase="'BUTTONS'" class="">
                                                <div *ngFor="let button of message?.buttons" class="template_buttons">
                                                    <h6>{{button?.text}}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="template_bottom">
                                <div class="bottom_left">
                                    <span class="template_lang">{{messageTemplateSelected?.language}}</span>
                                    <!-- <span class="template_lang">{{messageTemplateSelected?.category}}</span> -->
                                </div>
                                <span class="template_status">approved</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="get_value_buttons mt-3">
                <button (click)="backToChooseTemplate()" type="button" class="sighup_button mt-3" nz-button
                    nzType="default">
                    Change
                </button>

                <button [nzLoading]="sendingTemplateMedia" (click)="submitTemplate()" type="button"
                    class="sighup_button mt-3" nz-button nzType="primary">
                    Select
                </button>
            </div>
        </div>
        <ngx-spinner *ngIf="modalSpinner" bdColor="rgb(255,255,255)" size="medium" color="#000000"
            type="ball-clip-rotate-pulse" [fullScreen]="false">
        </ngx-spinner>
    </ng-container>

</nz-modal>

<!-- MESSAGE TEMPLATE MODAL ENDS -->

<!-- AUDIENCE SELECTION MODAL STARTS -->

<nz-modal [nzWidth]="modalWidth" [(nzVisible)]="audienceModal" [nzFooter]="null" [nzTitle]="'Select Audience'"
    nzCentered (nzOnCancel)="closeAudienceModal()">
    <ng-container *nzModalContent>
        <div class="main_audience_modal">
            <div class="row">
                <div *ngIf="audienceType == 'direct-customers'" class="col-3 left_menu contacts_table_column">
                    <nz-card class="customer_filter_height mt-2">
                        <div class="left_filter_height">
                            <div class="mb-3 filter_search_section">
                                <div class="">
                                    <h6>Filter Contacts By</h6>
                                    <div class="toggle_options mb-3">
                                        <nz-input-group class="search_input_section" [nzPrefix]="suffixIconSearch">
                                            <input [(ngModel)]="contactsFilter" autocomplete="off" type="text" nz-input
                                                placeholder="Search filters.." />
                                        </nz-input-group>
                                        <ng-template #suffixIconSearch>
                                            <span nz-icon nzType="search"></span>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>

                            <!-- filter next to search -->
                            <div *ngIf="appliedFiltersSample.length > 0" class="filter_checkbox">
                                <ng-container *ngFor="let filter of fieldsForFilter | filter : contactsFilter;
                             let a = index">
                                    <ng-container
                                        *ngIf="filterApplied && appliedFiltersSample.indexOf(filter.name) > -1"
                                        class="">
                                        <label [nzChecked]="appliedFilters.indexOf(filter.name) != -1" nz-checkbox
                                            (nzCheckedChange)="onFilterChecked(filter, a, $event, 'top')">
                                            {{ filter?.label}}</label>
                                        <div *ngIf="appliedFilters.indexOf(filter.name) > -1" class="w-100">
                                            <nz-form-item>
                                                <nz-form-control [nzSm]="24" [nzXs]="24">
                                                    <nz-input-group>
                                                        <nz-select [(ngModel)]="filterReq[a].filterType"
                                                            class="w-100 select_dropdown"
                                                            nzPlaceHolder="Select Condition">
                                                            <nz-option
                                                                *ngFor="let filter of filterSuggestion[filter.dataType]"
                                                                [nzLabel]="filter" [nzValue]="filter">
                                                            </nz-option>
                                                        </nz-select>

                                                        <p class="error_message"
                                                            *ngIf="errorObj.filterType.indexOf(filter.name) > -1 && !filterReq[a].filterType">
                                                            Please select filter type.
                                                        </p>
                                                    </nz-input-group>
                                                </nz-form-control>
                                            </nz-form-item>

                                            <nz-form-item
                                                *ngIf="filterReq[a].filterType != 'is empty' && filterReq[a].filterType != 'is not empty'">
                                                <nz-form-control [nzSm]="24" [nzXs]="24">
                                                    <nz-input-group [ngSwitch]="filter?.dataType">
                                                        <input *ngSwitchCase="'text'"
                                                            [(ngModel)]="filterReq[a].values[0]"
                                                            [ngModelOptions]="{ standalone: true }" autocomplete="off"
                                                            nz-input [placeholder]="'Enter value'" type="text" />

                                                        <input *ngSwitchCase="'email'"
                                                            [(ngModel)]="filterReq[a].values[0]"
                                                            [ngModelOptions]="{ standalone: true }" autocomplete="off"
                                                            nz-input [placeholder]="'Enter value'" type="email" />

                                                        <input *ngSwitchCase="'number'"
                                                            [(ngModel)]="filterReq[a].values[0]"
                                                            [ngModelOptions]="{ standalone: true }" autocomplete="off"
                                                            nz-input [placeholder]="'Enter value'" type="number" />

                                                        <input *ngSwitchCase="'phone-number'"
                                                            [(ngModel)]="filterReq[a].values[0]"
                                                            [ngModelOptions]="{ standalone: true }" autocomplete="off"
                                                            nz-input [placeholder]="'Enter value'" type="number" />

                                                        <nz-select *ngSwitchCase="'select'"
                                                            [(ngModel)]="filterReq[a].values[0]"
                                                            [ngModelOptions]="{ standalone: true }"
                                                            [nzPlaceHolder]="'Select value'" class="w-100">
                                                            <nz-option *ngFor="let option of filter?.acceptedValues"
                                                                [nzLabel]="option" [nzValue]="option"></nz-option>
                                                        </nz-select>

                                                        <nz-select *ngSwitchCase="'multi-select'"
                                                            [(ngModel)]="filterReq[a].values[0]"
                                                            [ngModelOptions]="{ standalone: true }"
                                                            [nzPlaceHolder]="'Select value'" class="w-100">
                                                            <nz-option *ngFor="let option of filter?.acceptedValues"
                                                                [nzLabel]="option" [nzValue]="option"></nz-option>
                                                        </nz-select>

                                                        <nz-select *ngSwitchCase="'switch'"
                                                            [(ngModel)]="filterReq[a].values[0]"
                                                            [ngModelOptions]="{ standalone: true }"
                                                            [nzPlaceHolder]="'Select value'" class="w-100">
                                                            <nz-option [nzLabel]="'True'"
                                                                [nzValue]="'true'"></nz-option>
                                                            <nz-option [nzLabel]="'False'"
                                                                [nzValue]="'false'"></nz-option>
                                                        </nz-select>

                                                        <nz-select *ngSwitchCase="'tags'"
                                                            [(ngModel)]="filterReq[a].values[0]"
                                                            [ngModelOptions]="{ standalone: true }"
                                                            [nzPlaceHolder]="'Select value'" class="w-100">
                                                            <nz-option *ngFor="let tag of allTags" [nzLabel]="tag.name"
                                                                [nzValue]="tag._id">
                                                            </nz-option>
                                                        </nz-select>

                                                        <nz-date-picker class="w-100" *ngSwitchCase="'date'"
                                                            [(ngModel)]="filterReq[a].values[0]"
                                                            [ngModelOptions]="{ standalone: true }" nzShowToday
                                                            nzFormat="dd-MM-yyyy" [nzRenderExtraFooter]="">
                                                        </nz-date-picker>

                                                        <p class="error_message"
                                                            *ngIf="errorObj.values.indexOf(filter.name) > -1 && !filterReq[a].values[0]">
                                                            Please fill filter value.
                                                        </p>
                                                    </nz-input-group>
                                                </nz-form-control>
                                            </nz-form-item>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </div>

                            <nz-collapse class="contacts_filter_collpase">
                                <nz-collapse-panel [nzHeader]="'System Defined Filters'" [nzActive]="true">
                                    <div class="filter_checkbox">
                                        <ng-container *ngFor="let filter of segmentsList | filter : contactsFilter">
                                            <label *ngIf="filter?.type == 'predefined'"
                                                [nzChecked]="appliedPredefined.indexOf(filter._id) != -1" nz-checkbox
                                                (nzCheckedChange)="applyPredefined(filter, $event)">
                                                {{ filter.name }}
                                            </label>
                                        </ng-container>
                                    </div>
                                </nz-collapse-panel>

                                <nz-collapse-panel [nzHeader]="'Filter By Fields'" [nzActive]="true">
                                    <div class="filter_checkbox">
                                        <ng-container
                                            *ngFor="let filter of fieldsForFilter | filter : contactsFilter;let a = index">
                                            <ng-container
                                                *ngIf="!filterApplied || (filterApplied && appliedFiltersSample.indexOf(filter.name) == -1)"
                                                class="">
                                                <label [nzChecked]="appliedFilters.indexOf(filter.name) != -1"
                                                    nz-checkbox
                                                    (nzCheckedChange)="onFilterChecked(filter, a, $event, '')">{{
                                                    filter?.label
                                                    }}</label>
                                                <div *ngIf="appliedFilters.indexOf(filter.name) > -1" class="w-100">
                                                    <nz-form-item>
                                                        <nz-form-control [nzSm]="24" [nzXs]="24">
                                                            <nz-input-group>
                                                                <nz-select [(ngModel)]="filterReq[a].filterType"
                                                                    class="w-100 select_dropdown"
                                                                    nzPlaceHolder="Select Condition">
                                                                    <nz-option
                                                                        *ngFor="let filter of filterSuggestion[filter.dataType]"
                                                                        [nzLabel]="filter" [nzValue]="filter">
                                                                    </nz-option>
                                                                </nz-select>

                                                                <p class="error_message"
                                                                    *ngIf="errorObj.filterType.indexOf(filter.name) > -1 && !filterReq[a].filterType">
                                                                    Please select filter type.
                                                                </p>
                                                            </nz-input-group>
                                                        </nz-form-control>
                                                    </nz-form-item>

                                                    <nz-form-item
                                                        *ngIf="filterReq[a].filterType != 'is empty' && filterReq[a].filterType != 'is not empty'">
                                                        <nz-form-control [nzSm]="24" [nzXs]="24">
                                                            <nz-input-group [ngSwitch]="filter?.dataType">
                                                                <input *ngSwitchCase="'text'"
                                                                    [(ngModel)]="filterReq[a].values[0]"
                                                                    [ngModelOptions]="{ standalone: true }"
                                                                    autocomplete="off" nz-input
                                                                    [placeholder]="'Enter value'" type="text" />

                                                                <input *ngSwitchCase="'email'"
                                                                    [(ngModel)]="filterReq[a].values[0]"
                                                                    [ngModelOptions]="{ standalone: true }"
                                                                    autocomplete="off" nz-input
                                                                    [placeholder]="'Enter value'" type="email" />

                                                                <input *ngSwitchCase="'number'"
                                                                    [(ngModel)]="filterReq[a].values[0]"
                                                                    [ngModelOptions]="{ standalone: true }"
                                                                    autocomplete="off" nz-input
                                                                    [placeholder]="'Enter value'" type="number" />

                                                                <input *ngSwitchCase="'phone-number'"
                                                                    [(ngModel)]="filterReq[a].values[0]"
                                                                    [ngModelOptions]="{ standalone: true }"
                                                                    autocomplete="off" nz-input
                                                                    [placeholder]="'Enter value'" type="number" />

                                                                <nz-select *ngSwitchCase="'select'"
                                                                    [(ngModel)]="filterReq[a].values[0]"
                                                                    [ngModelOptions]="{ standalone: true }"
                                                                    [nzPlaceHolder]="'Select value'" class="w-100">
                                                                    <nz-option
                                                                        *ngFor="let option of filter?.acceptedValues"
                                                                        [nzLabel]="option"
                                                                        [nzValue]="option"></nz-option>
                                                                </nz-select>

                                                                <nz-select *ngSwitchCase="'multi-select'"
                                                                    [(ngModel)]="filterReq[a].values[0]"
                                                                    [ngModelOptions]="{ standalone: true }"
                                                                    [nzPlaceHolder]="'Select value'" class="w-100">
                                                                    <nz-option
                                                                        *ngFor="let option of filter?.acceptedValues"
                                                                        [nzLabel]="option"
                                                                        [nzValue]="option"></nz-option>
                                                                </nz-select>

                                                                <nz-select *ngSwitchCase="'switch'"
                                                                    [(ngModel)]="filterReq[a].values[0]"
                                                                    [ngModelOptions]="{ standalone: true }"
                                                                    [nzPlaceHolder]="'Select value'" class="w-100">
                                                                    <nz-option [nzLabel]="'True'"
                                                                        [nzValue]="'true'"></nz-option>
                                                                    <nz-option [nzLabel]="'False'"
                                                                        [nzValue]="'false'"></nz-option>
                                                                </nz-select>

                                                                <nz-select *ngSwitchCase="'tags'"
                                                                    [(ngModel)]="filterReq[a].values[0]"
                                                                    [ngModelOptions]="{ standalone: true }"
                                                                    [nzPlaceHolder]="'Select value'" class="w-100">
                                                                    <nz-option *ngFor="let tag of allTags"
                                                                        [nzLabel]="tag.name" [nzValue]="tag._id">
                                                                    </nz-option>
                                                                </nz-select>

                                                                <nz-date-picker class="w-100" *ngSwitchCase="'date'"
                                                                    [(ngModel)]="filterReq[a].values[0]"
                                                                    [ngModelOptions]="{ standalone: true }" nzShowToday
                                                                    nzFormat="dd-MM-yyyy" [nzRenderExtraFooter]="">
                                                                </nz-date-picker>

                                                                <p class="error_message"
                                                                    *ngIf="errorObj.values.indexOf(filter.name) > -1 && !filterReq[a].values[0]">
                                                                    Please fill filter value.
                                                                </p>
                                                            </nz-input-group>
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                </nz-collapse-panel>
                            </nz-collapse>

                            <div *ngIf="appliedFilters?.length > 0" class="filter_bottom">
                                <a (click)="clearFilter()" nz-button nzType="default" class="filter_btn_bottom">
                                    <span>Clear Filter</span>
                                </a>
                                <a (click)="directApply()" nz-button nzType="primary" class="filter_btn_bottom">
                                    <span>Apply Filter</span>
                                </a>
                            </div>
                        </div>
                    </nz-card>
                </div>

                <div class="col-9" [ngClass]="{'col-12' : audienceType == 'segment'}">
                    <div class="audience_main_div">
                        <div *ngIf="audienceType == 'segment'" class="text-left segment_select_div mb-3">
                            <nz-select [(ngModel)]="segmentSelected" (ngModelChange)="segmentChange()"
                                [nzPlaceHolder]="'Select Segment'" class="w-100" nzMode="multiple">
                                <nz-option *ngFor="let segment of segmentsList" [nzLabel]="segment.name"
                                    [nzValue]="segment._id"></nz-option>
                            </nz-select>

                            <!-- <label [(ngModel)]="liveSegment" nz-checkbox>
                            Live Segment
                            <span nz-tooltip [nzTooltipTitle]="segmentInfo" class="info_icon" nz-icon
                                nzType="info-circle" nzTheme="outline"></span>
                        </label> -->
                        </div>

                        <div *ngIf="segmentSelected.length > 0 || audienceType == 'direct-customers'"
                            class="total_records">
                            <div class="record_search">
                                <h6>
                                    Total Records ({{ totalCount }})
                                </h6>
                                <div *ngIf="audienceType == 'direct-customers'" class="toggle_options">
                                    <nz-input-group class="search_input_section small_input_sec"
                                        [nzPrefix]="suffixIconSearch">
                                        <input autocomplete="off" [(ngModel)]="searchKey" (keyup)="searchContacts()"
                                            type="text" nz-input placeholder="Search here.." />
                                    </nz-input-group>
                                    <ng-template #suffixIconSearch>
                                        <span nz-icon nzType="search"></span>
                                    </ng-template>
                                </div>
                                <button *ngIf="audienceType == 'direct-customers'" class="segment_btn" nzTrigger="click"
                                    nz-button nz-dropdown [nzDropdownMenu]="segmentsMenu" [nzPlacement]="'bottomLeft'">
                                    {{ selectedTab?.name }}
                                    <span class="d-flex" nz-icon nzType="down"></span>
                                    <!-- <span nz-icon nzType="caret-down" nzTheme="outline"></span> -->
                                </button>
                                <nz-dropdown-menu #segmentsMenu="nzDropdownMenu">
                                    <ul class="segment_menu" nz-menu>
                                        <li [ngClass]="{'selected_segment': selectedTab._id == 'All Contacts'}"
                                            (click)="selectSegment({ name: 'All Contacts', _id: 'All Contacts' })"
                                            nz-menu-item>
                                            All Contacts ({{ allCustomerCount }})
                                        </li>
                                        <ng-container *ngFor="let segment of segmentsList">
                                            <li class="list_options" *ngIf="segment?.type != 'predefined'"
                                                [ngClass]="{selected_segment: selectedTab._id == segment._id}"
                                                (click)="selectSegment(segment)" nz-menu-item>
                                                <span>{{ segment?.name }} ({{ segment?.customersCount }})</span>
                                                <!-- <div class="edit_icon">
                                                <i [nzTooltipTitle]="'Edit'" nzTooltipPlacement="top" nz-tooltip
                                                    (click)="toEditSegment(segment)" class="edit_btn" nz-icon
                                                    nzType="edit" nzTheme="outline"></i>
                                                <i [nzTooltipTitle]="'Delete'" nzTooltipPlacement="top" nz-tooltip
                                                    (click)="showDeleteAlert($event, segment)" class="" nz-icon
                                                    nzType="delete" nzTheme="outline"></i>
                                            </div> -->
                                            </li>
                                        </ng-container>
                                        <!-- <li class="add_segment_link" (click)="addSegment()" nz-menu-item>
                                        Add new segment
                                    </li> -->
                                    </ul>
                                </nz-dropdown-menu>
                            </div>
                            <button class="customized_btn" [ngStyle]="setMyStyles()" nzTrigger="click" nz-dropdown
                                [nzDropdownMenu]="paginationMenu" [nzPlacement]="'bottomLeft'">
                                {{ pageSize + " records per page" }}
                            </button>

                            <nz-dropdown-menu #paginationMenu="nzDropdownMenu">
                                <ul nz-menu>
                                    <li (click)="changePageSize((n + 1) * 10)"
                                        *ngFor="let page of [].constructor(5); let n = index" nz-menu-item>
                                        {{ (n + 1) * 10 + " records per page" }}
                                    </li>
                                </ul>
                            </nz-dropdown-menu>
                        </div>
                        <nz-table class="contacts_table table_in_modal"
                            *ngIf="segmentSelected.length > 0 || audienceType == 'direct-customers'" #userListTable
                            [nzData]="userList?.customers" [nzScroll]="{ y: tableHeight, x : '750px' }"
                            [nzFrontPagination]="false" [nzTotal]="totalCount" [nzPageSize]="pageSize"
                            [nzPageIndex]="pageIndex" (nzQueryParams)="onPageNumberChange('categoryTable' , $event)"
                            [nzPaginationPosition]="'top'" [nzSimple]="true">
                            <thead>
                                <tr>
                                    <th *ngIf="audienceType == 'direct-customers'" nzWidth="20px"
                                        [(nzChecked)]="checked" (nzCheckedChange)="onAllChecked($event)">
                                    </th>
                                    <th *ngFor="let column of orderColumn" [nzWidth]="column.width">{{
                                        column.title
                                        }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr id="categoryTable" *ngFor="let item of userListTable.data ;  let i=index">
                                    <td *ngIf="audienceType == 'direct-customers'"
                                        [nzChecked]="selectedAudience.indexOf(item._id) > -1"
                                        (nzCheckedChange)="onItemChecked(item)"></td>
                                    <!-- <td>{{i+1}}</td> -->
                                    <td>
                                        <div (click)="updateCategory(item)" class="">
                                            {{item.name}}
                                        </div>
                                    </td>
                                    <td>
                                        {{item.phoneNo}}
                                    </td>
                                    <td>
                                        {{moment(item.createdAt).format('DD-MM-YYYY hh:mm A')}}
                                    </td>
                                </tr>
                                <ngx-spinner *ngIf="customerFilter" bdColor="rgb(255,255,255)" size="medium"
                                    color="#000000" type="ball-clip-rotate-pulse" [fullScreen]="false">
                                </ngx-spinner>
                            </tbody>
                        </nz-table>

                        <div class="choose_button mt-3">
                            <button [disabled]="segmentSelected.length == 0 && selectedAudience.length == 0"
                                (click)="closeAudienceModal()" type="button" class="sighup_button " nz-button
                                nzType="primary">
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</nz-modal>

<!-- AUDIENCE SELECTION MODAL ENDS -->

<!-- ERROR MODAL SECTION STARTS -->

<nz-modal [(nzVisible)]="warningModal" [nzFooter]="null" nzTitle="Please Check The Following Data" nzCentered
    (nzOnCancel)="closeWarningModal()">
    <ng-container *nzModalContent>
        <p *ngFor="let error of errorMessages">{{error}}</p>
    </ng-container>
</nz-modal>

<!-- ERROR MODAL SECTION STARTS -->

<!-- TEMPLATE PREVIEW MODAL STARTS -->

<nz-modal [(nzVisible)]="templatePreview" [nzWidth]="previewModalWidth" [nzFooter]="null" nzTitle="Template Preview"
    nzCentered (nzOnCancel)="closeTemplatePreview()">
    <ng-container *nzModalContent>

        <div class="template_col">
            <div class="preview_heading">
                <h5 [nzTooltipTitle]="messageTemplateSelected.name" nzTooltipPlacement="top" nz-tooltip>
                    {{messageTemplateSelected.name.length > 23 ?
                    messageTemplateSelected.name.slice(0,23) + '...' : messageTemplateSelected.name}}
                </h5>
            </div>
            <div class="preview_template">
                <div class="inner_template">
                    <div *ngFor="let message of messageTemplateSelected.components" class="mb-3">
                        <div [ngSwitch]="message?.type" class="">
                            <div *ngSwitchCase="'HEADER'" class="">
                                <div [ngSwitch]="message?.format" class="header_text">
                                    <p *ngSwitchCase="'TEXT'" [innerHTML]="message.text"></p>
                                    <p class="img_url" *ngSwitchCase="'IMAGE'">
                                        <span nz-icon nzType="file-image" nzTheme="outline"></span>
                                        IMAGE
                                    </p>
                                    <p class="img_url" *ngSwitchCase="'VIDEO'">
                                        <span nz-icon nzType="video-camera" nzTheme="outline"></span>
                                        VIDEO
                                    </p>
                                    <p class="img_url" *ngSwitchCase="'DOCUMENT'">
                                        <span nz-icon nzType="file-done" nzTheme="outline"></span>
                                        DOCUMENT
                                    </p>
                                </div>

                            </div>
                            <div *ngSwitchCase="'BODY'" class="">
                                <p [innerHTML]="((message?.text))"></p>
                            </div>
                            <div *ngSwitchCase="'FOOTER'" class="">
                                <p [innerHTML]="message?.text"></p>
                            </div>
                            <div *ngSwitchCase="'BUTTONS'" class="">
                                <div *ngFor="let button of message?.buttons" class="template_buttons">
                                    <h6>{{button?.text}}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="template_bottom">
                <div class="bottom_left">
                    <span class="template_lang">{{messageTemplateSelected?.language}}</span>
                </div>
                <span class="template_status">approved</span>
            </div>
        </div>

    </ng-container>
</nz-modal>

<!-- TEMPLATE PREVIEW MODAL ENDS -->