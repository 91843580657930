<div class="form-body on-top-mobile">
  <!-- <div class="website-logo">
        <a href="index.html">
            <div class="logo">
                <img class="logo-size" src="../../../assets/images/logo/your-logo.png" alt="">
            </div>
        </a>
    </div> -->
  <div class="row">
    <div class="col-lg-6 login_slider">
      <nz-carousel [nzEffect]="'scrollx'" nzAutoPlay>
        <div nz-carousel-content *ngFor="let image of loginBanners" class="slider_main_section">
          <div class="slider_inner_section">
            <div [ngStyle]="{ 'background-image': 'url(' + image + ')' }" class="carousal_container"></div>
            <!-- <img [src]="image" alt=""> -->
          </div>
        </div>
      </nz-carousel>
    </div>
    <!-- success message -->
    <div *ngIf="pageName == 'signup-success'" class="form-holder col-lg-5">
      <div class="form-content">
        <div class="form-items text-center">
          <div class="heading_section">
            <img class="mb-3" src="../../../assets/images/signup/signup_success.gif" alt="" />
            <h4 class="mb-3">Thank you for signing up</h4>
          </div>
        </div>
      </div>
    </div>

    <!-- otp input -->
    <!-- <div *ngIf="pageName == 'otp-verification'" class="form-holder col-lg-5">
            <div class="form-content">
                <div class="form-items text-center">
                    <div class="heading_section otp_input_section text-left">
                        <h4 class="mb-3">OTP Verification</h4>
                        <p>We have send you 6-digit One Time Password to your register email address or mobile number
                        </p>
                        <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="config">
                        </ng-otp-input>
                        <div class="text-right">
                            <span class="time_left">{{moment()
                                .startOf("day")
                                .seconds(resendTime)
                                .format("mm:ss")}}</span>
                        </div>

                        <nz-form-item class="justify-content-center mt-3">
                            <span class="resend_code mb-3">Resend Code</span>
                            <nz-input-group>
                                <button nz-button nzType="primary" class="login-form-button w-100">Submit</button>
                            </nz-input-group>
                        </nz-form-item>
                    </div>
                </div>
            </div>
        </div> -->

    <!-- password input -->
    <div *ngIf="pageName == 'create-password'" class="form-holder col-lg-5">
      <div class="form-content new_form">
        <div class="form-items text-center">
          <div class="heading_section otp_input_section text-left">


            <div *ngIf="!otpVerified" class="">
              <h4 class="mb-3">
                Almost done!
              </h4>

              <p>
                {{
                recoveryData
                ? "We've sent an OTP to the email you provided. " +
                "Just enter it here to reset your password."
                : "We've sent an OTP to the email you provided. " +
                "Just enter it here to complete the signup process."
                }}
              </p>

              <div class="mb-3">
                <nz-form-label class="large_label" nzFor="" [nzSm]="24" [nzXs]="24">
                  Enter OTP
                </nz-form-label>
                <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="config">
                </ng-otp-input>
                <p class="resend_section">Not receive your code? <span (click)="resendCode()">Resend code</span></p>
                <ngx-spinner bdColor="rgb(255,255,255)" size="medium" color="#000000" type="ball-clip-rotate-pulse"
                  [fullScreen]="false">
                </ngx-spinner>
              </div>
            </div>

            <div *ngIf="otpVerified" class="">
              <h4 class="mb-3">
                {{ recoveryData ? "Create New Password" : "Create Password" }}
              </h4>

              <p>
                Create a password that is at least 8 characters long, includes a mix of uppercase and lowercase letters,
                numbers, and special characters, and avoid common phrases or personal information for better security.
              </p>

              <form [formGroup]="setPasswordForm" (ngSubmit)="submitForm()" nz-form>
                <!-- <nz-form-item>
                  <nz-form-label class="large_label" nzFor="" [nzSm]="24" [nzXs]="24">
                    OTP
                  </nz-form-label>
                  <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please Enter 6-digit OTP!">
                    <nz-input-group [nzSuffix]="visibleIcon3">
                      <input autocomplete="new-password" type="tel" formControlName="otpNumber" nz-input
                        placeholder="Enter 6-digit OTP" maxlength="6"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" />
                    </nz-input-group>
                    <ng-template #visibleIcon3> </ng-template>
                  </nz-form-control>
                </nz-form-item> -->

                <nz-form-item>
                  <nz-form-label class="large_label" nzFor="" [nzSm]="24" [nzXs]="24">
                    New Password
                  </nz-form-label>
                  <nz-form-control [nzSm]="24" [nzXs]="24"
                    nzErrorTip="Your password must be a minimum of 8 characters in length.!">
                    <nz-input-group [nzSuffix]="visibleIcon1">
                      <input autocomplete="new-password" [type]="newPasswordVisible ? 'text' : 'password'"
                        formControlName="newPassword" (keyup)="verifyPasswords('new')" nz-input
                        placeholder="Enter new password" />
                    </nz-input-group>
                    <ng-template #visibleIcon1>
                      <i nz-icon [nzType]="newPasswordVisible ? 'eye' : 'eye-invisible'"
                        (click)="newPasswordVisible = !newPasswordVisible"></i>
                    </ng-template>
                  </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                  <nz-form-label class="large_label" nzFor="" [nzSm]="24" [nzXs]="24">
                    Confirm Password
                  </nz-form-label>
                  <nz-form-control [nzSm]="24" [nzXs]="24"
                    nzErrorTip="Your password must be a minimum of 8 characters in length.!">
                    <nz-input-group [ngClass]="{ incorrect_message: passwordIncorrect }" [nzSuffix]="visibleIcon2">
                      <input autocomplete="new-password" [type]="confirmPasswordVisible ? 'text' : 'password'"
                        formControlName="confirmPassword" (keyup)="verifyPasswords('confirm')" nz-input
                        placeholder="Confirm password" />
                    </nz-input-group>
                    <p class="error_text" *ngIf="passwordIncorrect && setPasswordForm.valid">
                      The confirm password field does not match the entered password.
                    </p>
                    <ng-template #visibleIcon2>
                      <i nz-icon [nzType]="
                          confirmPasswordVisible ? 'eye' : 'eye-invisible'
                        " (click)="confirmPasswordVisible = !confirmPasswordVisible"></i>
                    </ng-template>
                  </nz-form-control>
                </nz-form-item>

                <nz-form-item class="justify-content-center mt-3">
                  <nz-input-group>
                    <button [nzLoading]="creatingPassword" nz-button nzType="primary" class="login-form-button w-100">
                      Submit
                    </button>
                  </nz-input-group>
                </nz-form-item>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- forgot password -->
    <div *ngIf="pageName == 'forgot-password'" class="form-holder col-lg-5">
      <div class="form-content new_form">
        <div class="form-items text-center">
          <div class="heading_section otp_input_section text-left">
            <h4 class="mb-3">Forgot Password</h4>
            <p>
              Enter your email , we'll send you the Verification Code to reset
              your password
            </p>

            <form [formGroup]="mailForm" (ngSubmit)="checkMail()" nz-form>
              <nz-form-item>
                <nz-form-label class="large_label" nzFor="" [nzSm]="24" [nzXs]="24">
                  Email
                </nz-form-label>
                <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please Enter Email ">
                  <nz-input-group [nzSuffix]="visibleIcon3">
                    <input (keyup)="formatMailId()" autocomplete="off" type="text" formControlName="emailOrPhoneNo"
                      nz-input placeholder="Enter Email " />
                  </nz-input-group>
                  <ng-template #visibleIcon3> </ng-template>
                </nz-form-control>
              </nz-form-item>

              <nz-form-item class="justify-content-center mt-3">
                <nz-input-group>
                  <button [nzLoading]="checkingEmail" type="submit" nz-button nzType="primary"
                    class="login-form-button w-100">
                    Send Code
                  </button>
                </nz-input-group>
                <span routerLink="/login" class="resend_code mt-3"><span class="left_arrow" nz-icon nzType="left"
                    nzTheme="outline"></span>
                  Back to login</span>
              </nz-form-item>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="pageName == 'direct-mode-create' || pageName == 'direct-mode-forgot'" class="form-holder col-lg-5">
      <div class="form-content direct_section">
        <div class="form-items text-center">
          <ng-container [ngSwitch]="reqStatus">
            <!-- otp verified success section -->
            <div *ngSwitchCase="'uBqZ810ByQXllUbY87IRepICb'" class="heading_section">
              <img class="mb-3" src="../../../assets/images/signup/otp_verified.gif" alt="" />
              <h4 class="mb-3">OTP Verified Successfully</h4>
            </div>
            <!-- Link expired section -->
            <div *ngSwitchCase="'E2aTL4Kc0keH7LJcWf4x7-o8x'" class="heading_section">
              <img class="mb-3" src="../../../assets/images/signup/verification_failed.gif" alt="" />
              <h4 class="mb-3">Link Expired</h4>
              <button (click)="backToForm()" type="button" nz-button nzType="primary" class="login-form-button w-100">
                Back to form
              </button>
            </div>
            <!-- invalid link section -->
            <div *ngSwitchCase="'LdavDaa9LnFDc4kDyq78Puzpd'" class="heading_section">
              <img class="mb-3" src="../../../assets/images/signup/verification_failed.gif" alt="" />
              <h4 class="mb-3">Invalid Link</h4>
              <button (click)="backToForm()" type="button" nz-button nzType="primary" class="login-form-button w-100">
                Back to form
              </button>
            </div>
            <!-- user does not exist section -->
            <div *ngSwitchCase="'M0TJlzKIpokOrnClUgyxGcYct'" class="heading_section">
              <img class="mb-3" src="../../../assets/images/signup/verification_failed.gif" alt="" />
              <h4 class="mb-3">User does not exist</h4>
              <button (click)="backToForm()" type="button" nz-button nzType="primary" class="login-form-button w-100">
                Back to form
              </button>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>