<div class="row overflow_row_mobile">
    <div class="col-12 main_outer_heading d_mobile_none">
        <h3>
            <span routerLink="/chat-bot" nz-icon nzType="left" nzTheme="outline"></span>
            Bot Templates
        </h3>
    </div>

    <div class="col-3">
        <nz-card>
            <h4>Industries</h4>
            <ul class="bot-template-list">
                <li (click)="selectCategory('all')" [ngClass]="{'active_menu' : selectedCategory == 'all'}">All</li>
                <li (click)="selectCategory(category.id)" [ngClass]="{'active_menu' : category.id == selectedCategory}"
                    *ngFor="let category of allCategories">{{category.name}}</li>
            </ul>
        </nz-card>
    </div>

    <div class="col-9 right_col">
        <div class="row">
            <div class="col-12">
                <div class="search_add_div">
                    <div class="toggle_options">
                        <nz-input-group class="search_input_section small_input_sec" [nzPrefix]="suffixIconSearch">
                            <input autocomplete="off" type="text" nz-input placeholder="Search here.." />
                        </nz-input-group>
                        <ng-template #suffixIconSearch>
                            <span nz-icon nzType="search"></span>
                        </ng-template>
                    </div>
                </div>
            </div>

            <div class="row templates_scroll">
                <div *ngFor="let template of allTemplates" class="col-4">
                    <nz-card class="template_card">
                        <div class="bot_template_card">
                            <div class="template-image-div">
                                <img class="template_image" [src]="template.image" alt="">
                                <div class="overflow_container"></div>
                            </div>

                            <div class="bot-template-details">
                                <h5 class="">{{template.name}}</h5>
                                <span class="template_description">
                                    {{template.description.length > 80 ?
                                    (template.description.slice(0,80) + '...') :
                                    template.description}}</span>
                                <div class="category_name">
                                    <p class="">{{template.category}}</p>
                                </div>

                                <div class="use-button">
                                    <span></span>
                                    <button class="secondary_btn" nz-button>Use</button>
                                </div>
                            </div>
                        </div>
                    </nz-card>
                </div>

                <ngx-spinner bdColor="rgb(255,255,255)" size="medium" color="#000000" type="ball-clip-rotate-pulse"
                    [fullScreen]="false"></ngx-spinner>
            </div>
        </div>
    </div>
</div>