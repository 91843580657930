import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
// import * as io from "socket.io-client";
import { io } from "socket.io-client";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class WebSocketServiceService {
  socket: any;
  readonly url: string =
    environment.url + "?token=" + localStorage.getItem("qwertrewetrt");

  constructor() {
    this.socket = io(this.url, {
      transports: ["websocket"],
      withCredentials: true,
    });
  }

  listen(eventName: any) {
    return new Observable((subscriber) => {
      this.socket.on(eventName, (data) => {
        subscriber.next(data);
      });
    });
  }

  offSocket(eventName: any) {
    this.socket.removeListener(eventName);
  }

  emit(eventName: any, data: any) {
    this.socket.emit(eventName, data);
  }
}
