import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { CommonApiService } from "src/app/services/common-api.service";
import { environment } from "src/environments/environment";
import {
  countries,
  countryCodes,
  currency,
  timeZone,
} from "../../dashboard/chat/shared/messageData";

import * as moment from "moment";
import { AlertServiceService } from "src/app/services/alert-service.service";
import { NavService } from "src/app/services/nav.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-account-details",
  templateUrl: "./account-details.component.html",
  styleUrls: ["./account-details.component.css"],
})
export class AccountDetailsComponent implements OnInit {
  moment: any = moment;
  accountSettingForm!: FormGroup;
  previewImage: any = "";
  selectedMedia: any;
  updating: boolean = false;

  // pre loaded data
  countries: any = countries;

  currency: any = ["INR", "USD"];

  timeZone: any = timeZone;

  industry: any = [
    "Home & Garden",
    "HR Consultants",
    "Media",
    "NGO",
    "Real Estate",
    "Religious & Ceremonial",
    "Software",
    "Sporting Goods",
    "Tax Consultants",
    "Tours & Travel",
    "Vehicles & Parts",
    "Textiles",
    "Food",
    "Jewellery",
    "Beauty Care",
    "Automobile showroom",
    "Supermarkets",
    "Medical",
    "Hospital",
    "Others",
  ];

  noOfEmp: any = [
    "1 - 10",
    "11 - 20",
    "21 - 30",
    "31 - 40",
    "41 - 50",
    "51 - 60",
    "61 - 70",
    "71 - 80",
    "81 - 90",
    "91 - 100",
    "101 - 150",
    "151 - 200",
    "201 - 250",
    "251 - 300",
    "301 - 350",
    "351 - 400",
    "401 - 450",
    "451 - 500",
  ];

  dialCode: any = "91";

  countryCodes: any = countryCodes;

  accountDetails: any = {};

  mainUserData: any;

  imageTypes = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    // "image/webp",
    // "image/svg+xml",
  ];

  private subscription: Subscription;

  constructor(
    private fb: FormBuilder,
    private apiService: CommonApiService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private navService: NavService,
    private alertService: AlertServiceService
  ) {}

  ngOnInit(): void {
    this.getMainUserData();
    this.accountSettingForm = this.fb.group({
      website: ["", Validators.required],
      countryCode: ["", Validators.required],
      mobile: [null, Validators.required],
      country: ["", Validators.required],
      industry: ["", Validators.required],
      currency: ["", Validators.required],
      noOfEmployees: ["", Validators.required],
      timeZone: ["", Validators.required],
      businessName: ["", Validators.required],
      addressLine1: ["", Validators.required],
      addressLine2: ["", Validators.required],
      city: ["", Validators.required],
      district: ["", Validators.required],
      state: ["", Validators.required],
      zipCode: ["", Validators.required],
      // separateAddress: [false, Validators.required],
      // paymentAddress: [""],
      taxId: [""],
      officialWhatsappCountryCode: ["", Validators.required],
      officialWhatsappNumber: ["", Validators.required],
    });
    this.getAccountDetails();
  }

  getMainUserData() {
    this.subscription = this.navService.mainUserData.subscribe(
      (response: any) => {
        if (response) {
          this.mainUserData = response.data.userData;
        }
      }
    );
  }

  getAccountDetails() {
    this.spinner.show();
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url + "organizations/get-account-details",
        {},
        options
      )
      .subscribe(
        (response: any) => {
          // console.log(response);
          this.accountDetails = response?.data?.organization;
          this.setFormValue();
        },
        (error) => {
          console.log(error);
          this.spinner.hide();
        }
      );
  }

  setFormValue() {
    this.accountSettingForm.reset();
    this.accountSettingForm.patchValue({
      website: this?.accountDetails?.website,
      countryCode: this?.accountDetails?.contact?.countryCode,
      mobile: this?.accountDetails?.contact?.mobileNumber,
      officialWhatsappCountryCode:
        this?.accountDetails?.officialWhatsapp?.countryCode,
      officialWhatsappNumber:
        this?.accountDetails?.officialWhatsapp?.mobileNumber,
      country: this?.accountDetails?.country,
      industry: this?.accountDetails?.industry,
      currency: this?.accountDetails?.currency,
      noOfEmployees: this?.accountDetails?.numberOfEmployees,
      timeZone: this?.accountDetails?.timeZone,
      businessName: this?.accountDetails?.name,
      addressLine1: this?.accountDetails?.addressLine1,
      addressLine2: this?.accountDetails?.addressLine2,
      city: this?.accountDetails?.city,
      district: this?.accountDetails?.district,
      state: this?.accountDetails?.state,
      zipCode: this?.accountDetails?.zipCode,
      // separateAddress: this?.accountDetails
      //   ?.isSeparateAddressForReceivingPayments
      //   ? true
      //   : false,
      // paymentAddress: this?.accountDetails?.paymentAddress,
      taxId: this?.accountDetails?.taxId,
    });
    this.previewImage = this.accountDetails?.logo
      ? environment.imageUrl + this?.accountDetails?.logo
      : "";
    // console.log(this.accountSettingForm.value);
    this.spinner.hide();
  }

  getFile(event: any) {
    // console.log(event.target.files[0]);
    this.selectedMedia = event.target.files[0];
    const file = event.target.files[0];

    const reader = new FileReader();
    reader.onload = (e) => (this.previewImage = reader.result);
    reader.readAsDataURL(file);

    event.target.value = "";
  }

  updateAccountDetails() {
    // if (
    //   !this.mainUserData?.accessTo?.isExpired &&
    //   !this.mainUserData?.accessTo?.sandboxId
    // ) {
    // if (this.accountSettingForm.value.separateAddress) {
    //   this.accountSettingForm
    //     .get("paymentAddress")
    //     .setValidators(Validators.required);
    // } else {
    //   this.accountSettingForm.get("paymentAddress").clearValidators();
    // }
    // this.accountSettingForm.get("paymentAddress").updateValueAndValidity();
    if (this.accountSettingForm.valid) {
      this.updating = true;
      let options = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
        },
      };

      var data = new FormData();

      var contact: any = {
        countryCode: this?.accountSettingForm?.value?.countryCode,
        mobileNumber: this?.accountSettingForm?.value?.mobile,
      };
      data.append("contact", JSON.stringify(contact));

      var offWhatsapp: any = {
        countryCode:
          this?.accountSettingForm?.value?.officialWhatsappCountryCode,
        mobileNumber: this?.accountSettingForm?.value?.officialWhatsappNumber,
      };

      offWhatsapp.fullNumber =
        offWhatsapp.countryCode + offWhatsapp.mobileNumber;

      data.append("officialWhatsapp", JSON.stringify(offWhatsapp));

      data.append("website", this.accountSettingForm.value.website);
      // data.append(
      //   "contact.countryCode",
      //   this.accountSettingForm.value.countryCode
      // );
      // data.append("contact.mobileNumber", this.accountSettingForm.value.mobile);
      data.append("country", this.accountSettingForm.value.country);
      data.append("industry", this.accountSettingForm.value.industry);
      data.append("currency", this.accountSettingForm.value.currency);
      data.append(
        "numberOfEmployees",
        this.accountSettingForm.value.noOfEmployees
      );
      data.append("timeZone", this.accountSettingForm.value.timeZone);
      data.append("name", this.accountSettingForm.value.businessName);

      data.append("addressLine1", this.accountSettingForm.value.addressLine1);
      data.append("addressLine2", this.accountSettingForm.value.addressLine2);
      data.append("city", this.accountSettingForm.value.city);
      data.append("district", this.accountSettingForm.value.district);
      data.append("state", this.accountSettingForm.value.state);
      data.append("zipCode", this.accountSettingForm.value.zipCode);
      data.append("taxId", this.accountSettingForm.value?.taxId);
      // data.append(
      //   "isSeparateAddressForReceivingPayments",
      //   this.accountSettingForm.value.separateAddress
      // );
      // if (this.accountSettingForm.value.separateAddress) {
      //   data.append(
      //     "paymentAddress",
      //     this.accountSettingForm.value.paymentAddress
      //   );
      // }

      if (this.selectedMedia) {
        data.append("logo", this.selectedMedia);
      }

      this.apiService
        .commonPostMethod(
          environment.url + "organizations/update",
          data,
          options
        )
        .subscribe(
          (response: any) => {
            // console.log(response);
            this.getAccountDetails();
            this.toastr.success(response?.message, "", {
              timeOut: 2500,
            });
            this.updating = false;
          },
          (error) => {
            console.log(error);
            if (error?.error?.message) {
              this.toastr.error(error?.error?.message, "", {
                timeOut: 2500,
              });
            } else {
              this.toastr.error(error?.message, "", {
                timeOut: 2500,
              });
            }
            this.updating = false;
          }
        );
    } else {
      Object.values(this.accountSettingForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
    // } else if (this.mainUserData?.accessTo?.sandboxId) {
    //   var errHead: any = "You are using Sandbox account!";
    //   var errBody: any = "Please Subscribe any Plan to Update!..";
    //   this.alertService.freeTrialWarning(errHead, errBody);
    // }
  }

  ngOnDestroy(): void {
    // console.log("api key destroy..");
    this.subscription.unsubscribe();
  }
}
