<!-- <div class="expiry_alert_section">
    <div class="expiry_alert_second_section">
        <p class="">
            Now experience the full range of ownchat platform features without the hassle of connecting your own number
        </p>

        <div class="get_number_btn">
            <button type="button" class="own_number_btn">
                How to connect <span nz-icon nzType="caret-right" nzTheme="outline"></span>
            </button>
        </div>
    </div>
</div> -->

<!-- body section -->

<div class="row justify-content-center journey_main_row">
    <div class="col-11">
        <div class="row">
            <div class="col-12 main_outer_heading  mb-3">
                <h3>👋 Hello, {{userData?.firstName + (userData?.lastName ? ' ' + userData?.lastName : '')}}. Welcome To
                    Ownchat</h3>
            </div>

            <div class="col-12">
                <nz-card class="journey_nz_card">
                    <div class="row header_row">
                        <div class="col-lg-6 ">
                            <div class="text-left">
                                <h5>
                                    <img class="bell_icon" src="../../../assets/images/logo/bell_icon.png" alt="">
                                    You Have 7 Days Left In Your Free Trial
                                </h5>
                            </div>
                        </div>

                        <div class="col-lg-6 text-right">
                            <div class="search_add_div">
                                <a (click)="arrangeMeeting()" nz-button nzType="default"
                                    class="create-button expert_btn">
                                    <span>Connect With Our Expert</span>
                                </a>
                                <a (click)="ownNumber()" nz-button nzType="primary" class="create-button">
                                    <span>Connect Your Own Number</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </nz-card>
            </div>


            <div class="col-12">
                <!-- <nz-card class=""> -->
                <div class="row">
                    <div class="col-lg-6">
                        <div class="fixed_height_journey left_menu">
                            <div class="video_div" style="height: 60%;">
                                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/c8vofXytweE"
                                    title="YouTube video player" frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowfullscreen></iframe>
                            </div>
                            <div class="unlock_div">
                                <h5>Unlock the full potential of your business with ownchat the ultimate customer
                                    engagement solution</h5>
                                <div class="search_add_div mt-3">
                                    <a nz-button nzType="primary" class="create-button help_btn">
                                        <span>Need Help?</span>
                                    </a>
                                    <a nz-button nzType="default" class="create-button expert_btn">
                                        <span>Upgrade Now
                                            <img src="../../../assets/images/logo/crown.svg" alt="">
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="right_menu_card fixed_height_journey">
                            <div class="right_menu_heading">
                                <h5>Start with a DEMO Number</h5>
                                <p>Follow the steps below to connect with our
                                    <span class="number_text">TEST MOBILE NUMBER</span>
                                </p>
                                <p>You must send the WhatsApp message from your registered number
                                    <span class="test_number">{{userData?.accessTo?.phoneNumber ?
                                        userData?.accessTo?.phoneNumber : '+91 8015188187'}}</span>
                                </p>
                            </div>

                            <div class="qr_message_section mb-3">
                                <div class="qr_code_div">
                                    <qrcode-svg class="qr_code"
                                        [value]="'https://api.whatsapp.com/send?phone=' + (userData?.accessTo?.phoneNumber ?
                                        (userData?.accessTo?.phoneNumber).replaceAll(' ' , '%20') : '+91%8015188187') +'&text=SandBox%20code%20' + this.sandBoxCode"
                                        [color]="'#000'">
                                    </qrcode-svg>
                                </div>
                                <div class="">
                                    <h5>Scan the QR Code</h5>
                                    <p>Your phone will open a pre-filled message to send to our sandbox number. Hit
                                        'send' on
                                        Whatsapp
                                    </p>
                                </div>
                            </div>

                            <div class="middle_or_section">
                                <span></span>
                                <p>OR</p>
                                <span></span>
                            </div>

                            <div class="direct_message_section">
                                <div class="whatsapp_image">
                                    <img src="../../../assets/images/logo/new_whatsapp.svg" alt="">
                                </div>
                                <div class="whatsapp_message_section">
                                    <h5>Send a Message</h5>
                                    <p>Send us a WhatsApp message on {{userData?.accessTo?.phoneNumber ?
                                        userData?.accessTo?.phoneNumber : '+91 8015188187'}} by saving in your contacts
                                        and
                                        message
                                        should start with {{sandBoxCode?.replaceAll("%23", "#")}}</p>
                                    <a target="_blank"
                                        [href]="'https://api.whatsapp.com/send?phone=' + (userData?.accessTo?.phoneNumber ?
                                        userData?.accessTo?.phoneNumber : '8015188187') +'&text=SandBox code ' + this.sandBoxCode"
                                        nz-button nzType="primary" class="create-button">
                                        <span>WhatsApp
                                            <img src="../../../assets/images/logo/open_in_new.svg" alt="">
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- </nz-card> -->
            </div>
        </div>
    </div>
</div>