<div class="row overflow_row_mobile">
    <div class="col-12 main_outer_heading">
        <h3>
            <span routerLink="/chat-bot" nz-icon nzType="left" nzTheme="outline"></span>
            Paths
        </h3>
    </div>

    <div class="col-12">
        <nz-card class="contacts_top_card">
            <div class="contacts_top_bar">
                <div class="table_top_heading">
                    <h4>{{botData.name}}</h4>
                </div>
                <div class="search_add_div">
                    <div class="toggle_options">
                        <nz-input-group class="search_input_section small_input_sec" [nzPrefix]="suffixIconSearch">
                            <input autocomplete="off" [(ngModel)]="searchKey" (keyup)="searchContacts()" type="text"
                                nz-input placeholder="Search here.." />
                        </nz-input-group>
                        <ng-template #suffixIconSearch>
                            <span nz-icon nzType="search"></span>
                        </ng-template>
                    </div>
                    <a [disabled]="mainUserData?.accessTo?.isExpired" (click)="showFlowForm()" nz-button
                        nzType="primary" class="create-button">
                        <i nz-icon nzType="plus-circle" theme="outline"></i>
                        <span>Create New Path</span>
                    </a>
                </div>
            </div>
        </nz-card>
    </div>


    <div class="col-12" id="main-section">
        <nz-card class="customer_right_card customers_table_card">
            <div class="total_records">
                <h6>Total Records ({{ totalCount }})</h6>

                <button class="customized_btn" [ngStyle]="setMyStyles()" nzTrigger="click" nz-dropdown
                    [nzDropdownMenu]="paginationMenu" [nzPlacement]="'bottomLeft'">
                    {{ pageSize + " records per page" }}
                </button>

                <nz-dropdown-menu #paginationMenu="nzDropdownMenu">
                    <ul nz-menu>
                        <li (click)="changePageSize((n + 1) * 10)" *ngFor="let page of [].constructor(5); let n = index"
                            nz-menu-item>
                            {{ (n + 1) * 10 + " records per page" }}
                        </li>
                    </ul>
                </nz-dropdown-menu>
            </div>

            <nz-table class="contacts_table main_contacts_table" #allBotsTable [nzData]="allFlows"
                [nzScroll]="{ y: tableHeight, x: '900px' }" [nzFrontPagination]="false" [nzTotal]="totalCount"
                [nzPageSize]="pageSize" [nzPageIndex]="pageIndex"
                (nzQueryParams)="onPageNumberChange('flowsList', $event)" [nzPaginationPosition]="'top'"
                [nzSimple]="true">
                <thead>
                    <tr>
                        <th *ngFor="let column of orderColumn" [nzWidth]="column.width">
                            {{ column.title }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr id="flowsList" *ngFor="let item of allBotsTable.data; let i = index">
                        <td>
                            <a [routerLink]="['/chat-bot/' , botData._id , item._id]" target="_blank" class="edit_link">
                                {{ item?.name }}
                                <span *ngIf="item.isThisStartPath" nz-tooltip nzTooltipTitle="Start path!" nz-icon
                                    nzType="play-circle" nzTheme="outline">
                                </span>
                            </a>
                        </td>
                        <td>
                            {{item.createdBy?.firstName + ' ' +
                            (item.createdBy?.lastName ? item.createdBy?.lastName : '')}}
                        </td>
                        <td>
                            {{ moment(item?.lastUpdatedAt)?.format("DD-MM-YYYY hh:mm A") }}
                        </td>
                        <!-- <td>
                            <span class="unpublished" [ngClass]="{'published' : item.status == 'Published'}">
                                {{item.status}}
                            </span>
                        </td> -->
                        <td>
                            <div class="edit_delete_links">
                                <span class="edit_icon" (click)="showEditFlowForm(item)" nz-icon nzType="edit"
                                    nzTheme="outline"></span>
                                <span class="edit_icon" (click)="deleteAlert(item)" nz-icon nzType="delete"
                                    nzTheme="outline"></span>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <ngx-spinner bdColor="rgb(255,255,255)" size="medium" color="#000000" type="ball-clip-rotate-pulse"
                    [fullScreen]="false"></ngx-spinner>
            </nz-table>
        </nz-card>
    </div>
</div>

<!-- create edit form section starts -->

<nz-drawer [nzWidth]="'40%'" [nzClosable]="true" [nzVisible]="flowFormDrawer" [nzPlacement]="'right'"
    [nzTitle]="'Create new Path'" (nzOnClose)="closeFlowFormDrawer()">
    <ng-container *nzDrawerContent>
        <div class="">
            <form [formGroup]="flowForm" (ngSubmit)="createUpdateFlow()" nz-form>
                <nz-form-item>
                    <nz-form-label class="large_label" nzRequired [nzSm]="24" [nzXs]="24">
                        Path name
                    </nz-form-label>
                    <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please enter path name!">
                        <nz-input-group>
                            <input autocomplete="off" formControlName="flowName" type="text" nz-input
                                placeholder="Enter path for bot" required>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label class="large_label center_label" nzRequired [nzSm]="12" [nzXs]="12">
                        Is This Start Path
                    </nz-form-label>
                    <nz-form-control [nzSm]="12" [nzXs]="12">
                        <nz-input-group class="right_input">
                            <nz-switch formControlName="isThisStartPath"></nz-switch>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item class="submit_section mt-3">
                    <button [nzLoading]="submitting" type="submit" class="sighup_button w-100" nz-button
                        nzType="primary">
                        {{isEdit ? 'Update' : 'Create'}}
                    </button>
                </nz-form-item>
            </form>
        </div>
    </ng-container>
</nz-drawer>


<!-- alert for delete path -->

<nz-modal [nzStyle]="{ top: '100px' , width : '420px' }" [nzFooter]="null" [(nzVisible)]="alertModal"
    [nzMaskClosable]="false" [nzTitle]="''" (nzOnCancel)="closeAlertModal()">
    <ng-container *nzModalContent>
        <div class="mt-3 main_content">
            <span class="ques_icon" nz-icon nzType="question-circle" nzTheme="outline"></span>
            <div class="">
                <h5 class="">You are trying to delete Start path.</h5>
                <span>Select start path from below and delete current path.</span>
                <nz-form-item class="mt-2">
                    <nz-form-label class="large_label" nzRequired [nzSm]="24" [nzXs]="24">
                        Select Start Path
                    </nz-form-label>
                    <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please select start path!">
                        <nz-input-group>
                            <nz-select [(ngModel)]="editData" nzShowSearch nzPlaceHolder="Select start path"
                                class="w-100">
                                <ng-container *ngFor="let path of allFlows">
                                    <nz-option *ngIf="path._id != selectedPath._id" [nzLabel]="path.name"
                                        [nzValue]="path">
                                    </nz-option>
                                </ng-container>
                            </nz-select>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>



        <div class="custom_footer">
            <button nz-button nzType="default" (click)="closeAlertModal()">Cancel</button>
            <button [disabled]="!editData" nz-button nzType="primary"
                (click)="deleteBot(selectedPath._id , 'start')">Delete</button>
        </div>
    </ng-container>
</nz-modal>