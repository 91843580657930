import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { CommonApiService } from "src/app/services/common-api.service";
import { NavService } from "src/app/services/nav.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-subscription",
  templateUrl: "./subscription.component.html",
  styleUrls: ["./subscription.component.css"],
})
export class SubscriptionComponent implements OnInit {
  selectedSubs: any = "monthly";

  priceIds: any = {
    // for test api
    // standardMonthly: "price_1NVvdJSCmKSyR90TjuL72pXs",
    // standardQuarterly: "price_1NI9drSCmKSyR90TeKLMQltx",
    // standardYearly: "price_1NI9hzSCmKSyR90TIOREgkeZ",
    // advancedMonthly: "price_1NDLd9SCmKSyR90Twjz6BT37",
    // advancedQuarterly: "price_1NI9fjSCmKSyR90TnG8MUp9S",
    // advancedYearly: "price_1NI9gsSCmKSyR90TSUPn8gGQ",
    // for live api
    standardMonthly: "price_1NaAnRSCmKSyR90TpyVbK4HG",
    standardQuarterly: "price_1NaAnRSCmKSyR90TyPqaX9Vu",
    standardYearly: "price_1NaAnRSCmKSyR90TaQzyib6P",
    advancedMonthly: "price_1NaAq7SCmKSyR90TFw6eQCSb",
    advancedQuarterly: "price_1NaAq7SCmKSyR90TOEKHKLSS",
    advancedYearly: "price_1NaAq7SCmKSyR90TEECZgsY2",
  };

  planType: any = "";
  submitting: boolean = false;

  userSubscribed: boolean = true;

  subData: any;

  reDirecting: boolean = false;

  mainUserData: any;

  orderColumn: any[] = [
    {
      title: "Date",
    },
    {
      title: "Amount",
    },
    {
      title: "Status",
    },
    {
      title: "Description",
    },
  ];

  invoiceHistory: any[] = [
    {
      date: "10-04-2023",
      amount: "3000",
      status: "paid",
      description: "first payment",
    },
    {
      date: "15-05-2023",
      amount: "1800",
      status: "paid",
      description: "May month payment",
    },
    {
      date: "12-06-2023",
      amount: "2400",
      status: "paid",
      description: "full payment",
    },
    {
      date: "14-07-2023",
      amount: "400",
      status: "failed",
      description: "Ownchat",
    },
    {
      date: "18-07-2023",
      amount: "2000",
      status: "paid",
      description: "Ownchat",
    },
  ];

  menuOptions: any = {};
  locationData: any = {};

  private subscription: Subscription;

  constructor(
    private apiService: CommonApiService,
    private navService: NavService,
    private toastr: ToastrService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.spinner.show();
    this.checkSubscription();
    // this.getSubData();
    // this.getMainUserData();
  }

  checkSubscription() {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url + "stripe/get-subscription-page",
        {},
        options
      )
      .subscribe(
        (response: any) => {
          console.log(response);
          this.menuOptions = response;
          if (
            response.atleastOneTimeSubscribed &&
            !response.createPortalSessionButton
          ) {
            window.open(response?.redirectURL, "_self");
            // this.getLocation();
            // this.spinner.hide();
          } else {
            this.spinner.hide();
            this.getLocation();
          }
        },
        (error: any) => {
          console.log(error);
          if (
            error?.error?.message == "jwt expired" ||
            error?.error?.message == "jwt must be provided" ||
            error?.error?.message == "jwt malformed" ||
            error?.error?.message == "invalid signature" ||
            error?.error?.message == "Given token user does not exist." ||
            error?.error?.message == "invalid token"
          ) {
            this.navService.tokenExpired();
          } else if (error?.error?.message) {
            this.toastr.error(error?.error?.message, "", {
              timeOut: 2500,
            });
          } else {
            this.toastr.error(error?.message, "", {
              timeOut: 2500,
            });
          }
        }
      );
  }

  getLocation() {
    this.apiService
      .commonGetMethod("https://ipinfo.io/json?token=6d63057412214a", {})
      .subscribe(
        (response: any) => {
          console.log(response);
          this.locationData = response;
        },
        (err: any) => {
          console.log(err);
        }
      );
  }

  // ngAfterViewInit() {
  //   this.setIframeScrolling();
  // }

  setIframeScrolling() {
    const iframe = document.getElementsByTagName("iframe")[0];
    // console.log(iframe);
    if (iframe) {
      iframe.setAttribute("scrolling", "yes");
    } else {
      setTimeout(() => {
        this.setIframeScrolling();
      }, 2000); // Retry after 100ms
    }
  }

  getMainUserData() {
    this.subscription = this.navService.mainUserData.subscribe(
      (response: any) => {
        if (response) {
          this.mainUserData = response.data.userData;
          // console.log(this.mainUserData);
        }
      }
    );
  }

  getSubData() {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url + "organizations/get-current-subs",
        {},
        options
      )
      .subscribe(
        (response: any) => {
          this.subData = response.data.organization;
          // console.log(this.subData);
        },
        (error: any) => {
          console.log(error);
          if (
            error?.error?.message == "jwt expired" ||
            error?.error?.message == "jwt must be provided" ||
            error?.error?.message == "jwt malformed" ||
            error?.error?.message == "invalid signature" ||
            error?.error?.message == "Given token user does not exist." ||
            error?.error?.message == "invalid token"
          ) {
            this.navService.tokenExpired();
          }
        }
      );
  }

  changeSubscription(selected: any) {
    this.selectedSubs = selected;
    this.planType = "";
  }

  choosePlan(type: any) {
    this.submitting = true;
    // var planName = this.mainUserData.accessTo.curSubsPlan.name.split(" ")[0];
    // if (planName) {
    //   window.open(
    //     "https://hub.360dialog.com/dashboard/app/xAm2nuPA/permissions?name=" +
    //     this.mainUserData.accessTo.name +
    //       "&email=" +
    //       this.mainUserData.accessTo.email,
    //     "_blank"
    //   );
    // }
    this.planType = type;
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    var data: any = {};

    // console.log(this.selectedSubs);
    console.log(type);

    if (this.selectedSubs == "monthly") {
      if (type == "standard") {
        data.planId = this.priceIds.standardMonthly;
      } else if (type == "advanced") {
        data.planId = this.priceIds.advancedMonthly;
      }
    } else if (this.selectedSubs == "quarterly") {
      if (type == "standard") {
        data.planId = this.priceIds.standardQuarterly;
      } else if (type == "advanced") {
        data.planId = this.priceIds.advancedQuarterly;
      }
    } else if (this.selectedSubs == "yearly") {
      if (type == "standard") {
        data.planId = this.priceIds.standardYearly;
      } else if (type == "advanced") {
        data.planId = this.priceIds.advancedYearly;
      }
    }

    this.apiService
      .commonPostMethod(
        environment.url + "stripe/create-subscription-checkout",
        data,
        options
      )
      .subscribe(
        (response: any) => {
          // console.log(response);
          if (response.status == "success") {
            window.open(response?.redirectURL, "_self");
          } else if (response.status == "failed") {
            this.toastr.error(response.message, "", {
              timeOut: 2500,
            });
          }
          this.submitting = false;
          this.planType = "";
        },
        (error: any) => {
          console.log(error);
          if (error?.error?.message) {
            this.toastr.error(error?.error?.message, "", {
              timeOut: 3500,
            });
            if (
              error.status == 422 &&
              error?.error?.message ==
                "Important organization details were missing"
            ) {
              this.router.navigate(["/setting/account-details"]);
            }
          }
          this.submitting = false;
          this.planType = "";
        }
      );
  }

  toDetails() {
    this.reDirecting = true;

    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url + "stripe/create-portal-session",
        {},
        options
      )
      .subscribe(
        (response: any) => {
          // console.log(response);
          // if (response.status == "success") {
          window.open(response?.redirectURL, "_self");
          // } else if (response.status == "failed") {
          //   this.toastr.error(response.message, "", {
          //     timeOut: 2500,
          //   });
          // }

          this.reDirecting = false;
        },
        (error: any) => {
          console.log(error);
          if (error?.error?.message) {
            this.toastr.error(error?.error?.message, "", {
              timeOut: 2500,
            });
          } else {
            this.toastr.error(error?.message, "", {
              timeOut: 2500,
            });
          }
          this.reDirecting = false;
        }
      );
  }

  ngOnDestroy(): void {
    // console.log("api key destroy..");
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
