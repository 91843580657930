import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";

import {
  CdkDragDrop,
  moveItemInArray,
  CdkDrag,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import { environment } from "src/environments/environment";
import { CommonApiService } from "src/app/services/common-api.service";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import { NavService } from "src/app/services/nav.service";

@Component({
  selector: "app-segment",
  templateUrl: "./segment.component.html",
  styleUrls: ["./segment.component.css"],
})
export class SegmentComponent implements OnInit {
  moment: any = moment;
  new1: any = [{ test: [""] }];
  segmentForm!: FormGroup;

  preferences: any = [
    {
      name: "Customer Name",
    },
    {
      name: "Email Address",
    },
    {
      name: "Mobile number",
    },
    {
      name: "Regular Customer",
    },
    {
      name: "Status",
    },
  ];

  selectedColumns: any = [];
  visibilityPreference: any = "";
  visibilityOptions: any = [
    {
      name: "Only Me",
      value: "onlyMe",
    },
    {
      name: "Only Selected Users & Roles",
      value: "onlySelected",
    },
    {
      name: "Everyone",
      value: "everyone",
    },
  ];

  taglineArray: any = [
    {
      dataType: "",
      filterType: "",
      key: null,
      values: [null],
    },
  ];

  dragged: boolean = false;

  segmentName: any = "";
  requiredData: any = [];

  //input section

  customFields: any = [];
  filterOptions: any = {};
  allTags: any = [];

  // filtered list table

  filteredApplied: boolean = false;
  orderColumn: any = [];
  tableFilterArr: any = [];
  userList: any = [];
  viewMoreArr: any = [];

  // edit section
  isEdit: boolean = false;
  segmentId: any = "";
  mainLoader: boolean = false;

  // loaders
  addingSegment: boolean = false;

  // pagination

  pageIndex = 1;
  totalCount: number;
  pageSize: number;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private apiService: CommonApiService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private navService: NavService
  ) {}

  ngOnInit(): void {
    this.segmentForm = this.fb.group({
      name: ["", [Validators.required]],
    });

    this.getCustomFields();
    this.getAllTags();
    window.scroll(0, 0);

    this.route.params.subscribe((data) => {
      if (data["id"]) {
        this.mainLoader = true;
        this.spinner.show();
        this.isEdit = true;
        this.segmentId = data["id"];
        // console.log(this.segmentId);
      }
    });
  }

  getSegmentData() {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url + "segments/get-detail",
        { segmentId: this.segmentId },
        options
      )
      .subscribe(
        (response: any) => {
          // console.log(response);
          this.segmentName = response?.data?.segment?.name;
          // console.log(JSON.parse(response.data.segment.filterData));
          var temp = JSON.parse(response.data.segment.filterData);
          for (let i = 0; i < temp.length; i++) {
            var targetIndex = this.customFields.findIndex((data) => {
              return data.name == temp[i].key;
            });
            temp[i].key = this.customFields[targetIndex];
          }
          this.taglineArray = temp;
          this.mainLoader = false;
          this.spinner.hide();
          // this.taglineArray = temp;
        },
        (err: any) => {
          this.mainLoader = false;
          this.spinner.hide();
          console.log(err);
          this.spinner.hide();
        }
      );
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  getCustomFields() {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url + "customer-field/get?filter=for-create-segment",
        {},
        options
      )
      .subscribe(
        (response: any) => {
          console.log(response);
          this.customFields = response?.data?.customerFields;
          this.filterOptions = response?.data?.getFilterOperatorData;
          for (let i = 0; i < this.customFields.length; i++) {
            var temp: any = {};
            temp.label = this.customFields[i].label;
            temp.name = this.customFields[i].name;
            temp.dataType = this.customFields[i].dataType;

            if (this.customFields[i].name == "name") {
              temp.width = "100px";
            } else if (this.customFields[i].name == "email") {
              temp.width = "120px";
            } else if (this.customFields[i].name == "phoneNo") {
              temp.width = "100px";
            } else if (this.customFields[i].name == "allowBroadcast") {
              temp.width = "100px";
            } else {
              temp.width =
                temp?.label?.toString()?.length < 10
                  ? temp?.label?.toString()?.length * 17 + "px"
                  : temp?.label?.toString()?.length * 8 + "px";
            }

            this.orderColumn.push(temp);
          }
          this.tableFilterArr = this.orderColumn.map((e: any) => e.label);
          if (this.isEdit) {
            this.getSegmentData();
          }
        },
        (error) => {
          if (
            error?.error?.message == "jwt expired" ||
            error?.error?.message == "jwt must be provided" ||
            error?.error?.message == "jwt malformed" ||
            error?.error?.message == "invalid signature" ||
            error?.error?.message == "Given token user does not exist." ||
            error?.error?.message == "invalid token"
          ) {
            this.navService.tokenExpired();
          }
          this.mainLoader = false;
          this.spinner.hide();
          console.log(error);
        }
      );
  }

  getAllTags() {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(environment.url + "tag?noPagination", {}, options)
      .subscribe(
        (response: any) => {
          this.allTags = response.data.tags;
          // console.log(this.allTags);
        },
        (error: any) => {
          console.log(error);
        }
      );
  }

  addToSelected(selected: any) {
    this.dragged = false;
    var targetIndex = this.selectedColumns.findIndex((e: any) => {
      return e.name == selected.name;
    });

    if (targetIndex == -1) {
      this.selectedColumns.push(selected);
    }
  }

  removeSelected(selected) {
    var targetIndex = this.selectedColumns.findIndex((e: any) => {
      return e.name == selected.name;
    });

    if (targetIndex != -1) {
      if (this.dragged) {
        this.preferences.push(selected);
      }
      this.selectedColumns.splice(targetIndex, 1);
    }
  }

  toContacts() {
    this.router.navigate(["/contacts"]);
  }

  // cdk lists

  drop(event: CdkDragDrop<string[]>) {
    this.dragged = true;
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  addNewTagline() {
    var temp = {
      dataType: "",
      filterType: "",
      key: null,
      values: [null],
    };

    this.taglineArray.push(temp);
  }

  removeTagline(index: any) {
    this.taglineArray.splice(index, 1);
  }

  fieldChange(i: any, key: any) {
    this.taglineArray[i].values = [null];
    this.taglineArray[i].filterType = "";
    this.removeValidError(key);
  }

  addValueInput(index: any) {
    this.taglineArray[index].values.push(null);
  }

  removeValueInput(tagIndex: any, valueIndex: any) {
    this.taglineArray[tagIndex].values.splice(valueIndex, 1);
  }

  checkFiltered() {
    this.pageIndex = 1;
    this.addSegment(true);
  }

  addSegment(filter: boolean) {
    this.requiredData = [];
    if (!this.segmentName) {
      this.requiredData.push("segment name");
    } else if (this.requiredData.indexOf("segment name") > -1) {
      this.requiredData.splice(this.requiredData.indexOf("segment name"), 1);
    }

    for (let i = 0; i < this.taglineArray.length; i++) {
      if (!this.taglineArray[i].filterType) {
        this.requiredData.push("filterType" + i);
      } else if (this.requiredData.indexOf("filterType" + i) > -1) {
        this.requiredData.splice(
          this.requiredData.indexOf("filterType" + i),
          1
        );
      }
      if (!this.taglineArray[i].key) {
        this.requiredData.push("fieldName" + i);
      } else if (this.requiredData.indexOf("fieldName" + i) > -1) {
        this.requiredData.splice(this.requiredData.indexOf("fieldName" + i), 1);
      }

      for (let j = 0; j < this.taglineArray[i].values.length; j++) {
        if (
          !this.taglineArray[i].values[j] &&
          this.taglineArray[i].filterType != "is empty" &&
          this.taglineArray[i].filterType != "is not empty"
        ) {
          this.requiredData.push("value" + i + j);
        } else if (this.requiredData.indexOf("value" + i + j) > -1) {
          this.requiredData.splice(
            this.requiredData.indexOf("value" + i + j),
            1
          );
        }
      }
    }

    if (this.requiredData.length == 0) {
      if (filter) {
        this.filteredApplied = true;
        this.spinner.show();
      } else {
        this.addingSegment = true;
      }

      var data: any = [];

      for (let i = 0; i < this?.taglineArray?.length; i++) {
        var temp = {
          dataType: this?.taglineArray[i]?.key?.dataType,
          filterType: this?.taglineArray[i]?.filterType,
          key: this?.taglineArray[i]?.key?.name,
          values: this?.taglineArray[i].values,
        };
        data.push(temp);
      }

      var reqObj: any = {
        filter: {
          and: data,
        },
        checkFilter: filter,
        name: this.segmentName,
      };

      if (this.isEdit) {
        reqObj.segmentId = this.segmentId;
      }
      // console.log(reqObj);
      this.createRequest(reqObj, filter);
    }
  }

  createRequest(data: any, filter: boolean) {
    this.viewMoreArr = [];
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    var url = "";

    if (filter) {
      if (this.isEdit) {
        url = "segments/update?page=" + this.pageIndex;
      } else {
        url = "segments/create?page=" + this.pageIndex;
      }
    } else {
      if (this.isEdit) {
        url = "segments/update";
      } else {
        url = "segments/create";
      }
    }

    this.apiService
      .commonPostMethod(environment.url + url, data, options)
      .subscribe(
        (response: any) => {
          // console.log(response);
          this.totalCount = response?.data?.totalCount;
          this.pageSize = response?.data?.limit;
          if (filter) {
            this.userList = response?.data?.customers;
            document
              .getElementById("filter_result")
              .scrollIntoView({ behavior: "smooth" });
            this.spinner.hide();
          } else {
            if (this.isEdit) {
              this.router.navigate(["/contacts"]);
              this.isEdit = false;
            }
            this.toastr.success(response.message, "", {
              timeOut: 2500,
            });
            this.resetForm();
            this.addingSegment = false;
            window.scroll(0, 0);
          }
        },
        (error) => {
          console.log(error);
          this.addingSegment = false;
          this.spinner.hide();
        }
      );
  }

  removeValidError(key: any) {
    if (this.requiredData.indexOf(key) > -1) {
      this.requiredData.splice(this.requiredData.indexOf(key), 1);
    }
  }

  resetForm() {
    if (this.isEdit) {
      this.router.navigate(["/contacts/segment"]);
    }
    this.segmentName = "";
    this.taglineArray = [
      {
        dataType: "",
        filterType: "",
        key: null,
        values: [null],
      },
    ];
    this.filteredApplied = false;
    this.userList = [];
    this.requiredData = [];
  }

  onPageNumberChange(id: any, event: any) {
    if (this.pageIndex != event.pageIndex) {
      this.pageIndex = event.pageIndex;
      this.addSegment(true);
      document.getElementById(id).scrollIntoView();
    }
  }

  viewMore(y: number) {
    if (this.viewMoreArr.indexOf(y) > -1) {
      this.viewMoreArr.splice(this.viewMoreArr.indexOf(y), 1);
    } else {
      this.viewMoreArr.push(y);
    }

    // console.log(this.viewMoreArr);
  }
}
