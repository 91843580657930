<!-- <nz-modal [(nzVisible)]="waModal" nzTitle="Update WA" nzCentered (nzOnCancel)="closeOtpModal()">
    <ng-container *nzModalContent>

        <form [formGroup]="waForm" (ngSubmit)="updateWA()" nz-form>
            <div class="update_form_div">
                <nz-form-item>
                    <nz-form-control nzErrorTip="Please Enter Phone Number Id!">
                        <nz-input-group>
                            <input autocomplete="off" formControlName="phoneNoId" type="text" nz-input id="phone_NoId"
                                placeholder="Phone Number Id" required>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-control nzErrorTip="Please Enter User Access Token!">
                        <nz-input-group>
                            <input autocomplete="off" formControlName="userAccessToken" type="text" nz-input id="user_AccessToken"
                                placeholder="User Access Token" required>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-control nzErrorTip="Please Enter waBAId!">
                        <nz-input-group>
                            <input autocomplete="off" formControlName="waBAId" type="text" nz-input id="waBAId"
                                placeholder="Enter waBAId" required>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item class="submit_div">
                    <button [nzLoading]="submitting" type="submit" class="sighup_button" nz-button nzType="primary">Submit</button>
                </nz-form-item>
            </div>
        </form>

    </ng-container>
    <ng-template class="modal_footer" *nzModalFooter></ng-template>
</nz-modal> -->



<div class="row justify-content-center">
    <div class="col-lg-6 ">
        <nz-card>

            <div class="row justify-content-center m-b-30">
                <div class="col-11">
                    <h3>Setting</h3>
                </div>

                <div class="col-11 mt-3">
                    <form [formGroup]="waForm" (ngSubmit)="updateWA()" nz-form>
                        <div class="update_form_div">
                            <nz-form-item>
                                <nz-form-control nzErrorTip="Please Enter Phone Number Id!">
                                    <nz-input-group>
                                        <input autocomplete="off" formControlName="phoneNoId" type="text" nz-input
                                            id="phone_NoId" placeholder="Phone Number Id" required>
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>

                            <nz-form-item>
                                <nz-form-control nzErrorTip="Please Enter User Access Token!">
                                    <nz-input-group>
                                        <input autocomplete="off" formControlName="userAccessToken" type="text" nz-input
                                            id="user_AccessToken" placeholder="User Access Token" required>
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>

                            <nz-form-item>
                                <nz-form-control nzErrorTip="Please Enter waBAId!">
                                    <nz-input-group>
                                        <input autocomplete="off" formControlName="waBAId" type="text" nz-input
                                            id="waBAId" placeholder="Enter waBAId" required>
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>

                            <nz-form-item class="submit_div">
                                <button [nzLoading]="submitting" type="submit" class="sighup_button" nz-button
                                    nzType="primary">Update</button>
                            </nz-form-item>
                        </div>
                    </form>
                </div>
            </div>
        </nz-card>
    </div>
</div>