import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { AlertServiceService } from "src/app/services/alert-service.service";
import { CommonApiService } from "src/app/services/common-api.service";
import { NavService } from "src/app/services/nav.service";
import { environment } from "src/environments/environment";
import { catalogueCategories } from "../chat/shared/catelogue-categories";
import { countryList, currencyList } from "../chat/shared/messageData";
import { Subscription } from "rxjs";

@Component({
  selector: "app-create-catalogue",
  templateUrl: "./create-catalogue.component.html",
  styleUrls: ["./create-catalogue.component.css"],
})
export class CreateCatalogueComponent implements OnInit {
  createCatalogueForm!: FormGroup;
  defaultValueForm!: FormGroup;
  productImage: any;
  selectedImageUrl: any = "";
  formLoading: boolean;
  liveUrl = environment.imageUrl;

  localImage: boolean;

  @Input() editCatalogue: any;

  @Output() showTable: EventEmitter<any> = new EventEmitter();
  @Input() editdata!: any;

  mainUserData: any;

  countryList: any = countryList;

  currencyList: any = currencyList;

  imageTypes = ["image/png", "image/jpg", "image/jpeg"];

  categoryOptions = catalogueCategories;

  defaultValueModal: boolean = false;

  defaultValues: any = {
    availability: "in stock",
    condition: "new",
    originCountry: "IN",
    currency: "INR",
    brand: "Apple",
    category: "other",
    url: "https://www.apple.com/",
    waComplianceCategory: "DEFAULT",
  };

  private subscription: Subscription;

  constructor(
    private fb: FormBuilder,
    private apiService: CommonApiService,
    private toastr: ToastrService,
    private navService: NavService,
    private alertService: AlertServiceService
  ) {}

  ngOnInit(): void {
    this.createCatalogueForm = this.fb.group({
      retailerId: ["", Validators.required],
      name: ["", [Validators.required, Validators.maxLength(200)]],
      description: ["", [Validators.required, Validators.maxLength(9999)]],
      availability: ["", Validators.required],
      condition: ["", Validators.required],
      price: [null, Validators.required],
      salePrice: [null, Validators.required],
      originCountry: ["", Validators.required],
      currency: ["", Validators.required],
      brand: ["", Validators.required],
      category: [null, Validators.required],
      url: ["", Validators.required],
      waComplianceCategory: ["", Validators.required],
    });

    this.defaultValueForm = this.fb.group({
      availability: ["in stock"],
      condition: ["new"],
      originCountry: ["IN"],
      currency: ["INR"],
      brand: ["Apple"],
      category: ["other"],
      url: ["https://www.apple.com/"],
      waComplianceCategory: ["DEFAULT"],
    });

    // console.log(this.editCatalogue);
    if (this.editCatalogue == "edit") {
      this.setEditValues();
    }
    // else if (this.editCatalogue == "create") {
    //   this.getDefaultValues();
    // }

    this.subscription = this.navService.mainUserData.subscribe(
      (response: any) => {
        if (response) {
          this.mainUserData = response.data.userData;
        }
      }
    );
  }

  uploadFile(event: any) {
    this.localImage = true;
    this.productImage = event.target.files[0];
    const fileSizeMB: any = (this.productImage.size / 1000000).toFixed(2); // convert bytes to MB
    console.log(this.productImage);
    if (fileSizeMB <= 8) {
      let file = event.target.files[0];
      let reader = new FileReader();
      // reader.onload = (e) => (this.selectedImageUrl = reader.result);
      reader.onload = (e: any) => {
        const img = new Image();
        img.src = e.target.result;

        img.onload = () => {
          if (img.width >= 500 && img.height >= 500) {
            this.selectedImageUrl = img.src;
          } else {
            this.selectedImageUrl = null;
            // console.log("Image resolution is too low.");
            this.toastr.error(
              "Image resolution must be more than 500X500 pixels",
              "",
              {
                timeOut: 3500,
              }
            );
          }
        };
      };
      console.log(this.selectedImageUrl);
      reader.readAsDataURL(file);
    } else {
      this.toastr.error("Image size must be less than 8MB", "", {
        timeOut: 3500,
      });
    }

    event.target.value = "";
  }

  filesDropped(files: any): void {
    this.localImage = true;
    this.productImage = files[0].file;
    const fileSizeMB: any = (this.productImage.size / 1000000).toFixed(2); // convert bytes to MB
    // console.log(fileSizeMB + "MB");
    if (fileSizeMB <= 8) {
      let file = files[0].file;
      let reader = new FileReader();
      reader.onload = (e) => (this.selectedImageUrl = reader.result);
      reader.readAsDataURL(file);
    } else {
      this.toastr.error("Image size must be less than 8MB", "", {
        timeOut: 3500,
      });
    }
  }

  showCatalogueForm() {
    // this.createForm = true;
  }

  addCatalogue() {
    // console.log(this.createCatalogueForm.value.category);
    if (!this.mainUserData?.accessTo?.sandboxId) {
      if (this.createCatalogueForm.valid) {
        if (this.productImage || this.editCatalogue == "edit") {
          var formData = new FormData();
          var url = "";
          formData.append("catalogueItemId", this.editdata._id);
          if (this.editCatalogue == "edit") {
            url = "catalogue-item/update";
          } else if (this.editCatalogue == "create") {
            url = "catalogue-item/create";
          }
          this.formLoading = true;
          // console.log(this.createCatalogueForm.value);
          var price: any = this.createCatalogueForm.value.price;
          var salePrice: any = this.createCatalogueForm.value.salePrice;

          let options = {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
            },
          };

          formData.append(
            "retailerId",
            this.createCatalogueForm.value.retailerId
          );
          formData.append("name", this.createCatalogueForm.value.name);
          formData.append(
            "description",
            this.createCatalogueForm.value.description
          );
          formData.append(
            "availability",
            this.createCatalogueForm.value.availability
          );
          formData.append(
            "condition",
            this.createCatalogueForm.value.condition
          );
          formData.append("price", price);
          formData.append("salePrice", salePrice);
          formData.append(
            "originCountry",
            this.createCatalogueForm.value.originCountry
          );
          formData.append("currency", this.createCatalogueForm.value.currency);
          formData.append("brand", this.createCatalogueForm.value.brand);
          formData.append("category", this.createCatalogueForm.value.category);
          formData.append("url", this.createCatalogueForm.value.url);
          formData.append(
            "waComplianceCategory",
            this.createCatalogueForm.value.waComplianceCategory
          );

          if (this.productImage) {
            formData.append("image", this.productImage);
          }

          this.apiService
            .commonPostMethod(environment.url + url, formData, options)
            .subscribe(
              (response: any) => {
                // console.log(response);
                this.toastr.success(
                  response.message +
                    " , You can send this catalogue after 15 mins..",
                  "",
                  {
                    timeOut: 2500,
                  }
                );
                this.showTable.emit();
                this.formLoading = false;
              },
              (err: any) => {
                console.log(err);
                this.formLoading = false;
                if (err?.error?.message) {
                  this.toastr.error(err?.error?.message, "", {
                    timeOut: 2500,
                  });
                } else {
                  this.toastr.error(err?.message, "", {
                    timeOut: 2500,
                  });
                }
              }
            );
        } else {
          this.toastr.error("Please select Image", "", {
            timeOut: 2500,
          });
        }
      } else {
        Object.values(this.createCatalogueForm.controls).forEach((control) => {
          if (control.invalid) {
            control.markAsDirty();
            control.updateValueAndValidity({ onlySelf: true });
          }
        });
      }
    } else if (this.mainUserData?.accessTo?.sandboxId) {
      var errHead: any = "You are using Sandbox account!";
      var errBody: any =
        "Please Subscribe any Plan to Add or Update Catalogue!..";
      this.alertService.freeTrialWarning(errHead, errBody);
    }
  }

  // EDIT SECTION

  setEditValues() {
    // console.log(this.editdata);
    // console.log(this.editdata.price.split('₹')[1]);
    // console.log(parseInt(this.editdata.price.split('₹')[1].replace(/,/g, '')));
    this.localImage = false;

    this.createCatalogueForm.get("name").setValue(this.editdata.name);
    this.createCatalogueForm
      .get("retailerId")
      .setValue(this.editdata?.retailer_id);
    this.createCatalogueForm
      .get("description")
      .setValue(this.editdata?.description);
    this.selectedImageUrl = this.editdata?.image_url;
    this.createCatalogueForm
      .get("availability")
      .setValue(this.editdata?.availability);
    this.createCatalogueForm
      .get("condition")
      .setValue(this.editdata?.condition);
    this.createCatalogueForm
      .get("salePrice")
      .setValue(parseFloat(this.editdata?.sale_price));
    this.createCatalogueForm
      .get("originCountry")
      .setValue(this.editdata?.origin_country);
    this.createCatalogueForm.get("currency").setValue(this.editdata?.currency);
    this.createCatalogueForm
      .get("waComplianceCategory")
      .setValue(this.editdata?.wa_compliance_category);
    this.createCatalogueForm
      .get("price")
      .setValue(parseFloat(this.editdata?.price));
    this.createCatalogueForm.get("brand").setValue(this.editdata?.brand);
    this.createCatalogueForm.get("category").setValue(this.editdata?.category);
    this.createCatalogueForm.get("url").setValue(this.editdata?.url);
  }

  closeDefaultValueModal() {
    this.defaultValueModal = false;
  }

  showDefaultValueModal() {
    this.defaultValueModal = true;
  }

  getDefaultValues() {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(environment.url + "setting/defaultValues", {}, options)
      .subscribe(
        (response: any) => {
          // console.log(response);
          this.setDefaultValue();
        },
        (err: any) => {
          console.log(err);
        }
      );
  }

  setDefaultValue() {
    this.createCatalogueForm
      .get("availability")
      .setValue(this.defaultValues.availability);
    this.createCatalogueForm
      .get("condition")
      .setValue(this.defaultValues.condition);
    this.createCatalogueForm
      .get("originCountry")
      .setValue(this.defaultValues.originCountry);
    this.createCatalogueForm
      .get("currency")
      .setValue(this.defaultValues.currency);
    this.createCatalogueForm
      .get("waComplianceCategory")
      .setValue(this.defaultValues.waComplianceCategory);
    this.createCatalogueForm.get("brand").setValue(this.defaultValues.brand);
    this.createCatalogueForm
      .get("category")
      .setValue(this.defaultValues.category);
    this.createCatalogueForm.get("url").setValue(this.defaultValues.url);
  }

  updateDefaultValues() {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    var data: any = {
      availability: this.defaultValueForm.value.availability,
      condition: this.defaultValueForm.value.condition,
      originCountry: this.defaultValueForm.value.originCountry,
      currency: this.defaultValueForm.value.currency,
      brand: this.defaultValueForm.value.brand,
      category: this.defaultValueForm.value.category,
      url: this.defaultValueForm.value.url,
      waComplianceCategory: this.defaultValueForm.value.waComplianceCategory,
    };

    this.apiService
      .commonPostMethod(
        environment.url + "setting/update-defaultValues",
        data,
        options
      )
      .subscribe(
        (response: any) => {
          // console.log(response);
          this.setDefaultValue();
        },
        (err: any) => {
          console.log(err);
        }
      );
  }

  ngOnDestroy(): void {
    // console.log("api key destroy..");
    this.subscription.unsubscribe();
  }
}

// ?.split("₹")[1].replace(/,/g, "")
// ?.split("₹")[1].replace(/,/g, "")
// .join(" > ")  // removed to set the category dropdown with value other..  (send to api)
// ?.split(" > ")    // removed to set the category dropdown with value other.. (set form value)
