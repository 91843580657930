<nz-card class="main_chat_card">
    <div class="">
        <div class="d-flex pb-2 border-bottom profile_main_div">
            <div *ngIf="!showSearch" class="left_menu">
                <h3 (click)="showChatUsers('userList')" class="chat_area_back_icon">
                    <span nz-icon nzType="arrow-left" nzTheme="outline"></span>
                </h3>
                <p (click)="scrollFn('customer_details')"
                    [ngStyle]="{ 'background-color':  contactBgs[(selectedUser?.name)?.charAt(0)?.toUpperCase()]?.['bg'] , 'color' : contactBgs[(selectedUser?.name).charAt(0).toUpperCase()]?.['color']}"
                    class="user_profile_picture">{{ (selectedUser?.name)?.charAt(0)?.toUpperCase() }}</p>
                <div class="user_name">
                    <h5 (click)="scrollFn('customer_details')" [nzTooltipTitle]="selectedUser?.name"
                        nzTooltipPlacement="topLeft" nz-tooltip class="mt-0 mb-0 fs-14">{{ selectedUser?.name.length >
                        30 ?
                        (selectedUser?.name).slice(0,30) + '...'
                        : selectedUser?.name }}</h5>
                    <!-- <p class="mb-0">{{selectedUser?.phoneNo}}</p> -->
                    <div class="tags_container mt-1">
                        <nz-tag (click)="scrollFn('tags_container')"
                            *ngFor="let tag of customerDetails?.customer?.tags | slice:0:2;">
                            {{tag.name}}
                        </nz-tag>
                        <a (click)="scrollFn('add_tag')" *ngIf="mainUserData?.role != 'teammate' && selectedUser"
                            class="edit_user_link add_tag_link">
                            <span nz-icon nzType="plus-circle" nzTheme="twotone"></span>
                            Add Tags
                        </a>
                    </div>

                </div>
            </div>

            <div class="right_options">
                <div *ngIf="!showSearch" class="right_menu">
                    <!-- removed to user details component (right card)-->

                    <!-- <div class="">
                        <nz-progress [nzStatus]="timeDiffPercentage < 30 ? 'exception' : 'test'" [nzWidth]="45"
                            [nzPercent]="timeDiffPercentage" nzType="circle" [nzFormat]="formatOne"></nz-progress>
                    </div>
                    <div *ngIf="mainUserData?.role != 'teammate'" class="w-60">
                        <nz-select [disabled]="!selectedUser" class="w-100 select_dropdown"
                            nzPlaceHolder="Select Assignee" [(ngModel)]="assignedUser"
                            (ngModelChange)="assignMessage()">
                            <nz-option *ngFor="let user of assignUsersList" [nzValue]="user._id"
                                [nzLabel]="user?.firstName + ' ' + (user?.lastName ? user?.lastName : '')"></nz-option>
                        </nz-select>
                    </div> -->
                    <!-- <span (click)="showKanbanBoard()" class="search_icon web_cursor" nz-icon nzType="group"
                        nzTheme="outline"></span> -->
                    <div class="d-flex">
                        <span [ngClass]="{'disabled_tag' : !selectedUser}" class="search_icon web_cursor"
                            (click)="showChatSearch()" nz-icon nzType="search" nzTheme="outline"></span>
                    </div>
                    <!-- removed to user details component (right card)-->
                    <!-- <div class="d-flex chat_badge">
                        <img [ngClass]="{'disabled_tag' : !selectedUser}" (click)="followUpKey('follow up')"
                            class="clock_icon web_cursor" nzTrigger="click" nz-dropdown
                            [nzDropdownMenu]="mainUserData?.accessTo?.isExpired ? '' : followUp"
                            [src]="chatHistory?.customerData?.followUp ? '../../../../../assets/images/chat/timer.png' : '../../../../../assets/images/chat/timer_off.svg'"
                            alt="">
                        <nz-badge *ngIf="chatHistory?.customerData?.followUp" nzStatus="processing"></nz-badge>
                    </div> -->
                    <div class="d-flex">
                        <span [ngClass]="{'disabled_tag' : !selectedUser}" (click)="followUpKey('search')"
                            nzTrigger="click" nz-dropdown
                            [nzDropdownMenu]="mainUserData?.accessTo?.isExpired ? '' : moreOptions"
                            class="more_icon web_cursor" nz-icon nzType="more" nzTheme="outline"></span>
                    </div>

                    <!-- more options -->
                    <nz-dropdown-menu #moreOptions="nzDropdownMenu">
                        <ul class="more_options" nz-menu nzSelectable>
                            <li (click)="makeUserImportant()" nz-menu-item>
                                <span nz-icon nzType="exclamation" nzTheme="outline"></span>
                                {{mainUserData?.importantCustomers?.indexOf(selectedUser?._id) > -1 ?
                                'Unimportant' : 'Important'}}
                            </li>
                            <li (click)="closeChat()" nz-menu-item><span nz-icon nzType="select"
                                    nzTheme="outline"></span>
                                {{ chatHistory?.customerData?.inboxStatus == "closed" ? 'Open chat' : 'Close chat'}}
                            </li>
                        </ul>
                    </nz-dropdown-menu>

                    <!-- follow up options -->
                    <nz-dropdown-menu #followUp="nzDropdownMenu">
                        <ul *ngIf="!followUpAssigned" class="more_options follow_up_option" nz-menu nzSelectable>
                            <h6 class="follow_up_heading">Set Follow-up reminder</h6>
                            <li (click)="addFollowUp('later-today' , 'predefined')" nz-menu-item>
                                <div class="date_letter">
                                    <span nz-icon nzType="clock-circle" nzTheme="outline"></span>
                                    Later today
                                </div>
                                <p>{{moment(now).format('hh:mm a')}}</p>
                            </li>
                            <li (click)="addFollowUp('tomorrow' , 'predefined')" nz-menu-item>
                                <div class="date_letter">
                                    <span nz-icon nzType="right" nzTheme="outline"></span>
                                    Tomorrow
                                </div>
                                <p>{{moment(tomorrow).format('dd, hh:mm a')}}</p>
                            </li>
                            <li (click)="addFollowUp('next-week' , 'predefined')" nz-menu-item>
                                <div class="date_letter">
                                    <span nz-icon nzType="double-right" nzTheme="outline"></span>
                                    Next week
                                </div>
                                <p>{{moment(nextWeek).format('dd, hh:mm a')}}</p>
                            </li>
                            <li nz-menu-item>
                                <div class="">
                                    <nz-date-picker nzBorderless [nzShowTime]="{ nzFormat: 'hh:mm a' }"
                                        nzFormat="yyyy-MM-dd hh:mm a" ngModel (nzOnOk)="onOk($event)"></nz-date-picker>
                                </div>
                            </li>
                        </ul>
                        <ul *ngIf="followUpAssigned" class="more_options follow_up_option" nz-menu nzSelectable>
                            <p class="text-center">Follow-up reminder</p>
                            <li nz-menu-item>
                                <div class="follow_up_detail">
                                    <span nz-icon nzType="bell" nzTheme="twotone"></span>
                                    <p>{{moment(chatHistory?.customerData?.followUp).format('DD MMM | hh:mm a')}}</p>
                                    <div class="edit_delete_icons">
                                        <span (click)="showEdit()" nz-icon nzType="edit" nzTheme="outline"></span>
                                        <span (click)="deleteFollowUp()" nz-icon nzType="delete"
                                            nzTheme="outline"></span>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </nz-dropdown-menu>
                </div>
            </div>

            <div *ngIf="showSearch" class="search_menu w-100">
                <nz-input-group [nzSuffix]="suffixIconSearch">
                    <input autocomplete="off" type="search" nz-input placeholder="Search Chats.." />
                </nz-input-group>
                <ng-template #suffixIconSearch>
                    <span nz-icon nzType="search"></span>
                </ng-template>
                <span (click)="showSearch = false;" nz-icon nzType="close" nzTheme="outline"></span>
            </div>
            <!-- <div class="qr_code_icon">
                <span (click)="sandBoxModal = true" nz-icon nzType="qrcode" nzTheme="outline"></span>
            </div> -->
        </div>

        <div class="mt-1 chat_main_card">
            <div>
                <div appScrollDetect (customScrolled)="scrollHeight($event)" #chatBox
                    [ngClass]="{'disable_scroll' : isLoading, 'extra_height' : blobUrl || isRecording}"
                    class="scroll_div" infinite-scroll [infiniteScrollUpDistance]="2" [infiniteScrollDistance]="2"
                    [infiniteScrollThrottle]="10" [scrollWindow]="false" (scrolledUp)="onScrollUp()"
                    (scrolled)="onScrollDown()">
                    <ul class="conversation-list px-0 h-100">

                        <p *ngIf="newMessageAlert" (click)="showNewMessage()" class="new_message_alert">
                            {{newMessageCount}} New message</p>

                        <ng-container>
                            <div class="text-center loader_div">
                                <nz-spin [nzSpinning]="isLoading" [nzSize]="'large'" [nzDelay]="50" nzSimple></nz-spin>
                            </div>
                            <li #chatScroll class="clearfix" *ngFor="let item of chatHistory?.chats; let j = index"
                                [ngClass]="{ 'odd': item?.conversationType == 'sent' || item?.conversationType == 'sent-as-notes' , 'notes_message_type' : item?.conversationType == 'sent-as-notes' , 'search_result' : selectedId == item?._id}">
                                <div [id]="'a' + item?._id" class="conversation-text ms-0"
                                    [ngClass]="{'assign_message' : item?.conversationType == 'customer-assign-info' || item?.conversationType == 'inbox-status-info' , 'scroll_after_search' : selectedId == item?._id}">
                                    <div class="outer_div"
                                        [ngClass]="{'justify-content-end': item?.conversationType != 'sent' || item?.conversationType == 'sent-as-notes'}">
                                        <!-- <div class="conversation-actions dropstart" placement="start-top"
                                        *ngIf="item.status === 'sent'">
                                        <a href="javascript: void(0);" class="dropdown-toggle text-dark"
                                            nzTrigger="click" nz-dropdown [nzDropdownMenu]="menu">
                                            <span nz-icon nzType="more" nzTheme="outline"></span>
                                        </a>
                                        <nz-dropdown-menu #menu="nzDropdownMenu">
                                            <ul nz-menu nzSelectable>
                                                <li nz-menu-item>Reply</li>
                                                <li nz-menu-item>Starred</li>
                                                <li nz-menu-item>Delete</li>
                                                <li nz-menu-item>Copy</li>
                                            </ul>
                                        </nz-dropdown-menu>


                                    </div> -->
                                        <div *ngIf="item?.conversationType == 'sent' || item?.conversationType == 'sent-as-notes'"
                                            class="received_message_container">
                                            <div [ngSwitch]="item?.message?.msgType"
                                                [ngClass]="{'list_message_width' : item?.message?.data?.type == 'list'}"
                                                class="ctext-wrap">
                                                <div class="">
                                                    <!-- <p>{{item.message.data}}</p> -->
                                                    <div *ngSwitchCase="'template'" class="template_preview">
                                                        <div *ngFor="let message of item.message.data" class="mt-3">
                                                            <!-- <h6>{{message?.type}} :</h6> -->
                                                            <div [ngSwitch]="message?.type?.toLowerCase()" class="">
                                                                <div *ngSwitchCase="'header'" class="">
                                                                    <div [ngSwitch]="message?.format?.toLowerCase()"
                                                                        class="header_text">
                                                                        <p class="header_paragraph"
                                                                            *ngSwitchCase="'text'"
                                                                            [innerHTML]="message?.text"></p>
                                                                        <!-- <p class="header_paragraph" *ngSwitchCase="'TEXT'"
                                                                          [innerHTML]="message.text"></p> -->
                                                                        <!-- <img *ngSwitchCase="'IMAGE'" class="img-fluid" [src]="message.image ? 'https://now6.getdesignworks.com/'+message.image : sampleSrc" alt="IMAGE URL"> -->
                                                                        <img nz-image *ngSwitchCase="'image'"
                                                                            class="img-fluid"
                                                                            [nzSrc]="item.message?.mediaSource == 'url' ? message?.parameters?.[0]?.image?.link  : (item.localImage ? item.message?.media?.url :  liveUrl + item.message?.media?.url)"
                                                                            alt="IMAGE URL" />
                                                                        <video (play)="controlVideo($event.target)"
                                                                            preload="none" *ngSwitchCase="'video'"
                                                                            width="240" controls
                                                                            [src]="item.message?.mediaSource == 'url' ? message?.parameters?.[0]?.video?.link  : (item.localImage ? item.message?.media?.url :  liveUrl + item.message?.media?.url)"></video>
                                                                        <div (click)="showDocPreview(item.message?.mediaSource == 'url' ? item?.message?.data?.[0]?.parameters?.[0]?.document?.link : (liveUrl + item.message.media.url))"
                                                                            *ngSwitchCase="'document'"
                                                                            class="document_div cursor_pointer">
                                                                            <img class="document_icon"
                                                                                src="../../../../../assets/images/chat/icons8-google-docs.svg"
                                                                                alt="pdf" />
                                                                            <p>{{ (item.message?.mediaSource
                                                                                == 'url' ?
                                                                                item?.message?.data?.[0]?.parameters?.[0].document?.filename
                                                                                :
                                                                                item.message.media.url?.split('/')[item.message.media.url?.split('/')?.length
                                                                                -1])?.length > 30 ?
                                                                                (item.message?.mediaSource
                                                                                == 'url' ?
                                                                                item?.message?.data?.[0]?.parameters?.[0].document?.filename
                                                                                :
                                                                                item.message.media.url?.split('/')[item.message.media.url?.split('/')?.length
                                                                                -1]).slice(0,30) + '...'
                                                                                :
                                                                                (item.message?.mediaSource
                                                                                == 'url' ?
                                                                                item?.message?.data?.[0]?.parameters?.[0].document?.filename
                                                                                :
                                                                                item.message.media.url?.split('/')[item.message.media.url?.split('/')?.length
                                                                                -1])}}
                                                                            </p>
                                                                        </div>
                                                                        <!-- <p class="img_url" *ngSwitchCase="'IMAGE'">IMAGE URL</p> -->
                                                                    </div>
                                                                </div>
                                                                <div *ngSwitchCase="'body'" class="">
                                                                    <p [innerHTML]="message.text"></p>
                                                                </div>
                                                                <div *ngSwitchCase="'footer'" class="">
                                                                    <p [innerHTML]="message.text"></p>
                                                                </div>
                                                                <div *ngSwitchCase="'buttons'" class=""
                                                                    [ngClass]="{'template_button_reply' : message?.buttons[0]?.type == 'QUICK_REPLY' , 'template_button_link links_btn' : message?.buttons[0]?.type == 'URL' || message?.buttons[0]?.type == 'PHONE_NUMBER'} ">
                                                                    <div [ngClass]="{'more_buttons' : message?.buttons?.length > 2} "
                                                                        *ngFor="let button of message?.buttons"
                                                                        class="single_button">
                                                                        <h6 *ngIf="button?.type == 'QUICK_REPLY'">{{
                                                                            button.text }}</h6>
                                                                        <div class="">
                                                                            <a *ngIf="button?.type == 'URL'"
                                                                                [href]="button?.url" target="_blank">{{
                                                                                button.text }}</a>
                                                                            <a *ngIf="button?.type == 'PHONE_NUMBER'"
                                                                                [href]="'tel:' + button?.phone_number">{{
                                                                                button.text }}</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div *ngSwitchCase="'text'" class="">
                                                        <p [innerHTML]="item?.message?.data?.text ? 
                                                        item?.message?.data?.text : item?.message?.data?.body">
                                                        </p>
                                                    </div>

                                                    <div *ngSwitchCase="'image'" class="sent_image">
                                                        <img nz-image class="img-fluid template_image"
                                                            [nzSrc]="item.localImage ? item?.message?.media?.url : 
                                                            (item?.message?.mediaSource == 'url' ? item?.message?.data?.link :  liveUrl + item?.message?.media?.url)"
                                                            alt="IMAGE URL" />
                                                        <p class="image_caption" *ngIf="item?.message?.data?.caption"
                                                            [innerHTML]="item?.message?.data?.caption">
                                                        </p>
                                                    </div>

                                                    <div *ngSwitchCase="'video'" class="sent_video">
                                                        <video (play)="controlVideo($event.target)" preload="none"
                                                            width="240"
                                                            [src]="item.localImage ? item?.message?.media?.url : 
                                                            (item?.message?.mediaSource == 'url' ? item?.message?.data?.link :  liveUrl + item?.message?.media?.url)"
                                                            controls></video>
                                                        <p class="image_caption" *ngIf="item?.message?.data?.caption"
                                                            [innerHTML]="item?.message?.data?.caption">
                                                        </p>
                                                    </div>

                                                    <div *ngSwitchCase="'document'"
                                                        (click)="showDocPreview(liveUrl + item?.message?.media?.url , item.localImage)"
                                                        class="document_div"
                                                        [ngClass]="{'cursor_pointer' : !item.localImage}">
                                                        <div [ngSwitch]="item?.message?.mediaSource == 'url' ? 
                                                        (item?.message?.data?.link).split('.')[item?.message?.data?.link?.split('.').length - 1 ] :
                                                        (item?.message?.data?.filename)?.split('.')[item?.message?.data?.filename?.split('.').length - 1 ]"
                                                            class="document_left">
                                                            <img *ngSwitchCase="'pdf'" class="document_icon"
                                                                src="../../../../../assets/images/chat/pdf_icon.svg"
                                                                alt="pdf" />
                                                            <img *ngSwitchCase="'doc'" class="document_icon"
                                                                src="../../../../../assets/images/chat/icons8-google-docs.svg"
                                                                alt="pdf" />
                                                            <img *ngSwitchCase="'docx'" class="document_icon"
                                                                src="../../../../../assets/images/chat/icons8-google-docs.svg"
                                                                alt="pdf" />
                                                            <img *ngSwitchCase="'xlsx'" class="document_icon"
                                                                src="../../../../../assets/images/chat/excel_file.svg"
                                                                alt="excel" />
                                                        </div>
                                                        <p>{{item?.message?.data?.filename ?
                                                            item?.message?.data?.filename :
                                                            (item?.message?.media?.url?.split('/')[item?.message?.media?.url?.split('/').length-1]?.slice(0,50)
                                                            + '...')}}
                                                        </p>
                                                        <a (click)="downloadLink($event)" target="_blank"
                                                            [href]="item.localImage ? sanitize(item?.message?.media?.url) :  liveUrl + item?.message?.media?.url">
                                                            <img class="download_icon"
                                                                src="../../../../../assets/images/chat/download_1.png"
                                                                alt="download" />
                                                        </a>
                                                    </div>

                                                    <div *ngSwitchCase="'audio'" class="">
                                                        <audio (play)="controlAudio($event.target)" controls
                                                            [src]="item.localImage ? item?.message?.media?.url :  
                                                            (item?.message?.mediaSource == 'url' ? item?.message?.data?.link :  liveUrl + item?.message?.media?.url)"></audio>
                                                    </div>

                                                    <div *ngSwitchCase="'location'" class="location_div">
                                                        <a class="location_image"
                                                            [href]="'https://www.google.com/maps/search/?api=1&amp;query=' + item?.message?.data?.latitude +  ',' + item?.message?.data?.longitude"
                                                            target="_blank" rel="noopener noreferrer">
                                                            <img class="img-fluid"
                                                                src="../../../../../assets/images/chat/map_icon.jpeg"
                                                                alt="map">
                                                        </a>
                                                        <div class="text-center location_link">
                                                            <a class=""
                                                                [href]="'https://www.google.com/maps/search/?api=1&amp;query=' + item?.message?.data?.latitude +  ',' + item?.message?.data?.longitude"
                                                                target="_blank" rel="noopener noreferrer">
                                                                <p class="">Show in Maps</p>
                                                            </a>
                                                        </div>
                                                    </div>

                                                    <div *ngSwitchCase="'interactive'" class="">
                                                        <div [ngSwitch]="item?.message?.data?.type" class="">
                                                            <div *ngSwitchCase="'product'" class="">
                                                                <div class="catalogue_detail_div">
                                                                    <div class="">
                                                                        <img nz-image class="img-fluid catalogue_image"
                                                                            [nzSrc]="item?.message?.data?.product?.image_url"
                                                                            alt="">
                                                                    </div>
                                                                    <div class="catalogue_price">
                                                                        <h6>{{item?.message?.data?.product?.name}}</h6>
                                                                        <span>{{item?.message?.data?.product?.currency +
                                                                            ' '
                                                                            +
                                                                            item?.message?.data?.product?.sale_price}}</span>
                                                                    </div>
                                                                </div>
                                                                <div class="catalogue_description">
                                                                    <p [innerHTML]="item?.message?.data?.desc"></p>
                                                                    <h6 class="view_details_text">
                                                                        <span class="list_menu_icon" nz-icon
                                                                            nzType="unordered-list"
                                                                            nzTheme="outline"></span>
                                                                        {{item?.message?.data?.type ==
                                                                        'product' ? 'View' : 'View items'}}
                                                                    </h6>
                                                                </div>
                                                            </div>

                                                            <div *ngSwitchCase="'product_list'" class="">
                                                                <div class="catalogue_detail_div">
                                                                    <div class="">
                                                                        <img nz-image class="img-fluid catalogue_image"
                                                                            [nzSrc]="item?.message?.data?.productList
                                                                ?.image_url" alt="">
                                                                    </div>
                                                                    <div class="catalogue_price">
                                                                        <h6>Products</h6>
                                                                        <span>
                                                                            {{item?.message?.data?.productList?.count}}
                                                                            items
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div class="catalogue_description">
                                                                    <p>{{item?.message?.data?.desc}}</p>
                                                                    <h6 class="view_details_text">
                                                                        <span class="list_menu_icon" nz-icon
                                                                            nzType="unordered-list"
                                                                            nzTheme="outline"></span>
                                                                        {{item?.message?.data?.type ==
                                                                        'product' ? 'View' : 'View items'}}
                                                                    </h6>
                                                                </div>
                                                            </div>

                                                            <div *ngSwitchCase="'button'" class="">
                                                                <div class="continue_order_main_div">
                                                                    <div class="continue_text">
                                                                        <p
                                                                            [innerHTML]="item?.message?.data?.header?.text">
                                                                        </p>
                                                                        <p
                                                                            [innerHTML]="item?.message?.data?.body?.text">
                                                                        </p>
                                                                        <p
                                                                            [innerHTML]="item?.message?.data?.footer?.text">
                                                                        </p>
                                                                    </div>
                                                                    <div class="continue_order_buttons">
                                                                        <div *ngFor="let button of item?.message?.data?.action?.buttons"
                                                                            class="custom_button">
                                                                            <p>{{button?.reply?.title}}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div *ngSwitchCase="'list'" class="">
                                                                <p><b>{{item?.message?.data?.header?.text}}</b></p>
                                                                <p [innerHTML]="item?.message?.data?.body?.text"></p>
                                                                <div class="catalogue_description">
                                                                    <h6 class="view_details_text">
                                                                        <span class="list_menu_icon" nz-icon
                                                                            nzType="unordered-list"
                                                                            nzTheme="outline"></span>
                                                                        View
                                                                    </h6>
                                                                </div>
                                                            </div>

                                                            <div *ngSwitchCase="'address_message'" class="">
                                                                <p><b>{{item?.message?.data?.body?.text}}</b></p>
                                                                <div class="catalogue_description">
                                                                    <h6 class="view_details_text">
                                                                        <span class="list_menu_icon" nz-icon
                                                                            nzType="unordered-list"
                                                                            nzTheme="outline"></span>
                                                                        Provide Address
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- bot message image -->
                                                    <div class="bot_icon_div">
                                                        <img nzTooltipTitle="Automatic bot" nzTooltipPlacement="top"
                                                            nz-tooltip *ngIf="item?.responseBy == 'bot'"
                                                            class="bot-icon"
                                                            src="../../../../../assets/images/chat/bot_icon.svg" alt="">
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="item?.responseBy == 'user'" class="message_responser">
                                                <span
                                                    [nzTooltipTitle]="item?.responseByUser?.firstName + ' ' +
                                                 (item?.responseByUser?.lastName ? item?.responseByUser?.lastName : '')"
                                                    nzTooltipPlacement="top" nz-tooltip>{{
                                                    (item?.responseByUser?.firstName).charAt(0) }}
                                                </span>
                                            </div>
                                        </div>

                                        <div *ngIf="item?.conversationType == 'received'"
                                            [ngClass]="{'list_message_width' : item?.repliedTo?.message?.data?.type == 'list'}"
                                            class="ctext-wrap">

                                            <div *ngIf="item?.repliedTo" class="reply_box">
                                                <div (click)="getRepliedChat(item?.repliedTo)"
                                                    [ngSwitch]="item?.repliedTo?.message.msgType" class="">
                                                    <div *ngSwitchCase="'image'" class="">
                                                        <img nz-image class="img-fluid template_image reply_image"
                                                            [nzSrc]="item?.repliedTo?.message?.mediaSource == 'url' ? item?.repliedTo?.message?.data?.link : liveUrl + item?.repliedTo?.message?.media?.url"
                                                            alt="">
                                                        <p class="image_caption">
                                                            {{item?.repliedTo?.message?.data?.caption}}</p>
                                                    </div>

                                                    <p *ngSwitchCase="'text'" [innerHTML]="item?.repliedTo?.message?.data?.text ?
                                                    item?.repliedTo?.message?.data?.text :
                                                    item?.repliedTo?.message?.data?.body"></p>

                                                    <div *ngSwitchCase="'video'" class="">
                                                        <video (play)="controlVideo($event.target)" preload="none"
                                                            width="240"
                                                            [src]="item?.repliedTo?.message?.mediaSource == 'url' ? item?.repliedTo?.message?.data?.link : liveUrl + item?.repliedTo?.message?.media?.url"
                                                            controls></video>
                                                        <p class="image_caption">
                                                            {{item?.repliedTo?.message?.data?.caption}}</p>
                                                    </div>

                                                    <audio *ngSwitchCase="'audio'" controls
                                                        (play)="controlAudio($event.target)"
                                                        [src]="item?.repliedTo?.message?.mediaSource == 'url' ? item?.repliedTo?.message?.data?.link : liveUrl + item?.repliedTo?.message?.media?.url"></audio>

                                                    <div *ngSwitchCase="'sticker'" class="">
                                                        <img nz-image class="img-fluid template_image reply_image"
                                                            [nzSrc]="liveUrl + item?.repliedTo?.message?.media?.url"
                                                            alt="sticker" />
                                                    </div>

                                                    <div *ngSwitchCase="'document'"
                                                        (click)="showDocPreview(liveUrl + item?.repliedTo?.message?.media?.url)"
                                                        class="cursor_pointer">
                                                        <div class="document_div">
                                                            <div [ngSwitch]="item?.repliedTo?.message?.mediaSource == 'url' ? 
                                                            item?.repliedTo?.message?.data?.link.split('.')[item?.repliedTo?.message?.data?.link.split('.').length - 1 ] :
                                                            item?.repliedTo?.message?.data?.filename.split('.')[item?.repliedTo?.message?.data?.filename.split('.').length - 1 ]"
                                                                class="">
                                                                <img *ngSwitchCase="'pdf'" class="document_icon"
                                                                    src="../../../../../assets/images/chat/pdf_icon.svg"
                                                                    alt="pdf" />
                                                                <img *ngSwitchCase="'doc'" class="document_icon"
                                                                    src="../../../../../assets/images/chat/icons8-google-docs.svg"
                                                                    alt="pdf" />
                                                                <img *ngSwitchCase="'docx'" class="document_icon"
                                                                    src="../../../../../assets/images/chat/icons8-google-docs.svg"
                                                                    alt="pdf" />
                                                                <img *ngSwitchCase="'xlsx'" class="document_icon"
                                                                    src="../../../../../assets/images/chat/excel_file.svg"
                                                                    alt="excel" />
                                                            </div>
                                                            <p>{{ item?.repliedTo?.message?.data?.filename }}</p>
                                                            <a (click)="downloadLink($event)" target="_blank"
                                                                [href]="liveUrl + item?.repliedTo?.message?.media?.url">
                                                                <img class="download_icon"
                                                                    src="../../../../../assets/images/chat/download_1.png"
                                                                    alt="download" />
                                                            </a>
                                                        </div>
                                                        <p>{{item?.repliedTo?.message?.data?.caption}}</p>
                                                    </div>


                                                    <div *ngSwitchCase="'template'"
                                                        class="template_preview received_end">
                                                        <div *ngFor="let message of item?.repliedTo?.message?.data"
                                                            class="mt-3">
                                                            <!-- <h6>{{message?.type}} :</h6> -->
                                                            <div [ngSwitch]="message?.type?.toLowerCase()" class="">
                                                                <div *ngSwitchCase="'header'" class="">
                                                                    <div [ngSwitch]="message?.format?.toLowerCase()"
                                                                        class="header_text">
                                                                        <p class="header_paragraph"
                                                                            *ngSwitchCase="'text'"
                                                                            [innerHTML]="message?.text"></p>
                                                                        <!-- <p class="header_paragraph" *ngSwitchCase="'TEXT'"
                                                                [innerHTML]="message.text"></p> -->
                                                                        <!-- <img *ngSwitchCase="'IMAGE'" class="img-fluid" [src]="message.image ? 'https://now6.getdesignworks.com/'+message.image : sampleSrc" alt="IMAGE URL"> -->
                                                                        <img nz-image *ngSwitchCase="'image'"
                                                                            class="img-fluid"
                                                                            [nzSrc]="item?.repliedTo?.message?.mediaSource == 'url' ? message.parameters?.[0]?.image?.link : (liveUrl + item?.repliedTo?.message?.media?.url)"
                                                                            alt="IMAGE URL" />
                                                                        <video (play)="controlVideo($event.target)"
                                                                            preload="none" *ngSwitchCase="'video'"
                                                                            width="240" controls
                                                                            [src]="item?.repliedTo?.message?.mediaSource == 'url' ? message.parameters?.[0]?.video?.link : (liveUrl + item?.repliedTo?.message?.media?.url)"></video>
                                                                        <div *ngSwitchCase="'document'"
                                                                            (click)="showDocPreview(item.repliedTo.message?.mediaSource == 'url' ? item.repliedTo?.message?.data?.[0]?.parameters?.[0]?.document?.link : (liveUrl + item.repliedTo.message.media.url))"
                                                                            class="document_div cursor_pointer">
                                                                            <img class="document_icon"
                                                                                src="../../../../../assets/images/chat/icons8-google-docs.svg"
                                                                                alt="pdf" />
                                                                            <p>{{ (item.repliedTo.message?.mediaSource
                                                                                == 'url' ?
                                                                                item.repliedTo?.message?.data?.[0]?.parameters?.[0].document?.filename
                                                                                :
                                                                                item.repliedTo.message.media.url?.split('/')[item.repliedTo.message.media.url?.split('/').length
                                                                                -1]).length > 30 ?
                                                                                (item.repliedTo.message?.mediaSource
                                                                                == 'url' ?
                                                                                item.repliedTo?.message?.data?.[0]?.parameters?.[0].document?.filename
                                                                                :
                                                                                item.repliedTo.message.media.url?.split('/')[item.repliedTo.message.media.url?.split('/').length
                                                                                -1]).slice(0,30) + '...'
                                                                                :
                                                                                (item.repliedTo.message?.mediaSource
                                                                                == 'url' ?
                                                                                item.repliedTo?.message?.data?.[0]?.parameters?.[0].document?.filename
                                                                                :
                                                                                item.repliedTo.message.media.url?.split('/')[item.repliedTo.message.media.url?.split('/').length
                                                                                -1])}}
                                                                            </p>
                                                                        </div>
                                                                        <!-- <p class="img_url" *ngSwitchCase="'IMAGE'">IMAGE URL</p> -->
                                                                    </div>
                                                                </div>
                                                                <div *ngSwitchCase="'body'" class="">
                                                                    <p [innerHTML]="message.text"></p>
                                                                </div>
                                                                <div *ngSwitchCase="'footer'" class="">
                                                                    <p [innerHTML]="message.text"></p>
                                                                </div>
                                                                <div *ngSwitchCase="'butons'" class=""
                                                                    [ngClass]="{'template_button_reply' : message?.buttons[0]?.type == 'QUICK_REPLY' , 'template_button_link links_btn' : message?.buttons[0]?.type == 'URL' || message?.buttons[0]?.type == 'PHONE_NUMBER'} ">
                                                                    <div *ngFor="let button of message.buttons"
                                                                        class="single_button">
                                                                        <h6 *ngIf="button?.type == 'QUICK_REPLY'">{{
                                                                            button.text }}</h6>
                                                                        <div class="">
                                                                            <a *ngIf="button?.type == 'URL'"
                                                                                [href]="button?.url" target="_blank">{{
                                                                                button.text }}</a>
                                                                            <a *ngIf="button?.type == 'PHONE_NUMBER'"
                                                                                [href]="'tel:' + button?.phone_number">{{
                                                                                button.text }}</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div *ngSwitchCase="'contacts'" class="contact_message">
                                                        <div class="contacts_user">
                                                            <img src="../../../../../assets/images/chat/contacts_list.svg"
                                                                alt="call">
                                                            <p
                                                                [innerHTML]="item?.repliedTo?.message?.data[0]?.name?.formatted_name">
                                                            </p>
                                                        </div>
                                                        <div class="contacts_details">
                                                            <p
                                                                (click)="viewContactDetails(item?.repliedTo?.message?.data)">
                                                                View Contact
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div *ngSwitchCase="'button'" class="">
                                                        <p [innerHTML]="item?.repliedTo?.message?.data?.text"></p>
                                                    </div>

                                                    <div *ngSwitchCase="'location'" class="location_div">
                                                        <a class="location_image"
                                                            [href]="'https://www.google.com/maps/search/?api=1&amp;query=' + item?.repliedTo?.message?.data?.latitude +  ',' + item?.repliedTo?.message?.data?.longitude"
                                                            target="_blank" rel="noopener noreferrer">
                                                            <img class="img-fluid reply_image"
                                                                src="../../../../../assets/images/chat/map_icon.jpeg"
                                                                alt="map">
                                                        </a>
                                                        <div class="text-center location_link">
                                                            <a class=""
                                                                [href]="'https://www.google.com/maps/search/?api=1&amp;query=' + item?.repliedTo?.message?.data?.latitude +  ',' + item?.repliedTo?.message?.data?.longitude"
                                                                target="_blank" rel="noopener noreferrer">
                                                                <p class="">Show in Maps</p>
                                                            </a>
                                                        </div>
                                                    </div>

                                                    <div *ngSwitchCase="'interactive'" class="location_div">
                                                        <div [ngSwitch]="item?.repliedTo?.message?.data?.type" class="">
                                                            <div *ngSwitchCase="'button'" class="">
                                                                <div class="continue_order_main_div">
                                                                    <div class="continue_text">
                                                                        <p
                                                                            [innerHTML]="item?.repliedTo?.message?.data?.header?.text">
                                                                        </p>
                                                                        <p
                                                                            [innerHTML]="item?.repliedTo?.message?.data?.body?.text">
                                                                        </p>
                                                                        <p
                                                                            [innerHTML]="item?.repliedTo?.message?.data?.footer?.text">
                                                                        </p>
                                                                    </div>
                                                                    <div class="continue_order_buttons">
                                                                        <div *ngFor="let button of item?.repliedTo?.message?.data?.action?.buttons"
                                                                            class="custom_button replies_button">
                                                                            <p>{{button?.reply?.title}}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div *ngSwitchCase="'list'" class="">
                                                                <p><b>{{item?.repliedTo?.message?.data?.header?.text}}</b>
                                                                </p>
                                                                <p
                                                                    [innerHTML]="item?.repliedTo?.message?.data?.body?.text">
                                                                </p>
                                                                <div class="catalogue_description">
                                                                    <h6 class="view_details_text">
                                                                        <span class="list_menu_icon" nz-icon
                                                                            nzType="unordered-list"
                                                                            nzTheme="outline"></span>
                                                                        View
                                                                    </h6>
                                                                </div>
                                                            </div>

                                                            <div *ngSwitchCase="'product_list'" class="">
                                                                <div class="catalogue_detail_div">
                                                                    <div class="">
                                                                        <img nz-image class="img-fluid catalogue_image"
                                                                            [nzSrc]="item?.repliedTo?.message?.data?.productList
                                                                ?.image_url" alt="">
                                                                    </div>
                                                                    <div class="catalogue_price">
                                                                        <h6>Products</h6>
                                                                        <span>
                                                                            {{item?.repliedTo?.message?.data?.productList?.count}}
                                                                            items
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div class="catalogue_description">
                                                                    <p>{{item?.repliedTo?.message?.data?.desc}}</p>
                                                                    <h6 class="view_details_text">
                                                                        <span class="list_menu_icon" nz-icon
                                                                            nzType="unordered-list"
                                                                            nzTheme="outline"></span>
                                                                        {{item?.repliedTo?.message?.data?.type ==
                                                                        'product' ? 'View' : 'View items'}}
                                                                    </h6>
                                                                </div>
                                                            </div>

                                                            <p *ngSwitchCase="'button_reply'"
                                                                [innerHTML]="item?.repliedTo?.message?.data?.button_reply?.title">
                                                            </p>

                                                            <div *ngSwitchCase="'list_reply'" class="">
                                                                <p>
                                                                    {{item?.repliedTo?.message?.data?.list_reply?.title}}
                                                                </p>
                                                                <p
                                                                    [innerHTML]="item?.repliedTo?.message?.data?.list_reply?.description">
                                                                </p>
                                                            </div>

                                                            <p class="nfm_reply_text" *ngSwitchCase="'nfm_reply'"
                                                                [innerHTML]="item?.repliedTo?.message?.data?.nfm_reply?.body">
                                                            </p>

                                                            <div *ngSwitchCase="'address_message'" class="">
                                                                <p><b>{{item?.repliedTo?.message?.data?.body?.text}}</b>
                                                                </p>
                                                                <div class="catalogue_description">
                                                                    <h6 class="view_details_text">
                                                                        <span class="list_menu_icon" nz-icon
                                                                            nzType="unordered-list"
                                                                            nzTheme="outline"></span>
                                                                        Provide Address
                                                                    </h6>
                                                                </div>
                                                            </div>

                                                            <div *ngSwitchCase="'product'" class="">
                                                                <h6
                                                                    [innerHTML]="item?.repliedTo?.message?.data?.product?.name">
                                                                </h6>
                                                                <span>{{item?.repliedTo?.message?.data?.product?.currency
                                                                    + ' '
                                                                    +item?.repliedTo?.message?.data?.product?.sale_price}}</span>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div *ngSwitchCase="'order'" class="">
                                                        <div class="">
                                                            <div class="order_box">
                                                                <h6>
                                                                    {{item?.repliedTo?.message?.data?.product_items?.length}}
                                                                    items
                                                                </h6>
                                                                <span>
                                                                    {{item?.repliedTo?.message?.data?.product_items[0]?.currency
                                                                    +
                                                                    ' '+
                                                                    (item?.repliedTo?.message?.data?.orderedCartTotal)}}
                                                                    (estimated total)
                                                                </span>
                                                            </div>
                                                            <div class="view_link">
                                                                <h6 (click)="viewCartDetails(item.repliedTo)">
                                                                    <span class="list_menu_icon" nz-icon
                                                                        nzType="unordered-list"
                                                                        nzTheme="outline"></span>
                                                                    View Items
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div [ngSwitch]="item?.message?.msgType" class="">
                                                <p *ngSwitchCase="'text'" [innerHTML]="item?.message?.data?.body">
                                                </p>

                                                <div class="" *ngSwitchCase="'image'">
                                                    <img nz-image class="img-fluid template_image"
                                                        [nzSrc]="liveUrl + item?.message?.media?.url" alt="IMAGE URL" />
                                                    <p class="image_caption">{{item?.message?.data?.caption}}</p>
                                                </div>

                                                <div (click)="showDocPreview(liveUrl + item.message.media.url)"
                                                    *ngSwitchCase="'document'" class="">
                                                    <div class="document_div">
                                                        <div [ngSwitch]=" item.message.data.filename.split('.')[item.message.data.filename.split('.').length - 1 ]"
                                                            class="">
                                                            <img *ngSwitchCase="'pdf'" class="document_icon"
                                                                src="../../../../../assets/images/chat/pdf_icon.svg"
                                                                alt="pdf" />
                                                            <img *ngSwitchCase="'doc'" class="document_icon"
                                                                src="../../../../../assets/images/chat/icons8-google-docs.svg"
                                                                alt="pdf" />
                                                            <img *ngSwitchCase="'docx'" class="document_icon"
                                                                src="../../../../../assets/images/chat/icons8-google-docs.svg"
                                                                alt="pdf" />
                                                            <img *ngSwitchCase="'xlsx'" class="document_icon"
                                                                src="../../../../../assets/images/chat/excel_file.svg"
                                                                alt="excel" />
                                                        </div>
                                                        <p>{{ item.message.data.filename }}</p>
                                                        <a (click)="downloadLink($event)" target="_blank"
                                                            [href]="liveUrl + item.message.media.url">
                                                            <img class="download_icon"
                                                                src="../../../../../assets/images/chat/download_1.png"
                                                                alt="download" />
                                                        </a>
                                                    </div>

                                                    <p>{{item?.message?.data?.caption}}</p>
                                                </div>

                                                <div *ngSwitchCase="'video'" class="">
                                                    <video (play)="controlVideo($event.target)" preload="none"
                                                        width="240" [src]="liveUrl + item?.message?.media?.url"
                                                        controls></video>
                                                    <p class="image_caption">{{item?.message?.data?.caption}}</p>
                                                </div>

                                                <div *ngSwitchCase="'sticker'" class="">
                                                    <img nz-image class="img-fluid template_image"
                                                        [nzSrc]="liveUrl + item?.message?.media?.url" alt="sticker" />
                                                </div>

                                                <div *ngSwitchCase="'audio'" class="">
                                                    <audio (play)="controlAudio($event.target)" controls
                                                        [src]="liveUrl + item?.message?.media?.url"></audio>
                                                </div>

                                                <div *ngSwitchCase="'contacts'" class="contact_message">
                                                    <div class="contacts_user">
                                                        <img src="../../../../../assets/images/chat/contacts_list.svg"
                                                            alt="call">
                                                        <p>{{item?.message?.data[0].name.formatted_name}}</p>
                                                    </div>
                                                    <div class="contacts_details">
                                                        <p (click)="viewContactDetails(item?.message?.data)">
                                                            View Contact
                                                        </p>
                                                    </div>
                                                </div>

                                                <div *ngSwitchCase="'button'" class="">
                                                    <p [innerHTML]="item?.message?.data?.text"></p>
                                                </div>

                                                <div *ngSwitchCase="'location'" class="location_div">
                                                    <a class="location_image"
                                                        [href]="'https://www.google.com/maps/search/?api=1&amp;query=' + item?.message?.data?.latitude +  ',' + item?.message?.data?.longitude"
                                                        target="_blank" rel="noopener noreferrer">
                                                        <img class="img-fluid"
                                                            src="../../../../../assets/images/chat/map_icon.jpeg"
                                                            alt="map">
                                                    </a>
                                                    <div class="text-center location_link">
                                                        <a class=""
                                                            [href]="'https://www.google.com/maps/search/?api=1&amp;query=' + item?.message?.data?.latitude +  ',' + item?.message?.data?.longitude"
                                                            target="_blank" rel="noopener noreferrer">
                                                            <p class="">Show in Maps</p>
                                                        </a>
                                                    </div>
                                                </div>

                                                <div *ngSwitchCase="'order'" class="">
                                                    <div class="">
                                                        <div class="order_box">
                                                            <h6>
                                                                {{item?.message?.data?.product_items?.length}} items
                                                            </h6>
                                                            <span>
                                                                {{item?.message?.data?.product_items[0]?.currency + ' '+
                                                                item?.message?.data?.orderedCartTotal}}
                                                                (estimated total)
                                                            </span>
                                                        </div>
                                                        <div class="view_link">
                                                            <h6 (click)="viewCartDetails(item)">
                                                                <span class="list_menu_icon" nz-icon
                                                                    nzType="unordered-list" nzTheme="outline"></span>
                                                                View Items
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div *ngSwitchCase="'interactive'" class="">
                                                    <div [ngSwitch]="item?.message?.data?.type" class="">
                                                        <p *ngSwitchCase="'button_reply'"
                                                            [innerHTML]="item?.message?.data?.button_reply?.title">
                                                        </p>
                                                        <div *ngSwitchCase="'list_reply'" class="">
                                                            <p>
                                                                {{item?.message?.data?.list_reply?.title}}
                                                            </p>
                                                            <p
                                                                [innerHTML]="item?.message?.data?.list_reply?.description">
                                                            </p>
                                                        </div>
                                                        <p class="nfm_reply_text" *ngSwitchCase="'nfm_reply'"
                                                            [innerHTML]="item?.message?.data?.nfm_reply?.body">
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div *ngIf="item?.conversationType == 'customer-assign-info'"
                                            class="assign_message_div">
                                            <p>
                                                {{(item?.customerAssignInfo?.from || item?.customerAssignInfo?.fromRef)
                                                ?
                                                'This conversation was assigned from ' +
                                                (item?.customerAssignInfo?.fromRef == 'User' ?
                                                item?.customerAssignInfo?.from?.firstName + ' ' +
                                                (item?.customerAssignInfo?.from?.lastName ?
                                                item?.customerAssignInfo?.from?.lastName : '') : 'Bot')
                                                +' to ' +
                                                (item?.customerAssignInfo?.toRef == 'User' ?
                                                item?.customerAssignInfo?.to?.firstName + ' ' +
                                                (item?.customerAssignInfo?.to?.lastName ?
                                                item?.customerAssignInfo?.to?.lastName : '') : 'Bot')
                                                : 'This conversation was assigned to ' +
                                                (item?.customerAssignInfo?.toRef == 'User' ?
                                                item?.customerAssignInfo?.to?.firstName + ' ' +
                                                (item?.customerAssignInfo?.to?.lastName ?
                                                item?.customerAssignInfo?.to?.lastName : ''): 'Bot')
                                                }}</p>
                                        </div>

                                        <div *ngIf="item?.conversationType == 'inbox-status-info'"
                                            class="assign_message_div">
                                            <p *ngIf="item?.message?.inboxStatusInfo?.actionBy == 'User'">
                                                {{
                                                item?.message?.inboxStatusInfo?.status == 'open' ?
                                                'This conversation was opened by ' +
                                                item?.message?.inboxStatusInfo?.userId?.firstName +
                                                (item?.message?.inboxStatusInfo?.userId?.lastName ?
                                                ' ' + item?.message?.inboxStatusInfo?.userId?.lastName : '')
                                                :
                                                'This conversation was closed by ' +
                                                item?.message?.inboxStatusInfo?.userId?.firstName +
                                                (item?.message?.inboxStatusInfo?.userId?.lastName ?
                                                ' ' + item?.message?.inboxStatusInfo?.userId?.lastName : '')
                                                }}
                                            </p>
                                            <p *ngIf="item?.message?.inboxStatusInfo?.actionBy == 'Customer'">
                                                {{
                                                item?.message?.inboxStatusInfo?.status == 'open' ?
                                                'This conversation was opened by Customer' :
                                                'This conversation was closed by Customer'
                                                }}
                                            </p>
                                        </div>

                                        <!-- <div *ngIf="item?.conversationType == 'sent-as-notes'"
                                            class="received_message_container"></div> -->

                                        <!-- <div class="card mb-1 shadow-none border text-start">
                                        <div class="p-2">
                                            <div class="row align-items-center">
                                                <div class="col-auto">
                                                    <div class="avatar-sm bg-primary text-white">
                                                        <span class="avatar-title rounded">.ZIP</span>
                                                    </div>
                                                </div>
                                                <div class="col ps-0">
                                                    <a href="javascript:void(0);" class="text-muted fw-bold">{{item.value.file}}</a>
                                                    <p class="mb-0">{{item.value.size}}</p>
                                                </div>
                                                <div class="col-auto">
                                                    <a href="javascript:void(0);" class="ps-3 fs-24">
                                                        <i class="bi bi-arrow-down-square"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->

                                        <!-- <div class="conversation-actions dropend" placement="end-top"
                                        *ngIf="item.status != 'sent'">
                                        <a href="javascript: void(0);" class="dropdown-toggle text-dark"
                                            nzTrigger="click" nz-dropdown [nzDropdownMenu]="menu">
                                            <span nz-icon nzType="more" nzTheme="outline"></span>
                                        </a>
                                        <nz-dropdown-menu #menu="nzDropdownMenu">
                                            <ul nz-menu nzSelectable>
                                                <li nz-menu-item>Reply</li>
                                                <li nz-menu-item>Starred</li>
                                                <li nz-menu-item>Delete</li>
                                                <li nz-menu-item>Copy</li>
                                            </ul>
                                        </nz-dropdown-menu>
                                    </div> -->
                                    </div>
                                    <p *ngIf="item?.conversationType != 'customer-assign-info' && item?.conversationType != 'inbox-status-info'"
                                        class="text-muted chat_time fs-12 mb-0 mt-1">
                                        {{today == moment(item?.time).format("YYYY-MM-DD") ?
                                        moment(item?.time).format("h:mm a") :
                                        moment(item?.time).format("Do MMM , h:mm a") }}
                                        <span
                                            [nzTooltipTitle]="item?.statuses?.read?.isRead || item?.statuses?.delivered?.isDelivered ? deliveryReport : ''"
                                            nzTooltipPlacement="top" nz-tooltip>
                                            <span [ngClass]="{'message_read' : item?.statuses?.read?.isRead}"
                                                *ngIf="item?.conversationType == 'sent' && item.status != 'failed'"
                                                nz-icon nzType="check" nzTheme="outline"></span>
                                            <span [ngClass]="{'message_read' : item?.statuses?.read?.isRead}"
                                                class="second_check"
                                                *ngIf="(item?.conversationType == 'sent' && item?.statuses?.delivered) || (item.conversationType == 'sent' && item?.statuses?.read?.isRead)"
                                                nz-icon nzType="check" nzTheme="outline">
                                            </span>

                                            <ng-container *ngIf="item.status == 'failed'"
                                                [ngSwitch]="getTypeOf(item?.errorData)" class="">
                                                <span *ngSwitchCase="'String'" [nzTooltipTitle]="item?.errorData"
                                                    [nzTooltipColor]="'#ff1b1beb'" nzTooltipPlacement="top" nz-tooltip
                                                    class="error_tip" nz-icon nzType="warning" nzTheme="fill">
                                                </span>
                                                <span *ngSwitchCase="'Object'"
                                                    [nzTooltipTitle]="item?.errorData?.error?.error_data?.details"
                                                    [nzTooltipColor]="'#ff1b1beb'" nzTooltipPlacement="top" nz-tooltip
                                                    class="error_tip" nz-icon nzType="warning" nzTheme="fill">
                                                </span>
                                                <span *ngSwitchCase="'Array'"
                                                    [nzTooltipTitle]="item?.errorData[0]?.title"
                                                    [nzTooltipColor]="'#ff1b1beb'" nzTooltipPlacement="top" nz-tooltip
                                                    class="error_tip" nz-icon nzType="warning" nzTheme="fill">
                                                </span>
                                            </ng-container>
                                        </span>
                                    </p>

                                    <!-- Delivery Report -->
                                    <ng-template class="" #deliveryReport>
                                        <!-- <p *ngIf="item?.statuses?.sent">Sent : {{moment(item?.statuses?.sent?.time).format('h:mm a')}}</p> -->
                                        <div *ngIf="item?.statuses?.read.isRead || item?.statuses?.delivered?.isDelivered"
                                            class="delivery_report_div">
                                            <p>Delivered :
                                                {{item?.statuses?.delivered?.isDelivered ?
                                                moment(item?.statuses?.delivered?.time).format('h:mm a') :
                                                moment(item?.statuses?.read?.time).format('h:mm a')}}</p>
                                            <p *ngIf="item?.statuses?.read?.isRead">Read :
                                                {{moment(item?.statuses?.read?.time).format('h:mm a')}}</p>
                                        </div>
                                    </ng-template>
                                </div>
                            </li>
                        </ng-container>
                        <!-- </ng-container> -->
                    </ul>
                </div>
            </div>


            <div class="mt-2 bg-light rounded ">
                <form class="needs-validation" name="chat-form" id="chat-form"
                    (ngSubmit)="chatForm.form.valid && sendChatMessage()" #chatForm="ngForm">
                    <div class="message_type">
                        <p (click)="changeMessageType('reply')"
                            [ngClass]="{'selected_message_type' : messageType == 'reply' , 'disabled_tag' : mainUserData?.accessTo?.isExpired || !selectedUser}">
                            Reply</p>
                        <p (click)="changeMessageType('notes')"
                            [ngClass]="{'selected_message_type' : messageType == 'notes' , 'disabled_tag' : mainUserData?.accessTo?.isExpired || !selectedUser}">
                            Notes</p>
                    </div>
                    <div class="row main_input_row " [ngClass]="{'notes_message' : messageType == 'notes'}">
                        <div *ngIf="!isRecording && !blobUrl" class="col-12 input_column mt-1">
                            <img class="whatsapp_icon" src="../../../../../assets/images/chat/whatsapp_icon.png" alt="">
                            <!-- canned response dropdown -->
                            <ul class="canned_response" *ngIf="showCannedResponse">
                                <li [innerHTML]="response.message" *ngFor="let response of cannedResponses"
                                    (click)="sendCannedMessage($event , response.message)">
                                </li>
                                <li class="empty_response"
                                    *ngIf="cannedResponses?.length == 0 && mainUserData?.role != 'teammate'">
                                    no quick messages available
                                    <span routerLink="/setting/quick-message" class="link_span">Click here</span> to add
                                    new
                                </li>
                                <li class="empty_response"
                                    *ngIf="cannedResponses?.length == 0 && mainUserData?.role == 'teammate'">
                                    no quick messages available
                                </li>
                            </ul>

                            <!-- <input [disabled]="chatHistory?.isConversationExpired" (focus)="onFocus()"
                                autocomplete="off" nz-input type="text" class="" (keyup)="inputChange()"
                                [placeholder]="chatHistory?.isConversationExpired ? 'The customer does not spoke you within last 24 hours' : 'Type your message...'"
                                [(ngModel)]="newMessage" name="message" #message="ngModel" [ngClass]="{'is-invalid': chatForm.submitted &&
                    message.invalid && (message.dirty || message.touched) }" required="" /> -->
                            <!-- <div *ngIf="chatForm.submitted && message.invalid && (message.dirty || message.touched)"
                                class="invalid-feedback">
                                Please enter your message
                            </div> -->
                            <textarea autofocus #bodyText (paste)="onPaste($event)"
                                [disabled]="chatHistory?.isConversationExpired || mainUserData?.accessTo?.isExpired"
                                (focus)="onFocus()" autocomplete="off" nz-input type="text" class=""
                                (keyup)="keyUpEvent()" (keypress)="inputChange($event)" (mouseup)="mouseUp()"
                                (select)="onTextSelect()"
                                [placeholder]="chatHistory?.isConversationExpired ? 'The customer does not spoke you within last 24 hours' : 'Type your message...'"
                                [(ngModel)]="newMessage" name="message" #message="ngModel" [ngClass]="{'is-invalid': chatForm.submitted &&
                                message.invalid && (message.dirty || message.touched) }" required=""
                                [nzAutosize]="{ minRows: 1 , maxRows:2}">
                            </textarea>
                        </div>
                        <!-- emoji box -->
                        <emoji-mart *ngIf="showEmoji" class="emoji-mart" set="{{set}}" (emojiSelect)="addEmoji($event)"
                            title="Pick your emoji…"></emoji-mart>
                        <div class="col-12 options_column mt-1">
                            <div class="btn-group w-100 template_options_group"
                                [ngClass]="{'end_div' : blobUrl || isRecording}">
                                <div *ngIf="!isRecording && !blobUrl" class="">
                                    <a [ngClass]="{'disabled_tag' : chatHistory?.isConversationExpired || mainUserData?.accessTo?.isExpired || messageType == 'notes'}"
                                        (click)="showCatalogue()" class="btn btn-light smile_icon">
                                        <span nz-icon nzType="shop" nzTheme="outline"></span>
                                    </a>
                                    <a [ngClass]="{'disabled_tag' : mainUserData?.accessTo?.isExpired || messageType == 'notes' || !selectedUser}"
                                        (click)="showTemplateModal()" class="btn btn-light smile_icon">
                                        <span nz-icon nzType="layout" nzTheme="outline"></span>
                                    </a>
                                    <a [ngClass]="{'disabled_tag' : chatHistory?.isConversationExpired || mainUserData?.accessTo?.isExpired || messageType == 'notes'}"
                                        class="btn btn-light file_upload">
                                        <!-- <span nz-icon nzType="paper-clip" nzTheme="outline"></span> -->
                                        <label for='media_upload'>
                                            <span nz-icon nzType="paper-clip" nzTheme="outline"></span>
                                        </label>
                                        <input #fileInput multiple (change)="getFile($event)" id="media_upload"
                                            type="file" [accept]="allFileTypes">
                                    </a>
                                    <nz-dropdown-menu #uploadMenu="nzDropdownMenu">
                                        <ul nz-menu class="upload_menu_list">
                                            <li nz-menu-item>
                                                <label for='media_upload'>
                                                    <i nz-icon nzType="upload" nzTheme="outline"></i>
                                                    From PC
                                                </label>
                                            </li>
                                            <li (click)="showMediaModal()" nz-menu-item>
                                                <label>
                                                    <i nz-icon nzType="file-image" nzTheme="outline"></i>
                                                    From Media
                                                </label>
                                            </li>
                                            <!-- <li nz-menu-item>
                                                <label>
                                                    <i nz-icon nzType="link" nzTheme="outline"></i>
                                                    Enter url
                                                </label>
                                            </li> -->
                                        </ul>
                                    </nz-dropdown-menu>
                                    <a [ngClass]="{'disabled_tag' : chatHistory?.isConversationExpired || mainUserData?.accessTo?.isExpired}"
                                        (click)="toggleEmoji()" class="btn btn-light smile_icon">
                                        <span nz-icon nzType="smile" nzTheme="outline"></span>
                                    </a>

                                    <a [ngClass]="{'selected_style' : selectedStyle == 'Bold', 'disabled_tag' : chatHistory?.isConversationExpired || mainUserData?.accessTo?.isExpired}"
                                        (click)="editText('bold')" class="btn btn-light smile_icon">
                                        <span nz-icon nzType="bold" nzTheme="outline"></span>
                                    </a>
                                    <a [ngClass]="{'selected_style' : selectedStyle == 'Italic', 'disabled_tag' : chatHistory?.isConversationExpired || mainUserData?.accessTo?.isExpired}"
                                        (click)="editText('italic')" class="btn btn-light smile_icon">
                                        <span nz-icon nzType="italic" nzTheme="outline"></span>
                                    </a>
                                    <a [ngClass]="{'selected_style' : selectedStyle == 'Strike', 'disabled_tag' : chatHistory?.isConversationExpired || mainUserData?.accessTo?.isExpired}"
                                        (click)="editText('strikethrough')" class="btn btn-light smile_icon">
                                        <span nz-icon nzType="strikethrough" nzTheme="outline"></span>
                                    </a>
                                </div>

                                <div class="d-grid input_right">
                                    <!-- <a [ngClass]="{'disabled_tag' : chatHistory?.isConversationExpired || mainUserData?.accessTo?.isExpired}"
                                        (click)="startRecording()" class="btn btn-light smile_icon">
                                        <span nz-icon nzType="audio" nzTheme="outline"></span>
                                    </a>

                                    <a [ngClass]="{'disabled_tag' : mainUserData?.accessTo?.isExpired || !selectedUser}"
                                        (click)="stopRecording()" class="btn btn-light smile_icon">
                                        <span nz-icon nzType="pause-circle" nzTheme="outline"></span>
                                    </a> -->

                                    <!-- <a *ngIf="!isRecording && blobUrl"
                                        [ngClass]="{'disabled_tag' : mainUserData?.accessTo?.isExpired || !selectedUser}"
                                        (click)="clearRecordedData()" class="btn btn-light smile_icon">
                                        <span nz-icon nzType="delete" nzTheme="outline"></span>
                                    </a>

                                    <audio *ngIf="!isRecording && blobUrl" controls>
                                        <source [src]="blobUrl" type="audio/webm">
                                    </audio>

                                    <div *ngIf="isRecording && !blobUrl" class="record_timer">
                                        <span class="pulse_outer">
                                            <span class="pulse"></span>
                                        </span>
                                        Recording Audio : {{recordedTime}}
                                    </div>

                                    <a *ngIf="!isRecording && !blobUrl"
                                        [ngClass]="{'disabled_tag' : chatHistory?.isConversationExpired || mainUserData?.accessTo?.isExpired}"
                                        (click)="startRecording()" class="btn btn-light smile_icon">
                                        <span nz-icon nzType="audio" nzTheme="outline"></span>
                                    </a>

                                    <a *ngIf="isRecording && !blobUrl"
                                        [ngClass]="{'disabled_tag' : mainUserData?.accessTo?.isExpired || !selectedUser}"
                                        (click)="stopRecording()" class="btn btn-light smile_icon">
                                        <span nz-icon nzType="pause-circle" nzTheme="outline"></span>
                                    </a> -->

                                    <button *ngIf="blobUrl" (click)="uploadMedia()" nz-button nzType="primary"
                                        type="button" class="chat-send">
                                        Send <span class="send_icon" nz-icon nzType="send" nzTheme="outline"></span>
                                    </button>

                                    <button *ngIf="!isRecording && !blobUrl" nz-button nzType="primary" type="submit"
                                        [disabled]="!chatForm.form.valid" class="chat-send">
                                        Send <span class="send_icon" nz-icon nzType="send" nzTheme="outline"></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

                <!-- <button class="start-button" *ngIf="!isRecording && !blobUrl" (click)="startRecording()">
                    Start Recording
                </button> -->

                <!-- <button class="stop-button" *ngIf="isRecording && !blobUrl" (click)="stopRecording()">
                    Stop Recording
                </button> -->

                <!-- <button class="cancel-button" *ngIf="!isRecording && blobUrl" (click)="clearRecordedData()">
                    Clear Recording
                </button> -->


                <!-- <button *ngIf="!isRecording && blobUrl" type="button" (click)="uploadMedia()">send</button> -->

                <!-- <div *ngIf="isRecording && !blobUrl"> {{recordedTime}} </div> -->
                <!-- <div>
                    <audio *ngIf="!isRecording && blobUrl" controls>
                        <source [src]="blobUrl" type="audio/webm">
                    </audio>
                </div> -->
                <div (click)="scrollButton()" [ngClass]="{'show_scroll_btn' : scrollPosition == 'at top'}"
                    class="scroll_down_icon">
                    <img class="img-fluid" src="../../../../../assets/images/logo/scroll_down_icon.png" alt="">
                </div>
            </div>
        </div>
    </div>
    <!-- End card-body -->

    <ngx-spinner *ngIf="areaSpinner" bdColor="rgb(255,255,255)" size="medium" color="#000000"
        type="ball-clip-rotate-pulse" [fullScreen]="false">
    </ngx-spinner>
</nz-card>
<!-- End card -->


<!-- PREVIEW MODAL SECTION STARTS -->

<nz-modal [(nzVisible)]="previewModal" [nzFooter]="null" nzTitle="" nzCentered (nzOnCancel)="closePreviewModal()">
    <ng-container *nzModalContent>

        <div class="row mt-3">
            <div class="col-12 preview_column mb-3">
                <div [ngSwitch]="displayMedia[previewDoc]?.type" class="image_div preview_image_div">
                    <img nz-image *ngIf="imageTypes.indexOf(displayMedia[previewDoc]?.type) != -1" class="img-fluid"
                        [nzSrc]="displayMedia[previewDoc]?.url" alt="">
                    <!-- <img *ngSwitchCase="'image/jpg'" class="img-fluid" [src]="displayMedia[previewDoc]?.url" alt="">
                    <img *ngSwitchCase="'image/jpeg'" class="img-fluid" [src]="displayMedia[previewDoc]?.url" alt=""> -->
                    <video (play)="controlVideo($event.target)" preload="none"
                        *ngIf="videoTypes.indexOf(displayMedia[previewDoc]?.type) != -1" width="100%" height="100%"
                        [src]="displayMedia[previewDoc]?.url" controls></video>
                    <img *ngIf="docTypes.indexOf(displayMedia[previewDoc]?.type) != -1" (click)="preview(media , i)"
                        class="img-fluid" src="../../../../../assets/images/chat/icons8-google-docs.svg" alt="">
                    <audio (play)="controlAudio($event.target)"
                        *ngIf="audioTypes.indexOf(displayMedia[previewDoc]?.type) != -1" controls
                        [src]="displayMedia[previewDoc]?.url"></audio>
                </div>
            </div>

            <div class="preview_bottom">
                <div *ngFor="let media of displayMedia ; let i = index"
                    [ngClass]=" {'preview_file' : previewFile == media}" class="image_div preview_img_div">
                    <img *ngIf="imageTypes.indexOf(media?.type) != -1" (click)="preview(media , i)" class="img-fluid"
                        [src]="media?.url" alt="">
                    <img *ngIf="videoTypes.indexOf(media?.type) != -1" (click)="preview(media , i)" class="img-fluid"
                        src="../../../../../assets/images/logo/videoIcon.svg" alt="">
                    <!-- <video (play)="controlVideo($event.target)" preload="none"
                            *ngIf="videoTypes.indexOf(media?.type) != -1" (click)="preview(media , i)" width="100%"
                            height="100%" [src]="media?.url"></video> -->
                    <div *ngIf="docTypes.indexOf(media?.type) != -1" (click)="preview(media , i)" class="doc_preview">
                        <img class="img-fluid" src="../../../../../assets/images/chat/icons8-google-docs.svg" alt="">
                        <span>{{media?.name.slice(0,10) + '...'}}</span>
                    </div>
                    <img (click)="preview(media , i)" class="img-fluid" *ngIf="audioTypes.indexOf(media?.type) != -1"
                        src="../../../../../assets/images/chat/audio_icon.png" alt="">
                </div>
            </div>
        </div>

        <div class="send_btn mt-3">
            <input
                [disabled]="docTypes.indexOf(displayMedia[previewDoc]?.type) != -1 || displayMedia[previewDoc]?.type.includes('audio')"
                class="caption_input"
                [placeholder]="docTypes.indexOf(displayMedia[previewDoc]?.type) != -1 || displayMedia[previewDoc]?.type.includes('audio') ? 'File type does not support caption ' : 'Enter your text' "
                [(ngModel)]="displayMedia[previewDoc].captionText" nz-input type="text">
            <button class="file_upload_btn" [nzLoading]="uploading" (click)="uploadMedia()" nz-button nzType="text">
                <span nz-icon nzType="send" nzTheme="outline"></span>
            </button>
        </div>
    </ng-container>

</nz-modal>

<!-- PREVIEW MODAL SECTION ENDS -->

<!-- TEMPLATE MODAL SECTION STARTS -->

<nz-modal [nzWidth]="modalWidth" [(nzVisible)]="templateModal" [nzFooter]="null"
    [nzTitle]="getVariableValue ? 'Update Values' : 'Select Template'" nzCentered (nzOnCancel)="closeTemplateModal()">
    <ng-container *nzModalContent>
        <div *ngIf="!getVariableValue" class="">
            <div infinite-scroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="20"
                (scrolled)="bottomScrolled()" [scrollWindow]="false" class="row template_row template_preview_modal">
                <!-- <div class="col-4 template_names_column">
                    <div infinite-scroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="20"
                        (scrolled)="bottomScrolled()" [scrollWindow]="false" class="template_names">
                        <ul>
                            <li [ngClass]="{'selected_template' : messageTemplateSelected?._id == template?._id} "
                                (click)="showPreview(template)" *ngFor="let template of templateData">{{template.name}}
                            </li>
                        </ul>
                        <nz-spin [nzSpinning]="templateSpinner" class="end_spinner" nzSimple></nz-spin>
                    </div>
                </div> -->
                <div class="preview_top_section col-12">
                    <div class="filter">
                        <!-- <button nzTrigger="click" nz-dropdown [nzDropdownMenu]="filterOptions" type="button" nz-button
                            nzType="default">
                            <span nz-icon nzType="filter" nzTheme="outline"></span> Filters
                        </button> -->
                        <nz-dropdown-menu #filterOptions="nzDropdownMenu">
                            <ul class="filter_ul" nz-menu>
                                <li class="category_filter_options" *ngFor="let category of templateCategories">
                                    <label [nzChecked]="templateFilterArr.indexOf(category.value) >= 0"
                                        (nzCheckedChange)="templateCategoryFilter(category.value , $event)" nz-checkbox>
                                        {{category.label}}
                                    </label>
                                </li>
                            </ul>
                        </nz-dropdown-menu>
                    </div>
                    <div class="search">
                        <div class="toggle_options">
                            <nz-input-group class="" [nzPrefix]="suffixIconSearch">
                                <input [(ngModel)]="searchText" (keyup)="searchTemplates()"
                                    (keyup.enter)="searchTemplates()" autocomplete="off" type="text" nz-input
                                    placeholder="Search items.." />
                            </nz-input-group>
                            <ng-template #suffixIconSearch>
                                <span nz-icon nzType="search"></span>
                            </ng-template>
                        </div>
                    </div>
                    <!-- <div class="approved">
                        <label class="" [nzChecked]="true" nz-checkbox>Only approved Template</label>
                    </div> -->
                </div>

                <ng-container *ngFor="let template of templateData">
                    <div *ngIf="templateData?.length > 0" class="col-lg-4 ">
                        <div class="template_col" (click)="showPreview(template)">
                            <div class="preview_heading">
                                <h5 [nzTooltipTitle]="template.name" nzTooltipPlacement="top" nz-tooltip>
                                    {{template.name.length > 23 ? template.name.slice(0,23) + '...' : template.name}}
                                </h5>
                                <label class="template_checkbox" nz-checkbox
                                    [nzChecked]="messageTemplateSelected?._id == template?._id"
                                    (nzCheckedChange)="showPreview(template)"></label>
                            </div>
                            <div class="preview_template">
                                <div class="inner_template">
                                    <div *ngFor="let message of template.components" class="mb-3">
                                        <!-- <h6>{{message?.type}} :</h6> -->
                                        <div [ngSwitch]="message?.type" class="">
                                            <div *ngSwitchCase="'HEADER'" class="">
                                                <div [ngSwitch]="message?.format" class="header_text">
                                                    <p *ngSwitchCase="'TEXT'" [innerHTML]="message.text"></p>
                                                    <!-- <img *ngSwitchCase="'IMAGE'" class="img-fluid" [src]="message.example.header_handle"
                                        alt=""> -->
                                                    <p class="img_url" *ngSwitchCase="'IMAGE'">
                                                        <span nz-icon nzType="file-image" nzTheme="outline"></span>
                                                        IMAGE
                                                    </p>
                                                    <p class="img_url" *ngSwitchCase="'VIDEO'">
                                                        <span nz-icon nzType="video-camera" nzTheme="outline"></span>
                                                        VIDEO
                                                    </p>
                                                    <p class="img_url" *ngSwitchCase="'DOCUMENT'">
                                                        <span nz-icon nzType="file-done" nzTheme="outline"></span>
                                                        DOCUMENT
                                                    </p>
                                                </div>

                                            </div>
                                            <div *ngSwitchCase="'BODY'" class="">
                                                <p [innerHTML]="((message?.text))"></p>
                                            </div>
                                            <div *ngSwitchCase="'FOOTER'" class="">
                                                <p [innerHTML]="message?.text"></p>
                                            </div>
                                            <div *ngSwitchCase="'BUTTONS'" class="">
                                                <div *ngFor="let button of message?.buttons" class="template_buttons">
                                                    <h6>{{button?.text}}</h6>
                                                    <!-- <div [ngSwitch]="button.type" class="">
                                            <p *ngSwitchCase="'URL'">url : {{button.url}}</p>
                                            <p *ngSwitchCase="'PHONE_NUMBER'">{{button.phone_number}}</p>
                                        </div> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="template_bottom">
                                <div class="bottom_left">
                                    <span class="template_lang">{{template?.language}}</span>
                                    <span class="template_lang">{{template?.category}}</span>
                                </div>
                                <span class="template_status">{{template?.status}}</span>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <div *ngIf="templateSpinner" class="col-12">
                    <nz-spin [nzSpinning]="templateSpinner" class="end_spinner" nzSimple></nz-spin>
                </div>

                <div *ngIf="templateData?.length == 0" class="empty_templates col-12">
                    <p>No Templates were available</p>
                </div>
            </div>


            <div class="choose_button mt-3">
                <a [disable]="syncing" (click)="syncTemplate()" class="sync_btn">
                    <span [ngClass]="{'sync_loader' : syncing}" nz-icon nzType="sync" nzTheme="outline"></span>
                    {{syncing ? 'Syncing' : 'Sync'}}
                </a>
                <button [disabled]="!messageTemplateSelected" [nzLoading]="templateLoading" (click)="selectTemplate()"
                    type="button" class="sighup_button " nz-button nzType="primary">
                    Next
                </button>
            </div>
        </div>

        <div *ngIf="getVariableValue" class="">
            <div class="">
                <div class="row variables_row">
                    <div class="col-lg-8 variable_values">
                        <div *ngIf="variableData?.mediaData?.hasMedia" class="upload-button save-button mb-3 mt-3">
                            <div class="preview_and_label">
                                <label for='input-file' class="preview_sample">
                                    <span nz-icon nzType="cloud-upload" nzTheme="outline"></span>
                                    <p *ngIf="variableData?.mediaData?.type == 'IMAGE'">{{templateMediaPreview ?
                                        'Change' : 'Upload'}} Image</p>
                                    <p *ngIf="variableData?.mediaData?.type == 'VIDEO'">{{templateMediaPreview ?
                                        'Change' : 'Upload'}} Video</p>
                                    <p *ngIf="variableData?.mediaData?.type == 'DOCUMENT'">{{templateMediaPreview ?
                                        'Change' : 'Upload'}} Document</p>
                                </label>
                                <img nz-image *ngIf="templateMediaPreview && variableData?.mediaData?.type == 'IMAGE'"
                                    class="uploaded_img" [nzSrc]="templateMediaPreview" alt="">
                                <video (play)="controlVideo($event.target)" preload="none"
                                    *ngIf="templateMediaPreview && variableData?.mediaData?.type == 'VIDEO'"
                                    class="uploaded_img uploaded_video" [src]="templateMediaPreview" controls></video>
                                <span class="doc_preview_span"
                                    *ngIf="templateMediaPreview && variableData?.mediaData?.type == 'DOCUMENT'">
                                    <img class="template_doc"
                                        src="../../../../../assets/images/chat/icons8-google-docs.svg" alt="pdf" />
                                    <p class="document_name">
                                        {{templateMedia?.name?.length > 30 ?
                                        (templateMedia?.name?.slice(0, 30) + '...') :
                                        templateMedia?.name}}
                                    </p>
                                </span>
                            </div>
                            <!-- <label *ngIf="variableData?.mediaData?.type == 'IMAGE'" for='input-file'>Upload
                                Image</label>
                            <label *ngIf="variableData?.mediaData?.type == 'VIDEO'" for='input-file'>Upload
                                Video</label>
                            <label *ngIf="variableData?.mediaData?.type == 'DOCUMENT'" for='input-file'>Upload
                                Document</label> -->
                            <input (change)="getTemplateMedia($event)" class="upload_input" id='input-file' type='file'
                                [accept]="acceptedTypes">
                        </div>

                        <div *ngIf="variableData?.variableTemplate?.length" class="mt-3 variable_data_div">
                            <nz-table [nzShowPagination]="false" [nzScroll]="{ y: '500px', x : '500px' }"
                                #variableDataTable [nzData]="variableData.variableTemplate">
                                <thead>
                                    <tr>
                                        <th nzWidth="90px"></th>
                                        <th nzWidth="100px">Variable</th>
                                        <th nzWidth="200px">Value </th>
                                        <th nzWidth="200px">Fallback value </th>
                                    </tr>
                                </thead>
                                <tbody *ngFor="let template of variableDataTable.data">
                                    <tr *ngFor="let variable of template.variables ; let i = index ">
                                        <td>{{i == 0 ? template.type : ''}}</td>
                                        <td>
                                            <input [disabled]="true" [readonly]="true" autocomplete="off"
                                                [value]="'{{' + (i+1) + '}}'" nz-input>
                                        </td>
                                        <td>
                                            <nz-select class="w-100 select_dropdown" nzPlaceHolder="Select Value"
                                                [(ngModel)]="variable.field">
                                                <nz-option nzLabel="Use Fallback Value" nzValue="useFallbackValue">
                                                </nz-option>
                                                <nz-option *ngFor="let key of fieldData" [nzLabel]="key?.label"
                                                    [nzValue]="key?.name">
                                                </nz-option>

                                            </nz-select>
                                        </td>
                                        <td>
                                            <input [(ngModel)]="variable.fallbackValue" autocomplete="off" nz-input>
                                        </td>
                                    </tr>
                                </tbody>
                            </nz-table>
                        </div>
                    </div>

                    <div class="col-lg-4">
                        <div class="template_col">
                            <div class="preview_heading">
                                <h5 [nzTooltipTitle]="messageTemplateSelected.name" nzTooltipPlacement="top" nz-tooltip>
                                    {{messageTemplateSelected.name.length > 23 ?
                                    messageTemplateSelected.name.slice(0,23) + '...' : messageTemplateSelected.name}}
                                </h5>
                            </div>
                            <div class="preview_template">
                                <div class="inner_template">
                                    <div *ngFor="let message of messageTemplateSelected.components" class="mb-3">
                                        <div [ngSwitch]="message?.type" class="">
                                            <div *ngSwitchCase="'HEADER'" class="">
                                                <div [ngSwitch]="message?.format" class="header_text">
                                                    <p *ngSwitchCase="'TEXT'" [innerHTML]="message.text"></p>
                                                    <p class="img_url" *ngSwitchCase="'IMAGE'">
                                                        <span nz-icon nzType="file-image" nzTheme="outline"></span>
                                                        IMAGE
                                                    </p>
                                                    <p class="img_url" *ngSwitchCase="'VIDEO'">
                                                        <span nz-icon nzType="video-camera" nzTheme="outline"></span>
                                                        VIDEO
                                                    </p>
                                                    <p class="img_url" *ngSwitchCase="'DOCUMENT'">
                                                        <span nz-icon nzType="file-done" nzTheme="outline"></span>
                                                        DOCUMENT
                                                    </p>
                                                </div>

                                            </div>
                                            <div *ngSwitchCase="'BODY'" class="">
                                                <p [innerHTML]="((message?.text))"></p>
                                            </div>
                                            <div *ngSwitchCase="'FOOTER'" class="">
                                                <p [innerHTML]="message?.text"></p>
                                            </div>
                                            <div *ngSwitchCase="'BUTTONS'" class="">
                                                <div *ngFor="let button of message?.buttons" class="template_buttons">
                                                    <h6>{{button?.text}}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="template_bottom">
                                <div class="bottom_left">
                                    <span class="template_lang">{{messageTemplateSelected?.language}}</span>
                                    <span class="template_lang">{{messageTemplateSelected?.category}}</span>
                                </div>
                                <span class="template_status">{{messageTemplateSelected?.status}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="get_value_buttons mt-3">
                <button (click)="backToChooseTemplate()" type="button" class="sighup_button mt-3" nz-button
                    nzType="default">
                    Back
                </button>

                <button [nzLoading]="sendingTemplateMedia" (click)="submitTemplate()" type="button"
                    class="sighup_button mt-3" nz-button nzType="primary">
                    Send
                </button>
            </div>
        </div>
        <ngx-spinner *ngIf="modalSpinner" bdColor="rgb(255,255,255)" size="medium" color="#000000"
            type="ball-clip-rotate-pulse" [fullScreen]="false">
        </ngx-spinner>
    </ng-container>

</nz-modal>

<!-- TEMPLATE MODAL SECTION ENDS -->

<!-- CONTACT DETAILS MODAL SECTION STARTS -->

<nz-modal [(nzVisible)]="contactModal" [nzFooter]="null" nzTitle="Contact Details" nzCentered
    (nzOnCancel)="closeContactModal()">
    <ng-container *nzModalContent>
        <div class="">
            <div class="contact_detail_user">
                <img src="../../../../../assets/images/chat/contacts_list.svg" alt="call">
                <p>{{detailedContacts[0].name.formatted_name}}</p>
            </div>

            <div class="mt-3">
                <div *ngFor="let contact of detailedContacts" class="">
                    <div *ngIf="contact.phones" class="">
                        <div *ngFor="let phone of contact.phones" class="phone_numbers">
                            <p>{{phone.type.toLowerCase()}}</p>
                            <h6>{{phone.phone}}</h6>
                        </div>
                    </div>
                    <div *ngIf="contact.emails" class="">
                        <div *ngFor="let email of contact.emails" class="phone_numbers">
                            <p>{{email.type.toLowerCase()}}</p>
                            <h6>{{email.email}}</h6>
                        </div>
                    </div>
                    <div *ngIf="contact.org" class="">
                        <div class="phone_numbers">
                            <p>Company</p>
                            <h6>{{contact.org.company}}</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</nz-modal>

<!-- CONTACT DETAILS MODAL SECTION ENDS -->

<!-- CART DETAILS MODAL SECTION STARTS -->

<nz-modal [(nzVisible)]="cartDetailModal" [nzFooter]="null" nzTitle="Cart Items" nzCentered
    (nzOnCancel)="closeCartDetailModal()">
    <ng-container *nzModalContent>
        <div class="">
            <h5>{{cartItemSelected?.message?.data?.product_items?.length}} items</h5>
            <p>{{cartItemSelected?.message?.data?.product_items[0]?.currency + ' '+
                cartItemSelected?.message?.data?.orderedCartTotal}} (estimated)</p>
        </div>

        <ul class="product_list">
            <li class="product_list_item"
                *ngFor="let product of cartItemSelected?.message?.data?.product_items ; let k = index">
                <div class="product_main_div mb-3">
                    <div class="">
                        <img class="img-fluid product_list_image"
                            [src]="cartItemSelected?.message?.itemData[k]?.image_url" alt="product image">
                    </div>
                    <div class="">
                        <h6>{{cartItemSelected?.message?.itemData[k]?.name}}</h6>
                        <span>{{product.currency + ' ' + product.item_price + ' Quantity '+ product.quantity}}</span>
                    </div>
                </div>
            </li>
        </ul>

    </ng-container>
</nz-modal>

<!-- CART DETAILS MODAL SECTION ENDS -->


<!-- sandbox message modal -->

<nz-modal [nzWidth]="'80%'" [(nzVisible)]="sandBoxModal" [nzFooter]="null" nzTitle="Sandbox Message" nzCentered
    (nzOnCancel)="closeSandBoxModal()">
    <ng-container *nzModalContent>
        <app-sandbox-message></app-sandbox-message>
    </ng-container>
</nz-modal>

<!-- custom media modal -->

<nz-modal [nzWidth]="'80%'" [nzBodyStyle]="{'padding' : '0px'}" [(nzVisible)]="mediaModal" [nzFooter]="null"
    nzTitle="Select Media" nzCentered (nzOnCancel)="closeMediaModal()">
    <ng-container *nzModalContent>
        <app-content-page [selectedTab]="'media'" (selectedData)="selectedData($event)"></app-content-page>
    </ng-container>
</nz-modal>


<!-- document preview modal -->
<nz-modal [nzBodyStyle]="{'padding' : '0px'}" [(nzVisible)]="docPreview" nzTitle="" nzCentered nzWidth="90%"
    [nzClosable]="false" [nzFooter]="null" (nzOnCancel)="closeDocPreview()">
    <ng-container *nzModalContent>
        <ngx-doc-viewer [url]="docUrl" viewer="google" style="width:100%;height:90vh;">
        </ngx-doc-viewer>

        <ngx-spinner *ngIf="docSpinner" bdColor="rgb(255,255,255)" size="medium" color="#000000"
            type="ball-clip-rotate-pulse" [fullScreen]="false">
        </ngx-spinner>
    </ng-container>
</nz-modal>

<!-- line 885 -->
<!-- [ngClass]="{'disabled_tag' : chatHistory?.isConversationExpired || mainUserData?.accessTo?.isExpired || chatExpired}" -->

<!-- line 1077 nz-dropdown nzTrigger="click" [nzDropdownMenu]="uploadMenu"
                                        [nzPlacement]="'topLeft'" -->