<div class="common-layout {{ 'is-' + selectedHeaderColor }}" [ngClass]="{
    'is-folded': isFolded,
    'is-side-nav-dark' : isSideNavDark,
    'is-expand' : isExpand }">


    <ng-container *ngIf="UserData?.accessTo?.sandboxId">
        <div *ngIf="hideNavbarSidnav" class="expiry_alert_section expiry_alert_section_web">
            <div class="expiry_alert_second_section_web">
                <p *ngIf="!UserData?.accessTo?.isExpired && !UserData?.accessTo?.curSubsPlan" class="">
                    {{'You are currently using Own Chat with test Number ' +
                    (UserData?.accessTo?.phoneNumber ? UserData?.accessTo?.phoneNumber : '+91 8015188187') + ' -'}}


                    <span *ngIf="moment(UserData?.accessTo?.expiresAt).diff(now , 'days') > 0">
                        {{ moment(UserData?.accessTo?.expiresAt).diff(now , 'days') + ' days left'}}
                    </span>
                    <span
                        *ngIf="moment(UserData?.accessTo?.expiresAt).diff(now , 'days') == 0 && moment(UserData?.accessTo?.expiresAt).diff(now , 'hours') > 0">
                        {{moment(UserData?.accessTo?.expiresAt).diff(now , 'hours') + ' hours left'}}
                    </span>
                    <span *ngIf="moment(UserData?.accessTo?.expiresAt).diff(now , 'hours') == 0">
                        {{ moment(UserData?.accessTo?.expiresAt).diff(now , 'minutes') + ' minutes left'}}
                    </span>

                </p>

                <p *ngIf="!UserData?.accessTo?.isExpired && UserData?.accessTo?.curSubsPlan" class="">
                    You have an current active subscription plan
                </p>

                <p *ngIf="UserData?.accessTo?.isExpired" class="">
                    {{'Your subscription was Expired'}}
                </p>
                <div class="get_number_btn">
                    <button (click)="ownNumber()" type="button" class="own_number_btn">
                        Get your own number
                    </button>
                    <a (click)="arrangeMeeting()" class="">
                        Connect with our product expert
                    </a>
                </div>
            </div>
        </div>
    </ng-container>

    <div [ngClass]="{'profile_btn_div-purchase' : !UserData?.accessTo?.sandboxId} "
        *ngIf="hideNavbarSidnav && !currentUrl.includes('campaign/create-campaign')" class="profile_btn_div">
        <!-- <div (click)="toSettingPage()" class="setting_div">
            <span class="setting_icon" nz-icon nzType="setting" nzTheme="outline"></span>
        </div> -->

        <iframe id="serviceFrameSend" src="../../../assets/catalogue_array/test.html" width="240px" height="40px"
            frameborder="0">
        </iframe>

        <div class="connection_details">
            <img src="../../../assets/images/chat/whatsapp_icon.png" alt="">
            <div class="connection_details_span">
                <span class="connection_status">Connected</span>
                <span class="connection_number">{{UserData?.accessTo?.phoneNumber}}</span>
            </div>
        </div>

        <a class="profile_btn">
            <div class="left_icons">
                <!-- <span class="setting_icon" nz-icon nzType="customer-service" nzTheme="outline"></span>
                <span class="setting_icon" nz-icon nzType="question-circle" nzTheme="outline"></span> -->
                <span (click)="toSettingPage()" class="setting_icon" nz-icon nzType="setting" nzTheme="outline"></span>
            </div>
            <div nzTrigger="click" nz-dropdown [nzDropdownMenu]="menu" class="profile_data">
                <img class="profile_image"
                    [src]="UserData?.profileImage ? liveUrl + UserData?.profileImage: '../../../assets/images/logo/profile.png'"
                    alt="">
                <span class="user_name_sapn">
                    <!-- {{UserData?.firstName + ' ' + (UserData?.lastName ? UserData?.lastName : '') }} -->
                    {{(UserData?.firstName + ' ' + (UserData?.lastName ? UserData?.lastName : '')).length > 18 ?
                    ((UserData?.firstName + ' ' + (UserData?.lastName ? UserData?.lastName : '')).slice(0,18) +
                    '...') : (UserData?.firstName + ' ' + (UserData?.lastName ? UserData?.lastName : ''))}}
                    <span nz-icon nzType="caret-down" nzTheme="outline"></span>
                </span>
            </div>
        </a>
        <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul class="profile_options_list" nz-menu nzSelectable>
                <li routerLink="/setting/profile" nz-menu-item>
                    <span nz-icon nzType="user" nzTheme="outline"></span>
                    My Profile
                </li>
                <li (click)="logoutAlert()" nz-menu-item>
                    <span nz-icon nzType="logout" nzTheme="outline"></span>
                    Logout
                </li>
            </ul>
        </nz-dropdown-menu>
    </div>

    <app-header class="header" *ngIf="hideNavbarSidnav"></app-header>

    <app-sidenav *ngIf="hideNavbarSidnav"></app-sidenav>

    <div class="main_template " [ngClass]="{ 'page-container' : hideNavbarSidnav,'order_page' : !orderPage}">
        <div class="main-content" [ngClass]="{'heightProperty' : !hideNavbarSidnav}">
            <!-- <div class="main-content-header" *ngIf="contentHeaderDisplay !== 'none'">
                <h4 class="page-title" *ngIf="breadcrumbs$ | async; let breadcrumbs">{{(breadcrumbs[breadcrumbs.length - 1].label)}}</h4>
                <nz-breadcrumb nzSeparator=">">
                    <i class="m-r-5 text-gray" nz-icon nzType="home"></i>
                    <nz-breadcrumb-item *ngFor="let breadcrumb of breadcrumbs$ | async">
                        <a [routerLink]="breadcrumb.url">
                             {{ breadcrumb.label }}
                        </a>
                    </nz-breadcrumb-item>
                </nz-breadcrumb>
            </div> -->
            <router-outlet></router-outlet>

        </div>
    </div>
</div>

<nz-drawer [nzBodyStyle]="{'padding' : '0px'}" [nzHeight]="'200px'" class="info_drawer" [nzClosable]="false"
    [nzVisible]="showInfo" [nzPlacement]="'bottom'" [nzTitle]="null" (nzOnClose)="closeInfo()">
    <ng-container *nzDrawerContent>
        <div class="expiry_alert_section expiry_alert_section">
            <div class="expiry_alert_second_section">
                <p *ngIf="!UserData?.accessTo?.isExpired" class="">
                    {{'You are currently using Own Chat with test Number ' +
                    (UserData?.accessTo?.phoneNumber ? UserData?.accessTo?.phoneNumber : '+91 8015188187') + ' -'}}


                    <span *ngIf="moment(UserData?.accessTo?.expiresAt).diff(now , 'days') > 0">
                        {{ moment(UserData?.accessTo?.expiresAt).diff(now , 'days') + ' days left'}}
                    </span>
                    <span
                        *ngIf="moment(UserData?.accessTo?.expiresAt).diff(now , 'days') == 0 && moment(UserData?.accessTo?.expiresAt).diff(now , 'hours') > 0">
                        {{moment(UserData?.accessTo?.expiresAt).diff(now , 'hours') + ' hours left'}}
                    </span>
                    <span *ngIf="moment(UserData?.accessTo?.expiresAt).diff(now , 'hours') == 0">
                        {{ moment(UserData?.accessTo?.expiresAt).diff(now , 'minutes') + ' minutes left'}}
                    </span>

                </p>
                <p *ngIf="UserData?.accessTo?.isExpired" class="">
                    {{'Your subscription was Expired'}}
                </p>

                <a (click)="arrangeMeeting()" class="connect_btn">
                    Connect with our product expert
                </a>
                <button nz-button (click)="ownNumber()" nzType="primary" type="button" class="">
                    Get your own number
                </button>
            </div>
        </div>
    </ng-container>
</nz-drawer>

<!-- [ngClass]="{'page-container' : hideNavbarSidnav , 'order_page' : !orderPage}" -->