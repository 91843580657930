<div class="row">
    <div [ngClass]="{'col-8' : showRightMenu}" class="col-12 left_column_section">
        <div class="">
            <nz-card [ngClass]="{'no_keys_card' : allApiKeys?.length == 0 , 'all_keys_card' : allApiKeys?.length > 0}"
                class="details_card mt-2">
                <ng-container *ngIf="allApiKeys">
                    <div *ngIf="allApiKeys.length == 0" class="no_pai_keys">
                        <img src="../../../assets/images/gif/no_api_keys.svg" alt="">
                        <h5>It looks like you haven't created an API key yet</h5>
                        <p>No worries, it's a quick and easy process! Click the button below to generate your API key .
                        </p>
                        <a [disabled]="mainUserData?.accessTo?.isExpired" (click)="showApiForm()" nz-button
                            nzType="primary" class="create-button">
                            <span>Create Your API key</span>
                        </a>
                    </div>

                    <div *ngIf="allApiKeys.length > 0" class="">
                        <div class="keys-heading">
                            <h5>Api Key</h5>
                            <div class="heading_right_link">
                                <!-- <a class="learn_more">Learn More About API key</a> -->
                                <a [disabled]="mainUserData?.accessTo?.isExpired" (click)="showApiForm()" nz-button
                                    nzType="primary" class="create-button">
                                    <span>Create API key</span>
                                </a>
                                <span *ngIf="!mainUserData?.accessTo?.isExpired" nzTrigger="click" nz-dropdown
                                    [nzDropdownMenu]="moreOptions" class="more_icon web_cursor" nz-icon nzType="more"
                                    nzTheme="outline">
                                </span>
                                <nz-dropdown-menu #moreOptions="nzDropdownMenu">
                                    <ul class="more_options" nz-menu nzSelectable>
                                        <li (click)="delateAllAlert()" nz-menu-item>
                                            Delete all
                                        </li>
                                    </ul>
                                </nz-dropdown-menu>
                            </div>
                        </div>
                        <div class="all_keys row">
                            <div (click)="showEdit(key)" *ngFor="let key of allApiKeys" class="separate_keys col-lg-8 ">
                                <p>{{key.name}}</p>
                                <h6>xxxxx-xxxxxxx-xxxxxx-xxxxxx</h6>
                                <span *ngIf="!mainUserData?.accessTo?.isExpired" (click)="deleteAlert($event , key)"
                                    class="delete_key" nz-icon nzType="delete" nzTheme="outline"></span>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <ngx-spinner bdColor="rgb(255,255,255)" size="medium" color="#000000" type="ball-clip-rotate-pulse"
                    [fullScreen]="false">
                </ngx-spinner>
            </nz-card>
        </div>
    </div>

    <div [ngClass]="{'col-0 hide_details' : !showRightMenu}" class="col-4 left_menu mt-2">
        <nz-card class="api_forms_card" *ngIf="showRightMenu">
            <div *ngIf="generateApiKey" class="">
                <h4 class="form_heading">
                    {{editData ? 'Update' : 'Create'}} API key
                    <i (click)="closeForm()" nz-icon nzType="close" nzTheme="outline"></i>
                </h4>

                <div class="">
                    <form [formGroup]="apiForm" (ngSubmit)="createApiKey()" nz-form>
                        <nz-form-item>
                            <nz-form-label [nzSm]="24" [nzXs]="24" nzRequired class="large_label">
                                Api Name
                            </nz-form-label>
                            <nz-form-control [nzSm]="24" nzErrorTip="Please Enter Api Name!">
                                <nz-input-group>
                                    <input autocomplete="off" formControlName="apiName" type="text" nz-input
                                        placeholder="Api Name">
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>

                        <nz-form-item>
                            <nz-form-label [nzSm]="24" [nzXs]="24" nzRequired class="large_label">
                                API permission
                            </nz-form-label>
                            <nz-form-control [nzSm]="24" nzErrorTip="Please Enter API permission!">
                                <nz-input-group>
                                    <nz-select class="w-100" nzShowSearch formControlName="apiPermission">
                                        <nz-option [nzLabel]="'All'" [nzValue]="'all'"></nz-option>
                                    </nz-select>
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>

                        <nz-form-item class="submit_section">
                            <nz-form-control [nzSm]="24" [nzXs]="24">
                                <div class="api_notes">
                                    <span>Your API key is unique to your account and should be kept secure. If you
                                        suspect any unauthorized access or have concerns about its security, please
                                        Delete the API key and Create the new one.</span>
                                </div>
                                <div class="account_details_add_cancel">
                                    <button [nzLoading]="creatingKey" type="submit" class="sighup_button" nz-button
                                        nzType="primary">
                                        {{editData ? 'Update' : 'Create'}} API key
                                    </button>
                                </div>
                            </nz-form-control>
                        </nz-form-item>
                    </form>
                </div>
            </div>

            <div *ngIf="!generateApiKey" class="">
                <h4 class="form_heading success_heading">
                    API Key Generated Successfully!
                    <i (click)="closeForm()" nz-icon nzType="close" nzTheme="outline"></i>
                </h4>

                <div class="">
                    <form [formGroup]="successForm" nz-form>
                        <nz-form-item>
                            <nz-form-label [nzSm]="24" [nzXs]="24" nzRequired class="large_label">
                                Api Key
                            </nz-form-label>
                            <nz-form-control [nzSm]="24">
                                <nz-input-group [nzSuffix]="copyKey">
                                    <input readonly autocomplete="off" formControlName="apiKey" type="text" nz-input
                                        placeholder="Api Key">
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>

                        <nz-form-item>
                            <nz-form-label [nzSm]="24" [nzXs]="24" nzRequired class="large_label">
                                API Secret
                            </nz-form-label>
                            <nz-form-control [nzSm]="24">
                                <nz-input-group [nzSuffix]="copySecret">
                                    <input readonly autocomplete="off" formControlName="apiSecret" type="text" nz-input
                                        placeholder="API Secret Key">
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>

                        <ng-template #copyKey>
                            <span (click)="copyCode('key')" nz-tooltip
                                [nzTooltipTitle]="copyText == 'key' ? 'copied' : 'copy'" class="key_copy" nz-icon
                                nzType="copy" nzTheme="outline"></span>
                        </ng-template>

                        <ng-template #copySecret>
                            <span (click)="copyCode('secret')" nz-tooltip
                                [nzTooltipTitle]="copyText == 'secret' ? 'copied' : 'copy'" class="key_copy" nz-icon
                                nzType="copy" nzTheme="outline"></span>
                        </ng-template>

                        <nz-form-item class="submit_section">
                            <nz-form-control [nzSm]="24" [nzXs]="24">
                                <div class="api_notes">
                                    <span>To ensure its security, we will allow you to copy the API Secret only once.
                                        After that, it will be hidden for your protection. Remember to securely store
                                        your API Secret in a safe place.</span>
                                </div>
                                <div class="account_details_add_cancel">
                                    <button (click)="copyCode('all')" type="button" class="sighup_button" nz-button
                                        nzType="primary">
                                        {{copyText == 'all' ? 'Copied' : 'Copy API Secret'}}
                                    </button>
                                </div>
                            </nz-form-control>
                        </nz-form-item>
                    </form>
                </div>
            </div>
        </nz-card>
    </div>
</div>