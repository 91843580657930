import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { CommonApiService } from "src/app/services/common-api.service";
import { environment } from "src/environments/environment";
import {
  SearchCountryField,
  CountryISO,
  PhoneNumberFormat,
} from "ngx-intl-tel-input";
import { countryCodes } from "../dashboard/chat/shared/messageData";

@Component({
  selector: "app-sighup-page",
  templateUrl: "./sighup-page.component.html",
  styleUrls: ["./sighup-page.component.css"],
})
export class SighupPageComponent implements OnInit {
  //countryCode
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;

  //
  signupForm!: FormGroup;
  submitting = false;

  termsAgreed = false;

  loginBanners: any = [
    "../../../assets/images/login/banner_1.png",
    "../../../assets/images/login/banner_2.png",
    "../../../assets/images/login/banner_3.png",
    "../../../assets/images/login/banner_4.png",
    "../../../assets/images/login/banner_5.png",
    "../../../assets/images/login/banner_6.png",
  ];

  industryList = [
    {
      value: "Beauty & Personal Care",
    },
    {
      value: "Business & Enterprise",
    },
    {
      value: "Clothing & Apparel",
    },
    {
      value: "E-commerce Marketplace",
    },
  ];
  revenueList = [
    {
      value: "Less than 5 Lakhs",
    },
    {
      value: "5 Lakhs - 25 Lakhs",
    },
    {
      value: "25 Lakhs - 50 Lakhs",
    },
    {
      value: "50 Lakhs - 1 Crore",
    },
  ];

  countryCodes: any = countryCodes;

  constructor(
    private fb: FormBuilder,
    private apiService: CommonApiService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    // console.log(this.CountryISO);
    localStorage.removeItem("qwertrewetrt");
    this.signupForm = this.fb.group({
      firstName: ["", [Validators.required]],
      // lastName: ["", [Validators.required]],
      countryCode: ["+91", [Validators.required]],
      mobileNumber: ["", [Validators.required]],
      email: ["", [Validators.required, Validators.email]],
      // COMPANY DETAILS
      companyName: ["", [Validators.required]],
      companyWebsite: ["", [Validators.required]],
      // location: ["", [Validators.required]],
      // designation: ["", [Validators.required]],
      // industry: ["", [Validators.required]],
      // revenue: ["", [Validators.required]],
    });
  }

  signUp() {
    // console.log(this.signupForm.value);
    if (this.signupForm.valid && this.termsAgreed) {
      // console.log(this.signupForm.value);
      this.submitting = true;
      let options = {};
      var formData = {
        // new input
        // countryCode: this.signupForm?.value?.mobileNumber?.dialCode.toString(),
        // phoneNo: this.signupForm?.value?.mobileNumber?.number.toString(),
        firstName: this.signupForm.value.firstName,
        // lastName: this.signupForm.value.lastName,
        countryCode: this.signupForm.value.countryCode.toString(),
        phoneNo: this.signupForm.value.mobileNumber.toString(),
        email: this.signupForm.value.email,
        companyName: this.signupForm.value.companyName,
        website: this.signupForm.value.companyWebsite,
        // location: this.signupForm.value.location,
        // designation: this.signupForm.value.designation,
        // industry: this.signupForm.value.industry,
        // monthlyRevenue: this.signupForm.value.revenue,
      };

      // console.log(formData);
      this.apiService
        .commonPostMethod(
          environment.url + "organizations/create",
          formData,
          options
        )
        .subscribe(
          (data: any) => {
            // console.log(data);
            this.submitting = false;
            if (data.status == "success") {
              localStorage.setItem(
                "gtrdtghjdgfhjsgkahjhfjkafhfkjhfkfifghefywegfyuwgfuyew",
                data.data.userId
              );
              // localStorage.setItem("qwertrewetrt", data.data.userToken);
              // this.router.navigate(["/sandbox-message"]);
              this.router.navigate(["create-password"]);
              this.signupForm.reset();
            }
          },
          (err) => {
            console.log(err);
            if (err?.error?.message) {
              this.toastr.error(err?.error?.message, "", {
                timeOut: 2500,
              });
            } else {
              this.toastr.error(err?.message, "", {
                timeOut: 2500,
              });
            }

            this.submitting = false;
          }
        );
    } else {
      Object.values(this.signupForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  navigateTo(event: any, url: any) {
    event.stopPropagation();
    event.preventDefault();
    window.open(url, "_blank");
  }

  formatMailId() {
    const value = this.signupForm.value.email.replace(/ /g, "").toLowerCase();
    this.signupForm.get("email").setValue(value);
  }
}
