import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CommonApiService } from "src/app/services/common-api.service";
import { environment } from "src/environments/environment";
import { WebSocketServiceService } from "../dashboard/chat/shared/web-socket-service.service";

@Component({
  selector: "app-sandbox-message",
  templateUrl: "./sandbox-message.component.html",
  styleUrls: ["./sandbox-message.component.css"],
})
export class SandboxMessageComponent implements OnInit, OnDestroy {
  sandBoxCode: string = "";
  sandboxPage: boolean;

  constructor(
    private apiService: CommonApiService,
    private webSocketService: WebSocketServiceService,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (this.router.url == "/sandbox-message") {
      this.sandboxPage = true;
      this.webSocketService.listen("chat-page").subscribe((data) => {
        // console.log(data);
        if (data) {
          if (window.innerWidth > 992) {
            this.router.navigate(["/chat"]);
          } else {
            this.router.navigate(["/chat/mobile", "id", "name"]);
          }
        }
      });
    } else {
      this.sandboxPage = false;
    }

    this.getSandBoxCode();
  }

  ngOnDestroy() {
    // console.log("destroy");
    if (this.router.url == "/sandbox-message") {
      this.webSocketService.offSocket("chat-page");
    }
  }

  getSandBoxCode() {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonGetMethod(environment.url + "auth/get-user-data", options)
      .subscribe(
        (response: any) => {
          this.sandBoxCode =
            response.data.userData.accessTo.sandboxId.replaceAll("#", "%23");
          // console.log(this.sandBoxCode);
        },
        (error: any) => {
          console.log(error);
        }
      );
  }
}
