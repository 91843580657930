<nz-card class="account_details_card">
    <div class="row">
        <div class="col-12 top_heading">
            <h3>
                <i routerLink="/setting/profile" nz-icon nzType="left" nzTheme="outline"></i>
                Account Details
            </h3>
        </div>
    </div>

    <div class="row h-100">
        <div class="col-lg-12 col-12 mt-3">
            <form [formGroup]="accountSettingForm" (ngSubmit)="updateAccountDetails()" nz-form>
                <div class="update_form_div">
                    <div class="" nz-row [nzGutter]="24">
                        <div nz-col [nzXs]="24" class="" [nzSm]="12">
                            <nz-form-item>
                                <nz-form-label [nzSm]="24" [nzXs]="24" nzRequired class="large_label">
                                    Legal Business Name
                                </nz-form-label>
                                <nz-form-control [nzSm]="24" nzErrorTip="Please Enter Business Name!">
                                    <nz-input-group>
                                        <input autocomplete="off" formControlName="businessName" type="text" nz-input
                                            placeholder="Business Name">
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                        </div>

                        <div nz-col [nzXs]="24" class="" [nzSm]="12">
                            <nz-form-item>
                                <nz-form-label class="large_label">
                                    Business Logo
                                </nz-form-label>
                                <nz-form-control [nzSm]="24" nzErrorTip="Please Profile!">
                                    <img class="user_profile_image"
                                        [src]="previewImage ? previewImage : '../../../../assets/images/logo/download.png'"
                                        alt="">
                                    <label class="profile_label" for='account_profile_upload'>upload</label>
                                    <input (change)="getFile($event)" class="profile_input" id="account_profile_upload"
                                        type="file" [accept]="imageTypes">
                                </nz-form-control>
                            </nz-form-item>
                        </div>

                        <div nz-col [nzXs]="24" class="" [nzSm]="12">
                            <nz-form-item>
                                <nz-form-label [nzSm]="24" [nzXs]="24" nzRequired class="large_label">
                                    Address Line 1
                                </nz-form-label>
                                <nz-form-control [nzSm]="24" nzErrorTip="Please Enter Address Line 1!">
                                    <nz-input-group>
                                        <input autocomplete="off" formControlName="addressLine1" type="text" nz-input
                                            placeholder="Address Line 1">
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                        </div>

                        <div nz-col [nzXs]="24" class="" [nzSm]="6">
                            <nz-form-item>
                                <nz-form-label [nzSm]="24" [nzXs]="24" nzRequired class="large_label">
                                    Website
                                </nz-form-label>
                                <nz-form-control [nzSm]="24" nzErrorTip="Please Enter Website!">
                                    <nz-input-group>
                                        <input autocomplete="off" formControlName="website" type="text" nz-input
                                            placeholder="Website">
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                        </div>

                        <div nz-col [nzXs]="24" class="" [nzSm]="6">
                            <nz-form-item>
                                <nz-form-label [nzSm]="24" [nzXs]="24" class="large_label">
                                    Tax ID
                                </nz-form-label>
                                <nz-form-control [nzSm]="24" nzErrorTip="Please Enter Tax ID!">
                                    <nz-input-group>
                                        <input autocomplete="off" formControlName="taxId" type="text" nz-input
                                            placeholder="Tax ID">
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                        </div>

                        <div nz-col [nzXs]="24" class="" [nzSm]="6">
                            <nz-form-item>
                                <nz-form-label [nzSm]="24" [nzXs]="24" nzRequired class="large_label">
                                    Address Line 2
                                </nz-form-label>
                                <nz-form-control [nzSm]="24" nzErrorTip="Please Enter Address Line 2!">
                                    <nz-input-group>
                                        <input autocomplete="off" formControlName="addressLine2" type="text" nz-input
                                            placeholder="Address Line 2">
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                        </div>

                        <div nz-col [nzXs]="24" class="" [nzSm]="6">
                            <nz-form-item>
                                <nz-form-label [nzSm]="24" [nzXs]="24" nzRequired class="large_label">
                                    City
                                </nz-form-label>
                                <nz-form-control [nzSm]="24" nzErrorTip="Please Enter City!">
                                    <nz-input-group>
                                        <input autocomplete="off" formControlName="city" type="text" nz-input
                                            placeholder="City">
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                        </div>

                        <div nz-col [nzXs]="24" class="" [nzSm]="12">
                            <nz-form-item>
                                <nz-form-label [nzSm]="24" [nzXs]="24" nzRequired class="large_label">
                                    Mobile Number
                                </nz-form-label>
                                <nz-input-group nzCompact class="compact_group">
                                    <nz-form-control nzErrorTip="select country code!" style="width:30%;">
                                        <nz-select class="select_group" nzShowSearch formControlName="countryCode"
                                            nzPlaceHolder="country code" style="width:100%;">
                                            <nz-option *ngFor="let code of countryCodes"
                                                [nzLabel]="code?.countryCode + '-' + code?.Country"
                                                [nzValue]="code?.countryCode"></nz-option>
                                        </nz-select>
                                    </nz-form-control>
                                    <nz-form-control [nzSm]="24" nzErrorTip="Please Enter Mobile Number!"
                                        style="width:70%;">
                                        <input autocomplete="off" formControlName="mobile" type="number" nz-input
                                            placeholder="Mobile Number" style="width:100%;">
                                    </nz-form-control>
                                </nz-input-group>
                            </nz-form-item>
                        </div>

                        <div nz-col [nzXs]="24" class="" [nzSm]="6">
                            <nz-form-item>
                                <nz-form-label [nzSm]="24" [nzXs]="24" nzRequired class="large_label">
                                    District
                                </nz-form-label>
                                <nz-form-control [nzSm]="24" nzErrorTip="Please Enter District!">
                                    <nz-input-group>
                                        <input autocomplete="off" formControlName="district" type="text" nz-input
                                            placeholder="District">
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                        </div>

                        <div nz-col [nzXs]="24" class="" [nzSm]="6">
                            <nz-form-item>
                                <nz-form-label [nzSm]="24" [nzXs]="24" nzRequired class="large_label">
                                    State
                                </nz-form-label>
                                <nz-form-control [nzSm]="24" nzErrorTip="Please Enter State!">
                                    <nz-input-group>
                                        <input autocomplete="off" formControlName="state" type="text" nz-input
                                            placeholder="State">
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                        </div>

                        <div nz-col [nzXs]="24" class="" [nzSm]="12">
                            <nz-form-item>
                                <nz-form-label [nzSm]="24" [nzXs]="24" nzRequired class="large_label">
                                    Official Whatsapp Number
                                    <span nz-tooltip nzTooltipTitle="Enter the number to be used in WhatsApp API"
                                        class="info_icon" nz-icon nzType="info-circle" nzTheme="outline"></span>
                                </nz-form-label>
                                <nz-input-group nzCompact class="compact_group">
                                    <nz-form-control nzErrorTip="select country code!" style="width:30%;">
                                        <nz-select class="select_group" nzShowSearch
                                            formControlName="officialWhatsappCountryCode" nzPlaceHolder="country code"
                                            style="width:100%;">
                                            <nz-option *ngFor="let code of countryCodes"
                                                [nzLabel]="code?.countryCode + '-' + code?.Country"
                                                [nzValue]="code?.countryCode"></nz-option>
                                            <!-- <nz-option [nzLabel]="'+65'" [nzValue]="'+65'"></nz-option> -->
                                        </nz-select>
                                    </nz-form-control>
                                    <nz-form-control [nzSm]="24" nzErrorTip="Please Enter Mobile Number!"
                                        style="width:70%;">
                                        <input autocomplete="off" formControlName="officialWhatsappNumber" type="number"
                                            nz-input placeholder="Whatsapp Number" style="width:100%;">
                                    </nz-form-control>
                                </nz-input-group>
                            </nz-form-item>
                        </div>

                        <div nz-col [nzXs]="24" class="" [nzSm]="6">
                            <nz-form-item>
                                <nz-form-label [nzSm]="24" [nzXs]="24" nzRequired class="large_label">
                                    Country
                                </nz-form-label>
                                <nz-form-control [nzSm]="24" nzErrorTip="Please Select Country!">
                                    <nz-select nzShowSearch formControlName="country" class="w-100 select_dropdown"
                                        nzPlaceHolder="Select Country">
                                        <nz-option *ngFor="let item of countries" [nzLabel]="item" [nzValue]="item">
                                        </nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                        </div>

                        <div nz-col [nzXs]="24" class="" [nzSm]="6">
                            <nz-form-item>
                                <nz-form-label [nzSm]="24" [nzXs]="24" nzRequired class="large_label">
                                    Zip code
                                </nz-form-label>
                                <nz-form-control [nzSm]="24" nzErrorTip="Please Enter Zip code!">
                                    <nz-input-group>
                                        <input autocomplete="off" formControlName="zipCode" type="text" nz-input
                                            placeholder="Zip code">
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                        </div>

                        <div nz-col [nzXs]="24" class="" [nzSm]="6">
                            <nz-form-item>
                                <nz-form-label [nzSm]="24" [nzXs]="24" nzRequired class="large_label">
                                    Industry
                                </nz-form-label>
                                <nz-form-control [nzSm]="24" nzErrorTip="Please Select Industry!">
                                    <nz-select nzShowSearch formControlName="industry" class="w-100 select_dropdown"
                                        nzPlaceHolder="Select Industry">
                                        <nz-option *ngFor="let item of industry" [nzLabel]="item" [nzValue]="item">
                                        </nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                        </div>

                        <div nz-col [nzXs]="24" class="" [nzSm]="6">
                            <nz-form-item>
                                <nz-form-label [nzSm]="24" [nzXs]="24" nzRequired class="large_label">
                                    Number of employees
                                </nz-form-label>
                                <nz-form-control [nzSm]="24" nzErrorTip="Please Select Number of employees!">
                                    <nz-select nzShowSearch formControlName="noOfEmployees"
                                        class="w-100 select_dropdown" nzPlaceHolder="Select Number of employees">
                                        <nz-option *ngFor="let item of noOfEmp" [nzLabel]="item" [nzValue]="item">
                                        </nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                        </div>

                        <div nz-col [nzXs]="24" class="" [nzSm]="6">
                            <nz-form-item>
                                <nz-form-label [nzSm]="24" [nzXs]="24" nzRequired class="large_label">
                                    Time Zone
                                    <span nz-tooltip nzTooltipTitle="For India select Asia/Kolkata(GMT+05:30)"
                                        class="info_icon" nz-icon nzType="info-circle" nzTheme="outline"></span>
                                </nz-form-label>
                                <nz-form-control [nzSm]="24" nzErrorTip="Please Select Time Zone!">
                                    <nz-select nzShowSearch formControlName="timeZone" class="w-100 select_dropdown"
                                        nzPlaceHolder="Select Time Zone">
                                        <nz-option *ngFor="let item of timeZone" [nzLabel]="item?.label"
                                            [nzValue]="item?.value">
                                        </nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                        </div>


                        <div nz-col [nzXs]="24" class="" [nzSm]="6">
                            <nz-form-item>
                                <nz-form-label [nzSm]="24" [nzXs]="24" nzRequired class="large_label"> Currency
                                </nz-form-label>
                                <nz-form-control [nzSm]="24" nzErrorTip="Please Select Currency!">
                                    <nz-select nzShowSearch formControlName="currency" class="w-100 select_dropdown"
                                        nzPlaceHolder="Select Currency">
                                        <nz-option *ngFor="let item of currency" [nzLabel]="item" [nzValue]="item">
                                        </nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                        </div>

                        <!-- <div nz-col [nzXs]="24" class="" [nzSm]="6">
                            <iframe id="serviceFrameSend"
                                src="https://storage.googleapis.com/ownchat/wa_embed/index.html" width="auto"
                                height="100" frameborder="0">
                            </iframe>
                        </div> -->

                        <!-- <div nz-col [nzXs]="24" class="" [nzSm]="24">
                            <nz-form-item>
                                <nz-form-control [nzSm]="24">
                                    <label nz-checkbox formControlName="separateAddress">
                                        Would you like to add a separate address for receiving payments?</label>
                                </nz-form-control>
                            </nz-form-item>
                        </div>

                        <div *ngIf="accountSettingForm.value.separateAddress" nz-col [nzXs]="24" class="" [nzSm]="12">
                            <nz-form-item>
                                <nz-form-label [nzSm]="24" [nzXs]="24" nzRequired class="large_label">
                                    Payment Address
                                </nz-form-label>
                                <nz-form-control [nzSm]="24" nzErrorTip="Please enter address for receiving payments!">
                                    <textarea formControlName="paymentAddress" nz-input rows="3"
                                        placeholder="Enter address for receiving payments"></textarea>
                                </nz-form-control>
                            </nz-form-item>
                        </div> -->

                        <!-- <div nz-col [nzXs]="24" class="" [nzSm]="24">
                            <nz-form-item>
                                <nz-form-control [nzSm]="24">
                                    <nz-form-label [nzSm]="24" [nzXs]="24" nzRequired class="large_label">
                                        Working Hours
                                    </nz-form-label>
                                    <nz-form-control [nzSm]="24"
                                        nzErrorTip="Please enter address for receiving payments!">
                                        <button (click)="openWorkingHoursModal()" type="button" class="sighup_button"
                                            nz-button nzType="primary">Add
                                        </button>
                                    </nz-form-control>
                                </nz-form-control>
                            </nz-form-item>
                        </div> -->
                    </div>
                </div>

                <div class="submit_btn_div">
                    <nz-form-item class="submit_section text-right">
                        <nz-form-control [nzSm]="24" [nzXs]="24">
                            <div class="account_details_add_cancel">
                                <button (click)="setFormValue()" type="button" class="sighup_button mr-3" nz-button
                                    nzType="default">Cancel</button>
                                <button [nzLoading]="updating" type="submit" class="sighup_button" nz-button
                                    nzType="primary">Save</button>
                            </div>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </form>

            <ngx-spinner bdColor="rgb(255,255,255)" size="medium" color="#000000" type="ball-clip-rotate-pulse"
                [fullScreen]="false">
            </ngx-spinner>
        </div>
    </div>
</nz-card>