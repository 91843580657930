import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { Chart } from "chart.js";
import { NzMarks } from "ng-zorro-antd/slider";
import { CommonApiService } from "src/app/services/common-api.service";
import { NavService } from "src/app/services/nav.service";
import { environment } from "src/environments/environment";
import {
  countries,
  countriesForPayment,
} from "../dashboard/chat/shared/messageData";
import { Subscription } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-dashboard-page",
  templateUrl: "./dashboard-page.component.html",
  styleUrls: ["./dashboard-page.component.css"],
})
export class DashboardPageComponent implements OnInit {
  chart: any;
  addMoneyModal: boolean = false;
  videoModal: boolean = false;

  videoLink: SafeUrl = this.sanitize(
    "https://www.youtube.com/embed/c8vofXytweE"
  );

  businessValue: any = 3000;
  serviceValue: any = 7000;

  businessMarks: NzMarks = {
    0: "0",
    5000: "5K",
    10000: "10K",
  };

  serviceMarks: NzMarks = {
    0: "0",
    5000: "5K",
    10000: "10K",
  };

  userData: any;

  countries: any = countriesForPayment;

  selectedCountry: any = "India";

  private subscription: Subscription;

  estimation: any = {
    cost: 0,
    gst: 0,
    totalPrice: 0,
  };

  addingMoney: boolean = false;

  constructor(
    private navService: NavService,
    private sanitizer: DomSanitizer,
    private apiService: CommonApiService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.spinner.show();
    this.getUserData();
    this.getGraphData();
    // this.getConversationData();
    this.estimateCost();
  }

  getUserData() {
    this.navService.getMainUserData();
    this.subscription = this.navService.mainUserData.subscribe(
      (response: any) => {
        if (response) {
          this.userData = response.data.userData;
          // console.log(this.userData);
          this.spinner.hide();
        }
      }
    );
  }

  getGraphData() {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    var req = {
      conversationAnalytics: {
        year: 2023,
      },
    };

    this.apiService
      .commonPostMethod(environment.url + "dashboard/get", req, options)
      .subscribe(
        (response: any) => {
          console.log(response);
          this.createGraph(response);
        },
        (err: any) => {
          if (
            err?.error?.message == "jwt expired" ||
            err?.error?.message == "jwt must be provided" ||
            err?.error?.message == "jwt malformed" ||
            err?.error?.message == "invalid signature" ||
            err?.error?.message == "Given token user does not exist." ||
            err?.error?.message == "invalid token"
          ) {
            this.navService.tokenExpired();
          }
          console.log(err);
        }
      );
  }

  createGraph(graphData: any) {
    let costChart: any = document.getElementById(
      "lineCostChart"
    ) as HTMLElement;
    let countChart: any = document.getElementById(
      "lineCountChart"
    ) as HTMLElement;
    var costData = graphData.data.conversationAnalytics.conversationCostData;
    var countData = graphData.data.conversationAnalytics.conversationCountData;
    //options
    var costOptions = {
      responsive: true,
      scales: {
        yAxes: [
          {
            display: true,
            ticks: {
              // suggestedMin: 0,
              // stepSize: 10,
              // suggestedMax: 100,
              padding: 2,
            },
          },
        ],
      },
      title: {
        display: true,
        position: "bottom",
        text: "Conversion Cost Data",
        fontSize: 18,
        fontColor: "#111",
      },
      legend: {
        display: true,
        position: "top",
        labels: {
          fontColor: "#333",
          fontSize: 12,
        },
      },
    };
    var countOptions = {
      responsive: true,
      scales: {
        yAxes: [
          {
            display: true,
            ticks: {
              // suggestedMin: 0,
              // stepSize: 10,
              // suggestedMax: 100,
              padding: 2,
            },
          },
        ],
      },
      title: {
        display: true,
        position: "bottom",
        text: "Conversion Count Data",
        fontSize: 18,
        fontColor: "#111",
      },
      legend: {
        display: true,
        position: "top",
        labels: {
          fontColor: "#333",
          fontSize: 12,
        },
      },
    };
    var chart = new Chart(costChart, {
      type: "line",
      data: costData,
      options: costOptions,
    });

    var chart = new Chart(countChart, {
      type: "line",
      data: countData,
      options: countOptions,
    });
  }

  // ngAfterViewInit() {
  //   let ctx: any = document.getElementById("lineChart") as HTMLElement;
  //   var data = {
  //     labels: [
  //       "Jan",
  //       "Feb",
  //       "Mar",
  //       "Apr",
  //       "May",
  //       "Jun",
  //       "Jul",
  //       "Aug",
  //       "Sep",
  //       "Oct",
  //       "Nov",
  //     ],
  //     datasets: [
  //       {
  //         label: "TeamA Score",
  //         data: [10, 50, 25, 60, 40, 10, 40, 25, 10],
  //         backgroundColor: "blue",
  //         borderColor: "lightblue",
  //         fill: false,
  //         lineTension: 0,
  //         radius: 5,
  //       },
  //       {
  //         label: "TeamB Score",
  //         data: [20, 35, 40, 60, 50, 10, 50, 25, 40, 60, 50, 22],
  //         backgroundColor: "green",
  //         borderColor: "lightgreen",
  //         fill: false,
  //         lineTension: 0,
  //         radius: 5,
  //       },
  //     ],
  //   };

  //   //options
  //   var options = {
  //     responsive: true,
  //     scales: {
  //       yAxes: [
  //         {
  //           display: true,
  //           ticks: {
  //             suggestedMin: 0,
  //             stepSize: 10,
  //             suggestedMax: 100,
  //             padding: 2,
  //           },
  //         },
  //       ],
  //     },
  //     title: {
  //       display: true,
  //       position: "bottom",
  //       text: "Line Graph",
  //       fontSize: 18,
  //       fontColor: "#111",
  //     },
  //     legend: {
  //       display: true,
  //       position: "top",
  //       labels: {
  //         fontColor: "#333",
  //         fontSize: 12,
  //       },
  //     },
  //   };

  //   var chart = new Chart(ctx, {
  //     type: "line",
  //     data: data,
  //     options: options,
  //   });
  // }

  showAddMoneyModal() {
    this.addMoneyModal = true;
  }

  closeAddMoneyModal() {
    this.addMoneyModal = false;
  }

  closeVideoModal() {
    this.videoModal = false;
  }

  showVideo() {
    this.videoModal = true;
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  getConversationData() {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(environment.url + "conversations/history", {}, options)
      .subscribe(
        (response: any) => {
          // console.log(response);
        },
        (err: any) => {
          if (
            err?.error?.message == "jwt expired" ||
            err?.error?.message == "jwt must be provided" ||
            err?.error?.message == "jwt malformed" ||
            err?.error?.message == "invalid signature" ||
            err?.error?.message == "Given token user does not exist." ||
            err?.error?.message == "invalid token"
          ) {
            this.navService.tokenExpired();
          }
          console.log(err);
        }
      );
  }

  sliderRelease() {
    this.estimateCost();
  }

  countryChange() {
    this.estimateCost();
  }

  estimateCost() {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    var req = {
      businessInitiatedQuantity: this.businessValue,
      userInitiatedQuantity: this.serviceValue,
      countryName: this.selectedCountry,
    };

    this.apiService
      .commonPostMethod(
        environment.url + "conversations/calculate",
        req,
        options
      )
      .subscribe(
        (response: any) => {
          // console.log(response);
          this.estimation = response.data;
        },
        (err: any) => {
          if (err?.error?.message) {
            this.toastr.error(err?.error?.message, "", {
              timeOut: 2500,
            });
          } else {
            this.toastr.error(err?.message, "", {
              timeOut: 2500,
            });
          }
          console.log(err);
        }
      );
  }

  addMoney() {
    this.addingMoney = true;
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url + "wallets/add",
        { credits: this.estimation.cost },
        options
      )
      .subscribe(
        (response: any) => {
          // console.log(response);
          window.open(response?.data.url, "_self");
          this.addingMoney = false;
        },
        (err: any) => {
          this.addingMoney = false;
          if (err?.error?.message) {
            this.toastr.error(err?.error?.message, "", {
              timeOut: 2500,
            });
          } else {
            this.toastr.error(err?.message, "", {
              timeOut: 2500,
            });
          }
          console.log(err);
        }
      );
  }

  copyWhatsappLink() {
    navigator.clipboard
      .writeText("https://wa.me/+" + this.userData?.accessTo?.phoneNumber)
      .then(
        () => {
          this.toastr.success("Link copied", "", {
            timeOut: 2500,
          });
        },
        (error) => {
          this.toastr.error("Browser do not support Clipboard API", "", {
            timeOut: 2500,
          });
        }
      );
  }

  ngOnDestroy(): void {
    // console.log("api key destroy..");
    this.subscription.unsubscribe();
  }
}
