<div class="unsupported_screen">
    <div class="text-center inner_div">
        <img class="oops_gif" src="../../../assets/images/gif/oops.gif" alt="">
        <h3>Current screen size of your device is Unsupported</h3>
        <h4>Check out our Mobile App</h4>
        <!-- <h5>Please try disable inspect option.</h5> -->

        <a href="https://play.google.com/store/apps/details?id=com.ieyalchat.ownchat">
            <img src="../../../assets/images/logo/apple.svg" alt="">
        </a>
    </div>
</div>