import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { CommonApiService } from "src/app/services/common-api.service";
import { NavService } from "src/app/services/nav.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-bot-templates",
  templateUrl: "./bot-templates.component.html",
  styleUrls: ["./bot-templates.component.css"],
})
export class BotTemplatesComponent implements OnInit {
  allTemplates: Array<any> = [
    {
      image: "../../../../assets/images/plugin/sample.png",
      name: "Sample bot templates",
      description: `Lorem ipsum dolor sit amet consectetur adipisicing elit. In eveniet possimus voluptatem nobis vero quidem facilis voluptates quaerat culpa ab tempore, et magnam dignissimos. Deserunt laboriosam voluptatibus natus expedita! Iure?`,
      category: "Marketing",
    },
    {
      image: "../../../../assets/images/plugin/sample.png",
      name: "Sample bot templates",
      description: `Lorem ipsum dolor sit amet consectetur adipisicing elit. In eveniet possimus voluptatem nobis vero quidem facilis voluptates quaerat culpa ab tempore, et magnam dignissimos. Deserunt laboriosam voluptatibus natus expedita! Iure?`,
      category: "Marketing",
    },
    {
      image: "../../../../assets/images/plugin/sample.png",
      name: "Sample bot templates",
      description: `Lorem ipsum dolor sit amet consectetur adipisicing elit. In eveniet possimus voluptatem nobis vero quidem facilis voluptates quaerat culpa ab tempore, et magnam dignissimos. Deserunt laboriosam voluptatibus natus expedita! Iure?`,
      category: "Marketing",
    },
    {
      image: "../../../../assets/images/plugin/sample.png",
      name: "Sample bot templates",
      description: `Lorem ipsum dolor sit amet consectetur adipisicing elit. In eveniet possimus voluptatem nobis vero quidem facilis voluptates quaerat culpa ab tempore, et magnam dignissimos. Deserunt laboriosam voluptatibus natus expedita! Iure?`,
      category: "Marketing",
    },
    {
      image: "../../../../assets/images/plugin/sample.png",
      name: "Sample bot templates",
      description: `Lorem ipsum dolor sit amet consectetur adipisicing elit. In eveniet possimus voluptatem nobis vero quidem facilis voluptates quaerat culpa ab tempore, et magnam dignissimos. Deserunt laboriosam voluptatibus natus expedita! Iure?`,
      category: "Marketing",
    },
  ];

  allCategories: Array<any> = [
    { name: "Commerce", id: "commerce" },
    { name: "Education", id: "education" },
    { name: "Finance", id: "finance" },
    { name: "Travel", id: "travel" },
  ];

  selectedCategory: string = "all";

  constructor(
    private spinner: NgxSpinnerService,
    private apiService: CommonApiService,
    private navService: NavService
  ) {}

  ngOnInit(): void {}

  fetchData() {
    this.spinner.show();
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(environment.url + "flows", {}, options)
      .subscribe(
        (response: any) => {
          console.log(response);
          this.spinner.hide();
        },
        (err: any) => {
          if (
            err?.error?.message == "jwt expired" ||
            err?.error?.message == "jwt must be provided" ||
            err?.error?.message == "jwt malformed" ||
            err?.error?.message == "invalid signature" ||
            err?.error?.message == "Given token user does not exist." ||
            err?.error?.message == "invalid token"
          ) {
            this.navService.tokenExpired();
          }
          console.log(err);
          this.spinner.hide();
        }
      );
  }

  selectCategory(category: string) {
    this.selectedCategory = category;
  }
}
