// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // url: "https://getdesignworks.com:4802/",
  // url: "https://api.ownchat.app/",
  // url: "https://api-test.ownchat.app/",
  url: "https://cloud-api-test.ownchat.app/",
  imageUrl: "https://storage.googleapis.com/ownchat/",
  // url: "http://192.168.29.161:4810/",
  firebase: {
    apiKey: "AIzaSyAcC_ZjvmOfE1b8WjJI2EYzqpz6UpbjPaw",
    authDomain: "ownchat-fabd7.firebaseapp.com",
    projectId: "ownchat-fabd7",
    storageBucket: "ownchat-fabd7.appspot.com",
    messagingSenderId: "541328778133",
    appId: "1:541328778133:web:ca7cfb5586faa2bb8dcc9c",
    measurementId: "G-Z7WN60QX2H",
    vapidKey:
      "BDlvVytn9lcZA4wetO4ZSMHxraa7VAfR57hnxXfBDKl1gL6jeNAhaX5cXzYTYDzeDsE3LEqSzoU7NcLorIoE8fM",
  },
};

// local Url :: http://192.168.1.12:4802/
// Live url : https://now6.getdesignworks.com/

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
