<div class="row overflow_row_mobile">
  <div class="col-12 main_outer_heading">
    <h3>
      <span routerLink="/campaign" nz-icon nzType="left" nzTheme="outline"></span>
      <span [nzTooltipTitle]="campaignData?.name" nzTooltipPlacement="top" nz-tooltip>{{ campaignData?.name.length > 30
        ? (campaignData?.name.slice(0, 30) + '...') : campaignData?.name}}</span>
      <!-- <span *ngIf="!campaignData?.isFinished" nzTrigger="click" nz-dropdown [nzDropdownMenu]="moreOptions"
        class="more_options" nz-icon nzType="more" nzTheme="outline"></span> -->

      <nz-dropdown-menu #moreOptions="nzDropdownMenu">
        <ul nz-menu>
          <li (click)="cancelCampaignAlert()" class="list_menu" nz-menu-item> Cancel Campaign</li>
          <li (click)="openRescheduleModal()" *ngIf="!campaignData?.isCancelled" nz-menu-item> Reschedule Campaign</li>
        </ul>
      </nz-dropdown-menu>
    </h3>
  </div>

  <div class="col-3 left_column">
    <nz-card class="fixed_height">
      <div class="campaign_left">
        <div class="current_status">
          <p [ngClass]="{'complete_status' : campaignData?.isFinished , 'cancel_status' : campaignData?.isCancelled}">
            {{campaignData?.isCancelled ? 'Cancelled' : (campaignData?.isFinished ? 'Completed' : 'Active')}}</p>
        </div>
        <div class="left_div">
          <p>Campaign Type</p>
          <h5>Broadcast</h5>
        </div>
        <div class="left_div">
          <p>Template Name</p>
          <h5>{{ campaignData?.templateData?.name }}</h5>
        </div>
        <div class="left_div">
          <p>Created At</p>
          <h5>
            {{ moment(campaignData?.createdAt).format("DD-MM-YYYY hh:mm A") }}
          </h5>
        </div>
        <div class="left_div">
          <p>Scheduled At</p>
          <h5>
            {{ moment(campaignData?.scheduledAt).format("DD-MM-YYYY hh:mm A") }}
          </h5>
        </div>
        <div *ngIf="campaignData?.isFinished && campaignDuration > 0 && campaignData?.finishedAt" class="left_div">
          <p>Campaign Duration</p>
          <h5>
            Completed in {{ campaignDuration > 60 ? ((campaignDuration / 60)?.toFixed(0) + ' minutes') :
            (campaignDuration + ' seconds') }}
          </h5>
        </div>
        <div class="left_div">
          <p>Campaign Template</p>
          <div class="template_preview">
            <div *ngFor="let message of campaignData?.messageComponents" class="mt-3">
              <!-- <h6>{{ message?.type }} :</h6> -->
              <div [ngSwitch]="message?.type" class="">
                <div *ngSwitchCase="'HEADER'" class="">
                  <div [ngSwitch]="message?.format" class="header_text">
                    <p *ngSwitchCase="'TEXT'" [innerHTML]="message.text"></p>
                    <img *ngSwitchCase="'IMAGE'" class="img-fluid" [src]="campaignData.templateData?.mediaData?.mediaSource == 'url' ? 
                      campaignData.templateData?.mediaData?.urlData?.fallbackUrl : 
                      liveUrl + campaignData.templateData?.mediaData?.id?.url" alt="IMAGE URL" />
                    <video *ngSwitchCase="'VIDEO'" width="200" controls [src]="campaignData.templateData?.mediaData?.mediaSource == 'url' ? 
                      campaignData.templateData?.mediaData?.urlData?.fallbackUrl : 
                      liveUrl + campaignData.templateData?.mediaData?.id?.url"></video>
                    <div *ngSwitchCase="'DOCUMENT'" class="document_div">
                      <img class="document_icon" src="../../../../../assets/images/chat/icons8-google-docs.svg"
                        alt="pdf" />
                    </div>
                  </div>
                </div>
                <div *ngSwitchCase="'BODY'" class="">
                  <p [innerHTML]="message.text"></p>
                </div>
                <div *ngSwitchCase="'FOOTER'" class="">
                  <p [innerHTML]="message.text"></p>
                </div>
                <div *ngSwitchCase="'BUTTONS'" class="" [ngClass]="{
                    template_button_reply:
                      message?.buttons[0]?.type == 'QUICK_REPLY',
                    'template_button_link links_btn':
                      message?.buttons[0]?.type == 'URL' ||
                      message?.buttons[0]?.type == 'PHONE_NUMBER'
                  }">
                  <div [ngClass]="{
                      more_buttons: message?.buttons?.length > 2
                    }" *ngFor="let button of message.buttons" class="single_button">
                    <h6 *ngIf="button?.type == 'QUICK_REPLY'">
                      {{ button.text }}
                    </h6>
                    <div class="links_btn">
                      <a *ngIf="button?.type == 'URL'" [href]="button?.url" target="_blank">
                        {{ button.text }}
                      </a>
                      <a *ngIf="button?.type == 'PHONE_NUMBER'" [href]="'tel:' + button?.phone_number">{{ button.text
                        }}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nz-card>
  </div>

  <div class="col-9">
    <nz-card class="fixed_height campaign_right">
      <div class="right_menu">
        <h6>Campaign Performance</h6>
        <div class="text-right add-right-section">
          <a (click)="duplicateCampaign()" nz-button nzType="primary" class="create-button">
            <i nz-icon nzType="copy" nzTheme="outline"></i>
            <span>Duplicate Campaign</span>
          </a>
          <span [ngClass]="{ reload_btn_loading: pageReloading }" (click)="reloadPage()" class="reload_btn"
            nzTooltipTitle="Reload" nzTooltipPlacement="top" nz-tooltip nz-icon nzType="sync" nzTheme="outline"></span>
        </div>
      </div>

      <div class="campaign_status_box mb-3">
        <span [ngClass]="{ selected_filter: selectedFilter == 'all' }" (click)="getAll()" class="status_box">
          <!-- <span nz-tooltip nzTooltipTitle="Number of Notifications that were attempt to be sent" class="info_icon"
            nz-icon nzType="info-circle" nzTheme="outline"></span> -->
          <!-- <span class="report_percentage">{{analytics?.sentPercentage}}%</span> -->
          <p class="status_content"><span>Audience</span></p>
          <h4 class="status_head">{{ analytics?.totalChats }}</h4>
        </span>
        <span [ngClass]="{ selected_filter: selectedFilter == 'sent' }" class="status_box">
          <!-- <span nz-tooltip nzTooltipTitle="Number of Notifications in this campaign Sent successfully" class="info_icon"
            nz-icon nzType="info-circle" nzTheme="outline"></span> -->
          <span class="report_percentage">{{ analytics?.sentPercentage?.toFixed(0) }}%</span>
          <p class="status_content"><span>Sent</span></p>
          <h4 class="status_head">{{ analytics?.totalSentCount }}</h4>
        </span>
        <span [ngClass]="{ selected_filter: selectedFilter == 'delivered' }" class="status_box">
          <!-- <span nz-tooltip nzTooltipTitle="Number of Notifications in this campaign Delivered successfully"
            class="info_icon" nz-icon nzType="info-circle" nzTheme="outline"></span> -->
          <span class="report_percentage">{{ analytics?.deliveredPercentage?.toFixed(0) }}%</span>
          <p class="status_content"><span>Delivered</span></p>
          <h4 class="status_head">{{ analytics?.totalDeliveredCount }}</h4>
        </span>
        <span [ngClass]="{ selected_filter: selectedFilter == 'read' }" class="status_box">
          <!-- <span nz-tooltip nzTooltipTitle="Number of Notifications in this campaign Read by Customer" class="info_icon"
            nz-icon nzType="info-circle" nzTheme="outline"></span> -->
          <span class="report_percentage">{{ analytics?.readPercentage?.toFixed(0) }}%</span>
          <p class="status_content"><span>Read</span></p>
          <h4 class="status_head">{{ analytics?.totalReadCount }}</h4>
        </span>
        <!-- <span [ngClass]="{ selected_filter: selectedFilter == 'replied' }" class="status_box">
          <p class="status_content"><span>Replied</span></p>
          <h4 class="status_head">{{ analytics?.readCount }}</h4>
        </span> -->
        <span [ngClass]="{ selected_filter: selectedFilter == 'failed' }" class="status_box">
          <!-- <span nz-tooltip nzTooltipTitle="Number of Notifications that were Unsuccessful" class="info_icon" nz-icon
            nzType="info-circle" nzTheme="outline"></span> -->
          <!-- <span class="report_percentage">{{analytics?.sentPercentage}}%</span> -->
          <p class="status_content"><span>Failed</span></p>
          <h4 class="status_head">{{ analytics?.totalFailedCount }}</h4>
        </span>
      </div>

      <h6>Campaign Details</h6>
      <div class="top_main">
        <div class="campaign_filters">
          <div class="dropdown_div">
            <nz-select [(ngModel)]="selectedFilter" (ngModelChange)="newFilter()" class="w-100 campaign_drop"
              nzPlaceHolder="Select status">
              <nz-option nzLabel="All" nzValue="all"> </nz-option>
              <nz-option nzLabel="Sent" nzValue="sent"> </nz-option>
              <nz-option nzLabel="Delivered" nzValue="delivered"> </nz-option>
              <nz-option nzLabel="Read" nzValue="read"> </nz-option>
              <!-- <nz-option nzLabel="Replied" nzValue="replied"> </nz-option> -->
              <nz-option nzLabel="Failed" nzValue="failed"> </nz-option>
            </nz-select>
          </div>
          <!-- <div class="check_box_div">
            <label nz-checkbox [nzChecked]="repliedStatus == 'replied'" (nzCheckedChange)="replyFilter('replied')"
              nzValue="replied">Has
              Replied</label>

            <label nz-checkbox [nzChecked]="repliedStatus == 'notReplied'" (nzCheckedChange)="replyFilter('notReplied')"
              nzValue="notReplied">Not
              Replied</label>
          </div> -->
        </div>
        <div class="">
          <button class="customized_btn" [ngStyle]="setMyStyles()" nzTrigger="click" nz-dropdown
            [nzDropdownMenu]="paginationMenu" [nzPlacement]="'bottomLeft'">
            {{ pageSize + " records per page"}}
          </button>
        </div>

        <nz-dropdown-menu #paginationMenu="nzDropdownMenu">
          <ul nz-menu>
            <li (click)="changePageSize((n + 1) * 10)" *ngFor="let page of [].constructor(5); let n = index"
              nz-menu-item>
              {{ (n + 1) * 10 + " records per page" }}
            </li>
          </ul>
        </nz-dropdown-menu>
      </div>
      <!-- USER LIST TABLE STARTS -->
      <nz-table class="mt-3 contacts_table campaign_details_table" #campaignDataTable [nzData]="campaignData?.data"
        [nzShowPagination]="true" [nzScroll]="{ y: tableHeight, x: '1310px' }" [nzFrontPagination]="false"
        [nzTotal]="totalCount" [nzPageSize]="pageSize" [nzPageIndex]="pageIndex"
        (nzQueryParams)="onPageNumberChange('campaignTable', $event)" [nzPaginationPosition]="'top'" [nzSimple]="true"
        [nzTableLayout]="'auto'">
        <thead>
          <tr>
            <!-- <th [nzLeft]="true" class="checkbox_section" [nzWidth]="'35px'" [(nzChecked)]="checked"
              (nzCheckedChange)="onAllChecked($event)"></th> -->
            <th *ngFor="let column of orderColumn" [nzWidth]="column.width">
              {{ column.title }}
            </th>
            <!-- <th *ngIf="selectedFilter == 'failed'" nzWidth="80px">
              Failed Reason
            </th> -->
          </tr>
        </thead>
        <tbody>
          <tr id="campaignTable" *ngFor="let item of campaignDataTable?.data; let i = index">
            <!-- <td>{{i+1}}</td> -->
            <!-- <td [nzLeft]="true" class="checkbox_section" [nzChecked]="setOfCheckedId.has(item._id)"
              (nzCheckedChange)="onItemChecked(item._id, $event)"></td> -->
            <td>
              <div class="">
                {{ item?.customer?.name }}
              </div>
            </td>
            <td>
              {{ item?.customer?.phoneNo }}
            </td>
            <!-- <td>
              status
            </td> -->
            <td>
              {{item?.chat?.statuses?.sent?.time
              ? moment(item?.chat?.statuses?.sent?.time).format("Do MMM YYYY, h:mm a") : "-"
              }}
            </td>
            <td>
              {{
              item?.chat?.statuses?.delivered?.time
              ? moment(item?.chat?.statuses?.delivered?.time).format("Do MMM YYYY, h:mm a")
              : item?.chat?.statuses?.read?.time
              ? moment(item?.chat?.statuses?.read?.time).format(
              "Do MMM YYYY, h:mm a")
              : "-"
              }}
            </td>
            <td>
              {{item?.chat?.statuses?.read?.time
              ? moment(item?.chat?.statuses?.read?.time).format(
              "Do MMM YYYY, h:mm a") : "-"
              }}
            </td>
            <!-- <td>
              {{item?.chat?.statuses?.read?.time
              ? moment(item?.chat?.statuses?.read?.time).format(
              "Do MMM YYYY, h:mm a") : "-"
              }}
            </td> -->
            <!-- <td> {{ '-' }}</td> -->
            <td>
              <!-- <button (click)="getTypeOf({name : 'balaji'})">test</button> -->
              <div [ngSwitch]="getTypeOf(item?.chat?.errorData)" class="">

                <div *ngSwitchCase="'Object'" [nzTooltipTitle]="
                  item?.chat?.errorData?.error?.message
                " nzTooltipPlacement="top" nz-tooltip class="">
                  {{item?.chat?.errorData?.error?.message ?
                  (item?.chat?.errorData?.error?.message?.length > 60
                  ? item?.chat?.errorData?.error?.message.slice(
                  0,
                  60
                  ) + "..."
                  : item?.chat?.errorData?.error?.message) : '-'
                  }}
                </div>

                <div *ngSwitchCase="'Array'" [nzTooltipTitle]="
                  item?.chat?.errorData[0].title
                " nzTooltipPlacement="top" nz-tooltip class="">
                  {{item?.chat?.errorData[0].title ?
                  (item?.chat?.errorData[0].title?.length > 60
                  ? item?.chat?.errorData[0].title.slice(
                  0,
                  60
                  ) + "..."
                  : item?.chat?.errorData[0].title) : '-'
                  }}
                </div>

                <div *ngSwitchCase="'String'" [nzTooltipTitle]="
                  item?.chat?.errorData
                " nzTooltipPlacement="top" nz-tooltip class="">
                  {{item?.chat?.errorData ?
                  (item?.chat?.errorData?.length > 60
                  ? item?.chat?.errorData.slice(
                  0,
                  60
                  ) + "..."
                  : item?.chat?.errorData) : '-'
                  }}
                </div>

                <div *ngSwitchCase="'Undefined'" class="">
                  -
                </div>
              </div>


            </td>
          </tr>
        </tbody>
      </nz-table>
      <!-- USER LIST TABLE ENDS -->
    </nz-card>
  </div>

  <ngx-spinner bdColor="rgb(255,255,255)" size="medium" color="#000000" type="ball-clip-rotate-pulse"
    [fullScreen]="false">
  </ngx-spinner>

</div>

<nz-modal [(nzVisible)]="rescheduleModal" [nzFooter]="null" nzTitle="Reschedule Campaign" nzCentered
  (nzOnCancel)="closeRescheduleModal()">
  <ng-container *nzModalContent>
    <div class="campaign_timing">
      <div (click)="changeScheduleType('now')" class="schedule_time">
        <a>Send Now</a>
        <span>Send the campaign immediately to deliver to the recipient's inbox.</span>
      </div>
      <div (click)="changeScheduleType('schedule')" class="schedule_time">
        <a>Reschedule Now</a>
        <span>Send the campaign on the date and time you specify.</span>
      </div>
    </div>

    <div *ngIf="scheduleType == 'schedule'" class="">
      <nz-date-picker [(ngModel)]="scheduleTime" class="mt-3" nzShowToday [nzDisabledDate]="disabledDate"
        [nzShowTime]="{ nzFormat: 'hh:mm a' }" nzFormat="yyyy-MM-dd hh:mm a" [nzRenderExtraFooter]="">
      </nz-date-picker>
      <a class="create_link" (click)="rescheduleCampaign()">Reschedule campaign</a>
    </div>
  </ng-container>
</nz-modal>