import { Component, HostListener, OnInit } from "@angular/core";

@Component({
  selector: "app-remove-inspect",
  templateUrl: "./remove-inspect.component.html",
  styleUrls: ["./remove-inspect.component.css"],
})
export class RemoveInspectComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    if (window.innerWidth > 992) {
      history.back();
    }
  }

  @HostListener("window:resize", ["$event"])
  onWindowResize() {
    console.log();
    if (window.innerWidth > 992) {
      history.back();
    }
  }
}
