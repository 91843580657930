<!-- filter starts -->

<div class="mb-3">
    <div class="row">
        <div class="col-lg-2 col-sm-2 col-6 mb-3">
            <nz-select [(ngModel)]="selectedFilter" (ngModelChange)="filterChange('date')" class="w-100">
                <nz-option *ngFor="let filter of filterArr.dateFilter" [nzValue]="filter.id" [nzLabel]="filter.label">
                </nz-option>
            </nz-select>
        </div>
        <div *ngIf="selectedFilter == 'custom-date'" class="col-lg-3 col-sm-3 col-6">
            <nz-range-picker [(ngModel)]="customDate" (ngModelChange)="onDateChange($event)"></nz-range-picker>
        </div>
        <div class="col-lg-2 col-sm-2 col-6 mb-3">
            <nz-select [(ngModel)]="orderFilter" (ngModelChange)="filterChange('order')" class="w-100"
                nzPlaceHolder="Order status">
                <nz-option nzValue="all" nzLabel="All Orders"></nz-option>
                <nz-option *ngFor="let filter of filterArr.orderStatus" [nzValue]="filter.id" [nzLabel]="filter.label">
                </nz-option>
            </nz-select>
        </div>
        <div class="col-lg-2 col-sm-2 col-6">
            <nz-select [(ngModel)]="paymentFilter" (ngModelChange)="filterChange('payment')" class="w-100"
                nzPlaceHolder="Payment status">
                <nz-option nzValue="all" nzLabel="All Payments"></nz-option>
                <nz-option *ngFor="let filter of filterArr.paymentStatus" [nzValue]="filter.id"
                    [nzLabel]="filter.label">
                </nz-option>
            </nz-select>
        </div>
        <!-- <div class="col-lg-3 col-sm-3 col-6">
            <nz-select [(ngModel)]="selectedCustomer" (ngModelChange)="filterChange('customer')" nzShowSearch
                class="w-100" nzPlaceHolder="Customer Name">
                <nz-option nzValue="all" nzLabel="All Customers">
                </nz-option>
                <nz-option *ngFor="let customer of customerList" [nzValue]="customer._id" [nzLabel]="customer.name">
                </nz-option>
            </nz-select>
        </div> -->
    </div>

</div>

<!-- filter ends -->

<div class="main_row mt-1 mb-3">
    <div class="main_col main_col_one">
        <nz-card>
            <div class="main_count_div">
                <div class="left_count">
                    <h5>Carts Received</h5>
                    <h3>{{orderData?.analytics?.totalCartCount ? orderData?.analytics?.totalCartCount : '0'}}</h3>
                </div>
                <div class="right_count">
                    <h5>Total cart value</h5>
                    <h3>{{orderData?.analytics?.totalCartedAmount ? '₹ ' + orderData?.analytics?.totalCartedAmount :
                        '0'}}</h3>
                </div>
            </div>
        </nz-card>
    </div>
    <div class="main_col main_col_two">
        <nz-card>
            <div class="main_count_div">
                <div class="left_count">
                    <h5>Orders Received</h5>
                    <h3>{{orderData?.analytics?.totalOrderCount ? orderData?.analytics?.totalOrderCount : '0'}}</h3>
                </div>
                <div class="right_count">
                    <h5>Total order value</h5>
                    <h3>{{orderData?.analytics?.totalOrderedTotal ? '₹ '+orderData?.analytics?.totalOrderedTotal :
                        '0'}}</h3>
                </div>
            </div>
        </nz-card>
    </div>
    <div class="main_col_last main_col">
        <nz-card>
            <div class="main_count_div">
                <div class="right_count">
                    <h5>Conversion</h5>
                    <h3>{{orderData?.analytics?.orderConversionRate ? orderData?.analytics?.orderConversionRate +
                        ' %' : '0 %'}}</h3>
                </div>
            </div>
        </nz-card>
    </div>
</div>

<ngx-spinner bdColor="rgb(255,255,255)" size="medium" color="#000000" type="ball-clip-rotate-pulse"
    [fullScreen]="false"></ngx-spinner>