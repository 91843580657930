import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NZ_I18N, en_US } from "ng-zorro-antd/i18n";
import { NzBreadCrumbModule } from "ng-zorro-antd/breadcrumb";
import { ColorPickerModule } from "ngx-color-picker";
import { KeyboardShortcutsModule } from "ng-keyboard-shortcuts";
import { AppService } from "./shared/services/download.service";
import { HttpClientModule } from "@angular/common/http";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { PickerModule } from "@ctrl/ngx-emoji-mart";

import { DragDropModule } from "@angular/cdk/drag-drop";

import {
  registerLocaleData,
  PathLocationStrategy,
  LocationStrategy,
} from "@angular/common";
import en from "@angular/common/locales/en";

import { AppRoutingModule } from "./app-routing.module";
import { TemplateModule } from "./shared/template/template.module";
import { SharedModule } from "./shared/shared.module";
import { AppComponent } from "./app.component";
import { CommonLayoutComponent } from "./layouts/common-layout/common-layout.component";
import { FullLayoutComponent } from "./layouts/full-layout/full-layout.component";

import { NgChartjsModule } from "ng-chartjs";
import { ThemeConstantService } from "./shared/services/theme-constant.service";
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from "ngx-toastr";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { QRCodeSVGModule } from "ngx-qrcode-svg";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { NgxGraphModule } from "@swimlane/ngx-graph";
import { CodemirrorModule } from "@ctrl/ngx-codemirror";
// import { ReteModule } from "rete-angular-render-plugin";

import { initializeApp } from "firebase/app";
initializeApp(environment.firebase);
import { NgxDocViewerModule } from "ngx-doc-viewer";

// import { CardHoverDirective } from "../app/whatsapp-automation/dashboard/chat/chat-area/chat-area.component";

// NZ IMPORTS

import { NzButtonModule } from "ng-zorro-antd/button";
import { NzCardModule } from "ng-zorro-antd/card";
import { NzAvatarModule } from "ng-zorro-antd/avatar";
import { NzRateModule } from "ng-zorro-antd/rate";
import { NzBadgeModule } from "ng-zorro-antd/badge";
import { NzProgressModule } from "ng-zorro-antd/progress";
import { NzRadioModule } from "ng-zorro-antd/radio";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzDropDownModule } from "ng-zorro-antd/dropdown";
import { NzTimelineModule } from "ng-zorro-antd/timeline";
import { NzTabsModule } from "ng-zorro-antd/tabs";
import { NzTagModule } from "ng-zorro-antd/tag";
import { NzListModule } from "ng-zorro-antd/list";
import { NzCalendarModule } from "ng-zorro-antd/calendar";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { NzCheckboxModule } from "ng-zorro-antd/checkbox";
import { NzModalModule } from "ng-zorro-antd/modal";
import { NzUploadModule } from "ng-zorro-antd/upload";
import { NzMessageModule } from "ng-zorro-antd/message";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzTimePickerModule } from "ng-zorro-antd/time-picker";
import { NzDatePickerModule } from "ng-zorro-antd/date-picker";
import { NzPaginationModule } from "ng-zorro-antd/pagination";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzSwitchModule } from "ng-zorro-antd/switch";
import { NzCollapseModule } from "ng-zorro-antd/collapse";
import { NzSpinModule } from "ng-zorro-antd/spin";
import { NzDrawerModule } from "ng-zorro-antd/drawer";
import { NzImageModule } from "ng-zorro-antd/image";
import { NzPopoverModule } from "ng-zorro-antd/popover";
import { NzCascaderModule } from "ng-zorro-antd/cascader";
import { NzCarouselModule } from "ng-zorro-antd/carousel";
import { NzTreeSelectModule } from "ng-zorro-antd/tree-select";
import { NzSliderModule } from "ng-zorro-antd/slider";

import { InfiniteScrollModule } from "ngx-infinite-scroll";

import { NgOtpInputModule } from "ng-otp-input";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
// import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { LandingPageComponent } from "./whatsapp-automation/landing-page/landing-page.component";
import { HomePageComponent } from "./whatsapp-automation/home-page/home-page.component";
import { LoginPageComponent } from "./whatsapp-automation/login-page/login-page.component";
import { SighupPageComponent } from "./whatsapp-automation/sighup-page/sighup-page.component";
import { NotificationPageComponent } from "./whatsapp-automation/dashboard/notification-page/notification-page.component";
import { UserPageComponent } from "./whatsapp-automation/dashboard/user-page/user-page.component";
import { InboxPageComponent } from "./whatsapp-automation/dashboard/inbox-page/inbox-page.component";
import { UpdateWaDataComponent } from "./whatsapp-automation/dashboard/update-wa-data/update-wa-data.component";
import { DndDirective } from "./dnd.directive";
import { CreateCampaignComponent } from "./whatsapp-automation/dashboard/create-campaign/create-campaign.component";
import { ChatComponent } from "./whatsapp-automation/dashboard/chat/chat.component";
import { ChatAreaComponent } from "./whatsapp-automation/dashboard/chat/chat-area/chat-area.component";
import { ChatUsersComponent } from "./whatsapp-automation/dashboard/chat/chat-users/chat-users.component";
import { SimplebarAngularModule } from "simplebar-angular";
import { CampaignDetailsComponent } from "./whatsapp-automation/dashboard/campaign-details/campaign-details.component";
import { ChatUserDetailComponent } from "./whatsapp-automation/dashboard/chat/chat-user-detail/chat-user-detail.component";
import { CatalogueComponent } from "./whatsapp-automation/dashboard/catalogue/catalogue.component";
import { CreateCatalogueComponent } from "./whatsapp-automation/dashboard/create-catalogue/create-catalogue.component";

import { ScrollDetectDirective } from "./whatsapp-automation/dashboard/chat/shared/scroll-detect.directive";
import { OrdersPageComponent } from "./whatsapp-automation/dashboard/orders-page/orders-page.component";
import { AutomationComponent } from "./whatsapp-automation/dashboard/automation/automation.component";
import { CustomerPageComponent } from "./whatsapp-automation/dashboard/customer-page/customer-page.component";
import { SandboxMessageComponent } from "./whatsapp-automation/sandbox-message/sandbox-message.component";
import { SignupSuccessComponent } from "./whatsapp-automation/signup-success/signup-success.component";
import { SettingPageComponent } from "./whatsapp-automation/setting-page/setting-page.component";
import { WhatsappComponent } from "./whatsapp-automation/setting-page/whatsapp/whatsapp.component";
import { AccountDetailsComponent } from "./whatsapp-automation/setting-page/account-details/account-details.component";
import { FieldCollectionComponent } from "./whatsapp-automation/setting-page/field-collection/field-collection.component";
import { TagsComponent } from "./whatsapp-automation/setting-page/tags/tags.component";
import { CannedResponseComponent } from "./whatsapp-automation/setting-page/canned-response/canned-response.component";
import { WhatsappTemplateComponent } from "./whatsapp-automation/setting-page/whatsapp-template/whatsapp-template.component";
import { ChangePasswordComponent } from "./whatsapp-automation/setting-page/change-password/change-password.component";
import { ProfileComponent } from "./whatsapp-automation/setting-page/profile/profile.component";
import { FaqPageComponent } from "./whatsapp-automation/faq-page/faq-page.component";
import { SegmentComponent } from "./whatsapp-automation/dashboard/segment/segment.component";
import { CommercePageComponent } from "./whatsapp-automation/dashboard/commerce-page/commerce-page.component";
import { ImportHistoryComponent } from "./whatsapp-automation/dashboard/import-history/import-history.component";
import { UserJourneyComponent } from "./whatsapp-automation/user-journey/user-journey.component";
import { PluginPageComponent } from "./whatsapp-automation/plugin-page/plugin-page.component";
import { CommerceDashboardComponent } from "./whatsapp-automation/dashboard/commerce-page/commerce-dashboard/commerce-dashboard.component";
import { OnboardingLoaderPageComponent } from "./whatsapp-automation/onboarding-loader-page/onboarding-loader-page.component";
import { NoInternetPageComponent } from "./whatsapp-automation/no-internet-page/no-internet-page.component";
import { RemoveInspectComponent } from "./whatsapp-automation/remove-inspect/remove-inspect.component";
import { environment } from "src/environments/environment";
import { ChatBotComponent } from "./whatsapp-automation/bot/chat-bot/chat-bot.component";
import { DashboardPageComponent } from "./whatsapp-automation/dashboard-page/dashboard-page.component";
import { ContentPageComponent } from "./whatsapp-automation/content-page/content-page.component";
import { SubscriptionComponent } from "./whatsapp-automation/subscription/subscription.component";
import { PaymentSuccessComponent } from "./whatsapp-automation/payments/payment-success/payment-success.component";
import { PaymentFailedComponent } from "./whatsapp-automation/payments/payment-failed/payment-failed.component";
import { ApiKeysComponent } from "./whatsapp-automation/api-keys/api-keys.component";
import { DeveloperComponent } from "./whatsapp-automation/developer/developer.component";
import { CommerceMessagesComponent } from "./whatsapp-automation/dashboard/commerce-page/commerce-messages/commerce-messages.component";
import { RechargeHistoryComponent } from "./whatsapp-automation/payments/recharge-history/recharge-history.component";
import { WorkingHoursComponent } from "./whatsapp-automation/setting-page/working-hours/working-hours.component";
import { AllBotsComponent } from "./whatsapp-automation/bot/all-bots/all-bots.component";
import { BotFlowsComponent } from "./whatsapp-automation/bot/bot-flows/bot-flows.component";
import { BotTemplatesComponent } from "./whatsapp-automation/bot/bot-templates/bot-templates.component";
import { WorkflowComponent } from "./whatsapp-automation/flow/workflow/workflow.component";
import { FlowBuilderComponent } from "./whatsapp-automation/flow/flow-builder/flow-builder.component";
import { ConversationHistoryComponent } from "./whatsapp-automation/dashboard-page/conversation-history/conversation-history.component";
import { AllAppsComponent } from "./whatsapp-automation/apps/all-apps/all-apps.component";
import { KanbanBoardComponent } from "./whatsapp-automation/dashboard/kanban-board/kanban-board.component";
// import { CommerceItemsComponent } from "./whatsapp-automation/dashboard/commerce-page/commerce-items/commerce-items.component";
// import { CommerceCollectionsComponent } from './whatsapp-automation/dashboard/commerce-page/commerce-collections/commerce-collections.component';
// import { CommercePaymentsComponent } from './whatsapp-automation/dashboard/commerce-page/commerce-payments/commerce-payments.component';
// import { CommerceCustomersComponent } from './whatsapp-automation/dashboard/commerce-page/commerce-customers/commerce-customers.component';

registerLocaleData(en);

@NgModule({
  declarations: [
    AppComponent,
    CommonLayoutComponent,
    FullLayoutComponent,
    LandingPageComponent,
    HomePageComponent,
    LoginPageComponent,
    SighupPageComponent,
    NotificationPageComponent,
    UserPageComponent,
    InboxPageComponent,
    UpdateWaDataComponent,
    DndDirective,
    ScrollDetectDirective,
    CreateCampaignComponent,
    ChatComponent,
    ChatAreaComponent,
    ChatUsersComponent,
    CampaignDetailsComponent,
    ChatUserDetailComponent,
    CatalogueComponent,
    CreateCatalogueComponent,
    OrdersPageComponent,
    AutomationComponent,
    CustomerPageComponent,
    SandboxMessageComponent,
    SignupSuccessComponent,
    SettingPageComponent,
    WhatsappComponent,
    AccountDetailsComponent,
    FieldCollectionComponent,
    TagsComponent,
    CannedResponseComponent,
    WhatsappTemplateComponent,
    ChangePasswordComponent,
    ProfileComponent,
    FaqPageComponent,
    SegmentComponent,
    CommercePageComponent,
    ImportHistoryComponent,
    UserJourneyComponent,
    PluginPageComponent,
    CommerceDashboardComponent,
    OnboardingLoaderPageComponent,
    NoInternetPageComponent,
    RemoveInspectComponent,
    ChatBotComponent,
    DashboardPageComponent,
    ContentPageComponent,
    SubscriptionComponent,
    PaymentSuccessComponent,
    PaymentFailedComponent,
    ApiKeysComponent,
    DeveloperComponent,
    CommerceMessagesComponent,
    RechargeHistoryComponent,
    WorkingHoursComponent,
    AllBotsComponent,
    BotFlowsComponent,
    BotTemplatesComponent,
    WorkflowComponent,
    FlowBuilderComponent,
    ConversationHistoryComponent,
    AllAppsComponent,
    KanbanBoardComponent,
    // CommerceItemsComponent,
    // CommerceCollectionsComponent,
    // CommercePaymentsComponent,
    // CommerceCustomersComponent,
    // CardHoverDirective,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NzBreadCrumbModule,
    TemplateModule,
    SharedModule,
    NgChartjsModule,
    NzButtonModule,
    NzCardModule,
    NzAvatarModule,
    NzRateModule,
    NzBadgeModule,
    NzProgressModule,
    NzRadioModule,
    NzTableModule,
    NzDropDownModule,
    NzTimelineModule,
    NzTabsModule,
    NzTagModule,
    NzListModule,
    NzCalendarModule,
    NzToolTipModule,
    NzCheckboxModule,
    NzModalModule,
    NzUploadModule,
    NzMessageModule,
    NzFormModule,
    NzSelectModule,
    NzInputModule,
    NzTimePickerModule,
    NzDatePickerModule,
    NzPaginationModule,
    NzSwitchModule,
    NzCollapseModule,
    NzSpinModule,
    NzImageModule,
    NgOtpInputModule,
    NzDrawerModule,
    NzCascaderModule,
    NzTreeSelectModule,
    NzCarouselModule,
    NzSliderModule,
    ColorPickerModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    ToastrModule.forRoot(),
    // AngularFireAuthModule,
    KeyboardShortcutsModule.forRoot(),
    HttpClientModule,
    Ng2SearchPipeModule,
    SimplebarAngularModule,
    PickerModule,
    InfiniteScrollModule,
    AngularEditorModule,
    NzPopoverModule,
    QRCodeSVGModule,
    DragDropModule,
    NgxIntlTelInputModule,
    NgxGraphModule,
    NgxDocViewerModule,
    CodemirrorModule,
    // ReteModule,
  ],
  providers: [
    {
      provide: NZ_I18N,
      useValue: en_US,
    },
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
    ThemeConstantService,
    AppService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
