import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CommonApiService } from "src/app/services/common-api.service";
import { environment } from "src/environments/environment";

import * as moment from "moment";
import { NgxSpinnerService } from "ngx-spinner";
import { NavService } from "src/app/services/nav.service";
import { ToastrService } from "ngx-toastr";

import { countryCodes } from "../../dashboard/chat/shared/messageData";
import { Languages } from "../../dashboard/chat/shared/messageData";
import { NzModalService } from "ng-zorro-antd/modal";
import {
  AngularEditorComponent,
  AngularEditorConfig,
} from "@kolkov/angular-editor";
import { AlertServiceService } from "src/app/services/alert-service.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-whatsapp-template",
  templateUrl: "./whatsapp-template.component.html",
  styleUrls: ["./whatsapp-template.component.css"],
})
export class WhatsappTemplateComponent implements OnInit {
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "15rem",
    minHeight: "5rem",
    placeholder: "Enter your message here..",
    translate: "no",
    defaultParagraphSeparator: "p",
    defaultFontName: "",
    toolbarHiddenButtons: [["list", ""]],
  };
  moment: any = moment;
  showForm: boolean = false;
  countryCodes: any = countryCodes;
  showSampleForm: boolean = false;

  set = "twitter";

  urlValidator = Validators.pattern(/^https?:\/\/[\w\-]+(\.[\w\-]+)*[/#?]?.*$/);

  orderColumn: any = [
    {
      title: "Name",
      width: "110px",
    },
    {
      title: "Message",
      width: "110px",
    },
    {
      title: "Category",
      width: "80px",
    },
    {
      title: "Language",
      width: "50px",
    },
    {
      title: "Added On",
      width: "100px",
    },
    {
      title: "Status",
      width: "60px",
    },
    {
      title: "Action",
      width: "40px",
    },
  ];

  templateData: any = [];

  addTemplateForm!: FormGroup;
  addSampleForm!: FormGroup;

  filesTypes: any = {
    DOCUMENT: [
      // "text/plain",
      "application/pdf",
      "application/vnd.ms-powerpoint",
      "application/msword",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ],
    VIDEO: ["video/mp4", "video/3gp"],
    IMAGE: [
      "image/png",
      "image/jpg",
      "image/jpeg",
      // "image/webp",
      // "image/svg+xml",
    ],
  };

  filesSizeValidation: any = {
    IMAGE: 5,
    VIDEO: 16,
    DOCUMENT: 100,
  };

  // pagination

  pageIndex = 1;
  totalCount: number;
  pageSize: number = 20;
  tableHeight: any = "";

  mainUserData: any;

  syncing: boolean = false;

  comingSoon: boolean = false;
  creating: boolean = false;
  languages: any = Languages;

  // variable data

  variableIndex: number = 0;

  // buttons

  allButtons: any = [];

  // media

  selectedMedia: any;
  previewMedia: any;

  // sample values section

  sampleValuesBody: any = [];
  sampleValuesHeader: any = [];
  sampleValuesURL: any = [];
  sampleData: any = {};

  // edit section

  isEdit: boolean = false;
  editData: any;

  searchText: any = "";

  emojiVisible: boolean = false;

  selectedStyle: any = "";

  statusFilter: string = "all";

  searchTimeout: any;

  showPreview: boolean = false;
  previewTemplate: any = {};

  modalWidth: any = "35%";

  private subscription: Subscription;

  constructor(
    private fb: FormBuilder,
    private apiService: CommonApiService,
    private spinner: NgxSpinnerService,
    private navService: NavService,
    private toastr: ToastrService,
    private modal: NzModalService,
    private alertService: AlertServiceService
  ) {}

  @ViewChild("bodyText", { static: false }) bodyText: ElementRef;
  @ViewChild("headerText", { static: false }) headerText: ElementRef;

  ngOnInit(): void {
    if (window.innerWidth > 768) {
      this.modalWidth = "35%";
    } else {
      this.modalWidth = "95%";
    }
    this.getAllTemplates();
    // this.getAllLanguages();
    this.addTemplateForm = this.fb.group({
      name: ["", [Validators.required, Validators.maxLength(512)]],
      platform: ["whatsapp", Validators.required],
      category: ["UTILITY", Validators.required],
      language: ["en_US", Validators.required],
      templateType: ["media&interactive", Validators.required],
      headerType: ["TEXT"],
      headerFormat: ["IMAGE"],
      headerText: ["", Validators.maxLength(60)],
      bodyText: ["", [Validators.required, Validators.maxLength(1024)]],
      footerText: ["", Validators.maxLength(60)],
      buttonType: ["QUICK_REPLY", Validators.required],
    });

    this.subscription = this.navService.mainUserData.subscribe(
      (response: any) => {
        if (response) {
          // console.log(response);
          this.mainUserData = response.data.userData;
          if (
            response?.data?.userData?.accessTo?.sandboxId &&
            window.innerWidth > 992
          ) {
            this.tableHeight = "56vh";
          } else {
            this.tableHeight = "65vh";
          }
        }
      }
    );
  }

  getAllTemplates() {
    this.spinner.show();
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    var body = {
      filterByName: this.searchText,
      filterByStatus: this.statusFilter,
    };

    this.apiService
      .commonPostMethod(
        // environment.url + "templates?next=" + this.nextKey,
        environment.url +
          "templates/get-all?page=" +
          this.pageIndex +
          "&limit=" +
          this.pageSize, // new api url
        body,
        options
      )
      .subscribe(
        (response: any) => {
          // console.log(response);
          this.templateData = response?.data?.waMsgTemplates;
          this.totalCount = response?.data?.pagination?.totalCount;
          this.pageSize = response?.data?.pagination?.limit;
          // console.log(this.templateData);
          this.spinner.hide();
        },
        (error: any) => {
          this.spinner.hide();
          console.log(error);
        }
      );
  }

  getAllLanguages() {
    this.apiService
      .commonGetMethod(environment.url + "language/get", {})
      .subscribe(
        (response: any) => {
          this.languages = response?.data?.language;
          // console.log(this.languages);
        },
        (err: any) => {
          console.log(err);
        }
      );
  }

  showTemplateForm() {
    if (!this.mainUserData?.accessTo?.isExpired) {
      if (!this?.mainUserData?.accessTo?.sandboxId) {
        this.creating = false;
        this.resetTotalForm();
        this.isEdit = false;
        this.editData = {};
        this.showForm = true;
      } else {
        var errHead: any = "You are using Sandbox account!";
        var errBody: any = "Please Subscribe any Plan to Add Templates!..";
        this.alertService.freeTrialWarning(errHead, errBody);
      }
    }
    // this.comingSoon = true;
  }

  showTemplateTable() {
    this.resetTotalForm();
    this.showForm = false;
  }

  showEditForm(editData: any) {
    // this.comingSoon = true;
    this.editData = editData;
    // console.log(editData);
    this.addTemplateForm.get("category").setValue(editData?.category);
    this.addTemplateForm.get("name").setValue(editData?.name);
    this.addTemplateForm.get("language").setValue(editData?.language);
    for (let i = 0; i < editData?.components?.length; i++) {
      if (editData?.components[i]?.type == "BODY") {
        this.addTemplateForm
          .get("bodyText")
          .setValue(editData?.components[i]?.text);
      } else if (editData?.components[i]?.type == "FOOTER") {
        this.addTemplateForm
          .get("footerText")
          .setValue(editData?.components[i]?.text);
      } else if (editData?.components[i]?.type == "HEADER") {
        if (editData?.components[i]?.format == "TEXT") {
          this.addTemplateForm.get("headerType").setValue("TEXT");
          this.addTemplateForm
            .get("headerText")
            .setValue(editData?.components[i]?.text);
        } else {
          this.addTemplateForm.get("headerType").setValue("media");
          this.addTemplateForm
            .get("headerFormat")
            .setValue(editData?.components[i]?.format);
        }
      } else if (editData?.components[i]?.type == "BUTTONS") {
        for (let j = 0; j < editData?.components[i]?.buttons?.length; j++) {
          var btnObj = {};
          if (editData?.components[i]?.buttons[j]?.type == "QUICK_REPLY") {
            this.addTemplateForm.get("buttonType").setValue("QUICK_REPLY");
            this.addTemplateForm.addControl(
              "buttonLabel" + this.allButtons.length,
              this.fb.control(
                editData?.components[i]?.buttons[j]?.text,
                Validators.required
              )
            );
            btnObj = {
              text: "",
              type: "QUICK_REPLY",
            };
            this.allButtons.push(btnObj);
          } else if (editData?.components[i]?.buttons[j]?.type == "URL") {
            this.addTemplateForm.get("buttonType").setValue("call to action");
            this.addTemplateForm.addControl(
              "actionType" + this.allButtons.length,
              this.fb.control("url", Validators.required)
            );
            this.addTemplateForm.addControl(
              "actionLabel" + this.allButtons.length,
              this.fb.control(
                editData?.components[i]?.buttons[j]?.text,
                Validators.required
              )
            );
            this.addTemplateForm.addControl(
              "actionValue" + this.allButtons.length,
              this.fb.control(
                editData?.components[i]?.buttons[j]?.url,
                Validators.required
              )
            );

            btnObj = {
              text: "",
              type: "URL",
            };
            this.allButtons.push(btnObj);
          } else if (
            editData?.components[i]?.buttons[j]?.type == "PHONE_NUMBER"
          ) {
            this.addTemplateForm.get("buttonType").setValue("call to action");
            this.addTemplateForm.addControl(
              "actionType" + this.allButtons.length,
              this.fb.control("phone", Validators.required)
            );
            this.addTemplateForm.addControl(
              "actionLabel" + this.allButtons.length,
              this.fb.control(
                editData?.components[i]?.buttons[j]?.text,
                Validators.required
              )
            );
            this.addTemplateForm.addControl(
              "actionValue" + this.allButtons.length,
              this.fb.control(
                parseFloat(editData?.components[i]?.buttons[j]?.phone_number),
                Validators.required
              )
            );
            this.addTemplateForm.addControl(
              "countryCode" + this.allButtons.length,
              this.fb.control("+91", Validators.required)
            );
            btnObj = {
              text: "",
              type: "PHONE_NUMBER",
            };
            this.allButtons.push(btnObj);
          }
        }
      }
    }
    this.isEdit = true;
    this.showForm = true;
  }

  showDeleteAlert(item: any) {
    // this.comingSoon = true;
    if (this.mainUserData?.accessTo?.isExpired) {
      this.alertService.warning();
    } else {
      this.modal.confirm({
        nzOkText: "Yes",
        nzCancelText: "No",
        nzTitle: "<i>Sure you Want to delete this Template?</i>",
        nzContent: "<b>Once deleted, the Template cannot be recovered..</b>",
        nzOnOk: () => this.deleteTemplate(item),
      });
    }
  }

  deleteTemplate(item: any) {
    // console.log(item);
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url + "templates/delete",
        { templateName: item?.name },
        options
      )
      .subscribe(
        (response: any) => {
          // console.log(response);
          this.pageIndex = 1;
          this.getAllTemplates();
          this.toastr.success(response.message, "", {
            timeOut: 2500,
          });
        },
        (err: any) => {
          console.log(err);
          var errorMessage = err?.error?.data?.error?.meta?.developer_message;
          if (errorMessage) {
            if (errorMessage.startsWith("Invalid payload")) {
              // Extract the values of "error_user_title" and "error_user_msg" using regular expressions
              const errorUserTitleMatch = errorMessage.match(
                /"error_user_title":"(.*?)"/
              );
              const errorUserMsgMatch = errorMessage.match(
                /"error_user_msg":"(.*?)"/
              );

              if (errorUserTitleMatch && errorUserMsgMatch) {
                const errorUserTitle = errorUserTitleMatch[1];
                const errorUserMsg = errorUserMsgMatch[1];

                this.toastr.error(errorUserMsg, errorUserTitle, {
                  timeOut: 50000,
                  progressBar: true,
                  progressAnimation: "decreasing",
                  enableHtml: true,
                });
              } else {
                this.toastr.error(
                  err?.error?.data?.error?.meta?.developer_message,
                  "",
                  {
                    timeOut: 10000,
                    progressBar: true,
                    progressAnimation: "decreasing",
                  }
                );
              }
            } else {
              this.toastr.error(
                err?.error?.data?.error?.meta?.developer_message,
                "",
                {
                  timeOut: 10000,
                  progressBar: true,
                  progressAnimation: "decreasing",
                }
              );
            }
          } else {
            this.toastr.error(err?.error.data.error, "", {
              timeOut: 10000,
            });
          }
        }
      );
  }

  backFromComingSoon() {
    this.comingSoon = false;
  }

  syncTemplate() {
    this.syncing = true;
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url + "templates/sync-templates",
        {},
        options
      )
      .subscribe(
        (response: any) => {
          // console.log(response);
          this.pageIndex = 1;
          this.getAllTemplates();
          this.toastr.success(response.message, "", {
            timeOut: 2500,
          });
          this.syncing = false;
        },
        (err: any) => {
          console.log(err);
          this.syncing = false;
          this.toastr.error(err?.error?.message, "", {
            timeOut: 2500,
          });
        }
      );
  }

  addVariable() {
    if (this.addTemplateForm.value.bodyText) {
      const inputElement = this.bodyText.nativeElement;
      const cursorPosition = inputElement.selectionStart;
      const currentValue = this.addTemplateForm.value.bodyText;
      const variables = currentValue.match(/{{\d+}}/g) || []; // Get an array of all variables in the input text
      let nextVariable = variables.length + 1; // Determine the next variable number
      let newValue = currentValue;
      for (let i = 1; i <= nextVariable; i++) {
        const variable = `{{${i}}}`;
        if (!variables.includes(variable)) {
          if (i === nextVariable) {
            // newValue = `${currentValue}${variable}`; // Add the missing variable at the end
            newValue = `${currentValue.slice(
              0,
              cursorPosition
            )}${variable}${currentValue.slice(cursorPosition)}`;
            // this.addTemplateForm.get("bodyText").setValue(newValue);
          } else {
            const index = variables.findIndex(
              (v: any) => Number(v.slice(2, -2)) > i
            ); // Find the index of the first variable with a higher number
            const insertIndex =
              index === -1
                ? currentValue.length
                : currentValue.indexOf(variables[index]); // Determine the index to insert the missing variable
            newValue = `${currentValue.slice(
              0,
              insertIndex
            )}${variable}${currentValue.slice(insertIndex)}`; // Add the missing variable at the determined index
            break;
          }
        }
      }

      // const currentValue = this.addTemplateForm.value.bodyText;

      // const newValue = `${currentValue.slice(0, cursorPosition)}{{${
      //   this.nextVariable
      // }}}${currentValue.slice(cursorPosition)}`;
      // inputElement.value = newValue;
      // inputElement.selectionStart =
      //   cursorPosition + this.nextVariable.toString().length + 4;
      // inputElement.selectionEnd = inputElement.selectionStart;
      // inputElement.focus();

      // this.nextVariable++;

      // console.log(newValue);
      this.addTemplateForm.get("bodyText").setValue(newValue);
      // // this.addTemplateForm.value.bodyText.setSelectionRange(
      // //   newValue.length,
      // //   newValue.length
      // // );
      // this.addTemplateForm.patchValue({ bodyText: newValue });
      // var variableKey: any;
      // var temp: any = this.detectVariables(this.addTemplateForm.value.bodyText);
      // // console.log(temp.indexOf((temp.length + 1).toString()));
      // if (temp.indexOf((temp.length + 1).toString()) > -1) {
      //   variableKey = temp.length + 2;
      // } else if (temp.indexOf((temp.length + 1).toString()) == -1) {
      //   variableKey = temp.length + 1;
      // }
      // const inputElement = this.bodyText.nativeElement;
      // const cursorPos = inputElement.selectionStart;
      // const currentValue = this.addTemplateForm.value.bodyText;
      // const newValue = `${currentValue.slice(0, cursorPos)}${
      //   "{{" + variableKey + "}}"
      // }${currentValue.slice(cursorPos)}`;
      // this.addTemplateForm.get("bodyText").setValue(newValue);
      // inputElement.setSelectionRange(
      //   cursorPos + variableKey.length,
      //   cursorPos + variableKey.length
      // );
    }
  }

  addHeaderVariable() {
    if (this.addTemplateForm.value.headerText) {
      var temp: any = this.detectVariables(
        this.addTemplateForm.value.headerText
      );
      // console.log(temp);
      if (temp.indexOf("1") == -1) {
        const inputElement = this.headerText.nativeElement;
        const cursorPos = inputElement.selectionStart;
        const currentValue = this.addTemplateForm.value.headerText;
        const newValue = `${currentValue.slice(
          0,
          cursorPos
        )}${"{{1}}"}${currentValue.slice(cursorPos)}`;
        this.addTemplateForm.get("headerText").setValue(newValue);
        inputElement.setSelectionRange(cursorPos + 1, cursorPos + 1);
      }
    }
  }

  addButton() {
    if (this.allButtons.length <= 2) {
      var btnObj = {};
      if (this.addTemplateForm.value.buttonType == "QUICK_REPLY") {
        this.addTemplateForm.addControl(
          "buttonLabel" + this.allButtons.length,
          this.fb.control("", Validators.required)
        );
        btnObj = {
          text: "",
          type: "QUICK_REPLY",
        };
      } else if (this.addTemplateForm.value.buttonType == "call to action") {
        this.addTemplateForm.addControl(
          "actionLabel" + this.allButtons.length,
          this.fb.control("", Validators.required)
        );
        this.addTemplateForm.addControl(
          "actionValue" + this.allButtons.length,
          this.fb.control("", Validators.required)
        );

        if (this.addTemplateForm?.value?.actionType0 == "phone") {
          btnObj = {
            text: "",
            type: "URL",
          };
          this.addTemplateForm.addControl(
            "actionType" + this.allButtons.length,
            this.fb.control("url", Validators.required)
          );
        } else {
          this.addTemplateForm.addControl(
            "actionType" + this.allButtons.length,
            this.fb.control("phone", Validators.required)
          );
          this.addTemplateForm.addControl(
            "countryCode" + this.allButtons.length,
            this.fb.control("+91", Validators.required)
          );
          btnObj = {
            text: "",
            type: "PHONE_NUMBER",
          };
        }
      }
      this.allButtons.push(btnObj);

      console.log(this.addTemplateForm.value);
    }
  }

  removeButton(index: any) {
    var tempArr = JSON.parse(JSON.stringify(this.allButtons));
    console.log(tempArr);
    var filteredArray = tempArr.filter(
      (obj: any) => obj["type"] === "PHONE_NUMBER"
    );
    // console.log(filteredArray);
    this.allButtons.splice(index, 1);
    if (this.addTemplateForm.value.buttonType == "QUICK_REPLY") {
      this.addTemplateForm.removeControl(
        "buttonLabel" + this.allButtons.length
      );
    } else if (this.addTemplateForm.value.buttonType == "call to action") {
      this.addTemplateForm.removeControl(
        "actionLabel" + this.allButtons.length
      );
      this.addTemplateForm.removeControl(
        "actionValue" + this.allButtons.length
      );
      this.addTemplateForm.removeControl("actionType" + this.allButtons.length);
      if (this.addTemplateForm.value["actionType" + index] == "phone") {
        this.addTemplateForm.removeControl(
          "countryCode" + (filteredArray.length - 1)
        );
      }
    }
  }

  // submit form

  submitForm() {
    // console.log(this.addTemplateForm.value);
    if (this.addTemplateForm.valid) {
      this.sampleValuesURL = [];
      // console.log(this.addTemplateForm.value);
      this.creating = true;
      var reqObj: any = {
        category: this.addTemplateForm.value.category,
        language: this.addTemplateForm.value.language,
        name: this.addTemplateForm.value.name,
        components: [],
      };

      if (this?.addTemplateForm?.value?.headerType == "TEXT") {
        var headerObj: any = {
          format: "TEXT",
          text: this?.addTemplateForm?.value?.headerText,
          type: "HEADER",
        };
        if (this?.addTemplateForm?.value?.headerText) {
          reqObj.components.push(headerObj);
        }
      }

      if (this?.addTemplateForm?.value?.headerType == "media") {
        var headerObj: any = {
          format: this?.addTemplateForm?.value?.headerFormat,
          type: "HEADER",
        };
        if (this?.selectedMedia) {
          reqObj.components.push(headerObj);
        } else {
          this.toastr.error(
            "Please upload valid media file to create template",
            "",
            {
              timeOut: 2500,
            }
          );
          this.creating = false;
        }
      }

      var bodyObj = {
        text: this.addTemplateForm.value.bodyText,
        type: "BODY",
      };
      reqObj.components.push(bodyObj);

      var footerObj = {
        text: this.addTemplateForm.value.footerText,
        type: "FOOTER",
      };
      if (this.addTemplateForm.value.footerText) {
        reqObj.components.push(footerObj);
      }

      var buttonObj: any = {
        buttons: [],
        type: "BUTTONS",
      };

      for (let i = 0; i < this.allButtons.length; i++) {
        if (this.addTemplateForm.value.buttonType == "QUICK_REPLY") {
          var tempObj = {
            text: this.addTemplateForm.value["buttonLabel" + i],
            type: "QUICK_REPLY",
          };
          buttonObj.buttons.push(tempObj);
        } else {
          if (this.addTemplateForm.value["actionType" + i] == "phone") {
            var phoneObj = {
              text: this.addTemplateForm.value["actionLabel" + i],
              type: "PHONE_NUMBER",
              phone_number:
                this.addTemplateForm.value["countryCode" + i] +
                this.addTemplateForm.value["actionValue" + i],
            };
            buttonObj.buttons.push(phoneObj);
          } else if (this.addTemplateForm.value["actionType" + i] == "url") {
            if (
              this.detectVariables(
                this.addTemplateForm.value["actionValue" + i]
              ).length > 0
            ) {
              this.sampleValuesURL.push(
                this.addTemplateForm.value["actionValue" + i]
              );
            }
            var urlObj = {
              text: this.addTemplateForm.value["actionLabel" + i],
              type: "URL",
              url: this.addTemplateForm.value["actionValue" + i],
            };
            buttonObj.buttons.push(urlObj);
          }
        }
      }
      if (buttonObj?.buttons?.length > 0) {
        reqObj.components.push(buttonObj);
      }

      // console.log(reqObj);
      if (
        this.detectVariables(this.addTemplateForm.value.bodyText).length > 0 ||
        this.detectVariables(this.addTemplateForm.value.headerText).length >
          0 ||
        this.sampleValuesURL.length > 0
      ) {
        this.showSampleModal();
        this.sampleData = reqObj;
      } else if (
        this?.addTemplateForm?.value?.headerType != "media" ||
        this.selectedMedia
      ) {
        this.sampleValuesBody = [];
        this.sampleValuesHeader = [];
        this.createTemplate(reqObj);
        this.sampleData = {};
      }
    } else {
      this.toastr.error(
        "Please provide all required information to continue.",
        "",
        {
          timeOut: 2500,
        }
      );
      Object.values(this.addTemplateForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  showSampleModal() {
    this.creating = false;
    this.addSampleForm = this.fb.group({});
    this.sampleValuesBody = this.detectVariables(
      this.addTemplateForm.value.bodyText
    );
    this.sampleValuesHeader = this.detectVariables(
      this.addTemplateForm.value.headerText
    );
    // console.log(this.sampleValuesURL);
    for (let i = 0; i < this.sampleValuesBody.length; i++) {
      this.addSampleForm?.addControl(
        "sampleBody" + (i + 1),
        this.fb.control("", Validators.required)
      );
    }
    for (let i = 0; i < this.sampleValuesHeader.length; i++) {
      this.addSampleForm?.addControl(
        "sampleHeader" + (i + 1),
        this.fb.control("", Validators.required)
      );
    }
    for (let s = 0; s < this.sampleValuesURL.length; s++) {
      this.addSampleForm?.addControl(
        "sampleURL" + (s + 1),
        this.fb.control("", Validators.required)
      );
    }
    // console.log(this.addSampleForm.value);
    this.showSampleForm = true;
  }

  addSampleData() {
    if (this.addSampleForm.valid) {
      this.creating = true;
      // console.log(this.addSampleForm.value);
      this.createTemplate(this.sampleData);
    } else {
      Object.values(this.addSampleForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  backToForm() {
    this?.addSampleForm?.reset();
    for (let i = 0; i < this.sampleValuesBody.length; i++) {
      this.addSampleForm?.removeControl("sampleBody" + (i + 1));
      this.addSampleForm?.removeControl("sampleHeader" + (i + 1));
      this.addSampleForm?.removeControl("sampleURL" + (i + 1));
    }
    this.sampleValuesBody = [];
    this.sampleValuesURL = [];
    this.sampleValuesHeader = [];
    this.showSampleForm = false;
  }

  createTemplate(reqObj: any) {
    var formData = new FormData();
    formData.append("category", reqObj?.category);
    formData.append("language", reqObj?.language);
    formData.append("name", reqObj?.name);
    for (let j = 0; j < reqObj?.components?.length; j++) {
      if (
        reqObj?.components[j]?.type == "BODY" ||
        reqObj?.components[j]?.type == "FOOTER"
      ) {
        formData.append(
          "components[" + j + "][text]",
          reqObj?.components[j]?.text
        );
        formData.append(
          "components[" + j + "][type]",
          reqObj?.components[j]?.type
        );
        if (
          reqObj?.components[j]?.type == "BODY" &&
          this.sampleValuesBody?.length > 0
        ) {
          for (let l = 0; l < this.sampleValuesBody?.length; l++) {
            formData.append(
              "components[" + j + "][example][body_text][0][" + l + "]",
              this.addSampleForm.value["sampleBody" + (l + 1)]
            );
          }
        }
      } else if (reqObj?.components[j]?.type == "BUTTONS") {
        formData.append("components[" + j + "][type]", "BUTTONS");
        for (let s = 0; s < reqObj?.components[j]?.buttons?.length; s++) {
          formData.append(
            "components[" + j + "][buttons][" + s + "][text]",
            reqObj.components[j].buttons[s].text
          );
          formData.append(
            "components[" + j + "][buttons][" + s + "][type]",
            reqObj.components[j].buttons[s].type
          );
          if (reqObj.components[j].buttons[s].type == "PHONE_NUMBER") {
            formData.append(
              "components[" + j + "][buttons][" + s + "][phone_number]",
              reqObj.components[j].buttons[s].phone_number
            );
          } else if (reqObj.components[j].buttons[s].type == "URL") {
            formData.append(
              "components[" + j + "][buttons][" + s + "][url]",
              reqObj.components[j].buttons[s].url
            );
            if (
              this.detectVariables(reqObj?.components[j]?.buttons[s]?.url)
                ?.length > 0
            ) {
              for (let b = 0; b < this.sampleValuesURL.length; b++) {
                if (
                  this.sampleValuesURL[b] ==
                  reqObj?.components[j]?.buttons[s]?.url
                ) {
                  formData.append(
                    "components[" + j + "][buttons][" + s + "][example][0]",
                    this.addSampleForm.value["sampleURL" + (b + 1)]
                  );
                }
              }
            }
          }
        }
      } else if (reqObj?.components[j]?.type == "HEADER") {
        formData.append(
          "components[" + j + "][format]",
          reqObj?.components[j]?.format
        );
        formData.append(
          "components[" + j + "][type]",
          reqObj?.components[j]?.type
        );
        if (reqObj?.components[j]?.format == "TEXT") {
          formData.append(
            "components[" + j + "][text]",
            reqObj?.components[j]?.text
          );
          if (this.sampleValuesHeader?.length > 0) {
            formData.append(
              "components[" + j + "][example][header_text][0]",
              this.addSampleForm.value["sampleHeader1"]
            );
          }
        } else {
          formData.append("file", this.selectedMedia);
        }
      }
    }

    // api section
    var url: any = "";

    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    if (this.isEdit) {
      url = "wa-template/update";
      formData.append("id", this?.editData?._id);
    } else {
      url = "templates/create";
    }
    // console.log(formData);
    // "http://192.168.1.19:4090/wa-template/create",
    this.apiService
      .commonPostMethod(environment.url + url, formData, options)
      .subscribe(
        (response: any) => {
          // console.log(response);
          this.toastr.success(response.message, "", {
            timeOut: 2500,
          });
          this.creating = false;
          this.isEdit = false;
          this.editData = {};
          this.resetTotalForm();
          this.backToForm();
          this.showForm = false;
          this.pageIndex = 1;
          this.getAllTemplates();
        },
        (err: any) => {
          console.log(err);
          this.creating = false;

          var errorMessage = err?.error?.data?.error?.meta?.developer_message;
          if (errorMessage) {
            if (errorMessage.startsWith("Invalid payload")) {
              // Extract the values of "error_user_title" and "error_user_msg" using regular expressions
              const errorUserTitleMatch = errorMessage.match(
                /"error_user_title":"(.*?)"/
              );
              const errorUserMsgMatch = errorMessage.match(
                /"error_user_msg":"(.*?)"/
              );

              if (errorUserTitleMatch && errorUserMsgMatch) {
                const errorUserTitle = errorUserTitleMatch[1];
                const errorUserMsg = errorUserMsgMatch[1];

                this.toastr.error(errorUserMsg, errorUserTitle, {
                  timeOut: 50000,
                  progressBar: true,
                  progressAnimation: "decreasing",
                  enableHtml: true,
                });
              } else {
                this.toastr.error(
                  err?.error?.data?.error?.meta?.developer_message,
                  "",
                  {
                    timeOut: 10000,
                    progressBar: true,
                    progressAnimation: "decreasing",
                  }
                );
              }
            } else {
              this.toastr.error(
                err?.error?.data?.error?.meta?.developer_message,
                "",
                {
                  timeOut: 10000,
                  progressBar: true,
                  progressAnimation: "decreasing",
                }
              );
            }
          } else {
            this.toastr.error(err?.error.data.error, "", {
              timeOut: 10000,
            });
          }
        }
      );
  }

  // variable detect

  detectVariables(str: string) {
    if (str) {
      const pattern = /{{([^{}]+)}}/g;
      const matches = str.match(pattern);
      if (!matches) {
        return [];
      }
      return matches.map((match) => match.replace(/{{\s*|\s*}}/g, "").trim());
    } else {
      return [];
    }
  }

  // button value reset

  actionTypeChange(i: any) {
    // console.log(i);
    // console.log(this.addTemplateForm.value);
    if (this.addTemplateForm.value["actionType" + i] == "phone") {
      this.addTemplateForm.addControl(
        "countryCode" + i,
        this.fb.control("+91", Validators.required)
      );
      // this.allButtons[i].type = "PHONE_NUMBER";
    } else {
      // this.allButtons[i].type = "URL";
      // const filteredArray = this.allButtons.filter(
      //   (obj: any) => obj.type === "PHONE_NUMBER"
      // );
      // console.log(this.allButtons);
      // console.log(filteredArray);
      this.addTemplateForm.removeControl("countryCode" + i);
    }

    this.addTemplateForm.get("actionLabel" + i).reset();
    this.addTemplateForm.get("actionValue" + i).reset();
    // console.log(this.addTemplateForm.value);
  }

  onPageNumberChange(id: any, event: any) {
    if (this.pageIndex != event.pageIndex) {
      this.pageIndex = event.pageIndex;
      this.getAllTemplates();
      document.getElementById(id).scrollIntoView();
    }
  }

  getTemplateMedia(event: any) {
    this.selectedMedia = event.target.files[0];
    const expectedSize =
      this.filesSizeValidation[this.addTemplateForm.value.headerFormat];
    // console.log(this.selectedMedia);
    const fileSizeMB: any = (this.selectedMedia.size / 1000000).toFixed(2); // convert bytes to MB
    console.log(fileSizeMB);
    if (fileSizeMB <= expectedSize) {
      const file = event.target.files[0];

      const reader = new FileReader();
      reader.onload = (e) => (this.previewMedia = reader.result);
      reader.readAsDataURL(file);
    } else {
      this.toastr.error(`File size must be less than ${expectedSize}MB`, "", {
        timeOut: 3500,
      });
    }
  }

  addWebVariable(formControl: any) {
    // console.log(this.addTemplateForm.value[formControl]);
    this.addTemplateForm
      .get(formControl)
      .setValue(this.addTemplateForm.value[formControl]?.concat("{{1}}"));
  }

  resetMedia() {
    this.selectedMedia = null;
    this.previewMedia = null;
  }

  onKeyDown(event: KeyboardEvent) {
    const allowedKeys = [8, 9, 13, 27, 32, 35, 36, 37, 38, 39, 40, 46];

    if (
      allowedKeys.indexOf(event.keyCode) !== -1 ||
      /^[a-zA-Z0-9_]+$/.test(event.key)
    ) {
      if (event.keyCode === 32) {
        console.log("hello");
        const value = this.addTemplateForm.value.name
          .replaceAll("_", " ")
          .replace(/\s+/g, "_")
          .toLowerCase();
        this.addTemplateForm.get("name").setValue(value);
      } else {
        const value = this.addTemplateForm.value.name.toLowerCase();
        this.addTemplateForm.get("name").setValue(value);
        return true;
      }
    } else {
      event.preventDefault();
      return false;
    }
  }

  headerTypeChange() {
    this.addTemplateForm.get("headerText").reset();
    this.addTemplateForm.get("headerFormat").setValue("IMAGE");
    this.selectedMedia = null;
    this.previewMedia = null;
  }

  templateTypeChange() {
    // console.log("template change");
    this.resetMedia();
    this.addTemplateForm.get("headerType").setValue("TEXT");
    this.addTemplateForm.get("headerFormat").setValue("IMAGE");
    this.addTemplateForm.get("buttonType").setValue("QUICK_REPLY");
    this.addTemplateForm.get("headerText").reset();
    // this.addTemplateForm.get("bodyText").reset();
    this.addTemplateForm.get("footerText").reset();
    this.allButtons = [];
    for (let n = 0; n < 3; n++) {
      this.addTemplateForm?.removeControl("buttonLabel" + n);
      this.addTemplateForm?.removeControl("actionLabel" + n);
      this.addTemplateForm?.removeControl("actionValue" + n);
      this.addTemplateForm?.removeControl("countryCode" + n);
      this.addTemplateForm?.removeControl("actionType" + n);
    }
  }

  resetTotalForm() {
    this.addTemplateForm.reset();
    this.addTemplateForm.get("platform").setValue("whatsapp");
    this.addTemplateForm.get("category").setValue("UTILITY");
    this.addTemplateForm.get("language").setValue("en_US");
    this.addTemplateForm.get("templateType").setValue("media&interactive");
    this.templateTypeChange();
  }

  closeSampleModal() {
    this.showSampleForm = false;
  }

  changePageSize(size: any) {
    this.pageSize = size;
    this.getAllTemplates();
  }

  searchTemplates() {
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      this.pageIndex = 1;
      this.getAllTemplates();
    }, 500);
  }

  boldButton: ElementRef;

  editText(style: any) {
    const editorElement = this.bodyText.nativeElement;
    const selectedText = editorElement.value.substring(
      editorElement.selectionStart,
      editorElement.selectionEnd
    );
    const boldRegex = /^\*(.*?)\*$/;
    const strikeRegex = /^~(.*)~$/;
    const italicRegex = /^_(.*)_$/;

    if (style == "bold") {
      if (!boldRegex.test(selectedText)) {
        this.selectedStyle = "Bold";
        const newValue = `${editorElement.value.substring(
          0,
          editorElement.selectionStart
        )}*${selectedText}*${editorElement.value.substring(
          editorElement.selectionEnd
        )}`;
        // editorElement.value = newValue;
        this.addTemplateForm.get("bodyText").setValue(newValue);
      } else {
        this.selectedStyle = "";
        const newValue = `${editorElement.value.substring(
          0,
          editorElement.selectionStart
        )}${selectedText.substring(
          1,
          selectedText.length - 1
        )}${editorElement.value.substring(editorElement.selectionEnd)}`;
        // editorElement.value = newValue;
        this.addTemplateForm.get("bodyText").setValue(newValue);
      }
    }

    if (style == "italic") {
      if (!italicRegex.test(selectedText)) {
        this.selectedStyle = "Italic";
        const newValue = `${editorElement.value.substring(
          0,
          editorElement.selectionStart
        )}_${selectedText}_${editorElement.value.substring(
          editorElement.selectionEnd
        )}`;
        // editorElement.value = newValue;
        this.addTemplateForm.get("bodyText").setValue(newValue);
      } else {
        this.selectedStyle = "";
        const newValue = `${editorElement.value.substring(
          0,
          editorElement.selectionStart
        )}${selectedText.substring(
          1,
          selectedText.length - 1
        )}${editorElement.value.substring(editorElement.selectionEnd)}`;
        // editorElement.value = newValue;
        this.addTemplateForm.get("bodyText").setValue(newValue);
      }
    }

    if (style == "strikethrough") {
      if (!strikeRegex.test(selectedText)) {
        this.selectedStyle = "Strike";
        const newValue = `${editorElement.value.substring(
          0,
          editorElement.selectionStart
        )}~${selectedText}~${editorElement.value.substring(
          editorElement.selectionEnd
        )}`;
        // editorElement.value = newValue;
        this.addTemplateForm.get("bodyText").setValue(newValue);
      } else {
        this.selectedStyle = "";
        const newValue = `${editorElement.value.substring(
          0,
          editorElement.selectionStart
        )}${selectedText.substring(
          1,
          selectedText.length - 1
        )}${editorElement.value.substring(editorElement.selectionEnd)}`;
        // editorElement.value = newValue;
        this.addTemplateForm.get("bodyText").setValue(newValue);
      }
    }

    // editorElement.focus();

    // const startPos = editorElement.selectionStart + 1;
    // const endPos = startPos + selectedText.length;

    // editorElement.setSelectionRange(startPos, endPos);
  }

  showEmoji() {
    this.emojiVisible = !this.emojiVisible;
  }

  addEmoji(selected: any) {
    // console.log(selected.emoji.native);
    const editorElement = this.bodyText.nativeElement;
    const currentValue = editorElement.value;
    const selectionStart = editorElement.selectionStart;
    const selectionEnd = editorElement.selectionEnd;

    const newValue =
      currentValue.substring(0, selectionStart) +
      selected.emoji.native +
      currentValue.substring(selectionEnd);

    // editorElement.value = newValue;
    this.addTemplateForm.get("bodyText").setValue(newValue);
    // editorElement.focus();

    const newCursorPosition = selectionStart + selected.emoji.native.length;
    editorElement.setSelectionRange(newCursorPosition, newCursorPosition);
  }

  onTextSelect() {
    this.selectedStyle = "";
    const editorElement = this.bodyText.nativeElement;
    const selectedText = editorElement.value.substring(
      editorElement.selectionStart,
      editorElement.selectionEnd
    );

    const boldRegex = /^\*(.*?)\*$/;
    const strikeRegex = /^~(.*)~$/;
    const italicRegex = /^_(.*)_$/;

    if (boldRegex.test(selectedText)) {
      this.selectedStyle = "Bold";
    }

    if (strikeRegex.test(selectedText)) {
      this.selectedStyle = "Strike";
    }

    if (italicRegex.test(selectedText)) {
      this.selectedStyle = "Italic";
    }
  }

  bodyTextFocus() {
    this.emojiVisible = false;
    this.selectedStyle = "";
  }

  mouseUp() {
    this.selectedStyle = "";
  }

  applyFilter(filter: any) {
    this.statusFilter = filter;
    this.pageIndex = 1;
    this.getAllTemplates();
  }

  setMyStyles() {
    let styles = {
      "margin-right":
        135 + (this.totalCount / this.pageSize).toFixed(0).length * 5 + "px",
    };
    return styles;
  }

  showTemplatePreview(item: any) {
    this.previewTemplate = item;
    for (let a = 0; a < this.previewTemplate.components.length; a++) {
      if (this.previewTemplate.components[a].type == "BODY") {
        this.previewTemplate.components[a].text = this.format_text(
          this.previewTemplate.components[a].text
        );
      }
    }

    // console.log(this.previewTemplate);
    this.showPreview = true;
  }

  closeTemplatePreview() {
    this.showPreview = false;
    // this.previewTemplate = null;
  }

  format_text(text: any) {
    return text
      .replace(/(?:\*)(?:(?!\s))((?:(?!\*|\n).)+)(?:\*)/g, "<b>$1</b>")
      .replace(/(?:_)(?:(?!\s))((?:(?!\n|_).)+)(?:_)/g, "<em>$1</em>")
      .replace(/(?:~)(?:(?!\s))((?:(?!\n|~).)+)(?:~)/g, "<s>$1</s>")
      .replace(/(?:--)(?:(?!\s))((?:(?!\n|--).)+)(?:--)/g, "<u>$1</u>")
      .replace(/(?:```)(?:(?!\s))((?:(?!\n|```).)+)(?:```)/g, "<tt>$1</tt>");
  }

  reverseFormatText(text: any) {
    return text
      .replaceAll("<b>", "*")
      .replaceAll("</b>", "*")
      .replaceAll("<p>", "")
      .replaceAll("</p>", "")
      .replaceAll("<i>", "_")
      .replaceAll("</i>", "_")
      .replaceAll("<strike>", "~")
      .replaceAll("</strike>", "~")
      .replaceAll("&#160;", "")
      .replaceAll("&#10;", "")
      .replaceAll("<br>", "\n")
      .replaceAll("<span>", "")
      .replaceAll("</span>", "");
  }

  onPaste(event: any) {
    const clipboardData = event.clipboardData;
    if (clipboardData) {
      const pastedText = clipboardData.getData("text");
      const formattedText = this.reverseFormatText(pastedText);
      // console.log("Formatted text:", formattedText);

      // Modify the textarea value to display the formatted text
      const textarea = event.target as HTMLTextAreaElement;
      const currentText = textarea.value;
      const selectionStart = textarea.selectionStart;
      const selectionEnd = textarea.selectionEnd;
      const newText =
        currentText.slice(0, selectionStart) +
        formattedText +
        currentText.slice(selectionEnd);
      // textarea.value = newText;
      this.addTemplateForm.get("bodyText").setValue(newText.slice(0, 1024));

      // Prevent the default paste behavior
      event.preventDefault();
    }
  }

  ngOnDestroy(): void {
    // console.log("api key destroy..");
    this.subscription.unsubscribe();
  }
}
