import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { NgOtpInputConfig } from "ng-otp-input";
import { interval, Subscription } from "rxjs";
import * as moment from "moment";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CommonApiService } from "src/app/services/common-api.service";
import { environment } from "src/environments/environment";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-signup-success",
  templateUrl: "./signup-success.component.html",
  styleUrls: ["./signup-success.component.css"],
})
export class SignupSuccessComponent implements OnInit, OnDestroy {
  // OPT INPUT CONFIG
  config: NgOtpInputConfig = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: "",
    inputStyles: {
      width: "14%",
      height: "auto",
      fontSize: "20px",
    },
  };

  subscription!: Subscription;
  resendTime = 300;

  pageName: any = "";

  moment: any = moment;
  setPasswordForm!: FormGroup;
  mailForm!: FormGroup;

  confirmPasswordVisible: boolean = false;
  newPasswordVisible: boolean = false;

  passwordIncorrect: boolean = false;

  otpEntered: any;
  submitting = false;

  recoveryData: any = {};

  creatingPassword: boolean = false;
  checkingEmail: boolean = false;

  loginBanners: any = [
    "../../../assets/images/login/banner_1.png",
    "../../../assets/images/login/banner_2.png",
    "../../../assets/images/login/banner_3.png",
    "../../../assets/images/login/banner_4.png",
    "../../../assets/images/login/banner_5.png",
    "../../../assets/images/login/banner_6.png",
  ];

  otpVerified: boolean = false;

  reqStatus: any = "";

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private apiService: CommonApiService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute
  ) {
    this.generateForm();
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.reqStatus = "";
        // console.log(event.url);
        this.checkingEmail = false;
        this.creatingPassword = false;
        if (event.url == "/signup-success") {
          this.pageName = "signup-success";
          setTimeout(() => {
            // this.router.navigate(["/chat"]);
            this.router.navigate(["/user-journey"]).then(() => {
              window.location.reload();
            });
          }, 2000);
        } else if (event.url == "/otp-verification") {
          // localStorage.removeItem("qwertrewetrt");
          this.pageName = "otp-verification";
          this.resendTime = 300;
          this.subscription = interval(1000).subscribe((data) => {
            if (this.resendTime > 0) {
              this.reduceTime();
            }
          });
        } else if (event.url == "/create-password") {
          // localStorage.removeItem("qwertrewetrt");
          this.pageName = "create-password";
          this.recoveryData = null;
        } else if (event.url == "/forgot-password") {
          localStorage.removeItem("qwertrewetrt");
          this.pageName = "forgot-password";
        } else if (
          event.url.includes("/create-password/") ||
          event.url.includes("forgot-password/")
        ) {
          localStorage.removeItem("qwertrewetrt");
          this.route.params.subscribe((data: any) => {
            this.reqStatus = data["status"];
            if (data["status"] == "uBqZ810ByQXllUbY87IRepICb") {
              setTimeout(() => {
                this.pageName = "create-password";
                this.reqStatus = "";
              }, 2000);
            }
            if (event.url.includes("/create-password/")) {
              this.pageName = "direct-mode-create";
              localStorage.setItem(
                "gtrdtghjdgfhjsgkahjhfjkafhfkjhfkfifghefywegfyuwgfuyew",
                data["userId"]
              );
              this.recoveryData = null;
              this.otpVerified = true;
            } else if (event.url.includes("forgot-password/")) {
              this.pageName = "direct-mode-forgot";
              this.recoveryData.userId = data["userId"];
              this.otpVerified = true;
            }
            this.setPasswordForm.get("otpNumber").setValue(data["otp"]);
            // this.otpVerified = true;
            // console.log(data);
          });
        }
      }
    });
  }

  ngOnInit(): void {
    // this.setPasswordForm = this.fb.group({
    //   otpNumber: ["", Validators.required],
    //   newPassword: ["", Validators.required],
    //   confirmPassword: ["", Validators.required],
    // });

    this.mailForm = this.fb.group({
      emailOrPhoneNo: ["", [Validators.required, Validators.email]],
    });
  }

  generateForm() {
    this.setPasswordForm = this.fb.group({
      otpNumber: ["", Validators.required],
      newPassword: ["", [Validators.required, Validators.minLength(8)]],
      confirmPassword: ["", [Validators.required, Validators.minLength(8)]],
    });
  }

  onOtpChange(event: any) {
    if (event.length == 6) {
      this.setPasswordForm.get("otpNumber").setValue(event);
      // console.log(event);
      this.verifyOTP();
    }
  }

  resendCode() {
    var data: any = {};
    if (this.recoveryData) {
      data.userId = this.recoveryData.userId;
    } else {
      data.userId = localStorage.getItem(
        "gtrdtghjdgfhjsgkahjhfjkafhfkjhfkfifghefywegfyuwgfuyew"
      );
    }

    this.apiService
      .commonPostMethod(environment.url + "auth/resend-otp", data, {})
      .subscribe(
        (data: any) => {
          // console.log(data);
        },
        (err) => {
          console.log(err);
        }
      );
  }

  verifyOTP() {
    this.spinner.show();
    var data: any = {
      otp: this.setPasswordForm.value.otpNumber.toString(),
    };

    if (this.recoveryData) {
      data.userId = this.recoveryData.userId;
    } else {
      data.userId = localStorage.getItem(
        "gtrdtghjdgfhjsgkahjhfjkafhfkjhfkfifghefywegfyuwgfuyew"
      );
    }

    this.apiService
      .commonPostMethod(environment.url + "auth/verify-otp", data, {})
      .subscribe(
        (data: any) => {
          // console.log(data);
          this.otpVerified = true;
          this.spinner.hide();
        },
        (err) => {
          console.log(err);
          this.otpVerified = false;
          this.spinner.hide();
          this.toastr.error(err?.error?.message, "", {
            timeOut: 2500,
          });
        }
      );

    // setTimeout(() => {
    //   this.otpVerified = true;
    //   this.spinner.hide();
    // }, 3000);
  }

  ngOnDestroy() {
    // console.log("destroy");
    if ((this.pageName = "otp-verification")) {
      this.subscription && this.subscription.unsubscribe();
    }
  }

  reduceTime() {
    if (this.resendTime > 0) {
      this.resendTime = this.resendTime - 1;
    }
  }

  verifyPasswords(type: string) {
    if (
      (this.setPasswordForm.value.newPassword && type == "confirm") ||
      (this.setPasswordForm.value.confirmPassword && type == "new")
    ) {
      if (
        this.setPasswordForm.value.newPassword !=
        this.setPasswordForm.value.confirmPassword
      ) {
        this.passwordIncorrect = true;
      } else {
        this.passwordIncorrect = false;
      }
    }
  }

  submitForm() {
    // console.log(this.setPasswordForm.value);
    if (this.setPasswordForm.valid) {
      if (
        this.setPasswordForm.value.newPassword !=
        this.setPasswordForm.value.confirmPassword
      ) {
        this.passwordIncorrect = true;
      } else {
        this.passwordIncorrect = false;
        this.submitting = true;
        this.creatingPassword = true;
        let options = {};

        let url = "";
        var data: any = {
          otp: this.setPasswordForm.value.otpNumber.toString(),
          password: this.setPasswordForm.value.newPassword,
        };

        if (this.recoveryData) {
          url = "auth/reset-password";
          data.userId = this.recoveryData.userId;
        } else {
          url = "auth/welcome-login";
          data.userId = localStorage.getItem(
            "gtrdtghjdgfhjsgkahjhfjkafhfkjhfkfifghefywegfyuwgfuyew"
          );
        }

        this.apiService
          .commonPostMethod(environment.url + url, data, options)
          .subscribe(
            (data: any) => {
              // console.log(data);
              this.submitting = false;
              this.creatingPassword = false;
              if (data.status == "success") {
                localStorage.setItem("qwertrewetrt", data.data.token);
                localStorage.removeItem(
                  "gtrdtghjdgfhjsgkahjhfjkafhfkjhfkfifghefywegfyuwgfuyew"
                );
                if (this.recoveryData) {
                  if (window.innerWidth > 992) {
                    this.router.navigate(["/chat"]);
                  } else {
                    this.router.navigate(["/chat/mobile", "id", "name"]);
                  }
                } else {
                  this.router.navigate(["/signup-success"]);
                }
              }
            },
            (err) => {
              console.log(err);
              this.creatingPassword = false;
              if (err?.error?.message) {
                this.toastr.error(err?.error?.message, "", {
                  timeOut: 2500,
                });
              } else {
                this.toastr.error(err?.message, "", {
                  timeOut: 2500,
                });
              }
              this.submitting = false;
            }
          );
      }
      // console.log(this.setPasswordForm.value);
    } else {
      Object.values(this.setPasswordForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  checkMail() {
    if (this.mailForm.valid) {
      // console.log(this.mailForm.value);
      this.checkingEmail = true;

      let options = {};

      var data = {
        phoneNoOrEmail: this.mailForm.value.emailOrPhoneNo,
      };

      this.apiService
        .commonPostMethod(
          environment.url + "auth/forgot-password",
          data,
          options
        )
        .subscribe(
          (data: any) => {
            // console.log(data);
            this.checkingEmail = false;
            if (data.status == "success") {
              this.pageName = "create-password";
            }
            this.recoveryData = data.data;
          },
          (err) => {
            console.log(err);
            this.checkingEmail = false;
            if (err?.error?.message) {
              this.toastr.error(err?.error?.message, "", {
                timeOut: 2500,
              });
            } else {
              this.toastr.error(err?.message, "", {
                timeOut: 2500,
              });
            }
          }
        );
    } else {
      Object.values(this.mailForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  backToForm() {
    const route =
      this.pageName == "direct-mode-create" ? "/signup" : "/forgot-password";
    if (this.reqStatus == "M0TJlzKIpokOrnClUgyxGcYct") {
      this.router.navigate(["/signup"]);
    } else {
      this.router.navigate([route]);
    }
  }

  formatMailId() {
    const value = this.mailForm.value.emailOrPhoneNo
      .replace(/ /g, "")
      .toLowerCase();
    this.mailForm.get("emailOrPhoneNo").setValue(value);
  }
}
