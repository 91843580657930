<div class="row overflow_row_mobile">
  <div class="col-12 main_outer_heading d_mobile_none">
    <h3>Campaigns</h3>
  </div>

  <div class="col-12">
    <nz-card class="contacts_top_card">
      <div class="contacts_top_bar">
        <div class="contacts_top_bar_left">
          <button class="segment_btn" nzTrigger="click" nz-button nz-dropdown [nzDropdownMenu]="segmentsMenu"
            [nzPlacement]="'bottomLeft'">
            All Campaigns
            <span class="d-flex" nz-icon nzType="down"></span>
          </button>
          <nz-dropdown-menu #segmentsMenu="nzDropdownMenu">
            <ul nz-menu>
              <li nz-menu-item>All Campaigns</li>
            </ul>
          </nz-dropdown-menu>
        </div>

        <div class="contacts_top_bar_right">
          <div class="search_add_div">
            <div class="toggle_options">
              <nz-input-group class="search_input_section small_input_sec" [nzPrefix]="suffixIconSearch">
                <input autocomplete="off" [(ngModel)]="searchKey" (keyup)="searchContacts()" type="text" nz-input
                  placeholder="Search here.." />
              </nz-input-group>
              <ng-template #suffixIconSearch>
                <span nz-icon nzType="search"></span>
              </ng-template>
            </div>
            <a [disabled]="mainUserData?.accessTo?.isExpired" (click)="addCampaign()" nz-button nzType="primary"
              class="create-button">
              <i nz-icon nzType="plus-circle" theme="outline"></i>
              <span>Add Campaign</span>
            </a>
            <span [ngClass]="{ reload_btn_loading: pageReloading }" (click)="reloadPage()" class="reload_btn"
              nzTooltipTitle="reload" nzTooltipPlacement="top" nz-tooltip nz-icon nzType="sync"
              nzTheme="outline"></span>
          </div>
        </div>
      </div>
    </nz-card>
  </div>


  <div class="col-12" id="main-section">
    <nz-card class="customer_right_card customers_table_card">
      <div class="total_records">
        <h6>Total Records ({{ totalCount }})</h6>

        <button class="customized_btn" [ngStyle]="setMyStyles()" nzTrigger="click" nz-dropdown
          [nzDropdownMenu]="paginationMenu" [nzPlacement]="'bottomLeft'">
          {{ pageSize + " records per page" }}
        </button>

        <nz-dropdown-menu #paginationMenu="nzDropdownMenu">
          <ul nz-menu>
            <li (click)="changePageSize((n + 1) * 10)" *ngFor="let page of [].constructor(5); let n = index"
              nz-menu-item>
              {{ (n + 1) * 10 + " records per page" }}
            </li>
          </ul>
        </nz-dropdown-menu>
      </div>

      <nz-table class="contacts_table main_contacts_table" #campaignsListTable [nzData]="campaignsList"
        [nzScroll]="{ y: tableHeight, x: '900px' }" [nzFrontPagination]="false" [nzTotal]="totalCount"
        [nzPageSize]="pageSize" [nzPageIndex]="pageIndex" (nzQueryParams)="onPageNumberChange('categoryTable', $event)"
        [nzPaginationPosition]="'top'" [nzSimple]="true">
        <thead>
          <tr>
            <th *ngFor="let column of orderColumn" [nzWidth]="column.width">
              {{ column.title }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr id="categoryTable" *ngFor="let item of campaignsListTable.data; let i = index">
            <!-- <td>{{i+1}}</td> -->
            <td>
              <div (click)="campaignDetails(item)" class="edit_link">
                {{ item?.name }}
              </div>
            </td>
            <td>
              {{ moment(item?.createdAt)?.format("DD-MM-YYYY hh:mm A") }}
            </td>
            <td>
              <span [ngClass]="{ 'complete_status': item?.isFinished , 'cancel_status' : item?.isCancelled }"
                class="active_status">
                {{item?.isCancelled ? 'Cancelled' : (item?.isFinished ? "Completed" : "Waiting") }}</span>
            </td>
            <!-- <td>
              {{
              item?.sentPercentage
              ? item?.sentPercentage
              : "0"
              }}
              %
            </td>
            <td>
              {{
              item?.deliveredPercentage
              ? item?.deliveredPercentage
              : "0"
              }}
              %
            </td>
            <td>
              {{
              item?.readPercentage
              ? item?.readPercentage
              : "0"
              }}
              %
            </td> -->
            <td>{{ item?.templateData?.name }}</td>
          </tr>
        </tbody>
        <ngx-spinner bdColor="rgb(255,255,255)" size="medium" color="#000000" type="ball-clip-rotate-pulse"
          [fullScreen]="false"></ngx-spinner>
      </nz-table>
    </nz-card>
  </div>
</div>