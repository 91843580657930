import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuardService } from "./services/auth-guard.service";
import { InternetService } from "./services/internet.service";
import { RoleProtectionService } from "./services/role-protection.service";
import { SignupProtectionService } from "./services/signup-protection.service";
import { AutomationComponent } from "./whatsapp-automation/dashboard/automation/automation.component";
import { CampaignDetailsComponent } from "./whatsapp-automation/dashboard/campaign-details/campaign-details.component";
import { CatalogueComponent } from "./whatsapp-automation/dashboard/catalogue/catalogue.component";
import { ChatComponent } from "./whatsapp-automation/dashboard/chat/chat.component";
import { CommercePageComponent } from "./whatsapp-automation/dashboard/commerce-page/commerce-page.component";
import { CreateCampaignComponent } from "./whatsapp-automation/dashboard/create-campaign/create-campaign.component";
import { CustomerPageComponent } from "./whatsapp-automation/dashboard/customer-page/customer-page.component";
import { ImportHistoryComponent } from "./whatsapp-automation/dashboard/import-history/import-history.component";
import { NotificationPageComponent } from "./whatsapp-automation/dashboard/notification-page/notification-page.component";
import { OrdersPageComponent } from "./whatsapp-automation/dashboard/orders-page/orders-page.component";
import { SegmentComponent } from "./whatsapp-automation/dashboard/segment/segment.component";
import { UpdateWaDataComponent } from "./whatsapp-automation/dashboard/update-wa-data/update-wa-data.component";
import { UserPageComponent } from "./whatsapp-automation/dashboard/user-page/user-page.component";
import { FaqPageComponent } from "./whatsapp-automation/faq-page/faq-page.component";
import { LoginPageComponent } from "./whatsapp-automation/login-page/login-page.component";
import { NoInternetPageComponent } from "./whatsapp-automation/no-internet-page/no-internet-page.component";
import { OnboardingLoaderPageComponent } from "./whatsapp-automation/onboarding-loader-page/onboarding-loader-page.component";
import { PluginPageComponent } from "./whatsapp-automation/plugin-page/plugin-page.component";
import { RemoveInspectComponent } from "./whatsapp-automation/remove-inspect/remove-inspect.component";
import { SandboxMessageComponent } from "./whatsapp-automation/sandbox-message/sandbox-message.component";
import { SettingPageComponent } from "./whatsapp-automation/setting-page/setting-page.component";
import { SighupPageComponent } from "./whatsapp-automation/sighup-page/sighup-page.component";
import { SignupSuccessComponent } from "./whatsapp-automation/signup-success/signup-success.component";
import { UserJourneyComponent } from "./whatsapp-automation/user-journey/user-journey.component";
import { ChatBotComponent } from "./whatsapp-automation/bot/chat-bot/chat-bot.component";
import { DashboardPageComponent } from "./whatsapp-automation/dashboard-page/dashboard-page.component";
import { ContentPageComponent } from "./whatsapp-automation/content-page/content-page.component";
import { SubscriptionComponent } from "./whatsapp-automation/subscription/subscription.component";
import { PaymentFailedComponent } from "./whatsapp-automation/payments/payment-failed/payment-failed.component";
import { DeveloperComponent } from "./whatsapp-automation/developer/developer.component";
import { RechargeHistoryComponent } from "./whatsapp-automation/payments/recharge-history/recharge-history.component";
import { TagsComponent } from "./whatsapp-automation/setting-page/tags/tags.component";
import { FieldCollectionComponent } from "./whatsapp-automation/setting-page/field-collection/field-collection.component";
import { AllBotsComponent } from "./whatsapp-automation/bot/all-bots/all-bots.component";
import { BotFlowsComponent } from "./whatsapp-automation/bot/bot-flows/bot-flows.component";
import { BotTemplatesComponent } from "./whatsapp-automation/bot/bot-templates/bot-templates.component";
import { WorkflowComponent } from "./whatsapp-automation/flow/workflow/workflow.component";
import { FlowBuilderComponent } from "./whatsapp-automation/flow/flow-builder/flow-builder.component";
import { ConversationHistoryComponent } from "./whatsapp-automation/dashboard-page/conversation-history/conversation-history.component";
import { AllAppsComponent } from "./whatsapp-automation/apps/all-apps/all-apps.component";

const appRoutes: Routes = [
  // { path: "", component: LandingPageComponent },
  { path: "", component: LoginPageComponent },
  { path: "login", component: LoginPageComponent },
  // { path: "forgot-password", component: LoginPageComponent },
  {
    path: "signup",
    component: SighupPageComponent,
  },

  {
    path: "signup-success",
    component: SignupSuccessComponent,
    canActivate: [AuthGuardService, InternetService],
  },
  {
    path: "otp-verification",
    component: SignupSuccessComponent,
  },
  {
    path: "create-password",
    component: SignupSuccessComponent,
    canActivate: [SignupProtectionService],
  },
  {
    path: "forgot-password",
    component: SignupSuccessComponent,
  },
  {
    path: "create-password/:otp/:userId/:status",
    component: SignupSuccessComponent,
  },
  {
    path: "forgot-password/:otp/:userId/:status",
    component: SignupSuccessComponent,
  },

  {
    path: "campaign",
    component: NotificationPageComponent,
    canActivate: [AuthGuardService, RoleProtectionService, InternetService],
  },
  {
    path: "contacts",
    component: CustomerPageComponent,
    canActivate: [AuthGuardService, RoleProtectionService, InternetService],
  },
  {
    path: "wa-update",
    component: UpdateWaDataComponent,
    canActivate: [AuthGuardService, InternetService],
  },
  {
    path: "campaign/create-campaign",
    component: CreateCampaignComponent,
    canActivate: [AuthGuardService, RoleProtectionService, InternetService],
  },
  {
    path: "campaign/create-campaign/:id",
    component: CreateCampaignComponent,
    canActivate: [AuthGuardService, RoleProtectionService, InternetService],
  },
  {
    path: "developer",
    component: DeveloperComponent,
    canActivate: [AuthGuardService, RoleProtectionService, InternetService],
  },
  {
    path: "chat",
    component: ChatComponent,
    canActivate: [InternetService, AuthGuardService],
  },
  {
    path: "chat/:page/:id/:name",
    component: ChatComponent,
    canActivate: [InternetService, AuthGuardService],
  },
  {
    path: "dashboard",
    component: DashboardPageComponent,
    canActivate: [InternetService, AuthGuardService],
  },
  {
    path: "dashboard/conversation-history",
    component: ConversationHistoryComponent,
    canActivate: [AuthGuardService, RoleProtectionService, InternetService],
  },
  {
    path: "subscription",
    component: SubscriptionComponent,
    canActivate: [AuthGuardService, RoleProtectionService, InternetService],
  },
  {
    path: "content",
    component: ContentPageComponent,
    canActivate: [InternetService, AuthGuardService, RoleProtectionService],
  },
  {
    path: "content/:id",
    component: ContentPageComponent,
    canActivate: [InternetService, AuthGuardService, RoleProtectionService],
  },
  {
    path: "campaign/campaign-details/:id",
    component: CampaignDetailsComponent,
    canActivate: [AuthGuardService, RoleProtectionService, InternetService],
  },
  {
    path: "catalogue",
    component: CatalogueComponent,
    canActivate: [AuthGuardService, RoleProtectionService, InternetService],
  },
  {
    path: "catalogue/:id",
    component: CatalogueComponent,
    canActivate: [AuthGuardService, RoleProtectionService, InternetService],
  },
  {
    path: "order-panel",
    component: OrdersPageComponent,
    canActivate: [AuthGuardService, RoleProtectionService, InternetService],
  },
  {
    path: "order-panel/:id",
    component: OrdersPageComponent,
    canActivate: [AuthGuardService, RoleProtectionService, InternetService],
  },
  {
    path: "automation",
    component: AutomationComponent,
    canActivate: [AuthGuardService, RoleProtectionService, InternetService],
  },
  {
    path: "users",
    component: UserPageComponent,
    canActivate: [AuthGuardService, RoleProtectionService, InternetService],
  },
  {
    path: "sandbox-message",
    component: SandboxMessageComponent,
    canActivate: [AuthGuardService, RoleProtectionService, InternetService],
  },
  {
    path: "setting",
    component: SettingPageComponent,
    canActivate: [AuthGuardService, RoleProtectionService, InternetService],
  },
  {
    path: "setting/:id",
    component: SettingPageComponent,
    canActivate: [AuthGuardService, InternetService],
  },
  {
    path: "tags",
    component: TagsComponent,
    canActivate: [AuthGuardService, InternetService],
  },
  {
    path: "field-collection",
    component: FieldCollectionComponent,
    canActivate: [AuthGuardService, InternetService],
  },
  {
    path: "contacts/segment",
    component: SegmentComponent,
    canActivate: [AuthGuardService, RoleProtectionService, InternetService],
  },
  {
    path: "contacts/segment/:id",
    component: SegmentComponent,
    canActivate: [AuthGuardService, RoleProtectionService, InternetService],
  },
  {
    path: "plugin",
    component: PluginPageComponent,
    canActivate: [AuthGuardService, RoleProtectionService, InternetService],
  },
  {
    path: "faq",
    component: FaqPageComponent,
    canActivate: [AuthGuardService, RoleProtectionService, InternetService],
  },
  {
    path: "commerce",
    component: CommercePageComponent,
    canActivate: [AuthGuardService, RoleProtectionService, InternetService],
  },
  {
    path: "commerce/:option",
    component: CommercePageComponent,
    canActivate: [AuthGuardService, RoleProtectionService, InternetService],
  },
  {
    path: "contacts/uploads",
    component: ImportHistoryComponent,
    canActivate: [AuthGuardService, RoleProtectionService, InternetService],
  },
  {
    path: "recharge-history",
    component: RechargeHistoryComponent,
    canActivate: [AuthGuardService, RoleProtectionService, InternetService],
  },
  {
    path: "user-journey",
    component: UserJourneyComponent,
    canActivate: [AuthGuardService, InternetService],
  },
  {
    path: "onboarding",
    component: OnboardingLoaderPageComponent,
    canActivate: [AuthGuardService, InternetService],
  },
  // {
  //   path: "payment-success",
  //   component: PaymentSuccessComponent,
  //   canActivate: [AuthGuardService, InternetService],
  // },
  {
    path: "payment-failed",
    component: PaymentFailedComponent,
    canActivate: [AuthGuardService, InternetService],
  },
  {
    path: "no-internet",
    component: NoInternetPageComponent,
  },
  {
    path: "unsupportable-screen-size",
    component: RemoveInspectComponent,
    canActivate: [AuthGuardService, InternetService],
  },
  {
    path: "chat-bot",
    component: AllBotsComponent,
    canActivate: [AuthGuardService, RoleProtectionService, InternetService],
  },
  // {
  //   path: "chat-bot/templates",
  //   pathMatch: "full",
  //   component: BotTemplatesComponent,
  //   canActivate: [AuthGuardService, RoleProtectionService, InternetService],
  // },
  {
    path: "chat-bot/flow/:id",
    component: BotFlowsComponent,
    pathMatch: "full",
    canActivate: [AuthGuardService, RoleProtectionService, InternetService],
  },
  {
    path: "chat-bot/:botId/:flowId",
    component: ChatBotComponent,
    pathMatch: "full",
    canActivate: [AuthGuardService, RoleProtectionService, InternetService],
  },
  {
    path: "work-flow",
    component: WorkflowComponent,
    canActivate: [AuthGuardService, RoleProtectionService, InternetService],
  },
  {
    path: "work-flow/builder/:flowId",
    component: FlowBuilderComponent,
    pathMatch: "full",
    canActivate: [AuthGuardService, RoleProtectionService, InternetService],
  },
  {
    path: "work-flow/create/:flowId",
    component: FlowBuilderComponent,
    pathMatch: "full",
    canActivate: [AuthGuardService, RoleProtectionService, InternetService],
  },
  {
    path: "apps",
    component: AllAppsComponent,
    canActivate: [AuthGuardService, RoleProtectionService, InternetService],
  },
  { path: "**", redirectTo: "/login" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes
      //   {
      //   preloadingStrategy: PreloadAllModules,
      //   anchorScrolling: 'enabled',
      //   scrollPositionRestoration: 'enabled',
      // }
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
