import { Component, OnInit } from "@angular/core";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { NzModalService } from "ng-zorro-antd/modal";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { AlertServiceService } from "src/app/services/alert-service.service";
import { CommonApiService } from "src/app/services/common-api.service";
import { NavService } from "src/app/services/nav.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-automation",
  templateUrl: "./automation.component.html",
  styleUrls: ["./automation.component.css"],
})
export class AutomationComponent implements OnInit {
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "15rem",
    minHeight: "5rem",
    placeholder: "Select message to Edit...",
    translate: "no",
    defaultParagraphSeparator: "p",
    defaultFontName: "",
    toolbarHiddenButtons: [["list", ""]],
  };

  userOption = "Yes";

  updateLoading = false;

  htmlContent: any = "";

  orderFlowActions: any = [];
  displayFlow: any = [];

  addressAutoDetected = {
    message: {
      customTemplate: true,
      buttons: {
        hasButtons: true,
        values: [
          {
            text: "Previous Address available",
            nextTarget: 8,
          },
          {
            text: "No Previous Address Available",
            nextTarget: 2,
          },
        ],
      },
      text: "",
      predefinedText: "",
    },
    orderNo: 0,
    reply: {
      text: "No Previous Address Available",
      nextTarget: 2,
    },
  };

  mobileDevice: boolean;
  showEdit: boolean;
  showSetting: boolean = true;

  mainUserData: any = {};

  // setting section

  shippingCost: any;

  private subscription: Subscription;

  constructor(
    private apiService: CommonApiService,
    private toastr: ToastrService,
    private modal: NzModalService,
    private spinner: NgxSpinnerService,
    private alertService: AlertServiceService,
    private navService: NavService
  ) {}

  ngOnInit(): void {
    this.getMainUserData();
    if (window.innerWidth < 992) {
      this.mobileDevice = true;
    } else {
      this.mobileDevice = false;
      this.showEdit = false;
    }
    this.fetchData();
  }

  fetchData() {
    this.spinner.show();
    this.displayFlow = [];
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonGetMethod(environment.url + "flow-actions", options)
      .subscribe(
        (response: any) => {
          this.orderFlowActions = response?.data?.orderFlowActions;
          for (let i = 0; i < this.orderFlowActions.length; i++) {
            this.orderFlowActions[i].message.text = this.format_text(
              this.orderFlowActions[i]?.message?.text
            );
            if (this.orderFlowActions[i].message.buttons.hasButtons) {
              this.orderFlowActions[i].reply =
                this.orderFlowActions[i].message.buttons.values[0];
            }
          }
          this.displayFlow.push(this.orderFlowActions[0]);
          this.displayFlow.push(this.addressAutoDetected);
          for (let j = 0; j < this.orderFlowActions.length; j++) {
            if (
              this.orderFlowActions[j].orderNo >= 2 &&
              this.orderFlowActions[j].orderNo < 6
            ) {
              this.displayFlow.push(this.orderFlowActions[j]);
            }
          }
          // console.log(response);
          this.spinner.hide();
        },
        (error: any) => {
          this.spinner.hide();
          console.log(error);
        }
      );
  }

  // selectOption(selected: string) {
  //   this.userOption = selected;
  // }

  noAddress: any = 2;
  selectedTemplate: any;

  changeStatus(selected: any, index: any, message: any) {
    if (this.mainUserData?.accessTo?.isExpired) {
      this.alertService.warning();
    } else {
      // console.log(message);
      if (message.message.customTemplate) {
        this.addressAutoDetected.reply = selected;
      } else {
        var targetIndex = this.orderFlowActions?.findIndex((e: any) => {
          return e.orderNo == message.orderNo;
        });
        this.orderFlowActions[targetIndex].reply = selected;
      }

      // console.log(targetIndex);

      if (message.orderNo == 0) {
        this.displayFlow = [];
        this.displayFlow.push(this.orderFlowActions[0]);
        this.displayFlow.push(this.addressAutoDetected);
        if (selected.nextTarget == 2) {
          for (let j = 0; j < this.orderFlowActions.length; j++) {
            if (
              this.orderFlowActions[j].orderNo >= 2 &&
              this.orderFlowActions[j].orderNo < 6
            ) {
              this.displayFlow.push(this.orderFlowActions[j]);
            }
          }
        } else if (selected.nextTarget == 8) {
          var targetIndex = this.orderFlowActions?.findIndex((e: any) => {
            return e.orderNo == 8;
          });
          // console.log(targetIndex);
          this.orderFlowActions[targetIndex].message.expectedAnswer = "address";
          this.displayFlow.push(this.orderFlowActions[targetIndex]);

          for (let j = 0; j < this.orderFlowActions.length; j++) {
            if (
              this.orderFlowActions[j].orderNo == 4 ||
              this.orderFlowActions[j].orderNo == 5
            ) {
              this.displayFlow.push(this.orderFlowActions[j]);
            }
          }
        }
      } else if (message.orderNo == 1) {
        this.displayFlow = [];
        this.displayFlow.push(this.orderFlowActions[0]);

        if (selected.nextTarget == 2) {
          this.displayFlow.push(this.addressAutoDetected);
          for (let j = 0; j < this.orderFlowActions.length; j++) {
            if (
              this.orderFlowActions[j].orderNo >= selected.nextTarget &&
              this.orderFlowActions[j].orderNo < 6
            ) {
              this.displayFlow.push(this.orderFlowActions[j]);
            }
          }
        } else if (selected.nextTarget == 6) {
          for (let j = 0; j < this.orderFlowActions.length; j++) {
            if (
              this.orderFlowActions[j].orderNo == 6 ||
              this.orderFlowActions[j].orderNo == 7
            ) {
              this.displayFlow.push(this.orderFlowActions[j]);
            }
          }
        }
      } else if (message.orderNo == 4) {
        this.displayFlow.splice(
          this.displayFlow.length - this.noAddress,
          this.noAddress
        );
        var targetIndex1 = this.orderFlowActions?.findIndex((e: any) => {
          return e.orderNo == message.orderNo;
        });
        // console.log(this.orderFlowActions[targetIndex1]);
        this.displayFlow.push(this.orderFlowActions[targetIndex1]);
        if (selected.nextTarget == 5) {
          this.noAddress = 2;
          for (let j = 0; j < this.orderFlowActions.length; j++) {
            if (this.orderFlowActions[j].orderNo == selected.nextTarget) {
              this.displayFlow.push(this.orderFlowActions[j]);
            }
          }
        } else if (selected.nextTarget == 6) {
          this.noAddress = 3;
          for (let j = 0; j < this.orderFlowActions.length; j++) {
            if (
              this.orderFlowActions[j].orderNo == 6 ||
              this.orderFlowActions[j].orderNo == 7
            ) {
              this.displayFlow.push(this.orderFlowActions[j]);
            }
          }
        } else if (selected.nextTarget == 2) {
          this.noAddress = 2;
          var temp = {
            message: {
              text: "Rerouted to Get Address step",
              noEdit: true,
            },
          };
          this.displayFlow.push(temp);
        }
      }
    }
    // console.log(this.displayFlow);
  }

  editTemplate(messageData: any) {
    if (this.mainUserData?.accessTo?.isExpired) {
      this.alertService.warning();
    } else {
      // if (window.innerWidth < 992) {
      this.showEdit = true;
      this.showSetting = false;
      // }
      this.htmlContent = messageData.message.text;
      this.selectedTemplate = messageData;
    }
  }

  resetTemplate(selected: any) {
    if (this.mainUserData?.accessTo?.isExpired) {
      this.alertService.warning();
    } else {
      this.modal.confirm({
        nzTitle: "<b>Do you Want to Reset the template?</b>",
        nzOkText: "Yes",
        nzCancelText: "No",
        nzOnOk: () => {
          this.selectedTemplate = selected;
          this.updateMessage("reset");
        },
      });
    }
    // console.log(selected);
  }

  updateTemplate() {
    if (this.mainUserData?.accessTo?.isExpired) {
      this.alertService.warning();
    } else {
      // console.log(this.htmlContent);
      const keyVariable =
        this.selectedTemplate?.message?.predefinedText?.match(/\{.+?\}/g);
      // here we find any variable in the order flow action predefinedText for eg: {address} if exist then enter into if statement
      if (keyVariable?.length) {
        // here we check by includes {var} in text body key
        if (!this.htmlContent.includes(keyVariable[0])) {
          this.modal.error({
            nzTitle: "error",
            nzContent:
              "<b>It is mandatory to have " +
              keyVariable +
              " variable in your message body.</b> ",
          });
          // alert(
          //   "It is mandatory to have " +
          //     keyVariable +
          //     "variable in your message body. "
          // );
        } else {
          this.updateMessage("update");
        }
      } else {
        this.updateMessage("update");
      }
    }
  }

  updateMessage(type: string) {
    this.updateLoading = true;
    let editedText = this.reverseFormatText(this.htmlContent);

    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    let data: any = {
      flowActionId: this.selectedTemplate._id,
    };

    if (type == "update") {
      data.text = editedText;
    } else if (type == "reset") {
      data.text = this.selectedTemplate?.message?.predefinedText;
    }

    // console.log(data);

    this.apiService
      .commonPostMethod(environment.url + "flow-actions/update", data, options)
      .subscribe(
        (response: any) => {
          // console.log(response);
          this.htmlContent = "";
          this.selectedTemplate = {};
          // if (window.innerWidth < 992) {
          this.showEdit = false;
          // }
          this.updateLoading = false;
          this.fetchData();

          this.toastr.success(response.message, "", {
            timeOut: 2500,
          });
        },
        (error: any) => {
          this.updateLoading = false;
          console.log(error);
          if (error?.error?.message) {
            this.toastr.error(error?.error?.message, "", {
              timeOut: 2500,
            });
          } else {
            this.toastr.error(error?.message, "", {
              timeOut: 2500,
            });
          }
        }
      );
  }

  closeEditBox() {
    this.showEdit = false;
  }

  reverseFormatText(text) {
    return text
      .replaceAll("<b>", "*")
      .replaceAll("</b>", "*")
      .replaceAll("<p>", "")
      .replaceAll("</p>", "")
      .replaceAll("<i>", "_")
      .replaceAll("</i>", "_")
      .replaceAll("<strike>", "~")
      .replaceAll("</strike>", "~")
      .replaceAll("&#160;", "")
      .replaceAll("&#10;", "")
      .replaceAll("<br>", "\n");
  }

  format_text(text) {
    return text
      .replace(/(?:_)(?:(?!\s))((?:(?!\n|_).)+)(?:_)/g, "<em>$1</em>")
      .replace(/(?:\*)(?:(?!\s))((?:(?!\*|\n).)+)(?:\*)/g, "<b>$1</b>")
      .replace(/(?:~)(?:(?!\s))((?:(?!\n|~).)+)(?:~)/g, "<s>$1</s>")
      .replace(/(?:--)(?:(?!\s))((?:(?!\n|--).)+)(?:--)/g, "<u>$1</u>")
      .replace(/(?:```)(?:(?!\s))((?:(?!\n|```).)+)(?:```)/g, "<tt>$1</tt>")
      .replace(/\n/g, "<br>");
  }

  getMainUserData() {
    this.subscription = this.navService.mainUserData.subscribe((data: any) => {
      if (data) {
        // console.log(data);
        this.mainUserData = data.data.userData;
      }
    });
    // let options = {
    //   headers: {
    //     Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
    //   },
    // };

    // this.apiService
    //   .commonGetMethod(environment.url + "auth/get-user-data", options)
    //   .subscribe(
    //     (response: any) => {
    //       this.mainUserData = response.data.userData;
    //       // console.log(this.mainUserData);
    //     },
    //     (error: any) => {
    //       console.log(error);
    //     }
    //   );
  }

  ngOnDestroy(): void {
    // console.log("api key destroy..");
    this.subscription.unsubscribe();
  }
}
