import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { CommonApiService } from "src/app/services/common-api.service";
import { NavService } from "src/app/services/nav.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-all-apps",
  templateUrl: "./all-apps.component.html",
  styleUrls: ["./all-apps.component.css"],
})
export class AllAppsComponent implements OnInit {
  searchValue: string = "";

  allApps: Array<any> = [];

  private subscription: Subscription;
  mainUserData: any;
  totalCount: number;

  imageUrl: string = environment.imageUrl;

  showForm: boolean = false;
  formFields: any = {};

  connectionForm: FormGroup;
  loopForm: Array<any> = [];

  drawerTitle: string = "Choose Event";

  eventSelected: any;

  creating: boolean = false;

  passwordVisible: string = "";

  webhookUrl: string = "";
  genericWebhookUrl: any;
  webhookResponse: any;
  responseSampleLoader: boolean = false;

  allMenu: Array<any> = [
    { name: "Connected Apps", id: "Connected Apps" },
    { name: "All Apps", id: "All Apps" },
    { name: "Ecommerce", id: "Ecommerce" },
    { name: "Payments", id: "Payments" },
    { name: "CRM", id: "CRM" },
    { name: "Others", id: "Others" },
  ];

  showDetail: boolean = false;

  detailMenu: string = "overview";

  eventsModal: boolean = false;
  selectedConnection: any;
  subscribeLoader: boolean = false;

  selectedMenu: any = { name: "All Apps", id: "All Apps" };
  searchText: string = "";

  webhookId: string = "6512e01da69530ddb2573a5c";

  isEdit: boolean = false;

  constructor(
    private spinner: NgxSpinnerService,
    private apiService: CommonApiService,
    private navService: NavService,
    private fb: FormBuilder,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.getMainUserData();
    this.getAllApps();
  }

  getMainUserData() {
    this.navService.getMainUserData();
    this.subscription = this.navService.mainUserData.subscribe(
      (response: any) => {
        if (response) {
          this.mainUserData = response.data.userData;
          // console.log(this.mainUserData);
        }
      }
    );
  }

  getAllApps() {
    this.spinner.show();
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(environment.url + "apps/get-all", {}, options)
      .subscribe(
        (response: any) => {
          console.log(response);
          this.allApps = response?.data.apps;
          // this.totalCount = response?.data?.totalCount;
          this.spinner.hide();
        },
        (err: any) => {
          console.log(err);
          this.spinner.hide();
          if (
            err?.error?.message == "jwt expired" ||
            err?.error?.message == "jwt must be provided" ||
            err?.error?.message == "jwt malformed" ||
            err?.error?.message == "invalid signature" ||
            err?.error?.message == "Given token user does not exist." ||
            err?.error?.message == "invalid token"
          ) {
            this.navService.tokenExpired();
          }
        }
      );
  }

  getDetail(selected: any, type: string) {
    this.detailMenu = type;
    this.drawerTitle = "Choose Event";
    this.eventSelected = null;
    this.creating = false;
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url + "apps/get-detail",
        {
          appId: selected._id,
          connectionsListFor: "apps-detail",
        },
        options
      )
      .subscribe(
        (response: any) => {
          console.log(response);
          this.formFields = response.data;
          this.loopForm =
            this.formFields.app.customKeys.credentialsDetails.basicAuthFields;
          if (type == "connections" && this.selectedConnection) {
            const target = this.formFields.appExistingConnection.findIndex(
              (e) => {
                return e._id == this.selectedConnection?._id;
              }
            );
            this.selectedConnection =
              this.formFields.appExistingConnection[target];
          }
          this.showDetail = true;
          // this.createForm();
        },
        (err: any) => {
          console.log(err);
        }
      );
  }

  createForm() {
    console.log(this.selectedConnection);
    var controls: any = {
      connectionName: [
        this.isEdit ? this.selectedConnection.name : "",
        Validators.required,
      ],
    };

    this.loopForm.forEach((form) => {
      controls[form.name] = [
        this.isEdit && form.type != "password"
          ? this.selectedConnection.credentials[form.name]
          : "",
        Validators.required,
      ];
    });
    this.connectionForm = this.fb.group(controls);
    if (this.isEdit) {
      const webhook = `${
        environment.url
      }apps/${this.selectedConnection._id.toString()}/events/${
        this.selectedConnection.events[0]._id
      }`;
      console.log(webhook);
      this.genericWebhookUrl = {
        webhookUrl: webhook,
        connectionId: this.selectedConnection._id,
      };
      this.checkResponse();
    } else {
      this.genericWebhookUrl = null;
    }
    this.webhookUrl = "";
    this.webhookResponse = null;
    this.responseSampleLoader = false;
    this.showForm = true;
  }

  closeForm() {
    this.showForm = false;
  }

  selectEvent(event: any) {
    this.webhookUrl = "";
    this.genericWebhookUrl = null;
    this.webhookResponse = null;
    this.responseSampleLoader = false;
    this.creating = false;
    this.eventSelected = event;
    this.drawerTitle = "Create Connection";
    this.createForm();
  }

  addUpdateConnection() {
    if (this.connectionForm.valid) {
      this.creating = true;
      var final: any = {
        appId: this.formFields.app._id,
      };
      Object.assign(final, this.connectionForm.value);
      var url = "apps-connections/create";
      if (this.isEdit) {
        url = "apps-connections/update-connections";
        final.connectionId = this.selectedConnection._id;
      } else if (this.eventSelected) {
        url = "apps-connections/create";
        final.requestEvent = this.eventSelected.topic;
      } else if (this.genericWebhookUrl) {
        url = "apps-connections/update";
        final = { connectionId: this.genericWebhookUrl.connectionId };
      }
      console.log(final);
      let options = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
        },
      };

      this.apiService
        .commonPostMethod(environment.url + url, final, options)
        .subscribe(
          (response: any) => {
            console.log(response);
            this.toastr.success(response?.message, "", {
              timeOut: 2500,
            });
            if (this.formFields.app.isNeedToShowWebhookUrl) {
              this.webhookUrl = response.data.responseData.webhookUrl;
            } else if (this.eventSelected || this.genericWebhookUrl) {
              this.getAllApps();
              this.detailMenu = "connections";
              this.eventSelected = null;
              this.genericWebhookUrl = null;
            } else {
              this.genericWebhookUrl = response.data.responseData;
            }
            this.creating = false;
            this.getDetail(this.formFields?.app, "connections");
            if (!this.genericWebhookUrl?.connectionId) {
              this.showForm = false;
            }
          },
          (error: any) => {
            this.creating = false;
            if (error?.error?.message) {
              this.toastr.error(error?.error?.message, "", {
                timeOut: 2500,
              });
            } else {
              this.toastr.error(error?.message, "", {
                timeOut: 2500,
              });
            }
            console.log(error);
          }
        );
    } else {
      Object.values(this.connectionForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  copyUrl(url: any) {
    navigator.clipboard.writeText(url).then(() => {
      this.toastr.success("Url copied to clipboard", "", {
        timeOut: 2500,
      });
    });
  }

  backToApps() {
    this.formFields = {};
    this.showDetail = false;
  }

  changeMenu(menu: string) {
    this.detailMenu = menu;
  }

  openEventsModal() {
    this.subscribeLoader = false;
    this.eventsModal = true;
  }

  updateEditData(connection: any) {
    this.selectedConnection = connection;
  }

  closeEventsModal() {
    this.eventsModal = false;
  }

  subscribeEvent(event: any) {
    if (this.detectSubs(event) == -1) {
      this.subscribeLoader = true;
      let options = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
        },
      };

      this.apiService
        .commonPostMethod(
          environment.url + "apps-connections/add-events",
          {
            appId: this.formFields.app._id,
            requestEvent: event.topic,
            connectionId: this.selectedConnection._id,
          },
          options
        )
        .subscribe(
          (response: any) => {
            console.log(response);
            this.subscribeLoader = false;
            this.toastr.success(response?.message, "", {
              timeOut: 2500,
            });
            this.getDetail(this.formFields?.app, "connections");
            if (this.formFields.app.isNeedToShowWebhookUrl) {
              this.webhookUrl = response.data.responseData.webhookUrl;
              this.showForm = true;
              this.eventsModal = false;
            }
            // this.closeEventsModal();
          },
          (error: any) => {
            this.subscribeLoader = false;
            this.creating = false;
            if (error?.error?.message) {
              this.toastr.error(error?.error?.message, "", {
                timeOut: 2500,
              });
            } else {
              this.toastr.error(error?.message, "", {
                timeOut: 2500,
              });
            }
            console.log(error);
          }
        );
    }
  }

  detectSubs(event) {
    var currentIndex = this.selectedConnection.events.findIndex((e) => {
      return e._id == event._id;
    });

    return currentIndex;
  }

  selectMenu(menu: any) {
    this.selectedMenu = menu;
  }

  checkResponse() {
    this.responseSampleLoader = true;
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url + "apps-connections/is-generic-webhook-received",
        {
          connectionId: this.genericWebhookUrl.connectionId,
        },
        options
      )
      .subscribe(
        (response: any) => {
          console.log(response);
          this.webhookResponse = Object.entries(response.data.sampleDataObject);
          this.responseSampleLoader = false;
        },
        (error: any) => {
          console.log(error);
          this.toastr.info(error?.error.message, "", {
            timeOut: 2500,
          });
          this.responseSampleLoader = false;
        }
      );
  }

  editConnection() {
    this.isEdit = true;
    this.selectEvent("");
  }

  directEdit(connection: any) {
    if (!connection.isActivated) {
      this.selectedConnection = connection;
      if (this.formFields.app._id == this.webhookId) {
        this.editConnection();
      } else {
        this.openEventsModal();
      }
    }
  }

  newConnection() {
    this.selectedConnection = null;
    this.isEdit = false;
    this.selectEvent("");
  }

  ngOnDestroy(): void {
    // console.log("api key destroy..");
    this.subscription.unsubscribe();
  }
}
