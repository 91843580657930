<div *ngIf="!showForm" class="row m-b-30">

    <div class="col-12 top_heading">
        <nz-card class="contacts_top_card">
            <div class="contacts_top_bar">
                <div class="contacts_top_bar_left">
                    <h3><span>Settings > </span>Quick Message</h3>
                </div>

                <div class="contacts_top_bar_right">
                    <div class="search_add_div">
                        <div class="toggle_options">
                            <nz-input-group class="search_input_section" [nzPrefix]="suffixIconSearch">
                                <input autocomplete="off" [(ngModel)]="searchKey" (keyup)="searchContacts()" type="text"
                                    nz-input placeholder="Search here.." />
                            </nz-input-group>
                            <ng-template #suffixIconSearch>
                                <span nz-icon nzType="search"></span>
                            </ng-template>
                        </div>

                        <a [disabled]="mainUserData?.accessTo?.isExpired" (click)="showChannelForm()" nz-button
                            nzType="primary" class="create-button setting_add_button">
                            <i nz-icon nzType="plus" theme="outline"></i>
                            <span>Add New Channel Response</span>
                        </a>

                        <span [ngClass]="{'reload_btn_loading' : pageReloading}" (click)="reloadPage()"
                            class="reload_btn " nzTooltipTitle="reload" nzTooltipPlacement="top" nz-tooltip nz-icon
                            nzType="sync" nzTheme="outline"></span>
                    </div>
                </div>
            </div>
        </nz-card>
    </div>

    <div class="col-12">
        <nz-card class="tags_new_table customers_table_card">
            <div class="total_records">
                <h6>
                    Total Records ({{ totalCount }})
                </h6>
                <button class="customized_btn" [ngStyle]="setMyStyles()" nzTrigger="click" nz-dropdown
                    [nzDropdownMenu]="paginationMenu" [nzPlacement]="'bottomLeft'">
                    {{ pageSize + " records per page" }}
                </button>

                <nz-dropdown-menu #paginationMenu="nzDropdownMenu">
                    <ul nz-menu>
                        <li (click)="changePageSize((n + 1) * 10)" *ngFor="let page of [].constructor(5); let n = index"
                            nz-menu-item>
                            {{ (n + 1) * 10 + " records per page" }}
                        </li>
                    </ul>
                </nz-dropdown-menu>
            </div>

            <nz-table class="contacts_table main_contacts_table" #cannedResponseDataTable [nzData]="cannedResponseData"
                [nzScroll]="{ y: tableHeight, x : '900px' }" [nzFrontPagination]="false" [nzTotal]="totalCount"
                [nzPageSize]="pageSize" [nzPageIndex]="pageIndex"
                (nzQueryParams)="onPageNumberChange('quickMessageTable' , $event)" [nzPaginationPosition]="'top'"
                [nzSimple]="true">
                <thead>
                    <tr>
                        <th *ngFor="let column of orderColumn" [nzWidth]="column.width">{{ column.title }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr id="quickMessageTable" *ngFor="let item of cannedResponseDataTable.data ;  let i=index">
                        <td>
                            Whatsapp
                        </td>
                        <td> {{item.name}} </td>
                        <td>
                            <div [innerHTML]="item.message" class=""></div>
                        </td>
                        <!-- <td> {{item.attachedFile}} </td> -->
                        <td> {{moment(item.createdAt).format('DD-MM-YYYY hh:mm A')}} </td>
                        <td>
                            <div class="edit_delete_options">
                                <span (click)="editResponse(item)" nz-icon nzType="edit" nzTheme="outline"></span>
                                <span (click)="deleteAlert(item)" nz-icon nzType="delete" nzTheme="outline"></span>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <ngx-spinner bdColor="rgb(255,255,255)" size="medium" color="#000000" type="ball-clip-rotate-pulse"
                    [fullScreen]="false">
                </ngx-spinner>

            </nz-table>
        </nz-card>
    </div>
</div>

<nz-card *ngIf="showForm" class="details_card mt-1">
    <div class="row">
        <div class="col-12 top_heading">
            <h3><span>Setting > </span><span class="web_cursor" (click)="showChannelTable()">Quick Message ></span>
                {{editOption ? 'Edit' :
                'Add New ' }} Channel Response
            </h3>
        </div>

        <div class="col-lg-8 mt-3">
            <form [formGroup]="addResponseForm" (ngSubmit)="createUpdateMessage()" nz-form>
                <nz-form-item>
                    <nz-form-label nzRequired class="large_label" nzFor="" [nzSm]="24" [nzXs]="24">Name
                    </nz-form-label>
                    <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please Enter Name!">
                        <nz-input-group>
                            <input autocomplete="off" formControlName="name" type="text" nz-input id=""
                                placeholder="Enter Name" required>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <!-- <nz-form-item>
                <nz-form-label nzRequired class="large_label" nzFor="" [nzSm]="24" [nzXs]="24"> Channel Type
                </nz-form-label>
                <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please Select Channel Type!">
                    <nz-input-group>
                        <nz-select formControlName="channelType" class="w-100 select_dropdown"
                            nzPlaceHolder="Select Channel Type">
                            <nz-option nzLabel="Web" nzValue="web"> </nz-option>
                            <nz-option nzLabel="Whatsapp" nzValue="whatsapp"> </nz-option>
                        </nz-select>
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item> -->

                <nz-form-item>
                    <nz-form-label nzRequired class="large_label" nzFor="" [nzSm]="24" [nzXs]="24"> Message
                    </nz-form-label>
                    <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please Enter Message!">
                        <nz-input-group>

                            <textarea (paste)="onPaste($event)" (mouseup)="mouseUp()" (select)="onTextSelect()"
                                (focus)="bodyTextFocus()" #bodyText rows="4" formControlName="message"
                                nz-input></textarea>

                            <!-- <angular-editor (focus)="editorFocus()" #bodyText formControlName="message"
                                class="canned_response_div" [config]="config">
                            </angular-editor> -->

                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
                <div class="editor_options">
                    <emoji-mart *ngIf="emojiVisible" class="emoji-mart" set="{{set}}" (emojiSelect)="addEmoji($event)"
                        title="Pick your emoji…"></emoji-mart>
                    <p (click)="showEmoji()">
                        <span nz-icon nzType="smile" nzTheme="outline"></span>
                    </p>
                    <p [ngClass]="{'selected_style' : selectedStyle == 'Bold'}" (click)="editText('bold')">
                        <span nz-icon nzType="bold" nzTheme="outline"></span>
                    </p>
                    <p [ngClass]="{'selected_style' : selectedStyle == 'Italic'}" (click)="editText('italic')">
                        <span nz-icon nzType="italic" nzTheme="outline"></span>
                    </p>
                    <p [ngClass]="{'selected_style' : selectedStyle == 'Strike'}" (click)="editText('strikethrough')">
                        <span nz-icon nzType="strikethrough" nzTheme="outline"></span>
                    </p>
                </div>

                <button type="button" nzTrigger="click" nz-dropdown [nzDropdownMenu]="variables"
                    [nzPlacement]="'bottomLeft'" class="mt-3" [nzSm]="24" nzType="default" nz-button>
                    <i nz-icon nzType="plus" theme="outline"></i>
                    Add Variables
                </button>
                <nz-dropdown-menu #variables="nzDropdownMenu">
                    <ul nz-menu>
                        <li (click)="addVariable('name')" nz-menu-item>name</li>
                        <li (click)="addVariable('phoneNo')" nz-menu-item>phoneNo</li>
                        <li (click)="addVariable('email')" nz-menu-item>email</li>
                    </ul>
                </nz-dropdown-menu>

                <nz-form-item class="submit_section text-right">
                    <nz-form-control [nzSm]="24" [nzXs]="24">
                        <div class="account_details_add_cancel_right">
                            <button (click)="showChannelTable()" type="button" class="sighup_button mr-3" nz-button
                                nzType="default">Cancel</button>
                            <button [nzLoading]="submitting" type="submit" class="sighup_button" nz-button
                                nzType="primary">{{editOption ? 'Update' :
                                'Save'}}</button>
                        </div>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </div>
    </div>
</nz-card>

<!-- [(ngModel)]="messageContent" [ngModelOptions]="{standalone: true}" -->