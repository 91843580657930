<div *ngIf="!showForm " class="row template_main_row m-b-30">


    <div class="col-12 top_heading">
        <nz-card class="contacts_top_card">
            <div class="contacts_top_bar">
                <div class="contacts_top_bar_left">
                    <h3><span>Settings > </span>WhatsApp Template</h3>
                </div>

                <div class="contacts_top_bar_right">
                    <div class="search_add_div right_btn_div">
                        <div class="search_section">
                            <div class="status_div">
                                <button class="segment_btn" nzTrigger="click" nz-button nz-dropdown
                                    [nzDropdownMenu]="templateFilter" [nzPlacement]="'bottomLeft'">
                                    <span>{{ statusFilter }}</span>
                                    <span class="d-flex" nz-icon nzType="down"></span>
                                </button>
                                <nz-dropdown-menu #templateFilter="nzDropdownMenu">
                                    <ul class="segment_menu" nz-menu>
                                        <li [ngClass]="{'selected_segment': statusFilter == 'all'}"
                                            (click)="applyFilter('all')" nz-menu-item>
                                            All
                                        </li>
                                        <li [ngClass]="{'selected_segment': statusFilter == 'created'}"
                                            (click)="applyFilter('created')" nz-menu-item>
                                            Created
                                        </li>
                                        <li [ngClass]="{'selected_segment': statusFilter == 'approved'}"
                                            (click)="applyFilter('approved')" nz-menu-item>
                                            Approved
                                        </li>
                                        <li [ngClass]="{'selected_segment': statusFilter == 'rejected'}"
                                            (click)="applyFilter('rejected')" nz-menu-item>
                                            Rejected
                                        </li>
                                    </ul>
                                </nz-dropdown-menu>
                                <!-- <nz-select [(ngModel)]="statusFilter" (ngModelChange)="applyFilter()" class="w-100"
                                    nzPlaceHolder="Select status">
                                    <nz-option nzLabel="All" nzValue="all"> </nz-option>
                                    <nz-option nzLabel="Created" nzValue="created"> </nz-option>
                                    <nz-option nzLabel="Approved" nzValue="approved"> </nz-option>
                                    <nz-option nzLabel="Rejected" nzValue="rejected"> </nz-option>
                                </nz-select> -->
                            </div>
                            <div class="toggle_options">
                                <nz-input-group class="small_input_sec search_input_section"
                                    [nzPrefix]="suffixIconSearch">
                                    <input [(ngModel)]="searchText" (keyup)="searchTemplates()" autocomplete="off"
                                        type="text" nz-input placeholder="Search by name" />
                                </nz-input-group>
                                <ng-template #suffixIconSearch>
                                    <span nz-icon nzType="search"></span>
                                </ng-template>
                            </div>
                            <div [ngClass]="{'disable_div' : syncing}" (click)="syncTemplate()" class="sync_section">
                                <span [ngClass]="{'sync_loader' : syncing}" class="sync_icon" nz-icon nzType="sync"
                                    nzTheme="outline"></span>
                                <span>{{syncing ? 'syncing' : 'Sync'}}</span>
                            </div>
                            <a [disabled]="mainUserData?.accessTo?.isExpired" (click)="showTemplateForm()" nz-button
                                nzType="primary" class="create-button setting_add_button">
                                <i nz-icon nzType="plus" theme="outline"></i>
                                <span>Add Template</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </nz-card>
    </div>



    <div class="col-12">
        <nz-card class="tags_new_table customers_table_card">
            <div class="total_records">
                <h6>
                    Total Records ({{ totalCount }})
                </h6>
                <button class="customized_btn" [ngStyle]="setMyStyles()" nzTrigger="click" nz-dropdown
                    [nzDropdownMenu]="paginationMenu" [nzPlacement]="'bottomLeft'">
                    {{ pageSize + " records per page" }}
                </button>

                <nz-dropdown-menu #paginationMenu="nzDropdownMenu">
                    <ul nz-menu>
                        <li (click)="changePageSize((n + 1) * 10)" *ngFor="let page of [].constructor(5); let n = index"
                            nz-menu-item>
                            {{ (n + 1) * 10 + " records per page" }}
                        </li>
                    </ul>
                </nz-dropdown-menu>
            </div>

            <nz-table class="contacts_table main_contacts_table" #templateDataTable [nzData]="templateData"
                [nzScroll]="{ y: tableHeight, x : '980px' }" [nzFrontPagination]="false" [nzTotal]="totalCount"
                [nzPageSize]="pageSize" [nzPageIndex]="pageIndex"
                (nzQueryParams)="onPageNumberChange('templateTable' , $event)" [nzPaginationPosition]="'top'"
                [nzSimple]="true">
                <thead>
                    <tr>
                        <th *ngFor="let column of orderColumn" [nzWidth]="column.width">{{ column.title }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr id="templateTable" *ngFor="let item of templateDataTable.data ;  let i=index">
                        <td> {{item?.name}} </td>
                        <td>
                            <div (click)="showTemplatePreview(item)" class="template_message"
                                [ngSwitch]="item?.components[0]?.type">
                                <div *ngSwitchCase="'HEADER'" class="">
                                    <span [innerHTML]="item?.components[0]?.text"
                                        *ngIf="item?.components[0]?.format == 'TEXT'"
                                        class="float-end edit_link fs-12"></span>
                                    <span [innerHTML]="item?.components[1]?.text?.length > 30 ? 
                                    (item?.components[1]?.text)?.slice(0, 25) + '...' :
                            item?.components[1]?.text" *ngIf="item?.components[0]?.format != 'TEXT'"
                                        class="float-end edit_link fs-12"></span>
                                </div>
                                <span [innerHTML]="item?.components[0]?.text?.length > 30 ? 
                                (item?.components[0]?.text)?.slice(0, 25) + '...' :
                        item?.components[0]?.text" *ngSwitchCase="'BODY'" class="float-end edit_link fs-12"></span>
                            </div>
                        </td>
                        <td> {{item?.category}} </td>
                        <td> {{item?.language}} </td>
                        <td> {{moment(item?.createdAt).format('DD-MM-YYYY hh:mm A')}} </td>
                        <td>
                            <span class="status_span"
                                [ngClass]="{'approved' : item?.status == 'approved' , 'rejected' : item?.status == 'rejected', 'paused' : item?.status == 'paused'}">
                                {{item?.status}}
                            </span>
                            <span class="reason_info" *ngIf="item?.status == 'rejected'"
                                [nzTooltipTitle]="item?.rejectedReason" nzTooltipPlacement="top" nz-tooltip nz-icon
                                nzType="info-circle" nzTheme="outline"></span>
                        </td>
                        <td>
                            <div class="edit_delete_options">
                                <!-- <span (click)="showEditForm(item)" nz-icon nzType="edit" nzTheme="outline"></span> -->
                                <span (click)="showDeleteAlert(item)" nz-icon nzType="delete" nzTheme="outline"></span>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <ngx-spinner bdColor="rgb(255,255,255)" size="medium" color="#000000" type="ball-clip-rotate-pulse"
                    [fullScreen]="false">
                </ngx-spinner>
            </nz-table>
        </nz-card>
    </div>
</div>



<nz-card *ngIf="showForm" class="details_card mt-1">
    <div class="row">
        <div class="col-6 top_heading">
            <h3><span>Settings > </span><span class="web_cursor" (click)="showTemplateTable()">Whatsapp Template >
                </span>{{isEdit ? 'Update' : 'Add'}} Template</h3>
        </div>

        <div class="col-lg-10 col-12 mt-5">
            <form [formGroup]="addTemplateForm" (ngSubmit)="submitForm()" nz-form>
                <div class="" nz-row [nzGutter]="24">
                    <div nz-col [nzXs]="24" class="" [nzSm]="12">
                        <nz-form-item>
                            <nz-form-label nzRequired class="large_label" [nzSm]="24" [nzXs]="24">Name
                            </nz-form-label>
                            <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please Enter Name!">
                                <nz-input-group>
                                    <input (keyup)="onKeyDown($event)" maxlength="512" autocomplete="off"
                                        formControlName="name" type="text" nz-input placeholder="Enter Name" required>
                                </nz-input-group>
                            </nz-form-control>
                            <span *ngIf="addTemplateForm.get('name')?.valid"
                                class="name_length">{{addTemplateForm.get('name')?.value?.length}}/512</span>
                        </nz-form-item>
                    </div>

                    <div nz-col [nzXs]="24" class="" [nzSm]="12">
                        <nz-form-item>
                            <nz-form-label nzRequired class="large_label" [nzSm]="24" [nzXs]="24">Platform
                            </nz-form-label>
                            <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please Select Platform!">
                                <nz-input-group>
                                    <nz-select nzDisabled formControlName="platform" class="w-100 select_dropdown"
                                        nzPlaceHolder="Select Platform">
                                        <nz-option nzLabel="Whatsapp" nzValue="whatsapp"> </nz-option>
                                    </nz-select>
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <div nz-col [nzXs]="24" class="" [nzSm]="12">
                        <nz-form-item>
                            <nz-form-label nzRequired class="large_label" [nzSm]="24" [nzXs]="24">Category
                            </nz-form-label>
                            <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please Select Category!">
                                <nz-input-group>
                                    <nz-select formControlName="category" class="w-100 select_dropdown"
                                        nzPlaceHolder="Select Category">
                                        <nz-option nzLabel="Utility Conversations" nzValue="UTILITY"> </nz-option>
                                        <nz-option nzLabel="Authentication" nzValue="AUTHENTICATION"> </nz-option>
                                        <nz-option nzLabel="Marketing Conversations" nzValue="MARKETING"> </nz-option>
                                    </nz-select>
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <div nz-col [nzXs]="24" class="" [nzSm]="12">
                        <nz-form-item>
                            <nz-form-label nzRequired class="large_label" [nzSm]="24" [nzXs]="24">Language
                            </nz-form-label>
                            <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please Select Language!">
                                <nz-input-group>
                                    <nz-select nzShowSearch formControlName="language" class="w-100 select_dropdown"
                                        nzPlaceHolder="Select Language">
                                        <nz-option *ngFor="let language of languages" [nzLabel]="language?.language"
                                            [nzValue]="language?.code"> </nz-option>
                                    </nz-select>
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <div nz-col [nzXs]="24" class="" [nzSm]="24">
                        <nz-form-item>
                            <nz-form-label nzRequired class="large_label" [nzSm]="24" [nzXs]="24">
                                Template Type
                            </nz-form-label>
                            <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please Select Template Type!">
                                <nz-input-group>
                                    <nz-select formControlName="templateType" (ngModelChange)="templateTypeChange()"
                                        class="w-100 select_dropdown" nzPlaceHolder="Select Template Type">
                                        <nz-option nzLabel="Standard Template" nzValue="standard"> </nz-option>
                                        <nz-option nzLabel="Media & Interactive" nzValue="media&interactive">
                                        </nz-option>
                                    </nz-select>
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <div *ngIf="addTemplateForm.get('templateType').value == 'media&interactive'" nz-col [nzXs]="24"
                        class="" [nzSm]="24">
                        <nz-form-item class="radio_label">
                            <nz-form-label class="">Message Header(Optional)
                            </nz-form-label>
                            <nz-form-control>
                                <nz-input-group>
                                    <nz-radio-group formControlName="headerType" (ngModelChange)="headerTypeChange()">
                                        <!-- <label nz-radio nzValue="none">None</label> -->
                                        <label nz-radio nzValue="TEXT">Text</label>
                                        <label nz-radio nzValue="media">Media</label>
                                    </nz-radio-group>
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <div *ngIf="addTemplateForm.get('headerType').value == 'media'" nz-col [nzXs]="24" class=""
                        [nzSm]="24">
                        <nz-form-item>
                            <nz-form-label nzRequired class="large_label" [nzSm]="24" [nzXs]="24">
                                Media Type
                            </nz-form-label>
                            <nz-form-control [nzSm]="10" [nzXs]="24" nzErrorTip="Please Select Media Type!">
                                <nz-input-group>
                                    <nz-select (ngModelChange)="resetMedia()" formControlName="headerFormat"
                                        class="w-100 select_dropdown" nzPlaceHolder="Select Media Type">
                                        <nz-option nzLabel="IMAGE" nzValue="IMAGE"> </nz-option>
                                        <nz-option nzLabel="VIDEO" nzValue="VIDEO"> </nz-option>
                                        <nz-option nzLabel="DOCUMENT" nzValue="DOCUMENT"> </nz-option>
                                    </nz-select>
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <div *ngIf="addTemplateForm.get('templateType').value == 'media&interactive'" nz-col [nzXs]="24"
                        class="" [nzSm]="24">
                        <nz-form-item *ngIf="addTemplateForm.get('headerType').value == 'TEXT'">
                            <nz-form-label class="large_label" [nzSm]="24" [nzXs]="24">Header Text
                            </nz-form-label>
                            <nz-form-control [nzSm]="24" [nzXs]="24">
                                <nz-input-group class="header_text_group">
                                    <nz-textarea-count [nzMaxCharacterCount]="60">
                                        <textarea #headerText maxlength="60" [nzAutosize]="{ maxRows: 2 }"
                                            formControlName="headerText" nz-input></textarea>
                                    </nz-textarea-count>
                                    <button
                                        [disabled]="detectVariables(addTemplateForm?.get('headerText')?.value)?.length > 0"
                                        (click)="addHeaderVariable()" type="button" nzType="primary" nz-button>
                                        Add Variables
                                    </button>
                                    <span *ngIf="detectVariables(addTemplateForm?.get('headerText')?.value)?.length > 0"
                                        nzTooltipTitle="you can add maximum 1 variable in header"
                                        nzTooltipPlacement="top" nz-tooltip nz-icon nzType="info-circle"
                                        nzTheme="outline"></span>
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>
                        <div *ngIf="addTemplateForm.get('headerType').value == 'media'"
                            class="mb-3 upload-button save-button">
                            <div class="preview_sample"
                                [ngClass]="{'document_div' : previewMedia && addTemplateForm?.get('headerFormat')?.value == 'DOCUMENT'}">
                                <span *ngIf="!previewMedia" nz-icon nzType="file-image" nzTheme="outline"></span>
                                <img class="preview_image"
                                    *ngIf="previewMedia && addTemplateForm?.get('headerFormat')?.value == 'IMAGE'"
                                    [src]="previewMedia" [alt]="'image'">
                                <video controls class="preview_video"
                                    *ngIf="previewMedia && addTemplateForm?.get('headerFormat')?.value == 'VIDEO'"
                                    [src]="previewMedia"></video>
                                <img *ngIf="previewMedia && addTemplateForm?.get('headerFormat')?.value == 'DOCUMENT'"
                                    class="preview_image" src="../../../../assets/images/chat/icons8-google-docs.svg"
                                    alt="">
                                <p nz-tooltip [nzTooltipTitle]="selectedMedia?.name"
                                    *ngIf="previewMedia && addTemplateForm?.get('headerFormat')?.value == 'DOCUMENT'">
                                    {{selectedMedia?.name.length > 18 ? selectedMedia?.name.slice(0,18) + '..' :
                                    selectedMedia?.name}}</p>
                            </div>
                            <div class="upload_div">
                                <label for='template-file'>Upload
                                    {{addTemplateForm?.get('headerFormat')?.value?.toLowerCase()}}</label>
                                <span>File size must be less than
                                    {{filesSizeValidation[addTemplateForm.value.headerFormat]}}MB.</span>
                                <input (change)="getTemplateMedia($event)" class="upload_input" id='template-file'
                                    type='file' [accept]="filesTypes[addTemplateForm?.get('headerFormat')?.value]">
                            </div>
                        </div>

                    </div>

                    <div nz-col [nzXs]="24" class="" [nzSm]="24">
                        <nz-form-item>
                            <nz-form-label nzRequired class="large_label" [nzSm]="24" [nzXs]="24">Message Body
                            </nz-form-label>
                            <div class="w-100">
                                <nz-form-control nzErrorTip="Please Fill Message Body!" [nzSm]="24" [nzXs]="24">
                                    <nz-input-group>
                                        <nz-textarea-count [nzMaxCharacterCount]="1024">
                                            <textarea (paste)="onPaste($event)" (mouseup)="mouseUp()"
                                                (select)="onTextSelect()" (focus)="bodyTextFocus()" #bodyText
                                                maxlength="1024" rows="4" formControlName="bodyText"
                                                nz-input></textarea>
                                        </nz-textarea-count>
                                        <!-- <angular-editor #bodyText formControlName="bodyText" class="template_editor"
                                            [config]="config">
                                        </angular-editor> -->

                                    </nz-input-group>
                                </nz-form-control>
                                <div class="editor_options">
                                    <emoji-mart *ngIf="emojiVisible" class="emoji-mart" set="{{set}}"
                                        (emojiSelect)="addEmoji($event)" title="Pick your emoji…"></emoji-mart>
                                    <p (click)="showEmoji()">
                                        <span nz-icon nzType="smile" nzTheme="outline"></span>
                                    </p>
                                    <p [ngClass]="{'selected_style' : selectedStyle == 'Bold'}"
                                        (click)="editText('bold')">
                                        <span nz-icon nzType="bold" nzTheme="outline"></span>
                                    </p>
                                    <p [ngClass]="{'selected_style' : selectedStyle == 'Italic'}"
                                        (click)="editText('italic')">
                                        <span nz-icon nzType="italic" nzTheme="outline"></span>
                                    </p>
                                    <p [ngClass]="{'selected_style' : selectedStyle == 'Strike'}"
                                        (click)="editText('strikethrough')">
                                        <span nz-icon nzType="strikethrough" nzTheme="outline"></span>
                                    </p>
                                </div>

                                <button type="button" (click)="addVariable()" class="mt-3" [nzSm]="24" nzType="primary"
                                    nz-button>
                                    <i nz-icon nzType="plus" theme="outline"></i>
                                    Add Variables
                                </button>
                            </div>

                            <!-- <nz-dropdown-menu #variables="nzDropdownMenu">
                            <ul nz-menu>
                                <li (click)="addVariable('name')" nz-menu-item>name</li>
                                <li (click)="addVariable('phone')" nz-menu-item>phone</li>
                            </ul>
                        </nz-dropdown-menu> -->
                        </nz-form-item>
                    </div>

                    <div *ngIf="addTemplateForm.get('templateType').value == 'media&interactive'" nz-col [nzXs]="24"
                        class="" [nzSm]="24">
                        <nz-form-item>
                            <nz-form-label class="large_label" [nzSm]="24" [nzXs]="24">Message Footer(optional)
                            </nz-form-label>
                            <nz-form-control nzErrorTip="Maximum length is 60 characters.!" [nzSm]="24" [nzXs]="24">
                                <nz-input-group>
                                    <nz-textarea-count [nzMaxCharacterCount]="60">
                                        <textarea maxlength="60" [nzAutosize]="{ maxRows: 2 }"
                                            formControlName="footerText" nz-input></textarea>
                                    </nz-textarea-count>
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <div *ngIf="addTemplateForm.get('templateType').value == 'media&interactive'" nz-col [nzXs]="24"
                        class="" [nzSm]="14">
                        <nz-form-item>
                            <nz-form-label class="large_label" [nzSm]="24" [nzXs]="24">Buttons(optional)
                            </nz-form-label>
                            <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please Select Button Type!">
                                <nz-input-group class="add_btn_control">
                                    <nz-select [nzDisabled]="allButtons?.length > 0" formControlName="buttonType"
                                        class="w-100 select_dropdown" nzPlaceHolder="Select Button Type">
                                        <nz-option nzLabel="Call to Action" nzValue="call to action"> </nz-option>
                                        <nz-option nzLabel="Quick Reply" nzValue="QUICK_REPLY"> </nz-option>
                                    </nz-select>
                                    <button
                                        [disabled]="(allButtons?.length == 3 && addTemplateForm.get('buttonType').value == 'QUICK_REPLY') || (allButtons?.length == 2 && addTemplateForm.get('buttonType').value == 'call to action')"
                                        (click)="addButton()" type="button" class="" nzType="primary" nz-button>
                                        <i nz-icon nzType="plus" theme="outline"></i>
                                        Add Button
                                    </button>
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>

                        <div *ngIf="allButtons?.length > 0" class="all_buttons">
                            <ng-container *ngFor="let btn of allButtons; let i = index">
                                <span (click)="removeButton(i)" class="btn_del_icon" nz-icon nzType="delete"
                                    nzTheme="outline"></span>
                                <nz-form-label nzRequired class="large_label loop_label" [nzSm]="24" [nzXs]="24">
                                    Button {{i+1}}
                                </nz-form-label>
                                <!-- call to action -->
                                <!-- action type -->
                                <nz-form-item *ngIf="addTemplateForm.get('buttonType').value == 'call to action'">
                                    <nz-form-control class="multi_control" nzErrorTip="Please Select Action Type!"
                                        [nzSm]="24" [nzXs]="24">
                                        <nz-input-group class="">
                                            <nz-select [nzDisabled]="allButtons.length > 1"
                                                (ngModelChange)="actionTypeChange(i)"
                                                [formControlName]="'actionType' + i" class="w-100 select_dropdown"
                                                nzPlaceHolder="Select Action Type">
                                                <nz-option nzLabel="Phone Number" nzValue="phone"> </nz-option>
                                                <nz-option nzLabel="URL" nzValue="url"> </nz-option>
                                            </nz-select>
                                        </nz-input-group>
                                    </nz-form-control>
                                </nz-form-item>
                                <!-- action label -->
                                <nz-form-item *ngIf="addTemplateForm?.get('buttonType')?.value == 'call to action'">
                                    <nz-form-control class="multi_control" nzErrorTip="Please Enter Label Text!">
                                        <nz-input-group class="">
                                            <input maxlength="25" [formControlName]="'actionLabel' + i" class=""
                                                autocomplete="off" type="text" nz-input id=""
                                                placeholder="Enter Label for button">
                                        </nz-input-group>
                                    </nz-form-control>
                                    <span *ngIf="addTemplateForm.get('actionLabel' + i)?.valid"
                                        class="name_length">{{addTemplateForm.get('actionLabel' +
                                        i)?.value?.length}}/25</span>
                                </nz-form-item>
                                <!-- action value number -->
                                <nz-form-item
                                    *ngIf="addTemplateForm?.get('actionType' + i)?.value == 'phone' && addTemplateForm.get('buttonType').value == 'call to action'">
                                    <nz-form-control class="multi_control custom_mobile"
                                        nzErrorTip="Please Enter Phone Number!">
                                        <nz-input-group [nzAddOnBefore]="countryCode" class="">
                                            <input [formControlName]="'actionValue' + i" class="" autocomplete="off"
                                                type="number" nz-input id="" placeholder="Enter Phone Number">
                                        </nz-input-group>
                                        <ng-template #countryCode>
                                            <nz-select nzShowSearch class="w-100 select_dropdown"
                                                [formControlName]="'countryCode' + i">
                                                <nz-option *ngFor="let code of countryCodes"
                                                    [nzLabel]="code.countryCode"
                                                    [nzValue]="code.countryCode"></nz-option>
                                            </nz-select>
                                        </ng-template>
                                    </nz-form-control>
                                </nz-form-item>
                                <!-- action value url -->
                                <nz-form-item
                                    *ngIf="addTemplateForm?.get('actionType' + i)?.value == 'url' && addTemplateForm.get('buttonType').value == 'call to action'">
                                    <nz-form-control class="multi_control " nzErrorTip="Please Enter Url!">
                                        <nz-input-group [nzSuffix]="variableIcon">
                                            <input maxlength="2000" [validators]="[urlValidator]"
                                                [formControlName]="'actionValue' + i" class="" autocomplete="off"
                                                type="text" nz-input id="" placeholder="Enter Url">
                                        </nz-input-group>
                                        <ng-template #variableIcon>
                                            <span (click)="addWebVariable('actionValue' + i)"
                                                *ngIf="detectVariables(addTemplateForm?.get('actionValue' + i)?.value)?.length == 0"
                                                class="web_variable">Add Variable</span>
                                        </ng-template>
                                    </nz-form-control>
                                    <span *ngIf="addTemplateForm.get('actionValue' + i)?.valid"
                                        class="name_length">{{addTemplateForm.get('actionValue' +
                                        i)?.value?.length}}/2000</span>
                                </nz-form-item>


                                <!-- quick reply -->
                                <nz-form-item *ngIf="addTemplateForm.get('buttonType').value == 'QUICK_REPLY'">
                                    <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please Enter Label Text!">
                                        <nz-input-group class="">
                                            <input maxlength="25" [formControlName]="'buttonLabel' + i" class=""
                                                autocomplete="off" type="text" nz-input id=""
                                                placeholder="Enter Label for button">
                                        </nz-input-group>
                                    </nz-form-control>
                                    <span *ngIf="addTemplateForm.get('buttonLabel' + i)?.valid"
                                        class="name_length">{{addTemplateForm.get('buttonLabel' +
                                        i)?.value?.length}}/25</span>
                                </nz-form-item>
                            </ng-container>
                        </div>
                    </div>


                    <!--  <div nz-col [nzXs]="24" class="" [nzSm]="12">
                    <nz-form-item>
                        <nz-form-label class="large_label"  [nzSm]="24" [nzXs]="24">Buttons(optional)
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please Select Language!">
                            <nz-input-group>
                                <input autocomplete="off"  type="number" nz-input id=""
                                placeholder="Enter Name" required>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>
                </div> -->

                    <div nz-col [nzXs]="24" class="" [nzSm]="24">
                        <nz-form-item class="submit_section text-right">
                            <nz-form-control [nzSm]="24" [nzXs]="24">
                                <div class="account_details_add_cancel_right">
                                    <button (click)="showTemplateTable()" type="button" class="sighup_button mr-3"
                                        nz-button nzType="default">Cancel</button>
                                    <button [nzLoading]="creating" type="submit" class="sighup_button" nz-button
                                        nzType="primary">{{isEdit ? 'Update' : 'Create'}}</button>
                                </div>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
            </form>
        </div>
    </div>
</nz-card>


<div *ngIf="comingSoon" class="details_card ">
    <div class="coming_soon_div">
        <div class="soon_main">
            <img src="../../../../assets/images/logo/coming-soon.gif" alt="">
            <div class="">
                <a (click)="backFromComingSoon()" nz-button nzType="primary"
                    class="d-flex align-items-center create-button setting_add_button">
                    <i nz-icon nzType="double-left" nzTheme="outline"></i>
                    <span>View Templates</span>
                </a>
            </div>
        </div>
    </div>
</div>


<!-- sample form modal -->

<nz-modal [nzMaskClosable]="false" [nzWidth]="'60%'" [(nzVisible)]="showSampleForm" [nzFooter]="null"
    nzTitle="Add Sample Values" nzCentered (nzOnCancel)="closeSampleModal()">
    <ng-container *nzModalContent>
        <div class="modal_container">

            <form [formGroup]="addSampleForm" (ngSubmit)="addSampleData()" nz-form>
                <div class="">
                    <h5 *ngIf="sampleValuesHeader?.length > 0">HEADER</h5>
                    <p *ngIf="sampleValuesHeader?.length > 0" [innerHtml]="addTemplateForm.get('headerText').value">
                    </p>
                </div>
                <!-- sample header variables -->
                <nz-form-item *ngFor="let sample of sampleValuesHeader ; let k = index">
                    <nz-form-label nzRequired class="large_label" [nzSm]="24" [nzXs]="24">
                        {{'{' + '{' + (k+1) + '}' + '}'}}
                    </nz-form-label>
                    <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please Enter Value!">
                        <nz-input-group>
                            <input autocomplete="off" [formControlName]="'sampleHeader' + (k+1)" type="text" nz-input
                                placeholder="Enter Sample Value" required>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <div class="">
                    <h5 *ngIf="sampleValuesBody?.length > 0">BODY</h5>
                    <p *ngIf="sampleValuesBody?.length > 0" [innerHtml]="addTemplateForm.get('bodyText').value"></p>
                </div>
                <!-- sample body variables -->
                <nz-form-item *ngFor="let sample of sampleValuesBody ; let j = index">
                    <nz-form-label nzRequired class="large_label" [nzSm]="24" [nzXs]="24">
                        {{'{' + '{' + (j+1) + '}' + '}'}}
                    </nz-form-label>
                    <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please Enter Value!">
                        <nz-input-group>
                            <input autocomplete="off" [formControlName]="'sampleBody' + (j+1)" type="text" nz-input
                                placeholder="Enter Sample Value" required>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <div class="">
                    <h5 *ngIf="sampleValuesURL?.length > 0">BUTTONS</h5>
                    <div *ngFor="let url of sampleValuesURL; let o = index">
                        <p>{{url}}</p>
                        <nz-form-item>
                            <nz-form-label nzRequired class="large_label" [nzSm]="24" [nzXs]="24">
                                {{'{' + '{' + (o+1) + '}' + '}'}}
                            </nz-form-label>
                            <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please Enter Value!">
                                <nz-input-group>
                                    <input autocomplete="off" [formControlName]="'sampleURL' + (o+1)" type="text"
                                        nz-input placeholder="Enter Sample Value" required>
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>

                <nz-form-item class="submit_section text-right">
                    <nz-form-control [nzSm]="24" [nzXs]="24">
                        <div class="account_details_add_cancel_right">
                            <button (click)="backToForm()" type="button" class="sighup_button mr-3" nz-button
                                nzType="default">Cancel</button>
                            <button [nzLoading]="creating" type="submit" class="sighup_button" nz-button
                                nzType="primary">{{isEdit ? 'Update' : 'Create'}}</button>
                        </div>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </div>
    </ng-container>
</nz-modal>


<nz-modal [(nzVisible)]="showPreview" [nzWidth]="modalWidth" [nzFooter]="null" nzTitle="Template Preview" nzCentered
    (nzOnCancel)="closeTemplatePreview()">
    <ng-container *nzModalContent>

        <div class="template_col">
            <div class="preview_heading">
                <h5 [nzTooltipTitle]="previewTemplate.name" nzTooltipPlacement="top" nz-tooltip>
                    {{previewTemplate.name.length > 23 ?
                    previewTemplate.name.slice(0,23) + '...' : previewTemplate.name}}
                </h5>
            </div>
            <div class="preview_template">
                <div class="inner_template">
                    <div *ngFor="let message of previewTemplate.components" class="mb-3">
                        <div [ngSwitch]="message?.type" class="">
                            <div *ngSwitchCase="'HEADER'" class="">
                                <div [ngSwitch]="message?.format" class="header_text">
                                    <p *ngSwitchCase="'TEXT'" [innerHTML]="message.text"></p>
                                    <p class="img_url" *ngSwitchCase="'IMAGE'">
                                        <span nz-icon nzType="file-image" nzTheme="outline"></span>
                                        IMAGE
                                    </p>
                                    <p class="img_url" *ngSwitchCase="'VIDEO'">
                                        <span nz-icon nzType="video-camera" nzTheme="outline"></span>
                                        VIDEO
                                    </p>
                                    <p class="img_url" *ngSwitchCase="'DOCUMENT'">
                                        <span nz-icon nzType="file-done" nzTheme="outline"></span>
                                        DOCUMENT
                                    </p>
                                </div>

                            </div>
                            <div *ngSwitchCase="'BODY'" class="">
                                <p [innerHTML]="((message?.text))"></p>
                            </div>
                            <div *ngSwitchCase="'FOOTER'" class="">
                                <p [innerHTML]="message?.text"></p>
                            </div>
                            <div *ngSwitchCase="'BUTTONS'" class="">
                                <div *ngFor="let button of message?.buttons" class="template_buttons">
                                    <h6>{{button?.text}}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="template_bottom">
                <div class="bottom_left">
                    <span class="template_lang">{{previewTemplate?.language}}</span>
                    <span class="template_lang">{{previewTemplate?.category}}</span>
                </div>
                <span class="template_status">{{previewTemplate?.status}}</span>
            </div>
        </div>

    </ng-container>
</nz-modal>