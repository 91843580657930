<div *ngIf="!catalogueForm" class="row overflow_row_mobile">
    <div class="col-12 main_outer_heading d_mobile_none">
        <h3>Commerce</h3>
    </div>
    <!-- *ngIf="!mainUserData?.accessTo?.sandboxId" -->
    <div class="col-2 left_menu">
        <ul class="main_list">
            <li class="list_heading catalogue_heading mb-3">
                {{mobileDevice ? 'CATALOGUE' : 'WHATSAPP CATALOGUE'}}
                <span *ngIf="!mainUserData?.accessTo?.sandboxId" [nzTooltipTitle]="'Connect New Catalogue'"
                    nzTooltipPlacement="top" nz-tooltip (click)="changeCatalogueModal()" nz-icon nzType="plus-circle"
                    nzTheme="twotone"></span>
            </li>
            <li (click)="selectOption('items')" class="list_options items_option mb-3"
                [ngClass]="{'selected_tab_setting' : selectedTab == 'items'}">
                Items
                <a *ngIf="defaultCatalogueCode != mainUserData?.accessTo?.catalogueId" [disable]="syncing"
                    (click)="syncCatalogue()" class="sync_btn">
                    <span [ngClass]="{'sync_loader' : syncing}" nz-icon nzType="sync" nzTheme="outline"></span>
                    {{syncing ? 'Syncing' : 'Sync'}}
                </a>
            </li>
            <!-- <li (click)="selectOption('sets')" class="list_options mb-3"
                [ngClass]="{'selected_tab_setting' : selectedTab == 'sets'}">
                Sets
            </li> -->

            <li *ngIf="itemsData.length >= 0 && !initialLoad" class="list_heading mb-3">STORES</li>
            <li *ngIf="itemsData.length >= 0 && !initialLoad" (click)="selectOption('dashboard')"
                class="list_options mb-3" [ngClass]="{'selected_tab_setting' : selectedTab == 'dashboard'}">
                Dashboard
            </li>
            <li *ngIf="itemsData.length >= 0 && !initialLoad" (click)="selectOption('setting')"
                class="list_options mb-3" [ngClass]="{'selected_tab_setting' : selectedTab == 'setting'}">
                Settings
            </li>
            <li *ngIf="itemsData.length >= 0 && !initialLoad" (click)="selectOption('orders')" class="list_options mb-3"
                [ngClass]="{'selected_tab_setting' : selectedTab == 'orders'}">
                Orders
            </li>
            <!-- <li *ngIf="itemsData.length >= 0 && !initialLoad" (click)="selectOption('messages')"
                class="list_options mb-3" [ngClass]="{'selected_tab_setting' : selectedTab == 'messages'}">
                Messages
            </li> -->
            <!-- <li *ngIf="itemsData.length >= 0 && !initialLoad" (click)="selectOption('automation')"
                class="list_options mb-3" [ngClass]="{'selected_tab_setting' : selectedTab == 'automation'}">
                Automation
            </li> -->
        </ul>
    </div>

    <div class="col-10 right_column" id="main-section">
        <!-- new -->
        <!-- <div class="fixed_height main_card">
            
        </div> -->


        <div *ngIf="selectedTab == 'items'" class="h-100">
            <nz-card class="fixed_height main_card">
                <!-- empty items -->
                <div *ngIf="itemsData.length == 0 && initialLoad " class="empty_div">
                    <div class="mb-3">
                        <img src="../../../../assets/images/commerce/add_catalogue.svg" alt="">
                    </div>
                    <div class="text-center connect_catalogue">
                        <h5>In Order To Connect Catalogue, You Must Provide Compliance Info.</h5>
                        <a [disabled]="mainUserData?.accessTo?.isExpired || mainUserData?.accessTo?.sandboxId"
                            (click)="openCatalogueModal()" nz-button nzType="primary" class="create-button mt-3">
                            <i nz-icon nzType="plus-circle" theme="outline"></i>
                            <span>Add Catalogue</span>
                        </a>
                    </div>
                </div>

                <!-- items available -->
                <!-- *ngIf="itemsData.length >= 0 && !initialLoad" -->
                <div *ngIf="itemsData.length >= 0 && !initialLoad " class="">
                    <div class="row align-items-center justify-content-between m-b-30">
                        <div class="col-9 top_heading">
                            <h3>Items
                                <span *ngIf="!mainUserData?.accessTo?.sandboxId">
                                    ( Catalogue ID : {{mainUserData?.accessTo?.catalogueId}} )
                                    <i [nzTooltipTitle]="copyText" nzTooltipPlacement="top" nz-tooltip class="copy_url"
                                        (click)="copyId(mainUserData?.accessTo?.catalogueId)" nz-icon nzType="copy"
                                        nzTheme="outline"></i>
                                </span>
                            </h3>
                            <span>View and manage all the items in your catalog. You can quickly find specific items
                                using search or filters</span>
                        </div>
                        <div class="col-3 d-flex justify-content-end text-right">
                            <a [disabled]="mainUserData?.accessTo?.isExpired" (click)="addCatalogueForm()" nz-button
                                nzType="primary" class="create-button">
                                <i nz-icon nzType="plus-circle" theme="outline"></i>
                                <span>Add Items</span>
                            </a>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-12 table_section" [ngClass]="{'col-lg-8' : addItemForm}">
                            <div class="fixed_height_small">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="search_add_div">
                                            <div class="toggle_options items_search">
                                                <nz-input-group [nzPrefix]="suffixIconSearch">
                                                    <input autocomplete="off" [(ngModel)]="catalogueSearchKey"
                                                        (keyup)="searchCatalogue()" type="text" nz-input
                                                        placeholder="Search Items" />
                                                </nz-input-group>
                                                <ng-template #suffixIconSearch>
                                                    <span nz-icon nzType="search"></span>
                                                </ng-template>
                                            </div>
                                            <a (click)="showFilters()" nz-button nzType="default"
                                                class="create-button ">
                                                <i nz-icon nzType="sliders" nzTheme="outline"></i>
                                                <span>Filters</span>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <nz-table class="contacts_table normal_pagination" #itemsDataTable
                                            [nzData]="itemsData" [nzFrontPagination]="false" [nzTotal]="totalCount"
                                            [nzPageSize]="pageSize" [nzPageIndex]="pageIndex"
                                            (nzQueryParams)="onPageNumberChange('catalogueTable' , $event)"
                                            [nzScroll]="{ y: tableHeight, x : '500px' }" [nzPaginationPosition]="'top'"
                                            [nzSimple]="true">
                                            <thead>
                                                <tr>
                                                    <th *ngFor="let column of itemsOrderColumn"
                                                        [nzWidth]="column.width">
                                                        {{ column.title }}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr id="catalogueTable" *ngFor="let item of itemsData ;  let i=index">
                                                    <td>
                                                        <div class="item_name_section">
                                                            <nz-avatar nzShape="square" [nzSize]="40" nzIcon="picture"
                                                                [nzSrc]="item?.image_url"></nz-avatar>
                                                            <div class="item_name">
                                                                <p (click)="editCatalogue(item)" class="edit_link">
                                                                    {{item?.name}}</p>
                                                                <!-- <span>Content ID : {{item?.contentId}}</span> -->
                                                            </div>
                                                        </div>

                                                    </td>
                                                    <td class="capitalize">
                                                        {{item.brand ? item.brand : '-'}}
                                                    </td>
                                                    <td class="capitalize">
                                                        <nz-select [(ngModel)]="item.availability"
                                                            (ngModelChange)="directUpdate(item , i)"
                                                            nzPlaceHolder="Select Availability" class="w-100">
                                                            <nz-option nzValue="in stock"
                                                                nzLabel="In Stock"></nz-option>
                                                            <nz-option nzValue="out of stock"
                                                                nzLabel="Out of Stock"></nz-option>
                                                        </nz-select>
                                                        <!-- {{item.availability ? item.availability : '-'}} -->
                                                    </td>
                                                    <td>{{item?.currency + ' ' + item.sale_price}}</td>
                                                </tr>

                                            </tbody>
                                            <ngx-spinner *ngIf="commerceLoader" bdColor="rgb(255,255,255)" size="medium"
                                                color="#000000" type="ball-clip-rotate-pulse" [fullScreen]="false">
                                            </ngx-spinner>

                                        </nz-table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="addItemForm" class="col-4 form_section">
                            <nz-card class="filter_options fixed_height_small">
                                <div class="top_heading filter_heading mb-3">
                                    <h3>Filters</h3>
                                    <span (click)="reloadData()" nz-icon nzType="reload" nzTheme="outline"></span>
                                    <!-- <a nz-button nzType="primary" class="create-button">
                                    <span>Apply</span>
                                </a> -->
                                </div>

                                <!-- <nz-collapse class="main_collapse" [nzExpandIconPosition]="'right'"> -->
                                <!-- <nz-collapse-panel [nzHeader]="'Status'">
                                    <nz-radio-group class="status_filter" [(ngModel)]="itemFilterStatus">
                                        <label nz-radio nzValue="Active">Active</label>
                                        <label nz-radio nzValue="Archived">Archived</label>
                                        <label nz-radio nzValue="All items">All items</label>
                                    </nz-radio-group>
                                </nz-collapse-panel> -->

                                <!-- <nz-collapse-panel [nzHeader]="'Attributes'" [nzActive]="true"> -->
                                <nz-collapse class="inner_collapse" [nzExpandIconPosition]="'right'">
                                    <nz-collapse-panel [nzHeader]="'Condition'" [nzActive]="true">
                                        <nz-checkbox-wrapper style="width: 100%;"
                                            (nzOnChange)="conditionChange($event)">
                                            <div nz-row>
                                                <div nz-col nzSpan="24">
                                                    <label [ngModel]="filterCondition.indexOf('new') > -1" nz-checkbox
                                                        nzValue="new">New</label>
                                                </div>
                                                <div nz-col nzSpan="24">
                                                    <label [ngModel]="filterCondition.indexOf('refurbished') > -1"
                                                        nz-checkbox nzValue="refurbished">Refurbished</label>
                                                </div>
                                                <div nz-col nzSpan="24">
                                                    <label [ngModel]="filterCondition.indexOf('used') > -1" nz-checkbox
                                                        nzValue="used">Used</label>
                                                </div>
                                            </div>
                                        </nz-checkbox-wrapper>
                                    </nz-collapse-panel>
                                </nz-collapse>

                                <nz-collapse class="inner_collapse" [nzExpandIconPosition]="'right'">
                                    <nz-collapse-panel [nzHeader]="'Price'" [nzActive]="true">
                                        <nz-form-item>
                                            <nz-form-label class="" nzFor="">
                                                From
                                            </nz-form-label>
                                            <nz-form-control [nzSm]="24">
                                                <nz-input-group>
                                                    <input (keyup.enter)="priceChange()" autocomplete="off"
                                                        [(ngModel)]="filterFromPrice" type="number" nz-input
                                                        placeholder="Enter Price">
                                                    <p class="error_message"
                                                        *ngIf="requiredFilter.indexOf('from price') > -1 ">
                                                        Please enter from price</p>
                                                </nz-input-group>
                                            </nz-form-control>
                                        </nz-form-item>
                                        <nz-form-item>
                                            <nz-form-label class="" nzFor="">
                                                To
                                            </nz-form-label>
                                            <nz-form-control [nzSm]="24">
                                                <nz-input-group>
                                                    <input (keyup.enter)="priceChange()" [(ngModel)]="filterToPrice"
                                                        autocomplete="off" type="number" nz-input
                                                        placeholder="Enter Price">
                                                    <p class="error_message"
                                                        *ngIf="requiredFilter.indexOf('to price') > -1">
                                                        Please enter to price</p>
                                                </nz-input-group>
                                            </nz-form-control>
                                        </nz-form-item>

                                    </nz-collapse-panel>
                                </nz-collapse>

                                <nz-collapse class="inner_collapse" [nzExpandIconPosition]="'right'">
                                    <nz-collapse-panel [nzHeader]="'Brand'" [nzActive]="true">
                                        <input autocomplete="off" (keyup.enter)="brandChange()"
                                            [(ngModel)]="filterBrand" [type]="'text'" nz-input
                                            placeholder="Enter brand name" />
                                    </nz-collapse-panel>
                                </nz-collapse>

                                <nz-collapse class="inner_collapse" [nzExpandIconPosition]="'right'">
                                    <nz-collapse-panel [nzHeader]="'Availability'" [nzActive]="true">
                                        <nz-select [(ngModel)]="filterAvailability"
                                            (ngModelChange)="availabilityChange()" class="w-100 "
                                            nzPlaceHolder="Select Availability">
                                            <nz-option [nzLabel]="'All'" nzValue="all"></nz-option>
                                            <nz-option [nzLabel]="'In Stock'" nzValue="in stock"></nz-option>
                                            <nz-option [nzLabel]="'Out Of Stock'" nzValue="out of stock"></nz-option>
                                        </nz-select>
                                    </nz-collapse-panel>
                                </nz-collapse>
                            </nz-card>
                        </div>
                    </div>
                </div>
            </nz-card>
        </div>

        <div *ngIf="selectedTab == 'orders'" class="">
            <nz-card class="fixed_height main_card">
                <app-orders-page></app-orders-page>
            </nz-card>
        </div>

        <div *ngIf="selectedTab == 'automation'" class="">
            <nz-card class="fixed_height main_card">
                <app-automation></app-automation>
            </nz-card>
        </div>

        <div *ngIf="selectedTab == 'dashboard'" class="">
            <nz-card class="fixed_height main_card">
                <app-commerce-dashboard></app-commerce-dashboard>
            </nz-card>
        </div>

        <div *ngIf="selectedTab == 'setting'" class="">
            <div class="row align-items-center justify-content-between ">
                <div class="col-12 top_heading mb-1">
                    <h3>Setting</h3>
                </div>
                <div class="col-6">
                    <nz-card class="fixed_height_commerce_setting">
                        <ul class="campaign_actions_list">
                            <li (click)="changeSettingTab(action)" *ngFor="let action of itemSetting"
                                class="campaign_actions mb-3" [ngClass]="{'active_tab' : action.id == selectedSetting}">
                                <div class="title_menu">
                                    <span nz-icon [nzType]="action.icon" nzTheme="outline"></span>
                                    <p>{{action.title}}</p>
                                </div>
                                <span nz-icon nzType="right" nzTheme="outline"></span>
                            </li>
                        </ul>
                    </nz-card>
                </div>

                <div class="col-6">
                    <nz-card class="fixed_height_commerce_setting">
                        <!-- MOV and shipping cost -->
                        <div *ngIf="selectedSetting == 'mov/shippingCost'" class="">
                            <form [formGroup]="shippingForm" (ngSubmit)="addShipping(true)" nz-form>
                                <div class="mov_section">
                                    <div class="setting_right_heading">
                                        <h5>Minimum Order Value</h5>
                                        <button type="submit" nz-button nzType="primary" class="create-button">
                                            <span>Next</span>
                                        </button>
                                    </div>

                                    <div class="">
                                        <nz-form-item>
                                            <nz-form-label [nzSm]="24" class="large_label" nzFor="">
                                                Minimum Order Value
                                            </nz-form-label>
                                            <nz-form-control [nzSm]="24" nzErrorTip="Please Enter Minimum Order Value!">
                                                <nz-input-group>
                                                    <input min="0" formControlName="minimumOrderValue"
                                                        autocomplete="off" type="number" nz-input placeholder="0.00">
                                                </nz-input-group>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                </div>

                                <div class="mov_section mt-3">
                                    <div class="setting_right_heading">
                                        <h5>Shipping Cost</h5>
                                    </div>

                                    <div class="">
                                        <nz-form-item>
                                            <nz-form-label [nzSm]="24" class="large_label" nzFor="">
                                                Shipping Method
                                            </nz-form-label>
                                            <nz-form-control [nzSm]="24" nzErrorTip="Please Select Shipping Method!">
                                                <nz-input-group>
                                                    <nz-select formControlName="shippingMethod" class="w-100 "
                                                        nzPlaceHolder="Select Shipping Method">
                                                        <nz-option [nzLabel]="'Free Shipping'"
                                                            [nzValue]="true"></nz-option>
                                                        <nz-option [nzLabel]="'Add Shipping Cost'"
                                                            [nzValue]="false"></nz-option>
                                                    </nz-select>
                                                </nz-input-group>
                                            </nz-form-control>
                                        </nz-form-item>

                                        <nz-form-item *ngIf="shippingForm.get('shippingMethod').value == true">
                                            <nz-form-label [nzSm]="24" class="large_label" nzFor="">
                                                Applies To
                                            </nz-form-label>
                                            <nz-form-control [nzSm]="24" nzErrorTip="Please Select Applies To!">
                                                <nz-input-group>
                                                    <nz-select formControlName="appliesTo" class="w-100 "
                                                        nzPlaceHolder="Select Applies To">
                                                        <nz-option [nzLabel]="'All Carts'"
                                                            [nzValue]="'all-carts'"></nz-option>
                                                        <nz-option [nzLabel]="'Carts Of Value Below'"
                                                            [nzValue]="'cart-value-below'"></nz-option>
                                                    </nz-select>
                                                </nz-input-group>
                                            </nz-form-control>
                                        </nz-form-item>

                                        <nz-form-item
                                            *ngIf="shippingForm.get('appliesTo').value == 'cart-value-below' && shippingForm.get('shippingMethod').value == true">
                                            <nz-form-label [nzSm]="24" class="large_label" nzFor="">
                                                Carts Of Value
                                            </nz-form-label>
                                            <nz-form-control [nzSm]="24" nzErrorTip="Please Enter Carts Of Value!">
                                                <nz-input-group>
                                                    <input min="0" formControlName="freeDeliveryChargesOver"
                                                        autocomplete="off" type="number" nz-input placeholder="0.00">
                                                </nz-input-group>
                                            </nz-form-control>
                                        </nz-form-item>

                                        <nz-form-item
                                            *ngIf="shippingForm.get('shippingMethod').value != true || shippingForm.get('appliesTo').value != 'all-carts'">
                                            <nz-form-label [nzSm]="24" class="large_label" nzFor="">
                                                Shipping Cost
                                            </nz-form-label>
                                            <nz-form-control [nzSm]="24" nzErrorTip="Please Enter Shipping Cost!">
                                                <nz-input-group>
                                                    <input min="0" formControlName="deliveryCharges" autocomplete="off"
                                                        type="number" nz-input placeholder="0.00">
                                                </nz-input-group>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                </div>
                            </form>
                        </div>


                        <!-- discount -->
                        <div *ngIf="selectedSetting == 'discount'" class="mov_section">
                            <form [formGroup]="discountForm" (ngSubmit)="addDiscount(true)" nz-form>
                                <div class="setting_right_heading">
                                    <h5>Discount</h5>
                                    <button type="submit" nz-button nzType="primary" class="create-button">
                                        <span>Next</span>
                                    </button>
                                </div>

                                <div class="">
                                    <nz-form-item>
                                        <nz-form-label [nzSm]="24" class="large_label" nzFor="">
                                            Discount Method
                                        </nz-form-label>
                                        <nz-form-control [nzSm]="24" nzErrorTip="Please Select Discount Method!">
                                            <nz-input-group>
                                                <nz-select formControlName="isAllowed" class="w-100 "
                                                    nzPlaceHolder="Select Discount Method">
                                                    <nz-option [nzLabel]="'No Discount'" [nzValue]="false"></nz-option>
                                                    <nz-option [nzLabel]="'Provide Discount'"
                                                        [nzValue]="true"></nz-option>
                                                </nz-select>
                                            </nz-input-group>
                                        </nz-form-control>
                                    </nz-form-item>

                                    <nz-form-item *ngIf="discountForm.get('isAllowed').value == true">
                                        <nz-form-label [nzSm]="24" class="large_label" nzFor="">
                                            Applies To
                                        </nz-form-label>
                                        <nz-form-control [nzSm]="24" nzErrorTip="Please Select Applies To!">
                                            <nz-input-group>
                                                <nz-select formControlName="appliesTo" class="w-100 "
                                                    nzPlaceHolder="Select Applies To">
                                                    <nz-option [nzLabel]="'All Carts'"
                                                        [nzValue]="'all-carts'"></nz-option>
                                                    <nz-option [nzLabel]="'Carts Of Value Above'"
                                                        [nzValue]="'cart-value-above'"></nz-option>
                                                </nz-select>
                                            </nz-input-group>
                                        </nz-form-control>
                                    </nz-form-item>

                                    <nz-form-item
                                        *ngIf="discountForm.get('appliesTo').value == 'cart-value-above' && discountForm.get('isAllowed').value == true">
                                        <nz-form-label [nzSm]="24" class="large_label" nzFor="">
                                            Carts Of Value
                                        </nz-form-label>
                                        <nz-form-control [nzSm]="24" nzErrorTip="Please Enter Carts Of Value!">
                                            <nz-input-group>
                                                <input min="0" formControlName="cartValueAbove" autocomplete="off"
                                                    type="number" nz-input placeholder="0.00">
                                            </nz-input-group>
                                        </nz-form-control>
                                    </nz-form-item>

                                    <nz-form-item *ngIf="discountForm.get('isAllowed').value == true">
                                        <nz-form-label [nzSm]="24" class="large_label" nzFor="">
                                            Discount Value
                                        </nz-form-label>
                                        <nz-form-control [nzSm]="24" nzErrorTip="Please Enter Discount Value!">
                                            <nz-input-group>
                                                <input min="0" formControlName="discountValue" autocomplete="off"
                                                    type="number" nz-input placeholder="0.00">
                                            </nz-input-group>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                            </form>
                        </div>

                        <!-- payments -->
                        <div *ngIf="selectedSetting == 'paymentOptions'" class="mov_section">
                            <form [formGroup]="paymentsForm" (ngSubmit)="addPayment()" nz-form>
                                <div class="setting_right_heading">
                                    <h5>Payment Options</h5>
                                    <button [nzLoading]="settingUpdating" type="submit" nz-button nzType="primary"
                                        class="create-button">
                                        <span>Update</span>
                                    </button>
                                </div>

                                <div class="">
                                    <nz-form-item>
                                        <nz-form-label [nzSm]="24" class="large_label" nzFor="">
                                            Payment Options
                                        </nz-form-label>
                                        <nz-form-control [nzSm]="24" nzErrorTip="Please Select Payment Options!">
                                            <nz-input-group>
                                                <nz-select nzMode="multiple" formControlName="paymentsMethod"
                                                    class="w-100 " nzPlaceHolder="Select Payment Options">
                                                    <nz-option [nzLabel]="'Cash On Delivery'"
                                                        [nzValue]="'cash-on-delivery'"></nz-option>
                                                    <nz-option [nzLabel]="'Online Payment'"
                                                        [nzValue]="'online-payment'"></nz-option>
                                                </nz-select>
                                            </nz-input-group>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                            </form>
                        </div>

                    </nz-card>
                </div>
            </div>
        </div>

        <div *ngIf="selectedTab == 'sets'" class="h-100">
            <div class="empty_div">
                <div class="mb-3">
                    <img src="../../../../assets/images/commerce/add_catalogue.svg" alt="">
                </div>
                <div class="text-center connect_catalogue">
                    <h5>You Need To Connect The Catelogue To View The Sets</h5>
                    <a (click)="openCatalogueModal()" nz-button nzType="primary" class="create-button mt-3">
                        <i nz-icon nzType="plus-circle" theme="outline"></i>
                        <span>Add Catalogue</span>
                    </a>
                </div>
            </div>
        </div>

        <div *ngIf="selectedTab == 'messages'" class="">
            <app-commerce-messages></app-commerce-messages>
        </div>

        <ngx-spinner *ngIf="pageLoader" bdColor="rgb(255,255,255)" size="medium" color="#000000"
            type="ball-clip-rotate-pulse" [fullScreen]="false">
        </ngx-spinner>

    </div>
</div>

<!-- create catalogue -->

<div *ngIf="catalogueForm" class="row">
    <div class="col-12">
        <h3><span class="back_icon" (click)="showCatalogueTable('catalogue')" nz-icon nzType="left"
                nzTheme="outline"></span> {{ editOption == "edit" ? 'Update' : 'Create'}} Catalogues
        </h3>
    </div>

    <div class="col-12 mt-2">
        <app-create-catalogue [editCatalogue]="editOption" [editdata]="editCatalogueData"
            (showTable)="showCatalogueTable('create')"></app-create-catalogue>
    </div>
</div>

<!-- add catalogue modal -->

<nz-modal [(nzVisible)]="catalogueModal" [nzFooter]="null"
    nzTitle="Follow these steps to connect your WhatsApp catalog to OwnChat" nzCentered
    (nzOnCancel)="closeCatalogueModal()">
    <ng-container *nzModalContent>

        <ul class="catalogue_list">
            <li class="catalogue_list_li">
                <div class="list_name">
                    <div class="">
                        <h4>1.</h4>
                    </div>
                    <div class="">
                        <p>Set up FB Catalog & Collections
                            <span (click)="showVideoModal('https://www.youtube.com/embed/iZCeYAn51YI')"
                                class="play_icon">
                                <img src="../../../../assets/images/logo/play_iocn.png" alt="">
                            </span>
                        </p>
                        <span class="link_ref"><span class="normal_ref">via</span> Google Sheets</span>
                    </div>
                </div>
                <div class="">
                    <a href="https://business.facebook.com/commerce" target="_blank" nz-button nzType="primary"
                        class="create-button">
                        <span>Go to FB</span>
                    </a>
                </div>
            </li>

            <li class="catalogue_list_li">
                <div class="list_name">
                    <div class="">
                        <h4>2.</h4>
                    </div>
                    <div class="">
                        <p>Give Catalog Access to Ieyal Solutions
                            <span (click)="showVideoModal('https://www.youtube.com/embed/j5AYpkPT2JE')"
                                class="play_icon">
                                <img src="../../../../assets/images/logo/play_iocn.png" alt="">
                            </span>
                        </p>
                        <span class="normal_ref">Add Ieyal Solutions as Catalog Partner</span>
                        <br>
                        <span class="normal_ref">Ieyal Solutions Business Manager ID : 114226810352664
                            <i [nzTooltipTitle]="copyText" nzTooltipPlacement="top" nz-tooltip class="copy_url"
                                (click)="copyId('114226810352664')" nz-icon nzType="copy" nzTheme="outline"></i>
                        </span>
                    </div>
                </div>
                <div class="">
                    <a href="https://business.facebook.com/settings/" target="_blank" nz-button nzType="primary"
                        class="create-button">
                        <span>Go to FB</span>
                    </a>
                </div>
            </li>

            <li class="catalogue_list_li">
                <div class="list_name">
                    <div class="">
                        <h4>3.</h4>
                    </div>
                    <div class="">
                        <p>Connect your Catalog to your WhatsApp account
                            <span (click)="showVideoModal('https://www.youtube.com/embed/lTgDCtpilI4')"
                                class="play_icon">
                                <img src="../../../../assets/images/logo/play_iocn.png" alt="">
                            </span>
                        </p>

                        <span class="link_ref"><span class="normal_ref">Go</span>
                            <a href="https://business.facebook.com/settings/" target="_blank"> here</a>
                        </span>
                        <span class="link_ref">
                            <span class="normal_ref">
                                and follow the steps shown in the
                            </span>
                            <span (click)="showVideoModal('https://www.youtube.com/embed/lTgDCtpilI4')"> video</span>
                        </span>
                    </div>
                </div>
                <div class="">
                    <a href="https://business.facebook.com/settings/" target="_blank" nz-button nzType="primary"
                        class="create-button">
                        <span>Go to FB</span>
                    </a>
                </div>
            </li>

            <li class="catalogue_list_li">
                <div class="list_name">
                    <div class="">
                        <h4>4.</h4>
                    </div>
                    <div class="">
                        <p>Enter Facebook Catalog ID</p>
                        <span class="normal_ref">We will fetch products from this catalog</span>
                    </div>
                </div>
                <div class="">
                    <nz-input-group class="" [nzSuffix]="suffixTemplate">
                        <input autocomplete="off" [(ngModel)]="catalogueId" [type]="'text'" nz-input
                            placeholder="catalogue id" />
                    </nz-input-group>
                    <ng-template #suffixTemplate>
                        <span [ngClass]="{'disabled_tag' : !catalogueId || connecting}" (click)="connectCatalogue()"
                            class="link_ref">connect</span>
                    </ng-template>
                </div>
            </li>
        </ul>

    </ng-container>
</nz-modal>

<!-- video modal -->

<nz-modal [nzBodyStyle]="{'padding' : '0px'}" [(nzVisible)]="videoModal" nzTitle="" nzCentered nzWidth="auto"
    [nzClosable]="false" [nzFooter]="null" (nzOnCancel)="closeVideoModal()">
    <ng-container *nzModalContent>

        <div class="video_cover">
            <iframe width="560" height="315" [src]="videoLink" title="YouTube video player" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen></iframe>
            <!-- <iframe width="560" height="315" src="https://www.youtube.com/embed/c8vofXytweE"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen></iframe> -->
        </div>

    </ng-container>
</nz-modal>