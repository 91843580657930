import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  AngularEditorComponent,
  AngularEditorConfig,
} from "@kolkov/angular-editor";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { CommonApiService } from "src/app/services/common-api.service";
import { environment } from "src/environments/environment";
import * as moment from "moment";
import { AlertServiceService } from "src/app/services/alert-service.service";
import { NzModalService } from "ng-zorro-antd/modal";

@Component({
  selector: "app-canned-response",
  templateUrl: "./canned-response.component.html",
  styleUrls: ["./canned-response.component.css"],
})
export class CannedResponseComponent implements OnInit {
  moment: any = moment;
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "15rem",
    minHeight: "5rem",
    placeholder: "Enter your message here..",
    translate: "no",
    defaultParagraphSeparator: "p",
    defaultFontName: "",
    toolbarHiddenButtons: [["list", ""]],
  };

  set = "twitter";

  orderColumn: any = [
    {
      title: "Channel",
      width: "50px",
    },
    {
      title: "Name",
      width: "100px",
    },
    {
      title: "Content",
      width: "100px",
    },
    // {
    //   title: "Attached File",
    //   width: "60px",
    // },
    {
      title: "Created Date & Time",
      width: "90px",
    },
    {
      title: "Action",
      width: "50px",
    },
  ];

  cannedResponseData: any = [];

  showForm: boolean;

  addResponseForm!: FormGroup;
  messageContent: string = "";

  editOption: boolean;
  submitting: boolean = false;
  editData: any = {};

  // search and pagination

  searchKey: any = "";

  pageIndex = 1;
  totalCount: number;
  pageSize: number;

  @Input() mainUserData: any;
  tableHeight: any = "";

  pageReloading: boolean = false;

  emojiVisible: boolean = false;

  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private apiService: CommonApiService,
    private spinner: NgxSpinnerService,
    private alertService: AlertServiceService,
    private modal: NzModalService
  ) {}

  @ViewChild("bodyText") bodyText: ElementRef;

  ngOnInit(): void {
    if (this.mainUserData?.accessTo?.sandboxId) {
      this.tableHeight = "56vh";
    } else {
      this.tableHeight = "65vh";
    }

    this.fetchData();
    this.addResponseForm = this.fb.group({
      name: ["", [Validators.required]],
      // channelType: ["", [Validators.required]],
      message: ["", [Validators.required]],
    });
  }

  fetchData() {
    this.spinner.show();
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url +
          "quick-message?page=" +
          this.pageIndex +
          "&limit=" +
          this.pageSize,
        { filterValue: this.searchKey },
        options
      )
      .subscribe(
        (response: any) => {
          // console.log(response);
          this.cannedResponseData = response?.data?.quickMessages;
          this.totalCount = response?.data?.pagination?.totalCount;
          this.pageSize = response?.data?.pagination?.limit;
          this.spinner.hide();
          this.pageReloading = false;
        },
        (error) => {
          console.log(error);
          this.spinner.hide();
          this.pageReloading = false;
        }
      );
  }

  showChannelForm() {
    if (!this.mainUserData?.accessTo?.isExpired) {
      this.addResponseForm.reset();
      this.showForm = true;
      this.editOption = false;
    }
  }

  showChannelTable() {
    this.showForm = false;
  }

  addVariable(variable: any) {
    // this.messageContent = this.messageContent.concat("{" + variable + "}");
    if (this?.addResponseForm?.value?.message) {
      const inputElement = this.bodyText.nativeElement;
      const cursorPos = inputElement.selectionStart;
      const currentValue = this.addResponseForm.value.message;
      const newValue = `${currentValue.slice(0, cursorPos)}${
        "{" + variable + "}"
      }${currentValue.slice(cursorPos)}`;
      this.addResponseForm.get("message").setValue(newValue);
      inputElement.setSelectionRange(cursorPos + 1, cursorPos + 1);
    } else {
      this.addResponseForm.get("message").setValue("{" + variable + "}");
    }
  }

  editResponse(item: any) {
    if (this.mainUserData?.accessTo?.isExpired) {
      this, this.alertService.warning();
    } else {
      this.addResponseForm.get("name").setValue(item.name);
      this.addResponseForm
        .get("message")
        .setValue(this.reverse_format_text(item.message));
      this.editOption = true;
      this.editData = item;
      this.showForm = true;
    }
  }

  createUpdateMessage() {
    if (this.addResponseForm.valid) {
      this.submitting = true;
      let options = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
        },
      };

      var data: any = {};
      var url = "";

      if (this.editOption) {
        url = "quick-message/update";

        data.quickMessagesId = this.editData._id;
        data.name = this.addResponseForm.value.name;
        data.message = this.format_text(this.addResponseForm.value.message);
      } else {
        url = "quick-message/create";

        data.name = this.addResponseForm.value.name;
        data.message = this.format_text(this.addResponseForm.value.message);
      }

      this.apiService
        .commonPostMethod(environment.url + url, data, options)
        .subscribe(
          (response: any) => {
            // console.log(response);
            if (response.status == "success") {
              this.toastr.success(response.message, "", {
                timeOut: 2500,
              });
              this.pageIndex = 1;
              this.fetchData();
              this.submitting = false;
              this.showForm = false;
              this.addResponseForm.reset();
            }
          },
          (error) => {
            this.submitting = false;
            console.log(error);
          }
        );
    } else {
      Object.values(this.addResponseForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  deleteAlert(item: any) {
    this.modal.confirm({
      nzTitle: "Sure you want to delete this Message!.",
      nzOkText: "Yes",
      nzCancelText: "No",
      nzOnOk: () => {
        this.deleteMessage(item);
      },
    });
  }

  deleteMessage(item: any) {
    if (this.mainUserData?.accessTo?.isExpired) {
      this, this.alertService.warning();
    } else {
      let options = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
        },
      };

      var data: any = {
        quickMessagesId: item._id,
      };
      var url = "quick-message/delete";

      this.apiService
        .commonPostMethod(environment.url + url, data, options)
        .subscribe(
          (response: any) => {
            // console.log(response);
            if (response.status == "success") {
              this.toastr.success(response.message, "", {
                timeOut: 2500,
              });
              if (this.cannedResponseData?.length == 1 && this.pageIndex != 1) {
                this.pageIndex = this.pageIndex - 1;
              }
              this.fetchData();
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }

  reverseFormatText(text) {
    return text
      .replaceAll("<p>", "")
      .replaceAll("</p>", "")
      .replaceAll("&#160;", " ")
      .replaceAll("&#10;", " ")
      .replaceAll("<br>", "\n")
      .replaceAll("<span>", "")
      .replaceAll("</span>", "");
  }

  FormatText(text) {
    return text.replaceAll("\n", "<br>");
  }

  format_text(text: any) {
    return text
      .replace(/(?:\*)(?:(?!\s))((?:(?!\*|\n).)+)(?:\*)/g, "<b>$1</b>")
      .replace(/(?:_)(?:(?!\s))((?:(?!\n|_).)+)(?:_)/g, "<i>$1</i>")
      .replace(/(?:~)(?:(?!\s))((?:(?!\n|~).)+)(?:~)/g, "<s>$1</s>")
      .replace(/(?:--)(?:(?!\s))((?:(?!\n|--).)+)(?:--)/g, "<u>$1</u>")
      .replace(/(?:```)(?:(?!\s))((?:(?!\n|```).)+)(?:```)/g, "<tt>$1</tt>");
  }

  reverse_format_text(text: any) {
    return text
      .replaceAll("<b>", "*")
      .replaceAll("</b>", "*")
      .replaceAll("<p>", "")
      .replaceAll("</p>", "")
      .replaceAll("<i>", "_")
      .replaceAll("</i>", "_")
      .replaceAll("<em>", "_")
      .replaceAll("</em>", "_")
      .replaceAll("<strike>", "~")
      .replaceAll("</strike>", "~")
      .replaceAll("&#160;", "")
      .replaceAll("&#10;", "")
      .replaceAll("<br>", "\n")
      .replaceAll("<span>", "")
      .replaceAll("</span>", "");
  }

  onPageNumberChange(id: any, event: any) {
    if (this.pageIndex != event.pageIndex) {
      this.pageIndex = event.pageIndex;
      this.fetchData();
      document.getElementById(id).scrollIntoView();
    }
  }

  searchContacts() {
    this.pageIndex = 1;
    this.fetchData();
  }

  reloadPage() {
    this.pageReloading = true;
    this.searchKey = "";
    this.pageIndex = 1;
    this.fetchData();
  }

  changePageSize(size: any) {
    this.pageSize = size;
    this.fetchData();
  }

  setMyStyles() {
    let styles = {
      "margin-right":
        135 + (this.totalCount / this.pageSize).toFixed(0).length * 5 + "px",
    };
    return styles;
  }

  showEmoji() {
    this.emojiVisible = !this.emojiVisible;
  }

  // custom editor section

  selectedStyle: string = "";

  addEmoji(selected: any) {
    // console.log(selected.emoji.native);
    const editorElement = this.bodyText.nativeElement;
    const currentValue = editorElement.value;
    const selectionStart = editorElement.selectionStart;
    const selectionEnd = editorElement.selectionEnd;

    const newValue =
      currentValue.substring(0, selectionStart) +
      selected.emoji.native +
      currentValue.substring(selectionEnd);

    editorElement.value = newValue;
    // editorElement.focus();

    const newCursorPosition = selectionStart + selected.emoji.native.length;
    editorElement.setSelectionRange(newCursorPosition, newCursorPosition);
  }

  onTextSelect() {
    this.selectedStyle = "";
    const editorElement = this.bodyText.nativeElement;
    const selectedText = editorElement.value.substring(
      editorElement.selectionStart,
      editorElement.selectionEnd
    );

    const boldRegex = /^\*(.*?)\*$/;
    const strikeRegex = /^~(.*)~$/;
    const italicRegex = /^_(.*)_$/;

    if (boldRegex.test(selectedText)) {
      this.selectedStyle = "Bold";
    }

    if (strikeRegex.test(selectedText)) {
      this.selectedStyle = "Strike";
    }

    if (italicRegex.test(selectedText)) {
      this.selectedStyle = "Italic";
    }
  }

  bodyTextFocus() {
    this.emojiVisible = false;
    this.selectedStyle = "";
  }

  mouseUp() {
    this.selectedStyle = "";
  }

  editText(style: any) {
    const editorElement = this.bodyText.nativeElement;
    const selectedText = editorElement.value.substring(
      editorElement.selectionStart,
      editorElement.selectionEnd
    );
    const boldRegex = /^\*(.*?)\*$/;
    const strikeRegex = /^~(.*)~$/;
    const italicRegex = /^_(.*)_$/;

    if (style == "bold") {
      if (!boldRegex.test(selectedText)) {
        this.selectedStyle = "Bold";
        const newValue = `${editorElement.value.substring(
          0,
          editorElement.selectionStart
        )}*${selectedText}*${editorElement.value.substring(
          editorElement.selectionEnd
        )}`;
        // editorElement.value = newValue;
        this.addResponseForm.get("message").setValue(newValue);
      } else {
        this.selectedStyle = "";
        const newValue = `${editorElement.value.substring(
          0,
          editorElement.selectionStart
        )}${selectedText.substring(
          1,
          selectedText.length - 1
        )}${editorElement.value.substring(editorElement.selectionEnd)}`;
        // editorElement.value = newValue;
        this.addResponseForm.get("message").setValue(newValue);
      }
    }

    if (style == "italic") {
      if (!italicRegex.test(selectedText)) {
        this.selectedStyle = "Italic";
        const newValue = `${editorElement.value.substring(
          0,
          editorElement.selectionStart
        )}_${selectedText}_${editorElement.value.substring(
          editorElement.selectionEnd
        )}`;
        // editorElement.value = newValue;
        this.addResponseForm.get("message").setValue(newValue);
      } else {
        this.selectedStyle = "";
        const newValue = `${editorElement.value.substring(
          0,
          editorElement.selectionStart
        )}${selectedText.substring(
          1,
          selectedText.length - 1
        )}${editorElement.value.substring(editorElement.selectionEnd)}`;
        // editorElement.value = newValue;
        this.addResponseForm.get("message").setValue(newValue);
      }
    }

    if (style == "strikethrough") {
      if (!strikeRegex.test(selectedText)) {
        this.selectedStyle = "Strike";
        const newValue = `${editorElement.value.substring(
          0,
          editorElement.selectionStart
        )}~${selectedText}~${editorElement.value.substring(
          editorElement.selectionEnd
        )}`;
        // editorElement.value = newValue;
        this.addResponseForm.get("message").setValue(newValue);
      } else {
        this.selectedStyle = "";
        const newValue = `${editorElement.value.substring(
          0,
          editorElement.selectionStart
        )}${selectedText.substring(
          1,
          selectedText.length - 1
        )}${editorElement.value.substring(editorElement.selectionEnd)}`;
        // editorElement.value = newValue;
        this.addResponseForm.get("message").setValue(newValue);
      }
    }

    // editorElement.focus();

    // const startPos = editorElement.selectionStart + 1;
    // const endPos = startPos + selectedText.length;

    // editorElement.setSelectionRange(startPos, endPos);
  }

  onPaste(event: any) {
    const clipboardData = event.clipboardData;
    if (clipboardData) {
      const pastedText = clipboardData.getData("text");
      const formattedText = this.reverse_format_text(pastedText);
      // console.log("Formatted text:", formattedText);

      // Modify the textarea value to display the formatted text
      const textarea = event.target as HTMLTextAreaElement;
      const currentText = textarea.value;
      const selectionStart = textarea.selectionStart;
      const selectionEnd = textarea.selectionEnd;
      const newText =
        currentText.slice(0, selectionStart) +
        formattedText +
        currentText.slice(selectionEnd);
      // textarea.value = newText;
      this.addResponseForm.get("message").setValue(newText);

      // Prevent the default paste behavior
      event.preventDefault();
    }
  }
}
