<div class="row main_row" [ngClass]="{'modal_design_row' : !sandboxPage}">
    <div class="col-lg-8" [ngClass]="{'col-12 modal_design' : !sandboxPage}">
        <div class="row">
            <div class="col-12 mb-5 heading_column text-center">
                <h3>Check your Whatsapp to send the first message</h3>
            </div>
            <div class="col-lg-6">
                <div class="direct_message_section">
                    <div class="whatsapp_image">
                        <img src="../../../assets/images/logo/icons8-whatsapp.svg" alt="">
                    </div>
                    <div class="text-center">
                        <h5>Send a Message</h5>
                        <p>Send us a WhatsApp message on +917904159394 by saving in your contacts and message should
                            start
                            with
                            #0AA9!
                        </p>
                    </div>
                    <div class="">
                        <a target="_blank" [href]='"https://wa.me/+917904159394?text=" +
                        this.sandBoxCode +
                        "  was the sandbox code."' nz-button nzType="" class="create-button whatsapp_button">
                            <span>WhatsApp </span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="qr_message_section">
                    <div class="qr_code_div">
                        <!-- <img src="../../../assets/images/logo/icons8-whatsapp.svg" alt=""> -->
                        <qrcode-svg class="qr_code" [value]="'https://api.whatsapp.com/send?phone=917904159394&text=' +
                        this.sandBoxCode" [color]="'#000'">
                        </qrcode-svg>
                    </div>
                    <div class="text-center">
                        <h5>Scan the QR Code</h5>
                        <p>Your phone will ope a pre-filled message to send to our sandbox number. Hit 'send' on
                            Whatsapp
                        </p>
                    </div>
                    <!-- <div class="">
                        <a nz-button nzType="primary" class="create-button">
                            <span>QR Code</span>
                        </a>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>