import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { environment } from "src/environments/environment";
import { NavService } from "./nav.service";
import axios, { Axios } from "axios";

@Injectable({
  providedIn: "root",
})
export class RoleProtectionService {
  constructor(private route: Router, private navService: NavService) {}

  mainUserData: any;

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };
    if (localStorage.getItem("skjhfruibsndbvmnvckbhdsuifhbd") == "teammate") {
      this.route.navigate(["/chat"]);
      return false;
    } else if (
      localStorage.getItem("skjhfruibsndbvmnvckbhdsuifhbd") == "owner" ||
      localStorage.getItem("skjhfruibsndbvmnvckbhdsuifhbd") == "admin"
    ) {
      return true;
    } else if (localStorage.getItem("skjhfruibsndbvmnvckbhdsuifhbd") == null) {
      this.route.navigate(["/login"]);
      return false;
    }
  }
}
