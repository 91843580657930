<div class="row top_main_row">
    <div class="col-12 main_outer_heading">
        <h3>Subscription</h3>
    </div>

    <div class="col-12 subscription_options">
        <div class="row">
            <div class="col-12 d-flex justify-content-center">
                <div class="subscription_types">
                    <div (click)="changeSubscription('monthly')"
                        [ngClass]="{'subscription-selected' : selectedSubs == 'monthly'}" class="subscription">
                        <h5>Monthly</h5>
                    </div>
                    <div (click)="changeSubscription('quarterly')"
                        [ngClass]="{'subscription-selected' : selectedSubs == 'quarterly'}" class="subscription">
                        <h5>Quarterly <span>10% off</span></h5>
                    </div>
                    <div (click)="changeSubscription('yearly')"
                        [ngClass]="{'subscription-selected' : selectedSubs == 'yearly'}" class="subscription">
                        <h5>Yearly<span>20% off</span></h5>
                    </div>
                </div>
            </div>

            <div class="col-lg-4">
                <nz-card class="subscription_card">
                    <div class="">
                        <h3>Standard</h3>
                        <p>Unlock sales potential and streamline customer interactions for small businesses</p>
                        <h2 *ngIf="selectedSubs == 'monthly'">
                            {{(locationData?.country == 'IN' || !locationData?.country) ? 'Rs.2700' : '$66.00'}}
                        </h2>
                        <h2 *ngIf="selectedSubs == 'quarterly'">
                            {{(locationData?.country == 'IN' || !locationData?.country) ? 'Rs.2430 ' : '$59.00'}}
                        </h2>
                        <h2 *ngIf="selectedSubs == 'yearly'">
                            {{(locationData?.country == 'IN' || !locationData?.country) ? 'Rs.2160' : '$53.00'}}
                        </h2>
                        <p class="sub_text">(per month paid {{selectedSubs}})</p>
                        <a [nzLoading]="planType == 'standard' && submitting" (click)="choosePlan('standard')" nz-button
                            nzType="primary" class="create-button">
                            <span>Choose Plan</span>
                        </a>

                        <div class="feature_list">
                            <ul class="check-list-2">
                                <li>
                                    <p>WhatsApp API approval and setup</p>
                                </li>
                                <li>
                                    <p>Upto 3 Users</p>
                                </li>
                                <li>
                                    <p>Unlimited Contacts management</p>
                                </li>
                                <li>
                                    <p>Unlimited Advanced segmentation</p>
                                </li>
                                <li>
                                    <p>Unlimited tags and custom fields</p>
                                </li>
                                <li>
                                    <p>Campaign Scheduler</p>
                                </li>
                                <li>
                                    <p>Broadcast Upto 10k Messages / Day (Based on their tier limit.)</p>
                                </li>
                                <li>
                                    <p>Conversation analytics</p>
                                </li>
                                <li>
                                    <p>Task management</p>
                                </li>
                                <li>
                                    <p>3 conversational bots</p>
                                </li>
                                <li>
                                    <p>48-hour SLA</p>
                                </li>
                                <li>
                                    <p>WhatsApp Store upto 20 Products</p>
                                </li>
                                <li>
                                    <p>E-commerce chatbot</p>
                                </li>
                                <li>
                                    <p>Dashboard</p>
                                </li>
                                <li>
                                    <p>Abandoned cart report</p>
                                </li>
                                <li>
                                    <p>Order management</p>
                                </li>
                                <li>
                                    <p>Rate Limit - 100 API calls/min</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nz-card>
            </div>

            <div class="col-lg-4">
                <nz-card class="subscription_card">
                    <div class="">
                        <h3>Advanced</h3>
                        <p>integrated workflows and efficient team collaborations for mid-size companies</p>
                        <h2 *ngIf="selectedSubs == 'monthly'">
                            {{(locationData?.country == 'IN' || !locationData?.country) ? 'Rs.4700' : '$114.00'}}
                        </h2>
                        <h2 *ngIf="selectedSubs == 'quarterly'">
                            {{(locationData?.country == 'IN' || !locationData?.country) ? 'Rs.4230' : '$103.00'}}
                        </h2>
                        <h2 *ngIf="selectedSubs == 'yearly'">
                            {{(locationData?.country == 'IN' || !locationData?.country) ? 'Rs.3760' : '$91.00'}}
                        </h2>
                        <p class="sub_text">(per month paid {{selectedSubs}})</p>
                        <a [nzLoading]="planType == 'advanced' && submitting" (click)="choosePlan('advanced')" nz-button
                            nzType="primary" class="create-button">
                            <span>Choose Plan</span>
                        </a>


                        <div class="feature_list">
                            <ul class="check-list-2">
                                <li>
                                    <p>WhatsApp API approval and setup</p>
                                </li>
                                <li>
                                    <p>Shared Team Inbox with 5 User Login</p>
                                </li>
                                <li>
                                    <p>Unlimited Contacts management</p>
                                </li>
                                <li>
                                    <p>Unlimited Advanced segmentation</p>
                                </li>
                                <li>
                                    <p>Unlimited tags and custom fields</p>
                                </li>
                                <li>
                                    <p>Campaign scheduler</p>
                                </li>
                                <li>
                                    <p>Broadcast Upto 100k Messages</p>
                                </li>
                                <li>
                                    <p>Conversation analytics</p>
                                </li>
                                <li>
                                    <p>Task management</p>
                                </li>
                                <li>
                                    <p>5 Advanced bots</p>
                                </li>
                                <li>
                                    <p>48-hour SLA</p>
                                </li>
                                <li>
                                    <p>WhatsApp Store upto 100 Products</p>
                                </li>
                                <li>
                                    <p>E-commerce chatbot</p>
                                </li>
                                <li>
                                    <p>Dashboard</p>
                                </li>
                                <li>
                                    <p>Abandoned cart report</p>
                                </li>
                                <li>
                                    <p>Order management</p>
                                </li>
                                <li>
                                    <p>Shopify, Google Sheet Integration.</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nz-card>
            </div>

            <div class="col-lg-4">
                <nz-card class="subscription_card">
                    <div class="">
                        <h3>Enterprise</h3>
                        <p>Everything in advanced Plan + Higher Api Limits and Tailored features.</p>
                        <a href="https://booknow.ownchat.app/" target="_blank" nz-button nzType="primary"
                            class="create-button">
                            <span>Talk to sales</span>
                        </a>

                        <div class="feature_list">
                            <ul class="check-list-2">
                                <li>
                                    <p>No additional charges</p>
                                </li>
                                <li>
                                    <p>No markup charges</p>
                                </li>
                                <li>
                                    <p>Faster Message Delivery Speed</p>
                                </li>
                                <li>
                                    <p>Customer Success Manager.</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nz-card>
            </div>

            <div *ngIf="menuOptions.createPortalSessionButton" class="col-12 portal_session_card">
                <nz-card class="sub_details_card">
                    <div class="">
                        <div class="subs-details">
                            <h5>You can check your previous invoices by click the following button.</h5>
                        </div>
                        <a [nzLoading]="reDirecting" (click)="toDetails()" nz-button class="mt-3 details_btn">
                            <span>View Past Invoice</span>
                        </a>
                    </div>
                </nz-card>

            </div>

            <!-- <div class="col-12">
                <h3>Invoice History</h3>
                <nz-card class="">
                    <nz-table class="contacts_table main_contacts_table mb-3" #invoiceTable [nzData]="invoiceHistory"
                        [nzScroll]="{ y: '500px', x: '900px' }" [nzFrontPagination]="false" [nzPageSize]="1000">
                        <thead>
                            <tr>
                                <th *ngFor="let column of orderColumn">
                                    {{ column.title }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr id="categoryTable" *ngFor="let item of invoiceTable.data; let i = index">
                                <td>{{item.date}}</td>
                                <td>{{item.amount}}</td>
                                <td>
                                    <span [ngClass]="{ 'un-published': item?.status != 'paid' }" class="published">
                                        {{ item?.status == 'paid' ? "Paid" : "Failed" }}</span>
                                </td>
                                <td>{{item.description}}</td>
                            </tr>
                        </tbody>
                    </nz-table>
                </nz-card>
            </div> -->
        </div>
    </div>

    <!-- <div *ngIf="(subData?.isExpired && mainUserData?.accessTo?.curSubsPlan) || !mainUserData?.accessTo?.curSubsPlan"
        class="col-12 stripe_design">
        <stripe-pricing-table pricing-table-id="prctbl_1NITidSCmKSyR90T86npKpos"
            publishable-key="pk_test_51MYthjSCmKSyR90TjT9nDr5yB7GUf3hPVML7JYxX2n58fevmIkBENjOqtSVEu07r9TYIS6XZJ980h8eXI5yywbRo00UkJ4Lhqy"
            [attr.client-reference-id]="mainUserData?.accessTo?._id">
        </stripe-pricing-table>
    </div> -->


    <!-- <div *ngIf="(mainUserData?.accessTo?.curSubsPlan && !subData?.isExpired)" class="col-lg-5 sub_banner_col mt-5">
        <nz-card class="sub_details_card">
            <div class="">
                <h2 class="">Great news!</h2>
                <div class="subs-details">
                    <h5>You have an active <br> subscription with us.</h5>
                </div>
                <a [nzLoading]="reDirecting" (click)="toDetails()" nz-button class="mt-2 details_btn">
                    <span>View Details</span>
                </a>
            </div>
        </nz-card>
    </div> -->

    <!-- <div class="col-12 mt-5">
        <iframe class="subscription_card" src='https://booknow.ownchat.app/portal-embed#/customer/ownchat'
            frameborder='0' allowfullscreen=''> </iframe>
    </div> -->

    <ngx-spinner bdColor="rgb(255,255,255)" size="medium" color="#000000" type="ball-clip-rotate-pulse"
        [fullScreen]="false">
    </ngx-spinner>
</div>


<!-- <img src="../../../assets/images/icons/check.svg" alt=""> -->

<!-- *ngIf="(subData.isExpired && mainUserData.accessTo.curSubsPlan) || !mainUserData.accessTo.curSubsPlan" -->

<!-- mainUserData?.accessTo?._id   line 210 -->

<!-- line 7 & 2  *ngIf="(subData?.isExpired && mainUserData?.accessTo?.curSubsPlan) || !mainUserData?.accessTo?.curSubsPlan" -->

<!-- line 1 *ngIf="subData" -->