<div class="row top_row overflow_row_mobile" [ngClass]="{'detail_row' : showDetail}">
    <div class="col-12 main_outer_heading mb-3 col_left_spacing">
        <h3>
            Apps
        </h3>
    </div>

    <div *ngIf="!showDetail" class="col-lg-2 col-12 left_menu col_left_spacing">
        <ul class="main_list">
            <li (click)="selectMenu(menu)" [ngClass]="{'selected_title' : selectedMenu.id == menu.id}"
                class="list_heading" *ngFor="let menu of allMenu">
                {{menu.name}}
            </li>
        </ul>
    </div>

    <div class="col-lg-10 col-12">
        <nz-card class="">
            <div class="apps_right_card">
                <div *ngIf="!showDetail" class="row">
                    <div class="col-12 top_col details_sticky_top">
                        <div class="contacts_top_bar">
                            <div class="table_top_heading">
                                <h4>{{selectedMenu.name}}</h4>
                            </div>
                            <div class="search_add_div">
                                <nz-input-group class="search_input_section" [nzPrefix]="suffixIconSearch">
                                    <input autocomplete="off" [(ngModel)]="searchText" type="text" nz-input
                                        placeholder="Search apps.." />
                                </nz-input-group>
                                <ng-template #suffixIconSearch>
                                    <span nz-icon nzType="search"></span>
                                </ng-template>
                            </div>
                        </div>
                    </div>

                    <div (click)="getDetail(app , 'overview')" class="col-lg-4 mt-3"
                        *ngFor="let app of allApps | filter : searchText">
                        <div class="card_design">
                            <div class="card_top">
                                <img [src]="imageUrl + app.logo" alt="">
                                <div class="">
                                    <h5>{{app?.name}}</h5>
                                    <span class="description_text">
                                        {{app.category}}
                                    </span>
                                </div>
                            </div>
                            <div class="description">
                                <span class="description_text">
                                    {{app.description.length > 130 ? (app.description.slice(0,130) + '...') :
                                    app.description}}
                                </span>
                            </div>
                        </div>
                    </div>

                    <ngx-spinner bdColor="rgb(255,255,255)" size="medium" color="#000000" type="ball-clip-rotate-pulse"
                        [fullScreen]="false">
                    </ngx-spinner>
                </div>

                <div *ngIf="showDetail" class="">
                    <div class="details_sticky_top">
                        <div class="table_top_heading detail_top">
                            <span (click)="backToApps()" nz-icon nzType="left" nzTheme="outline"></span>
                            <h4>{{formFields?.app?.name}}</h4>
                        </div>
                        <div class="detail_menu">
                            <span [ngClass]="{'selected_menu' : detailMenu == 'overview'}"
                                (click)="changeMenu('overview')">Overview</span>
                            <span [ngClass]="{'selected_menu' : detailMenu == 'connections'}"
                                (click)="changeMenu('connections')">Connections</span>
                            <!-- <span *ngIf="webhookId != formFields?.app?._id"
                                [ngClass]="{'selected_menu' : detailMenu == 'events'}"
                                (click)="changeMenu('events')">Events</span> -->
                        </div>
                    </div>

                    <div class="mt-3">
                        <div *ngIf="detailMenu == 'overview'" class="overview">
                            <img [src]="imageUrl + formFields.app.featureImage" alt="">
                            <p>
                                {{formFields.app.description}}
                            </p>
                        </div>

                        <div *ngIf="detailMenu == 'connections'" class="connections">
                            <div class="w-60">
                                <div *ngFor="let connection of formFields.appExistingConnection"
                                    class="all_events all_connections">
                                    <div class="">
                                        <!-- <span>Connection Name</span> -->
                                        <p>{{connection.name}}</p>
                                        <!-- </div> -->

                                        <!-- <div class="">
                                        <div class="">
                                            <span>Connection Name</span> -->
                                        <a (click)="directEdit(connection)" class="secondary_btn"
                                            [ngClass]="{'connected_btn' : connection.isActivated}">
                                            <span>{{connection.isActivated ? 'connected' : 'connect'}}</span>
                                        </a>
                                    </div>
                                    <span nzTrigger="click" class="more_icon" nz-dropdown [nzDropdownMenu]="moreOption"
                                        (click)="updateEditData(connection)" nz-icon nzType="more" nzTheme="outline">
                                    </span>
                                    <!-- </div> -->
                                </div>
                            </div>
                            <button (click)="newConnection()" type="submit" class="sighup_button new_connection"
                                nz-button nzType="primary">
                                + Add a New Connection
                            </button>
                        </div>

                        <div *ngIf="detailMenu == 'events'" class="events">
                            <div *ngFor="let event of formFields.app.availableEvents" class="all_events">
                                <div class="">
                                    <p>{{event.name}}</p>
                                    <span>{{event.description}}</span>
                                </div>
                                <a (click)="selectEvent(event)" class="secondary_btn">
                                    <span>Subscribe</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nz-card>
    </div>
</div>


<nz-drawer [nzWidth]="'50%'" [nzClosable]="true" [nzVisible]="showForm" [nzPlacement]="'right'" [nzTitle]="drawerTitle"
    (nzOnClose)="closeForm()">
    <ng-container *nzDrawerContent>
        <form *ngIf="!webhookUrl && showForm" [formGroup]="connectionForm" (ngSubmit)="addUpdateConnection()" nz-form>
            <nz-form-item>
                <nz-form-label nzRequired class="large_label" [nzSm]="24">
                    Connection Name
                </nz-form-label>

                <nz-form-control [nzSm]="24" [nzXs]="24" [nzErrorTip]="'Please enter connection name'">
                    <nz-input-group>
                        <input formControlName="connectionName" autocomplete="off" nz-input
                            placeholder="Enter connection name" type="text">
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>

            <ng-container *ngIf="genericWebhookUrl">
                <nz-form-control [nzSm]="24" [nzXs]="24">
                    <div nz-row [nzGutter]="8">
                        <div nz-col [nzSpan]="20">
                            <input readonly autocomplete="off" nz-input type="text"
                                [value]="genericWebhookUrl.webhookUrl">
                        </div>
                        <div nz-col [nzSpan]="4" class="d-flex align-items-center">
                            <a (click)="copyUrl(genericWebhookUrl.webhookUrl)" class="secondary_btn">
                                <span>Copy URL</span>
                            </a>
                        </div>
                    </div>
                </nz-form-control>

                <div class="capture">
                    <a nz-button [nzLoading]="responseSampleLoader" (click)="checkResponse()" class="secondary_btn">
                        <span>capture response</span>
                    </a>
                </div>


                <div *ngIf="webhookResponse" class="webhook_res">
                    <div class="res_col">
                        <span>Key</span>
                        <span>Value</span>
                    </div>
                    <div *ngFor="let res of webhookResponse" class="res_col">
                        <input readonly autocomplete="off" nz-input type="text" [value]="res[0]">
                        <textarea readonly nz-input [nzAutosize]="{ minRows: 1, maxRows: 6 }" [value]="res[1]">
                        </textarea>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngFor="let field of loopForm">
                <nz-form-item>
                    <nz-form-label [nzRequired]="field?.required" class="large_label"
                        [nzSm]="field.dataType =='switch' ? '' : 24">
                        {{field.label}}
                    </nz-form-label>

                    <nz-form-control [nzSm]="24" [nzXs]="24" [nzErrorTip]="'Please Enter ' + field.label">
                        <nz-input-group [ngSwitch]="field.type">
                            <input [formControlName]="field.name" *ngSwitchCase="'text'" autocomplete="off" nz-input
                                [placeholder]="'Enter ' + field.label" type="text">
                            <input [formControlName]="field.name" *ngSwitchCase="'password'" autocomplete="new-password"
                                nz-input [placeholder]="'Enter ' + field.label" type="password">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
            </ng-container>



            <nz-form-item class="user_create mt-5">
                <!-- <button (click)="showForm = false" type="button" class="sighup_button" nz-button nzType="default">
                    Back
                </button> -->
                <button [disabled]="genericWebhookUrl?.webhookUrl && !webhookResponse" [nzLoading]="creating"
                    type="submit" class="sighup_button" nz-button nzType="primary">
                    {{isEdit ? 'Update connection' : 'Create connection'}}
                </button>
            </nz-form-item>
        </form>

        <!-- <div *ngIf="!eventSelected && !webhookUrl" class="events_main row">
            <div (click)="selectEvent(event)" *ngFor="let event of formFields?.app?.availableEvents"
                class="col-lg-12 mb-3">
                <div class="events_card w-100">
                    <img [src]="imageUrl + formFields.app.logo" alt="">
                    <div class="">
                        <p>{{event.name}}</p>
                        <span>{{event.description}}</span>
                    </div>
                </div>
            </div>
        </div> -->

        <div class="" *ngIf="webhookUrl">
            <nz-form-item>
                <nz-form-label class="large_label" [nzSm]="24">
                    Configure the webhook URL in your app or QR Code
                </nz-form-label>

                <nz-form-control [nzSm]="24" [nzXs]="24">
                    <div nz-row [nzGutter]="8">
                        <div nz-col [nzSpan]="20">
                            <input readonly autocomplete="off" nz-input type="text" [value]="webhookUrl">
                        </div>
                        <div nz-col [nzSpan]="4" class="d-flex align-items-center">
                            <a (click)="copyUrl(webhookUrl)" class="secondary_btn">
                                <span>Copy URL</span>
                            </a>
                        </div>
                    </div>
                </nz-form-control>
            </nz-form-item>
        </div>
    </ng-container>
</nz-drawer>

<nz-modal [nzFooter]="null" [(nzVisible)]="eventsModal" nzCentered [nzTitle]="'Events Subscribed'"
    (nzOnCancel)="closeEventsModal()">
    <ng-container *nzModalContent>
        <div class="">
            <div class="events modal_events">
                <div *ngFor="let event of formFields.app.availableEvents" class="all_events ">
                    <div class="">
                        <p>{{event.name}}</p>
                        <span>{{event.description}}</span>
                    </div>
                    <a [nzLoading]="subscribeLoader" nz-button (click)="subscribeEvent(event)" class="secondary_btn"
                        [ngClass]="{'normal' : detectSubs(event) == -1}">
                        <span>
                            {{detectSubs(event) == -1 ? 'Subscribe' : 'Subscribed'}}
                        </span>
                    </a>
                </div>
            </div>
        </div>
    </ng-container>
</nz-modal>


<!-- connections more options menu -->
<nz-dropdown-menu #moreOption="nzDropdownMenu">
    <ul nz-menu>
        <li *ngIf="webhookId != formFields?.app?._id" (click)="openEventsModal()" nz-menu-item>Events</li>
        <li (click)="editConnection()" nz-menu-item>Edit Connection</li>
    </ul>
</nz-dropdown-menu>