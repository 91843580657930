import { Injectable } from '@angular/core';

@Injectable()
export class AppService {
  downloadFile(data, receipt, filename = 'data') {
    let csvData = this.ConvertToCSV(data, [
      'customerName',
      'invoiceAmount',
      'invoiceAutoGenNo',
      'invoiceDate',
      'invoiceNo',
      'invoiceStatus',
      'locationName',
      'paidAmount',
      'paymentStatus',
      'paymentTerms',
      'remarks',
    ]);
    // console.log(csvData);
    let csvData1 = this.ConvertToCSV(receipt, [
      'customerName',
      'invoiceNumber',
      'amountReceived',
      'date',
      'bankCharges',
      'mode',
      'receiptId',
    ]);
    // console.log(csvData);
    let blob = new Blob(['\ufeff' + csvData + '\n\n' + [] + csvData1], {
      type: 'text/csv;charset=utf-8;',
    });
    let dwldLink = document.createElement('a');
    let url = URL.createObjectURL(blob);
    let isSafariBrowser =
      navigator.userAgent.indexOf('Safari') != -1 &&
      navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {
      //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute('target', '_blank');
    }
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', filename + '.csv');
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  downloadFile1(data, receipt, filename = 'data') {
    let csvData = this.ConvertToCSV(data, [
      'vendorName',
      'invoiceAmount',
      'invoiceAutoGenNo',
      'invoiceDate',
      'invoiceNo',
      'overdueAt',
      'invoiceStatus',
      'locationName',
      'paidAmount',
      'paymentStatus',
      'paymentTerms',
      'remarks',
    ]);
    // console.log(csvData);
    let csvData1 = this.ConvertToCSV(receipt, [
      'vendorName',
      'invoiceNumber',
      'amountPaid',
      'date',
      'bankCharges',
      'mode',
      'paymentId',
    ]);
    // console.log(csvData);
    let blob = new Blob(['\ufeff' + csvData + '\n\n' + [] + csvData1], {
      type: 'text/csv;charset=utf-8;',
    });
    let dwldLink = document.createElement('a');
    let url = URL.createObjectURL(blob);
    let isSafariBrowser =
      navigator.userAgent.indexOf('Safari') != -1 &&
      navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {
      //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute('target', '_blank');
    }
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', filename + '.csv');
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  ConvertToCSV(objArray, headerList) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = 'S No,';

    for (let index in headerList) {
      row += headerList[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = i + 1 + '';
      for (let index in headerList) {
        let head = headerList[index];

        // switch (head) {
        //   case 'customerName':
        //     head = 'Customer Name';
        //     break;
        //   default:
        //     break;
        // }

        line += ',' + array[i][head];
        // console.log('Line' + line);
        // console.log('Head' + head);
      }
      str += line + '\r\n';
    }
    return str;
  }
}
