import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { NzModalService } from "ng-zorro-antd/modal";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { AlertServiceService } from "src/app/services/alert-service.service";
import { CommonApiService } from "src/app/services/common-api.service";
import { NavService } from "src/app/services/nav.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-plugin-page",
  templateUrl: "./plugin-page.component.html",
  styleUrls: ["./plugin-page.component.css"],
})
export class PluginPageComponent implements OnInit {
  allPlugins: any = [];

  installLoader: boolean = false;
  searchValue: any = "";

  showDocs: boolean = false;

  previewPlugin: any = {};

  selectedCategory: any = "all";

  mainUserData: any;

  imageUrl: any = environment.imageUrl;
  totalCount: any;

  showTemplates: boolean = false;

  private subscription: Subscription;

  constructor(
    private modal: NzModalService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private apiService: CommonApiService,
    private navService: NavService,
    private toastr: ToastrService,
    private alertService: AlertServiceService
  ) {}

  @ViewChild("scrollableDiv") scrollableDiv!: ElementRef;

  ngOnInit(): void {
    window.addEventListener("scroll", () => {
      const scrolledToTopElementId = this.getScrolledToTopElementId();
      // console.log(scrolledToTopElementId);
    });
    this.getMainUserData();
    this.getAllPlugins();
  }

  getMainUserData() {
    this.navService.getMainUserData();
    this.subscription = this.navService.mainUserData.subscribe(
      (response: any) => {
        if (response) {
          this.mainUserData = response.data.userData;
          // console.log(this.mainUserData);
        }
      }
    );
  }

  getAllPlugins() {
    this.spinner.show();
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonGetMethod(environment.url + "plugins", options)
      .subscribe(
        (response: any) => {
          // console.log(response);
          this.allPlugins = response?.data?.allPlugins;
          this.totalCount = response?.data?.totalCount;
          this.spinner.hide();
        },
        (err: any) => {
          console.log(err);
          this.spinner.hide();
        }
      );
  }

  getScrolledToTopElementId() {
    const element = this.scrollableDiv.nativeElement.elementFromPoint(0, 0);
    return element ? element.id : null;
  }

  optionsClick(selected: any, event: any) {
    if (this?.mainUserData?.accessTo?.sandboxId) {
      var errHead: any = "You cannot Install Plugins in Sandbox account!";
      var errBody: any = "Please Subscribe any Plan to Install Plugins..!";
      this.alertService.freeTrialWarning(errHead, errBody);
    } else if (this.mainUserData?.accessTo?.isExpired) {
      this.alertService.warning();
    } else {
      event.preventDefault();
      event.stopPropagation();
      if (
        this.mainUserData?.accessTo?.pluginsEnabled.indexOf(selected?._id) == -1
      ) {
        // this.previewPlugin = selected;
        this.showDocs = true;
        this.getPluginDetails(selected, "");
      } else {
        // this.settingRoute(selected);
        this.getPluginDetails(selected, "redirect");
      }
    }
  }

  showDocument(selected: any) {
    if (this?.mainUserData?.accessTo?.sandboxId) {
      var errHead: any = "You cannot Install Plugins in Sandbox account!";
      var errBody: any = "Please Subscribe any Plan to Install Plugins..!";
      this.alertService.freeTrialWarning(errHead, errBody);
    } else if (this.mainUserData?.accessTo?.isExpired) {
      this.alertService.warning();
    } else {
      // console.log(selected);
      // this.previewPlugin = selected;
      this.showDocs = true;
      this.getPluginDetails(selected, "");
    }
  }

  uninstallAlert() {
    this.modal.confirm({
      nzOkText: "Yes",
      nzCancelText: "No",
      nzTitle: "<b>Sure you Want to Uninstall Plugin?</b>",
      nzContent: "<b>Once uninstalled, the Data were Removed..</b>",
      nzOnOk: () => this.uninstallPlugin(),
    });
  }

  uninstallPlugin() {
    this.spinner.show();
    this.changePluginStatus("disable");
  }

  install() {
    if (
      this.mainUserData?.accessTo?.pluginsInstalled?.indexOf(
        this.previewPlugin?._id
      ) == -1
    ) {
      this.installPlugin();
    } else if (
      this.mainUserData?.accessTo?.pluginsEnabled?.indexOf(
        this.previewPlugin._id
      ) == -1
    ) {
      this.installLoader = true;
      this.changePluginStatus("enable");
    } else {
      this.settingRoute(this.previewPlugin);
    }
  }

  categoryChange() {
    // console.log(this.selectedCategory);
    if (this.selectedCategory == "all") {
      document
        .getElementById(this.allPlugins?.[0]?.category + 0)
        .scrollIntoView({ behavior: "smooth" });
    } else {
      document
        .getElementById(this.selectedCategory + 0)
        .scrollIntoView({ behavior: "smooth" });
    }
  }

  changePluginStatus(status: any) {
    var reqObj: any = {
      pluginId: this.previewPlugin?._id,
      changeActiveStatus: status,
    };
    // console.log(reqObj);

    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(environment.url + "plugins/change", reqObj, options)
      .subscribe(
        (response: any) => {
          // console.log(response);
          this.installLoader = false;
          this.toastr.success(response.message, "", {
            timeOut: 2500,
          });
          this.getAllPlugins();
          this.getMainUserData();
        },
        (err: any) => {
          console.log(err);
          if (err?.error?.message) {
            this.toastr.error(err?.error?.message, "", {
              timeOut: 2500,
            });
          } else {
            this.toastr.error(err?.message, "", {
              timeOut: 2500,
            });
          }
          this.installLoader = false;
          this.spinner.hide();
        }
      );
  }

  settingRoute(plugin: any) {
    // console.log(plugin);
    this.showTemplates = true;
    // if (plugin._id == "642a7c2f418c702b8e096b30") {
    //   window.open("https://owncartecommerceadmin.web.app/", "_blank");
    // } else {
    //   this.router.navigate(["/commerce", "messages"]);
    // }
  }

  installPlugin() {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    var reqObj: any = {
      pluginId: this.previewPlugin?._id,
    };

    this.apiService
      .commonPostMethod(environment.url + "plugins/install", reqObj, options)
      .subscribe(
        (response: any) => {
          // console.log(response);
          this.installLoader = false;
          this.toastr.success(response.message, "", {
            timeOut: 2500,
          });
          this.getAllPlugins();
          this.getMainUserData();
        },
        (err: any) => {
          console.log(err);
          if (err?.error?.message) {
            this.toastr.error(err?.error?.message, "", {
              timeOut: 2500,
            });
          } else {
            this.toastr.error(err?.message, "", {
              timeOut: 2500,
            });
          }
          this.installLoader = false;
          this.spinner.hide();
        }
      );
  }

  getPluginDetails(selected: any, type: any) {
    this.spinner.show();

    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    var reqObj: any = {
      pluginId: selected._id,
    };

    this.apiService
      .commonPostMethod(environment.url + "plugins/detail", reqObj, options)
      .subscribe(
        (response: any) => {
          // console.log(response);
          this.previewPlugin = response?.data?.detail[0]?.plugin;
          if (this.previewPlugin?.waBaTemplates?.length > 0) {
            for (let i = 0; i < this.previewPlugin.waBaTemplates.length; i++) {
              // console.log(this.previewPlugin.waBaTemplates[i].components);
              this.previewPlugin.waBaTemplates[i].components = JSON.parse(
                this.previewPlugin.waBaTemplates[i].components
              );
            }
          }

          // this.previewPlugin.waBaTemplates[1].components = JSON.parse(
          //   this.previewPlugin.waBaTemplates[1].components
          // );
          // console.log(this.previewPlugin);
          if (type == "redirect") {
            this.showTemplates = true;
            this.showDocs = true;
          }
          this.spinner.hide();
        },
        (err: any) => {
          console.log(err);
          if (err?.error?.message) {
            this.toastr.error(err?.error?.message, "", {
              timeOut: 2500,
            });
          } else {
            this.toastr.error(err?.message, "", {
              timeOut: 2500,
            });
          }
          this.spinner.hide();
        }
      );

    // this.previewPlugin = selected;
  }

  setDefault() {
    this.showDocs = false;
    this.showTemplates = false;
    this.spinner.hide();
  }

  ngOnDestroy(): void {
    // console.log("api key destroy..");
    this.subscription.unsubscribe();
  }
}
