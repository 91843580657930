import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { ThemeConstantService } from "../../services/theme-constant.service";
import axios, { Axios } from "axios";
import { environment } from "src/environments/environment";
import { NavService } from "src/app/services/nav.service";
import { NzModalService } from "ng-zorro-antd/modal";
import { Subscription } from "rxjs";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent {
  searchVisible: boolean = false;
  quickViewVisible: boolean = false;
  isFolded: boolean;
  isExpand: boolean;
  userData: any = {};
  placeholder = "Customer";
  searchType = "customer";
  searchKey = "";
  Filtered: [] = [];
  noProducts = false;

  liveUrl = environment.imageUrl;

  private subscription: Subscription;

  constructor(
    private themeService: ThemeConstantService,
    private router: Router,
    private modal: NzModalService,
    private navService: NavService
  ) {}

  ngOnInit(): void {
    // this.userData = JSON.parse(localStorage.getItem('qweuserdataqwe'));
    // console.log(this.userData);
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.navService.getMainUserData();
    this.subscription = this.navService.mainUserData.subscribe((data: any) => {
      if (data) {
        // console.log(data);
        this.userData = data?.data?.userData;
      }
    });

    // this.apiService
    //   .commonGetMethod(environment.url + "auth/get-user-data", options)
    //   .subscribe(
    //     (response: any) => {
    //       // console.log(response);
    //       this.userData = {
    //         username: response.data.userData.accessTo.name,
    //         role: response.data.userData.role,
    //       };
    //     },
    //     (error: any) => {
    //       console.log(error);
    //     }
    //   );
    // this.userData = {
    //   username: "Ieyal Solutions",
    //   role: "Admin",
    // };
    this.themeService.isMenuFoldedChanges.subscribe(
      (isFolded) => (this.isFolded = isFolded)
    );
    this.themeService.isExpandChanges.subscribe(
      (isExpand) => (this.isExpand = isExpand)
    );

    // this.navService.userData.subscribe((data) => {
    //   this.userData = data;
    // });

    // axios
    //   .get(environment.url + 'auth/user-detail/', {
    //     headers: {
    //       Authorization: 'Bearer ' + localStorage.getItem('Token'),
    //     },
    //   })
    //   .then((response) => {
    //     console.log(response);
    //     this.userData = response?.data?.data?.user;
    //   });
  }

  toggleFold() {
    this.isFolded = !this.isFolded;
    this.themeService.toggleFold(this.isFolded);
  }

  toggleExpand() {
    this.isFolded = false;
    this.isExpand = !this.isExpand;
    this.themeService.toggleExpand(this.isExpand);
    this.themeService.toggleFold(this.isFolded);
  }

  searchToggle(): void {
    this.searchVisible = !this.searchVisible;
  }

  quickViewToggle(): void {
    this.quickViewVisible = !this.quickViewVisible;
  }

  notificationList = [];

  logoutAlert() {
    this.modal.confirm({
      nzTitle: "Sure you want to Logout?",
      nzOkText: "Yes",
      nzCancelText: "No",
      nzOnOk: () => this.Logout(),
    });
  }

  Logout() {
    localStorage.removeItem("qwertrewetrt");
    localStorage.removeItem(
      "gtrdtghjdgfhjsgkahjhfjkafhfkjhfkfifghefywegfyuwgfuyew"
    );
    localStorage.removeItem("skjhfruibsndbvmnvckbhdsuifhbd");
    this.router.navigate(["/login"]);
  }

  create(path: any) {
    this.router.navigate([path, { openForm: true }]);
  }

  searchBy(search: any) {
    if (search == "vendor") {
      this.placeholder = "Vendor";
    } else {
      this.placeholder = "Customer";
    }
    this.searchType = search;
    this.searchKey = "";
    this.Filtered = [];
  }

  searchData(searchKey) {
    axios
      .post(
        environment.url + "dashboard/search",
        {
          filterType: this.searchType,
          filterValue: searchKey,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("Token"),
          },
        }
      )
      .then((response) => {
        // console.log(response.data.data.searchResults);
        this.Filtered = response.data.data.searchResults;
        if (this.Filtered.length == 0) {
          this.noProducts = true;
        } else {
          this.noProducts = false;
        }
      });
  }

  keyUp() {
    // console.log(event);
    if (this.searchKey == "") {
      this.Filtered = [];
      this.noProducts = false;
    } else {
      this.searchData(this.searchKey);
    }
  }

  toSelectedDetails(item: any) {
    var index = item;
    if (this.searchType == "customer") {
      this.router.navigate([
        "/customer/customer-details",
        { customerDetail: JSON.stringify({ item }) },
      ]);
    } else if (this.searchType == "vendor") {
      this.router.navigate([
        "/vendor/vendor-details",
        { vendorDetail: JSON.stringify({ index }) },
      ]);
    }
    // console.log(item);
  }

  toSettingPage() {
    if (this.userData.role == "teammate") {
      this.router.navigate(["/setting/profile"]);
    } else {
      this.router.navigate(["/setting/whatsapp"]);
    }
  }

  //change css variable colors

  // colorChange() {
  //   document.documentElement.style.setProperty("--test-color", "red");
  // }

  ngOnDestroy(): void {
    // console.log("api key destroy..");
    this.subscription.unsubscribe();
  }
}
