<div class="row overflow_row_mobile">
    <div class="col-12 main_outer_heading">
        <h3><span routerLink="/dashboard" nz-icon nzType="left" nzTheme="outline"></span>
            Conversation History
        </h3>
    </div>

    <!-- <div class="col-12">
        <nz-card class="contacts_top_card">
        </nz-card>
    </div> -->

    <div class="col-12" id="main-section">
        <nz-card class="customer_right_card customers_table_card">
            <div class="total_records">
                <h6>
                    Total Records ({{ totalCount }})
                    <span [ngClass]="{ 'reload_btn_loading': pageReloading }" (click)="reloadPage()" class="reload_btn"
                        nzTooltipTitle="reload" nzTooltipPlacement="top" nz-tooltip nz-icon nzType="sync"
                        nzTheme="outline"></span>
                </h6>
                <button class="customized_btn" [ngStyle]="setMyStyles()" nzTrigger="click" nz-dropdown
                    [nzDropdownMenu]="paginationMenu" [nzPlacement]="'bottomLeft'">
                    {{ pageSize + " records per page" }}
                </button>

                <nz-dropdown-menu #paginationMenu="nzDropdownMenu">
                    <ul nz-menu>
                        <li (click)="changePageSize((n + 1) * 10)" *ngFor="let page of [].constructor(5); let n = index"
                            nz-menu-item>
                            {{ (n + 1) * 10 + " records per page" }}
                        </li>
                    </ul>
                </nz-dropdown-menu>
            </div>

            <nz-table class="contacts_table main_contacts_table" #rechargeHistoryTable [nzData]="conversationHistory"
                [nzScroll]="{ y: tableHeight, x: '500px' }" [nzFrontPagination]="false" [nzTotal]="totalCount"
                [nzPageSize]="pageSize" [nzPageIndex]="pageIndex"
                (nzQueryParams)="onPageNumberChange('categoryTable', $event)" [nzPaginationPosition]="'top'"
                [nzSimple]="true" [nzTableLayout]="'auto'">
                <thead>
                    <tr>
                        <th *ngFor="let column of orderColumn" [nzWidth]="column.width">
                            {{ column.title }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr id="categoryTable" *ngFor="let item of rechargeHistoryTable.data; let i = index">
                        <td>{{item.customerId?.name}}</td>
                        <td>{{item.customerId?.phoneNo}}</td>
                        <td>{{item?.conversationType}}</td>
                        <td>{{item?.cost?.toFixed(4)}}</td>
                        <td>{{moment(item?.time).format("DD-MM-YYYY hh:mm a")}}</td>
                    </tr>
                </tbody>
                <ngx-spinner bdColor="rgb(255,255,255)" size="medium" color="#000000" type="ball-clip-rotate-pulse"
                    [fullScreen]="false"></ngx-spinner>
            </nz-table>
        </nz-card>
    </div>
</div>