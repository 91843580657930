<div class="row m-b-30">
    <div class="col-12 top_heading">
        <h3>
            <i routerLink="/setting" nz-icon nzType="left" nzTheme="outline"></i>
            Change Password
        </h3>
    </div>

    <div class="col-lg-8 mt-5 password_form_col">
        <form [formGroup]="resetPasswordForm" (ngSubmit)="submitForm()" nz-form>
            <nz-form-item>
                <nz-form-label nzRequired class="large_label" nzFor="" [nzSm]="8" [nzXs]="24">
                    Current Password
                </nz-form-label>
                <nz-form-control [nzSm]="16" [nzXs]="24" nzErrorTip="Please Enter Password!">
                    <nz-input-group [nzSuffix]="visibleIcon">
                        <input autocomplete="off" [type]="currentPasswordVisible ? 'text' : 'password'"
                            formControlName="currentPassword" nz-input placeholder="Enter current password">
                    </nz-input-group>
                    <ng-template #visibleIcon>
                        <i nz-icon [nzType]="currentPasswordVisible ? 'eye' : 'eye-invisible'"
                            (click)="currentPasswordVisible = !currentPasswordVisible"></i>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label nzRequired class="large_label" nzFor="" [nzSm]="8" [nzXs]="24">
                    New Password
                </nz-form-label>
                <nz-form-control [nzSm]="16" [nzXs]="24" nzErrorTip="Please Enter Password!">
                    <nz-input-group [nzSuffix]="visibleIcon1">
                        <input autocomplete="off" [type]="newPasswordVisible ? 'text' : 'password'"
                            formControlName="newPassword" nz-input placeholder="Enter new password">
                    </nz-input-group>
                    <ng-template #visibleIcon1>
                        <i nz-icon [nzType]="newPasswordVisible ? 'eye' : 'eye-invisible'"
                            (click)="newPasswordVisible = !newPasswordVisible"></i>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label nzRequired class="large_label" nzFor="" [nzSm]="8" [nzXs]="24">
                    Confirm Password
                </nz-form-label>
                <nz-form-control [nzSm]="16" [nzXs]="24" nzErrorTip="Please Confirm Password!">
                    <nz-input-group [ngClass]="{'incorrect_message' : passwordIncorrect}" [nzSuffix]="visibleIcon2">
                        <input autocomplete="off" [type]="confirmPasswordVisible ? 'text' : 'password'"
                            formControlName="confirmPassword" nz-input placeholder="Enter new password">
                    </nz-input-group>
                    <p class="error_text" *ngIf="passwordIncorrect">password is incorrect</p>
                    <ng-template #visibleIcon2>
                        <i nz-icon [nzType]="confirmPasswordVisible ? 'eye' : 'eye-invisible'"
                            (click)="confirmPasswordVisible = !confirmPasswordVisible"></i>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item class="text-right mt-3">
                <nz-form-control [nzSm]="24" [nzXs]="24">
                    <div class="account_details_add_cancel_right">
                        <button type="reset" class="sighup_button mr-3" nz-button nzType="default">Cancel</button>
                        <button [disabled]="mainUserData?.accessTo?.isExpired" [nzLoading]="submitting" type="submit"
                            class="sighup_button" nz-button nzType="primary">Save</button>
                    </div>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
</div>