<div *ngIf="showChart" class="bot_main_layout ">
    <div class="row">
        <div *ngIf="!fullScreen" class="col-3 p-0" #menuDivElement>
            <div class="bot_menu_list">
                <div class="sticky_left">
                    <h3 nz-tooltip nzTooltipPlacement="bottomLeft" [nzTooltipTitle]="pathName?.name">
                        <!-- <span [routerLink]="['/chat-bot/flow' , botData.botId]" nz-icon nzType="left"
                            nzTheme="outline"></span>
                        <img class="head_logo" src="../../../../assets/images/logo/own_cart_mini_logo.png" alt=""> -->
                        {{pathName?.name?.length > 40 ? (pathName?.name.slice(0,40) + '...') : pathName?.name}}
                    </h3>

                    <h4 *ngIf="!showSearch">
                        Bot Actions
                        <i (click)="openSearch(true)" nz-icon nzType="search" nzTheme="outline"></i>
                        <!-- <span *ngIf="selectedMenu !='all'" class="cursor_pointer" (click)="selectedMenu = 'all'" nz-icon
                        nzType="arrow-left" nzTheme="outline"></span>
                    {{selectedMenu !='all' ? selectedMenu.category : 'Create Actions'}} -->
                    </h4>
                    <div class="search" *ngIf="showSearch">
                        <i (click)="openSearch(false)" nz-icon nzType="arrow-left" nzTheme="outline"></i>
                        <input [(ngModel)]="searchText" autocomplete="off" type="text" nz-input
                            placeholder="search actions">
                    </div>
                </div>
                <nz-collapse *ngIf="!searchText" nzAccordion nzGhost [nzExpandIconPosition]="'right'">
                    <nz-collapse-panel *ngFor="let menu of nodeMenu" [nzHeader]="menu.category" [nzActive]="false"
                        [nzExtra]="extraTpl">
                        <div class="row">
                            <div (click)="selectMessageType(option.type , false , '',false)" class="col-6 menu_col"
                                *ngFor="let option of menu.options">
                                <div class="action_menu">
                                    <img [src]="option?.icon" alt="">
                                    <span>
                                        {{option.name}}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <ng-template #extraTpl>
                            <img class="extra_logo" [src]="menu.logo" alt="">
                        </ng-template>
                    </nz-collapse-panel>
                </nz-collapse>
                <div *ngIf="searchText">
                    <div class="row">
                        <ng-container *ngFor="let menu of nodeMenu">
                            <div (click)="selectMessageType(option.type , false , '',false)" class="col-6 menu_col"
                                *ngFor="let option of menu.options | filter : searchText">
                                <div class="action_menu">
                                    <img [src]="option?.icon" alt="">
                                    <span>
                                        {{option.name}}
                                    </span>
                                </div>
                            </div>
                        </ng-container>
                        <!-- <li (click)="selectMessageType(option.type , false , '',false)"
                            *ngFor="let option of menu.options | filter : searchText">
                            {{option.name}}
                        </li> -->
                    </div>
                </div>
            </div>
        </div>

        <div class="col-9 p-0" [ngClass]="{'col-12' : fullScreen}">
            <div class="row">
                <div *ngIf="!fullScreen" class="col-12 mt-1 main_outer_heading p-0 m-0">
                    <nz-card class="contacts_top_card m-0 bot_menu_card">
                        <div class="search_add_div">
                            <div class="bot_path_name">
                                <span class="cursor_pointer" (click)="enableFullScreen()" nz-icon nzType="expand"
                                    nzTheme="outline"></span>
                                <!-- <span>{{pathName?.name}}</span> -->
                                <span>ChatBot Builder</span>
                            </div>

                            <div class="head_right">
                                <div class="animation_div">
                                    <button (click)="publishPath()" nz-tooltip
                                        [nzTooltipTitle]="pathName?.isNeedToPublish ? 'Publish recent changes' : ''"
                                        [disabled]="!pathName?.isNeedToPublish" class="sighup_button " nz-button
                                        nzType="primary">
                                        Publish
                                    </button>
                                    <div [ngClass]="{'button-animation' : pathName?.isNeedToPublish}" class=""></div>
                                </div>
                                <span [routerLink]="['/chat-bot/flow' , botData.botId]" nz-icon nzType="close"
                                    nzTheme="outline"></span>
                            </div>
                        </div>
                    </nz-card>
                </div>

                <div class="col-12 p-0">
                    <ngx-graph *ngIf="nodes.length > 0" #graphContainer (activate)="activate()" class="chart-container"
                        [minZoomLevel]=".2" [autoZoom]="false" [autoCenter]="false" [showMiniMap]="true" [links]="links"
                        [nodes]="nodes" [autoLayout]="false" [layout]="layout" [ngClass]="{'full_screen' : fullScreen}">
                        <ng-template #nodeTemplate let-node>
                            <svg:g class="node">
                                <svg:rect class="rect_node" [attr.width]="node.dimension.width"
                                    [attr.height]="node.dimension.height" [attr.fill]="node.data.color" />

                                <foreignObject [attr.x]="0" [attr.y]="0"
                                    [attr.width]="(node?.actionType == 'SEND_AUDIO' ? 270 : 230) + (node?.actionData?.message?.length / 2) + (node?.actionData?.bodyText?.length / 4) + (node?.actionData?.url?.length) + 'px'"
                                    [attr.height]="140 + (node.actionName?.length / 2) + (node?.actionData?.message?.length) + (node?.actionData?.buttons?.length * 35) + (listHeight(node?.actionData?.sections)) + (node?.actionData?.bodyText?.length + node?.actionData?.footerText?.length + node?.actionData?.headerText?.length + (node?.actionData?.url?.length * 2)) + (node?.isThisStartPath ? 20 : 0) + (mediaTypes.indexOf(node?.actionType) > -1 ? 60 : 0) + (node?.actionData?.caption?.length * 2) + (node?.actionData?.conditions?.length * 30) + (node.actionType == 'IF_STATEMENT' || node.actionType == 'API_CALL' || node.actionData.type == 'dynamic' ? 55 : 0) + 'px'">
                                    <div [ngClass]="{'start_node' : node?.isThisStartPath}" class="node_main_div"
                                        [ngSwitch]="node?.actionType">
                                        <i class="initial_icon" nz-tooltip [nzTooltipTitle]="'Start Action'"
                                            *ngIf="node?.isThisStartPath" nz-icon nzType="play-circle"
                                            nzTheme="outline"></i>
                                        <span class="action_type"
                                            [ngClass]="{'right_space' : node?.actionType == 'TRIGGER_PATH'}">
                                            {{node.actionName}}
                                            <span>{{'(' + node.actionType + ')'}}</span>
                                        </span>
                                        <div class="action_body">
                                            <div *ngSwitchCase="'SEND_TEXT'" class="">
                                                <p>{{node?.actionData?.message}}</p>
                                            </div>

                                            <div *ngSwitchCase="'SEND_IMAGE'" class="mt-1">
                                                <div class="media_cover mb-2">
                                                    <img class="node_media"
                                                        [src]="liveUrl + node?.actionData?.mediaData?.id?.url"
                                                        alt="send media">
                                                </div>

                                                <p>{{node?.actionData?.caption}}</p>
                                            </div>

                                            <div *ngSwitchCase="'SEND_VIDEO'" class="mt-1">
                                                <div class="media_cover mb-2">
                                                    <video class="node_media"
                                                        [src]="liveUrl + node?.actionData?.mediaData?.id?.url" controls
                                                        preload="none"></video>
                                                </div>
                                                <p>{{node?.actionData?.caption}}</p>
                                            </div>

                                            <div *ngSwitchCase="'SEND_AUDIO'" class="mt-2">
                                                <audio class="node_audio"
                                                    [src]="liveUrl + node?.actionData?.mediaData?.id?.url"
                                                    controls></audio>
                                            </div>

                                            <div *ngSwitchCase="'SEND_DOCUMENT'" class="mt-2">
                                                <div class="node_document">
                                                    <img class="media_preview_demo"
                                                        src="../../../../assets/images/chat/icons8-google-docs.svg"
                                                        alt="">
                                                    <a target="_blank"
                                                        [href]="liveUrl + node?.actionData?.mediaData?.id?.url">
                                                        https://storage.goo...
                                                    </a>
                                                </div>
                                                <p>{{node?.actionData?.caption}}</p>
                                            </div>

                                            <div *ngSwitchCase="'SEND_BUTTONS'" class="">
                                                <p>{{node?.actionData?.headerText}}</p>
                                                <p>{{node?.actionData?.bodyText}}</p>
                                                <p class="mt-2 mb-3">{{node?.actionData?.footerText}}</p>
                                                <div class="buttons_div">
                                                    <button (click)="addSource(node , btn)"
                                                        *ngFor="let btn of node.actionData.buttons">{{btn.title}}</button>
                                                </div>
                                            </div>

                                            <div *ngSwitchCase="'SEND_LIST'" class="">
                                                <p>{{node?.actionData?.headerText}}</p>
                                                <p>{{node?.actionData?.bodyText}}</p>
                                                <p class="mt-2 mb-3">{{node?.actionData?.footerText}}</p>
                                                <ng-container *ngIf="node.actionData.type == 'static'" class="">
                                                    <div *ngFor="let menu of node.actionData?.sections"
                                                        class="menu_list buttons_div">
                                                        <span>{{menu?.title}}</span>
                                                        <button (click)="addSource(node , btn)"
                                                            *ngFor="let btn of menu?.rows">{{btn.title}}</button>
                                                        <!-- <a (click)="addSource(node , btn)"
                                                *ngFor="let btn of menu?.rows">{{btn.title}}</a> -->
                                                    </div>
                                                </ng-container>
                                                <div *ngIf="node.actionData.type == 'dynamic'" class="text-center">
                                                    <a>{{node.actionData.buttonText}}</a>
                                                    <div class="buttons_div">
                                                        <button
                                                            (click)="addSource(node , {id : node?.goBackAction , type : 'Go back'})">
                                                            Go back
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div *ngSwitchCase="'SEND_PRODUCTS'" class="product_list_node">
                                                <p>{{node?.actionData?.headerText}}</p>
                                                <p>{{node?.actionData?.bodyText}}</p>
                                                <p *ngIf="node.actionData.type == 'single'">
                                                    {{node?.actionData?.product_retailer_id}}</p>
                                                <div *ngIf="node.actionData.type == 'multi'" class="mb-2">
                                                    <a class="text-center d-block">View items</a>
                                                    <!-- <div *ngFor="let menu of node.actionData?.sections" class="menu_list">
                                            <span>{{menu?.title}}</span>
                                            <a *ngFor="let btn of menu?.product_items">
                                                {{btn.product_retailer_id}}
                                            </a>
                                        </div> -->
                                                </div>
                                                <p>{{node?.actionData?.footerText}}</p>
                                            </div>

                                            <div *ngSwitchCase="'SEND_TEMPLATE'" class="mt-2">
                                                <p class="edit_link"
                                                    (click)="showTemplateModal(node?.actionData?.templateId)">
                                                    {{node?.actionData?.templateId?.name}}</p>
                                            </div>

                                            <div *ngIf="askTypes.indexOf(node?.actionType) > -1" class="">
                                                <p>{{node?.actionData?.message ? node?.actionData?.message :
                                                    node?.actionData?.bodyText}}</p>
                                            </div>

                                            <div *ngSwitchCase="'TRIGGER_PATH'" class="">
                                                <a [href]="urlOrigin + '/chat-bot/' + botData.botId + '/' + node?.actionData?.path?._id"
                                                    target="_blank">
                                                    <img class="new_tab"
                                                        src="../../../../assets/images/icons/open-link.svg" alt="">
                                                </a>
                                                <p>Path Name : {{node?.actionData?.path.name}}</p>
                                            </div>

                                            <div *ngSwitchCase="'ASSIGN_CONVERSATION'" class="">
                                                <p>Assignee :
                                                    {{ node?.actionData?.type == 'Flow' ?
                                                    'Bot' :
                                                    (node?.actionData?.assigneeId.firstName + ' ' +
                                                    (node?.actionData?.assigneeId.lastName ?
                                                    node?.actionData?.assigneeId.lastName :
                                                    ''))}}
                                                </p>
                                            </div>

                                            <div *ngSwitchCase="'API_CALL'" class="">
                                                <p>Method : {{node?.actionData?.method}}</p>
                                                <p>URL : {{node?.actionData?.url}}</p>
                                                <div class="buttons_div mt-2">
                                                    <button
                                                        (click)="addSource(node , {type : 'success' , id : node.actionData.onSuccess})">
                                                        On Success
                                                    </button>
                                                    <button
                                                        (click)="addSource(node , {type : 'fail' , id : node.actionData.onFailed})">
                                                        On Fail
                                                    </button>
                                                </div>
                                            </div>

                                            <div *ngSwitchCase="'IF_STATEMENT'" class="node_statement">
                                                <span nz-tooltip
                                                    [nzTooltipTitle]="condition.key + ' ' + condition.operator + ' ' + condition.value"
                                                    class="node_condition"
                                                    *ngFor="let condition of node.actionData.conditions ; let s = index">
                                                    <p *ngIf="s > 0">{{node.actionData.conditionType}}</p>
                                                    {{(s+1) + '. ' + (condition.key.length < 10 ? condition.key :
                                                        condition.key.slice(0,10))}} <span>{{condition.operator}}
                                                </span>
                                                {{(condition.value.length < 10 ? condition.value :
                                                    condition.value.slice(0,10))}} </span>

                                                    <div class="buttons_div mt-2">
                                                        <button
                                                            (click)="addSource(node , {type : 'success' , id : node.actionData.onSuccess})">On
                                                            Success</button>
                                                        <button
                                                            (click)="addSource(node , {type : 'fail' , id : node.actionData.onFailed})">On
                                                            Fail</button>
                                                    </div>
                                            </div>

                                            <div class="edit_delete_div">
                                                <span (click)="selectMessageType(node.actionType , true , node, false)"
                                                    nz-tooltip nzTooltipTitle="Edit" class="" nz-icon nzType="edit"
                                                    nzTheme="outline"></span>
                                                <span (click)="removeNodeAlert(node)" nz-tooltip nzTooltipTitle="Delete"
                                                    class="delete_link" nz-icon nzType="delete"
                                                    nzTheme="outline"></span>
                                                <span (click)="updateStartPath(node)" nz-tooltip
                                                    [nzTooltipTitle]="'Make this as Start Action'"
                                                    *ngIf="!node?.isThisStartPath" nz-icon nzType="play-circle"
                                                    nzTheme="outline"></span>
                                                <span nz-dropdown nzTrigger="click" [nzDropdownMenu]="pathsMenu"
                                                    (click)="selectMessageType(node.actionType , true , node , true)"
                                                    nz-tooltip nzTooltipTitle="Duplicate" nz-icon nzType="copy"
                                                    nzTheme="outline"></span>
                                            </div>

                                            <div class="add_link_section in_section">
                                                <span (click)="addLink(node , 'in')" class=""></span>
                                            </div>

                                            <div *ngIf="outOptionTypes.indexOf(node.actionType) > -1 && node.actionData.type != 'static'"
                                                class="add_link_section out_section">
                                                <span (click)="addLink(node , 'out')" class=""></span>
                                            </div>
                                        </div>
                                    </div>
                                </foreignObject>
                            </svg:g>
                        </ng-template>

                        <ng-template #linkTemplate let-link>
                            <svg:g class="edge">
                                <svg:path class="line" stroke-width="2" marker-end="url(#arrow)"></svg:path>

                                <!-- <svg:path class="line" [attr.d]="getCurvePath(link)" stroke-width="2" marker-end="url(#arrow)">
                        </svg:path> -->

                                <svg:text class="edge-label" text-anchor="middle">
                                    <textPath class="text-path" [attr.href]="'#' + link.id"
                                        [style.dominant-baseline]="link.dominantBaseline" startOffset="45%">
                                        {{link.label}}
                                    </textPath>
                                    <textPath (click)="removeLink(link)" class="text-path close_link"
                                        [attr.href]="'#' + link.id" [style.dominant-baseline]="link.dominantBaseline"
                                        startOffset="86%">
                                        X
                                    </textPath>
                                </svg:text>
                            </svg:g>
                        </ng-template>

                        <ng-template #defsTemplate>
                            <svg:marker id="arrow" viewBox="0 -5 10 10" refX="8" refY="0" markerWidth="4"
                                markerHeight="4" orient="auto">
                                <svg:path d="M0,-5L10,0L0,5" class="arrow-head" />
                            </svg:marker>
                        </ng-template>

                        <svg>
                            <line *ngIf="isDrawingLine" class="custom_line" [attr.x1]="lineStartX"
                                [attr.y1]="lineStartY" [attr.x2]="lineEndX" [attr.y2]="lineEndY">
                            </line>
                        </svg>
                    </ngx-graph>

                    <!-- <span (click)="getWidth()" class="bot_menu" [ngStyle]="{'right' : divWidth + 'px'}" nz-icon
                nzType="menu-fold" nzTheme="outline"></span> -->
                </div>
            </div>
        </div>
    </div>
</div>




<nz-drawer [nzWidth]="'45%'" [nzClosable]="true" [nzVisible]="nodesDrawer" [nzPlacement]="'left'"
    [nzTitle]="messageType.replaceAll('_' , ' ')" (nzOnClose)="closeNodesDrawer()">
    <ng-container *nzDrawerContent>

        <div class="messages_list">
            <form [formGroup]="addMessageForm" (ngSubmit)="addNode()" nz-form>
                <nz-form-item>
                    <nz-form-label class="large_label" nzRequired [nzSm]="24" [nzXs]="24">
                        Action name
                    </nz-form-label>
                    <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please enter action name!">
                        <nz-input-group>
                            <input autocomplete="off" formControlName="actionName" type="text" nz-input
                                placeholder="Enter name for action" required>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <ng-container *ngIf="messageType == 'SEND_TEMPLATE'">
                    <nz-form-item>
                        <nz-form-label class="large_label" nzRequired [nzSm]="24" [nzXs]="24">
                            Select Template
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please select template!">
                            <nz-input-group>
                                <nz-select nzShowSearch (nzScrollToBottom)="loadMore()" formControlName="templateId"
                                    nzPlaceHolder="Select template" class="w-100" (ngModelChange)="selectTemplate()">
                                    <nz-option *ngFor="let template of allTemplates?.waMsgTemplates"
                                        [nzLabel]="template.name" [nzValue]="template._id">
                                    </nz-option>
                                </nz-select>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <div *ngIf="messageTemplateSelected" class="preview_out">
                        <div class="template_col">
                            <div class="preview_heading">
                                <h5 [nzTooltipTitle]="messageTemplateSelected.name" nzTooltipPlacement="top" nz-tooltip>
                                    {{messageTemplateSelected.name.length > 23 ?
                                    messageTemplateSelected.name.slice(0,23) + '...' : messageTemplateSelected.name}}
                                </h5>
                            </div>
                            <div class="preview_template">
                                <div class="inner_template">
                                    <div *ngFor="let message of messageTemplateSelected.components" class="mb-3">
                                        <div [ngSwitch]="message?.type" class="">
                                            <div *ngSwitchCase="'HEADER'" class="">
                                                <div [ngSwitch]="message?.format" class="header_text">
                                                    <p *ngSwitchCase="'TEXT'" [innerHTML]="message.text"></p>
                                                    <p class="img_url" *ngSwitchCase="'IMAGE'">
                                                        <span nz-icon nzType="file-image" nzTheme="outline"></span>
                                                        IMAGE
                                                    </p>
                                                    <p class="img_url" *ngSwitchCase="'VIDEO'">
                                                        <span nz-icon nzType="video-camera" nzTheme="outline"></span>
                                                        VIDEO
                                                    </p>
                                                    <p class="img_url" *ngSwitchCase="'DOCUMENT'">
                                                        <span nz-icon nzType="file-done" nzTheme="outline"></span>
                                                        DOCUMENT
                                                    </p>
                                                </div>

                                            </div>
                                            <div *ngSwitchCase="'BODY'" class="">
                                                <p [innerHTML]="((message?.text))"></p>
                                            </div>
                                            <div *ngSwitchCase="'FOOTER'" class="">
                                                <p [innerHTML]="message?.text"></p>
                                            </div>
                                            <div *ngSwitchCase="'BUTTONS'" class="">
                                                <div *ngFor="let button of message?.buttons" class="template_buttons">
                                                    <h6>{{button?.text}}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="template_bottom">
                                <div class="bottom_left">
                                    <span class="template_lang">{{messageTemplateSelected?.language}}</span>
                                    <span class="template_lang">{{messageTemplateSelected?.category}}</span>
                                </div>
                                <span class="template_status">{{messageTemplateSelected?.status}}</span>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="templateVariables" class="">
                        <div *ngIf="templateVariables.mediaData.hasMedia" class="condition_input">
                            <nz-form-item>
                                <nz-form-label class="large_label" nzRequired [nzSm]="24" [nzXs]="24">
                                    Media
                                </nz-form-label>
                                <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please select media!">
                                    <nz-input-group>
                                        <div class="media_div">
                                            <a (click)="showMediaModal()" nz-button nzType="primary"
                                                class="create-button">
                                                <i nz-icon nzType="upload" theme="outline"></i>
                                                <span>Upload Media</span>
                                            </a>
                                            <img class="media_preview" *ngIf="mediaId && mediaType == 'image'"
                                                [src]="mediaId.url" alt="">
                                            <img class="media_preview_demo" *ngIf="mediaId && mediaType == 'video'"
                                                src="../../../../assets/images/icons/video-icon.png" alt="">
                                            <img class="media_preview_demo" *ngIf="mediaId && mediaType == 'audio'"
                                                src="../../../../assets/images/icons/audio_icon.png" alt="">
                                            <img class="media_preview_demo" *ngIf="mediaId && mediaType == 'document'"
                                                src="../../../../assets/images/icons/document-icon.png" alt="">
                                        </div>
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                        </div>

                        <div *ngFor="let variable of templateVariables.variableTemplate;let k = index" class="">
                            <div nz-row [nzGutter]="24" class="nz_row">
                                <div nz-col [nzXs]="24" [nzSm]="24" [nzSpan]="12" class="condition_input">
                                    <h5>{{variable.type}}</h5>
                                    <div nz-row [nzGutter]="24" class="nz_row">
                                        <ng-container *ngFor="let field of variable.variables;let n = index">
                                            <div class="variable_index" nz-col [nzXs]="24" [nzSm]="3" [nzSpan]="12">
                                                {{'{{' + (n+1) + '}}'}}
                                            </div>
                                            <div nz-col [nzXs]="24" [nzSm]="10" [nzSpan]="12">
                                                <nz-form-item class="template_variable_value">
                                                    <nz-form-label class="large_label" nzRequired [nzSm]="24"
                                                        [nzXs]="24">
                                                        Value
                                                    </nz-form-label>
                                                    <nz-form-control [nzSm]="24" [nzXs]="24"
                                                        nzErrorTip="Please select value!">
                                                        <nz-input-group>
                                                            <!-- <nz-select [(ngModel)]="field.field"
                                                                [ngModelOptions]="{standalone: true}"
                                                                nzPlaceHolder="Select value" class="w-100">
                                                                <nz-option nzLabel="Use Fallback Value"
                                                                    nzValue="useFallbackValue">
                                                                </nz-option>
                                                                <nz-option *ngFor="let key of fieldData"
                                                                    [nzLabel]="key?.label" [nzValue]="key?.name">
                                                                </nz-option>
                                                            </nz-select> -->
                                                            <input [id]="'value' + n" [(ngModel)]="field.field"
                                                                [ngModelOptions]="{standalone: true}" autocomplete="off"
                                                                type="text" nz-input placeholder="Enter value">
                                                        </nz-input-group>
                                                        <p class="error_text" *ngIf="submitted && !field.field">
                                                            Please select field
                                                        </p>
                                                    </nz-form-control>
                                                </nz-form-item>

                                                <button (click)="apiVar(n , 'value'+n, 'value', k)" nz-dropdown
                                                    nzTrigger="click" [nzDropdownMenu]="apiVariable" type="button"
                                                    class="variable_add template_var" nz-button nzType="default">
                                                    Add variable
                                                </button>
                                            </div>
                                            <div nz-col [nzXs]="24" [nzSm]="11" [nzSpan]="12">
                                                <nz-form-item>
                                                    <nz-form-label class="large_label" nzRequired [nzSm]="24"
                                                        [nzXs]="24">
                                                        Fallback Value
                                                    </nz-form-label>
                                                    <nz-form-control [nzSm]="24" [nzXs]="24"
                                                        nzErrorTip="Please enter operator!">
                                                        <nz-input-group>
                                                            <input [(ngModel)]="field.fallbackValue"
                                                                [ngModelOptions]="{standalone: true}" autocomplete="off"
                                                                type="text" nz-input placeholder="Enter fallback value">
                                                        </nz-input-group>
                                                        <p class="error_text" *ngIf="submitted && !field.fallbackValue">
                                                            Please enter fallback value
                                                        </p>
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="messageType == 'IF_STATEMENT'">
                    <nz-form-item>
                        <nz-form-label class="large_label" nzRequired [nzSm]="24" [nzXs]="24">
                            Condition Type
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please select condition type!">
                            <nz-input-group>
                                <nz-select formControlName="conditionType" nzPlaceHolder="Select condition type"
                                    class="w-100">
                                    <nz-option nzLabel="OR" nzValue="OR"></nz-option>
                                    <nz-option nzLabel="AND" nzValue="AND"></nz-option>
                                </nz-select>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <div class="">
                        <div *ngFor="let condition of statementCondition;let k = index" class="condition_input">
                            <div nz-row [nzGutter]="24">
                                <div nz-col [nzXs]="24" [nzSm]="12" [nzSpan]="12">
                                    <nz-form-item>
                                        <nz-form-label class="large_label" nzRequired [nzSm]="24" [nzXs]="24">
                                            Operator
                                        </nz-form-label>
                                        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please select operator!">
                                            <nz-input-group>
                                                <nz-select [(ngModel)]="condition.operator"
                                                    [ngModelOptions]="{standalone: true}"
                                                    nzPlaceHolder="Select operator" class="w-100">
                                                    <nz-option *ngIf="condition.dataType == 'text'"
                                                        nzLabel="Is equals to" nzValue="is equals to"></nz-option>
                                                    <ng-container *ngIf="condition.dataType == 'boolean'">
                                                        <nz-option nzLabel="Is" nzValue="is"></nz-option>
                                                        <nz-option nzLabel="Is Not" nzValue="is not"></nz-option>
                                                    </ng-container>
                                                    <ng-container *ngIf="condition.dataType == 'array'">
                                                        <nz-option nzLabel="Is Empty" nzValue="is empty"></nz-option>
                                                        <nz-option nzLabel="Is Not Empty"
                                                            nzValue="is not empty"></nz-option>
                                                    </ng-container>
                                                </nz-select>
                                            </nz-input-group>
                                            <p class="error_text" *ngIf="submitted && !condition.operator">
                                                Please select operator
                                            </p>
                                        </nz-form-control>
                                    </nz-form-item>

                                    <nz-form-item>
                                        <nz-form-label class="large_label" nzRequired [nzSm]="24" [nzXs]="24">
                                            Key
                                        </nz-form-label>
                                        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please enter operator!">
                                            <nz-input-group>
                                                <input [id]="'condition'+k" [(ngModel)]="condition.key"
                                                    [ngModelOptions]="{standalone: true}" autocomplete="off" type="text"
                                                    nz-input placeholder="Enter operator">
                                                <!-- <nz-select nzShowSearch [(ngModel)]="condition.key"
                                                    [ngModelOptions]="{standalone: true}" required
                                                    nzPlaceHolder="Select variable kay" class="w-100">
                                                    <nz-option *ngFor="let item of flowVariables" [nzLabel]="item.name"
                                                        [nzValue]="'{{variables.' + item.name + '}}'"></nz-option>
                                                </nz-select> -->
                                            </nz-input-group>
                                            <p class="error_text" *ngIf="submitted && !condition.key">
                                                Please select key variable
                                            </p>
                                        </nz-form-control>
                                    </nz-form-item>
                                    <button (click)="apiVar(k , 'condition'+k , 'condition', 0)" nz-dropdown
                                        nzTrigger="click" [nzDropdownMenu]="apiVariable" type="button"
                                        class="variable_add" nz-button nzType="default">
                                        Add variable
                                    </button>
                                </div>

                                <div nz-col [nzXs]="24" [nzSm]="12" [nzSpan]="12">
                                    <nz-form-item>
                                        <nz-form-label class="large_label" nzRequired [nzSm]="24" [nzXs]="24">
                                            Data Type
                                        </nz-form-label>
                                        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please select data type!">
                                            <nz-input-group>
                                                <nz-select [(ngModel)]="condition.dataType"
                                                    [ngModelOptions]="{standalone: true}"
                                                    (ngModelChange)="condition.operator = ''"
                                                    nzPlaceHolder="Select data type" class="w-100">
                                                    <nz-option nzLabel="Text" nzValue="text"></nz-option>
                                                    <nz-option nzLabel="Boolean" nzValue="boolean"></nz-option>
                                                    <nz-option nzLabel="Array" nzValue="array"></nz-option>
                                                </nz-select>
                                            </nz-input-group>
                                            <p class="error_text" *ngIf="submitted && !condition.dataType">
                                                Please select data type
                                            </p>
                                        </nz-form-control>
                                    </nz-form-item>

                                    <nz-form-item>
                                        <nz-form-label class="large_label" nzRequired [nzSm]="24" [nzXs]="24">
                                            Value
                                        </nz-form-label>
                                        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please enter value!">
                                            <nz-input-group>
                                                <input [(ngModel)]="condition.value"
                                                    [ngModelOptions]="{standalone: true}" autocomplete="off" type="text"
                                                    nz-input placeholder="Enter value" required>
                                            </nz-input-group>
                                            <p class="error_text" *ngIf="submitted && !condition.value">
                                                Please enter value
                                            </p>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                            </div>
                            <span *ngIf="k > 0" (click)="removeStatement(k)" class="condition_delete" nz-icon
                                nzType="delete" nzTheme="outline"></span>
                        </div>
                        <a (click)="addStatementArr()" class="add_link">
                            <span class="" nz-icon nzType="plus-circle" nzTheme="outline"></span>
                            Add new condition
                        </a>
                    </div>
                </ng-container>

                <ng-container *ngIf="messageType == 'ASSIGN_CONVERSATION'">
                    <nz-form-item>
                        <nz-form-label class="large_label" nzRequired [nzSm]="24" [nzXs]="24">
                            Select Assign Type
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please select assign type!">
                            <nz-input-group>
                                <nz-select formControlName="assigneeType" (ngModelChange)="conversationChange('')"
                                    nzPlaceHolder="Select assign type" class="w-100">
                                    <nz-option nzLabel="User" nzValue="User"></nz-option>
                                    <nz-option nzLabel="Bot" nzValue="Flow"></nz-option>
                                </nz-select>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item *ngIf="addMessageForm.value.assigneeType == 'User'">
                        <nz-form-label class="large_label" nzRequired [nzSm]="24" [nzXs]="24">
                            Select User
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please select user!">
                            <nz-input-group>
                                <nz-select formControlName="assignee" nzPlaceHolder="Select user" class="w-100">
                                    <nz-option *ngFor="let user of allUsers"
                                        [nzLabel]="user?.firstName + ' ' + (user?.lastName ? user?.lastName : '')"
                                        [nzValue]="user._id"></nz-option>
                                </nz-select>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>
                </ng-container>

                <nz-form-item *ngIf="messageType == 'TRIGGER_PATH'">
                    <nz-form-label class="large_label" nzRequired [nzSm]="24" [nzXs]="24">
                        Select Path
                    </nz-form-label>
                    <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please enter message!">
                        <nz-input-group>
                            <nz-select nzShowSearch formControlName="triggerPath" nzPlaceHolder="Enter List Options"
                                class="w-100">
                                <nz-option *ngFor="let path of allPaths" [nzLabel]="path.name" [nzValue]="path._id">
                                </nz-option>
                            </nz-select>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <ng-container
                    *ngIf="outOptionTypes.indexOf(messageType) > -1 && messageType != 'API_CALL' && mediaTypes.indexOf(messageType) == -1 && messageType != 'SEND_PRODUCTS' && messageType != 'SEND_TEMPLATE' && messageType != 'ASSIGN_CONVERSATION' && messageType != 'SEND_LIST'">
                    <nz-form-item>
                        <nz-form-label class="large_label" nzRequired [nzSm]="24" [nzXs]="24">
                            Message
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24"
                            [nzErrorTip]="addMessageForm.value.message.length < 4096 ? 'Please enter message!' : 'Max limit 4096 reached!'">
                            <nz-input-group>
                                <textarea #messageField (mouseup)="mouseUp()" (select)="onTextSelect('message')"
                                    (focus)="bodyTextFocus()" formControlName="message" nz-input
                                    placeholder="Enter Message" [nzAutosize]="{ minRows: 6, maxRows: 6 }" required>
                                </textarea>
                                <div class="count">
                                    <span class="">{{addMessageForm.value.message.length}}/4096</span>
                                </div>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>
                    <ng-container [ngTemplateOutlet]="textEditor"
                        [ngTemplateOutletContext]="{editorData : {type :  'message'}}"></ng-container>
                </ng-container>

                <ng-container *ngIf="mediaTypes.indexOf(messageType) > -1">
                    <nz-form-item>
                        <nz-form-label class="large_label" nzRequired [nzSm]="24" [nzXs]="24">
                            Media
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please select media!">
                            <nz-input-group>
                                <div class="media_div">
                                    <a (click)="showMediaModal()" nz-button nzType="primary" class="create-button">
                                        <i nz-icon nzType="upload" theme="outline"></i>
                                        <span>Upload Media</span>
                                    </a>
                                    <img class="media_preview" *ngIf="mediaId && messageType == 'SEND_IMAGE'"
                                        [src]="mediaId.url" alt="">
                                    <img class="media_preview_demo" *ngIf="mediaId && messageType == 'SEND_VIDEO'"
                                        src="../../../../assets/images/icons/video-icon.png" alt="">
                                    <img class="media_preview_demo" *ngIf="mediaId && messageType == 'SEND_AUDIO'"
                                        src="../../../../assets/images/icons/audio_icon.png" alt="">
                                    <img class="media_preview_demo" *ngIf="mediaId && messageType == 'SEND_DOCUMENT'"
                                        src="../../../../assets/images/icons/document-icon.png" alt="">
                                </div>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <ng-container *ngIf="messageType != 'SEND_AUDIO'">
                        <nz-form-item>
                            <nz-form-label class="large_label" [nzSm]="24" [nzXs]="24">
                                Caption
                            </nz-form-label>
                            <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Max limit 4096 reached!">
                                <nz-input-group>
                                    <input #captionVariable (mouseup)="mouseUp()" (select)="onTextSelect('caption')"
                                        (focus)="bodyTextFocus()" autocomplete="off" formControlName="caption"
                                        type="text" nz-input placeholder="Enter Caption">
                                    <div class="count">
                                        <span class="">{{addMessageForm.value.caption.length}}/4096</span>
                                    </div>
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>
                        <ng-container [ngTemplateOutlet]="textEditor"
                            [ngTemplateOutletContext]="{editorData : {type :  'caption'}}"></ng-container>
                    </ng-container>

                    <!-- <nz-form-item *ngIf="messageType == 'SEND_DOCUMENT'">
                        <nz-form-label class="large_label" nzRequired [nzSm]="24" [nzXs]="24">
                            Document Name
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please enter document name!">
                            <nz-input-group>
                                <input autocomplete="off" formControlName="documentName" type="text" nz-input
                                    placeholder="Enter Document Name" required>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item> -->
                </ng-container>

                <ng-container *ngIf="messageType == 'SEND_BUTTONS'">
                    <nz-form-item>
                        <nz-form-label class="large_label center_label" nzRequired [nzSm]="12" [nzXs]="12">
                            Has Media
                        </nz-form-label>
                        <nz-form-control [nzSm]="12" [nzXs]="12" nzErrorTip="Please enter error message!">
                            <nz-input-group class="right_input">
                                <nz-switch formControlName="hasMedia"></nz-switch>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <ng-container *ngIf="!addMessageForm.value.hasMedia">
                        <nz-form-item>
                            <nz-form-label class="large_label" [nzSm]="24" [nzXs]="24">
                                Header Text
                            </nz-form-label>
                            <nz-form-control [nzSm]="24" [nzXs]="24"
                                [nzErrorTip]="addMessageForm.value.headerText.length < 60 ? 'Please enter header text!' : 'Max limit 60 reached!'">
                                <nz-input-group>
                                    <input #hederVariable (mouseup)="mouseUp()" (select)="onTextSelect('headerText')"
                                        (focus)="bodyTextFocus()" formControlName="headerText" autocomplete="off"
                                        type="text" nz-input placeholder="Enter header Text">
                                    <div class="count">
                                        <span class="">{{addMessageForm.value.headerText.length}}/60</span>
                                    </div>
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>
                        <ng-container [ngTemplateOutlet]="textEditor"
                            [ngTemplateOutletContext]="{editorData : {type :  'headerText'}}"></ng-container>
                    </ng-container>

                    <ng-container *ngIf="addMessageForm.value.hasMedia">
                        <nz-form-item>
                            <nz-form-label nzRequired class="large_label" [nzSm]="24" [nzXs]="24">
                                Media Type
                            </nz-form-label>
                            <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please select type!">
                                <nz-input-group>
                                    <nz-select nzPlaceHolder="select type" formControlName="buttonMediaType"
                                        (ngModelChange)="mediaTypeChange()" class="w-100">
                                        <nz-option nzLabel="Image" nzValue="image"></nz-option>
                                        <nz-option nzLabel="Video" nzValue="video"></nz-option>
                                        <!-- <nz-option nzLabel="Audio" nzValue="audio"></nz-option> -->
                                        <nz-option nzLabel="Document" nzValue="document"></nz-option>
                                    </nz-select>
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>

                        <nz-form-item>
                            <nz-form-label class="large_label" nzRequired [nzSm]="24" [nzXs]="24">
                                Media
                            </nz-form-label>
                            <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please select media!">
                                <nz-input-group>
                                    <div class="media_div">
                                        <a (click)="showMediaModal()" nz-button nzType="primary" class="create-button">
                                            <i nz-icon nzType="upload" theme="outline"></i>
                                            <span>Upload Media</span>
                                        </a>
                                        <img class="media_preview" *ngIf="mediaId && mediaType == 'image'"
                                            [src]="mediaId.url" alt="">
                                        <img class="media_preview_demo" *ngIf="mediaId && mediaType == 'video'"
                                            src="../../../../assets/images/icons/video-icon.png" alt="">
                                        <img class="media_preview_demo" *ngIf="mediaId && mediaType == 'audio'"
                                            src="../../../../assets/images/icons/audio_icon.png" alt="">
                                        <img class="media_preview_demo" *ngIf="mediaId && mediaType == 'document'"
                                            src="../../../../assets/images/icons/document-icon.png" alt="">
                                    </div>
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>
                    </ng-container>

                    <nz-form-item>
                        <nz-form-label class="large_label" nzRequired [nzSm]="24" [nzXs]="24">
                            Body Text
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24"
                            [nzErrorTip]="addMessageForm.value.bodyText.length < 4096 ? 'Please enter body text!' : 'Max limit 4096 reached!'">
                            <nz-input-group>
                                <textarea #messageField (mouseup)="mouseUp()" (select)="onTextSelect('bodyText')"
                                    (focus)="bodyTextFocus()" formControlName="bodyText" nz-input
                                    placeholder="Enter Body Text" [nzAutosize]="{ minRows: 6, maxRows: 6 }" required>
                                </textarea>
                                <div class="count">
                                    <span class="">{{addMessageForm.value.bodyText.length}}/4096</span>
                                </div>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <ng-container [ngTemplateOutlet]="textEditor"
                        [ngTemplateOutletContext]="{editorData : {type :  'bodyText'}}">
                    </ng-container>

                    <nz-form-item>
                        <nz-form-label class="large_label" [nzSm]="24" [nzXs]="24">
                            Footer Text
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24"
                            [nzErrorTip]="addMessageForm.value.footerText.length < 60 ? 'Please enter footer text!' : 'Max limit 60 reached!'">
                            <nz-input-group>
                                <input #footerVariable (mouseup)="mouseUp()" (select)="onTextSelect('footerText')"
                                    (focus)="bodyTextFocus()" formControlName="footerText" autocomplete="off"
                                    type="text" nz-input placeholder="Enter footer text">
                                <div class="count">
                                    <span class="">{{addMessageForm.value.footerText.length}}/60</span>
                                </div>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>
                    <ng-container [ngTemplateOutlet]="textEditor"
                        [ngTemplateOutletContext]="{editorData : {type :  'footerText'}}">
                    </ng-container>

                    <div class="">
                        <nz-form-item *ngFor="let button of buttonsList ; let i = index">
                            <nz-form-label class="large_label" nzRequired [nzSm]="24" [nzXs]="24">
                                {{'Button ' + (i + 1)}}
                            </nz-form-label>
                            <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please enter buttons!">
                                <nz-input-group>
                                    <!-- <nz-select formControlName="buttons" nzMode="tags" [nzMaxMultipleCount]="3"
                                        nzPlaceHolder="Enter Buttons" class="w-100">
                                    </nz-select> -->
                                    <input [ngClass]="{'error_input' : submitted && !button.title}"
                                        [(ngModel)]="button.title" [ngModelOptions]="{standalone: true}"
                                        autocomplete="off" type="text" maxlength="20"
                                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                        nz-input placeholder="Enter Button Text">
                                    <div class="count">
                                        <span class="">{{button.title.length}}/20</span>
                                    </div>
                                </nz-input-group>
                                <p class="error_text" *ngIf="submitted && !button.title">
                                    Please enter value for button
                                </p>
                            </nz-form-control>
                            <span (click)="removeButton(i)" *ngIf="i > 0" class="plus_circle" nz-icon
                                nzType="minus-circle" nzTheme="outline"></span>
                        </nz-form-item>

                        <a *ngIf="buttonsList.length < 3" (click)="addButtonArr()" class="add_link">
                            <span class="" nz-icon nzType="plus-circle" nzTheme="outline"></span>
                            Add new button
                        </a>
                    </div>

                    <nz-form-item class="mt-3">
                        <nz-form-label class="large_label" [nzSm]="24" [nzXs]="24">
                            Store response in the variable
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Maximum limit 200!">
                            <nz-input-group>
                                <nz-select #responseVar nzShowSearch nzAllowClear formControlName="responseVariable"
                                    nzPlaceHolder="Select variable to store response" class="w-100"
                                    (ngModelChange)="addNewVariable()" (keyup)="detectAvailVariables($event)">
                                    <nz-option *ngFor="let item of flowVariables" [nzLabel]="item.name"
                                        [nzValue]="item.name"></nz-option>
                                    <nz-option *ngIf="newVariable" [nzLabel]="'Create new variable : ' + newVariable"
                                        [nzValue]="'new variable'"></nz-option>
                                </nz-select>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>
                </ng-container>

                <ng-container *ngIf="messageType == 'SEND_LIST'">
                    <nz-form-item>
                        <nz-form-label class="large_label" [nzSm]="24" [nzXs]="24">
                            Header Text
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24"
                            [nzErrorTip]="addMessageForm.value.headerText.length < 60 ? 'Please enter header text!' : 'Max limit 60 reached!'">
                            <nz-input-group>
                                <input #hederVariable (mouseup)="mouseUp()" (select)="onTextSelect('headerText')"
                                    (focus)="bodyTextFocus()" formControlName="headerText" autocomplete="off"
                                    type="text" nz-input placeholder="Enter header text">
                                <div class="count">
                                    <span class="">{{addMessageForm.value.headerText.length}}/60</span>
                                </div>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <ng-container [ngTemplateOutlet]="textEditor"
                        [ngTemplateOutletContext]="{editorData : {type :  'headerText'}}"></ng-container>

                    <nz-form-item>
                        <nz-form-label class="large_label" nzRequired [nzSm]="24" [nzXs]="24">
                            Body Text
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24"
                            [nzErrorTip]="addMessageForm.value.bodyText.length < 4096 ? 'Please enter body text!' : 'Max limit 4096 reached!'">
                            <nz-input-group>
                                <textarea #messageField (mouseup)="mouseUp()" (select)="onTextSelect('bodyText')"
                                    (focus)="bodyTextFocus()" formControlName="bodyText" nz-input
                                    placeholder="Enter Body Text" [nzAutosize]="{ minRows: 6, maxRows: 6 }" required>
                                </textarea>
                                <div class="count">
                                    <span class="">{{addMessageForm.value.bodyText.length}}/4096</span>
                                </div>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>
                    <ng-container [ngTemplateOutlet]="textEditor"
                        [ngTemplateOutletContext]="{editorData : {type :  'bodyText'}}"></ng-container>

                    <nz-form-item>
                        <nz-form-label class="large_label" [nzSm]="24" [nzXs]="24">
                            Footer Text
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24"
                            [nzErrorTip]="addMessageForm.value.footerText.length < 60 ? 'Please enter footer text!' : 'Max limit 60 reached!'">
                            <nz-input-group>
                                <input #footerVariable formControlName="footerText" autocomplete="off" type="text"
                                    nz-input placeholder="Enter footer text">
                                <div class="count">
                                    <span class="">{{addMessageForm.value.footerText.length}}/60</span>
                                </div>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-dropdown-menu #variableMenu="nzDropdownMenu">
                        <ul nz-menu class="dropdown_menu">
                            <div class="variable_search">
                                <input [(ngModel)]="variableSearch" [ngModelOptions]="{standalone: true}"
                                    autocomplete="off" nz-input placeholder="search" type="text" />
                            </div>
                            <span *ngIf="flowVariables?.length > 0" class="drop_heading">Variables</span>
                            <li (click)="addVariable(item.name, 'variables', 'footerText')" nz-menu-item
                                *ngFor="let item of flowVariables | filter : variableSearch">
                                {{item.name}}</li>
                            <li nz-menu-divider></li>
                            <span class="drop_heading">Customer fields</span>
                            <li (click)="addVariable(field.label, 'customer', 'footerText')" nz-menu-item
                                *ngFor="let field of customerFields | filter : variableSearch">{{field.label}}</li>
                        </ul>
                    </nz-dropdown-menu>

                    <nz-form-item>
                        <nz-form-label nzRequired class="large_label" [nzSm]="24" [nzXs]="24">
                            Button text
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please enter button text!">
                            <nz-input-group>
                                <input formControlName="buttonText" autocomplete="off" type="text" nz-input
                                    placeholder="Enter button Text" required>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item>
                        <nz-form-label class="large_label" nzRequired [nzSm]="24" [nzXs]="24">
                            List Type
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please select list type!">
                            <nz-input-group>
                                <nz-select formControlName="listType" nzPlaceHolder="Select list type" class="w-100"
                                    required>
                                    <nz-option nzLabel="Static" nzValue="static"></nz-option>
                                    <nz-option nzLabel="Dynamic" nzValue="dynamic"></nz-option>
                                </nz-select>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <div *ngIf="addMessageForm.value.listType == 'static'" class="">
                        <div *ngFor="let menu of menuList ; let k = index" class="condition_input">
                            <nz-form-item>
                                <nz-form-label class="large_label" nzRequired [nzSm]="24" [nzXs]="24">
                                    List Title {{k+1}}
                                </nz-form-label>
                                <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please enter list title!">
                                    <nz-input-group>
                                        <input [ngClass]="{'error_input' : submitted && !menu.menu}"
                                            [(ngModel)]="menu.menu" [ngModelOptions]="{standalone: true}"
                                            autocomplete="off" type="text" nz-input placeholder="Enter List Title"
                                            required>
                                    </nz-input-group>
                                    <p class="error_text" *ngIf="submitted && !menu.menu">
                                        Please enter list title
                                    </p>
                                </nz-form-control>
                            </nz-form-item>

                            <div *ngFor="let opt of menu.list; let j = index" class="static_list_options">
                                <nz-form-item class="">
                                    <nz-form-label class="large_label" nzRequired [nzSm]="24" [nzXs]="24">
                                        Option
                                    </nz-form-label>
                                    <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please enter list option!">
                                        <nz-input-group>
                                            <input [ngClass]="{'error_input' : submitted && !opt.title}"
                                                [(ngModel)]="opt.title" [ngModelOptions]="{standalone: true}"
                                                autocomplete="off" type="text" nz-input placeholder="Enter list option"
                                                maxlength="24"
                                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                                            <div class="count">
                                                <span class="">{{opt.title.length}}/24</span>
                                            </div>
                                        </nz-input-group>
                                        <p class="error_text" *ngIf="submitted && !opt.title">
                                            Please enter list option
                                        </p>

                                    </nz-form-control>
                                </nz-form-item>

                                <nz-form-item class="">
                                    <nz-form-label class="large_label" [nzSm]="24" [nzXs]="24">
                                        Description
                                    </nz-form-label>
                                    <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please enter list options!">
                                        <nz-input-group>
                                            <input [(ngModel)]="opt.description" [ngModelOptions]="{standalone: true}"
                                                autocomplete="off" type="text" nz-input placeholder="Enter list option">
                                        </nz-input-group>
                                        <!-- <p class="error_text" *ngIf="submitted && !opt.description">
                                            Please enter list description
                                        </p> -->
                                    </nz-form-control>
                                </nz-form-item>
                                <span (click)="removeOption(k , j)" class="condition_delete" nz-icon nzType="delete"
                                    nzTheme="outline"></span>
                            </div>
                            <a *ngIf="getListCount('menu') < 10" (click)="addOption(k)" class="add_link">
                                New option
                            </a>
                            <span *ngIf="k > 0" (click)="removeMenu(k)" class="condition_delete" nz-icon nzType="delete"
                                nzTheme="outline"></span>
                        </div>

                        <a *ngIf="getListCount('menu') < 10 && menuList?.length < 10" (click)="addMenu()"
                            class="add_link">
                            <span class="plus_circle" nz-icon nzType="plus-circle" nzTheme="outline"></span>
                            Add new menu
                        </a>
                    </div>

                    <div *ngIf="addMessageForm.value.listType == 'dynamic'" class="">
                        <div class="condition_input">
                            <div nz-row [nzGutter]="24">
                                <div nz-col [nzXs]="24" [nzSm]="12" [nzSpan]="12">
                                    <nz-form-item>
                                        <nz-form-label class="large_label" nzRequired [nzSm]="24" [nzXs]="24">
                                            Variable Name
                                        </nz-form-label>
                                        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please select variable!">
                                            <nz-input-group>
                                                <!-- <input autocomplete="off" formControlName="variableName" type="text"
                                                nz-input placeholder="Enter variable name" required> -->
                                                <nz-select nzShowSearch formControlName="variableName" required
                                                    nzPlaceHolder="Select variable" class="w-100">
                                                    <nz-option *ngFor="let item of flowVariables" [nzLabel]="item.name"
                                                        [nzValue]="item.name"></nz-option>
                                                </nz-select>
                                            </nz-input-group>
                                        </nz-form-control>
                                    </nz-form-item>

                                    <nz-form-item>
                                        <nz-form-label class="large_label" nzRequired [nzSm]="24" [nzXs]="24">
                                            Variable Key Title
                                        </nz-form-label>
                                        <nz-form-control [nzSm]="24" [nzXs]="24"
                                            nzErrorTip="Please enter variable key title!">
                                            <nz-input-group>
                                                <input autocomplete="off" formControlName="variableTitle" type="text"
                                                    nz-input placeholder="Enter variable key title" required>
                                            </nz-input-group>
                                        </nz-form-control>
                                    </nz-form-item>

                                    <nz-form-item>
                                        <nz-form-label class="large_label" nzRequired [nzSm]="24" [nzXs]="24">
                                            Error Attempt
                                        </nz-form-label>
                                        <nz-form-control [nzSm]="24" [nzXs]="24"
                                            nzErrorTip="Please enter error attempt!">
                                            <nz-input-group>
                                                <input autocomplete="off" formControlName="errorAttempt" type="number"
                                                    nz-input placeholder="Enter Error Attempt" required>
                                            </nz-input-group>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>

                                <div nz-col [nzXs]="24" [nzSm]="12" [nzSpan]="12">
                                    <nz-form-item>
                                        <nz-form-label class="large_label" nzRequired [nzSm]="24" [nzXs]="24">
                                            Variable Path
                                        </nz-form-label>
                                        <nz-form-control [nzSm]="24" [nzXs]="24"
                                            nzErrorTip="Please enter variable path!">
                                            <nz-input-group>
                                                <input autocomplete="off" formControlName="variablePath" type="text"
                                                    nz-input placeholder="Enter variable path" required>
                                            </nz-input-group>
                                        </nz-form-control>
                                    </nz-form-item>

                                    <nz-form-item>
                                        <nz-form-label class="large_label" nzRequired [nzSm]="24" [nzXs]="24">
                                            Variable Key Description
                                        </nz-form-label>
                                        <nz-form-control [nzSm]="24" [nzXs]="24"
                                            nzErrorTip="Please enter variable key description!">
                                            <nz-input-group>
                                                <input autocomplete="off" formControlName="variableDescription"
                                                    type="text" nz-input placeholder="Enter variable key description"
                                                    required>
                                            </nz-input-group>
                                        </nz-form-control>
                                    </nz-form-item>

                                    <nz-form-item>
                                        <nz-form-label class="large_label" nzRequired [nzSm]="24" [nzXs]="24">
                                            Error Message
                                        </nz-form-label>
                                        <nz-form-control [nzSm]="24" [nzXs]="24"
                                            [nzErrorTip]="addMessageForm.value.errorMessage.length < 4000 ? 'Please enter error message!' : 'Max limit 4096 reached!'">
                                            <nz-input-group>
                                                <input autocomplete="off" formControlName="errorMessage" type="text"
                                                    nz-input placeholder="Enter Error Message" required>
                                                <div class="count">
                                                    <span
                                                        class="">{{addMessageForm.value.errorMessage.length}}/4000</span>
                                                </div>
                                            </nz-input-group>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                            </div>
                        </div>
                        <nz-form-item>
                            <nz-form-label class="large_label" nzRequired [nzSm]="24" [nzXs]="24">
                                Action on invalid
                            </nz-form-label>
                            <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please select invalid action!">
                                <nz-input-group>
                                    <nz-radio-group formControlName="onInvalidResponse">
                                        <label nz-radio nzValue="End-the-flow">End the flow</label>
                                        <label nz-radio nzValue="Skip-the-flow">Skip the question</label>
                                    </nz-radio-group>
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <nz-form-item class="mt-3">
                        <nz-form-label class="large_label" [nzSm]="24" [nzXs]="24">
                            Store response in the variable
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Maximum limit 200!">
                            <nz-input-group>
                                <nz-select #responseVar nzShowSearch nzAllowClear formControlName="responseVariable"
                                    nzPlaceHolder="Select variable to store response" class="w-100"
                                    (ngModelChange)="addNewVariable()" (keyup)="detectAvailVariables($event)">
                                    <nz-option *ngFor="let item of flowVariables" [nzLabel]="item.name"
                                        [nzValue]="item.name"></nz-option>
                                    <nz-option *ngIf="newVariable" [nzLabel]="'Create new variable : ' + newVariable"
                                        [nzValue]="'new variable'"></nz-option>
                                </nz-select>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>
                </ng-container>

                <ng-container *ngIf="askTypes.indexOf(messageType) > -1">
                    <ng-container *ngIf="messageType == 'SELECT_ADDRESS_FROM_THE_LIST'">
                        <nz-form-item>
                            <nz-form-label nzRequired class="large_label" [nzSm]="24" [nzXs]="24">
                                Variable
                            </nz-form-label>
                            <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please select variable!">
                                <nz-input-group>
                                    <nz-select nzPlaceHolder="select variable" formControlName="variable" class="w-100"
                                        required>
                                        <nz-option *ngFor="let item of flowVariables" [nzLabel]="item.name"
                                            [nzValue]="item.name"></nz-option>
                                    </nz-select>
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>

                        <nz-form-item>
                            <nz-form-label class="large_label" [nzSm]="24" [nzXs]="24">
                                Variable Path
                            </nz-form-label>
                            <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please enter variable path">
                                <nz-input-group>
                                    <input autocomplete="off" formControlName="path" type="text" nz-input
                                        placeholder="Enter variable path">
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>
                    </ng-container>

                    <nz-form-item *ngIf="messageType == 'ASK_FILE'">
                        <nz-form-label class="large_label" [nzSm]="24" [nzXs]="24">
                            File Type
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please select file type!">
                            <nz-input-group>
                                <nz-select nzPlaceHolder="select file type" formControlName="fileType" class="w-100">
                                    <nz-option nzLabel="Image" nzValue="image"></nz-option>
                                    <nz-option nzLabel="Video" nzValue="video"></nz-option>
                                    <nz-option nzLabel="Audio" nzValue="audio"></nz-option>
                                    <nz-option nzLabel="Document" nzValue="document"></nz-option>
                                </nz-select>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item *ngIf="messageType == 'ASK_DATE'">
                        <nz-form-label class="large_label" [nzSm]="24" [nzXs]="24">
                            Date Format
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please select date format!">
                            <nz-input-group>
                                <nz-select nzPlaceHolder="select date format" formControlName="dateFormat"
                                    class="w-100">
                                    <nz-option *ngFor="let format of dateFormats" [nzLabel]="format" [nzValue]="format">
                                    </nz-option>
                                </nz-select>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item>
                        <nz-form-label class="large_label" nzRequired [nzSm]="24" [nzXs]="24">
                            Error Attempt
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please enter error attempt!">
                            <nz-input-group>
                                <input autocomplete="off" formControlName="errorAttempt" type="number" nz-input
                                    placeholder="Enter Error Attempt" required>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item>
                        <nz-form-label class="large_label" nzRequired [nzSm]="24" [nzXs]="24">
                            Error Message
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24"
                            [nzErrorTip]="addMessageForm.value.errorMessage.length < 4000 ? 'Please enter error message!' : 'Max limit 4096 reached!'">
                            <nz-input-group>
                                <input autocomplete="off" formControlName="errorMessage" type="text" nz-input
                                    placeholder="Enter Error Message" required>
                                <div class="count">
                                    <span class="">{{addMessageForm.value.errorMessage.length}}/4000</span>
                                </div>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item>
                        <nz-form-label class="large_label" nzRequired [nzSm]="24" [nzXs]="24">
                            Action on invalid
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please select invalid action!">
                            <nz-input-group>
                                <nz-radio-group formControlName="onInvalidResponse">
                                    <label nz-radio nzValue="End-the-flow">End the flow</label>
                                    <label nz-radio nzValue="Skip-the-flow">Skip the question</label>
                                </nz-radio-group>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <div *ngIf="askValidators.indexOf(messageType) > -1 " class="">
                        <nz-form-item>
                            <nz-form-label class="large_label center_label" nzRequired [nzSm]="12" [nzXs]="12">
                                Validation Setting
                                <!-- <nz-switch [ngModel]="true"></nz-switch> -->
                            </nz-form-label>
                            <nz-form-control [nzSm]="12" [nzXs]="12" nzErrorTip="Please enter error message!">
                                <nz-input-group class="right_input">
                                    <nz-switch formControlName="validationSetting"></nz-switch>
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>

                        <ng-container *ngIf="addMessageForm.value.validationSetting">
                            <nz-form-item>
                                <nz-form-label class="large_label" nzRequired [nzSm]="24" [nzXs]="24">
                                    {{messageType == 'ASK_DATE' ? 'Start Date' :
                                    (messageType == 'ASK_NUMBER' ? 'Min Value' : 'Min Characters')}}
                                </nz-form-label>
                                <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please enter minimum value!">
                                    <nz-input-group>
                                        <nz-date-picker *ngIf="messageType == 'ASK_DATE'" class="w-100" nzShowToday
                                            formControlName="minValue"
                                            [nzFormat]="addMessageForm.value.dateFormat.replaceAll('DD' , 'dd')">
                                        </nz-date-picker>
                                        <input *ngIf="messageType != 'ASK_DATE'" autocomplete="off"
                                            formControlName="minValue" type="number" nz-input
                                            placeholder="Enter minimum value" required>
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>

                            <nz-form-item>
                                <nz-form-label class="large_label" nzRequired [nzSm]="24" [nzXs]="24">
                                    {{messageType == 'ASK_DATE' ? 'End Date' :
                                    (messageType == 'ASK_NUMBER' ? 'Max Value' : 'Max Characters')}}
                                </nz-form-label>
                                <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please enter maximum value!">
                                    <nz-input-group>
                                        <nz-date-picker *ngIf="messageType == 'ASK_DATE'" class="w-100" nzShowToday
                                            formControlName="maxValue"
                                            [nzFormat]="addMessageForm.value.dateFormat.replaceAll('DD' , 'dd')">
                                        </nz-date-picker>
                                        <input *ngIf="messageType != 'ASK_DATE'" autocomplete="off"
                                            formControlName="maxValue" type="number" nz-input
                                            placeholder="Enter maximum value" required>
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                        </ng-container>
                    </div>

                    <nz-form-item *ngIf="askCustomerFields.indexOf(messageType) > -1">
                        <nz-form-label class="large_label" [nzSm]="24" [nzXs]="24">
                            Customer Field
                            <span nz-tooltip nzTooltipTitle="In which filed to update the user answer" class="info_icon"
                                nz-icon nzType="info-circle" nzTheme="outline">
                            </span>
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please select date format!">
                            <nz-input-group>
                                <nz-select nzPlaceHolder="select date format" nzAllowClear formControlName="fieldData"
                                    class="w-100">
                                    <ng-container *ngFor="let field of customerFields">
                                        <nz-option *ngIf="fieldType == field.dataType" [nzLabel]="field.label"
                                            [nzValue]="field._id">
                                        </nz-option>
                                    </ng-container>
                                </nz-select>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item *ngIf="askNoCustomFields.indexOf(messageType) == -1">
                        <nz-form-label class="large_label" [nzSm]="24" [nzXs]="24">
                            Store response in the variable
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Maximum limit 200!">
                            <nz-input-group>
                                <nz-select #responseVar nzShowSearch nzAllowClear formControlName="responseVariable"
                                    nzPlaceHolder="Select variable to store response" class="w-100"
                                    (ngModelChange)="addNewVariable()" (keyup)="detectAvailVariables($event)">
                                    <nz-option *ngFor="let item of flowVariables" [nzLabel]="item.name"
                                        [nzValue]="item.name"></nz-option>
                                    <nz-option *ngIf="newVariable" [nzLabel]="'Create new variable : ' + newVariable"
                                        [nzValue]="'new variable'"></nz-option>
                                </nz-select>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>
                </ng-container>

                <ng-container *ngIf="messageType == 'API_CALL'">
                    <nz-form-item>
                        <nz-form-label class="large_label" nzRequired [nzSm]="24" [nzXs]="24">
                            Method
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please select method!">
                            <nz-input-group>
                                <nz-select nzPlaceHolder="select method" formControlName="method" class="w-100">
                                    <nz-option *ngFor="let method of apiMethods" [nzLabel]="method" [nzValue]="method">
                                    </nz-option>
                                </nz-select>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item>
                        <nz-form-label class="large_label" nzRequired [nzSm]="24" [nzXs]="24">
                            URL
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please enter url!">
                            <nz-input-group>
                                <input #urlVariable (mouseup)="mouseUp()" (select)="onTextSelect('url')"
                                    (focus)="bodyTextFocus()" formControlName="url" autocomplete="off" type="text"
                                    nz-input placeholder="Enter url">
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>
                    <ng-container [ngTemplateOutlet]="textEditor"
                        [ngTemplateOutletContext]="{editorData : {type :  'url'}}"></ng-container>

                    <nz-form-item>
                        <nz-form-label class="large_label" nzRequired [nzSm]="24" [nzXs]="24">
                            Content Type
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please select content type!">
                            <nz-input-group>
                                <nz-select nzPlaceHolder="select content type" formControlName="contentType"
                                    class="w-100">
                                    <nz-option nzLabel="JSON" nzValue="application/json"></nz-option>
                                    <!-- <nz-option nzLabel="URL Encoded"
                                        nzValue="application/x-www-form-urlencoded"></nz-option> -->
                                </nz-select>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <div class="">
                        <div class="condition_input">
                            <h5>Headers</h5>

                            <div *ngFor="let header of apiHeaders; let s = index"
                                class="static_list_options api_header">
                                <nz-form-item class="">
                                    <nz-form-label class="large_label" nzRequired [nzSm]="24" [nzXs]="24">
                                        Key
                                    </nz-form-label>
                                    <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please select Key!">
                                        <nz-input-group>
                                            <input [ngClass]="{'error_input' : submitted && !header.key}"
                                                [(ngModel)]="header.key" [ngModelOptions]="{standalone: true}"
                                                autocomplete="off" type="text" nz-input placeholder="Enter Key">
                                        </nz-input-group>
                                        <p class="error_text" *ngIf="submitted && !header.key">
                                            Please enter Key
                                        </p>
                                    </nz-form-control>
                                </nz-form-item>

                                <div class="">
                                    <nz-form-item class="">
                                        <nz-form-label class="large_label" nzRequired [nzSm]="24" [nzXs]="24">
                                            Value
                                        </nz-form-label>
                                        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please enter Value!">
                                            <nz-input-group>
                                                <input [id]="'header' + s"
                                                    [ngClass]="{'error_input' : submitted && !header.value}"
                                                    [(ngModel)]="header.value" [ngModelOptions]="{standalone: true}"
                                                    autocomplete="off" type="text" nz-input placeholder="Enter Value">
                                            </nz-input-group>
                                            <p class="error_text" *ngIf="submitted && !header.value">
                                                Please enter Value
                                            </p>
                                        </nz-form-control>
                                    </nz-form-item>
                                    <button (click)="apiVar(s , 'header'+s, 'header' , 0)" nz-dropdown nzTrigger="click"
                                        [nzDropdownMenu]="apiVariable" type="button" class="variable_add" nz-button
                                        nzType="default">
                                        Add variable
                                    </button>
                                </div>

                                <span (click)="removeHeader(s)" class="condition_delete" nz-icon nzType="delete"
                                    nzTheme="outline"></span>
                            </div>
                            <a (click)="addHeader()" class="add_link">
                                Add header
                            </a>
                            <span *ngIf="k > 0" (click)="removeMenu(k)" class="condition_delete" nz-icon nzType="delete"
                                nzTheme="outline"></span>
                        </div>
                        <div class="condition_input">
                            <h5>QueryParams</h5>

                            <div *ngFor="let query of apiQueryParams; let s = index"
                                class="static_list_options api_header">
                                <nz-form-item class="">
                                    <nz-form-label class="large_label" nzRequired [nzSm]="24" [nzXs]="24">
                                        Key
                                    </nz-form-label>
                                    <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please enter Key!">
                                        <nz-input-group>
                                            <input [ngClass]="{'error_input' : submitted && !query.key}"
                                                [(ngModel)]="query.key" [ngModelOptions]="{standalone: true}"
                                                autocomplete="off" type="text" nz-input placeholder="Enter Key">
                                        </nz-input-group>
                                        <p class="error_text" *ngIf="submitted && !query.key">
                                            Please enter Key
                                        </p>
                                    </nz-form-control>
                                </nz-form-item>

                                <div class="">
                                    <nz-form-item class="">
                                        <nz-form-label class="large_label" nzRequired [nzSm]="24" [nzXs]="24">
                                            Value
                                        </nz-form-label>
                                        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please enter Value!">
                                            <nz-input-group>
                                                <input [id]="'query' + s"
                                                    [ngClass]="{'error_input' : submitted && !query.value}"
                                                    [(ngModel)]="query.value" [ngModelOptions]="{standalone: true}"
                                                    autocomplete="off" type="text" nz-input placeholder="Enter Value">
                                            </nz-input-group>
                                            <p class="error_text" *ngIf="submitted && !query.value">
                                                Please enter Value
                                            </p>
                                        </nz-form-control>
                                    </nz-form-item>
                                    <button (click)="apiVar(s , 'query'+s , 'queryParams', 0)" nz-dropdown
                                        nzTrigger="click" [nzDropdownMenu]="apiVariable" type="button"
                                        class="variable_add" nz-button nzType="default">
                                        Add variable
                                    </button>
                                </div>
                                <span (click)="removeQuery(s)" class="condition_delete" nz-icon nzType="delete"
                                    nzTheme="outline"></span>
                            </div>
                            <a (click)="addQuery()" class="add_link">
                                Add params
                            </a>
                            <span *ngIf="k > 0" (click)="removeMenu(k)" class="condition_delete" nz-icon nzType="delete"
                                nzTheme="outline"></span>
                        </div>
                    </div>

                    <!-- <nz-form-item>
                        <nz-form-label class="large_label" [nzSm]="24" [nzXs]="24">
                            Headers
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please enter headers data!">
                            <nz-input-group>
                                <input #headerText (focus)="showEditor('headers')" value="{}" autocomplete="off"
                                    type="text" nz-input placeholder="Enter headers data">
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item> -->

                    <!-- <nz-form-item>
                        <nz-form-label class="large_label" [nzSm]="24" [nzXs]="24">
                            QueryParams
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please enter queryParams!">
                            <nz-input-group>
                                <input #queryParams (focus)="showEditor('queryParams')" value="{}" autocomplete="off"
                                    type="text" nz-input placeholder="Enter queryParams">
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item> -->

                    <nz-form-item>
                        <nz-form-label class="large_label" [nzSm]="24" [nzXs]="24">
                            Body
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please enter body data!">
                            <nz-input-group>
                                <input #bodyText (focus)="showEditor('bodyText')" [value]="apiBody" autocomplete="off"
                                    type="text" nz-input placeholder="Enter body data">
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <div class="mb-3" *ngIf="codeEditor">
                        <ngx-codemirror #codemirror [options]="codeMirrorOptions" [(ngModel)]="apiBody"
                            [ngModelOptions]="{standalone: true}">
                        </ngx-codemirror>
                        <button (click)="variableSearch = ''" type="button" class="variable_add mt-2" nz-dropdown
                            nzTrigger="click" [nzDropdownMenu]="editorMenu" nz-button nzType="default">
                            Add variable
                        </button>
                    </div>


                    <nz-form-item>
                        <nz-form-label class="large_label" [nzSm]="24" [nzXs]="24">
                            Variable Type
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please select variable type!">
                            <nz-input-group>
                                <nz-select nzAllowClear nzPlaceHolder="select variable type"
                                    formControlName="variableType" class="w-100">
                                    <nz-option *ngFor="let variable of apiVariableType" [nzLabel]="variable"
                                        [nzValue]="variable"></nz-option>
                                </nz-select>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item class="mt-3">
                        <nz-form-label class="large_label" [nzSm]="24" [nzXs]="24">
                            Store response in the variable
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Maximum limit 200!">
                            <nz-input-group>
                                <nz-select #responseVar nzShowSearch nzAllowClear formControlName="responseVariable"
                                    nzPlaceHolder="Select variable to store response" class="w-100"
                                    (ngModelChange)="addNewVariable()" (keyup)="detectAvailVariables($event)">
                                    <ng-container *ngFor="let item of flowVariables">
                                        <nz-option *ngIf="item?.data?.dataType == addMessageForm.value.variableType"
                                            [nzLabel]="item.name" [nzValue]="item.name"></nz-option>
                                    </ng-container>
                                    <nz-option *ngIf="newVariable" [nzLabel]="'Create new variable : ' + newVariable"
                                        [nzValue]="'new variable'"></nz-option>
                                </nz-select>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>
                </ng-container>

                <ng-container *ngIf="messageType == 'SEND_PRODUCTS'">
                    <nz-form-item>
                        <nz-form-label nzRequired class="large_label" [nzSm]="24" [nzXs]="24">
                            Type
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please select type!">
                            <nz-input-group>
                                <nz-select nzPlaceHolder="select type" formControlName="type" class="w-100" required>
                                    <nz-option nzLabel="Single" nzValue="single"></nz-option>
                                    <nz-option nzLabel="Multiple" nzValue="multi"></nz-option>
                                </nz-select>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item *ngIf="addMessageForm.value.type == 'single'">
                        <nz-form-label nzRequired class="large_label" [nzSm]="24" [nzXs]="24">
                            Product
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please select product!">
                            <nz-input-group>
                                <nz-select (nzScrollToBottom)="loadProducts()" nzPlaceHolder="select product"
                                    formControlName="productIds" class="w-100" required>
                                    <nz-option *ngFor="let product of allProducts?.catalogues" [nzLabel]="product.name"
                                        [nzValue]="product.retailer_id">
                                    </nz-option>
                                </nz-select>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <ng-container *ngIf="addMessageForm.value.type == 'multi'">
                        <div *ngFor="let menu of productList ; let k = index" class="condition_input">
                            <nz-form-item>
                                <nz-form-label class="large_label" nzRequired [nzSm]="24" [nzXs]="24">
                                    Product List Title {{k+1}}
                                </nz-form-label>
                                <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please enter list title!">
                                    <nz-input-group>
                                        <input [ngClass]="{'error_input' : submitted && !menu.menu}"
                                            [(ngModel)]="menu.menu" [ngModelOptions]="{standalone: true}"
                                            autocomplete="off" type="text" nz-input placeholder="Enter List Title"
                                            required>
                                    </nz-input-group>
                                    <p class="error_text" *ngIf="submitted && !menu.menu">
                                        Please enter list title
                                    </p>
                                </nz-form-control>
                                <!-- <span *ngIf="k > 0" (click)="removeProduct(k)" class="plus_circle " nz-icon
                                    nzType="minus-circle" nzTheme="outline"></span> -->
                            </nz-form-item>

                            <nz-form-item>
                                <nz-form-label class="large_label" nzRequired [nzSm]="24" [nzXs]="24">
                                    Product List
                                </nz-form-label>
                                <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please select products!">
                                    <nz-input-group>
                                        <nz-select (nzScrollToBottom)="loadProducts()" nzMode="tags"
                                            [(ngModel)]="menu.list" [ngModelOptions]="{standalone: true}"
                                            nzPlaceHolder="select product" class="w-100">
                                            <nz-option *ngFor="let product of allProducts?.catalogues"
                                                [nzLabel]="product.name" [nzValue]="product.retailer_id">
                                            </nz-option>
                                        </nz-select>
                                    </nz-input-group>
                                    <p class="error_text" *ngIf="errorArr.indexOf('list' + k) > -1">
                                        Please select products
                                    </p>
                                </nz-form-control>
                            </nz-form-item>
                            <span *ngIf="k > 0" (click)="removeProduct(k)" class="condition_delete" nz-icon
                                nzType="delete" nzTheme="outline"></span>
                        </div>
                        <a *ngIf="getListCount('product') < 30 && productList?.length < 10" (click)="addProduct()"
                            class="add_link mb-3">
                            <span class="plus_circle" nz-icon nzType="plus-circle" nzTheme="outline"></span>
                            Add new menu
                        </a>
                    </ng-container>

                    <ng-container *ngIf="addMessageForm.value.type == 'multi'">
                        <nz-form-item>
                            <nz-form-label nzRequired class="large_label" [nzSm]="24" [nzXs]="24">
                                Header Text
                            </nz-form-label>
                            <nz-form-control [nzSm]="24" [nzXs]="24"
                                [nzErrorTip]="addMessageForm.value.headerText.length < 60 ? 'Please enter header text!' : 'Max limit 60 reached!'">
                                <nz-input-group>
                                    <input #hederVariable (mouseup)="mouseUp()" (select)="onTextSelect('headerText')"
                                        (focus)="bodyTextFocus()" formControlName="headerText" autocomplete="off"
                                        type="text" nz-input placeholder="Enter header text" required>
                                    <div class="count">
                                        <span class="">{{addMessageForm.value.headerText.length}}/60</span>
                                    </div>
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>
                        <ng-container [ngTemplateOutlet]="textEditor"
                            [ngTemplateOutletContext]="{editorData : {type :  'headerText'}}"></ng-container>
                    </ng-container>

                    <nz-form-item>
                        <nz-form-label [nzRequired]="addMessageForm.value.type == 'multi'" class="large_label"
                            [nzSm]="24" [nzXs]="24">
                            Body Text
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24"
                            [nzErrorTip]="addMessageForm.value.bodyText.length < 4096 ? 'Please enter body text!' : 'Max limit 4096 reached!'">
                            <nz-input-group>
                                <textarea #messageField (mouseup)="mouseUp()" (select)="onTextSelect('bodyText')"
                                    (focus)="bodyTextFocus()" formControlName="bodyText" nz-input
                                    placeholder="Enter Body Text" [nzAutosize]="{ minRows: 6, maxRows: 6 }" required>
                            </textarea>
                                <div class="count">
                                    <span class="">{{addMessageForm.value.bodyText.length}}/4096</span>
                                </div>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>
                    <ng-container [ngTemplateOutlet]="textEditor"
                        [ngTemplateOutletContext]="{editorData : {type :  'bodyText'}}"></ng-container>

                    <nz-form-item>
                        <nz-form-label class="large_label" [nzSm]="24" [nzXs]="24">
                            Footer Text
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24"
                            [nzErrorTip]="addMessageForm.value.footerText.length < 60 ? 'Please enter footer text!' : 'Max limit 60 reached!'">
                            <nz-input-group>
                                <input #footerVariable (mouseup)="mouseUp()" (select)="onTextSelect('footerText')"
                                    (focus)="bodyTextFocus()" formControlName="footerText" autocomplete="off"
                                    type="text" nz-input placeholder="Enter footer text">
                                <div class="count">
                                    <span class="">{{addMessageForm.value.footerText.length}}/60</span>
                                </div>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>
                    <ng-container [ngTemplateOutlet]="textEditor"
                        [ngTemplateOutletContext]="{editorData : {type :  'footerText'}}"></ng-container>
                </ng-container>

                <nz-form-item class="submit_section mt-5">
                    <button [nzLoading]="submitting" type="submit" class="sighup_button w-100" nz-button
                        nzType="primary">
                        {{isEdit ? 'Update' : 'Create'}}
                    </button>
                </nz-form-item>
            </form>
        </div>
    </ng-container>
</nz-drawer>

<!-- template preview popup -->

<nz-modal [nzWidth]="'40%'" [(nzVisible)]="templateModal" [nzFooter]="null" [nzTitle]="'Template Preview'" nzCentered
    (nzOnCancel)="closeTemplateModal()">
    <ng-container *nzModalContent>
        <div class="preview_out">
            <div class="template_col">
                <div class="preview_heading">
                    <h5 [nzTooltipTitle]="templateComponents.name" nzTooltipPlacement="top" nz-tooltip>
                        {{templateComponents.name.length > 23 ?
                        templateComponents.name.slice(0,23) + '...' : templateComponents.name}}
                    </h5>
                </div>
                <div class="preview_template">
                    <div class="inner_template">
                        <div *ngFor="let message of templateComponents.components" class="mb-3">
                            <div [ngSwitch]="message?.type" class="">
                                <div *ngSwitchCase="'HEADER'" class="">
                                    <div [ngSwitch]="message?.format" class="header_text">
                                        <p *ngSwitchCase="'TEXT'" [innerHTML]="message.text"></p>
                                        <p class="img_url" *ngSwitchCase="'IMAGE'">
                                            <span nz-icon nzType="file-image" nzTheme="outline"></span>
                                            IMAGE
                                        </p>
                                        <p class="img_url" *ngSwitchCase="'VIDEO'">
                                            <span nz-icon nzType="video-camera" nzTheme="outline"></span>
                                            VIDEO
                                        </p>
                                        <p class="img_url" *ngSwitchCase="'DOCUMENT'">
                                            <span nz-icon nzType="file-done" nzTheme="outline"></span>
                                            DOCUMENT
                                        </p>
                                    </div>

                                </div>
                                <div *ngSwitchCase="'BODY'" class="">
                                    <p [innerHTML]="((message?.text))"></p>
                                </div>
                                <div *ngSwitchCase="'FOOTER'" class="">
                                    <p [innerHTML]="message?.text"></p>
                                </div>
                                <div *ngSwitchCase="'BUTTONS'" class="">
                                    <div *ngFor="let button of message?.buttons" class="template_buttons">
                                        <h6>{{button?.text}}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </ng-container>
</nz-modal>

<!-- template preview popup -->

<nz-modal [nzWidth]="'80%'" [nzBodyStyle]="{'padding' : '0px'}" [(nzVisible)]="mediaModal" [nzFooter]="null"
    nzTitle="Select Media" nzCentered (nzOnCancel)="closeMediaModal()">
    <ng-container *nzModalContent>
        <app-content-page [singleSelect]="true" [showMenu]="false" [filterSelected]="mediaType" [selectedTab]="'media'"
            (selectedData)="selectedData($event)"></app-content-page>
    </ng-container>
</nz-modal>

<!-- add variable dropdowns -->

<nz-dropdown-menu #messageMenu="nzDropdownMenu">
    <ul nz-menu class="dropdown_menu">
        <div class="variable_search">
            <input [(ngModel)]="variableSearch" autocomplete="off" nz-input placeholder="search" type="text" />
        </div>
        <span *ngIf="flowVariables?.length > 0" class="drop_heading">Variables</span>
        <li (click)="addVariable(item.name, 'variables')" nz-menu-item
            *ngFor="let item of flowVariables | filter : variableSearch">
            {{item.name}}</li>
        <li nz-menu-divider></li>
        <span class="drop_heading">Customer fields</span>
        <li (click)="addVariable(field.name, 'customer')" nz-menu-item
            *ngFor="let field of customerFields | filter : variableSearch">
            {{field.label}}</li>
    </ul>
</nz-dropdown-menu>

<nz-dropdown-menu #editorMenu="nzDropdownMenu">
    <ul nz-menu class="dropdown_menu">
        <div class="variable_search">
            <input [(ngModel)]="variableSearch" autocomplete="off" nz-input placeholder="search" type="text" />
        </div>
        <span *ngIf="flowVariables?.length > 0" class="drop_heading">Variables</span>
        <li (click)="addVarEditor(item.name, 'variables')" nz-menu-item
            *ngFor="let item of flowVariables | filter : variableSearch">
            {{item.name}}</li>
        <li nz-menu-divider></li>
        <span class="drop_heading">Customer fields</span>
        <li (click)="addVarEditor(field.name, 'customer')" nz-menu-item
            *ngFor="let field of customerFields | filter : variableSearch">
            {{field.label}}</li>
    </ul>
</nz-dropdown-menu>

<nz-dropdown-menu #apiVariable="nzDropdownMenu">
    <ul nz-menu class="dropdown_menu">
        <div class="variable_search">
            <input [(ngModel)]="variableSearch" autocomplete="off" nz-input placeholder="search" type="text" />
        </div>
        <span *ngIf="flowVariables?.length > 0" class="drop_heading">Variables</span>
        <li (click)="addApiVar('variables' , item.name)" nz-menu-item
            *ngFor="let item of flowVariables | filter : variableSearch">
            {{item.name}}</li>
        <li nz-menu-divider></li>
        <span class="drop_heading">Customer fields</span>
        <li (click)="addApiVar('customer' , field.name)" nz-menu-item
            *ngFor="let field of customerFields | filter : variableSearch">
            {{field.label}}</li>
    </ul>
</nz-dropdown-menu>

<!-- Paths dropdown to choose duplicate action -->
<nz-dropdown-menu #pathsMenu="nzDropdownMenu">
    <ul nz-menu class="dropdown_menu">
        <span class="drop_heading path_dropdown">Choose Path</span>
        <li (click)="selectPath(path)" nz-menu-item *ngFor="let path of allPaths">
            {{path.name}}</li>
        <li nz-menu-divider></li>
    </ul>
</nz-dropdown-menu>

<!-- text editor section -->
<ng-template #textEditor let-data="editorData">
    <div class="editor_options">
        <button (click)="addField(data.type)" nz-dropdown nzTrigger="click" [nzDropdownMenu]="messageMenu" type="button"
            class="variable_add" nz-button nzType="default">
            Add variable
        </button>
        <ng-container *ngIf="data.type != 'url'">
            <emoji-mart *ngIf="emojiVisible && field == data.type" class="emoji-mart" set="{{set}}"
                (emojiSelect)="addEmoji($event, data.type)" title="Pick your emoji…"></emoji-mart>
            <p (click)="showEmoji(data.type)">
                <span nz-icon nzType="smile" nzTheme="outline"></span>
            </p>
            <ng-container *ngIf="data.type != 'headerText'">
                <p [ngClass]="{'selected_style' : selectedStyle == 'Bold' && field == data.type}"
                    (click)="editText('bold' , data.type)">
                    <span nz-icon nzType="bold" nzTheme="outline"></span>
                </p>
                <p [ngClass]="{'selected_style' : selectedStyle == 'Italic' && field == data.type}"
                    (click)="editText('italic', data.type)">
                    <span nz-icon nzType="italic" nzTheme="outline"></span>
                </p>
                <p [ngClass]="{'selected_style' : selectedStyle == 'Strike' && field == data.type}"
                    (click)="editText('strikethrough', data.type)">
                    <span nz-icon nzType="strikethrough" nzTheme="outline"></span>
                </p>
            </ng-container>
        </ng-container>
    </div>
</ng-template>

<!-- alert for delete node -->

<nz-modal [nzStyle]="{ top: '100px' , width : '420px' }" [nzFooter]="null" [(nzVisible)]="alertModal"
    [nzMaskClosable]="false" [nzTitle]="''" (nzOnCancel)="closeAlertModal()">
    <ng-container *nzModalContent>
        <div class="mt-3 main_content">
            <span class="ques_icon" nz-icon nzType="question-circle" nzTheme="outline"></span>
            <div class="">
                <h5 class="">You are trying to delete Start action.</h5>
                <span>Select start action from below and delete current action.</span>
                <nz-form-item class="mt-2">
                    <nz-form-label class="large_label" nzRequired [nzSm]="24" [nzXs]="24">
                        Start Action
                    </nz-form-label>
                    <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please select start action!">
                        <nz-input-group>
                            <nz-select [(ngModel)]="startPath" nzShowSearch nzPlaceHolder="Select start pactionath"
                                class="w-100">
                                <ng-container *ngFor="let path of nodes">
                                    <nz-option *ngIf="path.id != selectedNode" [nzLabel]="path.actionName"
                                        [nzValue]="path">
                                    </nz-option>
                                </ng-container>
                            </nz-select>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>

        <div class="custom_footer">
            <button nz-button nzType="default" (click)="closeAlertModal()">Cancel</button>
            <button [disabled]="!startPath" nz-button nzType="primary"
                (click)="removeNode(selectedNode, 'update')">Delete</button>
        </div>
    </ng-container>
</nz-modal>