import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { CommonApiService } from "src/app/services/common-api.service";
import { NavService } from "src/app/services/nav.service";
import { environment } from "src/environments/environment";
import * as moment from "moment";
import { Subscription } from "rxjs";

@Component({
  selector: "app-recharge-history",
  templateUrl: "./recharge-history.component.html",
  styleUrls: ["./recharge-history.component.css"],
})
export class RechargeHistoryComponent implements OnInit {
  moment: any = moment;
  rechargeHistory: any = [];

  orderColumn = [
    {
      title: "Amount",
      width: "100px",
    },
    {
      title: "Paid By",
      width: "100px",
    },
    {
      title: "Time",
      width: "100px",
    },
  ];

  tableHeight: any = "";
  mainUserData: any = {};

  pageIndex = 1;
  totalCount: number;
  pageSize: number = 10;

  private subscription: Subscription;

  constructor(
    private apiService: CommonApiService,
    private navService: NavService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.getMainUserData();
    this.getRechargeHistory();
  }

  getMainUserData() {
    this.subscription = this.navService.mainUserData.subscribe(
      (response: any) => {
        if (response) {
          // console.log(response);
          this.mainUserData = response.data.userData;
          if (
            response?.data?.userData?.accessTo?.sandboxId &&
            window.innerWidth > 992
          ) {
            this.tableHeight = "70vh";
          } else {
            this.tableHeight = "75vh";
          }
        }
      }
    );
  }

  getRechargeHistory() {
    this.spinner.show();
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url +
          "wallets/history?page=" +
          this.pageIndex +
          "&limit=" +
          this.pageSize,
        {},
        options
      )
      .subscribe(
        (response: any) => {
          console.log(response);
          this.rechargeHistory = response.data.walletHistories;
          this.totalCount = response?.data?.pagination?.totalCount;
          this.pageSize = response?.data?.pagination?.limit;
          this.spinner.hide();
        },
        (err: any) => {
          if (
            err?.error?.message == "jwt expired" ||
            err?.error?.message == "jwt must be provided" ||
            err?.error?.message == "jwt malformed" ||
            err?.error?.message == "invalid signature" ||
            err?.error?.message == "Given token user does not exist." ||
            err?.error?.message == "invalid token"
          ) {
            this.navService.tokenExpired();
          }
          console.log(err);
          this.spinner.hide();
        }
      );
  }

  onPageNumberChange(id: any, event: any) {
    if (this.pageIndex != event.pageIndex) {
      this.pageIndex = event.pageIndex;
      this.getRechargeHistory();
    }
  }

  changePageSize(size: any) {
    this.pageSize = size;
    this.getRechargeHistory();
  }

  setMyStyles() {
    let styles = {
      "margin-right":
        135 + (this.totalCount / this.pageSize).toFixed(0).length * 5 + "px",
    };
    return styles;
  }

  ngOnDestroy(): void {
    // console.log("api key destroy..");
    this.subscription.unsubscribe();
  }
}
