import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { CommonApiService } from "src/app/services/common-api.service";
import { environment } from "src/environments/environment";
import * as moment from "moment";

@Component({
  selector: "app-commerce-dashboard",
  templateUrl: "./commerce-dashboard.component.html",
  styleUrls: ["./commerce-dashboard.component.css"],
})
export class CommerceDashboardComponent implements OnInit {
  moment: any = moment;
  liveUrl = environment.imageUrl;

  filterArr: any = {
    dateFilter: [
      { id: "today", label: "Today" },
      { id: "last-week", label: "Last Week" },
      { id: "last-month", label: "Last Month" },
      { id: "last-year", label: "Last Year" },
      { id: "custom-date", label: "Custom Date" },
    ],
    orderStatus: [
      // { id: "all", label: "All Orders" },
      { id: "order placed", label: "Order Placed" },
      { id: "order shipped", label: "Order Shipped" },
      { id: "delivered", label: "Delivered" },
    ],
    paymentStatus: [
      // { id: "all", label: "All Payments" },
      { id: "paid", label: "Paid" },
      { id: "unpaid", label: "Unpaid" },
    ],
  };

  customDate: any;
  customerList: any = [];

  selectedFilter = "today";
  paymentFilter = "all";
  orderFilter = "all";

  orderList: any = [];

  orderData: any;

  selectedCustomer = "all";

  selectDateError: boolean;

  pageNumber = 1;
  pageSize: number;
  totalCount: number;

  productsModal = false;
  viewItems: any = {};

  constructor(
    private apiService: CommonApiService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((data) => {
      // console.log(data);
      if (data["id"]) {
        this.selectedCustomer = data["id"];
      }
    });
    this.fetchData();
    // this.getCustomerNameList();
  }

  fetchData() {
    this.spinner.show();
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    let data: any = {
      filter: this.selectedFilter,
      customerId: this.selectedCustomer,
      orderStatus: this.orderFilter,
      paymentStatus: this.paymentFilter,
    };

    if (this.selectedFilter == "custom-date") {
      data.fromDate = moment(this.customDate[0]).format();
      data.toDate = moment(this.customDate[1]).format();
    }

    this.apiService
      .commonPostMethod(
        environment.url + "orders?page=" + this.pageNumber,
        data,
        options
      )
      .subscribe(
        (response: any) => {
          this.orderData = response.data;
          this.pageSize = response?.data.pagination?.limit;
          this.totalCount = response?.data?.pagination?.totalCount;
          // console.log(this.orderData);
          this.spinner.hide();
        },
        (err: any) => {
          console.log(err);
          this.spinner.hide();
        }
      );
  }

  getCustomerNameList() {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonGetMethod(environment.url + "customers/get-names-only", options)
      .subscribe(
        (response: any) => {
          // console.log(response);
          this.customerList = response?.data?.customers;
        },
        (err: any) => {
          console.log(err);
        }
      );
  }

  filterChange(type: any) {
    this.pageNumber = 1;
    if (this.selectedFilter != "custom-date") {
      this.fetchData();
    } else if (this.selectedFilter == "custom-date" && type == "date") {
      this.customDate = "";
    } else if (
      this.selectedFilter == "custom-date" &&
      type != "date" &&
      !this.customDate
    ) {
      // this.selectDateError = true;
      this.toastr.error("Please select date!..", "", {
        timeOut: 2500,
      });
    } else if (
      this.selectedFilter == "custom-date" &&
      type != "date" &&
      this.customDate
    ) {
      this.fetchData();
    }
  }

  onDateChange(event: any) {
    // console.log(this.customDate);
    this.fetchData();
  }

  tempOrderData: any;

  statusUpdate(orderData: any, key: string) {
    this.tempOrderData = {};
    // console.log(orderData);
    this.tempOrderData = orderData;
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    var data = {
      orderId: orderData._id,
      orderStatus: orderData.shipping.tracking.orderStatus,
      paymentStatus: orderData.shipping.tracking.paymentStatus,
    };

    this.apiService
      .commonPostMethod(
        environment.url + "orders/update-status" + key,
        data,
        options
      )
      .subscribe(
        (response: any) => {
          // console.log(response);
          if (response.message == "conversation expired") {
            // this.alertModal();
            this.orderAlertModal = true;
          } else {
            this.orderAlertModal = false;
            this.toastr.success("Order status updated!..", "", {
              timeOut: 2500,
            });
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  // order status update modal

  orderAlertModal: boolean = false;

  closeOrderAlertModal() {
    this.orderAlertModal = false;
  }
}
