import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { NzModalService } from "ng-zorro-antd/modal";

@Injectable({
  providedIn: "root",
})
export class AlertServiceService {
  constructor(private modal: NzModalService, private router: Router) {}

  warning(): void {
    this.modal.warning({
      nzTitle: "Your don't have active subscription..",
      nzContent: "To continue service please subscribe..",
      nzOkText: "View Pricing",
      nzOnOk: () => {
        this.router.navigate(["/subscription"]);
      },
    });
  }

  freeTrialWarning(head: string, body: string) {
    this.modal.warning({
      nzTitle: head,
      nzContent: body,
      nzOkText: "View Pricing",
      nzOnOk: () => {
        this.router.navigate(["/subscription"]);
      },
    });
  }
}
