import { Injectable } from "@angular/core";
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
declare var $: any;

@Injectable({
  providedIn: "root",
})
export class AuthGuardService implements CanActivate {
  constructor(private route: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    // console.log(localStorage.getItem("qwertrewetrt"));
    if (
      localStorage.getItem("qwertrewetrt") === null ||
      !localStorage.getItem("qwertrewetrt") ||
      localStorage.getItem("qwertrewetrt") == undefined
    ) {
      this.route.navigate(["/login"]);
      return false;
    } else {
      return true;
    }
  }
}
