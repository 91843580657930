<div class="main_nav">
    <div class="container nav">
        <div class="nav-left">
            <img src="../../../assets/images/logo/your-logo.png" alt="your logo">
        </div>
        <div class="nav-right">
            <p routerLink="/login">Login</p>
            <button routerLink="/signup" nz-button nzType="primary" class="login-form-button">Sign Up</button>
        </div>
    </div>
</div>


<div class="container mt-5">
    <div class="row">
        <div class="col-lg-6">
            <div class="">
                <h5>Built on Official WhatsApp Business Platform</h5>
                <h2>Grow your business faster with WhatsApp</h2>
            </div>
            <div class="mt-3">
                <p>Transform conversations to commerce using Ieyal</p>
                <div class="list_options">
                    <ul class="first_list">
                        <li>Share catalogs at scale</li>
                        <li>Send bulk campaigns</li>
                        <li>Track campaign analytics</li>
                    </ul>
                    <ul class="second_list">
                        <li>Automate notifications</li>
                        <li>Multi-agent team inbox</li>
                        <li>Green tick verification</li>
                    </ul>
                </div>

            </div>
        </div>
        <div class="col-lg-6">
            <div class="">
                <img class="img-fluid" src="../../../assets/images/landing-page/landing-page-image1.png" alt="">
            </div>
        </div>
    </div>
</div>