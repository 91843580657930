<div class="row">
    <div class="col-12 top_heading">
        <h3>
            <i routerLink="/setting" nz-icon nzType="left" nzTheme="outline"></i>
            Profile
        </h3>
    </div>

    <div class="col-lg-8 col-12 mt-5 profile_card">
        <form [formGroup]="profileForm" (ngSubmit)="updateProfile()" nz-form>
            <div class="update_form_div">
                <nz-form-item>
                    <nz-form-label nzRequired class="large_label" nzFor="" [nzSm]="6" [nzXs]="24">Profile
                    </nz-form-label>
                    <nz-form-control [nzSm]="18" [nzXs]="24" nzErrorTip="Please Profile!">
                        <img class="user_profile_image" [src]="previewImage" alt="">
                        <label class="profile_label" for='profile_upload'>upload</label>
                        <input [disabled]="userData?.accessTo?.isExpired" (change)="getFile($event)"
                            class="profile_input" id="profile_upload" type="file" [accept]="imageTypes">
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label nzRequired class="large_label" nzFor="" [nzSm]="6" [nzXs]="24">First Name
                    </nz-form-label>
                    <nz-form-control [nzSm]="18" [nzXs]="24" nzErrorTip="Please Enter Name!">
                        <nz-input-group>
                            <input autocomplete="off" formControlName="firstName" type="text" nz-input
                                placeholder="Enter First Name">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label class="large_label" nzFor="" [nzSm]="6" [nzXs]="24">Last Name
                    </nz-form-label>
                    <nz-form-control [nzSm]="18" [nzXs]="24" nzErrorTip="Please Enter Name!">
                        <nz-input-group>
                            <input autocomplete="off" formControlName="lastName" type="text" nz-input
                                placeholder="Enter Last Name">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label class="large_label" nzFor="" [nzSm]="6" [nzXs]="24"> Email
                    </nz-form-label>
                    <nz-form-control [nzSm]="18" [nzXs]="24" nzErrorTip="Please Enter Email!">
                        <nz-input-group>
                            <input autocomplete="off" formControlName="email" type="email" nz-input
                                placeholder="Enter email id">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label class="large_label" nzFor="" [nzSm]="6" [nzXs]="24"> Role
                    </nz-form-label>
                    <nz-form-control [nzSm]="18" [nzXs]="24" nzErrorTip="Please select role!">
                        <nz-input-group>
                            <input autocomplete="off" formControlName="role" type="text" nz-input
                                placeholder="select role">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>


                <nz-form-item class="submit_section text-right mt-5">
                    <nz-form-control [nzSm]="24" [nzXs]="24">
                        <div class="account_details_add_cancel_right">
                            <button (click)="resetForm()" [disabled]="userData?.accessTo?.isExpired" type="button"
                                class="sighup_button mr-3" nz-button nzType="default">Cancel</button>
                            <button [nzLoading]="updating" [disabled]="userData?.accessTo?.isExpired" type="submit"
                                class="sighup_button" nz-button nzType="primary">Save</button>
                        </div>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </form>

        <ngx-spinner bdColor="rgb(255,255,255)" size="medium" color="#000000" type="ball-clip-rotate-pulse"
            [fullScreen]="false">
        </ngx-spinner>
    </div>
</div>