import {
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { AlertServiceService } from "src/app/services/alert-service.service";
import { CommonApiService } from "src/app/services/common-api.service";
import { NavService } from "src/app/services/nav.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-commerce-page",
  templateUrl: "./commerce-page.component.html",
  styleUrls: ["./commerce-page.component.css"],
})
export class CommercePageComponent implements OnInit {
  copyText: any = "Copy";
  selectedTab = "items";
  selectedSetting: any = "mov/shippingCost";

  commerceActions: any = [
    {
      title: "Add products to your Whatsapp Store",
      icon: "wallet",
      id: "store",
    },
    {
      title: "Setup Messages for Product Collections & Catalogs",
      icon: "shop",
      id: "catalog",
    },
    {
      title: "Send out Catalogs in Campaigns",
      icon: "shopping",
      id: "campaign",
    },
    {
      title: "Send out Catalogs in Auto Replies",
      icon: "message",
      id: "replies",
    },
    {
      title: "Ownchat Autocheckout Workflow!",
      icon: "apartment",
      id: "workflow",
    },
  ];

  orderColumn = [
    {
      title: "FB Collections",
      width: "100px",
    },
    {
      title: "Include in Top 10?",
      width: "100px",
    },
    {
      title: "Set Catalog Message for every collection",
      width: "100px",
    },
  ];

  catalogueModal: boolean = false;

  // items section

  itemSetting: any = [
    {
      id: "mov/shippingCost",
      icon: "unordered-list",
      title: "MOV / Shipping Cost",
    },
    {
      id: "discount",
      icon: "percentage",
      title: "Discount",
    },
    {
      id: "paymentOptions",
      icon: "credit-card",
      title: "Payment Options",
    },
  ];
  itemsData: any = [];
  itemsOrderColumn: any = [
    {
      title: "Name",
      width: "150px",
    },
    {
      title: "Brand",
      width: "70px",
    },
    {
      title: "Availability",
      width: "80px",
    },
    {
      title: "Price",
      width: "70px",
    },
  ];

  addItemForm: boolean = false;
  itemFilterStatus: any = "Active";

  commerceLoader: boolean = false;

  // catalogue section

  connecting: boolean = false;
  catalogueId: any = "";

  pageIndex = 1;
  totalCount: number;
  pageSize: number;

  initialLoad: boolean = true;
  mainUserData: any;

  defaultCatalogueCode = "395063032767433";

  // catalogue form section

  catalogueForm: boolean = false;
  editCatalogueData: any = {};
  editOption: any = "";

  // filter section

  filterObj: any = {
    availability: "all",
  };

  filterFromPrice: any;
  filterToPrice: any;
  filterBrand: any = "";
  filterAvailability: any = "all";
  catalogueSearchKey: any = "";
  filterCondition: any = [];

  // form section

  shippingForm!: FormGroup;
  discountForm!: FormGroup;
  paymentsForm!: FormGroup;
  settingUpdating: boolean = false;

  requiredFilter: any = [];

  catalogueSettingData: any = {};
  tableHeight: any = "";

  // catalogue sync

  syncing: boolean = false;

  videoLink: SafeUrl;

  // loader
  pageLoader: boolean = false;

  fromPlugin: boolean = false;

  mobileDevice: boolean = false;

  allProductsCount: number;

  availability: any = "";

  @ViewChildren("checkboxes") checkboxes: any;

  private subscription: Subscription;

  constructor(
    private apiService: CommonApiService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private navService: NavService,
    private alertService: AlertServiceService,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
    private router: Router,
    private apisService: CommonApiService
  ) {}

  ngOnInit(): void {
    if (window.innerWidth > 992) {
      this.mobileDevice = false;
    } else {
      this.mobileDevice = true;
    }
    this.initialLoad = true;
    this.pageLoader = true;
    this.route.params.subscribe((params) => {
      this.pageLoader = true;
      if (params["option"]) {
        this.selectedTab = params["option"];
      } else {
        this.selectedTab = "items";
      }
      this.getUserData();
    });

    this.getCatalogue();
    this.initiateForm();
    this.getCatalogueSetting();
  }

  getUserData() {
    this.subscription = this.navService.mainUserData.subscribe(
      (response: any) => {
        if (response) {
          this.mainUserData = response.data.userData;
          if (
            this.mainUserData.accessTo.pluginsEnabled.indexOf(
              "642c04bc0736249bbe825dad"
            ) > -1
          ) {
            if (
              response?.data?.userData?.accessTo?.sandboxId &&
              window.innerWidth > 992
            ) {
              this.tableHeight = "48vh";
            } else {
              this.tableHeight = "53vh";
            }
          } else {
            this.router.navigate(["/plugin"]);
          }
        }
      }
    );
  }

  initiateForm() {
    this.shippingForm = this.fb.group({
      minimumOrderValue: ["", Validators.required],
      deliveryCharges: ["", Validators.required],
      freeDeliveryChargesOver: ["", Validators.required],
      shippingMethod: [false, Validators.required],
      appliesTo: ["all-carts", Validators.required],
    });

    this.discountForm = this.fb.group({
      isAllowed: [false, Validators.required],
      discountValue: ["", Validators.required],
      appliesTo: ["all-carts", Validators.required],
      cartValueAbove: ["", Validators.required],
    });

    this.paymentsForm = this.fb.group({
      paymentsMethod: [[], Validators.required],
    });
  }

  getCatalogueSetting() {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(environment.url + "settings/get", {}, options)
      .subscribe(
        (response: any) => {
          // console.log(response);
          this.catalogueSettingData = response?.data?.settings?.ecommerce;
          this.spinner.hide();
          this.pageLoader = false;
          // shipping form value set
          this.shippingForm.patchValue({
            minimumOrderValue:
              this.catalogueSettingData?.shipping?.minimumOrderValue,
            deliveryCharges:
              this.catalogueSettingData?.shipping?.deliveryCharges,
            freeDeliveryChargesOver:
              this.catalogueSettingData?.shipping?.cartValueBelow,
            shippingMethod: this.catalogueSettingData?.shipping?.isShippingFree,
            appliesTo: this.catalogueSettingData?.shipping?.appliesTo,
          });
          // discount form value set
          this.discountForm.patchValue({
            isAllowed: this.catalogueSettingData?.discount?.isAllowed,
            discountValue: this.catalogueSettingData?.discount?.discountValue,
            appliesTo: this.catalogueSettingData?.discount?.appliesTo,
            cartValueAbove: this.catalogueSettingData?.discount?.cartValueAbove,
          });
          // payment form value set
          this.paymentsForm.patchValue({
            paymentsMethod: this?.catalogueSettingData?.availablePaymentMethods,
          });
        },
        (err: any) => {
          console.log(err);
          this.spinner.hide();
          this.pageLoader = false;
        }
      );
  }

  conditionChange(value: any): void {
    // console.log(this.checkboxes?._results[0].checkboxList);
    // this.checkboxes.forEach((element) => {
    //   element.nativeElement._results[0].checkboxList = "";
    // });
    this.filterCondition = value;
    this.initialLoad = false;
    this.filterObj.condition = value;
    this.pageIndex = 1;
    this.getCatalogue();
  }

  priceChange() {
    this.requiredFilter = [];
    if (!this.filterFromPrice) {
      this.requiredFilter.push("from price");
    } else if (this.requiredFilter.indexOf("from price") > -1) {
      this.requiredFilter.splice(this.requiredFilter.indexOf("from price"), 1);
    }

    if (!this.filterToPrice) {
      this.requiredFilter.push("to price");
    } else if (this.requiredFilter.indexOf("to price") > -1) {
      this.requiredFilter.splice(this.requiredFilter.indexOf("to price"), 1);
    }

    if (this.requiredFilter.length == 0) {
      this.requiredFilter = [];
      this.initialLoad = false;
      this.filterObj.priceFrom = this.filterFromPrice;
      this.filterObj.priceTo = this.filterToPrice;
      this.pageIndex = 1;
      this.getCatalogue();
    }
  }

  brandChange() {
    this.initialLoad = false;
    this.filterObj.brand = this.filterBrand;
    this.pageIndex = 1;
    this.getCatalogue();
  }

  availabilityChange() {
    this.initialLoad = false;
    this.filterObj.availability = this.filterAvailability;
    this.pageIndex = 1;
    this.getCatalogue();
  }

  searchCatalogue() {
    this.initialLoad = false;
    this.filterObj.search = this.catalogueSearchKey;
    this.pageIndex = 1;
    this.getCatalogue();
  }

  reloadData() {
    // this.checkboxes._results[0].checkboxList = [];
    this.filterCondition = [];
    this.filterBrand = "";
    this.filterFromPrice = "";
    this.filterToPrice = "";
    this.filterObj = {
      availability: "all",
    };
    this.pageIndex = 1;
    this.getCatalogue();
  }

  getCatalogue() {
    this.spinner.show();
    this.commerceLoader = true;
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url + "catalogue-item/get?page=" + this.pageIndex,
        this.filterObj,
        options
      )
      .subscribe(
        (response: any) => {
          // console.log(response);
          this.itemsData = response?.data?.catalogues;
          this.totalCount = response?.data?.pagination?.totalCount;
          this.pageSize = response?.data?.pagination?.limit;
          if (this.itemsData.length > 0 && this.initialLoad == true) {
            this.initialLoad = false;
          }
          this.allProductsCount = response?.data?.allProductsCount;
          // if (this.fromPlugin && this.itemsData.length > 0) {
          //   this.selectedTab = "messages";
          // } else {
          //   this.selectedTab = "items";
          // }
          this.spinner.hide();
          this.commerceLoader = false;
        },
        (err: any) => {
          console.log(err);
          if (
            err?.error?.message == "jwt expired" ||
            err?.error?.message == "jwt must be provided" ||
            err?.error?.message == "jwt malformed" ||
            err?.error?.message == "invalid signature" ||
            err?.error?.message == "Given token user does not exist." ||
            err?.error?.message == "invalid token"
          ) {
            this.navService.tokenExpired();
          }
          this.spinner.hide();
          this.commerceLoader = false;
        }
      );
  }

  onPageNumberChange(id: any, event: any) {
    if (this.pageIndex != event.pageIndex) {
      this.initialLoad = false;
      this.pageIndex = event.pageIndex;
      this.getCatalogue();
      document.getElementById(id).scrollIntoView();
    }
  }

  switchMenu(menu: any) {
    // this.selectedTab = menu;
    this.router.navigate(["/commerce", menu]);
  }

  changeSettingTab(action: any) {
    if (action.id == "mov/shippingCost") {
      this.selectedSetting = action.id;
    } else if (action.id == "discount") {
      if (!this.shippingForm.valid) {
        this.addShipping(false);
      }
      if (this.shippingForm.valid) {
        this.selectedSetting = action.id;
        this.addShipping(false);
      }
    } else if (action.id == "paymentOptions") {
      if (!this.shippingForm.valid) {
        this.addShipping(false);
      }
      if (!this.discountForm.valid) {
        this.addDiscount(false);
      }
      if (this.discountForm.valid && this.shippingForm.valid) {
        this.selectedSetting = action.id;
        this.addShipping(false);
        this.addDiscount(true);
      }
    }
    // this.selectedSetting = action.id;
  }

  selectOption(selected: any) {
    // if (selected == "setting") {
    //   this.getCatalogueSetting();
    //   this.selectedSetting = "mov/shippingCost";
    // }
    // this.selectedTab = selected;
    this.router.navigate(["/commerce", selected]);
  }

  closeCatalogueModal() {
    this.catalogueModal = false;
  }

  openCatalogueModal() {
    if (
      !this.mainUserData?.accessTo?.isExpired &&
      !this.mainUserData?.accessTo?.sandboxId
    ) {
      if (
        this.defaultCatalogueCode != this.mainUserData?.accessTo?.catalogueId
      ) {
        this.addCatalogueForm();
      } else {
        this.connecting = false;
        this.catalogueModal = true;
      }
    }
  }

  changeCatalogueModal() {
    this.connecting = false;
    this.catalogueModal = true;
  }

  // itemsSection
  showFilters() {
    this.addItemForm = !this.addItemForm;
  }

  addCatalogueForm() {
    if (
      !this.mainUserData?.accessTo?.isExpired &&
      !this.mainUserData?.accessTo?.sandboxId
    ) {
      var planName = this.mainUserData.accessTo.curSubsPlan.name.split(" ")[0];
      if (this.allProductsCount >= 20 && planName == "Standard") {
        var errHead: any = "You are using Standard Plan!";
        var errBody: any = "You can add maximum 20 products by this plan!..";
        this.alertService.freeTrialWarning(errHead, errBody);
      } else if (this.allProductsCount >= 100 && planName == "Advanced") {
        var errHead: any = "You are using Advanced Plan!";
        var errBody: any = "You can add maximum 100 products by this plan!..";
        this.alertService.freeTrialWarning(errHead, errBody);
      } else {
        this.catalogueForm = true;
        this.editOption = "create";
      }
    } else if (this.mainUserData?.accessTo?.sandboxId) {
      var errHead: any = "You are using Sandbox account!";
      var errBody: any = "Please Subscribe any Plan to Add Catalogue!..";
      this.alertService.freeTrialWarning(errHead, errBody);
    }
  }

  editCatalogue(item: any) {
    if (!this.mainUserData?.accessTo?.isExpired) {
      console.log(item);
      this.editOption = "edit";
      this.editCatalogueData = item;
      this.catalogueForm = true;
    } else {
      this.alertService.warning();
    }
  }

  showCatalogueTable(method: any) {
    // this.initialLoad = false;
    this.catalogueForm = false;
    this.editCatalogueData = {};
    if (method == "create") {
      this.initialLoad = true;
      this.getCatalogue();
    }
  }

  connectCatalogue() {
    this.connecting = true;

    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url + "organizations/update-wa-data",
        { catalogueId: this.catalogueId },
        options
      )
      .subscribe(
        (response: any) => {
          // console.log(response);
          this.connecting = false;
          this.catalogueModal = false;
          this.catalogueId = "";
          this.navService.getMainUserData();
          this.getUserData();
          this.syncCatalogue();
          this.toastr.success(response.message, "", {
            timeOut: 2500,
          });
        },
        (err: any) => {
          console.log(err);
          this.connecting = false;
          if (err?.error?.message) {
            this.toastr.error(err?.error?.message, "", {
              timeOut: 2500,
            });
          } else {
            this.toastr.error(err?.message, "", {
              timeOut: 2500,
            });
          }
        }
      );
  }

  // setting section

  settingObj: any = {
    ecommerce: {},
  };

  addShipping(directMode: boolean) {
    if (this?.shippingForm?.value?.appliesTo == "all-carts") {
      this?.shippingForm.get("freeDeliveryChargesOver").setValue(0);
    }

    if (this?.shippingForm?.value?.shippingMethod == false) {
      this?.shippingForm.get("appliesTo").setValue("all-carts");
    }

    if (
      this?.shippingForm?.value?.appliesTo == "all-carts" &&
      this?.shippingForm?.value?.shippingMethod == true
    ) {
      this?.shippingForm.get("deliveryCharges").setValue(0);
    }

    if (this.shippingForm.valid) {
      this.settingObj.ecommerce.shipping = {
        minimumOrderValue: this?.shippingForm?.value?.minimumOrderValue,
        isShippingFree: this?.shippingForm?.value?.shippingMethod,
        appliesTo: this?.shippingForm?.value?.appliesTo,
      };

      if (this?.shippingForm?.value?.appliesTo == "cart-value-below") {
        this.settingObj.ecommerce.shipping.cartValueBelow =
          this?.shippingForm?.value?.freeDeliveryChargesOver;
      }

      if (
        (this?.shippingForm?.value?.appliesTo == "cart-value-below" &&
          this?.shippingForm?.value?.shippingMethod == true) ||
        this?.shippingForm?.value?.shippingMethod == false
      ) {
        this.settingObj.ecommerce.shipping.deliveryCharges =
          this?.shippingForm?.value?.deliveryCharges;
      }

      // console.log(this.settingObj);
      // this.updateSetting(reqObj);
      if (directMode) {
        this.selectedSetting = "discount";
      }
    } else {
      Object.values(this.shippingForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  addPayment() {
    if (this.paymentsForm.valid) {
      (this.settingObj.ecommerce.availablePaymentMethods =
        this.paymentsForm.value.paymentsMethod),
        // console.log(this.settingObj);
        this.updateSetting(this.settingObj);
    } else {
      Object.values(this.paymentsForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  addDiscount(directMode: boolean) {
    if (this?.discountForm?.value?.appliesTo == "all-carts") {
      this?.discountForm.get("cartValueAbove").setValue(0);
    }

    if (this?.discountForm?.value?.isAllowed == false) {
      this?.discountForm.get("appliesTo").setValue("all-carts");
    }

    if (
      this?.discountForm?.value?.appliesTo == "all-carts" &&
      this?.discountForm?.value?.isAllowed == false
    ) {
      this?.discountForm.get("discountValue").setValue(0);
    }

    if (this.discountForm.valid) {
      // console.log(this.discountForm.value);

      this.settingObj.ecommerce.discount = {
        isAllowed: this?.discountForm?.value?.isAllowed,
        appliesTo: this?.discountForm?.value?.appliesTo,
      };

      if (this?.discountForm?.value?.appliesTo == "cart-value-above") {
        this.settingObj.ecommerce.discount.cartValueAbove =
          this?.discountForm?.value?.cartValueAbove;
      }

      if (
        (this?.discountForm?.value?.appliesTo == "cart-value-above" &&
          this?.discountForm?.value?.isAllowed == false) ||
        this?.discountForm?.value?.isAllowed == true
      ) {
        this.settingObj.ecommerce.discount.discountValue =
          this?.discountForm?.value?.discountValue;
      }

      // console.log(this.settingObj);
      // this.updateSetting(reqObj);
      if (directMode) {
        this.selectedSetting = "paymentOptions";
      }
    } else {
      Object.values(this.discountForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  updateSetting(reqObj: any) {
    this.settingUpdating = true;
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url + "settings/update",
        this.settingObj,
        options
      )
      .subscribe(
        (response: any) => {
          // console.log(response);
          this.getCatalogueSetting();
          this.selectedSetting = "mov/shippingCost";
          this.toastr.success(response.message, "", {
            timeOut: 2500,
          });
          this.settingUpdating = false;
        },
        (err: any) => {
          console.log(err);
          this.settingUpdating = false;
          if (err?.error?.message) {
            this.toastr.error(err?.error?.message, "", {
              timeOut: 2500,
            });
          } else {
            this.toastr.error(err?.message, "", {
              timeOut: 2500,
            });
          }
        }
      );
  }

  copyId(text: any) {
    navigator.clipboard.writeText(text);
    this.copyText = "copied";
    setTimeout(() => {
      this.copyText = "Copy";
    }, 1000);
  }

  syncCatalogue() {
    this.syncing = true;

    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(environment.url + "catalogue-item/sync", {}, options)
      .subscribe(
        (response: any) => {
          // console.log(response);
          this.reloadData();
          this.toastr.success(response.message, "", {
            timeOut: 2500,
          });
          this.syncing = false;
        },
        (err: any) => {
          console.log(err);
          if (err?.error?.message) {
            this.toastr.error(err?.error?.message, "", {
              timeOut: 2500,
            });
          } else {
            this.toastr.error(err?.message, "", {
              timeOut: 2500,
            });
          }
          this.syncing = false;
        }
      );
  }

  // video modal

  videoModal: boolean = false;

  showVideoModal(link: any) {
    this.videoLink = this.sanitize(link);
    this.videoModal = true;
  }

  closeVideoModal() {
    this.videoModal = false;
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  directUpdate(item: any, index: number) {
    // console.log(item);
    var formData = new FormData();
    var url = "catalogue-item/update";

    formData.append("catalogueItemId", item._id);
    formData.append("retailerId", item.retailer_id);
    formData.append("name", item.name);
    formData.append("description", item.description);
    formData.append("availability", item.availability);
    formData.append("condition", item.condition);
    formData.append("price", item.price);
    formData.append("salePrice", item.sale_price);
    formData.append("originCountry", item.origin_country);
    formData.append("currency", item.currency);
    formData.append("brand", item.brand);
    formData.append("category", item.category);
    formData.append("url", item.url);
    formData.append("waComplianceCategory", item.wa_compliance_category);

    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apisService
      .commonPostMethod(environment.url + url, formData, options)
      .subscribe(
        (response: any) => {
          // console.log(response);
        },
        (err: any) => {
          console.log(err);
          if (err?.error?.message) {
            this.toastr.error(err?.error?.message, "", {
              timeOut: 2500,
            });
          } else {
            this.toastr.error(err?.message, "", {
              timeOut: 2500,
            });
          }
          // this.getCatalogue();
          this.itemsData[index].availability =
            this.itemsData[index].availability == "in stock"
              ? "out of stock"
              : "in stock";
        }
      );
  }

  ngOnDestroy(): void {
    // console.log("api key destroy..");
    this.subscription.unsubscribe();
  }
}
