import { Component, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { distinctUntilChanged, filter, map, startWith } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { IBreadcrumb } from "../../shared/interfaces/breadcrumb.type";
import { ThemeConstantService } from "../../shared/services/theme-constant.service";
import * as moment from "moment-timezone";
import { NavService } from "src/app/services/nav.service";
import { NzModalService } from "ng-zorro-antd/modal";

@Component({
  selector: "app-home-page",
  templateUrl: "./home-page.component.html",
  styleUrls: ["./home-page.component.css"],
})
export class HomePageComponent implements OnInit, OnChanges {
  moment: any = moment;
  breadcrumbs$: Observable<IBreadcrumb[]>;
  contentHeaderDisplay: string;
  isFolded: boolean;
  isSideNavDark: boolean;
  isExpand: boolean;
  selectedHeaderColor: string;

  hideNavbarSidnav = false;
  orderPage: boolean;
  UserData: any;

  liveUrl: any = environment.imageUrl;

  currentUrl: string = "";

  now = moment().tz("Asia/kolkata").format();

  showInfo: boolean = false;

  private subscription: Subscription;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private themeService: ThemeConstantService,
    private modal: NzModalService,
    private navService: NavService
  ) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
        // console.log(this.currentUrl);
        if (
          event.url == "/" ||
          event.url == "/forgot-password" ||
          event.url == "/login" ||
          event.url.includes("/login") ||
          event.url == "/signup"
        ) {
          this.hideNavbarSidnav = false;
        } else if (
          event.url == "/campaign" ||
          event.url == "/contacts/uploads" ||
          event.url == "/setting" ||
          event.url == "/contacts" ||
          event.url == "/users" ||
          event.url == "/wa-update" ||
          event.url == "/campaign/create-campaign" ||
          event.url == "/chat-bot/templates" ||
          event.url == "/order-panel" ||
          event.url == "/automation" ||
          event.url == "/faq" ||
          event.url == "/chat" ||
          event.url == "/chat-bot" ||
          event.url == "/plugin" ||
          event.url == "/my-profile" ||
          event.url == "/subscription" ||
          event.url == "/developer" ||
          event.url == "/content" ||
          event.url == "/recharge-history" ||
          event.url == "/tags" ||
          event.url == "/field-collection" ||
          event.url == "/work-flow" ||
          event.url == "/apps" ||
          event.url.includes("/chat-bot/flow/") ||
          event.url.includes("/dashboard") ||
          // event.url.includes("/work-flow/") ||
          event.url.includes("/chat/") ||
          event.url.includes("/content/") ||
          event.url.includes("/commerce") ||
          event.url.includes("/catalogue") ||
          event.url.includes("/contacts/segment") ||
          event.url.includes("/campaign/create-campaign/") ||
          event.url.includes("campaign/campaign-details/") ||
          event.url.includes("/order-panel/") ||
          event.url.includes("/setting/")
        ) {
          this.hideNavbarSidnav = true;
        } else {
          this.hideNavbarSidnav = false;
        }

        if (event.url.includes("/order-panel") || event.url == "/signup") {
          this.orderPage = true;
        } else {
          this.orderPage = false;
        }

        if (event.url != "/" && !this.UserData) {
          this.getUserData();
        }
      }
    });

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild;
          while (child) {
            if (child.firstChild) {
              child = child.firstChild;
            } else if (
              child.snapshot.data &&
              child.snapshot.data["headerDisplay"]
            ) {
              return child.snapshot.data["headerDisplay"];
            } else {
              return null;
            }
          }
          return null;
        })
      )
      .subscribe((data: any) => {
        this.contentHeaderDisplay = data;
      });
  }

  ngOnInit(): void {
    // console.log("homepage");
    this.breadcrumbs$ = this.router.events.pipe(
      startWith(new NavigationEnd(0, "/", "/")),
      filter((event) => event instanceof NavigationEnd),
      distinctUntilChanged(),
      map((data) => this.buildBreadCrumb(this.activatedRoute.root))
    );
    this.themeService.isMenuFoldedChanges.subscribe(
      (isFolded) => (this.isFolded = isFolded)
    );
    this.themeService.isSideNavDarkChanges.subscribe(
      (isDark) => (this.isSideNavDark = isDark)
    );
    this.themeService.selectedHeaderColor.subscribe(
      (color) => (this.selectedHeaderColor = color)
    );
    this.themeService.isExpandChanges.subscribe(
      (isExpand) => (this.isExpand = isExpand)
    );
  }

  private buildBreadCrumb(
    route: ActivatedRoute,
    url: string = "",
    breadcrumbs: IBreadcrumb[] = []
  ): IBreadcrumb[] {
    let label = "",
      path = "/",
      display = null;

    if (route.routeConfig) {
      if (route.routeConfig.data) {
        label = route.routeConfig.data["title"];
        path += route.routeConfig.path;
      }
    } else {
      label = "Dashboard";
      path += "dashboard";
    }

    const nextUrl = path && path !== "/dashboard" ? `${url}${path}` : url;
    const breadcrumb = <IBreadcrumb>{
      label: label,
      url: nextUrl,
    };

    const newBreadcrumbs = label
      ? [...breadcrumbs, breadcrumb]
      : [...breadcrumbs];
    if (route.firstChild) {
      return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
    }
    return newBreadcrumbs;
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log("change");
  }

  getUserData() {
    this.subscription = this.navService.mainUserData.subscribe(
      (response: any) => {
        if (response) {
          // console.log(response);
          this.UserData = response.data.userData;
          if (this.UserData?.accessTo?.sandboxId && window.innerWidth < 992) {
            this.showInfo = true;
          } else {
            this.showInfo = false;
          }
        }
      }
    );
    // let options = {
    //   headers: {
    //     Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
    //   },
    // };

    // if (localStorage.getItem("qwertrewetrt")) {
    //   this.apiService
    //     .commonGetMethod(environment.url + "auth/get-user-data", options)
    //     .subscribe(
    //       (response: any) => {
    //         this.UserData = response.data.userData;
    //         console.log(this.UserData);
    //       },
    //       (error: any) => {
    //         console.log(error);
    //       }
    //     );
    // }
  }

  logoutAlert() {
    this.modal.confirm({
      nzTitle: "Sure you want to Logout?",
      nzOkText: "Yes",
      nzCancelText: "No",
      nzOnOk: () => this.logOut(),
    });
  }

  logOut() {
    localStorage.removeItem("qwertrewetrt");
    localStorage.removeItem(
      "gtrdtghjdgfhjsgkahjhfjkafhfkjhfkfifghefywegfyuwgfuyew"
    );
    localStorage.removeItem("skjhfruibsndbvmnvckbhdsuifhbd");
    this.router.navigate(["/login"]);
  }

  arrangeMeeting() {
    window.open("https://booknow.ownchat.app/", "_blank");
  }

  ownNumber() {
    // console.log(this.UserData);
    var planName = this?.UserData?.accessTo?.curSubsPlan?.name.split(" ")[0];
    if (planName) {
      window.open(
        "https://hub.360dialog.com/dashboard/app/xAm2nuPA/permissions?name=" +
          this?.UserData?.firstName +
          (this?.UserData?.lastName ? "%20" + this?.UserData?.lastName : "") +
          "&email=" +
          this?.UserData?.email,
        "_blank"
      );
    } else {
      // window.open("https://booknow.ownchat.app/", "_blank");
      this.router.navigate(["/subscription"]);
      this.showInfo = false;
    }
  }

  toSettingPage() {
    if (this.UserData.role == "teammate") {
      this.router.navigate(["/setting/profile"]);
    } else {
      this.router.navigate(["/setting/whatsapp"]);
    }
  }

  closeInfo() {
    this.showInfo = false;
  }

  ngOnDestroy(): void {
    // console.log("api key destroy..");
    this.subscription.unsubscribe();
  }
}
