import { SideNavInterface } from "../../interfaces/side-nav.type";
export const ROUTES: SideNavInterface[] = [
  {
    path: "dashboard",
    title: "Dashboard",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "fund",
    submenu: [],
    role: "admin-owner",
  },
  {
    path: "chat",
    title: "Chat",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "wechat",
    submenu: [],
    role: "admin-owner",
  },
  {
    path: "contacts",
    title: "Contacts",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "user",
    submenu: [],
    role: "admin-owner",
  },
  {
    path: "chat-bot",
    title: "Bot",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "robot",
    submenu: [],
    role: "admin-owner",
  },
  {
    path: "campaign",
    title: "Campaign",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "notification",
    submenu: [],
    role: "admin-owner",
  },
  {
    path: "content",
    title: "Content",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "container",
    submenu: [],
    role: "admin-owner",
  },
  // {
  //   path: "automation",
  //   title: "Automation",
  //   iconType: "nzIcon",
  //   iconTheme: "outline",
  //   icon: "deployment-unit",
  //   submenu: [],
  // role:'admin-owner'
  // },
  {
    path: "commerce",
    title: "Commerce",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "shop",
    submenu: [],
    role: "admin-owner",
  },
  // {
  //   path: "catalogue",
  //   title: "Catalogue",
  //   iconType: "nzIcon",
  //   iconTheme: "outline",
  //   icon: "file-done",
  //   submenu: [],
  // role:'admin-owner'
  // },
  {
    path: "work-flow",
    title: "Work Flows",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "branches",
    submenu: [],
    role: "admin-owner",
  },
  {
    path: "apps",
    title: "Apps",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "appstore",
    submenu: [],
    role: "admin-owner",
  },
  {
    path: "plugin",
    title: "Plugin",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "api",
    submenu: [],
    role: "admin-owner",
  },
  {
    path: "subscription",
    title: "Subscription",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "dollar",
    submenu: [],
    role: "admin-owner",
  },
  {
    path: "developer",
    title: "Developer",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "partition",
    submenu: [],
    role: "admin-owner",
  },
  // {
  //   path: "order-panel",
  //   title: "Orders",
  //   iconType: "nzIcon",
  //   iconTheme: "outline",
  //   icon: "shop",
  //   submenu: [],
  // role:'admin-owner'
  // },
  // {
  //   path: "users",
  //   title: "Users",
  //   iconType: "nzIcon",
  //   iconTheme: "outline",
  //   icon: "user-switch",
  //   submenu: [],
  // role:'admin-owner'
  // },
  // {
  //   path: "wa-update",
  //   title: "Update WA",
  //   iconType: "nzIcon",
  //   iconTheme: "outline",
  //   icon: "setting",
  //   submenu: [],
  // role:'admin-owner'
  // },

  // Role Team Member
  {
    path: "chat",
    title: "Chat",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "wechat",
    submenu: [],
    role: "teammate",
  },
];
