// list of possible message structure

export const messageFormats: any = [
  // plain text
  {
    message: {
      data: {
        text: "",
      },
      msgType: "text",
      itemData: [],
    },
    conversationType: "sent",
    time: "",
    localImage: true,
    responseBy: "user",
    responseByUser: {
      firstName: "",
      lastName: "",
    },
  },
  // media data
  {
    message: {
      data: {
        caption: "",
      },
      msgType: "image",
      media: {
        url: "",
      },
      itemData: [],
    },
    conversationType: "sent",
    time: "",
    localImage: true,
    responseBy: "user",
    responseByUser: {
      firstName: "",
      lastName: "",
    },
  },
  // audio data
  {
    message: {
      data: {},
      msgType: "audio",
      media: {
        url: "",
      },
      itemData: [],
    },
    conversationType: "sent",
    time: "",
    localImage: true,
    responseBy: "user",
    responseByUser: {
      firstName: "",
      lastName: "",
    },
  },
  // video data
  {
    message: {
      data: {
        caption: "",
      },
      msgType: "video",
      media: {
        url: "",
      },
      itemData: [],
    },
    conversationType: "sent",
    time: "",
    localImage: true,
    responseBy: "user",
    responseByUser: {
      firstName: "",
      lastName: "",
    },
  },
  // document data
  {
    message: {
      data: {
        filename: "",
      },
      msgType: "document",
      media: {
        url: "",
      },
      itemData: [],
    },
    conversationType: "sent",
    time: "",
    localImage: true,
    responseBy: "user",
    responseByUser: {
      firstName: "",
      lastName: "",
    },
  },
  // template data
  {
    message: {
      data: [],
      msgType: "template",
      media: {
        url: "",
      },
    },
    conversationType: "sent",
    time: "",
    localImage: true,
    responseBy: "user",
    responseByUser: {
      firstName: "",
      lastName: "",
    },
  },
  // Interactive data
  {
    message: {
      data: {
        type: "",
        desc: "",
      },
      msgType: "interactive",
      itemData: [],
    },
    conversationType: "sent",
    time: "",
    localImage: true,
    responseBy: "user",
    responseByUser: {
      firstName: "",
      lastName: "",
    },
  },
];

export const countryCodes: any = [
  {
    Country: "Canada",
    countryCode: "+1",
    countryCodeNormal: "1",
  },
  {
    Country: "United States",
    countryCode: "+1",
    countryCodeNormal: "1",
  },
  {
    Country: "Russia",
    countryCode: "+7",
    countryCodeNormal: "7",
  },
  {
    Country: "Egypt",
    countryCode: "+20",
    countryCodeNormal: "20",
  },
  {
    Country: "South Africa",
    countryCode: "+27",
    countryCodeNormal: "27",
  },
  {
    Country: "Greece",
    countryCode: "+30",
    countryCodeNormal: "30",
  },
  {
    Country: "Netherlands",
    countryCode: "+31",
    countryCodeNormal: "31",
  },
  {
    Country: "Belgium",
    countryCode: "+32",
    countryCodeNormal: "32",
  },
  {
    Country: "France",
    countryCode: "+33",
    countryCodeNormal: "33",
  },
  {
    Country: "Spain",
    countryCode: "+34",
    countryCodeNormal: "34",
  },
  {
    Country: "Hungary",
    countryCode: "+36",
    countryCodeNormal: "36",
  },
  {
    Country: "Italy",
    countryCode: "+39",
    countryCodeNormal: "39",
  },
  {
    Country: "Romania",
    countryCode: "+40",
    countryCodeNormal: "40",
  },
  {
    Country: "Switzerland",
    countryCode: "+41",
    countryCodeNormal: "41",
  },
  {
    Country: "Austria",
    countryCode: "+43",
    countryCodeNormal: "43",
  },
  {
    Country: "United Kingdom",
    countryCode: "+44",
    countryCodeNormal: "44",
  },
  {
    Country: "Denmark",
    countryCode: "+45",
    countryCodeNormal: "45",
  },
  {
    Country: "Sweden",
    countryCode: "+46",
    countryCodeNormal: "46",
  },
  {
    Country: "Norway",
    countryCode: "+47",
    countryCodeNormal: "47",
  },
  {
    Country: "Poland",
    countryCode: "+48",
    countryCodeNormal: "48",
  },
  {
    Country: "Germany",
    countryCode: "+49",
    countryCodeNormal: "49",
  },
  {
    Country: "Peru",
    countryCode: "+51",
    countryCodeNormal: "51",
  },
  {
    Country: "Mexico",
    countryCode: "+52",
    countryCodeNormal: "52",
  },
  {
    Country: "Argentina",
    countryCode: "+54",
    countryCodeNormal: "54",
  },
  {
    Country: "Brazil",
    countryCode: "+55",
    countryCodeNormal: "55",
  },
  {
    Country: "Chile",
    countryCode: "+56",
    countryCodeNormal: "56",
  },
  {
    Country: "Colombia",
    countryCode: "+57",
    countryCodeNormal: "57",
  },
  {
    Country: "Venezuela",
    countryCode: "+58",
    countryCodeNormal: "58",
  },
  {
    Country: "Malaysia",
    countryCode: "+60",
    countryCodeNormal: "60",
  },
  {
    Country: "Australia",
    countryCode: "+61",
    countryCodeNormal: "61",
  },
  {
    Country: "Indonesia",
    countryCode: "+62",
    countryCodeNormal: "62",
  },
  {
    Country: "Philippines",
    countryCode: "+63",
    countryCodeNormal: "63",
  },
  {
    Country: "New Zealand",
    countryCode: "+64",
    countryCodeNormal: "64",
  },
  {
    Country: "Singapore",
    countryCode: "+65",
    countryCodeNormal: "65",
  },
  {
    Country: "Thailand",
    countryCode: "+66",
    countryCodeNormal: "66",
  },
  {
    Country: "Japan",
    countryCode: "+81",
    countryCodeNormal: "81",
  },
  {
    Country: "Vietnam",
    countryCode: "+84",
    countryCodeNormal: "84",
  },
  {
    Country: "China",
    countryCode: "+86",
    countryCodeNormal: "86",
  },
  {
    Country: "Turkey",
    countryCode: "+90",
    countryCodeNormal: "90",
  },
  {
    Country: "India",
    countryCode: "+91",
    countryCodeNormal: "91",
  },
  {
    Country: "Pakistan",
    countryCode: "+92",
    countryCodeNormal: "92",
  },
  {
    Country: "Afghanistan",
    countryCode: "+93",
    countryCodeNormal: "93",
  },
  {
    Country: "Sri Lanka",
    countryCode: "+94",
    countryCodeNormal: "94",
  },
  {
    Country: "South Sudan",
    countryCode: "+211",
    countryCodeNormal: "211",
  },
  {
    Country: "Morocco",
    countryCode: "+212",
    countryCodeNormal: "212",
  },
  {
    Country: "Algeria",
    countryCode: "+213",
    countryCodeNormal: "213",
  },
  {
    Country: "Tunisia",
    countryCode: "+216",
    countryCodeNormal: "216",
  },
  {
    Country: "Libya",
    countryCode: "+218",
    countryCodeNormal: "218",
  },
  {
    Country: "Gambia",
    countryCode: "+220",
    countryCodeNormal: "220",
  },
  {
    Country: "Senegal",
    countryCode: "+221",
    countryCodeNormal: "221",
  },
  {
    Country: "Mauritania",
    countryCode: "+222",
    countryCodeNormal: "222",
  },
  {
    Country: "Mali",
    countryCode: "+223",
    countryCodeNormal: "223",
  },
  {
    Country: "Ivory Coast",
    countryCode: "+225",
    countryCodeNormal: "225",
  },
  {
    Country: "Burkina Faso",
    countryCode: "+226",
    countryCodeNormal: "226",
  },
  {
    Country: "Niger",
    countryCode: "+227",
    countryCodeNormal: "227",
  },
  {
    Country: "Togo",
    countryCode: "+228",
    countryCodeNormal: "228",
  },
  {
    Country: "Benin",
    countryCode: "+229",
    countryCodeNormal: "229",
  },
  {
    Country: "Liberia",
    countryCode: "+231",
    countryCodeNormal: "231",
  },
  {
    Country: "Sierra Leone",
    countryCode: "+232",
    countryCodeNormal: "232",
  },
  {
    Country: "Ghana",
    countryCode: "+233",
    countryCodeNormal: "233",
  },
  {
    Country: "Nigeria",
    countryCode: "+234",
    countryCodeNormal: "234",
  },
  {
    Country: "Chad",
    countryCode: "+235",
    countryCodeNormal: "235",
  },
  {
    Country: "Cameroon",
    countryCode: "+237",
    countryCodeNormal: "237",
  },
  {
    Country: "Gabon",
    countryCode: "+241",
    countryCodeNormal: "241",
  },
  {
    Country: "Congo",
    countryCode: "+243",
    countryCodeNormal: "243",
  },
  {
    Country: "Angola",
    countryCode: "+244",
    countryCodeNormal: "244",
  },
  {
    Country: "Guinea-Bissau",
    countryCode: "+245",
    countryCodeNormal: "245",
  },
  {
    Country: "Sudan",
    countryCode: "+249",
    countryCodeNormal: "249",
  },
  {
    Country: "Rwanda",
    countryCode: "+250",
    countryCodeNormal: "250",
  },
  {
    Country: "Ethiopia",
    countryCode: "+251",
    countryCodeNormal: "251",
  },
  {
    Country: "Somalia",
    countryCode: "+252",
    countryCodeNormal: "252",
  },
  {
    Country: "Kenya",
    countryCode: "+254",
    countryCodeNormal: "254",
  },
  {
    Country: "Tanzania",
    countryCode: "+255",
    countryCodeNormal: "255",
  },
  {
    Country: "Uganda",
    countryCode: "+256",
    countryCodeNormal: "256",
  },
  {
    Country: "Burundi",
    countryCode: "+257",
    countryCodeNormal: "257",
  },
  {
    Country: "Mozambique",
    countryCode: "+258",
    countryCodeNormal: "258",
  },
  {
    Country: "Zambia",
    countryCode: "+260",
    countryCodeNormal: "260",
  },
  {
    Country: "Madagascar",
    countryCode: "+261",
    countryCodeNormal: "261",
  },
  {
    Country: "Namibia",
    countryCode: "+264",
    countryCodeNormal: "264",
  },
  {
    Country: "Malawi",
    countryCode: "+265",
    countryCodeNormal: "265",
  },
  {
    Country: "Lesotho",
    countryCode: "+266",
    countryCodeNormal: "266",
  },
  {
    Country: "Botswana",
    countryCode: "+267",
    countryCodeNormal: "267",
  },
  {
    Country: "Swaziland",
    countryCode: "+268",
    countryCodeNormal: "268",
  },
  {
    Country: "Eritrea",
    countryCode: "+291",
    countryCodeNormal: "291",
  },
  {
    Country: "Portugal",
    countryCode: "+351",
    countryCodeNormal: "351",
  },
  {
    Country: "Ireland",
    countryCode: "+353",
    countryCodeNormal: "353",
  },
  {
    Country: "Albania",
    countryCode: "+355",
    countryCodeNormal: "355",
  },
  {
    Country: "Finland",
    countryCode: "+358",
    countryCodeNormal: "358",
  },
  {
    Country: "Bulgaria",
    countryCode: "+359",
    countryCodeNormal: "359",
  },
  {
    Country: "Lithuania",
    countryCode: "+370",
    countryCodeNormal: "370",
  },
  {
    Country: "Latvia",
    countryCode: "+371",
    countryCodeNormal: "371",
  },
  {
    Country: "Moldova",
    countryCode: "+373",
    countryCodeNormal: "373",
  },
  {
    Country: "Armenia",
    countryCode: "+374",
    countryCodeNormal: "374",
  },
  {
    Country: "Belarus",
    countryCode: "+375",
    countryCodeNormal: "375",
  },
  {
    Country: "Ukraine",
    countryCode: "+380",
    countryCodeNormal: "380",
  },
  {
    Country: "Serbia",
    countryCode: "+381",
    countryCodeNormal: "381",
  },
  {
    Country: "Croatia",
    countryCode: "+385",
    countryCodeNormal: "385",
  },
  {
    Country: "Slovenia",
    countryCode: "+386",
    countryCodeNormal: "386",
  },
  {
    Country: "Macedonia",
    countryCode: "+389",
    countryCodeNormal: "389",
  },
  {
    Country: "Czech Republic",
    countryCode: "+420",
    countryCodeNormal: "420",
  },
  {
    Country: "Slovakia",
    countryCode: "+421",
    countryCodeNormal: "421",
  },
  {
    Country: "Guatemala",
    countryCode: "+502",
    countryCodeNormal: "502",
  },
  {
    Country: "El Salvador",
    countryCode: "+503",
    countryCodeNormal: "503",
  },
  {
    Country: "Honduras",
    countryCode: "+504",
    countryCodeNormal: "504",
  },
  {
    Country: "Nicaragua",
    countryCode: "+505",
    countryCodeNormal: "505",
  },
  {
    Country: "Costa Rica",
    countryCode: "+506",
    countryCodeNormal: "506",
  },
  {
    Country: "Panama",
    countryCode: "+507",
    countryCodeNormal: "507",
  },
  {
    Country: "Haiti",
    countryCode: "+509",
    countryCodeNormal: "509",
  },
  {
    Country: "Bolivia",
    countryCode: "+591",
    countryCodeNormal: "591",
  },
  {
    Country: "Ecuador",
    countryCode: "+593",
    countryCodeNormal: "593",
  },
  {
    Country: "Paraguay",
    countryCode: "+595",
    countryCodeNormal: "595",
  },
  {
    Country: "Uruguay",
    countryCode: "+598",
    countryCodeNormal: "598",
  },
  {
    Country: "Papua New Guinea",
    countryCode: "+675",
    countryCodeNormal: "675",
  },
  {
    Country: "Hong Kong",
    countryCode: "+852",
    countryCodeNormal: "852",
  },
  {
    Country: "Cambodia",
    countryCode: "+855",
    countryCodeNormal: "855",
  },
  {
    Country: "Laos",
    countryCode: "+856",
    countryCodeNormal: "856",
  },
  {
    Country: "Bangladesh",
    countryCode: "+880",
    countryCodeNormal: "880",
  },
  {
    Country: "Taiwan",
    countryCode: "+886",
    countryCodeNormal: "886",
  },
  {
    Country: "Lebanon",
    countryCode: "+961",
    countryCodeNormal: "961",
  },
  {
    Country: "Jordan",
    countryCode: "+962",
    countryCodeNormal: "962",
  },
  {
    Country: "Iraq",
    countryCode: "+964",
    countryCodeNormal: "964",
  },
  {
    Country: "Kuwait",
    countryCode: "+965",
    countryCodeNormal: "965",
  },
  {
    Country: "Saudi Arabia",
    countryCode: "+966",
    countryCodeNormal: "966",
  },
  {
    Country: "Yemen",
    countryCode: "+967",
    countryCodeNormal: "967",
  },
  {
    Country: "Oman",
    countryCode: "+968",
    countryCodeNormal: "968",
  },
  {
    Country: "United Arab Emirates",
    countryCode: "+971",
    countryCodeNormal: "971",
  },
  {
    Country: "Israel",
    countryCode: "+972",
    countryCodeNormal: "972",
  },
  {
    Country: "Bahrain",
    countryCode: "+973",
    countryCodeNormal: "973",
  },
  {
    Country: "Qatar",
    countryCode: "+974",
    countryCodeNormal: "974",
  },
  {
    Country: "Mongolia",
    countryCode: "+976",
    countryCodeNormal: "976",
  },
  {
    Country: "Nepal",
    countryCode: "+977",
    countryCodeNormal: "977",
  },
  {
    Country: "Tajikistan",
    countryCode: "+992",
    countryCodeNormal: "992",
  },
  {
    Country: "Turkmenistan",
    countryCode: "+993",
    countryCodeNormal: "993",
  },
  {
    Country: "Azerbaijan",
    countryCode: "+994",
    countryCodeNormal: "994",
  },
  {
    Country: "Georgia",
    countryCode: "+995",
    countryCodeNormal: "995",
  },
  {
    Country: "Uzbekistan",
    countryCode: "+998",
    countryCodeNormal: "998",
  },
  {
    Country: "Puerto Rico",
    countryCode: "+1-787",
    countryCodeNormal: "1-787",
  },
  {
    Country: "Dominican Republic",
    countryCode: "+1-809",
    countryCodeNormal: "1-809",
  },
  {
    Country: "Jamaica",
    countryCode: "+1-876",
    countryCodeNormal: "1-876",
  },
];

export const Languages: any = [
  { language: "Afrikaans", code: "af" },
  { language: "Albanian", code: "sq" },
  { language: "Arabic", code: "ar" },
  { language: "Azerbaijani", code: "az" },
  { language: "Bengali", code: "bn" },
  { language: "Bulgarian", code: "bg" },
  { language: "Catalan", code: "ca" },
  { language: "Chinese (CHN)", code: "zh_CN" },
  { language: "Chinese (HKG)", code: "zh_HK" },
  { language: "Chinese (TAI)", code: "zh_TW" },
  { language: "Croatian", code: "hr" },
  { language: "Czech", code: "cs" },
  { language: "Danish", code: "da" },
  { language: "Dutch", code: "nl" },
  { language: "English", code: "en" },
  { language: "English (UK)", code: "en_GB" },
  { language: "English (US)", code: "en_US" },
  { language: "Estonian", code: "et" },
  { language: "Filipino", code: "fil" },
  { language: "Finnish", code: "fi" },
  { language: "French", code: "fr" },
  { language: "Georgian", code: "ka" },
  { language: "German", code: "de" },
  { language: "Greek", code: "el" },
  { language: "Gujarati", code: "gu" },
  { language: "Hausa", code: "ha" },
  { language: "Hebrew", code: "he" },
  { language: "Hindi", code: "hi" },
  { language: "Hungarian", code: "hu" },
  { language: "Indonesian", code: "id" },
  { language: "Irish", code: "ga" },
  { language: "Italian", code: "it" },
  { language: "Japanese", code: "ja" },
  { language: "Kannada", code: "kn" },
  { language: "Kazakh", code: "kk" },
  { language: "Kinyarwanda", code: "rw_RW" },
  { language: "Korean", code: "ko" },
  { language: "Kyrgyz (Kyrgyzstan)", code: "ky_KG" },
  { language: "Lao", code: "lo" },
  { language: "Latvian", code: "lv" },
  { language: "Lithuanian", code: "lt" },
  { language: "Macedonian", code: "mk" },
  { language: "Malay", code: "ms" },
  { language: "Malayalam", code: "ml" },
  { language: "Marathi", code: "mr" },
  { language: "Norwegian", code: "nb" },
  { language: "Persian", code: "fa" },
  { language: "Polish", code: "pl" },
  { language: "Portuguese (BR)", code: "pt_BR" },
  { language: "Portuguese (POR)", code: "pt_PT" },
  { language: "Punjabi", code: "pa" },
  { language: "Romanian", code: "ro" },
  { language: "Russian", code: "ru" },
  { language: "Serbian", code: "sr" },
  { language: "Slovak", code: "sk" },
  { language: "Slovenian", code: "sl" },
  { language: "Spanish", code: "es" },
  { language: "Spanish (ARG)", code: "es_AR" },
  { language: "Spanish (SPA)", code: "es_ES" },
  { language: "Spanish (MEX)", code: "es_MX" },
  { language: "Swahili", code: "sw" },
  { language: "Swedish", code: "sv" },
  { language: "Tamil", code: "ta" },
  { language: "Telugu", code: "te" },
  { language: "Thai", code: "th" },
  { language: "Turkish", code: "tr" },
  { language: "Ukrainian", code: "uk" },
  { language: "Urdu", code: "ur" },
  { language: "Uzbek", code: "uz" },
  { language: "Vietnamese", code: "vi" },
  { language: "Zulu", code: "zu" },
];

export const countries: any = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Republic of the Congo",
  "DR Congo",
  "Cook Islands",
  "Costa Rica",
  "Ivory Coast",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Vatican City",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "North Korea",
  "South Korea",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macau",
  "Macedonia",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn Islands",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Réunion",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "São Tomé and Príncipe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Montenegro",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela",
  "Vietnam",
  "British Virgin Islands",
  "United States Virgin Islands",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

export const countriesForPayment: any = [
  "Argentina",
  "Brazil",
  "Chile",
  "Colombia",
  "Egypt",
  "France",
  "Germany",
  "India",
  "Indonesia",
  "Israel",
  "Italy",
  "Malaysia",
  "Mexico",
  "Netherlands",
  "Nigeria",
  "Pakistan",
  "Peru",
  "Russia",
  "Saudi Arabia",
  "South Africa",
  "Spain",
  "Turkey",
  "United Arab Emirates",
  "United Kingdom",
  "Other",
  "Canada",
  "United States",
  "Algeria",
  "Angola",
  "Benin",
  "Botswana",
  "Burkina Faso",
  "Burundi",
  "Cameroon",
  "Chad",
  "Congo",
  "Eritrea",
  "Ethiopia",
  "Gabon",
  "Gambia",
  "Ghana",
  "Guinea-Bissau",
  "Ivory Coast",
  "Kenya",
  "Lesotho",
  "Liberia",
  "Libya",
  "Madagascar",
  "Malawi",
  "Mali",
  "Mauritania",
  "Morocco",
  "Mozambique",
  "Namibia",
  "Niger",
  "Rwanda",
  "Senegal",
  "Sierra Leone",
  "Somalia",
  "South Sudan",
  "Sudan",
  "Swaziland",
  "Tanzania",
  "Togo",
  "Tunisia",
  "Uganda",
  "Zambia",
  "Afghanistan",
  "Australia",
  "Bangladesh",
  "Cambodia",
  "China",
  "Hong Kong",
  "Japan",
  "Laos",
  "Mongolia",
  "Nepal",
  "New Zealand",
  "Papua New Guinea",
  "Philippines",
  "Singapore",
  "Sri Lanka",
  "Taiwan",
  "Tajikistan",
  "Thailand",
  "Turkmenistan",
  "Uzbekistan",
  "Vietnam",
  "Albania",
  "Armenia",
  "Azerbaijan",
  "Belarus",
  "Bulgaria",
  "Croatia",
  "Czech Republic",
  "Georgia",
  "Greece",
  "Hungary",
  "Latvia",
  "Lithuania",
  "Macedonia",
  "Moldova",
  "Poland",
  "Romania",
  "Serbia",
  "Slovakia",
  "Slovenia",
  "Ukraine",
  "Bolivia",
  "Costa Rica",
  "Dominican Republic",
  "Ecuador",
  "El Salvador",
  "Guatemala",
  "Haiti",
  "Honduras",
  "Jamaica",
  "Nicaragua",
  "Panama",
  "Paraguay",
  "Puerto Rico",
  "Uruguay",
  "Venezuela",
  "Bahrain",
  "Iraq",
  "Jordan",
  "Kuwait",
  "Lebanon",
  "Oman",
  "Qatar",
  "Yemen",
  "Austria",
  "Belgium",
  "Denmark",
  "Finland",
  "Ireland",
  "Norway",
  "Portugal",
  "Sweden",
  "Switzerland",
];

export const currency: any = [
  "Afghanistan-Afghani",
  "Albania-Lek",
  "Algeria-Dinar",
  "American Samoa-Dollar",
  "Andorra-Euro",
  "Angola-Kwanza",
  "Anguilla-Dollar",
  "Antigua and Barbuda-Dollar",
  "Argentina-Peso",
  "Armenia-Dram",
  "Aruba-Guilder",
  "Australia-Dollar",
  "Austria-Euro",
  "Azerbaijan-Manat",
  "Bahamas-Dollar",
  "Bahrain-Dinar",
  "Bangladesh-Taka",
  "Barbados-Dollar",
  "Belarus-Ruble",
  "Belgium-Euro",
  "Belize-Dollar",
  "Benin-Franc",
  "Bermuda-Dollar",
  "Bhutan-Ngultrum",
  "Bolivia-Boliviano",
  "Bosnia and Herzegovina-Marka",
  "Botswana-Pula",
  "Brazil-Real",
  "Brunei-Dollar",
  "Bulgaria-Lev",
  "Burkina Faso-Franc",
  "Burundi-Franc",
  "Cambodia-Riels",
  "Cameroon-Franc",
  "Canada-Dollar",
  "Cape Verde-Escudo",
  "Cayman Islands-Dollar",
  "Central African Republic-Franc",
  "Chad-Franc",
  "Chile-Peso",
  "China-Yuan Renminbi",
  "Colombia-Peso",
  "Comoros-Franc",
  "Republic of the Congo-Franc",
  "DR Congo-Franc",
  "Cook Islands-Dollar",
  "Costa Rica-Colon",
  "Ivory Coast-Franc",
  "Croatia-Kuna",
  "Cuba-Peso",
  "Cyprus-Euro",
  "Czech Republic-Koruna",
  "Denmark-Krone",
  "Djibouti-Franc",
  "Dominica-Dollar",
  "Dominican Republic-Peso",
  "Ecuador-Dollar",
  "Egypt-Pound",
  "El Salvador-Dollar",
  "Equatorial Guinea-Franc",
  "Eritrea-Nakfa",
  "Estonia-Euro",
  "Ethiopia-Birr",
  "Falkland Islands-Pound",
  "Faroe Islands-Krone",
  "Fiji-Dollar",
  "Finland-Euro",
  "France-Euro",
  "French Guiana-undefined",
  "French Polynesia-Franc",
  "Gabon-Franc",
  "Gambia-Dalasi",
  "Georgia-Lari",
  "Germany-Euro",
  "Ghana-Cedi",
  "Gibraltar-Pound",
  "Greece-Euro",
  "Greenland-Krone",
  "Grenada-Dollar",
  "Guadeloupe-undefined",
  "Guam-Dollar",
  "Guatemala-Quetzal",
  "Guinea-Franc",
  "Guinea-Bissau-Franc",
  "Guyana-Dollar",
  "Haiti-Gourde",
  "Vatican City-Euro",
  "Honduras-Lempira",
  "Hong Kong-Dollar",
  "Hungary-Forint",
  "Iceland-Krona",
  "India-Rupee",
  "Indonesia-Rupiah",
  "Iran-Rial",
  "Iraq-Dinar",
  "Ireland-Euro",
  "Israel-Shekel",
  "Italy-Euro",
  "Jamaica-Dollar",
  "Japan-Yen",
  "Jordan-Dinar",
  "Kazakhstan-Tenge",
  "Kenya-Shilling",
  "Kiribati-Dollar",
  "North Korea-Won",
  "South Korea-Won",
  "Kuwait-Dinar",
  "Kyrgyzstan-Som",
  "Laos-Kip",
  "Latvia-Euro",
  "Lebanon-Pound",
  "Lesotho-Loti",
  "Liberia-Dollar",
  "Libya-Dinar",
  "Liechtenstein-Franc",
  "Lithuania-Euro",
  "Luxembourg-Euro",
  "Macau-Pataca",
  "Macedonia-Denar",
  "Madagascar-Ariary",
  "Malawi-Kwacha",
  "Malaysia-Ringgit",
  "Maldives-Rufiyaa",
  "Mali-Franc",
  "Malta-Euro",
  "Marshall Islands-Dollar",
  "Martinique-undefined",
  "Mauritania-Ouguiya",
  "Mauritius-Rupee",
  "Mexico-Peso",
  "Micronesia-Dollar",
  "Moldova-Leu",
  "Monaco-Euro",
  "Mongolia-Tugrik",
  "Montserrat-Dollar",
  "Morocco-Dirham",
  "Mozambique-Metical",
  "Myanmar-Kyat",
  "Namibia-Dollar",
  "Nauru-Dollar",
  "Nepal-Rupee",
  "Netherlands-Euro",
  "New Caledonia-Franc",
  "New Zealand-Dollar",
  "Nicaragua-Cordoba",
  "Niger-Franc",
  "Nigeria-Naira",
  "Niue-Dollar",
  "Norfolk Island-undefined",
  "Northern Mariana Islands-Dollar",
  "Norway-Krone",
  "Oman-Rial",
  "Pakistan-Rupee",
  "Palau-Dollar",
  "Panama-Balboa",
  "Papua New Guinea-Kina",
  "Paraguay-Guarani",
  "Peru-Sol",
  "Philippines-Peso",
  "Pitcairn Islands-Dollar",
  "Poland-Zloty",
  "Portugal-Euro",
  "Puerto Rico-Dollar",
  "Qatar-Rial",
  "Réunion-Euro",
  "Romania-Leu",
  "Russia-Ruble",
  "Rwanda-Franc",
  "Saint Kitts and Nevis-Dollar",
  "Saint Lucia-Dollar",
  "Saint Pierre and Miquelon-Euro",
  "Saint Vincent and the Grenadines-Dollar",
  "Samoa-Tala",
  "San Marino-Euro",
  "São Tomé and Príncipe-Dobra",
  "Saudi Arabia-Rial",
  "Senegal-Franc",
  "Serbia-undefined",
  "Montenegro-undefined",
  "Seychelles-Rupee",
  "Sierra Leone-Leone",
  "Singapore-Dollar",
  "Slovakia-Euro",
  "Slovenia-Euro",
  "Solomon Islands-Dollar",
  "Somalia-Shilling",
  "South Africa-Rand",
  "Spain-Euro",
  "Sri Lanka-Rupee",
  "Sudan-Pound",
  "Suriname-Dollar",
  "Svalbard and Jan Mayen-Krone",
  "Swaziland-Lilangeni",
  "Sweden-Krona",
  "Switzerland-Franc",
  "Syria-Pound",
  "Taiwan-Dollar",
  "Tajikistan-Somoni",
  "Tanzania-Shilling",
  "Thailand-Baht",
  "Togo-Franc",
  "Tokelau-Dollar",
  "Tonga-Pa'anga",
  "Trinidad and Tobago-Dollar",
  "Tunisia-Dinar",
  "Turkey-Lira",
  "Turkmenistan-Manat",
  "Turks and Caicos Islands-Dollar",
  "Tuvalu-Dollar",
  "Uganda-Shilling",
  "Ukraine-Hryvnia",
  "United Arab Emirates-Dirham",
  "United Kingdom-Pound",
  "United States-Dollar",
  "Uruguay-Peso",
  "Uzbekistan-Som",
  "Vanuatu-Vatu",
  "Venezuela-Bolivar",
  "Vietnam-Dong",
  "British Virgin Islands-Dollar",
  "United States Virgin Islands-Dollar",
  "Wallis and Futuna-Franc",
  "Western Sahara-Dirham",
  "Yemen-Rial",
  "Zambia-Kwacha",
  "Zimbabwe-Dollar",
];

export const timeZone: any = [
  {
    label: "Africa/Abidjan(GMT+00:00)",
    value: "Africa/Abidjan",
  },
  {
    label: "Africa/Accra(GMT+00:00)",
    value: "Africa/Accra",
  },
  {
    label: "Africa/Addis_Ababa(GMT+03:00)",
    value: "Africa/Addis_Ababa",
  },
  {
    label: "Africa/Algiers(GMT+01:00)",
    value: "Africa/Algiers",
  },
  {
    label: "Africa/Asmara(GMT+03:00)",
    value: "Africa/Asmara",
  },
  {
    label: "Africa/Asmera(GMT+03:00)",
    value: "Africa/Asmera",
  },
  {
    label: "Africa/Bamako(GMT+00:00)",
    value: "Africa/Bamako",
  },
  {
    label: "Africa/Bangui(GMT+01:00)",
    value: "Africa/Bangui",
  },
  {
    label: "Africa/Banjul(GMT+00:00)",
    value: "Africa/Banjul",
  },
  {
    label: "Africa/Bissau(GMT+00:00)",
    value: "Africa/Bissau",
  },
  {
    label: "Africa/Blantyre(GMT+02:00)",
    value: "Africa/Blantyre",
  },
  {
    label: "Africa/Brazzaville(GMT+01:00)",
    value: "Africa/Brazzaville",
  },
  {
    label: "Africa/Bujumbura(GMT+02:00)",
    value: "Africa/Bujumbura",
  },
  {
    label: "Africa/Cairo(GMT+02:00)",
    value: "Africa/Cairo",
  },
  {
    label: "Africa/Casablanca(GMT+01:00)",
    value: "Africa/Casablanca",
  },
  {
    label: "Africa/Ceuta(GMT+01:00)",
    value: "Africa/Ceuta",
  },
  {
    label: "Africa/Conakry(GMT+00:00)",
    value: "Africa/Conakry",
  },
  {
    label: "Africa/Dakar(GMT+00:00)",
    value: "Africa/Dakar",
  },
  {
    label: "Africa/Dar_es_Salaam(GMT+03:00)",
    value: "Africa/Dar_es_Salaam",
  },
  {
    label: "Africa/Djibouti(GMT+03:00)",
    value: "Africa/Djibouti",
  },
  {
    label: "Africa/Douala(GMT+01:00)",
    value: "Africa/Douala",
  },
  {
    label: "Africa/El_Aaiun(GMT+01:00)",
    value: "Africa/El_Aaiun",
  },
  {
    label: "Africa/Freetown(GMT+00:00)",
    value: "Africa/Freetown",
  },
  {
    label: "Africa/Gaborone(GMT+02:00)",
    value: "Africa/Gaborone",
  },
  {
    label: "Africa/Harare(GMT+02:00)",
    value: "Africa/Harare",
  },
  {
    label: "Africa/Johannesburg(GMT+02:00)",
    value: "Africa/Johannesburg",
  },
  {
    label: "Africa/Juba(GMT+02:00)",
    value: "Africa/Juba",
  },
  {
    label: "Africa/Kampala(GMT+03:00)",
    value: "Africa/Kampala",
  },
  {
    label: "Africa/Khartoum(GMT+02:00)",
    value: "Africa/Khartoum",
  },
  {
    label: "Africa/Kigali(GMT+02:00)",
    value: "Africa/Kigali",
  },
  {
    label: "Africa/Kinshasa(GMT+01:00)",
    value: "Africa/Kinshasa",
  },
  {
    label: "Africa/Lagos(GMT+01:00)",
    value: "Africa/Lagos",
  },
  {
    label: "Africa/Libreville(GMT+01:00)",
    value: "Africa/Libreville",
  },
  {
    label: "Africa/Lome(GMT+00:00)",
    value: "Africa/Lome",
  },
  {
    label: "Africa/Luanda(GMT+01:00)",
    value: "Africa/Luanda",
  },
  {
    label: "Africa/Lubumbashi(GMT+02:00)",
    value: "Africa/Lubumbashi",
  },
  {
    label: "Africa/Lusaka(GMT+02:00)",
    value: "Africa/Lusaka",
  },
  {
    label: "Africa/Malabo(GMT+01:00)",
    value: "Africa/Malabo",
  },
  {
    label: "Africa/Maputo(GMT+02:00)",
    value: "Africa/Maputo",
  },
  {
    label: "Africa/Maseru(GMT+02:00)",
    value: "Africa/Maseru",
  },
  {
    label: "Africa/Mbabane(GMT+02:00)",
    value: "Africa/Mbabane",
  },
  {
    label: "Africa/Mogadishu(GMT+03:00)",
    value: "Africa/Mogadishu",
  },
  {
    label: "Africa/Monrovia(GMT+00:00)",
    value: "Africa/Monrovia",
  },
  {
    label: "Africa/Nairobi(GMT+03:00)",
    value: "Africa/Nairobi",
  },
  {
    label: "Africa/Ndjamena(GMT+01:00)",
    value: "Africa/Ndjamena",
  },
  {
    label: "Africa/Niamey(GMT+01:00)",
    value: "Africa/Niamey",
  },
  {
    label: "Africa/Nouakchott(GMT+00:00)",
    value: "Africa/Nouakchott",
  },
  {
    label: "Africa/Ouagadougou(GMT+00:00)",
    value: "Africa/Ouagadougou",
  },
  {
    label: "Africa/Porto-Novo(GMT+01:00)",
    value: "Africa/Porto-Novo",
  },
  {
    label: "Africa/Sao_Tome(GMT+00:00)",
    value: "Africa/Sao_Tome",
  },
  {
    label: "Africa/Timbuktu(GMT+00:00)",
    value: "Africa/Timbuktu",
  },
  {
    label: "Africa/Tripoli(GMT+02:00)",
    value: "Africa/Tripoli",
  },
  {
    label: "Africa/Tunis(GMT+01:00)",
    value: "Africa/Tunis",
  },
  {
    label: "Africa/Windhoek(GMT+02:00)",
    value: "Africa/Windhoek",
  },
  {
    label: "America/Adak(GMT-09:00)",
    value: "America/Adak",
  },
  {
    label: "America/Anchorage(GMT-08:00)",
    value: "America/Anchorage",
  },
  {
    label: "America/Anguilla(GMT-04:00)",
    value: "America/Anguilla",
  },
  {
    label: "America/Antigua(GMT-04:00)",
    value: "America/Antigua",
  },
  {
    label: "America/Araguaina(GMT-03:00)",
    value: "America/Araguaina",
  },
  {
    label: "America/Argentina/Buenos_Aires(GMT-03:00)",
    value: "America/Argentina/Buenos_Aires",
  },
  {
    label: "America/Argentina/Catamarca(GMT-03:00)",
    value: "America/Argentina/Catamarca",
  },
  {
    label: "America/Argentina/ComodRivadavia(GMT-03:00)",
    value: "America/Argentina/ComodRivadavia",
  },
  {
    label: "America/Argentina/Cordoba(GMT-03:00)",
    value: "America/Argentina/Cordoba",
  },
  {
    label: "America/Argentina/Jujuy(GMT-03:00)",
    value: "America/Argentina/Jujuy",
  },
  {
    label: "America/Argentina/La_Rioja(GMT-03:00)",
    value: "America/Argentina/La_Rioja",
  },
  {
    label: "America/Argentina/Mendoza(GMT-03:00)",
    value: "America/Argentina/Mendoza",
  },
  {
    label: "America/Argentina/Rio_Gallegos(GMT-03:00)",
    value: "America/Argentina/Rio_Gallegos",
  },
  {
    label: "America/Argentina/Salta(GMT-03:00)",
    value: "America/Argentina/Salta",
  },
  {
    label: "America/Argentina/San_Juan(GMT-03:00)",
    value: "America/Argentina/San_Juan",
  },
  {
    label: "America/Argentina/San_Luis(GMT-03:00)",
    value: "America/Argentina/San_Luis",
  },
  {
    label: "America/Argentina/Tucuman(GMT-03:00)",
    value: "America/Argentina/Tucuman",
  },
  {
    label: "America/Argentina/Ushuaia(GMT-03:00)",
    value: "America/Argentina/Ushuaia",
  },
  {
    label: "America/Aruba(GMT-04:00)",
    value: "America/Aruba",
  },
  {
    label: "America/Asuncion(GMT-03:00)",
    value: "America/Asuncion",
  },
  {
    label: "America/Atikokan(GMT-05:00)",
    value: "America/Atikokan",
  },
  {
    label: "America/Atka(GMT-09:00)",
    value: "America/Atka",
  },
  {
    label: "America/Bahia(GMT-03:00)",
    value: "America/Bahia",
  },
  {
    label: "America/Bahia_Banderas(GMT-06:00)",
    value: "America/Bahia_Banderas",
  },
  {
    label: "America/Barbados(GMT-04:00)",
    value: "America/Barbados",
  },
  {
    label: "America/Belem(GMT-03:00)",
    value: "America/Belem",
  },
  {
    label: "America/Belize(GMT-06:00)",
    value: "America/Belize",
  },
  {
    label: "America/Blanc-Sablon(GMT-04:00)",
    value: "America/Blanc-Sablon",
  },
  {
    label: "America/Boa_Vista(GMT-04:00)",
    value: "America/Boa_Vista",
  },
  {
    label: "America/Bogota(GMT-05:00)",
    value: "America/Bogota",
  },
  {
    label: "America/Boise(GMT-06:00)",
    value: "America/Boise",
  },
  {
    label: "America/Buenos_Aires(GMT-03:00)",
    value: "America/Buenos_Aires",
  },
  {
    label: "America/Cambridge_Bay(GMT-06:00)",
    value: "America/Cambridge_Bay",
  },
  {
    label: "America/Campo_Grande(GMT-04:00)",
    value: "America/Campo_Grande",
  },
  {
    label: "America/Cancun(GMT-05:00)",
    value: "America/Cancun",
  },
  {
    label: "America/Caracas(GMT-04:00)",
    value: "America/Caracas",
  },
  {
    label: "America/Catamarca(GMT-03:00)",
    value: "America/Catamarca",
  },
  {
    label: "America/Cayenne(GMT-03:00)",
    value: "America/Cayenne",
  },
  {
    label: "America/Cayman(GMT-05:00)",
    value: "America/Cayman",
  },
  {
    label: "America/Chicago(GMT-05:00)",
    value: "America/Chicago",
  },
  {
    label: "America/Chihuahua(GMT-07:00)",
    value: "America/Chihuahua",
  },
  {
    label: "America/Coral_Harbour(GMT-05:00)",
    value: "America/Coral_Harbour",
  },
  {
    label: "America/Cordoba(GMT-03:00)",
    value: "America/Cordoba",
  },
  {
    label: "America/Costa_Rica(GMT-06:00)",
    value: "America/Costa_Rica",
  },
  {
    label: "America/Creston(GMT-07:00)",
    value: "America/Creston",
  },
  {
    label: "America/Cuiaba(GMT-04:00)",
    value: "America/Cuiaba",
  },
  {
    label: "America/Curacao(GMT-04:00)",
    value: "America/Curacao",
  },
  {
    label: "America/Danmarkshavn(GMT+00:00)",
    value: "America/Danmarkshavn",
  },
  {
    label: "America/Dawson(GMT-07:00)",
    value: "America/Dawson",
  },
  {
    label: "America/Dawson_Creek(GMT-07:00)",
    value: "America/Dawson_Creek",
  },
  {
    label: "America/Denver(GMT-06:00)",
    value: "America/Denver",
  },
  {
    label: "America/Detroit(GMT-04:00)",
    value: "America/Detroit",
  },
  {
    label: "America/Dominica(GMT-04:00)",
    value: "America/Dominica",
  },
  {
    label: "America/Edmonton(GMT-06:00)",
    value: "America/Edmonton",
  },
  {
    label: "America/Eirunepe(GMT-05:00)",
    value: "America/Eirunepe",
  },
  {
    label: "America/El_Salvador(GMT-06:00)",
    value: "America/El_Salvador",
  },
  {
    label: "America/Ensenada(GMT-07:00)",
    value: "America/Ensenada",
  },
  {
    label: "America/Fort_Nelson(GMT-07:00)",
    value: "America/Fort_Nelson",
  },
  {
    label: "America/Fort_Wayne(GMT-04:00)",
    value: "America/Fort_Wayne",
  },
  {
    label: "America/Fortaleza(GMT-03:00)",
    value: "America/Fortaleza",
  },
  {
    label: "America/Glace_Bay(GMT-03:00)",
    value: "America/Glace_Bay",
  },
  {
    label: "America/Godthab(GMT-03:00)",
    value: "America/Godthab",
  },
  {
    label: "America/Goose_Bay(GMT-03:00)",
    value: "America/Goose_Bay",
  },
  {
    label: "America/Grand_Turk(GMT-04:00)",
    value: "America/Grand_Turk",
  },
  {
    label: "America/Grenada(GMT-04:00)",
    value: "America/Grenada",
  },
  {
    label: "America/Guadeloupe(GMT-04:00)",
    value: "America/Guadeloupe",
  },
  {
    label: "America/Guatemala(GMT-06:00)",
    value: "America/Guatemala",
  },
  {
    label: "America/Guayaquil(GMT-05:00)",
    value: "America/Guayaquil",
  },
  {
    label: "America/Guyana(GMT-04:00)",
    value: "America/Guyana",
  },
  {
    label: "America/Halifax(GMT-03:00)",
    value: "America/Halifax",
  },
  {
    label: "America/Havana(GMT-04:00)",
    value: "America/Havana",
  },
  {
    label: "America/Hermosillo(GMT-07:00)",
    value: "America/Hermosillo",
  },
  {
    label: "America/Indiana/Indianapolis(GMT-04:00)",
    value: "America/Indiana/Indianapolis",
  },
  {
    label: "America/Indiana/Knox(GMT-05:00)",
    value: "America/Indiana/Knox",
  },
  {
    label: "America/Indiana/Marengo(GMT-04:00)",
    value: "America/Indiana/Marengo",
  },
  {
    label: "America/Indiana/Petersburg(GMT-04:00)",
    value: "America/Indiana/Petersburg",
  },
  {
    label: "America/Indiana/Tell_City(GMT-05:00)",
    value: "America/Indiana/Tell_City",
  },
  {
    label: "America/Indiana/Vevay(GMT-04:00)",
    value: "America/Indiana/Vevay",
  },
  {
    label: "America/Indiana/Vincennes(GMT-04:00)",
    value: "America/Indiana/Vincennes",
  },
  {
    label: "America/Indiana/Winamac(GMT-04:00)",
    value: "America/Indiana/Winamac",
  },
  {
    label: "America/Indianapolis(GMT-04:00)",
    value: "America/Indianapolis",
  },
  {
    label: "America/Inuvik(GMT-06:00)",
    value: "America/Inuvik",
  },
  {
    label: "America/Iqaluit(GMT-04:00)",
    value: "America/Iqaluit",
  },
  {
    label: "America/Jamaica(GMT-05:00)",
    value: "America/Jamaica",
  },
  {
    label: "America/Jujuy(GMT-03:00)",
    value: "America/Jujuy",
  },
  {
    label: "America/Juneau(GMT-08:00)",
    value: "America/Juneau",
  },
  {
    label: "America/Kentucky/Louisville(GMT-04:00)",
    value: "America/Kentucky/Louisville",
  },
  {
    label: "America/Kentucky/Monticello(GMT-04:00)",
    value: "America/Kentucky/Monticello",
  },
  {
    label: "America/Knox_IN(GMT-05:00)",
    value: "America/Knox_IN",
  },
  {
    label: "America/Kralendijk(GMT-04:00)",
    value: "America/Kralendijk",
  },
  {
    label: "America/La_Paz(GMT-04:00)",
    value: "America/La_Paz",
  },
  {
    label: "America/Lima(GMT-05:00)",
    value: "America/Lima",
  },
  {
    label: "America/Los_Angeles(GMT-07:00)",
    value: "America/Los_Angeles",
  },
  {
    label: "America/Louisville(GMT-04:00)",
    value: "America/Louisville",
  },
  {
    label: "America/Lower_Princes(GMT-04:00)",
    value: "America/Lower_Princes",
  },
  {
    label: "America/Maceio(GMT-03:00)",
    value: "America/Maceio",
  },
  {
    label: "America/Managua(GMT-06:00)",
    value: "America/Managua",
  },
  {
    label: "America/Manaus(GMT-04:00)",
    value: "America/Manaus",
  },
  {
    label: "America/Marigot(GMT-04:00)",
    value: "America/Marigot",
  },
  {
    label: "America/Martinique(GMT-04:00)",
    value: "America/Martinique",
  },
  {
    label: "America/Matamoros(GMT-05:00)",
    value: "America/Matamoros",
  },
  {
    label: "America/Mazatlan(GMT-07:00)",
    value: "America/Mazatlan",
  },
  {
    label: "America/Mendoza(GMT-03:00)",
    value: "America/Mendoza",
  },
  {
    label: "America/Menominee(GMT-05:00)",
    value: "America/Menominee",
  },
  {
    label: "America/Merida(GMT-06:00)",
    value: "America/Merida",
  },
  {
    label: "America/Metlakatla(GMT-08:00)",
    value: "America/Metlakatla",
  },
  {
    label: "America/Mexico_City(GMT-06:00)",
    value: "America/Mexico_City",
  },
  {
    label: "America/Miquelon(GMT-02:00)",
    value: "America/Miquelon",
  },
  {
    label: "America/Moncton(GMT-03:00)",
    value: "America/Moncton",
  },
  {
    label: "America/Monterrey(GMT-06:00)",
    value: "America/Monterrey",
  },
  {
    label: "America/Montevideo(GMT-03:00)",
    value: "America/Montevideo",
  },
  {
    label: "America/Montreal(GMT-04:00)",
    value: "America/Montreal",
  },
  {
    label: "America/Montserrat(GMT-04:00)",
    value: "America/Montserrat",
  },
  {
    label: "America/Nassau(GMT-04:00)",
    value: "America/Nassau",
  },
  {
    label: "America/New_York(GMT-04:00)",
    value: "America/New_York",
  },
  {
    label: "America/Nipigon(GMT-04:00)",
    value: "America/Nipigon",
  },
  {
    label: "America/Nome(GMT-08:00)",
    value: "America/Nome",
  },
  {
    label: "America/Noronha(GMT-02:00)",
    value: "America/Noronha",
  },
  {
    label: "America/North_Dakota/Beulah(GMT-05:00)",
    value: "America/North_Dakota/Beulah",
  },
  {
    label: "America/North_Dakota/Center(GMT-05:00)",
    value: "America/North_Dakota/Center",
  },
  {
    label: "America/North_Dakota/New_Salem(GMT-05:00)",
    value: "America/North_Dakota/New_Salem",
  },
  {
    label: "America/Nuuk(GMT-03:00)",
    value: "America/Nuuk",
  },
  {
    label: "America/Ojinaga(GMT-06:00)",
    value: "America/Ojinaga",
  },
  {
    label: "America/Panama(GMT-05:00)",
    value: "America/Panama",
  },
  {
    label: "America/Pangnirtung(GMT-04:00)",
    value: "America/Pangnirtung",
  },
  {
    label: "America/Paramaribo(GMT-03:00)",
    value: "America/Paramaribo",
  },
  {
    label: "America/Phoenix(GMT-07:00)",
    value: "America/Phoenix",
  },
  {
    label: "America/Port-au-Prince(GMT-04:00)",
    value: "America/Port-au-Prince",
  },
  {
    label: "America/Port_of_Spain(GMT-04:00)",
    value: "America/Port_of_Spain",
  },
  {
    label: "America/Porto_Acre(GMT-05:00)",
    value: "America/Porto_Acre",
  },
  {
    label: "America/Porto_Velho(GMT-04:00)",
    value: "America/Porto_Velho",
  },
  {
    label: "America/Puerto_Rico(GMT-04:00)",
    value: "America/Puerto_Rico",
  },
  {
    label: "America/Punta_Arenas(GMT-03:00)",
    value: "America/Punta_Arenas",
  },
  {
    label: "America/Rainy_River(GMT-05:00)",
    value: "America/Rainy_River",
  },
  {
    label: "America/Rankin_Inlet(GMT-05:00)",
    value: "America/Rankin_Inlet",
  },
  {
    label: "America/Recife(GMT-03:00)",
    value: "America/Recife",
  },
  {
    label: "America/Regina(GMT-06:00)",
    value: "America/Regina",
  },
  {
    label: "America/Resolute(GMT-05:00)",
    value: "America/Resolute",
  },
  {
    label: "America/Rio_Branco(GMT-05:00)",
    value: "America/Rio_Branco",
  },
  {
    label: "America/Rosario(GMT-03:00)",
    value: "America/Rosario",
  },
  {
    label: "America/Santa_Isabel(GMT-07:00)",
    value: "America/Santa_Isabel",
  },
  {
    label: "America/Santarem(GMT-03:00)",
    value: "America/Santarem",
  },
  {
    label: "America/Santiago(GMT-03:00)",
    value: "America/Santiago",
  },
  {
    label: "America/Santo_Domingo(GMT-04:00)",
    value: "America/Santo_Domingo",
  },
  {
    label: "America/Sao_Paulo(GMT-03:00)",
    value: "America/Sao_Paulo",
  },
  {
    label: "America/Scoresbysund(GMT-01:00)",
    value: "America/Scoresbysund",
  },
  {
    label: "America/Shiprock(GMT-06:00)",
    value: "America/Shiprock",
  },
  {
    label: "America/Sitka(GMT-08:00)",
    value: "America/Sitka",
  },
  {
    label: "America/St_Barthelemy(GMT-04:00)",
    value: "America/St_Barthelemy",
  },
  {
    label: "America/St_Johns(GMT-02:30)",
    value: "America/St_Johns",
  },
  {
    label: "America/St_Kitts(GMT-04:00)",
    value: "America/St_Kitts",
  },
  {
    label: "America/St_Lucia(GMT-04:00)",
    value: "America/St_Lucia",
  },
  {
    label: "America/St_Thomas(GMT-04:00)",
    value: "America/St_Thomas",
  },
  {
    label: "America/St_Vincent(GMT-04:00)",
    value: "America/St_Vincent",
  },
  {
    label: "America/Swift_Current(GMT-06:00)",
    value: "America/Swift_Current",
  },
  {
    label: "America/Tegucigalpa(GMT-06:00)",
    value: "America/Tegucigalpa",
  },
  {
    label: "America/Thule(GMT-03:00)",
    value: "America/Thule",
  },
  {
    label: "America/Thunder_Bay(GMT-04:00)",
    value: "America/Thunder_Bay",
  },
  {
    label: "America/Tijuana(GMT-07:00)",
    value: "America/Tijuana",
  },
  {
    label: "America/Toronto(GMT-04:00)",
    value: "America/Toronto",
  },
  {
    label: "America/Tortola(GMT-04:00)",
    value: "America/Tortola",
  },
  {
    label: "America/Vancouver(GMT-07:00)",
    value: "America/Vancouver",
  },
  {
    label: "America/Virgin(GMT-04:00)",
    value: "America/Virgin",
  },
  {
    label: "America/Whitehorse(GMT-07:00)",
    value: "America/Whitehorse",
  },
  {
    label: "America/Winnipeg(GMT-05:00)",
    value: "America/Winnipeg",
  },
  {
    label: "America/Yakutat(GMT-08:00)",
    value: "America/Yakutat",
  },
  {
    label: "America/Yellowknife(GMT-06:00)",
    value: "America/Yellowknife",
  },
  {
    label: "Antarctica/Casey(GMT+11:00)",
    value: "Antarctica/Casey",
  },
  {
    label: "Antarctica/Davis(GMT+07:00)",
    value: "Antarctica/Davis",
  },
  {
    label: "Antarctica/DumontDUrville(GMT+10:00)",
    value: "Antarctica/DumontDUrville",
  },
  {
    label: "Antarctica/Macquarie(GMT+11:00)",
    value: "Antarctica/Macquarie",
  },
  {
    label: "Antarctica/Mawson(GMT+05:00)",
    value: "Antarctica/Mawson",
  },
  {
    label: "Antarctica/McMurdo(GMT+13:00)",
    value: "Antarctica/McMurdo",
  },
  {
    label: "Antarctica/Palmer(GMT-03:00)",
    value: "Antarctica/Palmer",
  },
  {
    label: "Antarctica/Rothera(GMT-03:00)",
    value: "Antarctica/Rothera",
  },
  {
    label: "Antarctica/South_Pole(GMT+13:00)",
    value: "Antarctica/South_Pole",
  },
  {
    label: "Antarctica/Syowa(GMT+03:00)",
    value: "Antarctica/Syowa",
  },
  {
    label: "Antarctica/Troll(GMT+00:00)",
    value: "Antarctica/Troll",
  },
  {
    label: "Antarctica/Vostok(GMT+06:00)",
    value: "Antarctica/Vostok",
  },
  {
    label: "Arctic/Longyearbyen(GMT+01:00)",
    value: "Arctic/Longyearbyen",
  },
  {
    label: "Asia/Aden(GMT+03:00)",
    value: "Asia/Aden",
  },
  {
    label: "Asia/Almaty(GMT+06:00)",
    value: "Asia/Almaty",
  },
  {
    label: "Asia/Amman(GMT+03:00)",
    value: "Asia/Amman",
  },
  {
    label: "Asia/Anadyr(GMT+12:00)",
    value: "Asia/Anadyr",
  },
  {
    label: "Asia/Aqtau(GMT+05:00)",
    value: "Asia/Aqtau",
  },
  {
    label: "Asia/Aqtobe(GMT+05:00)",
    value: "Asia/Aqtobe",
  },
  {
    label: "Asia/Ashgabat(GMT+05:00)",
    value: "Asia/Ashgabat",
  },
  {
    label: "Asia/Ashkhabad(GMT+05:00)",
    value: "Asia/Ashkhabad",
  },
  {
    label: "Asia/Atyrau(GMT+05:00)",
    value: "Asia/Atyrau",
  },
  {
    label: "Asia/Baghdad(GMT+03:00)",
    value: "Asia/Baghdad",
  },
  {
    label: "Asia/Bahrain(GMT+03:00)",
    value: "Asia/Bahrain",
  },
  {
    label: "Asia/Baku(GMT+04:00)",
    value: "Asia/Baku",
  },
  {
    label: "Asia/Bangkok(GMT+07:00)",
    value: "Asia/Bangkok",
  },
  {
    label: "Asia/Barnaul(GMT+07:00)",
    value: "Asia/Barnaul",
  },
  {
    label: "Asia/Beirut(GMT+02:00)",
    value: "Asia/Beirut",
  },
  {
    label: "Asia/Bishkek(GMT+06:00)",
    value: "Asia/Bishkek",
  },
  {
    label: "Asia/Brunei(GMT+08:00)",
    value: "Asia/Brunei",
  },
  {
    label: "Asia/Calcutta(GMT+05:30)",
    value: "Asia/Calcutta",
  },
  {
    label: "Asia/Chita(GMT+09:00)",
    value: "Asia/Chita",
  },
  {
    label: "Asia/Choibalsan(GMT+08:00)",
    value: "Asia/Choibalsan",
  },
  {
    label: "Asia/Chongqing(GMT+08:00)",
    value: "Asia/Chongqing",
  },
  {
    label: "Asia/Chungking(GMT+08:00)",
    value: "Asia/Chungking",
  },
  {
    label: "Asia/Colombo(GMT+05:30)",
    value: "Asia/Colombo",
  },
  {
    label: "Asia/Dacca(GMT+06:00)",
    value: "Asia/Dacca",
  },
  {
    label: "Asia/Damascus(GMT+02:00)",
    value: "Asia/Damascus",
  },
  {
    label: "Asia/Dhaka(GMT+06:00)",
    value: "Asia/Dhaka",
  },
  {
    label: "Asia/Dili(GMT+09:00)",
    value: "Asia/Dili",
  },
  {
    label: "Asia/Dubai(GMT+04:00)",
    value: "Asia/Dubai",
  },
  {
    label: "Asia/Dushanbe(GMT+05:00)",
    value: "Asia/Dushanbe",
  },
  {
    label: "Asia/Famagusta(GMT+02:00)",
    value: "Asia/Famagusta",
  },
  {
    label: "Asia/Gaza(GMT+02:00)",
    value: "Asia/Gaza",
  },
  {
    label: "Asia/Harbin(GMT+08:00)",
    value: "Asia/Harbin",
  },
  {
    label: "Asia/Hebron(GMT+02:00)",
    value: "Asia/Hebron",
  },
  {
    label: "Asia/Ho_Chi_Minh(GMT+07:00)",
    value: "Asia/Ho_Chi_Minh",
  },
  {
    label: "Asia/Hong_Kong(GMT+08:00)",
    value: "Asia/Hong_Kong",
  },
  {
    label: "Asia/Hovd(GMT+07:00)",
    value: "Asia/Hovd",
  },
  {
    label: "Asia/Irkutsk(GMT+08:00)",
    value: "Asia/Irkutsk",
  },
  {
    label: "Asia/Istanbul(GMT+03:00)",
    value: "Asia/Istanbul",
  },
  {
    label: "Asia/Jakarta(GMT+07:00)",
    value: "Asia/Jakarta",
  },
  {
    label: "Asia/Jayapura(GMT+09:00)",
    value: "Asia/Jayapura",
  },
  {
    label: "Asia/Jerusalem(GMT+02:00)",
    value: "Asia/Jerusalem",
  },
  {
    label: "Asia/Kabul(GMT+04:30)",
    value: "Asia/Kabul",
  },
  {
    label: "Asia/Kamchatka(GMT+12:00)",
    value: "Asia/Kamchatka",
  },
  {
    label: "Asia/Karachi(GMT+05:00)",
    value: "Asia/Karachi",
  },
  {
    label: "Asia/Kashgar(GMT+06:00)",
    value: "Asia/Kashgar",
  },
  {
    label: "Asia/Kathmandu(GMT+05:45)",
    value: "Asia/Kathmandu",
  },
  {
    label: "Asia/Katmandu(GMT+05:45)",
    value: "Asia/Katmandu",
  },
  {
    label: "Asia/Khandyga(GMT+09:00)",
    value: "Asia/Khandyga",
  },
  {
    label: "Asia/Kolkata(GMT+05:30)",
    value: "Asia/Kolkata",
  },
  {
    label: "Asia/Krasnoyarsk(GMT+07:00)",
    value: "Asia/Krasnoyarsk",
  },
  {
    label: "Asia/Kuala_Lumpur(GMT+08:00)",
    value: "Asia/Kuala_Lumpur",
  },
  {
    label: "Asia/Kuching(GMT+08:00)",
    value: "Asia/Kuching",
  },
  {
    label: "Asia/Kuwait(GMT+03:00)",
    value: "Asia/Kuwait",
  },
  {
    label: "Asia/Macao(GMT+08:00)",
    value: "Asia/Macao",
  },
  {
    label: "Asia/Macau(GMT+08:00)",
    value: "Asia/Macau",
  },
  {
    label: "Asia/Magadan(GMT+11:00)",
    value: "Asia/Magadan",
  },
  {
    label: "Asia/Makassar(GMT+08:00)",
    value: "Asia/Makassar",
  },
  {
    label: "Asia/Manila(GMT+08:00)",
    value: "Asia/Manila",
  },
  {
    label: "Asia/Muscat(GMT+04:00)",
    value: "Asia/Muscat",
  },
  {
    label: "Asia/Nicosia(GMT+02:00)",
    value: "Asia/Nicosia",
  },
  {
    label: "Asia/Novokuznetsk(GMT+07:00)",
    value: "Asia/Novokuznetsk",
  },
  {
    label: "Asia/Novosibirsk(GMT+07:00)",
    value: "Asia/Novosibirsk",
  },
  {
    label: "Asia/Omsk(GMT+06:00)",
    value: "Asia/Omsk",
  },
  {
    label: "Asia/Oral(GMT+05:00)",
    value: "Asia/Oral",
  },
  {
    label: "Asia/Phnom_Penh(GMT+07:00)",
    value: "Asia/Phnom_Penh",
  },
  {
    label: "Asia/Pontianak(GMT+07:00)",
    value: "Asia/Pontianak",
  },
  {
    label: "Asia/Pyongyang(GMT+09:00)",
    value: "Asia/Pyongyang",
  },
  {
    label: "Asia/Qatar(GMT+03:00)",
    value: "Asia/Qatar",
  },
  {
    label: "Asia/Qostanay(GMT+06:00)",
    value: "Asia/Qostanay",
  },
  {
    label: "Asia/Qyzylorda(GMT+05:00)",
    value: "Asia/Qyzylorda",
  },
  {
    label: "Asia/Rangoon(GMT+06:30)",
    value: "Asia/Rangoon",
  },
  {
    label: "Asia/Riyadh(GMT+03:00)",
    value: "Asia/Riyadh",
  },
  {
    label: "Asia/Saigon(GMT+07:00)",
    value: "Asia/Saigon",
  },
  {
    label: "Asia/Sakhalin(GMT+11:00)",
    value: "Asia/Sakhalin",
  },
  {
    label: "Asia/Samarkand(GMT+05:00)",
    value: "Asia/Samarkand",
  },
  {
    label: "Asia/Seoul(GMT+09:00)",
    value: "Asia/Seoul",
  },
  {
    label: "Asia/Shanghai(GMT+08:00)",
    value: "Asia/Shanghai",
  },
  {
    label: "Asia/Singapore(GMT+08:00)",
    value: "Asia/Singapore",
  },
  {
    label: "Asia/Srednekolymsk(GMT+11:00)",
    value: "Asia/Srednekolymsk",
  },
  {
    label: "Asia/Taipei(GMT+08:00)",
    value: "Asia/Taipei",
  },
  {
    label: "Asia/Tashkent(GMT+05:00)",
    value: "Asia/Tashkent",
  },
  {
    label: "Asia/Tbilisi(GMT+04:00)",
    value: "Asia/Tbilisi",
  },
  {
    label: "Asia/Tehran(GMT+03:30)",
    value: "Asia/Tehran",
  },
  {
    label: "Asia/Tel_Aviv(GMT+02:00)",
    value: "Asia/Tel_Aviv",
  },
  {
    label: "Asia/Thimbu(GMT+06:00)",
    value: "Asia/Thimbu",
  },
  {
    label: "Asia/Thimphu(GMT+06:00)",
    value: "Asia/Thimphu",
  },
  {
    label: "Asia/Tokyo(GMT+09:00)",
    value: "Asia/Tokyo",
  },
  {
    label: "Asia/Tomsk(GMT+07:00)",
    value: "Asia/Tomsk",
  },
  {
    label: "Asia/Ujung_Pandang(GMT+08:00)",
    value: "Asia/Ujung_Pandang",
  },
  {
    label: "Asia/Ulaanbaatar(GMT+08:00)",
    value: "Asia/Ulaanbaatar",
  },
  {
    label: "Asia/Ulan_Bator(GMT+08:00)",
    value: "Asia/Ulan_Bator",
  },
  {
    label: "Asia/Urumqi(GMT+06:00)",
    value: "Asia/Urumqi",
  },
  {
    label: "Asia/Ust-Nera(GMT+10:00)",
    value: "Asia/Ust-Nera",
  },
  {
    label: "Asia/Vientiane(GMT+07:00)",
    value: "Asia/Vientiane",
  },
  {
    label: "Asia/Vladivostok(GMT+10:00)",
    value: "Asia/Vladivostok",
  },
  {
    label: "Asia/Yakutsk(GMT+09:00)",
    value: "Asia/Yakutsk",
  },
  {
    label: "Asia/Yangon(GMT+06:30)",
    value: "Asia/Yangon",
  },
  {
    label: "Asia/Yekaterinburg(GMT+05:00)",
    value: "Asia/Yekaterinburg",
  },
  {
    label: "Asia/Yerevan(GMT+04:00)",
    value: "Asia/Yerevan",
  },
  {
    label: "Atlantic/Azores(GMT-01:00)",
    value: "Atlantic/Azores",
  },
  {
    label: "Atlantic/Bermuda(GMT-03:00)",
    value: "Atlantic/Bermuda",
  },
  {
    label: "Atlantic/Canary(GMT+00:00)",
    value: "Atlantic/Canary",
  },
  {
    label: "Atlantic/Cape_Verde(GMT-01:00)",
    value: "Atlantic/Cape_Verde",
  },
  {
    label: "Atlantic/Faeroe(GMT+00:00)",
    value: "Atlantic/Faeroe",
  },
  {
    label: "Atlantic/Faroe(GMT+00:00)",
    value: "Atlantic/Faroe",
  },
  {
    label: "Atlantic/Jan_Mayen(GMT+01:00)",
    value: "Atlantic/Jan_Mayen",
  },
  {
    label: "Atlantic/Madeira(GMT+00:00)",
    value: "Atlantic/Madeira",
  },
  {
    label: "Atlantic/Reykjavik(GMT+00:00)",
    value: "Atlantic/Reykjavik",
  },
  {
    label: "Atlantic/South_Georgia(GMT-02:00)",
    value: "Atlantic/South_Georgia",
  },
  {
    label: "Atlantic/St_Helena(GMT+00:00)",
    value: "Atlantic/St_Helena",
  },
  {
    label: "Atlantic/Stanley(GMT-03:00)",
    value: "Atlantic/Stanley",
  },
  {
    label: "Australia/ACT(GMT+11:00)",
    value: "Australia/ACT",
  },
  {
    label: "Australia/Adelaide(GMT+10:30)",
    value: "Australia/Adelaide",
  },
  {
    label: "Australia/Brisbane(GMT+10:00)",
    value: "Australia/Brisbane",
  },
  {
    label: "Australia/Broken_Hill(GMT+10:30)",
    value: "Australia/Broken_Hill",
  },
  {
    label: "Australia/Canberra(GMT+11:00)",
    value: "Australia/Canberra",
  },
  {
    label: "Australia/Currie(GMT+11:00)",
    value: "Australia/Currie",
  },
  {
    label: "Australia/Darwin(GMT+09:30)",
    value: "Australia/Darwin",
  },
  {
    label: "Australia/Eucla(GMT+08:45)",
    value: "Australia/Eucla",
  },
  {
    label: "Australia/Hobart(GMT+11:00)",
    value: "Australia/Hobart",
  },
  {
    label: "Australia/LHI(GMT+11:00)",
    value: "Australia/LHI",
  },
  {
    label: "Australia/Lindeman(GMT+10:00)",
    value: "Australia/Lindeman",
  },
  {
    label: "Australia/Lord_Howe(GMT+11:00)",
    value: "Australia/Lord_Howe",
  },
  {
    label: "Australia/Melbourne(GMT+11:00)",
    value: "Australia/Melbourne",
  },
  {
    label: "Australia/NSW(GMT+11:00)",
    value: "Australia/NSW",
  },
  {
    label: "Australia/North(GMT+09:30)",
    value: "Australia/North",
  },
  {
    label: "Australia/Perth(GMT+08:00)",
    value: "Australia/Perth",
  },
  {
    label: "Australia/Queensland(GMT+10:00)",
    value: "Australia/Queensland",
  },
  {
    label: "Australia/South(GMT+10:30)",
    value: "Australia/South",
  },
  {
    label: "Australia/Sydney(GMT+11:00)",
    value: "Australia/Sydney",
  },
  {
    label: "Australia/Tasmania(GMT+11:00)",
    value: "Australia/Tasmania",
  },
  {
    label: "Australia/Victoria(GMT+11:00)",
    value: "Australia/Victoria",
  },
  {
    label: "Australia/West(GMT+08:00)",
    value: "Australia/West",
  },
  {
    label: "Australia/Yancowinna(GMT+10:30)",
    value: "Australia/Yancowinna",
  },
  {
    label: "Brazil/Acre(GMT-05:00)",
    value: "Brazil/Acre",
  },
  {
    label: "Brazil/DeNoronha(GMT-02:00)",
    value: "Brazil/DeNoronha",
  },
  {
    label: "Brazil/East(GMT-03:00)",
    value: "Brazil/East",
  },
  {
    label: "Brazil/West(GMT-04:00)",
    value: "Brazil/West",
  },
  {
    label: "CET(GMT+01:00)",
    value: "CET",
  },
  {
    label: "CST6CDT(GMT-05:00)",
    value: "CST6CDT",
  },
  {
    label: "Canada/Atlantic(GMT-03:00)",
    value: "Canada/Atlantic",
  },
  {
    label: "Canada/Central(GMT-05:00)",
    value: "Canada/Central",
  },
  {
    label: "Canada/Eastern(GMT-04:00)",
    value: "Canada/Eastern",
  },
  {
    label: "Canada/Mountain(GMT-06:00)",
    value: "Canada/Mountain",
  },
  {
    label: "Canada/Newfoundland(GMT-02:30)",
    value: "Canada/Newfoundland",
  },
  {
    label: "Canada/Pacific(GMT-07:00)",
    value: "Canada/Pacific",
  },
  {
    label: "Canada/Saskatchewan(GMT-06:00)",
    value: "Canada/Saskatchewan",
  },
  {
    label: "Canada/Yukon(GMT-07:00)",
    value: "Canada/Yukon",
  },
  {
    label: "Chile/Continental(GMT-03:00)",
    value: "Chile/Continental",
  },
  {
    label: "Chile/EasterIsland(GMT-05:00)",
    value: "Chile/EasterIsland",
  },
  {
    label: "Cuba(GMT-04:00)",
    value: "Cuba",
  },
  {
    label: "EET(GMT+02:00)",
    value: "EET",
  },
  {
    label: "EST(GMT-05:00)",
    value: "EST",
  },
  {
    label: "EST5EDT(GMT-04:00)",
    value: "EST5EDT",
  },
  {
    label: "Egypt(GMT+02:00)",
    value: "Egypt",
  },
  {
    label: "Eire(GMT+00:00)",
    value: "Eire",
  },
  {
    label: "Etc/GMT(GMT+00:00)",
    value: "Etc/GMT",
  },
  {
    label: "Etc/GMT+0(GMT+00:00)",
    value: "Etc/GMT+0",
  },
  {
    label: "Etc/GMT+1(GMT-01:00)",
    value: "Etc/GMT+1",
  },
  {
    label: "Etc/GMT+10(GMT-10:00)",
    value: "Etc/GMT+10",
  },
  {
    label: "Etc/GMT+11(GMT-11:00)",
    value: "Etc/GMT+11",
  },
  {
    label: "Etc/GMT+12(GMT-12:00)",
    value: "Etc/GMT+12",
  },
  {
    label: "Etc/GMT+2(GMT-02:00)",
    value: "Etc/GMT+2",
  },
  {
    label: "Etc/GMT+3(GMT-03:00)",
    value: "Etc/GMT+3",
  },
  {
    label: "Etc/GMT+4(GMT-04:00)",
    value: "Etc/GMT+4",
  },
  {
    label: "Etc/GMT+5(GMT-05:00)",
    value: "Etc/GMT+5",
  },
  {
    label: "Etc/GMT+6(GMT-06:00)",
    value: "Etc/GMT+6",
  },
  {
    label: "Etc/GMT+7(GMT-07:00)",
    value: "Etc/GMT+7",
  },
  {
    label: "Etc/GMT+8(GMT-08:00)",
    value: "Etc/GMT+8",
  },
  {
    label: "Etc/GMT+9(GMT-09:00)",
    value: "Etc/GMT+9",
  },
  {
    label: "Etc/GMT-0(GMT+00:00)",
    value: "Etc/GMT-0",
  },
  {
    label: "Etc/GMT-1(GMT+01:00)",
    value: "Etc/GMT-1",
  },
  {
    label: "Etc/GMT-10(GMT+10:00)",
    value: "Etc/GMT-10",
  },
  {
    label: "Etc/GMT-11(GMT+11:00)",
    value: "Etc/GMT-11",
  },
  {
    label: "Etc/GMT-12(GMT+12:00)",
    value: "Etc/GMT-12",
  },
  {
    label: "Etc/GMT-13(GMT+13:00)",
    value: "Etc/GMT-13",
  },
  {
    label: "Etc/GMT-14(GMT+14:00)",
    value: "Etc/GMT-14",
  },
  {
    label: "Etc/GMT-2(GMT+02:00)",
    value: "Etc/GMT-2",
  },
  {
    label: "Etc/GMT-3(GMT+03:00)",
    value: "Etc/GMT-3",
  },
  {
    label: "Etc/GMT-4(GMT+04:00)",
    value: "Etc/GMT-4",
  },
  {
    label: "Etc/GMT-5(GMT+05:00)",
    value: "Etc/GMT-5",
  },
  {
    label: "Etc/GMT-6(GMT+06:00)",
    value: "Etc/GMT-6",
  },
  {
    label: "Etc/GMT-7(GMT+07:00)",
    value: "Etc/GMT-7",
  },
  {
    label: "Etc/GMT-8(GMT+08:00)",
    value: "Etc/GMT-8",
  },
  {
    label: "Etc/GMT-9(GMT+09:00)",
    value: "Etc/GMT-9",
  },
  {
    label: "Etc/GMT0(GMT+00:00)",
    value: "Etc/GMT0",
  },
  {
    label: "Etc/Greenwich(GMT+00:00)",
    value: "Etc/Greenwich",
  },
  {
    label: "Etc/UCT(GMT+00:00)",
    value: "Etc/UCT",
  },
  {
    label: "Etc/UTC(GMT+00:00)",
    value: "Etc/UTC",
  },
  {
    label: "Etc/Universal(GMT+00:00)",
    value: "Etc/Universal",
  },
  {
    label: "Etc/Zulu(GMT+00:00)",
    value: "Etc/Zulu",
  },
  {
    label: "Europe/Amsterdam(GMT+01:00)",
    value: "Europe/Amsterdam",
  },
  {
    label: "Europe/Andorra(GMT+01:00)",
    value: "Europe/Andorra",
  },
  {
    label: "Europe/Astrakhan(GMT+04:00)",
    value: "Europe/Astrakhan",
  },
  {
    label: "Europe/Athens(GMT+02:00)",
    value: "Europe/Athens",
  },
  {
    label: "Europe/Belfast(GMT+00:00)",
    value: "Europe/Belfast",
  },
  {
    label: "Europe/Belgrade(GMT+01:00)",
    value: "Europe/Belgrade",
  },
  {
    label: "Europe/Berlin(GMT+01:00)",
    value: "Europe/Berlin",
  },
  {
    label: "Europe/Bratislava(GMT+01:00)",
    value: "Europe/Bratislava",
  },
  {
    label: "Europe/Brussels(GMT+01:00)",
    value: "Europe/Brussels",
  },
  {
    label: "Europe/Bucharest(GMT+02:00)",
    value: "Europe/Bucharest",
  },
  {
    label: "Europe/Budapest(GMT+01:00)",
    value: "Europe/Budapest",
  },
  {
    label: "Europe/Busingen(GMT+01:00)",
    value: "Europe/Busingen",
  },
  {
    label: "Europe/Chisinau(GMT+02:00)",
    value: "Europe/Chisinau",
  },
  {
    label: "Europe/Copenhagen(GMT+01:00)",
    value: "Europe/Copenhagen",
  },
  {
    label: "Europe/Dublin(GMT+00:00)",
    value: "Europe/Dublin",
  },
  {
    label: "Europe/Gibraltar(GMT+01:00)",
    value: "Europe/Gibraltar",
  },
  {
    label: "Europe/Guernsey(GMT+00:00)",
    value: "Europe/Guernsey",
  },
  {
    label: "Europe/Helsinki(GMT+02:00)",
    value: "Europe/Helsinki",
  },
  {
    label: "Europe/Isle_of_Man(GMT+00:00)",
    value: "Europe/Isle_of_Man",
  },
  {
    label: "Europe/Istanbul(GMT+03:00)",
    value: "Europe/Istanbul",
  },
  {
    label: "Europe/Jersey(GMT+00:00)",
    value: "Europe/Jersey",
  },
  {
    label: "Europe/Kaliningrad(GMT+02:00)",
    value: "Europe/Kaliningrad",
  },
  {
    label: "Europe/Kiev(GMT+02:00)",
    value: "Europe/Kiev",
  },
  {
    label: "Europe/Kirov(GMT+03:00)",
    value: "Europe/Kirov",
  },
  {
    label: "Europe/Kyiv(GMT+02:00)",
    value: "Europe/Kyiv",
  },
  {
    label: "Europe/Lisbon(GMT+00:00)",
    value: "Europe/Lisbon",
  },
  {
    label: "Europe/Ljubljana(GMT+01:00)",
    value: "Europe/Ljubljana",
  },
  {
    label: "Europe/London(GMT+00:00)",
    value: "Europe/London",
  },
  {
    label: "Europe/Luxembourg(GMT+01:00)",
    value: "Europe/Luxembourg",
  },
  {
    label: "Europe/Madrid(GMT+01:00)",
    value: "Europe/Madrid",
  },
  {
    label: "Europe/Malta(GMT+01:00)",
    value: "Europe/Malta",
  },
  {
    label: "Europe/Mariehamn(GMT+02:00)",
    value: "Europe/Mariehamn",
  },
  {
    label: "Europe/Minsk(GMT+03:00)",
    value: "Europe/Minsk",
  },
  {
    label: "Europe/Monaco(GMT+01:00)",
    value: "Europe/Monaco",
  },
  {
    label: "Europe/Moscow(GMT+03:00)",
    value: "Europe/Moscow",
  },
  {
    label: "Europe/Nicosia(GMT+02:00)",
    value: "Europe/Nicosia",
  },
  {
    label: "Europe/Oslo(GMT+01:00)",
    value: "Europe/Oslo",
  },
  {
    label: "Europe/Paris(GMT+01:00)",
    value: "Europe/Paris",
  },
  {
    label: "Europe/Podgorica(GMT+01:00)",
    value: "Europe/Podgorica",
  },
  {
    label: "Europe/Prague(GMT+01:00)",
    value: "Europe/Prague",
  },
  {
    label: "Europe/Riga(GMT+02:00)",
    value: "Europe/Riga",
  },
  {
    label: "Europe/Rome(GMT+01:00)",
    value: "Europe/Rome",
  },
  {
    label: "Europe/Samara(GMT+04:00)",
    value: "Europe/Samara",
  },
  {
    label: "Europe/San_Marino(GMT+01:00)",
    value: "Europe/San_Marino",
  },
  {
    label: "Europe/Sarajevo(GMT+01:00)",
    value: "Europe/Sarajevo",
  },
  {
    label: "Europe/Saratov(GMT+04:00)",
    value: "Europe/Saratov",
  },
  {
    label: "Europe/Simferopol(GMT+03:00)",
    value: "Europe/Simferopol",
  },
  {
    label: "Europe/Skopje(GMT+01:00)",
    value: "Europe/Skopje",
  },
  {
    label: "Europe/Sofia(GMT+02:00)",
    value: "Europe/Sofia",
  },
  {
    label: "Europe/Stockholm(GMT+01:00)",
    value: "Europe/Stockholm",
  },
  {
    label: "Europe/Tallinn(GMT+02:00)",
    value: "Europe/Tallinn",
  },
  {
    label: "Europe/Tirane(GMT+01:00)",
    value: "Europe/Tirane",
  },
  {
    label: "Europe/Tiraspol(GMT+02:00)",
    value: "Europe/Tiraspol",
  },
  {
    label: "Europe/Ulyanovsk(GMT+04:00)",
    value: "Europe/Ulyanovsk",
  },
  {
    label: "Europe/Uzhgorod(GMT+02:00)",
    value: "Europe/Uzhgorod",
  },
  {
    label: "Europe/Vaduz(GMT+01:00)",
    value: "Europe/Vaduz",
  },
  {
    label: "Europe/Vatican(GMT+01:00)",
    value: "Europe/Vatican",
  },
  {
    label: "Europe/Vienna(GMT+01:00)",
    value: "Europe/Vienna",
  },
  {
    label: "Europe/Vilnius(GMT+02:00)",
    value: "Europe/Vilnius",
  },
  {
    label: "Europe/Volgograd(GMT+03:00)",
    value: "Europe/Volgograd",
  },
  {
    label: "Europe/Warsaw(GMT+01:00)",
    value: "Europe/Warsaw",
  },
  {
    label: "Europe/Zagreb(GMT+01:00)",
    value: "Europe/Zagreb",
  },
  {
    label: "Europe/Zaporozhye(GMT+02:00)",
    value: "Europe/Zaporozhye",
  },
  {
    label: "Europe/Zurich(GMT+01:00)",
    value: "Europe/Zurich",
  },
  {
    label: "GB(GMT+00:00)",
    value: "GB",
  },
  {
    label: "GB-Eire(GMT+00:00)",
    value: "GB-Eire",
  },
  {
    label: "GMT(GMT+00:00)",
    value: "GMT",
  },
  {
    label: "GMT+0(GMT+00:00)",
    value: "GMT+0",
  },
  {
    label: "GMT-0(GMT+00:00)",
    value: "GMT-0",
  },
  {
    label: "GMT0(GMT+00:00)",
    value: "GMT0",
  },
  {
    label: "Greenwich(GMT+00:00)",
    value: "Greenwich",
  },
  {
    label: "HST(GMT-10:00)",
    value: "HST",
  },
  {
    label: "Hongkong(GMT+08:00)",
    value: "Hongkong",
  },
  {
    label: "Iceland(GMT+00:00)",
    value: "Iceland",
  },
  {
    label: "Indian/Antananarivo(GMT+03:00)",
    value: "Indian/Antananarivo",
  },
  {
    label: "Indian/Chagos(GMT+06:00)",
    value: "Indian/Chagos",
  },
  {
    label: "Indian/Christmas(GMT+07:00)",
    value: "Indian/Christmas",
  },
  {
    label: "Indian/Cocos(GMT+06:30)",
    value: "Indian/Cocos",
  },
  {
    label: "Indian/Comoro(GMT+03:00)",
    value: "Indian/Comoro",
  },
  {
    label: "Indian/Kerguelen(GMT+05:00)",
    value: "Indian/Kerguelen",
  },
  {
    label: "Indian/Mahe(GMT+04:00)",
    value: "Indian/Mahe",
  },
  {
    label: "Indian/Maldives(GMT+05:00)",
    value: "Indian/Maldives",
  },
  {
    label: "Indian/Mauritius(GMT+04:00)",
    value: "Indian/Mauritius",
  },
  {
    label: "Indian/Mayotte(GMT+03:00)",
    value: "Indian/Mayotte",
  },
  {
    label: "Indian/Reunion(GMT+04:00)",
    value: "Indian/Reunion",
  },
  {
    label: "Iran(GMT+03:30)",
    value: "Iran",
  },
  {
    label: "Israel(GMT+02:00)",
    value: "Israel",
  },
  {
    label: "Jamaica(GMT-05:00)",
    value: "Jamaica",
  },
  {
    label: "Japan(GMT+09:00)",
    value: "Japan",
  },
  {
    label: "Kwajalein(GMT+12:00)",
    value: "Kwajalein",
  },
  {
    label: "Libya(GMT+02:00)",
    value: "Libya",
  },
  {
    label: "MET(GMT+01:00)",
    value: "MET",
  },
  {
    label: "MST(GMT-07:00)",
    value: "MST",
  },
  {
    label: "MST7MDT(GMT-06:00)",
    value: "MST7MDT",
  },
  {
    label: "Mexico/BajaNorte(GMT-07:00)",
    value: "Mexico/BajaNorte",
  },
  {
    label: "Mexico/BajaSur(GMT-07:00)",
    value: "Mexico/BajaSur",
  },
  {
    label: "Mexico/General(GMT-06:00)",
    value: "Mexico/General",
  },
  {
    label: "NZ(GMT+13:00)",
    value: "NZ",
  },
  {
    label: "NZ-CHAT(GMT+13:45)",
    value: "NZ-CHAT",
  },
  {
    label: "Navajo(GMT-06:00)",
    value: "Navajo",
  },
  {
    label: "PRC(GMT+08:00)",
    value: "PRC",
  },
  {
    label: "PST8PDT(GMT-07:00)",
    value: "PST8PDT",
  },
  {
    label: "Pacific/Apia(GMT+13:00)",
    value: "Pacific/Apia",
  },
  {
    label: "Pacific/Auckland(GMT+13:00)",
    value: "Pacific/Auckland",
  },
  {
    label: "Pacific/Bougainville(GMT+11:00)",
    value: "Pacific/Bougainville",
  },
  {
    label: "Pacific/Chatham(GMT+13:45)",
    value: "Pacific/Chatham",
  },
  {
    label: "Pacific/Chuuk(GMT+10:00)",
    value: "Pacific/Chuuk",
  },
  {
    label: "Pacific/Easter(GMT-05:00)",
    value: "Pacific/Easter",
  },
  {
    label: "Pacific/Efate(GMT+11:00)",
    value: "Pacific/Efate",
  },
  {
    label: "Pacific/Enderbury(GMT+13:00)",
    value: "Pacific/Enderbury",
  },
  {
    label: "Pacific/Fakaofo(GMT+13:00)",
    value: "Pacific/Fakaofo",
  },
  {
    label: "Pacific/Fiji(GMT+12:00)",
    value: "Pacific/Fiji",
  },
  {
    label: "Pacific/Funafuti(GMT+12:00)",
    value: "Pacific/Funafuti",
  },
  {
    label: "Pacific/Galapagos(GMT-06:00)",
    value: "Pacific/Galapagos",
  },
  {
    label: "Pacific/Gambier(GMT-09:00)",
    value: "Pacific/Gambier",
  },
  {
    label: "Pacific/Guadalcanal(GMT+11:00)",
    value: "Pacific/Guadalcanal",
  },
  {
    label: "Pacific/Guam(GMT+10:00)",
    value: "Pacific/Guam",
  },
  {
    label: "Pacific/Honolulu(GMT-10:00)",
    value: "Pacific/Honolulu",
  },
  {
    label: "Pacific/Johnston(GMT-10:00)",
    value: "Pacific/Johnston",
  },
  {
    label: "Pacific/Kanton(GMT+13:00)",
    value: "Pacific/Kanton",
  },
  {
    label: "Pacific/Kiritimati(GMT+14:00)",
    value: "Pacific/Kiritimati",
  },
  {
    label: "Pacific/Kosrae(GMT+11:00)",
    value: "Pacific/Kosrae",
  },
  {
    label: "Pacific/Kwajalein(GMT+12:00)",
    value: "Pacific/Kwajalein",
  },
  {
    label: "Pacific/Majuro(GMT+12:00)",
    value: "Pacific/Majuro",
  },
  {
    label: "Pacific/Marquesas(GMT-09:30)",
    value: "Pacific/Marquesas",
  },
  {
    label: "Pacific/Midway(GMT-11:00)",
    value: "Pacific/Midway",
  },
  {
    label: "Pacific/Nauru(GMT+12:00)",
    value: "Pacific/Nauru",
  },
  {
    label: "Pacific/Niue(GMT-11:00)",
    value: "Pacific/Niue",
  },
  {
    label: "Pacific/Norfolk(GMT+12:00)",
    value: "Pacific/Norfolk",
  },
  {
    label: "Pacific/Noumea(GMT+11:00)",
    value: "Pacific/Noumea",
  },
  {
    label: "Pacific/Pago_Pago(GMT-11:00)",
    value: "Pacific/Pago_Pago",
  },
  {
    label: "Pacific/Palau(GMT+09:00)",
    value: "Pacific/Palau",
  },
  {
    label: "Pacific/Pitcairn(GMT-08:00)",
    value: "Pacific/Pitcairn",
  },
  {
    label: "Pacific/Pohnpei(GMT+11:00)",
    value: "Pacific/Pohnpei",
  },
  {
    label: "Pacific/Ponape(GMT+11:00)",
    value: "Pacific/Ponape",
  },
  {
    label: "Pacific/Port_Moresby(GMT+10:00)",
    value: "Pacific/Port_Moresby",
  },
  {
    label: "Pacific/Rarotonga(GMT-10:00)",
    value: "Pacific/Rarotonga",
  },
  {
    label: "Pacific/Saipan(GMT+10:00)",
    value: "Pacific/Saipan",
  },
  {
    label: "Pacific/Samoa(GMT-11:00)",
    value: "Pacific/Samoa",
  },
  {
    label: "Pacific/Tahiti(GMT-10:00)",
    value: "Pacific/Tahiti",
  },
  {
    label: "Pacific/Tarawa(GMT+12:00)",
    value: "Pacific/Tarawa",
  },
  {
    label: "Pacific/Tongatapu(GMT+13:00)",
    value: "Pacific/Tongatapu",
  },
  {
    label: "Pacific/Truk(GMT+10:00)",
    value: "Pacific/Truk",
  },
  {
    label: "Pacific/Wake(GMT+12:00)",
    value: "Pacific/Wake",
  },
  {
    label: "Pacific/Wallis(GMT+12:00)",
    value: "Pacific/Wallis",
  },
  {
    label: "Pacific/Yap(GMT+10:00)",
    value: "Pacific/Yap",
  },
  {
    label: "Poland(GMT+01:00)",
    value: "Poland",
  },
  {
    label: "Portugal(GMT+00:00)",
    value: "Portugal",
  },
  {
    label: "ROC(GMT+08:00)",
    value: "ROC",
  },
  {
    label: "ROK(GMT+09:00)",
    value: "ROK",
  },
  {
    label: "Singapore(GMT+08:00)",
    value: "Singapore",
  },
  {
    label: "Turkey(GMT+03:00)",
    value: "Turkey",
  },
  {
    label: "UCT(GMT+00:00)",
    value: "UCT",
  },
  {
    label: "US/Alaska(GMT-08:00)",
    value: "US/Alaska",
  },
  {
    label: "US/Aleutian(GMT-09:00)",
    value: "US/Aleutian",
  },
  {
    label: "US/Arizona(GMT-07:00)",
    value: "US/Arizona",
  },
  {
    label: "US/Central(GMT-05:00)",
    value: "US/Central",
  },
  {
    label: "US/East-Indiana(GMT-04:00)",
    value: "US/East-Indiana",
  },
  {
    label: "US/Eastern(GMT-04:00)",
    value: "US/Eastern",
  },
  {
    label: "US/Hawaii(GMT-10:00)",
    value: "US/Hawaii",
  },
  {
    label: "US/Indiana-Starke(GMT-05:00)",
    value: "US/Indiana-Starke",
  },
  {
    label: "US/Michigan(GMT-04:00)",
    value: "US/Michigan",
  },
  {
    label: "US/Mountain(GMT-06:00)",
    value: "US/Mountain",
  },
  {
    label: "US/Pacific(GMT-07:00)",
    value: "US/Pacific",
  },
  {
    label: "US/Samoa(GMT-11:00)",
    value: "US/Samoa",
  },
  {
    label: "UTC(GMT+00:00)",
    value: "UTC",
  },
  {
    label: "Universal(GMT+00:00)",
    value: "Universal",
  },
  {
    label: "W-SU(GMT+03:00)",
    value: "W-SU",
  },
  {
    label: "WET(GMT+00:00)",
    value: "WET",
  },
  {
    label: "Zulu(GMT+00:00)",
    value: "Zulu",
  },
];

export const contactBGs: any = {
  A: { bg: "#FFCCBC", color: "#222222" },
  B: { bg: "#C5E1A5", color: "#222222" },
  C: { bg: "#FFF9C4", color: "#222222" },
  D: { bg: "#BBDEFB", color: "#222222" },
  E: { bg: "#E0F7FA", color: "#222222" },
  F: { bg: "#B3E5FC", color: "#222222" },
  G: { bg: "#D1C4E9", color: "#222222" },
  H: { bg: "#F8BBD0", color: "#222222" },
  I: { bg: "#F5F5F5", color: "#222222" },
  J: { bg: "#C8E6C9", color: "#222222" },
  K: { bg: "#FFF59D", color: "#222222" },
  L: { bg: "#FFAB91", color: "#222222" },
  M: { bg: "#D7CCC8", color: "#222222" },
  N: { bg: "#EDE7F6", color: "#222222" },
  O: { bg: "#FFF9C4", color: "#222222" },
  P: { bg: "#D1C4E9", color: "#222222" },
  Q: { bg: "#D1C4E9", color: "#222222" },
  R: { bg: "#F8BBD0", color: "#222222" },
  S: { bg: "#FFAB91", color: "#222222" },
  T: { bg: "#B2DFDB", color: "#222222" },
  U: { bg: "#FFCCBC", color: "#222222" },
  V: { bg: "#E1BEE7", color: "#222222" },
  W: { bg: "#C8E6C9", color: "#222222" },
  X: { bg: "#EF9A9A", color: "#222222" },
  Y: { bg: "#F8BBD0", color: "#222222" },
  Z: { bg: "#A5D6A7", color: "#222222" },
  "0": { bg: "#607D8B ", color: "#fff" },
  "1": { bg: "#FF5722 ", color: "#fff" },
  "2": { bg: "#FFC107", color: "#fff" },
  "3": { bg: "#4CAF50", color: "#fff" },
  "4": { bg: "#2196F3", color: "#fff" },
  "5": { bg: "#9C27B0", color: "#fff" },
  "6": { bg: "#FF9800", color: "#fff" },
  "7": { bg: "#F44336", color: "#fff" },
  "8": { bg: "#795548", color: "#fff" },
  "9": { bg: "#009688", color: "#fff" },
  "+": { bg: "#607D8B", color: "#fff" },
};

export const countryList: any = [
  {
    Country: "Afghanistan ",
    countryCode: "AF",
  },
  {
    Country: "Albania ",
    countryCode: "AL",
  },
  {
    Country: "Algeria ",
    countryCode: "DZ",
  },
  {
    Country: "American Samoa",
    countryCode: "AS",
  },
  {
    Country: "Andorra, Principality of ",
    countryCode: "AD",
  },
  {
    Country: "Angola",
    countryCode: "AO",
  },
  {
    Country: "Anguilla ",
    countryCode: "AI",
  },
  {
    Country: "Antarctica",
    countryCode: "AQ",
  },
  {
    Country: "Antigua and Barbuda",
    countryCode: "AG",
  },
  {
    Country: "Argentina ",
    countryCode: "AR",
  },
  {
    Country: "Armenia",
    countryCode: "AM",
  },
  {
    Country: "Aruba",
    countryCode: "AW",
  },
  {
    Country: "Australia",
    countryCode: "AU",
  },
  {
    Country: "Austria",
    countryCode: "AT",
  },
  {
    Country: "Azerbaijan",
    countryCode: "AZ",
    "International dialing": "+994",
  },

  { Country: "Bahamas, Commonwealth of The", countryCode: "BS" },
  {
    Country: "Bahrain, Kingdom of (Former Dilmun)",
    countryCode: "BH",
  },
  {
    Country: "Bangladesh (Former East Pakistan)",
    countryCode: "BD",
  },
  {
    Country: "Barbados ",
    countryCode: "BB",
  },
  {
    Country: "Belarus",
    countryCode: "BY",
  },
  {
    Country: "Belgium ",
    countryCode: "BE",
  },
  {
    Country: "Belize (Former British Honduras)",
    countryCode: "BZ",
  },
  {
    Country: "Benin (Former Dahomey)",
    countryCode: "BJ",
  },
  {
    Country: "Bermuda ",
    countryCode: "BM",
  },
  {
    Country: "Bhutan, Kingdom of",
    countryCode: "BT",
  },
  {
    Country: "Bolivia ",
    countryCode: "BO",
  },
  {
    Country: "Bosnia and Herzegovina ",
    countryCode: "BA",
  },
  {
    Country: "Botswana (Former Bechuanaland)",
    countryCode: "BW",
  },
  {
    Country: "Bouvet Island (Territory of Norway)",
    countryCode: "BV",
  },
  {
    Country: "Brazil ",
    countryCode: "BR",
  },
  {
    Country: "British Indian Ocean Territory (BIOT)",
    countryCode: "IO",
  },
  {
    Country: "Brunei (Negara Brunei Darussalam) ",
    countryCode: "BN",
  },
  {
    Country: "Bulgaria ",
    countryCode: "BG",
  },
  {
    Country: "Burkina Faso (Former Upper Volta)",
    countryCode: "BF",
  },
  {
    Country: "Burundi (Former Urundi)",
    countryCode: "BI",
  },

  { Country: "ambodia", countryCode: "KH" },
  {
    Country: "Cameroon (Former French Cameroon)",
    countryCode: "CM",
  },
  {
    Country: "Canada ",
    countryCode: "CA",
  },
  {
    Country: "Cape Verde ",
    countryCode: "CV",
  },
  {
    Country: "Cayman Islands ",
    countryCode: "KY",
  },
  {
    Country: "Central African Republic ",
    countryCode: "CF",
  },
  {
    Country: "Chad ",
    countryCode: "TD",
  },
  {
    Country: "Chile ",
    countryCode: "CL",
  },
  {
    Country: "China ",
    countryCode: "CN",
  },
  {
    Country: "Christmas Island ",
    countryCode: "CX",
  },
  {
    Country: "Cocos (Keeling) Islands ",
    countryCode: "CC",
  },
  {
    Country: "Colombia ",
    countryCode: "CO",
  },
  {
    Country: "Comoros, Union of the ",
    countryCode: "KM",
  },
  {
    Country: "Congo, Democratic Republic of the (Former Zaire) ",
    countryCode: "CD",
  },
  {
    Country: "Congo, Republic of the",
    countryCode: "CG",
  },
  {
    Country: "Cook Islands (Former Harvey Islands)",
    countryCode: "CK",
  },
  {
    Country: "Costa Rica ",
    countryCode: "CR",
  },
  {
    Country: "Cote D'Ivoire (Former Ivory Coast) ",
    countryCode: "CI",
  },
  {
    Country: "Croatia (Hrvatska) ",
    countryCode: "HR",
  },
  {
    Country: "Cuba ",
    countryCode: "CU",
  },
  {
    Country: "Cyprus ",
    countryCode: "CY",
  },
  {
    Country: "Czech Republic",
    countryCode: "CZ",
  },
  {
    Country: "Czechoslavakia (Former) See CZ Czech Republic or Slovakia",
    countryCode: "CS",
  },

  { Country: "Denmark ", countryCode: "DK" },
  {
    Country: "Djibouti",
    countryCode: "DJ",
  },
  {
    Country: "Dominica ",
    countryCode: "DM",
  },
  {
    Country: "Dominican Republic ",
    countryCode: "DO",
  },

  { Country: "East Timor (Former Portuguese Timor)", countryCode: "TP" },
  {
    Country: "Ecuador ",
    countryCode: "EC",
  },
  {
    Country: "Egypt (Former United Arab Republic - with Syria)",
    countryCode: "EG",
  },
  {
    Country: "El Salvador ",
    countryCode: "SV",
  },
  {
    Country: "Equatorial Guinea (Former Spanish Guinea)",
    countryCode: "GQ",
  },
  {
    Country: "Eritrea (Former Eritrea Autonomous Region in Ethiopia)",
    countryCode: "ER",
  },
  {
    Country: "Estonia (Former Estonian Soviet Socialist Republic)",
    countryCode: "EE",
  },
  {
    Country: "Ethiopia (Former Abyssinia, Italian East Africa)",
    countryCode: "ET",
  },

  { Country: "Falkland Islands (Islas Malvinas) ", countryCode: "FK" },
  {
    Country: "Faroe Islands ",
    countryCode: "FO",
  },
  {
    Country: "Fiji ",
    countryCode: "FJ",
  },
  {
    Country: "Finland ",
    countryCode: "FI",
  },
  {
    Country: "France ",
    countryCode: "FR",
  },
  {
    Country: "French Guiana or French Guyana ",
    countryCode: "GF",
  },
  {
    Country: "French Polynesia (Former French Colony of Oceania)",
    countryCode: "PF",
  },
  {
    Country: "French Southern Territories and Antarctic Lands ",
    countryCode: "TF",
  },

  { Country: "Gabon (Gabonese Republic)", countryCode: "GA" },
  {
    Country: "Gambia, The ",
    countryCode: "GM",
  },
  {
    Country: "Georgia (Former Georgian Soviet Socialist Republic)",
    countryCode: "GE",
  },
  {
    Country: "Germany ",
    countryCode: "DE",
  },
  {
    Country: "Ghana (Former Gold Coast)",
    countryCode: "GH",
  },
  {
    Country: "Gibraltar ",
    countryCode: "GI",
  },
  {
    Country: "Great Britain (United Kingdom) ",
    countryCode: "GB",
  },
  {
    Country: "Greece ",
    countryCode: "GR",
  },
  {
    Country: "Greenland ",
    countryCode: "GL",
  },
  {
    Country: "Grenada ",
    countryCode: "GD",
  },
  {
    Country: "Guadeloupe",
    countryCode: "GP",
  },
  {
    Country: "Guam",
    countryCode: "GU",
  },
  {
    Country: "Guatemala ",
    countryCode: "GT",
  },
  {
    Country: "Guinea (Former French Guinea)",
    countryCode: "GN",
  },
  {
    Country: "Guinea-Bissau (Former Portuguese Guinea)",
    countryCode: "GW",
  },
  {
    Country: "Guyana (Former British Guiana)",
    countryCode: "GY",
  },
  { Country: "Haiti ", countryCode: "HT" },
  {
    Country: "Heard Island and McDonald Islands (Territory of Australia)",
    countryCode: "HM",
  },
  {
    Country: "Holy See (Vatican City State)",
    countryCode: "VA",
  },
  {
    Country: "Honduras ",
    countryCode: "HN",
  },
  {
    Country: "Hong Kong ",
    countryCode: "HK",
  },
  {
    Country: "Hungary ",
    countryCode: "HU",
  },

  { Country: "Iceland ", countryCode: "IS" },
  {
    Country: "India ",
    countryCode: "IN",
  },
  {
    Country: "Indonesia",
    countryCode: "ID",
  },
  {
    Country: "Iran, Islamic Republic of",
    countryCode: "IR",
  },
  {
    Country: "Iraq ",
    countryCode: "IQ",
  },
  {
    Country: "Ireland ",
    countryCode: "IE",
  },
  {
    Country: "Israel ",
    countryCode: "IL",
  },
  {
    Country: "Italy ",
    countryCode: "IT",
  },

  { Country: "Jamaica ", countryCode: "JM" },
  {
    Country: "Japan ",
    countryCode: "JP",
  },
  {
    Country: "Jordan (Former Transjordan)",
    countryCode: "JO",
  },

  { Country: "azakstan", countryCode: "KZ" },
  {
    Country: "Kenya (Former British East Africa)",
    countryCode: "KE",
  },
  {
    Country: "Kiribati",
    countryCode: "KI",
  },
  {
    Country: "Korea, Democratic People's Republic of (North Korea)",
    countryCode: "KP",
  },
  {
    Country: "Korea, Republic of (South Korea) ",
    countryCode: "KR",
  },
  {
    Country: "Kuwait ",
    countryCode: "KW",
  },
  {
    Country: "Kyrgyzstan",
    countryCode: "KG",
  },
  { Country: "Lao People's Democratic Republic (Laos)", countryCode: "LA" },
  {
    Country: "Latvia (Former Latvian Soviet Socialist Republic)",
    countryCode: "LV",
  },
  {
    Country: "Lebanon ",
    countryCode: "LB",
  },
  {
    Country: "Lesotho (Former Basutoland)",
    countryCode: "LS",
  },
  {
    Country: "Liberia ",
    countryCode: "LR",
  },
  {
    Country: "Libya (Libyan Arab Jamahiriya)",
    countryCode: "LY",
  },
  {
    Country: "Liechtenstein ",
    countryCode: "LI",
  },
  {
    Country: "Lithuania (Former Lithuanian Soviet Socialist Republic)",
    countryCode: "LT",
  },
  {
    Country: "Luxembourg ",
    countryCode: "LU",
  },
  { Country: "Macau ", countryCode: "MO" },
  {
    Country: "Macedonia, The Former Yugoslav Republic of",
    countryCode: "MK",
  },
  {
    Country: "Madagascar (Former Malagasy Republic)",
    countryCode: "MG",
  },
  {
    Country: "Malawi",
    countryCode: "MW",
  },
  {
    Country: "Malaysia ",
    countryCode: "MY",
  },
  {
    Country: "Maldives ",
    countryCode: "MV",
  },
  {
    Country: "Mali (Former French Sudan and Sudanese Republic) ",
    countryCode: "ML",
  },
  {
    Country: "Malta ",
    countryCode: "MT",
  },
  {
    Country: "Marshall Islands",
    countryCode: "MH",
  },
  {
    Country: "Martinique (French) ",
    countryCode: "MQ",
  },
  {
    Country: "Mauritania ",
    countryCode: "MR",
  },
  {
    Country: "Mauritius ",
    countryCode: "MU",
  },
  {
    Country: "Mayotte (Territorial Collectivity of Mayotte)",
    countryCode: "YT",
  },
  {
    Country: "Mexico ",
    countryCode: "MX",
  },
  {
    Country: "Micronesia",
    countryCode: "FM",
  },
  {
    Country: "Moldova, Republic of",
    countryCode: "MD",
  },
  {
    Country: "Monaco, Principality of",
    countryCode: "MC",
  },
  {
    Country: "Mongolia (Former Outer Mongolia)",
    countryCode: "MN",
  },
  {
    Country: "Montserrat ",
    countryCode: "MS",
  },
  {
    Country: "Morocco ",
    countryCode: "MA",
  },
  {
    Country: "Mozambique (Former Portuguese East Africa)",
    countryCode: "MZ",
  },
  {
    Country: "Myanmar, Union of (Former Burma)",
    countryCode: "MM",
  },
  {
    Country: "Namibia (Former German Southwest Africa, South-West Africa)",
    countryCode: "NA",
  },
  {
    Country: "Nauru (Former Pleasant Island)",
    countryCode: "NR",
  },
  {
    Country: "Nepal ",
    countryCode: "NP",
  },
  {
    Country: "Netherlands ",
    countryCode: "NL",
  },
  {
    Country: "Netherlands Antilles (Former Curacao and Dependencies)",
    countryCode: "AN",
  },
  {
    Country: "New Caledonia ",
    countryCode: "NC",
  },
  {
    Country: "New Zealand (Aotearoa) ",
    countryCode: "NZ",
  },
  {
    Country: "Nicaragua ",
    countryCode: "NI",
  },
  {
    Country: "Niger ",
    countryCode: "NE",
  },
  {
    Country: "Nigeria ",
    countryCode: "NG",
  },
  {
    Country: "Niue (Former Savage Island)",
    countryCode: "NU",
  },
  {
    Country: "Norfolk Island ",
    countryCode: "NF",
  },
  {
    Country: "Northern MarianaIslands",
    countryCode: "MP",
  },
  {
    Country: "Norway ",
    countryCode: "NO",
  },

  {
    Country: "Oman, Sultanate of (Former Muscat and Oman)",
    countryCode: "OM",
  },

  { Country: "Pakistan (Former West Pakistan)", countryCode: "PK" },
  {
    Country: "Palau",
    countryCode: "PW",
  },
  {
    Country: "Palestinian State (Proposed)",
    countryCode: "PS",
  },
  {
    Country: "Panama ",
    countryCode: "PA",
  },
  {
    Country: "Papua New Guinea (Former Territory of Papua and New Guinea)",
    countryCode: "PG",
  },
  {
    Country: "Paraguay ",
    countryCode: "PY",
  },
  {
    Country: "Peru ",
    countryCode: "PE",
  },
  {
    Country: "Philippines ",
    countryCode: "PH",
  },
  {
    Country: "Pitcairn Island",
    countryCode: "PN",
  },
  {
    Country: "Poland ",
    countryCode: "PL",
  },
  {
    Country: "Portugal ",
    countryCode: "PT",
  },
  {
    Country: "Puerto Rico ",
    countryCode: "PR",
  },

  { Country: "Qatar, State of ", countryCode: "QA" },

  { Country: "Reunion (French) (Former Bourbon Island)", countryCode: "RE" },
  {
    Country: "Romania ",
    countryCode: "RO",
  },
  {
    Country: "Russia -USSR",
    countryCode: "SU",
  },
  {
    Country: "Russian Federation ",
    countryCode: "RU",
  },
  {
    Country: "Rwanda (Rwandese Republic) (Former Ruanda)",
    countryCode: "RW",
  },

  { Country: "Saint Helena ", countryCode: "SH" },
  {
    Country: "Saint Kittsand Nevis",
    countryCode: "KN",
  },
  {
    Country: "Saint Lucia ",
    countryCode: "LC",
  },
  {
    Country: "Saint Pierre and Miquelon ",
    countryCode: "PM",
  },
  {
    Country: "Saint Vincent and the Grenadines ",
    countryCode: "VC",
  },
  {
    Country: "Samoa (Former Western Samoa)",
    countryCode: "WS",
  },
  {
    Country: "San Marino ",
    countryCode: "SM",
  },
  {
    Country: "Sao Tome and Principe ",
    countryCode: "ST",
  },
  {
    Country: "Saudi Arabia ",
    countryCode: "SA",
  },
  {
    Country: "Serbia, Republic of",
    countryCode: "RS",
  },
  {
    Country: "Senegal ",
    countryCode: "SN",
  },
  {
    Country: "Seychelles ",
    countryCode: "SC",
  },
  {
    Country: "Sierra Leone ",
    countryCode: "SL",
  },
  {
    Country: "Singapore ",
    countryCode: "SG",
  },
  {
    Country: "Slovakia",
    countryCode: "SK",
  },
  {
    Country: "Slovenia ",
    countryCode: "SI",
  },
  {
    Country: "Solomon Islands (Former British Solomon Islands)",
    countryCode: "SB",
  },
  {
    Country: "Somalia",
    countryCode: "SO",
  },
  {
    Country: "South Africa (Former Union of South Africa)",
    countryCode: "ZA",
  },
  {
    Country: "South Georgia and the South Sandwich Islands",
    countryCode: "GS",
  },
  {
    Country: "Spain ",
    countryCode: "ES",
  },
  {
    Country: "Sri Lanka (Former Serendib, Ceylon) ",
    countryCode: "LK",
  },
  {
    Country: "Sudan (Former Anglo-Egyptian Sudan) ",
    countryCode: "SD",
  },
  {
    Country: "Suriname (Former Netherlands Guiana, Dutch Guiana)",
    countryCode: "SR",
  },
  {
    Country: "Svalbard (Spitzbergen) and Jan Mayen Islands ",
    countryCode: "SJ",
  },
  {
    Country: "Swaziland, Kingdom of ",
    countryCode: "SZ",
  },
  {
    Country: "Sweden ",
    countryCode: "SE",
  },
  {
    Country: "Switzerland ",
    countryCode: "CH",
  },
  {
    Country: "Syria",
    countryCode: "SY",
  },

  { Country: "Taiwan (Former Formosa)", countryCode: "TW" },
  {
    Country: "Tajikistan (Former Tajik Soviet Socialist Republic)",
    countryCode: "TJ",
  },
  {
    Country: "Tanzania,United Republic",
    countryCode: "TZ",
  },
  {
    Country: "Thailand (Former Siam)",
    countryCode: "TH",
  },
  {
    Country: "Togo (Former French Togoland)",
    countryCode: "TG",
  },
  {
    Country: "Tokelau ",
    countryCode: "TK",
  },
  {
    Country: "Tonga, Kingdom of (Former Friendly Islands)",
    countryCode: "TO",
  },
  {
    Country: "Trinidad and Tobago ",
    countryCode: "TT",
  },
  {
    Country: "Tromelin Island ",
    countryCode: "TE",
  },
  {
    Country: "Tunisia ",
    countryCode: "TN",
  },
  {
    Country: "Turkey ",
    countryCode: "TR",
  },
  {
    Country: "Turkmenistan (Former Turkmen Soviet Socialist Republic)",
    countryCode: "TM",
  },
  {
    Country: "Turks and Caicos Islands ",
    countryCode: "TC",
  },
  {
    Country: "Tuvalu (Former Ellice Islands)",
    countryCode: "TV",
  },

  { Country: "Uganda, Republic of", countryCode: "UG" },
  {
    Country: "Ukraine",
    countryCode: "UA",
  },
  {
    Country: "United ArabEmirates (UAE)",
    countryCode: "AE",
  },
  {
    Country: "United Kingdom (Great Britain / UK)",
    countryCode: "GB",
  },
  {
    Country: "United States ",
    countryCode: "US",
  },
  {
    Country: "United States Minor Outlying Islands ",
    countryCode: "UM",
  },
  {
    Country: "Uruguay,Oriental Republic ",
    countryCode: "UY",
  },
  {
    Country: "Uzbekistan (Former UZbek Soviet Socialist Republic)",
    countryCode: "UZ",
  },

  { Country: "Vanuatu (Former New Hebrides)", countryCode: "VU" },
  {
    Country: "Vatican City State (Holy See)",
    countryCode: "VA",
  },
  {
    Country: "Venezuela ",
    countryCode: "VE",
  },
  {
    Country: "Vietnam ",
    countryCode: "VN",
  },
  {
    Country: "Virgin Islands, British ",
    countryCode: "VI",
  },
  {
    Country: "Virgin Islands, United States (Former Danish West Indies) ",
    countryCode: "VQ",
  },

  { Country: "Wallis and Futuna Islands ", countryCode: "WF" },
  {
    Country: "Western Sahara (Former Spanish Sahara)",
    countryCode: "EH",
  },

  { Country: "Yemen ", countryCode: "YE" },
  {
    Country: "Yugoslavia ",
    countryCode: "YU",
  },
  {
    Country: "Zaire",
    countryCode: "ZR",
  },
  {
    Country: "Zambia, Republic of (Former Northern Rhodesia) ",
    countryCode: "ZM",
  },
  {
    Country: "Zimbabwe, Republic of (Former Southern Rhodesia, Rhodesia) ",
    countryCode: "ZW",
  },
];

export const currencyList: any = [
  {
    CurrencyName: "Abkhazian apsar",
    ISOcode: "none",
  },
  {
    CurrencyName: "Russian ruble",
    ISOcode: "RUB",
  },
  {
    CurrencyName: "Afghan afghani",
    ISOcode: "AFN",
  },
  {
    CurrencyName: "Euro",
    ISOcode: "EUR",
  },
  {
    CurrencyName: "Albanian lek",
    ISOcode: "ALL",
  },
  {
    CurrencyName: "Alderney pound",
    ISOcode: "none",
  },
  {
    CurrencyName: "British pound",
    ISOcode: "GBP",
  },
  {
    CurrencyName: "Guernsey pound",
    ISOcode: "GGP",
  },
  {
    CurrencyName: "Algerian dinar",
    ISOcode: "DZD",
  },

  {
    CurrencyName: "Angolan kwanza",
    ISOcode: "AOA",
  },
  {
    CurrencyName: "East Caribbean dollar",
    ISOcode: "XCD",
  },

  {
    CurrencyName: "Argentine peso",
    ISOcode: "ARS",
  },
  {
    CurrencyName: "Armenian dram",
    ISOcode: "AMD",
  },
  {
    CurrencyName: "Aruban florin",
    ISOcode: "AWG",
  },
  {
    CurrencyName: "Ascension pound",
    ISOcode: "none",
  },
  {
    CurrencyName: "Saint Helena pound",
    ISOcode: "SHP",
  },
  {
    CurrencyName: "Australian dollar",
    ISOcode: "AUD",
  },

  {
    CurrencyName: "Azerbaijani manat",
    ISOcode: "AZN",
  },
  {
    CurrencyName: "Bahamian dollar",
    ISOcode: "BSD",
  },
  {
    CurrencyName: "Bahraini dinar",
    ISOcode: "BHD",
  },
  {
    CurrencyName: "Bangladeshi taka",
    ISOcode: "BDT",
  },
  {
    CurrencyName: "Barbadian dollar",
    ISOcode: "BBD",
  },
  {
    CurrencyName: "Belarusian ruble",
    ISOcode: "BYR",
  },

  {
    CurrencyName: "Belize dollar",
    ISOcode: "BZD",
  },
  {
    CurrencyName: "West African CFA franc",
    ISOcode: "XOF",
  },
  {
    CurrencyName: "Bermudian dollar",
    ISOcode: "BMD",
  },
  {
    CurrencyName: "Bhutanese ngultrum",
    ISOcode: "BTN",
  },
  {
    CurrencyName: "Indian rupee",
    ISOcode: "INR",
  },
  {
    CurrencyName: "Bolivian boliviano",
    ISOcode: "BOB",
  },
  {
    CurrencyName: "United States dollar",
    ISOcode: "USD",
  },
  {
    CurrencyName: "Bosnia and Herzegovina convertible mark",
    ISOcode: "BAM",
  },
  {
    CurrencyName: "Botswana pula",
    ISOcode: "BWP",
  },
  {
    CurrencyName: "Brazilian real",
    ISOcode: "BRL",
  },

  // {
  //   CurrencyName: "British Virgin Islands dollar",
  // },

  {
    CurrencyName: "Brunei dollar",
    ISOcode: "BND",
  },
  {
    CurrencyName: "Singapore dollar",
    ISOcode: "SGD",
  },
  {
    CurrencyName: "Bulgarian lev",
    ISOcode: "BGN",
  },

  {
    CurrencyName: "Burundian franc",
    ISOcode: "BIF",
  },
  {
    CurrencyName: "Cambodian riel",
    ISOcode: "KHR",
  },
  {
    CurrencyName: "Central African CFA franc",
    ISOcode: "XAF",
  },
  {
    CurrencyName: "Canadian dollar",
    ISOcode: "CAD",
  },
  {
    CurrencyName: "Cape Verdean escudo",
    ISOcode: "CVE",
  },
  {
    CurrencyName: "Cayman Islands dollar",
    ISOcode: "KYD",
  },
  {
    CurrencyName: "Chilean peso",
    ISOcode: "CLP",
  },
  {
    CurrencyName: "Chinese yuan",
    ISOcode: "CNY",
  },
  {
    CurrencyName: "Colombian peso",
    ISOcode: "COP",
  },
  {
    CurrencyName: "Comorian franc",
    ISOcode: "KMF",
  },
  {
    CurrencyName: "Congolese franc",
    ISOcode: "CDF",
  },
  {
    CurrencyName: "New Zealand dollar",
    ISOcode: "NZD",
  },
  // {
  //   CurrencyName: "Cook Islands dollar",
  // },
  {
    CurrencyName: "Costa Rican colón",
    ISOcode: "CRC",
  },

  {
    CurrencyName: "Croatian kuna",
    ISOcode: "HRK",
  },
  {
    CurrencyName: "Cuban convertible peso",
    ISOcode: "CUC",
  },
  {
    CurrencyName: "Cuban peso",
    ISOcode: "CUP",
  },
  {
    CurrencyName: "Netherlands Antillean guilder",
    ISOcode: "ANG",
  },

  {
    CurrencyName: "Czech koruna",
    ISOcode: "CZK",
  },
  {
    CurrencyName: "Danish krone",
    ISOcode: "DKK",
  },
  {
    CurrencyName: "Djiboutian franc",
    ISOcode: "DJF",
  },

  {
    CurrencyName: "Dominican peso",
    ISOcode: "DOP",
  },
  {
    CurrencyName: "Egyptian pound",
    ISOcode: "EGP",
  },
  {
    CurrencyName: "Eritrean nakfa",
    ISOcode: "ERN",
  },

  {
    CurrencyName: "Ethiopian birr",
    ISOcode: "ETB",
  },
  {
    CurrencyName: "Falkland Islands pound",
    ISOcode: "FKP",
  },

  {
    CurrencyName: "Fijian dollar",
    ISOcode: "FJD",
  },

  {
    CurrencyName: "CFP franc",
    ISOcode: "XPF",
  },

  {
    CurrencyName: "Gambian dalasi",
    ISOcode: "GMD",
  },
  {
    CurrencyName: "Georgian lari",
    ISOcode: "GEL",
  },

  {
    CurrencyName: "Ghana cedi",
    ISOcode: "GHS",
  },
  {
    CurrencyName: "Gibraltar pound",
    ISOcode: "GIP",
  },

  {
    CurrencyName: "Guatemalan quetzal",
    ISOcode: "GTQ",
  },

  {
    CurrencyName: "Guinean franc",
    ISOcode: "GNF",
  },

  {
    CurrencyName: "Guyanese dollar",
    ISOcode: "GYD",
  },
  {
    CurrencyName: "Haitian gourde",
    ISOcode: "HTG",
  },
  {
    CurrencyName: "Honduran lempira",
    ISOcode: "HNL",
  },
  {
    CurrencyName: "Hong Kong dollar",
    ISOcode: "HKD",
  },
  {
    CurrencyName: "Hungarian forint",
    ISOcode: "HUF",
  },
  {
    CurrencyName: "Icelandic króna",
    ISOcode: "ISK",
  },
  {
    CurrencyName: "Indonesian rupiah",
    ISOcode: "IDR",
  },
  {
    CurrencyName: "Iranian rial",
    ISOcode: "IRR",
  },
  {
    CurrencyName: "Iraqi dinar",
    ISOcode: "IQD",
  },

  // {
  //   CurrencyName: "Manx pound",
  // },
  {
    CurrencyName: "Israeli new shekel",
    ISOcode: "ILS",
  },

  {
    CurrencyName: "Jamaican dollar",
    ISOcode: "JMD",
  },
  {
    CurrencyName: "Japanese yen",
    ISOcode: "JPY",
  },

  {
    CurrencyName: "Jersey pound",
  },
  {
    CurrencyName: "Jordanian dinar",
    ISOcode: "JOD",
  },
  {
    CurrencyName: "Kazakhstani tenge",
    ISOcode: "KZT",
  },
  {
    CurrencyName: "Kenyan shilling",
    ISOcode: "KES",
  },

  // {
  //   CurrencyName: "Kiribati dollar",
  // },
  {
    CurrencyName: "North Korean won",
    ISOcode: "KPW",
  },
  {
    CurrencyName: "South Korean won",
    ISOcode: "KRW",
  },

  {
    CurrencyName: "Kuwaiti Dinar",
    ISOcode: "KWD",
  },
  {
    CurrencyName: "Kyrgyzstani som",
    ISOcode: "KGS",
  },
  {
    CurrencyName: "Lao kip",
    ISOcode: "LAK",
  },

  {
    CurrencyName: "Lebanese pound",
    ISOcode: "LBP",
  },
  {
    CurrencyName: "Lesotho loti",
    ISOcode: "LSL",
  },
  {
    CurrencyName: "South African rand",
    ISOcode: "ZAR",
  },
  {
    CurrencyName: "Liberian dollar",
    ISOcode: "LRD",
  },
  {
    CurrencyName: "Libyan dinar",
    ISOcode: "LYD",
  },
  {
    CurrencyName: "Swiss franc",
    ISOcode: "CHF",
  },

  {
    CurrencyName: "Macanese pataca",
    ISOcode: "MOP",
  },
  {
    CurrencyName: "Macedonian denar",
    ISOcode: "MKD",
  },
  {
    CurrencyName: "Malagasy ariary",
    ISOcode: "MGA",
  },
  {
    CurrencyName: "Malawian kwacha",
    ISOcode: "MWK",
  },
  {
    CurrencyName: "Malaysian ringgit",
    ISOcode: "MYR",
  },
  {
    CurrencyName: "Maldivian rufiyaa",
    ISOcode: "MVR",
  },
  {
    CurrencyName: "Mauritanian ouguiya",
    ISOcode: "MRO",
  },
  {
    CurrencyName: "Mauritian rupee",
    ISOcode: "MUR",
  },
  {
    CurrencyName: "Mexican peso",
    ISOcode: "MXN",
  },
  {
    CurrencyName: "Moldovan leu",
    ISOcode: "MDL",
  },

  {
    CurrencyName: "Mongolian tögrög",
    ISOcode: "MNT",
  },

  {
    CurrencyName: "Moroccan dirham",
    ISOcode: "MAD",
  },
  {
    CurrencyName: "Mozambican metical",
    ISOcode: "MZN",
  },
  {
    CurrencyName: "Burmese kyat",
    ISOcode: "MMK",
  },

  {
    CurrencyName: "Namibian dollar",
    ISOcode: "NAD",
  },

  {
    CurrencyName: "Nepalese rupee",
    ISOcode: "NPR",
  },

  {
    CurrencyName: "Nicaraguan córdoba",
    ISOcode: "NIO",
  },

  {
    CurrencyName: "Nigerian naira",
    ISOcode: "NGN",
  },

  {
    CurrencyName: "Turkish lira",
    ISOcode: "TRY",
  },
  {
    CurrencyName: "Norwegian krone",
    ISOcode: "NOK",
  },
  {
    CurrencyName: "Omani rial",
    ISOcode: "OMR",
  },
  {
    CurrencyName: "Pakistani rupee",
    ISOcode: "PKR",
  },
  {
    CurrencyName: "Panamanian balboa",
    ISOcode: "PAB",
  },

  {
    CurrencyName: "Papua New Guinean kina",
    ISOcode: "PGK",
  },
  {
    CurrencyName: "Paraguayan guaraní",
    ISOcode: "PYG",
  },
  {
    CurrencyName: "Peruvian nuevo sol",
    ISOcode: "PEN",
  },
  {
    CurrencyName: "Philippine peso",
    ISOcode: "PHP",
  },

  {
    CurrencyName: "Polish złoty",
    ISOcode: "PLN",
  },

  {
    CurrencyName: "Qatari riyal",
    ISOcode: "QAR",
  },
  {
    CurrencyName: "Romanian leu",
    ISOcode: "RON",
  },

  {
    CurrencyName: "Rwandan franc",
    ISOcode: "RWF",
  },

  {
    CurrencyName: "Samoan tālā",
    ISOcode: "WST",
  },

  {
    CurrencyName: "São Tomé and Príncipe dobra",
    ISOcode: "STD",
  },
  {
    CurrencyName: "Saudi riyal",
    ISOcode: "SAR",
  },

  {
    CurrencyName: "Serbian dinar",
    ISOcode: "RSD",
  },
  {
    CurrencyName: "Seychellois rupee",
    ISOcode: "SCR",
  },
  {
    CurrencyName: "Sierra Leonean leone",
    ISOcode: "SLL",
  },

  {
    CurrencyName: "Solomon Islands dollar",
    ISOcode: "SBD",
  },
  {
    CurrencyName: "Somali shilling",
    ISOcode: "SOS",
  },

  {
    CurrencyName: "South Sudanese pound",
    ISOcode: "SSP",
  },
  {
    CurrencyName: "Sri Lankan rupee",
    ISOcode: "LKR",
  },
  {
    CurrencyName: "Sudanese pound",
    ISOcode: "SDG",
  },
  {
    CurrencyName: "Surinamese dollar",
    ISOcode: "SRD",
  },
  {
    CurrencyName: "Swazi lilangeni",
    ISOcode: "SZL",
  },
  {
    CurrencyName: "Swedish krona",
    ISOcode: "SEK",
  },
  {
    CurrencyName: "Syrian pound",
    ISOcode: "SYP",
  },
  {
    CurrencyName: "New Taiwan dollar",
    ISOcode: "TWD",
  },
  {
    CurrencyName: "Tajikistani somoni",
    ISOcode: "TJS",
  },
  {
    CurrencyName: "Tanzanian shilling",
    ISOcode: "TZS",
  },
  {
    CurrencyName: "Thai baht",
    ISOcode: "THB",
  },

  {
    CurrencyName: "Tongan paanga",
    ISOcode: "TOP",
  },

  {
    CurrencyName: "Trinidad and Tobago dollar",
    ISOcode: "TTD",
  },

  {
    CurrencyName: "Tunisian dinar",
    ISOcode: "TND",
  },
  {
    CurrencyName: "Turkmenistan manat",
    ISOcode: "TMT",
  },

  {
    CurrencyName: "Ugandan shilling",
    ISOcode: "UGX",
  },
  {
    CurrencyName: "Ukrainian hryvnia",
    ISOcode: "UAH",
  },
  {
    CurrencyName: "United Arab Emirates dirham",
    ISOcode: "AED",
  },
  {
    CurrencyName: "Uruguayan peso",
    ISOcode: "UYU",
  },
  {
    CurrencyName: "Uzbekistani som",
    ISOcode: "UZS",
  },
  {
    CurrencyName: "Vanuatu vatu",
    ISOcode: "VUV",
  },

  {
    CurrencyName: "Venezuelan bolívar",
    ISOcode: "VEF",
  },
  {
    CurrencyName: "Vietnamese đồng",
    ISOcode: "VND",
  },

  {
    CurrencyName: "Yemeni rial",
    ISOcode: "YER",
  },
  {
    CurrencyName: "Zambian kwacha",
    ISOcode: "ZMW",
  },
];
