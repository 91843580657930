import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import * as moment from "moment";
import { NzModalService } from "ng-zorro-antd/modal";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { CommonApiService } from "src/app/services/common-api.service";
import { NavService } from "src/app/services/nav.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-all-bots",
  templateUrl: "./all-bots.component.html",
  styleUrls: ["./all-bots.component.css"],
})
export class AllBotsComponent implements OnInit {
  moment: any = moment;
  orderColumn: Array<any> = [
    {
      title: "Name",
      width: "150px",
    },
    {
      title: "Channel Type",
      width: "100px",
    },
    {
      title: "Created By",
      width: "120px",
    },
    {
      title: "Created At",
      width: "100px",
    },
    {
      title: "Status",
      width: "100px",
    },
    {
      title: "Action",
      width: "80px",
    },
  ];

  tableHeight: any = "";
  mainUserData: any = {};

  // pagination section
  totalCount: number;
  pageIndex: number = 1;
  pageSize: number = 30;

  // search section
  searchKey: string = "";

  // create edit form section

  botForm: FormGroup;
  botFormDrawer: boolean = false;
  isEdit: boolean = false;

  allBots: Array<any> = [
    // {
    //   name: "E-commerce",
    //   channelType: "Whatsapp",
    //   intents: ["Hello", "Welcome"],
    //   sessionTimeout: {
    //     count: 5,
    //     unit: "minutes",
    //     message: "Your bot session was expired.",
    //   },
    //   createdBy: "Balaji K",
    //   createdAt: "2023-08-01T14:08:35.000Z",
    //   plugins: [],
    //   _id: "9asdf8a7fasdf789adf78afda",
    // },
    // {
    //   name: "College Admission",
    //   channelType: "Whatsapp",
    //   intents: [],
    //   sessionTimeout: {
    //     count: 5,
    //     unit: "minutes",
    //     message: "Your bot session was expired.",
    //   },
    //   createdBy: "Balaji K",
    //   createdAt: "2023-08-01T14:08:35.000Z",
    //   plugins: ["642c04bc0736249bbe825dad"],
    //   _id: "9asdf8a7fasdf789adf78afda",
    // },
    // {
    //   name: "Hospital Management",
    //   channelType: "Whatsapp",
    //   intents: [],
    //   sessionTimeout: {
    //     count: 5,
    //     unit: "minutes",
    //     message: "Your bot session was expired.",
    //   },
    //   createdBy: "Balaji K",
    //   createdAt: "2023-08-01T14:08:35.000Z",
    //   plugins: [],
    //   _id: "9asdf8a7fasdf789adf78afda",
    // },
    // {
    //   name: "Hostel Attendance Management",
    //   channelType: "Whatsapp",
    //   intents: [],
    //   sessionTimeout: {
    //     count: 5,
    //     unit: "minutes",
    //     message: "Your bot session was expired.",
    //   },
    //   createdBy: "Balaji K",
    //   createdAt: "2023-08-01T14:08:35.000Z",
    //   plugins: [],
    //   _id: "9asdf8a7fasdf789adf78afda",
    // },
  ];

  private subscription: Subscription;
  allPlugins: any = [];

  editData: any = {};
  submitting: boolean = false;

  constructor(
    private navService: NavService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private apiService: CommonApiService,
    private modal: NzModalService,
    private toastr: ToastrService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.botForm = this.fb.group({
      botName: ["", Validators.required],
      intents: [[], Validators.required],
      expiryTime: ["", Validators.required],
      expiryMessage: ["", Validators.required],
      plugin: [[]],
      isActive: [true],
    });

    this.getMainUserData();
    this.getAllPlugins();
    this.fetchData();
  }

  fetchData() {
    this.spinner.show();
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url +
          "flows?page=" +
          this.pageIndex +
          "&limit=" +
          this.pageSize,
        { filterValue: this.searchKey },
        options
      )
      .subscribe(
        (response: any) => {
          console.log(response);
          this.allBots = response.data.flows;
          this.totalCount = response?.data?.pagination?.totalCount;
          this.pageSize = response?.data?.pagination?.limit;
          this.spinner.hide();
        },
        (err: any) => {
          if (
            err?.error?.message == "jwt expired" ||
            err?.error?.message == "jwt must be provided" ||
            err?.error?.message == "jwt malformed" ||
            err?.error?.message == "invalid signature" ||
            err?.error?.message == "Given token user does not exist." ||
            err?.error?.message == "invalid token"
          ) {
            this.navService.tokenExpired();
          }
          console.log(err);
          this.spinner.hide();
        }
      );
  }

  getAllPlugins() {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonGetMethod(environment.url + "plugins", options)
      .subscribe(
        (response: any) => {
          // console.log(response);
          this.allPlugins = response?.data?.allPlugins.map(
            (obj) => obj.plugins
          );
          this.allPlugins = this.allPlugins.flat();
          // console.log(this.allPlugins);
        },
        (err: any) => {
          console.log(err);
        }
      );
  }

  getMainUserData() {
    this.subscription = this.navService.mainUserData.subscribe(
      (response: any) => {
        if (response) {
          // console.log(response);
          this.mainUserData = response.data.userData;
          if (response?.data?.userData?.accessTo?.sandboxId) {
            this.router.navigate(["/chat"]);
          }
          if (
            response?.data?.userData?.accessTo?.sandboxId &&
            window.innerWidth > 992
          ) {
            this.tableHeight = "56vh";
          } else {
            this.tableHeight = "65vh";
          }
        }
      }
    );
  }

  onPageNumberChange(id: any, event: any) {
    if (this.pageIndex != event.pageIndex) {
      this.pageIndex = event.pageIndex;
      this.fetchData();
      document.getElementById(id).scrollIntoView();
    }
  }

  changePageSize(size: any) {
    this.pageSize = size;
    this.pageIndex = 1;
    this.fetchData();
  }

  searchContacts() {
    this.pageIndex = 1;
    this.fetchData();
  }

  setMyStyles() {
    let styles = {
      "margin-right":
        135 + (this.totalCount / this.pageSize).toFixed(0).length * 5 + "px",
    };
    return styles;
  }

  // create and edit section

  showBotForm() {
    this.isEdit = false;
    this.botForm.reset();
    this.botForm.get("isActive").setValue(true);
    this.botFormDrawer = true;
  }

  showEditBotForm(editData: any) {
    this.editData = editData;
    this.isEdit = true;
    this.botForm.reset();
    this.botForm.patchValue({
      botName: editData.name,
      intents: editData.intents,
      expiryTime: editData.sessionTimeout.count,
      expiryMessage: editData.sessionTimeout.message,
      plugin: editData?.plugins,
      isActive: editData?.isActive,
    });
    this.botFormDrawer = true;
  }

  closeBotFormDrawer() {
    this.botFormDrawer = false;
  }

  createUpdateBot() {
    if (this.botForm.valid) {
      this.submitting = true;
      let options = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
        },
      };

      var url: string = "";

      var reqObj: any = {
        flowName: this.botForm.value.botName,
        intents: this.botForm.value.intents,
        sessionTimeout: {
          count: this.botForm.value.expiryTime,
          message: this.botForm.value.expiryMessage,
        },
        pluginIds: this.botForm.value.plugins,
        isActive: this.botForm.value.isActive,
      };

      if (this.isEdit) {
        url = "flows/update";
        reqObj.flowId = this.editData?._id;
      } else {
        url = "flows/create";
      }

      this.apiService
        .commonPostMethod(environment.url + url, reqObj, options)
        .subscribe(
          (response: any) => {
            console.log(response);
            this.toastr.success(response.message, "", {
              timeOut: 2500,
            });
            this.fetchData();
            this.botFormDrawer = false;
            this.submitting = false;
          },
          (err: any) => {
            console.log(err);
            this.submitting = false;
            if (err?.error?.message) {
              this.toastr.error(err?.error?.message, "", {
                timeOut: 2500,
              });
            } else {
              this.toastr.error(err?.message, "", {
                timeOut: 2500,
              });
            }
          }
        );
    } else {
      Object.values(this.botForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  deleteAlert(selected: any) {
    this.modal.confirm({
      nzOkText: "Yes",
      nzCancelText: "No",
      nzTitle: "Bot will be deleted Permanently.",
      nzContent: "Are you sure you want to delete?",
      nzOnOk: () => this.deleteBot(selected._id),
      nzOnCancel: () => {},
    });
  }

  deleteBot(botId: any) {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url + "flows/delete",
        { flowId: botId },
        options
      )
      .subscribe(
        (response: any) => {
          console.log(response);
          this.toastr.success(response.message, "", {
            timeOut: 2500,
          });
          this.fetchData();
        },
        (err: any) => {
          console.log(err);
          if (err?.error?.message) {
            this.toastr.error(err?.error?.message, "", {
              timeOut: 2500,
            });
          } else {
            this.toastr.error(err?.message, "", {
              timeOut: 2500,
            });
          }
        }
      );
  }

  ngOnDestroy(): void {
    // console.log("api key destroy..");
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
