<div class="row overflow_row_mobile">
    <div class="col-12 main_outer_heading d_mobile_none">
        <h3>Work Flows</h3>
    </div>

    <div class="col-12">
        <nz-card class="contacts_top_card">
            <div class="search_add_div">
                <div class="toggle_options">
                    <nz-input-group class="search_input_section small_input_sec" [nzPrefix]="suffixIconSearch">
                        <input autocomplete="off" [(ngModel)]="searchKey" (keyup)="searchContacts()" type="text"
                            nz-input placeholder="Search here.." />
                    </nz-input-group>
                    <ng-template #suffixIconSearch>
                        <span nz-icon nzType="search"></span>
                    </ng-template>
                </div>
                <a [disabled]="mainUserData?.accessTo?.isExpired" (click)="showFlowFormDrawer()" nz-button
                    nzType="primary" class="create-button">
                    <i nz-icon nzType="plus-circle" theme="outline"></i>
                    <span>Create New Flow</span>
                </a>
            </div>
        </nz-card>
    </div>

    <div class="col-12" id="main-section">
        <nz-card class="customer_right_card customers_table_card">
            <div class="total_records">
                <h6>Total Records ({{ totalCount }})</h6>

                <button class="customized_btn" [ngStyle]="setMyStyles()" nzTrigger="click" nz-dropdown
                    [nzDropdownMenu]="paginationMenu" [nzPlacement]="'bottomLeft'">
                    {{ pageSize + " records per page" }}
                </button>

                <nz-dropdown-menu #paginationMenu="nzDropdownMenu">
                    <ul nz-menu>
                        <li (click)="changePageSize((n + 1) * 10)" *ngFor="let page of [].constructor(5); let n = index"
                            nz-menu-item>
                            {{ (n + 1) * 10 + " records per page" }}
                        </li>
                    </ul>
                </nz-dropdown-menu>
            </div>

            <nz-table class="contacts_table main_contacts_table" #allBotsTable [nzData]="allFlows"
                [nzScroll]="{ y: tableHeight, x: '900px' }" [nzFrontPagination]="false" [nzTotal]="totalCount"
                [nzPageSize]="pageSize" [nzPageIndex]="pageIndex"
                (nzQueryParams)="onPageNumberChange('botsList', $event)" [nzPaginationPosition]="'top'"
                [nzSimple]="true">
                <thead>
                    <tr>
                        <th *ngFor="let column of orderColumn" [nzWidth]="column.width">
                            {{ column.title }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr id="botsList" *ngFor="let item of allBotsTable.data; let i = index">
                        <td>
                            <a [routerLink]="['/work-flow/builder' , item._id]" class="edit_link">
                                {{ item?.name }}
                            </a>
                        </td>
                        <td>
                            <!-- {{item.channelType}} -->
                            Whatsapp
                        </td>
                        <td>
                            {{item.createdBy?.firstName + ' ' +
                            (item.createdBy?.lastName ? item.createdBy?.lastName : '')}}
                        </td>
                        <td>
                            {{ moment(item?.createdAt)?.format("DD-MM-YYYY hh:mm A") }}
                        </td>
                        <td>
                            <!-- <span [ngClass]="{ 'inactive': !item?.isActive}" class="active">
                                {{item?.isActive ? 'Published' : 'Unpublished'}}
                            </span> -->
                            Published
                        </td>
                        <!-- <td>
                            <div class="edit_delete_links">
                                <span class="edit_icon" (click)="showEditBotForm(item)" nz-icon nzType="edit"
                                    nzTheme="outline"></span>
                                <span class="edit_icon cursor_pointer" (click)="deleteAlert(item)" nz-icon
                                    nzType="delete" nzTheme="outline"></span>
                            </div>
                        </td> -->
                    </tr>
                </tbody>
                <ngx-spinner bdColor="rgb(255,255,255)" size="medium" color="#000000" type="ball-clip-rotate-pulse"
                    [fullScreen]="false"></ngx-spinner>
            </nz-table>
        </nz-card>
    </div>
</div>



<!-- create new work flow -->

<nz-drawer [nzWidth]="'40%'" [nzClosable]="true" [nzVisible]="flowFormDrawer" [nzPlacement]="'right'"
    [nzTitle]="'Create new flow'" (nzOnClose)="closeFlowFormDrawer()">
    <ng-container *nzDrawerContent>
        <div class="">
            <form [formGroup]="flowForm" (ngSubmit)="createUpdateFlow()" nz-form>
                <nz-form-item>
                    <nz-form-label class="large_label" nzRequired [nzSm]="24" [nzXs]="24">
                        Flow name
                    </nz-form-label>
                    <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please enter flow name!">
                        <nz-input-group>
                            <input autocomplete="off" formControlName="flowName" type="text" nz-input
                                placeholder="Enter name for flow" required>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label class="large_label" [nzSm]="24" [nzXs]="24">
                        Description
                    </nz-form-label>
                    <nz-form-control [nzSm]="24" [nzXs]="24">
                        <nz-input-group>
                            <textarea formControlName="description" nz-input
                                placeholder="Enter description for work flow" [nzAutosize]="{ minRows: 3, maxRows: 5 }">
                                </textarea>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item class="submit_section mt-3">
                    <button [nzLoading]="submitting" type="submit" class="sighup_button w-100" nz-button
                        nzType="primary">
                        Create
                    </button>
                </nz-form-item>
            </form>
        </div>
    </ng-container>
</nz-drawer>