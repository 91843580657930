import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class SignupProtectionService {
  constructor(private route: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (
      localStorage.getItem(
        "gtrdtghjdgfhjsgkahjhfjkafhfkjhfkfifghefywegfyuwgfuyew"
      ) === null
    ) {
      localStorage.removeItem("qwertrewetrt");
      this.route.navigate(["/login"]);
      return false;
    } else {
      return true;
    }
  }
}
