<div class="form-body on-top-mobile">
  <div class="row">
    <div class="col-lg-6 login_slider">
      <nz-carousel [nzEffect]="'scrollx'" nzAutoPlay>
        <div nz-carousel-content *ngFor="let image of loginBanners" class="slider_main_section">
          <div class="slider_inner_section">
            <div [ngStyle]="{ 'background-image': 'url(' + image + ')' }" class="carousal_container"></div>
            <!-- <img [src]="image" alt=""> -->
          </div>
        </div>
      </nz-carousel>
    </div>
    <div class="form-holder col-lg-6">
      <div class="form-content">
        <div class="form-items">
          <div class="heading_section">
            <img class="mb-3 own_chat_logo" src="../../../assets/images/logo/ownChat_logo.png" alt="" />
            <h4 class="mb-3">Signup</h4>
          </div>
          <form [formGroup]="signupForm" (ngSubmit)="signUp()" nz-form>
            <div class="update_form_div">
              <nz-form-item>
                <nz-form-label class="large_label" nzFor="" [nzSm]="24" [nzXs]="24">
                  Name
                </nz-form-label>
                <nz-form-control [nzSm]="20" [nzXs]="24" nzErrorTip="Please Enter Name!">
                  <nz-input-group>
                    <input autocomplete="off" formControlName="firstName" type="text" nz-input id="first_name"
                      placeholder="First Name" required />
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>

              <nz-form-item>
                <nz-form-label class="large_label" nzFor="" [nzSm]="24" [nzXs]="24">
                  Mobile Number
                </nz-form-label>
                <nz-input-group class="d-flex" nzCompact>
                  <nz-form-control class="select_control" [nzSm]="20" [nzXs]="24"
                    nzErrorTip="Please Enter Mobile Number!">
                    <nz-select class="group_select" nzShowSearch formControlName="countryCode" style="width: 100%">
                      <nz-option *ngFor="let code of countryCodes" [nzLabel]="code?.countryCode + '-' + code?.Country"
                        [nzValue]="code?.countryCode"></nz-option>
                    </nz-select>
                  </nz-form-control>
                  <nz-form-control class="number_control" [nzSm]="20" [nzXs]="24"
                    nzErrorTip="Please Enter Mobile Number!">
                    <input class="mobile_number_input" autocomplete="off" formControlName="mobileNumber" type="number"
                      nz-input id="mobile-number" placeholder="Mobile Number" required style="width: 65%" />
                  </nz-form-control>
                </nz-input-group>
              </nz-form-item>

              <nz-form-item>
                <nz-form-label class="large_label" nzFor="" [nzSm]="24" [nzXs]="24">
                  EMail Address
                </nz-form-label>
                <nz-form-control [nzSm]="20" [nzXs]="24" nzErrorTip="Please Enter Email ID!">
                  <nz-input-group>
                    <input (keyup)="formatMailId()" autocomplete="off" formControlName="email" type="text" nz-input
                      id="mail_id" placeholder="Email ID" required />
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>

              <nz-form-item>
                <nz-form-label class="large_label" nzFor="" [nzSm]="24" [nzXs]="24">
                  Company Name
                </nz-form-label>
                <nz-form-control [nzSm]="20" [nzXs]="24" nzErrorTip="Please Enter Company Name!">
                  <nz-input-group>
                    <input autocomplete="off" formControlName="companyName" type="text" nz-input id="company_name"
                      placeholder="Company Name" required />
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>

              <nz-form-item>
                <nz-form-label class="large_label" nzFor="" [nzSm]="24" [nzXs]="24">
                  Company Website
                </nz-form-label>
                <nz-form-control [nzSm]="20" [nzXs]="24" nzErrorTip="Please Enter Company Website!">
                  <nz-input-group>
                    <input autocomplete="off" formControlName="companyWebsite" type="text" nz-input id="company_name"
                      placeholder="Company Website" required />
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>

              <nz-form-item>
                <nz-form-control [nzSm]="20" [nzXs]="24" nzErrorTip="Please Enter Company Website!">
                  <label class="terms_label" nz-checkbox [(ngModel)]="termsAgreed"
                    [ngModelOptions]="{ standalone: true }">
                    I agree to the
                    <span (click)="
                        navigateTo(
                          $event,
                          'https://ownchat.app/terms-conditions'
                        )
                      ">
                      Terms of Use
                    </span>
                    and
                    <span (click)="
                        navigateTo($event, 'https://ownchat.app/privacy-policy')
                      ">
                      Privacy Policy
                    </span>
                  </label>
                </nz-form-control>
              </nz-form-item>

              <nz-form-item class="submit_section mt-1">
                <nz-form-control [nzSm]="20" [nzXs]="24">
                  <button [nzLoading]="submitting" type="submit" class="sighup_button w-100" nz-button nzType="primary">
                    Sign Up
                  </button>
                </nz-form-control>
              </nz-form-item>
              <nz-form-item class="">
                <nz-form-control [nzSm]="20" [nzXs]="24">
                  <p class="login_link">
                    Already a user <span routerLink="/login">Login</span>
                  </p>
                </nz-form-control>
              </nz-form-item>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- group_select -->