<!-- <nz-card> -->

<!-- <div class="row m-b-30">
        <div class="col-lg-8">
            <h3>Your Orders</h3>
        </div>
    </div> -->

<!-- filter starts -->

<div class="mb-3">
    <div class="row">
        <div class="col-lg-2 col-sm-2 col-6 mb-3">
            <nz-select [(ngModel)]="selectedFilter" (ngModelChange)="filterChange('date')" class="w-100">
                <nz-option *ngFor="let filter of filterArr.dateFilter" [nzValue]="filter.id" [nzLabel]="filter.label">
                </nz-option>
            </nz-select>
        </div>
        <div *ngIf="selectedFilter == 'custom-date'" class="col-lg-3 col-sm-3 col-6">
            <nz-range-picker [(ngModel)]="customDate" (ngModelChange)="onDateChange($event)"></nz-range-picker>
        </div>
        <div class="col-lg-2 col-sm-2 col-6 mb-3">
            <nz-select [(ngModel)]="orderFilter" (ngModelChange)="filterChange('order')" class="w-100"
                nzPlaceHolder="Order status">
                <nz-option nzValue="all" nzLabel="All Orders"></nz-option>
                <nz-option *ngFor="let filter of filterArr.orderStatus" [nzValue]="filter.id" [nzLabel]="filter.label">
                </nz-option>
            </nz-select>
        </div>
        <div class="col-lg-2 col-sm-2 col-6">
            <nz-select [(ngModel)]="paymentFilter" (ngModelChange)="filterChange('payment')" class="w-100"
                nzPlaceHolder="Payment status">
                <nz-option nzValue="all" nzLabel="All Payments"></nz-option>
                <nz-option *ngFor="let filter of filterArr.paymentStatus" [nzValue]="filter.id"
                    [nzLabel]="filter.label">
                </nz-option>
            </nz-select>
        </div>
        <!-- <div class="col-lg-3 col-sm-3 col-6">
            <nz-select [(ngModel)]="selectedCustomer" (ngModelChange)="filterChange('customer')" nzShowSearch
                class="w-100" nzPlaceHolder="Customer Name">
                <nz-option nzValue="all" nzLabel="All Customers">
                </nz-option>
                <nz-option *ngFor="let customer of customerList" [nzValue]="customer._id" [nzLabel]="customer.name">
                </nz-option>
            </nz-select>
        </div> -->
    </div>

</div>

<!-- filter ends -->

<div class="main_row mt-1">
    <div class="main_col main_col_one">
        <nz-card>
            <div class="main_count_div">
                <div class="left_count">
                    <h5>Carts Received</h5>
                    <h3>{{orderData?.analytics?.totalCartCount ? orderData?.analytics?.totalCartCount : '0'}}</h3>
                </div>
                <div class="right_count">
                    <h5>Total cart value</h5>
                    <h3>{{orderData?.analytics?.totalCartedAmount ? '₹ ' + orderData?.analytics?.totalCartedAmount :
                        '0'}}</h3>
                </div>
            </div>
        </nz-card>
    </div>
    <div class="main_col main_col_two">
        <nz-card>
            <div class="main_count_div">
                <div class="left_count">
                    <h5>Orders Received</h5>
                    <h3>{{orderData?.analytics?.totalOrderCount ? orderData?.analytics?.totalOrderCount : '0'}}</h3>
                </div>
                <div class="right_count">
                    <h5>Total order value</h5>
                    <h3>{{orderData?.analytics?.totalOrderedTotal ? '₹ '+orderData?.analytics?.totalOrderedTotal :
                        '0'}}</h3>
                </div>
            </div>
        </nz-card>
    </div>
    <div class="main_col_last main_col">
        <nz-card>
            <div class="main_count_div">
                <div class="right_count">
                    <h5>Conversion</h5>
                    <h3>{{orderData?.analytics?.orderConversionRate ? orderData?.analytics?.orderConversionRate +
                        ' %' : '0 %'}}</h3>
                </div>
            </div>
        </nz-card>
    </div>
</div>

<!-- table starts -->

<nz-table class="contacts_table normal_pagination" #orderListTable [nzData]="orderData?.orders"
    [nzScroll]="{ y: tableHeight, x : '1650px' }" (nzQueryParams)="onPageNumberChange('ordersTable' , $event)"
    [nzFrontPagination]="false" [nzTotal]="totalCount" [nzPageSize]="pageSize" [nzPageIndex]="pageNumber"
    [nzPaginationPosition]="'top'" [nzSimple]="true">
    <thead>
        <tr>
            <th *ngFor="let column of orderColumn" [nzWidth]="column.width">{{ column.title }}</th>
        </tr>
    </thead>
    <tbody>
        <tr id="ordersTable" *ngFor="let item of orderListTable.data ;  let i=index">
            <!-- <td>{{i+1}}</td> -->
            <td>
                <div class="name_section">
                    <div class="">
                        {{item?.customerId?.name}}
                    </div>
                    <span [nzTooltipTitle]="'Open chat'" nz-tooltip class="" (click)="openChatDrawer(item)" nz-icon
                        nzType="wechat" nzTheme="outline"></span>
                </div>

            </td>
            <td>{{item?.customerId?.phoneNo}}</td>
            <td>{{item?.orderId ? item?.orderId : '-'}}</td>
            <td>{{moment(item?.orderedDate).format('DD-MM-YYYY hh:mm A')}}</td>
            <td>
                <div class="order_details_div">
                    <span>{{item?.items?.length}} items</span>
                    <p>{{'Total Value : ' + item?.items[0]?.currency + ' ' + item?.orderTotal}}</p>
                    <a (click)="productDetailModal(item)" class="product_detail">view details</a>
                </div>
            </td>
            <td>
                <nz-select [(ngModel)]="item.shipping.tracking.orderStatus" (ngModelChange)="statusUpdate(item , '')"
                    class="w-100">
                    <nz-option *ngFor="let order of filterArr.orderStatus" [nzValue]="order.id"
                        [nzLabel]="order.label"></nz-option>
                </nz-select>
            </td>
            <td>
                <nz-select [(ngModel)]="item.shipping.tracking.paymentStatus"
                    (ngModelChange)="statusUpdate(item , '?sendMessage=false')" class="w-100">
                    <nz-option *ngFor="let payment of filterArr.paymentStatus" [nzValue]="payment.id"
                        [nzLabel]="payment.label"></nz-option>
                </nz-select>
            </td>
            <td>
                {{item.paymentMode}}
            </td>
            <td>
                {{item?.shipping?.deliveryType ? '-' : item?.shipping?.address?.name + ', ' +
                item?.shipping?.address?.address}}
            </td>
            <td>
                {{item?.shipping?.deliveryType ? item?.shipping?.deliveryType : 'Door delivery'}}
            </td>
        </tr>
    </tbody>
    <ngx-spinner bdColor="rgb(255,255,255)" size="medium" color="#000000" type="ball-clip-rotate-pulse"
        [fullScreen]="false"></ngx-spinner>
</nz-table>

<!-- table ends -->
<!-- </nz-card> -->


<!-- products details modal starts -->

<nz-modal [(nzVisible)]="productsModal" [nzFooter]="null" nzTitle="All Items" nzCentered
    (nzOnCancel)="closeProductDetailModal()">
    <ng-container *nzModalContent>

        <div class="">
            <h5>{{viewItems?.items?.length}} items</h5>
            <p>{{viewItems?.items[0]?.currency + ' '+
                viewItems?.orderTotal}} (estimated)</p>
        </div>

        <ul class="product_list">
            <li class="product_list_item" *ngFor="let product of viewItems?.items">
                <div class="product_main_div mb-3">
                    <div class="">
                        <img class="img-fluid product_list_image" [src]="product?.catalogueId?.image_url" alt="">
                    </div>
                    <div class="">
                        <h6>{{product?.catalogueId?.name}}</h6>
                        <span>{{product?.currency + ' ' + product?.item_price + ' Quantity '+ product?.quantity}}</span>
                    </div>
                </div>
            </li>
        </ul>
    </ng-container>

</nz-modal>

<!-- products details modal ends -->


<nz-modal [(nzVisible)]="orderAlertModal" [nzFooter]="null" [nzStyle]="{ top: '20px' }" nzTitle="Alert"
    (nzOnCancel)="closeOrderAlertModal()">
    <ng-container *nzModalContent>
        <div class="alert_text">
            <p>Your conversation with this ordered customer has expired do you want to sent message click send message,
                it consume one conversation charge or just update status click Update Status.</p>
        </div>
        <div class="alert_button">
            <a (click)="statusUpdate(tempOrderData, '?sendMessage=false')" nz-button nzType="default"
                class="create-button">
                <span>update status </span>
            </a>
            <a (click)="statusUpdate(tempOrderData, '?sendMessage=true')" nz-button nzType="primary"
                class="create-button">
                <span>sent message</span>
            </a>
        </div>
    </ng-container>

</nz-modal>

<!-- CHAT SCREEN DRAWER INSIDE ORDERS PAGE -->

<nz-drawer [nzWidth]="'80%'" [nzClosable]="false"
    [nzBodyStyle]="{'padding' : '8px 24px 0px 15px' , 'background' : 'linear-gradient(93deg, #e5f7ff 0%, #f7f9ff 53%, #deeaf6 100%) 0% 0% no-repeat padding-box'}"
    [nzVisible]="chatDrawer" [nzPlacement]="'right'" (nzOnClose)="closeChatDrawer()">
    <ng-container *nzDrawerContent>
        <div class="">
            <h3 class="chat_drawer_back">
                <span class="cursor_pointer" (click)="closeChatDrawer()" nz-icon nzType="left" nzTheme="outline"></span>
                Chat
            </h3>
            <app-chat [chatData]="chatData"></app-chat>
        </div>
    </ng-container>
</nz-drawer>