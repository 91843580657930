import { Location } from "@angular/common";
import { AfterViewInit, Component, HostListener, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ShortcutEventOutput, ShortcutInput } from "ng-keyboard-shortcuts";

// notification

import { environment } from "../environments/environment";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit, AfterViewInit {
  constructor(
    private router: Router,
    private toastr: ToastrService,
    private location: Location
  ) {}

  shortcuts: ShortcutInput[] = [];

  message: any;

  allowedUrl: any = [
    "/login",
    "/forgot-password",
    "/signup",
    "/user-journey",
    "/signup-success",
    "/chat",
    "/otp-verification",
    "/create-password",
    "/onboarding",
    "/subscription",
    "/contacts",
    "/campaign",
  ];

  ngOnInit(): void {
    // console.log(navigator.onLine);
    // this.requestPermission();
    // this.listen();
  }

  @HostListener("window:online", ["$event"])
  onOnline(event) {
    // console.log("Online");
    if (localStorage.getItem("qwertrewetrt")) {
      // this.router.navigate(["/chat"]);
      this.location.back();
    }
  }

  @HostListener("window:offline", ["$event"])
  onOffline(event) {
    // console.log("Offline");
    if (localStorage.getItem("qwertrewetrt")) {
      this.router.navigate(["/no-internet"]);
    }
  }

  // @HostListener("window:resize", ["$event"])
  // onWindowResize() {
  //   console.log("resize");
  //   if (
  //     window.innerWidth <= 992 &&
  //     this.allowedUrl.indexOf(this.router.url) == -1 &&
  //     !this.router.url.includes("/create-password/") &&
  //     !this.router.url.includes("/forgot-password/") &&
  //     !this.router.url.includes("/chat/") &&
  //     !this.router.url.includes("/contacts/")
  //   ) {
  //     this.router.navigate(["/unsupportable-screen-size"]);
  //   }
  // }

  ngAfterViewInit() {
    this.shortcuts.push(
      {
        key: "f2",
        command: (output: ShortcutEventOutput) => {
          this.router.navigate(["/customer", { openForm: true }]);
        },
        preventDefault: true,
      },
      {
        key: "f3",
        command: (output: ShortcutEventOutput) => {
          this.router.navigate(["/Sales", { openForm: true }]);
        },
        preventDefault: true,
      },
      {
        key: "f6",
        command: (output: ShortcutEventOutput) => {
          this.router.navigate(["/vendor", { openForm: true }]);
        },
        preventDefault: true,
      },
      {
        key: "f7",
        command: (output: ShortcutEventOutput) => {
          this.router.navigate(["/Purchase", { openForm: true }]);
        },
        preventDefault: true,
      }
    );
  }

  // Push Notification

  requestPermission() {
    const messaging = getMessaging();
    getToken(messaging, { vapidKey: environment.firebase.vapidKey })
      .then((currentToken) => {
        if (currentToken) {
          // console.log("Hurraaa!!! we got the token.....");
          // console.log(currentToken);
        } else {
          console.log(
            "No registration token available. Request permission to generate one."
          );
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
      });
  }

  listen() {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      console.log("Message received. ", payload);
      this.playSound();
      this.message = payload;
      this.toastr.info(
        payload?.notification?.body,
        payload?.notification?.title,
        {
          timeOut: 2500,
        }
      );
    });
  }

  playSound() {
    const audio = new Audio("../assets/audio/push_notification_alert.mp3");
    audio.play();
  }
}
