<div class="row align-items-center">
    <!-- <div class="col-12 top_heading mb-1">
        <h3>Messages</h3>
    </div> -->

    <div class="col-12">
        <nz-card class="customers_table_card">
            <div class="instruction_title">
                <h5>Setup Messages</h5>
                <!-- <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque vitae sit at quis aut, iusto odio nam
                    natus iste accusamus atque saepe optio, culpa aperiam. In porro fugit suscipit saepe?</p> -->
            </div>
        </nz-card>
    </div>

    <div class="col-4" *ngFor="let template of allTemplate">
        <nz-card class="template_list_card">
            <!-- <div class="preview_main"> -->
            <div class="template_card">
                <div class="">
                    <div class="mb-3 template_name">
                        <img src="../../../../../assets/images/commerce/order.png" alt="">
                        <h5>{{template.name}}</h5>
                        <span>TEXT</span>
                    </div>
                    <ng-container *ngFor="let component of template.components">
                        <p *ngIf="component.type == 'BODY'">
                            {{component.text}}
                        </p>
                    </ng-container>
                </div>
                <div class="template_submit mt-3">
                    <button (click)="showTemplatePreview(template)" nz-button nzType="primary" class="create-button">
                        <span>{{template.status == 'rejected' || !template.status ? 'Review & Submit' :
                            template.status}}</span>
                    </button>
                </div>
            </div>
            <!-- </div> -->
        </nz-card>
    </div>
</div>


<nz-modal [(nzVisible)]="showPreview" [nzWidth]="'35%'" [nzFooter]="null" nzTitle="Template Preview" nzCentered
    (nzOnCancel)="closeTemplatePreview()">
    <ng-container *nzModalContent>

        <div class="template_col">
            <div class="preview_heading">
                <h5 [nzTooltipTitle]="requestedTemplate.name" nzTooltipPlacement="top" nz-tooltip>
                    {{requestedTemplate.name.length > 23 ?
                    requestedTemplate.name.slice(0,23) + '...' : requestedTemplate.name}}
                </h5>
            </div>
            <div class="preview_template">
                <div class="inner_template">
                    <div *ngFor="let message of requestedTemplate.components" class="mb-3">
                        <div [ngSwitch]="message?.type" class="">
                            <div *ngSwitchCase="'HEADER'" class="">
                                <div [ngSwitch]="message?.format" class="header_text">
                                    <p *ngSwitchCase="'TEXT'" [innerHTML]="message.text"></p>
                                    <p class="img_url" *ngSwitchCase="'IMAGE'">
                                        <span nz-icon nzType="file-image" nzTheme="outline"></span>
                                        IMAGE
                                    </p>
                                    <p class="img_url" *ngSwitchCase="'VIDEO'">
                                        <span nz-icon nzType="video-camera" nzTheme="outline"></span>
                                        VIDEO
                                    </p>
                                    <p class="img_url" *ngSwitchCase="'DOCUMENT'">
                                        <span nz-icon nzType="file-done" nzTheme="outline"></span>
                                        DOCUMENT
                                    </p>
                                </div>

                            </div>
                            <div *ngSwitchCase="'BODY'" class="">
                                <p [innerHTML]="((message?.text))"></p>
                            </div>
                            <div *ngSwitchCase="'FOOTER'" class="">
                                <p [innerHTML]="message?.text"></p>
                            </div>
                            <div *ngSwitchCase="'BUTTONS'" class="">
                                <div *ngFor="let button of message?.buttons" class="template_buttons">
                                    <h6>{{button?.text}}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="template_bottom">
                <div class="bottom_left">
                    <span class="template_lang">{{requestedTemplate?.language}}</span>
                    <span class="template_lang">{{requestedTemplate?.category}}</span>
                </div>
                <!-- <span class="template_status">{{requestedTemplate?.status}}</span> -->
            </div>
        </div>

        <div *ngIf="requestedTemplate.status == 'rejected' || !requestedTemplate.status" class="template_submit">
            <button [nzLoading]="submitting" (click)="requestApproval()" nz-button nzType="primary"
                class="create-button">
                <span>Submit</span>
            </button>
        </div>

    </ng-container>
</nz-modal>