<div class="row overflow_row_mobile">
    <div class="col-12 main_outer_heading d_mobile_none">
        <h3>Developer</h3>
    </div>

    <div class="col-2 left_menu mt-2">
        <nz-card class="mobile_left_menu">
            <ng-container>
                <ul class="main_list">
                    <li class="list_options mb-3" [ngClass]="{'selected_tab_setting' : selectedMenu == 'apiKey'}">
                        <span nz-icon nzType="key" nzTheme="outline"></span>
                        API Keys
                    </li>
                    <li class="list_options mb-3">
                        <a class="custom_icon_tag" href="https://documenter.getpostman.com/view/21382012/2s93sf1Afw"
                            target="_blank">
                            <!-- <span nz-icon nzType="audit" nzTheme="outline"></span> -->
                            <img class="link_icon" src="../../../assets/images/icons/open-link.svg" alt="">
                            API Docs
                        </a>
                    </li>
                </ul>
            </ng-container>
        </nz-card>
    </div>
    <div class="col-10 developer_right">
        <ng-container *ngIf="selectedMenu == 'apiKey'">
            <app-api-keys></app-api-keys>
        </ng-container>
    </div>
</div>