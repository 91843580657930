import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DagreNodesOnlyLayout, Layout } from "@swimlane/ngx-graph";
import { NavService } from "src/app/services/nav.service";
// import { stepRound } from "./customStepCurved";
import { environment } from "src/environments/environment";
import { CommonApiService } from "src/app/services/common-api.service";
import { NzModalService } from "ng-zorro-antd/modal";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import * as moment from "moment";
import { NzMessageService } from "ng-zorro-antd/message";
import { DOCUMENT } from "@angular/common";
// import * as shape from "d3-shape";

@Component({
  selector: "app-chat-bot",
  templateUrl: "./chat-bot.component.html",
  styleUrls: ["./chat-bot.component.css"],
})
export class ChatBotComponent implements OnInit {
  codeMirrorOptions: any = {
    theme: "dracula",
    mode: "application/ld+json",
    lineNumbers: true,
    lineWrapping: true,
    foldGutter: true,
    gutters: [
      "CodeMirror-linenumbers",
      "CodeMirror-foldgutter",
      "CodeMirror-lint-markers",
    ],
    autoCloseBrackets: true,
    matchBrackets: true,
    lint: true,
  };

  templateModal: boolean = false;
  templateComponents: any = {};

  liveUrl: any = environment.imageUrl;

  obj;
  codeEditor: boolean = false;
  codeType: string = "";

  dragging: boolean = false;

  variableSearch: string = "";

  // public curve: any = shape.curveBundle.beta(1);
  // public curve: any = shape.curveCardinal;
  // public curve: any = shape.curveCatmullRom;
  // public curve: any = shape.curveLinear;
  // public curve: any = shape.curveMonotoneX;
  // public curve: any = shape.curveMonotoneY;
  // public curve: any = shape.curveNatural;
  // public curve: any = shape.curveStep;
  // public curve: any = shape.curveStepAfter;
  // public curve: any = shape.curveStepBefore;

  // public curve: any = stepRoundAfter;
  // public curve: any = stepRoundBefore;
  public layout: Layout = new DagreNodesOnlyLayout();
  links: any = [
    // {
    //   id: "link_1",
    //   source: "node_1",
    //   target: "node_2",
    //   label: "",
    // },
    // {
    //   id: "link_2",
    //   source: "node_2",
    //   target: "node_3",
    //   label: "Restaurants",
    // },
    // {
    //   id: "link_3",
    //   source: "node_2",
    //   target: "node_3",
    //   label: "Hotels",
    // },
  ];

  nodes = [];

  source: any = "";
  target: string = "";
  label: any = "";

  showChart: boolean = false;

  nodesDrawer: boolean = false;
  showForm: boolean = false;

  messageType: any = "";

  buttonsList: Array<any> = [{ title: "" }];
  menuList: Array<any> = [{ menu: "", list: [{ title: "", description: "" }] }];
  productList: Array<any> = [{ menu: "", list: [] }];
  statementCondition: Array<any> = [
    {
      operator: "is equals to",
      key: "",
      value: "",
      dataType: "text",
    },
  ];

  apiBody: any = JSON.stringify({ key: "", value: "" }, null, 2);
  // apiHeaders: any = JSON.stringify({ key: "", value: "" });
  // apiQueryParams: any = JSON.stringify({ key: "", value: "" });

  apiHeaders: any = [{ key: "", value: "" }];
  apiQueryParams: any = [{ key: "", value: "" }];

  settings = {
    enableAutoMagneticDragging: false,
  };

  // forms section

  addMessageForm: FormGroup;

  errorArr: any = [];
  submitted: boolean = false;
  submitting: boolean = false;

  mediaType: string = "";
  fieldType: any = "";

  dateFormats: Array<string> = [
    "DD/MM/YYYY",
    "MM/DD/YYYY",
    "MM/DD/YY",
    "YYYY-MM-DD",
    "YYYY/MM/DD",
    "YY-MM-DD",
    "YY/MM/DD",
  ];

  outOptionTypes: Array<string> = [
    "SEND_TEXT",
    "SEND_IMAGE",
    "SEND_AUDIO",
    "SEND_VIDEO",
    "SEND_DOCUMENT",
    "SEND_PRODUCTS",
    "SEND_TEMPLATE",
    // "API_CALL",
    "ASK_TEXT",
    "ASK_NUMBER",
    "ASK_PHONE",
    "ASK_EMAIL",
    "ASK_DATE",
    "ASK_URL",
    "ASK_LOCATION",
    "ASK_ADDRESS",
    "ASK_FILE",
    "ASK_ORDER",
    "SELECT_ADDRESS_FROM_THE_LIST",
    "ASSIGN_CONVERSATION",
    "SEND_LIST",
  ];

  askValidators: Array<string> = [
    "ASK_TEXT",
    "ASK_NUMBER",
    "ASK_PHONE",
    "ASK_DATE",
  ];

  askCustomerFields: Array<string> = [
    // "ASK_TEXT",         // temporarily removed for text field updation
    "ASK_NUMBER",
    "ASK_PHONE",
    "ASK_EMAIL",
    "ASK_DATE",
    "ASK_URL",
  ];

  askTypes: Array<string> = [
    "ASK_TEXT",
    "ASK_NUMBER",
    "ASK_PHONE",
    "ASK_EMAIL",
    "ASK_DATE",
    "ASK_URL",
    "ASK_LOCATION",
    "ASK_ADDRESS",
    "ASK_FILE",
    "ASK_ORDER",
    "SELECT_ADDRESS_FROM_THE_LIST",
  ];

  askNoCustomFields: Array<string> = ["ASK_FILE"];

  mediaTypes: Array<string> = [
    "SEND_IMAGE",
    "SEND_AUDIO",
    "SEND_VIDEO",
    "SEND_DOCUMENT",
  ];

  selectedMenu = "all";

  nodeMenu: any[] = [
    {
      category: "Send Message",
      logo: "../../../../assets/images/icons/Bot_page/send_messages.png",
      options: [
        {
          name: "Send Text",
          type: "SEND_TEXT",
          icon: "../../../../assets/images/icons/Bot_page/send_text.png",
        },
        {
          name: "Send Image",
          type: "SEND_IMAGE",
          icon: "../../../../assets/images/icons/Bot_page/send_images.png",
        },
        {
          name: "Send Audio",
          type: "SEND_AUDIO",
          icon: "../../../../assets/images/icons/Bot_page/send_audio.png",
        },
        {
          name: "Send Video",
          type: "SEND_VIDEO",
          icon: "../../../../assets/images/icons/Bot_page/send_video.png",
        },
        {
          name: "Send Document",
          type: "SEND_DOCUMENT",
          icon: "../../../../assets/images/icons/Bot_page/send_document.png",
        },
        {
          name: "Send Products",
          type: "SEND_PRODUCTS",
          icon: "../../../../assets/images/icons/Bot_page/send_products.png",
        },
        {
          name: "Send Buttons",
          type: "SEND_BUTTONS",
          icon: "../../../../assets/images/icons/Bot_page/send_buttons.png",
        },
        {
          name: "Send List",
          type: "SEND_LIST",
          icon: "../../../../assets/images/icons/Bot_page/send_list.png",
        },
        {
          name: "Send Template",
          type: "SEND_TEMPLATE",
          icon: "../../../../assets/images/icons/Bot_page/send_template.svg",
        },
      ],
    },
    {
      category: "Ask Question",
      logo: "../../../../assets/images/icons/Bot_page/ask_questions.svg",
      options: [
        {
          name: "Ask Text",
          type: "ASK_TEXT",
          icon: "../../../../assets/images/icons/Bot_page/ask_text.svg",
        },
        {
          name: "Ask Number",
          type: "ASK_NUMBER",
          icon: "../../../../assets/images/icons/Bot_page/ask_numbers.svg",
        },
        {
          name: "Ask Phone Number",
          type: "ASK_PHONE",
          icon: "../../../../assets/images/icons/Bot_page/ask_number.svg",
        },
        {
          name: "Ask Email",
          type: "ASK_EMAIL",
          icon: "../../../../assets/images/icons/Bot_page/ask_email.svg",
        },
        {
          name: "Ask Date",
          type: "ASK_DATE",
          icon: "../../../../assets/images/icons/Bot_page/ask_date.svg",
        },
        {
          name: "Ask URL",
          type: "ASK_URL",
          icon: "../../../../assets/images/icons/Bot_page/ask_url.svg",
        },
        {
          name: "Ask Location",
          type: "ASK_LOCATION",
          icon: "../../../../assets/images/icons/Bot_page/ask_location.svg",
        },
        {
          name: "Ask File",
          type: "ASK_FILE",
          icon: "../../../../assets/images/icons/Bot_page/ask_file.svg",
        },
        {
          name: "Ask Order",
          type: "ASK_ORDER",
          icon: "../../../../assets/images/icons/Bot_page/ask_order.svg",
        },
        {
          name: "Ask Address",
          type: "ASK_ADDRESS",
          icon: "../../../../assets/images/icons/Bot_page/ask_address.svg",
        },
        {
          name: "Select Address From List",
          type: "SELECT_ADDRESS_FROM_THE_LIST",
          icon: "../../../../assets/images/icons/Bot_page/address_from_list.svg",
        },
      ],
    },
    {
      category: "Utilities",
      logo: "../../../../assets/images/icons/Bot_page/utilities.svg",
      options: [
        {
          name: "Trigger Path",
          type: "TRIGGER_PATH",
          icon: "../../../../assets/images/icons/Bot_page/trigger_path.svg",
        },
        {
          name: "Assign Conversation",
          type: "ASSIGN_CONVERSATION",
          icon: "../../../../assets/images/icons/Bot_page/assign_user.svg",
        },
        {
          name: "IF Statement",
          type: "IF_STATEMENT",
          icon: "../../../../assets/images/icons/Bot_page/if_statement.svg",
        },
      ],
    },
    {
      category: "Connections",
      logo: "../../../../assets/images/icons/Bot_page/connections.svg",

      options: [
        {
          name: "API Call",
          type: "API_CALL",
          icon: "../../../../assets/images/icons/Bot_page/api_call.svg",
        },
      ],
    },
  ];

  apiMethods: Array<string> = ["GET", "POST", "PUT", "PATCH", "DELETE"];

  isEdit: boolean = false;
  editIndex: any = {};

  mediaId: any = "";
  mediaModal: boolean = false;

  messageTemplateSelected: any;

  apiVariableType: Array<string> = [
    "text",
    "number",
    "phone-number",
    "boolean",
    "object",
    "array",
  ];

  flowVariables: any;

  newVariable: any = "";

  // emoji and text editor options

  emojiVisible: boolean = false;
  selectedStyle: string = "";
  set = "twitter";

  showSearch: boolean = false;
  searchText: string = "";

  fullScreen: boolean = false;
  lineLeft: number = 255;

  constructor(
    private fb: FormBuilder,
    private navService: NavService,
    private apiService: CommonApiService,
    private modal: NzModalService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private message: NzMessageService,
    private elementRef: ElementRef,
    private router: Router,
    @Inject(DOCUMENT) private document: any
  ) {}

  @ViewChild("graphContainer", { static: false }) graphContainerRef!: any;
  @ViewChild("menuDivElement", { static: false }) menuDivElement: ElementRef;
  myDiv: ElementRef;

  divWidth: number = 0;

  botData: any = {
    botId: "",
    flowId: "",
  };

  pathName: any = "";

  allProducts: any;
  customerFields: any;
  allPaths: any;
  allUsers: any;
  allTemplates: any;
  templateVariables: any;
  fieldData: any;
  valueChangesSubscription: Subscription;

  templatePage: number = 1;
  productsPage: number = 1;

  urlOrigin: string = "";

  duplicatePath: any = "";

  ngOnInit(): void {
    // this.obj = JSON.stringify({
    //   string: {
    //     type: "string",
    //     title: "String",
    //   },
    // });

    this.botData = this.route.snapshot.params;

    // this.route.params.subscribe((e) => {
    //   // console.log(e);
    //   this.botData = e;
    // });

    this.showChart = false;
    this.addMessageForm = this.fb.group({
      actionName: ["", Validators.required],
    });

    this.getAllNodes();
    this.getMainUserData();
    this.urlOrigin = window.location.origin;
    console.log(window.location.origin);
  }

  getAllNodes() {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    var req = { pathId: this.botData.flowId };

    this.apiService
      .commonPostMethod(environment.url + "flow-actions/get-all", req, options)
      .subscribe(
        (response: any) => {
          console.log(response);
          // const allowedActionTypes = ["SEND_TEXT", "SEND_BUTTONS", "SEND_LIST"];
          this.pathName = response.data.flowPath;
          const modifiedData = response.data.flowActions
            // .filter((item) => allowedActionTypes.includes(item.actionType))
            .map((item) => {
              const { _id, ...rest } = item;
              return { id: _id, label: "label", ...rest };
            });

          this.nodes = modifiedData;

          console.log(this.nodes);
          // this.showChart = true;
          this.testReFormatting();
          // this.getWidthByClassName();
        },
        (err: any) => {
          console.log(err);
          if (
            err?.error?.message == "jwt expired" ||
            err?.error?.message == "jwt must be provided" ||
            err?.error?.message == "jwt malformed" ||
            err?.error?.message == "invalid signature" ||
            err?.error?.message == "Given token user does not exist." ||
            err?.error?.message == "invalid token"
          ) {
            this.navService.tokenExpired();
          }
        }
      );
  }

  subscription: Subscription;

  getMainUserData() {
    this.navService.getMainUserData();
    this.subscription = this.navService.mainUserData.subscribe(
      (response: any) => {
        if (response) {
          if (response?.data?.userData?.accessTo?.sandboxId) {
            this.router.navigate(["/chat"]);
          }
        }
      }
    );
  }

  activate() {
    console.log("activated");
  }

  addLink(nodeId: any, type: string) {
    // console.log(nodeId);
    const element =
      this.elementRef.nativeElement.querySelector(`.bot_menu_list`);
    if (element) {
      // console.log(element.offsetWidth);
      this.lineLeft = element.offsetWidth;
    } else {
      this.lineLeft = 255;
    }
    if (!this.isDrawingLine) {
      this.startDrawingLine(nodeId);
    }
    if (type == "out") {
      if (this.target && this.target != nodeId.id) {
        this.isDrawingLine = false;
        window.removeEventListener("mousedown", this.handleMouseDown);
        window.removeEventListener("mousemove", this.handleMouseMove);
        window.removeEventListener("mouseup", this.handleMouseUp);
        var hasLink = false;
        if (this.links.length > 0) {
          hasLink = this.links.some(
            (link: any) =>
              (link.source === nodeId.id && link.label === this.label
                ? this.label
                : "") ||
              (link.source === nodeId.id &&
                this.outOptionTypes.indexOf(nodeId.actionType) > -1)
          );
        } else {
          hasLink = false;
        }
        // console.log(hasLink);
        if (!hasLink) {
          // const tempLink = {
          //   id: "link_" + (this.links.length + 1),
          //   source: nodeId.id,
          //   target: this.target,
          //   label: this.label,
          // };
          // this.links = [...this.links, tempLink];

          // var sourceIndex = this.nodes.findIndex((node) => {
          //   return node.id == nodeId.id;
          // });
          // this.nodes[sourceIndex].nextAction = this.target;
          var reqLink1 = {
            flowActionId: nodeId.id,
            nextAction: this.target,
            type: "join",
          };

          this.updateLink(reqLink1);
        }

        this.target = "";
        this.source = "";
      } else {
        this.source = nodeId;
        this.target = "";
        // console.log("out direct");
      }
    } else if (type == "in") {
      console.log(this.source);
      if (this.source.id && this.source.id != nodeId.id) {
        // console.log(this.links);
        // console.log(this.label);
        this.isDrawingLine = false;
        window.removeEventListener("mousedown", this.handleMouseDown);
        window.removeEventListener("mousemove", this.handleMouseMove);
        window.removeEventListener("mouseup", this.handleMouseUp);
        var hasLink = false;
        if (this.links.length > 0) {
          // console.log(this.links);
          // console.log(this.source);
          hasLink = this.links.some(
            (link: any) =>
              (link.source === this.source.id &&
                link.id === `link_${this.label.id}`) ||
              (link.source === this.source.id &&
                link.id ===
                  `link_statement${this.label.id}nodeId${nodeId.id}`) ||
              (link.source === this.source.id &&
                link.id === `goBack_${this.label.id}nodeId${nodeId.id}`) ||
              (link.source === this.source.id &&
                this.outOptionTypes.indexOf(this.source.actionType) > -1 &&
                this.source.actionType != "SEND_LIST") ||
              (link.source === nodeId.id &&
                link.target === this.source.id &&
                this.outOptionTypes.indexOf(this.source.actionType) > -1 &&
                this.source.actionType != "SEND_LIST")
          );
        } else {
          hasLink = false;
        }

        // console.log(hasLink);

        if (!hasLink) {
          var reqLink: any = {
            flowActionId: this.source.id,
            nextAction: nodeId.id,
            type: "join",
          };

          if (
            (this.source.actionType == "SEND_LIST" &&
              this.source.actionData?.type == "static") ||
            this.source.actionType == "SEND_BUTTONS"
          ) {
            reqLink.indexId = this.label.id;
          }

          if (
            this.source.actionType == "SEND_LIST" &&
            this.source.actionData?.type == "dynamic" &&
            this.label.type == "Go back"
          ) {
            delete reqLink.nextAction;
            reqLink.goBackAction = nodeId.id;
          }

          if (
            this.source.actionType == "IF_STATEMENT" ||
            this.source.actionType == "API_CALL"
          ) {
            delete reqLink.nextAction;
            this.label.type == "success"
              ? (reqLink.onSuccess = nodeId.id)
              : (reqLink.onFailed = nodeId.id);
          }
          this.updateLink(reqLink);
          // console.log(reqLink);
        }
        this.source = "";
        this.target = "";
      } else {
        this.target = nodeId.id;
        this.source = "";
      }
    }
    this.label = "";
  }

  selectedNode: any;
  startPath: any;

  removeNodeAlert(nodeId: any) {
    this.startPath = null;
    if (nodeId.isThisStartPath && this.nodes.length > 1) {
      this.alertModal = true;
      this.selectedNode = nodeId.id;
    } else {
      this.modal.confirm({
        nzOkText: "Yes",
        nzCancelText: "No",
        nzTitle: "<b>Sure you Want to delete Node?</b>",
        // nzContent: "<b>Once deleted, the note cannot be recovered..</b>",
        nzOnOk: () => this.removeNode(nodeId.id, "remove"),
      });
    }
  }

  closeAlertModal() {
    this.alertModal = false;
    this.startPath = null;
    this.selectedNode = null;
  }

  alertModal: boolean = false;

  removeNode(nodeId: any, method: string) {
    if (method == "update") {
      this.updateStartPath(this.startPath);
    }
    console.log(nodeId);
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url + "flow-actions/delete",
        { flowActionId: nodeId },
        options
      )
      .subscribe(
        (response: any) => {
          console.log(response);
          this.getAllNodes();
        },
        (err: any) => {
          console.log(err);
          if (err?.error?.message) {
            this.toastr.error(err?.error?.message, "", {
              timeOut: 2500,
            });
          } else {
            this.toastr.error(err?.message, "", {
              timeOut: 2500,
            });
          }
        }
      );
    // var targetIndex = this.nodes.findIndex((e: any) => {
    //   return e.id == nodeId;
    // });

    // if (targetIndex > -1) {
    //   this.nodes.splice(targetIndex, 1);
    //   this.nodes = [...this.nodes];
    //   this.links = this.links.filter(
    //     (link) => link.source !== nodeId && link.target !== nodeId
    //   );
    // }
  }

  removeLink(linkId: any) {
    console.log(linkId);
    console.log(this.label);
    var reqLink: any = {
      flowActionId: linkId.source,
      nextAction: linkId.target,
      type: "isolate",
    };
    if (
      (linkId.sourceType == "SEND_LIST" && linkId.label != "go back") ||
      linkId.sourceType == "SEND_BUTTONS"
    ) {
      reqLink.indexId = linkId.id.replace("link_", "");
    }

    if (linkId.sourceType == "SEND_LIST" && linkId.label == "go back") {
      delete reqLink.nextAction;
      reqLink.goBackAction = linkId.id
        .replace("goBack_", "")
        .split("nodeId")[0];
    }

    if (
      linkId.sourceType == "IF_STATEMENT" ||
      linkId.sourceType == "API_CALL"
    ) {
      delete reqLink.nextAction;
      linkId.label == "On Success"
        ? (reqLink.onSuccess = linkId.id
            .replace("link_statement", "")
            .split("nodeId")[0])
        : (reqLink.onFailed = linkId.id
            .replace("link_statement", "")
            .split("nodeId")[0]);
    }

    console.log(reqLink);

    this.updateLink(reqLink);
  }

  addSource(nodeSelected: any, label: any) {
    this.source = nodeSelected;
    this.label = label;
    this.startDrawingLine(nodeSelected);
    console.log(this.source);
  }

  closeNodesDrawer() {
    this.showForm = false;
    this.nodesDrawer = false;
  }

  // forms section

  selectPath(path) {
    this.duplicatePath = path;
    this.showForm = true;
    this.nodesDrawer = true;
  }

  selectMessageType(
    type: string,
    edit: boolean,
    data: any,
    isDuplicate: boolean
  ) {
    this.duplicatePath = "";
    this.variableSearch = "";
    console.log(data);
    this.codeEditor = false;
    this.field = "";
    this.emojiVisible = false;
    this.selectedStyle = "";
    this.mediaId = "";
    this.newVariable = "";
    this.isEdit = edit;
    this.submitting = false;
    this.submitted = false;
    this.buttonsList = [{ title: "" }];
    this.menuList = [{ menu: "", list: [{ title: "", description: "" }] }];
    this.productList = [{ menu: "", list: [] }];
    this.apiBody = JSON.stringify({ key: "", value: "" });
    this.statementCondition = [
      {
        operator: "is equals to",
        key: "",
        value: "",
        dataType: "text",
      },
    ];
    this.removeControls();
    if (!this.flowVariables || this.flowVariables?.length == 0) {
      this.getAllVariables("");
    }
    if (!this.customerFields) {
      this.getCustomerFields();
    }
    if (!this.allPaths) {
      this.getAllPaths();
    }
    if (edit) {
      this.editIndex = this.getNodeIndex(data.id);
      this.addMessageForm.get("actionName").setValue(data.actionName);
    }
    if (type == "SEND_TEXT") {
      this.addMessageForm.addControl(
        "message",
        this.fb.control(edit ? data.actionData.message : "", [
          Validators.required,
          Validators.maxLength(4096),
        ])
      );
    } else if (
      type == "SEND_IMAGE" ||
      type == "SEND_VIDEO" ||
      type == "SEND_DOCUMENT"
    ) {
      this.addMessageForm.addControl(
        "caption",
        this.fb.control(
          edit ? data.actionData?.caption : "",
          Validators.maxLength(4096)
        )
      );
      if (type == "SEND_DOCUMENT") {
        this.addMessageForm.addControl(
          "documentName",
          this.fb.control(
            edit ? data.actionData?.caption : ""
            // Validators.required
          )
        );
      }

      if (edit) {
        this.mediaId = {
          id: data.actionData.mediaData?.id?._id,
          url: this.liveUrl + data.actionData.mediaData?.id?.url,
        };
      }
    } else if (type == "SEND_AUDIO") {
      if (edit) {
        this.mediaId = {
          id: data.actionData.mediaData.id._id,
          url: this.liveUrl + data.actionData.mediaData.id.url,
        };
      }
    } else if (type == "SEND_PRODUCTS") {
      if (!this.allProducts) {
        this.getAllProducts();
      }

      this.addMessageForm.addControl(
        "type",
        this.fb.control(
          edit ? data.actionData.type : "single",
          Validators.required
        )
      );

      this.addMessageForm.addControl(
        "productIds",
        this.fb.control(
          edit ? data.actionData.product_retailer_id : "",
          Validators.required
        )
      );

      this.addMessageForm.addControl(
        "bodyText",
        this.fb.control(
          edit ? data.actionData.bodyText : "",
          Validators.maxLength(4096)
        )
      );

      this.addMessageForm.addControl(
        "footerText",
        this.fb.control(
          edit ? data.actionData.footerText : "",
          Validators.maxLength(60)
        )
      );

      this.valueChangesSubscription = this.addMessageForm
        .get("type")
        .valueChanges.subscribe((newValue) => {
          this.productsTypeChange(newValue, data);
        });

      if (edit && data.actionData.type == "multi") {
      }

      if (edit && data.actionData.type == "multi") {
        this.productsTypeChange("multi", data);
        this.productList = [];
        data.actionData.sections.forEach((item: any) => {
          const productList = item.product_items.map(
            (e: any) => e.product_retailer_id
          );
          this.productList.push({ menu: item.title, list: productList });
        });
      }
    } else if (type == "SEND_BUTTONS") {
      this.addMessageForm.addControl(
        "headerText",
        this.fb.control(
          edit
            ? data.actionData?.headerText
              ? data.actionData?.headerText
              : ""
            : "",
          Validators.maxLength(60)
        )
      );

      this.addMessageForm.addControl(
        "hasMedia",
        this.fb.control(
          edit ? data.actionData?.mediaData.hasMedia : false,
          Validators.required
        )
      );

      this.addMessageForm.addControl(
        "bodyText",
        this.fb.control(edit ? data.actionData.bodyText : "", [
          Validators.required,
          Validators.maxLength(4096),
        ])
      );

      this.addMessageForm.addControl(
        "buttonMediaType",
        this.fb.control(
          data.actionData?.mediaData?.type && edit
            ? data.actionData?.mediaData?.type
            : "image"
        )
      );

      this.addMessageForm.addControl(
        "footerText",
        this.fb.control(
          edit ? data.actionData?.footerText : "",
          Validators.maxLength(60)
        )
      );

      this.addMessageForm.addControl(
        "responseVariable",
        this.fb.control(
          edit ? data.actionData?.variables?.name : "",
          Validators.maxLength(200)
        )
      );

      if (edit) {
        if (data.actionData?.mediaData.hasMedia) {
          this.mediaId = {
            id: data.actionData.mediaData.id._id,
            url: this.liveUrl + data.actionData.mediaData.id.url,
          };
          this.mediaType = data.actionData.mediaData.type;
        }

        if (data.actionData.buttons.length > 0) {
          this.buttonsList = [];
          data.actionData.buttons.forEach((item: any) => {
            this.buttonsList.push({
              id: item?.id,
              nextAction: item?.nextAction,
              title: item.title,
            });
          });
        }
      }
    } else if (type == "SEND_LIST") {
      this.addMessageForm.addControl(
        "headerText",
        this.fb.control(
          edit ? data.actionData?.headerText : "",
          Validators.maxLength(60)
        )
      );
      this.addMessageForm.addControl(
        "bodyText",
        this.fb.control(edit ? data.actionData?.bodyText : "", [
          Validators.required,
          Validators.maxLength(4096),
        ])
      );
      this.addMessageForm.addControl(
        "footerText",
        this.fb.control(
          edit ? data.actionData?.footerText : "",
          Validators.maxLength(60)
        )
      );
      this.addMessageForm.addControl(
        "buttonText",
        this.fb.control(
          edit ? data.actionData?.buttonText : "",
          Validators.required
        )
      );

      this.addMessageForm.addControl(
        "listType",
        this.fb.control(edit ? data.actionData?.type : "static")
      );

      this.valueChangesSubscription = this.addMessageForm
        .get("listType")
        .valueChanges.subscribe((newValue) => {
          this.ListTypeChange(newValue, data);
        });

      this.addMessageForm.addControl(
        "responseVariable",
        this.fb.control(
          data ? data.actionData?.variables?.name : "",
          Validators.maxLength(200)
        )
      );

      if (edit) {
        if (data?.actionData?.type == "static") {
          this.menuList = [];
          let actionData = JSON.parse(JSON.stringify(data.actionData));
          actionData.sections.forEach((item: any) => {
            this.menuList.push({ menu: item.title, list: item.rows });
          });
        } else {
          this.ListTypeChange("dynamic", data);
        }
      }
    } else if (this.askTypes.indexOf(type) > -1) {
      switch (type) {
        case "ASK_NUMBER":
          this.fieldType = "number";
          break;
        case "ASK_PHONE":
          this.fieldType = "phone-number";
          break;
        case "ASK_EMAIL":
          this.fieldType = "email";
          break;
        case "ASK_DATE":
          this.fieldType = "date";
          break;
        case "ASK_URL":
          this.fieldType = "text";
          break;
        default:
          break;
      }

      if (type == "ASK_ADDRESS" || type == "SELECT_ADDRESS_FROM_THE_LIST") {
        this.addMessageForm.addControl(
          "message",
          this.fb.control(edit ? data.actionData.bodyText : "", [
            Validators.required,
            Validators.maxLength(4096),
          ])
        );
      } else {
        this.addMessageForm.addControl(
          "message",
          this.fb.control(edit ? data.actionData.message : "", [
            Validators.required,
            Validators.maxLength(4096),
          ])
        );
      }
      this.addMessageForm.addControl(
        "errorAttempt",
        this.fb.control(edit ? data.actionData.errorAttempt : 1, [
          Validators.required,
          Validators.min(1),
          Validators.max(10),
        ])
      );
      this.addMessageForm.addControl(
        "errorMessage",
        this.fb.control(edit ? data.actionData.errorMessage : "", [
          Validators.required,
          Validators.maxLength(4000),
        ])
      );
      this.addMessageForm.addControl(
        "onInvalidResponse",
        this.fb.control(
          edit ? data.actionData.onInvalidResponse : "Skip-the-flow",
          Validators.required
        )
      );

      if (type == "SELECT_ADDRESS_FROM_THE_LIST") {
        this.addMessageForm.addControl(
          "variable",
          this.fb.control(
            edit ? data.actionData.variableData.variable : "",
            Validators.required
          )
        );
        this.addMessageForm.addControl(
          "path",
          this.fb.control(
            edit ? data.actionData.variableData.path : "",
            Validators.required
          )
        );
      }

      if (type == "ASK_FILE") {
        this.addMessageForm.addControl(
          "fileType",
          this.fb.control(
            edit ? data.actionData.fileType : "image",
            Validators.required
          )
        );
      }

      if (type == "ASK_DATE") {
        this.addMessageForm.addControl(
          "dateFormat",
          this.fb.control(
            edit ? data.actionData.format : "DD/MM/YYYY",
            Validators.required
          )
        );
      }

      if (this.askNoCustomFields.indexOf(type) == -1) {
        this.addMessageForm.addControl(
          "responseVariable",
          this.fb.control(
            edit ? data.actionData?.variables?.name : "",
            Validators.maxLength(200)
          )
        );
      }

      if (this.askCustomerFields.indexOf(type) > -1) {
        this.addMessageForm.addControl(
          "fieldData",
          this.fb.control(
            edit ? data.actionData?.customerField?.fieldData?.id : ""
          )
        );
      }

      if (this.askValidators.indexOf(type) > -1) {
        this.addMessageForm.addControl(
          "validationSetting",
          this.fb.control(
            edit ? data.actionData.validation.hasValidation : false,
            Validators.required
          )
        );

        if (type == "ASK_TEXT") {
          this.addMessageForm.addControl(
            "minValue",
            this.fb.control(
              edit ? data.actionData.validation?.characters?.min : 0
            )
          );
          this.addMessageForm.addControl(
            "maxValue",
            this.fb.control(
              edit ? data.actionData.validation?.characters?.max : 10
            )
          );
        } else if (type == "ASK_NUMBER") {
          this.addMessageForm.addControl(
            "minValue",
            this.fb.control(edit ? data.actionData.validation?.value?.min : 0)
          );
          this.addMessageForm.addControl(
            "maxValue",
            this.fb.control(edit ? data.actionData.validation?.value?.max : 10)
          );
        } else if (type == "ASK_PHONE") {
          this.addMessageForm.addControl(
            "minValue",
            this.fb.control(edit ? data.actionData.validation?.length?.min : 0)
          );
          this.addMessageForm.addControl(
            "maxValue",
            this.fb.control(edit ? data.actionData.validation?.length?.max : 10)
          );
        } else if (type == "ASK_DATE") {
          this.addMessageForm.addControl(
            "minValue",
            this.fb.control(
              edit ? data.actionData.validation?.allowedDates?.min : ""
            )
          );
          this.addMessageForm.addControl(
            "maxValue",
            this.fb.control(
              edit ? data.actionData.validation?.allowedDates?.max : ""
            )
          );
        }
      }
    } else if (type == "API_CALL") {
      this.addMessageForm.addControl(
        "variableType",
        this.fb.control(edit ? data.actionData.dataType : "array")
      );

      this.addMessageForm.addControl(
        "method",
        this.fb.control(
          edit ? data.actionData.method : "POST",
          Validators.required
        )
      );
      this.addMessageForm.addControl(
        "url",
        this.fb.control(edit ? data.actionData.url : "", Validators.required)
      );

      this.addMessageForm.addControl(
        "contentType",
        this.fb.control(
          edit ? data.actionData.contentType : "application/json",
          Validators.required
        )
      );

      if (edit) {
        // this.apiQueryParams = JSON.stringify(data.actionData.queryParams);
        // this.apiHeaders = JSON.stringify(data.actionData.headers);
        for (const key in data.actionData.headers) {
          if (data.actionData.headers.hasOwnProperty(key)) {
            this.apiHeaders.push({
              key: key,
              value: data.actionData.headers[key],
            });
          }
        }
        for (const key in data.actionData.queryParams) {
          if (data.actionData.queryParams.hasOwnProperty(key)) {
            this.apiQueryParams.push({
              key: key,
              value: data.actionData.queryParams[key],
            });
          }
        }
        this.apiBody = data.actionData.body
          ? JSON.stringify(data.actionData.body, null, 2)
          : JSON.stringify({});
      }

      this.addMessageForm.addControl(
        "responseVariable",
        this.fb.control(
          edit && data.actionData?.variables?.name
            ? data.actionData?.variables?.name
            : "",
          Validators.maxLength(200)
        )
      );
    } else if (type == "TRIGGER_PATH") {
      this.addMessageForm.addControl(
        "triggerPath",
        this.fb.control(
          edit ? data.actionData?.path._id : "",
          Validators.required
        )
      );
    } else if (type == "ASSIGN_CONVERSATION") {
      this.addMessageForm.addControl(
        "assigneeType",
        this.fb.control(
          edit ? data.actionData?.type : "User",
          Validators.required
        )
      );

      this.conversationChange(data);

      if (!this.allUsers) {
        this.getAllUsers();
      }
    } else if (type == "IF_STATEMENT") {
      this.addMessageForm.addControl(
        "conditionType",
        this.fb.control(
          edit ? data.actionData?.conditionType : "OR",
          Validators.required
        )
      );

      if (edit) {
        this.statementCondition = [];
        data.actionData.conditions.forEach((item: any) => {
          const conditions = {
            operator: item.operator,
            key: item.key,
            value: item.value,
            dataType: item.dataType,
          };
          this.statementCondition.push(conditions);
        });
      }
    } else if (type == "SEND_TEMPLATE") {
      this.addMessageForm.addControl(
        "templateId",
        this.fb.control(
          edit ? data.actionData?.templateId?._id : "",
          Validators.required
        )
      );
      this.templateVariables = null;

      if (edit) {
        if (!this.fieldData) {
          this.getFieldData();
        }

        // if (this.allTemplates) {
        //   this.allTemplates.waMsgTemplates.push({
        //     _id: this.addMessageForm.value.templateId,
        //     name: "sample name",
        //   });
        // } else {
        //   this.allTemplates = {
        //     waMsgTemplates: [
        //       {
        //         _id: this.addMessageForm.value.templateId,
        //         name: "sample name",
        //       },
        //     ],
        //   };
        // }

        this.templateVariables = {
          mediaData: data.actionData.mediaData,
          variableTemplate: data.actionData.variableData,
        };

        this.mediaId = { id: data.actionData.mediaData.id };
      }

      if (!this.allTemplates || edit) {
        this.templatePage = 1;
        this.getAllTemplates();
      }
    }

    if (isDuplicate) {
      this.isEdit = false;
    } else {
      this.showForm = true;
      this.nodesDrawer = true;
    }

    this.messageType = type;
  }

  productsTypeChange(newValue: any, data: any) {
    if (newValue == "single") {
      this.addMessageForm?.removeControl("headerText");
      this.addMessageForm.addControl(
        "productIds",
        this.fb.control("", Validators.required)
      );
      this.addMessageForm.get("bodyText").clearValidators();
      this.addMessageForm.get("bodyText").updateValueAndValidity();
    } else {
      this.addMessageForm?.removeControl("productIds");
      this.addMessageForm.addControl(
        "headerText",
        this.fb.control(data ? data.actionData.headerText : "", [
          Validators.required,
          Validators.maxLength(60),
        ])
      );

      this.addMessageForm.get("bodyText").setValidators([Validators.required]);
      this.addMessageForm.get("bodyText").updateValueAndValidity();
    }
  }

  ListTypeChange(newValue: any, data: any) {
    // console.log(newValue);
    if (newValue == "static") {
      this.addMessageForm?.removeControl("errorAttempt");
      this.addMessageForm?.removeControl("errorMessage");
      this.addMessageForm?.removeControl("onInvalidResponse");

      this.addMessageForm?.removeControl("variableName");
      this.addMessageForm?.removeControl("variablePath");
      this.addMessageForm?.removeControl("variableTitle");
      this.addMessageForm?.removeControl("variableDescription");
    } else {
      this.addMessageForm.addControl(
        "errorAttempt",
        this.fb.control(
          data ? data.actionData?.errorAttempt : "",
          Validators.required
        )
      );
      this.addMessageForm.addControl(
        "errorMessage",
        this.fb.control(
          data
            ? data.actionData?.errorMessage
              ? data.actionData?.errorMessage
              : ""
            : "",
          [Validators.required, Validators.maxLength(4000)]
        )
      );
      this.addMessageForm.addControl(
        "onInvalidResponse",
        this.fb.control(
          data ? data.actionData?.onInvalidResponse : "Skip-the-flow",
          Validators.required
        )
      );

      this.addMessageForm.addControl(
        "variableName",
        this.fb.control(
          data ? data.actionData?.variableData?.variable : "",
          Validators.required
        )
      );
      this.addMessageForm.addControl(
        "variablePath",
        this.fb.control(
          data ? data.actionData?.variableData?.path : "",
          Validators.required
        )
      );
      this.addMessageForm.addControl(
        "variableTitle",
        this.fb.control(
          data ? data.actionData?.variableData?.keys?.title : "",
          Validators.required
        )
      );
      this.addMessageForm.addControl(
        "variableDescription",
        this.fb.control(
          data ? data.actionData?.variableData?.keys?.description : "",
          Validators.required
        )
      );
    }
  }

  conversationChange(data: any) {
    if (this.addMessageForm.value.assigneeType == "User") {
      this.addMessageForm.addControl(
        "assignee",
        this.fb.control(
          data.actionData?.assigneeId._id
            ? data.actionData?.assigneeId._id
            : "",
          Validators.required
        )
      );
    } else {
      this.addMessageForm.removeControl("assignee");
    }
  }

  removeControls() {
    this.addMessageForm.reset();
    this.addMessageForm?.removeControl("bodyText");
    this.addMessageForm?.removeControl("footerText");
    this.addMessageForm?.removeControl("message");
    this.addMessageForm?.removeControl("headerText");
    this.addMessageForm?.removeControl("listType");
    this.addMessageForm?.removeControl("buttonText");

    this.addMessageForm?.removeControl("errorAttempt");
    this.addMessageForm?.removeControl("errorMessage");
    this.addMessageForm?.removeControl("onInvalidResponse");
    this.addMessageForm?.removeControl("validationSetting");
    this.addMessageForm?.removeControl("minValue");
    this.addMessageForm?.removeControl("maxValue");

    this.addMessageForm?.removeControl("method");
    this.addMessageForm?.removeControl("url");
    this.addMessageForm?.removeControl("responseVariable");
    this.addMessageForm?.removeControl("caption");
    this.addMessageForm?.removeControl("contentType");

    this.addMessageForm?.removeControl("type");
    this.addMessageForm?.removeControl("productIds");
    this.addMessageForm?.removeControl("fileType");

    this.addMessageForm?.removeControl("dateFormat");
    this.addMessageForm?.removeControl("fieldData");

    this.addMessageForm?.removeControl("variable");
    this.addMessageForm?.removeControl("path");
    this.addMessageForm?.removeControl("triggerPath");

    this.addMessageForm?.removeControl("assigneeType");
    this.addMessageForm?.removeControl("assignee");

    this.addMessageForm?.removeControl("conditionType");
    this.addMessageForm?.removeControl("variableName");
    this.addMessageForm?.removeControl("variablePath");
    this.addMessageForm?.removeControl("variableTitle");
    this.addMessageForm?.removeControl("variableDescription");

    this.addMessageForm?.removeControl("buttonMediaType");
    this.addMessageForm?.removeControl("hasMedia");
    this.addMessageForm?.removeControl("templateId");

    this.addMessageForm?.removeControl("variableType");

    this.mediaId = "";
    this.messageTemplateSelected = "";

    this.apiQueryParams = [];
    this.apiHeaders = [];
    this.apiBody = JSON.stringify({});

    if (this.valueChangesSubscription) {
      this.valueChangesSubscription.unsubscribe();
    }
  }

  getNodeIndex(id: any) {
    var targetIndex = this.nodes.findIndex((node) => {
      return node.id == id;
    });

    return targetIndex;
  }

  addNode() {
    this.errorArr = [];
    this.submitted = true;
    // console.log(this.addMessageForm.value);
    if (this.addMessageForm.valid) {
      // console.log(this.botData);
      this.submitting = true;
      var reqObj: any = {};

      if (!this.isEdit) {
        reqObj = {
          actionName: this.addMessageForm.value.actionName,
          actionType: this.messageType,
          flowId: this.botData.botId,
          flowPathId: this.duplicatePath
            ? this.duplicatePath._id
            : this.botData.flowId,
        };
      } else {
        console.log(this.nodes[this.editIndex]);
        reqObj = {
          flowActionId: this.nodes[this.editIndex].id,
          actionName: this.addMessageForm.value.actionName,
          flowId: this.botData.botId,
        };
      }

      if (this.messageType == "SEND_TEXT") {
        reqObj.actionData = {
          message: this.addMessageForm.value.message,
        };
      } else if (this.mediaTypes.indexOf(this.messageType) > -1) {
        if (this.mediaId?.id) {
          reqObj.actionData = {
            mediaData: {
              id: this.mediaId.id,
            },
            errorMessage: "Media Not Supported",
          };
          if (this.messageType != "SEND_AUDIO") {
            reqObj.actionData.caption = this.addMessageForm.value.caption;
          }
        } else {
          this.toastr.error("Please select media file!", "", {
            timeOut: 2500,
          });
          this.errorArr.push("provide valid media");
        }
      } else if (this.messageType == "SEND_BUTTONS") {
        var buttons: any = [];
        // console.log(this.buttonsList);
        this.buttonsList.forEach((data: any, index: number) => {
          // console.log(data);
          if (data.title) {
            var buttonsObj = {
              title: data.title,
              nextAction: data.nextAction ? data.nextAction : null,
              id: data.id ? data.id : null,
            };
            buttons.push(buttonsObj);
          } else {
            this.errorArr.push("button" + index);
          }
        });
        reqObj.actionType = this.messageType;
        reqObj.actionData = {
          mediaData: {
            hasMedia: this.addMessageForm.value.hasMedia,
          },
          type: "button",
          bodyText: this.addMessageForm.value.bodyText,
          footerText: this.addMessageForm.value.footerText,
          buttons: buttons,
          variables: {
            needToUpdate: this.addMessageForm.value.responseVariable
              ? true
              : false,
          },
        };

        if (this.addMessageForm.value.responseVariable) {
          reqObj.actionData.variables.name =
            this.addMessageForm.value.responseVariable;
        }

        if (!this.addMessageForm.value.hasMedia) {
          reqObj.actionData.headerText = this.addMessageForm.value.headerText;
        }

        if (this.addMessageForm.value.hasMedia && this.mediaId.id) {
          reqObj.actionData.mediaData.id = this.mediaId.id;
          reqObj.actionData.mediaData.type =
            this.addMessageForm.value.buttonMediaType;
        } else if (this.addMessageForm.value.hasMedia && !this.mediaId.id) {
          this.errorArr.push("media");
          this.toastr.error("Please select media file", "", {
            timeOut: 2500,
          });
        }
        // console.log(reqObj);
        // console.log(this.errorArr);
      } else if (this.messageType == "SEND_LIST") {
        reqObj.actionType = this.messageType;
        reqObj.actionData = {
          type: this.addMessageForm.value.listType,
          headerText: this.addMessageForm.value.headerText,
          bodyText: this.addMessageForm.value.bodyText,
          footerText: this.addMessageForm.value.footerText,
          buttonText: this.addMessageForm.value.buttonText,
          variables: {
            needToUpdate: false,
          },
          customerField: {
            needToUpdate: false,
          },
        };

        if (this.addMessageForm.value.listType == "static") {
          reqObj.actionData.sections = [];
          for (let i = 0; i < this.menuList.length; i++) {
            var stopMainLoop = false;
            let e = this.menuList[i];
            if (!e.menu || stopMainLoop) {
              this.errorArr.push("menu" + i);
              break;
            }
            for (let j = 0; j < e.list.length; j++) {
              let list = e.list[j];
              if (!list.title) {
                this.errorArr.push("title" + i);
                stopMainLoop = true;
                break;
              }
            }
            if (e.menu) {
              var tempData = {
                title: e.menu,
                rows: e.list,
              };
            }

            reqObj.actionData.sections.push(tempData);
          }
          console.log(this.errorArr);
        } else {
          reqObj.actionData.errorAttempt =
            this.addMessageForm.value.errorAttempt;
          reqObj.actionData.errorMessage =
            this.addMessageForm.value.errorMessage;
          reqObj.actionData.onInvalidResponse =
            this.addMessageForm.value.onInvalidResponse;
          reqObj.actionData.variableData = {
            variable: this.addMessageForm.value.variableName,
            path: this.addMessageForm.value.variablePath,
            keys: {
              title: this.addMessageForm.value.variableTitle,
              description: this.addMessageForm.value.variableDescription,
            },
          };
        }

        reqObj.actionData.variables = {
          needToUpdate: this.addMessageForm.value.responseVariable
            ? true
            : false,
        };

        if (this.addMessageForm.value.responseVariable) {
          reqObj.actionData.variables.name =
            this.addMessageForm.value.responseVariable;
        }
      } else if (this.messageType == "SEND_PRODUCTS") {
        reqObj.actionData = {
          type: this.addMessageForm.value.type,
          footerText: this.addMessageForm.value.footerText,
          bodyText: this.addMessageForm.value.bodyText,
        };
        if (this.addMessageForm.value.type == "single") {
          reqObj.actionData.product_retailer_id =
            this.addMessageForm.value.productIds;
        } else {
          reqObj.actionData.headerText = this.addMessageForm.value.headerText;
          reqObj.actionData.sections = [];
          console.log(this.productList);
          this.productList.forEach((e: any, i: number) => {
            if (e.list.length == 0) {
              this.errorArr.push("list" + i);
            }
            if (e.menu) {
              var tempData = {
                title: e.menu,
                product_items: [],
              };
              e.list.forEach((list: any, index: number) => {
                var tempList = {
                  product_retailer_id: list,
                };
                tempData.product_items.push(tempList);
              });
            } else {
              this.errorArr.push("menu" + i);
            }

            reqObj.actionData.sections.push(tempData);
          });
        }
      } else if (this.messageType == "ASK_TEXT") {
        reqObj.actionData = {
          message: this.addMessageForm.value.message,
          errorAttempt: this.addMessageForm.value.errorAttempt,
          errorMessage: this.addMessageForm.value.errorMessage,
          validation: {
            hasValidation: this.addMessageForm.value.validationSetting,
          },
          variables: {
            needToUpdate: this.addMessageForm.value.responseVariable
              ? true
              : false,
          },
          customerField: {
            needToUpdate: this.addMessageForm.value.fieldData ? true : false,
          },
          onInvalidResponse: this.addMessageForm.value.onInvalidResponse,
        };

        if (this.addMessageForm.value.responseVariable) {
          reqObj.actionData.variables.name =
            this.addMessageForm.value.responseVariable;
        }

        if (this.addMessageForm.value.fieldData) {
          reqObj.actionData.customerField.fieldData = {
            id: this.addMessageForm.value.fieldData,
          };
        }

        if (this.addMessageForm.value.validationSetting) {
          reqObj.actionData.validation.characters = {
            min: this.addMessageForm.value.minValue,
            max: this.addMessageForm.value.maxValue,
          };
        }
      } else if (this.messageType == "ASK_NUMBER") {
        reqObj.actionData = {
          message: this.addMessageForm.value.message,
          errorAttempt: this.addMessageForm.value.errorAttempt,
          errorMessage: this.addMessageForm.value.errorMessage,
          validation: {
            hasValidation: this.addMessageForm.value.validationSetting,
          },
          variables: {
            needToUpdate: this.addMessageForm.value.responseVariable
              ? true
              : false,
          },
          customerField: {
            needToUpdate: this.addMessageForm.value.fieldData ? true : false,
          },
          onInvalidResponse: this.addMessageForm.value.onInvalidResponse,
        };

        if (this.addMessageForm.value.responseVariable) {
          reqObj.actionData.variables.name =
            this.addMessageForm.value.responseVariable;
        }

        if (this.addMessageForm.value.fieldData) {
          reqObj.actionData.customerField.fieldData = {
            id: this.addMessageForm.value.fieldData,
          };
        }

        if (this.addMessageForm.value.validationSetting) {
          reqObj.actionData.validation.value = {
            min: this.addMessageForm.value.minValue,
            max: this.addMessageForm.value.maxValue,
          };
        }
      } else if (this.messageType == "ASK_PHONE") {
        reqObj.actionData = {
          message: this.addMessageForm.value.message,
          errorAttempt: this.addMessageForm.value.errorAttempt,
          errorMessage: this.addMessageForm.value.errorMessage,
          validation: {
            hasValidation: this.addMessageForm.value.validationSetting,
          },
          variables: {
            needToUpdate: this.addMessageForm.value.responseVariable
              ? true
              : false,
          },
          customerField: {
            needToUpdate: this.addMessageForm.value.fieldData ? true : false,
          },
          onInvalidResponse: this.addMessageForm.value.onInvalidResponse,
        };

        if (this.addMessageForm.value.responseVariable) {
          reqObj.actionData.variables.name =
            this.addMessageForm.value.responseVariable;
        }

        if (this.addMessageForm.value.fieldData) {
          reqObj.actionData.customerField.fieldData = {
            id: this.addMessageForm.value.fieldData,
          };
        }

        if (this.addMessageForm.value.validationSetting) {
          reqObj.actionData.validation.length = {
            min: this.addMessageForm.value.minValue,
            max: this.addMessageForm.value.maxValue,
          };
        }
      } else if (this.messageType == "ASK_EMAIL") {
        reqObj.actionData = {
          message: this.addMessageForm.value.message,
          errorAttempt: this.addMessageForm.value.errorAttempt,
          errorMessage: this.addMessageForm.value.errorMessage,
          variables: {
            needToUpdate: this.addMessageForm.value.responseVariable
              ? true
              : false,
          },
          customerField: {
            needToUpdate: this.addMessageForm.value.fieldData ? true : false,
          },
          onInvalidResponse: this.addMessageForm.value.onInvalidResponse,
        };

        if (this.addMessageForm.value.fieldData) {
          reqObj.actionData.customerField.fieldData = {
            id: this.addMessageForm.value.fieldData,
          };
        }

        if (this.addMessageForm.value.responseVariable) {
          reqObj.actionData.variables.name =
            this.addMessageForm.value.responseVariable;
        }
      } else if (this.messageType == "ASK_DATE") {
        reqObj.actionData = {
          message: this.addMessageForm.value.message,
          errorAttempt: this.addMessageForm.value.errorAttempt,
          errorMessage: this.addMessageForm.value.errorMessage,
          format: this.addMessageForm.value.dateFormat,
          validation: {
            hasValidation: this.addMessageForm.value.validationSetting,
          },
          variables: {
            needToUpdate: this.addMessageForm.value.responseVariable
              ? true
              : false,
          },
          customerField: {
            needToUpdate: this.addMessageForm.value.fieldData ? true : false,
          },
          onInvalidResponse: this.addMessageForm.value.onInvalidResponse,
        };

        if (this.addMessageForm.value.responseVariable) {
          reqObj.actionData.variables.name =
            this.addMessageForm.value.responseVariable;
        }

        if (this.addMessageForm.value.fieldData) {
          reqObj.actionData.customerField.fieldData = {
            id: this.addMessageForm.value.fieldData,
          };
        }

        if (this.addMessageForm.value.validationSetting) {
          reqObj.actionData.validation.allowedDates = {
            minDate: moment(this.addMessageForm.value.minValue).format(
              this.addMessageForm.value.dateFormat
            ),
            maxDate: moment(this.addMessageForm.value.maxValue).format(
              this.addMessageForm.value.dateFormat
            ),
          };
        }
      } else if (this.messageType == "ASK_URL") {
        reqObj.actionData = {
          message: this.addMessageForm.value.message,
          errorAttempt: this.addMessageForm.value.errorAttempt,
          errorMessage: this.addMessageForm.value.errorMessage,
          variables: {
            needToUpdate: this.addMessageForm.value.responseVariable
              ? true
              : false,
          },
          customerField: {
            needToUpdate: this.addMessageForm.value.fieldData ? true : false,
          },
          onInvalidResponse: this.addMessageForm.value.onInvalidResponse,
        };

        if (this.addMessageForm.value.responseVariable) {
          reqObj.actionData.variables.name =
            this.addMessageForm.value.responseVariable;
        }

        if (this.addMessageForm.value.fieldData) {
          reqObj.actionData.customerField.fieldData = {
            id: this.addMessageForm.value.fieldData,
          };
        }
      } else if (this.messageType == "ASK_LOCATION") {
        reqObj.actionData = {
          message: this.addMessageForm.value.message,
          errorAttempt: this.addMessageForm.value.errorAttempt,
          errorMessage: this.addMessageForm.value.errorMessage,
          variables: {
            needToUpdate: this.addMessageForm.value.responseVariable
              ? true
              : false,
          },
          onInvalidResponse: this.addMessageForm.value.onInvalidResponse,
        };

        if (this.addMessageForm.value.responseVariable) {
          reqObj.actionData.variables.name =
            this.addMessageForm.value.responseVariable;
        }
      } else if (this.messageType == "ASK_FILE") {
        reqObj.actionData = {
          message: this.addMessageForm.value.message,
          fileType: this.addMessageForm.value.fileType,
          errorAttempt: this.addMessageForm.value.errorAttempt,
          errorMessage: this.addMessageForm.value.errorMessage,
          onInvalidResponse: this.addMessageForm.value.onInvalidResponse,
        };
      } else if (this.messageType == "ASK_ORDER") {
        reqObj.actionData = {
          message: this.addMessageForm.value.message,
          errorAttempt: this.addMessageForm.value.errorAttempt,
          errorMessage: this.addMessageForm.value.errorMessage,
          validation: {
            hasValidation: false,
          },
          variables: {
            needToUpdate: this.addMessageForm.value.responseVariable
              ? true
              : false,
          },
          onInvalidResponse: this.addMessageForm.value.onInvalidResponse,
        };

        if (this.addMessageForm.value.responseVariable) {
          reqObj.actionData.variables.name =
            this.addMessageForm.value.responseVariable;
        }
      } else if (this.messageType == "ASK_ADDRESS") {
        reqObj.actionData = {
          bodyText: this.addMessageForm.value.message,
          errorAttempt: this.addMessageForm.value.errorAttempt,
          errorMessage: this.addMessageForm.value.errorMessage,
          variables: {
            needToUpdate: this.addMessageForm.value.responseVariable
              ? true
              : false,
          },
          customerField: {
            needToUpdate: false,
          },
          onInvalidResponse: this.addMessageForm.value.onInvalidResponse,
        };

        if (this.addMessageForm.value.responseVariable) {
          reqObj.actionData.variables.name =
            this.addMessageForm.value.responseVariable;
        }
      } else if (this.messageType == "SELECT_ADDRESS_FROM_THE_LIST") {
        reqObj.actionData = {
          bodyText: this.addMessageForm.value.message,
          errorAttempt: this.addMessageForm.value.errorAttempt,
          errorMessage: this.addMessageForm.value.errorMessage,
          onInvalidResponse: this.addMessageForm.value.onInvalidResponse,

          variables: {
            needToUpdate: this.addMessageForm.value.responseVariable
              ? true
              : false,
          },
          variableData: {
            variable: this.addMessageForm.value.variable,
            path: this.addMessageForm.value.path,
          },
        };

        if (this.addMessageForm.value.responseVariable) {
          reqObj.actionData.variables.name =
            this.addMessageForm.value.responseVariable;
        }
      } else if (this.messageType == "API_CALL") {
        reqObj.actionData = {
          method: this.addMessageForm.value.method,
          url: this.addMessageForm.value.url,
          contentType: this.addMessageForm.value.contentType,
          dataType: this.addMessageForm.value.variableType,
          queryParams: {},
          headers: {},

          variables: {
            needToUpdate: this.addMessageForm.value.responseVariable
              ? true
              : false,
          },
          onSuccess: this.isEdit
            ? this.nodes[this.editIndex].actionData.onSuccess
            : null,
          onFailed: this.isEdit
            ? this.nodes[this.editIndex].actionData.onFailed
            : null,
        };

        try {
          reqObj.actionData.body = JSON?.parse(this.apiBody);
        } catch (error) {
          this.toastr.error("invalid JSON at Body", "", {
            timeOut: 2500,
          });
          this.errorArr.push("invalid JSON");
          this.submitting = false;
        }

        this.apiHeaders.forEach((header: any) => {
          if (!header.key || !header.value) {
            this.errorArr.push("invalid header");
          }
          reqObj.actionData.headers[header.key] = header.value;
        });

        this.apiQueryParams.forEach((header: any) => {
          if (!header.key || !header.value) {
            this.errorArr.push("invalid query");
          }
          reqObj.actionData.queryParams[header.key] = header.value;
        });

        if (this.addMessageForm.value.responseVariable) {
          reqObj.actionData.variables.name =
            this.addMessageForm.value.responseVariable;
        }
      } else if (this.messageType == "TRIGGER_PATH") {
        reqObj.actionData = {
          path: this.addMessageForm.value.triggerPath,
        };
      } else if (this.messageType == "ASSIGN_CONVERSATION") {
        reqObj.actionData = {
          type: this.addMessageForm.value.assigneeType,
        };

        if (this.addMessageForm.value.assigneeType == "User") {
          reqObj.actionData.assigneeId = this.addMessageForm.value.assignee;
        }
      } else if (this.messageType == "IF_STATEMENT") {
        reqObj.actionData = {
          conditionType: this.addMessageForm.value.conditionType,
          conditions: [],
          onSuccess: this.isEdit
            ? this.nodes[this.editIndex]?.actionData?.onSuccess
            : null,
          onFailed: this.isEdit
            ? this.nodes[this.editIndex]?.actionData?.onFailed
            : null,
        };

        this.statementCondition.forEach((statement, index) => {
          if (!statement.operator) {
            this.errorArr.push("operator" + index);
          }
          if (!statement.key) {
            this.errorArr.push("key" + index);
          }
          if (!statement.value) {
            this.errorArr.push("value" + index);
          }
          if (!statement.dataType) {
            this.errorArr.push("dataType" + index);
          }
          var condition = {
            operator: statement.operator,
            key: statement.key,
            value: statement.value,
            dataType: statement.dataType,
          };
          reqObj.actionData.conditions.push(condition);
        });
      } else if (this.messageType == "SEND_TEMPLATE") {
        reqObj.actionData = {
          templateId: this.addMessageForm.value.templateId,
          mediaData: {
            hasMedia: this.templateVariables.mediaData.hasMedia,
          },
          variableData: this.templateVariables.variableTemplate,
        };

        for (
          let m = 0;
          m < this.templateVariables.variableTemplate.length;
          m++
        ) {
          let variable = this.templateVariables.variableTemplate[m];
          for (let n = 0; n < variable.variables.length; n++) {
            if (
              !variable.variables[n].field ||
              !variable.variables[n].fallbackValue
            ) {
              this.errorArr.push("templateVariables");
              break;
            }
          }
        }

        if (this.templateVariables.mediaData.hasMedia) {
          if (!this.mediaId.id) {
            this.errorArr.push("templateMedia");
            this.toastr.error("Please select media file", "", {
              timeOut: 2500,
            });
          }
          reqObj.actionData.mediaData.id = this.mediaId.id;
        }
      }

      if (this.errorArr.length == 0) {
        this.createUpdateNode(reqObj);
        // console.log(reqObj);
        // if (this.isEdit) {
        //   this.nodes = [...this.nodes];
        //   this.testReFormatting();
        // } else {
        //   this.nodes = [...this.nodes, tempNode];
        // }
        // console.log(this.nodes);
        // this.nodesDrawer = false;
      } else {
        this.submitting = false;
      }
    } else {
      Object.values(this.addMessageForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  createUpdateNode(reqObj: any) {
    // console.log(reqObj);
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    var url: string = "";

    if (this.isEdit) {
      url = "flow-actions/update";
    } else {
      url = "flow-actions/create";
    }

    this.submitting = true;
    this.apiService
      .commonPostMethod(environment.url + url, reqObj, options)
      .subscribe(
        (response: any) => {
          console.log(response);
          // this.toastr.success(response.message, "", {
          //   timeOut: 2500,
          // });
          this.message.create("success", response.message);
          if (this.isEdit) {
            this.pathName.isNeedToPublish = true;
            var req = response.data.updatedAction;
            req.label = "label";
            var targetIndex = this.nodes.findIndex((e) => {
              return e.id == req._id;
            });
            const newValue = {
              ...req,
              id: req._id,
            };
            delete newValue._id;
            this.nodes[targetIndex] = newValue;
            this.nodes = [...this.nodes];
            this.testReFormatting();
          } else if (
            this.duplicatePath._id == this.botData.flowId ||
            !this.duplicatePath
          ) {
            this.pathName.isNeedToPublish = true;
            var req = response.data.newAction;
            req.label = "label";
            const newValue = {
              ...req,
              id: req._id,
            };
            delete newValue._id;
            this.nodes = [...this.nodes, newValue];
            if (this.nodes.length == 1) {
              this.updateStartPath(this.nodes[0]);
            }
          }
          this.nodesDrawer = false;
          this.submitting = false;
        },
        (err: any) => {
          this.submitting = false;
          console.log(err);
          if (err?.error?.message) {
            this.toastr.error(err?.error?.message, "", {
              timeOut: 2500,
            });
          } else {
            this.toastr.error(err?.message, "", {
              timeOut: 2500,
            });
          }
        }
      );
  }

  // Drawing line

  isDrawingLine = false;
  lineStartX = 0;
  lineStartY = 0;
  lineEndX = 0;
  lineEndY = 0;

  startDrawingLine(node: any) {
    this.isDrawingLine = true;
    this.lineStartY = node.position.y;
    this.lineStartX = node.position.x;
    this.lineEndY = node.position.y;
    this.lineEndX = node.position.x;
    // this.dragging = true;
    // console.log(node);
    window.addEventListener("mousedown", this.handleMouseDown);
    window.addEventListener("mousemove", this.handleMouseMove);
    window.addEventListener("mouseup", this.handleMouseUp);
  }

  handleMouseDown() {
    this.dragging = false;
    // console.log("mouse down", this.dragging);
  }

  handleMouseMove = (event: MouseEvent) => {
    // console.log("mouse move");
    if (!this.dragging) {
      this.dragging = true;
    }
    // console.log(event);
    // const containerElement = event.currentTarget as HTMLElement;
    const containerRect = this.graphContainerRef.transformationMatrix;
    var pos: any = {
      i: 0,
      j: 0,
    };
    if (this.fullScreen) {
      pos = {
        i: 10,
        j: 0,
      };
    } else {
      pos = {
        i: this.lineLeft,
        j: 45,
      };
    }
    // console.log(containerRect);
    this.lineEndX =
      event.clientX / containerRect.a -
      pos.i / containerRect.a -
      containerRect.e / containerRect.a;
    this.lineEndY =
      event.clientY / containerRect.a -
      pos.j / containerRect.a -
      containerRect.f / containerRect.a;
    // console.log(event.clientX);
    // console.log(containerRect.e);
    // console.log(this.lineEndX);
  };

  handleMouseUp = (event: MouseEvent) => {
    // console.log("mouse up", this.dragging);
    if (this.isDrawingLine && !this.dragging) {
      this.isDrawingLine = false;
      window.removeEventListener("mousedown", this.handleMouseDown);
      window.removeEventListener("mousemove", this.handleMouseMove);
      window.removeEventListener("mouseup", this.handleMouseUp);
    }
    this.dragging = false;
  };

  structureData() {
    this.nodes.forEach((element: any) => {
      // console.log(element);
      var targetIndex = this.links.findIndex((e: any) => {
        return e.source == element.id;
      });

      if (element.actionType == "SEND_BUTTONS") {
        element.actionData.buttons.forEach((button: any) => {
          var btnIndex = this.links.findIndex((e: any) => {
            return e.label == button.title;
          });
          // console.log(button);
          if (btnIndex > -1) {
            button.nextAction = this.links[btnIndex].target;
          }
        });
      } else if (
        element.actionType == "SEND_LIST" &&
        element.actionData.type == "static"
      ) {
        element.actionData.sections.forEach((section: any) => {
          section.rows.forEach((button: any) => {
            var btnIndex = this.links.findIndex((e: any) => {
              return e.label == button.title;
            });
            // console.log(button);
            if (btnIndex > -1) {
              button.nextAction = this.links[btnIndex].target;
            }
          });
        });
      } else if (this.outOptionTypes.indexOf(element.actionType) > -1) {
        if (targetIndex > -1) {
          // console.log(this.links[targetIndex].target);
          element.nextAction = this.links[targetIndex].target;
        }
      }
    });

    console.log(this.nodes);
    // console.log(this.links);
  }

  testReFormatting() {
    // console.log(this.nodes);
    var links: any[] = [];
    this.nodes.forEach((data: any, i: any) => {
      // console.log(data);
      var temp = {};
      if (
        this.outOptionTypes.indexOf(data.actionType) > -1 &&
        data.nextAction
      ) {
        temp = {
          id: "link_" + data.id,
          source: data.id,
          target: data.nextAction,
          label: "",
          sourceType: data.actionType,
        };
        links.push(temp);
        if (data.goBackAction) {
          temp = {
            id: `goBack_${data.goBackAction}nodeId${data.id}`,
            source: data.id,
            target: data.goBackAction,
            label: "go back",
            sourceType: data.actionType,
          };
          links.push(temp);
        }
      } else if (data.actionType == "SEND_BUTTONS") {
        data.actionData.buttons.forEach((e: any, index) => {
          if (e.nextAction) {
            temp = {
              id: "link_" + e.id,
              source: data.id,
              target: e.nextAction,
              label: e.title,
              sourceType: data.actionType,
            };
            links.push(temp);
          }
        });
      } else if (data.actionType == "SEND_LIST") {
        if (data.goBackAction) {
          temp = {
            id: `goBack_${data.goBackAction}nodeId${data.id}`,
            source: data.id,
            target: data.goBackAction,
            label: "go back",
            sourceType: data.actionType,
          };
          links.push(temp);
        }
        data.actionData?.sections?.forEach((section: any) => {
          section.rows.forEach((e: any) => {
            if (e.nextAction) {
              temp = {
                id: "link_" + e.id,
                source: data.id,
                target: e.nextAction,
                label: e.title,
                sourceType: data.actionType,
              };
              links.push(temp);
            }
          });
        });
      } else if (
        data.actionType == "IF_STATEMENT" ||
        data.actionType == "API_CALL"
      ) {
        if (data.actionData.onSuccess) {
          let successLink = {
            id: `link_statement${data.actionData.onSuccess}nodeId${data.id}`,
            source: data.id,
            target: data.actionData.onSuccess,
            label: "On Success",
            sourceType: data.actionType,
          };
          links.push(successLink);
        }
        if (data.actionData.onFailed) {
          let failLink = {
            id: `link_statement${data.actionData.onFailed}nodeId${data.id}`,
            source: data.id,
            target: data.actionData.onFailed,
            label: "On Fail",
            sourceType: data.actionType,
          };
          links.push(failLink);
        }
      }
    });

    // this.links = links;
    this.links = links;
    this.links = [...this.links];
    console.log(this.links);
    this.showChart = true;
  }

  listHeight(resData: any[]) {
    var height: number = 0;
    if (resData) {
      resData.forEach((e, index) => {
        height =
          height +
          (e?.rows ? e.rows.length * 41 : e?.product_items?.length * 0); // product_items changed to zero because temporarily don't wanna show product_items
      });

      // console.log(height);
    }
    return height;
  }

  addButtonArr() {
    // this.addMessageForm.addControl(
    //   `button${this.buttonsList.length + 1}`,
    //   this.fb.control("", Validators.required)
    // );
    // this.addMessageForm.get(`button${this.buttonsList.length + 1}`).reset();

    this.buttonsList.push({ title: "" });

    // console.log(this.buttonsList);

    // console.log(this.addMessageForm.value);
  }

  removeButton(index: number) {
    this.buttonsList.splice(index, 1);
    // if (index == 1) {
    //   this.addMessageForm
    //     .get("button2")
    //     .setValue(this.addMessageForm.value.button3);
    // }
    // this.addMessageForm?.removeControl("button3");
    // this.buttonsList.splice(7, 1);
    // console.log(this.addMessageForm.value);
  }

  addMenu() {
    this.menuList.push({ menu: "", list: [{ title: "", description: "" }] });
  }

  addOption(menuIndex: number) {
    this.menuList[menuIndex].list.push({ title: "", description: "" });
  }

  removeOption(menuIndex: number, listIndex: number) {
    this.menuList[menuIndex].list.splice(listIndex, 1);
  }

  // api header and query params

  addHeader() {
    this.apiHeaders.push({ key: "", value: "" });
  }

  removeHeader(index: number) {
    this.apiHeaders.splice(index, 1);
  }

  addQuery() {
    this.apiQueryParams.push({ key: "", value: "" });
  }

  removeQuery(index: number) {
    this.apiQueryParams.splice(index, 1);
  }

  addStatementArr() {
    this.statementCondition.push({
      operator: "is equals to",
      key: "",
      value: "",
      dataType: "text",
    });
  }

  addProduct() {
    this.productList.push({ menu: "", list: [] });
  }

  removeMenu(index: number) {
    this.menuList.splice(index, 1);
  }

  removeStatement(index: number) {
    this.statementCondition.splice(index, 1);
  }

  removeProduct(index: number) {
    this.productList.splice(index, 1);
  }

  addApiKeys() {
    this.apiBody.push({ key: "", value: "" });
  }

  removeApiKeys(index: number) {
    this.apiBody.splice(index, 1);
  }

  getListCount(type: string) {
    var totalCount: any;
    if (type == "menu") {
      totalCount = this.menuList.reduce(
        (count, item) => count + item.list.length,
        0
      );
    } else {
      totalCount = this.productList.reduce(
        (count, item) => count + item.list.length,
        0
      );
    }

    return totalCount;
  }

  @ViewChild("queryParams", { static: false }) queryParams: ElementRef;
  @ViewChild("headerText", { static: false }) headerText: ElementRef;
  @ViewChild("bodyText", { static: false }) bodyText: ElementRef;
  @ViewChild("messageField", { static: false }) messageField: ElementRef;
  @ViewChild("footerVariable", { static: false }) footerVariable: ElementRef;
  @ViewChild("hederVariable", { static: false }) hederVariable: ElementRef;
  @ViewChild("urlVariable", { static: false }) urlVariable: ElementRef;
  @ViewChild("captionVariable", { static: false }) captionVariable: ElementRef;

  @ViewChild("responseVar", { static: false }) responseVar: any;
  @ViewChild("codemirror", { static: false }) codemirror: any;

  showEditor(value: string) {
    this.codeType = value;
    // this.queryParams.nativeElement.blur();
    // this.headerText.nativeElement.blur();
    this.bodyText.nativeElement.blur();
    this.codeEditor = !this.codeEditor;
    // setTimeout(() => {
    //   this.codemirror.codeMirror.setLineNumbers();
    // }, 500);
  }

  closeEditor() {
    this.codeEditor = false;
    this.codeType = " ";
  }

  getWidth() {
    if (this.divWidth > 0) {
      this.divWidth = 0;
    } else {
      this.divWidth = this.menuDivElement.nativeElement.offsetWidth;
    }
    console.log(this.divWidth);
  }

  // custom media section

  showMediaModal() {
    this.mediaModal = true;
    if (this.messageType == "SEND_IMAGE") {
      this.mediaType = "image";
    } else if (this.messageType == "SEND_VIDEO") {
      this.mediaType = "video";
    } else if (this.messageType == "SEND_AUDIO") {
      this.mediaType = "audio";
    } else if (this.messageType == "SEND_DOCUMENT") {
      this.mediaType = "document";
    } else if (this.messageType == "SEND_BUTTONS") {
      this.mediaType = this.addMessageForm.value.buttonMediaType;
    } else {
      this.mediaType = this.templateVariables.mediaData.type?.toLowerCase();
    }
  }

  closeMediaModal() {
    this.mediaModal = false;
  }

  selectedData(event: any) {
    console.log(event);
    this.mediaId = event[0];
    this.mediaModal = false;
  }

  getAllProducts() {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url +
          "catalogue-item/get?limit=100&page=" +
          this.productsPage,
        {
          availability: "in stock",
          forPlace: "flow-action",
        },
        options
      )
      .subscribe(
        (response: any) => {
          if (this.productsPage == 1) {
            this.allProducts = response.data;
          } else {
            this.allProducts.catalogues = this.allProducts?.catalogues.concat(
              response?.data?.catalogues
            );
          }
          console.log(response);
        },
        (err: any) => {
          console.log(err);
        }
      );
  }

  // get customer fields data

  getCustomerFields() {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url + "customer-field/get?filter=for-show-fields",
        {},
        options
      )
      .subscribe(
        (response: any) => {
          this.customerFields = response.data.customerFields;
          console.log(this.customerFields);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  // get all paths

  getAllPaths() {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url + "flow-paths?page=1&limit=100",
        { flowId: this.botData.botId },
        options
      )
      .subscribe(
        (response: any) => {
          // console.log(response);
          this.allPaths = response.data.paths;
        },
        (err: any) => {
          console.log(err);
        }
      );
  }

  // get all users

  getAllUsers() {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonGetMethod(
        environment.url + "auth/get-all-user-data?projection=name",
        options
      )
      .subscribe(
        (response: any) => {
          this.allUsers = response.data.users;
          // console.log(this.allUsers);
        },
        (err: any) => {
          console.log(err);
        }
      );
  }

  changeMenu(options: any) {
    this.selectedMenu = options;
  }

  formatKeyName() {
    // Replace spaces with underscores
    const inputValue = this.addMessageForm
      .get("responseVariable")
      .value.replaceAll("_", " ")
      .replace(/\s+/g, "_")
      .replace(/[^A-Za-z0-9_]/g, "");

    this.addMessageForm.get("responseVariable").setValue(inputValue);
  }

  // Update Start Path

  updateStartPath(node: any) {
    // console.log(node);
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url + "flow-actions/update-start-path",
        {
          flowActionId: node.id,
          flowPathId: this.botData.flowId,
        },
        options
      )
      .subscribe(
        (response: any) => {
          console.log(response);
          this.alertModal = false;
          this.getAllNodes();
        },
        (err: any) => {
          console.log(err);
        }
      );
  }

  updateLink(req: any) {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url + "flow-actions/update-nodes",
        req,
        options
      )
      .subscribe(
        (response: any) => {
          console.log(response);
          this.pathName.isNeedToPublish = true;
          var req = response.data.updatedAction;
          req.label = "label";
          var targetIndex = this.nodes.findIndex((e) => {
            return e.id == req._id;
          });
          const newValue = {
            ...req,
            id: req._id,
          };
          delete newValue._id;
          this.nodes[targetIndex] = newValue;
          this.nodes = [...this.nodes];
          this.testReFormatting();
        },
        (err: any) => {
          console.log(err);
          if (err?.error?.message) {
            this.toastr.error(err?.error?.message, "", {
              timeOut: 2500,
            });
          } else {
            this.toastr.error(err?.message, "", {
              timeOut: 2500,
            });
          }
        }
      );
  }

  getAllTemplates() {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url +
          "templates/get-all?page=" +
          this.templatePage +
          "&limit=100",
        { filterByName: "", filterByStatus: "approved" },
        options
      )
      .subscribe(
        (response: any) => {
          if (this.templatePage == 1) {
            this.allTemplates = response.data;
          } else {
            // this.allTemplates.pagination = response.data.pagination;
            this.allTemplates.waMsgTemplates =
              this.allTemplates?.waMsgTemplates.concat(
                response?.data?.waMsgTemplates
              );
          }
          if (
            !this.messageTemplateSelected &&
            this.addMessageForm.value.templateId
          ) {
            var targetIndex = this.allTemplates.waMsgTemplates.findIndex(
              (e) => {
                return e._id == this.addMessageForm.value.templateId;
              }
            );

            this.messageTemplateSelected =
              this.allTemplates.waMsgTemplates[targetIndex];
          }
        },
        (error: any) => {
          console.log(error);
        }
      );
  }

  loadMore() {
    if (
      this.allTemplates.pagination.totalCount >
      this.allTemplates.waMsgTemplates.length
    ) {
      this.templatePage++;
      this.getAllTemplates();
    }
  }

  loadProducts() {
    if (
      this.allProducts.pagination.totalCount >
      this.allProducts.catalogues.length
    ) {
      this.productsPage++;
      this.getAllProducts();
    }
  }

  selectTemplate() {
    this.mediaId = null;
    var targetIndex = this.allTemplates.waMsgTemplates.findIndex((e) => {
      return e._id == this.addMessageForm.value.templateId;
    });
    console.log(targetIndex);
    this.messageTemplateSelected =
      this.allTemplates.waMsgTemplates[targetIndex];
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };
    var data = { messageId: this.addMessageForm.value.templateId };
    this.apiService
      .commonPostMethod(
        environment.url + "templates/get-variables-template-data",
        data,
        options
      )
      .subscribe(
        (response: any) => {
          this.templateVariables = response.data;
          console.log(this.templateVariables);
        },
        (error) => {
          console.log(error);
        }
      );

    if (!this.fieldData) {
      this.getFieldData();
    }
  }

  getFieldData() {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url + "customer-field/get?filter=for-template",
        {},
        options
      )
      .subscribe(
        (response: any) => {
          this.fieldData = response.data.customerFields;
          console.log(response);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  getAllVariables(type: string) {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url + "flow-path-variables",
        { flowId: this.botData.botId },
        options
      )
      .subscribe(
        (response: any) => {
          console.log(response);
          this.flowVariables = response.data.allVariables;
          if (type == "new" && this.flowVariables.length > 0) {
            this.addMessageForm
              .get("responseVariable")
              .setValue(this.flowVariables[0].name);
          }
        },
        (err: any) => {
          console.log(err);
        }
      );
  }

  addNewVariable() {
    if (
      this.addMessageForm.value.responseVariable == "new variable" &&
      this.newVariable
    ) {
      let options = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
        },
      };

      var reqObj: any = {
        flowId: this.botData.botId,
        flowPathId: this.botData.flowId,
        actionType: this.messageType,
        name: this.newVariable,
      };

      if (this.messageType == "API_CALL") {
        reqObj.dataType = this.addMessageForm.value.variableType;
      }

      this.apiService
        .commonPostMethod(
          environment.url + "flow-path-variables/create",
          reqObj,
          options
        )
        .subscribe(
          (response: any) => {
            console.log(response);
            this.getAllVariables("new");
            this.newVariable = "";
            this.availVal = "";
          },
          (err: any) => {
            console.log(err);
            this.toastr.error(err?.error?.message, "", {
              timeOut: 2500,
            });
          }
        );
    }
  }

  availVal: string = "";

  detectAvailVariables(event: any) {
    this.availVal = event.target.value;

    var targetIndex = this.flowVariables?.findIndex((v) => {
      return this.flowVariables.name == event.target.value;
    });

    if (targetIndex == -1) {
      const inputValue = event.target.value;
      this.newVariable = inputValue
        .replaceAll("_", " ")
        .replace(/\s+/g, "_")
        .replace(/[^A-Za-z0-9_]/g, "");
      this.responseVar.searchValue = this.newVariable;
    } else {
      this.newVariable = "";
      this.availVal = "";
    }

    console.log(this.newVariable);
  }

  field: any;

  addField(field: string) {
    this.variableSearch = "";
    this.field = field;
  }

  addVariable(variable: any, type: string) {
    console.log(this.field);
    if (this?.addMessageForm?.value?.[this.field]) {
      // const inputElement = this.messageField.nativeElement;
      var inputElement: any;

      switch (this.field) {
        case "message":
        case "bodyText":
          inputElement = this.messageField.nativeElement;
          break;
        case "footerText":
          inputElement = this.footerVariable.nativeElement;
          break;
        case "headerText":
          inputElement = this.hederVariable.nativeElement;
          break;
        case "url":
          inputElement = this.urlVariable.nativeElement;
          break;
        case "caption":
          inputElement = this.captionVariable.nativeElement;
          break;

        default:
          break;
      }

      const cursorPos = inputElement.selectionStart;
      const currentValue = this.addMessageForm.value[this.field];
      const newValue = `${currentValue.slice(0, cursorPos)}${
        "{{" + type + "." + variable + "}}"
      }${currentValue.slice(cursorPos)}`;
      this.addMessageForm.get(this.field).setValue(newValue);
      inputElement.setSelectionRange(cursorPos + 1, cursorPos + 1);
    } else {
      this.addMessageForm
        .get(this.field)
        .setValue("{{" + type + "." + variable + "}}");
    }
  }

  apiIndex: number;
  cursorPos: number;
  apiBodyType: string = "";
  selectId: any;
  templateIndex: number;

  apiVar(index: number, id: string, type: string, templateIndex: number) {
    this.variableSearch = "";
    this.apiIndex = index;
    this.selectId = id;
    this.apiBodyType = type;
    this.templateIndex = templateIndex;
  }

  addApiVar(type: string, value: string) {
    this.variableSearch = "";
    const val: any = document.getElementById(this.selectId);
    this.cursorPos = val.selectionStart;
    const final = `{{${type}.${value}}}`;
    var currentValue: any;

    switch (this.apiBodyType) {
      case "header":
        currentValue = this.apiHeaders[this.apiIndex].value;
        break;
      case "queryParams":
        currentValue = this.apiQueryParams[this.apiIndex].value;
        break;
      case "value":
        currentValue =
          this.templateVariables.variableTemplate[this.templateIndex].variables[
            this.apiIndex
          ].field;
        break;
      case "condition":
        currentValue = this.statementCondition[this.apiIndex].key;
        break;

      default:
        break;
    }

    const newValue = `${currentValue.slice(
      0,
      this.cursorPos
    )}${final}${currentValue.slice(this.cursorPos)}`;

    if (this.apiBodyType == "header") {
      this.apiHeaders[this.apiIndex].value = newValue;
    } else if (this.apiBodyType == "queryParams") {
      this.apiQueryParams[this.apiIndex].value = newValue;
    } else if (this.apiBodyType == "value") {
      this.templateVariables.variableTemplate[this.templateIndex].variables[
        this.apiIndex
      ].field = newValue;
    } else if (this.apiBodyType == "condition") {
      this.statementCondition[this.apiIndex].key = newValue;
    }
  }

  showTemplateModal(components: any) {
    console.log(components);
    this.templateComponents = components;
    this.templateModal = true;
  }

  closeTemplateModal() {
    this.templateModal = false;
    // this.templateComponents = {};
  }

  publishPath() {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url + "flow-paths/publish",
        { flowPathId: this.botData.flowId },
        options
      )
      .subscribe(
        (response: any) => {
          console.log(response);
          this.pathName.isNeedToPublish = false;
          // this.toastr.success(response.message, "", {
          //   timeOut: 2500,
          // });
          this.message.create("success", response.message);
        },
        (err: any) => {
          console.log(err);
          if (err?.error?.message) {
            this.toastr.error(err?.error?.message, "", {
              timeOut: 2500,
            });
          } else {
            this.toastr.error(err?.message, "", {
              timeOut: 2500,
            });
          }
        }
      );
  }

  // emoji and text editor options

  showEmoji(field: string) {
    this.field = field;
    this.emojiVisible = !this.emojiVisible;
  }

  bodyTextFocus() {
    this.emojiVisible = false;
    this.selectedStyle = "";
  }

  mouseUp() {
    this.selectedStyle = "";
  }

  onTextSelect(field: string) {
    this.selectedStyle = "";
    var editorElement: any;
    this.field = field;
    switch (field) {
      case "message":
      case "bodyText":
        editorElement = this.messageField.nativeElement;
        break;
      case "footerText":
        editorElement = this.footerVariable.nativeElement;
        break;
      case "headerText":
        editorElement = this.hederVariable.nativeElement;
        break;
      case "url":
        editorElement = this.urlVariable.nativeElement;
        break;
      case "caption":
        editorElement = this.captionVariable.nativeElement;
        break;

      default:
        break;
    }
    const selectedText = editorElement.value.substring(
      editorElement.selectionStart,
      editorElement.selectionEnd
    );

    const boldRegex = /^\*(.*?)\*$/;
    const strikeRegex = /^~(.*)~$/;
    const italicRegex = /^_(.*)_$/;

    if (boldRegex.test(selectedText)) {
      this.selectedStyle = "Bold";
    }

    if (strikeRegex.test(selectedText)) {
      this.selectedStyle = "Strike";
    }

    if (italicRegex.test(selectedText)) {
      this.selectedStyle = "Italic";
    }
  }

  editText(style: any, field: string) {
    var editorElement: any;
    this.field = field;
    switch (field) {
      case "message":
      case "bodyText":
        editorElement = this.messageField.nativeElement;
        break;
      case "footerText":
        editorElement = this.footerVariable.nativeElement;
        break;
      case "headerText":
        editorElement = this.hederVariable.nativeElement;
        break;
      case "url":
        editorElement = this.urlVariable.nativeElement;
        break;
      case "caption":
        editorElement = this.captionVariable.nativeElement;
        break;

      default:
        break;
    }

    const selectedText = editorElement.value.substring(
      editorElement.selectionStart,
      editorElement.selectionEnd
    );
    const boldRegex = /^\*(.*?)\*$/;
    const strikeRegex = /^~(.*)~$/;
    const italicRegex = /^_(.*)_$/;

    if (style == "bold") {
      if (!boldRegex.test(selectedText)) {
        this.selectedStyle = "Bold";
        const newValue = `${editorElement.value.substring(
          0,
          editorElement.selectionStart
        )}*${selectedText}*${editorElement.value.substring(
          editorElement.selectionEnd
        )}`;
        // editorElement.value = newValue;
        this.addMessageForm.get(field).setValue(newValue);
      } else {
        this.selectedStyle = "";
        const newValue = `${editorElement.value.substring(
          0,
          editorElement.selectionStart
        )}${selectedText.substring(
          1,
          selectedText.length - 1
        )}${editorElement.value.substring(editorElement.selectionEnd)}`;
        // editorElement.value = newValue;
        this.addMessageForm.get(field).setValue(newValue);
      }
    }

    if (style == "italic") {
      if (!italicRegex.test(selectedText)) {
        this.selectedStyle = "Italic";
        const newValue = `${editorElement.value.substring(
          0,
          editorElement.selectionStart
        )}_${selectedText}_${editorElement.value.substring(
          editorElement.selectionEnd
        )}`;
        // editorElement.value = newValue;
        this.addMessageForm.get(field).setValue(newValue);
      } else {
        this.selectedStyle = "";
        const newValue = `${editorElement.value.substring(
          0,
          editorElement.selectionStart
        )}${selectedText.substring(
          1,
          selectedText.length - 1
        )}${editorElement.value.substring(editorElement.selectionEnd)}`;
        // editorElement.value = newValue;
        this.addMessageForm.get(field).setValue(newValue);
      }
    }

    if (style == "strikethrough") {
      if (!strikeRegex.test(selectedText)) {
        this.selectedStyle = "Strike";
        const newValue = `${editorElement.value.substring(
          0,
          editorElement.selectionStart
        )}~${selectedText}~${editorElement.value.substring(
          editorElement.selectionEnd
        )}`;
        // editorElement.value = newValue;
        this.addMessageForm.get(field).setValue(newValue);
      } else {
        this.selectedStyle = "";
        const newValue = `${editorElement.value.substring(
          0,
          editorElement.selectionStart
        )}${selectedText.substring(
          1,
          selectedText.length - 1
        )}${editorElement.value.substring(editorElement.selectionEnd)}`;
        // editorElement.value = newValue;
        this.addMessageForm.get(field).setValue(newValue);
      }
    }
  }

  addEmoji(selected: any, field: string) {
    // console.log(selected.emoji.native);
    var editorElement: any;
    this.field = field;
    switch (field) {
      case "message":
      case "bodyText":
        editorElement = this.messageField.nativeElement;
        break;
      case "footerText":
        editorElement = this.footerVariable.nativeElement;
        break;
      case "headerText":
        editorElement = this.hederVariable.nativeElement;
        break;
      case "url":
        editorElement = this.urlVariable.nativeElement;
        break;
      case "caption":
        editorElement = this.captionVariable.nativeElement;
        break;

      default:
        break;
    }

    const currentValue = editorElement.value;
    const selectionStart = editorElement.selectionStart;
    const selectionEnd = editorElement.selectionEnd;

    const newValue =
      currentValue.substring(0, selectionStart) +
      selected.emoji.native +
      currentValue.substring(selectionEnd);

    editorElement.value = newValue;
    this.addMessageForm.get(field).setValue(newValue);
    // editorElement.focus();

    const newCursorPosition = selectionStart + selected.emoji.native.length;
    editorElement.setSelectionRange(newCursorPosition, newCursorPosition);
  }

  format_text(text: any) {
    return text
      .replace(/(?:\*)(?:(?!\s))((?:(?!\*|\n).)+)(?:\*)/g, "<b>$1</b>")
      .replace(/(?:_)(?:(?!\s))((?:(?!\n|_).)+)(?:_)/g, "<i>$1</i>")
      .replace(/(?:~)(?:(?!\s))((?:(?!\n|~).)+)(?:~)/g, "<s>$1</s>")
      .replace(/(?:--)(?:(?!\s))((?:(?!\n|--).)+)(?:--)/g, "<u>$1</u>")
      .replace(/(?:```)(?:(?!\s))((?:(?!\n|```).)+)(?:```)/g, "<tt>$1</tt>");
  }

  // Add variable to code editor

  addVarEditor(name: string, type: string) {
    const cursor = this.codemirror.codeMirror.getCursor();

    const variableText = `"{{${type}.${name}}}"`;
    this.apiBody =
      this.apiBody.slice(0, this.codemirror.codeMirror.indexFromPos(cursor)) +
      variableText +
      this.apiBody.slice(this.codemirror.codeMirror.indexFromPos(cursor));
  }

  mediaTypeChange() {
    this.mediaId = null;
  }

  openSearch(search: boolean) {
    this.showSearch = search;
    this.searchText = "";
  }

  enableFullScreen() {
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    }
    if (document.exitFullscreen) {
      this.document.exitFullscreen();
    }
    // this.message.info("Press ESC to exit full screen", {
    //   nzDuration: 4000,
    // });
    // this.fullScreen = true;
    // setTimeout(() => {
    //   this.getWidthByClassName();
    // }, 500);
  }

  // Listen to key down event to detect esc button click
  @HostListener("window:keydown", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent) {
    // console.log(event);
    if (event.key === "Escape") {
      this.fullScreen = false;
      // setTimeout(() => {
      //   this.getWidthByClassName();
      // }, 500);
    }
  }

  getWidthByClassName() {
    const element =
      this.elementRef.nativeElement.querySelector(`.chart-container`);
    if (element) {
      console.log(element.offsetWidth);
      document.documentElement.style.setProperty(
        "--minimap_x",
        `${element.offsetWidth - 100}px`
      );
    }
  }

  ngOnDestroy(): void {
    // console.log("api key destroy..");
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}

// Never forget to add key label : 'label' for every node else nodes will not displayed properly
// getCurvePath(link: any): string {
//   return `M620,210.83333333333334L611.6666666666666,214.86111111111111C603.3333333333334,218.8888888888889,586.6666666666666,226.94444444444446,553.3333333333334,230.9722222222222C520,235,470,235,420,235C370,235,320,235,286.6666666666667,230.9722222222222C253.33333333333334,226.94444444444446,236.66666666666666,218.8888888888889,228.33333333333334,214.86111111111111L220,210.83333333333334`;
// }

// DRAWING LINE NUMBER TO DIVIDE
// FOR GRAPH IN LEFT X-15 Y-45
// FOR GRAPH IN RIGHT X-255 Y-45
// FOR GRAPH FULL SCREEN X-10 Y-0
