import { Injectable } from "@angular/core";
import { Workbook } from "exceljs";
import * as fs from "file-saver";

@Injectable({
  providedIn: "root",
})
export class ExportExcelService {
  constructor() {}

  exportExcel(excelData: any, filename: string) {
    //Title, Header & Data
    // const title = excelData.title;
    const header = excelData.headers;
    const data = excelData.data;

    //Create a workbook with a worksheet
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("sampleFile");

    //Adding Header Row
    let headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell, number) => {});

    // Adding Data
    data?.forEach((d) => {
      let row = worksheet.addRow(d);
    });

    // Iterate over all worksheets
    workbook.eachSheet((worksheet, sheetId) => {
      // Iterate over all rows in the worksheet
      worksheet.eachRow((row, rowIndex) => {
        // Iterate over all cells in the row
        row.eachCell((cell, colIndex) => {
          // Set the format of the cell to text
          cell.numFmt = "@";
        });
      });
    });

    //Generate & Save Excel File
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(blob, filename + ".xlsx");
    });
  }
}
