import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import { DagreNodesOnlyLayout, Layout } from "@swimlane/ngx-graph";
// import { stepRound } from "../../bot/chat-bot/customStepCurved";
import { NavService } from "src/app/services/nav.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { CommonApiService } from "src/app/services/common-api.service";
import { environment } from "src/environments/environment";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import { NzMessageService } from "ng-zorro-antd/message";
import * as shape from "d3-shape";
import { timeZone } from "../../dashboard/chat/shared/messageData";
import * as moment from "moment";
import { DOCUMENT } from "@angular/common";

@Component({
  selector: "app-flow-builder",
  templateUrl: "./flow-builder.component.html",
  styleUrls: ["./flow-builder.component.css"],
})
export class FlowBuilderComponent implements OnInit {
  codeMirrorOptions: any = {
    theme: "dracula",
    mode: "application/ld+json",
    lineNumbers: true,
    lineWrapping: true,
    foldGutter: true,
    gutters: [
      "CodeMirror-linenumbers",
      "CodeMirror-foldgutter",
      "CodeMirror-lint-markers",
    ],
    autoCloseBrackets: true,
    matchBrackets: true,
    lint: true,
  };

  flowData: any = {
    name: "First Flow",
  };

  flowDetail: any;

  imageUrl: string = environment.imageUrl;

  public layout: Layout = new DagreNodesOnlyLayout();
  public curve: any = shape.curveLinear;

  links: Array<any> = [];

  nodes: any = [];

  allApps: Array<any> = [];

  nodeMenu: any = [
    {
      category: "Operations",
      logo: "../../../../assets/images/icons/Bot_page/if_statement.svg",
      options: [
        {
          name: "Add Delay",
          type: "DELAY",
          icon: "../../../../assets/images/icons/Bot_page/send_list.png",
        },
        {
          name: "API Call",
          type: "API_CALL",
          icon: "../../../../assets/images/icons/Bot_page/api_call.svg",
        },
      ],
    },
    {
      category: "Actions",
      logo: "../../../../assets/images/icons/Bot_page/send_messages.png",
      options: [
        {
          name: "Send Template",
          type: "SEND_WA_TEMPLATE",
          icon: "../../../../assets/images/icons/Bot_page/send_template.svg",
        },
      ],
    },
  ];

  outOptionTypes: Array<any> = ["TRIGGER_ACTION", "DELAY", "SEND_WA_TEMPLATE"];

  // forms section

  appForm: FormGroup;
  actionForm: FormGroup;
  appFormDrawer: boolean = false;
  appSelected: any;

  currentUrl: string = "";
  formMenu: string = "";

  drawerWidth: string = "45%";
  submitting: boolean = false;

  // templates section

  allTemplates: any = [];
  templatePage: number = 1;
  messageTemplateSelected: any;
  templateVariables: any;
  fieldData: any;

  // template media section

  mediaModal: boolean = false;
  mediaType: string = "";
  mediaId: any = "";
  submitted: boolean = false;

  // Add links section

  source: any = "";
  target: string = "";
  label: any = "";

  // connections

  connectionsData: any = [];
  variableSearch: string = "";
  webhookId: string = "6512e01da69530ddb2573a5c";

  messageType: string = "";

  weekDays: any = [];

  timeZone: any = timeZone;

  editData: any;

  allFormats: any = [
    "yyyy-MM-dd",
    "MM/dd/yyyy",
    "dd/MM/yyyy",
    "yyyy-MM-dd HH:mm:ss",
    "MMM dd, yyyy",
    "dd/MM/yyyy HH:mm:ss",
    "d MMM YYYY",
    "MMM dd YYYY",
    "dd MMM YYYY",
  ];

  fullScreen: boolean = false;
  showSearch: boolean = false;
  searchText: string = "";

  lineLeft: number = 255;

  drawerTitle: string = "";

  // API CALL

  apiBody: any = JSON.stringify({}, null, 2);
  apiHeaders: any = [{ key: "", value: "" }];
  apiQueryParams: any = [{ key: "", value: "" }];

  apiMethods: Array<string> = ["GET", "POST", "PUT", "PATCH", "DELETE"];
  emojiVisible: boolean = false;
  selectedStyle: string = "";
  set = "twitter";
  field: any;
  menuList: Array<any> = [{ menu: "", list: [{ title: "", description: "" }] }];

  codeEditor: boolean = false;
  codeType: string = "";

  apiVariableType: Array<string> = [
    "text",
    "number",
    "phone-number",
    "boolean",
    "object",
    "array",
  ];

  flowVariables: any;
  newVariable: any = "";
  customerFields: any;

  constructor(
    private navService: NavService,
    private fb: FormBuilder,
    private router: Router,
    private apiService: CommonApiService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private message: NzMessageService,
    private elementRef: ElementRef,
    @Inject(DOCUMENT) private document: any
  ) {}

  @ViewChild("graphContainer", { static: false }) graphContainerRef!: any;
  @ViewChild("queryParams", { static: false }) queryParams: ElementRef;
  @ViewChild("headerText", { static: false }) headerText: ElementRef;
  @ViewChild("bodyText", { static: false }) bodyText: ElementRef;
  @ViewChild("messageField", { static: false }) messageField: ElementRef;
  @ViewChild("footerVariable", { static: false }) footerVariable: ElementRef;
  @ViewChild("hederVariable", { static: false }) hederVariable: ElementRef;
  @ViewChild("urlVariable", { static: false }) urlVariable: ElementRef;
  @ViewChild("captionVariable", { static: false }) captionVariable: ElementRef;

  @ViewChild("responseVar", { static: false }) responseVar: any;
  @ViewChild("codemirror", { static: false }) codemirror: any;

  ngOnInit(): void {
    this.currentUrl = this.router.url;
    this.route.params.subscribe((e) => {
      console.log(e);
      if (e) {
        this.flowData = e;
      }
    });

    if (this.router.url.includes("/work-flow/create/")) {
      this.drawerTitle = "Choose App";
      this.getAllApps();
      this.appFormDrawer = true;
      this.drawerWidth = "65%";
      this.formMenu = "apps";
      this.appForm = this.fb.group({
        connection: ["", Validators.required],
        event: ["", Validators.required],
        variableName: ["response", Validators.required],
      });
    } else {
      this.drawerTitle = "Select Template";
      this.templatePage = 1;
      this.getAllTemplates();
      this.actionForm = this.fb.group({
        actionName: ["", Validators.required],
      });
    }

    this.getFlowDetails();
    this.navService.getMainUserData();
  }

  getAllApps() {
    this.spinner.show();
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(environment.url + "apps/get-all", {}, options)
      .subscribe(
        (response: any) => {
          // console.log(response);
          this.allApps = response?.data.apps;
          // this.totalCount = response?.data?.totalCount;
          this.spinner.hide();
        },
        (err: any) => {
          console.log(err);
          this.spinner.hide();
        }
      );
  }

  getFlowDetails() {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url + "work-flow/flow-action-builder/get-all",
        {
          workFlowId: this.flowData.flowId,
        },
        options
      )
      .subscribe(
        (response: any) => {
          console.log(response);
          this.flowDetail = response?.data;
          if (
            this.router.url.includes("/work-flow/builder/") &&
            response.data.workFlow.intents.length == 0
          ) {
            this.router.navigate(["/work-flow/create", this.flowData.flowId]);
          } else if (this.router.url.includes("/work-flow/builder/")) {
            const modifiedData = response.data.flowActions.map((item) => {
              const { _id, ...rest } = item;
              return { id: _id, label: "label", ...rest };
            });

            this.nodes = modifiedData;
            this.testReFormatting();
            // this.getWidthByClassName();
          }
          // console.log(this.nodes);
        },
        (error: any) => {
          console.log(error);
          this.spinner.hide();
          if (
            error?.error?.message == "jwt expired" ||
            error?.error?.message == "jwt must be provided" ||
            error?.error?.message == "jwt malformed" ||
            error?.error?.message == "invalid signature" ||
            error?.error?.message == "Given token user does not exist." ||
            error?.error?.message == "invalid token"
          ) {
            this.navService.tokenExpired();
          }
        }
      );
  }

  createUpdateFlow() {
    if (this.appForm.valid) {
      this.submitting = true;
      console.log(this.appForm);
      let options = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
        },
      };

      var request: any = {
        workFlowId: this.flowData.flowId,
        appId: this.appSelected._id,
        connectionId: this.appForm.value.connection,
        variableName: this.appForm.value.variableName,
      };

      if (this.appSelected._id != this.webhookId) {
        request.eventId = this.appForm.value?.event?._id;
        request.eventName = this.appForm.value?.event?.topic;
      } else {
        request.eventId = this.connectionsData[this.getIndex()].events[0]._id;
        request.eventName = "Generic Webhook";
      }

      this.apiService
        .commonPostMethod(
          environment.url + "work-flow/update",
          request,
          options
        )
        .subscribe(
          (response: any) => {
            this.submitting = false;
            console.log(response);
            this.toastr.success(response?.message, "", {
              timeOut: 2500,
            });
            this.router.navigate(["/work-flow/builder", this.flowData.flowId]);
          },
          (err: any) => {
            console.log(err);
            this.submitting = false;
          }
        );
      // this.router.navigate(["/work-flow/builder", "641b00ab990885c232cdd4af"]);
    } else {
      Object.values(this.appForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  showForm(type: any, data: any, edit: boolean) {
    this.drawerTitle = edit ? "Action Information" : type;
    console.log(data);
    this.editData = data;
    this.submitted = false;
    this.templateVariables = null;
    this.messageTemplateSelected = null;
    this.mediaModal = false;
    this.mediaType = "";
    this.removeControls();
    if (!this.flowVariables || this.flowVariables?.length == 0) {
      this.getAllVariables("");
    }
    if (!this.customerFields) {
      this.getCustomerFields();
    }
    if (this.router.url.includes("/work-flow/builder/")) {
      this.actionForm.get("actionName").setValue(data.actionName);
      if (type == "SEND_WA_TEMPLATE") {
        this.actionForm.addControl(
          "templateId",
          this.fb.control(
            edit ? data.actionData?.templateId?._id : "",
            Validators.required
          )
        );

        this.actionForm.addControl(
          "contactNo",
          this.fb.control(edit ? data.actionData?.to : "", Validators.required)
        );

        this.actionForm.addControl(
          "customerName",
          this.fb.control(
            edit ? data.actionData?.name : "",
            Validators.required
          )
        );

        if (!this.allTemplates || edit) {
          this.templatePage = 1;
          this.getAllTemplates();
        }

        if (edit) {
          if (!this.fieldData) {
            this.getFieldData();
          }

          this.templateVariables = {
            mediaData: data.actionData?.mediaData,
            variableTemplate: data.actionData?.variableData,
          };
          this.mediaId = { id: data.actionData.mediaData.id };
        }
      } else if (type == "DELAY") {
        this.actionForm.addControl(
          "delayType",
          this.fb.control(
            edit ? data.actionData?.delayType : "delay-for",
            Validators.required
          )
        );

        if (edit) {
          this.actionForm.addControl(
            "inputType",
            this.fb.control(
              moment(data.actionData?.delayUntil?.value).isValid
                ? "datepicker"
                : "variable"
            )
          );
        } else {
          this.actionForm.addControl(
            "inputType",
            this.fb.control("datepicker")
          );
        }

        this.actionForm.addControl(
          "advanceOption",
          this.fb.control(edit ? true : false)
        );

        this.actionForm.addControl(
          "resumeWorkflow",
          this.fb.control(
            edit ? data.actionData?.resumeWorkFlow : "immediately"
          )
        );

        this.actionForm.addControl(
          "timeZone",
          this.fb.control(
            edit ? data.actionData?.timeZone : "Asia/Kolkata",
            Validators.required
          )
        );

        if (edit) {
          for (let i = 0; i < this.weekDays.length; i++) {
            this.weekDays[i].action = data.actionData.performActionDays[i];
          }
        }

        this.delayTypeChange(data);
        this.flowChange(data);
      } else if (type == "API_CALL") {
        this.actionForm.addControl(
          "variableType",
          this.fb.control(edit ? data.actionData.dataType : "array")
        );

        this.actionForm.addControl(
          "method",
          this.fb.control(
            edit ? data.actionData.method : "POST",
            Validators.required
          )
        );
        this.actionForm.addControl(
          "url",
          this.fb.control(edit ? data.actionData.url : "", Validators.required)
        );

        this.actionForm.addControl(
          "contentType",
          this.fb.control(
            edit ? data.actionData.contentType : "application/json",
            Validators.required
          )
        );

        if (edit) {
          // this.apiQueryParams = JSON.stringify(data.actionData.queryParams);
          // this.apiHeaders = JSON.stringify(data.actionData.headers);
          for (const key in data.actionData.headers) {
            if (data.actionData.headers.hasOwnProperty(key)) {
              this.apiHeaders.push({
                key: key,
                value: data.actionData.headers[key],
              });
            }
          }
          for (const key in data.actionData.queryParams) {
            if (data.actionData.queryParams.hasOwnProperty(key)) {
              this.apiQueryParams.push({
                key: key,
                value: data.actionData.queryParams[key],
              });
            }
          }
          this.apiBody = data.actionData.body
            ? JSON.stringify(data.actionData.body, null, 2)
            : JSON.stringify({});
        }

        this.actionForm.addControl(
          "responseVariable",
          this.fb.control(
            edit && data.actionData?.variables?.name
              ? data.actionData?.variables?.name
              : "",
            Validators.maxLength(200)
          )
        );
      }
    } else {
      this.appForm.reset();
      this.appForm.get("variableName").setValue("response");
    }
    this.messageType = type;
    this.appFormDrawer = true;
  }

  delayTypeChange(data) {
    if (this.actionForm.value.delayType == "delay-for") {
      // console.log("in - for");
      this.actionForm.addControl(
        "delayCount",
        this.fb.control(
          this.editData?.actionData?.delayFor?.count
            ? this.editData?.actionData?.delayFor.count
            : "",
          Validators.required
        )
      );

      this.actionForm.addControl(
        "delayTiming",
        this.fb.control(
          this.editData?.actionData?.delayFor?.timePeriod
            ? this.editData?.actionData?.delayFor.timePeriod
            : "",
          Validators.required
        )
      );

      this.actionForm?.removeControl("delayValue");
      this.actionForm?.removeControl("delayFormat");
    } else {
      // console.log("in - until");
      this.actionForm?.removeControl("delayCount");
      this.actionForm?.removeControl("delayTiming");

      this.actionForm.addControl(
        "delayValue",
        this.fb.control(
          this.editData?.actionData?.delayUntil
            ? this.editData?.actionData?.delayUntil.value
            : "",
          Validators.required
        )
      );

      // this.actionForm.addControl(
      //   "delayFormat",
      //   this.fb.control(
      //     this.editData?.actionData?.delayUntil
      //       ? this.editData?.actionData?.delayUntil.format
      //       : "",
      //     Validators.required
      //   )
      // );
    }
  }

  flowChange(data: any) {
    // console.log(this.editData);
    if (this.actionForm.value.resumeWorkflow == "between") {
      this.actionForm.addControl(
        "fromTime",
        this.fb.control(
          this.editData.actionData?.ifResumeWorkFlowBetween?.fromTime
            ? moment(
                this.editData.actionData.ifResumeWorkFlowBetween.fromTime,
                "hh:mmA"
              ).toDate()
            : null,
          Validators.required
        )
      );

      this.actionForm.addControl(
        "toTime",
        this.fb.control(
          this.editData.actionData?.ifResumeWorkFlowBetween?.toTime
            ? moment(
                this.editData.actionData.ifResumeWorkFlowBetween.toTime,
                "hh:mmA"
              ).toDate()
            : null,
          Validators.required
        )
      );
    } else {
      this.actionForm?.removeControl("fromTime");
      this.actionForm?.removeControl("toTime");
    }

    // console.log(this.actionForm.value);
  }

  removeControls() {
    this.messageType = "";
    this.actionForm.reset();
    this.actionForm?.removeControl("templateId");
    this.actionForm?.removeControl("contactNo");
    this.actionForm?.removeControl("customerName");

    this.actionForm?.removeControl("delayType");
    this.actionForm?.removeControl("advanceOption");
    this.actionForm?.removeControl("resumeWorkflow");
    this.actionForm?.removeControl("timeZone");

    this.actionForm?.removeControl("delayCount");
    this.actionForm?.removeControl("delayTiming");

    this.actionForm?.removeControl("fromTime");
    this.actionForm?.removeControl("toTime");
    this.actionForm?.removeControl("delayValue");
    this.actionForm?.removeControl("delayFormat");

    this.actionForm?.removeControl("inputType");

    this.actionForm?.removeControl("variableType");
    this.actionForm?.removeControl("method");
    this.actionForm?.removeControl("url");
    this.actionForm?.removeControl("contentType");
    this.actionForm?.removeControl("responseVariable");

    this.apiQueryParams = [];
    this.apiHeaders = [];
    this.apiBody = JSON.stringify({});

    this.weekDays = [
      { day: "Sun", action: false },
      { day: "Mon", action: true },
      { day: "Tue", action: true },
      { day: "Wed", action: true },
      { day: "Thu", action: true },
      { day: "Fri", action: true },
      { day: "Sat", action: false },
    ];
  }

  selectApp(appSelected: any) {
    this.appSelected = appSelected;
    if (this.appSelected._id == this.webhookId) {
      this.appForm.removeControl("event");
    }
    this.spinner.show();
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url + "apps-connections/get-by-app-id",
        { appId: appSelected._id },
        options
      )
      .subscribe(
        (response: any) => {
          console.log(response);
          this.connectionsData = response?.data.myConnections;
          this.appForm.reset();
          this.appForm.get("connection").setValue(this.connectionsData[0]._id);
          this.appForm.get("variableName").setValue("response");
          this.formMenu = "appForm";
          this.drawerWidth = "50%";
          this.spinner.hide();
        },
        (err: any) => {
          console.log(err);
          this.spinner.hide();
        }
      );
  }

  closeAppFormDrawer() {
    if (this.router.url.includes("/work-flow/builder/")) {
      this.appFormDrawer = false;
    } else {
      this.router.navigate(["/work-flow"]);
    }
  }

  // Template get selection section

  selectTemplate() {
    this.mediaId = null;
    var targetIndex = this.allTemplates.waMsgTemplates.findIndex((e) => {
      return e._id == this.actionForm.value.templateId;
    });
    // console.log(targetIndex);
    this.messageTemplateSelected =
      this.allTemplates.waMsgTemplates[targetIndex];
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };
    var data = { messageId: this.actionForm.value.templateId };
    this.apiService
      .commonPostMethod(
        environment.url + "templates/get-variables-template-data",
        data,
        options
      )
      .subscribe(
        (response: any) => {
          this.templateVariables = response.data;
          // console.log(this.templateVariables);
        },
        (error) => {
          console.log(error);
        }
      );

    if (!this.fieldData) {
      this.getFieldData();
    }
  }

  getFieldData() {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url + "customer-field/get?filter=for-template",
        {},
        options
      )
      .subscribe(
        (response: any) => {
          this.fieldData = response.data.customerFields;
          console.log(response);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  getAllTemplates() {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url +
          "templates/get-all?page=" +
          this.templatePage +
          "&limit=100",
        { filterByName: "", filterByStatus: "approved" },
        options
      )
      .subscribe(
        (response: any) => {
          if (this.templatePage == 1) {
            this.allTemplates = response.data;
          } else {
            // this.allTemplates.pagination = response.data.pagination;
            this.allTemplates.waMsgTemplates =
              this.allTemplates?.waMsgTemplates.concat(
                response?.data?.waMsgTemplates
              );
          }
          if (
            !this.messageTemplateSelected &&
            this.actionForm.value.templateId
          ) {
            var targetIndex = this.allTemplates.waMsgTemplates.findIndex(
              (e) => {
                return e._id == this.actionForm.value.templateId;
              }
            );

            this.messageTemplateSelected =
              this.allTemplates.waMsgTemplates[targetIndex];
          }
        },
        (error: any) => {
          console.log(error);
        }
      );
  }

  loadMore() {
    if (
      this.allTemplates.pagination.totalCount >
      this.allTemplates.waMsgTemplates.length
    ) {
      this.templatePage++;
      this.getAllTemplates();
    }
  }

  // template media selection modal

  showMediaModal() {
    this.mediaType = this.templateVariables.mediaData.type?.toLowerCase();
    // console.log(this.mediaType);
    this.mediaModal = true;
  }

  closeMediaModal() {
    this.mediaType = "";
    this.mediaModal = false;
  }

  selectedData(event: any) {
    // console.log(event);
    this.mediaId = event[0];
    this.mediaModal = false;
  }

  // add action to trigger

  addAction() {
    console.log(this.actionForm.value);
    var errorArr = [];
    this.submitted = true;

    if (this.messageType == "DELAY" && !this.actionForm.value.advanceOption) {
      this.actionForm.get("resumeWorkflow").setValue("immediately");
      this.flowChange("");
    }

    if (this.actionForm.valid) {
      console.log(this.actionForm.value);
      var reqObj: any = {
        actionName: this.actionForm.value.actionName,
        actionType: this.messageType,
        workFlowId: this.flowData.flowId,
        actionData: {},
      };

      if (this.messageType == "SEND_WA_TEMPLATE") {
        for (
          let m = 0;
          m < this.templateVariables.variableTemplate.length;
          m++
        ) {
          let variable = this.templateVariables.variableTemplate[m];
          for (let n = 0; n < variable.variables.length; n++) {
            if (
              !variable.variables[n].field ||
              !variable.variables[n].fallbackValue
            ) {
              errorArr.push("templateVariables");
              break;
            }
          }
        }

        reqObj.actionData = {
          templateId: this.actionForm.value.templateId,
          to: this.actionForm.value.contactNo,
          name: this.actionForm.value.customerName,
          mediaData: {
            hasMedia: this.templateVariables.mediaData.hasMedia,
          },
          variableData: this.templateVariables.variableTemplate,
        };

        if (this.templateVariables.mediaData.hasMedia) {
          if (!this.mediaId?.id) {
            errorArr.push("templateMedia");
            this.toastr.error("Please select media file", "", {
              timeOut: 2500,
            });
          } else {
            reqObj.actionData.mediaData.id = this.mediaId?.id;
          }
        }
      } else if (this.messageType == "DELAY") {
        reqObj.actionData = {
          delayType: this.actionForm.value.delayType,
          timeZone: this.actionForm.value.timeZone,
          performActionDays: this.weekDays.map((item) => item.action),
        };

        if (this.actionForm.value.delayType == "delay-for") {
          reqObj.actionData.delayFor = {
            count: this.actionForm.value.delayCount,
            timePeriod: this.actionForm.value.delayTiming,
          };
        } else {
          reqObj.actionData.delayUntil = {
            value: this.actionForm.value.delayValue,
            // format: this.actionForm.value.delayFormat,
          };
        }

        if (this.actionForm.value.advanceOption) {
          reqObj.actionData.resumeWorkFlow =
            this.actionForm.value.resumeWorkflow;
          if (this.actionForm.value.resumeWorkflow == "between") {
            reqObj.actionData.ifResumeWorkFlowBetween = {
              fromTime: moment(this.actionForm.value.fromTime).format("hh:mmA"),
              toTime: moment(this.actionForm.value.toTime).format("hh:mmA"),
            };
          }
        }

        const allFalse = this.weekDays.every((item) => item.action === false);

        if (allFalse) {
          errorArr.push("allFalse");
          this.toastr.error("Please select at least one day..", "", {
            timeOut: 2500,
          });
        }
      }

      if (errorArr.length == 0) {
        this.createAction(reqObj);
      }
    } else {
      Object.values(this.actionForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  createAction(req: any) {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url + "work-flow/flow-action-builder/create",
        req,
        options
      )
      .subscribe(
        (response: any) => {
          console.log(response);
          this.toastr.success(response?.message, "", {
            timeOut: 2500,
          });
          this.getFlowDetails();
          this.appFormDrawer = false;
          // this.actionForm.reset();
        },
        (error: any) => {
          console.log(error);
        }
      );
  }

  // Add link to nodes

  addLink(nodeId: any, type: string) {
    const element =
      this.elementRef.nativeElement.querySelector(`.bot_menu_list`);
    if (element) {
      // console.log(element.offsetWidth);
      this.lineLeft = element.offsetWidth;
    } else {
      this.lineLeft = 255;
    }
    if (!this.isDrawingLine) {
      this.startDrawingLine(nodeId);
    }
    if (type == "out") {
      if (this.target && this.target != nodeId.id) {
        this.isDrawingLine = false;
        window.removeEventListener("mousedown", this.handleMouseDown);
        window.removeEventListener("mousemove", this.handleMouseMove);
        window.removeEventListener("mouseup", this.handleMouseUp);
        var hasLink = false;
        if (this.links.length > 0) {
          hasLink = this.links.some((link: any) => link.source === nodeId.id);
        } else {
          hasLink = false;
        }
        // console.log(hasLink);
        if (!hasLink) {
          const reqLink = {
            workFlowActionId: nodeId.id,
            nextAction: this.target,
            type: "join",
          };

          this.updateLink(reqLink);
        }

        this.target = "";
        this.source = "";
      } else {
        this.source = nodeId;
        this.target = "";
        // console.log("out direct");
      }
    } else if (type == "in") {
      // console.log(this.source);
      if (this.source.id && this.source.id != nodeId.id) {
        this.isDrawingLine = false;
        window.removeEventListener("mousedown", this.handleMouseDown);
        window.removeEventListener("mousemove", this.handleMouseMove);
        window.removeEventListener("mouseup", this.handleMouseUp);
        var hasLink = false;
        if (this.links.length > 0) {
          hasLink = this.links.some(
            (link: any) => link.source === this.source.id
          );
        } else {
          hasLink = false;
        }

        if (!hasLink) {
          const reqLink: any = {
            workFlowActionId: this.source.id,
            nextAction: nodeId.id,
            type: "join",
          };

          this.updateLink(reqLink);
          // console.log(reqLink);
        }
        this.source = "";
        this.target = "";
      } else {
        this.target = nodeId.id;
        this.source = "";
      }
    }
    this.label = "";
  }

  updateLink(request: any) {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url + "work-flow/flow-action-builder/update-nodes",
        request,
        options
      )
      .subscribe(
        (response: any) => {
          console.log(response);
          var req = response.data.updatedAction;
          req.label = "label";
          var targetIndex = this.nodes.findIndex((e) => {
            return e.id == req._id;
          });
          const newValue = {
            ...req,
            id: req._id,
          };
          delete newValue._id;
          this.nodes[targetIndex] = newValue;
          this.nodes = [...this.nodes];
          this.testReFormatting();
        },
        (err: any) => {
          console.log(err);
          if (err?.error?.message) {
            this.toastr.error(err?.error?.message, "", {
              timeOut: 2500,
            });
          } else {
            this.toastr.error(err?.message, "", {
              timeOut: 2500,
            });
          }
        }
      );
  }

  // Drawing line

  dragging: boolean = false;
  isDrawingLine = false;
  lineStartX = 0;
  lineStartY = 0;
  lineEndX = 0;
  lineEndY = 0;

  startDrawingLine(node: any) {
    this.isDrawingLine = true;
    this.lineStartY = node.position.y;
    this.lineStartX = node.position.x;
    this.lineEndY = node.position.y;
    this.lineEndX = node.position.x;
    // this.dragging = true;
    // console.log(node);
    window.addEventListener("mousedown", this.handleMouseDown);
    window.addEventListener("mousemove", this.handleMouseMove);
    window.addEventListener("mouseup", this.handleMouseUp);
  }

  handleMouseDown() {
    this.dragging = false;
    // console.log("mouse down", this.dragging);
  }

  handleMouseMove = (event: MouseEvent) => {
    // console.log("mouse move");
    if (!this.dragging) {
      this.dragging = true;
    }
    // console.log(event);
    // const containerElement = event.currentTarget as HTMLElement;
    const containerRect = this.graphContainerRef.transformationMatrix;
    // console.log(containerRect);
    var pos: any = {
      i: 0,
      j: 0,
    };
    if (this.fullScreen) {
      pos = {
        i: 10,
        j: 0,
      };
    } else {
      pos = {
        i: this.lineLeft,
        j: 45,
      };
    }
    this.lineEndX =
      event.clientX / containerRect.a -
      pos.i / containerRect.a -
      containerRect.e / containerRect.a;
    this.lineEndY =
      event.clientY / containerRect.a -
      pos.j / containerRect.a -
      containerRect.f / containerRect.a;
  };

  handleMouseUp = (event: MouseEvent) => {
    // console.log("mouse up", this.dragging);
    if (this.isDrawingLine && !this.dragging) {
      this.isDrawingLine = false;
      window.removeEventListener("mousedown", this.handleMouseDown);
      window.removeEventListener("mousemove", this.handleMouseMove);
      window.removeEventListener("mouseup", this.handleMouseUp);
    }
    this.dragging = false;
  };

  removeLink(linkId: any) {
    var reqLink: any = {
      workFlowActionId: linkId.source,
      nextAction: linkId.target,
      type: "isolate",
    };

    this.updateLink(reqLink);
  }

  backToApp() {
    this.drawerWidth = "65%";
    this.formMenu = "apps";
  }

  getIndex() {
    var targetIndex = this.connectionsData.findIndex((e: any) => {
      return e._id == this.appForm.value.connection;
    });
    // console.log(targetIndex);
    return targetIndex;
  }

  formatVariableName(event: any) {
    const inputValue = event.target.value;
    const structuredValue = inputValue
      .replaceAll("_", " ")
      .replace(/\s+/g, "_")
      .replace(/[^A-Za-z0-9_]/g, "");
    this.appForm.get("variableName").setValue(structuredValue);
  }

  testReFormatting() {
    // console.log(this.nodes);
    var links: any[] = [];
    this.nodes.forEach((data: any, i: any) => {
      // console.log(data);
      var temp = {};
      if (
        this.outOptionTypes.indexOf(data.actionType) > -1 &&
        data.nextAction
      ) {
        temp = {
          id: "link_" + data.id,
          source: data.id,
          target: data.nextAction,
          label: "",
          sourceType: data.actionType,
        };
        links.push(temp);
        if (data.goBackAction) {
          temp = {
            id: `goBack_${data.goBackAction}nodeId${data.id}`,
            source: data.id,
            target: data.goBackAction,
            label: "go back",
            sourceType: data.actionType,
          };
          links.push(temp);
        }
      }
    });

    // this.links = links;
    this.links = links;
    this.links = [...this.links];
    // console.log(this.links);
  }

  // Add variables to template variable

  apiIndex: number;
  cursorPos: number;
  apiBodyType: string = "";
  selectId: any;
  templateIndex: number;

  apiVar(index: number, id: string, type: string, templateIndex: number) {
    this.variableSearch = "";
    this.apiIndex = index;
    this.selectId = id;
    this.apiBodyType = type;
    this.templateIndex = templateIndex;
  }

  addApiVar(type: string, value: string) {
    const val: any = document.getElementById(this.selectId);
    this.cursorPos = val.selectionStart;
    const final = `{{${type}.${value}}}`;
    var currentValue: any;

    switch (this.apiBodyType) {
      case "header":
        currentValue = this.apiHeaders[this.apiIndex].value;
        break;
      case "queryParams":
        currentValue = this.apiQueryParams[this.apiIndex].value;
        break;
      case "value":
        currentValue =
          this.templateVariables.variableTemplate[this.templateIndex].variables[
            this.apiIndex
          ].field;
        break;
      case "contact number":
        currentValue = this.actionForm.value.contactNo
          ? this.actionForm.value.contactNo
          : "";
        break;
      case "contact name":
        currentValue = this.actionForm.value.customerName
          ? this.actionForm.value.customerName
          : "";
        break;
      case "date":
        currentValue = this.actionForm.value.delayValue
          ? this.actionForm.value.delayValue
          : "";
        break;
      default:
        break;
    }

    const newValue = `${currentValue.slice(
      0,
      this.cursorPos
    )}${final}${currentValue.slice(this.cursorPos)}`;

    if (this.apiBodyType == "header") {
      this.apiHeaders[this.apiIndex].value = newValue;
    } else if (this.apiBodyType == "queryParams") {
      this.apiQueryParams[this.apiIndex].value = newValue;
    } else if (this.apiBodyType == "value") {
      this.templateVariables.variableTemplate[this.templateIndex].variables[
        this.apiIndex
      ].field = newValue;
    } else if (this.apiBodyType == "contact number") {
      this.actionForm.get("contactNo").setValue(newValue);
    } else if (this.apiBodyType == "contact name") {
      this.actionForm.get("customerName").setValue(newValue);
    } else if (this.apiBodyType == "date") {
      this.actionForm.get("delayValue").setValue(newValue);
    }
  }

  addFormat(format) {
    // const val: any = document.getElementById("dateFormat");
    // this.cursorPos = val.selectionStart;
    // const final = format;
    // var currentValue: any;

    // currentValue = this.actionForm.value.delayFormat
    //   ? this.actionForm.value.delayFormat
    //   : "";

    // const newValue = `${currentValue.slice(
    //   0,
    //   this.cursorPos
    // )}${final}${currentValue.slice(this.cursorPos)}`;

    this.actionForm.get("delayFormat").setValue(format);
  }

  // Publish flow

  publishPath() {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url + "work-flow/publish",
        { workFlowId: this.flowData.flowId },
        options
      )
      .subscribe(
        (response: any) => {
          console.log(response);
          this.flowDetail.workFlow.isNeedToPublish = false;
          this.message.create("success", response.message);
        },
        (err: any) => {
          console.log(err);
          if (err?.error?.message) {
            this.toastr.error(err?.error?.message, "", {
              timeOut: 2500,
            });
          } else {
            this.toastr.error(err?.message, "", {
              timeOut: 2500,
            });
          }
        }
      );
  }

  test() {
    this.nodes[0].position = { x: 500, y: 500 };
    this.nodes = [...this.nodes];
  }

  inputTypeChange() {
    this.actionForm.get("delayValue").setValue("");
  }

  // new design logics
  @ViewChild("myInput", { static: false }) myInput: ElementRef;
  openSearch(search: boolean) {
    this.showSearch = search;
    this.searchText = "";
    if (search) {
      setTimeout(() => {
        this.myInput.nativeElement.focus();
      }, 500);
    }
  }

  enableFullScreen() {
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    }
    if (document.exitFullscreen) {
      this.document.exitFullscreen();
    }
    // this.message.info("Press ESC to exit full screen", {
    //   nzDuration: 4000,
    // });
    // this.fullScreen = true;
    // setTimeout(() => {
    //   this.getWidthByClassName();
    // }, 500);
  }

  // Listen to key down event to detect esc button click
  @HostListener("window:keydown", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent) {
    // console.log(event);
    if (event.key === "Escape") {
      this.fullScreen = false;
      // setTimeout(() => {
      //   this.getWidthByClassName();
      // }, 500);
    }
  }

  getWidthByClassName() {
    const element =
      this.elementRef.nativeElement.querySelector(`.chart-container`);
    if (element) {
      console.log(element.offsetWidth);
      document.documentElement.style.setProperty(
        "--minimap_x",
        `${element.offsetWidth - 100}px`
      );
    }
  }

  // API CALL

  mouseUp() {
    this.selectedStyle = "";
  }

  onTextSelect(field: string) {
    this.selectedStyle = "";
    var editorElement: any;
    this.field = field;
    switch (field) {
      case "message":
      case "bodyText":
        editorElement = this.messageField.nativeElement;
        break;
      case "footerText":
        editorElement = this.footerVariable.nativeElement;
        break;
      case "headerText":
        editorElement = this.hederVariable.nativeElement;
        break;
      case "url":
        editorElement = this.urlVariable.nativeElement;
        break;
      case "caption":
        editorElement = this.captionVariable.nativeElement;
        break;

      default:
        break;
    }
    const selectedText = editorElement.value.substring(
      editorElement.selectionStart,
      editorElement.selectionEnd
    );

    const boldRegex = /^\*(.*?)\*$/;
    const strikeRegex = /^~(.*)~$/;
    const italicRegex = /^_(.*)_$/;

    if (boldRegex.test(selectedText)) {
      this.selectedStyle = "Bold";
    }

    if (strikeRegex.test(selectedText)) {
      this.selectedStyle = "Strike";
    }

    if (italicRegex.test(selectedText)) {
      this.selectedStyle = "Italic";
    }
  }

  bodyTextFocus() {
    this.emojiVisible = false;
    this.selectedStyle = "";
  }

  // api header and query params

  addHeader() {
    this.apiHeaders.push({ key: "", value: "" });
  }

  removeHeader(index: number) {
    this.apiHeaders.splice(index, 1);
  }

  addQuery() {
    this.apiQueryParams.push({ key: "", value: "" });
  }

  removeQuery(index: number) {
    this.apiQueryParams.splice(index, 1);
  }

  removeMenu(index: number) {
    this.menuList.splice(index, 1);
  }

  showEditor(value: string) {
    this.codeType = value;
    this.bodyText.nativeElement.blur();
    this.codeEditor = !this.codeEditor;
  }

  addNewVariable() {
    if (
      this.actionForm.value.responseVariable == "new variable" &&
      this.newVariable
    ) {
      let options = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
        },
      };

      var reqObj: any = {
        workFlowId: this.flowData.flowId,
        actionType: this.messageType,
        name: this.newVariable,
      };

      if (this.messageType == "API_CALL") {
        reqObj.dataType = this.actionForm.value.variableType;
      }

      this.apiService
        .commonPostMethod(
          environment.url + "flow-path-variables/create",
          reqObj,
          options
        )
        .subscribe(
          (response: any) => {
            console.log(response);
            this.getAllVariables("new");
            this.newVariable = "";
            this.availVal = "";
          },
          (err: any) => {
            console.log(err);
            this.toastr.error(err?.error?.message, "", {
              timeOut: 2500,
            });
          }
        );
    }
  }

  availVal: string = "";

  detectAvailVariables(event: any) {
    this.availVal = event.target.value;

    var targetIndex = this.flowVariables?.findIndex((v) => {
      return this.flowVariables.name == event.target.value;
    });

    if (targetIndex == -1) {
      const inputValue = event.target.value;
      this.newVariable = inputValue
        .replaceAll("_", " ")
        .replace(/\s+/g, "_")
        .replace(/[^A-Za-z0-9_]/g, "");
      this.responseVar.searchValue = this.newVariable;
    } else {
      this.newVariable = "";
      this.availVal = "";
    }

    console.log(this.newVariable);
  }

  getAllVariables(type: string) {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url + "flow-path-variables",
        { workFlowId: this.flowData.flowId },
        options
      )
      .subscribe(
        (response: any) => {
          console.log(response);
          this.flowVariables = response.data.allVariables;
          if (type == "new" && this.flowVariables.length > 0) {
            this.actionForm
              .get("responseVariable")
              .setValue(this.flowVariables[0].name);
          }
        },
        (err: any) => {
          console.log(err);
        }
      );
  }

  // get customer fields data

  getCustomerFields() {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url + "customer-field/get?filter=for-show-fields",
        {},
        options
      )
      .subscribe(
        (response: any) => {
          this.customerFields = response.data.customerFields;
          console.log(this.customerFields);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  // Add variable to code editor

  addVarEditor(name: string, type: string) {
    const cursor = this.codemirror.codeMirror.getCursor();

    const variableText = `"{{${type}.${name}}}"`;
    this.apiBody =
      this.apiBody.slice(0, this.codemirror.codeMirror.indexFromPos(cursor)) +
      variableText +
      this.apiBody.slice(this.codemirror.codeMirror.indexFromPos(cursor));
  }
}
