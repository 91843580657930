<div (mouseenter)="mouseEnter()" (mouseleave)="mouseLeave()" class="side-nav" sideNav>
    <ul class="main_list" [ngClass]="{'main_list-purchased' : !userData?.accessTo?.sandboxId} ">
        <ul class="ant-menu ant-menu-root ant-menu-inline side-nav-menu" [ngClass]="{
                'ant-menu-inline-collapsed': isFolded,
                'ant-menu-dark' : isSideNavDark
            }">
            <li nz-button class="ant-menu-item version image_li">
                <a class="menu-list" (click)="closeMobileMenu()">
                    <a>
                        <div class="">
                            <span *ngIf="isFolded">
                                <img class="folded_logo" src="../../../../assets/images/logo/own_cart_mini_logo.png"
                                    alt="logo">
                            </span>
                            <span *ngIf="!isFolded">
                                <img class="own_chat_logo" src="../../../../assets/images/logo/ownchat_logo_latest.png"
                                    alt="logo">
                            </span>
                        </div>
                    </a>
                </a>
            </li>


            <ng-container *ngFor="let item of menuItems">
                <li nz-button
                    *ngIf="
                    ((item?.role.includes(userData?.role) && (userData?.accessTo?.pluginsEnabled?.indexOf(commerceId) > -1 && item.title == 'Commerce')) && ((userData?.accessTo?.sandboxId && item.title != 'Bot') || !userData?.accessTo?.sandboxId)) 
                    || ((item?.role?.includes(userData?.role) && item.title != 'Commerce') && ((userData?.accessTo?.sandboxId && item.title != 'Bot') || !userData?.accessTo?.sandboxId))"
                    [ngClass]="item.submenu.length > 0?'ant-menu-submenu ant-menu-submenu-inline':'ant-menu-item'"
                    [routerLinkActive]="item.submenu.length > 0 ? 'ant-menu-submenu-open' : 'ant-menu-item-selected'">
                    <a href="javascript:void(0);" class="ant-menu-submenu-title" *ngIf="item.submenu.length > 0 ">
                        <i *ngIf="item.iconType == 'nzIcon'" nz-icon [nzType]="item.icon" [theme]="item.iconTheme"></i>
                        <i *ngIf="item.iconType == 'fontawesome'" class="m-r-10"
                            [ngClass]="[item.iconTheme , item.icon]"></i>
                        <span>{{item.title}}</span>
                        <i class="ant-menu-submenu-arrow"></i>
                    </a>

                    <a class="menu-list" (click)="closeMobileMenu()"
                        *ngIf="item.submenu.length === 0 && item.iconType !== '' ">
                        <a [routerLink]="item.path">
                            <div class="">
                                <span *ngIf="item.iconType == 'nzIcon'" nz-icon [nzType]="item.icon"
                                    [theme]="item.iconTheme"></span>
                                <span *ngIf="item.iconType == 'fontawesome'" class="m-r-10"
                                    [ngClass]="[item.iconTheme , item.icon]"></span>
                                <span>{{item.title}}</span>
                            </div>
                        </a>
                    </a>
                </li>
            </ng-container>
        </ul>

        <ul class="ant-menu ant-menu-root ant-menu-inline side-nav-menu" [ngClass]="{ 'ant-menu-inline-collapsed': isFolded,
            'ant-menu-dark' : isSideNavDark }">

            <li nz-button class='ant-menu-item version'>
                <a class="menu-list">
                    <a>
                        <div class="text-center">
                            <span *ngIf="isFolded" class="app_version">V 3.0</span>
                            <span *ngIf="!isFolded" class="app_version">Version 3.0</span>
                        </div>
                    </a>
                </a>
            </li>
        </ul>
    </ul>
</div>



<!-- [routerLinkActiveOptions]="{exact: true}"  Goes to line 9 -->

<!-- nz-tooltip [nzTooltipTitle]="item.title" -->

<!-- (mouseenter)="mouseEnter()" (mouseleave)="mouseLeave()" -->