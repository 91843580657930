<div class="row top_row overflow_row_mobile">
    <div class="col-12 main_outer_heading">
        <h3>
            <span *ngIf="showDocs" (click)="setDefault() " nz-icon nzType="left" nzTheme="outline"></span>
            {{showDocs ? 'Plugins' : 'All Plugins'}}
        </h3>
    </div>

    <!-- plugins list -->
    <div *ngIf="!showDocs && !showTemplates" class="col-lg-12 right_column">
        <div #scrollableDiv class="fixed_height plugin_main mt-3">
            <div class="row">
                <div class="col-12 d-flex">
                    <div class="">
                        <nz-input-group class="search_input_section" [nzPrefix]="suffixIconSearch">
                            <input autocomplete="off" [(ngModel)]="searchValue" type="text" nz-input
                                placeholder="Search plugins.." />
                        </nz-input-group>
                        <ng-template #suffixIconSearch>
                            <span nz-icon nzType="search"></span>
                        </ng-template>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-3">
                    <div class="left_menu mt-3">
                        <nz-radio-group [(ngModel)]="selectedCategory" (ngModelChange)="categoryChange()">
                            <label nz-radio nzValue="all">All ({{totalCount}})</label>
                            <label *ngFor="let plug of allPlugins" nz-radio
                                [nzValue]="plug?.category">{{plug?.category}}
                                ({{plug?.plugins?.length}})</label>
                        </nz-radio-group>
                    </div>
                </div>

                <div class="col-sm-9">
                    <div class="row">
                        <ng-container *ngFor="let plug of allPlugins ;  let i = index">
                            <div [id]="plug?.category + j"
                                *ngFor="let item of plug?.plugins | filter : searchValue ;  let j = index"
                                class="col-lg-12 col-xl-12 col-xxl-6">
                                <div (click)="showDocument(item)" class="card_design available_plugin mt-3">
                                    <div class="card_top">
                                        <img [src]="imageUrl + item?.logo" alt="">
                                        <div class="">
                                            <h5>{{item?.name}}</h5>
                                            <span [nzTooltipTitle]="item?.description" nzTooltipPlacement="top"
                                                nz-tooltip>{{item?.description?.length > 100 ?
                                                item?.description?.slice(0,100) +
                                                '...' :
                                                item?.description}}</span>
                                        </div>
                                    </div>
                                    <div class="card_bottom">
                                        <a [disabled]="mainUserData?.accessTo?.isExpired"
                                            (click)="optionsClick(item , $event)" nz-button
                                            [nzType]="mainUserData?.accessTo?.pluginsEnabled.indexOf(item?._id) > -1 ? 'default' : 'primary'"
                                            class="create-button">
                                            <i class="d-flex"
                                                *ngIf="mainUserData?.accessTo?.pluginsEnabled.indexOf(item?._id) > -1"
                                                nz-icon nzType="setting" nzTheme="outline"></i>
                                            <span>{{
                                                mainUserData?.accessTo?.pluginsEnabled.indexOf(item?._id) > -1 ?
                                                'Setting' : 'Install'}}
                                            </span>
                                        </a>
                                    </div>
                                </div>

                                <ngx-spinner bdColor="rgb(255,255,255)" size="medium" color="#000000"
                                    type="ball-clip-rotate-pulse" [fullScreen]="false">
                                </ngx-spinner>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <!-- plugin details -->
    <div *ngIf="showDocs && !showTemplates" class="col-lg-12 right_column">
        <nz-card class="fixed_height">
            <div class="plugin_detail">
                <div class="plugin_detail_left">
                    <img [src]="imageUrl + previewPlugin?.logo" alt="">
                    <h3>{{previewPlugin?.name}}</h3>
                </div>
                <div class="plugin_detail_right">
                    <a *ngIf="mainUserData?.accessTo?.pluginsEnabled.indexOf(previewPlugin?._id)  > -1"
                        (click)="uninstallAlert()">
                        Uninstall Plugin
                    </a>
                    <a [nzLoading]="installLoader" (click)="install()" nz-button
                        [nzType]="mainUserData?.accessTo?.pluginsEnabled.indexOf(previewPlugin?._id)  > -1 ? 'default' : 'primary'"
                        class="create-button">
                        <i class="d-flex"
                            *ngIf="mainUserData?.accessTo?.pluginsEnabled.indexOf(previewPlugin?._id)  > -1" nz-icon
                            nzType="setting" nzTheme="outline"></i>
                        <span>{{mainUserData?.accessTo?.pluginsEnabled.indexOf(previewPlugin?._id) > -1 ?
                            'Setting' : 'Install Plugin'}}
                        </span>
                    </a>
                </div>
            </div>

            <div class="">
                <div class="banner_image mt-3">
                    <img [src]="imageUrl + previewPlugin?.featureImage" alt="">
                </div>
                <div class="description_section mt-5">
                    <!-- <h4>Description</h4> -->
                    <div class="inner_text mb-3" [innerHTML]="previewPlugin?.description"></div>
                    <div class="inner_text" [innerHTML]="previewPlugin?.features"></div>

                </div>
            </div>

            <ngx-spinner bdColor="rgb(255,255,255)" size="medium" color="#000000" type="ball-clip-rotate-pulse"
                [fullScreen]="false">
            </ngx-spinner>
        </nz-card>
    </div>

    <!-- templates sections -->
    <div class="col-lg-12 right_column">
        <app-commerce-messages *ngIf="showTemplates"
            [allTemplate]="previewPlugin.waBaTemplates"></app-commerce-messages>
    </div>

</div>