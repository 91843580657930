<!-- <div class="row m-b-30">
    <div class="col-lg-8">
        <h3>Tags</h3>
    </div>
    <div class="col-12 mt-3">
        <div class="search_add_div">
            <div class="toggle_options">
                <nz-input-group [nzPrefix]="suffixIconSearch">
                    <input type="text" nz-input placeholder="Search tag name" />
                </nz-input-group>
                <ng-template #suffixIconSearch>
                    <span nz-icon nzType="search"></span>
                </ng-template>
            </div>
            <a (click)="showTagModal()" nz-button nzType="primary" class="create-button setting_add_button">
                <i nz-icon nzType="plus" theme="outline"></i>
                <span>Add Tags</span>
            </a>
        </div>
    </div>

    <div class="col-lg-8 col-12 mt-3">
        <nz-table #tagsDataTable [nzData]="tagsData" [nzScroll]="{ y: '56vh', x : '500px' }">
            <thead>
                <tr>
                    <th *ngFor="let column of orderColumn" [nzWidth]="column.width">{{ column.title }}</th>
                </tr>
            </thead>
            <tbody>
                <tr id="catalogueTable" *ngFor="let item of tagsDataTable.data ;  let i=index">
                    <td> {{item.name}} </td>
                    <td>
                        <div class="left_list">
                            <p class="user_profile_picture">B</p>
                            <div class="">
                                <p class="user_name">Balaji</p>
                            </div>
                        </div>
                    </td>
                    <td>
                        <p>26.11.2022</p>
                    </td>
                    <td>
                        <span nz-icon nzType="delete" nzTheme="outline"></span>
                    </td>
                </tr>
            </tbody>
            <ngx-spinner bdColor="rgb(255,255,255)" size="medium" color="#000000" type="ball-clip-rotate-pulse"
                [fullScreen]="false">
            </ngx-spinner>

        </nz-table>
    </div>
</div> -->

<!-- <nz-modal [(nzVisible)]="addTagModal" nzTitle="Create a new tag" nzCentered (nzOnCancel)="closeAddTagModal()">
    <ng-container *nzModalContent>
        
    </ng-container>
    <ng-template class="modal_footer" *nzModalFooter>

    </ng-template>
</nz-modal> -->

<div class="row" [ngClass]="{'main_tags_row' : tagsPage}">
    <div *ngIf="tagsPage" class="col-12 main_outer_heading d_mobile_none">
        <h3>
            <span class="cursor_pointer" routerLink="/contacts" nz-icon nzType="left" nzTheme="outline"></span>
            Tags
        </h3>
    </div>
    <div class="col-lg-12 table_section" [ngClass]="{ 'col-lg-8': showForm }">
        <div class="row m-b-30">
            <div *ngIf="!tagsPage" class="col-12 top_heading">
                <nz-card class="contacts_top_card">
                    <div class="contacts_top_bar">
                        <div class="contacts_top_bar_left">
                            <h3><span>Settings > </span>Tags</h3>
                        </div>

                        <div class="contacts_top_bar_right">
                            <div class="search_add_div">
                                <div class="toggle_options">
                                    <nz-input-group class="search_input_section small_input_sec"
                                        [nzPrefix]="suffixIconSearch">
                                        <input autocomplete="off" [(ngModel)]="searchKey" (keyup)="searchContacts()"
                                            type="text" nz-input placeholder="Search tag name" />
                                    </nz-input-group>
                                    <ng-template #suffixIconSearch>
                                        <span nz-icon nzType="search"></span>
                                    </ng-template>
                                </div>

                                <div class="d-flex align-items-center">
                                    <a [disabled]="mainUserData?.accessTo?.isExpired" (click)="toggleForm()" nz-button
                                        nzType="primary" class="create-button setting_add_button">
                                        <i nz-icon nzType="plus" theme="outline"></i>
                                        <span>Add Tags</span>
                                    </a>

                                    <span [ngClass]="{ reload_btn_loading: pageReloading }" (click)="reloadPage()"
                                        class="reload_btn" nzTooltipTitle="reload" nzTooltipPlacement="top" nz-tooltip
                                        nz-icon nzType="sync" nzTheme="outline"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </nz-card>
            </div>

            <div class="col-12">
                <nz-card class="tags_new_table customers_table_card">
                    <div class="total_records">
                        <h6>
                            Total Records ({{ totalCount }})
                        </h6>
                        <div class="tags_top_right">
                            <div *ngIf="tagsPage" class="contacts_top_bar_right">
                                <div class="search_add_div">
                                    <div class="toggle_options">
                                        <nz-input-group class="search_input_section small_input_sec"
                                            [nzPrefix]="suffixIconSearch">
                                            <input autocomplete="off" [(ngModel)]="searchKey" (keyup)="searchContacts()"
                                                type="text" nz-input placeholder="Search tag name" />
                                        </nz-input-group>
                                        <ng-template #suffixIconSearch>
                                            <span nz-icon nzType="search"></span>
                                        </ng-template>
                                    </div>

                                    <div class="d-flex align-items-center">
                                        <a [disabled]="mainUserData?.accessTo?.isExpired" (click)="toggleForm()"
                                            nz-button nzType="primary" class="create-button setting_add_button">
                                            <i nz-icon nzType="plus" theme="outline"></i>
                                            <span>Add Tags</span>
                                        </a>

                                        <span [ngClass]="{ reload_btn_loading: pageReloading }" (click)="reloadPage()"
                                            class="reload_btn" nzTooltipTitle="reload" nzTooltipPlacement="top"
                                            nz-tooltip nz-icon nzType="sync" nzTheme="outline"></span>
                                    </div>
                                </div>
                            </div>
                            <!-- <button class="customized_btn" [ngStyle]="setMyStyles()" nzTrigger="click" nz-dropdown
                                [nzDropdownMenu]="paginationMenu" [nzPlacement]="'bottomLeft'">
                                {{ pageSize + " records per page" }}
                            </button> -->

                            <nz-dropdown-menu #paginationMenu="nzDropdownMenu">
                                <ul nz-menu>
                                    <li (click)="changePageSize((n + 1) * 10)"
                                        *ngFor="let page of [].constructor(5); let n = index" nz-menu-item>
                                        {{ (n + 1) * 10 + " records per page" }}
                                    </li>
                                </ul>
                            </nz-dropdown-menu>
                        </div>
                    </div>

                    <nz-table class="contacts_table main_contacts_table" #tagsDataTable [nzData]="tagsData"
                        [nzScroll]="{ y: tableHeight, x: '500px' }" [nzShowPagination]="false"
                        [nzFrontPagination]="false" [nzTotal]="totalCount" [nzPageSize]="pageSize"
                        [nzPageIndex]="pageIndex" (nzQueryParams)="onPageNumberChange('categoryTable', $event)"
                        [nzPaginationPosition]="'top'" [nzSimple]="true">
                        <thead>
                            <tr>
                                <th *ngFor="let column of orderColumn" [nzWidth]="column.width">
                                    {{ column.title }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr id="catalogueTable" *ngFor="let item of tagsDataTable.data; let i = index">
                                <td>{{ item.name }}</td>
                                <td>
                                    <div class="left_list">
                                        <p class="user_profile_picture">
                                            {{ (item?.createdBy?.firstName).charAt(0) }}
                                        </p>
                                        <div class="">
                                            <p class="user_name">
                                                {{
                                                item?.createdBy?.firstName +
                                                " " +
                                                (item?.createdBy?.lastName
                                                ? item?.createdBy?.lastName
                                                : "")
                                                }}
                                            </p>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <p>
                                        {{ moment(item?.createdAt).format("DD-MM-YYYY hh:mm A") }}
                                    </p>
                                </td>
                                <td>
                                    <span class="delete_icon" (click)="deleteAlert(item)" nz-icon nzType="delete"
                                        nzTheme="outline"></span>
                                </td>
                            </tr>
                        </tbody>
                        <ngx-spinner bdColor="rgb(255,255,255)" size="medium" color="#000000"
                            type="ball-clip-rotate-pulse" [fullScreen]="false">
                        </ngx-spinner>
                    </nz-table>
                </nz-card>
            </div>
        </div>
    </div>

    <div *ngIf="showForm" class="col-lg-4 form_section">
        <nz-card class="details_card">
            <div class="form_heading_section mb-3">
                <h5>Create a new tag</h5>
                <span class="close_icon" (click)="toggleForm()" nz-icon nzType="close" nzTheme="outline"></span>
            </div>
            <form [formGroup]="addTagForm" (ngSubmit)="addTag()" nz-form>
                <nz-form-item>
                    <nz-form-label nzRequired class="large_label" nzFor="" [nzSm]="24" [nzXs]="24">Name
                    </nz-form-label>
                    <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please Enter Name!">
                        <nz-input-group>
                            <input autocomplete="off" formControlName="name" type="text" nz-input id=""
                                placeholder="Enter Name" required />
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item class="submit_section justify-content-center mt-3">
                    <nz-form-control [nzSm]="20" [nzXs]="24">
                        <button [nzLoading]="addingTag" type="submit" class="sighup_button setting_add_button w-100"
                            nz-button nzType="primary">
                            Create
                        </button>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </nz-card>
    </div>
</div>