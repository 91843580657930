import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { CommonApiService } from "src/app/services/common-api.service";
import { NavService } from "src/app/services/nav.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.css"],
})
export class ProfileComponent implements OnInit {
  profileForm!: FormGroup;
  selectedMedia: any;

  private subscription: Subscription;

  constructor(
    private fb: FormBuilder,
    private apiService: CommonApiService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private navService: NavService
  ) {}

  userData: any = {};

  previewImage: any = "";
  updating: boolean = false;

  imageTypes = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    // "image/webp",
    // "image/svg+xml",
  ];

  ngOnInit(): void {
    this.profileForm = this.fb.group({
      firstName: ["", Validators.required],
      lastName: [""],
      email: [{ value: "", disabled: true }],
      role: [{ value: "", disabled: true }],
    });

    this.fetchData();
  }

  fetchData() {
    this.spinner.show();
    this.subscription = this.navService.mainUserData.subscribe(
      (response: any) => {
        if (response) {
          // console.log(response);
          this.userData = response?.data?.userData;
          this.previewImage = response?.data?.userData?.profileImage
            ? environment.imageUrl + response?.data?.userData?.profileImage
            : "../../../../assets/images/logo/profile.png";
          this.profileForm
            .get("firstName")
            .setValue(response?.data?.userData?.firstName);
          this.profileForm
            .get("lastName")
            .setValue(response?.data?.userData?.lastName);
          this.profileForm.get("role").setValue(response?.data?.userData?.role);
          this.profileForm
            .get("email")
            .setValue(response?.data?.userData?.email);
          this.spinner.hide();
        }
      }
    );
    // let options = {
    //   headers: {
    //     Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
    //   },
    // };

    // this.apiService
    //   .commonGetMethod(environment.url + "auth/get-user-data", options)
    //   .subscribe(
    //     (response: any) => {
    //       console.log(response);
    //       this.userData = response.data.userData;
    //       this.previewImage =
    //         environment.imageUrl + response?.data?.userData?.profileImage;
    //       this.profileForm
    //         .get("firstName")
    //         .setValue(response?.data?.userData?.firstName);
    //       this.profileForm
    //         .get("lastName")
    //         .setValue(response?.data?.userData?.lastName);
    //       this.profileForm.get("role").setValue(response.data.userData.role);
    //       this.spinner.hide();
    //     },
    //     (error: any) => {
    //       console.log(error);
    //       this.spinner.hide();
    //     }
    //   );
  }

  getFile(event: any) {
    // console.log(event.target.files[0]);
    this.selectedMedia = event.target.files[0];
    const file = event.target.files[0];

    const reader = new FileReader();
    reader.onload = (e) => (this.previewImage = reader.result);
    reader.readAsDataURL(file);

    event.target.value = "";
  }

  updateProfile() {
    if (this.profileForm.valid) {
      this.updating = true;
      let options = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
        },
      };

      var data = new FormData();

      if (this.selectedMedia) {
        data.append("profile-image", this.selectedMedia);
      }
      data.append("firstName", this.profileForm.value.firstName);
      data.append("lastName", this.profileForm.value.lastName);

      this.apiService
        .commonPostMethod(
          environment.url + "auth/update-profile",
          data,
          options
        )
        .subscribe(
          (response: any) => {
            // console.log(response);
            // this.fetchData();
            this.navService.getMainUserData();
            this.toastr.success(response?.message, "", {
              timeOut: 2500,
            });
            this.updating = false;
          },
          (error) => {
            console.log(error);
            if (error?.error?.message) {
              this.toastr.error(error?.error?.message, "", {
                timeOut: 2500,
              });
            } else {
              this.toastr.error(error?.message, "", {
                timeOut: 2500,
              });
            }
            this.updating = false;
          }
        );
    }
  }

  resetForm() {
    // this.profileForm.reset();
    // this.fetchData();
    this.navService.getMainUserData();
    this.updating = false;
  }

  ngOnDestroy(): void {
    // console.log("api key destroy..");
    this.subscription.unsubscribe();
  }
}
