<div class="row overflow_row_mobile">
    <div class="col-12 main_outer_heading">
        <h3><span routerLink="/contacts" nz-icon nzType="left" nzTheme="outline"></span> {{isEdit ? 'Edit' : 'New'}}
            Segment
        </h3>
    </div>

    <div class="col-12 mt-2">
        <nz-card class="">
            <!-- <div class="row m-b-30"> -->
            <!-- <div class="col-12 top_heading">
                    <h3><span class="cursor_pointer" (click)="toContacts()">Contacts ></span> New Segment</h3>
                </div> -->

            <!-- <div class="col-12 mt-3"> -->
            <form class="customer_right_card" nz-form>
                <div class="" nz-row [nzGutter]="24">
                    <div nz-col [nzXs]="24" class="" [nzSm]="12">
                        <nz-form-item>
                            <nz-form-label nzRequired class="large_label" nzFor="">Name
                            </nz-form-label>
                            <nz-form-control [nzSm]="16" [nzXs]="24" nzErrorTip="Please Enter Name!">
                                <nz-input-group>
                                    <input [(ngModel)]="segmentName" [ngModelOptions]="{standalone: true}"
                                        (ngModelChange)="removeValidError('segment name')" autocomplete="off"
                                        type="text" nz-input id="" placeholder="Enter Name">
                                    <p class="error_message" *ngIf="requiredData.indexOf('segment name') > -1">
                                        Please
                                        Enter Segment Name</p>
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <div nz-col [nzXs]="24" class="mt-3 sub_heading" [nzSm]="24">
                        <h6>Define The Tagline (If any)</h6>
                    </div>

                    <div nz-col [nzXs]="24" class="mt-3" [nzSm]="24">
                        <div *ngFor="let tagline of taglineArray ; let i = index" class="tagline_section mb-3" nz-row
                            [nzGutter]="24">
                            <div nz-col [nzXs]="24" class="" [nzSm]="6">
                                <nz-form-item>
                                    <div class="serial_number">
                                        <p>{{i + 1}}.</p>
                                    </div>
                                    <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please Enter Name!">
                                        <nz-input-group>
                                            <nz-select nzShowSearch [(ngModel)]="tagline.key"
                                                [ngModelOptions]="{standalone: true}"
                                                (ngModelChange)="fieldChange(i , 'fieldName' + i)"
                                                class="w-100 select_dropdown" nzPlaceHolder="Select Field Name">
                                                <nz-option *ngFor="let fields of customFields" [nzLabel]="fields.label"
                                                    [nzValue]="fields"> </nz-option>
                                            </nz-select>
                                            <p class="error_message" *ngIf="requiredData.indexOf('fieldName' + i) > -1">
                                                Please select field name.
                                            </p>
                                        </nz-input-group>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>

                            <div nz-col [nzXs]="24" class="" [nzSm]="6">
                                <nz-form-item>
                                    <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please Enter Name!">
                                        <nz-input-group>
                                            <nz-select nzShowSearch [(ngModel)]="tagline.filterType"
                                                [ngModelOptions]="{standalone: true}"
                                                (ngModelChange)="removeValidError('filterType' + i)"
                                                class="w-100 select_dropdown" nzPlaceHolder="Select Condition">
                                                <nz-option *ngFor="let filter of filterOptions[tagline?.key?.dataType]"
                                                    [nzLabel]="filter" [nzValue]="filter"> </nz-option>
                                            </nz-select>
                                            <p class="error_message"
                                                *ngIf="requiredData.indexOf('filterType' + i) > -1">
                                                Please select filter type.
                                            </p>
                                        </nz-input-group>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>

                            <div *ngIf="tagline.key && tagline.filterType != 'is empty' && tagline.filterType != 'is not empty'"
                                nz-col [nzXs]="24" class="accept_value_section" [nzSm]="6">
                                <nz-form-item *ngFor="let value of tagline.values ; let j = index; trackBy:trackByFn">
                                    <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please Enter Name!">
                                        <nz-input-group [ngSwitch]="tagline?.key?.dataType">
                                            <input *ngSwitchCase="'text'" [(ngModel)]="tagline.values[j]"
                                                [ngModelOptions]="{standalone: true}"
                                                (ngModelChange)="removeValidError('value' + i + j)" autocomplete="off"
                                                nz-input [placeholder]="'Enter value'" type="text">

                                            <input *ngSwitchCase="'email'" [(ngModel)]="tagline.values[j]"
                                                [ngModelOptions]="{standalone: true}"
                                                (ngModelChange)="removeValidError('value' + i + j)" autocomplete="off"
                                                nz-input [placeholder]="'Enter value'" type="email">

                                            <input *ngSwitchCase="'number'" [(ngModel)]="tagline.values[j]"
                                                [ngModelOptions]="{standalone: true}"
                                                (ngModelChange)="removeValidError('value' + i + j)" autocomplete="off"
                                                nz-input [placeholder]="'Enter value'" type="number">

                                            <input *ngSwitchCase="'phone-number'" [(ngModel)]="tagline.values[j]"
                                                [ngModelOptions]="{standalone: true}"
                                                (ngModelChange)="removeValidError('value' + i + j)" autocomplete="off"
                                                nz-input [placeholder]="'Enter value'" type="number">

                                            <nz-select nzShowSearch *ngSwitchCase="'select'"
                                                [(ngModel)]="tagline.values[j]" [ngModelOptions]="{standalone: true}"
                                                (ngModelChange)="removeValidError('value' + i + j)"
                                                [nzPlaceHolder]="'Select value'" class="w-100">
                                                <nz-option *ngFor="let option of tagline?.key?.acceptedValues"
                                                    [nzLabel]="option" [nzValue]="option"></nz-option>
                                            </nz-select>

                                            <nz-select nzShowSearch *ngSwitchCase="'multi-select'"
                                                [(ngModel)]="tagline.values[j]" [ngModelOptions]="{standalone: true}"
                                                (ngModelChange)="removeValidError('value' + i + j)"
                                                [nzPlaceHolder]="'Select value'" class="w-100">
                                                <nz-option *ngFor="let option of tagline?.key?.acceptedValues"
                                                    [nzLabel]="option" [nzValue]="option"></nz-option>
                                            </nz-select>

                                            <nz-select *ngSwitchCase="'switch'" [(ngModel)]="tagline.values[j]"
                                                [ngModelOptions]="{standalone: true}"
                                                (ngModelChange)="removeValidError('value' + i + j)"
                                                [nzPlaceHolder]="'Select value'" class="w-100">
                                                <nz-option [nzLabel]="'True'" [nzValue]="'true'"></nz-option>
                                                <nz-option [nzLabel]="'False'" [nzValue]="'false'"></nz-option>
                                            </nz-select>

                                            <nz-select nzShowSearch *ngSwitchCase="'tags'"
                                                [(ngModel)]="tagline.values[j]" [ngModelOptions]="{standalone: true}"
                                                (ngModelChange)="removeValidError('value' + i + j)"
                                                [nzPlaceHolder]="'Select value'" class="w-100">
                                                <nz-option *ngFor="let tag of allTags" [nzLabel]="tag.name"
                                                    [nzValue]="tag._id">
                                                </nz-option>
                                            </nz-select>

                                            <nz-date-picker class="w-100" *ngSwitchCase="'date'"
                                                [(ngModel)]="tagline.values[j]" [ngModelOptions]="{standalone: true}"
                                                (ngModelChange)="removeValidError('value' + i + j)" nzShowToday
                                                nzFormat="dd-MM-yyyy" [nzRenderExtraFooter]="">
                                            </nz-date-picker>
                                            <p class="error_message" *ngIf="requiredData.indexOf('value' + i + j) > -1">
                                                Please fill filter value.
                                            </p>
                                        </nz-input-group>
                                    </nz-form-control>
                                    <span *ngIf="j > 0" (click)="removeValueInput(i , j)" nz-icon nzType="close-circle"
                                        nzTheme="outline"></span>
                                </nz-form-item>
                            </div>

                            <div nz-col [nzXs]="24" class="add_remove_section" [nzSm]="4">
                                <div class="remove_link">
                                    <span *ngIf="i > 0" (click)="removeTagline(i)" nz-icon nzType="minus-circle"
                                        nzTheme="outline"></span>
                                    <span
                                        *ngIf="tagline.key && tagline.filterType != 'is empty' && tagline.filterType != 'is not empty'"
                                        (click)="addValueInput(i)" nz-icon nzType="plus-circle"
                                        nzTheme="twotone"></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div nz-col [nzXs]="24" class="tag_buttons" [nzSm]="22">
                        <a (click)="addNewTagline()" class="add_tagline_link mb-1"><span nz-icon nzType="plus-circle"
                                nzTheme="twotone"></span>
                            Add Tagline
                        </a>
                        <div class="reset_check_btn">
                            <button (click)="resetForm()" type="button" class="sighup_button mr-3" nz-button
                                nzType="default"><span nz-icon nzType="reload" nzTheme="outline"></span>
                                Reset
                            </button>
                            <button (click)="checkFiltered()" type="button" class="sighup_button mt-1" nz-button
                                nzType="primary">
                                Check Filter
                            </button>
                        </div>
                    </div>

                    <div *ngIf="filteredApplied" id="filter_result" nz-col [nzXs]="24" class="mt-3 sub_heading"
                        [nzSm]="24">
                        <nz-table class="contacts_table normal_pagination" #userListTable [nzShowPagination]="true"
                            [nzData]="userList" [nzScroll]="{ x: tableFilterArr.length * 140 + 80 + 'px', y: '50vh'}"
                            [nzFrontPagination]="false" [nzTotal]="totalCount" [nzPageSize]="pageSize"
                            [nzPageIndex]="pageIndex" (nzQueryParams)="onPageNumberChange('segmentTable' , $event)"
                            [nzPaginationPosition]="'top'" [nzSimple]="true">
                            <thead>
                                <tr>
                                    <ng-container *ngFor="let column of orderColumn">
                                        <th [nzWidth]="column.width">
                                            {{column.label }}
                                        </th>
                                    </ng-container>
                                </tr>
                            </thead>
                            <tbody>
                                <tr id="segmentTable" *ngFor="let item of userListTable.data ;  let i=index">
                                    <ng-container *ngFor="let field of orderColumn; let y = index">
                                        <td [nzLeft]="false" *ngIf="tableFilterArr.indexOf(field?.label) >= 0">
                                            <div *ngIf="field?.name == 'name'" class="">
                                                {{ item[field?.name] ? item[field?.name] : "-" }}
                                            </div>
                                            <div *ngIf="field?.dataType == 'tags'">
                                                <div class="table_tags"
                                                    [ngClass]="{'show_tags' : viewMoreArr.indexOf(i) > -1}">
                                                    <span *ngFor="let tag of item[field?.name]">
                                                        {{ tag ? tag?.name + ", " : "-" }}
                                                    </span>
                                                    <span *ngIf="item[field?.name].length == 0">-</span>
                                                </div>
                                                <span class="show_more_span" (click)="viewMore(i)"
                                                    *ngIf="item[field?.name].length > 1">
                                                    show {{viewMoreArr.indexOf(i) > -1 ? 'less' : 'more'}}
                                                </span>
                                            </div>
                                            <div *ngIf="field?.dataType == 'multi-select'">
                                                <span *ngFor="let tag of item[field?.name]">
                                                    {{ tag ? tag + ", " : "-" }}
                                                </span>
                                            </div>
                                            <div *ngIf="field?.dataType == 'date'">
                                                {{
                                                item[field?.name]
                                                ? moment(item[field?.name]).format("DD-MM-YYYY")
                                                : "-"
                                                }}
                                            </div>
                                            <div *ngIf="field?.name != 'name' &&
                                            field?.dataType != 'tags' &&
                                            field?.dataType != 'multi-select' &&
                                            field?.dataType != 'date' &&
                                            field?.dataType != 'phone-number'" class="">
                                                {{ item[field?.name] ? item[field?.name] : "-" }}
                                            </div>
                                            <div *ngIf="field?.dataType == 'phone-number'" class="whatsapp_icon">
                                                {{ item[field?.name] ? item[field?.name] : "-" }}
                                                <!-- <img *ngIf="item['isContactHasWhatsapp']"
                                                    src="../../../../assets/images/chat/whatsapp_icon.png" alt="" /> -->
                                            </div>
                                        </td>
                                    </ng-container>
                                </tr>
                            </tbody>
                            <ngx-spinner bdColor="rgb(255,255,255)" size="medium" color="#000000"
                                type="ball-clip-rotate-pulse" [fullScreen]="false">
                            </ngx-spinner>
                        </nz-table>
                    </div>
                </div>

                <div class="mt-3 bottom_button_section">
                    <div class="bottom_section">
                        <div class="reset_check_btn">
                            <button (click)="toContacts()" type="button" class="sighup_button mr-3" nz-button
                                nzType="default">Cancel</button>
                            <button [nzLoading]="addingSegment" (click)="addSegment(false)" type="button"
                                class="sighup_button" nz-button nzType="primary">
                                {{isEdit ? 'Update' : 'Save'}}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
            <!-- </div>
            </div> -->

            <ngx-spinner *ngIf="mainLoader" bdColor="rgb(255,255,255)" size="medium" color="#000000"
                type="ball-clip-rotate-pulse" [fullScreen]="false">
            </ngx-spinner>
        </nz-card>
    </div>
</div>