import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { CommonApiService } from "src/app/services/common-api.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-update-wa-data",
  templateUrl: "./update-wa-data.component.html",
  styleUrls: ["./update-wa-data.component.css"],
})
export class UpdateWaDataComponent implements OnInit {
  waModal: boolean;
  waForm!: FormGroup;
  submitting = false;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private apiService: CommonApiService
  ) {}

  ngOnInit(): void {
    this.waModal = true;

    this.waForm = this.fb.group({
      phoneNoId: ["", [Validators.required]],
      userAccessToken: ["", [Validators.required]],
      waBAId: ["", [Validators.required]],
    });
  }

  updateWA() {
    if (this.waForm.valid) {
      // console.log(this.waForm.value);
      this.submitting = true;

      let options = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
        },
      };

      var formData = {
        phoneNoId: this.waForm.value.phoneNoId,
        userAccessToken: this.waForm.value.userAccessToken,
        waBAId: this.waForm.value.waBAId,
      };

      this.apiService
        .commonPostMethod(
          environment.url + "organizations/update-wa-data",
          formData,
          options
        )
        .subscribe(
          (response: any) => {
            // console.log(response);
            this.router.navigate(["/campaign"]);
            this.submitting = false;
          },
          (err: any) => {
            console.log(err);
            this.submitting = false;
          }
        );
    } else {
      Object.values(this.waForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  closeOtpModal() {
    this.router.navigate(["/campaign"]);
  }
}
