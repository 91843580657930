import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import * as moment from "moment";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { AlertServiceService } from "src/app/services/alert-service.service";
import { CommonApiService } from "src/app/services/common-api.service";
import { NavService } from "src/app/services/nav.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-working-hours",
  templateUrl: "./working-hours.component.html",
  styleUrls: ["./working-hours.component.css"],
})
export class WorkingHoursComponent implements OnInit {
  moment: any = moment;
  daysArr: any = [
    { name: "Monday", label: "monday" },
    { name: "Tuesday", label: "tuesday" },
    { name: "Wednesday", label: "wednesday" },
    { name: "Thursday", label: "thursday" },
    { name: "Friday", label: "friday" },
    { name: "Saturday", label: "saturday" },
    { name: "Sunday", label: "sunday" },
  ];

  extraFields: any = [];

  workingHoursForm: FormGroup;

  updating: boolean = false;

  mainUserData: any;

  private subscription: Subscription;

  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private apiService: CommonApiService,
    private spinner: NgxSpinnerService,
    private navService: NavService,
    private alertService: AlertServiceService
  ) {}

  ngOnInit(): void {
    this.spinner.show();
    this.getMainUserData();
    this.workingHoursForm = this.fb.group({
      isHolidayMode: [false],
      mondayActive: [false],
      tuesdayActive: [false],
      wednesdayActive: [false],
      thursdayActive: [false],
      fridayActive: [false],
      saturdayActive: [false],
      sundayActive: [false],
      mondayStart: [null],
      tuesdayStart: [null],
      wednesdayStart: [null],
      thursdayStart: [null],
      fridayStart: [null],
      saturdayStart: [null],
      sundayStart: [null],
      mondayEnd: [null],
      tuesdayEnd: [null],
      wednesdayEnd: [null],
      thursdayEnd: [null],
      fridayEnd: [null],
      saturdayEnd: [null],
      sundayEnd: [null],
    });

    this.fetchData();
  }

  getMainUserData() {
    this.subscription = this.navService.mainUserData.subscribe(
      (response: any) => {
        if (response) {
          this.mainUserData = response.data.userData;
        }
      }
    );
  }

  fetchData() {
    this.spinner.show();
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(environment.url + "settings/get", {}, options)
      .subscribe(
        (response: any) => {
          // console.log(response?.data?.settings?.wh);
          this.setFormValue(response?.data?.settings?.wh);
        },
        (error: any) => {
          console.log(error);
          this.spinner.hide();
        }
      );
  }

  setFormValue(value: any) {
    // this.workingHoursForm.reset();
    this.workingHoursForm?.get("isHolidayMode").setValue(value?.isHolidayMode);
    for (let i = 0; i < value?.days?.length; i++) {
      const e = value?.days[i];
      this.workingHoursForm.get(e?.day + "Active").setValue(e?.isOpen);
      if (e?.times?.[0]?.[0] && e?.times?.[0]?.[0] != "Invalid date") {
        this.workingHoursForm
          .get(e?.day + "Start")
          .setValue(moment(e?.times?.[0]?.[0], "h:mma").toDate());
        this.workingHoursForm
          .get(e?.day + "End")
          .setValue(moment(e?.times?.[0]?.[1], "h:mma").toDate());
      }
      if (e?.times?.length > 1) {
        if (this.workingHoursForm.hasOwnProperty(e?.day + "Start2")) {
          this.workingHoursForm
            .get(e?.day + "Start2")
            .setValue(moment(e?.times?.[1]?.[0], "h:mma").toDate());
          this.workingHoursForm
            .get(e.day + "End2")
            .setValue(moment(e?.times?.[1]?.[1], "h:mma").toDate());
        } else {
          this.addExtra(
            { label: e?.day },
            e?.times?.[1]?.[0],
            e?.times?.[1]?.[1]
          );
        }
        // this.addExtra({ label: e.day }, e.times[1][1]);
      }
    }

    this.spinner.hide();
  }

  validateInputs() {
    if (
      !this.mainUserData?.accessTo?.isExpired &&
      !this.mainUserData?.accessTo?.sandboxId
    ) {
      if (this.workingHoursForm.valid) {
        this.updating = true;
        var validated: boolean = false;
        // console.log(this.workingHoursForm.value);
        var reqData: any = {
          isHolidayMode: this.workingHoursForm.value.isHolidayMode,
          days: [],
        };

        var daysData: any = {};

        for (let i = 0; i < this.daysArr.length; i++) {
          const e = this.daysArr[i];
          if (
            !this.workingHoursForm.value[e.label + "Active"] ||
            (this.workingHoursForm.value[e.label + "Active"] &&
              this.workingHoursForm.value[e.label + "Start"] &&
              this.workingHoursForm.value[e.label + "End"])
          ) {
            daysData = {
              day: e.label,
              isOpen: this.workingHoursForm.value[e.label + "Active"],
              times: [
                [
                  moment(this.workingHoursForm.value[e.label + "Start"]).format(
                    "h:mmA"
                  ),
                  moment(this.workingHoursForm.value[e.label + "End"]).format(
                    "h:mmA"
                  ),
                ],
              ],
            };

            if (
              this.extraFields.indexOf(e.label) > -1 &&
              this.workingHoursForm.value[e.label + "Start2"] &&
              this.workingHoursForm.value[e.label + "End2"]
            ) {
              validated = true;
              var secondTime = [
                moment(this.workingHoursForm.value[e.label + "Start2"]).format(
                  "h:mmA"
                ),
                moment(this.workingHoursForm.value[e.label + "End2"]).format(
                  "h:mmA"
                ),
              ];
              daysData.times.push(secondTime);
            } else if (this.extraFields.indexOf(e.label) > -1) {
              validated = false;
              this.updating = false;
              // console.log(
              //   e.label + "Start2",
              //   this.workingHoursForm.value[e.label + "Start2"]
              // );
              this.toastr.error("Please fill all required data  multi!", "", {
                timeOut: 2500,
              });
              break;
            } else {
              validated = true;
            }

            reqData.days.push(daysData);
          } else {
            this.updating = false;
            validated = false;
            this.toastr.error("Please fill all required data!", "", {
              timeOut: 2500,
            });
            break;
          }
        }

        if (validated) {
          this.updating = true;
          // console.log(reqData);
          this.updateWorkingHours(reqData);
        }
      }
    } else if (this.mainUserData?.accessTo?.sandboxId) {
      var errHead: any = "You are using Sandbox account!";
      var errBody: any = "Please Subscribe any Plan to Add Working hours!..";
      this.alertService.freeTrialWarning(errHead, errBody);
    }
  }

  addExtra(day: any, start: any, end: any) {
    // console.log(day);
    if (this.extraFields.indexOf(day.label) == -1) {
      this.workingHoursForm.addControl(
        day.label + "Start2",
        this.fb.control(start ? moment(start, "h:mma").toDate() : null)
      );
      this.workingHoursForm.addControl(
        day.label + "End2",
        this.fb.control(end ? moment(end, "h:mma").toDate() : null)
      );
      this.extraFields.push(day.label);
    }
  }

  deleteExtra(day: any) {
    if (this.extraFields.indexOf(day.label) > -1) {
      this.extraFields.splice(this.extraFields.indexOf(day.label), 1);
      this.workingHoursForm.removeControl(day.label + "Start2");
      this.workingHoursForm.removeControl(day.label + "End2");
    }
  }

  updateWorkingHours(reqData: any) {
    // console.log(reqData);
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url + "settings/update-working-hours",
        reqData,
        options
      )
      .subscribe(
        (response: any) => {
          // console.log(response);
          this.fetchData();
          this.toastr.success(response.message, "", {
            timeOut: 2500,
          });
          this.updating = false;
        },
        (error: any) => {
          console.log(error);
          this.updating = false;
        }
      );
  }

  ngOnDestroy(): void {
    // console.log("api key destroy..");
    this.subscription.unsubscribe();
  }
}
