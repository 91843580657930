import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { CommonApiService } from "src/app/services/common-api.service";
import { environment } from "src/environments/environment";
import { ChatUser } from "../shared/chat.model";
import { USERS } from "../shared/data";
import * as moment from "moment";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { AlertServiceService } from "src/app/services/alert-service.service";
import { NavService } from "src/app/services/nav.service";
import { contactBGs, countryCodes } from "../shared/messageData";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";

@Component({
  selector: "app-chat-users",
  templateUrl: "./chat-users.component.html",
  styleUrls: ["./chat-users.component.css"],
})
export class ChatUsersComponent implements OnInit {
  loggedInUser: any = {};
  userList: any = [];
  @Input() selectedUser!: any;
  searchUser: string = "";
  selected: any;
  showSpinner = false;
  moment: any = moment;
  today = moment(new Date()).format("YYYY-MM-DD");

  addUserModal: boolean = false;

  @Input() socketData: any;
  @Input() closeChat: any;

  newUserForm!: FormGroup;
  customersSpinner: boolean = false;

  userFilters: any = [
    { value: "all", role: "admin-owner-teammate" },
    { value: "mine", role: "admin-owner" },
    { value: "important", role: "admin-owner-teammate" },
    { value: "pinned", role: "admin-owner-teammate" },
    { value: "unread", role: "admin-owner-teammate" },
  ];

  // custom field section starts

  fieldData: any = [];
  formControlObj: any = {};
  singleCustomerForm!: FormGroup;
  allTags: any = [];

  mainUserData: any = {};

  listSearch: any = "";

  dialCode: any = "91";

  countryCodes: any = countryCodes;

  // pagination and search

  pageIndex: any = 1;
  totalCount: number;

  //On selecting new user
  @Output() selectUser: EventEmitter<ChatUser> = new EventEmitter();
  @Output() initUser: EventEmitter<ChatUser> = new EventEmitter();
  @Output() stateManagementData: EventEmitter<ChatUser> = new EventEmitter();

  // pagination

  chatCustomerPage: any = 1;
  contactsLoading: boolean = false;

  contactBgs: any = contactBGs;

  private subscription: Subscription;

  constructor(
    private apiService: CommonApiService,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private alertService: AlertServiceService,
    private navService: NavService,
    private router: Router
  ) {}

  ngOnInit(): void {
    // Get users for chat

    this.chatCustomerPage = 1;
    this.getMainUserData();
    this._fetchUsers();
    this.getAssignUserList();
    this.getAllTags();
    // this.getFieldData();

    this.newUserForm = this.fb.group({
      firstName: ["", [Validators.required]],
      countryCode: ["+91", [Validators.required]],
      mobileNumber: ["", [Validators.required]],
    });
    if (window.innerWidth > 992) {
      this.activateUser(this.userList[0], 0);
    }
  }

  ngOnChanges(): void {
    // console.log(this.socketData);
    if (this.socketData && !this.searchUser) {
      // console.log(this.socketData);
      if (
        this.selected?._id != this?.socketData?.chat?.with &&
        this?.socketData?.chat?.conversationType == "received"
      ) {
        this.playSound("../../../../../assets/audio/iphone_sound.mp3");
      }
      if (this.socketData.type == "messages") {
        this.socketData.customerLatestMessage[0].chats.message.data =
          typeof this.socketData.customerLatestMessage[0].chats.message.data ==
          "string"
            ? JSON.parse(
                this.socketData?.customerLatestMessage[0]?.chats?.message?.data
              )
            : "";

        var targetIndex = this.userList.findIndex((e: any) => {
          return e._id == this.socketData?.chat?.with;
        });
        //console.log(targetIndex);
        if (targetIndex != -1) {
          this.userList.splice(targetIndex, 1);
        }

        if (
          targetIndex > -1 ||
          (targetIndex == -1 && this.customFilter == "all")
        ) {
          this.userList = this.socketData.customerLatestMessage.concat(
            this.userList
          );
        }

        //console.log(this.userList);

        for (let k = 0; k < this.userList.length; k++) {
          if (this.selected?._id == this.userList[k]._id) {
            this.userList[k].chats.statuses.read.isRead = true;
          }
        }
      }
    }

    this.socketData = null;

    if (this.closeChat && this.customFilter == "important") {
      this.chatCustomerPage = 1;
      this._fetchUsers();
    }
  }

  _fetchUsers(): void {
    // this.userList = messageFormats;
    if (this.chatCustomerPage == 1) {
      this.showSpinner = true;
      this.spinner.show();
    }
    var data: any = {
      responsive: this.filterText,
      inboxStatus: this.statusText,
      search: this.searchUser,
    };

    if (this.customFilter && this.customFilter != "all") {
      data.viewType = this.customFilter;
    }

    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url +
          "chat/get-customers-list?page=" +
          this.chatCustomerPage,
        data,
        options
      )
      .subscribe(
        (response: any) => {
          // console.log(response);
          if (this.chatCustomerPage == 1) {
            this.userList = response?.data?.customers;
            if (this.userList[0]?.chats?.statuses && window.innerWidth > 992) {
              this.userList[0].chats.statuses.read.isRead = true;
            }
            for (let i = 0; i < this.userList.length; i++) {
              this.userList[i].chats.message.data = this?.userList[i]?.chats
                .message.data
                ? JSON.parse(this?.userList[i]?.chats?.message?.data)
                : "";
            }
            // console.log(this.userList);
            if (
              !this.closeChat &&
              this.mainUserData.role != "teammate" &&
              !this.searchUser &&
              !this.selected
            ) {
              this.initUser.emit(this.userList[0]);
              if (window.innerWidth > 992) {
                this.selected = this.userList[0];
              }
            } else if (!this.closeChat && !this.searchUser && !this.selected) {
              for (let a = 0; a < this.userList.length; a++) {
                if (
                  this.userList[a].responsive == this.mainUserData._id &&
                  window.innerWidth > 992
                ) {
                  this.initUser.emit(this.userList[a]);
                  this.selected = this.userList[a];
                  return;
                }
              }
            }
          } else {
            for (let i = 0; i < response?.data?.customers.length; i++) {
              response.data.customers[i].chats.message.data = response?.data
                ?.customers[i]?.chats.message.data
                ? JSON.parse(response?.data?.customers[i]?.chats?.message?.data)
                : "";
            }
            this.userList = this.userList?.concat(response?.data?.customers);
            this.contactsLoading = false;
          }

          this.spinner.hide();
          this.showSpinner = false;
          this.closeChat = false;
        },
        (error: any) => {
          if (
            error?.error?.message == "jwt expired" ||
            error?.error?.message == "jwt must be provided" ||
            error?.error?.message == "jwt malformed" ||
            error?.error?.message == "invalid signature" ||
            error?.error?.message == "Given token user does not exist." ||
            error?.error?.message == "invalid token"
          ) {
            this.navService.tokenExpired();
          }
          //console.log(error);
          this.spinner.hide();
          this.showSpinner = false;
          this.closeChat = false;
          this.contactsLoading = false;
        }
      );
  }

  /**
   * changes active user
   * @param user chat user
   */
  activateUser(user: any, index: any): void {
    if (this.userList[index]?.chats?.statuses) {
      this.userList[index].chats.statuses.read.isRead = true;
    }

    if (window.innerWidth > 992) {
      this.selectedUser = user;
      this.selected = user;
      this.selectUser.emit(this.selectedUser);
    } else {
      this.router.navigate(["/chat/area", user?._id, user?.name]);
    }
    // this.socketData = null;
  }

  /**
   * search user from list of users
   */
  search(): void {
    this.userList =
      this.searchUser === ""
        ? [...USERS]
        : [...USERS].filter(
            (u) =>
              u.name!.toLowerCase().indexOf(this.searchUser.toLowerCase()) >= 0
          );
  }

  // filter section

  @Input() filterText: any;
  @Input() statusText: any;
  assignUsersList: any = [];
  @Input() customFilter: any;

  getAssignUserList() {
    var options: any = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };
    this.apiService
      .commonGetMethod(
        environment.url + "auth/get-all-user-data?projection=name",
        options
      )
      .subscribe(
        (response: any) => {
          // //console.log(response);
          this.assignUsersList = response.data.users;
        },
        (error) => {
          console.log(error);
          if (
            error?.error?.message == "jwt expired" ||
            error?.error?.message == "jwt must be provided" ||
            error?.error?.message == "jwt malformed" ||
            error?.error?.message == "invalid signature" ||
            error?.error?.message == "Given token user does not exist." ||
            error?.error?.message == "invalid token"
          ) {
            this.navService.tokenExpired();
          }
          this.spinner.hide();
        }
      );
  }

  filterUsers() {
    // this.filterText = "all";
    // this.statusText = "all";
    // this.customFilter = "all";
    this.chatCustomerPage = 1;
    if (window.innerWidth < 992) {
      const filter: any = {
        custom: this.customFilter,
        text: this.filterText,
        status: this.statusText,
      };
      this.stateManagementData.emit(filter);
    }
    this._fetchUsers();
  }

  checkOptionsOne: boolean;

  pinChat(event: any, userData: any) {
    event.stopPropagation();

    if (this.mainUserData?.accessTo?.isExpired) {
      this.alertService.warning();
    } else {
      let options = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
        },
      };

      var reqObj: any = {
        customerId: userData?._id,
      };

      var toastrMsg: string = "";

      if (this?.mainUserData?.pinnedCustomers?.indexOf(userData?._id) > -1) {
        reqObj.action = "unpin";
        toastrMsg = "customer unpinned!..";
      } else {
        reqObj.action = "pin";
        toastrMsg = "customer pinned!..";
      }

      this.apiService
        .commonPostMethod(
          environment.url + "customers/customize",
          reqObj,
          options
        )
        .subscribe(
          (response: any) => {
            //console.log(response);
            // this.getMainUserData();
            if (reqObj.action == "unpin" && this.customFilter == "pinned") {
              this.closeChat = true;
              this.chatCustomerPage = 1;
              this._fetchUsers();
            }
            this.navService.getMainUserData();

            this.toastr.success(toastrMsg, "", {
              timeOut: 2500,
            });
          },
          (error) => {
            this.toastr.error("Customer already pinned!..", "", {
              timeOut: 2500,
            });
            //console.log(error);
          }
        );
    }
    // this.userList[index].chats.pinned = !this.userList[index].chats.pinned;
    // alert("pinned");
  }

  // add user section

  allUsers: any = [];
  addNewForm: boolean = false;

  closeAddUserModal() {
    this.listSearch = "";
    if (this.addNewForm) {
      this.addNewForm = false;
    } else {
      this.addUserModal = false;
    }
  }

  showAddUserModal() {
    this.spinner.show();
    this.getAllUsers();
    this.addNewForm = false;
    this.listSearch = "";
    this.dialCode = "91";
    this.addUserModal = true;
  }

  getAllUsers() {
    this.customersSpinner = true;
    // this.spinner.show();
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url + "customers?page=" + this.pageIndex,
        { filterValue: this.listSearch },
        options
      )
      .subscribe(
        (response: any) => {
          //console.log(response);
          if (this.listSearch == "") {
            this.totalCount = response?.data?.pagination?.totalCount;
          }
          if (this.pageIndex == 1) {
            this.allUsers = response.data.customers;
          } else if (this.pageIndex > 1) {
            this.allUsers = this.allUsers.concat(response.data.customers);
          }

          this.spinner.hide();
          this.customersSpinner = false;
        },
        (err: any) => {
          //console.log(err);
          this.spinner.hide();
          this.customersSpinner = false;
        }
      );
  }

  playSound(url: any) {
    const audio = new Audio(url);
    audio.play();
  }

  addUserToChat(userData: any) {
    // //console.log(userData);
    var targetIndex = this.userList.findIndex((e: any) => {
      return e._id == userData._id;
    });
    if (targetIndex == -1) {
      this.userList = [userData].concat(this.userList);
      targetIndex = 0;
    }
    //console.log(this.userList[targetIndex]);

    if (this?.userList[targetIndex]?.chats?.statuses) {
      this.userList[targetIndex].chats.statuses.read.isRead = true;
    }

    this.selectedUser = this.userList[targetIndex];
    this.selected = this.userList[targetIndex];
    this.selectUser.emit(this.selectedUser);
    this.closeAddUserModal();
  }

  applyFilter(filterSelected: any) {
    // //console.log(filterSelected);
    if (filterSelected == "all") {
      this.filterText = "all";
      this.statusText = "all";
    }
    this.chatCustomerPage = 1;
    this.customFilter = filterSelected;
    if (window.innerWidth < 992) {
      const filter: any = {
        custom: this.customFilter,
        text: this.filterText,
        status: this.statusText,
      };
      this.stateManagementData.emit(filter);
    }
    this._fetchUsers();
  }

  // create new contact section

  addNewContact() {
    if (this?.mainUserData?.accessTo?.sandboxId) {
      if (this.totalCount >= 3) {
        var errHead: any =
          "You can add maximum 3 Customers by Sandbox account!";
        var errBody: any = "Please Subscribe any Plan to Add more..!";
        this.alertService.freeTrialWarning(errHead, errBody);
      } else {
        this.getFieldData();
      }
    } else {
      this.getFieldData();
    }
  }

  getFieldData() {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url + "customer-field/get?filter=for-csv-excel-upload",
        {},
        options
      )
      .subscribe(
        (response: any) => {
          this.fieldData = response.data.customerFields;
          // console.log(this.fieldData);
          for (let s = 0; s < this.fieldData.length; s++) {
            if (this.fieldData[s].dataType == "switch") {
              this.formControlObj[this.fieldData[s].name] = [true];
            } else if (this.fieldData[s].isMandatory) {
              this.formControlObj[this.fieldData[s].name] = [
                null,
                [Validators.required],
              ];
            } else {
              this.formControlObj[this.fieldData[s].name] = [null];
            }
          }
          this.singleCustomerForm = this.fb.group(this.formControlObj);
          this.creatingCustomer = false;
          this.addNewForm = true;
        },
        (error) => {
          //console.log(error);
          if (error?.error?.message) {
            this.toastr.error(error?.error?.message, "", {
              timeOut: 2500,
            });
          } else {
            this.toastr.error(error?.message, "", {
              timeOut: 2500,
            });
          }
        }
      );
  }

  creatingCustomer: boolean = false;

  createCustomer() {
    if (this.singleCustomerForm.valid) {
      this.singleCustomerForm.value.phoneNo = parseFloat(
        this.dialCode + this.singleCustomerForm.value.phoneNo
      );
      //console.log(this.singleCustomerForm.value);
      this.creatingCustomer = true;
      let options = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
        },
      };

      var tempArray = Object.keys(this.singleCustomerForm.value);
      //console.log(tempArray);
      for (let n = 0; n < tempArray.length; n++) {
        if (this.singleCustomerForm.value[tempArray[n]] == null) {
          this.singleCustomerForm.value[tempArray[n]] = "";
        }
        if (Array.isArray(this.singleCustomerForm.value[tempArray[n]])) {
          // //console.log(tempArray[n]);
          this.singleCustomerForm.value[tempArray[n]] =
            this.singleCustomerForm.value[tempArray[n]].toString();
        }

        if (this.singleCustomerForm.value[tempArray[n]] instanceof Date) {
          // console.log(tempArray[n]);
          this.singleCustomerForm.value[tempArray[n]] = moment(
            this.singleCustomerForm.value[tempArray[n]]
          ).format("DD-MM-YYYY hh:mm A");
        }
      }
      var formData = this.singleCustomerForm.value;

      this.apiService
        .commonPostMethod(
          environment.url + "customers/create",
          formData,
          options
        )
        .subscribe(
          (response: any) => {
            // console.log(response);
            if (response.status == "success") {
              // if (response?.data?.newCustomer?.isContactHasWhatsapp) {
              this.toastr.success(response.message, "", {
                timeOut: 2500,
              });
              this.addUserToChat(response?.data?.newCustomer);
              // } else {
              //   this.toastr.error(
              //     "There is No Whatsapp Account in given number",
              //     "",
              //     {
              //       timeOut: 2500,
              //     }
              //   );
              // }
            }
            this.creatingCustomer = false;
            this.addUserModal = false;
          },
          (error) => {
            console.log(error);
            this.creatingCustomer = false;
            if (error?.error?.message) {
              this.toastr.error(error?.error?.message, "", {
                timeOut: 2500,
              });
            } else {
              this.toastr.error(error?.message, "", {
                timeOut: 2500,
              });
            }
          }
        );
    } else {
      this.toastr.error(
        "Please provide all required information to continue.",
        "",
        {
          timeOut: 2500,
        }
      );
      Object.values(this.singleCustomerForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  getAllTags() {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(environment.url + "tag?noPagination", {}, options)
      .subscribe(
        (response: any) => {
          this.allTags = response.data.tags;
          // console.log(this.allTags);
        },
        (error: any) => {
          //console.log(error);
        }
      );
  }

  getMainUserData() {
    this.subscription = this.navService.mainUserData.subscribe(
      (response: any) => {
        if (response) {
          // //console.log(response);
          this.mainUserData = response.data.userData;
        }
      }
    );
    // let options = {
    //   headers: {
    //     Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
    //   },
    // };

    // this.apiService
    //   .commonGetMethod(environment.url + "auth/get-user-data", options)
    //   .subscribe(
    //     (response: any) => {
    //       this.mainUserData = response.data.userData;
    //       //console.log(response);
    //     },
    //     (error: any) => {
    //       //console.log(error);
    //     }
    //   );
  }

  // search and pagination

  searchUserList() {
    this.pageIndex = 1;
    this.getAllUsers();
  }

  userScrollPagination() {
    // if (this.allUsers.length < this.totalCount) {
    this.pageIndex = this.pageIndex + 1;
    this.getAllUsers();
    // }
  }

  // scroll pagination

  scrolledDown() {
    this.contactsLoading = true;
    this.chatCustomerPage++;
    this._fetchUsers();
    // console.log("scrolled to bottom");
  }

  searchUsers() {
    this.chatCustomerPage = 1;
    this._fetchUsers();
  }

  ngOnDestroy(): void {
    // console.log("api key destroy..");
    this.subscription.unsubscribe();
  }
}
