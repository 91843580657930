import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CommonApiService } from "src/app/services/common-api.service";
import { environment } from "src/environments/environment";
import * as moment from "moment";
import { NgxSpinnerService } from "ngx-spinner";
import { NavService } from "src/app/services/nav.service";
import { AlertServiceService } from "src/app/services/alert-service.service";
import { Subscription } from "rxjs";
import { NzModalService } from "ng-zorro-antd/modal";
import { ToastrService } from "ngx-toastr";
import { differenceInCalendarDays } from "date-fns";

@Component({
  selector: "app-campaign-details",
  templateUrl: "./campaign-details.component.html",
  styleUrls: ["./campaign-details.component.css"],
})
export class CampaignDetailsComponent implements OnInit {
  campaignData: any;
  moment: any = moment;
  liveUrl = environment.imageUrl;
  orderColumn = [
    // {
    //   title: "S.No",
    //   width: "50px",
    // },
    {
      title: "Name",
      width: "120px",
    },
    {
      title: "Number",
      width: "90px",
    },
    // {
    //   title: "Status",
    //   width: "50px",
    // },
    {
      title: "Sent",
      width: "140px",
    },
    {
      title: "Delivered",
      width: "140px",
    },
    {
      title: "Read",
      width: "140px",
    },
    // {
    //   title: "Replied",
    //   width: "140px",
    // },
    {
      title: "Remarks",
      width: "100px",
    },
  ];
  analytics: any = {};
  mainUserData: any = {};

  tableHeight: any = "";

  pageReloading: boolean = true;

  campaignId: string = "";

  // pagination

  totalCount: number;
  pageSize: number = 20;
  pageIndex: number = 1;

  // filter section

  selectedFilter: any = "all";
  campaignFilter: any = "all";

  repliedStatus: any = "replied";

  campaignDuration: any;

  private subscription: Subscription;

  rescheduleModal: boolean = false;
  scheduleType: string = "";
  scheduleTime: any = new Date();
  today = new Date();
  disabledDate = (current: Date): boolean =>
    // Can not select days before today and today
    differenceInCalendarDays(this.today, current) > 0;

  constructor(
    private route: ActivatedRoute,
    private apiService: CommonApiService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private navService: NavService,
    private alertService: AlertServiceService,
    private modal: NzModalService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.getMainUserData();
    this.route.params.subscribe((params: any) => {
      // console.log(params);
      this.pageIndex = 1;
      this.campaignId = params.id;
      this.fetchData();
    });
  }

  fetchData() {
    this.spinner.show();
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };
    var data = {
      campaignId: this.campaignId,
    };

    this.apiService
      .commonPostMethod(
        environment.url +
          "campaigns/detail?page=" +
          this.pageIndex +
          "&limit=" +
          this.pageSize,
        data,
        options
      )
      .subscribe(
        (response: any) => {
          for (
            let j = 0;
            j < response.data?.detail?.messageComponents?.length;
            j++
          ) {
            if (response.data.detail.messageComponents[j].text) {
              response.data.detail.messageComponents[j].text = this.format_text(
                response.data.detail.messageComponents[j]?.text
              );
            }
          }
          this.campaignData = response.data.detail;
          this.campaignDuration = moment(this.campaignData?.finishedAt).diff(
            moment(this.campaignData?.scheduledAt),
            "seconds"
          );
          // console.log(this.campaignDuration);
          // this.campaignDuration = 80;
          // this.pageIndex = 1;
          this.totalCount = response?.data?.pagination?.totalCount;
          this.pageSize = response?.data?.pagination?.limit;
          // this.totalCount = this.campaignData.data.length;
          // this.pageSize = 20;
          this.analytics = response.data?.analytics;

          // console.log(response);
          this.spinner.hide();
          this.pageReloading = false;
        },
        (err) => {
          console.log(err);
          if (
            err?.error?.message == "jwt expired" ||
            err?.error?.message == "jwt must be provided" ||
            err?.error?.message == "jwt malformed" ||
            err?.error?.message == "invalid signature" ||
            err?.error?.message == "Given token user does not exist." ||
            err?.error?.message == "invalid token"
          ) {
            this.navService.tokenExpired();
          }
          this.spinner.hide();
          this.pageReloading = false;
        }
      );
  }

  duplicateCampaign() {
    if (!this.mainUserData?.accessTo?.isExpired) {
      if (this.mainUserData?.accessTo?.sandboxId) {
        var errHead: any = "Maximum 1 campaign allowed in sandbox mode!";
        var errBody: any = "Please Subscribe any Plan to create campaign..!";
        this.alertService.freeTrialWarning(errHead, errBody);
      } else {
        this.router.navigate(["/campaign/create-campaign", this.campaignId]);
      }
    }
  }

  // TABLE SCROLL TOP ON PAGE CHANGE

  onPageNumberChange(id: any, event: any) {
    if (this.pageIndex != event.pageIndex) {
      this.pageIndex = event.pageIndex;
      document.getElementById(id).scrollIntoView();

      if (this.selectedFilter != "all") {
        this.applyFilter();
      } else {
        this.fetchData();
      }
    }

    // setTimeout(() => {
    //   window.scroll(0, 0);
    // }, 300);
  }

  format_text(text) {
    return text
      .replace(/(?:\*)(?:(?!\s))((?:(?!\*|\n).)+)(?:\*)/g, "<b>$1</b>")
      .replace(/(?:_)(?:(?!\s))((?:(?!\n|_).)+)(?:_)/g, "<em>$1</em>")
      .replace(/(?:~)(?:(?!\s))((?:(?!\n|~).)+)(?:~)/g, "<s>$1</s>")
      .replace(/(?:--)(?:(?!\s))((?:(?!\n|--).)+)(?:--)/g, "<u>$1</u>")
      .replace(/(?:```)(?:(?!\s))((?:(?!\n|```).)+)(?:```)/g, "<tt>$1</tt>");
  }

  getMainUserData() {
    this.subscription = this.navService.mainUserData.subscribe(
      (response: any) => {
        if (response) {
          // console.log(response);
          this.mainUserData = response.data.userData;
          if (response?.data?.userData?.accessTo?.isExpired) {
            this.router.navigate(["/campaign"]);
          }
          if (
            response?.data?.userData?.accessTo?.sandboxId &&
            window.innerWidth > 992
          ) {
            this.tableHeight = "45vh";
          } else {
            this.tableHeight = "50vh";
          }
        }
      }
    );
  }

  reloadPage() {
    this.pageReloading = true;
    this.pageIndex = 1;
    if (this.selectedFilter != "all") {
      this.applyFilter();
    } else {
      this.fetchData();
    }
  }

  filterClick() {
    this.pageIndex = 1;
    this.applyFilter();
  }

  applyFilter() {
    this.selectedFilter = this.selectedFilter;
    this.spinner.show();

    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url +
          "campaigns/get-insights?page=" +
          this.pageIndex +
          "&limit=" +
          this.pageSize,
        {
          campaignId: this.campaignId,
          filterType: this.selectedFilter,
        },
        options
      )
      .subscribe(
        (response: any) => {
          // console.log(response);
          this.campaignData.data = response?.data?.campaign;
          this.totalCount = response?.data?.pagination?.totalCount;
          this.pageSize = response?.data?.pagination?.limit;
          this.spinner.hide();
        },
        (err) => {
          console.log(err);
          this.spinner.hide();
        }
      );
  }

  getAll() {
    this.selectedFilter = "all";
    this.pageIndex = 1;
    this.fetchData();
  }

  newFilter() {
    if (this.selectedFilter == "all") {
      this.pageIndex = 1;
      this.fetchData();
    } else {
      this.filterClick();
    }
  }

  replyFilter(filter: string) {
    this.repliedStatus = filter;
    // console.log(this.repliedStatus);
  }

  changePageSize(size: any) {
    this.pageSize = size;
    this.newFilter();
  }

  // checkbox section

  setOfCheckedId = new Set<number>();
  checked = false;
  selectedContacts: any = [];

  onAllChecked(value: boolean): void {
    this.campaignData?.data.forEach((item) =>
      this.updateCheckedSet(item._id, value)
    );
    this.refreshCheckedStatus();
  }

  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  refreshCheckedStatus(): void {
    this.checked = this.campaignData?.data.every((item) =>
      this.setOfCheckedId.has(item._id)
    );
    // this.indeterminate = this.listOfCurrentPageData.some(item => this.setOfCheckedId.has(item.id)) && !this.checked;
  }

  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }

    this.selectedContacts = Array.from(this.setOfCheckedId);

    // console.log(this.selectedContacts);
  }

  setMyStyles() {
    let styles = {
      "margin-right":
        115 + (this.totalCount / this.pageSize).toFixed(0).length * 5 + "px",
    };
    return styles;
  }

  getTypeOf(variable: any) {
    const type = Object.prototype.toString.call(variable).slice(8, -1);
    return type;
  }

  // cancel and reschedule campaign

  cancelCampaignAlert() {
    this.modal.confirm({
      nzOkText: "Yes",
      nzCancelText: "No",
      nzTitle: "<b>Campaign will be cancelled.</b>",
      nzContent: "Are you sure you want to cancel?",
      nzOnOk: () => this.cancelCampaign(),
      nzOnCancel: () => {},
    });
  }

  cancelCampaign() {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url + "campaigns/cancel",
        {
          campaignId: this.campaignId,
        },
        options
      )
      .subscribe(
        (response: any) => {
          console.log(response);
          this.toastr.success(response.message, "", {
            timeOut: 2500,
          });
          this.fetchData();
        },
        (error) => {
          console.log(error);
          this.toastr.error(
            error?.error?.message ? error?.error?.message : error?.message,
            "",
            {
              timeOut: 2500,
            }
          );
        }
      );
  }

  openRescheduleModal() {
    this.scheduleTime = new Date();
    this.scheduleType = "";
    this.rescheduleModal = true;
  }

  closeRescheduleModal() {
    this.rescheduleModal = false;
  }

  changeScheduleType(type: string) {
    this.scheduleType = type;
    if (type == "now") {
      this.rescheduleCampaign();
    }
  }

  rescheduleCampaign() {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    var req: any = {
      campaignId: this.campaignId,
      publishMode: this.scheduleType,
    };

    if (this.scheduleType == "schedule") {
      req.scheduleAt = moment(this.scheduleTime).second(0).format();
    }

    this.apiService
      .commonPostMethod(environment.url + "campaigns/reschedule", req, options)
      .subscribe(
        (response: any) => {
          console.log(response);
          this.rescheduleModal = false;
          this.toastr.success(response.message, "", {
            timeOut: 2500,
          });
          this.fetchData();
        },
        (error) => {
          console.log(error);
          this.toastr.error(
            error?.error?.message ? error?.error?.message : error?.message,
            "",
            {
              timeOut: 2500,
            }
          );
        }
      );
  }

  ngOnDestroy(): void {
    // console.log("api key destroy..");
    this.subscription.unsubscribe();
  }
}
