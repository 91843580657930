import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NzModalService } from "ng-zorro-antd/modal";
import { ToastrService } from "ngx-toastr";
import { CommonApiService } from "src/app/services/common-api.service";
import { NavService } from "src/app/services/nav.service";
import { environment } from "src/environments/environment";
import { CustomMessageService } from "../dashboard/chat/shared/custom-message.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Subscription } from "rxjs";

@Component({
  selector: "app-content-page",
  templateUrl: "./content-page.component.html",
  styleUrls: ["./content-page.component.css"],
})
export class ContentPageComponent implements OnInit {
  @Input() selectedTab: any = "";
  @Input() singleSelect: boolean = false;
  @Input() showMenu: boolean = true;
  @Input() filterSelected: string = "all";
  @Output() selectedData: EventEmitter<any> = new EventEmitter();
  formatTwo = (): string =>
    `${
      this.usedSize?.usedSizeInText ? this.usedSize?.usedSizeInText : "0KB"
    } of 5GB used`;

  usedSize: any = {};
  storagePercentage: number = 0;

  fileName: string = "";
  selectedFile: any = null;
  menus: any = [
    {
      name: "CONFIGURATION",
      role: "admin-owner",
      options: [
        {
          name: "Quick Message",
          icon: "file-text",
          role: "admin-owner",
        },
        {
          name: "Media",
          icon: "file-image",
          role: "admin-owner",
        },
        // {
        //   name: "Documents",
        //   icon: "file-protect",
        //   role: "admin-owner",
        // },
        // {
        //   name: "Videos",
        //   icon: "video-camera-add",
        //   role: "admin-owner",
        // },
      ],
    },
  ];
  mainUserData: any = {};
  paths: any = ["quick-message"];
  allPaths: any = ["quick-message", "media"];

  searchKey: any = "";

  filters: any = ["all", "image", "video", "document", "audio"];
  allMedia: any[] = [];
  imageUrl: any = environment.imageUrl;

  // pagination

  pageSize: number = 25;
  pageIndex: number = 1;

  // upload section

  acceptedFiles: any[] = [
    "text/plain",
    "application/pdf",
    "application/vnd.ms-powerpoint",
    "application/msword",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "audio/aac",
    "audio/mp4",
    // 'audio/mpeg',
    "audio/amr",
    "audio/ogg",
    "video/mp4",
    "video/3gp",
    "image/png",
    "image/jpg",
    "image/jpeg",
    // "image/webp",
    // "image/svg+xml",
  ];

  uploadModal: boolean = false;
  uploadUrl: any;
  selectedMedia: any;
  uploading: boolean = false;

  // document preview section

  docPreview: boolean = false;

  docUrl = "";

  contentPage: boolean = true;

  routeSubscription: Subscription;

  // {
  //   url: "https://movicenter.com.pa/wp-content/uploads/2023/03/6sAj9nSfwxod4sxvj1v0VA1eCnJaM8Dxc0oLSNum-3.jpg",
  //   type: "image",
  // },
  // {
  //   url: "https://idestiny.in/wp-content/uploads/2022/09/iPhone_14_Pro_Deep_Purple_PDP_Image_Position-1a_Avail__en-IN.jpg",
  //   type: "image",
  // },
  // {
  //   url: "https://www.digitaltrends.com/wp-content/uploads/2022/08/iPhone-SE-2022-Starlight-Back-in-Hand.jpg?p=1",
  //   type: "image",
  // },
  // {
  //   url: "https://images.samsung.com/in/smartphones/galaxy-s23-ultra/buy/product_color_green.png",
  //   type: "image",
  // },
  // {
  //   url: "https://images.samsung.com/ca/smartphones/galaxy-s23-ultra/images/galaxy-s23-ultra-highlights-design-end-s.jpg",
  //   type: "image",
  // },
  // {
  //   url: "https://m.media-amazon.com/images/I/71ESPnb152L._AC_UF894,1000_QL80_.jpg",
  //   type: "image",
  // },
  // {
  //   url: "https://cdn.shopify.com/s/files/1/0624/8952/6501/products/Sony-xperia-5-mark-2-1-510x510.jpg?v=1680602293",
  //   type: "image",
  // },
  // {
  //   url: "https://images-cdn.ubuy.co.in/633ffa2bf37caa48be27e704-sony-xperia-5-ii-unlocked-smartphone.jpg",
  //   type: "image",
  // },
  // {
  //   url: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerEscapes.mp4",
  //   type: "video",
  // },
  // {
  //   url: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4",
  //   type: "video",
  // },
  // {
  //   url: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
  //   type: "document",
  // },
  // {
  //   url: "https://www.orimi.com/pdf-test.pdf",
  //   type: "document",
  // },
  // {
  //   url: "https://www2.cs.uic.edu/~i101/SoundFiles/gettysburg.wav",
  //   type: "audio",
  // },
  // ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: CommonApiService,
    private navService: NavService,
    private modal: NzModalService,
    private toastr: ToastrService,
    private audioService: CustomMessageService,
    private spinner: NgxSpinnerService
  ) {}

  @ViewChild("inputElement") inputElement: ElementRef | undefined;

  ngOnInit(): void {
    this.spinner.show();
    this.getMainUserData();
    this.getAllMedia();

    if (this.router.url.includes("/content")) {
      this.contentPage = true;
    } else {
      this.contentPage = false;
    }
  }

  getMainUserData() {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonGetMethod(environment.url + "auth/get-user-data", options)
      .subscribe(
        (response: any) => {
          this.mainUserData = response.data.userData;
          // console.log(this.mainUserData);
          this.getUrl();
        },
        (error: any) => {
          console.log(error);
          if (
            error?.error?.message == "jwt expired" ||
            error?.error?.message == "jwt must be provided" ||
            error?.error?.message == "jwt malformed" ||
            error?.error?.message == "invalid signature" ||
            error?.error?.message == "Given token user does not exist." ||
            error?.error?.message == "invalid token"
          ) {
            this.navService.tokenExpired();
          }
        }
      );
  }

  getAllMedia() {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    var req = { filter: this.filterSelected, searchText: this.searchKey };

    this.apiService
      .commonPostMethod(
        environment.url +
          "media/get?page=" +
          this.pageIndex +
          "&limit=" +
          this.pageSize,
        req,
        options
      )
      .subscribe(
        (response: any) => {
          console.log(response);
          if (this.pageIndex == 1) {
            this.allMedia = response.data.media;
            this.usedSize = response.data.usedSize;

            this.storagePercentage =
              (this.usedSize.usedSizeInBytes / 5368709120) * 100;
            // console.log(this.storagePercentage);
          } else {
            this.allMedia = this.allMedia.concat(response?.data?.media);
          }
          this.spinner.hide();
        },
        (error: any) => {
          console.log(error);
          this.spinner.hide();
          if (
            error?.error?.message == "jwt expired" ||
            error?.error?.message == "jwt must be provided" ||
            error?.error?.message == "jwt malformed" ||
            error?.error?.message == "invalid signature" ||
            error?.error?.message == "Given token user does not exist." ||
            error?.error?.message == "invalid token"
          ) {
            this.navService.tokenExpired();
          }
        }
      );
  }

  getUrl() {
    this.routeSubscription = this.route.params.subscribe((params) => {
      // console.log(params["id"]);
      if (params["id"]) {
        this.selectedTab = params["id"];
        if (this.mainUserData.role == "teammate") {
          this.router.navigate(["/setting/profile"]);
        } else if (this.allPaths.indexOf(params["id"]) == -1) {
          this.router.navigate(["/content/quick-message"]);
        }
      } else if (this.router.url == "/content") {
        // this.router.navigate(["/content/quick-message"]);
        this.selectedTab = "quick-message";
      }
    });
  }

  switchPage(data: any) {
    this.router.navigate([
      "/content",
      data.name.toLowerCase().replaceAll(" ", "-"),
    ]);
  }

  getMediaFromPc(event: any) {
    console.log(event.target.files);
  }

  searchFiles() {
    this.spinner.show();
    this.pageIndex = 1;
    this.getAllMedia();
  }

  showEditName(index: number, fileData: any) {
    this.fileName = fileData;
    this.selectedFile = index;
    setTimeout(() => {
      this.inputElement.nativeElement.focus();
    });
  }

  closeEdit() {
    this.fileName = "";
    this.selectedFile = null;
  }

  updateName() {
    this.fileName = "";
    this.selectedFile = null;
  }

  applyFilter(filter: string) {
    this.spinner.show();
    this.pageIndex = 1;
    this.filterSelected = filter;
    window.scroll(0, 0);
    this.getAllMedia();
  }

  deleteAlert(mediaId: string, message: any) {
    var errMessage: string = "";
    if (message == "alert") {
      errMessage = `<b>File were deleted Permanently</b>`;
    } else {
      errMessage = `<b>The file you selected used in following places</b>`;
      if (message.inChatCount > 0) {
        errMessage = errMessage + `<br> Chat message : ${message.inChatCount}`;
      }
      if (message.inCatalogueCount > 0) {
        errMessage =
          errMessage + `<br> Catalogue message : ${message.inCatalogueCount}`;
      }
    }
    this.modal.confirm({
      nzOkText: "Yes",
      nzCancelText: "No",
      nzTitle: errMessage,
      nzContent: "Are you sure you want to delete file?",
      nzOnOk: () => this.delateMedia(mediaId, false),
      nzOnCancel: () => {},
    });
  }

  delateMedia(mediaId: string, check: boolean) {
    // console.log(mediaId);
    if (!check) {
      this.spinner.show();
    }

    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    var req = {
      mediaId: mediaId,
      check: check,
    };

    this.apiService
      .commonPostMethod(environment.url + "media/delete", req, options)
      .subscribe(
        (response: any) => {
          console.log(response);
          if (check) {
            if (
              response.data.inChatCount > 0 ||
              response.data.inCatalogueCount > 0
            ) {
              this.deleteAlert(mediaId, response.data);
            } else {
              this.deleteAlert(mediaId, "alert");
            }
          } else {
            this.toastr.success(response.message, "", {
              timeOut: 2500,
            });
            this.pageIndex = 1;
            this.getAllMedia();
          }
          this.spinner.hide();
        },
        (error: any) => {
          console.log(error);
          this.spinner.hide();

          this.toastr.error(
            error?.error?.message ? error?.error?.message : error?.message,
            "",
            {
              timeOut: 2500,
            }
          );
        }
      );
  }

  copyUrl(imageData: any) {
    event.preventDefault();
    navigator.clipboard.writeText(this.imageUrl + imageData).then(
      () => {
        this.toastr.success("File url copied to clipboard", "", {
          timeOut: 2500,
        });
      },
      (error) => {
        this.toastr.error("Browser do not support Clipboard API", "", {
          timeOut: 2500,
        });
      }
    );
  }

  lastUrl = "";

  playAudio(audioUrl: string) {
    this.lastUrl = audioUrl;
  }

  controlAudio(event: any) {
    this.audioService.setCurrentlyPlayingAudio(event);
    this.audioService.setCurrentlyPlayingVideo(event);
  }

  bottomScrolled() {
    this.pageIndex++;
    this.getAllMedia();
  }

  // controlVideo(event: any) {
  //   this.audioService.setCurrentlyPlayingVideo(event);
  // }

  // upload section
  openModal() {
    this.uploadUrl = "";
    this.uploadModal = true;
    this.uploading = false;
    this.spinner.hide();
  }

  closeUploadModal() {
    this.uploadModal = false;
  }

  filesDropped(files: any): void {
    console.log(files);
    this.uploadMedia("Dnd", files);
  }

  uploadFile(ev: any) {
    console.log(ev.target.files);
    this.uploadMedia("fromPc", ev.target.files);
  }

  uploadMedia(type: string, media: any) {
    this.uploading = true;
    this.spinner.show();
    console.log(type);
    var req: any;
    var url: string = "";

    if (type == "url") {
      req = { url: this.uploadUrl };
      url = "media/upload-from-url";
    } else {
      var unsupportedFiles: any[] = [];
      url = "media/upload-from-local";
      req = new FormData();
      if (media.length > 10) {
        this.toastr.error("Maximum 10 files allowed at once!", "", {
          timeOut: 2500,
        });
      }
      for (let i = 0; i < media.length; i++) {
        if (i <= 9) {
          const file = type == "fromPc" ? media[i] : media[i].file;
          if (this.acceptedFiles.indexOf(file.type) > -1) {
            req.append("files", file, file.name);
          } else {
            unsupportedFiles.push(file.name);
          }
        } else {
          break;
        }
      }

      if (unsupportedFiles?.length > 0) {
        var error = `Below files are in unsupported format, will not be uploaded. <br>`;
        unsupportedFiles.forEach((e: any) => {
          error = error + `<br> ${e},`;
        });
        this.toastr.error(error, "", {
          timeOut: 6000,
          enableHtml: true,
        });
      }
    }

    if (unsupportedFiles?.length != media?.length || type == "url") {
      let options = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
        },
      };

      this.apiService
        .commonPostMethod(environment.url + url, req, options)
        .subscribe(
          (response: any) => {
            console.log(response);
            this.toastr.success(response.message, "", {
              timeOut: 2500,
            });
            if (type == "url") {
              if (
                response.data.newMedia.mediaType == this.filterSelected ||
                this.filterSelected == "all"
              ) {
                this.allMedia.unshift(response.data.newMedia);
              }
            } else {
              this.pageIndex = 1;
              this.getAllMedia();
            }
            this.uploading = false;
            this.uploadModal = false;
            this.spinner.hide();
          },
          (error: any) => {
            console.log(error);
            if (error?.error?.message) {
              this.uploading = false;
              this.spinner.hide();
              this.toastr.error(error?.error?.message, "", {
                timeOut: 2500,
              });
            } else {
              this.toastr.error(error?.message, "", {
                timeOut: 2500,
              });
            }
          }
        );
    } else {
      this.uploading = false;
      this.spinner.hide();
    }

    // setTimeout(() => {
    //   this.toastr.success("media uploaded", "", {
    //     timeOut: 2500,
    //   });
    //   this.uploading = false;
    //   this.uploadModal = false;
    //   this.spinner.hide();
    // }, 3000);
  }

  // document preview section

  showDocPreview(url: any) {
    this.docUrl = url?.startsWith(this.imageUrl) ? url : this.imageUrl + url;
    this.docPreview = true;
  }

  closeDocPreview() {
    this.docPreview = false;
  }

  selectedMediaArr: any[] = [];

  selectionChange(event: any, data: any) {
    // console.log(data);
    if (this.singleSelect) {
      var temp = {
        name: data.fileName,
        type: data.mediaType,
        id: data._id,
        url: this.imageUrl + data.url,
      };
      this.selectedMediaArr = [temp];
    } else {
      if (event) {
        var temp = {
          name: data.fileName,
          type: data.mediaType,
          id: data._id,
          url: this.imageUrl + data.url,
        };
        this.selectedMediaArr.push(temp);
      } else {
        this.selectedMediaArr.splice(this.findMediaIndex(data._id), 1);
      }

      console.log(this.selectedMediaArr);
    }
  }

  findMediaIndex(id: any) {
    var targetIndex = this.selectedMediaArr.findIndex((e: any) => {
      return e.id == id;
    });

    return targetIndex;
  }

  sendSelected() {
    this.selectedData.emit(this.selectedMediaArr);
  }

  ngOnDestroy(): void {
    // console.log("api key destroy..");
    this.routeSubscription.unsubscribe();
  }
}
