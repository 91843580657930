import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-developer",
  templateUrl: "./developer.component.html",
  styleUrls: ["./developer.component.css"],
})
export class DeveloperComponent implements OnInit {
  selectedMenu = "apiKey";

  constructor() {}

  ngOnInit(): void {}
}
