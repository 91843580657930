<div *ngIf="!viewDetails && !viewEdit && !showEditCustomer && !showCatalogue && !showSearch" class="scroll_section">

    <div id="user_detail_main" *ngIf="customerDetails && mainUserData?.role != 'teammate'" class="assignee_main">
        <div *ngIf="assignedUser" class="assignee_info">
            <i (click)="toChat()" class="user_back_icon" nz-icon nzType="arrow-left" nzTheme="outline"></i>
            <p [ngStyle]="{ 'background-color':  contactBgs[(assignedUser?.firstName).charAt(0).toUpperCase()]?.['bg'] , 'color' : contactBgs[(assignedUser?.firstName).charAt(0).toUpperCase()]?.['color']}"
                class="user_profile_picture">{{ (assignedUser?.firstName).charAt(0).toUpperCase() }}</p>
            <div class="assignee_name">
                <span>Assigned to</span>
                <p [nzTooltipTitle]="(assignedUser?.firstName + ' ' + assignedUser?.lastName)" nzTooltipPlacement="left"
                    nz-tooltip>{{((assignedUser?.firstName + ' ' +
                    assignedUser?.lastName).length > 10) ?
                    ((assignedUser?.firstName + ' ' + assignedUser?.lastName).slice(0,10) + '..')
                    : (assignedUser?.firstName + ' ' + assignedUser?.lastName)}}
                </p>
            </div>
        </div>
        <div *ngIf="!assignedUser" class="assignee_info un_assigned">
            <i (click)="toChat()" class="user_back_icon" nz-icon nzType="arrow-left" nzTheme="outline"></i>
            <div class="assignee_name">
                <span>Unassigned Chat</span>
            </div>
        </div>
        <div (click)="alertMessage()" nzTrigger="click" nz-dropdown
            [nzDropdownMenu]="mainUserData?.accessTo?.isExpired ? '' : userList" class="add_change_user">
            <img src="../../../../../assets/images/chat/switch_icon.svg" alt="">
            <span>
                {{assignedUser ? 'Change' : 'Add'}} User
            </span>
        </div>
        <nz-dropdown-menu #userList="nzDropdownMenu">
            <ul class="more_options" nz-menu nzSelectable>
                <ng-container *ngFor="let user of assignUsersList">
                    <li *ngIf="user._id != assignedUser?._id" (click)="assignChat(user)" nz-menu-item>
                        {{user?.firstName + ' ' + (user?.lastName ? user?.lastName : '')}}
                    </li>
                </ng-container>
                <li *ngIf="assignedUser?._id != 'Flow'" (click)="assignChat({_id : 'Flow'})" nz-menu-item>
                    Bot
                </li>
            </ul>
        </nz-dropdown-menu>
    </div>

    <!-- timer section -->
    <div class="timer_card">
        <div class="timer_heading">
            <img src="../../../../../assets/images/chat/timer.svg" alt="">
            <h6>Conversation Timer</h6>
        </div>
        <div class="timer_main_card">
            <!-- no conversation -->
            <div *ngIf="conversion == 'no-convo'" class="">
                <span>No Conversation Window Open Send Template Message to Start the conversation.</span>
            </div>
            <!-- no conversation -->
            <!-- timers -->
            <div *ngIf="conversion == 'default'" class="default_timers">
                <div class="timer_left">
                    <div class="">
                        <div class="progress_div">
                            <span>Marketing</span>
                            <nz-progress [nzStatus]="marketingDiffPercentage < 30 ? 'exception' : 'test'" [nzWidth]="45"
                                [nzPercent]="marketingDiffPercentage" nzType="circle"
                                [nzFormat]="marketingRemaining"></nz-progress>
                        </div>
                        <div class="progress_div">
                            <span>Utility</span>
                            <nz-progress [nzStatus]="utilityDiffPercentage < 30 ? 'exception' : 'test'" [nzWidth]="45"
                                [nzPercent]="utilityDiffPercentage" nzType="circle"
                                [nzFormat]="utilityRemaining"></nz-progress>
                        </div>
                        <div class="progress_div">
                            <span>Authentication</span>
                            <nz-progress [nzStatus]="authenticationDiffPercentage < 30 ? 'exception' : 'test'"
                                [nzWidth]="45" [nzPercent]="authenticationDiffPercentage" nzType="circle"
                                [nzFormat]="authenticationRemaining"></nz-progress>
                        </div>
                    </div>
                    <span class="timer_type">Business Initiated</span>
                </div>
                <div class="timer_right">
                    <div class="">
                        <div class="progress_div">
                            <span>Service</span>
                            <nz-progress [nzStatus]="serviceDiffPercentage < 30 ? 'exception' : 'test'" [nzWidth]="45"
                                [nzPercent]="serviceDiffPercentage" nzType="circle"
                                [nzFormat]="serviceRemaining"></nz-progress>
                        </div>
                    </div>
                    <span class="timer_type">User Initiated</span>
                </div>
            </div>
            <!-- timers -->
            <!-- free conversion -->
            <div *ngIf="conversion == 'free-convo'" class="free_conversion">
                <h4>Free Entry Point</h4>
                <h5>No Conversation Charges</h5>
                <h2>{{referralDiffHours}} Hrs</h2>
            </div>
            <!-- free conversion -->
        </div>
    </div>
    <!-- timer section -->

    <!-- follow up -->
    <div class="timer_card follow_up_card">
        <div class="timer_heading">
            <img src="../../../../../assets/images/chat/timer_off.svg" alt="">
            <h6 [ngClass]="{'follow_up_available' : customerDetails?.customer?.followUp}" class="follow_up_heading">
                Follow up Reminders
                <span (click)="showFollowUpModal()">
                    {{customerDetails?.customer?.followUp ? 'Change' : 'Add'}}
                </span>
            </h6>
        </div>
        <div *ngIf="customerDetails" class="follow_up_time">
            <div *ngIf="customerDetails?.customer?.followUp" class="follow_up_value">
                <span>{{moment(customerDetails?.customer?.followUp).format('DD MMMM YYYY | hh:mm a')}}</span>
                <span (click)="deleteRemainderAlert()" class="delete_reminder" nz-icon nzType="delete"
                    nzTheme="outline"></span>
            </div>
            <div *ngIf="!customerDetails?.customer?.followUp" class="">
                <span>No Reminders</span>
            </div>
        </div>
    </div>
    <!-- follow up -->

    <!-- open rate -->
    <div class="timer_card">
        <div *ngIf="customerDetails" class="rate_main_card">
            <div [ngClass]="{'w-100' : !customerDetails?.analytics}" class="">
                <div class="rate_heading">
                    <p>
                        <img src="../../../../../assets/images/chat/chat_mail.svg" alt="">
                        Open Rate
                    </p>
                    <h3 *ngIf="customerDetails?.analytics">
                        {{(customerDetails?.analytics?.readPercentage)?.toFixed(0)}}%</h3>
                </div>
                <div *ngIf="customerDetails?.analytics" class="rate_by_status">
                    <span class="status_1"><i nz-icon nzType="check" nzTheme="outline"></i>Sent</span>
                    <span class="status_2">
                        <i nz-icon nzType="check" nzTheme="outline"></i>
                        <i class="second_tick" nz-icon nzType="check" nzTheme="outline"></i>
                        Delivered
                    </span>
                    <span class="status_3">
                        <i nz-icon nzType="check" nzTheme="outline"></i>
                        <i class="second_tick" nz-icon nzType="check" nzTheme="outline"></i>
                        Read
                    </span>
                    <span class="status_4">
                        <i nz-icon nzType="check" nzTheme="outline"></i>
                        <i class="second_tick" nz-icon nzType="check" nzTheme="outline"></i>
                        Unread
                    </span>
                </div>
                <div *ngIf="!customerDetails?.analytics" class="no_open_rate">
                    <span>No open rate data available.</span>
                </div>
            </div>
            <div *ngIf="customerDetails?.analytics" class="">
                <div *ngIf="!fetchingData" class="text-center chart_div">
                    <canvas ngChartjs [data]="openRateData" [labels]="openRateLabels" [colors]="openRateColors"
                        [chartType]="'pie'" [options]="openRateChartOptions" [legend]="false">
                    </canvas>
                </div>
            </div>
        </div>
    </div>
    <!-- open rate -->

    <!-- user details -->
    <div id="customer_details" class="detail_card timer_card user_name_card">
        <div class="user_header mb-2">
            <div class="user_name_div">
                <img src="../../../../../assets/images/chat/user_icon.svg" alt="">
                <h5 class="mt-0 mb-0 fs-14">
                    Contact Details
                </h5>
            </div>
            <a *ngIf="mainUserData?.role != 'teammate' && userData" (click)="editCustomerOption()"
                class="edit_user_link">Edit</a>
        </div>

        <!-- <div class="collapse_details">
            <div class="">
                <p>Name</p>
                <h6>{{customerDetails?.customer?.name}}</h6>
            </div>
        </div>

        <div class="collapse_details">
            <div class="">
                <p>Contact Number</p>
                <h6>{{customerDetails?.customer?.phoneNo}}</h6>
            </div>
        </div> -->

        <!-- <div class="collapse_details">
            <div class="">
                <p>Email</p>
                <h6>{{customerDetails?.customer?.email ? customerDetails?.customer?.email : '-'}}</h6>
            </div>
        </div> -->

        <div class="">
            <ng-container *ngFor="let fields of additionalDetails; let i = index">
                <div *ngIf="showMoreDetails || i < 2" class="collapse_details">
                    <div class="">
                        <p>{{fields?.label}}</p>
                        <h6 *ngIf="fields.dataType != 'date'">{{fields?.value ? fields?.value : '-'}}</h6>
                        <h6 *ngIf="fields.dataType == 'date'">{{fields?.value ?
                            moment(fields?.value).format('DD-MM-YYYY') : '-'}}</h6>
                    </div>
                    <!-- <div *ngIf="mainUserData?.role != 'teammate'" class="">
                        <span (click)="editCustomerOption()" nz-icon nzType="edit" nzTheme="outline"></span>
                    </div> -->
                </div>
            </ng-container>
        </div>

        <div class="show_more_div">
            <span (click)="showAllContactDetails()">show {{showMoreDetails ? 'less' : 'more'}}</span>
        </div>
    </div>

    <!-- additional details -->
    <!-- <nz-card class="detail_card additional_details_div">
        <nz-collapse [nzExpandIconPosition]="'right'">
            <nz-collapse-panel [nzHeader]="'Additional Details'" [nzActive]="true">
                <ng-container *ngFor="let fields of additionalDetails">
                    <div *ngIf="fields?.name != 'email'" class="collapse_details mb-1">
                        <div class="">
                            <p>{{fields?.label}}</p>
                            <h6>{{fields?.value ? fields?.value : '-'}}</h6>
                        </div>
                        <div *ngIf="mainUserData?.role != 'teammate'" class="">
                            <span (click)="editCustomerOption()" nz-icon nzType="edit" nzTheme="outline"></span>
                        </div>
                    </div>
                </ng-container>
            </nz-collapse-panel>
        </nz-collapse>
    </nz-card> -->

    <!-- tags -->
    <nz-card id="tags_container" class="detail_card">

        <div class="user_header">
            <div class="user_name_div large_heading">
                <h5 class="mt-0 mb-0 fs-14">Tags</h5>
            </div>
            <a *ngIf="userData" (click)="editTags()" class="edit_user_link add_tag_link">
                <span nz-icon nzType="plus-circle" nzTheme="twotone"></span>
                Add Tags
            </a>
        </div>

        <div class="">
            <div class="no_data_div">
                <span *ngIf="customerDetails?.customer?.tags?.length == 0">No tags Available</span>
            </div>

            <div *ngIf="customerDetails?.customer?.tags?.length > 0" class="">
                <nz-tag *ngFor="let tag of customerDetails?.customer?.tags | slice:0:2;" [nzMode]="'closeable'"
                    (nzOnClose)="removeTag(tag , $event)">
                    {{tag.name}}
                </nz-tag>
            </div>
        </div>

        <div *ngIf="customerDetails?.customer?.tags?.length > 2" class="show_more">
            <p class="link" (click)="showMore('tags' , detailsDiv)">Show More <span nz-icon nzType="double-right"
                    nzTheme="outline"></span>
            </p>
        </div>

    </nz-card>

    <!-- notes -->
    <nz-card class="detail_card">
        <div class="user_header">
            <div class="user_name_div large_heading">
                <h5 class="mt-0 mb-0 fs-14">Notes</h5>
            </div>
        </div>

        <div *ngIf="userData" class="mt-3">
            <nz-input-group class="notes_input" [nzSuffix]="suffixTemplate">
                <input autocomplete="off" (keyup.enter)="directAdd()" [disabled]="mainUserData?.accessTo?.isExpired"
                    [type]="'text'" [(ngModel)]="notesText" nz-input placeholder="Add your notes" />
            </nz-input-group>
            <ng-template #suffixTemplate>
                <span *ngIf="!buttonLoader" class="web_cursor" [ngClass]="{'disabled_span' : !this.notesText}"
                    (click)="directAdd()" nz-icon nzType="send" nzTheme="outline"></span>
                <span *ngIf="buttonLoader" nz-icon nzType="loading" nzTheme="outline"></span>
            </ng-template>
        </div>

        <div class="no_data_div">
            <span *ngIf="customerDetails?.notes?.length == 0">No Notes Available</span>

            <div *ngIf="customerDetails?.notes?.length > 0" class="notes_main_div row">
                <div class="notes_title col-9">
                    <p>{{customerDetails?.notes[0]?.text}}</p>
                    <span>@{{customerDetails?.notes[0]?.lastUpdatedBy.firstName +
                        (customerDetails?.notes[0]?.lastUpdatedBy.lastName ?
                        customerDetails?.notes[0]?.lastUpdatedBy.lastName : '')

                        + ' ' + moment(customerDetails?.notes[0]?.lastUpdatedAt).format('DD/MM/YYYY')
                        }}
                    </span>
                </div>
                <div class="notes_options col-3">
                    <div class="notes_icon edit_icon">
                        <span (click)="showEditNotes('edit' , customerDetails?.notes[0] ,  'customer')" nz-icon
                            nzType="edit" nzTheme="outline"></span>
                    </div>
                    <div class="notes_icon">
                        <span (click)="deleteNotesAlert(customerDetails?.notes[0])" nz-icon nzType="delete"
                            nzTheme="outline"></span>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="customerDetails?.notes?.length > 1" class="show_more">
            <p (click)="showMore('notes' , detailsDiv)" class="link">Show More <span nz-icon nzType="double-right"
                    nzTheme="outline"></span></p>
        </div>
    </nz-card>

    <!-- order history -->
    <!-- <nz-card class="mb-3">
        <div class="user_header">
            <div class="user_name_div large_heading">
                <h5 class="mt-0 mb-0 fs-14">Order History</h5>
            </div>
        </div>
        <div *ngIf="customerDetails?.lastOrderByThisCustomer" class="recent_orders mt-3">
            <div class="last_order mb-3">
                <p>{{customerDetails?.lastOrderByThisCustomer?.items.length}} items</p>
                <span
                    class="order_status">{{customerDetails?.lastOrderByThisCustomer?.shipping?.tracking?.orderStatus}}</span>
            </div>
            <span>{{customerDetails?.lastOrderByThisCustomer?.items[0]?.currency}}
                {{customerDetails?.lastOrderByThisCustomer?.orderTotal}} (estimated total)</span>
        </div>
        <div *ngIf="customerDetails?.lastOrderByThisCustomer" class="show_more">
            <p (click)="showAllOrders()" class="link">Show More <span nz-icon nzType="double-right"
                    nzTheme="outline"></span></p>
        </div>
        <div *ngIf="!customerDetails?.lastOrderByThisCustomer" class="no_data_div">
            <span>No Orders Available</span>
        </div>
    </nz-card> -->

</div>

<!-- DETAILS SECTION STARTS -->

<div #detailsDiv *ngIf="viewDetails" class="scroll_section">
    <nz-card class="mb-0">
        <div class="edit_notes_heading">
            <h5><span class="link" (click)="backFromDetails()" nz-icon nzType="left" nzTheme="outline"></span>
                View Details
            </h5>
            <p class="notes_link" (click)="showEditNotes('add' , 'add' , '')">
                Add</p>
        </div>

        <div *ngIf="selectedOption == 'tags'" class="mt-3 details_scroll_section">
            <nz-tag *ngFor="let tag of customerDetails?.customer?.tags" [nzMode]="'closeable'"
                (nzOnClose)="removeTag(tag , $event)">{{tag.name}}
            </nz-tag>
        </div>

        <div *ngIf="selectedOption == 'notes'" class="mt-3 details_scroll_section">
            <div *ngFor="let note of customerDetails?.notes" class="notes_main_div mb-3 row">
                <div class="notes_title col-9">
                    <p>{{note.text}}</p>
                    <span>@{{customerDetails?.notes[0]?.lastUpdatedBy.firstName +
                        customerDetails?.notes[0]?.lastUpdatedBy.lastName

                        + ' ' + moment(customerDetails?.notes[0]?.lastUpdatedAt).format('DD/MM/YYYY')}}</span>
                </div>
                <div class="notes_options col-3">
                    <div class="notes_icon edit_icon">
                        <span (click)="showEditNotes('edit' , note , 'notes')" nz-icon nzType="edit"
                            nzTheme="outline"></span>
                    </div>
                    <div class="notes_icon">
                        <span (click)="deleteNotesAlert(note)" nz-icon nzType="delete" nzTheme="outline"></span>
                    </div>
                </div>
            </div>
        </div>

    </nz-card>
</div>

<!-- DETAILS SECTION ENDS -->

<!-- EDIT SECTION STARTS -->

<div #detailsDiv *ngIf="viewEdit" class="scroll_section">

    <nz-card class="mb-0">
        <div class="edit_notes_heading">
            <h5><span class="link" (click)="backFromEdit()" nz-icon nzType="left" nzTheme="outline"></span>
                {{this.notesAction == 'edit' ? 'Edit Notes' : 'Notes'}}
            </h5>
            <p [ngClass]="{'disable_tag' : buttonLoader}" (click)="changeNotes()" class="notes_link">
                <span *ngIf="buttonLoader" nz-icon nzType="loading" nzTheme="outline"></span>
                {{notesAction == 'edit' ? (buttonLoader ? 'Saving' : 'Save') : (buttonLoader ? 'Adding' : 'Add')}}
            </p>
        </div>

        <div class="mt-3">
            <nz-input-group>
                <input autocomplete="off" (keyup.enter)="changeNotes()" [type]="'text'" [(ngModel)]="notesText" nz-input
                    placeholder="Add your notes" />
            </nz-input-group>
            <!-- <ng-template #suffixTemplate>
                <span [ngClass]="{'disabled_span' : !this.notesText}" (click)="addNotes()" nz-icon nzType="send"
                    nzTheme="outline"></span>
            </ng-template> -->
        </div>

    </nz-card>
</div>

<!-- EDIT SECTION ENDS -->

<!-- CUSTOMER UPDATE SECTION STARTS -->

<div #detailsDiv *ngIf="showEditCustomer" class="">

    <nz-card class="mb-3 scroll_section">
        <div class="edit_notes_heading">
            <h5><span class="link" (click)="backFromDetails()" nz-icon nzType="left" nzTheme="outline"></span>
                Edit Details
            </h5>
            <p [ngClass]="{'disable_tag' : buttonLoader}" (click)="updateCustomer()" class="notes_link">
                <span *ngIf="buttonLoader" nz-icon nzType="loading" nzTheme="outline"></span>
                {{buttonLoader ? 'Saving' : 'Save'}}
            </p>
        </div>

        <div class="details_scroll_section mt-3">
            <form [formGroup]="updateCustomerForm" nz-form>
                <!-- <nz-form-item>
                    <nz-form-label nzRequired nzFor="customer_name" [nzSm]="24" [nzXs]="24"> Name </nz-form-label>
                    <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please Enter Your Name!">
                        <nz-input-group>
                            <input autocomplete="off" formControlName="name" type="text" nz-input id="customer_name"
                                placeholder="Enter Your Name" nzrequired>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item> -->

                <!-- <nz-form-item>
                    <nz-form-label nzFor="customer_name" [nzSm]="24" [nzXs]="24"> Email </nz-form-label>
                    <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please Enter Your Mail id!">
                        <nz-input-group>
                            <input autocomplete="off" formControlName="email" type="email" nz-input id="email"
                                placeholder="Enter Your Mail id">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item> -->

                <!-- <nz-form-item>
                    <nz-form-control>
                        <nz-input-group>
                            <label nz-checkbox formControlName="allowBroadcast">Allow Broadcast</label>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item> -->

                <nz-form-item *ngFor="let field of fieldData">
                    <nz-form-label [nzRequired]="field?.isMandatory"
                        [ngClass]="{'full_height' : field.dataType =='switch'}" class="large_label" nzFor=""
                        [nzSm]="field.dataType =='switch' ? '' : 24" [nzXs]="field.dataType =='switch' ? '' : 24">
                        {{field.label}}
                    </nz-form-label>
                    <ng-container>
                        <nz-form-control [nzSm]="24" [nzXs]="24" [nzErrorTip]="'Please Enter ' + field.label">
                            <nz-input-group [ngSwitch]="field.dataType">

                                <input autocomplete="off" [formControlName]="field.name" *ngSwitchCase="'text'" nz-input
                                    [placeholder]="'Enter ' + field.label" type="text">

                                <input autocomplete="off" [formControlName]="field.name" *ngSwitchCase="'email'"
                                    nz-input [placeholder]="'Enter ' + field.label" type="email">

                                <input autocomplete="off" [formControlName]="field.name" *ngSwitchCase="'number'"
                                    nz-input [placeholder]="'Enter ' + field.label" type="number">

                                <input [ngClass]="{ 'disabled_tag': field?.fieldType == 'predefined' }"
                                    [formControlName]="field.name" *ngSwitchCase="'phone-number'" autocomplete="off"
                                    nz-input [placeholder]="'Enter ' + field.label" type="number">

                                <textarea autocomplete="off" [formControlName]="field.name" *ngSwitchCase="'textarea'"
                                    nz-input rows="2" [placeholder]="'write ' +  field.label +  ' here'"></textarea>

                                <nz-select [nzPlaceHolder]="'Select ' + field.name" [formControlName]="field.name"
                                    class="w-100" *ngSwitchCase="'select'">
                                    <nz-option *ngFor="let option of field.acceptedValues" [nzLabel]="option"
                                        [nzValue]="option"></nz-option>
                                </nz-select>

                                <nz-select [nzPlaceHolder]="'Select ' + field.name" [formControlName]="field.name"
                                    class="w-100" *ngSwitchCase="'multi-select'" nzMode="multiple">
                                    <nz-option *ngFor="let option of field.acceptedValues" [nzLabel]="option"
                                        [nzValue]="option"></nz-option>
                                </nz-select>

                                <nz-select [nzPlaceHolder]="'Select ' + field.name" [formControlName]="field.name"
                                    class="w-100" *ngSwitchCase="'tags'" nzMode="tags">
                                    <nz-option *ngFor="let tag of allTags" [nzLabel]="tag.name" [nzValue]="tag.name">
                                    </nz-option>

                                </nz-select>

                                <nz-date-picker [formControlName]="field.name" class="w-100" *ngSwitchCase="'date'"
                                    nzShowToday nzFormat="dd-MM-yyyy" [nzRenderExtraFooter]="">
                                </nz-date-picker>

                                <nz-switch [formControlName]="field.name" *ngSwitchCase="'switch'"></nz-switch>
                            </nz-input-group>
                        </nz-form-control>
                    </ng-container>
                </nz-form-item>
            </form>
        </div>

    </nz-card>
</div>

<!-- CUSTOMER UPDATE SECTION ENDS -->



<!-- TAGS MODAL SECTION STARTS -->

<nz-modal [(nzVisible)]="tagsModal" [nzFooter]="null" nzTitle="Edit Tags" nzCentered (nzOnCancel)="closeTagsModal()">
    <ng-container *nzModalContent>
        <div class="tags_div">
            <ul>
                <li class="mb-3">
                    <input autocomplete="off" (keyup)="filterTags()" [(ngModel)]="filterText" autocomplete="off"
                        type="text" nz-input placeholder="Search tags or Create tags" required>
                </li>
                <li *ngFor="let tag of tags | filter : filterText" class="tags_list">
                    <p>{{tag.name}}</p>
                    <label [nzChecked]="tagsSelected.indexOf(tag._id) != -1" nz-checkbox
                        (nzCheckedChange)="onTagsChecked(tag , $event)"></label>
                </li>
                <li *ngIf="filteredData.length == 0 && filterText.toString().length > 0">
                    <p class="add_new_tag" (click)="addNewTag()">click here to add new Tag <span>:
                            {{filterText}}</span>
                    </p>
                </li>
            </ul>
        </div>

        <div class="">
            <button [nzLoading]="addingTags" (click)="saveTags()" type="button" class="sighup_button mt-3" nz-button
                nzType="primary">
                Save Tags
            </button>
        </div>
    </ng-container>
</nz-modal>


<!-- CATALOGUE DESIGN STARTS -->

<div *ngIf="showCatalogue && !showSearch" infinite-scroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="10"
    (scrolled)="onScroll()" [scrollWindow]="false" class="main_catalogue_div">
    <h5 class="all_products"><span class="link" (click)="showDetails()" nz-icon nzType="left" nzTheme="outline"></span>
        All Products
    </h5>
    <div *ngIf="catalogueData?.data?.catalogues?.length > 0" class="row catalogue_row">
        <div *ngFor="let category of catalogueData?.data?.catalogues ; let s = index" class="col-6 mt-3">
            <div class="catalogue_product">
                <!-- <label nzChecked (nzCheckedChange)="onCatalogueChecked(category , $event)" class="select_box"
                    nz-checkbox></label> -->

                <label class="product_selection_label" [for]="'catalogCheck' + s">

                    <input [id]="'catalogCheck' + s" autocomplete="off" #checkboxes class="form-check-input select_box"
                        type="checkbox" (change)="onCatalogueChecked(category , $event)">

                    <img class="img-fluid product_image" [src]="category?.image_url" alt="">

                    <h6 class="product_name" [nzTooltipTitle]="category?.name" nzTooltipPlacement="top" nz-tooltip>
                        {{category?.name.length > 12 ?
                        category?.name.slice(0,12) + '..' : category?.name}}</h6>

                    <span>{{category?.currency + ' ' + category?.sale_price}}</span>
                </label>
            </div>
        </div>
        <ngx-spinner *ngIf="catalogSpinner" bdColor="rgb(255,255,255)" size="medium" color="#000000"
            type="ball-clip-rotate-pulse" [fullScreen]="false">
        </ngx-spinner>
    </div>

    <div *ngIf="catalogueData?.data?.catalogues?.length > 0" class="mt-3 send_section">
        <form [formGroup]="catalogueForm" (ngSubmit)="sendCatalogue()" #textForm="ngForm" nz-form>
            <div class="catalogue_submit_form" nz-row [nzGutter]="24">

                <div *ngIf="selectedCatalogues.length > 1" nz-col [nzXs]="24">
                    <nz-form-item>
                        <nz-form-control [nzSm]="24" [nzXs]="24">
                            <nz-input-group>
                                <input autocomplete="off" formControlName="headerText" type="text" nz-input
                                    placeholder="Enter header text">
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>
                </div>

                <div nz-col [nzXs]="24">
                    <nz-form-item>
                        <nz-form-control [nzSm]="24" [nzXs]="24">
                            <nz-input-group>
                                <input autocomplete="off" formControlName="messageText" type="text" nz-input
                                    placeholder="Write Message">
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>
                </div>

                <div nz-col [nzXs]="24">
                    <nz-form-item>
                        <nz-form-control [nzSm]="24" [nzXs]="24">
                            <nz-input-group>
                                <button [nzLoading]="sendLoading"
                                    [disabled]="!activateSend || catalogueForm.controls['messageText'].invalid"
                                    type="submit" class="sighup_button w-100" nz-button nzType="primary">
                                    Send Catalogue Message
                                </button>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
        </form>
    </div>

    <div *ngIf="catalogueData?.data?.catalogues?.length == 0" class="no_catalogues">
        <p *ngIf="mainUserData.role != 'teammate'">no catalogues found <span (click)="toCatalogue()"
                class="link_span">Click here </span> to add catalogues</p>
        <p *ngIf="mainUserData.role == 'teammate'">no catalogues found </p>
    </div>

</div>

<!-- CATALOGUE DESIGN ENDS -->

<!-- CHAT SEARCH SECTION STARTS -->

<div *ngIf="showSearch" class="main_search_div">
    <h5 class="all_products "><span class="cursor_pointer" (click)="closeSearch()" nz-icon nzType="close"
            nzTheme="outline"></span>
        Search Messages
    </h5>

    <div class="row justify-content-center search_main_row">
        <div class="col-12 mt-1">
            <nz-input-group [nzPrefix]="suffixIconSearch">
                <input autocomplete="off" [(ngModel)]="searchText" (keyup.enter)="searchMessages()" type="text" nz-input
                    placeholder="Search Chats.." />
            </nz-input-group>
            <ng-template #suffixIconSearch>
                <span nz-icon nzType="search"></span>
                <!-- <span nz-icon nzType="arrow-left" nzTheme="outline"></span> -->
            </ng-template>
        </div>
        <div class="col-12 search_result">
            <div *ngIf="searchHit && searchResult?.length > 0" class="mt-1">
                <ul class="chat_result_list">
                    <li (click)="selectSearchChat(chat)" class="" *ngFor="let chat of searchResult">
                        <span>{{today == moment(chat?.time).format("YYYY-MM-DD") ?
                            moment(chat?.time).format("h:mm a") :
                            moment(chat?.time).format("Do MMM , h:mm a")}}
                        </span>
                        <div [ngSwitch]="chat.message.msgType" class="">
                            <p *ngSwitchCase="'text'" [innerHTML]="chat?.message?.data?.text ? 
                                (chat?.message?.data?.text?.length > 30 ? 
                                chat?.message?.data?.text.slice(0,30)+'...' : chat?.message?.data?.text) 
                                : 
                                (chat?.message?.data?.body.length > 30 ? 
                                chat?.message?.data?.body.slice(0,30)+'...' : chat?.message?.data?.body)">
                            </p>
                            <p *ngSwitchCase="'image'">
                                <span nz-icon nzType="file-image" nzTheme="outline"></span> image
                            </p>
                            <p *ngSwitchCase="'document'">
                                <span nz-icon nzType="file-word" nzTheme="outline"></span> document
                            </p>
                            <p *ngSwitchCase="'video'">
                                <span nz-icon nzType="video-camera" nzTheme="outline"></span> video
                            </p>
                            <p *ngSwitchCase="'sticker'">
                                <span nz-icon nzType="file-image" nzTheme="outline"></span> sticker
                            </p>
                            <p *ngSwitchCase="'audio'">
                                <span nz-icon nzType="audio" nzTheme="outline"></span> audio
                            </p>
                            <p *ngSwitchCase="'contacts'">
                                <span nz-icon nzType="contacts" nzTheme="outline"></span> contacts
                            </p>
                            <p *ngSwitchCase="'button'" [innerHTML]="item?.message?.data?.text"></p>
                            <p *ngSwitchCase="'location'">
                                <span nz-icon nzType="environment" nzTheme="outline"></span> location
                            </p>
                            <p *ngSwitchCase="'order'">
                                order
                            </p>
                            <p *ngSwitchCase="'interactive'" [innerHTML]="chat.message?.data?.button_reply?.title">
                            </p>
                            <p *ngSwitchCase="'template'"
                                [innerHTML]="chat?.message?.data[1]?.text ? chat?.message?.data[1]?.text : chat?.message?.data[0]?.text">
                            </p>
                        </div>
                    </li>
                </ul>
            </div>

            <div *ngIf="searchHit && searchResult?.length == 0" class="no_messages_section">
                <p>No Messages Found</p>
            </div>
            <ngx-spinner *ngIf="searchSpinner" bdColor="rgb(255,255,255)" size="medium" color="#000000"
                type="ball-clip-rotate-pulse" [fullScreen]="false">
            </ngx-spinner>
        </div>

    </div>
</div>

<!-- CHAT SEARCH SECTION ENDS -->

<!-- FOLLOW UP MODAL SECTION STARTS -->

<nz-modal [nzWidth]="followUpModalWidth" [(nzVisible)]="followUpModal" [nzFooter]="null"
    [nzTitle]="customerDetails?.customer?.followUp ? 'Change Remainder' : 'Add Remainder'" nzCentered
    (nzOnCancel)="closeFollowUpModal()">
    <ng-container *nzModalContent>
        <div class="follow_up_selection">
            <div class="custom_timing_section">
                <div (click)="addFollowUp('later-today' , 'predefined')" class="predefined_time">
                    <div class="date_letter">
                        <span nz-icon nzType="clock-circle" nzTheme="outline"></span>
                        Later today
                    </div>
                    <p>{{moment(now).format('hh:mm a')}}</p>
                </div>

                <div (click)="addFollowUp('tomorrow' , 'predefined')" class="predefined_time">
                    <div class="date_letter">
                        <span nz-icon nzType="right" nzTheme="outline"></span>
                        Tomorrow
                    </div>
                    <p>{{moment(tomorrow).format('dd, hh:mm a')}}</p>
                </div>

                <div (click)="addFollowUp('next-week' , 'predefined')" class="predefined_time">
                    <div class="date_letter">
                        <span nz-icon nzType="double-right" nzTheme="outline"></span>
                        Next week
                    </div>
                    <p>{{moment(nextWeek).format('dd, hh:mm a')}}</p>
                </div>
            </div>
            <div class="date_picker_section">
                <div class="">
                    <nz-date-picker nzInline [nzShowTime]="{ nzFormat: 'hh:mm a' }" nzFormat="yyyy-MM-dd hh:mm a"
                        ngModel (nzOnOk)="onOk($event)"></nz-date-picker>
                </div>
            </div>
        </div>
    </ng-container>
</nz-modal>

<!-- FOLLOW UP MODAL SECTION ENDS -->