import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inbox-page',
  templateUrl: './inbox-page.component.html',
  styleUrls: ['./inbox-page.component.css']
})
export class InboxPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
