import { Injectable, EventEmitter } from "@angular/core";
import { Subject, Observable, BehaviorSubject } from "rxjs";
import axios, { Axios } from "axios";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

@Injectable({
  providedIn: "root",
})
export class NavService {
  navChange: EventEmitter<any> = new EventEmitter();
  public formRefreshAnnouncedSource: BehaviorSubject<any> =
    new BehaviorSubject<any>("");
  public formRefreshAnnouncedSource1: BehaviorSubject<any> =
    new BehaviorSubject<any>("");
  public attributesList: BehaviorSubject<any> = new BehaviorSubject<any>("");
  public brandList: BehaviorSubject<any> = new BehaviorSubject<any>("");
  public dropdownValue: BehaviorSubject<any> = new BehaviorSubject<any>("");
  public userData: BehaviorSubject<any> = new BehaviorSubject<any>("");
  public mainUserData: BehaviorSubject<any> = new BehaviorSubject<any>("");

  constructor(private router: Router, private toastr: ToastrService) {}

  publishFormRefresh(value) {
    this.formRefreshAnnouncedSource.next(value);
  }
  parentToChild(value) {
    this.formRefreshAnnouncedSource1.next(value);
  }
  sendBrandList(values) {
    this.brandList.next(values);
  }
  sendAttributesList(value) {
    this.attributesList.next(value);
  }

  getLocationDropdown() {
    axios
      .get(environment.url + "locations/", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("Token"),
        },
      })
      .then((response) => {
        this.dropdownValue.next(response.data.data.locations);
      })
      .catch((error) => {
        console.log(error.response);
      });
  }

  getUserDetails() {
    axios
      .get(environment.url + "auth/user-detail/", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("Token"),
        },
      })
      .then((response) => {
        // console.log(response);
        this.userData.next(response?.data?.data?.user);
      });
  }

  getMainUserData() {
    if (localStorage.getItem("qwertrewetrt")) {
      let options = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
        },
      };
      axios
        .get(environment.url + "auth/get-user-data", options)
        .then((response) => {
          // console.log(response);
          localStorage.setItem(
            "skjhfruibsndbvmnvckbhdsuifhbd",
            response?.data?.data?.userData?.role
          );
          this.mainUserData.next(response.data);
          if (
            !response.data.data.userData.accessTo.sandboxId ||
            window.innerWidth < 992
          ) {
            // document.documentElement.style.setProperty(`--${your-variable}`, value + suffix);
            document.documentElement.style.setProperty(
              "--purchase_height",
              "0vh"
            );
          } else {
            document.documentElement.style.setProperty(
              "--purchase_height",
              "6vh"
            );
          }
        });
    }
  }

  tokenExpired() {
    localStorage.removeItem("qwertrewetrt");
    this.toastr.error("Your Session Expired..!", "", {
      timeOut: 2500,
    });
    this.router.navigate(["/"]);
  }
}
