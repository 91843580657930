import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import * as moment from "moment";
import { NzModalService } from "ng-zorro-antd/modal";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { CommonApiService } from "src/app/services/common-api.service";
import { NavService } from "src/app/services/nav.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-workflow",
  templateUrl: "./workflow.component.html",
  styleUrls: ["./workflow.component.css"],
})
export class WorkflowComponent implements OnInit {
  moment: any = moment;
  orderColumn: Array<any> = [
    {
      title: "Name",
      width: "150px",
    },
    {
      title: "Channel Type",
      width: "100px",
    },
    {
      title: "Created By",
      width: "120px",
    },
    {
      title: "Created At",
      width: "100px",
    },
    {
      title: "Status",
      width: "100px",
    },
    // {
    //   title: "Action",
    //   width: "80px",
    // },
  ];
  tableHeight: any = "";
  mainUserData: any = {};
  searchKey: string = "";

  // pagination section
  totalCount: number = 4;
  pageIndex: number = 1;
  pageSize: number = 30;

  allFlows: Array<any> = [
    // {
    //   name: "First Flow",
    //   channelType: "whatsapp",
    //   createdBy: { firstName: "Balaji ", lastName: "Karthik" },
    //   createdAt: "2023-09-07T04:43:39.000Z",
    //   isActive: true,
    // },
    // {
    //   name: "Second Flow",
    //   channelType: "whatsapp",
    //   createdBy: { firstName: "Balaji ", lastName: "Karthik" },
    //   createdAt: "2023-09-07T04:43:39.000Z",
    //   isActive: true,
    // },
    // {
    //   name: "Third Flow",
    //   channelType: "whatsapp",
    //   createdBy: { firstName: "Balaji ", lastName: "Karthik" },
    //   createdAt: "2023-09-07T04:43:39.000Z",
    //   isActive: true,
    // },
    // {
    //   name: "Fourth Flow",
    //   channelType: "whatsapp",
    //   createdBy: { firstName: "Balaji ", lastName: "Karthik" },
    //   createdAt: "2023-09-07T04:43:39.000Z",
    //   isActive: true,
    // },
  ];

  // forms drawer section

  flowFormDrawer: boolean = false;
  flowForm: FormGroup;
  submitting: boolean = false;

  private subscription: Subscription;

  constructor(
    private navService: NavService,
    private fb: FormBuilder,
    private router: Router,
    private apiService: CommonApiService,
    private spinner: NgxSpinnerService,
    private modal: NzModalService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.flowForm = this.fb.group({
      flowName: ["", Validators.required],
      description: [""],
    });
    this.fetchData();
    this.getMainUserData();
  }

  getMainUserData() {
    this.subscription = this.navService.mainUserData.subscribe(
      (response: any) => {
        if (response) {
          // console.log(response);
          this.mainUserData = response.data.userData;
          if (
            response?.data?.userData?.accessTo?.sandboxId &&
            window.innerWidth > 992
          ) {
            this.tableHeight = "56vh";
          } else {
            this.tableHeight = "65vh";
          }
        }
      }
    );
  }

  searchContacts() {
    this.pageIndex = 1;
    this.fetchData();
  }

  changePageSize(size: any) {
    this.pageSize = size;
    this.pageIndex = 1;
    // this.fetchData();
  }

  fetchData() {
    this.spinner.show();
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(environment.url + "work-flow/get-all", {}, options)
      .subscribe(
        (response: any) => {
          // console.log(response);
          this.allFlows = response.data.allWorkFlows;
          this.spinner.hide();
        },
        (error: any) => {
          console.log(error);
          this.spinner.hide();
          if (
            error?.error?.message == "jwt expired" ||
            error?.error?.message == "jwt must be provided" ||
            error?.error?.message == "jwt malformed" ||
            error?.error?.message == "invalid signature" ||
            error?.error?.message == "Given token user does not exist." ||
            error?.error?.message == "invalid token"
          ) {
            this.navService.tokenExpired();
          }
        }
      );
  }

  onPageNumberChange(id: any, event: any) {
    if (this.pageIndex != event.pageIndex) {
      this.pageIndex = event.pageIndex;
      this.fetchData();
      document.getElementById(id).scrollIntoView();
    }
  }

  setMyStyles() {
    let styles = {
      "margin-right":
        135 + (this.totalCount / this.pageSize).toFixed(0).length * 5 + "px",
    };
    return styles;
  }

  // create and update flow  Forms section

  closeFlowFormDrawer() {
    this.flowFormDrawer = false;
  }

  showFlowFormDrawer() {
    this.flowForm.reset();
    this.submitting = false;
    this.flowFormDrawer = true;
  }

  createUpdateFlow() {
    if (this.flowForm.valid) {
      // console.log(this.flowForm.value);
      this.submitting = true;
      let options = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
        },
      };

      var req: any = {
        name: this.flowForm.value.flowName,
        description: this.flowForm.value.description,
      };

      this.apiService
        .commonPostMethod(environment.url + "work-flow/create", req, options)
        .subscribe(
          (response: any) => {
            console.log(response);
            this.submitting = false;
            this.router.navigate([
              "/work-flow/create",
              response.data.newWorkFlow._id,
            ]);
          },
          (err: any) => {
            this.submitting = false;
            console.log(err);
          }
        );
    } else {
      Object.values(this.flowForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  deleteAlert(selected: any) {
    this.modal.confirm({
      nzOkText: "Yes",
      nzCancelText: "No",
      nzTitle: "Flow will be deleted Permanently.",
      nzContent: "Are you sure you want to delete?",
      nzOnOk: () => this.deleteFlow(selected._id),
      nzOnCancel: () => {},
    });
  }

  deleteFlow(flowId: any) {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url + "work-flow/delete",
        { workFlowId: flowId },
        options
      )
      .subscribe(
        (response: any) => {
          console.log(response);
          this.toastr.success(response.message, "", {
            timeOut: 2500,
          });
          this.fetchData();
        },
        (err: any) => {
          console.log(err);
          if (err?.error?.message) {
            this.toastr.error(err?.error?.message, "", {
              timeOut: 2500,
            });
          } else {
            this.toastr.error(err?.message, "", {
              timeOut: 2500,
            });
          }
        }
      );
  }

  ngOnDestroy(): void {
    // console.log("api key destroy..");
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
