import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class CommonApiService {
  constructor(private http: HttpClient) {}

  commonPostMethod(url: any, data: any, options: any) {
    return this.http.post(url, data, options);
  }

  commonGetMethod(url: any, data: any) {
    return this.http.get(url, data);
  }

  commonDeleteMethod(url: any, options: any) {
    return this.http.delete(environment.url + url, options);
  }
}
