<div class="row mt-5">
    <div class="col-12">
        <nz-card class="contacts_top_card">
            <div class="search_add_div">
                <div class="toggle_options">
                    <i (click)="showChatScreen()" nz-icon nzType="arrow-left" nzTheme="outline"></i>
                    <nz-input-group class="search_input_section small_input_sec" [nzPrefix]="suffixIconSearch">
                        <input autocomplete="off" [(ngModel)]="searchKey" type="text" nz-input
                            placeholder="Search here.." />
                    </nz-input-group>
                    <ng-template #suffixIconSearch>
                        <span nz-icon nzType="search"></span>
                    </ng-template>
                </div>

                <div class="filters">
                    <button class="segment_btn" nzTrigger="click" nz-button nz-dropdown [nzDropdownMenu]="assigneeMenu"
                        [nzPlacement]="'bottomLeft'">
                        User 1
                        <span class="d-flex" nz-icon nzType="down"></span>
                    </button>
                    <nz-dropdown-menu #assigneeMenu="nzDropdownMenu">
                        <ul class="segment_menu" nz-menu>
                            <li nz-menu-item> User 1 </li>
                            <li nz-menu-item> User 2 </li>
                            <li nz-menu-item> User 3 </li>
                        </ul>
                    </nz-dropdown-menu>
                    <nz-select nzPlaceHolder="Filter" class="">
                        <nz-option [nzLabel]="'Mine'" [nzValue]="'mine'"> </nz-option>
                        <nz-option [nzLabel]="'Important'" [nzValue]="'important'"> </nz-option>
                        <nz-option [nzLabel]="'Pinned'" [nzValue]="'pinned'"> </nz-option>
                        <nz-option [nzLabel]="'Unread'" [nzValue]="'unread'"> </nz-option>
                    </nz-select>
                </div>
            </div>
        </nz-card>
    </div>

    <div class="col-12 board_main">
        <div class="cdk_main_col" cdkDropListGroup>
            <div class="status-list drag_card" *ngFor="let lane of lanes">
                <div class="px-3 pb-4 pt-3">
                    {{ lane.title }}
                </div>
                <div class="issue-card-container" cdkDropList [cdkDropListData]="lane.issues"
                    (cdkDropListDropped)="drop($event)" [id]="lane.id">
                    <div class="inner_box_wrap" *ngFor="let issue of lane.issues" cdkDrag
                        (cdkDragDropped)="onDragDropped(issue, $event)">
                        <div class="inner_box">
                            <a class="text-body">
                                <div class="d-flex align-items-start user_list_main">
                                    <div class="position-relative">
                                        <p [ngStyle]="{ 'background-color':  '#c5e1a5'}" class="user_profile_picture">
                                            {{(issue?.name).charAt(0).toUpperCase()}}
                                        </p>
                                    </div>
                                    <div class="w-100 overflow-hidden">
                                        <div class="user_data">
                                            <h5 class="mt-0 mb-0 fs-14">
                                                {{issue.name}}
                                            </h5>
                                            <p class="chat_time user_badge">
                                                <nz-badge nzStatus="success">
                                                </nz-badge>
                                                <span class="chat_time_span">
                                                    4:47 pm
                                                    <span (click)="pinChat($event , user)" class="pin_icon" nz-icon
                                                        nzType="pushpin" [nzTheme]="'fill'"></span>
                                                </span>
                                            </p>
                                        </div>

                                        <div class="message_section">
                                            <span class="float-end text-muted fs-12">
                                                Hello Ownchat
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="add_stages status-list">
            <div class="px-3 pb-4 pt-3">
                <a>
                    <span nz-icon nzType="plus-circle" nzTheme="twotone"></span>
                    Add Stage
                </a>
            </div>
        </div>
    </div>
</div>