import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { Subscription } from "rxjs";
import { AlertServiceService } from "src/app/services/alert-service.service";
import { CommonApiService } from "src/app/services/common-api.service";
import { NavService } from "src/app/services/nav.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-import-history",
  templateUrl: "./import-history.component.html",
  styleUrls: ["./import-history.component.css"],
})
export class ImportHistoryComponent implements OnInit {
  orderColumn = [
    {
      title: "Name",
      width: "100px",
    },
    {
      title: "Created Count",
      width: "100px",
    },
    {
      title: "Updated Count",
      width: "100px",
    },
    {
      title: "Duplicate Count",
      width: "100px",
    },
    {
      title: "Status",
      width: "60px",
    },
    {
      title: "Source url",
      width: "100px",
    },
    {
      title: "Error Message",
      width: "100px",
    },
  ];

  tableHeight: any = "";
  mainUserData: any = {};

  uploadHistory: any = [];

  imageUrl = environment.imageUrl;
  private subscription: Subscription;

  constructor(
    private apiService: CommonApiService,
    private navService: NavService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.getMainUserData();
    this.getUploadHistory();
  }

  getMainUserData() {
    this.subscription = this.navService.mainUserData.subscribe(
      (response: any) => {
        if (response) {
          // console.log(response);
          this.mainUserData = response.data.userData;
          if (
            response?.data?.userData?.accessTo?.sandboxId &&
            window.innerWidth > 992
          ) {
            this.tableHeight = "69vh";
          } else {
            this.tableHeight = "79vh";
          }
        }
      }
    );
  }

  getUploadHistory() {
    this.spinner.show();
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(environment.url + "bulk-uploads/get-all", {}, options)
      .subscribe(
        (response: any) => {
          // console.log(response);
          this.uploadHistory = response.data.bulkUploads;
          this.spinner.hide();
        },
        (err: any) => {
          if (
            err?.error?.message == "jwt expired" ||
            err?.error?.message == "jwt must be provided" ||
            err?.error?.message == "jwt malformed" ||
            err?.error?.message == "invalid signature" ||
            err?.error?.message == "Given token user does not exist." ||
            err?.error?.message == "invalid token"
          ) {
            this.navService.tokenExpired();
          }
          console.log(err);
          this.spinner.hide();
        }
      );
  }

  ngOnDestroy(): void {
    // console.log("api key destroy..");
    this.subscription.unsubscribe();
  }
}
