import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Component, HostListener, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NzModalService } from "ng-zorro-antd/modal";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { AlertServiceService } from "src/app/services/alert-service.service";
import { CommonApiService } from "src/app/services/common-api.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-field-collection",
  templateUrl: "./field-collection.component.html",
  styleUrls: ["./field-collection.component.css"],
})
export class FieldCollectionComponent implements OnInit {
  orderColumn: any = [
    {
      title: "Label",
      width: "80px",
    },
    // {
    //   title: "Field",
    //   width: "80px",
    // },
    {
      title: "Type",
      width: "50px",
    },
    {
      title: "Status",
      width: "60px",
    },
    {
      title: "Mandatory",
      width: "60px",
    },
    {
      title: "",
      width: "40px",
    },
  ];

  fieldData: any[] = [];
  dataTypes: any[] = [
    {
      label: "Text",
      value: "text",
    },
    {
      label: "Text Area",
      value: "textarea",
    },
    {
      label: "Number",
      value: "number",
    },
    {
      label: "Email",
      value: "email",
    },
    {
      label: "Select",
      value: "select",
    },
    {
      label: "Date",
      value: "date",
    },
    {
      label: "Switch",
      value: "switch",
    },
    {
      label: "Phone Number",
      value: "phone-number",
    },
    {
      label: "Multi Select",
      value: "multi-select",
    },
    // {
    //   label: "Tags",
    //   value: "tags",
    // },
  ];

  addFieldForm!: FormGroup;
  updateFieldForm!: FormGroup;
  fieldAdding: boolean = false;
  showForm = false;

  editOption: boolean = false;
  updateData: any;

  updateReqObj: any = {};
  isDirectUpdate: boolean = false;

  selectedTab = "contacts";
  @Input() mainUserData: any;
  tableHeight: any = "";

  pageReloading: boolean = false;
  tagsPage: boolean = false;

  searchKey: any = "";

  isDragging: boolean = false;

  constructor(
    private fb: FormBuilder,
    private apiService: CommonApiService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private alertService: AlertServiceService,
    private modal: NzModalService,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (this.router.url == "/field-collection") {
      this.tagsPage = true;
    } else {
      this.tagsPage = false;
    }

    if (this.tagsPage) {
      if (this.mainUserData?.accessTo?.sandboxId && window.innerWidth > 992) {
        this.tableHeight = "66vh";
      } else {
        this.tableHeight = "75vh";
      }
    } else {
      if (this.mainUserData?.accessTo?.sandboxId && window.innerWidth > 992) {
        this.tableHeight = "58vh";
      } else {
        this.tableHeight = "67vh";
      }
    }

    this.addFieldForm = this.fb.group({
      name: [""],
      label: ["", Validators.required],
      type: ["", Validators.required],
      hint: [""],
      acceptedValues: [""],
      isMandatory: [true],
    });

    this.updateFieldForm = this.fb.group({
      label: ["", Validators.required],
      hint: ["", Validators.required],
      isMandatory: [Boolean],
      isActive: [Boolean],
    });

    this.fetchData();
  }

  switchTable(tabSelected: string) {
    this.selectedTab = tabSelected;
  }

  toggleForm() {
    if (!this.mainUserData?.accessTo?.isExpired) {
      this.editOption = false;
      this.addFieldForm.reset();
      this.addFieldForm.get("isMandatory").setValue(true);
      this.addFieldForm.get("label").valueChanges.subscribe((val) => {
        this.addFieldForm.get("name").setValue(val?.replaceAll(" ", "_"));
      });
      // this.addFieldForm.get("name").clearValidators();
      // this.addFieldForm.get("name").updateValueAndValidity();
      this.showForm = !this.showForm;
    }
  }

  fetchData() {
    this.spinner.show();
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url + "customer-field/get?filter=for-show-fields",
        {},
        options
      )
      .subscribe(
        (response: any) => {
          this.fieldData = response.data.customerFields;
          console.log(response);
          this.spinner.hide();
          this.pageReloading = false;
        },
        (error) => {
          console.log(error);
          this.spinner.hide();
          this.pageReloading = false;
        }
      );
  }

  createField() {
    if (
      this.addFieldForm.value.type == "multi-select" ||
      this.addFieldForm.value.type == "select"
    ) {
      this.addFieldForm
        .get("acceptedValues")
        .setValidators([Validators.required]);
    } else {
      this.addFieldForm.get("acceptedValues").clearValidators();
    }
    this.addFieldForm.get("acceptedValues").updateValueAndValidity();
    if (this.addFieldForm.valid) {
      this.fieldAdding = true;
      let options = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
        },
      };

      var url = "customer-field/create";
      var formData: any = {
        label: this.addFieldForm.value.label,
        name: this.addFieldForm.value.name,
        hint: this.addFieldForm.value.hint ? this.addFieldForm.value.hint : "",
        dataType: this.addFieldForm.value.type,
        isMandatory: this.addFieldForm.value.isMandatory,
        isActive: true,
      };

      if (
        this.addFieldForm.value.type == "multi-select" ||
        this.addFieldForm.value.type == "select"
      ) {
        formData.acceptedValues = this.addFieldForm.value.acceptedValues;
      }

      this.apiService
        .commonPostMethod(environment.url + url, formData, options)
        .subscribe(
          (response: any) => {
            // console.log(response);
            if (response.status == "success") {
              this.toastr.success(response.message, "", {
                timeOut: 2500,
              });
              this.fetchData();
              this.fieldAdding = false;
              this.showForm = false;
              this.addFieldForm.reset();
            }
          },
          (error) => {
            console.log(error);
            this.fieldAdding = false;
            this.toastr.error(error?.error?.message, "", {
              timeOut: 2500,
            });
          }
        );
    } else {
      Object.values(this.addFieldForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  showUpdateFieldForm(selected: any) {
    if (this.mainUserData?.accessTo?.isExpired) {
      this.alertService.warning();
    } else {
      this.updateData = selected;
      // console.log(selected);
      this.isDirectUpdate = false;
      this.editOption = true;
      this.showForm = true;
      if (
        selected.dataType == "multi-select" ||
        selected.dataType == "select"
      ) {
        this.updateFieldForm?.addControl(
          "acceptedValues",
          this.fb.control([], Validators.required)
        );
        this.updateFieldForm
          .get("acceptedValues")
          .setValue(selected.acceptedValues);
      } else {
        this.updateFieldForm?.removeControl("acceptedValues");
      }
      this.updateFieldForm.get("label").setValue(selected.label);
      this.updateFieldForm.get("hint").setValue(selected.hint);
      this.updateFieldForm.get("isMandatory").setValue(selected.isMandatory);
      this.updateFieldForm.get("isActive").setValue(selected.isActive);

      // console.log(this.updateFieldForm.value);
    }
  }

  updateField() {
    if (this.updateFieldForm.valid || this.isDirectUpdate) {
      this.fieldAdding = true;
      // console.log();
      let options = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
        },
      };
      if (!this.isDirectUpdate) {
        this.updateReqObj = {
          customerFieldId: this.updateData._id,
          label: this.updateFieldForm.value.label,
          hint: this.updateFieldForm.value.hint,
          isMandatory: this.updateFieldForm.value.isMandatory,
          isActive: this.updateFieldForm.value.isActive,
        };

        if (
          this.updateData?.dataType == "multi-select" ||
          this.updateData?.dataType == "select"
        ) {
          this.updateReqObj.acceptedValues =
            this.updateFieldForm.value.acceptedValues;
        }
      }

      this.apiService
        .commonPostMethod(
          environment.url + "customer-field/update",
          this.updateReqObj,
          options
        )
        .subscribe(
          (response: any) => {
            // console.log(response);
            if (response.status == "success") {
              this.toastr.success(response.message, "", {
                timeOut: 2500,
              });
              if (!this.isDirectUpdate) {
                this.fetchData();
              }

              this.fieldAdding = false;
              this.showForm = false;
              this.updateFieldForm.reset();
            }
          },
          (error) => {
            if (this.isDirectUpdate) {
              this.fetchData();
            }
            console.log(error);
            this.fieldAdding = false;
          }
        );
    } else {
      Object.values(this.updateFieldForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  directUpdate(data: any, event: any) {
    // console.log(data);
    if (this.mainUserData?.accessTo?.isExpired) {
      this.alertService.warning();
      event.preventDefault();
      event.stopPropagation();
    } else {
      this.isDirectUpdate = true;
      this.updateReqObj = {
        customerFieldId: data._id,
        label: data.label,
        hint: data.hint,
        isMandatory: data.isMandatory,
        isActive: data.isActive,
      };
      if (data?.dataType == "multi-select" || data?.dataType == "select") {
        this.updateReqObj.acceptedValues = data?.acceptedValues;
      }
      this.updateField();
    }
  }

  // delete tag

  showDeleteAlert(item: any) {
    if (this.mainUserData?.accessTo?.isExpired) {
      this.alertService.warning();
    } else {
      this.modal.confirm({
        nzOkText: "Yes",
        nzCancelText: "No",
        nzTitle: "<b>Sure you Want to delete this Field?</b>",
        nzContent: "<b>Once deleted, the Field cannot be recovered..</b>",
        nzOnOk: () => this.deleteField(item),
      });
    }
  }

  deleteField(item: any) {
    // console.log(item);
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url + "customer-field/delete",
        { customerFieldId: item._id },
        options
      )
      .subscribe(
        (response: any) => {
          // console.log(response);
          this.toastr.success("Field Collection Removed!..", "", {
            timeOut: 2500,
          });
          // if (this.tagsData.length == 1 && this.pageIndex != 1) {
          //   this.pageIndex = this.pageIndex - 1;
          // }
          this.fetchData();
        },
        (error: any) => {
          console.log(error);
          if (error?.error?.message) {
            this.toastr.error(error?.error?.message, "", {
              timeOut: 2500,
            });
          } else {
            this.toastr.error(error?.message, "", {
              timeOut: 2500,
            });
          }
        }
      );
  }

  reloadPage() {
    this.pageReloading = true;
    this.fetchData();
  }

  drop(event: CdkDragDrop<string[]>): void {
    // console.log(event);
    moveItemInArray(this.fieldData, event.previousIndex, event.currentIndex);
    // console.log(this.fieldData);

    const filteredArray: any[] = this.fieldData.map((item, index) => {
      return { _id: item._id, index };
    });

    // console.log(filteredArray);

    this.changeFieldPosition(filteredArray);
  }

  changeFieldPosition(req: any[]) {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url + "customer-field/update-positions",
        req,
        options
      )
      .subscribe(
        (response: any) => {
          // console.log(response);
        },
        (error: any) => {
          console.log(error);
          if (error?.error?.message) {
            this.toastr.error(error?.error?.message, "", {
              timeOut: 2500,
            });
          } else {
            this.toastr.error(error?.message, "", {
              timeOut: 2500,
            });
          }
        }
      );
  }

  enableDrag() {
    this.isDragging = true;
  }

  @HostListener("document:mouseup", ["$event"])
  onMouseUp() {
    this.isDragging = false;
  }
}
