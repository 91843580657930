import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { CommonApiService } from "src/app/services/common-api.service";
import { environment } from "src/environments/environment";
import { ChatUser } from "../shared/chat.model";
// import * as moment from "moment";
import * as moment from "moment-timezone";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import { interval, Subscription } from "rxjs";
import { AlertServiceService } from "src/app/services/alert-service.service";
import { NavService } from "src/app/services/nav.service";
import { contactBGs, messageFormats } from "../shared/messageData";
import { DomSanitizer } from "@angular/platform-browser";
import { CustomMessageService } from "../shared/custom-message.service";
import { Router } from "@angular/router";
import { AudioRecordingService } from "src/app/services/audio-recording.service";
import { OpusService } from "src/app/services/opus.service";
// import { CustomMessageService } from "../shared/custom-message.service";

// @Directive({
//   selector: "[ccCardHover]",
// })
// export class CardHoverDirective {
//   constructor(private el: ElementRef) {}
//   @HostListener("scroll", ["$event"]) onScroll(e) {
//     console.log(this.el.nativeElement.scrollTop);
//   }
// }

@Component({
  selector: "app-chat-area",
  templateUrl: "./chat-area.component.html",
  styleUrls: ["./chat-area.component.css"],
})
export class ChatAreaComponent implements OnInit {
  moment: any = moment;
  @Input() selectedUser: any;
  sampleSrc =
    "https://www.sinch.com/sites/default/files/styles/small_hq/public/image/2021-07/Illustration_mobile_whatsapp.png?itok=up93dsO6";
  liveUrl = environment.imageUrl;
  loading: boolean = false;
  chatHistory: any = [];
  toUser!: ChatUser;
  newMessage: any = "";
  captionText: string = "";

  set = "twitter";
  emojiSelected = "";
  showEmoji = false;
  mediaSelected: any;
  displayMedia: any;
  previewModal = false;
  previewDoc: any = 0;
  uploading = false;
  areaSpinner = false;
  modalSpinner = false;

  catalogueData: any = [];
  showCannedResponse: boolean = false;

  templateModal = false;
  today = moment(new Date()).format("YYYY-MM-DD");

  docTypes = [
    // "text/plain",
    "application/pdf",
    "application/vnd.ms-powerpoint",
    "application/msword",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "document",
  ];

  audioTypes = [
    "audio/aac",
    "audio/mp4",
    // 'audio/mpeg',
    "audio/amr",
    "audio/ogg",
    "audio",
  ];
  videoTypes = ["video/mp4", "video/3gp", "video"];
  imageTypes = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    // "image/webp",
    // "image/svg+xml",
    "image",
  ];

  allFileTypes: any = [
    // "text/plain",
    "application/pdf",
    "application/vnd.ms-powerpoint",
    "application/msword",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "audio/aac",
    "audio/mp4",
    // 'audio/mpeg',
    "audio/amr",
    "audio/ogg",
    "video/mp4",
    "video/3gp",
    "image/png",
    "image/jpg",
    "image/jpeg",
    // "image/webp",
    // "image/svg+xml",
  ];

  acceptedTypes: any = [];
  // TEMPLATE SECTION

  templateData: any;
  messageTemplateSelected: any;
  templateLoading = false;
  selectedPreview: any;
  variableData: any = [];
  templateMediaPreview: any;
  templateMedia: any;
  sendingTemplateMedia = false;
  getVariableValue = false;
  modalWidth: any;
  templatePage: any = 1;
  templateFilterArr: any = [];

  templateCategories: any = [
    {
      label: "Utility Conversations",
      value: "UTILITY",
    },
    {
      label: "Authentication",
      value: "AUTHENTICATION",
    },
    {
      label: "Marketing Conversations",
      value: "MARKETING",
    },
  ];

  contactModal = false;
  detailedContacts: any;

  nextKey: any = "";
  templateSpinner: boolean;

  // scrolling pagination

  initialPage: boolean;
  pageIndex = 1;
  pageScroll: boolean;

  isLoading = false;

  cartDetailModal = false;

  cartItemSelected: any = {};

  scrollPosition: any = "";

  // progress bar
  timeDiffInHours: any;
  timeDiffPercentage: any;
  formatOne = (): any => this.timeDiffInHours;
  subscription: Subscription;

  @ViewChild("chatForm", { static: true }) chatForm: any;
  @ViewChild("chatBox", { static: false }) chatBox: ElementRef;
  @ViewChild("chatScroll", { static: false }) chatScroll: ElementRef;
  @ViewChild("fileInput") fileInput: ElementRef;

  @ViewChild("bodyText", { static: false }) bodyText: ElementRef;

  @Output() switchPage = new EventEmitter();
  @Output() reloadUserList = new EventEmitter();
  @Output() switchChatSearch = new EventEmitter();
  @Output() showTags = new EventEmitter();
  @Output() enableBoardView = new EventEmitter();

  @Input() socketData: any;
  @Input() customCatalogue: any;
  @Input() tagsReset: any;

  newMessageAlert = false;
  newMessageCount: number = 0;

  source = interval(60000);
  // subscription: Subscription;

  showSearch: boolean = false;

  messageType: any = "reply";

  // temp for reassign chat status while switching between notes and reply

  chatExpired: boolean;

  // follow up section
  now: any;
  tomorrow: any;
  nextWeek: any;
  followUpAssigned: boolean = false;
  getFollowUp: boolean = false;
  timePicker: boolean = true;

  // canned response section
  cannedResponses: any = [];

  // user data section
  mainUserData: any;
  // search section
  @Input() searchChat: any;
  searchEnabled: boolean = false;
  scrollType: any = "top";
  selectedId: any;

  // variable data
  fieldData: any = [];

  noContactSelected: any = false;

  // custom templates

  customMessages: any = messageFormats;
  previewFile: any;

  searchText: any;
  searchTimeout: any;

  contactBgs: any = contactBGs;

  customerDetails: any;

  private userDataSubscription: Subscription;
  recordFile: Subscription;

  mediaModal: boolean = false;
  docPreview: boolean = false;
  docSpinner: boolean = false;

  docUrl: any = "";

  constructor(
    private apiService: CommonApiService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private cdref: ChangeDetectorRef,
    private el: ElementRef,
    private alertService: AlertServiceService,
    private audioService: CustomMessageService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private navService: NavService,
    // private formatService: CustomMessageService,
    private audioRecordingService: AudioRecordingService // private opusService: OpusService
  ) {
    this.now = moment().tz("Asia/kolkata").add(2, "hours").format();
    this.tomorrow = moment().tz("Asia/kolkata").add(1, "day").format();
    this.nextWeek = moment().tz("Asia/kolkata").add(1, "week").format();
    setInterval(() => {
      this.now = moment(this.now).add(1, "minutes").format();
      this.tomorrow = moment(this.tomorrow).add(1, "minutes").format();
      this.nextWeek = moment(this.nextWeek).add(1, "minutes").format();
    }, 60000);

    this.audioRecordingService
      .recordingFailed()
      .subscribe(() => (this.isRecording = false));

    this.audioRecordingService.getRecordedTime().subscribe((time) => {
      this.recordedTime = time;
    });
  }

  ngOnInit(): void {
    // console.log(this.customMessages);
    // this.formatService.allFormats();

    // this.formatService.messageFormats.subscribe((data: any) => {
    //   console.log(data);
    //   this.customMessages = data;
    // });

    this.initData();
    this.initialPage = true;
    this.pageIndex = 1;
    if (window.innerWidth > 768) {
      this.modalWidth = "80%";
    } else {
      this.modalWidth = "95%";
    }
    this.getMainUserData();
    this.getFieldData();
    // this.getAssignUserList();
  }

  ngAfterViewChecked() {
    this.cdref.detectChanges();
  }

  // ngAfterContentInit(): void {
  //   console.log("hi");
  //   if (this.searchChat) {
  //     document.getElementById(this.searchChat._id).scrollIntoView(true);
  //     this.searchChat = null;
  //   }
  // }

  // @HostListener("scroll", ["$event"]) onScroll(e) {
  //   console.log(this.el.nativeElement.scrollTop);
  // }

  // @HostListener("document:mousedown", ["$event"])
  // onGlobalClick(event: any): void {
  //   console.log(event.target);
  // }

  temp: any;

  ngOnChanges(changes: SimpleChanges): void {
    // this.subscription && this.subscription.unsubscribe();
    // console.log(this.selectedUser);
    if (this.selectedUser) {
      this.noContactSelected = false;
      if (this.selectedUser._id != this.temp?._id) {
        this.sampleIndex = 0;
        this.scrollType = "top";
        // console.log(this.selectedUser);
        // this.assignedUser = this?.selectedUser?.responsive;
        this.newMessage = "";
        this.messageType = "reply";
        this.assignedUser = "";
        this.pageIndex = 1;
        this.initialPage = true;
        this.chatHistory = [];
        this.areaSpinner = true;
        this.pageScroll = true;
        this.getFollowUp = false;
        this.spinner.show();
        this.getMessages(1);
        this.getQuickMessages("");
        this.getCustomerData();
        this.getAssignUserList();
        this.temp = this.selectedUser;
        if (window.innerWidth > 992) {
          this.searchChat = null;
          this.searchEnabled = false;
        }
        this.stopRecording();
        if (this.recordFile) {
          this.recordFile.unsubscribe();
        }
        this.blobUrl = null;
        this.clearRecordedData();
        // console.log(this.temp);
      }
    } else {
      this.noContactSelected = true;
      this.selectedUser = null;
      this.sampleIndex = 0;
      this.scrollType = "top";
      // console.log(this.selectedUser);
      // this.assignedUser = this?.selectedUser?.responsive;
      this.newMessage = "";
      this.messageType = "reply";
      this.assignedUser = "";
      this.pageIndex = 1;
      this.initialPage = true;
      this.chatHistory = [];
      this.areaSpinner = true;
      this.pageScroll = true;
      this.getFollowUp = false;
      this.chatHistory = {
        isConversationExpired: true,
      };
      this.timeDiffPercentage = 0;
      this.timeDiffInHours = "00:00";
      this.temp = null;
    }

    if (this.customCatalogue) {
      this.chatHistory?.chats?.push(
        JSON.parse(JSON.stringify(this.customCatalogue))
      );
      this.pageScroll = true;
      this.scrollToBottom();
      this.customCatalogue = null;
    }

    if (this.socketData) {
      console.log(this.socketData);

      if (
        this.socketData?.chat?.with == this.selectedUser?._id &&
        this.socketData?.type == "messages"
      ) {
        if (
          (this.socketData?.chat?.conversationType != "sent" &&
            this.socketData?.chat?.conversationType != "sent-as-notes") ||
          this.socketData?.chat?.responseBy == "bot" ||
          this.socketData?.chat?.message?.msgType == "template"
        ) {
          this.socketData.chat.message.data = this.socketData.chat.message.data
            ? JSON.parse(this.socketData?.chat?.message?.data)
            : "";
          // }
          if (this.socketData?.chat?.repliedTo?.message?.data) {
            this.socketData.chat.repliedTo.message.data = JSON.parse(
              this.socketData?.chat?.repliedTo?.message?.data
            );
          }

          if (this?.socketData?.chat?.conversationType == "received") {
            this.subscription && this.subscription.unsubscribe();
            this.progressTimeReset();
            this.subscription = this.source.subscribe((val) =>
              this.progressTime()
            );
            // this.playSound("../../../../../assets/audio/iphone_sound.mp3");
            this.playSound(
              "../../../../../assets/audio/chat_open_notification.mp3"
            );
            if (window.innerWidth < 992) {
              this.makeChatRead();
            }
          }
          this.chatHistory?.chats?.push(this.socketData.chat);
          this.pageScroll = true;

          // console.log(this.chatHistory);

          if (
            this.socketData.chat.conversationType == "received" ||
            this.socketData.chat.conversationType == "customer-assign-info" ||
            this.socketData?.chat?.responseBy == "bot"
          ) {
            if (
              this.socketData.chat.conversationType == "received" ||
              this.socketData?.chat?.responseBy == "bot"
            ) {
              this.chatHistory.isConversationExpired = false;
              this.chatExpired = false;
            }

            if (
              (this.scrollPosition == "at top" &&
                this.socketData.chat.conversationType == "received") ||
              (this.socketData?.chat?.responseBy == "bot" &&
                this.scrollPosition == "at top")
            ) {
              this.newMessageCount = this.newMessageCount + 1;
              this.newMessageAlert = true;
            } else if (this.scrollPosition == "at bottom") {
              this.scrollToBottom();
              this.newMessageCount = 0;
            }
          } else if (this.socketData.chat.conversationType == "sent") {
            this.scrollToBottom();
          }
        }
      }
    }

    if (this.searchChat) {
      // console.log(this.searchChat);
      this.areaSpinner = true;
      this.spinner.show();
      this.initialPage = true;
      this.searchEnabled = true;
      this.selectedId = this?.searchChat?._id;
      this.getMessages(1);
    }

    if (this.tagsReset) {
      this.getCustomerData();
    }

    this.socketData = null;
  }

  sampleIndex: any = 0;

  getMessages(pageNo: any) {
    this.showEmoji = false;
    var data: any = {
      customerId: this.selectedUser?._id,
    };

    if (this.searchChat) {
      data.chatId = this.searchChat._id;
    }

    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url + "chat/get-customer-chats?page=" + pageNo,
        data,
        options
      )
      .subscribe(
        (response: any) => {
          // console.log(response);
          if (!this.getFollowUp) {
            if (this.initialPage) {
              this.chatHistory = response.data;
              this.chatExpired = this.chatHistory.isConversationExpired;
              for (let i = 0; i < this.chatHistory.chats.length; i++) {
                this.chatHistory.chats[i].message.data = this.chatHistory.chats[
                  i
                ].message.data
                  ? JSON.parse(this.chatHistory.chats[i].message.data)
                  : "";
                if (this.chatHistory.chats[i].repliedTo) {
                  this.chatHistory.chats[i].repliedTo.message.data = this
                    .chatHistory?.chats[i]?.repliedTo?.message?.data
                    ? JSON.parse(
                        this?.chatHistory?.chats[i]?.repliedTo?.message?.data
                      )
                    : "";
                }
              }
            } else {
              for (let i = 0; i < response.data.chats.length; i++) {
                response.data.chats[i].message.data = response?.data?.chats[i]
                  ?.message?.data
                  ? JSON.parse(response?.data?.chats[i]?.message?.data)
                  : "";
                if (response.data.chats[i].repliedTo) {
                  response.data.chats[i].repliedTo.message.data = response?.data
                    ?.chats[i]?.repliedTo?.message?.data
                    ? JSON?.parse(
                        response?.data?.chats[i]?.repliedTo?.message?.data
                      )
                    : "";
                }
              }
              if (this.searchEnabled && this.scrollType == "bottom") {
                this.chatHistory.chats = this.chatHistory.chats.concat(
                  response.data.chats
                );
              } else if (this.scrollType == "top") {
                this.chatHistory.chats = response.data.chats.concat(
                  this.chatHistory.chats
                );
              }

              this.isLoading = false;
            }
          } else {
            this.chatHistory.customerData = response.data.customerData;
          }

          this.subscription && this.subscription.unsubscribe();

          this.timeDiffPercentage =
            this.chatHistory.timeDiffInSeconds > 86400
              ? 0
              : ((86400 - this.chatHistory.timeDiffInSeconds) / 86400) * 100;

          this.timeDiffInHours =
            this.chatHistory.timeDiffInSeconds > 86400
              ? "00:00"
              : moment()
                  .startOf("day")
                  .seconds(86400 - this.chatHistory.timeDiffInSeconds)
                  .format("HH:mm");

          this.subscription = this.source.subscribe((val) =>
            this.progressTime()
          );

          console.log(this.chatHistory);

          if (this?.chatHistory?.customerData?.followUp) {
            this.followUpAssigned = true;
            // this.timePicker = false;
          } else {
            this.followUpAssigned = false;
          }

          this.spinner.hide();
          this.areaSpinner = false;

          if (this.searchChat) {
            this.pageIndex = response?.data?.page;
            this.sampleIndex = response?.data?.page;
            setTimeout(() => {
              document
                .getElementById("a" + this.searchChat._id)
                .scrollIntoView({ behavior: "smooth" });
              this.searchChat = null;
            }, 1000);
            setTimeout(() => {
              this.selectedId = "";
            }, 2000);
          } else if (this.scrollType != "bottom") {
            this.scrollToBottom();
          }
        },
        (error: any) => {
          console.log(error);
          this.spinner.hide();
          this.areaSpinner = false;
          this.isLoading = false;
        }
      );
    // this.cdref.detectChanges();
  }

  getCustomerData() {
    var data = {
      customerId: this.selectedUser._id,
    };

    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(environment.url + "customers/detail", data, options)
      .subscribe(
        (response: any) => {
          this.customerDetails = response.data;
          // console.log(this.customerDetails);
          this.tagsReset = false;
        },
        (error: any) => {
          console.log(error);
        }
      );
  }

  /**
   * set user
   */
  initData(): void {
    this.toUser = {
      id: 9,
      name: "Shreyu N",
      avatar: "assets/images/users/avatar-2.jpg",
    };
  }

  showChatUsers(option: any) {
    // this.switchPage.emit(option);
    this.router.navigate(["/chat/mobile", "id", "name"]);
  }

  /**
   * add new message
   */
  sendChatMessage(): void {
    // const modifiedMessages = [
    //   ...this.chatHistory[this.chatHistory.length - 1].messages,
    // ];
    // modifiedMessages.push({
    //   id: modifiedMessages.length + 1,
    //   from: this.toUser,
    //   to: this.selectedUser,
    //   messages: [{ type: "text", value: this.newMessage }],
    //   sendOn: new Date().getHours() + ":" + new Date().getMinutes(),
    // });
    // this.chatHistory[this.chatHistory.length - 1].messages = modifiedMessages;
    // console.log(this.newMessage);
    var data = {
      customerId: this.selectedUser._id,
      message: this.newMessage,
      type: "text",
      saveMessageAs: this.messageType == "notes" ? "notes" : "conversation",
    };

    this.setCustomMessage("text", "");

    this.selectedStyle = "";

    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(environment.url + "chat/send-message", data, options)
      .subscribe(
        (response: any) => {
          // console.log(response);
          response.data.chat.message.data = JSON.parse(
            response.data?.chat?.message?.data
          );
          // this.chatHistory.chats.push(response.data.chat);
          // console.log(response);
          // this.showEmoji = false;
          // this.newMessage = "";
          // this.pageScroll = true;
          // this.scrollToBottom();
        },
        (err: any) => {
          console.log(err);
        }
      );
    // this.chatForm.resetForm();
  }

  // scroll to bottom of chat box area

  scrollToBottom = () => {
    if (this.pageScroll) {
      setTimeout(() => {
        // Scroll to bottom first time
        this.chatBox.nativeElement.scrollTop =
          this.chatBox.nativeElement.scrollHeight;
      }, 1.5);

      // console.log("page scroll true");
    }

    // console.log("page scroll false");
  };

  toggleEmoji() {
    this.showCannedResponse = false;
    this.showEmoji = !this.showEmoji;
  }

  onFocus() {
    this.showEmoji = false;
  }

  addEmoji(selected: any) {
    // console.log(selected);
    const { newMessage } = this;
    const text = `${newMessage}${selected.emoji.native}`;
    this.newMessage = text;
  }

  getFile(event: any) {
    this.showCannedResponse = false;
    this.showEmoji = false;
    this.previewDoc = 0;
    this.displayMedia = [];
    this.mediaSelected = event.target.files;
    console.log(event.target.files);
    // const file = event.target.files[0];
    // const reader = new FileReader();
    // reader.onload = (e) => (this.previewDoc = reader.result);
    // reader.readAsDataURL(file);

    for (let i = 0; i < this.mediaSelected.length; i++) {
      if (i < 5) {
        // console.log(event.target?.files);
        if (this.allFileTypes.indexOf(event.target?.files[i]?.type) != -1) {
          let temp: any = {};
          temp.type = event.target?.files[i]?.type;
          temp.name = event.target?.files[i]?.name;
          let file = event.target.files[i];
          let reader = new FileReader();
          reader.onload = (e) => (temp.url = reader.result);
          reader.readAsDataURL(file);
          this.displayMedia.push(temp);
        } else {
          this.toastr.error("File format of some files not supported", "", {
            timeOut: 2500,
          });
        }
      } else {
        this.toastr.error("You can select maximum 5 files", "", {
          timeOut: 2500,
        });
        break;
      }
    }

    // this.previewDoc = this.displayMedia[0];
    // console.log(this.displayMedia);
    if (this.displayMedia.length > 0) {
      this.previewModal = true;
      this.previewFile = this.displayMedia[0];
    }
    // event.target.value = "";
  }

  closePreviewModal() {
    this.previewModal = false;
    // this.previewDoc = "";
    this.displayMedia = [];
    this.fileInput.nativeElement.value = null;
  }

  preview(media: any, index: any) {
    this.previewDoc = index;
    this.previewFile = media;
    // console.log(media);
  }

  uploadMedia() {
    // console.log(this.mediaSelected.length);

    // this.uploading = true;

    // console.log(this.mediaSelected);

    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    for (let k = 0; k < this.mediaSelected.length; k++) {
      var formData = new FormData();
      console.log(this.mediaSelected[k]);
      formData.append("media", this.mediaSelected[k]);
      formData.append("customerId", this.selectedUser._id);
      formData.append("saveMessageAs", "conversation");

      if (this.imageTypes.indexOf(this.mediaSelected[k]?.type) != -1) {
        formData.append("type", "image");
        formData.append(
          "message",
          this.displayMedia[k]?.captionText
            ? this.displayMedia[k]?.captionText
            : ""
        );
        this.setCustomMessage("image", this.displayMedia[k]);
      } else if (this.videoTypes.indexOf(this.mediaSelected[k]?.type) != -1) {
        formData.append("type", "video");
        formData.append(
          "message",
          this.displayMedia[k]?.captionText
            ? this.displayMedia[k]?.captionText
            : ""
        );
        this.setCustomMessage("video", this.displayMedia[k]);
      } else if (this.docTypes.indexOf(this.mediaSelected[k]?.type) != -1) {
        formData.append("type", "document");
        formData.append("message", "");
        this.setCustomMessage("document", this.displayMedia[k]);
      } else if (this.audioTypes.indexOf(this.mediaSelected[k]?.type) != -1) {
        formData.append("type", "audio");
        formData.append("message", "");
        this.setCustomMessage("audio", this.displayMedia[k]);
      }

      this.blobUrl = null;

      this.apiService
        .commonPostMethod(
          environment.url + "chat/send-media-message",
          formData,
          options
        )
        .subscribe(
          (response: any) => {
            // console.log(response);
            this.pageScroll = true;
            this.scrollToBottom();
            response.data.chat.message.data = JSON.parse(
              response.data?.chat?.message?.data
            );
            // this.chatHistory.chats.push(response.data.chat);
            if (k == this.mediaSelected.length - 1) {
              this.uploading = false;
              this.previewModal = false;
              this.mediaSelected = [];
              this.displayMedia = [];
            }
          },
          (err: any) => {
            console.log(err);
            this.uploading = false;
            this.previewModal = false;
            if (err?.error?.message) {
              this.toastr.error(err?.error?.message, "", {
                timeOut: 2500,
              });
            } else {
              this.toastr.error(err?.message, "", {
                timeOut: 2500,
              });
            }
          }
        );
    }

    if (this.fileInput?.nativeElement) {
      this.fileInput.nativeElement.value = null;
    }
  }

  // TEMPLATE SECTION

  showTemplateModal() {
    this.templatePage = 1;
    this.showCannedResponse = false;
    this.showEmoji = false;
    this.modalSpinner = true;
    this.templateLoading = false;
    this.messageTemplateSelected = null;
    this.getVariableValue = false;
    this.sendingTemplateMedia = false;
    this.templateModal = true;
    this.nextKey = "";
    this.getTemplates("all");
  }

  closeTemplateModal() {
    this.templateModal = false;
  }

  getTemplates(key: any) {
    if (key == "all") {
      this.modalSpinner = true;
    }
    this.spinner.show();
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };
    var body = {
      filterByName: this.searchText,
      filterByStatus: "approved",
    };

    this.apiService
      .commonPostMethod(
        // environment.url + "templates?next=" + this.nextKey,
        environment.url + "templates/get-all?page=" + this.templatePage, // new api url
        body,
        options
      )
      .subscribe(
        (response: any) => {
          // console.log(response);
          for (let i = 0; i < response?.data?.waMsgTemplates.length; i++) {
            for (
              let j = 0;
              j < response?.data?.waMsgTemplates[i].components.length;
              j++
            ) {
              if (response?.data?.waMsgTemplates[i].components[j].text) {
                response.data.waMsgTemplates[i].components[j].text =
                  this.format_text(
                    response?.data?.waMsgTemplates[i].components[j]?.text
                  );
              }
            }
          }

          if (key == "all") {
            this.templateData = response?.data?.waMsgTemplates;
          } else if (key == "new") {
            // for (let n = 0; n < response?.data?.waMsgTemplates.length; n++) {
            //   this.templateData.push(response?.data?.waMsgTemplates[n]);
            // }
            this.templateData = this.templateData.concat(
              response?.data?.waMsgTemplates
            );
          }
          // console.log(this.templateData);
          // this.nextKey = response.data.next;
          this.syncing = false;
          this.spinner.hide();
          this.templateSpinner = false;
          this.modalSpinner = false;
          // console.log(this.templateData);
        },
        (error: any) => {
          this.spinner.hide();
          this.modalSpinner = false;
          console.log(error);
        }
      );
  }

  showPreview(selected: any) {
    // console.log(selected);
    this.variableData = [];
    if (this.messageTemplateSelected?._id == selected._id) {
      this.messageTemplateSelected = null;
    } else {
      this.messageTemplateSelected = selected;
    }
  }

  selectTemplate() {
    this.templateLoading = true;
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };
    var data = { messageId: this.messageTemplateSelected._id };
    this.selectedPreview = this.messageTemplateSelected.components;
    this.apiService
      .commonPostMethod(
        environment.url + "templates/get-variables-template-data",
        data,
        options
      )
      .subscribe(
        (response: any) => {
          this.variableData = response.data;
          // console.log(this.variableData);
          if (this?.variableData?.mediaData?.type == "IMAGE") {
            this.acceptedTypes = this.imageTypes;
          } else if (this?.variableData?.mediaData?.type == "VIDEO") {
            this.acceptedTypes = this.videoTypes;
          } else if (this?.variableData?.mediaData?.type == "DOCUMENT") {
            this.acceptedTypes = this.docTypes;
          }
          if (
            this.variableData?.variableTemplate?.length > 0 ||
            this.variableData?.mediaData.hasMedia
          ) {
            this.templateMediaPreview = null;
            this.sendingTemplateMedia = false;
            this.templateLoading = false;
            this.getVariableValue = true;
          } else {
            this.sendTemplate();
          }
        },
        (error) => {
          console.log(error);
          this.templateLoading = false;
        }
      );
  }

  getTemplateMedia(event: any) {
    // if (this.variableData?.mediaData?.type == "VIDEO") {
    //   if (this.videoTypes.indexOf(event.target?.files[0]?.type) != -1) {
    this.templateMedia = event.target.files[0];
    // console.log(this.templateMedia);
    const file = event.target.files[0];

    const reader = new FileReader();
    reader.onload = (e) => (this.templateMediaPreview = reader.result);
    reader.readAsDataURL(file);
    // } else {
    //   this.toastr.error("Please Select Video file", "", {
    //     timeOut: 2500,
    //   });
    // }
    // } else if (this.variableData?.mediaData?.type == "IMAGE") {
    //   if (this.imageTypes.indexOf(event.target?.files[0]?.type) != -1) {
    //     this.templateMedia = event.target.files[0];
    //     // console.log(this.templateMedia);
    //     const file = event.target.files[0];

    //     const reader = new FileReader();
    //     reader.onload = (e) => (this.templateMediaPreview = reader.result);
    //     reader.readAsDataURL(file);
    //   } else {
    //     this.toastr.error("Please Select Image file", "", {
    //       timeOut: 2500,
    //     });
    //   }
    // } else if (this.variableData?.mediaData?.type == "DOCUMENT") {
    //   if (this.docTypes.indexOf(event.target?.files[0]?.type) != -1) {
    //     this.templateMedia = event.target.files[0];
    //     // console.log(this.templateMedia);
    //     const file = event.target.files[0];

    //     const reader = new FileReader();
    //     reader.onload = (e) => (this.templateMediaPreview = reader.result);
    //     reader.readAsDataURL(file);
    //   } else {
    //     this.toastr.error("Please Select Document file", "", {
    //       timeOut: 2500,
    //     });
    //   }
    // }
  }

  submitTemplate() {
    // var totalVariableCount: any = 0;
    if (
      this.variableData?.mediaData.hasMedia &&
      this.variableData?.variableTemplate?.length == 0
    ) {
      this.sendTemplate();
    } else {
      for (let i = 0; i < this.variableData?.variableTemplate?.length; i++) {
        var stopLoop = false;
        for (
          let j = 0;
          j < this.variableData?.variableTemplate[i]?.variables?.length;
          j++
        ) {
          // totalVariableCount =
          //   totalVariableCount +
          //   this?.variableData?.variableTemplate[i]?.variables[j]?.fallbackValue
          //     ?.length;
          // console.log(totalVariableCount);
          if (
            this.variableData.variableTemplate[i].variables[j].fallbackValue ==
              "" ||
            this.variableData.variableTemplate[i].variables[j].field == ""
          ) {
            this.toastr.error("Please Fill the Variable Data", "", {
              timeOut: 2500,
            });
            stopLoop = true;
            break;
          }
        }
        if (stopLoop) {
          break;
        } else if (
          !this.templateMedia &&
          this.variableData?.mediaData.hasMedia
        ) {
          this.toastr.error("Please Upload Files", "", {
            timeOut: 2500,
          });
          break;
        } else {
          // if (totalVariableCount <= 4096) {
          this.sendTemplate();
          // } else {
          //   this.toastr.error("Total Variable Data Length Exceeds 4096", "", {
          //     timeOut: 2500,
          //   });
          // }
          break;
        }
      }
    }
  }

  sendTemplate() {
    // console.log(this.messageTemplateSelected);
    // console.log(this.variableData);

    let url = "";
    var data: any;
    if (this.variableData?.mediaData.hasMedia) {
      url = "chat/send-media-template-message";
      var formData = new FormData();
      formData.append("customerId", this.selectedUser._id);
      formData.append("templateId", this.messageTemplateSelected._id);
      formData.append("media", this.templateMedia);

      if (this.variableData?.variableTemplate.length > 0) {
        for (let i = 0; i < this.variableData?.variableTemplate.length; i++) {
          formData.append(
            "templateVariableData[" + i + "][type]",
            this.variableData?.variableTemplate[i].type
          );
          for (
            let j = 0;
            j < this.variableData?.variableTemplate[i].variables.length;
            j++
          ) {
            formData.append(
              "templateVariableData[" + i + "][variables][" + j + "][field]",
              this.variableData?.variableTemplate[i].variables[j].field
            );
            formData.append(
              "templateVariableData[" +
                i +
                "][variables][" +
                j +
                "][fallbackValue]",
              this.variableData?.variableTemplate[i].variables[j].fallbackValue
            );
          }
        }
      }
    } else {
      url = "chat/send-text-template-message";
      data = {
        customerId: this.selectedUser._id,
        templateId: this.messageTemplateSelected._id,
        templateVariableData: this.variableData?.variableTemplate,
      };
    }

    this.sendingTemplateMedia = true;
    // this.setCustomMessage("template", "");
    var options: any = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    // if (this.variableData?.mediaData.hasMedia) {

    // }

    // console.log(data);

    this.apiService
      .commonPostMethod(
        environment.url + url,
        this.variableData?.mediaData.hasMedia ? formData : data,
        options
      )
      .subscribe(
        (response: any) => {
          // console.log(response);
          // response.data.chat.message.data = JSON.parse(
          //   response.data?.chat?.message?.data
          // );
          // this.chatHistory.chats.push(response.data.chat);
          this.templateModal = false;
          this.sendingTemplateMedia = false;
          this.getVariableValue = false;
          this.templateLoading = false;
          this.pageScroll = true;
          this.scrollToBottom();
        },
        (error) => {
          console.log(error);
          if (error?.error?.message) {
            this.toastr.error(error?.error?.message, "", {
              timeOut: 2500,
            });
          } else {
            this.toastr.error(error?.message, "", {
              timeOut: 2500,
            });
          }
          // this.templateModal = false;
          this.sendingTemplateMedia = false;
          this.templateLoading = false;
        }
      );
  }

  backToChooseTemplate() {
    this.getVariableValue = false;
  }

  onScrollDown() {
    this.selectedId = "";
    // console.log(this.searchEnabled);
    if (this.pageIndex > 1 && this.searchEnabled) {
      if (this.pageIndex >= this.sampleIndex) {
        this.pageIndex = this.sampleIndex;
      }
      this.scrollType = "bottom";
      this.initialPage = false;
      this.pageIndex = this.pageIndex - 1;
      this.getMessages(this.pageIndex);
    }
  }

  onScrollUp() {
    this.selectedId = "";
    if (this.sampleIndex > this.pageIndex) {
      this.pageIndex = this.sampleIndex;
    }
    this.scrollType = "top";
    this.isLoading = true;
    this.pageScroll = false;
    this.getFollowUp = false;
    // console.log("Scroll Up");
    this.initialPage = false;
    this.pageIndex = this.pageIndex + 1;
    this.getMessages(this.pageIndex);
    // console.log("Page Number : " + this.pageIndex);
  }

  format_text(text: any) {
    return text
      .replace(/(?:\*)(?:(?!\s))((?:(?!\*|\n).)+)(?:\*)/g, "<b>$1</b>")
      .replace(/(?:_)(?:(?!\s))((?:(?!\n|_).)+)(?:_)/g, "<em>$1</em>")
      .replace(/(?:~)(?:(?!\s))((?:(?!\n|~).)+)(?:~)/g, "<s>$1</s>")
      .replace(/(?:--)(?:(?!\s))((?:(?!\n|--).)+)(?:--)/g, "<u>$1</u>")
      .replace(/(?:```)(?:(?!\s))((?:(?!\n|```).)+)(?:```)/g, "<tt>$1</tt>");
  }

  reverseFormatText(text: any) {
    return text
      .replaceAll("<b>", "*")
      .replaceAll("</b>", "*")
      .replaceAll("<p>", "")
      .replaceAll("</p>", "")
      .replaceAll("<i>", "_")
      .replaceAll("</i>", "_")
      .replaceAll("<strike>", "~")
      .replaceAll("</strike>", "~")
      .replaceAll("&#160;", "")
      .replaceAll("&#10;", "")
      .replaceAll("<br>", "\n")
      .replaceAll("<span>", "")
      .replaceAll("</span>", "");
  }

  closeContactModal() {
    this.contactModal = false;
  }

  viewContactDetails(details: any) {
    // console.log(details);
    this.detailedContacts = details;
    this.contactModal = true;
  }

  showCatalogue() {
    if (window.innerWidth > 992) {
      this.showCannedResponse = false;
      this.showEmoji = false;
      this.catalogueData.type = "catalogue";
      this.switchPage.emit(this.catalogueData);
    } else {
      this.router.navigate([
        "/chat/catalogue",
        this.selectedUser?._id,
        this.selectedUser?.name,
      ]);
    }
  }

  viewCartDetails(cartItem: any) {
    this.cartItemSelected = cartItem;
    // console.log(this.cartItemSelected);
    this.cartDetailModal = true;
  }

  closeCartDetailModal() {
    this.cartDetailModal = false;
  }

  scrollHeight(event: any) {
    // console.log(event);
    this.scrollPosition = event;
    if (event == "at bottom") {
      this.newMessageAlert = false;
      this.newMessageCount = 0;
    }
  }

  showNewMessage() {
    this.scrollToBottom();
    this.newMessageAlert = false;
    this.newMessageCount = 0;
  }

  // assign message to user

  assignedUser: any = "";
  assignUsersList: any = [];

  getAssignUserList() {
    var options: any = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };
    this.apiService
      .commonGetMethod(
        environment.url + "auth/get-all-user-data?projection=name",
        options
      )
      .subscribe(
        (response: any) => {
          // console.log(response);
          this.assignUsersList = response.data.users;
          this.assignedUser = this?.selectedUser?.responsive;
        },
        (error) => {
          console.log(error);
        }
      );
  }

  assignMessage() {
    if (this.mainUserData?.accessTo?.isExpired) {
      this.alertService.warning();
    } else {
      let options = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
        },
      };

      var data = {
        customerId: this.selectedUser._id,
        responsive: this.assignedUser,
      };
      // console.log(data);

      this.apiService
        .commonPostMethod(
          environment.url + "customers/assign-user",
          data,
          options
        )
        .subscribe(
          (response: any) => {
            // console.log(response);
            this.toastr.success(response.message, "", {
              timeOut: 2500,
            });
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }

  // scroll pagination for templates

  bottomScrolled() {
    // console.log("scrolled");
    // if (this.nextKey) {
    this.templatePage++;
    this.templateSpinner = true;
    this.getTemplates("new");
    // }
  }

  // sandbox-message

  sandBoxModal = false;
  closeSandBoxModal() {
    this.sandBoxModal = false;
  }

  // progress bar setting

  progressTime() {
    // console.log(86400 - this.chatHistory.timeDiffInSeconds);
    if (86400 - this.chatHistory.timeDiffInSeconds < 100) {
      // alert("hit");
      this.chatHistory.isConversationExpired = true;
      this.subscription && this.subscription.unsubscribe();
    }

    this.chatHistory.timeDiffInSeconds =
      this.chatHistory.timeDiffInSeconds + 60;

    this.timeDiffPercentage =
      this.chatHistory.timeDiffInSeconds > 86400
        ? 0
        : ((86400 - this.chatHistory.timeDiffInSeconds) / 86400) * 100;

    this.timeDiffInHours =
      this.chatHistory.timeDiffInSeconds > 86400
        ? "00:00"
        : moment()
            .startOf("day")
            .seconds(86400 - this.chatHistory.timeDiffInSeconds)
            .format("HH:mm");
  }

  progressTimeReset() {
    this.chatHistory.timeDiffInSeconds = 60;

    this.timeDiffPercentage =
      this.chatHistory.timeDiffInSeconds > 86400
        ? 0
        : ((86400 - this.chatHistory.timeDiffInSeconds) / 86400) * 100;

    this.timeDiffInHours =
      this.chatHistory.timeDiffInSeconds > 86400
        ? "00:00"
        : moment()
            .startOf("day")
            .seconds(86400 - this.chatHistory.timeDiffInSeconds)
            .format("HH:mm");
  }

  closeChat() {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    var data: any = {
      customerId: this?.selectedUser?._id,
    };
    var toastrMsg: any = "";

    if (this?.chatHistory?.customerData?.inboxStatus == "closed") {
      data.inboxStatus = "open";
      toastrMsg = "Chat opened!..";
    } else {
      data.inboxStatus = "closed";
      toastrMsg = "Chat closed!..";
    }

    this.apiService
      .commonPostMethod(
        environment.url + "customers/update-inbox-status",
        data,
        options
      )
      .subscribe(
        (response: any) => {
          // console.log(response);
          // this.reloadUserList.emit();
          // this.getMessages(this.pageIndex);
          if (this?.chatHistory?.customerData?.inboxStatus == "closed") {
            this.chatHistory.customerData.inboxStatus = "opened";
          } else {
            this.chatHistory.customerData.inboxStatus = "closed";
          }
          this.toastr.success(toastrMsg, "", {
            timeOut: 2500,
          });
        },
        (error) => {
          console.log(error);
          this.toastr.success(error?.error?.message, "", {
            timeOut: 2500,
          });
        }
      );
  }

  changeMessageType(type: string) {
    this.showCannedResponse = false;
    this.showEmoji = false;
    this.newMessage = "";
    this.messageType = type;
    if (type == "notes") {
      this.chatHistory.isConversationExpired = false;
    } else {
      this.chatHistory.isConversationExpired = this.chatExpired;
    }
  }

  playSound(url: any) {
    const audio = new Audio(url);
    audio.play();
  }

  showChatSearch() {
    if (!this.mainUserData?.accessTo?.isExpired) {
      if (window.innerWidth > 992) {
        this.switchChatSearch.emit(true);
      } else {
        this.router.navigate([
          "/chat/search",
          this.selectedUser?._id,
          this.selectedUser?.name,
        ]);
      }
    } else {
      this.alertService.warning();
    }
  }

  inputChange(event: any) {
    // console.log(event.which);
    // console.log(event.shiftKey);
    if (event.which == 13 && !event.shiftKey) {
      event.preventDefault(); //Stops enter from creating a new line
      if (this.newMessage) {
        this.sendChatMessage();
      }
    }
  }

  keyUpEvent() {
    if (this.messageType == "reply") {
      if (this?.newMessage == "#" || this?.newMessage?.charAt(0) == "#") {
        this.getQuickMessages(this?.newMessage?.replace("#", ""));
        this.showCannedResponse = true;
      } else {
        this.showCannedResponse = false;
      }
    }
  }

  sendCannedMessage(event: any, selected: any) {
    event.stopPropagation();
    // console.log("canned response");
    this.showCannedResponse = false;
    this.newMessage = this.reverseFormatText(selected);
    var matchingKeys = this.newMessage.match(/\{.+?\}/g);
    if (matchingKeys) {
      for (let s = 0; s < matchingKeys.length; s++) {
        var assigned = "";
        var errKey: any = "";
        var selectedKey: any = new RegExp(`${matchingKeys[s]}`, "g");
        // console.log(matchingKeys[s]);
        if (matchingKeys[s] == "{email}") {
          assigned = this?.selectedUser?.email;
          errKey = "Email";
        } else if (matchingKeys[s] == "{name}") {
          assigned = this?.selectedUser?.name;
          errKey = "Name";
        } else if (matchingKeys[s] == "{phoneNo}") {
          assigned = this?.selectedUser?.phoneNo;
          errKey = "Mobile Number";
        } else {
          assigned = matchingKeys[s];
        }
        if (assigned) {
          this.newMessage = this.newMessage.replace(selectedKey, assigned);
        } else {
          // console.log(selectedKey);
          this.newMessage = "";
          this.toastr.error(errKey + " not available for this user!", "", {
            timeOut: 2500,
          });
        }
      }
    }
  }

  onOk(event: any) {
    console.log(event);
    this.addFollowUp(event, "customDate");
  }

  addFollowUp(data: any, type: string) {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };
    var reqObj: any = {
      customerId: this.selectedUser._id,
    };

    var url = "";

    if (this?.chatHistory?.customerData?.followUp) {
      url = "customers/update-follow-up";
    } else {
      url = "customers/create-follow-up";
    }

    if (type == "customDate") {
      reqObj.customDate = moment(data).format();
      reqObj.type = "custom";
    } else if (type == "predefined") {
      reqObj.type = data;
    }

    this.apiService
      .commonPostMethod(environment.url + url, reqObj, options)
      .subscribe(
        (response: any) => {
          // console.log(response);
          this.getFollowUp = true;
          this.getMessages(this.pageIndex);
          // if (type == "customDate") {
          //   this.timePicker = false;
          // }
          this.toastr.success(response.message, "", {
            timeOut: 2500,
          });
        },
        (error) => {
          console.log(error);
          if (error?.error?.message) {
            this.toastr.error(error?.error?.message, "", {
              timeOut: 2500,
            });
          } else {
            this.toastr.error(error?.message, "", {
              timeOut: 2500,
            });
          }
        }
      );
  }

  deleteFollowUp() {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };
    var reqObj: any = {
      customerId: this.selectedUser._id,
    };

    this.apiService
      .commonPostMethod(
        environment.url + "customers/delete-follow-up",
        reqObj,
        options
      )
      .subscribe(
        (response: any) => {
          // console.log(response);
          this.getFollowUp = true;
          this.getMessages(this.pageIndex);
          this.toastr.success(response.message, "", {
            timeOut: 2500,
          });
        },
        (error) => {
          console.log(error);
          if (error?.error?.message) {
            this.toastr.error(error?.error?.message, "", {
              timeOut: 2500,
            });
          } else {
            this.toastr.error(error?.message, "", {
              timeOut: 2500,
            });
          }
        }
      );
  }

  showEdit() {
    this.followUpAssigned = false;
  }

  getQuickMessages(searchKey: any) {
    this.spinner.show();
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url + "quick-message?noPagination",
        { filterValue: searchKey },
        options
      )
      .subscribe(
        (response: any) => {
          // console.log(response);
          this.cannedResponses = response.data.quickMessages;
          this.spinner.hide();
        },
        (error) => {
          console.log(error);
          this.spinner.hide();
        }
      );
  }

  // showTimePicker() {
  //   this.timePicker = true;
  // }

  // make user important

  makeUserImportant() {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    var toastrMsg: string = "";

    var reqObj: any = {
      customerId: this.selectedUser._id,
    };

    if (
      this?.mainUserData?.importantCustomers?.indexOf(this?.selectedUser?._id) >
      -1
    ) {
      reqObj.action = "unimportant";
      toastrMsg = "customer removed from important!..";
    } else {
      reqObj.action = "important";
      toastrMsg = "customer added to important!..";
    }

    this.apiService
      .commonPostMethod(
        environment.url + "customers/customize",
        reqObj,
        options
      )
      .subscribe(
        (response: any) => {
          // console.log(response);
          this.navService.getMainUserData();
          // this.getMainUserData();
          if (reqObj.action == "unimportant") {
            this.reloadUserList.emit();
          }
          this.toastr.success(toastrMsg, "", {
            timeOut: 2500,
          });
        },
        (error) => {
          this.toastr.error("Customer already added to important!..", "", {
            timeOut: 2500,
          });
          console.log(error);
        }
      );
  }

  getMainUserData() {
    this.userDataSubscription = this.navService.mainUserData.subscribe(
      (response: any) => {
        if (response) {
          // console.log(response);
          this.mainUserData = response.data.userData;
        }
      }
    );
    // let options = {
    //   headers: {
    //     Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
    //   },
    // };

    // this.apiService
    //   .commonGetMethod(environment.url + "auth/get-user-data", options)
    //   .subscribe(
    //     (response: any) => {
    //       this.mainUserData = response.data.userData;
    //       console.log(this.mainUserData);
    //     },
    //     (error: any) => {
    //       console.log(error);
    //     }
    //   );
  }

  followUpKey(type: any) {
    if (this.mainUserData?.accessTo?.isExpired) {
      this.alertService.warning();
    } else if (type == "follow up") {
      if (this?.chatHistory?.customerData?.followUp) {
        this.followUpAssigned = true;
      } else {
        this.followUpAssigned = false;
      }
    }
  }

  getFieldData() {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url + "customer-field/get?filter=for-template",
        {},
        options
      )
      .subscribe(
        (response: any) => {
          this.fieldData = response.data.customerFields;
          // console.log(this.fieldData);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  outside() {
    // console.log("out");
  }

  // sync template section

  syncing: boolean = false;

  syncTemplate() {
    this.syncing = true;
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url + "templates/sync-templates",
        {},
        options
      )
      .subscribe(
        (response: any) => {
          // console.log(response);
          this.templatePage = 1;
          this.getTemplates("all");
          this.toastr.success(response.message, "", {
            timeOut: 2500,
          });
        },
        (err: any) => {
          console.log(err);
          this.syncing = false;
        }
      );
  }

  setCustomMessage(type: any, media: any) {
    // console.log(this.newMessage);
    var targetIndex = this.customMessages?.findIndex((e: any) => {
      return e?.message?.msgType == type;
    });

    var tempMsg: any = Object.assign({}, this.customMessages[targetIndex]);

    tempMsg.responseByUser.firstName = this.mainUserData?.firstName;
    tempMsg.responseByUser.lastName = this.mainUserData?.lastName;
    tempMsg.time = new Date();

    if (type == "text") {
      tempMsg.message.data.text = this.format_text(
        this.newMessage?.replaceAll("\n", "<br>")
      );
      if (this.messageType == "notes") {
        tempMsg.conversationType = "sent-as-notes";
      }
      this.chatHistory?.chats?.push(JSON.parse(JSON.stringify(tempMsg)));
      this.showEmoji = false;
      this.newMessage = "";
      this.pageScroll = true;
      this.scrollToBottom();
    }

    if (type == "image") {
      tempMsg.message.data.caption = media?.captionText;
      tempMsg.message.media.url = media?.url;
      this.chatHistory?.chats?.push(JSON.parse(JSON.stringify(tempMsg)));
      this.uploading = false;
      this.previewModal = false;
      this.pageScroll = true;
      this.scrollToBottom();
    }

    if (type == "video") {
      // console.log(media);
      tempMsg.message.data.caption = media?.captionText;
      tempMsg.message.media.url = media?.url;
      this.chatHistory?.chats?.push(JSON.parse(JSON.stringify(tempMsg)));
      this.uploading = false;
      this.previewModal = false;
      this.pageScroll = true;
      this.scrollToBottom();
    }

    if (type == "audio") {
      // console.log(media);
      tempMsg.message.media.url = media?.url;
      this.chatHistory?.chats?.push(JSON.parse(JSON.stringify(tempMsg)));
      this.uploading = false;
      this.previewModal = false;
      this.pageScroll = true;
      this.scrollToBottom();
    }

    if (type == "document") {
      // console.log(media);
      tempMsg.message.data.caption = media?.captionText;
      tempMsg.message.media.url = media?.url;
      tempMsg.message.data.filename = media.name;
      this.chatHistory?.chats?.push(JSON.parse(JSON.stringify(tempMsg)));
      this.uploading = false;
      this.previewModal = false;
      this.pageScroll = true;
      this.scrollToBottom();
    }

    if (type == "template") {
      // console.log(media);
      tempMsg.message.data = this?.messageTemplateSelected?.components;
      tempMsg.message.media.url = this.templateMediaPreview;
      this.chatHistory?.chats?.push(JSON.parse(JSON.stringify(tempMsg)));
      this.templateModal = false;
      this.sendingTemplateMedia = false;
      this.getVariableValue = false;
      this.templateLoading = false;
      this.pageScroll = true;
      this.scrollToBottom();
    }

    // console.log(this.chatHistory);
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  controlAudio(event: any) {
    this.audioService.setCurrentlyPlayingAudio(event);
  }

  controlVideo(event: any) {
    this.audioService.setCurrentlyPlayingVideo(event);
  }

  scrollButton() {
    // console.log("new");
    if (this.searchEnabled) {
      this.searchChat = null;
      this.pageIndex = 1;
      this.initialPage = true;
      this.searchEnabled = false;
      this.sampleIndex = 0;
      this.getMessages(1);
    } else {
      this.pageScroll = true;
      this.scrollToBottom();
    }
  }

  searchTemplates() {
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      this.templatePage = 1;
      this.getTemplates("all");
    }, 500);
  }

  templateCategoryFilter(filter: any, event: any) {
    // console.log(event);
    if (event) {
      this.templateFilterArr.push(filter);
    } else {
      this.templateFilterArr.splice(this.templateFilterArr.indexOf(filter), 1);
    }

    // console.log(this.templateFilterArr);
  }

  // custom editor section

  selectedStyle: string = "";

  editText(style: any) {
    const editorElement = this.bodyText.nativeElement;
    const selectedText = editorElement.value.substring(
      editorElement.selectionStart,
      editorElement.selectionEnd
    );
    const boldRegex = /^\*(.*?)\*$/;
    const strikeRegex = /^~(.*)~$/;
    const italicRegex = /^_(.*)_$/;

    if (style == "bold") {
      if (!boldRegex.test(selectedText)) {
        this.selectedStyle = "Bold";
        const newValue = `${editorElement.value.substring(
          0,
          editorElement.selectionStart
        )}*${selectedText}*${editorElement.value.substring(
          editorElement.selectionEnd
        )}`;
        // editorElement.value = newValue;
        this.newMessage = newValue;
      } else {
        this.selectedStyle = "";
        const newValue = `${editorElement.value.substring(
          0,
          editorElement.selectionStart
        )}${selectedText.substring(
          1,
          selectedText.length - 1
        )}${editorElement.value.substring(editorElement.selectionEnd)}`;
        // editorElement.value = newValue;
        this.newMessage = newValue;
        // console.log(this.newMessage);
      }
    }

    if (style == "italic") {
      if (!italicRegex.test(selectedText)) {
        this.selectedStyle = "Italic";
        const newValue = `${editorElement.value.substring(
          0,
          editorElement.selectionStart
        )}_${selectedText}_${editorElement.value.substring(
          editorElement.selectionEnd
        )}`;
        // editorElement.value = newValue;
        this.newMessage = newValue;
      } else {
        this.selectedStyle = "";
        const newValue = `${editorElement.value.substring(
          0,
          editorElement.selectionStart
        )}${selectedText.substring(
          1,
          selectedText.length - 1
        )}${editorElement.value.substring(editorElement.selectionEnd)}`;
        // editorElement.value = newValue;
        this.newMessage = newValue;
      }
    }

    if (style == "strikethrough") {
      if (!strikeRegex.test(selectedText)) {
        this.selectedStyle = "Strike";
        const newValue = `${editorElement.value.substring(
          0,
          editorElement.selectionStart
        )}~${selectedText}~${editorElement.value.substring(
          editorElement.selectionEnd
        )}`;
        // editorElement.value = newValue;
        this.newMessage = newValue;
      } else {
        this.selectedStyle = "";
        const newValue = `${editorElement.value.substring(
          0,
          editorElement.selectionStart
        )}${selectedText.substring(
          1,
          selectedText.length - 1
        )}${editorElement.value.substring(editorElement.selectionEnd)}`;
        // editorElement.value = newValue;
        this.newMessage = newValue;
        // console.log(this.newMessage);
      }
    }

    // editorElement.focus();

    // const startPos = editorElement.selectionStart + 1;
    // const endPos = startPos + selectedText.length;

    // editorElement.setSelectionRange(startPos, endPos);
  }

  onTextSelect() {
    this.selectedStyle = "";
    const editorElement = this.bodyText.nativeElement;
    const selectedText = editorElement.value.substring(
      editorElement.selectionStart,
      editorElement.selectionEnd
    );

    const boldRegex = /^\*(.*?)\*$/;
    const strikeRegex = /^~(.*)~$/;
    const italicRegex = /^_(.*)_$/;

    if (boldRegex.test(selectedText)) {
      this.selectedStyle = "Bold";
    }

    if (strikeRegex.test(selectedText)) {
      this.selectedStyle = "Strike";
    }

    if (italicRegex.test(selectedText)) {
      this.selectedStyle = "Italic";
    }
  }

  mouseUp() {
    this.selectedStyle = "";
  }

  getTypeOf(variable: any) {
    const type = Object.prototype.toString.call(variable).slice(8, -1);
    return type;
  }

  onPaste(event: any) {
    const clipboardData = event.clipboardData;
    if (clipboardData) {
      const pastedText = clipboardData.getData("text");
      const formattedText = this.reverseFormatText(pastedText);
      // console.log("Formatted text:", formattedText);

      // Modify the textarea value to display the formatted text
      const textarea = event.target as HTMLTextAreaElement;
      const currentText = textarea.value;
      const selectionStart = textarea.selectionStart;
      const selectionEnd = textarea.selectionEnd;
      const newText =
        currentText.slice(0, selectionStart) +
        formattedText +
        currentText.slice(selectionEnd);
      // textarea.value = newText;
      this.newMessage = newText;

      // Prevent the default paste behavior
      event.preventDefault();
    }
  }

  scrollFn(type: string) {
    if (this.mainUserData?.accessTo?.isExpired) {
      this.alertService.warning();
    } else {
      if (window.innerWidth > 992) {
        this.showTags.emit(type);
      } else if (type == "customer_details") {
        this.router.navigate([
          "/chat/details",
          this.selectedUser?._id,
          this.selectedUser?.name,
        ]);
      }
    }
  }

  makeChatRead() {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    var data: any = {
      customerId: this.selectedUser?._id,
    };

    this.apiService
      .commonPostMethod(
        environment.url + "chat/get-customer-chats?page=1",
        data,
        options
      )
      .subscribe(
        (response: any) => {
          // console.log(response);
        },
        (error: any) => {
          console.log(error);
        }
      );
  }

  showMediaModal() {
    this.mediaModal = true;
  }

  closeMediaModal() {
    this.mediaModal = false;
  }

  selectedData(event: any[]) {
    console.log(event);
    if (event.length > 0) {
      this.displayMedia = event;
      this.mediaModal = false;
      this.previewModal = true;
      this.previewFile = this.displayMedia[0];
      this.mediaSelected = event.map((data) => ({
        id: data.id,
        type: data.type,
      }));
    }
  }

  showDocPreview(url: any, localImage: any) {
    // console.log(localImage);
    if (!localImage) {
      this.docSpinner = true;
      this.spinner.show();
      this.docUrl = url;
      this.docPreview = true;
      setTimeout(() => {
        this.spinner.hide();
        this.docSpinner = false;
      }, 2000);
      // console.log(url);
    }
  }

  closeDocPreview() {
    this.docPreview = false;
  }

  downloadLink(event: any) {
    event.stopPropagation();
  }

  // record audio section
  isRecording = false;
  recordedTime;
  blobUrl;
  recAudio;

  startRecording() {
    // this.opusService.startRecording();
    if (!this.isRecording) {
      this.isRecording = true;
      this.audioRecordingService.startRecording();
    }
  }

  stopRecording() {
    // this.opusService.stopRecording();
    if (this.isRecording) {
      this.audioRecordingService.stopRecording();
      // this.blobUrl = {};
      setTimeout(() => {
        this.isRecording = false;
      }, 500);
    }
    this.recordFile = this.audioRecordingService
      .getRecordedBlob()
      .subscribe((data: any) => {
        console.log(data);
        // this.recAudio = data;
        this.mediaSelected = [data];
        let temp: any = {};
        temp.type = data?.type;
        temp.name = data?.name;
        let file = data;
        let reader = new FileReader();
        reader.onload = (e) => (
          (this.blobUrl = reader.result), (temp.url = reader.result)
        );
        reader.readAsDataURL(file);
        temp.url = this.blobUrl;
        // this.blobUrl = this.sanitizer.bypassSecurityTrustUrl(
        //   URL.createObjectURL(data.blob)
        // );
        this.displayMedia = [temp];
        console.log(temp);
        // this.blobUrl = this.sanitizer.bypassSecurityTrustUrl(
        //   URL.createObjectURL(data.blob)
        // );
      });
    // console.log(this.recAudio);
  }

  clearRecordedData() {
    this.blobUrl = null;
  }

  showKanbanBoard() {
    this.enableBoardView.emit();
  }

  getRepliedChat(message: any) {
    // console.log(message);
    this.pageIndex = 1;
    this.sampleIndex = 0;
    this.searchChat = message;
    this.initialPage = true;
    this.searchEnabled = true;
    this.selectedId = message?._id;
    this.getMessages(1);
  }

  ngOnDestroy(): void {
    // console.log("api key destroy..");
    this.userDataSubscription.unsubscribe();
  }
}
