import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import * as moment from "moment";
import { NzModalService } from "ng-zorro-antd/modal";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { CommonApiService } from "src/app/services/common-api.service";
import { NavService } from "src/app/services/nav.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-orders-page",
  templateUrl: "./orders-page.component.html",
  styleUrls: ["./orders-page.component.css"],
})
export class OrdersPageComponent implements OnInit {
  // dummy = "";
  moment: any = moment;
  orderColumn = [
    // {
    //   title: "S.No",
    //   width: "50px",
    // },
    {
      title: "Customer Name",
      width: "100px",
    },
    {
      title: "Phone Number",
      width: "100px",
    },
    // {
    //   title: "Cart Date",
    //   width: "80px",
    // },
    {
      title: "Order ID",
      width: "80px",
    },
    {
      title: "Order Date",
      width: "130px",
    },
    {
      title: "Order Details",
      width: "130px",
    },
    {
      title: "Order Status",
      width: "120px",
    },
    {
      title: "Payment Status",
      width: "120px",
    },
    {
      title: "Payment Mode",
      width: "100px",
    },
    {
      title: "Shipping Address",
      width: "150px",
    },
    {
      title: "Delivery Type",
      width: "80px",
    },
  ];

  liveUrl = environment.imageUrl;

  filterArr: any = {
    dateFilter: [
      { id: "today", label: "Today" },
      { id: "last-week", label: "Last Week" },
      { id: "last-month", label: "Last Month" },
      { id: "last-year", label: "Last Year" },
      { id: "custom-date", label: "Custom Date" },
    ],
    orderStatus: [
      // { id: "all", label: "All Orders" },
      { id: "order placed", label: "Order Placed" },
      { id: "order shipped", label: "Order Shipped" },
      { id: "delivered", label: "Delivered" },
    ],
    paymentStatus: [
      // { id: "all", label: "All Payments" },
      { id: "paid", label: "Paid" },
      { id: "unpaid", label: "Unpaid" },
    ],
  };

  customDate: any;
  customerList: any = [];

  selectedFilter = "today";
  paymentFilter = "all";
  orderFilter = "all";

  orderList: any = [];

  orderData: any;

  selectedCustomer = "all";

  selectDateError: boolean;

  pageNumber = 1;
  pageSize: number;
  totalCount: number;

  productsModal = false;
  viewItems: any = {};

  mainUserData: any = {};
  tableHeight: any = "";

  chatDrawer: boolean = false;

  private subscription: Subscription;

  constructor(
    private apiService: CommonApiService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private modal: NzModalService,
    private navService: NavService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((data) => {
      // console.log(data);
      if (data["id"]) {
        this.selectedCustomer = data["id"];
      }
    });
    this.fetchData();
    // this.getCustomerNameList();
    this.getMainUserData();
  }

  getMainUserData() {
    this.subscription = this.navService.mainUserData.subscribe(
      (response: any) => {
        if (response) {
          // console.log(response);
          this.mainUserData = response.data.userData;
          if (
            response?.data?.userData?.accessTo?.sandboxId &&
            window.innerWidth > 992
          ) {
            this.tableHeight = "45vh";
          } else {
            this.tableHeight = "50vh";
          }
        }
      }
    );
  }

  fetchData() {
    this.spinner.show();
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    let data: any = {
      filter: this.selectedFilter,
      customerId: this.selectedCustomer,
      orderStatus: this.orderFilter,
      paymentStatus: this.paymentFilter,
    };

    if (this.selectedFilter == "custom-date") {
      data.fromDate = moment(this.customDate[0]).format();
      data.toDate = moment(this.customDate[1]).format();
    }

    this.apiService
      .commonPostMethod(
        environment.url + "orders?page=" + this.pageNumber,
        data,
        options
      )
      .subscribe(
        (response: any) => {
          this.orderData = response.data;
          this.pageSize = response?.data.pagination?.limit;
          this.totalCount = response?.data?.pagination?.totalCount;
          console.log(this.orderData);
          this.spinner.hide();
        },
        (err: any) => {
          console.log(err);
          this.spinner.hide();
        }
      );
  }

  getCustomerNameList() {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonGetMethod(environment.url + "customers/get-names-only", options)
      .subscribe(
        (response: any) => {
          // console.log(response);
          this.customerList = response?.data?.customers;
        },
        (err: any) => {
          console.log(err);
        }
      );
  }

  filterChange(type: any) {
    this.pageNumber = 1;
    if (this.selectedFilter != "custom-date") {
      this.fetchData();
    } else if (this.selectedFilter == "custom-date" && type == "date") {
      this.customDate = "";
    } else if (
      this.selectedFilter == "custom-date" &&
      type != "date" &&
      !this.customDate
    ) {
      // this.selectDateError = true;
      this.toastr.error("Please select date!..", "", {
        timeOut: 2500,
      });
    } else if (
      this.selectedFilter == "custom-date" &&
      type != "date" &&
      this.customDate
    ) {
      this.fetchData();
    }
  }

  onDateChange(event: any) {
    // console.log(this.customDate);
    this.fetchData();
  }

  tempOrderData: any;

  statusUpdate(orderData: any, key: string) {
    this.tempOrderData = {};
    // console.log(orderData);
    this.tempOrderData = orderData;
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    var data = {
      orderId: orderData._id,
      orderStatus: orderData.shipping.tracking.orderStatus,
      paymentStatus: orderData.shipping.tracking.paymentStatus,
    };

    this.apiService
      .commonPostMethod(
        environment.url + "orders/update-status" + key,
        data,
        options
      )
      .subscribe(
        (response: any) => {
          // console.log(response);
          if (response.message == "conversation expired") {
            // this.alertModal();
            this.orderAlertModal = true;
          } else {
            this.orderAlertModal = false;
            this.toastr.success("Order status updated!..", "", {
              timeOut: 2500,
            });
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  // alertModal() {
  //   this.modal.confirm({
  //     // nzTitle: '<i>Do you Want to delete these items?</i>',
  //     nzOkText: "sent message",
  //     nzCancelText: "update status",
  //     nzContent:
  //       "Your conversation with this ordered customer has expired do you want to sent message click send message, it consume one conversation charge or just update status click Update Status.",
  //     nzOnOk: () => {
  //       this.statusUpdate(this.tempOrderData, "?sendMessage=true");
  //     },
  //     nzOnCancel: () => {
  //       this.statusUpdate(this.tempOrderData, "?sendMessage=false");
  //     },
  //   });
  // }

  // product modal
  productDetailModal(productData: any) {
    this.viewItems = productData;
    this.productsModal = true;
    // console.log(this.viewItems);
  }

  closeProductDetailModal() {
    this.productsModal = false;
  }

  // TABLE SCROLL TOP ON PAGE CHANGE

  onPageNumberChange(id: any, event: any) {
    if (this.pageNumber != event.pageIndex) {
      this.pageNumber = event.pageIndex;
      this.fetchData();
      document.getElementById(id).scrollIntoView();
      // setTimeout(() => {
      window.scroll(0, 0);
      // }, 300);
    }
  }

  // order status update modal

  orderAlertModal: boolean = false;

  closeOrderAlertModal() {
    this.orderAlertModal = false;
  }

  chatData: any;

  openChatDrawer(chatData: any) {
    console.log(chatData);
    this.chatData = chatData.customerId;
    this.chatDrawer = true;
  }

  closeChatDrawer() {
    this.chatDrawer = false;
  }

  ngOnDestroy(): void {
    // console.log("api key destroy..");
    this.subscription.unsubscribe();
  }
}
