import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { CommonApiService } from "src/app/services/common-api.service";
import { environment } from "src/environments/environment";
import * as moment from "moment";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { NavService } from "src/app/services/nav.service";
import { differenceInCalendarDays } from "date-fns";
import { timeZone } from "../chat/shared/messageData";
import { Subscription } from "rxjs";
import { NzModalService } from "ng-zorro-antd/modal";
@Component({
  selector: "app-create-campaign",
  templateUrl: "./create-campaign.component.html",
  styleUrls: ["./create-campaign.component.css"],
})
export class CreateCampaignComponent implements OnInit {
  moment: any = moment;
  scheduleType = "now";
  campaignName = "";
  orderColumn = [
    // {
    //   title: "S.No",
    //   width: "30px",
    // },
    {
      title: "Name",
      width: "100px",
    },
    {
      title: "Contact No",
      width: "100px",
    },
    {
      title: "Created On",
      width: "100px",
    },
  ];
  modalWidth: any;

  userList: any = [];
  checked = false;
  selectedAudience = [];
  templateModal = false;
  templateData: any = [];
  messageTemplateSelected: any;
  variableData: any = [];
  variableValue: any = [];
  scheduleDate = moment(new Date()).format();
  scheduleTime: any = new Date();
  combinedDateTime: any;
  warningModal = false;
  errorMessages = [];
  templateMediaPreview: any;
  templateLoading = false;
  imageData: any;
  campaignCreating = false;

  segmentSelected: any = [];
  segmentsList: any;

  customerFilter: boolean;
  templateSpinner: boolean;

  // variable data
  fieldData: any = [];

  docTypes = [
    // "text/plain",
    "application/pdf",
    "application/vnd.ms-powerpoint",
    "application/msword",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];

  audioTypes = [
    "audio/aac",
    "audio/mp4",
    // 'audio/mpeg',
    "audio/amr",
    "audio/ogg",
  ];
  videoTypes = ["video/mp4", "video/3gp"];
  imageTypes = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    // "image/webp",
    // "image/svg+xml",
  ];

  campaignId: any = "";
  campaignData: any = {};
  duplicateCampaign = false;

  reqArray: any = [];

  previewSpinner: boolean = false;

  templatePage = 1;
  syncing: boolean = false;

  // audience pagination
  pageIndex = 1;
  totalCount: number;
  pageSize: number = 30;

  segmentColumn: any = [
    {
      title: "Name",
      width: "100px",
    },
    {
      title: "No.of Contacts",
      width: "100px",
    },
  ];

  // new customers modal

  contactsListModal: boolean = false;

  tableHeight: any = "";
  mainUserData: any = {};

  today = new Date();

  // media url variables
  mediaSource: string = "file";
  mediaUrlField: any;
  mediaUrlFallback: any;
  manualFilename: string = "";
  filenameUsageType: any = "default";
  validMedia: boolean = false;

  // new Campaign design

  campaignType: any = "";
  contacts: any = "";

  getVariableValue: boolean = false;
  acceptedTypes: any = [];

  sendingTemplateMedia = false;

  audienceType: any = "";
  audienceModal: boolean = false;
  searchKey: any = "";

  modalSpinner: boolean = false;
  templatePreview: boolean = false;
  previewModalWidth: string = "50%";
  timeZone: any = timeZone;

  // customer filter section
  selectedTab: any = { name: "All Contacts", _id: "All Contacts" };
  appliedFiltersSample: any = [];
  allCustomerCount: any;
  contactsFilter: any = "";

  fieldsForFilter: any;
  filterSuggestion: any;
  filterReq: any = [];
  appliedPredefined: any = [];
  appliedFilters: any = [];
  filterApplied: boolean = false;

  errorObj: any = {
    filterType: [],
    values: [],
  };

  searchTimeout: any;
  searchText: any;

  liveSegment: boolean = true;
  segmentInfo: string =
    "Campaign send to all live contacts in segments at the scheduled time";

  range(start: number, end: number): number[] {
    const result: number[] = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }

  disabledDate = (current: Date): boolean =>
    // Can not select days before today and today
    differenceInCalendarDays(this.today, current) > 0;

  private subscription: Subscription;

  constructor(
    private apiService: CommonApiService,
    private toastr: ToastrService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private navService: NavService,
    private cdref: ChangeDetectorRef,
    private modal: NzModalService
  ) {}

  @ViewChild("timePicker") timePickerRef: ElementRef;

  ngOnInit(): void {
    if (window.innerWidth > 1000) {
      this.modalWidth = "95%";
      this.previewModalWidth = "35%";
    } else {
      this.modalWidth = "95%";
      this.previewModalWidth = "95%";
    }

    this.getMainUserData();
    this.getSegments();
    this.templatePage = 1;
    // this.getTemplates("all");
    this.getFieldData();
    this.getFieldsForFilter();

    this.route.params.subscribe((data) => {
      // console.log(data);
      // this.duplicateCampaignId = data["id"];
      if (data["id"]) {
        this.campaignType = "simpleBroadcast";
        if (data["id"] != "simple-broadcast") {
          this.campaignId = data["id"];
          this.getCampaignDetails();
        }
      } else {
        this.campaignId = "";
      }
    });
  }

  ngAfterViewChecked() {
    this.cdref.detectChanges();
  }

  fetchData() {
    this.customerFilter = true;
    this.spinner.show();
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    var request: any = { filterValue: this.searchKey };

    if (this.appliedPredefined.length > 0) {
      request.predefined = this.appliedPredefined;
    }

    this.apiService
      .commonPostMethod(
        environment.url +
          "customers?page=" +
          this.pageIndex +
          "&limit=" +
          this.pageSize,
        request,
        options
      )
      .subscribe(
        (response: any) => {
          this.customerFilter = false;
          this.spinner.hide();
          // console.log(response);
          this.userList = response?.data;
          this.totalCount = response?.data?.pagination?.totalCount;
          this.pageSize = response?.data?.pagination?.limit;
          if (!this.searchKey) {
            this.allCustomerCount = response?.data?.pagination?.totalCount;
          }
          // console.log(this.userList);
        },
        (err: any) => {
          if (
            err?.error?.message == "jwt expired" ||
            err?.error?.message == "jwt must be provided" ||
            err?.error?.message == "jwt malformed" ||
            err?.error?.message == "invalid signature" ||
            err?.error?.message == "Given token user does not exist." ||
            err?.error?.message == "invalid token"
          ) {
            this.navService.tokenExpired();
          }
          console.log(err);
        }
      );
  }

  getSegments() {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonGetMethod(environment.url + "segments", options)
      .subscribe(
        (response: any) => {
          // console.log(response);
          this.segmentsList = response.data.segments;
        },
        (error: any) => {
          console.log(error);
        }
      );
  }

  // SELECT ALL TABLE ROW

  onAllChecked(event: any) {
    // console.log(event);
    if (event) {
      for (let i = 0; i < this.userList?.customers.length; i++) {
        this.selectedAudience.push(this.userList?.customers[i]._id);
      }
    } else {
      this.selectedAudience = [];
    }
    // console.log(this.selectedAudience);
  }

  onAllCheckedSegment(event: any) {
    if (event) {
      for (let i = 0; i < this.segmentsList.length; i++) {
        this.segmentSelected.push(this.segmentsList[i]._id);
      }
    } else {
      this.segmentSelected = [];
    }
  }

  // SELECT SINGLE ROW

  onItemChecked(item) {
    // console.log(this.selectedAudience.indexOf(item._id));
    if (this.selectedAudience.indexOf(item._id) == -1) {
      this.selectedAudience.push(item._id);
    } else {
      this.selectedAudience.splice(this.selectedAudience.indexOf(item._id), 1);
    }
    // console.log(this.selectedAudience);
  }

  onItemCheckedSegment(item: any) {
    if (this.segmentSelected.indexOf(item._id) == -1) {
      this.segmentSelected.push(item._id);
    } else {
      this.segmentSelected.splice(this.segmentSelected.indexOf(item._id), 1);
    }
  }

  // GET ALL MESSAGE TEMPLATES

  getTemplates(key: any) {
    // this.spinner.show();
    this.variableData = [];
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };
    var body = {
      filterByName: this.searchText,
      filterByStatus: "approved",
    };

    this.apiService
      .commonPostMethod(
        // environment.url + "templates?next=" + this.nextKey, // old api url
        environment.url + "templates/get-all?page=" + this.templatePage, // new api url
        body,
        options
      )
      .subscribe(
        (response: any) => {
          // console.log(response);
          for (let i = 0; i < response?.data?.waMsgTemplates?.length; i++) {
            for (
              let j = 0;
              j < response?.data?.waMsgTemplates[i].components.length;
              j++
            ) {
              if (response?.data?.waMsgTemplates[i].components[j].text) {
                response.data.waMsgTemplates[i].components[j].text =
                  this.format_text(
                    response?.data?.waMsgTemplates[i].components[j]?.text
                  );
              }
            }
          }
          if (key == "all") {
            this.templateData = response?.data?.waMsgTemplates;
            this.syncing = false;
          } else if (key == "new") {
            this.templateData = this.templateData.concat(
              response?.data?.waMsgTemplates
            );
          }
          this.templateSpinner = false;
          // console.log(response.data);
          this.spinner.hide();
          this.modalSpinner = false;
        },
        (error: any) => {
          this.spinner.hide();
          this.modalSpinner = false;
          console.log(error);
        }
      );
  }

  // SELECT MESSAGE TEMPLATE MODAL

  showTemplateModal() {
    // console.log(this.variableData);
    this.spinner.show();
    this.modalSpinner = true;
    if (
      this.variableData?.mediaData?.hasMedia ||
      this.variableData?.variableTemplate?.length > 0
    ) {
      this.selectTemplate();
    } else {
      this.templatePage = 1;
      this.getTemplates("all");
    }
    this.templateModal = true;
  }

  closeTemplateModal() {
    this.templateModal = false;
  }

  showPreview(selected: any) {
    this.variableData = [];
    if (this.messageTemplateSelected?._id == selected._id) {
      this.messageTemplateSelected = null;
    } else {
      this.messageTemplateSelected = selected;
    }
  }

  selectTemplate() {
    this.templateLoading = true;
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };
    var data = { messageId: this.messageTemplateSelected._id };
    this.apiService
      .commonPostMethod(
        environment.url + "templates/get-variables-template-data",
        data,
        options
      )
      .subscribe(
        (response: any) => {
          // console.log(this.variableData);
          if (!this.variableData || this.variableData?.length == 0) {
            this.variableData = response.data;
            // console.log(response);
            if (this?.variableData?.mediaData?.type == "IMAGE") {
              this.acceptedTypes = this.imageTypes;
            } else if (this?.variableData?.mediaData?.type == "VIDEO") {
              this.acceptedTypes = this.videoTypes;
            } else if (this?.variableData?.mediaData?.type == "DOCUMENT") {
              this.acceptedTypes = this.docTypes;
            }

            if (
              this.variableData?.variableTemplate?.length > 0 ||
              this.variableData?.mediaData?.hasMedia
            ) {
              this.templateMediaPreview = null;
              this.sendingTemplateMedia = false;
              this.getVariableValue = true;
            } else {
              this.templateModal = false;
            }
          }

          this.templateLoading = false;
          this.spinner.hide();
          this.modalSpinner = false;
        },
        (error) => {
          console.log(error);
          this.templateLoading = false;
          this.spinner.hide();
          this.modalSpinner = false;
        }
      );
  }

  // CREATE CAMPAIGN SECTION

  createCampaign() {
    this.errorMessages = [];
    var temp: any = {};

    // console.log(this.templateMediaPreview);

    var errorArr: any = [];

    if (
      this.variableData?.mediaData?.hasMedia &&
      !this.templateMediaPreview &&
      this.mediaSource == "file"
    ) {
      errorArr.push("Please select an image for Message");
    }

    if (!this.campaignName) {
      errorArr.push("Please Enter Campaign Name");
    }

    if (!this.audienceType) {
      errorArr.push("Please Select Audience");
    }

    if (this.segmentSelected.length == 0 && this.audienceType == "segment") {
      errorArr.push("Please Select Audience");
    }

    if (
      this.selectedAudience.length == 0 &&
      this.audienceType == "direct-customers"
    ) {
      errorArr.push("Please Select Audience");
    }

    if (!this.messageTemplateSelected?._id) {
      errorArr.push("Please Select Message Template");
    }

    if (this.variableData?.mediaData?.type == "DOCUMENT") {
      if (this.filenameUsageType == "manual" && !this.manualFilename) {
        errorArr.push("Please Fill Required Variable data!..");
      }
    }

    if (this.mediaSource == "url" && this.variableData?.mediaData?.hasMedia) {
      if (!this.mediaUrlField || !this.mediaUrlFallback) {
        errorArr.push("Please Fill Required Variable data!..");
      }
      if (!this.validMedia) {
        errorArr[0] = "Please enter valid file url";
      }
    }
    for (let i = 0; i < this.variableData?.variableTemplate?.length; i++) {
      var stopLoop = false;
      for (
        let j = 0;
        j < this.variableData?.variableTemplate[i]?.variables?.length;
        j++
      ) {
        if (
          this.variableData.variableTemplate[i].variables[j].fallbackValue ==
            "" ||
          this.variableData.variableTemplate[i].variables[j].field == ""
        ) {
          errorArr.push("Please Fill Required Variable data");
          stopLoop = true;
          break;
        }
      }
      if (stopLoop) {
        break;
      }
    }

    console.log(errorArr);
    if (errorArr.length > 0) {
      // this.templateModal = true;
      this.warningModal = true;
      this.errorMessages = errorArr;
    } else {
      temp.name = this.campaignName;
      if (this.audienceType == "direct-customers") {
        temp.customerIds = this.selectedAudience;
      } else {
        temp.segmentIds = this.segmentSelected;
      }
      temp.filterType = this.audienceType;
      temp.templateId = this.messageTemplateSelected._id;
      temp.publishMode = this.scheduleType;
      temp.templateVariableData = this.variableData.variableTemplate;
      if (this.scheduleType == "schedule") {
        temp.scheduleAt = moment(this.scheduleTime).second(0).format();
        // console.log(temp.scheduleAt);
      }
      // console.log(temp);
      this.newCampaign(temp);
    }
  }

  newCampaign(temp: any) {
    this.spinner.show();
    this.campaignCreating = true;
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(environment.url + "campaigns/create", temp, options)
      .subscribe(
        (response: any) => {
          // console.log(response);
          if (response.status == "success") {
            if (this.variableData?.mediaData?.hasMedia) {
              this.uploadFile(response.data.campaignId);
            } else {
              this.toastr.success(response.message, "", {
                timeOut: 2500,
              });
              this.campaignCreating = false;
              this.spinner.hide();
              this.router.navigate(["/campaign"]);
            }
          }
        },
        (err) => {
          console.log(err);
          this.campaignCreating = false;
          this.spinner.hide();
          if (err?.error?.message) {
            this.toastr.error(err?.error?.message, "", {
              timeOut: 2500,
            });
          } else {
            this.toastr.error(err?.message, "", {
              timeOut: 2500,
            });
          }
        }
      );
  }

  closeWarningModal() {
    this.warningModal = false;
  }

  // MEDIA UPLOAD

  getFile(event: any) {
    // if (this.variableData?.mediaData?.type == "VIDEO") {
    // if (this.videoTypes.indexOf(event.target?.files[0]?.type) != -1) {
    this.imageData = event.target.files[0];
    // console.log(this.imageData);
    const file = event.target.files[0];

    const reader = new FileReader();
    reader.onload = (e) => (this.templateMediaPreview = reader.result);
    reader.readAsDataURL(file);
    // }
    // else {
    //   this.toastr.error("Please Select Video file", "", {
    //     timeOut: 2500,
    //   });
    // }
    // } else if (this.variableData?.mediaData?.type == "IMAGE") {
    //   if (this.imageTypes.indexOf(event.target?.files[0]?.type) != -1) {
    //     this.imageData = event.target.files[0];
    //     // console.log(this.imageData);
    //     const file = event.target.files[0];

    //     const reader = new FileReader();
    //     reader.onload = (e) => (this.templateMediaPreview = reader.result);
    //     reader.readAsDataURL(file);
    //   } else {
    //     this.toastr.error("Please Select Image file", "", {
    //       timeOut: 2500,
    //     });
    //   }
    // } else if (this.variableData?.mediaData?.type == "DOCUMENT") {
    //   if (this.docTypes.indexOf(event.target?.files[0]?.type) != -1) {
    //     this.imageData = event.target.files[0];
    //     // console.log(this.imageData);
    //     const file = event.target.files[0];

    //     const reader = new FileReader();
    //     reader.onload = (e) => (this.templateMediaPreview = reader.result);
    //     reader.readAsDataURL(file);
    //   } else {
    //     this.toastr.error("Please Select Document file", "", {
    //       timeOut: 2500,
    //     });
    //   }
    // }
    // this.imageData = event.target.files[0];
    // console.log(this.imageData);
    // const file = event.target.files[0];

    // const reader = new FileReader();
    // reader.onload = (e) => (this.templateMediaPreview = reader.result);
    // reader.readAsDataURL(file);
  }

  uploadFile(campaignId: any) {
    // console.log(this.imageData);
    var formData = new FormData();

    // if (this.imageTypes.indexOf(this.imageData?.type) != -1) {
    //   formData.append("mediaType", "IMAGE");
    // } else if (this.videoTypes.indexOf(this.imageData?.type) != -1) {
    //   formData.append("mediaType", "VIDEO");
    // } else if (this.docTypes.indexOf(this.imageData?.type) != -1) {
    //   formData.append("mediaType", "DOCUMENT");
    // }

    formData.append(
      "mediaType",
      (this.variableData?.mediaData?.type).toLowerCase()
    );
    formData.append("mediaSource", this.mediaSource);

    if (this.variableData?.mediaData?.type == "DOCUMENT") {
      formData.append("filenameUsageType", this.filenameUsageType);
      if (this.filenameUsageType == "manual") {
        formData.append("manualFilename", this.manualFilename);
      }
    }

    if (this.mediaSource == "url") {
      formData.append("urlField", this.mediaUrlField);
      formData.append("fallbackUrl", this.mediaUrlFallback);
      if (this.videoTypes.indexOf(this.imageData?.type) != -1) {
        formData.append("filenameUsageType", this.filenameUsageType);
        if (this.filenameUsageType == "manual") {
          formData.append("manualFilename", this.manualFilename);
        }
      }
    } else {
      formData.append("media", this.imageData);
    }

    formData.append("campaignId", campaignId);

    let options = {
      headers: {
        // "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url + "campaigns/update-media",
        formData,
        options
      )
      .subscribe(
        (response) => {
          // console.log(response);
          this.campaignCreating = false;
          this.toastr.success("Campaign created Successfully!..", "", {
            timeOut: 2500,
          });
          this.router.navigate(["/campaign"]);
        },
        (err) => {
          console.log(err);
          this.campaignCreating = false;
          if (err?.error?.message) {
            this.toastr.error(err?.error?.message, "", {
              timeOut: 2500,
            });
          } else {
            this.toastr.error(err?.message, "", {
              timeOut: 2500,
            });
          }
        }
      );
  }

  getCampaignDetails() {
    this.duplicateCampaign = true;
    this.spinner.show();
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };
    var data = {
      campaignId: this.campaignId,
    };

    this.apiService
      .commonPostMethod(environment.url + "campaigns/detail", data, options)
      .subscribe(
        (response: any) => {
          for (
            let j = 0;
            j < response.data?.detail?.messageComponents?.length;
            j++
          ) {
            if (response.data.detail.messageComponents[j].text) {
              response.data.detail.messageComponents[j].text = this.format_text(
                response.data.detail.messageComponents[j]?.text
              );
            }
          }
          this.campaignData = response.data.detail;
          // console.log(this.campaignData);
          this.campaignName = this.campaignData.name;
          this.messageTemplateSelected = this.campaignData.templateData;
          this.messageTemplateSelected.components =
            this.campaignData.messageComponents;
          this.messageTemplateSelected._id = this.campaignData.templateData.id;
          this.variableData.variableTemplate = this.campaignData.variableData;
          this.audienceType = this.campaignData.filterType;
          if (this.audienceType == "segment") {
            this.segmentSelected = this.campaignData?.segmentData;
            this.getCustomerBySegment();
          } else if (this.audienceType == "direct-customers") {
            this.selectedAudience = this.campaignData.data.map(
              (item: any) => item.customer._id
            );
          }

          if (this.campaignData.templateData.mediaData) {
            this.variableData.mediaData =
              this.campaignData.templateData.mediaData;

            this.variableData.mediaData.type =
              this.campaignData.messageComponents[0].format;

            // this.templateMediaPreview = environment.url + this.campaignData.templateData.mediaData.id.url;
          }

          this.duplicateCampaign = false;
          this.spinner.hide();
        },
        (err) => {
          console.log(err);
          this.duplicateCampaign = false;
          this.spinner.hide();
        }
      );
  }

  format_text(text) {
    return text
      .replace(/(?:\*)(?:(?!\s))((?:(?!\*|\n).)+)(?:\*)/g, "<b>$1</b>")
      .replace(/(?:_)(?:(?!\s))((?:(?!\n|_).)+)(?:_)/g, "<em>$1</em>")
      .replace(/(?:~)(?:(?!\s))((?:(?!\n|~).)+)(?:~)/g, "<s>$1</s>")
      .replace(/(?:--)(?:(?!\s))((?:(?!\n|--).)+)(?:--)/g, "<u>$1</u>")
      .replace(/(?:```)(?:(?!\s))((?:(?!\n|```).)+)(?:```)/g, "<tt>$1</tt>");
  }

  // TABLE SCROLL TOP ON PAGE CHANGE

  onPageNumberChange(id: any, event: any) {
    if (this.pageIndex != event.pageIndex) {
      this.pageIndex = event.pageIndex;
      if (this.audienceType == "direct-customers") {
        if (this.selectedTab._id != "All Contacts") {
          this.segmentSelected = [this.selectedTab._id];
          this.getCustomerBySegment();
        } else if (this.selectedTab._id == "All Contacts") {
          this.fetchData();
        }
      } else {
        this.getCustomerBySegment();
      }

      document.getElementById(id).scrollIntoView();
    }
  }

  // template pagination

  bottomScrolled() {
    this.templatePage++;
    this.templateSpinner = true;
    this.getTemplates("new");
  }

  segmentChange() {
    if (this.segmentSelected.length > 0) {
      this.getCustomerBySegment();
    }
  }

  getCustomerBySegment() {
    this.customerFilter = true;
    this.spinner.show();
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url +
          "segments/get-filtered-contacts?page=" +
          this.pageIndex +
          "&limit=" +
          this.pageSize,
        { segmentIds: this.segmentSelected, filterValue: this.searchKey },
        options
      )
      .subscribe(
        (response: any) => {
          // console.log(response);
          this.userList = response.data;
          this.totalCount = response?.data?.pagination?.totalCount;
          this.pageSize = response?.data?.pagination?.limit;
          this.spinner.hide();
          this.customerFilter = false;
          // console.log(this.userList);
        },
        (err: any) => {
          console.log(err);
          if (err.error.message == "Some of the segment does not exists") {
            this.segmentSelected = [];
            this.audienceType = "";
            this.toastr.error(err?.error?.message, "", {
              timeOut: 2500,
            });
          }
          this.customerFilter = false;
          this.spinner.hide();
        }
      );
  }

  syncTemplate() {
    this.syncing = true;
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url + "templates/sync-templates",
        {},
        options
      )
      .subscribe(
        (response: any) => {
          // console.log(response);
          this.templatePage = 1;
          this.getTemplates("all");
          this.toastr.success(response.message, "", {
            timeOut: 2500,
          });
        },
        (err: any) => {
          console.log(err);
          this.syncing = false;
        }
      );
  }

  getFieldData() {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url + "customer-field/get?filter=for-template",
        {},
        options
      )
      .subscribe(
        (response: any) => {
          this.fieldData = response.data.customerFields;
          // console.log(this.fieldData);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  getMainUserData() {
    this.subscription = this.navService.mainUserData.subscribe(
      (response: any) => {
        if (response) {
          // console.log(response);
          this.mainUserData = response.data.userData;
          if (
            response?.data?.userData?.accessTo?.sandboxId &&
            window.innerWidth > 992
          ) {
            this.tableHeight = "50vh";
          } else {
            this.tableHeight = "56vh";
          }
        }
      }
    );
  }

  changeMediaSource(type: string) {
    this.mediaSource = type;
    if (type == "url") {
      this.imageData = "";
      this.templateMediaPreview = "";
      setTimeout(() => {
        document
          .getElementById("media_url_sec")
          .scrollIntoView({ behavior: "smooth" });
      }, 200);
    }
  }

  checkUrl() {
    if (this.mediaSource == "url") {
      var extension = this.mediaUrlFallback.split(".").pop().toLowerCase();

      const imageExtensions = ["jpg", "jpeg", "png", "gif"];
      const videoExtensions = ["mp4", "avi", "mov"];
      const documentExtensions = ["pdf", "doc", "docx"];
      if (this.variableData?.mediaData?.type == "DOCUMENT") {
        if (documentExtensions.includes(extension)) {
          this.validMedia = true;
        } else {
          this.validMedia = false;
        }
      }
      if (this.variableData?.mediaData?.type == "IMAGE") {
        if (imageExtensions.includes(extension)) {
          this.validMedia = true;
        } else {
          this.validMedia = false;
        }
      }
      if (this.variableData?.mediaData?.type == "VIDEO") {
        if (videoExtensions.includes(extension)) {
          this.validMedia = true;
        } else {
          this.validMedia = false;
        }
      }

      // console.log(this.validMedia);
    }
  }

  changeType(type: string) {
    this.campaignType = type;
    if (type == "simpleBroadcast") {
      this.router.navigate(["/campaign/create-campaign/simple-broadcast"]);
    }
  }

  // new campaign design starts

  submitTemplate() {
    if (!this.messageTemplateSelected._id) {
      this.toastr.error("Please Select Template!..", "", {
        timeOut: 2500,
      });
    } else if (
      this.variableData?.mediaData?.hasMedia &&
      !this.templateMediaPreview &&
      this.mediaSource == "file"
    ) {
      this.toastr.error("Please Select Media!..", "", {
        timeOut: 2500,
      });
    } else {
      var tempArr: any = [];
      if (this.variableData?.mediaData?.type == "DOCUMENT") {
        if (this.filenameUsageType == "manual" && !this.manualFilename) {
          tempArr.push("Please Fill Required Variable data!..");
        }
      }
      if (this.mediaSource == "url") {
        if (!this.mediaUrlField || !this.mediaUrlFallback) {
          tempArr.push("Please Fill Required Variable data!..");
        }
        if (!this.validMedia) {
          tempArr[0] = "Please enter valid file url";
        }
      }
      for (let i = 0; i < this.variableData?.variableTemplate?.length; i++) {
        var stopLoop = false;
        for (
          let j = 0;
          j < this.variableData?.variableTemplate[i]?.variables?.length;
          j++
        ) {
          if (
            this.variableData.variableTemplate[i].variables[j].fallbackValue ==
              "" ||
            this.variableData.variableTemplate[i].variables[j].field == ""
          ) {
            tempArr.push("Please Fill Required Variable data!..");
            stopLoop = true;
            break;
          }
        }
        if (stopLoop) {
          break;
        }
      }

      if (tempArr.length > 0) {
        this.toastr.error(tempArr[0], "", {
          timeOut: 2500,
        });
      } else {
        this.templateModal = false;
      }
    }
  }

  backToChooseTemplate() {
    this.variableData = null;
    this.getVariableValue = false;
    if (!this.templateData || this.templateData?.length == 0) {
      this.templatePage = 1;
      this.getTemplates("all");
    }
  }

  changeSchedule(type: string) {
    this.scheduleType = type;
    if (type == "now") {
      this.createCampaign();
    }
    // console.log(this.timePickerRef);
    // const timePicker = this.timePickerRef.nativeElement;
    // timePicker.nzOnOpenChange.subscribe((isOpen: boolean) => {
    //   console.log(isOpen);
    //   if (isOpen) {
    //     const timePickerTop = timePicker.getBoundingClientRect().top;
    //     const viewportHeight =
    //       window.innerHeight || document.documentElement.clientHeight;
    //     const scrollOffset = 10; // Adjust the scroll offset as needed
    //     const scrollTo = timePickerTop - viewportHeight / 2 + scrollOffset;
    //     window.scrollTo({ top: scrollTo, behavior: "smooth" });
    //   }
    // });
  }

  selectAudienceType() {
    if (this.audienceType == "direct-customers") {
      // console.log(this.selectedTab);
      if (this.selectedTab._id != "All Contacts") {
        this.segmentSelected = [this.selectedTab._id];
        this.getCustomerBySegment();
      } else if (this.selectedTab._id == "All Contacts") {
        this.fetchData();
      }
      // this.fetchData();
      if (this.mainUserData?.accessTo?.sandboxId && window.innerWidth > 992) {
        this.tableHeight = "52vh";
      } else {
        this.tableHeight = "58vh";
      }
    } else {
      if (this.mainUserData?.accessTo?.sandboxId && window.innerWidth > 992) {
        this.tableHeight = "47vh";
      } else {
        this.tableHeight = "52vh";
      }
    }
    this.audienceModal = true;
  }

  closeAudienceModal() {
    if (this.segmentSelected.length == 0 && this.selectedAudience.length == 0) {
      this.audienceType = "";
    }
    this.audienceModal = false;
  }

  changePageSize(size: any) {
    this.pageSize = size;
    if (this.audienceType == "direct-customers") {
      if (this.selectedTab._id != "All Contacts") {
        this.segmentSelected = [this.selectedTab._id];
        this.getCustomerBySegment();
      } else if (this.selectedTab._id == "All Contacts") {
        this.fetchData();
      }
    } else {
      this.getCustomerBySegment();
    }
  }

  setMyStyles() {
    let styles = {
      "margin-right":
        135 + (this.totalCount / this.pageSize).toFixed(0).length * 5 + "px",
    };
    return styles;
  }

  searchContacts() {
    this.pageIndex = 1;
    if (this.audienceType == "direct-customers") {
      if (this.selectedTab._id != "All Contacts") {
        this.segmentSelected = [this.selectedTab._id];
        this.getCustomerBySegment();
      } else if (this.selectedTab._id == "All Contacts") {
        this.fetchData();
      }
    }
  }

  resetAudienceAlert() {
    this.modal.confirm({
      nzTitle: "Sure you want to Delete?",
      nzOkText: "Yes",
      nzCancelText: "No",
      nzOnOk: () => this.resetAudience(),
    });
  }

  resetAudience() {
    this.selectedTab = { name: "All Contacts", _id: "All Contacts" };
    this.selectedAudience = [];
    this.segmentSelected = [];
    this.audienceType = "";
  }

  backBtn() {
    if (!this.campaignType || this.campaignId) {
      this.router.navigate(["/campaign"]);
    } else {
      this.campaignType = "";
      this.router.navigate(["/campaign/create-campaign"]);
    }
  }

  viewSelectedTemplate() {
    this.templatePreview = true;
  }

  closeTemplatePreview() {
    this.templatePreview = false;
  }

  combineTime() {
    this.combinedDateTime = moment(this.scheduleDate).set({
      hour: moment(this.scheduleTime).hours(),
      minute: moment(this.scheduleTime).minutes(),
      second: moment(this.scheduleTime).seconds(),
    });

    this.createCampaign();
  }

  // customers filter section

  selectSegment(selected: any) {
    this.filterApplied = false;
    this.selectedTab = selected;
    // console.log(selected);
    this.pageIndex = 1;
    this.searchKey = "";
    if (this.filterApplied) {
      this.applyFilter();
    } else if (selected._id != "All Contacts") {
      this.segmentSelected = [selected._id];
      this.getCustomerBySegment();
    } else if (selected._id == "All Contacts") {
      this.fetchData();
    }
  }

  getFieldsForFilter() {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url + "customer-field/get?filter=for-create-segment",
        {},
        options
      )
      .subscribe(
        (response: any) => {
          // console.log(response);
          this.fieldsForFilter = response?.data?.customerFields;
          this.filterSuggestion = response?.data?.getFilterOperatorData;
          for (let s = 0; s < this.fieldsForFilter.length; s++) {
            var temp = {
              dataType: "",
              filterType: "",
              key: "",
              values: [],
            };
            this.filterReq.push(temp);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  // system defined filters

  applyPredefined(filter: any, event: any) {
    if (event) {
      this.appliedPredefined.push(filter?._id);
    } else {
      this.appliedPredefined.splice(
        this.appliedPredefined.indexOf(filter?._id),
        1
      );
    }

    this.pageIndex = 1;
    if (this.filterApplied) {
      this.applyFilter();
    } else if (this.selectedTab?._id != "All Contacts") {
      this.getCustomerBySegment();
    } else if (this.selectedTab?._id == "All Contacts") {
      this.fetchData();
    }
    this.onAllChecked(false);
    this.checked = false;
  }

  applyFilter() {
    this.filterApplied = true;

    this.errorObj = {
      filterType: [],
      values: [],
    };

    var reqArray: any[] = this.filterReq.filter((obj) => obj.dataType !== "");

    for (let l = 0; l < reqArray.length; l++) {
      // console.log(reqArray[l].values.length);

      if (reqArray[l].filterType === "") {
        this.errorObj.filterType.push(reqArray[l].key);
      } else if (this.errorObj.filterType.indexOf(reqArray[l].key) > -1) {
        this.errorObj.filterType.splice(
          this.errorObj.filterType.indexOf(reqArray[l].key),
          1
        );
      }

      if (
        reqArray[l].values.length == 0 &&
        reqArray[l].filterType != "is empty" &&
        reqArray[l].filterType != "is not empty"
      ) {
        this.errorObj.values.push(reqArray[l].key);
      } else if (this.errorObj.values.indexOf(reqArray[l].key) > -1) {
        this.errorObj.values.splice(
          this.errorObj.values.indexOf(reqArray[l].key),
          1
        );
      }
    }

    if (this.selectedTab?._id != "All Contacts") {
      // console.log(JSON.parse(this.selectedTab.filterData));
      reqArray = reqArray.concat(JSON.parse(this.selectedTab.filterData));
      // console.log(reqArray);
    }

    // console.log(this.errorObj);

    if (
      this.errorObj.filterType.length == 0 &&
      this.errorObj.values.length == 0
    ) {
      this.appliedFiltersSample = JSON.parse(
        JSON.stringify(this.appliedFilters)
      );

      var request: any = {
        filter: {
          and: reqArray,
        },
        checkFilter: true,
        name: "contacts filter",
      };

      if (this.appliedPredefined.length > 0) {
        request.predefined = this.appliedPredefined;
      }

      let options = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
        },
      };

      var url =
        "segments/create?page=" + this.pageIndex + "&limit=" + this.pageSize;

      this.apiService
        .commonPostMethod(environment.url + url, request, options)
        .subscribe(
          (response: any) => {
            // console.log(response);
            this.userList = response.data;
            this.totalCount = response?.data?.totalCount;
            // this.totalRecords = response?.data?.totalCount;
            this.pageSize = response?.data?.limit;
          },
          (error) => {
            console.log(error);
          }
        );
    } else {
      this.toastr.error(
        "Please provide all required information to continue.",
        "",
        {
          timeOut: 2500,
        }
      );
    }
  }

  onFilterChecked(selected: any, index: any, event: any, filterFrom: string) {
    if (event) {
      this.appliedFilters.push(selected?.name);
      this.filterReq[index].dataType = selected?.dataType;
      this.filterReq[index].key = selected?.name;
    } else {
      this.appliedFilters.splice(
        this.appliedFilters.indexOf(selected?.name),
        1
      );

      this.appliedFiltersSample.splice(
        this.appliedFiltersSample.indexOf(selected?.name),
        1
      );

      this.filterReq[index].dataType = "";
      this.filterReq[index].key = "";
      this.filterReq[index].filterType = "";
      this.filterReq[index].values = [];
    }
    // console.log(this.appliedFilters);
    if (filterFrom == "top") {
      this.directApply();
    }
  }

  directApply() {
    this.pageIndex = 1;
    this.applyFilter();
    this.onAllChecked(false);

    // document.getElementById("filterDiv").scrollIntoView();
  }

  clearFilter() {
    this.onAllChecked(false);
    this.filterApplied = false;
    this.appliedFilters = [];
    this.appliedFiltersSample = [];
    this.appliedPredefined = [];
    this.filterReq = [];
    for (let s = 0; s < this.fieldsForFilter.length; s++) {
      var temp = {
        dataType: "",
        filterType: "",
        key: "",
        values: [],
      };
      this.filterReq.push(temp);
    }
    // console.log(this.selectedTab);
    this.pageIndex = 1;
    // this.viewMoreArr = [];
    if (this.selectedTab?._id != "All Contacts") {
      this.getCustomerBySegment();
    } else if (this.selectedTab?._id == "All Contacts") {
      this.fetchData();
    }
  }

  searchTemplates() {
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      this.templatePage = 1;
      this.getTemplates("all");
    }, 500);
  }

  ngOnDestroy(): void {
    // console.log("api key destroy..");
    this.subscription.unsubscribe();
  }

  resetTemplateAlert() {
    this.modal.confirm({
      nzTitle: "Sure you want to Delete?",
      nzOkText: "Yes",
      nzCancelText: "No",
      nzOnOk: () => this.resetTemplate(),
    });
  }

  resetTemplate() {
    this.getVariableValue = false;
    this.messageTemplateSelected = null;
    this.variableData = [];
  }
}
