<form [formGroup]="createCatalogueForm" (ngSubmit)="addCatalogue()" nz-form>
    <div class="row create_row">
        <div class="col-lg-7 left_column ">
            <nz-card class="catalogue_card">

                <nz-form-item [ngClass]="{'extra_mb' : createCatalogueForm.get('name').value.length == 200}">
                    <nz-form-label nzRequired nzFor="product_name" [nzSm]="24" [nzXs]="24">
                        Product Name
                        <!-- <span nz-tooltip nzTooltipTitle="Enter the name of the product here" class="info_icon" nz-icon
                            nzType="info-circle" nzTheme="outline"></span> -->
                    </nz-form-label>
                    <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please Enter Product Name!">
                        <nz-input-group>
                            <input autocomplete="off" formControlName="name" type="text" maxlength="200" nz-input
                                id="product_name" placeholder="Enter Product Name" required>
                            <div class="count">
                                <p *ngIf="createCatalogueForm.get('name').value.length == 200" class="error_message">
                                    Max limit 200 reached
                                </p>
                                <span class="">{{createCatalogueForm.get('name').value.length }}/200</span>
                            </div>
                        </nz-input-group>
                    </nz-form-control>

                </nz-form-item>

                <nz-form-item>
                    <nz-form-label nzRequired nzFor="retailer_id" [nzSm]="24" [nzXs]="24"> Retailer Id
                        <span nz-tooltip nzTooltipTitle="This can be a SKU or another unique identifier."
                            class="info_icon" nz-icon nzType="info-circle" nzTheme="outline"></span>
                    </nz-form-label>
                    <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please Enter Retailer Id!">
                        <nz-input-group>
                            <input autocomplete="off" formControlName="retailerId" type="text" nz-input id="retailer_id"
                                placeholder="Enter Retailer Id" required>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item [ngClass]="{'extra_mb' : createCatalogueForm.get('description').value.length == 9999}">
                    <nz-form-label nzRequired nzFor="product_description" [nzSm]="24" [nzXs]="24"> Description
                        <!-- <span nz-tooltip nzTooltipTitle="Enter the name of the product here" class="info_icon" nz-icon
                            nzType="info-circle" nzTheme="outline"></span> -->
                    </nz-form-label>
                    <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please Enter Product Description!">
                        <nz-input-group>
                            <textarea maxlength="9999" formControlName="description" nz-input rows="3"
                                placeholder="Enter Product Description"></textarea>
                            <div class="count">
                                <p *ngIf="createCatalogueForm.get('description').value.length == 9999"
                                    class="error_message">
                                    Max limit 9999 reached
                                </p>
                                <span class="">{{createCatalogueForm.get('description').value.length }}/9999</span>
                            </div>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>


                <nz-form-item>
                    <nz-form-label nzRequired [nzSm]="24" [nzXs]="24"> Product Image
                        <!-- <span nz-tooltip nzTooltipTitle="Enter the name of the product here" class="info_icon" nz-icon
                            nzType="info-circle" nzTheme="outline"></span> -->
                    </nz-form-label>
                    <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please Enter Product Description!">
                        <!-- nzExtra="Your images need to be at least 500x500 pixels, and no larger than 8 MB." -->

                        <nz-input-group>
                            <div class="drag_drop_container web-upload" appDnd (files)="filesDropped($event)">
                                <div class="drag_drop_section">
                                    <p>Drag & Drop your Image here</p>
                                    <span>OR</span>
                                    <div class="upload-button save-button mt-3">
                                        <label for='input-file'>Upload Image</label>
                                        <input (change)="uploadFile($event)" [accept]="imageTypes" class="upload_input"
                                            id='input-file' type='file'>
                                    </div>
                                </div>
                            </div>

                            <div class="upload-button save-button mt-3 mobile-upload">
                                <label for='input-file'>Upload Image</label>
                                <input (change)="uploadFile($event)" [accept]="imageTypes" class="upload_input"
                                    id='input-file' type='file'>
                            </div>
                            <p class="input_notes">
                                Your images need to be at least 500x500 pixels, and no larger than 8 MB.
                            </p>

                            <img *ngIf="selectedImageUrl" class="img-fluid preview_image mt-3 mb-3"
                                [src]="localImage ? selectedImageUrl :  selectedImageUrl" alt="">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
            </nz-card>
        </div>

        <div class="col-lg-5 right_column ">
            <nz-card class="catalogue_card">

                <div nz-row [nzGutter]="24">
                    <!-- <div class="setting_col" nz-col [nzSm]="24" [nzSpan]="12">
                        <span (click)="showDefaultValueModal()" class="setting_icon" nz-icon nzType="setting"
                            nzTheme="outline"></span>
                    </div> -->

                    <div nz-col [nzXs]="24" [nzSm]="12" [nzSpan]="12">
                        <nz-form-item>
                            <nz-form-label nzRequired [nzSm]="24" [nzXs]="24" nzFor="product_Price"> Price
                                <!-- <span nz-tooltip nzTooltipTitle="Enter the name of the product here" class="info_icon"
                                    nz-icon nzType="info-circle" nzTheme="outline"></span> -->
                            </nz-form-label>
                            <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please Enter Price!">
                                <nz-input-group>
                                    <input autocomplete="off" formControlName="price" type="text" nz-input
                                        id="product_Price" placeholder="Price" required>
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <div nz-col [nzXs]="24" [nzSm]="12" [nzSpan]="12">
                        <nz-form-item>
                            <nz-form-label nzRequired [nzSm]="24" [nzXs]="24" nzFor="product_sale_price"> Sale Price
                                <!-- <span nz-tooltip nzTooltipTitle="Enter the name of the product here" class="info_icon"
                                    nz-icon nzType="info-circle" nzTheme="outline"></span> -->
                            </nz-form-label>
                            <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please Enter Sale Price!">
                                <nz-input-group>
                                    <input autocomplete="off" formControlName="salePrice" type="text" nz-input
                                        id="product_sale_price" placeholder="Sale Price" required>
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <div nz-col [nzXs]="24" [nzSm]="12" [nzSpan]="12">
                        <nz-form-item>
                            <nz-form-label nzRequired [nzSm]="24" [nzXs]="24" nzFor="product_brand"> Brand
                                <!-- <span nz-tooltip nzTooltipTitle="Enter the name of the product here" class="info_icon"
                                    nz-icon nzType="info-circle" nzTheme="outline"></span> -->
                            </nz-form-label>
                            <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please Enter Brand Name!">
                                <nz-input-group>
                                    <input autocomplete="off" formControlName="brand" type="text" nz-input
                                        id="product_brand" placeholder="Brand Name" required>
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <div nz-col [nzXs]="24" [nzSm]="12" [nzSpan]="12">
                        <nz-form-item>
                            <nz-form-label nzRequired [nzSm]="24" [nzXs]="24" nzFor="product_category"> Category
                                <!-- <span nz-tooltip nzTooltipTitle="Enter the name of the product here" class="info_icon"
                                    nz-icon nzType="info-circle" nzTheme="outline"></span> -->
                            </nz-form-label>
                            <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please Enter Category Name!">
                                <nz-input-group>
                                    <!-- <input autocomplete="off" formControlName="category" type="text" nz-input
                                        id="product_category" placeholder="Category Name" required> -->
                                    <nz-select class="select_option" id="product_category" formControlName="category"
                                        nzPlaceHolder="Select Category">
                                        <nz-option nzValue="other" nzLabel="Other"></nz-option>
                                    </nz-select>
                                    <!-- <nz-cascader [nzShowSearch]="true" [nzPlaceHolder]="'Select Category'" class="w-100"
                                        [nzOptions]="categoryOptions" [formControlName]="'category'"></nz-cascader> -->
                                    <!-- <nz-tree-select class="w-100" [nzNodes]="categoryOptions" nzShowSearch
                                        nzPlaceHolder="Please select"></nz-tree-select> -->
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <div nz-col [nzXs]="24" [nzSm]="24" [nzSpan]="12">
                        <nz-form-item>
                            <nz-form-label nzRequired nzFor="product_availability" [nzSm]="24" [nzXs]="24"> Availability
                                <!-- <span nz-tooltip nzTooltipTitle="Enter the name of the product here" class="info_icon"
                                    nz-icon nzType="info-circle" nzTheme="outline"></span> -->
                            </nz-form-label>
                            <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please Select Availability!">
                                <nz-input-group>
                                    <nz-select class="select_option" id="product_availability"
                                        formControlName="availability" nzPlaceHolder="Select Availability">
                                        <nz-option nzValue="in stock" nzLabel="In Stock"></nz-option>
                                        <nz-option nzValue="out of stock" nzLabel="Out of Stock"></nz-option>
                                    </nz-select>
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <div nz-col [nzXs]="24" [nzSm]="24" [nzSpan]="12">
                        <nz-form-item>
                            <nz-form-label nzRequired nzFor="product_condition" [nzSm]="24" [nzXs]="24"> Condition
                                <!-- <span nz-tooltip nzTooltipTitle="Enter the name of the product here" class="info_icon"
                                    nz-icon nzType="info-circle" nzTheme="outline"></span> -->
                            </nz-form-label>
                            <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please Select Condition!">
                                <nz-input-group>
                                    <nz-select class="select_option" id="product_condition" formControlName="condition"
                                        nzPlaceHolder="Select Condition">
                                        <nz-option nzValue="new" nzLabel="New"></nz-option>
                                        <nz-option nzValue="refurbished" nzLabel="Refurbished"></nz-option>
                                        <nz-option nzValue="used" nzLabel="Used"></nz-option>
                                    </nz-select>
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <div nz-col [nzXs]="24" [nzSm]="12" [nzSpan]="12">
                        <nz-form-item>
                            <nz-form-label nzRequired [nzSm]="24" [nzXs]="24" nzFor="product_origin_country"> Origin
                                Country
                                <!-- <span nz-tooltip nzTooltipTitle="Enter the name of the product here" class="info_icon"
                                    nz-icon nzType="info-circle" nzTheme="outline"></span> -->
                            </nz-form-label>
                            <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please Enter Origin Country!">
                                <nz-input-group>
                                    <!-- <input autocomplete="off" formControlName="originCountry" type="text" nz-input
                                        id="product_origin_country" placeholder="Origin Country" required> -->
                                    <nz-select nzShowSearch class="select_option" id="product_origin_country"
                                        formControlName="originCountry" nzPlaceHolder="Select Origin Country">
                                        <nz-option *ngFor="let country of countryList" [nzValue]="country?.countryCode"
                                            [nzLabel]="country?.Country"></nz-option>
                                    </nz-select>
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <div nz-col [nzXs]="24" [nzSm]="12" [nzSpan]="12">
                        <nz-form-item>
                            <nz-form-label nzRequired [nzSm]="24" [nzXs]="24" nzFor="product_currency"> Currency
                                <!-- <span nz-tooltip nzTooltipTitle="Enter the name of the product here" class="info_icon"
                                    nz-icon nzType="info-circle" nzTheme="outline"></span> -->
                            </nz-form-label>
                            <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please Enter Currency!">
                                <nz-input-group>
                                    <!-- <input autocomplete="off" formControlName="currency" type="text" nz-input
                                        id="product_currency" placeholder="Currency" required> -->
                                    <nz-select nzShowSearch class="select_option" id="product_currency"
                                        formControlName="currency" nzPlaceHolder="Select Currency">
                                        <nz-option *ngFor="let item of currencyList" [nzValue]="item?.ISOcode"
                                            [nzLabel]="item?.CurrencyName"></nz-option>
                                    </nz-select>
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <div nz-col [nzXs]="24" [nzSm]="24" [nzSpan]="12">
                        <nz-form-item>
                            <nz-form-label nzRequired [nzSm]="24" [nzXs]="24" nzFor="product_url"> Url
                                <span nz-tooltip
                                    nzTooltipTitle="Url link helps people find the specific items on your website."
                                    class="info_icon" nz-icon nzType="info-circle" nzTheme="outline"></span>
                            </nz-form-label>
                            <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please Enter url!">
                                <nz-input-group>
                                    <input autocomplete="off" formControlName="url" type="text" nz-input
                                        id="product_url" placeholder="Enter url" required>
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <div nz-col [nzXs]="24" [nzSm]="24" [nzSpan]="12">
                        <nz-form-item>
                            <nz-form-label nzRequired [nzSm]="24" [nzXs]="24" nzFor="product_waComplianceCategory">
                                WA Compliance Category
                                <!-- <span nz-tooltip nzTooltipTitle="Enter the name of the product here" class="info_icon"
                                    nz-icon nzType="info-circle" nzTheme="outline"></span> -->
                            </nz-form-label>
                            <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please select category!">
                                <nz-input-group>
                                    <nz-select class="select_option" id="product_waComplianceCategory"
                                        formControlName="waComplianceCategory" nzPlaceHolder="WA Compliance Category">
                                        <nz-option nzValue="DEFAULT" nzLabel="DEFAULT"></nz-option>
                                    </nz-select>
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>

                <nz-form-item class="submit_section">
                    <button [nzLoading]="formLoading" type="submit" class="sighup_button w-100" nz-button
                        nzType="primary">{{ editCatalogue == "edit" ? 'Update' : 'Create'}} Catalogue</button>
                </nz-form-item>
            </nz-card>
        </div>
    </div>
</form>


<nz-modal [nzWidth]="'50%'" [(nzVisible)]="defaultValueModal" [nzFooter]="null" [nzTitle]="'Set Default Values'"
    nzCentered (nzOnCancel)="closeDefaultValueModal()">
    <ng-container *nzModalContent>
        <div class="default_values_div">
            <form [formGroup]="defaultValueForm" nz-form>
                <div nz-row [nzGutter]="24">
                    <div nz-col [nzSm]="12" [nzXs]="24" [nzSpan]="12">
                        <nz-form-item>
                            <nz-form-label [nzSm]="24" [nzXs]="24"> Brand
                            </nz-form-label>
                            <nz-form-control [nzSm]="24" [nzXs]="24">
                                <nz-input-group>
                                    <input formControlName="brand" autocomplete="off" type="text" nz-input
                                        placeholder="Brand Name" required>
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <div nz-col [nzSm]="12" [nzXs]="24" [nzSpan]="12">
                        <nz-form-item>
                            <nz-form-label [nzSm]="24" [nzXs]="24"> Category
                            </nz-form-label>
                            <nz-form-control [nzSm]="24" [nzXs]="24">
                                <nz-input-group>
                                    <nz-select formControlName="category" class="select_option"
                                        nzPlaceHolder="Select Category">
                                        <nz-option nzValue="other" nzLabel="Other"></nz-option>
                                    </nz-select>
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <div nz-col [nzSm]="12" [nzXs]="24" [nzSpan]="12">
                        <nz-form-item>
                            <nz-form-label [nzSm]="24" [nzXs]="24"> Availability
                            </nz-form-label>
                            <nz-form-control [nzSm]="24" [nzXs]="24">
                                <nz-input-group>
                                    <nz-select formControlName="availability" class="select_option"
                                        nzPlaceHolder="Select Availability">
                                        <nz-option nzValue="in stock" nzLabel="In Stock"></nz-option>
                                        <nz-option nzValue="out of stock" nzLabel="Out of Stock"></nz-option>
                                    </nz-select>
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <div nz-col [nzSm]="12" [nzXs]="24" [nzSpan]="12">
                        <nz-form-item>
                            <nz-form-label [nzSm]="24" [nzXs]="24"> Condition
                            </nz-form-label>
                            <nz-form-control [nzSm]="24" [nzXs]="24">
                                <nz-input-group>
                                    <nz-select formControlName="condition" class="select_option"
                                        nzPlaceHolder="Select Condition">
                                        <nz-option nzValue="new" nzLabel="New"></nz-option>
                                        <nz-option nzValue="refurbished" nzLabel="Refurbished"></nz-option>
                                        <nz-option nzValue="used" nzLabel="Used"></nz-option>
                                    </nz-select>
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <div nz-col [nzSm]="12" [nzXs]="24" [nzSpan]="12">
                        <nz-form-item>
                            <nz-form-label [nzSm]="24" [nzXs]="24"> Origin Country
                            </nz-form-label>
                            <nz-form-control [nzSm]="24" [nzXs]="24">
                                <nz-input-group>
                                    <nz-select formControlName="originCountry" nzShowSearch class="select_option"
                                        nzPlaceHolder="Select Origin Country">
                                        <nz-option *ngFor="let country of countryList" [nzValue]="country?.countryCode"
                                            [nzLabel]="country?.Country"></nz-option>
                                    </nz-select>
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <div nz-col [nzSm]="12" [nzXs]="24" [nzSpan]="12">
                        <nz-form-item>
                            <nz-form-label [nzSm]="24" [nzXs]="24"> Currency
                            </nz-form-label>
                            <nz-form-control [nzSm]="24" [nzXs]="24">
                                <nz-input-group>
                                    <nz-select formControlName="currency" nzShowSearch class="select_option"
                                        nzPlaceHolder="Select Currency">
                                        <nz-option *ngFor="let item of currencyList" [nzValue]="item?.ISOcode"
                                            [nzLabel]="item?.CurrencyName"></nz-option>
                                    </nz-select>
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <div nz-col [nzSm]="12" [nzXs]="24" [nzSpan]="12">
                        <nz-form-item>
                            <nz-form-label [nzSm]="24" [nzXs]="24"> Url
                            </nz-form-label>
                            <nz-form-control [nzSm]="24" [nzXs]="24">
                                <nz-input-group>
                                    <input formControlName="url" autocomplete="off" type="text" nz-input
                                        placeholder="Enter url" required>
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <div nz-col [nzSm]="12" [nzXs]="24" [nzSpan]="12">
                        <nz-form-item>
                            <nz-form-label [nzSm]="24" [nzXs]="24"> WA Compliance Category
                            </nz-form-label>
                            <nz-form-control [nzSm]="24" [nzXs]="24">
                                <nz-input-group>
                                    <nz-select formControlName="waComplianceCategory" class="select_option"
                                        nzPlaceHolder="WA Compliance Category">
                                        <nz-option nzValue="DEFAULT" nzLabel="DEFAULT"></nz-option>
                                    </nz-select>
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <div class="mt-5 value_update_col" nz-col [nzSm]="24" [nzXs]="24" [nzSpan]="12">
                        <nz-form-item class="submit_section text-right">
                            <nz-form-control [nzSm]="24" [nzXs]="24">
                                <div class="account_details_add_cancel_right">
                                    <!-- <button type="button" class="sighup_button mr-3" nz-button nzType="default">
                                        Cancel
                                    </button> -->
                                    <button type="submit" class="sighup_button" nz-button nzType="primary">
                                        Save
                                    </button>
                                </div>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
            </form>
        </div>
    </ng-container>
</nz-modal>