import { ElementRef, Component, HostListener } from "@angular/core";
import { ROUTES } from "./side-nav-routes.config";
import { ThemeConstantService } from "../../services/theme-constant.service";
import { Router } from "@angular/router";
import { ShortcutEventOutput, ShortcutInput } from "ng-keyboard-shortcuts";
import { environment } from "src/environments/environment";
import { CommonApiService } from "src/app/services/common-api.service";
import { NavService } from "src/app/services/nav.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-sidenav",
  templateUrl: "./side-nav.component.html",
  styleUrls: ["./side-nav.component.css"],
})
export class SideNavComponent {
  status: any = "active";
  userData: any;
  public menuItems: any[];
  isFolded: boolean;
  isSideNavDark: boolean;
  isExpand: boolean;
  selectedPath: any;
  shortcuts: ShortcutInput[] = [];

  liveUrl = environment.imageUrl;

  test = false;

  profileOpen: boolean;

  commerceId: any = "642c04bc0736249bbe825dad";

  private subscription: Subscription;

  constructor(
    private themeService: ThemeConstantService,
    private router: Router,
    private elementRef: ElementRef,
    private apiService: CommonApiService,
    private navService: NavService
  ) {}

  ngOnInit(): void {
    // this.userData = JSON.parse(localStorage.getItem("qweuserdataqwe"));
    this.getUserData();
    this.menuItems = ROUTES.filter((menuItem) => menuItem);
    this.themeService.isMenuFoldedChanges.subscribe(
      (isFolded) => (this.isFolded = isFolded)
    );
    this.themeService.isExpandChanges.subscribe(
      (isExpand) => (this.isExpand = isExpand)
    );
    this.themeService.isSideNavDarkChanges.subscribe(
      (isDark) => (this.isSideNavDark = isDark)
    );
    // close sidenav by default
    this.isFolded = true;
    this.themeService.toggleFold(this.isFolded);
    // console.log(this.userData);
  }

  getUserData() {
    this.navService.getMainUserData();
    this.subscription = this.navService.mainUserData.subscribe((data: any) => {
      if (data) {
        this.userData = data.data.userData;
        // console.log(this.userData);
      }
    });
    // let options = {
    //   headers: {
    //     Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
    //   },
    // };

    // this.apiService
    //   .commonGetMethod(environment.url + "auth/get-user-data", options)
    //   .subscribe(
    //     (response: any) => {
    //       this.userData = response.data.userData;
    //       console.log(this.userData);
    //     },
    //     (error: any) => {
    //       console.log(error);
    //     }
    //   );
  }

  @HostListener("document:mousedown", ["$event"])
  onGlobalClick(event: any): void {
    if (this.profileOpen) {
      this.profileOpen = false;
      this.mouseLeave();
    }
    if (
      !this.elementRef.nativeElement.contains(event.target) &&
      window.innerWidth < 992
    ) {
      this.isFolded = false;
      this.isExpand = false;
      this.themeService.toggleExpand(this.isExpand);
      this.themeService.toggleFold(this.isFolded);
    }
  }

  closeMobileMenu(): void {
    if (window.innerWidth < 992) {
      this.isFolded = true;
      this.isExpand = false;
      this.themeService.toggleExpand(this.isExpand);
      this.themeService.toggleFold(this.isFolded);
    } else {
      this.mouseLeave();
    }
    // this.router.navigate([path]);
    // this.selectedPath = path;
  }

  showForm(path: any) {
    // alert('add button works');
    this.router.navigate([path, { openForm: true }]);
  }

  mouseEnter() {
    if (window.innerWidth > 992) {
      this.isFolded = false;
      this.themeService.toggleFold(this.isFolded);
    }
  }

  mouseLeave() {
    if (window.innerWidth > 992 && !this.profileOpen) {
      this.isFolded = true;
      this.themeService.toggleFold(this.isFolded);
    }
  }

  logOut() {
    localStorage.removeItem("qwertrewetrt");
    localStorage.removeItem(
      "gtrdtghjdgfhjsgkahjhfjkafhfkjhfkfifghefywegfyuwgfuyew"
    );
    localStorage.removeItem("skjhfruibsndbvmnvckbhdsuifhbd");
    this.router.navigate(["/login"]);
  }

  showOptions() {
    this.profileOpen = true;
  }

  optionsLeave() {
    this.profileOpen = false;
  }

  ngOnDestroy(): void {
    // console.log("api key destroy..");
    this.subscription.unsubscribe();
  }
}
