import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { Subscription } from "rxjs";
import { AlertServiceService } from "src/app/services/alert-service.service";
import { CommonApiService } from "src/app/services/common-api.service";
import { NavService } from "src/app/services/nav.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-catalogue",
  templateUrl: "./catalogue.component.html",
  styleUrls: ["./catalogue.component.css"],
})
export class CatalogueComponent implements OnInit {
  createForm: boolean;
  catalogueData: any = [];
  editCatalogueData: any = {};
  editOption: any = "";

  pageIndex = 1;
  totalCount: number;
  pageSize: number;

  initialLoad = true;

  liveUrl = environment.imageUrl;

  orderColumn = [
    // {
    //   title: "S.No",
    //   width: "50px",
    // },
    {
      title: "Name",
      width: "80px",
    },
    {
      title: "Image",
      width: "50px",
    },
    {
      title: "Brand",
      width: "80px",
    },
    {
      title: "Category",
      width: "60px",
    },
    {
      title: "Price",
      width: "80px",
    },
    {
      title: "Url",
      width: "100px",
    },
  ];

  mainUserData: any;
  directCreate: boolean = false;

  tableHeight: any = "";

  private subscription: Subscription;

  constructor(
    private apiService: CommonApiService,
    private spinner: NgxSpinnerService,
    private alertService: AlertServiceService,
    private navService: NavService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getMainUserData();
    this.route.params.subscribe((params) => {
      // console.log(params);
      if (params["id"]) {
        this.directCreate = true;
        this.showCatalogueForm();
      } else {
        this.directCreate = false;
        this.fetchData();
      }
    });
  }

  fetchData() {
    this.spinner.show();
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonGetMethod(
        environment.url + "catalogue-item/get?page=" + this.pageIndex,
        options
      )
      .subscribe(
        (response: any) => {
          if (this.initialLoad) {
            this.catalogueData = response?.data?.catalogues;
            this.totalCount = response?.data?.pagination?.totalCount;
            this.pageSize = response?.data?.pagination?.limit;
          } else {
            // for (let a = 0; a < response.items.length; a++) {
            this.catalogueData.items = this.catalogueData.items.concat(
              response.items
            );
            // }
            this.catalogueData.next = response.next;
          }
          // console.log(this.catalogueData);
          // this.pageSize = this.catalogueData.items.length;
          this.spinner.hide();
        },
        (err: any) => {
          console.log(err);
          this.spinner.hide();
        }
      );
  }

  getMainUserData() {
    this.subscription = this.navService.mainUserData.subscribe(
      (response: any) => {
        if (response) {
          // console.log(response);
          this.mainUserData = response.data.userData;
          if (
            response?.data?.userData?.accessTo?.sandboxId &&
            window.innerWidth > 992
          ) {
            this.tableHeight = "45vh";
          } else {
            this.tableHeight = "50vh";
          }
        }
      }
    );
    // let options = {
    //   headers: {
    //     Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
    //   },
    // };

    // this.apiService
    //   .commonGetMethod(environment.url + "auth/get-user-data", options)
    //   .subscribe(
    //     (response: any) => {
    //       this.mainUserData = response.data.userData;
    //       // console.log(this.mainUserData);
    //     },
    //     (error: any) => {
    //       console.log(error);
    //     }
    //   );
  }

  showCatalogueForm() {
    if (!this.mainUserData?.accessTo?.isExpired) {
      this.createForm = true;
      this.editOption = "create";
    }
  }

  showCatalogueTable(method: any) {
    if (this.directCreate) {
      this.router.navigate(["/commerce", "items"]);
    } else {
      if (method == "create") {
        this.fetchData();
      }
      this.initialLoad = true;
      this.createForm = false;
      this.editCatalogueData = {};
    }
  }

  // TABLE SCROLL TOP ON PAGE CHANGE

  onPageNumberChange(id: any, event: any) {
    if (this.pageIndex != event.pageIndex) {
      this.pageIndex = event.pageIndex;
      this.fetchData();
      document.getElementById(id).scrollIntoView();
    }

    // window.scroll(0, 0);
  }

  editCatalogue(item) {
    if (!this.mainUserData?.accessTo?.isExpired) {
      // console.log(item);
      this.editOption = "edit";
      this.editCatalogueData = item;
      this.createForm = true;
    } else {
      this.alertService.warning();
    }
  }

  scrolled(event: any) {
    // console.log(event);
  }

  loadMore() {
    if (this.catalogueData.next) {
      this.initialLoad = false;
      this.fetchData();
    }
  }

  ngOnDestroy(): void {
    // console.log("api key destroy..");
    this.subscription.unsubscribe();
  }
}
