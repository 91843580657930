import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { CommonApiService } from "src/app/services/common-api.service";
import { environment } from "src/environments/environment";
import * as moment from "moment";
import { NzModalService } from "ng-zorro-antd/modal";
import { AlertServiceService } from "src/app/services/alert-service.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-tags",
  templateUrl: "./tags.component.html",
  styleUrls: ["./tags.component.css"],
})
export class TagsComponent implements OnInit {
  moment: any = moment;
  orderColumn: any = [
    {
      title: "Tag Name",
      width: "80px",
    },
    {
      title: "Created By",
      width: "100px",
    },
    {
      title: "Created At",
      width: "80px",
    },
    {
      title: "Action",
      width: "50px",
    },
  ];

  tagsData: any = [];
  addTagForm!: FormGroup;
  showForm: boolean = false;
  addingTag: boolean = false;

  addTagModal: boolean = false;
  @Input() mainUserData: any;

  // search and pagination

  searchKey: any = "";

  pageIndex = 1;
  totalCount: number;
  pageSize: number = 10;

  tableHeight: any = "";

  pageReloading: boolean = false;

  tagsPage: boolean = false;

  constructor(
    private apiService: CommonApiService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private modal: NzModalService,
    private alertService: AlertServiceService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.addTagForm = this.fb.group({
      name: ["", Validators.required],
    });
    this.fetchData();
    if (this.router.url == "/tags") {
      this.tagsPage = true;
    } else {
      this.tagsPage = false;
    }

    if (this.tagsPage) {
      if (this.mainUserData?.accessTo?.sandboxId && window.innerWidth > 992) {
        this.tableHeight = "66vh";
      } else {
        this.tableHeight = "75vh";
      }
    } else {
      if (this.mainUserData?.accessTo?.sandboxId && window.innerWidth > 992) {
        this.tableHeight = "56vh";
      } else {
        this.tableHeight = "65vh";
      }
    }
  }

  fetchData() {
    this.spinner.show();
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url +
          "tag?page=" +
          this.pageIndex +
          "&limit=" +
          this.pageSize,
        { filterValue: this.searchKey },
        options
      )
      .subscribe(
        (response: any) => {
          this.tagsData = response?.data?.tags;
          this.totalCount = response?.data?.pagination?.totalCount;
          this.pageSize = response?.data?.pagination?.limit;
          // console.log(response);
          this.spinner.hide();
          this.pageReloading = false;
        },
        (error: any) => {
          console.log(error);
          this.spinner.hide();
          this.pageReloading = false;
        }
      );
  }

  closeAddTagModal() {
    this.addTagModal = false;
    this.addTagForm.reset();
  }

  showTagModal() {
    this.addTagModal = true;
  }

  // add tag

  addTag() {
    if (this.addTagForm.valid) {
      this.addingTag = true;
      let options = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
        },
      };

      var data = {
        name: this.addTagForm.value.name,
      };

      this.apiService
        .commonPostMethod(environment.url + "tag/create", data, options)
        .subscribe(
          (response: any) => {
            // console.log(response);
            this.toastr.success("New Tag Added", "", {
              timeOut: 2500,
            });
            this.pageIndex = 1;
            this.fetchData();
            this.addingTag = false;
            // this.closeAddTagModal();
            this.showForm = false;
          },
          (error: any) => {
            console.log(error);
            this.addingTag = false;
          }
        );
    }
  }

  toggleForm() {
    if (!this.mainUserData?.accessTo?.isExpired) {
      this.addTagForm.reset();
      this.showForm = !this.showForm;
    }
  }

  deleteAlert(selected: any) {
    if (this.mainUserData?.accessTo?.isExpired) {
      this.alertService.warning();
    } else {
      this.modal.confirm({
        nzTitle: "Sure you want to delete this Tag!.",
        nzContent: "This tag will removed from all assigned users.",
        nzOkText: "Yes",
        nzCancelText: "No",
        nzOnOk: () => {
          this.deleteTag(selected);
        },
      });
    }
  }

  onPageNumberChange(id: any, event: any) {
    if (this.pageIndex != event.pageIndex) {
      this.pageIndex = event.pageIndex;
      this.fetchData();
      document.getElementById(id).scrollIntoView();
    }
  }

  searchContacts() {
    this.pageIndex = 1;
    this.fetchData();
  }

  deleteTag(selectedTag: any) {
    // console.log(selectedTag);
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(
        environment.url + "tag/delete",
        { tagId: selectedTag._id },
        options
      )
      .subscribe(
        (response: any) => {
          // console.log(response);
          this.toastr.success("Tag Removed!..", "", {
            timeOut: 2500,
          });
          if (this.tagsData.length == 1 && this.pageIndex != 1) {
            this.pageIndex = this.pageIndex - 1;
          }
          this.fetchData();
        },
        (error: any) => {
          console.log(error);
          if (error?.error?.message) {
            this.toastr.error(error?.error?.message, "", {
              timeOut: 2500,
            });
          } else {
            this.toastr.error(error?.message, "", {
              timeOut: 2500,
            });
          }
        }
      );
  }

  reloadPage() {
    this.pageReloading = true;
    this.searchKey = "";
    this.pageIndex = 1;
    this.fetchData();
  }

  changePageSize(size: any) {
    this.pageSize = size;
    this.fetchData();
  }

  setMyStyles() {
    let styles = {
      "margin-right":
        135 + (this.totalCount / this.pageSize).toFixed(0).length * 5 + "px",
    };
    return styles;
  }
}
