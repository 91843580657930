import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class CustomMessageService {
  private currentlyPlayingAudio: HTMLAudioElement;
  private currentlyPlayingVideo: HTMLVideoElement;
  constructor() {}

  public setCurrentlyPlayingAudio(audio: HTMLAudioElement): void {
    if (this.currentlyPlayingAudio && this.currentlyPlayingAudio !== audio) {
      this.currentlyPlayingAudio.pause();
    }
    this.currentlyPlayingAudio = audio;
  }

  public setCurrentlyPlayingVideo(video: HTMLVideoElement): void {
    if (this.currentlyPlayingVideo && this.currentlyPlayingVideo !== video) {
      this.currentlyPlayingVideo.pause();
    }
    this.currentlyPlayingVideo = video;
  }
}
