import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NzModalService } from "ng-zorro-antd/modal";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { AlertServiceService } from "src/app/services/alert-service.service";
import { CommonApiService } from "src/app/services/common-api.service";
import { NavService } from "src/app/services/nav.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-api-keys",
  templateUrl: "./api-keys.component.html",
  styleUrls: ["./api-keys.component.css"],
})
export class ApiKeysComponent implements OnInit {
  apiForm!: FormGroup;
  successForm!: FormGroup;

  generateApiKey: boolean = true;
  showRightMenu: boolean = false;

  allApiKeys: any;
  editData: any;

  creatingKey: boolean = false;

  copyText: string = "";
  mainUserData: any;

  private subscription: Subscription;

  constructor(
    private fb: FormBuilder,
    private apiService: CommonApiService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private modal: NzModalService,
    private navService: NavService,
    private alertService: AlertServiceService
  ) {}

  ngOnInit(): void {
    this.getAllKeys();
    this.getMainUserData();
    this.apiForm = this.fb.group({
      apiName: ["", Validators.required],
      apiPermission: ["all", Validators.required],
    });

    this.successForm = this.fb.group({
      apiKey: ["", Validators.required],
      apiSecret: ["", Validators.required],
    });
  }

  getMainUserData() {
    this.subscription = this.navService.mainUserData.subscribe(
      (response: any) => {
        if (response) {
          this.mainUserData = response.data.userData;
        }
      }
    );
  }

  getAllKeys() {
    this.spinner.show();
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(environment.url + "api-keys/get-all", {}, options)
      .subscribe(
        (response: any) => {
          // console.log(response);
          this.allApiKeys = response.data.apiKeys;
          this.spinner.hide();
        },
        (error: any) => {
          console.log(error);
          if (
            error?.error?.message == "jwt expired" ||
            error?.error?.message == "jwt must be provided" ||
            error?.error?.message == "jwt malformed" ||
            error?.error?.message == "invalid signature" ||
            error?.error?.message == "Given token user does not exist." ||
            error?.error?.message == "invalid token"
          ) {
            this.navService.tokenExpired();
          }
          this.spinner.hide();
        }
      );
  }

  createApiKey() {
    if (this.apiForm.valid) {
      this.creatingKey = true;
      let options = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
        },
      };

      var data: any = {
        name: this.apiForm.value.apiName,
        permissions: [
          {
            features: this.apiForm.value.apiPermission,
            operations: [this.apiForm.value.apiPermission],
          },
        ],
      };

      var url = "";

      if (this.editData) {
        url = "api-keys/update";
        data.apiKeyId = this.editData._id;
      } else {
        url = "api-keys/create";
      }

      this.apiService
        .commonPostMethod(environment.url + url, data, options)
        .subscribe(
          (response: any) => {
            // console.log(response);
            this.toastr.success(response?.message, "", {
              timeOut: 2500,
            });
            this.getAllKeys();
            if (!this.editData) {
              this.successForm.get("apiKey").setValue(response.data.apiKey);
              this.successForm
                .get("apiSecret")
                .setValue(response.data.apiSecret);
              this.generateApiKey = false;
            } else {
              this.showRightMenu = false;
            }
            this.creatingKey = false;
          },
          (error: any) => {
            console.log(error);
            this.creatingKey = false;
            if (error?.error?.message) {
              this.toastr.error(error?.error?.message, "", {
                timeOut: 2500,
              });
            } else {
              this.toastr.error(error?.message, "", {
                timeOut: 2500,
              });
            }
          }
        );
    } else {
      Object.values(this.apiForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  showApiForm() {
    if (this.mainUserData?.accessTo?.isExpired) {
      this.alertService.warning();
    } else if (this?.mainUserData?.accessTo?.sandboxId) {
      var errHead: any = "You cannot create API Keys in Sandbox account!";
      var errBody: any = "Please Subscribe any Plan to create API Keys..!";
      this.alertService.freeTrialWarning(errHead, errBody);
    } else {
      this.editData = null;
      this.apiForm.reset();
      this.apiForm.get("apiPermission").setValue("all");
      this.generateApiKey = true;
      this.showRightMenu = true;
    }
  }

  closeForm() {
    this.showRightMenu = false;
  }

  showEdit(key: any) {
    if (this.mainUserData?.accessTo?.isExpired) {
      this.alertService.warning();
    } else if (this?.mainUserData?.accessTo?.sandboxId) {
      var errHead: any = "You cannot Update API Keys in Sandbox account!";
      var errBody: any = "Please Subscribe any Plan to Update API Keys..!";
      this.alertService.freeTrialWarning(errHead, errBody);
    } else {
      this.editData = key;
      this.apiForm.get("apiName").setValue(key.name);
      this.apiForm.get("apiPermission").setValue(key?.permissions[0]?.features);
      this.generateApiKey = true;
      this.showRightMenu = true;
    }
  }

  deleteAlert(event: any, key: any) {
    event.preventDefault();
    event.stopPropagation();
    this.modal.confirm({
      nzOkText: "Yes",
      nzCancelText: "No",
      nzTitle: "<b>API key will be deleted Permanently</b>",
      nzContent: "Are you sure you want to delete?",
      nzOnOk: () => this.delateApiKey(key),
      nzOnCancel: () => {},
    });
  }

  delateAllAlert() {
    if (this?.mainUserData?.accessTo?.sandboxId) {
      var errHead: any = "You cannot Delete API Keys in Sandbox account!";
      var errBody: any = "Please Subscribe any Plan to Add/Update API Keys..!";
      this.alertService.freeTrialWarning(errHead, errBody);
    } else {
      this.modal.confirm({
        nzOkText: "Yes",
        nzCancelText: "No",
        nzTitle: "<b>All API keys will be deleted Permanently</b>",
        nzContent: "Are you sure you want to delete?",
        nzOnOk: () => this.delateApiKey("all"),
        nzOnCancel: () => {},
      });
    }
  }

  delateApiKey(key: any) {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    var url: any = "";

    var data: any = {};

    if (key == "all") {
      url = "api-keys/delete-all";
    } else {
      url = "api-keys/delete";
      data.apiKeyId = key._id;
    }

    this.apiService
      .commonPostMethod(environment.url + url, data, options)
      .subscribe(
        (response: any) => {
          // console.log(response);
          this.getAllKeys();
        },
        (error: any) => {
          console.log(error);
          if (error?.error?.message) {
            this.toastr.error(error?.error?.message, "", {
              timeOut: 2500,
            });
          } else {
            this.toastr.error(error?.message, "", {
              timeOut: 2500,
            });
          }
        }
      );
  }

  copyCode(type: string) {
    var resetTimer = 1000;
    if (type == "all") {
      var copyValue = `{ApiKey : ${this.successForm.value.apiKey} , ApiSecret : ${this.successForm.value.apiSecret}}`;
      navigator.clipboard.writeText(copyValue);
      resetTimer = 4000;
    } else if (type == "key") {
      navigator.clipboard.writeText(this.successForm.value.apiKey);
    } else {
      navigator.clipboard.writeText(this.successForm.value.apiSecret);
    }
    this.copyText = type;
    setTimeout(() => {
      this.copyText = "";
    }, resetTimer);
  }

  ngOnDestroy(): void {
    // console.log("api key destroy..");
    this.subscription.unsubscribe();
  }
}
