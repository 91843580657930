<div class="bot_main_layout">
    <div class="row">
        <div *ngIf="!fullScreen" class="col-3 p-0" #menuDivElement>
            <div class="bot_menu_list">
                <div class="sticky_left">
                    <h3 nz-tooltip nzTooltipPlacement="bottomLeft" [nzTooltipTitle]="flowDetail?.workFlow?.name">
                        {{flowDetail?.workFlow?.name?.length > 40 ? (flowDetail?.workFlow?.name.slice(0,40) + '...') :
                        flowDetail?.workFlow?.name}}
                    </h3>

                    <h4 *ngIf="!showSearch">
                        Flow Actions
                        <i (click)="openSearch(true)" nz-icon nzType="search" nzTheme="outline"></i>
                    </h4>
                    <div class="search" *ngIf="showSearch">
                        <i (click)="openSearch(false)" nz-icon nzType="arrow-left" nzTheme="outline"></i>
                        <input #myInput [(ngModel)]="searchText" autocomplete="off" type="text" nz-input
                            placeholder="search actions">
                    </div>
                </div>
                <nz-collapse *ngIf="!searchText" nzAccordion nzGhost [nzExpandIconPosition]="'right'">
                    <nz-collapse-panel *ngFor="let menu of nodeMenu" [nzHeader]="menu.category" [nzActive]="false"
                        [nzExtra]="extraTpl">
                        <div class="row">
                            <div (click)="showForm(option.type, false , false)" class="col-6 menu_col"
                                *ngFor="let option of menu.options">
                                <div class="action_menu">
                                    <img [src]="option?.icon" alt="">
                                    <span>
                                        {{option.name}}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <ng-template #extraTpl>
                            <img class="extra_logo" [src]="menu.logo" alt="">
                        </ng-template>
                    </nz-collapse-panel>
                </nz-collapse>
                <div *ngIf="searchText">
                    <div class="row">
                        <ng-container *ngFor="let menu of nodeMenu">
                            <div (click)="showForm(option.type, false , false)" class="col-6 menu_col"
                                *ngFor="let option of menu.options | filter : searchText">
                                <div class="action_menu">
                                    <img [src]="option?.icon" alt="">
                                    <span>
                                        {{option.name}}
                                    </span>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-9 p-0" [ngClass]="{'col-12' : fullScreen}">
            <div class="row">
                <div *ngIf="!fullScreen" class="col-12 mt-1 main_outer_heading p-0 m-0">
                    <nz-card class="contacts_top_card m-0 bot_menu_card">
                        <div class="search_add_div">
                            <div class="bot_path_name">
                                <span class="cursor_pointer" (click)="enableFullScreen()" nz-icon nzType="expand"
                                    nzTheme="outline"></span>
                                <span>Flow Builder</span>
                            </div>

                            <div class="head_right">
                                <div class="animation_div">
                                    <button (click)="publishPath()" nz-tooltip
                                        [nzTooltipTitle]="flowDetail?.workFlow?.isNeedToPublish ? 'Publish recent changes' : ''"
                                        [disabled]="!flowDetail?.workFlow?.isNeedToPublish" class="sighup_button "
                                        nz-button nzType="primary">
                                        Publish
                                    </button>
                                    <div [ngClass]="{'button-animation' : flowDetail?.workFlow?.isNeedToPublish}"
                                        class="">
                                    </div>
                                </div>
                                <span [routerLink]="['/work-flow']" nz-icon nzType="close" nzTheme="outline"></span>
                            </div>
                        </div>
                    </nz-card>
                </div>

                <div class="col-12 p-0">
                    <ngx-graph *ngIf="nodes.length > 0" #graphContainer (activate)="activate()" class="chart-container"
                        [minZoomLevel]=".2" [autoZoom]="false" [autoCenter]="false" [showMiniMap]="true" [links]="links"
                        [nodes]="nodes" [autoLayout]="false" [automaticLayout]="false" [layout]="layout"
                        [layoutSettings]="{ orientation: 'TB' }" [curve]="curve">
                        <ng-template #nodeTemplate let-node>
                            <svg:g class="node">
                                <svg:rect class="rect_node" [attr.width]="node.dimension.width"
                                    [attr.height]="node.dimension.height" [attr.fill]="node.data.color" />

                                <foreignObject [attr.x]="0" [attr.y]="0"
                                    [attr.width]="250 + node?.actionName?.length + 'px'" [attr.height]="'60px'">
                                    <div class="node_main_div">
                                        <img [src]="imageUrl + flowDetail?.appData?.logo" alt="">

                                        <div class="name_options">
                                            <p>{{node?.actionName}}</p>
                                        </div>

                                        <div *ngIf="node.actionType != 'TRIGGER_ACTION'"
                                            class="add_link_section in_section">
                                            <span (click)="addLink(node , 'in')" class=""></span>
                                        </div>

                                        <div *ngIf="outOptionTypes.indexOf(node.actionType) > -1"
                                            class="add_link_section out_section">
                                            <span (click)="addLink(node , 'out')" class=""></span>
                                        </div>

                                        <div class="edit_delete_div">
                                            <span (click)="showForm(node.actionType  , node, true)" nz-tooltip
                                                nzTooltipTitle="Info" class="" nz-icon nzType="info-circle"
                                                nzTheme="outline"></span>
                                            <!-- <span (click)="removeNodeAlert(node)" nz-tooltip nzTooltipTitle="Delete"
                                                class="delete_link" nz-icon nzType="delete" nzTheme="outline"></span> -->
                                        </div>
                                    </div>
                                </foreignObject>
                            </svg:g>
                        </ng-template>

                        <ng-template #linkTemplate let-link>
                            <svg:g class="edge">
                                <svg:path class="line" stroke-width="2" marker-end="url(#arrow)"></svg:path>
                                <svg:text class="edge-label" text-anchor="middle">
                                    <textPath class="text-path" [attr.href]="'#' + link.id"
                                        [style.dominant-baseline]="link.dominantBaseline" startOffset="45%">
                                        {{link.label}}
                                    </textPath>
                                    <textPath (click)="removeLink(link)" class="text-path close_link"
                                        [attr.href]="'#' + link.id" [style.dominant-baseline]="link.dominantBaseline"
                                        startOffset="86%">
                                        X
                                    </textPath>
                                </svg:text>
                            </svg:g>
                        </ng-template>

                        <ng-template #defsTemplate>
                            <svg:marker id="arrow" viewBox="0 -5 10 10" refX="8" refY="0" markerWidth="4"
                                markerHeight="4" orient="auto">
                                <svg:path d="M0,-5L10,0L0,5" class="arrow-head" />
                            </svg:marker>
                        </ng-template>

                        <svg>
                            <line *ngIf="isDrawingLine" class="custom_line" [attr.x1]="lineStartX"
                                [attr.y1]="lineStartY" [attr.x2]="lineEndX" [attr.y2]="lineEndY">
                            </line>
                        </svg>
                    </ngx-graph>
                </div>
            </div>
        </div>
    </div>
</div>


<nz-drawer [nzWidth]="drawerWidth" [nzClosable]="true" [nzVisible]="appFormDrawer" [nzPlacement]="'left'"
    [nzTitle]="drawerTitle" (nzOnClose)="closeAppFormDrawer()" nzCloseIcon="close">
    <ng-container *nzDrawerContent>
        <div class="" *ngIf="currentUrl.includes('/work-flow/create/')">
            <div class="" *ngIf="formMenu == 'appForm'">
                <form [formGroup]="appForm" (ngSubmit)="createUpdateFlow()" nz-form>
                    <nz-form-item>
                        <nz-form-label class="large_label" nzRequired [nzSm]="24" [nzXs]="24">
                            Connection
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please select connection!">
                            <nz-input-group>
                                <nz-select nzShowSearch formControlName="connection" nzPlaceHolder="Select template"
                                    class="w-100">
                                    <nz-option *ngFor="let connection of connectionsData" [nzLabel]="connection.name"
                                        [nzValue]="connection._id">
                                    </nz-option>
                                </nz-select>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item *ngIf="webhookId != appSelected._id">
                        <nz-form-label class="large_label" nzRequired [nzSm]="24" [nzXs]="24">
                            Event
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please select event!">
                            <nz-input-group>
                                <nz-select nzShowSearch formControlName="event" nzPlaceHolder="Select event"
                                    class="w-100">
                                    <nz-option *ngFor="let connection of connectionsData[getIndex()]?.events"
                                        [nzLabel]="connection.topic" [nzValue]="connection">
                                    </nz-option>
                                </nz-select>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item>
                        <nz-form-label class="large_label" nzRequired [nzSm]="24" [nzXs]="24">
                            Variable Name
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please enter variableName!">
                            <nz-input-group>
                                <input (keyup)="formatVariableName($event)" autocomplete="off"
                                    formControlName="variableName" type="text" nz-input
                                    placeholder="Enter name for variable" required>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item class="submit_section mt-5">
                        <button (click)="backToApp()" type="button" class="sighup_button" nz-button nzType="default">
                            Back
                        </button>
                        <button [nzLoading]="submitting" type="submit" class="sighup_button" nz-button nzType="primary">
                            Create
                        </button>
                    </nz-form-item>
                </form>
            </div>

            <div class="all_apps" *ngIf="formMenu == 'apps'">
                <div (click)="selectApp(app)" *ngFor="let app of allApps" class="app_card">
                    <img [src]="imageUrl + app.logo" alt="">
                    <h5>{{app.name}}</h5>
                </div>
            </div>
        </div>

        <ng-container *ngIf="currentUrl.includes('/work-flow/builder/')">
            <form [formGroup]="actionForm" (ngSubmit)="addAction()" nz-form>
                <nz-form-item>
                    <nz-form-label class="large_label" nzRequired [nzSm]="24" [nzXs]="24">
                        Action Name
                    </nz-form-label>
                    <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please enter action name!">
                        <nz-input-group>
                            <input autocomplete="off" formControlName="actionName" type="text" nz-input
                                placeholder="Enter name for action" required>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <ng-container *ngIf="messageType == 'SEND_WA_TEMPLATE'">
                    <nz-form-item>
                        <nz-form-label class="large_label" nzRequired [nzSm]="24" [nzXs]="24">
                            Customer Contact Number
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please select template!">
                            <nz-input-group>
                                <input id="cusContact" autocomplete="off" formControlName="contactNo" type="text"
                                    nz-input placeholder="Enter name for action" required>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>
                    <button (click)="apiVar(0 , 'cusContact', 'contact number', 0)" nz-dropdown nzTrigger="click"
                        [nzDropdownMenu]="apiVariable" type="button" class="variable_add template_var" nz-button
                        nzType="default">
                        Add variable
                    </button>

                    <nz-form-item>
                        <nz-form-label class="large_label" nzRequired [nzSm]="24" [nzXs]="24">
                            Customer Name
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please select template!">
                            <nz-input-group>
                                <input id="cusName" autocomplete="off" formControlName="customerName" type="text"
                                    nz-input placeholder="Enter name for action" required>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>
                    <button (click)="apiVar(0 , 'cusName', 'contact name', 0)" nz-dropdown nzTrigger="click"
                        [nzDropdownMenu]="apiVariable" type="button" class="variable_add template_var" nz-button
                        nzType="default">
                        Add variable
                    </button>

                    <nz-form-item>
                        <nz-form-label class="large_label" nzRequired [nzSm]="24" [nzXs]="24">
                            Select Template
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please select template!">
                            <nz-input-group>
                                <nz-select nzShowSearch (nzScrollToBottom)="loadMore()" formControlName="templateId"
                                    nzPlaceHolder="Select template" class="w-100" (ngModelChange)="selectTemplate()">
                                    <nz-option *ngFor="let template of allTemplates?.waMsgTemplates"
                                        [nzLabel]="template.name" [nzValue]="template._id">
                                    </nz-option>
                                </nz-select>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <div *ngIf="messageTemplateSelected" class="preview_out">
                        <div class="template_col">
                            <div class="preview_heading">
                                <h5 [nzTooltipTitle]="messageTemplateSelected.name" nzTooltipPlacement="top" nz-tooltip>
                                    {{messageTemplateSelected.name.length > 23 ?
                                    messageTemplateSelected.name.slice(0,23) + '...' : messageTemplateSelected.name}}
                                </h5>
                            </div>

                            <div class="preview_template">
                                <div class="inner_template">
                                    <div *ngFor="let message of messageTemplateSelected.components" class="mb-3">
                                        <div [ngSwitch]="message?.type" class="">
                                            <div *ngSwitchCase="'HEADER'" class="">
                                                <div [ngSwitch]="message?.format" class="header_text">
                                                    <p *ngSwitchCase="'TEXT'" [innerHTML]="message.text"></p>
                                                    <p class="img_url" *ngSwitchCase="'IMAGE'">
                                                        <span nz-icon nzType="file-image" nzTheme="outline"></span>
                                                        IMAGE
                                                    </p>
                                                    <p class="img_url" *ngSwitchCase="'VIDEO'">
                                                        <span nz-icon nzType="video-camera" nzTheme="outline"></span>
                                                        VIDEO
                                                    </p>
                                                    <p class="img_url" *ngSwitchCase="'DOCUMENT'">
                                                        <span nz-icon nzType="file-done" nzTheme="outline"></span>
                                                        DOCUMENT
                                                    </p>
                                                </div>

                                            </div>
                                            <div *ngSwitchCase="'BODY'" class="">
                                                <p [innerHTML]="((message?.text))"></p>
                                            </div>
                                            <div *ngSwitchCase="'FOOTER'" class="">
                                                <p [innerHTML]="message?.text"></p>
                                            </div>
                                            <div *ngSwitchCase="'BUTTONS'" class="">
                                                <div *ngFor="let button of message?.buttons" class="template_buttons">
                                                    <h6>{{button?.text}}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="template_bottom">
                                <div class="bottom_left">
                                    <span class="template_lang">{{messageTemplateSelected?.language}}</span>
                                    <span class="template_lang">{{messageTemplateSelected?.category}}</span>
                                </div>
                                <span class="template_status">{{messageTemplateSelected?.status}}</span>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="templateVariables" class="">
                        <div *ngIf="templateVariables.mediaData.hasMedia" class="condition_input">
                            <nz-form-item>
                                <nz-form-label class="large_label" nzRequired [nzSm]="24" [nzXs]="24">
                                    Media
                                </nz-form-label>
                                <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please select media!">
                                    <nz-input-group>
                                        <div class="media_div">
                                            <a (click)="showMediaModal()" nz-button nzType="primary"
                                                class="create-button">
                                                <i nz-icon nzType="upload" theme="outline"></i>
                                                <span>Upload Media</span>
                                            </a>
                                            <img class="media_preview" *ngIf="mediaId && mediaType == 'image'"
                                                [src]="mediaId.url" alt="">
                                            <img class="media_preview_demo" *ngIf="mediaId && mediaType == 'video'"
                                                src="../../../../assets/images/icons/video-icon.png" alt="">
                                            <img class="media_preview_demo" *ngIf="mediaId && mediaType == 'audio'"
                                                src="../../../../assets/images/icons/audio_icon.png" alt="">
                                            <img class="media_preview_demo" *ngIf="mediaId && mediaType == 'document'"
                                                src="../../../../assets/images/icons/document-icon.png" alt="">
                                        </div>
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                        </div>

                        <div *ngFor="let variable of templateVariables.variableTemplate;let k = index" class="">
                            <div nz-row [nzGutter]="24" class="nz_row">
                                <div nz-col [nzXs]="24" [nzSm]="24" [nzSpan]="12" class="condition_input">
                                    <h5>{{variable.type}}</h5>
                                    <div nz-row [nzGutter]="24" class="nz_row">
                                        <ng-container *ngFor="let field of variable.variables;let n = index">
                                            <div class="variable_index" nz-col [nzXs]="24" [nzSm]="3" [nzSpan]="12">
                                                {{'{{' + (n+1) + '}}'}}
                                            </div>
                                            <div nz-col [nzXs]="24" [nzSm]="10" [nzSpan]="12">
                                                <nz-form-item>
                                                    <nz-form-label class="large_label" nzRequired [nzSm]="24"
                                                        [nzXs]="24">
                                                        Value
                                                    </nz-form-label>
                                                    <nz-form-control [nzSm]="24" [nzXs]="24"
                                                        nzErrorTip="Please select value!">
                                                        <nz-input-group>
                                                            <input [id]="'value' + n" [(ngModel)]="field.field"
                                                                [ngModelOptions]="{standalone: true}" autocomplete="off"
                                                                type="text" nz-input placeholder="Enter value">
                                                        </nz-input-group>
                                                        <p class="error_text" *ngIf="submitted && !field.field">
                                                            Please enter field
                                                        </p>
                                                    </nz-form-control>
                                                </nz-form-item>

                                                <button (click)="apiVar(n , 'value'+n, 'value', k)" nz-dropdown
                                                    nzTrigger="click" [nzDropdownMenu]="apiVariable" type="button"
                                                    class="variable_add template_var" nz-button nzType="default">
                                                    Add variable
                                                </button>
                                            </div>
                                            <div nz-col [nzXs]="24" [nzSm]="11" [nzSpan]="12">
                                                <nz-form-item>
                                                    <nz-form-label class="large_label" nzRequired [nzSm]="24"
                                                        [nzXs]="24">
                                                        Fallback Value
                                                    </nz-form-label>
                                                    <nz-form-control [nzSm]="24" [nzXs]="24"
                                                        nzErrorTip="Please enter operator!">
                                                        <nz-input-group>
                                                            <input [(ngModel)]="field.fallbackValue"
                                                                [ngModelOptions]="{standalone: true}" autocomplete="off"
                                                                type="text" nz-input placeholder="Enter fallback value">
                                                        </nz-input-group>
                                                        <p class="error_text" *ngIf="submitted && !field.fallbackValue">
                                                            Please enter fallback value
                                                        </p>
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="messageType == 'DELAY'">
                    <div class="condition_input mb-2">
                        <nz-form-item>
                            <nz-form-label class="large_label mb-2" nzRequired [nzSm]="24" [nzXs]="24">
                                Delay Type
                            </nz-form-label>
                            <nz-form-control [nzSm]="24" [nzXs]="24">
                                <nz-input-group>
                                    <nz-radio-group formControlName="delayType" (ngModelChange)="delayTypeChange('')"
                                        class="vertical_radio">
                                        <label nz-radio nzValue="delay-for">Wait For Specific Time</label>
                                        <div *ngIf="actionForm.value.delayType == 'delay-for'"
                                            class="radio_input_group">
                                            <div class="">
                                                <input formControlName="delayCount" autocomplete="off" type="number"
                                                    nz-input placeholder="Enter time" required>
                                                <p *ngIf="actionForm.controls['delayCount'].invalid && submitted"
                                                    class="error_message">
                                                    Please enter delay time
                                                </p>
                                            </div>
                                            <div class="">
                                                <nz-select formControlName="delayTiming" nzPlaceHolder="Select time">
                                                    <nz-option nzLabel="Minutes" nzValue="minutes"> </nz-option>
                                                    <nz-option nzLabel="Hours" nzValue="hours"> </nz-option>
                                                    <nz-option nzLabel="Days" nzValue="days"> </nz-option>
                                                    <nz-option nzLabel="Weeks" nzValue="weeks"> </nz-option>
                                                </nz-select>
                                                <p *ngIf="actionForm.controls['delayTiming'].invalid && submitted"
                                                    class="error_message">
                                                    Please select delay time
                                                </p>
                                            </div>
                                        </div>

                                        <label nz-radio nzValue="delay-until">Wait until Specific date and time</label>
                                        <div *ngIf="actionForm.value.delayType == 'delay-until'"
                                            class="radio_input_group">
                                            <div class="input_data_options">
                                                <nz-select (ngModelChange)="inputTypeChange()"
                                                    formControlName="inputType" nzPlaceHolder="Select Format">
                                                    <nz-option nzLabel="Select Date" nzValue="datepicker"> </nz-option>
                                                    <nz-option nzLabel="Select Variable" nzValue="variable">
                                                    </nz-option>
                                                </nz-select>
                                            </div>
                                            <div class="">
                                                <ng-container *ngIf="actionForm.value.inputType == 'variable'">
                                                    <input class="mb-2" id="dateVariable" formControlName="delayValue"
                                                        autocomplete="off" type="text" nz-input
                                                        placeholder="Add variable">
                                                    <button (click)="apiVar(0 , 'dateVariable', 'date', 0)" nz-dropdown
                                                        nzTrigger="click" [nzDropdownMenu]="apiVariable" type="button"
                                                        class="variable_add" nz-button nzType="default">
                                                        Add variable
                                                    </button>
                                                </ng-container>
                                                <nz-date-picker *ngIf="actionForm.value.inputType == 'datepicker'"
                                                    formControlName="delayValue" nzShowToday nzShowTime
                                                    [nzFormat]="'dd/MM/YYYY hh:mm a'">
                                                </nz-date-picker>
                                                <p *ngIf="actionForm.controls['delayValue'].invalid && submitted"
                                                    class="error_message">
                                                    Please select delay time
                                                </p>
                                            </div>
                                            <!-- <div class="">
                                                <input class="mb-2" id="dateFormat" formControlName="delayFormat"
                                                    autocomplete="off" type="text" nz-input placeholder="Select format">
                                                <button nz-dropdown nzTrigger="click" [nzDropdownMenu]="dateFormats"
                                                    type="button" class="variable_add" nz-button nzType="default">
                                                    Select format
                                                </button>
                                                <p *ngIf="actionForm.controls['delayFormat'].invalid && submitted"
                                                    class="error_message">
                                                    Please select delay format
                                                </p>
                                            </div> -->
                                        </div>
                                    </nz-radio-group>
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <nz-form-item class="mb-0">
                        <nz-form-control [nzSm]="24" [nzXs]="24">
                            <nz-input-group>
                                <label nz-checkbox formControlName="advanceOption">Advanced Options</label>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item class="mt-2" *ngIf="actionForm.value.advanceOption">
                        <nz-form-label class="large_label mb-2" [nzSm]="24" [nzXs]="24">
                            Resume Workflow
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24">
                            <nz-input-group>
                                <nz-radio-group formControlName="resumeWorkflow" (ngModelChange)="flowChange('')"
                                    class="vertical_radio">
                                    <label nz-radio nzValue="immediately">Immediately</label>
                                    <label nz-radio nzValue="between">Between</label>
                                    <div *ngIf="actionForm.value.resumeWorkflow == 'between'" class="radio_input_group">
                                        <div class="">
                                            <nz-time-picker formControlName="fromTime"
                                                nzFormat="hh:mm a"></nz-time-picker>
                                            <p *ngIf="actionForm.controls['fromTime'].invalid && submitted"
                                                class="error_message">
                                                Please select from time
                                            </p>
                                        </div>
                                        <div class="">
                                            <nz-time-picker formControlName="toTime"
                                                nzFormat="hh:mm a"></nz-time-picker>
                                            <p *ngIf="actionForm.controls['toTime'].invalid && submitted"
                                                class="error_message">
                                                Please select from time
                                            </p>
                                        </div>
                                    </div>
                                </nz-radio-group>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <div class="condition_input">
                        <nz-form-item class="mt-2">
                            <nz-form-label class="large_label mb-2" [nzSm]="24" [nzXs]="24">
                                Days of the week selection
                            </nz-form-label>
                            <nz-form-control [nzSm]="24" [nzXs]="24">
                                <nz-input-group class="days_array">
                                    <label *ngFor="let days of weekDays" [(ngModel)]="days.action"
                                        [ngModelOptions]="{standalone: true}" nz-checkbox>
                                        {{days.day}}
                                    </label>
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>

                        <nz-form-item class="mt-2">
                            <nz-form-label nzRequired class="large_label mb-2" [nzSm]="24" [nzXs]="24">
                                Time Zone
                            </nz-form-label>
                            <nz-form-control [nzSm]="24" [nzXs]="24">
                                <nz-input-group>
                                    <nz-select nzShowSearch formControlName="timeZone" class="w-100 select_dropdown"
                                        nzPlaceHolder="Select Time Zone">
                                        <nz-option *ngFor="let item of timeZone" [nzLabel]="item?.label"
                                            [nzValue]="item?.value">
                                        </nz-option>
                                    </nz-select>
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </ng-container>

                <ng-container *ngIf="messageType == 'API_CALL'">
                    <nz-form-item>
                        <nz-form-label class="large_label" nzRequired [nzSm]="24" [nzXs]="24">
                            Method
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please select method!">
                            <nz-input-group>
                                <nz-select nzPlaceHolder="select method" formControlName="method" class="w-100">
                                    <nz-option *ngFor="let method of apiMethods" [nzLabel]="method" [nzValue]="method">
                                    </nz-option>
                                </nz-select>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item>
                        <nz-form-label class="large_label" nzRequired [nzSm]="24" [nzXs]="24">
                            URL
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please enter url!">
                            <nz-input-group>
                                <input #urlVariable (mouseup)="mouseUp()" (select)="onTextSelect('url')"
                                    (focus)="bodyTextFocus()" formControlName="url" autocomplete="off" type="text"
                                    nz-input placeholder="Enter url">
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>
                    <ng-container [ngTemplateOutlet]="textEditor"
                        [ngTemplateOutletContext]="{editorData : {type :  'url'}}"></ng-container>

                    <nz-form-item>
                        <nz-form-label class="large_label" nzRequired [nzSm]="24" [nzXs]="24">
                            Content Type
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please select content type!">
                            <nz-input-group>
                                <nz-select nzPlaceHolder="select content type" formControlName="contentType"
                                    class="w-100">
                                    <nz-option nzLabel="JSON" nzValue="application/json"></nz-option>
                                    <!-- <nz-option nzLabel="URL Encoded"
                                        nzValue="application/x-www-form-urlencoded"></nz-option> -->
                                </nz-select>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <div class="">
                        <div class="condition_input">
                            <h5>Headers</h5>

                            <div *ngFor="let header of apiHeaders; let s = index"
                                class="static_list_options api_header">
                                <nz-form-item class="">
                                    <nz-form-label class="large_label" nzRequired [nzSm]="24" [nzXs]="24">
                                        Key
                                    </nz-form-label>
                                    <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please select Key!">
                                        <nz-input-group>
                                            <input [ngClass]="{'error_input' : submitted && !header.key}"
                                                [(ngModel)]="header.key" [ngModelOptions]="{standalone: true}"
                                                autocomplete="off" type="text" nz-input placeholder="Enter Key">
                                        </nz-input-group>
                                        <p class="error_text" *ngIf="submitted && !header.key">
                                            Please enter Key
                                        </p>
                                    </nz-form-control>
                                </nz-form-item>

                                <div class="">
                                    <nz-form-item class="">
                                        <nz-form-label class="large_label" nzRequired [nzSm]="24" [nzXs]="24">
                                            Value
                                        </nz-form-label>
                                        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please enter Value!">
                                            <nz-input-group>
                                                <input [id]="'header' + s"
                                                    [ngClass]="{'error_input' : submitted && !header.value}"
                                                    [(ngModel)]="header.value" [ngModelOptions]="{standalone: true}"
                                                    autocomplete="off" type="text" nz-input placeholder="Enter Value">
                                            </nz-input-group>
                                            <p class="error_text" *ngIf="submitted && !header.value">
                                                Please enter Value
                                            </p>
                                        </nz-form-control>
                                    </nz-form-item>
                                    <button (click)="apiVar(s , 'header'+s, 'header' , 0)" nz-dropdown nzTrigger="click"
                                        [nzDropdownMenu]="apiVariable" type="button" class="variable_add" nz-button
                                        nzType="default">
                                        Add variable
                                    </button>
                                </div>

                                <span (click)="removeHeader(s)" class="condition_delete" nz-icon nzType="delete"
                                    nzTheme="outline"></span>
                            </div>
                            <a (click)="addHeader()" class="add_link">
                                Add header
                            </a>
                            <span *ngIf="k > 0" (click)="removeMenu(k)" class="condition_delete" nz-icon nzType="delete"
                                nzTheme="outline"></span>
                        </div>
                        <div class="condition_input">
                            <h5>QueryParams</h5>

                            <div *ngFor="let query of apiQueryParams; let s = index"
                                class="static_list_options api_header">
                                <nz-form-item class="">
                                    <nz-form-label class="large_label" nzRequired [nzSm]="24" [nzXs]="24">
                                        Key
                                    </nz-form-label>
                                    <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please enter Key!">
                                        <nz-input-group>
                                            <input [ngClass]="{'error_input' : submitted && !query.key}"
                                                [(ngModel)]="query.key" [ngModelOptions]="{standalone: true}"
                                                autocomplete="off" type="text" nz-input placeholder="Enter Key">
                                        </nz-input-group>
                                        <p class="error_text" *ngIf="submitted && !query.key">
                                            Please enter Key
                                        </p>
                                    </nz-form-control>
                                </nz-form-item>

                                <div class="">
                                    <nz-form-item class="">
                                        <nz-form-label class="large_label" nzRequired [nzSm]="24" [nzXs]="24">
                                            Value
                                        </nz-form-label>
                                        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please enter Value!">
                                            <nz-input-group>
                                                <input [id]="'query' + s"
                                                    [ngClass]="{'error_input' : submitted && !query.value}"
                                                    [(ngModel)]="query.value" [ngModelOptions]="{standalone: true}"
                                                    autocomplete="off" type="text" nz-input placeholder="Enter Value">
                                            </nz-input-group>
                                            <p class="error_text" *ngIf="submitted && !query.value">
                                                Please enter Value
                                            </p>
                                        </nz-form-control>
                                    </nz-form-item>
                                    <button (click)="apiVar(s , 'query'+s , 'queryParams', 0)" nz-dropdown
                                        nzTrigger="click" [nzDropdownMenu]="apiVariable" type="button"
                                        class="variable_add" nz-button nzType="default">
                                        Add variable
                                    </button>
                                </div>
                                <span (click)="removeQuery(s)" class="condition_delete" nz-icon nzType="delete"
                                    nzTheme="outline"></span>
                            </div>
                            <a (click)="addQuery()" class="add_link">
                                Add params
                            </a>
                            <span *ngIf="k > 0" (click)="removeMenu(k)" class="condition_delete" nz-icon nzType="delete"
                                nzTheme="outline"></span>
                        </div>
                    </div>

                    <nz-form-item>
                        <nz-form-label class="large_label" [nzSm]="24" [nzXs]="24">
                            Body
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please enter body data!">
                            <nz-input-group>
                                <input #bodyText (focus)="showEditor('bodyText')" [value]="apiBody" autocomplete="off"
                                    type="text" nz-input placeholder="Enter body data">
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <div class="mb-3" *ngIf="codeEditor">
                        <ngx-codemirror #codemirror [options]="codeMirrorOptions" [(ngModel)]="apiBody"
                            [ngModelOptions]="{standalone: true}">
                        </ngx-codemirror>
                        <button (click)="variableSearch = ''" type="button" class="variable_add mt-2" nz-dropdown
                            nzTrigger="click" [nzDropdownMenu]="editorMenu" nz-button nzType="default">
                            Add variable
                        </button>
                    </div>


                    <nz-form-item>
                        <nz-form-label class="large_label" [nzSm]="24" [nzXs]="24">
                            Variable Type
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please select variable type!">
                            <nz-input-group>
                                <nz-select nzAllowClear nzPlaceHolder="select variable type"
                                    formControlName="variableType" class="w-100">
                                    <nz-option *ngFor="let variable of apiVariableType" [nzLabel]="variable"
                                        [nzValue]="variable"></nz-option>
                                </nz-select>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item class="mt-3">
                        <nz-form-label class="large_label" [nzSm]="24" [nzXs]="24">
                            Store response in the variable
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Maximum limit 200!">
                            <nz-input-group>
                                <nz-select #responseVar nzShowSearch nzAllowClear formControlName="responseVariable"
                                    nzPlaceHolder="Select variable to store response" class="w-100"
                                    (ngModelChange)="addNewVariable()" (keyup)="detectAvailVariables($event)">
                                    <ng-container *ngFor="let item of flowVariables">
                                        <nz-option *ngIf="item?.data?.dataType == addMessageForm.value.variableType"
                                            [nzLabel]="item.name" [nzValue]="item.name"></nz-option>
                                    </ng-container>
                                    <nz-option *ngIf="newVariable" [nzLabel]="'Create new variable : ' + newVariable"
                                        [nzValue]="'new variable'"></nz-option>
                                </nz-select>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>
                </ng-container>

                <nz-form-item *ngIf="!editData" class="submit_section mt-5">
                    <button type="submit" class="sighup_button w-100" nz-button nzType="primary">
                        Add action
                    </button>
                </nz-form-item>
            </form>
        </ng-container>

        <ngx-spinner bdColor="rgb(255,255,255)" size="medium" color="#000000" type="ball-clip-rotate-pulse"
            [fullScreen]="false">
        </ngx-spinner>
    </ng-container>
</nz-drawer>

<nz-modal [nzWidth]="'80%'" [nzBodyStyle]="{'padding' : '0px'}" [(nzVisible)]="mediaModal" [nzFooter]="null"
    nzTitle="Select Media" nzCentered (nzOnCancel)="closeMediaModal()">
    <ng-container *nzModalContent>
        <app-content-page [singleSelect]="true" [showMenu]="false" [filterSelected]="mediaType" [selectedTab]="'media'"
            (selectedData)="selectedData($event)"></app-content-page>
    </ng-container>
</nz-modal>

<!-- TEMPLATE VARIABLES DROPDOWN -->

<nz-dropdown-menu #apiVariable="nzDropdownMenu">
    <ul nz-menu class="dropdown_menu">
        <div class="variable_search">
            <input [(ngModel)]="variableSearch" autocomplete="off" nz-input placeholder="search" type="text" />
        </div>
        <span class="drop_heading">Variables</span>
        <li (click)="addApiVar(flowDetail?.workFlow?.variableName , item.keyName)" nz-menu-item
            *ngFor="let item of flowDetail?.variableData | filter : variableSearch">
            {{item.keyName}}
        </li>
    </ul>
</nz-dropdown-menu>

<nz-dropdown-menu #dateFormats="nzDropdownMenu">
    <ul nz-menu class="dropdown_menu">
        <span class="drop_heading path_dropdown">Variables</span>
        <li (click)="addFormat(format)" nz-menu-item *ngFor="let format of allFormats">
            {{format}}
        </li>
    </ul>
</nz-dropdown-menu>

<nz-dropdown-menu #editorMenu="nzDropdownMenu">
    <ul nz-menu class="dropdown_menu">
        <div class="variable_search">
            <input [(ngModel)]="variableSearch" autocomplete="off" nz-input placeholder="search" type="text" />
        </div>
        <span *ngIf="flowVariables?.length > 0" class="drop_heading">Variables</span>
        <li (click)="addVarEditor(item.name, 'variables')" nz-menu-item
            *ngFor="let item of flowVariables | filter : variableSearch">
            {{item.name}}</li>
        <li nz-menu-divider></li>
        <span class="drop_heading">Customer fields</span>
        <li (click)="addVarEditor(field.name, 'customer')" nz-menu-item
            *ngFor="let field of customerFields | filter : variableSearch">
            {{field.label}}</li>
    </ul>
</nz-dropdown-menu>

<!-- [nzMaskClosable]="currentUrl.includes('/work-flow/builder/')" -->