export const catalogueCategories: any = [
  {
    value: "food & beverages",
    label: "Food Beverages",
    children: [
      {
        value: "food",
        label: "Food",
        children: [
          {
            value: "soups & broths",
            label: "Soups Broths",
            isLeaf: true,
          },
          {
            value: "nuts & seeds",
            label: "Nuts Seeds",
            isLeaf: true,
          },
          {
            value: "snacks & chips",
            label: "Snacks Chips",
            isLeaf: true,
          },
          {
            value: "meat substitutes",
            label: "Meat Substitutes",
            isLeaf: true,
          },
          {
            value: "spices & seasonings",
            label: "Spices Seasonings",
            isLeaf: true,
          },
          {
            value: "pasta & grains",
            label: "Pasta Grains",
            isLeaf: true,
          },
          {
            value: "prepared food & meal kits",
            label: "Prepared Food Meal Kits",
            isLeaf: true,
          },
          {
            value: "candy & chocolate",
            label: "Candy Chocolate",
            isLeaf: true,
          },
          {
            value: "dips & spreads",
            label: "Dips Spreads",
            isLeaf: true,
          },
          {
            value: "meat & seafood",
            label: "Meat Seafood",
            isLeaf: true,
          },
          {
            value: "bakery & bread",
            label: "Bakery Bread",
            isLeaf: true,
          },
          {
            value: "condiments & sauces",
            label: "Condiments Sauces",
            isLeaf: true,
          },
          {
            value: "dairy, eggs & cheese",
            label: "Dairy Eggs Cheese",
            isLeaf: true,
          },
          {
            value: "frozen food",
            label: "Frozen Food",
            isLeaf: true,
          },
          {
            value: "baking ingredients",
            label: "Baking Ingredients",
            isLeaf: true,
          },
          {
            value: "fruits & vegetables",
            label: "Fruits Vegetables",
            isLeaf: true,
          },
          {
            value: "breakfast & cereal",
            label: "Breakfast Cereal",
            isLeaf: true,
          },
        ],
      },
      {
        value: "beverages",
        label: "Beverages",
        children: [
          {
            value: "sports & energy drinks",
            label: "Sports Energy Drinks",
            isLeaf: true,
          },
          {
            value: "juice",
            label: "Juice",
            isLeaf: true,
          },
          {
            value: "powdered beverage mixes",
            label: "Powdered Beverage Mixes",
            isLeaf: true,
          },
          {
            value: "soda",
            label: "Soda",
            isLeaf: true,
          },
          {
            value: "water",
            label: "Water",
            isLeaf: true,
          },
          {
            value: "coffee & tea",
            label: "Coffee Tea",
            isLeaf: true,
          },
          {
            value: "alcoholic beverages",
            label: "Alcoholic Beverages",
            isLeaf: true,
          },
          {
            value: "milk",
            label: "Milk",
            isLeaf: true,
          },
        ],
      },
    ],
  },

  {
    value: "toys & games",
    label: "Toys Games",
    children: [
      {
        value: "outdoor toys",
        label: "Outdoor Toys",
        isLeaf: true,
      },
      {
        value: "stuffed animals & plush toys",
        label: "Stuffed Animals Plush Toys",
        isLeaf: true,
      },
      {
        value: "games",
        label: "Games",
        children: [
          {
            value: "role playing games",
            label: "Role Playing Games",
            isLeaf: true,
          },
          {
            value: "chess",
            label: "Chess",
            isLeaf: true,
          },
          {
            value: "miniature & war games",
            label: "Miniature War Games",
            isLeaf: true,
          },
          {
            value: "dice games",
            label: "Dice Games",
            isLeaf: true,
          },
          {
            value: "tile games",
            label: "Tile Games",
            isLeaf: true,
          },
          {
            value: "card games",
            label: "Card Games",
            isLeaf: true,
          },
          {
            value: "dexterity games",
            label: "Dexterity Games",
            isLeaf: true,
          },
          {
            value: "board games",
            label: "Board Games",
            isLeaf: true,
          },
        ],
      },
      {
        value: "puzzles",
        label: "Puzzles",
        isLeaf: true,
      },
      {
        value: "riding toys",
        label: "Riding Toys",
        isLeaf: true,
      },
      {
        value: "pretend play",
        label: "Pretend Play",
        isLeaf: true,
      },
      {
        value: "toy vehicles",
        label: "Toy Vehicles",
        isLeaf: true,
      },
      {
        value: "remote control toys",
        label: "Remote Control Toys",
        isLeaf: true,
      },
      {
        value: "educational toys",
        label: "Educational Toys",
        isLeaf: true,
      },
      {
        value: "dolls & doll houses",
        label: "Dolls Doll Houses",
        isLeaf: true,
      },
      {
        value: "building toys",
        label: "Building Toys",
        isLeaf: true,
      },
      {
        value: "action figures",
        label: "Action Figures",
        isLeaf: true,
      },
      {
        value: "electronic toys",
        label: "Electronic Toys",
        isLeaf: true,
      },
    ],
  },

  {
    value: "home improvement",
    label: "Home Improvement",
    children: [
      {
        value: "home heating & cooling",
        label: "Home Heating Cooling",
        children: [
          {
            value: "water heaters",
            label: "Water Heaters",
            isLeaf: true,
          },
          {
            value: "thermostats",
            label: "Thermostats",
            isLeaf: true,
          },
          {
            value: "space heaters",
            label: "Space Heaters",
            isLeaf: true,
          },
          {
            value: "humidifiers",
            label: "Humidifiers",
            isLeaf: true,
          },
          {
            value: "heated flooring",
            label: "Heated Flooring",
            isLeaf: true,
          },
          {
            value: "furnaces & heating systems",
            label: "Furnaces Heating Systems",
            isLeaf: true,
          },
          {
            value: "fireplaces & stoves",
            label: "Fireplaces Stoves",
            children: [
              {
                value: "stove & chimney pipes",
                label: "Stove Chimney Pipes",
                isLeaf: true,
              },
              {
                value: "replacement parts",
                label: "Replacement Parts",
                isLeaf: true,
              },
              {
                value: "pokers, tools & sets",
                label: "Pokers Tools Sets",
                isLeaf: true,
              },
              {
                value: "log holders & carriers",
                label: "Log Holders Carriers",
                isLeaf: true,
              },
              {
                value: "heating stoves",
                label: "Heating Stoves",
                isLeaf: true,
              },
              {
                value: "fuel & firewood",
                label: "Fuel Firewood",
                isLeaf: true,
              },
              {
                value: "fireplaces",
                label: "Fireplaces",
                isLeaf: true,
              },
              {
                value: "fireplace screens & doors",
                label: "Fireplace Screens Doors",
                isLeaf: true,
              },
              {
                value: "fireplace mantels & surrounds",
                label: "Fireplace Mantels Surrounds",
                isLeaf: true,
              },
              {
                value: "fireplace hearths",
                label: "Fireplace Hearths",
                isLeaf: true,
              },
              {
                value: "decorative logs, stone & glass",
                label: "Decorative Logs Stone Glass",
                isLeaf: true,
              },
              {
                value: "chimney brushes",
                label: "Chimney Brushes",
                isLeaf: true,
              },
              {
                value: "andirons, grates & firedogs",
                label: "Andirons Grates Firedogs",
                isLeaf: true,
              },
            ],
          },
          {
            value: "fans",
            label: "Fans",
            isLeaf: true,
          },
          {
            value: "dehumidifiers",
            label: "Dehumidifiers",
            isLeaf: true,
          },
          {
            value: "air conditioners",
            label: "Air Conditioners",
            isLeaf: true,
          },
          {
            value: "air filters",
            label: "Air Filters",
            isLeaf: true,
          },
          {
            value: "air purifiers",
            label: "Air Purifiers",
            isLeaf: true,
          },
        ],
      },
      {
        value: "building supplies & hardware",
        label: "Building Supplies Hardware",
        children: [
          {
            value: "chain, wire & rope",
            label: "Chain Wire Rope",
            children: [
              {
                value: "bungee cords",
                label: "Bungee Cords",
                isLeaf: true,
              },
              {
                value: "utility wire",
                label: "Utility Wire",
                isLeaf: true,
              },
              {
                value: "twine",
                label: "Twine",
                isLeaf: true,
              },
              {
                value: "tie downs & straps",
                label: "Tie Downs Straps",
                isLeaf: true,
              },
              {
                value: "ropes",
                label: "Ropes",
                isLeaf: true,
              },
              {
                value: "chains",
                label: "Chains",
                isLeaf: true,
              },
            ],
          },
          {
            value: "cabinets & cabinet hardware",
            label: "Cabinets Cabinet Hardware",
            children: [
              {
                value: "cabinet & drawer locks",
                label: "Cabinet Drawer Locks",
                isLeaf: true,
              },
              {
                value: "drawer slides",
                label: "Drawer Slides",
                isLeaf: true,
              },
              {
                value: "cabinets",
                label: "Cabinets",
                isLeaf: true,
              },
              {
                value: "cabinet knobs & pulls",
                label: "Cabinet Knobs Pulls",
                isLeaf: true,
              },
              {
                value: "cabinet keyhole covers & escutcheons",
                label: "Cabinet Keyhole Covers Escutcheons",
                isLeaf: true,
              },
              {
                value: "cabinet hinges",
                label: "Cabinet Hinges",
                isLeaf: true,
              },
              {
                value: "cabinet catches",
                label: "Cabinet Catches",
                isLeaf: true,
              },
              {
                value: "cabinet backplates",
                label: "Cabinet Backplates",
                isLeaf: true,
              },
            ],
          },
          {
            value: "building plans & blueprints",
            label: "Building Plans Blueprints",
            isLeaf: true,
          },
          {
            value: "windows & window hardware",
            label: "Windows Window Hardware",
            children: [
              {
                value: "weather stripping",
                label: "Weather Stripping",
                isLeaf: true,
              },
              {
                value: "windows",
                label: "Windows",
                isLeaf: true,
              },
              {
                value: "window sills",
                label: "Window Sills",
                isLeaf: true,
              },
              {
                value: "window screens",
                label: "Window Screens",
                isLeaf: true,
              },
              {
                value: "window hardware",
                label: "Window Hardware",
                isLeaf: true,
              },
              {
                value: "window frames",
                label: "Window Frames",
                isLeaf: true,
              },
              {
                value: "window cranks",
                label: "Window Cranks",
                isLeaf: true,
              },
            ],
          },
          {
            value: "wallpaper & accessories",
            label: "Wallpaper Accessories",
            children: [
              {
                value: "wallpaper borders",
                label: "Wallpaper Borders",
                isLeaf: true,
              },
              {
                value: "wallpaper tools & accessories",
                label: "Wallpaper Tools Accessories",
                isLeaf: true,
              },
              {
                value: "wallpaper rolls & sheets",
                label: "Wallpaper Rolls Sheets",
                isLeaf: true,
              },
              {
                value: "wallpaper murals",
                label: "Wallpaper Murals",
                isLeaf: true,
              },
            ],
          },
          {
            value: "staircases",
            label: "Staircases",
            isLeaf: true,
          },
          {
            value: "siding",
            label: "Siding",
            isLeaf: true,
          },
          {
            value: "roofing supplies",
            label: "Roofing Supplies",
            children: [
              {
                value: "gutters",
                label: "Gutters",
                isLeaf: true,
              },
              {
                value: "shingles & tiles",
                label: "Shingles Tiles",
                isLeaf: true,
              },
              {
                value: "roof flashings",
                label: "Roof Flashings",
                isLeaf: true,
              },
            ],
          },
          {
            value: "nails, screws & fasteners",
            label: "Nails Screws Fasteners",
            children: [
              {
                value: "washers",
                label: "Washers",
                isLeaf: true,
              },
              {
                value: "utility buckles",
                label: "Utility Buckles",
                isLeaf: true,
              },
              {
                value: "threaded rods",
                label: "Threaded Rods",
                isLeaf: true,
              },
              {
                value: "springs",
                label: "Springs",
                isLeaf: true,
              },
              {
                value: "screws",
                label: "Screws",
                isLeaf: true,
              },
              {
                value: "screw posts",
                label: "Screw Posts",
                isLeaf: true,
              },
              {
                value: "rivets",
                label: "Rivets",
                isLeaf: true,
              },
              {
                value: "pins",
                label: "Pins",
                isLeaf: true,
              },
              {
                value: "anchors",
                label: "Anchors",
                isLeaf: true,
              },
              {
                value: "nuts & bolts",
                label: "Nuts Bolts",
                isLeaf: true,
              },
              {
                value: "nails",
                label: "Nails",
                isLeaf: true,
              },
              {
                value: "hooks",
                label: "Hooks",
                isLeaf: true,
              },
              {
                value: "gear ties",
                label: "Gear Ties",
                isLeaf: true,
              },
              {
                value: "fastener assortments",
                label: "Fastener Assortments",
                isLeaf: true,
              },
              {
                value: "coils",
                label: "Coils",
                isLeaf: true,
              },
              {
                value: "clamps & shackles",
                label: "Clamps Shackles",
                isLeaf: true,
              },
              {
                value: "chain connectors & links",
                label: "Chain Connectors Links",
                isLeaf: true,
              },
              {
                value: "braces & brackets",
                label: "Braces Brackets",
                isLeaf: true,
              },
            ],
          },
          {
            value: "mailboxes & mailbox hardware",
            label: "Mailboxes Mailbox Hardware",
            children: [
              {
                value: "mail slots",
                label: "Mail Slots",
                isLeaf: true,
              },
              {
                value: "mailboxes",
                label: "Mailboxes",
                isLeaf: true,
              },
              {
                value: "mailbox replacement doors",
                label: "Mailbox Replacement Doors",
                isLeaf: true,
              },
              {
                value: "mailbox posts",
                label: "Mailbox Posts",
                isLeaf: true,
              },
              {
                value: "mailbox flags",
                label: "Mailbox Flags",
                isLeaf: true,
              },
              {
                value: "mailbox enclosures",
                label: "Mailbox Enclosures",
                isLeaf: true,
              },
              {
                value: "mailbox covers",
                label: "Mailbox Covers",
                isLeaf: true,
              },
            ],
          },
          {
            value: "lumber & composites",
            label: "Lumber Composites",
            children: [
              {
                value: "lumber",
                label: "Lumber",
                isLeaf: true,
              },
              {
                value: "wood veneer sheets",
                label: "Wood Veneer Sheets",
                isLeaf: true,
              },
              {
                value: "wood composite panels & sheets",
                label: "Wood Composite Panels Sheets",
                isLeaf: true,
              },
              {
                value: "wall paneling",
                label: "Wall Paneling",
                isLeaf: true,
              },
            ],
          },
          {
            value: "locks & keys",
            label: "Locks Keys",
            children: [
              {
                value: "key blanks",
                label: "Key Blanks",
                isLeaf: true,
              },
              {
                value: "locks & latches",
                label: "Locks Latches",
                isLeaf: true,
              },
              {
                value: "key card entry systems",
                label: "Key Card Entry Systems",
                isLeaf: true,
              },
              {
                value: "key caps",
                label: "Key Caps",
                isLeaf: true,
              },
            ],
          },
          {
            value: "garage doors & openers",
            label: "Garage Doors Openers",
            children: [
              {
                value: "garage door parts & accessories",
                label: "Garage Door Parts Accessories",
                isLeaf: true,
              },
              {
                value: "opener systems",
                label: "Opener Systems",
                isLeaf: true,
              },
              {
                value: "garage doors",
                label: "Garage Doors",
                isLeaf: true,
              },
              {
                value: "garage door remotes",
                label: "Garage Door Remotes",
                isLeaf: true,
              },
            ],
          },
          {
            value: "flooring & tiles",
            label: "Flooring Tiles",
            children: [
              {
                value: "floor & wall tiles",
                label: "Floor Wall Tiles",
                isLeaf: true,
              },
              {
                value: "wood flooring",
                label: "Wood Flooring",
                isLeaf: true,
              },
              {
                value: "laminate & vinyl flooring",
                label: "Laminate Vinyl Flooring",
                isLeaf: true,
              },
              {
                value: "flooring underlayment",
                label: "Flooring Underlayment",
                isLeaf: true,
              },
              {
                value: "flooring moldings & trims",
                label: "Flooring Moldings Trims",
                isLeaf: true,
              },
              {
                value: "flooring & tiling accessories",
                label: "Flooring Tiling Accessories",
                isLeaf: true,
              },
            ],
          },
          {
            value: "electrical & solar supplies",
            label: "Electrical Solar Supplies",
            children: [
              {
                value: "circuit breakers & fuse boxes",
                label: "Circuit Breakers Fuse Boxes",
                isLeaf: true,
              },
              {
                value: "wall socket controls & sensors",
                label: "Wall Socket Controls Sensors",
                isLeaf: true,
              },
              {
                value: "switches & outlets",
                label: "Switches Outlets",
                isLeaf: true,
              },
              {
                value: "switch plates & outlet covers",
                label: "Switch Plates Outlet Covers",
                isLeaf: true,
              },
              {
                value: "solar water pumps",
                label: "Solar Water Pumps",
                isLeaf: true,
              },
              {
                value: "solar panels",
                label: "Solar Panels",
                isLeaf: true,
              },
              {
                value: "solar chargers & inverters",
                label: "Solar Chargers Inverters",
                isLeaf: true,
              },
              {
                value: "plug caps",
                label: "Plug Caps",
                isLeaf: true,
              },
              {
                value: "generators",
                label: "Generators",
                isLeaf: true,
              },
              {
                value: "extension cords",
                label: "Extension Cords",
                isLeaf: true,
              },
            ],
          },
          {
            value: "doors & door hardware",
            label: "Doors Door Hardware",
            children: [
              {
                value: "weather stripping",
                label: "Weather Stripping",
                isLeaf: true,
              },
              {
                value: "screen doors",
                label: "Screen Doors",
                isLeaf: true,
              },
              {
                value: "latches & bolts",
                label: "Latches Bolts",
                isLeaf: true,
              },
              {
                value: "doors",
                label: "Doors",
                isLeaf: true,
              },
              {
                value: "doorbells",
                label: "Doorbells",
                isLeaf: true,
              },
              {
                value: "door viewers",
                label: "Door Viewers",
                isLeaf: true,
              },
              {
                value: "door strikes",
                label: "Door Strikes",
                isLeaf: true,
              },
              {
                value: "door closers",
                label: "Door Closers",
                isLeaf: true,
              },
              {
                value: "door stops",
                label: "Door Stops",
                isLeaf: true,
              },
              {
                value: "door push plates",
                label: "Door Push Plates",
                isLeaf: true,
              },
              {
                value: "door locks & lock mechanisms",
                label: "Door Locks Lock Mechanisms",
                isLeaf: true,
              },
              {
                value: "door knockers",
                label: "Door Knockers",
                isLeaf: true,
              },
              {
                value: "door knobs & levers",
                label: "Door Knobs Levers",
                isLeaf: true,
              },
              {
                value: "door keyhole covers & escutcheons",
                label: "Door Keyhole Covers Escutcheons",
                isLeaf: true,
              },
              {
                value: "door hinges",
                label: "Door Hinges",
                isLeaf: true,
              },
              {
                value: "door frames",
                label: "Door Frames",
                isLeaf: true,
              },
            ],
          },
          {
            value: "countertops",
            label: "Countertops",
            isLeaf: true,
          },
          {
            value: "consumables",
            label: "Consumables",
            children: [
              {
                value: "glue & adhesives",
                label: "Glue Adhesives",
                isLeaf: true,
              },
              {
                value: "glass",
                label: "Glass",
                isLeaf: true,
              },
              {
                value: "drywall",
                label: "Drywall",
                isLeaf: true,
              },
              {
                value: "chemicals",
                label: "Chemicals",
                isLeaf: true,
              },
              {
                value: "cement, mortar & concrete mixes",
                label: "Cement Mortar Concrete Mixes",
                isLeaf: true,
              },
              {
                value: "bricks & concrete blocks",
                label: "Bricks Concrete Blocks",
                isLeaf: true,
              },
              {
                value: "tape",
                label: "Tape",
                isLeaf: true,
              },
              {
                value: "solvents, strippers & thinners",
                label: "Solvents Strippers Thinners",
                isLeaf: true,
              },
              {
                value: "solder & flux",
                label: "Solder Flux",
                isLeaf: true,
              },
              {
                value: "rebar & remesh",
                label: "Rebar Remesh",
                isLeaf: true,
              },
              {
                value: "protective coatings & sealants",
                label: "Protective Coatings Sealants",
                isLeaf: true,
              },
              {
                value: "plumbing primers",
                label: "Plumbing Primers",
                isLeaf: true,
              },
              {
                value: "plaster & patching compounds",
                label: "Plaster Patching Compounds",
                isLeaf: true,
              },
              {
                value: "paint & varnish",
                label: "Paint Varnish",
                children: [
                  {
                    value: "varnishes & finishes",
                    label: "Varnishes Finishes",
                    isLeaf: true,
                  },
                  {
                    value: "stains",
                    label: "Stains",
                    isLeaf: true,
                  },
                  {
                    value: "paint primers",
                    label: "Paint Primers",
                    isLeaf: true,
                  },
                  {
                    value: "paint binders",
                    label: "Paint Binders",
                    isLeaf: true,
                  },
                  {
                    value: "paint",
                    label: "Paint",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "lubricants",
                label: "Lubricants",
                isLeaf: true,
              },
              {
                value: "insulation",
                label: "Insulation",
                isLeaf: true,
              },
              {
                value: "grout",
                label: "Grout",
                isLeaf: true,
              },
            ],
          },
        ],
      },
      {
        value: "plumbing & fixtures",
        label: "Plumbing Fixtures",
        children: [
          {
            value: "well supplies",
            label: "Well Supplies",
            isLeaf: true,
          },
          {
            value: "water timers",
            label: "Water Timers",
            isLeaf: true,
          },
          {
            value: "water softeners",
            label: "Water Softeners",
            isLeaf: true,
          },
          {
            value: "water levelers",
            label: "Water Levelers",
            isLeaf: true,
          },
          {
            value: "vanities",
            label: "Vanities",
            isLeaf: true,
          },
          {
            value: "valves, fittings & clamps",
            label: "Valves Fittings Clamps",
            isLeaf: true,
          },
          {
            value: "toilets",
            label: "Toilets",
            isLeaf: true,
          },
          {
            value: "toilet parts & accessories",
            label: "Toilet Parts Accessories",
            children: [
              {
                value: "ballcocks & flappers",
                label: "Ballcocks Flappers",
                isLeaf: true,
              },
              {
                value: "toilet trim",
                label: "Toilet Trim",
                isLeaf: true,
              },
              {
                value: "toilet tanks",
                label: "Toilet Tanks",
                isLeaf: true,
              },
              {
                value: "toilet tank levers",
                label: "Toilet Tank Levers",
                isLeaf: true,
              },
              {
                value: "toilet & bidet seats",
                label: "Toilet Bidet Seats",
                isLeaf: true,
              },
              {
                value: "bidet faucets & sprayers",
                label: "Bidet Faucets Sprayers",
                isLeaf: true,
              },
            ],
          },
          {
            value: "sinks",
            label: "Sinks",
            isLeaf: true,
          },
          {
            value: "shower stalls",
            label: "Shower Stalls",
            isLeaf: true,
          },
          {
            value: "bathroom suites",
            label: "Bathroom Suites",
            isLeaf: true,
          },
          {
            value: "shower parts & accessories",
            label: "Shower Parts Accessories",
            children: [
              {
                value: "bathtub & shower jets",
                label: "Bathtub Shower Jets",
                isLeaf: true,
              },
              {
                value: "shower walls & surrounds",
                label: "Shower Walls Surrounds",
                isLeaf: true,
              },
              {
                value: "shower heads",
                label: "Shower Heads",
                isLeaf: true,
              },
              {
                value: "shower doors & enclosures",
                label: "Shower Doors Enclosures",
                isLeaf: true,
              },
              {
                value: "shower columns",
                label: "Shower Columns",
                isLeaf: true,
              },
              {
                value: "shower bases",
                label: "Shower Bases",
                isLeaf: true,
              },
              {
                value: "shower arms & connectors",
                label: "Shower Arms Connectors",
                isLeaf: true,
              },
              {
                value: "electric & power showers",
                label: "Electric Power Showers",
                isLeaf: true,
              },
            ],
          },
          {
            value: "pumps",
            label: "Pumps",
            isLeaf: true,
          },
          {
            value: "pipes",
            label: "Pipes",
            isLeaf: true,
          },
          {
            value: "garbage disposals",
            label: "Garbage Disposals",
            isLeaf: true,
          },
          {
            value: "faucets",
            label: "Faucets",
            isLeaf: true,
          },
          {
            value: "drains",
            label: "Drains",
            isLeaf: true,
          },
          {
            value: "bathtubs",
            label: "Bathtubs",
            isLeaf: true,
          },
        ],
      },
      {
        value: "tools",
        label: "Tools",
        children: [
          {
            value: "masonry tools",
            label: "Masonry Tools",
            children: [
              {
                value: "brick tools",
                label: "Brick Tools",
                isLeaf: true,
              },
              {
                value: "power trowels",
                label: "Power Trowels",
                isLeaf: true,
              },
              {
                value: "masonry trowels",
                label: "Masonry Trowels",
                isLeaf: true,
              },
              {
                value: "masonry jointers",
                label: "Masonry Jointers",
                isLeaf: true,
              },
              {
                value: "masonry edgers & groovers",
                label: "Masonry Edgers Groovers",
                isLeaf: true,
              },
              {
                value: "grout sponges",
                label: "Grout Sponges",
                isLeaf: true,
              },
              {
                value: "floats",
                label: "Floats",
                isLeaf: true,
              },
              {
                value: "construction lines",
                label: "Construction Lines",
                isLeaf: true,
              },
              {
                value: "cement mixers",
                label: "Cement Mixers",
                isLeaf: true,
              },
            ],
          },
          {
            value: "miscellaneous tools",
            label: "Miscellaneous Tools",
            children: [
              {
                value: "blowtorches",
                label: "Blowtorches",
                isLeaf: true,
              },
              {
                value: "anvils",
                label: "Anvils",
                isLeaf: true,
              },
              {
                value: "caulk & sealant",
                label: "Caulk Sealant",
                isLeaf: true,
              },
              {
                value: "work lights",
                label: "Work Lights",
                isLeaf: true,
              },
              {
                value: "work benches",
                label: "Work Benches",
                isLeaf: true,
              },
              {
                value: "wet/dry & shop vacuums",
                label: "Wet Dry Shop Vacuums",
                isLeaf: true,
              },
              {
                value: "welding & soldering tools",
                label: "Welding Soldering Tools",
                isLeaf: true,
              },
              {
                value: "tool boxes, belts & storage",
                label: "Tool Boxes Belts Storage",
                isLeaf: true,
              },
              {
                value: "socket drivers",
                label: "Socket Drivers",
                isLeaf: true,
              },
              {
                value: "shapers",
                label: "Shapers",
                isLeaf: true,
              },
              {
                value: "saw horses",
                label: "Saw Horses",
                isLeaf: true,
              },
              {
                value: "sanding blocks",
                label: "Sanding Blocks",
                isLeaf: true,
              },
              {
                value: "rivet guns & pliers",
                label: "Rivet Guns Pliers",
                isLeaf: true,
              },
              {
                value: "lighters & matches",
                label: "Lighters Matches",
                isLeaf: true,
              },
              {
                value: "reamers",
                label: "Reamers",
                isLeaf: true,
              },
              {
                value: "pipe & bar benders",
                label: "Pipe Bar Benders",
                isLeaf: true,
              },
              {
                value: "milling machines",
                label: "Milling Machines",
                isLeaf: true,
              },
              {
                value: "marking tools",
                label: "Marking Tools",
                isLeaf: true,
              },
              {
                value: "magnetizers & demagnetizers",
                label: "Magnetizers Demagnetizers",
                isLeaf: true,
              },
              {
                value: "magnetic sweepers",
                label: "Magnetic Sweepers",
                isLeaf: true,
              },
              {
                value: "light bulb changers",
                label: "Light Bulb Changers",
                isLeaf: true,
              },
              {
                value: "inspection mirrors",
                label: "Inspection Mirrors",
                isLeaf: true,
              },
              {
                value: "handheld power mixers",
                label: "Handheld Power Mixers",
                isLeaf: true,
              },
              {
                value: "flashlights",
                label: "Flashlights",
                isLeaf: true,
              },
              {
                value: "dollies & hand trucks",
                label: "Dollies Hand Trucks",
                isLeaf: true,
              },
              {
                value: "deburrers",
                label: "Deburrers",
                isLeaf: true,
              },
              {
                value: "concrete brooms",
                label: "Concrete Brooms",
                isLeaf: true,
              },
              {
                value: "compactors",
                label: "Compactors",
                isLeaf: true,
              },
            ],
          },
          {
            value: "ladders & scaffolding",
            label: "Ladders Scaffolding",
            children: [
              {
                value: "ladder carts",
                label: "Ladder Carts",
                isLeaf: true,
              },
              {
                value: "work platforms",
                label: "Work Platforms",
                isLeaf: true,
              },
              {
                value: "step stools",
                label: "Step Stools",
                isLeaf: true,
              },
              {
                value: "scaffolding",
                label: "Scaffolding",
                isLeaf: true,
              },
              {
                value: "ladders",
                label: "Ladders",
                isLeaf: true,
              },
            ],
          },
          {
            value: "painting tools",
            label: "Painting Tools",
            children: [
              {
                value: "caulking guns",
                label: "Caulking Guns",
                isLeaf: true,
              },
              {
                value: "painter's tape",
                label: "Painters Tape",
                isLeaf: true,
              },
              {
                value: "paint trays",
                label: "Paint Trays",
                isLeaf: true,
              },
              {
                value: "paint sprayers",
                label: "Paint Sprayers",
                isLeaf: true,
              },
              {
                value: "paint rollers",
                label: "Paint Rollers",
                isLeaf: true,
              },
              {
                value: "paint mixers",
                label: "Paint Mixers",
                isLeaf: true,
              },
              {
                value: "paint buckets",
                label: "Paint Buckets",
                isLeaf: true,
              },
              {
                value: "paint brushes",
                label: "Paint Brushes",
                isLeaf: true,
              },
              {
                value: "extension poles",
                label: "Extension Poles",
                isLeaf: true,
              },
              {
                value: "drop cloths",
                label: "Drop Cloths",
                isLeaf: true,
              },
              {
                value: "cleaning solutions",
                label: "Cleaning Solutions",
                isLeaf: true,
              },
            ],
          },
          {
            value: "power tools",
            label: "Power Tools",
            children: [
              {
                value: "winches",
                label: "Winches",
                isLeaf: true,
              },
              {
                value: "sharpeners",
                label: "Sharpeners",
                isLeaf: true,
              },
              {
                value: "screwdrivers",
                label: "Screwdrivers",
                isLeaf: true,
              },
              {
                value: "saws & blades",
                label: "Saws Blades",
                isLeaf: true,
              },
              {
                value: "sanders",
                label: "Sanders",
                isLeaf: true,
              },
              {
                value: "routers",
                label: "Routers",
                isLeaf: true,
              },
              {
                value: "router tables",
                label: "Router Tables",
                isLeaf: true,
              },
              {
                value: "router bits",
                label: "Router Bits",
                isLeaf: true,
              },
              {
                value: "rotary tools",
                label: "Rotary Tools",
                isLeaf: true,
              },
              {
                value: "power tool parts & accessories",
                label: "Power Tool Parts Accessories",
                isLeaf: true,
              },
              {
                value: "planers",
                label: "Planers",
                isLeaf: true,
              },
              {
                value: "nail & staple guns",
                label: "Nail Staple Guns",
                isLeaf: true,
              },
              {
                value: "mixed power tool sets",
                label: "Mixed Power Tool Sets",
                isLeaf: true,
              },
              {
                value: "lathes",
                label: "Lathes",
                isLeaf: true,
              },
              {
                value: "joiners",
                label: "Joiners",
                isLeaf: true,
              },
              {
                value: "jigs",
                label: "Jigs",
                isLeaf: true,
              },
              {
                value: "buffers & polishers",
                label: "Buffers Polishers",
                isLeaf: true,
              },
              {
                value: "jacks & stands",
                label: "Jacks Stands",
                isLeaf: true,
              },
              {
                value: "impact drivers & wrenches",
                label: "Impact Drivers Wrenches",
                isLeaf: true,
              },
              {
                value: "heat guns",
                label: "Heat Guns",
                isLeaf: true,
              },
              {
                value: "grinders",
                label: "Grinders",
                isLeaf: true,
              },
              {
                value: "drills",
                label: "Drills",
                isLeaf: true,
              },
            ],
          },
          {
            value: "measuring tools & sensors",
            label: "Measuring Tools Sensors",
            children: [
              {
                value: "ph meters",
                label: "Ph Meters",
                isLeaf: true,
              },
              {
                value: "weather forecasters & stations",
                label: "Weather Forecasters Stations",
                isLeaf: true,
              },
              {
                value: "vibration meters",
                label: "Vibration Meters",
                isLeaf: true,
              },
              {
                value: "uv light meters",
                label: "Uv Light Meters",
                isLeaf: true,
              },
              {
                value: "transducers",
                label: "Transducers",
                isLeaf: true,
              },
              {
                value: "thermocouples & thermopiles",
                label: "Thermocouples Thermopiles",
                isLeaf: true,
              },
              {
                value: "thermal imaging cameras",
                label: "Thermal Imaging Cameras",
                isLeaf: true,
              },
              {
                value: "theodolites",
                label: "Theodolites",
                isLeaf: true,
              },
              {
                value: "tape measures",
                label: "Tape Measures",
                isLeaf: true,
              },
              {
                value: "stud finders",
                label: "Stud Finders",
                isLeaf: true,
              },
              {
                value: "sound meters",
                label: "Sound Meters",
                isLeaf: true,
              },
              {
                value: "seismometer",
                label: "Seismometer",
                isLeaf: true,
              },
              {
                value: "rulers & squares",
                label: "Rulers Squares",
                isLeaf: true,
              },
              {
                value: "rebar locators",
                label: "Rebar Locators",
                isLeaf: true,
              },
              {
                value: "protractors",
                label: "Protractors",
                isLeaf: true,
              },
              {
                value: "probes & finders",
                label: "Probes Finders",
                isLeaf: true,
              },
              {
                value: "multimeters",
                label: "Multimeters",
                isLeaf: true,
              },
              {
                value: "moisture meters",
                label: "Moisture Meters",
                isLeaf: true,
              },
              {
                value: "measuring wheels",
                label: "Measuring Wheels",
                isLeaf: true,
              },
              {
                value: "measuring scales",
                label: "Measuring Scales",
                isLeaf: true,
              },
              {
                value: "levels",
                label: "Levels",
                isLeaf: true,
              },
              {
                value: "knife guides",
                label: "Knife Guides",
                isLeaf: true,
              },
              {
                value: "infrared thermometers",
                label: "Infrared Thermometers",
                isLeaf: true,
              },
              {
                value: "air quality meters",
                label: "Air Quality Meters",
                isLeaf: true,
              },

              {
                value: "altimeters",
                label: "Altimeters",
                isLeaf: true,
              },
              {
                value: "hygrometers",
                label: "Hygrometers",
                isLeaf: true,
              },
              {
                value: "geiger counters",
                label: "Geiger Counters",
                isLeaf: true,
              },
              {
                value: "gauges",
                label: "Gauges",
                isLeaf: true,
              },
              {
                value: "gas detectors",
                label: "Gas Detectors",
                isLeaf: true,
              },
              {
                value: "flow meters & controllers",
                label: "Flow Meters Controllers",
                isLeaf: true,
              },
              {
                value: "electrical testing tools",
                label: "Electrical Testing Tools",
                isLeaf: true,
              },
              {
                value: "dividers",
                label: "Dividers",
                isLeaf: true,
              },
              {
                value: "distance meters",
                label: "Distance Meters",
                isLeaf: true,
              },
              {
                value: "cruising rods",
                label: "Cruising Rods",
                isLeaf: true,
              },
              {
                value: "calipers",
                label: "Calipers",
                isLeaf: true,
              },
              {
                value: "barometers",
                label: "Barometers",
                isLeaf: true,
              },
              {
                value: "anemometers",
                label: "Anemometers",
                isLeaf: true,
              },
            ],
          },
          {
            value: "air tools",
            label: "Air Tools",
            children: [
              {
                value: "air compressors",
                label: "Air Compressors",
                isLeaf: true,
              },
              {
                value: "wrenches",
                label: "Wrenches",
                isLeaf: true,
              },
              {
                value: "sanders",
                label: "Sanders",
                isLeaf: true,
              },
              {
                value: "sandblasters",
                label: "Sandblasters",
                isLeaf: true,
              },
              {
                value: "ratchets",
                label: "Ratchets",
                isLeaf: true,
              },
              {
                value: "nailers",
                label: "Nailers",
                isLeaf: true,
              },
              {
                value: "hammers",
                label: "Hammers",
                isLeaf: true,
              },
              {
                value: "grinders",
                label: "Grinders",
                isLeaf: true,
              },
              {
                value: "grease & sealant guns",
                label: "Grease Sealant Guns",
                isLeaf: true,
              },
              {
                value: "drills",
                label: "Drills",
                isLeaf: true,
              },
              {
                value: "cut-off tools",
                label: "Cut Off Tools",
                isLeaf: true,
              },
              {
                value: "air tool parts & accessories",
                label: "Air Tool Parts Accessories",
                isLeaf: true,
              },
              {
                value: "air tool kits",
                label: "Air Tool Kits",
                isLeaf: true,
              },
            ],
          },
          {
            value: "hand tools",
            label: "Hand Tools",
            children: [
              {
                value: "wrenches",
                label: "Wrenches",
                isLeaf: true,
              },
              {
                value: "winches",
                label: "Winches",
                isLeaf: true,
              },
              {
                value: "trowels",
                label: "Trowels",
                isLeaf: true,
              },
              {
                value: "tool keys",
                label: "Tool Keys",
                isLeaf: true,
              },
              {
                value: "taps & dies",
                label: "Taps Dies",
                isLeaf: true,
              },
              {
                value: "screwdrivers",
                label: "Screwdrivers",
                isLeaf: true,
              },
              {
                value: "saws",
                label: "Saws",
                isLeaf: true,
              },
              {
                value: "putty knives & scrapers",
                label: "Putty Knives Scrapers",
                isLeaf: true,
              },
              {
                value: "punches",
                label: "Punches",
                isLeaf: true,
              },
              {
                value: "chisels",
                label: "Chisels",
                isLeaf: true,
              },
              {
                value: "pry bars",
                label: "Pry Bars",
                isLeaf: true,
              },
              {
                value: "pliers",
                label: "Pliers",
                isLeaf: true,
              },
              {
                value: "planes",
                label: "Planes",
                isLeaf: true,
              },
              {
                value: "pickup tools",
                label: "Pickup Tools",
                isLeaf: true,
              },
              {
                value: "multitools",
                label: "Multitools",
                isLeaf: true,
              },
              {
                value: "mixed hand tool sets",
                label: "Mixed Hand Tool Sets",
                isLeaf: true,
              },
              {
                value: "knives & blades",
                label: "Knives Blades",
                isLeaf: true,
              },
              {
                value: "hand tool parts & accessories",
                label: "Hand Tool Parts Accessories",
                isLeaf: true,
              },
              {
                value: "hammers & mallets",
                label: "Hammers Mallets",
                isLeaf: true,
              },
              {
                value: "files",
                label: "Files",
                isLeaf: true,
              },
              {
                value: "cutters",
                label: "Cutters",
                isLeaf: true,
              },
              {
                value: "clamps & vises",
                label: "Clamps Vises",
                isLeaf: true,
              },
            ],
          },
        ],
      },
    ],
  },

  {
    value: "sporting goods",
    label: "Sporting Goods",
    children: [
      {
        value: "emergency preparedness",
        label: "Emergency Preparedness",
        children: [
          {
            value: "earthquake alarms",
            label: "Earthquake Alarms",
            isLeaf: true,
          },
          {
            value: "emergency tools & kits",
            label: "Emergency Tools Kits",
            isLeaf: true,
          },
          {
            value: "emergency food kits",
            label: "Emergency Food Kits",
            isLeaf: true,
          },
          {
            value: "emergency blankets",
            label: "Emergency Blankets",
            isLeaf: true,
          },
        ],
      },
      {
        value: "sports equipment",
        label: "Sports Equipment",
        children: [
          {
            value: "wrestling",
            label: "Wrestling",
            children: [
              {
                value: "wrestling mats",
                label: "Wrestling Mats",
                isLeaf: true,
              },
              {
                value: "clothing & shoes",
                label: "Clothing Shoes",
                isLeaf: true,
              },
              {
                value: "protective gear",
                label: "Protective Gear",
                children: [
                  {
                    value: "knee pads",
                    label: "Knee Pads",
                    isLeaf: true,
                  },
                  {
                    value: "headgear",
                    label: "Headgear",
                    isLeaf: true,
                  },
                ],
              },
            ],
          },
          {
            value: "ice skating",
            label: "Ice Skating",
            children: [
              {
                value: "protective gear",
                label: "Protective Gear",
                children: [
                  {
                    value: "chest protectors",
                    label: "Chest Protectors",
                    isLeaf: true,
                  },
                  {
                    value: "knee & elbow guards",
                    label: "Knee Elbow Guards",
                    isLeaf: true,
                  },
                  {
                    value: "headgear",
                    label: "Headgear",
                    isLeaf: true,
                  },
                  {
                    value: "hand wraps",
                    label: "Hand Wraps",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "blade guards",
                label: "Blade Guards",
                isLeaf: true,
              },
              {
                value: "protective gear",
                label: "Protective Gear",
                isLeaf: true,
              },
              {
                value: "lace tighteners",
                label: "Lace Tighteners",
                isLeaf: true,
              },
              {
                value: "ice skates",
                label: "Ice Skates",
                isLeaf: true,
              },
              {
                value: "ice skate sharpeners",
                label: "Ice Skate Sharpeners",
                isLeaf: true,
              },
              {
                value: "ice skate blades",
                label: "Ice Skate Blades",
                isLeaf: true,
              },
              {
                value: "figure skating boots",
                label: "Figure Skating Boots",
                isLeaf: true,
              },
              {
                value: "clothing",
                label: "Clothing",
                isLeaf: true,
              },
            ],
          },
          {
            value: "gymnastics",
            label: "Gymnastics",
            children: [
              {
                value: "bars & balance beams",
                label: "Bars Balance Beams",
                isLeaf: true,
              },
              {
                value: "vaulting & pommel horses",
                label: "Vaulting Pommel Horses",
                isLeaf: true,
              },
              {
                value: "springboards",
                label: "Springboards",
                isLeaf: true,
              },
              {
                value: "gymnastics rings",
                label: "Gymnastics Rings",
                isLeaf: true,
              },
              {
                value: "gymnastics grips",
                label: "Gymnastics Grips",
                isLeaf: true,
              },
              {
                value: "gymnastics bars",
                label: "Gymnastics Bars",
                isLeaf: true,
              },
              {
                value: "floor systems",
                label: "Floor Systems",
                isLeaf: true,
              },
            ],
          },
          {
            value: "general purpose equipment",
            label: "General Purpose Equipment",
            children: [
              {
                value: "athletic cups",
                label: "Athletic Cups",
                isLeaf: true,
              },
              {
                value: "stopwatches",
                label: "Stopwatches",
                isLeaf: true,
              },
              {
                value: "stadium seats & cushions",
                label: "Stadium Seats Cushions",
                isLeaf: true,
              },
              {
                value: "speed & agility ladders, hurdles & cones",
                label: "Speed Agility Ladders Hurdles Cones",
                isLeaf: true,
              },
              {
                value: "reflective gear",
                label: "Reflective Gear",
                isLeaf: true,
              },
              {
                value: "mouth guards",
                label: "Mouth Guards",
                isLeaf: true,
              },
              {
                value: "gym mats",
                label: "Gym Mats",
                isLeaf: true,
              },
              {
                value: "grip spray & chalk",
                label: "Grip Spray Chalk",
                isLeaf: true,
              },
              {
                value: "fitness & activity monitors",
                label: "Fitness Activity Monitors",
                isLeaf: true,
              },
              {
                value: "exercise mat storage racks",
                label: "Exercise Mat Storage Racks",
                isLeaf: true,
              },
              {
                value: "elevation training masks",
                label: "Elevation Training Masks",
                isLeaf: true,
              },
              {
                value: "ball pumps",
                label: "Ball Pumps",
                isLeaf: true,
              },
              {
                value: "ball carrying carts",
                label: "Ball Carrying Carts",
                isLeaf: true,
              },
            ],
          },
          {
            value: "coaching & referee gear",
            label: "Coaching Referee Gear",
            children: [
              {
                value: "captains armbands",
                label: "Captains Armbands",
                isLeaf: true,
              },
              {
                value: "whistles",
                label: "Whistles",
                isLeaf: true,
              },
              {
                value: "uniforms",
                label: "Uniforms",
                isLeaf: true,
              },
              {
                value: "score boards & cards",
                label: "Score Boards Cards",
                isLeaf: true,
              },
              {
                value: "referee wallets",
                label: "Referee Wallets",
                isLeaf: true,
              },
              {
                value: "referee stands & chairs",
                label: "Referee Stands Chairs",
                isLeaf: true,
              },
              {
                value: "penalty cards & flags",
                label: "Penalty Cards Flags",
                isLeaf: true,
              },
              {
                value: "megaphones",
                label: "Megaphones",
                isLeaf: true,
              },
              {
                value: "linesman flags & boundary markers",
                label: "Linesman Flags Boundary Markers",
                isLeaf: true,
              },
              {
                value: "flip coins & discs",
                label: "Flip Coins Discs",
                isLeaf: true,
              },
            ],
          },
          {
            value: "cheerleading",
            label: "Cheerleading",
            children: [
              {
                value: "clothing & accessories",
                label: "Clothing Accessories",
                children: [
                  {
                    value: "hair accessories",
                    label: "Hair Accessories",
                    isLeaf: true,
                  },
                  {
                    value: "training equipment",
                    label: "Training Equipment",
                    isLeaf: true,
                  },
                  {
                    value: "shoes",
                    label: "Shoes",
                    isLeaf: true,
                  },
                  {
                    value: "pom poms",
                    label: "Pom Poms",
                    isLeaf: true,
                  },
                  {
                    value: "outfits",
                    label: "Outfits",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "training equipment",
                label: "Training Equipment",
                children: [
                  {
                    value: "floor systems & tracks",
                    label: "Floor Systems Tracks",
                    isLeaf: true,
                  },
                  {
                    value: "training shapes",
                    label: "Training Shapes",
                    isLeaf: true,
                  },
                  {
                    value: "spring boards & trampolines",
                    label: "Spring Boards Trampolines",
                    isLeaf: true,
                  },
                  {
                    value: "safety pits",
                    label: "Safety Pits",
                    isLeaf: true,
                  },
                  {
                    value: "landing mats",
                    label: "Landing Mats",
                    isLeaf: true,
                  },
                ],
              },
            ],
          },
          {
            value: "broomball",
            label: "Broomball",
            children: [
              {
                value: "broomball brooms",
                label: "Broomball Brooms",
                isLeaf: true,
              },
              {
                value: "protective gear",
                label: "Protective Gear",
                children: [
                  {
                    value: "chest protectors",
                    label: "Chest Protectors",
                    isLeaf: true,
                  },
                  {
                    value: "shin guards",
                    label: "Shin Guards",
                    isLeaf: true,
                  },
                  {
                    value: "knee & elbow guards",
                    label: "Knee Elbow Guards",
                    isLeaf: true,
                  },
                  {
                    value: "helmets",
                    label: "Helmets",
                    isLeaf: true,
                  },
                  {
                    value: "goalie masks",
                    label: "Goalie Masks",
                    isLeaf: true,
                  },
                  {
                    value: "gloves",
                    label: "Gloves",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "clothing & shoes",
                label: "Clothing Shoes",
                isLeaf: true,
              },
              {
                value: "broomballs",
                label: "Broomballs",
                isLeaf: true,
              },
            ],
          },
          {
            value: "ice & roller hockey",
            label: "Ice Roller Hockey",
            children: [
              {
                value: "protective gear",
                label: "Protective Gear",
                children: [
                  {
                    value: "chest protectors",
                    label: "Chest Protectors",
                    isLeaf: true,
                  },
                  {
                    value: "suspenders & belts",
                    label: "Suspenders Belts",
                    isLeaf: true,
                  },
                  {
                    value: "shin guards",
                    label: "Shin Guards",
                    isLeaf: true,
                  },
                  {
                    value: "pants",
                    label: "Pants",
                    isLeaf: true,
                  },
                  {
                    value: "helmets",
                    label: "Helmets",
                    isLeaf: true,
                  },
                  {
                    value: "goalie equipment",
                    label: "Goalie Equipment",
                    isLeaf: true,
                  },
                  {
                    value: "gloves",
                    label: "Gloves",
                    isLeaf: true,
                  },
                  {
                    value: "elbow pads",
                    label: "Elbow Pads",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "clothing & shoes",
                label: "Clothing Shoes",
                isLeaf: true,
              },
              {
                value: "hockey sticks",
                label: "Hockey Sticks",
                isLeaf: true,
              },
              {
                value: "hockey sledges",
                label: "Hockey Sledges",
                isLeaf: true,
              },
              {
                value: "hockey skates",
                label: "Hockey Skates",
                isLeaf: true,
              },
              {
                value: "hockey goals",
                label: "Hockey Goals",
                isLeaf: true,
              },
              {
                value: "hockey balls & pucks",
                label: "Hockey Balls Pucks",
                isLeaf: true,
              },
            ],
          },
          {
            value: "rugby",
            label: "Rugby",
            children: [
              {
                value: "training aids",
                label: "Training Aids",
                isLeaf: true,
              },
              {
                value: "rugby posts",
                label: "Rugby Posts",
                isLeaf: true,
              },
              {
                value: "jerseys & cleats",
                label: "Jerseys Cleats",
                isLeaf: true,
              },
              {
                value: "protective gear",
                label: "Protective Gear",
                children: [
                  {
                    value: "rugby gloves",
                    label: "Rugby Gloves",
                    isLeaf: true,
                  },
                ],
              },
            ],
          },
          {
            value: "tennis & racquet sports",
            label: "Tennis Racquet Sports",
            children: [
              {
                value: "tennis",
                label: "Tennis",
                children: [
                  {
                    value: "tennis bags",
                    label: "Tennis Bags",
                    isLeaf: true,
                  },
                  {
                    value: "discus",
                    label: "Discus",
                    isLeaf: true,
                  },
                  {
                    value: "tennis racquets",
                    label: "Tennis Racquets",
                    isLeaf: true,
                  },
                  {
                    value: "tennis nets",
                    label: "Tennis Nets",
                    isLeaf: true,
                  },
                  {
                    value: "tennis balls",
                    label: "Tennis Balls",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "racquetball & squash",
                label: "Racquetball Squash",
                children: [
                  {
                    value: "racquetball & squash balls",
                    label: "Racquetball Squash Balls",
                    isLeaf: true,
                  },
                  {
                    value: "racquetball & squash racquets",
                    label: "Racquetball Squash Racquets",
                    isLeaf: true,
                  },
                  {
                    value: "racquetball & squash goggles",
                    label: "Racquetball Squash Goggles",
                    isLeaf: true,
                  },
                  {
                    value: "racquetball & squash gloves",
                    label: "Racquetball Squash Gloves",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "pickleball & paddle tennis equipment",
                label: "Pickleball Paddle Tennis Equipment",
                isLeaf: true,
              },
              {
                value: "clothing & shoes",
                label: "Clothing Shoes",
                isLeaf: true,
              },
              {
                value: "ball & racquet accessories",
                label: "Ball Racquet Accessories",
                children: [
                  {
                    value: "racquet accessories",
                    label: "Racquet Accessories",
                    isLeaf: true,
                  },
                  {
                    value: "tennis ball savers",
                    label: "Tennis Ball Savers",
                    isLeaf: true,
                  },
                  {
                    value: "tennis ball machines",
                    label: "Tennis Ball Machines",
                    isLeaf: true,
                  },
                  {
                    value: "tennis ball hoppers & carts",
                    label: "Tennis Ball Hoppers Carts",
                    isLeaf: true,
                  },
                  {
                    value: "racquet stringing machines",
                    label: "Racquet Stringing Machines",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "badminton",
                label: "Badminton",
                children: [
                  {
                    value: "badminton nets",
                    label: "Badminton Nets",
                    isLeaf: true,
                  },
                  {
                    value: "ball & racquet accessories",
                    label: "Ball Racquet Accessories",
                    isLeaf: true,
                  },
                  {
                    value: "shuttlecocks",
                    label: "Shuttlecocks",
                    isLeaf: true,
                  },
                  {
                    value: "badminton racquets",
                    label: "Badminton Racquets",
                    isLeaf: true,
                  },
                ],
              },
            ],
          },
          {
            value: "volleyball",
            label: "Volleyball",
            children: [
              {
                value: "volleyballs",
                label: "Volleyballs",
                isLeaf: true,
              },
              {
                value: "volleyball nets & poles",
                label: "Volleyball Nets Poles",
                isLeaf: true,
              },
              {
                value: "volleyball accessories",
                label: "Volleyball Accessories",
                children: [
                  {
                    value: "carts & hammocks",
                    label: "Carts Hammocks",
                    isLeaf: true,
                  },
                  {
                    value: "volleyball bags",
                    label: "Volleyball Bags",
                    isLeaf: true,
                  },
                  {
                    value: "training equipment",
                    label: "Training Equipment",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "protective gear",
                label: "Protective Gear",
                children: [
                  {
                    value: "braces & supports",
                    label: "Braces Supports",
                    isLeaf: true,
                  },
                  {
                    value: "knee pads",
                    label: "Knee Pads",
                    isLeaf: true,
                  },
                ],
              },
            ],
          },
          {
            value: "golf",
            label: "Golf",
            children: [
              {
                value: "golf training aids",
                label: "Golf Training Aids",
                children: [
                  {
                    value: "golf nets, cages & mats",
                    label: "Golf Nets Cages Mats",
                    isLeaf: true,
                  },
                  {
                    value: "swing trainers",
                    label: "Swing Trainers",
                    isLeaf: true,
                  },
                  {
                    value: "putting greens",
                    label: "Putting Greens",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "golf training aids",
                label: "Golf Training Aids",
                isLeaf: true,
              },
              {
                value: "golf clubs",
                label: "Golf Clubs",
                isLeaf: true,
              },
              {
                value: "golf club parts & accessories",
                label: "Golf Club Parts Accessories",
                children: [
                  {
                    value: "golf club grips",
                    label: "Golf Club Grips",
                    isLeaf: true,
                  },
                  {
                    value: "golf club shafts",
                    label: "Golf Club Shafts",
                    isLeaf: true,
                  },
                  {
                    value: "golf club heads",
                    label: "Golf Club Heads",
                    isLeaf: true,
                  },
                  {
                    value: "golf club headcovers",
                    label: "Golf Club Headcovers",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "golf balls",
                label: "Golf Balls",
                isLeaf: true,
              },
              {
                value: "golf ball accessories",
                label: "Golf Ball Accessories",
                children: [
                  {
                    value: "golf ball markers",
                    label: "Golf Ball Markers",
                    isLeaf: true,
                  },
                  {
                    value: "golf ball tags",
                    label: "Golf Ball Tags",
                    isLeaf: true,
                  },
                  {
                    value: "golf ball pickups & retrievers",
                    label: "Golf Ball Pickups Retrievers",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "golf accessories",
                label: "Golf Accessories",
                children: [
                  {
                    value: "golf accessory sets",
                    label: "Golf Accessory Sets",
                    isLeaf: true,
                  },
                  {
                    value: "golf umbrellas",
                    label: "Golf Umbrellas",
                    isLeaf: true,
                  },
                  {
                    value: "golf towels",
                    label: "Golf Towels",
                    isLeaf: true,
                  },
                  {
                    value: "golf tees",
                    label: "Golf Tees",
                    isLeaf: true,
                  },
                  {
                    value: "golf stroke counters",
                    label: "Golf Stroke Counters",
                    isLeaf: true,
                  },
                  {
                    value: "golf rangefinders",
                    label: "Golf Rangefinders",
                    isLeaf: true,
                  },
                  {
                    value: "golf push carts",
                    label: "Golf Push Carts",
                    isLeaf: true,
                  },
                  {
                    value: "golf groove sharpeners",
                    label: "Golf Groove Sharpeners",
                    isLeaf: true,
                  },
                  {
                    value: "golf gloves",
                    label: "Golf Gloves",
                    isLeaf: true,
                  },
                  {
                    value: "golf flags",
                    label: "Golf Flags",
                    isLeaf: true,
                  },
                  {
                    value: "golf divot tools",
                    label: "Golf Divot Tools",
                    isLeaf: true,
                  },
                  {
                    value: "golf bags",
                    label: "Golf Bags",
                    isLeaf: true,
                  },
                  {
                    value: "golf bag covers",
                    label: "Golf Bag Covers",
                    isLeaf: true,
                  },
                ],
              },
            ],
          },
          {
            value: "soccer",
            label: "Soccer",
            children: [
              {
                value: "training equipment",
                label: "Training Equipment",
                isLeaf: true,
              },
              {
                value: "soccer goals & nets",
                label: "Soccer Goals Nets",
                isLeaf: true,
              },
              {
                value: "soccer balls",
                label: "Soccer Balls",
                isLeaf: true,
              },
              {
                value: "jerseys & cleats",
                label: "Jerseys Cleats",
                isLeaf: true,
              },
              {
                value: "protective gear",
                label: "Protective Gear",
                children: [
                  {
                    value: "goalkeeper bottoms",
                    label: "Goalkeeper Bottoms",
                    isLeaf: true,
                  },
                  {
                    value: "soccer gloves",
                    label: "Soccer Gloves",
                    isLeaf: true,
                  },
                  {
                    value: "shin guards",
                    label: "Shin Guards",
                    isLeaf: true,
                  },
                  {
                    value: "headguards",
                    label: "Headguards",
                    isLeaf: true,
                  },
                  {
                    value: "goalkeeper tops",
                    label: "Goalkeeper Tops",
                    isLeaf: true,
                  },
                ],
              },
            ],
          },
          {
            value: "football",
            label: "Football",
            children: [
              {
                value: "protective gear",
                label: "Protective Gear",
                children: [
                  {
                    value: "football pants",
                    label: "Football Pants",
                    isLeaf: true,
                  },
                  {
                    value: "shoulder pads",
                    label: "Shoulder Pads",
                    isLeaf: true,
                  },
                  {
                    value: "rib protection shirts",
                    label: "Rib Protection Shirts",
                    isLeaf: true,
                  },
                  {
                    value: "neck rolls",
                    label: "Neck Rolls",
                    isLeaf: true,
                  },
                  {
                    value: "helmets",
                    label: "Helmets",
                    isLeaf: true,
                  },
                  {
                    value: "girdles",
                    label: "Girdles",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "clothing & shoes",
                label: "Clothing Shoes",
                isLeaf: true,
              },
              {
                value: "footballs",
                label: "Footballs",
                isLeaf: true,
              },
              {
                value: "football kicking tees",
                label: "Football Kicking Tees",
                isLeaf: true,
              },
              {
                value: "football goal posts",
                label: "Football Goal Posts",
                isLeaf: true,
              },
              {
                value: "football gloves",
                label: "Football Gloves",
                isLeaf: true,
              },
              {
                value: "football dummies & sleds",
                label: "Football Dummies Sleds",
                isLeaf: true,
              },
            ],
          },
          {
            value: "track & field",
            label: "Track Field",
            children: [
              {
                value: "high jump crossbars",
                label: "High Jump Crossbars",
                isLeaf: true,
              },
              {
                value: "vaulting poles",
                label: "Vaulting Poles",
                isLeaf: true,
              },
              {
                value: "track starting blocks",
                label: "Track Starting Blocks",
                isLeaf: true,
              },
              {
                value: "track hurdles",
                label: "Track Hurdles",
                isLeaf: true,
              },
              {
                value: "track & field pits",
                label: "Track Field Pits",
                isLeaf: true,
              },
              {
                value: "track & field accessories",
                label: "Track Field Accessories",
                isLeaf: true,
              },

              {
                value: "throwing hammers",
                label: "Throwing Hammers",
                isLeaf: true,
              },
              {
                value: "shot puts",
                label: "Shot Puts",
                isLeaf: true,
              },
              {
                value: "javelins",
                label: "Javelins",
                isLeaf: true,
              },
            ],
          },
          {
            value: "cricket",
            label: "Cricket",
            children: [
              {
                value: "protective gear",
                label: "Protective Gear",
                children: [
                  {
                    value: "chest & leg guards",
                    label: "Chest Leg Guards",
                    isLeaf: true,
                  },
                  {
                    value: "helmets",
                    label: "Helmets",
                    isLeaf: true,
                  },
                  {
                    value: "gloves",
                    label: "Gloves",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "clothing & shoes",
                label: "Clothing Shoes",
                isLeaf: true,
              },
              {
                value: "cricket stumps",
                label: "Cricket Stumps",
                isLeaf: true,
              },
              {
                value: "cricket sets",
                label: "Cricket Sets",
                isLeaf: true,
              },
              {
                value: "cricket bats",
                label: "Cricket Bats",
                isLeaf: true,
              },
              {
                value: "cricket balls",
                label: "Cricket Balls",
                isLeaf: true,
              },
            ],
          },
          {
            value: "field hockey & lacrosse",
            label: "Field Hockey Lacrosse",
            children: [
              {
                value: "training aids",
                label: "Training Aids",
                isLeaf: true,
              },
              {
                value: "protective gear",
                label: "Protective Gear",
                children: [
                  {
                    value: "gloves",
                    label: "Gloves",
                    isLeaf: true,
                  },
                  {
                    value: "pads & body protectors",
                    label: "Pads Body Protectors",
                    isLeaf: true,
                  },
                  {
                    value: "helmets",
                    label: "Helmets",
                    isLeaf: true,
                  },
                  {
                    value: "goggles & masks",
                    label: "Goggles Masks",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "lacrosse",
                label: "Lacrosse",
                children: [
                  {
                    value: "lacrosse balls",
                    label: "Lacrosse Balls",
                    isLeaf: true,
                  },
                  {
                    value: "lacrosse sticks",
                    label: "Lacrosse Sticks",
                    isLeaf: true,
                  },
                  {
                    value: "lacrosse goals",
                    label: "Lacrosse Goals",
                    isLeaf: true,
                  },
                  {
                    value: "lacrosse equipment sets",
                    label: "Lacrosse Equipment Sets",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "field hockey",
                label: "Field Hockey",
                children: [
                  {
                    value: "field hockey balls",
                    label: "Field Hockey Balls",
                    isLeaf: true,
                  },
                  {
                    value: "field hockey sticks",
                    label: "Field Hockey Sticks",
                    isLeaf: true,
                  },
                  {
                    value: "field hockey goals",
                    label: "Field Hockey Goals",
                    isLeaf: true,
                  },
                ],
              },
            ],
          },
          {
            value: "boxing & martial arts",
            label: "Boxing Martial Arts",
            children: [
              {
                value: "training equipment",
                label: "Training Equipment",
                children: [
                  {
                    value: "blockers & targets",
                    label: "Blockers Targets",
                    isLeaf: true,
                  },
                  {
                    value: "strike pads & mitts",
                    label: "Strike Pads Mitts",
                    isLeaf: true,
                  },
                  {
                    value: "punching bags",
                    label: "Punching Bags",
                    isLeaf: true,
                  },
                  {
                    value: "grappling dummies",
                    label: "Grappling Dummies",
                    isLeaf: true,
                  },
                  {
                    value: "floor mats & pads",
                    label: "Floor Mats Pads",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "protective gear",
                label: "Protective Gear",
                children: [
                  {
                    value: "shin guards",
                    label: "Shin Guards",
                    isLeaf: true,
                  },
                  {
                    value: "knee & elbow guards",
                    label: "Knee Elbow Guards",
                    isLeaf: true,
                  },
                  {
                    value: "headgear",
                    label: "Headgear",
                    isLeaf: true,
                  },
                  {
                    value: "hand wraps",
                    label: "Hand Wraps",
                    isLeaf: true,
                  },
                  {
                    value: "chest guards",
                    label: "Chest Guards",
                    isLeaf: true,
                  },
                  {
                    value: "arm guards",
                    label: "Arm Guards",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "martial arts weapons",
                label: "Martial Arts Weapons",
                isLeaf: true,
              },
              {
                value: "clothing & accessories",
                label: "Clothing Accessories",
                children: [
                  {
                    value: "boxing & martial arts gloves",
                    label: "Boxing Martial Arts Gloves",
                    isLeaf: true,
                  },
                  {
                    value: "martial arts uniforms & outfits",
                    label: "Martial Arts Uniforms Outfits",
                    isLeaf: true,
                  },
                  {
                    value: "martial arts belts",
                    label: "Martial Arts Belts",
                    isLeaf: true,
                  },
                  {
                    value: "boxing & martial arts shorts",
                    label: "Boxing Martial Arts Shorts",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "boxing rings",
                label: "Boxing Rings",
                isLeaf: true,
              },
            ],
          },
          {
            value: "dance & gymnastics",
            label: "Dance Gymnastics",
            children: [
              {
                value: "women's dancewear",
                label: "Womens Dancewear",
                children: [
                  {
                    value: "leg warmers",
                    label: "Leg Warmers",
                    isLeaf: true,
                  },
                  {
                    value: "tops & shirts",
                    label: "Tops Shirts",
                    isLeaf: true,
                  },
                  {
                    value: "skirts",
                    label: "Skirts",
                    isLeaf: true,
                  },
                  {
                    value: "pants & shorts",
                    label: "Pants Shorts",
                    isLeaf: true,
                  },
                  {
                    value: "leotards & unitards",
                    label: "Leotards Unitards",
                    isLeaf: true,
                  },
                  {
                    value: "leggings & tights",
                    label: "Leggings Tights",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "men's dancewear",
                label: "Mens Dancewear",
                children: [
                  {
                    value: "leg warmers",
                    label: "Leg Warmers",
                    isLeaf: true,
                  },
                  {
                    value: "tops & shirts",
                    label: "Tops Shirts",
                    isLeaf: true,
                  },
                  {
                    value: "pants & shorts",
                    label: "Pants Shorts",
                    isLeaf: true,
                  },
                  {
                    value: "leggings & tights",
                    label: "Leggings Tights",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "leotards & unitards",
                label: "Leotards Unitards",
                isLeaf: true,
              },
              {
                value: "dancewear",
                label: "Dancewear",
                isLeaf: true,
              },
              {
                value: "dance shoes",
                label: "Dance Shoes",
                isLeaf: true,
              },
              {
                value: "glassless mirrors",
                label: "Glassless Mirrors",
                isLeaf: true,
              },
              {
                value: "dance floors",
                label: "Dance Floors",
                isLeaf: true,
              },
              {
                value: "dance equipment",
                label: "Dance Equipment",
                children: [
                  {
                    value: "ballet barres",
                    label: "Ballet Barres",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "dance accessories",
                label: "Dance Accessories",
                isLeaf: true,
              },
              {
                value: "baby & toddler dancewear",
                label: "Baby Toddler Dancewear",
                isLeaf: true,
              },
              {
                value: "children's dancewear",
                label: "Childrens Dancewear",
                children: [
                  {
                    value: "dresses & tutus",
                    label: "Dresses Tutus",
                    isLeaf: true,
                  },
                  {
                    value: "tops & shirts",
                    label: "Tops Shirts",
                    isLeaf: true,
                  },
                  {
                    value: "pants & shorts",
                    label: "Pants Shorts",
                    isLeaf: true,
                  },
                  {
                    value: "leotards & unitards",
                    label: "Leotards Unitards",
                    isLeaf: true,
                  },
                  {
                    value: "leggings & tights",
                    label: "Leggings Tights",
                    isLeaf: true,
                  },
                  {
                    value: "leg warmers",
                    label: "Leg Warmers",
                    isLeaf: true,
                  },
                ],
              },
            ],
          },
          {
            value: "baseball & softball",
            label: "Baseball Softball",
            children: [
              {
                value: "training equipment",
                label: "Training Equipment",
                children: [
                  {
                    value: "batting tees",
                    label: "Batting Tees",
                    isLeaf: true,
                  },
                  {
                    value: "weighted balls & bats",
                    label: "Weighted Balls Bats",
                    isLeaf: true,
                  },
                  {
                    value: "umpire indicators",
                    label: "Umpire Indicators",
                    isLeaf: true,
                  },
                  {
                    value: "radar guns & speed sensors",
                    label: "Radar Guns Speed Sensors",
                    isLeaf: true,
                  },
                  {
                    value: "pitching machines",
                    label: "Pitching Machines",
                    isLeaf: true,
                  },
                  {
                    value: "pitch counters",
                    label: "Pitch Counters",
                    isLeaf: true,
                  },
                ],
              },

              {
                value: "protective gear",
                label: "Protective Gear",
                children: [
                  {
                    value: "batting helmets",
                    label: "Batting Helmets",
                    isLeaf: true,
                  },
                  {
                    value: "umpire's equipment",
                    label: "Umpires Equipment",
                    isLeaf: true,
                  },
                  {
                    value: "leg guards",
                    label: "Leg Guards",
                    isLeaf: true,
                  },
                  {
                    value: "chest protectors",
                    label: "Chest Protectors",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "jerseys & cleats",
                label: "Jerseys Cleats",
                isLeaf: true,
              },
              {
                value: "gloves & mitts",
                label: "Gloves Mitts",
                isLeaf: true,
              },
              {
                value: "baseball & softball bats",
                label: "Baseball Softball Bats",
                isLeaf: true,
              },
              {
                value: "field equipment",
                label: "Field Equipment",
                children: [
                  {
                    value: "pitching & batting mats",
                    label: "Pitching Batting Mats",
                    isLeaf: true,
                  },
                  {
                    value: "bases & plates",
                    label: "Bases Plates",
                    isLeaf: true,
                  },
                  {
                    value: "backstop netting",
                    label: "Backstop Netting",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "baseballs & softballs",
                label: "Baseballs Softballs",
                isLeaf: true,
              },
            ],
          },
          {
            value: "basketball",
            label: "Basketball",
            children: [
              {
                value: "training aids",
                label: "Training Aids",
                isLeaf: true,
              },
              {
                value: "jerseys",
                label: "Jerseys",
                isLeaf: true,
              },
              {
                value: "basketballs",
                label: "Basketballs",
                isLeaf: true,
              },
              {
                value: "hoops & hoop accessories",
                label: "Hoops Hoop Accessories",
                children: [
                  {
                    value: "backboards",
                    label: "Backboards",
                    isLeaf: true,
                  },
                  {
                    value: "rims & nets",
                    label: "Rims Nets",
                    isLeaf: true,
                  },
                  {
                    value: "hoops",
                    label: "Hoops",
                    isLeaf: true,
                  },
                  {
                    value: "hoop posts",
                    label: "Hoop Posts",
                    isLeaf: true,
                  },
                  {
                    value: "hoop padding",
                    label: "Hoop Padding",
                    isLeaf: true,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        value: "exercise & fitness",
        label: "Exercise Fitness",
        children: [
          {
            value: "vibration exercise machines",
            label: "Vibration Exercise Machines",
            isLeaf: true,
          },
          {
            value: "suspension trainers",
            label: "Suspension Trainers",
            isLeaf: true,
          },
          {
            value: "running",
            label: "Running",
            children: [
              {
                value: "speed & resistance parachutes",
                label: "Speed Resistance Parachutes",
                isLeaf: true,
              },
            ],
          },
          {
            value: "reaction balls",
            label: "Reaction Balls",
            isLeaf: true,
          },
          {
            value: "push up & pull up bars",
            label: "Push Up Pull Up Bars",
            isLeaf: true,
          },
          {
            value: "power towers",
            label: "Power Towers",
            isLeaf: true,
          },
          {
            value: "inversion tables",
            label: "Inversion Tables",
            isLeaf: true,
          },
          {
            value: "hand exercisers",
            label: "Hand Exercisers",
            isLeaf: true,
          },
          {
            value: "foam rollers & massagers",
            label: "Foam Rollers Massagers",
            isLeaf: true,
          },
          {
            value: "exercise benches & wedges",
            label: "Exercise Benches Wedges",
            isLeaf: true,
          },
          {
            value: "exercise bands",
            label: "Exercise Bands",
            isLeaf: true,
          },
          {
            value: "exercise balls",
            label: "Exercise Balls",
            isLeaf: true,
          },
          {
            value: "balance trainers",
            label: "Balance Trainers",
            isLeaf: true,
          },
          {
            value: "aerobic steps",
            label: "Aerobic Steps",
            isLeaf: true,
          },
          {
            value: "ab exercisers",
            label: "Ab Exercisers",
            isLeaf: true,
          },
          {
            value: "yoga & pilates",
            label: "Yoga Pilates",
            children: [
              {
                value: "pilates machines",
                label: "Pilates Machines",
                isLeaf: true,
              },
              {
                value: "yoga straps & bands",
                label: "Yoga Straps Bands",
                isLeaf: true,
              },
              {
                value: "yoga mats",
                label: "Yoga Mats",
                isLeaf: true,
              },
              {
                value: "yoga blocks",
                label: "Yoga Blocks",
                isLeaf: true,
              },
              {
                value: "yoga bags & mat carriers",
                label: "Yoga Bags Mat Carriers",
                isLeaf: true,
              },
              {
                value: "yoga & pilates towels",
                label: "Yoga Pilates Towels",
                isLeaf: true,
              },
            ],
          },
          {
            value: "cardio equipment",
            label: "Cardio Equipment",
            children: [
              {
                value: "cardio machine accessories",
                label: "Cardio Machine Accessories",
                isLeaf: true,
              },
              {
                value: "treadmills",
                label: "Treadmills",
                isLeaf: true,
              },
              {
                value: "stair climbers & step machines",
                label: "Stair Climbers Step Machines",
                isLeaf: true,
              },
              {
                value: "rowing machines",
                label: "Rowing Machines",
                isLeaf: true,
              },
              {
                value: "jump ropes",
                label: "Jump Ropes",
                isLeaf: true,
              },
              {
                value: "exercise bikes",
                label: "Exercise Bikes",
                isLeaf: true,
              },
              {
                value: "elliptical trainers",
                label: "Elliptical Trainers",
                isLeaf: true,
              },
            ],
          },
          {
            value: "weight lifting equipment",
            label: "Weight Lifting Equipment",
            children: [
              {
                value: "weighted clothing",
                label: "Weighted Clothing",
                isLeaf: true,
              },
              {
                value: "weight plates",
                label: "Weight Plates",
                isLeaf: true,
              },
              {
                value: "weight lifting machines & racks",
                label: "Weight Lifting Machines Racks",
                isLeaf: true,
              },
              {
                value: "dumbbells",
                label: "Dumbbells",
                isLeaf: true,
              },
              {
                value: "weight lifting accessories",
                label: "Weight Lifting Accessories",
                children: [
                  {
                    value: "exercise equipment mats",
                    label: "Exercise Equipment Mats",
                    isLeaf: true,
                  },
                  {
                    value: "weight storage racks",
                    label: "Weight Storage Racks",
                    isLeaf: true,
                  },
                  {
                    value: "weight lifting machine accessories",
                    label: "Weight Lifting Machine Accessories",
                    isLeaf: true,
                  },
                  {
                    value: "weight lifting gloves",
                    label: "Weight Lifting Gloves",
                    isLeaf: true,
                  },
                  {
                    value: "weight lifting belts",
                    label: "Weight Lifting Belts",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "weight bars & collars",
                label: "Weight Bars Collars",
                isLeaf: true,
              },
              {
                value: "medicine balls",
                label: "Medicine Balls",
                isLeaf: true,
              },
              {
                value: "kettlebells",
                label: "Kettlebells",
                isLeaf: true,
              },
            ],
          },
        ],
      },
      {
        value: "indoor games",
        label: "Indoor Games",
        children: [
          {
            value: "table shuffleboard",
            label: "Table Shuffleboard",
            children: [
              {
                value: "shuffleboard tables",
                label: "Shuffleboard Tables",
                isLeaf: true,
              },
              {
                value: "table shuffleboard pucks",
                label: "Table Shuffleboard Pucks",
                isLeaf: true,
              },
              {
                value: "table shuffleboard powder & wax",
                label: "Table Shuffleboard Powder Wax",
                isLeaf: true,
              },
            ],
          },
          {
            value: "ping pong & table tennis",
            label: "Ping Pong Table Tennis",
            children: [
              {
                value: "ping pong balls",
                label: "Ping Pong Balls",
                isLeaf: true,
              },
              {
                value: "ping pong tables",
                label: "Ping Pong Tables",
                isLeaf: true,
              },
              {
                value: "ping pong robots",
                label: "Ping Pong Robots",
                isLeaf: true,
              },
              {
                value: "ping pong paddles",
                label: "Ping Pong Paddles",
                isLeaf: true,
              },
              {
                value: "ping pong nets & posts",
                label: "Ping Pong Nets Posts",
                isLeaf: true,
              },
            ],
          },
          {
            value: "multi-game tables",
            label: "Multi Game Tables",
            isLeaf: true,
          },
          {
            value: "foosball",
            label: "Foosball",
            children: [
              {
                value: "foosball balls",
                label: "Foosball Balls",
                isLeaf: true,
              },
              {
                value: "foosball tables",
                label: "Foosball Tables",
                isLeaf: true,
              },
              {
                value: "foosball handles",
                label: "Foosball Handles",
                isLeaf: true,
              },
              {
                value: "foosball figures",
                label: "Foosball Figures",
                isLeaf: true,
              },
            ],
          },
          {
            value: "darts",
            label: "Darts",
            children: [
              {
                value: "throwing darts",
                label: "Throwing Darts",
                isLeaf: true,
              },
              {
                value: "dartboards",
                label: "Dartboards",
                isLeaf: true,
              },
              {
                value: "dartboard cabinets",
                label: "Dartboard Cabinets",
                isLeaf: true,
              },
              {
                value: "dartboard backboards",
                label: "Dartboard Backboards",
                isLeaf: true,
              },
              {
                value: "dart sets",
                label: "Dart Sets",
                isLeaf: true,
              },
              {
                value: "dart parts",
                label: "Dart Parts",
                children: [
                  {
                    value: "dart flights",
                    label: "Dart Flights",
                    isLeaf: true,
                  },
                  {
                    value: "dart tips",
                    label: "Dart Tips",
                    isLeaf: true,
                  },
                  {
                    value: "dart shafts",
                    label: "Dart Shafts",
                    isLeaf: true,
                  },
                ],
              },
            ],
          },
          {
            value: "bowling",
            label: "Bowling",
            children: [
              {
                value: "bowling bags",
                label: "Bowling Bags",
                isLeaf: true,
              },
              {
                value: "clothing & shoes",
                label: "Clothing Shoes",
                isLeaf: true,
              },
              {
                value: "bowling pins",
                label: "Bowling Pins",
                isLeaf: true,
              },
              {
                value: "bowling gloves & wrist supports",
                label: "Bowling Gloves Wrist Supports",
                isLeaf: true,
              },
              {
                value: "bowling balls",
                label: "Bowling Balls",
                isLeaf: true,
              },
            ],
          },
          {
            value: "billiards",
            label: "Billiards",
            children: [
              {
                value: "billiard tables",
                label: "Billiard Tables",
                isLeaf: true,
              },
              {
                value: "billiard table accessories",
                label: "Billiard Table Accessories",
                children: [
                  {
                    value: "billiard pockets",
                    label: "Billiard Pockets",
                    isLeaf: true,
                  },
                  {
                    value: "billiard table lights",
                    label: "Billiard Table Lights",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "billiard gloves",
                label: "Billiard Gloves",
                isLeaf: true,
              },
              {
                value: "billiard cues & bridges",
                label: "Billiard Cues Bridges",
                isLeaf: true,
              },
              {
                value: "billiard ball racks",
                label: "Billiard Ball Racks",
                isLeaf: true,
              },
              {
                value: "billiard cue accessories",
                label: "Billiard Cue Accessories",
                children: [
                  {
                    value: "billiard cue cases",
                    label: "Billiard Cue Cases",
                    isLeaf: true,
                  },
                  {
                    value: "billiard cue tips",
                    label: "Billiard Cue Tips",
                    isLeaf: true,
                  },
                  {
                    value: "billiard cue racks",
                    label: "Billiard Cue Racks",
                    isLeaf: true,
                  },
                  {
                    value: "billiard cue chalk",
                    label: "Billiard Cue Chalk",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "billiard balls",
                label: "Billiard Balls",
                isLeaf: true,
              },
            ],
          },
          {
            value: "air hockey",
            label: "Air Hockey",
            children: [
              {
                value: "air hockey paddles",
                label: "Air Hockey Paddles",
                isLeaf: true,
              },
              {
                value: "air hockey tables",
                label: "Air Hockey Tables",
                isLeaf: true,
              },
              {
                value: "air hockey pucks",
                label: "Air Hockey Pucks",
                isLeaf: true,
              },
            ],
          },
        ],
      },
      {
        value: "outdoor recreation",
        label: "Outdoor Recreation",
        children: [
          {
            value: "equestrian",
            label: "Equestrian",
            children: [
              {
                value: "stable accessories",
                label: "Stable Accessories",
                children: [
                  {
                    value: "bridle & halter bags",
                    label: "Bridle Halter Bags",
                    isLeaf: true,
                  },
                  {
                    value: "toys & salt licks",
                    label: "Toys Salt Licks",
                    isLeaf: true,
                  },
                  {
                    value: "tack hooks, racks & boxes",
                    label: "Tack Hooks Racks Boxes",
                    isLeaf: true,
                  },
                  {
                    value: "saddle racks & stands",
                    label: "Saddle Racks Stands",
                    isLeaf: true,
                  },
                  {
                    value: "hay nets & bags",
                    label: "Hay Nets Bags",
                    isLeaf: true,
                  },
                  {
                    value: "feeders & waterers",
                    label: "Feeders Waterers",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "saddles & tack",
                label: "Saddles Tack",
                children: [
                  {
                    value: "stirrups",
                    label: "Stirrups",
                    isLeaf: true,
                  },
                  {
                    value: "spurs & spur straps",
                    label: "Spurs Spur Straps",
                    isLeaf: true,
                  },
                  {
                    value: "saddles",
                    label: "Saddles",
                    isLeaf: true,
                  },
                  {
                    value: "breast collars",
                    label: "Breast Collars",
                    isLeaf: true,
                  },
                  {
                    value: "saddle accessories",
                    label: "Saddle Accessories",
                    children: [
                      {
                        value: "saddle bags",
                        label: "Saddle Bags",
                        isLeaf: true,
                      },
                      {
                        value: "seat savers",
                        label: "Seat Savers",
                        isLeaf: true,
                      },
                      {
                        value: "saddle pads",
                        label: "Saddle Pads",
                        isLeaf: true,
                      },
                      {
                        value: "saddle covers",
                        label: "Saddle Covers",
                        isLeaf: true,
                      },
                    ],
                  },
                  {
                    value: "riding crops & whips",
                    label: "Riding Crops Whips",
                    isLeaf: true,
                  },
                  {
                    value: "reins",
                    label: "Reins",
                    isLeaf: true,
                  },
                  {
                    value: "martingales & breastplates",
                    label: "Martingales Breastplates",
                    isLeaf: true,
                  },
                  {
                    value: "lead ropes",
                    label: "Lead Ropes",
                    isLeaf: true,
                  },
                  {
                    value: "harnesses",
                    label: "Harnesses",
                    isLeaf: true,
                  },
                  {
                    value: "halters",
                    label: "Halters",
                    isLeaf: true,
                  },
                  {
                    value: "cinches",
                    label: "Cinches",
                    isLeaf: true,
                  },
                  {
                    value: "bridles",
                    label: "Bridles",
                    isLeaf: true,
                  },
                  {
                    value: "bridle bits",
                    label: "Bridle Bits",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "protective gear",
                label: "Protective Gear",
                children: [
                  {
                    value: "body protectors",
                    label: "Body Protectors",
                    isLeaf: true,
                  },
                  {
                    value: "safety vests",
                    label: "Safety Vests",
                    isLeaf: true,
                  },
                  {
                    value: "riding helmets",
                    label: "Riding Helmets",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "horse wear",
                label: "Horse Wear",
                children: [
                  {
                    value: "fly masks",
                    label: "Fly Masks",
                    isLeaf: true,
                  },
                  {
                    value: "tail bags & wraps",
                    label: "Tail Bags Wraps",
                    isLeaf: true,
                  },
                  {
                    value: "shoulder guards",
                    label: "Shoulder Guards",
                    isLeaf: true,
                  },
                  {
                    value: "horse boots & leg wraps",
                    label: "Horse Boots Leg Wraps",
                    isLeaf: true,
                  },
                  {
                    value: "horse blankets & sheets",
                    label: "Horse Blankets Sheets",
                    isLeaf: true,
                  },
                  {
                    value: "hoods & neck covers",
                    label: "Hoods Neck Covers",
                    isLeaf: true,
                  },
                  {
                    value: "grazing muzzles",
                    label: "Grazing Muzzles",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "horse jumps & arena equipment",
                label: "Horse Jumps Arena Equipment",
                children: [
                  {
                    value: "horse jumps",
                    label: "Horse Jumps",
                    isLeaf: true,
                  },
                  {
                    value: "rodeo & roping equipment",
                    label: "Rodeo Roping Equipment",
                    isLeaf: true,
                  },
                  {
                    value: "lunging rollers & pads",
                    label: "Lunging Rollers Pads",
                    isLeaf: true,
                  },
                  {
                    value: "lunge lines & cavessons",
                    label: "Lunge Lines Cavessons",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "horse feed",
                label: "Horse Feed",
                children: [
                  {
                    value: "horse treats",
                    label: "Horse Treats",
                    isLeaf: true,
                  },
                  {
                    value: "horse supplements",
                    label: "Horse Supplements",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "horse carts & carriages",
                label: "Horse Carts Carriages",
                isLeaf: true,
              },
              {
                value: "horse care",
                label: "Horse Care",
                children: [
                  {
                    value: "fly & insect repellents",
                    label: "Fly Insect Repellents",
                    isLeaf: true,
                  },
                  {
                    value: "tail extensions",
                    label: "Tail Extensions",
                    isLeaf: true,
                  },
                  {
                    value: "sweat scrapers",
                    label: "Sweat Scrapers",
                    isLeaf: true,
                  },
                  {
                    value: "horse wormers",
                    label: "Horse Wormers",
                    isLeaf: true,
                  },
                  {
                    value: "horse shampoo & conditioner",
                    label: "Horse Shampoo Conditioner",
                    isLeaf: true,
                  },
                  {
                    value: "horse combs & brushes",
                    label: "Horse Combs Brushes",
                    isLeaf: true,
                  },
                  {
                    value: "horse clippers",
                    label: "Horse Clippers",
                    isLeaf: true,
                  },
                  {
                    value: "hoof rasps & farrier tools",
                    label: "Hoof Rasps Farrier Tools",
                    isLeaf: true,
                  },
                  {
                    value: "hoof oil & moisturizer",
                    label: "Hoof Oil Moisturizer",
                    isLeaf: true,
                  },
                  {
                    value: "grooming kits",
                    label: "Grooming Kits",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "clothing & shoes",
                label: "Clothing Shoes",
                children: [
                  {
                    value: "chaps & pants",
                    label: "Chaps Pants",
                    isLeaf: true,
                  },
                  {
                    value: "vests",
                    label: "Vests",
                    isLeaf: true,
                  },
                  {
                    value: "shirts",
                    label: "Shirts",
                    isLeaf: true,
                  },
                  {
                    value: "riding gloves",
                    label: "Riding Gloves",
                    isLeaf: true,
                  },
                  {
                    value: "riding boots",
                    label: "Riding Boots",
                    isLeaf: true,
                  },
                  {
                    value: "jodhpurs & breeches",
                    label: "Jodhpurs Breeches",
                    isLeaf: true,
                  },
                  {
                    value: "jackets",
                    label: "Jackets",
                    isLeaf: true,
                  },
                  {
                    value: "hats",
                    label: "Hats",
                    isLeaf: true,
                  },
                ],
              },
            ],
          },
          {
            value: "archery",
            label: "Archery",
            children: [
              {
                value: "protective gear",
                label: "Protective Gear",
                children: [
                  {
                    value: "archery gloves",
                    label: "Archery Gloves",
                    isLeaf: true,
                  },
                  {
                    value: "finger guards",
                    label: "Finger Guards",
                    isLeaf: true,
                  },
                  {
                    value: "arm guards & chest guards",
                    label: "Arm Guards Chest Guards",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "bows",
                label: "Bows",
                children: [
                  {
                    value: "compound bows",
                    label: "Compound Bows",
                    isLeaf: true,
                  },
                  {
                    value: "recurve & longbows",
                    label: "Recurve Longbows",
                    isLeaf: true,
                  },
                  {
                    value: "crossbows",
                    label: "Crossbows",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "arrow parts",
                label: "Arrow Parts",
                children: [
                  {
                    value: "arrow fletchings",
                    label: "Arrow Fletchings",
                    isLeaf: true,
                  },
                  {
                    value: "arrow shafts",
                    label: "Arrow Shafts",
                    isLeaf: true,
                  },
                  {
                    value: "arrow points & arrowheads",
                    label: "Arrow Points Arrowheads",
                    isLeaf: true,
                  },
                  {
                    value: "arrow nocks",
                    label: "Arrow Nocks",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "arrow parts",
                label: "Arrow Parts",
                isLeaf: true,
              },
              {
                value: "archery sets",
                label: "Archery Sets",
                isLeaf: true,
              },
              {
                value: "archery arrows & bolts",
                label: "Archery Arrows Bolts",
                isLeaf: true,
              },
              {
                value: "archery accessories",
                label: "Archery Accessories",
                children: [
                  {
                    value: "archery bags & cases",
                    label: "Archery Bags Cases",
                    isLeaf: true,
                  },
                  {
                    value: "quivers",
                    label: "Quivers",
                    isLeaf: true,
                  },
                  {
                    value: "bow strings",
                    label: "Bow Strings",
                    isLeaf: true,
                  },
                  {
                    value: "bow slings",
                    label: "Bow Slings",
                    isLeaf: true,
                  },
                  {
                    value: "arrow rests",
                    label: "Arrow Rests",
                    isLeaf: true,
                  },
                  {
                    value: "archery targets",
                    label: "Archery Targets",
                    isLeaf: true,
                  },
                  {
                    value: "archery stabilizers",
                    label: "Archery Stabilizers",
                    isLeaf: true,
                  },
                  {
                    value: "archery sights",
                    label: "Archery Sights",
                    isLeaf: true,
                  },
                  {
                    value: "archery releases",
                    label: "Archery Releases",
                    isLeaf: true,
                  },
                ],
              },
            ],
          },
          {
            value: "skydiving & hangliding",
            label: "Skydiving Hangliding",
            children: [
              {
                value: "variometers & altimeters",
                label: "Variometers Altimeters",
                isLeaf: true,
              },
              {
                value: "paragliders",
                label: "Paragliders",
                isLeaf: true,
              },
              {
                value: "parachutes",
                label: "Parachutes",
                isLeaf: true,
              },
              {
                value: "parachute canopies",
                label: "Parachute Canopies",
                isLeaf: true,
              },
              {
                value: "hang gliders",
                label: "Hang Gliders",
                isLeaf: true,
              },
              {
                value: "complete gear packages",
                label: "Complete Gear Packages",
                isLeaf: true,
              },
              {
                value: "airframes",
                label: "Airframes",
                isLeaf: true,
              },
              {
                value: "clothing & protective gear",
                label: "Clothing Protective Gear",
                children: [
                  {
                    value: "jumpsuits",
                    label: "Jumpsuits",
                    isLeaf: true,
                  },
                  {
                    value: "wing suits & air suits",
                    label: "Wing Suits Air Suits",
                    isLeaf: true,
                  },
                  {
                    value: "skydiving helmets",
                    label: "Skydiving Helmets",
                    isLeaf: true,
                  },
                  {
                    value: "skydiving harness systems",
                    label: "Skydiving Harness Systems",
                    isLeaf: true,
                  },
                  {
                    value: "skydiving goggles",
                    label: "Skydiving Goggles",
                    isLeaf: true,
                  },
                ],
              },
            ],
          },
          {
            value: "paintball & airsoft",
            label: "Paintball Airsoft",
            children: [
              {
                value: "protective gear",
                label: "Protective Gear",
                children: [
                  {
                    value: "paintball & airsoft chest protectors",
                    label: "Paintball Airsoft Chest Protectors",
                    isLeaf: true,
                  },
                  {
                    value: "paintball & airsoft pads",
                    label: "Paintball Airsoft Pads",
                    isLeaf: true,
                  },
                  {
                    value: "paintball & airsoft masks",
                    label: "Paintball Airsoft Masks",
                    isLeaf: true,
                  },
                  {
                    value: "paintball & airsoft gloves",
                    label: "Paintball Airsoft Gloves",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "paintball & airsoft targets",
                label: "Paintball Airsoft Targets",
                isLeaf: true,
              },
              {
                value: "paintball & airsoft sets",
                label: "Paintball Airsoft Sets",
                isLeaf: true,
              },
              {
                value: "paintball & airsoft optics",
                label: "Paintball Airsoft Optics",
                isLeaf: true,
              },
              {
                value: "paintball & airsoft holsters",
                label: "Paintball Airsoft Holsters",
                isLeaf: true,
              },
              {
                value: "paintball & airsoft equipment bags",
                label: "Paintball Airsoft Equipment Bags",
                isLeaf: true,
              },
              {
                value: "paintball",
                label: "Paintball",
                children: [
                  {
                    value: "paintballs",
                    label: "Paintballs",
                    isLeaf: true,
                  },
                  {
                    value: "paintball harnesses & packs",
                    label: "Paintball Harnesses Packs",
                    isLeaf: true,
                  },
                  {
                    value: "paintball guns",
                    label: "Paintball Guns",
                    isLeaf: true,
                  },
                  {
                    value: "clothing & shoes",
                    label: "Clothing Shoes",
                    isLeaf: true,
                  },
                  {
                    value: "paintball gun parts & accessories",
                    label: "Paintball Gun Parts Accessories",
                    children: [
                      {
                        value: "paintball drop forwards",
                        label: "Paintball Drop Forwards",
                        isLeaf: true,
                      },
                      {
                        value: "paintball triggers & frames",
                        label: "Paintball Triggers Frames",
                        isLeaf: true,
                      },
                      {
                        value: "paintball tanks",
                        label: "Paintball Tanks",
                        isLeaf: true,
                      },
                      {
                        value: "paintball regulators",
                        label: "Paintball Regulators",
                        isLeaf: true,
                      },
                      {
                        value: "paintball hoppers & loaders",
                        label: "Paintball Hoppers Loaders",
                        isLeaf: true,
                      },
                      {
                        value: "paintball gun barrels",
                        label: "Paintball Gun Barrels",
                        isLeaf: true,
                      },
                      {
                        value: "paintball grips",
                        label: "Paintball Grips",
                        isLeaf: true,
                      },
                      {
                        value: "paintball feednecks",
                        label: "Paintball Feednecks",
                        isLeaf: true,
                      },
                    ],
                  },
                  {
                    value: "paintball grenade launchers",
                    label: "Paintball Grenade Launchers",
                    isLeaf: true,
                  },
                  {
                    value: "paintball filling stations",
                    label: "Paintball Filling Stations",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "airsoft",
                label: "Airsoft",
                children: [
                  {
                    value: "airsoft pellets",
                    label: "Airsoft Pellets",
                    isLeaf: true,
                  },
                  {
                    value: "airsoft guns",
                    label: "Airsoft Guns",
                    isLeaf: true,
                  },
                  {
                    value: "airsoft gun parts & accessories",
                    label: "Airsoft Gun Parts Accessories",
                    children: [
                      {
                        value: "airsoft foregrips",
                        label: "Airsoft Foregrips",
                        isLeaf: true,
                      },
                      {
                        value: "airsoft magazines",
                        label: "Airsoft Magazines",
                        isLeaf: true,
                      },
                      {
                        value: "airsoft gun batteries",
                        label: "Airsoft Gun Batteries",
                        isLeaf: true,
                      },
                      {
                        value: "airsoft gun barrels",
                        label: "Airsoft Gun Barrels",
                        isLeaf: true,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            value: "kite buggying",
            label: "Kite Buggying",
            isLeaf: true,
          },
          {
            value: "hunting & shooting",
            label: "Hunting Shooting",
            children: [
              {
                value: "gun parts & accessories",
                label: "Gun Parts Accessories",
                children: [
                  {
                    value: "shooting optics",
                    label: "Shooting Optics",
                    children: [
                      {
                        value: "hunting rangefinders",
                        label: "Hunting Rangefinders",
                        isLeaf: true,
                      },
                      {
                        value: "spotting scopes",
                        label: "Spotting Scopes",
                        isLeaf: true,
                      },
                      {
                        value: "rifle scopes",
                        label: "Rifle Scopes",
                        isLeaf: true,
                      },
                      {
                        value: "red dot & laser sights",
                        label: "Red Dot Laser Sights",
                        isLeaf: true,
                      },
                      {
                        value: "night vision viewers & scopes",
                        label: "Night Vision Viewers Scopes",
                        isLeaf: true,
                      },
                      {
                        value: "mounted flashlights",
                        label: "Mounted Flashlights",
                        isLeaf: true,
                      },
                    ],
                  },
                  {
                    value: "reloading equipment",
                    label: "Reloading Equipment",
                    children: [
                      {
                        value: "powder measures & scales",
                        label: "Powder Measures Scales",
                        isLeaf: true,
                      },
                      {
                        value: "reloading presses",
                        label: "Reloading Presses",
                        isLeaf: true,
                      },
                      {
                        value: "reloading powders & primers",
                        label: "Reloading Powders Primers",
                        isLeaf: true,
                      },
                      {
                        value: "reloading molds",
                        label: "Reloading Molds",
                        isLeaf: true,
                      },
                      {
                        value: "reloading dies",
                        label: "Reloading Dies",
                        isLeaf: true,
                      },
                    ],
                  },
                  {
                    value: "holsters & pouches",
                    label: "Holsters Pouches",
                    children: [
                      {
                        value: "ammunition belts & bandoliers",
                        label: "Ammunition Belts Bandoliers",
                        isLeaf: true,
                      },
                      {
                        value: "shell caddies",
                        label: "Shell Caddies",
                        isLeaf: true,
                      },
                      {
                        value: "magazine pouches",
                        label: "Magazine Pouches",
                        isLeaf: true,
                      },
                      {
                        value: "gun holsters",
                        label: "Gun Holsters",
                        isLeaf: true,
                      },
                    ],
                  },
                  {
                    value: "gun storage",
                    label: "Gun Storage",
                    children: [
                      {
                        value: "ammunition cases",
                        label: "Ammunition Cases",
                        isLeaf: true,
                      },
                      {
                        value: "gun sleeves",
                        label: "Gun Sleeves",
                        isLeaf: true,
                      },
                      {
                        value: "gun racks",
                        label: "Gun Racks",
                        isLeaf: true,
                      },
                      {
                        value: "gun locks",
                        label: "Gun Locks",
                        isLeaf: true,
                      },
                      {
                        value: "gun cases",
                        label: "Gun Cases",
                        isLeaf: true,
                      },
                      {
                        value: "gun cabinets & safes",
                        label: "Gun Cabinets Safes",
                        isLeaf: true,
                      },
                    ],
                  },
                  {
                    value: "gun parts",
                    label: "Gun Parts",
                    isLeaf: true,
                  },
                  {
                    value: "gun maintenance",
                    label: "Gun Maintenance",
                    children: [
                      {
                        value: "cleaning supplies",
                        label: "Cleaning Supplies",
                        isLeaf: true,
                      },
                      {
                        value: "gun vises",
                        label: "Gun Vises",
                        isLeaf: true,
                      },
                      {
                        value: "gun smithing equipment",
                        label: "Gun Smithing Equipment",
                        isLeaf: true,
                      },
                    ],
                  },
                ],
              },
              {
                value: "taxidermy supplies",
                label: "Taxidermy Supplies",
                isLeaf: true,
              },
              {
                value: "taxidermy animals",
                label: "Taxidermy Animals",
                isLeaf: true,
              },
              {
                value: "tactical gear",
                label: "Tactical Gear",
                children: [
                  {
                    value: "body armor",
                    label: "Body Armor",
                    isLeaf: true,
                  },
                  {
                    value: "tactical vests & chest rigs",
                    label: "Tactical Vests Chest Rigs",
                    isLeaf: true,
                  },
                  {
                    value: "tactical slings",
                    label: "Tactical Slings",
                    isLeaf: true,
                  },
                  {
                    value: "tactical shoes",
                    label: "Tactical Shoes",
                    isLeaf: true,
                  },
                  {
                    value: "tactical pants",
                    label: "Tactical Pants",
                    isLeaf: true,
                  },
                  {
                    value: "tactical leg rigs",
                    label: "Tactical Leg Rigs",
                    isLeaf: true,
                  },
                  {
                    value: "tactical gloves",
                    label: "Tactical Gloves",
                    isLeaf: true,
                  },
                  {
                    value: "tactical bags",
                    label: "Tactical Bags",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "shooting accessories",
                label: "Shooting Accessories",
                children: [
                  {
                    value: "clay pigeon throwers",
                    label: "Clay Pigeon Throwers",
                    isLeaf: true,
                  },
                  {
                    value: "shooting targets",
                    label: "Shooting Targets",
                    isLeaf: true,
                  },
                  {
                    value: "shooting sticks & bipods",
                    label: "Shooting Sticks Bipods",
                    isLeaf: true,
                  },
                  {
                    value: "shooting rests",
                    label: "Shooting Rests",
                    isLeaf: true,
                  },
                  {
                    value: "shooting glasses",
                    label: "Shooting Glasses",
                    isLeaf: true,
                  },
                  {
                    value: "gun coatings & wraps",
                    label: "Gun Coatings Wraps",
                    isLeaf: true,
                  },
                  {
                    value: "clay pigeons",
                    label: "Clay Pigeons",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "hunting clothing",
                label: "Hunting Clothing",
                children: [
                  {
                    value: "ghillie suits",
                    label: "Ghillie Suits",
                    isLeaf: true,
                  },
                  {
                    value: "hunting waders",
                    label: "Hunting Waders",
                    isLeaf: true,
                  },
                  {
                    value: "hunting vests",
                    label: "Hunting Vests",
                    isLeaf: true,
                  },
                  {
                    value: "hunting jackets",
                    label: "Hunting Jackets",
                    isLeaf: true,
                  },
                  {
                    value: "hunting gloves",
                    label: "Hunting Gloves",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "hunting accessories",
                label: "Hunting Accessories",
                children: [
                  {
                    value: "tree stands",
                    label: "Tree Stands",
                    isLeaf: true,
                  },
                  {
                    value: "trail markers & game finders",
                    label: "Trail Markers Game Finders",
                    isLeaf: true,
                  },
                  {
                    value: "hunting knives",
                    label: "Hunting Knives",
                    isLeaf: true,
                  },
                  {
                    value: "hunting dog equipment",
                    label: "Hunting Dog Equipment",
                    children: [
                      {
                        value: "check cords",
                        label: "Check Cords",
                        isLeaf: true,
                      },
                      {
                        value: "tracking collars",
                        label: "Tracking Collars",
                        isLeaf: true,
                      },
                      {
                        value: "tail protectors",
                        label: "Tail Protectors",
                        isLeaf: true,
                      },
                      {
                        value: "retriever dummies",
                        label: "Retriever Dummies",
                        isLeaf: true,
                      },
                      {
                        value: "protective vests",
                        label: "Protective Vests",
                        isLeaf: true,
                      },
                      {
                        value: "dog boots & leg protectors",
                        label: "Dog Boots Leg Protectors",
                        isLeaf: true,
                      },
                    ],
                  },
                  {
                    value: "hunting blinds & screens",
                    label: "Hunting Blinds Screens",
                    isLeaf: true,
                  },
                  {
                    value: "hunting bags",
                    label: "Hunting Bags",
                    isLeaf: true,
                  },
                  {
                    value: "game ears & hearing aids",
                    label: "Game Ears Hearing Aids",
                    isLeaf: true,
                  },
                  {
                    value: "game carts & gambrels",
                    label: "Game Carts Gambrels",
                    isLeaf: true,
                  },
                  {
                    value: "game & trail cameras",
                    label: "Game Trail Cameras",
                    isLeaf: true,
                  },
                  {
                    value: "camouflage materials",
                    label: "Camouflage Materials",
                    isLeaf: true,
                  },
                  {
                    value: "animal traps",
                    label: "Animal Traps",
                    isLeaf: true,
                  },
                  {
                    value: "wildlife attractants",
                    label: "Wildlife Attractants",
                    children: [
                      {
                        value: "game calls",
                        label: "Game Calls",
                        isLeaf: true,
                      },
                      {
                        value: "cover scents & attractants",
                        label: "Cover Scents Attractants",
                        isLeaf: true,
                      },
                      {
                        value: "hunting decoys",
                        label: "Hunting Decoys",
                        isLeaf: true,
                      },
                      {
                        value: "hunting bait, feed & minerals",
                        label: "Hunting Bait Feed Minerals",
                        isLeaf: true,
                      },
                      {
                        value: "game feeders",
                        label: "Game Feeders",
                        isLeaf: true,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            value: "fishing",
            label: "Fishing",
            children: [
              {
                value: "tackle bags & boxes",
                label: "Tackle Bags Boxes",
                isLeaf: true,
              },
              {
                value: "fishing waders",
                label: "Fishing Waders",
                isLeaf: true,
              },
              {
                value: "fishing tackle",
                label: "Fishing Tackle",
                children: [
                  {
                    value: "fishing floats",
                    label: "Fishing Floats",
                    isLeaf: true,
                  },
                  {
                    value: "fishing snaps & swivels",
                    label: "Fishing Snaps Swivels",
                    isLeaf: true,
                  },
                  {
                    value: "fishing sinkers",
                    label: "Fishing Sinkers",
                    isLeaf: true,
                  },
                  {
                    value: "fishing lines & leaders",
                    label: "Fishing Lines Leaders",
                    isLeaf: true,
                  },
                  {
                    value: "fishing hooks",
                    label: "Fishing Hooks",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "fishing rods & reels",
                label: "Fishing Rods Reels",
                children: [
                  {
                    value: "fishing reels",
                    label: "Fishing Reels",
                    isLeaf: true,
                  },
                  {
                    value: "fishing rods",
                    label: "Fishing Rods",
                    isLeaf: true,
                  },
                  {
                    value: "fishing rod & reel combos",
                    label: "Fishing Rod Reel Combos",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "fishing rod & reel accessories",
                label: "Fishing Rod Reel Accessories",
                children: [
                  {
                    value: "fishing reel bags & cases",
                    label: "Fishing Reel Bags Cases",
                    isLeaf: true,
                  },
                  {
                    value: "fishing rod holders & racks",
                    label: "Fishing Rod Holders Racks",
                    isLeaf: true,
                  },
                  {
                    value: "fishing rod bags & cases",
                    label: "Fishing Rod Bags Cases",
                    isLeaf: true,
                  },
                  {
                    value: "fishing reel spools",
                    label: "Fishing Reel Spools",
                    isLeaf: true,
                  },
                  {
                    value: "fishing reel lubricants",
                    label: "Fishing Reel Lubricants",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "fishing equipment",
                label: "Fishing Equipment",
                children: [
                  {
                    value: "bait & fillet knives",
                    label: "Bait Fillet Knives",
                    isLeaf: true,
                  },
                  {
                    value: "pliers & hook removers",
                    label: "Pliers Hook Removers",
                    isLeaf: true,
                  },
                  {
                    value: "fly tying materials",
                    label: "Fly Tying Materials",
                    isLeaf: true,
                  },
                  {
                    value: "fishing traps",
                    label: "Fishing Traps",
                    isLeaf: true,
                  },
                  {
                    value: "fishing spears & gaffs",
                    label: "Fishing Spears Gaffs",
                    isLeaf: true,
                  },
                  {
                    value: "fishing scalers & skinners",
                    label: "Fishing Scalers Skinners",
                    isLeaf: true,
                  },
                  {
                    value: "fishing nets",
                    label: "Fishing Nets",
                    isLeaf: true,
                  },
                  {
                    value: "fishfinders",
                    label: "Fishfinders",
                    isLeaf: true,
                  },
                  {
                    value: "bite alarms",
                    label: "Bite Alarms",
                    isLeaf: true,
                  },
                  {
                    value: "bait buckets",
                    label: "Bait Buckets",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "fishing baits & lures",
                label: "Fishing Baits Lures",
                children: [
                  {
                    value: "attractants",
                    label: "Attractants",
                    isLeaf: true,
                  },
                  {
                    value: "spinnerbaits",
                    label: "Spinnerbaits",
                    isLeaf: true,
                  },
                  {
                    value: "soft baits",
                    label: "Soft Baits",
                    isLeaf: true,
                  },
                  {
                    value: "live bait",
                    label: "Live Bait",
                    isLeaf: true,
                  },
                  {
                    value: "jigs & rigs",
                    label: "Jigs Rigs",
                    isLeaf: true,
                  },
                  {
                    value: "hard baits",
                    label: "Hard Baits",
                    isLeaf: true,
                  },
                  {
                    value: "fishing flies",
                    label: "Fishing Flies",
                    isLeaf: true,
                  },
                  {
                    value: "bait & lure kits",
                    label: "Bait Lure Kits",
                    isLeaf: true,
                  },
                ],
              },
            ],
          },
          {
            value: "water sports & boating",
            label: "Water Sports Boating",
            children: [
              {
                value: "windsurfing",
                label: "Windsurfing",
                children: [
                  {
                    value: "windsurfing boards",
                    label: "Windsurfing Boards",
                    isLeaf: true,
                  },
                  {
                    value: "windsurfing sails",
                    label: "Windsurfing Sails",
                    isLeaf: true,
                  },
                  {
                    value: "windsurfing masts",
                    label: "Windsurfing Masts",
                    isLeaf: true,
                  },
                  {
                    value: "windsurfing fins",
                    label: "Windsurfing Fins",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "water sport apparel & accessories",
                label: "Water Sport Apparel Accessories",
                children: [
                  {
                    value: "drysuits",
                    label: "Drysuits",
                    isLeaf: true,
                  },
                  {
                    value: "wetsuits",
                    label: "Wetsuits",
                    isLeaf: true,
                  },
                  {
                    value: "water shoes",
                    label: "Water Shoes",
                    isLeaf: true,
                  },
                  {
                    value: "swim goggles",
                    label: "Swim Goggles",
                    isLeaf: true,
                  },
                  {
                    value: "swim caps",
                    label: "Swim Caps",
                    isLeaf: true,
                  },
                  {
                    value: "rash guards",
                    label: "Rash Guards",
                    isLeaf: true,
                  },
                  {
                    value: "life vests",
                    label: "Life Vests",
                    isLeaf: true,
                  },
                  {
                    value: "helmets",
                    label: "Helmets",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "water polo",
                label: "Water Polo",
                children: [
                  {
                    value: "caps & head guards",
                    label: "Caps Head Guards",
                    isLeaf: true,
                  },
                  {
                    value: "water polo goals",
                    label: "Water Polo Goals",
                    isLeaf: true,
                  },
                  {
                    value: "water polo balls",
                    label: "Water Polo Balls",
                    isLeaf: true,
                  },
                  {
                    value: "training equipment",
                    label: "Training Equipment",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "wakeboarding & water skiing",
                label: "Wakeboarding Water Skiing",
                children: [
                  {
                    value: "water skis",
                    label: "Water Skis",
                    isLeaf: true,
                  },
                  {
                    value: "kneeboards",
                    label: "Kneeboards",
                    isLeaf: true,
                  },
                  {
                    value: "wakeboarding & water skiing accessories",
                    label: "Wakeboarding Water Skiing Accessories",
                    children: [
                      {
                        value: "sit-down hydrofoils",
                        label: "Sit Down Hydrofoils",
                        isLeaf: true,
                      },
                      {
                        value: "wakeboards",
                        label: "Wakeboards",
                        isLeaf: true,
                      },
                      {
                        value: "wakeboard & water ski cases",
                        label: "Wakeboard Water Ski Cases",
                        isLeaf: true,
                      },
                      {
                        value: "wakeboard & water ski bindings",
                        label: "Wakeboard Water Ski Bindings",
                        isLeaf: true,
                      },
                      {
                        value: "tow cables",
                        label: "Tow Cables",
                        isLeaf: true,
                      },
                    ],
                  },
                  {
                    value: "towable rafts & tubes",
                    label: "Towable Rafts Tubes",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "swimming",
                label: "Swimming",
                children: [
                  {
                    value: "hand paddles",
                    label: "Hand Paddles",
                    isLeaf: true,
                  },
                  {
                    value: "swimming machines",
                    label: "Swimming Machines",
                    isLeaf: true,
                  },
                  {
                    value: "swimming fins",
                    label: "Swimming Fins",
                    isLeaf: true,
                  },
                  {
                    value: "swimming aids",
                    label: "Swimming Aids",
                    isLeaf: true,
                  },
                  {
                    value: "swim weights",
                    label: "Swim Weights",
                    isLeaf: true,
                  },
                  {
                    value: "swim nose clips",
                    label: "Swim Nose Clips",
                    isLeaf: true,
                  },
                  {
                    value: "swim gloves",
                    label: "Swim Gloves",
                    isLeaf: true,
                  },
                  {
                    value: "swim belts",
                    label: "Swim Belts",
                    isLeaf: true,
                  },
                  {
                    value: "pull buoys",
                    label: "Pull Buoys",
                    isLeaf: true,
                  },
                  {
                    value: "kickboards",
                    label: "Kickboards",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "surfing",
                label: "Surfing",
                children: [
                  {
                    value: "bodyboards",
                    label: "Bodyboards",
                    isLeaf: true,
                  },
                  {
                    value: "surfing accessories",
                    label: "Surfing Accessories",
                    children: [
                      {
                        value: "surf leashes",
                        label: "Surf Leashes",
                        isLeaf: true,
                      },
                      {
                        value: "surfing tail pads",
                        label: "Surfing Tail Pads",
                        isLeaf: true,
                      },
                      {
                        value: "surfing gloves",
                        label: "Surfing Gloves",
                        isLeaf: true,
                      },
                      {
                        value: "surfboard wax",
                        label: "Surfboard Wax",
                        isLeaf: true,
                      },
                      {
                        value: "surfboard fins",
                        label: "Surfboard Fins",
                        isLeaf: true,
                      },
                      {
                        value: "surfboard cases",
                        label: "Surfboard Cases",
                        isLeaf: true,
                      },
                      {
                        value: "surfboard car & storage racks",
                        label: "Surfboard Car Storage Racks",
                        isLeaf: true,
                      },
                    ],
                  },
                  {
                    value: "surfboards",
                    label: "Surfboards",
                    isLeaf: true,
                  },
                  {
                    value: "skimboards",
                    label: "Skimboards",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "scuba diving & snorkeling",
                label: "Scuba Diving Snorkeling",
                children: [
                  {
                    value: "buoyancy compensators",
                    label: "Buoyancy Compensators",
                    isLeaf: true,
                  },
                  {
                    value: "snorkels",
                    label: "Snorkels",
                    isLeaf: true,
                  },
                  {
                    value: "sea scooters",
                    label: "Sea Scooters",
                    isLeaf: true,
                  },
                  {
                    value: "diving regulators",
                    label: "Diving Regulators",
                    isLeaf: true,
                  },
                  {
                    value: "diving lights",
                    label: "Diving Lights",
                    isLeaf: true,
                  },
                  {
                    value: "diving knives & shears",
                    label: "Diving Knives Shears",
                    isLeaf: true,
                  },
                  {
                    value: "diving gauges & compasses",
                    label: "Diving Gauges Compasses",
                    isLeaf: true,
                  },
                  {
                    value: "diving flags",
                    label: "Diving Flags",
                    isLeaf: true,
                  },
                  {
                    value: "diving belts",
                    label: "Diving Belts",
                    isLeaf: true,
                  },
                  {
                    value: "diving & snorkeling masks",
                    label: "Diving Snorkeling Masks",
                    isLeaf: true,
                  },
                  {
                    value: "diving & snorkeling fins",
                    label: "Diving Snorkeling Fins",
                    isLeaf: true,
                  },
                  {
                    value: "diving & snorkeling equipment sets",
                    label: "Diving Snorkeling Equipment Sets",
                    isLeaf: true,
                  },
                  {
                    value: "dive computers",
                    label: "Dive Computers",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "kitesurfing",
                label: "Kitesurfing",
                children: [
                  {
                    value: "kiteboard cases",
                    label: "Kiteboard Cases",
                    isLeaf: true,
                  },
                  {
                    value: "kitesurfing kites",
                    label: "Kitesurfing Kites",
                    isLeaf: true,
                  },
                  {
                    value: "kitesurfing harnesses",
                    label: "Kitesurfing Harnesses",
                    isLeaf: true,
                  },
                  {
                    value: "kiteboards",
                    label: "Kiteboards",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "boating, rafting & paddling",
                label: "Boating Rafting Paddling",
                children: [
                  {
                    value: "boat storage racks",
                    label: "Boat Storage Racks",
                    isLeaf: true,
                  },
                  {
                    value: "sailing gloves",
                    label: "Sailing Gloves",
                    isLeaf: true,
                  },
                  {
                    value: "row boats",
                    label: "Row Boats",
                    isLeaf: true,
                  },
                  {
                    value: "rafts",
                    label: "Rafts",
                    isLeaf: true,
                  },
                  {
                    value: "pontoon boats",
                    label: "Pontoon Boats",
                    isLeaf: true,
                  },
                  {
                    value: "pedal boats",
                    label: "Pedal Boats",
                    isLeaf: true,
                  },
                  {
                    value: "paddles & oars",
                    label: "Paddles Oars",
                    isLeaf: true,
                  },
                  {
                    value: "paddleboards",
                    label: "Paddleboards",
                    isLeaf: true,
                  },
                  {
                    value: "kayaks",
                    label: "Kayaks",
                    isLeaf: true,
                  },
                  {
                    value: "canoes",
                    label: "Canoes",
                    isLeaf: true,
                  },
                ],
              },
            ],
          },
          {
            value: "skateboarding",
            label: "Skateboarding",
            children: [
              {
                value: "skateboards & longboards",
                label: "Skateboards Longboards",
                isLeaf: true,
              },
              {
                value: "skateboard ramps & rails",
                label: "Skateboard Ramps Rails",
                isLeaf: true,
              },
              {
                value: "skateboard protective gear",
                label: "Skateboard Protective Gear",
                children: [
                  {
                    value: "skateboarding gloves",
                    label: "Skateboarding Gloves",
                    isLeaf: true,
                  },
                  {
                    value: "skateboarding knee & elbow pads",
                    label: "Skateboarding Knee Elbow Pads",
                    isLeaf: true,
                  },
                  {
                    value: "skateboarding helmets",
                    label: "Skateboarding Helmets",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "skateboard parts",
                label: "Skateboard Parts",
                children: [
                  {
                    value: "skateboard bearings",
                    label: "Skateboard Bearings",
                    isLeaf: true,
                  },
                  {
                    value: "skateboard wheels",
                    label: "Skateboard Wheels",
                    isLeaf: true,
                  },
                  {
                    value: "skateboard trucks",
                    label: "Skateboard Trucks",
                    isLeaf: true,
                  },
                  {
                    value: "skateboard decks",
                    label: "Skateboard Decks",
                    isLeaf: true,
                  },
                ],
              },
            ],
          },
          {
            value: "outdoor games",
            label: "Outdoor Games",
            children: [
              {
                value: "tetherball",
                label: "Tetherball",
                children: [
                  {
                    value: "tetherball poles",
                    label: "Tetherball Poles",
                    isLeaf: true,
                  },
                  {
                    value: "tetherballs",
                    label: "Tetherballs",
                    isLeaf: true,
                  },
                  {
                    value: "tetherball sets",
                    label: "Tetherball Sets",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "lawn games",
                label: "Lawn Games",
                children: [
                  {
                    value: "bocce ball",
                    label: "Bocce Ball",
                    isLeaf: true,
                  },
                  {
                    value: "horseshoes",
                    label: "Horseshoes",
                    isLeaf: true,
                  },
                  {
                    value: "croquet",
                    label: "Croquet",
                    isLeaf: true,
                  },
                  {
                    value: "cornhole bag toss",
                    label: "Cornhole Bag Toss",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "disc golf",
                label: "Disc Golf",
                children: [
                  {
                    value: "disc golf bags",
                    label: "Disc Golf Bags",
                    isLeaf: true,
                  },
                  {
                    value: "disc golf targets",
                    label: "Disc Golf Targets",
                    isLeaf: true,
                  },
                  {
                    value: "disc golf sets",
                    label: "Disc Golf Sets",
                    isLeaf: true,
                  },
                  {
                    value: "disc golf discs",
                    label: "Disc Golf Discs",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "deck shuffleboard",
                label: "Deck Shuffleboard",
                children: [
                  {
                    value: "deck shuffleboard courts",
                    label: "Deck Shuffleboard Courts",
                    isLeaf: true,
                  },
                  {
                    value: "deck shuffleboard pucks",
                    label: "Deck Shuffleboard Pucks",
                    isLeaf: true,
                  },
                  {
                    value: "deck shuffleboard cues",
                    label: "Deck Shuffleboard Cues",
                    isLeaf: true,
                  },
                ],
              },
            ],
          },
          {
            value: "cycling",
            label: "Cycling",
            children: [
              {
                value: "unicycles",
                label: "Unicycles",
                isLeaf: true,
              },
              {
                value: "tricycles",
                label: "Tricycles",
                isLeaf: true,
              },
              {
                value: "cycling shoes & protective gear",
                label: "Cycling Shoes Protective Gear",
                children: [
                  {
                    value: "bicycle shoe covers",
                    label: "Bicycle Shoe Covers",
                    isLeaf: true,
                  },
                  {
                    value: "bicycle protective pads",
                    label: "Bicycle Protective Pads",
                    isLeaf: true,
                  },
                  {
                    value: "bicycle helmets",
                    label: "Bicycle Helmets",
                    isLeaf: true,
                  },
                  {
                    value: "bicycle gloves",
                    label: "Bicycle Gloves",
                    isLeaf: true,
                  },
                  {
                    value: "bicycle cleats",
                    label: "Bicycle Cleats",
                    isLeaf: true,
                  },
                  {
                    value: "bicycle cleat accessories",
                    label: "Bicycle Cleat Accessories",
                    children: [
                      {
                        value: "bicycle cleat bolts",
                        label: "Bicycle Cleat Bolts",
                        isLeaf: true,
                      },
                      {
                        value: "bicycle cleat shims & wedges",
                        label: "Bicycle Cleat Shims Wedges",
                        isLeaf: true,
                      },
                      {
                        value: "bicycle cleat covers",
                        label: "Bicycle Cleat Covers",
                        isLeaf: true,
                      },
                    ],
                  },
                ],
              },
              {
                value: "cycling clothing",
                label: "Cycling Clothing",
                children: [
                  {
                    value: "bicycle bibs",
                    label: "Bicycle Bibs",
                    isLeaf: true,
                  },
                  {
                    value: "bicycle tights",
                    label: "Bicycle Tights",
                    isLeaf: true,
                  },
                  {
                    value: "bicycle skinsuits",
                    label: "Bicycle Skinsuits",
                    isLeaf: true,
                  },
                  {
                    value: "bicycle shorts & briefs",
                    label: "Bicycle Shorts Briefs",
                    isLeaf: true,
                  },
                  {
                    value: "bicycle jerseys",
                    label: "Bicycle Jerseys",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "bicycles",
                label: "Bicycles",
                isLeaf: true,
              },
              {
                value: "bicycle parts",
                label: "Bicycle Parts",
                children: [
                  {
                    value: "bicycle wheels & wheel parts",
                    label: "Bicycle Wheels Wheel Parts",
                    children: [
                      {
                        value: "bicycle foot pegs",
                        label: "Bicycle Foot Pegs",
                        isLeaf: true,
                      },
                      {
                        value: "bicycle wheels",
                        label: "Bicycle Wheels",
                        isLeaf: true,
                      },
                      {
                        value: "bicycle wheel rims",
                        label: "Bicycle Wheel Rims",
                        isLeaf: true,
                      },
                      {
                        value: "bicycle wheel nipples",
                        label: "Bicycle Wheel Nipples",
                        isLeaf: true,
                      },
                      {
                        value: "bicycle wheel axles & skewers",
                        label: "Bicycle Wheel Axles Skewers",
                        isLeaf: true,
                      },
                      {
                        value: "bicycle spokes",
                        label: "Bicycle Spokes",
                        isLeaf: true,
                      },
                      {
                        value: "bicycle rim strips",
                        label: "Bicycle Rim Strips",
                        isLeaf: true,
                      },
                      {
                        value: "bicycle hubs",
                        label: "Bicycle Hubs",
                        isLeaf: true,
                      },
                    ],
                  },
                  {
                    value: "bicycle tires & tire parts",
                    label: "Bicycle Tires Tire Parts",
                    children: [
                      {
                        value: "bicycle tire valve adapters",
                        label: "Bicycle Tire Valve Adapters",
                        isLeaf: true,
                      },
                      {
                        value: "bicycle tubes",
                        label: "Bicycle Tubes",
                        isLeaf: true,
                      },
                      {
                        value: "bicycle tires",
                        label: "Bicycle Tires",
                        isLeaf: true,
                      },
                      {
                        value: "bicycle tire valves",
                        label: "Bicycle Tire Valves",
                        isLeaf: true,
                      },
                      {
                        value: "bicycle tire valve caps",
                        label: "Bicycle Tire Valve Caps",
                        isLeaf: true,
                      },
                    ],
                  },
                  {
                    value: "bicycle suspension parts",
                    label: "Bicycle Suspension Parts",
                    children: [
                      {
                        value: "bicycle forks",
                        label: "Bicycle Forks",
                        isLeaf: true,
                      },
                      {
                        value: "bicycle shocks",
                        label: "Bicycle Shocks",
                        isLeaf: true,
                      },
                      {
                        value: "bicycle shock pumps",
                        label: "Bicycle Shock Pumps",
                        isLeaf: true,
                      },
                    ],
                  },
                  {
                    value: "bicycle stems",
                    label: "Bicycle Stems",
                    isLeaf: true,
                  },
                  {
                    value: "bicycle saddles & seatposts",
                    label: "Bicycle Saddles Seatposts",
                    children: [
                      {
                        value: "bicycle saddles",
                        label: "Bicycle Saddles",
                        isLeaf: true,
                      },
                      {
                        value: "bicycle seatposts",
                        label: "Bicycle Seatposts",
                        isLeaf: true,
                      },
                      {
                        value: "bicycle seatpost clamps",
                        label: "Bicycle Seatpost Clamps",
                        isLeaf: true,
                      },
                    ],
                  },
                  {
                    value: "bicycle kickstands",
                    label: "Bicycle Kickstands",
                    isLeaf: true,
                  },
                  {
                    value: "bicycle headsets & headset parts",
                    label: "Bicycle Headsets Headset Parts",
                    children: [
                      {
                        value: "bicycle headset bearings",
                        label: "Bicycle Headset Bearings",
                        isLeaf: true,
                      },
                      {
                        value: "bicycle headsets",
                        label: "Bicycle Headsets",
                        isLeaf: true,
                      },
                      {
                        value: "bicycle headset spacers",
                        label: "Bicycle Headset Spacers",
                        isLeaf: true,
                      },
                    ],
                  },
                  {
                    value: "bicycle handlebars & accessories",
                    label: "Bicycle Handlebars Accessories",
                    children: [
                      {
                        value: "bicycle handlebar extensions",
                        label: "Bicycle Handlebar Extensions",
                        isLeaf: true,
                      },
                      {
                        value: "bicycle handlebars",
                        label: "Bicycle Handlebars",
                        isLeaf: true,
                      },
                      {
                        value: "bicycle handlebar grips & pads",
                        label: "Bicycle Handlebar Grips Pads",
                        isLeaf: true,
                      },
                    ],
                  },
                  {
                    value: "bicycle frames",
                    label: "Bicycle Frames",
                    isLeaf: true,
                  },
                  {
                    value: "bicycle drivetrain parts",
                    label: "Bicycle Drivetrain Parts",
                    children: [
                      {
                        value: "bicycle bottom brackets",
                        label: "Bicycle Bottom Brackets",
                        isLeaf: true,
                      },
                      {
                        value: "bicycle shifters",
                        label: "Bicycle Shifters",
                        isLeaf: true,
                      },
                      {
                        value: "bicycle pedals",
                        label: "Bicycle Pedals",
                        isLeaf: true,
                      },
                      {
                        value: "bicycle groupsets",
                        label: "Bicycle Groupsets",
                        isLeaf: true,
                      },
                      {
                        value: "bicycle derailleurs",
                        label: "Bicycle Derailleurs",
                        isLeaf: true,
                      },
                      {
                        value: "bicycle cranks",
                        label: "Bicycle Cranks",
                        isLeaf: true,
                      },
                      {
                        value: "bicycle chains",
                        label: "Bicycle Chains",
                        isLeaf: true,
                      },
                      {
                        value: "bicycle chainrings",
                        label: "Bicycle Chainrings",
                        isLeaf: true,
                      },
                      {
                        value: "bicycle chain guards",
                        label: "Bicycle Chain Guards",
                        isLeaf: true,
                      },
                      {
                        value: "bicycle cassettes & freewheels",
                        label: "Bicycle Cassettes Freewheels",
                        isLeaf: true,
                      },
                    ],
                  },
                  {
                    value: "bicycle cables & housings",
                    label: "Bicycle Cables Housings",
                    isLeaf: true,
                  },
                  {
                    value: "bicycle brake parts",
                    label: "Bicycle Brake Parts",
                    children: [
                      {
                        value: "bicycle brake calipers",
                        label: "Bicycle Brake Calipers",
                        isLeaf: true,
                      },
                      {
                        value: "bicycle brakes",
                        label: "Bicycle Brakes",
                        isLeaf: true,
                      },
                      {
                        value: "bicycle brake rotors",
                        label: "Bicycle Brake Rotors",
                        isLeaf: true,
                      },
                      {
                        value: "bicycle brake levers",
                        label: "Bicycle Brake Levers",
                        isLeaf: true,
                      },
                    ],
                  },
                ],
              },
              {
                value: "bicycle accessories",
                label: "Bicycle Accessories",
                children: [
                  {
                    value: "electric bicycle conversion kits",
                    label: "Electric Bicycle Conversion Kits",
                    isLeaf: true,
                  },
                  {
                    value: "bicycle water sport board racks",
                    label: "Bicycle Water Sport Board Racks",
                    isLeaf: true,
                  },
                  {
                    value: "bicycle water bottle cages",
                    label: "Bicycle Water Bottle Cages",
                    isLeaf: true,
                  },
                  {
                    value: "bicycle transport bags & cases",
                    label: "Bicycle Transport Bags Cases",
                    isLeaf: true,
                  },
                  {
                    value: "bicycle training wheels",
                    label: "Bicycle Training Wheels",
                    isLeaf: true,
                  },
                  {
                    value: "bicycle trainers",
                    label: "Bicycle Trainers",
                    isLeaf: true,
                  },
                  {
                    value: "bicycle trailers",
                    label: "Bicycle Trailers",
                    isLeaf: true,
                  },
                  {
                    value: "bicycle tools",
                    label: "Bicycle Tools",
                    isLeaf: true,
                  },
                  {
                    value: "bicycle toe straps & clips",
                    label: "Bicycle Toe Straps Clips",
                    isLeaf: true,
                  },
                  {
                    value: "bicycle tire repair supplies & kits",
                    label: "Bicycle Tire Repair Supplies Kits",
                    isLeaf: true,
                  },
                  {
                    value: "bicycle stands & storage",
                    label: "Bicycle Stands Storage",
                    isLeaf: true,
                  },
                  {
                    value: "bicycle spoke beads",
                    label: "Bicycle Spoke Beads",
                    isLeaf: true,
                  },
                  {
                    value: "bicycle saddle pads & seat covers",
                    label: "Bicycle Saddle Pads Seat Covers",
                    isLeaf: true,
                  },
                  {
                    value: "bicycle pumps",
                    label: "Bicycle Pumps",
                    isLeaf: true,
                  },
                  {
                    value: "bicycle mirrors",
                    label: "Bicycle Mirrors",
                    isLeaf: true,
                  },
                  {
                    value: "bicycle bags & panniers",
                    label: "Bicycle Bags Panniers",
                    isLeaf: true,
                  },
                  {
                    value: "bicycle locks",
                    label: "Bicycle Locks",
                    isLeaf: true,
                  },
                  {
                    value: "bicycle lights & reflectors",
                    label: "Bicycle Lights Reflectors",
                    isLeaf: true,
                  },
                  {
                    value: "bicycle front & rear racks",
                    label: "Bicycle Front Rear Racks",
                    isLeaf: true,
                  },
                  {
                    value: "bicycle fenders",
                    label: "Bicycle Fenders",
                    isLeaf: true,
                  },
                  {
                    value: "bicycle covers",
                    label: "Bicycle Covers",
                    isLeaf: true,
                  },
                  {
                    value: "bicycle computers",
                    label: "Bicycle Computers",
                    isLeaf: true,
                  },
                  {
                    value: "bicycle child seats",
                    label: "Bicycle Child Seats",
                    isLeaf: true,
                  },
                  {
                    value: "bicycle cages",
                    label: "Bicycle Cages",
                    isLeaf: true,
                  },
                  {
                    value: "bicycle bells & horns",
                    label: "Bicycle Bells Horns",
                    isLeaf: true,
                  },
                  {
                    value: "bicycle baskets",
                    label: "Bicycle Baskets",
                    isLeaf: true,
                  },
                ],
              },
            ],
          },
          {
            value: "winter sports",
            label: "Winter Sports",
            children: [
              {
                value: "avalanche safety",
                label: "Avalanche Safety",
                children: [
                  {
                    value: "avalanche safety airbags",
                    label: "Avalanche Safety Airbags",
                    isLeaf: true,
                  },
                  {
                    value: "avalanche probes",
                    label: "Avalanche Probes",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "winter clothing",
                label: "Winter Clothing",
                children: [
                  {
                    value: "goggles & masks",
                    label: "Goggles Masks",
                    isLeaf: true,
                  },
                  {
                    value: "snow suits",
                    label: "Snow Suits",
                    isLeaf: true,
                  },
                  {
                    value: "rain suits",
                    label: "Rain Suits",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "snowshoes",
                label: "Snowshoes",
                isLeaf: true,
              },
              {
                value: "snowball makers",
                label: "Snowball Makers",
                isLeaf: true,
              },
              {
                value: "sleds & snow tubes",
                label: "Sleds Snow Tubes",
                isLeaf: true,
              },
              {
                value: "skiing & snowboarding",
                label: "Skiing Snowboarding",
                children: [
                  {
                    value: "snowboarding",
                    label: "Snowboarding",
                    children: [
                      {
                        value: "snowboard bindings",
                        label: "Snowboard Bindings",
                        isLeaf: true,
                      },
                      {
                        value: "snowboards",
                        label: "Snowboards",
                        isLeaf: true,
                      },
                      {
                        value: "snowboard boots",
                        label: "Snowboard Boots",
                        isLeaf: true,
                      },
                    ],
                  },
                  {
                    value: "skiing",
                    label: "Skiing",
                    children: [
                      {
                        value: "ski bindings",
                        label: "Ski Bindings",
                        isLeaf: true,
                      },
                      {
                        value: "skis",
                        label: "Skis",
                        isLeaf: true,
                      },
                      {
                        value: "ski poles",
                        label: "Ski Poles",
                        isLeaf: true,
                      },
                      {
                        value: "ski boots",
                        label: "Ski Boots",
                        isLeaf: true,
                      },
                    ],
                  },
                  {
                    value: "ski & snowboard accessories",
                    label: "Ski Snowboard Accessories",
                    children: [
                      {
                        value: "ski & snowboard leashes",
                        label: "Ski Snowboard Leashes",
                        isLeaf: true,
                      },
                      {
                        value: "ski & snowboard helmets",
                        label: "Ski Snowboard Helmets",
                        isLeaf: true,
                      },
                      {
                        value: "ski & snowboard bags",
                        label: "Ski Snowboard Bags",
                        isLeaf: true,
                      },
                      {
                        value: "ski & snowboard storage racks",
                        label: "Ski Snowboard Storage Racks",
                        isLeaf: true,
                      },
                      {
                        value: "ski & snowboard wax",
                        label: "Ski Snowboard Wax",
                        isLeaf: true,
                      },
                      {
                        value: "ski & snowboard tuning supplies",
                        label: "Ski Snowboard Tuning Supplies",
                        isLeaf: true,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            value: "climbing",
            label: "Climbing",
            children: [
              {
                value: "clothing & shoes",
                label: "Clothing Shoes",
                isLeaf: true,
              },
              {
                value: "climbing hardware",
                label: "Climbing Hardware",
                children: [
                  {
                    value: "ascenders & descenders",
                    label: "Ascenders Descenders",
                    isLeaf: true,
                  },
                  {
                    value: "quickdraws",
                    label: "Quickdraws",
                    isLeaf: true,
                  },
                  {
                    value: "pulleys",
                    label: "Pulleys",
                    isLeaf: true,
                  },
                  {
                    value: "indoor climbing holds",
                    label: "Indoor Climbing Holds",
                    isLeaf: true,
                  },
                  {
                    value: "climbing rope & webbing",
                    label: "Climbing Rope Webbing",
                    isLeaf: true,
                  },
                  {
                    value: "climbing harnesses",
                    label: "Climbing Harnesses",
                    isLeaf: true,
                  },
                  {
                    value: "carabiners",
                    label: "Carabiners",
                    isLeaf: true,
                  },
                  {
                    value: "cams & nuts",
                    label: "Cams Nuts",
                    isLeaf: true,
                  },
                  {
                    value: "belay & rappel devices",
                    label: "Belay Rappel Devices",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "climbing accessories",
                label: "Climbing Accessories",
                children: [
                  {
                    value: "chalk bags",
                    label: "Chalk Bags",
                    isLeaf: true,
                  },
                  {
                    value: "rope bags",
                    label: "Rope Bags",
                    isLeaf: true,
                  },
                  {
                    value: "crash pads & mats",
                    label: "Crash Pads Mats",
                    isLeaf: true,
                  },
                  {
                    value: "climbing tape",
                    label: "Climbing Tape",
                    isLeaf: true,
                  },
                  {
                    value: "climbing helmets",
                    label: "Climbing Helmets",
                    isLeaf: true,
                  },
                  {
                    value: "climbing gloves",
                    label: "Climbing Gloves",
                    isLeaf: true,
                  },
                  {
                    value: "climbing brushes",
                    label: "Climbing Brushes",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "ice climbing",
                label: "Ice Climbing",
                children: [
                  {
                    value: "crampons",
                    label: "Crampons",
                    isLeaf: true,
                  },
                  {
                    value: "ice axes",
                    label: "Ice Axes",
                    isLeaf: true,
                  },
                  {
                    value: "ice screws",
                    label: "Ice Screws",
                    isLeaf: true,
                  },
                ],
              },
            ],
          },
          {
            value: "inline & roller skating",
            label: "Inline Roller Skating",
            children: [
              {
                value: "skate wheels",
                label: "Skate Wheels",
                isLeaf: true,
              },
              {
                value: "roller skis",
                label: "Roller Skis",
                isLeaf: true,
              },
              {
                value: "roller skates",
                label: "Roller Skates",
                isLeaf: true,
              },
              {
                value: "inline skates",
                label: "Inline Skates",
                isLeaf: true,
              },
              {
                value: "protective gear",
                label: "Protective Gear",
                children: [
                  {
                    value: "helmets",
                    label: "Helmets",
                    isLeaf: true,
                  },
                  {
                    value: "wrist guards",
                    label: "Wrist Guards",
                    isLeaf: true,
                  },
                  {
                    value: "protective gear sets",
                    label: "Protective Gear Sets",
                    isLeaf: true,
                  },
                  {
                    value: "knee & elbow pads",
                    label: "Knee Elbow Pads",
                    isLeaf: true,
                  },
                ],
              },
            ],
          },
          {
            value: "camping & hiking",
            label: "Camping Hiking",
            children: [
              {
                value: "trekking poles",
                label: "Trekking Poles",
                isLeaf: true,
              },
              {
                value: "tents & camping shelters",
                label: "Tents Camping Shelters",
                isLeaf: true,
              },
              {
                value: "tent accessories",
                label: "Tent Accessories",
                children: [
                  {
                    value: "inner tents",
                    label: "Inner Tents",
                    isLeaf: true,
                  },
                  {
                    value: "windbreaks",
                    label: "Windbreaks",
                    isLeaf: true,
                  },
                  {
                    value: "tent vestibules",
                    label: "Tent Vestibules",
                    isLeaf: true,
                  },
                  {
                    value: "tent poles & stakes",
                    label: "Tent Poles Stakes",
                    isLeaf: true,
                  },
                  {
                    value: "tent footprints",
                    label: "Tent Footprints",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "portable toilets & showers",
                label: "Portable Toilets Showers",
                children: [
                  {
                    value: "portable showers & privacy enclosures",
                    label: "Portable Showers Privacy Enclosures",
                    isLeaf: true,
                  },
                  {
                    value: "portable urination devices",
                    label: "Portable Urination Devices",
                    isLeaf: true,
                  },
                  {
                    value: "portable toilets",
                    label: "Portable Toilets",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "outdoor sleeping gear",
                label: "Outdoor Sleeping Gear",
                children: [
                  {
                    value: "compression sacks",
                    label: "Compression Sacks",
                    isLeaf: true,
                  },
                  {
                    value: "sleeping mats & pads",
                    label: "Sleeping Mats Pads",
                    isLeaf: true,
                  },
                  {
                    value: "sleeping bags",
                    label: "Sleeping Bags",
                    isLeaf: true,
                  },
                  {
                    value: "sleeping bag liners",
                    label: "Sleeping Bag Liners",
                    isLeaf: true,
                  },
                  {
                    value: "hammocks",
                    label: "Hammocks",
                    isLeaf: true,
                  },
                  {
                    value: "cots",
                    label: "Cots",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "hiking backpacks",
                label: "Hiking Backpacks",
                children: [
                  {
                    value: "backpacking packs",
                    label: "Backpacking Packs",
                    isLeaf: true,
                  },
                  {
                    value: "hydration packs",
                    label: "Hydration Packs",
                    isLeaf: true,
                  },
                  {
                    value: "dry bags & boxes",
                    label: "Dry Bags Boxes",
                    isLeaf: true,
                  },
                  {
                    value: "daypacks",
                    label: "Daypacks",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "camping tools",
                label: "Camping Tools",
                children: [
                  {
                    value: "camping axes & hatchets",
                    label: "Camping Axes Hatchets",
                    isLeaf: true,
                  },
                  {
                    value: "survival knives",
                    label: "Survival Knives",
                    isLeaf: true,
                  },
                  {
                    value: "multitools",
                    label: "Multitools",
                    isLeaf: true,
                  },
                  {
                    value: "camping trowels",
                    label: "Camping Trowels",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "camping lights & lanterns",
                label: "Camping Lights Lanterns",
                children: [
                  {
                    value: "flashlights",
                    label: "Flashlights",
                    isLeaf: true,
                  },
                  {
                    value: "lanterns",
                    label: "Lanterns",
                    isLeaf: true,
                  },
                  {
                    value: "headlamps",
                    label: "Headlamps",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "camping gear",
                label: "Camping Gear",
                children: [
                  {
                    value: "camping chairs",
                    label: "Camping Chairs",
                    isLeaf: true,
                  },
                  {
                    value: "water filters & purifiers",
                    label: "Water Filters Purifiers",
                    isLeaf: true,
                  },
                  {
                    value: "survival & safety whistles",
                    label: "Survival Safety Whistles",
                    isLeaf: true,
                  },
                  {
                    value: "paracord",
                    label: "Paracord",
                    isLeaf: true,
                  },
                  {
                    value: "mosquito nets & insect screens",
                    label: "Mosquito Nets Insect Screens",
                    isLeaf: true,
                  },
                  {
                    value: "insect repellent",
                    label: "Insect Repellent",
                    isLeaf: true,
                  },
                  {
                    value: "hand warmers",
                    label: "Hand Warmers",
                    isLeaf: true,
                  },
                  {
                    value: "gaiters",
                    label: "Gaiters",
                    isLeaf: true,
                  },
                  {
                    value: "fire starters",
                    label: "Fire Starters",
                    isLeaf: true,
                  },
                  {
                    value: "compasses",
                    label: "Compasses",
                    isLeaf: true,
                  },
                  {
                    value: "camping heaters",
                    label: "Camping Heaters",
                    isLeaf: true,
                  },
                  {
                    value: "camping generators",
                    label: "Camping Generators",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "camping cooking supplies",
                label: "Camping Cooking Supplies",
                children: [
                  {
                    value: "camping cookware",
                    label: "Camping Cookware",
                    isLeaf: true,
                  },
                  {
                    value: "water carriers",
                    label: "Water Carriers",
                    isLeaf: true,
                  },
                  {
                    value: "cooking utensils",
                    label: "Cooking Utensils",
                    isLeaf: true,
                  },
                  {
                    value: "camping tableware & dinnerware",
                    label: "Camping Tableware Dinnerware",
                    isLeaf: true,
                  },
                  {
                    value: "camping stoves & burners",
                    label: "Camping Stoves Burners",
                    isLeaf: true,
                  },
                  {
                    value: "camping ovens",
                    label: "Camping Ovens",
                    isLeaf: true,
                  },
                  {
                    value: "camping kettles & coffee pots",
                    label: "Camping Kettles Coffee Pots",
                    isLeaf: true,
                  },
                  {
                    value: "camping grills",
                    label: "Camping Grills",
                    isLeaf: true,
                  },
                  {
                    value: "camping food",
                    label: "Camping Food",
                    isLeaf: true,
                  },
                  {
                    value: "camping coolers",
                    label: "Camping Coolers",
                    isLeaf: true,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },

  {
    value: "pet supplies",
    label: "Pet Supplies",
    children: [
      {
        value: "pet carriers & containment",
        label: "Pet Carriers Containment",
        isLeaf: true,
      },
      {
        value: "pet grooming supplies",
        label: "Pet Grooming Supplies",
        isLeaf: true,
      },
      {
        value: "small animal supplies",
        label: "Small Animal Supplies",
        isLeaf: true,
      },
      {
        value: "pet feeding & watering supplies",
        label: "Pet Feeding Watering Supplies",
        isLeaf: true,
      },
      {
        value: "reptile & amphibian supplies",
        label: "Reptile Amphibian Supplies",
        isLeaf: true,
      },
      {
        value: "pet collars, harnesses & leashes",
        label: "Pet Collars Harnesses Leashes",
        isLeaf: true,
      },
      {
        value: "pet steps & ramps",
        label: "Pet Steps Ramps",
        isLeaf: true,
      },
      {
        value: "bird supplies",
        label: "Bird Supplies",
        isLeaf: true,
      },
      {
        value: "pet care & health",
        label: "Pet Care Health",
        isLeaf: true,
      },
      {
        value: "pet beds & bedding",
        label: "Pet Beds Bedding",
        isLeaf: true,
      },
      {
        value: "fish supplies",
        label: "Fish Supplies",
        isLeaf: true,
      },
      {
        value: "cat supplies",
        label: "Cat Supplies",
        isLeaf: true,
      },
      {
        value: "dog supplies",
        label: "Dog Supplies",
        isLeaf: true,
      },
    ],
  },

  {
    value: "patio & garden",
    label: "Patio Garden",
    children: [
      {
        value: "fencing",
        label: "Fencing",
        children: [
          {
            value: "protective gear",
            label: "Protective Gear",
            children: [
              {
                value: "chest protectors",
                label: "Chest Protectors",
                isLeaf: true,
              },
              {
                value: "uniforms",
                label: "Uniforms",
                isLeaf: true,
              },
              {
                value: "masks",
                label: "Masks",
                isLeaf: true,
              },
              {
                value: "jackets & lames",
                label: "Jackets Lames",
                isLeaf: true,
              },
              {
                value: "gloves & cuffs",
                label: "Gloves Cuffs",
                isLeaf: true,
              },
            ],
          },
          {
            value: "fencing weapons",
            label: "Fencing Weapons",
            isLeaf: true,
          },
          {
            value: "chain link fencing",
            label: "Chain Link Fencing",
            isLeaf: true,
          },
          {
            value: "trellises",
            label: "Trellises",
            isLeaf: true,
          },
          {
            value: "railings",
            label: "Railings",
            isLeaf: true,
          },
          {
            value: "privacy screens & windscreens",
            label: "Privacy Screens Windscreens",
            isLeaf: true,
          },
          {
            value: "post caps & drivers",
            label: "Post Caps Drivers",
            isLeaf: true,
          },
          {
            value: "post bases, supports & spikes",
            label: "Post Bases Supports Spikes",
            isLeaf: true,
          },
          {
            value: "hardware cloth & metal mesh",
            label: "Hardware Cloth Metal Mesh",
            isLeaf: true,
          },
          {
            value: "gates",
            label: "Gates",
            isLeaf: true,
          },
          {
            value: "fence posts",
            label: "Fence Posts",
            isLeaf: true,
          },
          {
            value: "fence pickets",
            label: "Fence Pickets",
            isLeaf: true,
          },
          {
            value: "fence panels",
            label: "Fence Panels",
            isLeaf: true,
          },
        ],
      },
      {
        value: "bird & wildlife accessories",
        label: "Bird Wildlife Accessories",
        children: [
          {
            value: "wildlife houses",
            label: "Wildlife Houses",
            isLeaf: true,
          },
          {
            value: "squirrel feeders",
            label: "Squirrel Feeders",
            isLeaf: true,
          },
          {
            value: "butterfly feeders",
            label: "Butterfly Feeders",
            isLeaf: true,
          },
          {
            value: "birdhouses",
            label: "Birdhouses",
            isLeaf: true,
          },
          {
            value: "birdbaths",
            label: "Birdbaths",
            isLeaf: true,
          },
          {
            value: "bird feeders",
            label: "Bird Feeders",
            isLeaf: true,
          },
        ],
      },

      {
        value: "structures",
        label: "Structures",
        children: [
          {
            value: "arbors & arches",
            label: "Arbors Arches",
            isLeaf: true,
          },
          {
            value: "umbrellas",
            label: "Umbrellas",
            isLeaf: true,
          },
          {
            value: "storage sheds",
            label: "Storage Sheds",
            isLeaf: true,
          },
          {
            value: "shade sails",
            label: "Shade Sails",
            isLeaf: true,
          },
          {
            value: "pergolas",
            label: "Pergolas",
            isLeaf: true,
          },
          {
            value: "marquees & tents",
            label: "Marquees Tents",
            isLeaf: true,
          },
          {
            value: "greenhouses",
            label: "Greenhouses",
            isLeaf: true,
          },
          {
            value: "gazebos",
            label: "Gazebos",
            isLeaf: true,
          },
          {
            value: "bridges",
            label: "Bridges",
            isLeaf: true,
          },
          {
            value: "awnings & canopies",
            label: "Awnings Canopies",
            isLeaf: true,
          },
        ],
      },

      {
        value: "gardening supplies",
        label: "Gardening Supplies",
        children: [
          {
            value: "hydroponics & seed starting",
            label: "Hydroponics Seed Starting",
            children: [
              {
                value: "environmental controls",
                label: "Environmental Controls",
                isLeaf: true,
              },
              {
                value: "tents, tarps & shelves",
                label: "Tents Tarps Shelves",
                isLeaf: true,
              },
              {
                value: "nutrients, ph & supplements",
                label: "Nutrients Ph Supplements",
                isLeaf: true,
              },
              {
                value: "hydroponic systems",
                label: "Hydroponic Systems",
                isLeaf: true,
              },
              {
                value: "hydroponic parts & accessories",
                label: "Hydroponic Parts Accessories",
                isLeaf: true,
              },
              {
                value: "growing media",
                label: "Growing Media",
                isLeaf: true,
              },
              {
                value: "grow light parts & accessories",
                label: "Grow Light Parts Accessories",
                isLeaf: true,
              },
              {
                value: "grow light kits",
                label: "Grow Light Kits",
                isLeaf: true,
              },
              {
                value: "grow light bulbs",
                label: "Grow Light Bulbs",
                isLeaf: true,
              },
              {
                value: "grow light ballasts",
                label: "Grow Light Ballasts",
                isLeaf: true,
              },
            ],
          },
          {
            value: "garden clothing & gear",
            label: "Garden Clothing Gear",
            children: [
              {
                value: "garden kneelers, pads & seats",
                label: "Garden Kneelers Pads Seats",
                isLeaf: true,
              },
              {
                value: "tool carriers, aprons & belts",
                label: "Tool Carriers Aprons Belts",
                isLeaf: true,
              },
              {
                value: "gardening gloves",
                label: "Gardening Gloves",
                isLeaf: true,
              },
              {
                value: "gardening boots & shoes",
                label: "Gardening Boots Shoes",
                isLeaf: true,
              },
            ],
          },
          {
            value: "composting & yard waste",
            label: "Composting Yard Waste",
            children: [
              {
                value: "compost",
                label: "Compost",
                isLeaf: true,
              },
              {
                value: "composters",
                label: "Composters",
                isLeaf: true,
              },
              {
                value: "compost worms",
                label: "Compost Worms",
                isLeaf: true,
              },
              {
                value: "compost waste bags",
                label: "Compost Waste Bags",
                isLeaf: true,
              },
              {
                value: "compost aerators",
                label: "Compost Aerators",
                isLeaf: true,
              },
              {
                value: "compost accelerators",
                label: "Compost Accelerators",
                isLeaf: true,
              },
            ],
          },
          {
            value: "weed & pest control",
            label: "Weed Pest Control",
            children: [
              {
                value: "weed control",
                label: "Weed Control",
                children: [
                  {
                    value: "herbicides & fungicides",
                    label: "Herbicides Fungicides",
                    isLeaf: true,
                  },
                  {
                    value: "weeders",
                    label: "Weeders",
                    isLeaf: true,
                  },
                  {
                    value: "weed preventers",
                    label: "Weed Preventers",
                    isLeaf: true,
                  },
                  {
                    value: "weed control fabric",
                    label: "Weed Control Fabric",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "ultrasonic pest repellers",
                label: "Ultrasonic Pest Repellers",
                isLeaf: true,
              },
              {
                value: "rodenticides & baits",
                label: "Rodenticides Baits",
                isLeaf: true,
              },
              {
                value: "rodent & animal traps",
                label: "Rodent Animal Traps",
                isLeaf: true,
              },
              {
                value: "protective netting/mesh",
                label: "Protective Netting Mesh",
                isLeaf: true,
              },
            ],
          },
          {
            value: "snow removal",
            label: "Snow Removal",
            children: [
              {
                value: "ice melt",
                label: "Ice Melt",
                isLeaf: true,
              },
              {
                value: "snow shovels & ice scrapers",
                label: "Snow Shovels Ice Scrapers",
                isLeaf: true,
              },
            ],
          },
          {
            value: "landscaping materials",
            label: "Landscaping Materials",
            children: [
              {
                value: "landscape fabric",
                label: "Landscape Fabric",
                isLeaf: true,
              },
              {
                value: "anchor pins, staples & stakes",
                label: "Anchor Pins Staples Stakes",
                isLeaf: true,
              },
              {
                value: "synthetic grass",
                label: "Synthetic Grass",
                isLeaf: true,
              },
              {
                value: "sod",
                label: "Sod",
                isLeaf: true,
              },
              {
                value: "paving & decking",
                label: "Paving Decking",
                isLeaf: true,
              },
              {
                value: "landscape rocks & pebbles",
                label: "Landscape Rocks Pebbles",
                isLeaf: true,
              },
              {
                value: "edging & borders",
                label: "Edging Borders",
                isLeaf: true,
              },
            ],
          },
        ],
      },
      {
        value: "watering equipment",
        label: "Watering Equipment",
        children: [
          {
            value: "connectors, clamps & fittings",
            label: "Connectors Clamps Fittings",
            isLeaf: true,
          },
          {
            value: "watering timers & controllers",
            label: "Watering Timers Controllers",
            isLeaf: true,
          },
          {
            value: "watering cans",
            label: "Watering Cans",
            isLeaf: true,
          },
          {
            value: "water storage & rain barrels",
            label: "Water Storage Rain Barrels",
            isLeaf: true,
          },
          {
            value: "water pumps & pressure tanks",
            label: "Water Pumps Pressure Tanks",
            isLeaf: true,
          },
          {
            value: "valves",
            label: "Valves",
            isLeaf: true,
          },
          {
            value: "spigots",
            label: "Spigots",
            isLeaf: true,
          },
          {
            value: "lawn sprinklers",
            label: "Lawn Sprinklers",
            isLeaf: true,
          },
          {
            value: "hoses",
            label: "Hoses",
            isLeaf: true,
          },
          {
            value: "hose reels & storage",
            label: "Hose Reels Storage",
            isLeaf: true,
          },
          {
            value: "hose nozzles & wands",
            label: "Hose Nozzles Wands",
            isLeaf: true,
          },
          {
            value: "hose guides",
            label: "Hose Guides",
            isLeaf: true,
          },
          {
            value: "globes & spikes",
            label: "Globes Spikes",
            isLeaf: true,
          },
          {
            value: "drip irrigation",
            label: "Drip Irrigation",
            isLeaf: true,
          },
        ],
      },
      {
        value: "ponds & water features",
        label: "Ponds Water Features",
        children: [
          {
            value: "outdoor fountains",
            label: "Outdoor Fountains",
            isLeaf: true,
          },
          {
            value: "waterfall boxes & spillways",
            label: "Waterfall Boxes Spillways",
            isLeaf: true,
          },
          {
            value: "ponds",
            label: "Ponds",
            isLeaf: true,
          },
          {
            value: "pond water testing & treatments",
            label: "Pond Water Testing Treatments",
            isLeaf: true,
          },
          {
            value: "pond vacuums",
            label: "Pond Vacuums",
            isLeaf: true,
          },
          {
            value: "pond liners",
            label: "Pond Liners",
            isLeaf: true,
          },
          {
            value: "pond lights",
            label: "Pond Lights",
            isLeaf: true,
          },
          {
            value: "pond filtration equipment",
            label: "Pond Filtration Equipment",
            isLeaf: true,
          },
          {
            value: "pond filter media",
            label: "Pond Filter Media",
            isLeaf: true,
          },
          {
            value: "pond decor",
            label: "Pond Decor",
            isLeaf: true,
          },
          {
            value: "pond de-icers",
            label: "Pond De Icers",
            isLeaf: true,
          },
          {
            value: "pond & fountain pumps",
            label: "Pond Fountain Pumps",
            isLeaf: true,
          },
        ],
      },
      {
        value: "outdoor power equipment",
        label: "Outdoor Power Equipment",
        children: [
          {
            value: "chainsaws",
            label: "Chainsaws",
            isLeaf: true,
          },
          {
            value: "tillers",
            label: "Tillers",
            isLeaf: true,
          },
          {
            value: "sweepers",
            label: "Sweepers",
            isLeaf: true,
          },
          {
            value: "snow blowers",
            label: "Snow Blowers",
            isLeaf: true,
          },
          {
            value: "pressure washers",
            label: "Pressure Washers",
            isLeaf: true,
          },
          {
            value: "outdoor power equipment sets",
            label: "Outdoor Power Equipment Sets",
            isLeaf: true,
          },
          {
            value: "outdoor power equipment batteries",
            label: "Outdoor Power Equipment Batteries",
            isLeaf: true,
          },
          {
            value: "log splitters",
            label: "Log Splitters",
            isLeaf: true,
          },
          {
            value: "leaf blowers & vacuums",
            label: "Leaf Blowers Vacuums",
            isLeaf: true,
          },
          {
            value: "lawn mowers",
            label: "Lawn Mowers",
            isLeaf: true,
          },
          {
            value: "lawn mower parts & accessories",
            label: "Lawn Mower Parts Accessories",
            isLeaf: true,
          },
          {
            value: "hole diggers & augers",
            label: "Hole Diggers Augers",
            isLeaf: true,
          },
          {
            value: "hedge & weed trimmers",
            label: "Hedge Weed Trimmers",
            isLeaf: true,
          },
          {
            value: "grass shears",
            label: "Grass Shears",
            isLeaf: true,
          },
          {
            value: "edgers",
            label: "Edgers",
            isLeaf: true,
          },
          {
            value: "chippers, shredders & mulchers",
            label: "Chippers Shredders Mulchers",
            isLeaf: true,
          },
        ],
      },
      {
        value: "outdoor lighting",
        label: "Outdoor Lighting",
        children: [
          {
            value: "garden & patio torches",
            label: "Garden Patio Torches",
            isLeaf: true,
          },
          {
            value: "outdoor wall & porch lights",
            label: "Outdoor Wall Porch Lights",
            isLeaf: true,
          },
          {
            value: "outdoor string lights",
            label: "Outdoor String Lights",
            isLeaf: true,
          },
          {
            value: "outdoor security & floodlights",
            label: "Outdoor Security Floodlights",
            isLeaf: true,
          },
          {
            value: "outdoor decor candle lanterns",
            label: "Outdoor Decor Candle Lanterns",
            isLeaf: true,
          },
          {
            value: "landscape & walkway lights",
            label: "Landscape Walkway Lights",
            isLeaf: true,
          },
        ],
      },
      {
        value: "barbecues & outdoor cooking",
        label: "Barbecues Outdoor Cooking",
        children: [
          {
            value: "bbq & grill replacement parts",
            label: "Bbq Grill Replacement Parts",
            isLeaf: true,
          },
          {
            value: "fire pits & chimineas",
            label: "Fire Pits Chimineas",
            isLeaf: true,
          },
          {
            value: "barbecues, grills & smokers",
            label: "Barbecues Grills Smokers",
            isLeaf: true,
          },
          {
            value: "barbecue & grill covers",
            label: "Barbecue Grill Covers",
            isLeaf: true,
          },
          {
            value: "bbq tools & accessories",
            label: "Bbq Tools Accessories",
            isLeaf: true,
          },
        ],
      },
      {
        value: "plants, seeds & bulbs",
        label: "Plants Seeds Bulbs",
        children: [
          {
            value: "moss",
            label: "Moss",
            isLeaf: true,
          },
          {
            value: "seeds & bulbs",
            label: "Seeds Bulbs",
            isLeaf: true,
          },
          {
            value: "plants & seedlings",
            label: "Plants Seedlings",
            isLeaf: true,
          },
          {
            value: "plant growing kits",
            label: "Plant Growing Kits",
            isLeaf: true,
          },
          {
            value: "mushrooms, spawns & kits",
            label: "Mushrooms Spawns Kits",
            isLeaf: true,
          },
        ],
      },
      {
        value: "outdoor furniture",
        label: "Outdoor Furniture",
        children: [
          {
            value: "hammocks",
            label: "Hammocks",
            isLeaf: true,
          },
          {
            value: "outdoor tables",
            label: "Outdoor Tables",
            isLeaf: true,
          },
          {
            value: "outdoor storage boxes",
            label: "Outdoor Storage Boxes",
            isLeaf: true,
          },
          {
            value: "outdoor sofas",
            label: "Outdoor Sofas",
            isLeaf: true,
          },
          {
            value: "outdoor ottomans",
            label: "Outdoor Ottomans",
            isLeaf: true,
          },
          {
            value: "outdoor heaters",
            label: "Outdoor Heaters",
            isLeaf: true,
          },
          {
            value: "outdoor furniture sets",
            label: "Outdoor Furniture Sets",
            isLeaf: true,
          },
          {
            value: "outdoor furniture parts & accessories",
            label: "Outdoor Furniture Parts Accessories",
            isLeaf: true,
          },
          {
            value: "outdoor furniture covers",
            label: "Outdoor Furniture Covers",
            isLeaf: true,
          },
          {
            value: "outdoor cushions",
            label: "Outdoor Cushions",
            isLeaf: true,
          },
          {
            value: "outdoor chairs, benches & swings",
            label: "Outdoor Chairs Benches Swings",
            isLeaf: true,
          },
          {
            value: "outdoor blankets",
            label: "Outdoor Blankets",
            isLeaf: true,
          },
          {
            value: "outdoor beds",
            label: "Outdoor Beds",
            isLeaf: true,
          },
        ],
      },
      {
        value: "pools & spas",
        label: "Pools Spas",
        children: [
          {
            value: "swimming pools",
            label: "Swimming Pools",
            isLeaf: true,
          },
          {
            value: "spas & hot tubs",
            label: "Spas Hot Tubs",
            isLeaf: true,
          },
          {
            value: "spa & hot tub covers",
            label: "Spa Hot Tub Covers",
            isLeaf: true,
          },
          {
            value: "saunas",
            label: "Saunas",
            isLeaf: true,
          },
          {
            value: "sauna heaters",
            label: "Sauna Heaters",
            isLeaf: true,
          },
          {
            value: "pool toys",
            label: "Pool Toys",
            isLeaf: true,
          },
          {
            value: "pool floats & rafts",
            label: "Pool Floats Rafts",
            isLeaf: true,
          },
          {
            value: "pool equipment & parts",
            label: "Pool Equipment Parts",
            children: [
              {
                value: "pool & spa lights",
                label: "Pool Spa Lights",
                isLeaf: true,
              },
              {
                value: "pool slides & diving boards",
                label: "Pool Slides Diving Boards",
                isLeaf: true,
              },
              {
                value: "pool skimmer systems & baskets",
                label: "Pool Skimmer Systems Baskets",
                isLeaf: true,
              },
              {
                value: "pool pumps",
                label: "Pool Pumps",
                isLeaf: true,
              },
              {
                value: "pool liners",
                label: "Pool Liners",
                isLeaf: true,
              },
              {
                value: "pool ladders & steps",
                label: "Pool Ladders Steps",
                isLeaf: true,
              },
              {
                value: "pool heaters & solar panels",
                label: "Pool Heaters Solar Panels",
                isLeaf: true,
              },
              {
                value: "pool filters",
                label: "Pool Filters",
                isLeaf: true,
              },
              {
                value: "pool fences",
                label: "Pool Fences",
                isLeaf: true,
              },
              {
                value: "pool covers & rollers",
                label: "Pool Covers Rollers",
                isLeaf: true,
              },
              {
                value: "pool chlorine generators",
                label: "Pool Chlorine Generators",
                isLeaf: true,
              },
            ],
          },
          {
            value: "pool cleaning tools",
            label: "Pool Cleaning Tools",
            children: [
              {
                value: "handheld pool brushes & nets",
                label: "Handheld Pool Brushes Nets",
                isLeaf: true,
              },
              {
                value: "pool hoses",
                label: "Pool Hoses",
                isLeaf: true,
              },
              {
                value: "pool cleaners & vacuums",
                label: "Pool Cleaners Vacuums",
                isLeaf: true,
              },
            ],
          },
          {
            value: "pool chemicals & testing",
            label: "Pool Chemicals Testing",
            children: [
              {
                value: "pool & spa thermometers",
                label: "Pool Spa Thermometers",
                isLeaf: true,
              },
              {
                value: "pool water testing & kits",
                label: "Pool Water Testing Kits",
                isLeaf: true,
              },
              {
                value: "pool chemicals & clarifiers",
                label: "Pool Chemicals Clarifiers",
                isLeaf: true,
              },
            ],
          },
        ],
      },
      {
        value: "plant care, soil & accessories",
        label: "Plant Care Soil Accessories",
        children: [
          {
            value: "bark mulch & wood chips",
            label: "Bark Mulch Wood Chips",
            isLeaf: true,
          },
          {
            value: "soil & soil amendments",
            label: "Soil Soil Amendments",
            isLeaf: true,
          },
          {
            value: "seeders & spreaders",
            label: "Seeders Spreaders",
            isLeaf: true,
          },
          {
            value: "seed starting pots & trays",
            label: "Seed Starting Pots Trays",
            isLeaf: true,
          },
          {
            value: "pots & planters",
            label: "Pots Planters",
            isLeaf: true,
          },
          {
            value: "planter liners",
            label: "Planter Liners",
            isLeaf: true,
          },
          {
            value: "plant ties & supports",
            label: "Plant Ties Supports",
            isLeaf: true,
          },
          {
            value: "plant stands",
            label: "Plant Stands",
            isLeaf: true,
          },
          {
            value: "plant labels",
            label: "Plant Labels",
            isLeaf: true,
          },
          {
            value: "plant hooks & hangers",
            label: "Plant Hooks Hangers",
            isLeaf: true,
          },
          {
            value: "moisture & ph meters",
            label: "Moisture Ph Meters",
            isLeaf: true,
          },
          {
            value: "garden sprayers",
            label: "Garden Sprayers",
            isLeaf: true,
          },
          {
            value: "frost protection",
            label: "Frost Protection",
            isLeaf: true,
          },
          {
            value: "fertilizers",
            label: "Fertilizers",
            isLeaf: true,
          },
        ],
      },
      {
        value: "garden decor",
        label: "Garden Decor",
        children: [
          {
            value: "flags & flag poles",
            label: "Flags Flag Poles",
            isLeaf: true,
          },
          {
            value: "windmills & spinners",
            label: "Windmills Spinners",
            isLeaf: true,
          },
          {
            value: "wind chimes",
            label: "Wind Chimes",
            isLeaf: true,
          },
          {
            value: "weathervanes",
            label: "Weathervanes",
            isLeaf: true,
          },
          {
            value: "sundials",
            label: "Sundials",
            isLeaf: true,
          },
          {
            value: "stepping stones",
            label: "Stepping Stones",
            isLeaf: true,
          },
          {
            value: "statues & lawn ornaments",
            label: "Statues Lawn Ornaments",
            isLeaf: true,
          },
          {
            value: "rain gauges",
            label: "Rain Gauges",
            isLeaf: true,
          },
          {
            value: "plaques & signs",
            label: "Plaques Signs",
            isLeaf: true,
          },
          {
            value: "outdoor thermometers",
            label: "Outdoor Thermometers",
            isLeaf: true,
          },
        ],
      },
      {
        value: "garden hand tools & equipment",
        label: "Garden Hand Tools Equipment",
        children: [
          {
            value: "wheelbarrows, carts & wagons",
            label: "Wheelbarrows Carts Wagons",
            isLeaf: true,
          },
          {
            value: "trowels",
            label: "Trowels",
            isLeaf: true,
          },
          {
            value: "shovels",
            label: "Shovels",
            isLeaf: true,
          },
          {
            value: "scythes & sickles",
            label: "Scythes Sickles",
            isLeaf: true,
          },
          {
            value: "saws",
            label: "Saws",
            isLeaf: true,
          },
          {
            value: "rakes",
            label: "Rakes",
            isLeaf: true,
          },
          {
            value: "pruning shears & snips",
            label: "Pruning Shears Snips",
            isLeaf: true,
          },
          {
            value: "post hole diggers & augers",
            label: "Post Hole Diggers Augers",
            isLeaf: true,
          },
          {
            value: "parts & accessories",
            label: "Parts Accessories",
            isLeaf: true,
          },
          {
            value: "mattocks & picks",
            label: "Mattocks Picks",
            isLeaf: true,
          },
          {
            value: "axes & hatchets",
            label: "Axes Hatchets",
            isLeaf: true,
          },
          {
            value: "manual edgers",
            label: "Manual Edgers",
            isLeaf: true,
          },
          {
            value: "loppers",
            label: "Loppers",
            isLeaf: true,
          },
          {
            value: "lawn rollers",
            label: "Lawn Rollers",
            isLeaf: true,
          },
          {
            value: "lawn aerators",
            label: "Lawn Aerators",
            isLeaf: true,
          },
          {
            value: "hoes & cultivators",
            label: "Hoes Cultivators",
            isLeaf: true,
          },
          {
            value: "hedge & grass shears",
            label: "Hedge Grass Shears",
            isLeaf: true,
          },
          {
            value: "garden tool sets",
            label: "Garden Tool Sets",
            isLeaf: true,
          },
          {
            value: "garden tool racks",
            label: "Garden Tool Racks",
            isLeaf: true,
          },
          {
            value: "fruit pickers",
            label: "Fruit Pickers",
            isLeaf: true,
          },
          {
            value: "forks",
            label: "Forks",
            isLeaf: true,
          },
          {
            value: "bonsai tools",
            label: "Bonsai Tools",
            isLeaf: true,
          },
        ],
      },
    ],
  },

  {
    value: "office supplies",
    label: "Office Supplies",
    children: [
      {
        value: "writing supplies",
        label: "Writing Supplies",
        isLeaf: true,
      },
      {
        value: "presentation & display boards",
        label: "Presentation Display Boards",
        isLeaf: true,
      },
      {
        value: "staplers & punches",
        label: "Staplers Punches",
        isLeaf: true,
      },
      {
        value: "projectors",
        label: "Projectors",
        isLeaf: true,
      },
      {
        value: "tape & adhesives",
        label: "Tape Adhesives",
        isLeaf: true,
      },
      {
        value: "filing & storage",
        label: "Filing Storage",
        isLeaf: true,
      },
      {
        value: "clips & fasteners",
        label: "Clips Fasteners",
        isLeaf: true,
      },
      {
        value: "labels",
        label: "Labels",
        isLeaf: true,
      },
      {
        value: "calendars & planners",
        label: "Calendars Planners",
        isLeaf: true,
      },
      {
        value: "binders & accessories",
        label: "Binders Accessories",
        isLeaf: true,
      },
      {
        value: "office equipment & machines",
        label: "Office Equipment Machines",
        isLeaf: true,
      },
      {
        value: "mailing supplies",
        label: "Mailing Supplies",
        isLeaf: true,
      },
      {
        value: "paper products",
        label: "Paper Products",
        isLeaf: true,
      },
    ],
  },

  {
    value: "musical instruments",
    label: "Musical Instruments",
    children: [
      {
        value: "pro audio equipment",
        label: "Pro Audio Equipment",
        isLeaf: true,
      },
      {
        value: "music accessories",
        label: "Music Accessories",
        isLeaf: true,
      },
      {
        value: "guitars & basses",
        label: "Guitars Basses",
        isLeaf: true,
      },
      {
        value: "brass instruments",
        label: "Brass Instruments",
        isLeaf: true,
      },
      {
        value: "drums & percussion instruments",
        label: "Drums Percussion Instruments",
        isLeaf: true,
      },
      {
        value: "pianos & keyboard instruments",
        label: "Pianos Keyboard Instruments",
        isLeaf: true,
      },
      {
        value: "woodwind instruments",
        label: "Woodwind Instruments",
        isLeaf: true,
      },
      {
        value: "string instruments",
        label: "String Instruments",
        isLeaf: true,
      },
    ],
  },

  {
    value: "media",
    label: "Media",
    children: [
      {
        value: "newspapers",
        label: "Newspapers",
        isLeaf: true,
      },
      {
        value: "magazines & catalogs",
        label: "Magazines Catalogs",
        isLeaf: true,
      },
      {
        value: "books",
        label: "Books",
        isLeaf: true,
      },
      {
        value: "movies & tv shows",
        label: "Movies Tv Shows",
        isLeaf: true,
      },
      {
        value: "music",
        label: "Music",
        isLeaf: true,
      },
    ],
  },

  {
    value: "travel & luggage",
    label: "Travel Luggage",
    children: [
      {
        value: "garment bags",
        label: "Garment Bags",
        isLeaf: true,
      },
      {
        value: "travel accessories",
        label: "Travel Accessories",
        isLeaf: true,
      },
      {
        value: "handbags & wallets",
        label: "Handbags Wallets",
        children: [
          {
            value: "wallets",
            label: "Wallets",
            isLeaf: true,
          },
          {
            value: "satchels",
            label: "Satchels",
            isLeaf: true,
          },
          {
            value: "crossbody bags",
            label: "Crossbody Bags",
            isLeaf: true,
          },
          {
            value: "totes",
            label: "Totes",
            isLeaf: true,
          },
          {
            value: "shoulder bags",
            label: "Shoulder Bags",
            isLeaf: true,
          },
          {
            value: "clutches",
            label: "Clutches",
            isLeaf: true,
          },
          {
            value: "belt bags & waist packs",
            label: "Belt Bags Waist Packs",
            isLeaf: true,
          },
        ],
      },
      {
        value: "suitcases",
        label: "Suitcases",
        isLeaf: true,
      },
      {
        value: "duffels & gym bags",
        label: "Duffels Gym Bags",
        isLeaf: true,
      },
      {
        value: "luggage sets",
        label: "Luggage Sets",
        isLeaf: true,
      },
      {
        value: "briefcases & laptop bags",
        label: "Briefcases Laptop Bags",
        isLeaf: true,
      },
      {
        value: "backpacks",
        label: "Backpacks",
        isLeaf: true,
      },
    ],
  },

  {
    value: "arts & crafts",
    label: "Arts Crafts",
    children: [
      {
        value: "craft supplies",
        label: "Craft Supplies",
        isLeaf: true,
      },
      {
        value: "sewing",
        label: "Sewing",
        isLeaf: true,
      },
      {
        value: "needlecrafts & yarn",
        label: "Needlecrafts Yarn",
        isLeaf: true,
      },
      {
        value: "beading & jewelry making",
        label: "Beading Jewelry Making",
        isLeaf: true,
      },
      {
        value: "art supplies",
        label: "Art Supplies",
        isLeaf: true,
      },
    ],
  },

  {
    value: "auto parts & accessories",
    label: "Auto Parts Accessories",
    children: [
      {
        value: "trailer parts & accessories",
        label: "Trailer Parts Accessories",
        isLeaf: true,
      },
      {
        value: "powersports parts & accessories",
        label: "Powersports Parts Accessories",
        isLeaf: true,
      },
      {
        value: "rv & camper parts & accessories",
        label: "Rv Camper Parts Accessories",
        isLeaf: true,
      },
      {
        value: "car parts & accessories",
        label: "Car Parts Accessories",
        isLeaf: true,
      },
      {
        value: "boat parts & accessories",
        label: "Boat Parts Accessories",
        isLeaf: true,
      },
      {
        value: "motorcycle parts & accessories",
        label: "Motorcycle Parts Accessories",
        isLeaf: true,
      },
      {
        value: "automotive tools",
        label: "Automotive Tools",
        isLeaf: true,
      },
    ],
  },

  {
    value: "antiques & collectibles",
    label: "Antiques Collectibles",
    children: [
      {
        value: "collectible sports memorabilia",
        label: "Collectible Sports Memorabilia",
        isLeaf: true,
      },
      {
        value: "collectible home goods",
        label: "Collectible Home Goods",
        isLeaf: true,
      },
      {
        value: "collectible electronics",
        label: "Collectible Electronics",
        isLeaf: true,
      },
      {
        value: "collectible coins & paper money",
        label: "Collectible Coins Paper Money",
        isLeaf: true,
      },
      {
        value: "collectible stamps",
        label: "Collectible Stamps",
        isLeaf: true,
      },
      {
        value: "collectible furniture",
        label: "Collectible Furniture",
        isLeaf: true,
      },
      {
        value: "collectible appliances",
        label: "Collectible Appliances",
        isLeaf: true,
      },
      {
        value: "collectible toys",
        label: "Collectible Toys",
        isLeaf: true,
      },
    ],
  },

  {
    value: "baby products",
    label: "Baby Products",
    children: [
      {
        value: "baby feeding",
        label: "Baby Feeding",
        isLeaf: true,
      },
      {
        value: "baby transport",
        label: "Baby Transport",
        children: [
          {
            value: "baby carriers",
            label: "Baby Carriers",
            isLeaf: true,
          },
          {
            value: "strollers & accessories",
            label: "Strollers Accessories",
            isLeaf: true,
          },
          {
            value: "car seats & accessories",
            label: "Car Seats Accessories",
            isLeaf: true,
          },
        ],
      },
      {
        value: "baby health & safety",
        label: "Baby Health Safety",
        isLeaf: true,
      },
      {
        value: "baby bathing",
        label: "Baby Bathing",
        isLeaf: true,
      },
      {
        value: "toys",
        label: "Toys",
        children: [
          {
            value: "activity centers & entertainers",
            label: "Activity Centers Entertainers",
            isLeaf: true,
          },
          {
            value: "baby toys",
            label: "Baby Toys",
            isLeaf: true,
          },
        ],
      },
      {
        value: "diapering & potty training",
        label: "Diapering Potty Training",
        isLeaf: true,
      },
      {
        value: "nursery",
        label: "Nursery",
        children: [
          {
            value: "nursery bedding",
            label: "Nursery Bedding",
            isLeaf: true,
          },
          {
            value: "nursery decor",
            label: "Nursery Decor",
            isLeaf: true,
          },
          {
            value: "nursery furniture",
            label: "Nursery Furniture",
            isLeaf: true,
          },
        ],
      },
    ],
  },

  {
    value: "electronics",
    label: "Electronics",
    children: [
      {
        value: "portable audio & video",
        label: "Portable Audio Video",
        isLeaf: true,
      },
      {
        value: "home audio & video",
        label: "Home Audio Video",
        isLeaf: true,
      },
      {
        value: "home security & automation",
        label: "Home Security Automation",
        isLeaf: true,
      },
      {
        value: "cell phones & smart watches",
        label: "Cell Phones Smart Watches",
        children: [
          {
            value: "cell phones",
            label: "Cell Phones",
            isLeaf: true,
          },
          {
            value: "cell phone & smart watch accessories",
            label: "Cell Phone Smart Watch Accessories",
            isLeaf: true,
          },
          {
            value: "smart watches",
            label: "Smart Watches",
            isLeaf: true,
          },
        ],
      },
      {
        value: "cameras",
        label: "Cameras",
        children: [
          {
            value: "binoculars & telescopes",
            label: "Binoculars Telescopes",
            isLeaf: true,
          },
          {
            value: "film cameras",
            label: "Film Cameras",
            isLeaf: true,
          },
          {
            value: "camera accessories",
            label: "Camera Accessories",
            isLeaf: true,
          },
          {
            value: "camera drones",
            label: "Camera Drones",
            isLeaf: true,
          },
          {
            value: "digital cameras",
            label: "Digital Cameras",
            isLeaf: true,
          },
          {
            value: "video cameras",
            label: "Video Cameras",
            isLeaf: true,
          },
        ],
      },
      {
        value: "tvs & monitors",
        label: "Tvs Monitors",
        children: [
          {
            value: "tvs",
            label: "Tvs",
            isLeaf: true,
          },
          {
            value: "computer monitors",
            label: "Computer Monitors",
            isLeaf: true,
          },
        ],
      },
      {
        value: "software",
        label: "Software",
        isLeaf: true,
      },
      {
        value: "printers & scanners",
        label: "Printers Scanners",
        isLeaf: true,
      },
      {
        value: "video game consoles & video games",
        label: "Video Game Consoles Video Games",
        children: [
          {
            value: "video game accessories",
            label: "Video Game Accessories",
            isLeaf: true,
          },
          {
            value: "video game consoles",
            label: "Video Game Consoles",
            isLeaf: true,
          },
          {
            value: "video games",
            label: "Video Games",
            isLeaf: true,
          },
        ],
      },
      {
        value: "computers & tablets",
        label: "Computers Tablets",
        children: [
          {
            value: "tablets & ebook readers",
            label: "Tablets Ebook Readers",
            isLeaf: true,
          },
          {
            value: "networking & servers",
            label: "Networking Servers",
            isLeaf: true,
          },
          {
            value: "computer components & hardware",
            label: "Computer Components Hardware",
            isLeaf: true,
          },
          {
            value: "laptops",
            label: "Laptops",
            isLeaf: true,
          },
          {
            value: "desktop computers",
            label: "Desktop Computers",
            isLeaf: true,
          },
        ],
      },
      {
        value: "accessories",
        label: "Accessories",
        children: [
          {
            value: "computer peripherals",
            label: "Computer Peripherals",
            isLeaf: true,
          },
          {
            value: "batteries & power supplies",
            label: "Batteries Power Supplies",
            isLeaf: true,
          },

          {
            value: "blank media",
            label: "Blank Media",
            isLeaf: true,
          },
          {
            value: "cables, chargers & adapters",
            label: "Cables Chargers Adapters",
            isLeaf: true,
          },
        ],
      },
    ],
  },

  {
    value: "jewelry & watches",
    label: "Jewelry Watches",
    children: [
      {
        value: "watches",
        label: "Watches",
        children: [
          {
            value: "watch parts & accessories",
            label: "Watch Parts Accessories",
            isLeaf: true,
          },
          {
            value: "watch accessories",
            label: "Watch Accessories",
            children: [
              {
                value: "watch bands",
                label: "Watch Bands",
                isLeaf: true,
              },
              {
                value: "watch winders",
                label: "Watch Winders",
                isLeaf: true,
              },
              {
                value: "watch repair kits & tools",
                label: "Watch Repair Kits Tools",
                isLeaf: true,
              },
              {
                value: "watch parts",
                label: "Watch Parts",
                isLeaf: true,
              },
              {
                value: "watch manuals & guides",
                label: "Watch Manuals Guides",
                isLeaf: true,
              },
              {
                value: "watch boxes & cases",
                label: "Watch Boxes Cases",
                isLeaf: true,
              },
            ],
          },
          {
            value: "kids' watches",
            label: "Kids Watches",
            isLeaf: true,
          },
          {
            value: "men's watches",
            label: "Mens Watches",
            isLeaf: true,
          },
          {
            value: "women's watches",
            label: "Womens Watches",
            isLeaf: true,
          },
        ],
      },
      {
        value: "jewelry",
        label: "Jewelry",
        children: [
          {
            value: "men's jewelry",
            label: "Mens Jewelry",
            children: [
              {
                value: "bolo ties",
                label: "Bolo Ties",
                isLeaf: true,
              },
              {
                value: "tie clips",
                label: "Tie Clips",
                isLeaf: true,
              },
              {
                value: "rings",
                label: "Rings",
                isLeaf: true,
              },
              {
                value: "necklaces",
                label: "Necklaces",
                isLeaf: true,
              },
              {
                value: "lapel pins & brooches",
                label: "Lapel Pins Brooches",
                isLeaf: true,
              },
              {
                value: "jewelry sets",
                label: "Jewelry Sets",
                isLeaf: true,
              },
              {
                value: "earrings",
                label: "Earrings",
                isLeaf: true,
              },
              {
                value: "cufflinks & button studs",
                label: "Cufflinks Button Studs",
                isLeaf: true,
              },
              {
                value: "bracelets",
                label: "Bracelets",
                isLeaf: true,
              },
            ],
          },
          {
            value: "loose gemstones",
            label: "Loose Gemstones",
            isLeaf: true,
          },
          {
            value: "loose beads",
            label: "Loose Beads",
            isLeaf: true,
          },
          {
            value: "engagement jewelry",
            label: "Engagement Jewelry",
            children: [
              {
                value: "bridal jewelry",
                label: "Bridal Jewelry",
                isLeaf: true,
              },
              {
                value: "wedding & anniversary bands",
                label: "Wedding Anniversary Bands",
                isLeaf: true,
              },
              {
                value: "engagement rings",
                label: "Engagement Rings",
                isLeaf: true,
              },
              {
                value: "engagement & wedding ring sets",
                label: "Engagement Wedding Ring Sets",
                isLeaf: true,
              },
            ],
          },
          {
            value: "cremation & memorial jewelry",
            label: "Cremation Memorial Jewelry",
            isLeaf: true,
          },
          {
            value: "rings",
            label: "Rings",
            isLeaf: true,
          },
          {
            value: "necklaces",
            label: "Necklaces",
            isLeaf: true,
          },
          {
            value: "jewelry sets",
            label: "Jewelry Sets",
            isLeaf: true,
          },
          {
            value: "lapel pins & brooches",
            label: "Lapel Pins Brooches",
            isLeaf: true,
          },
          {
            value: "anklets",
            label: "Anklets",
            isLeaf: true,
          },
          {
            value: "charms & pendants",
            label: "Charms Pendants",
            isLeaf: true,
          },
          {
            value: "earrings",
            label: "Earrings",
            isLeaf: true,
          },
          {
            value: "body jewelry",
            label: "Body Jewelry",
            isLeaf: true,
          },
          {
            value: "bracelets",
            label: "Bracelets",
            isLeaf: true,
          },
        ],
      },
    ],
  },

  {
    value: "clothing & accessories",
    label: "Clothing Accessories",
    children: [
      {
        value: "clothing accessories",
        label: "Clothing Accessories",
        children: [
          {
            value: "women's accessories",
            label: "Womens Accessories",
            children: [
              {
                value: "belts",
                label: "Belts",
                isLeaf: true,
              },
              {
                value: "belt buckles",
                label: "Belt Buckles",
                isLeaf: true,
              },
              {
                value: "gloves & mittens",
                label: "Gloves Mittens",
                isLeaf: true,
              },
              {
                value: "sunglasses",
                label: "Sunglasses",
                isLeaf: true,
              },
              {
                value: "scarves & wraps",
                label: "Scarves Wraps",
                isLeaf: true,
              },
              {
                value: "hats",
                label: "Hats",
                isLeaf: true,
              },
            ],
          },
          {
            value: "men's accessories",
            label: "Mens Accessories",
            children: [
              {
                value: "belt buckles",
                label: "Belt Buckles",
                isLeaf: true,
              },
              {
                value: "ties",
                label: "Ties",
                isLeaf: true,
              },
              {
                value: "belts",
                label: "Belts",
                isLeaf: true,
              },
              {
                value: "hats",
                label: "Hats",
                isLeaf: true,
              },
              {
                value: "suspenders",
                label: "Suspenders",
                isLeaf: true,
              },
              {
                value: "sunglasses",
                label: "Sunglasses",
                isLeaf: true,
              },
              {
                value: "gloves & mittens",
                label: "Gloves Mittens",
                isLeaf: true,
              },
              {
                value: "scarves",
                label: "Scarves",
                isLeaf: true,
              },
            ],
          },
          {
            value: "girls' accessories",
            label: "Girls Accessories",
            children: [
              {
                value: "hats",
                label: "Hats",
                isLeaf: true,
              },
              {
                value: "gloves & mittens",
                label: "Gloves Mittens",
                isLeaf: true,
              },
              {
                value: "belts",
                label: "Belts",
                isLeaf: true,
              },
              {
                value: "scarves",
                label: "Scarves",
                isLeaf: true,
              },
              {
                value: "sunglasses",
                label: "Sunglasses",
                isLeaf: true,
              },
            ],
          },
          {
            value: "boys' accessories",
            label: "Boys Accessories",
            children: [
              {
                value: "ties",
                label: "Ties",
                isLeaf: true,
              },
              {
                value: "suspenders",
                label: "Suspenders",
                isLeaf: true,
              },
              {
                value: "wallets",
                label: "Wallets",
                isLeaf: true,
              },
              {
                value: "gloves & mittens",
                label: "Gloves Mittens",
                isLeaf: true,
              },
              {
                value: "hats",
                label: "Hats",
                isLeaf: true,
              },
              {
                value: "scarves",
                label: "Scarves",
                isLeaf: true,
              },
              {
                value: "belts",
                label: "Belts",
                isLeaf: true,
              },
              {
                value: "sunglasses",
                label: "Sunglasses",
                isLeaf: true,
              },
            ],
          },
          {
            value: "newborn & baby fashion accessories",
            label: "Newborn Baby Fashion Accessories",
            children: [
              {
                value: "bags",
                label: "Bags",
                isLeaf: true,
              },
              {
                value: "belts",
                label: "Belts",
                isLeaf: true,
              },
              {
                value: "gloves & mittens",
                label: "Gloves Mittens",
                isLeaf: true,
              },
              {
                value: "hats",
                label: "Hats",
                isLeaf: true,
              },
              {
                value: "scarves",
                label: "Scarves",
                isLeaf: true,
              },
              {
                value: "sunglasses",
                label: "Sunglasses",
                isLeaf: true,
              },
              {
                value: "ties",
                label: "Ties",
                isLeaf: true,
              },
            ],
          },
          {
            value: "unisex accessories",
            label: "Unisex Accessories",
            children: [
              {
                value: "scarves & bandanas",
                label: "Scarves Bandanas",
                isLeaf: true,
              },
              {
                value: "shoelaces",
                label: "Shoelaces",
                isLeaf: true,
              },
            ],
          },
        ],
      },
      {
        value: "shoes & footwear",
        label: "Shoes Footwear",
        children: [
          {
            value: "unisex shoes",
            label: "Unisex Shoes",
            isLeaf: true,
          },
          {
            value: "women's shoes",
            label: "Womens Shoes",
            children: [
              {
                value: "boots",
                label: "Boots",
                isLeaf: true,
              },
              {
                value: "heels",
                label: "Heels",
                isLeaf: true,
              },
              {
                value: "fashion sneakers",
                label: "Fashion Sneakers",
                isLeaf: true,
              },
              {
                value: "slippers",
                label: "Slippers",
                isLeaf: true,
              },
              {
                value: "sandals",
                label: "Sandals",
                isLeaf: true,
              },
              {
                value: "flats",
                label: "Flats",
                isLeaf: true,
              },
              {
                value: "athletic shoes",
                label: "Athletic Shoes",
                isLeaf: true,
              },
            ],
          },
          {
            value: "men's shoes",
            label: "Mens Shoes",
            children: [
              {
                value: "sandals",
                label: "Sandals",
                isLeaf: true,
              },
              {
                value: "casual & dress shoes",
                label: "Casual Dress Shoes",
                isLeaf: true,
              },
              {
                value: "boots",
                label: "Boots",
                isLeaf: true,
              },
              {
                value: "athletic shoes",
                label: "Athletic Shoes",
                isLeaf: true,
              },
              {
                value: "slippers",
                label: "Slippers",
                isLeaf: true,
              },
              {
                value: "fashion sneakers",
                label: "Fashion Sneakers",
                isLeaf: true,
              },
            ],
          },
          {
            value: "kids' shoes",
            label: "Kids Shoes",
            children: [
              {
                value: "boys' baby shoes",
                label: "Boys Baby Shoes",
                isLeaf: true,
              },
              {
                value: "girls' baby shoes",
                label: "Girls Baby Shoes",
                isLeaf: true,
              },
            ],
          },
          {
            value: "baby shoes",
            label: "Baby Shoes",
            children: [
              {
                value: "boys' baby shoes",
                label: "Boys Baby Shoes",
                isLeaf: true,
              },
              {
                value: "girls' baby shoes",
                label: "Girls Baby Shoes",
                isLeaf: true,
              },
            ],
          },
        ],
      },
      {
        value: "clothing",
        label: "Clothing",
        children: [
          {
            value: "uniforms & work clothing",
            label: "Uniforms Work Clothing",
            children: [
              {
                value: "aprons",
                label: "Aprons",
                isLeaf: true,
              },
              {
                value: "shirts",
                label: "Shirts",
                isLeaf: true,
              },
              {
                value: "scrubs",
                label: "Scrubs",
                isLeaf: true,
              },
              {
                value: "pants & shorts",
                label: "Pants Shorts",
                isLeaf: true,
              },
              {
                value: "other uniforms & work clothing",
                label: "Other Uniforms Work Clothing",
                isLeaf: true,
              },
              {
                value: "lab coats",
                label: "Lab Coats",
                isLeaf: true,
              },
              {
                value: "jackets & vests",
                label: "Jackets Vests",
                isLeaf: true,
              },
              {
                value: "coveralls & jumpsuits",
                label: "Coveralls Jumpsuits",
                isLeaf: true,
              },
            ],
          },
          {
            value: "costumes",
            label: "Costumes",
            isLeaf: true,
          },
          {
            value: "women's clothing",
            label: "Womens Clothing",
            children: [
              {
                value: "tops",
                label: "Tops",
                children: [
                  {
                    value: "tops & t-shirts",
                    label: "Tops T Shirts",
                    isLeaf: true,
                  },
                  {
                    value: "sweatshirts & hoodies",
                    label: "Sweatshirts Hoodies",
                    isLeaf: true,
                  },
                  {
                    value: "suits & blazers",
                    label: "Suits Blazers",
                    isLeaf: true,
                  },
                  {
                    value: "sweaters",
                    label: "Sweaters",
                    isLeaf: true,
                  },
                  {
                    value: "vests",
                    label: "Vests",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "tights, socks & hosiery",
                label: "Tights Socks Hosiery",
                children: [
                  {
                    value: "socks",
                    label: "Socks",
                    isLeaf: true,
                  },
                  {
                    value: "leg warmers",
                    label: "Leg Warmers",
                    isLeaf: true,
                  },
                  {
                    value: "pantyhose & tights",
                    label: "Pantyhose Tights",
                    isLeaf: true,
                  },
                  {
                    value: "stockings & thigh-highs",
                    label: "Stockings Thigh Highs",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "swimwear",
                label: "Swimwear",
                isLeaf: true,
              },
              {
                value: "bottoms",
                label: "Bottoms",
                children: [
                  {
                    value: "shorts",
                    label: "Shorts",
                    isLeaf: true,
                  },
                  {
                    value: "leggings",
                    label: "Leggings",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "pants",
                label: "Pants",
                isLeaf: true,
              },
              {
                value: "skirts",
                label: "Skirts",
                isLeaf: true,
              },
              {
                value: "dresses",
                label: "Dresses",
                isLeaf: true,
              },
              {
                value: "maternity clothing",
                label: "Maternity Clothing",
                children: [
                  {
                    value: "maternity bras",
                    label: "Maternity Bras",
                    isLeaf: true,
                  },
                  {
                    value: "maternity swimwear",
                    label: "Maternity Swimwear",
                    isLeaf: true,
                  },
                  {
                    value: "maternity sweaters",
                    label: "Maternity Sweaters",
                    isLeaf: true,
                  },
                  {
                    value: "maternity sleepwear",
                    label: "Maternity Sleepwear",
                    isLeaf: true,
                  },
                  {
                    value: "maternity pants",
                    label: "Maternity Pants",
                    isLeaf: true,
                  },
                  {
                    value: "maternity panties",
                    label: "Maternity Panties",
                    isLeaf: true,
                  },
                  {
                    value: "maternity leggings",
                    label: "Maternity Leggings",
                    isLeaf: true,
                  },
                  {
                    value: "maternity jumpsuits & rompers",
                    label: "Maternity Jumpsuits Rompers",
                    isLeaf: true,
                  },
                  {
                    value: "maternity jeans",
                    label: "Maternity Jeans",
                    isLeaf: true,
                  },
                  {
                    value: "maternity dresses",
                    label: "Maternity Dresses",
                    isLeaf: true,
                  },
                  {
                    value: "maternity coats & jackets",
                    label: "Maternity Coats Jackets",
                    isLeaf: true,
                  },
                  {
                    value: "maternity tops & t-shirts",
                    label: "Maternity Tops T Shirts",
                    isLeaf: true,
                  },
                  {
                    value: "maternity shorts",
                    label: "Maternity Shorts",
                    isLeaf: true,
                  },
                  {
                    value: "maternity pantyhose & tights",
                    label: "Maternity Pantyhose Tights",
                    isLeaf: true,
                  },
                  {
                    value: "maternity sweatshirts & hoodies",
                    label: "Maternity Sweatshirts Hoodies",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "lingerie & sleepwear",
                label: "Lingerie Sleepwear",
                children: [
                  {
                    value: "sleepwear",
                    label: "Sleepwear",
                    isLeaf: true,
                  },
                  {
                    value: "underwear",
                    label: "Underwear",
                    isLeaf: true,
                  },
                  {
                    value: "slips",
                    label: "Slips",
                    isLeaf: true,
                  },
                  {
                    value: "bras",
                    label: "Bras",
                    isLeaf: true,
                  },
                  {
                    value: "shapewear",
                    label: "Shapewear",
                    isLeaf: true,
                  },
                  {
                    value: "teddies",
                    label: "Teddies",
                    isLeaf: true,
                  },
                  {
                    value: "garter belts",
                    label: "Garter Belts",
                    isLeaf: true,
                  },
                  {
                    value: "corsets",
                    label: "Corsets",
                    isLeaf: true,
                  },
                  {
                    value: "camisoles",
                    label: "Camisoles",
                    isLeaf: true,
                  },
                  {
                    value: "bra accessories",
                    label: "Bra Accessories",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "jeans",
                label: "Jeans",
                isLeaf: true,
              },
              {
                value: "jumpsuits & rompers",
                label: "Jumpsuits Rompers",
                isLeaf: true,
              },
              {
                value: "activewear",
                label: "Activewear",
                children: [
                  {
                    value: "activewear jackets",
                    label: "Activewear Jackets",
                    isLeaf: true,
                  },
                  {
                    value: "activewear leggings",
                    label: "Activewear Leggings",
                    isLeaf: true,
                  },
                  {
                    value: "sports bras",
                    label: "Sports Bras",
                    isLeaf: true,
                  },
                  {
                    value: "activewear sweatshirts & hoodies",
                    label: "Activewear Sweatshirts Hoodies",
                    isLeaf: true,
                  },
                  {
                    value: "activewear skirts",
                    label: "Activewear Skirts",
                    isLeaf: true,
                  },
                  {
                    value: "activewear shorts",
                    label: "Activewear Shorts",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "coats & jackets",
                label: "Coats Jackets",
                isLeaf: true,
              },
            ],
          },
          {
            value: "men's clothing",
            label: "Mens Clothing",
            children: [
              {
                value: "bottoms",
                label: "Bottoms",
                children: [
                  {
                    value: "underwear",
                    label: "Underwear",
                    isLeaf: true,
                  },
                  {
                    value: "swimwear",
                    label: "Swimwear",
                    isLeaf: true,
                  },
                  {
                    value: "jeans",
                    label: "Jeans",
                    isLeaf: true,
                  },
                  {
                    value: "pants",
                    label: "Pants",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "tops",
                label: "Tops",
                children: [
                  {
                    value: "vests",
                    label: "Vests",
                    isLeaf: true,
                  },

                  {
                    value: "sweatshirts & hoodies",
                    label: "Sweatshirts Hoodies",
                    isLeaf: true,
                  },
                  {
                    value: "sweaters",
                    label: "Sweaters",
                    isLeaf: true,
                  },
                  {
                    value: "casual shirts",
                    label: "Casual Shirts",
                    isLeaf: true,
                  },
                  {
                    value: "blazers & sports coats",
                    label: "Blazers Sports Coats",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "t-shirts",
                label: "T Shirts",
                isLeaf: true,
              },
              {
                value: "sleepwear",
                label: "Sleepwear",
                isLeaf: true,
              },

              {
                value: "suits",
                label: "Suits",
                isLeaf: true,
              },

              {
                value: "dress shirts",
                label: "Dress Shirts",
                isLeaf: true,
              },
              {
                value: "socks",
                label: "Socks",
                isLeaf: true,
              },
              {
                value: "shorts",
                label: "Shorts",
                isLeaf: true,
              },
              {
                value: "activewear",
                label: "Activewear",
                children: [
                  {
                    value: "activewear leggings & pants",
                    label: "Activewear Leggings Pants",
                    isLeaf: true,
                  },
                  {
                    value: "activewear jackets",
                    label: "Activewear Jackets",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "blazers & sport coats",
                label: "Blazers Sport Coats",
                isLeaf: true,
              },
              {
                value: "coats & jackets",
                label: "Coats Jackets",
                isLeaf: true,
              },
            ],
          },
          {
            value: "kids' clothing",
            label: "Kids Clothing",
            children: [
              {
                value: "girls' clothing",
                label: "Girls Clothing",
                children: [
                  {
                    value: "swimwear",
                    label: "Swimwear",
                    isLeaf: true,
                  },
                  {
                    value: "underwear",
                    label: "Underwear",
                    isLeaf: true,
                  },
                  {
                    value: "tops & t-shirts",
                    label: "Tops T Shirts",
                    isLeaf: true,
                  },
                  {
                    value: "sweatshirts & hoodies",
                    label: "Sweatshirts Hoodies",
                    isLeaf: true,
                  },
                  {
                    value: "skirts",
                    label: "Skirts",
                    isLeaf: true,
                  },
                  {
                    value: "socks & tights",
                    label: "Socks Tights",
                    isLeaf: true,
                  },
                  {
                    value: "shorts",
                    label: "Shorts",
                    isLeaf: true,
                  },
                  {
                    value: "sleepwear",
                    label: "Sleepwear",
                    isLeaf: true,
                  },
                  {
                    value: "pants",
                    label: "Pants",
                    isLeaf: true,
                  },
                  {
                    value: "sweaters",
                    label: "Sweaters",
                    isLeaf: true,
                  },
                  {
                    value: "outerwear",
                    label: "Outerwear",
                    isLeaf: true,
                  },
                  {
                    value: "jeans",
                    label: "Jeans",
                    isLeaf: true,
                  },
                  {
                    value: "jumpsuits & rompers",
                    label: "Jumpsuits Rompers",
                    isLeaf: true,
                  },
                  {
                    value: "leggings",
                    label: "Leggings",
                    isLeaf: true,
                  },
                  {
                    value: "outfits & sets",
                    label: "Outfits Sets",
                    isLeaf: true,
                  },
                  {
                    value: "dresses",
                    label: "Dresses",
                    isLeaf: true,
                  },
                  {
                    value: "vests",
                    label: "Vests",
                    isLeaf: true,
                  },
                  {
                    value: "skorts",
                    label: "Skorts",
                    isLeaf: true,
                  },
                  {
                    value: "girls' uniforms",
                    label: "Girls Uniforms",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "boys' clothing",
                label: "Boys Clothing",
                children: [
                  {
                    value: "suits",
                    label: "Suits",
                    isLeaf: true,
                  },
                  {
                    value: "underwear",
                    label: "Underwear",
                    isLeaf: true,
                  },
                  {
                    value: "sweatshirts & hoodies",
                    label: "Sweatshirts Hoodies",
                    isLeaf: true,
                  },
                  {
                    value: "sweaters",
                    label: "Sweaters",
                    isLeaf: true,
                  },
                  {
                    value: "swimwear",
                    label: "Swimwear",
                    isLeaf: true,
                  },
                  {
                    value: "tops & t-shirts",
                    label: "Tops T Shirts",
                    isLeaf: true,
                  },
                  {
                    value: "sleepwear",
                    label: "Sleepwear",
                    isLeaf: true,
                  },
                  {
                    value: "outfits & sets",
                    label: "Outfits Sets",
                    isLeaf: true,
                  },
                  {
                    value: "pants",
                    label: "Pants",
                    isLeaf: true,
                  },
                  {
                    value: "shorts",
                    label: "Shorts",
                    isLeaf: true,
                  },
                  {
                    value: "jeans",
                    label: "Jeans",
                    isLeaf: true,
                  },
                  {
                    value: "outerwear",
                    label: "Outerwear",
                    isLeaf: true,
                  },
                  {
                    value: "vests",
                    label: "Vests",
                    isLeaf: true,
                  },
                  {
                    value: "boys' uniforms",
                    label: "Boys Uniforms",
                    isLeaf: true,
                  },
                ],
              },
            ],
          },
          {
            value: "baby clothing",
            label: "Baby Clothing",
            children: [
              {
                value: "girls' baby clothing",
                label: "Girls Baby Clothing",
                children: [
                  {
                    value: "swimwear",
                    label: "Swimwear",
                    isLeaf: true,
                  },
                  {
                    value: "dresses",
                    label: "Dresses",
                    isLeaf: true,
                  },
                  {
                    value: "sleepwear",
                    label: "Sleepwear",
                    isLeaf: true,
                  },
                  {
                    value: "outerwear",
                    label: "Outerwear",
                    isLeaf: true,
                  },
                  {
                    value: "bottoms",
                    label: "Bottoms",
                    isLeaf: true,
                  },
                  {
                    value: "sweaters",
                    label: "Sweaters",
                    isLeaf: true,
                  },
                  {
                    value: "outfits & sets",
                    label: "Outfits Sets",
                    isLeaf: true,
                  },
                  {
                    value: "socks",
                    label: "Socks",
                    isLeaf: true,
                  },
                  {
                    value: "bodysuits",
                    label: "Bodysuits",
                    isLeaf: true,
                  },
                  {
                    value: "skirts",
                    label: "Skirts",
                    isLeaf: true,
                  },
                  {
                    value: "sweatshirts & hoodies",
                    label: "Sweatshirts Hoodies",
                    isLeaf: true,
                  },
                  {
                    value: "t-shirts",
                    label: "T Shirts",
                    isLeaf: true,
                  },
                  {
                    value: "tights",
                    label: "Tights",
                    isLeaf: true,
                  },
                  {
                    value: "underwear",
                    label: "Underwear",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "boys' baby clothing",
                label: "Boys Baby Clothing",
                children: [
                  {
                    value: "swimwear",
                    label: "Swimwear",
                    isLeaf: true,
                  },
                  {
                    value: "suits",
                    label: "Suits",
                    isLeaf: true,
                  },
                  {
                    value: "sweaters",
                    label: "Sweaters",
                    isLeaf: true,
                  },
                  {
                    value: "socks",
                    label: "Socks",
                    isLeaf: true,
                  },
                  {
                    value: "tops",
                    label: "Tops",
                    isLeaf: true,
                  },
                  {
                    value: "bodysuits",
                    label: "Bodysuits",
                    isLeaf: true,
                  },
                  {
                    value: "sleepwear",
                    label: "Sleepwear",
                    isLeaf: true,
                  },
                  {
                    value: "bottoms",
                    label: "Bottoms",
                    isLeaf: true,
                  },
                  {
                    value: "outerwear",
                    label: "Outerwear",
                    isLeaf: true,
                  },
                  {
                    value: "outfits & sets",
                    label: "Outfits Sets",
                    isLeaf: true,
                  },
                  {
                    value: "sweatshirts & hoodies",
                    label: "Sweatshirts Hoodies",
                    isLeaf: true,
                  },
                  {
                    value: "t-shirts",
                    label: "T Shirts",
                    isLeaf: true,
                  },
                  {
                    value: "underwear",
                    label: "Underwear",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "newborn & baby fashion accessories",
                label: "Newborn Baby Fashion Accessories",
                children: [
                  {
                    value: "bags",
                    label: "Bags",
                    isLeaf: true,
                  },
                  {
                    value: "belts",
                    label: "Belts",
                    isLeaf: true,
                  },
                  {
                    value: "gloves & mittens",
                    label: "Gloves Mittens",
                    isLeaf: true,
                  },
                  {
                    value: "hats",
                    label: "Hats",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "unisex newborn & baby clothing",
                label: "Unisex Newborn Baby Clothing",
                children: [
                  {
                    value: "bodysuits",
                    label: "Bodysuits",
                    isLeaf: true,
                  },
                  {
                    value: "bottoms",
                    label: "Bottoms",
                    isLeaf: true,
                  },
                  {
                    value: "outerwear",
                    label: "Outerwear",
                    isLeaf: true,
                  },
                  {
                    value: "outfits & sets",
                    label: "Outfits Sets",
                    isLeaf: true,
                  },
                  {
                    value: "sleepwear",
                    label: "Sleepwear",
                    isLeaf: true,
                  },
                  {
                    value: "socks",
                    label: "Socks",
                    isLeaf: true,
                  },
                  {
                    value: "tops & t-shirts",
                    label: "Tops T Shirts",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "other baby clothing & accessories",
                label: "Other Baby Clothing Accessories",
                isLeaf: true,
              },
            ],
          },
        ],
      },
      {
        value: "costumes",
        label: "Costumes",
        children: [
          {
            value: "boys' costumes",
            label: "Boys Costumes",
            isLeaf: true,
          },
          {
            value: "costume accessories",
            label: "Costume Accessories",
            children: [
              {
                value: "bags & pouches",
                label: "Bags Pouches",
                isLeaf: true,
              },
              {
                value: "boas & leis",
                label: "Boas Leis",
                isLeaf: true,
              },
              {
                value: "capes & cloaks",
                label: "Capes Cloaks",
                isLeaf: true,
              },
              {
                value: "crowns & tiaras",
                label: "Crowns Tiaras",
                isLeaf: true,
              },
              {
                value: "face paint & makeup",
                label: "Face Paint Makeup",
                isLeaf: true,
              },
              {
                value: "fans & parasols",
                label: "Fans Parasols",
                isLeaf: true,
              },
              {
                value: "glasses & eyewear",
                label: "Glasses Eyewear",
                isLeaf: true,
              },
              {
                value: "gloves",
                label: "Gloves",
                isLeaf: true,
              },
              {
                value: "hats & headwear",
                label: "Hats Headwear",
                isLeaf: true,
              },
              {
                value: "masks",
                label: "Masks",
                isLeaf: true,
              },
              {
                value: "props & handheld items",
                label: "Props Handheld Items",
                isLeaf: true,
              },
              {
                value: "prosthetics",
                label: "Prosthetics",
                isLeaf: true,
              },
              {
                value: "shoes",
                label: "Shoes",
                isLeaf: true,
              },
              {
                value: "wigs & facial hair",
                label: "Wigs Facial Hair",
                isLeaf: true,
              },
              {
                value: "wings, tails, ears & noses",
                label: "Wings Tails Ears Noses",
                isLeaf: true,
              },
            ],
          },
          {
            value: "girls' costumes",
            label: "Girls Costumes",
            isLeaf: true,
          },
          {
            value: "infant & toddler costumes",
            label: "Infant Toddler Costumes",
            isLeaf: true,
          },
          {
            value: "men's costumes",
            label: "Mens Costumes",
            isLeaf: true,
          },
          {
            value: "unisex costumes",
            label: "Unisex Costumes",
            isLeaf: true,
          },
          {
            value: "women's costumes",
            label: "Womens Costumes",
            isLeaf: true,
          },
        ],
      },
    ],
  },

  {
    value: "health & beauty",
    label: "Health Beauty",
    children: [
      {
        value: "health",
        label: "Health",
        children: [
          {
            value: "oral care",
            label: "Oral Care",
            isLeaf: true,
          },
          {
            value: "first aid",
            label: "First Aid",
            isLeaf: true,
          },
          {
            value: "feminine care",
            label: "Feminine Care",
            isLeaf: true,
          },
          {
            value: "vision care",
            label: "Vision Care",
            isLeaf: true,
          },
          {
            value: "massage",
            label: "Massage",
            isLeaf: true,
          },
          {
            value: "shaving & hair removal",
            label: "Shaving Hair Removal",
            isLeaf: true,
          },
        ],
      },
      {
        value: "beauty",
        label: "Beauty",
        children: [
          {
            value: "skin care",
            label: "Skin Care",
            children: [
              {
                value: "tanning oil & lotion",
                label: "Tanning Oil Lotion",
                isLeaf: true,
              },
              {
                value: "lip balms & treatments",
                label: "Lip Balms Treatments",
                isLeaf: true,
              },
              {
                value: "masks & peels",
                label: "Masks Peels",
                isLeaf: true,
              },
              {
                value: "exfoliators & scrubs",
                label: "Exfoliators Scrubs",
                isLeaf: true,
              },
              {
                value: "facial lotion & moisturizer",
                label: "Facial Lotion Moisturizer",
                isLeaf: true,
              },
              {
                value: "pore strips",
                label: "Pore Strips",
                isLeaf: true,
              },
              {
                value: "night treatments",
                label: "Night Treatments",
                isLeaf: true,
              },
              {
                value: "sunscreen",
                label: "Sunscreen",
                children: [
                  {
                    value: "self tanner & sunless tanning products",
                    label: "Self Tanner Sunless Tanning Products",
                    isLeaf: true,
                  },
                  {
                    value: "sun care",
                    label: "Sun Care",
                    children: [
                      {
                        value: "after-sun care",
                        label: "After Sun Care",
                        isLeaf: true,
                      },
                      {
                        value: "sunscreen",
                        label: "Sunscreen",
                        isLeaf: true,
                      },
                      {
                        value: "tanning oil & lotion",
                        label: "Tanning Oil Lotion",
                        isLeaf: true,
                      },
                      {
                        value: "tanning beds & tents",
                        label: "Tanning Beds Tents",
                        isLeaf: true,
                      },
                    ],
                  },
                ],
              },
              {
                value: "skin cleansing brushes & systems",
                label: "Skin Cleansing Brushes Systems",
                isLeaf: true,
              },
              {
                value: "acne & blemish treatments",
                label: "Acne Blemish Treatments",
                isLeaf: true,
              },
              {
                value: "cleansers & toners",
                label: "Cleansers Toners",
                isLeaf: true,
              },
              {
                value: "anti-aging products",
                label: "Anti Aging Products",
                isLeaf: true,
              },
              {
                value: "eye treatments & masks",
                label: "Eye Treatments Masks",
                isLeaf: true,
              },
              {
                value: "facial rollers",
                label: "Facial Rollers",
                isLeaf: true,
              },
              {
                value: "facial saunas & steamers",
                label: "Facial Saunas Steamers",
                isLeaf: true,
              },
              {
                value: "lightening cream",
                label: "Lightening Cream",
                isLeaf: true,
              },
              {
                value: "makeup removers",
                label: "Makeup Removers",
                isLeaf: true,
              },
              {
                value: "pumice stones",
                label: "Pumice Stones",
                isLeaf: true,
              },
            ],
          },
          {
            value: "nail care",
            label: "Nail Care",
            children: [
              {
                value: "pumice stones",
                label: "Pumice Stones",
                isLeaf: true,
              },
              {
                value: "nail polish & art",
                label: "Nail Polish Art",
                children: [
                  {
                    value: "nail airbrush kits & supplies",
                    label: "Nail Airbrush Kits Supplies",
                    isLeaf: true,
                  },
                  {
                    value: "nail art accessories",
                    label: "Nail Art Accessories",
                    isLeaf: true,
                  },
                  {
                    value: "nail polish",
                    label: "Nail Polish",
                    isLeaf: true,
                  },
                  {
                    value: "nail polish remover",
                    label: "Nail Polish Remover",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "nail tools",
                label: "Nail Tools",
                children: [
                  {
                    value: "hand & nail treatment creams",
                    label: "Hand Nail Treatment Creams",
                    isLeaf: true,
                  },
                  {
                    value: "cuticle treatments",
                    label: "Cuticle Treatments",
                    isLeaf: true,
                  },
                  {
                    value: "nail buffers",
                    label: "Nail Buffers",
                    isLeaf: true,
                  },
                  {
                    value: "nail clippers",
                    label: "Nail Clippers",
                    isLeaf: true,
                  },
                  {
                    value: "nail drills",
                    label: "Nail Drills",
                    isLeaf: true,
                  },
                  {
                    value: "nail dryers & lamps",
                    label: "Nail Dryers Lamps",
                    isLeaf: true,
                  },
                  {
                    value: "nail files & emery boards",
                    label: "Nail Files Emery Boards",
                    isLeaf: true,
                  },
                  {
                    value: "nail spas & baths",
                    label: "Nail Spas Baths",
                    isLeaf: true,
                  },
                  {
                    value: "cuticle pushers",
                    label: "Cuticle Pushers",
                    isLeaf: true,
                  },
                  {
                    value: "cuticle scissors",
                    label: "Cuticle Scissors",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "artificial nails & accessories",
                label: "Artificial Nails Accessories",
                children: [
                  {
                    value: "acrylic powders & liquids",
                    label: "Acrylic Powders Liquids",
                    isLeaf: true,
                  },
                  {
                    value: "artificial nail tips",
                    label: "Artificial Nail Tips",
                    isLeaf: true,
                  },
                  {
                    value: "gel nails",
                    label: "Gel Nails",
                    isLeaf: true,
                  },
                  {
                    value: "manicure & pedicure spacers",
                    label: "Manicure Pedicure Spacers",
                    isLeaf: true,
                  },
                  {
                    value: "manicure tool sets",
                    label: "Manicure Tool Sets",
                    isLeaf: true,
                  },
                ],
              },
            ],
          },
          {
            value: "makeup",
            label: "Makeup",
            children: [
              {
                value: "makeup tools & accessories",
                label: "Makeup Tools Accessories",
                children: [
                  {
                    value: "blotting paper",
                    label: "Blotting Paper",
                    isLeaf: true,
                  },
                  {
                    value: "brush cleaners",
                    label: "Brush Cleaners",
                    isLeaf: true,
                  },
                  {
                    value: "brushes & sponges",
                    label: "Brushes Sponges",
                    isLeaf: true,
                  },
                  {
                    value: "eyelash tools",
                    label: "Eyelash Tools",
                    isLeaf: true,
                  },
                  {
                    value: "makeup bags & storage",
                    label: "Makeup Bags Storage",
                    isLeaf: true,
                  },
                  {
                    value: "makeup finishing sprays",
                    label: "Makeup Finishing Sprays",
                    isLeaf: true,
                  },
                  {
                    value: "makeup mirrors",
                    label: "Makeup Mirrors",
                    isLeaf: true,
                  },
                  {
                    value: "refillable makeup palettes",
                    label: "Refillable Makeup Palettes",
                    isLeaf: true,
                  },
                  {
                    value: "sharpeners",
                    label: "Sharpeners",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "body makeup",
                label: "Body Makeup",
                isLeaf: true,
              },
              {
                value: "lip makeup",
                label: "Lip Makeup",
                children: [
                  {
                    value: "lip plumpers",
                    label: "Lip Plumpers",
                    isLeaf: true,
                  },
                  {
                    value: "lip gloss",
                    label: "Lip Gloss",
                    isLeaf: true,
                  },
                  {
                    value: "lip liners",
                    label: "Lip Liners",
                    isLeaf: true,
                  },
                  {
                    value: "lip stains",
                    label: "Lip Stains",
                    isLeaf: true,
                  },
                  {
                    value: "lipstick",
                    label: "Lipstick",
                    isLeaf: true,
                  },
                  {
                    value: "lip primers",
                    label: "Lip Primers",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "makeup sets & kits",
                label: "Makeup Sets Kits",
                isLeaf: true,
              },
              {
                value: "face makeup",
                label: "Face Makeup",
                children: [
                  {
                    value: "foundations",
                    label: "Foundations",
                    isLeaf: true,
                  },
                  {
                    value: "face powders",
                    label: "Face Powders",
                    isLeaf: true,
                  },
                  {
                    value: "blushes",
                    label: "Blushes",
                    isLeaf: true,
                  },
                  {
                    value: "bronzers & highlighters",
                    label: "Bronzers Highlighters",
                    isLeaf: true,
                  },
                  {
                    value: "face primers",
                    label: "Face Primers",
                    isLeaf: true,
                  },
                  {
                    value: "concealers",
                    label: "Concealers",
                    isLeaf: true,
                  },
                  {
                    value: "bb, cc & alphabet creams",
                    label: "Bb Cc Alphabet Creams",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "eye makeup",
                label: "Eye Makeup",
                children: [
                  {
                    value: "eyebrow products",
                    label: "Eyebrow Products",
                    isLeaf: true,
                  },
                  {
                    value: "eyeliners",
                    label: "Eyeliners",
                    isLeaf: true,
                  },
                  {
                    value: "mascara",
                    label: "Mascara",
                    isLeaf: true,
                  },
                  {
                    value: "eye shadows",
                    label: "Eye Shadows",
                    isLeaf: true,
                  },
                  {
                    value: "false eyelashes & adhesives",
                    label: "False Eyelashes Adhesives",
                    isLeaf: true,
                  },
                  {
                    value: "eye primers",
                    label: "Eye Primers",
                    isLeaf: true,
                  },
                  {
                    value: "lash growth enhancers & conditioners",
                    label: "Lash Growth Enhancers Conditioners",
                    isLeaf: true,
                  },
                ],
              },
            ],
          },
          {
            value: "fragrances",
            label: "Fragrances",
            children: [
              {
                value: "men's fragrances",
                label: "Mens Fragrances",
                isLeaf: true,
              },
              {
                value: "women's fragrances",
                label: "Womens Fragrances",
                isLeaf: true,
              },
              {
                value: "kids' fragrances",
                label: "Kids Fragrances",
                isLeaf: true,
              },
            ],
          },
          {
            value: "bath & body",
            label: "Bath Body",
            children: [
              {
                value: "bubble baths",
                label: "Bubble Baths",
                isLeaf: true,
              },
              {
                value: "bath salts",
                label: "Bath Salts",
                isLeaf: true,
              },

              {
                value: "body lotions & moisturizers",
                label: "Body Lotions Moisturizers",
                isLeaf: true,
              },

              {
                value: "body washes & soaps",
                label: "Body Washes Soaps",
                isLeaf: true,
              },

              {
                value: "sponges & brushes",
                label: "Sponges Brushes",
                isLeaf: true,
              },

              {
                value: "scrubs & exfoliants",
                label: "Scrubs Exfoliants",
                isLeaf: true,
              },

              {
                value: "bath oils",
                label: "Bath Oils",
                isLeaf: true,
              },
              {
                value: "bath bombs",
                label: "Bath Bombs",
                isLeaf: true,
              },
              {
                value: "bath & body gift sets",
                label: "Bath Body Gift Sets",
                isLeaf: true,
              },
              {
                value: "body powder",
                label: "Body Powder",
                isLeaf: true,
              },
              {
                value: "deodorants & antiperspirants",
                label: "Deodorants Antiperspirants",
                isLeaf: true,
              },
              {
                value: "hand sanitizers",
                label: "Hand Sanitizers",
                isLeaf: true,
              },
              {
                value: "hand soaps",
                label: "Hand Soaps",
                isLeaf: true,
              },
              {
                value: "shower caps",
                label: "Shower Caps",
                isLeaf: true,
              },
            ],
          },
          {
            value: "foot care",
            label: "Foot Care",
            children: [
              {
                value: "bunion care",
                label: "Bunion Care",
                isLeaf: true,
              },
              {
                value: "corn & callus care",
                label: "Corn Callus Care",
                isLeaf: true,
              },
              {
                value: "foot odor removers",
                label: "Foot Odor Removers",
                isLeaf: true,
              },
              {
                value: "toe spacers",
                label: "Toe Spacers",
                isLeaf: true,
              },
            ],
          },
          {
            value: "hair care & styling",
            label: "Hair Care Styling",
            children: [
              {
                value: "shampoos & conditioners",
                label: "Shampoos Conditioners",
                children: [
                  {
                    value: "conditioners",
                    label: "Conditioners",
                    isLeaf: true,
                  },
                  {
                    value: "shampoo & conditioner sets",
                    label: "Shampoo Conditioner Sets",
                    isLeaf: true,
                  },
                  {
                    value: "shampoos",
                    label: "Shampoos",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "rollers & curlers",
                label: "Rollers Curlers",
                isLeaf: true,
              },
              {
                value: "straightening & curling irons",
                label: "Straightening Curling Irons",
                isLeaf: true,
              },
              {
                value: "styling products",
                label: "Styling Products",
                isLeaf: true,
              },
              {
                value: "hair oils & protectors",
                label: "Hair Oils Protectors",
                isLeaf: true,
              },
              {
                value: "hair ties & styling accessories",
                label: "Hair Ties Styling Accessories",
                isLeaf: true,
              },
              {
                value: "hair cutting scissors & shears",
                label: "Hair Cutting Scissors Shears",
                isLeaf: true,
              },
              {
                value: "hair dryers",
                label: "Hair Dryers",
                isLeaf: true,
              },
              {
                value: "hair extensions & wigs",
                label: "Hair Extensions Wigs",
                isLeaf: true,
              },
              {
                value: "brushes & combs",
                label: "Brushes Combs",
                isLeaf: true,
              },
              {
                value: "hair coloring & dyes",
                label: "Hair Coloring Dyes",
                isLeaf: true,
              },
              {
                value: "hair extensions & wigs",
                label: "Hair Extensions Wigs",
                children: [
                  {
                    value: "hair extension & wig supplies",
                    label: "Hair Extension Wig Supplies",
                    isLeaf: true,
                  },
                  {
                    value: "hair extensions",
                    label: "Hair Extensions",
                    isLeaf: true,
                  },
                  {
                    value: "wigs & hair pieces",
                    label: "Wigs Hair Pieces",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "hair relaxers & straightening products",
                label: "Hair Relaxers Straightening Products",
                isLeaf: true,
              },
              {
                value: "medicated hair treatments",
                label: "Medicated Hair Treatments",
                isLeaf: true,
              },
              {
                value: "perms",
                label: "Perms",
                isLeaf: true,
              },
              {
                value: "temporary tattoos",
                label: "Temporary Tattoos",
                isLeaf: true,
              },
            ],
          },
        ],
      },
    ],
  },

  {
    value: "home",
    label: "Home",
    children: [
      {
        value: "cleaning supplies",
        label: "Cleaning Supplies",
        children: [
          {
            value: "trash cans & wastebaskets",
            label: "Trash Cans Wastebaskets",
            isLeaf: true,
          },
          {
            value: "shoe care",
            label: "Shoe Care",
            children: [
              {
                value: "boot pulls",
                label: "Boot Pulls",
                isLeaf: true,
              },
              {
                value: "shoe trees & shapers",
                label: "Shoe Trees Shapers",
                isLeaf: true,
              },
              {
                value: "shoe treatments & dyes",
                label: "Shoe Treatments Dyes",
                isLeaf: true,
              },
              {
                value: "shoe scrapers",
                label: "Shoe Scrapers",
                isLeaf: true,
              },
              {
                value: "shoe polishes & waxes",
                label: "Shoe Polishes Waxes",
                isLeaf: true,
              },
              {
                value: "shoe polishers",
                label: "Shoe Polishers",
                isLeaf: true,
              },
              {
                value: "shoe horns",
                label: "Shoe Horns",
                isLeaf: true,
              },
              {
                value: "shoe dryers",
                label: "Shoe Dryers",
                isLeaf: true,
              },
              {
                value: "shoe care kits",
                label: "Shoe Care Kits",
                isLeaf: true,
              },
              {
                value: "shoe brushes",
                label: "Shoe Brushes",
                isLeaf: true,
              },
              {
                value: "shoe bags",
                label: "Shoe Bags",
                isLeaf: true,
              },
            ],
          },
          {
            value: "party supplies",
            label: "Party Supplies",
            children: [
              {
                value: "piñatas",
                label: "Pinatas",
                isLeaf: true,
              },
              {
                value: "wrapping paper",
                label: "Wrapping Paper",
                isLeaf: true,
              },
              {
                value: "tulle",
                label: "Tulle",
                isLeaf: true,
              },
              {
                value: "ribbons & bows",
                label: "Ribbons Bows",
                isLeaf: true,
              },
              {
                value: "pi√±atas",
                label: "Pi√ Atas",
                isLeaf: true,
              },
              {
                value: "party sets & kits",
                label: "Party Sets Kits",
                isLeaf: true,
              },
              {
                value: "party favors",
                label: "Party Favors",
                isLeaf: true,
              },
              {
                value: "organizers & planners",
                label: "Organizers Planners",
                isLeaf: true,
              },
              {
                value: "invitations & stationery",
                label: "Invitations Stationery",
                isLeaf: true,
              },
              {
                value: "guest books",
                label: "Guest Books",
                isLeaf: true,
              },
              {
                value: "glow sticks",
                label: "Glow Sticks",
                isLeaf: true,
              },
              {
                value: "gift bags",
                label: "Gift Bags",
                isLeaf: true,
              },
              {
                value: "aisle runners",
                label: "Aisle Runners",
                isLeaf: true,
              },
              {
                value: "confetti",
                label: "Confetti",
                isLeaf: true,
              },
              {
                value: "centerpieces",
                label: "Centerpieces",
                isLeaf: true,
              },
              {
                value: "card boxes",
                label: "Card Boxes",
                isLeaf: true,
              },
              {
                value: "candles",
                label: "Candles",
                isLeaf: true,
              },
              {
                value: "cake toppers",
                label: "Cake Toppers",
                isLeaf: true,
              },
              {
                value: "banners, bunting & garlands",
                label: "Banners Bunting Garlands",
                isLeaf: true,
              },
              {
                value: "balloons",
                label: "Balloons",
                isLeaf: true,
              },
              {
                value: "badges & pins",
                label: "Badges Pins",
                isLeaf: true,
              },
            ],
          },
          {
            value: "household disposables",
            label: "Household Disposables",
            children: [
              {
                value: "trash bags",
                label: "Trash Bags",
                isLeaf: true,
              },
              {
                value: "toilet paper",
                label: "Toilet Paper",
                isLeaf: true,
              },
              {
                value: "paper towels",
                label: "Paper Towels",
                isLeaf: true,
              },
              {
                value: "disposable straws",
                label: "Disposable Straws",
                isLeaf: true,
              },
              {
                value: "disposable tableware",
                label: "Disposable Tableware",
                children: [
                  {
                    value: "disposable cups",
                    label: "Disposable Cups",
                    isLeaf: true,
                  },
                  {
                    value: "disposable tablecloths",
                    label: "Disposable Tablecloths",
                    isLeaf: true,
                  },
                  {
                    value: "disposable plates",
                    label: "Disposable Plates",
                    isLeaf: true,
                  },
                  {
                    value: "disposable napkins",
                    label: "Disposable Napkins",
                    isLeaf: true,
                  },
                  {
                    value: "disposable cutlery",
                    label: "Disposable Cutlery",
                    isLeaf: true,
                  },
                ],
              },
            ],
          },
          {
            value: "floor protectors & runners",
            label: "Floor Protectors Runners",
            isLeaf: true,
          },
          {
            value: "fabric & upholstery protectors",
            label: "Fabric Upholstery Protectors",
            isLeaf: true,
          },
          {
            value: "drawer & shelf liners",
            label: "Drawer Shelf Liners",
            isLeaf: true,
          },
          {
            value: "cleaning products & chemicals",
            label: "Cleaning Products Chemicals",
            children: [
              {
                value: "carpet shampooers",
                label: "Carpet Shampooers",
                isLeaf: true,
              },
              {
                value: "cleaning products & solutions",
                label: "Cleaning Products Solutions",
                isLeaf: true,
              },
              {
                value: "carpet steamers",
                label: "Carpet Steamers",
                isLeaf: true,
              },
            ],
          },
          {
            value: "vacuums & floor cleaners",
            label: "Vacuums Floor Cleaners",
            children: [
              {
                value: "canister vacuum cleaners",
                label: "Canister Vacuum Cleaners",
                isLeaf: true,
              },
              {
                value: "vacuum cleaners",
                label: "Vacuum Cleaners",
                isLeaf: true,
              },
              {
                value: "vacuum cleaner parts & accessories",
                label: "Vacuum Cleaner Parts Accessories",
                isLeaf: true,
              },
              {
                value: "upright vacuum cleaners",
                label: "Upright Vacuum Cleaners",
                isLeaf: true,
              },
              {
                value: "stick vacuum cleaners",
                label: "Stick Vacuum Cleaners",
                isLeaf: true,
              },
              {
                value: "robotic vacuum cleaners",
                label: "Robotic Vacuum Cleaners",
                isLeaf: true,
              },
              {
                value: "handheld vacuum cleaners",
                label: "Handheld Vacuum Cleaners",
                isLeaf: true,
              },
            ],
          },
          {
            value: "laundry supplies",
            label: "Laundry Supplies",
            children: [
              {
                value: "wrinkle releasers & anti-static sprays",
                label: "Wrinkle Releasers Anti Static Sprays",
                isLeaf: true,
              },
              {
                value: "washing bags",
                label: "Washing Bags",
                isLeaf: true,
              },
              {
                value: "lint rollers",
                label: "Lint Rollers",
                isLeaf: true,
              },
              {
                value: "laundry detergents",
                label: "Laundry Detergents",
                isLeaf: true,
              },
              {
                value: "laundry balls",
                label: "Laundry Balls",
                isLeaf: true,
              },
              {
                value: "irons",
                label: "Irons",
                isLeaf: true,
              },
              {
                value: "ironing boards",
                label: "Ironing Boards",
                isLeaf: true,
              },
              {
                value: "ironing board racks",
                label: "Ironing Board Racks",
                isLeaf: true,
              },
              {
                value: "ironing board covers",
                label: "Ironing Board Covers",
                isLeaf: true,
              },
              {
                value: "iron rests",
                label: "Iron Rests",
                isLeaf: true,
              },
              {
                value: "hampers & baskets",
                label: "Hampers Baskets",
                isLeaf: true,
              },
              {
                value: "garment steamers",
                label: "Garment Steamers",
                isLeaf: true,
              },
              {
                value: "garment shields",
                label: "Garment Shields",
                isLeaf: true,
              },
              {
                value: "bleaches & stain removers",
                label: "Bleaches Stain Removers",
                isLeaf: true,
              },
              {
                value: "fabric starch",
                label: "Fabric Starch",
                isLeaf: true,
              },
              {
                value: "fabric softeners & dryer sheets",
                label: "Fabric Softeners Dryer Sheets",
                isLeaf: true,
              },
              {
                value: "fabric shavers",
                label: "Fabric Shavers",
                isLeaf: true,
              },
              {
                value: "fabric deodorizers",
                label: "Fabric Deodorizers",
                isLeaf: true,
              },
              {
                value: "drying racks & hangers",
                label: "Drying Racks Hangers",
                isLeaf: true,
              },
              {
                value: "dry cleaning kits",
                label: "Dry Cleaning Kits",
                isLeaf: true,
              },
              {
                value: "clothespins",
                label: "Clothespins",
                isLeaf: true,
              },
            ],
          },
          {
            value: "dishwashing supplies",
            label: "Dishwashing Supplies",
            children: [
              {
                value: "dish soaps",
                label: "Dish Soaps",
                isLeaf: true,
              },
              {
                value: "dishwasher rinse aids",
                label: "Dishwasher Rinse Aids",
                isLeaf: true,
              },
              {
                value: "dishwasher detergents",
                label: "Dishwasher Detergents",
                isLeaf: true,
              },
            ],
          },
          {
            value: "cleaning tools",
            label: "Cleaning Tools",
            children: [
              {
                value: "carpet & floor sweepers",
                label: "Carpet Floor Sweepers",
                isLeaf: true,
              },
              {
                value: "squeegees",
                label: "Squeegees",
                isLeaf: true,
              },
              {
                value: "cleaning buckets",
                label: "Cleaning Buckets",
                isLeaf: true,
              },
              {
                value: "sponges & scouring pads",
                label: "Sponges Scouring Pads",
                isLeaf: true,
              },
              {
                value: "scrub brushes",
                label: "Scrub Brushes",
                isLeaf: true,
              },
              {
                value: "pipe cleaners",
                label: "Pipe Cleaners",
                isLeaf: true,
              },
              {
                value: "mops & brooms",
                label: "Mops Brooms",
                isLeaf: true,
              },
              {
                value: "dustpans",
                label: "Dustpans",
                isLeaf: true,
              },
              {
                value: "dusters",
                label: "Dusters",
                isLeaf: true,
              },
              {
                value: "cleaning towels & cloths",
                label: "Cleaning Towels Cloths",
                isLeaf: true,
              },
              {
                value: "cleaning gloves",
                label: "Cleaning Gloves",
                isLeaf: true,
              },
            ],
          },
        ],
      },
      {
        value: "large appliances",
        label: "Large Appliances",
        children: [
          {
            value: "ranges & ovens",
            label: "Ranges Ovens",
            isLeaf: true,
          },
          {
            value: "refrigerators & freezers",
            label: "Refrigerators Freezers",
            isLeaf: true,
          },
          {
            value: "washers & dryers",
            label: "Washers Dryers",
            children: [
              {
                value: "dryers",
                label: "Dryers",
                isLeaf: true,
              },
              {
                value: "washer & dryer sets",
                label: "Washer Dryer Sets",
                isLeaf: true,
              },
              {
                value: "washing machines",
                label: "Washing Machines",
                isLeaf: true,
              },
            ],
          },
          {
            value: "microwave ovens",
            label: "Microwave Ovens",
            isLeaf: true,
          },
          {
            value: "dishwashers",
            label: "Dishwashers",
            isLeaf: true,
          },
          {
            value: "cooktops",
            label: "Cooktops",
            isLeaf: true,
          },
          {
            value: "ice makers",
            label: "Ice Makers",
            isLeaf: true,
          },
          {
            value: "large appliance parts & accessories",
            label: "Large Appliance Parts Accessories",
            isLeaf: true,
          },
          {
            value: "range hoods",
            label: "Range Hoods",
            isLeaf: true,
          },
          {
            value: "trash compactors",
            label: "Trash Compactors",
            isLeaf: true,
          },
        ],
      },
      {
        value: "bedding",
        label: "Bedding",
        children: [
          {
            value: "comforters",
            label: "Comforters",
            isLeaf: true,
          },
          {
            value: "mattress pads & protectors",
            label: "Mattress Pads Protectors",
            isLeaf: true,
          },
          {
            value: "duvet covers",
            label: "Duvet Covers",
            isLeaf: true,
          },
          {
            value: "blankets & throws",
            label: "Blankets Throws",
            isLeaf: true,
          },
          {
            value: "bedspreads, quilts & coverlets",
            label: "Bedspreads Quilts Coverlets",
            isLeaf: true,
          },
          {
            value: "bed skirts",
            label: "Bed Skirts",
            isLeaf: true,
          },
          {
            value: "bed sheets & pillowcases",
            label: "Bed Sheets Pillowcases",
            isLeaf: true,
          },
          {
            value: "bedding sets & collections",
            label: "Bedding Sets Collections",
            isLeaf: true,
          },
          {
            value: "bed pillows & positioners",
            label: "Bed Pillows Positioners",
            isLeaf: true,
          },
          {
            value: "bed canopies",
            label: "Bed Canopies",
            isLeaf: true,
          },
        ],
      },
      {
        value: "furniture",
        label: "Furniture",
        children: [
          {
            value: "wall shelves & ledges",
            label: "Wall Shelves Ledges",
            isLeaf: true,
          },
          {
            value: "vanities & makeup tables",
            label: "Vanities Makeup Tables",
            isLeaf: true,
          },
          {
            value: "trunks & chests",
            label: "Trunks Chests",
            isLeaf: true,
          },
          {
            value: "slipcovers",
            label: "Slipcovers",
            isLeaf: true,
          },
          {
            value: "room dividers",
            label: "Room Dividers",
            isLeaf: true,
          },
          {
            value: "hall trees & coat racks",
            label: "Hall Trees Coat Racks",
            isLeaf: true,
          },
          {
            value: "benches",
            label: "Benches",
            isLeaf: true,
          },
          {
            value: "bean bags & inflatable furniture",
            label: "Bean Bags Inflatable Furniture",
            isLeaf: true,
          },
          {
            value: "living room furniture",
            label: "Living Room Furniture",
            children: [
              {
                value: "sofas, loveseats & sectionals",
                label: "Sofas Loveseats Sectionals",
                isLeaf: true,
              },
              {
                value: "ottomans & footstools",
                label: "Ottomans Footstools",
                isLeaf: true,
              },
              {
                value: "media storage racks",
                label: "Media Storage Racks",
                isLeaf: true,
              },
              {
                value: "magazine racks",
                label: "Magazine Racks",
                isLeaf: true,
              },
              {
                value: "bookcases",
                label: "Bookcases",
                isLeaf: true,
              },
              {
                value: "living room furniture sets",
                label: "Living Room Furniture Sets",
                isLeaf: true,
              },
              {
                value: "living room chairs",
                label: "Living Room Chairs",
                isLeaf: true,
              },
              {
                value: "futons",
                label: "Futons",
                isLeaf: true,
              },
              {
                value: "entertainment units & tv stands",
                label: "Entertainment Units Tv Stands",
                isLeaf: true,
              },
              {
                value: "end & side tables",
                label: "End Side Tables",
                isLeaf: true,
              },
              {
                value: "console tables",
                label: "Console Tables",
                isLeaf: true,
              },
              {
                value: "coffee tables",
                label: "Coffee Tables",
                isLeaf: true,
              },
              {
                value: "chaise lounges",
                label: "Chaise Lounges",
                isLeaf: true,
              },
            ],
          },
          {
            value: "office furniture",
            label: "Office Furniture",
            children: [
              {
                value: "storage & filing cabinets",
                label: "Storage Filing Cabinets",
                isLeaf: true,
              },
              {
                value: "office suites",
                label: "Office Suites",
                isLeaf: true,
              },
              {
                value: "office furniture sets",
                label: "Office Furniture Sets",
                isLeaf: true,
              },
              {
                value: "office chairs",
                label: "Office Chairs",
                isLeaf: true,
              },
              {
                value: "desks",
                label: "Desks",
                isLeaf: true,
              },
            ],
          },
          {
            value: "dining room furniture",
            label: "Dining Room Furniture",
            children: [
              {
                value: "kitchen islands",
                label: "Kitchen Islands",
                isLeaf: true,
              },
              {
                value: "dining tables",
                label: "Dining Tables",
                isLeaf: true,
              },
              {
                value: "dining furniture sets",
                label: "Dining Furniture Sets",
                isLeaf: true,
              },
              {
                value: "dining chairs",
                label: "Dining Chairs",
                isLeaf: true,
              },
              {
                value: "china cabinets & hutches",
                label: "China Cabinets Hutches",
                isLeaf: true,
              },
              {
                value: "buffets & sideboards",
                label: "Buffets Sideboards",
                isLeaf: true,
              },
              {
                value: "bar stools",
                label: "Bar Stools",
                isLeaf: true,
              },
              {
                value: "bar carts & serving carts",
                label: "Bar Carts Serving Carts",
                isLeaf: true,
              },
            ],
          },
          {
            value: "bedroom furniture",
            label: "Bedroom Furniture",
            children: [
              {
                value: "armoires & wardrobes",
                label: "Armoires Wardrobes",
                isLeaf: true,
              },
              {
                value: "nightstands",
                label: "Nightstands",
                isLeaf: true,
              },
              {
                value: "dressers & chests of drawers",
                label: "Dressers Chests Of Drawers",
                isLeaf: true,
              },
              {
                value: "beds & mattresses",
                label: "Beds Mattresses",
                children: [
                  {
                    value: "bed & waterbed accessories",
                    label: "Bed Waterbed Accessories",
                    isLeaf: true,
                  },
                  {
                    value: "mattresses",
                    label: "Mattresses",
                    isLeaf: true,
                  },
                  {
                    value: "mattress foundations",
                    label: "Mattress Foundations",
                    isLeaf: true,
                  },
                  {
                    value: "inflatable mattresses",
                    label: "Inflatable Mattresses",
                    isLeaf: true,
                  },
                  {
                    value: "headboards & footboards",
                    label: "Headboards Footboards",
                    isLeaf: true,
                  },
                  {
                    value: "beds & bed frames",
                    label: "Beds Bed Frames",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "bedroom furniture sets",
                label: "Bedroom Furniture Sets",
                isLeaf: true,
              },
            ],
          },
        ],
      },
      {
        value: "home goods",
        label: "Home Goods",
        children: [
          {
            value: "storage & organization",
            label: "Storage Organization",
            children: [
              {
                value: "clothing storage",
                label: "Clothing Storage",
                children: [
                  {
                    value: "closet organizers",
                    label: "Closet Organizers",
                    isLeaf: true,
                  },
                  {
                    value: "clothes hangers",
                    label: "Clothes Hangers",
                    isLeaf: true,
                  },
                  {
                    value: "garment racks",
                    label: "Garment Racks",
                    isLeaf: true,
                  },
                  {
                    value: "hat boxes",
                    label: "Hat Boxes",
                    isLeaf: true,
                  },
                  {
                    value: "shoe organizers",
                    label: "Shoe Organizers",
                    isLeaf: true,
                  },
                  {
                    value: "umbrella stands & cases",
                    label: "Umbrella Stands Cases",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "general storage",
                label: "General Storage",
                children: [
                  {
                    value: "hooks & hangers",
                    label: "Hooks Hangers",
                    isLeaf: true,
                  },
                  {
                    value: "storage bags",
                    label: "Storage Bags",
                    isLeaf: true,
                  },
                  {
                    value: "storage bins & baskets",
                    label: "Storage Bins Baskets",
                    isLeaf: true,
                  },
                  {
                    value: "storage drawers",
                    label: "Storage Drawers",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "jewelry organizers",
                label: "Jewelry Organizers",
                children: [
                  {
                    value: "hanging jewelry organizers",
                    label: "Hanging Jewelry Organizers",
                    isLeaf: true,
                  },
                  {
                    value: "jewelry bags & pouches",
                    label: "Jewelry Bags Pouches",
                    isLeaf: true,
                  },
                  {
                    value: "jewelry boxes",
                    label: "Jewelry Boxes",
                    isLeaf: true,
                  },
                  {
                    value: "jewelry displays",
                    label: "Jewelry Displays",
                    isLeaf: true,
                  },
                  {
                    value: "jewelry drawer inserts",
                    label: "Jewelry Drawer Inserts",
                    isLeaf: true,
                  },
                  {
                    value: "jewelry rolls",
                    label: "Jewelry Rolls",
                    isLeaf: true,
                  },
                  {
                    value: "jewelry stands",
                    label: "Jewelry Stands",
                    isLeaf: true,
                  },
                  {
                    value: "jewelry trays",
                    label: "Jewelry Trays",
                    isLeaf: true,
                  },
                ],
              },
            ],
          },
          {
            value: "lamps & lighting",
            label: "Lamps Lighting",
            children: [
              {
                value: "light bulbs",
                label: "Light Bulbs",
                isLeaf: true,
              },
              {
                value: "night lights",
                label: "Night Lights",
                isLeaf: true,
              },
              {
                value: "string lights",
                label: "String Lights",
                isLeaf: true,
              },
              {
                value: "track lighting",
                label: "Track Lighting",
                isLeaf: true,
              },
              {
                value: "wall fixtures",
                label: "Wall Fixtures",
                isLeaf: true,
              },
              {
                value: "ceiling fans",
                label: "Ceiling Fans",
                isLeaf: true,
              },
              {
                value: "chandeliers",
                label: "Chandeliers",
                isLeaf: true,
              },
              {
                value: "lamp shades",
                label: "Lamp Shades",
                isLeaf: true,
              },
              {
                value: "lamps",
                label: "Lamps",
                children: [
                  {
                    value: "floor lamps",
                    label: "Floor Lamps",
                    isLeaf: true,
                  },
                  {
                    value: "lamp accessories",
                    label: "Lamp Accessories",
                    isLeaf: true,
                  },
                  {
                    value: "table lamps",
                    label: "Table Lamps",
                    isLeaf: true,
                  },
                ],
              },
            ],
          },
          {
            value: "kitchen & dining",
            label: "Kitchen Dining",
            children: [
              {
                value: "food storage",
                label: "Food Storage",
                children: [
                  {
                    value: "wax paper",
                    label: "Wax Paper",
                    isLeaf: true,
                  },
                  {
                    value: "vacuum sealers",
                    label: "Vacuum Sealers",
                    isLeaf: true,
                  },
                  {
                    value: "twist ties & bag clips",
                    label: "Twist Ties Bag Clips",
                    isLeaf: true,
                  },
                  {
                    value: "storage canisters & jars",
                    label: "Storage Canisters Jars",
                    isLeaf: true,
                  },
                  {
                    value: "plastic wrap",
                    label: "Plastic Wrap",
                    isLeaf: true,
                  },
                  {
                    value: "pastry cloth",
                    label: "Pastry Cloth",
                    isLeaf: true,
                  },
                  {
                    value: "honey jars",
                    label: "Honey Jars",
                    isLeaf: true,
                  },
                  {
                    value: "food wrap dispensers",
                    label: "Food Wrap Dispensers",
                    isLeaf: true,
                  },
                  {
                    value: "food storage containers",
                    label: "Food Storage Containers",
                    isLeaf: true,
                  },
                  {
                    value: "food storage bags",
                    label: "Food Storage Bags",
                    isLeaf: true,
                  },
                  {
                    value: "parchment paper",
                    label: "Parchment Paper",
                    isLeaf: true,
                  },
                  {
                    value: "bread boxes",
                    label: "Bread Boxes",
                    isLeaf: true,
                  },
                  {
                    value: "candy buckets",
                    label: "Candy Buckets",
                    isLeaf: true,
                  },
                  {
                    value: "cookie jars",
                    label: "Cookie Jars",
                    isLeaf: true,
                  },
                  {
                    value: "foil",
                    label: "Foil",
                    isLeaf: true,
                  },
                  {
                    value: "food & beverage labels",
                    label: "Food Beverage Labels",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "food & beverage carriers",
                label: "Food Beverage Carriers",
                children: [
                  {
                    value: "replacement drink lids",
                    label: "Replacement Drink Lids",
                    isLeaf: true,
                  },
                  {
                    value: "reusable straws",
                    label: "Reusable Straws",
                    isLeaf: true,
                  },
                  {
                    value: "thermoses",
                    label: "Thermoses",
                    isLeaf: true,
                  },
                  {
                    value: "water bottles",
                    label: "Water Bottles",
                    isLeaf: true,
                  },
                  {
                    value: "wine carriers",
                    label: "Wine Carriers",
                    isLeaf: true,
                  },
                  {
                    value: "insulated bags",
                    label: "Insulated Bags",
                    isLeaf: true,
                  },
                  {
                    value: "grocery bags",
                    label: "Grocery Bags",
                    isLeaf: true,
                  },
                  {
                    value: "lunch boxes & totes",
                    label: "Lunch Boxes Totes",
                    isLeaf: true,
                  },
                  {
                    value: "picnic baskets",
                    label: "Picnic Baskets",
                    isLeaf: true,
                  },
                  {
                    value: "flasks",
                    label: "Flasks",
                    isLeaf: true,
                  },
                  {
                    value: "drink sleeves",
                    label: "Drink Sleeves",
                    isLeaf: true,
                  },
                  {
                    value: "coolers",
                    label: "Coolers",
                    isLeaf: true,
                  },
                  {
                    value: "airpots",
                    label: "Airpots",
                    isLeaf: true,
                  },
                  {
                    value: "canteens",
                    label: "Canteens",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "baking accessories",
                label: "Baking Accessories",
                children: [
                  {
                    value: "baking weights",
                    label: "Baking Weights",
                    isLeaf: true,
                  },
                  {
                    value: "cake boards",
                    label: "Cake Boards",
                    isLeaf: true,
                  },
                  {
                    value: "cake decorating supplies",
                    label: "Cake Decorating Supplies",
                    isLeaf: true,
                  },
                  {
                    value: "cookie cutters & presses",
                    label: "Cookie Cutters Presses",
                    isLeaf: true,
                  },
                  {
                    value: "icing bags",
                    label: "Icing Bags",
                    isLeaf: true,
                  },
                  {
                    value: "pastry cutters",
                    label: "Pastry Cutters",
                    isLeaf: true,
                  },
                  {
                    value: "pastry forms",
                    label: "Pastry Forms",
                    isLeaf: true,
                  },
                  {
                    value: "rolling pins",
                    label: "Rolling Pins",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "prefabricated kitchens & kitchenettes",
                label: "Prefabricated Kitchens Kitchenettes",
                isLeaf: true,
              },
              {
                value: "linens & textiles",
                label: "Linens Textiles",
                children: [
                  {
                    value: "aprons",
                    label: "Aprons",
                    isLeaf: true,
                  },
                  {
                    value: "doilies",
                    label: "Doilies",
                    isLeaf: true,
                  },
                  {
                    value: "napkin rings & holders",
                    label: "Napkin Rings Holders",
                    isLeaf: true,
                  },
                  {
                    value: "napkins",
                    label: "Napkins",
                    isLeaf: true,
                  },
                  {
                    value: "oven mitts & potholders",
                    label: "Oven Mitts Potholders",
                    isLeaf: true,
                  },
                  {
                    value: "placemats",
                    label: "Placemats",
                    isLeaf: true,
                  },
                  {
                    value: "table linen sets",
                    label: "Table Linen Sets",
                    isLeaf: true,
                  },
                  {
                    value: "table runners",
                    label: "Table Runners",
                    isLeaf: true,
                  },
                  {
                    value: "table skirts",
                    label: "Table Skirts",
                    isLeaf: true,
                  },
                  {
                    value: "tablecloths",
                    label: "Tablecloths",
                    isLeaf: true,
                  },
                  {
                    value: "tea cozies",
                    label: "Tea Cozies",
                    isLeaf: true,
                  },
                  {
                    value: "towels & dishcloths",
                    label: "Towels Dishcloths",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "small kitchen appliances",
                label: "Small Kitchen Appliances",
                children: [
                  {
                    value: "toasters & toaster ovens",
                    label: "Toasters Toaster Ovens",
                    isLeaf: true,
                  },
                  {
                    value: "water filters & pitchers",
                    label: "Water Filters Pitchers",
                    isLeaf: true,
                  },
                  {
                    value: "beverage warmers",
                    label: "Beverage Warmers",
                    isLeaf: true,
                  },
                  {
                    value: "bread makers",
                    label: "Bread Makers",
                    isLeaf: true,
                  },
                  {
                    value: "cookers & steamers",
                    label: "Cookers Steamers",
                    isLeaf: true,
                  },
                  {
                    value: "deep fryers & air fryers",
                    label: "Deep Fryers Air Fryers",
                    isLeaf: true,
                  },
                  {
                    value: "electric skillets & woks",
                    label: "Electric Skillets Woks",
                    isLeaf: true,
                  },
                  {
                    value: "fondue pots",
                    label: "Fondue Pots",
                    isLeaf: true,
                  },
                  {
                    value: "food dehydrators",
                    label: "Food Dehydrators",
                    isLeaf: true,
                  },
                  {
                    value: "food processors",
                    label: "Food Processors",
                    isLeaf: true,
                  },
                  {
                    value: "griddles & grills",
                    label: "Griddles Grills",
                    isLeaf: true,
                  },
                  {
                    value: "grinders & mills",
                    label: "Grinders Mills",
                    isLeaf: true,
                  },
                  {
                    value: "hot plates",
                    label: "Hot Plates",
                    isLeaf: true,
                  },
                  {
                    value: "ice crushers & shavers",
                    label: "Ice Crushers Shavers",
                    isLeaf: true,
                  },
                  {
                    value: "juicers",
                    label: "Juicers",
                    isLeaf: true,
                  },
                  {
                    value: "kitchen scales",
                    label: "Kitchen Scales",
                    isLeaf: true,
                  },
                  {
                    value: "meat grinders",
                    label: "Meat Grinders",
                    isLeaf: true,
                  },
                  {
                    value: "mixers & blenders",
                    label: "Mixers Blenders",
                    isLeaf: true,
                  },
                  {
                    value: "pizza makers & ovens",
                    label: "Pizza Makers Ovens",
                    isLeaf: true,
                  },
                  {
                    value: "portable stoves",
                    label: "Portable Stoves",
                    isLeaf: true,
                  },
                  {
                    value: "roaster ovens & rotisseries",
                    label: "Roaster Ovens Rotisseries",
                    isLeaf: true,
                  },
                  {
                    value: "slicers & electric knives",
                    label: "Slicers Electric Knives",
                    isLeaf: true,
                  },
                  {
                    value: "coffee makers & espresso machines",
                    label: "Coffee Makers Espresso Machines",
                    children: [
                      {
                        value: "coffee grinders",
                        label: "Coffee Grinders",
                        isLeaf: true,
                      },
                      {
                        value: "coffee makers",
                        label: "Coffee Makers",
                        isLeaf: true,
                      },
                      {
                        value: "coffee roasters",
                        label: "Coffee Roasters",
                        isLeaf: true,
                      },
                      {
                        value: "espresso machines",
                        label: "Espresso Machines",
                        isLeaf: true,
                      },
                      {
                        value: "french presses",
                        label: "French Presses",
                        isLeaf: true,
                      },
                      {
                        value: "milk frothers",
                        label: "Milk Frothers",
                        isLeaf: true,
                      },
                      {
                        value: "percolators & moka pots",
                        label: "Percolators Moka Pots",
                        isLeaf: true,
                      },
                      {
                        value: "tea infusers & strainers",
                        label: "Tea Infusers Strainers",
                        isLeaf: true,
                      },
                      {
                        value: "tea kettles",
                        label: "Tea Kettles",
                        isLeaf: true,
                      },
                      {
                        value: "tea makers",
                        label: "Tea Makers",
                        isLeaf: true,
                      },
                    ],
                  },
                  {
                    value: "specialty kitchen appliances",
                    label: "Specialty Kitchen Appliances",
                    children: [
                      {
                        value: "cotton candy machines",
                        label: "Cotton Candy Machines",
                        isLeaf: true,
                      },
                      {
                        value: "cupcake, pie & dessert makers",
                        label: "Cupcake Pie Dessert Makers",
                        isLeaf: true,
                      },
                      {
                        value: "frozen drink makers",
                        label: "Frozen Drink Makers",
                        isLeaf: true,
                      },
                      {
                        value: "hot drink makers",
                        label: "Hot Drink Makers",
                        isLeaf: true,
                      },
                      {
                        value: "ice cream makers",
                        label: "Ice Cream Makers",
                        isLeaf: true,
                      },
                      {
                        value: "pasta makers",
                        label: "Pasta Makers",
                        isLeaf: true,
                      },
                      {
                        value: "popcorn makers",
                        label: "Popcorn Makers",
                        isLeaf: true,
                      },
                      {
                        value: "soda makers",
                        label: "Soda Makers",
                        isLeaf: true,
                      },
                      {
                        value: "soy milk makers",
                        label: "Soy Milk Makers",
                        isLeaf: true,
                      },
                      {
                        value: "waffle makers",
                        label: "Waffle Makers",
                        isLeaf: true,
                      },
                      {
                        value: "yogurt makers",
                        label: "Yogurt Makers",
                        isLeaf: true,
                      },
                    ],
                  },
                ],
              },
              {
                value: "flatware",
                label: "Flatware",
                children: [
                  {
                    value: "spoons",
                    label: "Spoons",
                    isLeaf: true,
                  },
                  {
                    value: "scissors & shears",
                    label: "Scissors Shears",
                    isLeaf: true,
                  },
                  {
                    value: "knives",
                    label: "Knives",
                    isLeaf: true,
                  },
                  {
                    value: "knife sharpeners",
                    label: "Knife Sharpeners",
                    isLeaf: true,
                  },
                  {
                    value: "knife blocks & storage",
                    label: "Knife Blocks Storage",
                    isLeaf: true,
                  },
                  {
                    value: "forks",
                    label: "Forks",
                    isLeaf: true,
                  },
                  {
                    value: "chopsticks",
                    label: "Chopsticks",
                    isLeaf: true,
                  },
                  {
                    value: "cutlery & kitchen knives",
                    label: "Cutlery Kitchen Knives",
                    isLeaf: true,
                  },
                  {
                    value: "flatware sets",
                    label: "Flatware Sets",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "dinnerware",
                label: "Dinnerware",
                children: [
                  {
                    value: "cake stands",
                    label: "Cake Stands",
                    isLeaf: true,
                  },
                  {
                    value: "specialty serving dishes",
                    label: "Specialty Serving Dishes",
                    children: [
                      {
                        value: "egg cups",
                        label: "Egg Cups",
                        isLeaf: true,
                      },
                      {
                        value: "gravy & sauce boats",
                        label: "Gravy Sauce Boats",
                        isLeaf: true,
                      },
                      {
                        value: "punch bowls & sets",
                        label: "Punch Bowls Sets",
                        isLeaf: true,
                      },
                      {
                        value: "trivets",
                        label: "Trivets",
                        isLeaf: true,
                      },
                    ],
                  },
                  {
                    value: "serving bowls & tureens",
                    label: "Serving Bowls Tureens",
                    isLeaf: true,
                  },
                  {
                    value: "salt & pepper shakers",
                    label: "Salt Pepper Shakers",
                    isLeaf: true,
                  },
                  {
                    value: "platters & trays",
                    label: "Platters Trays",
                    isLeaf: true,
                  },
                  {
                    value: "plates",
                    label: "Plates",
                    isLeaf: true,
                  },
                  {
                    value: "dinnerware sets",
                    label: "Dinnerware Sets",
                    isLeaf: true,
                  },
                  {
                    value: "bowls",
                    label: "Bowls",
                    isLeaf: true,
                  },
                  {
                    value: "butter dishes",
                    label: "Butter Dishes",
                    isLeaf: true,
                  },
                  {
                    value: "pitchers & carafes",
                    label: "Pitchers Carafes",
                    isLeaf: true,
                  },
                  {
                    value: "creamers & sugar bowls",
                    label: "Creamers Sugar Bowls",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "kitchen tools & utensils",
                label: "Kitchen Tools Utensils",
                children: [
                  {
                    value: "baking peels",
                    label: "Baking Peels",
                    isLeaf: true,
                  },
                  {
                    value: "burger presses",
                    label: "Burger Presses",
                    isLeaf: true,
                  },
                  {
                    value: "can crushers",
                    label: "Can Crushers",
                    isLeaf: true,
                  },
                  {
                    value: "can openers",
                    label: "Can Openers",
                    isLeaf: true,
                  },
                  {
                    value: "canning supplies",
                    label: "Canning Supplies",
                    isLeaf: true,
                  },
                  {
                    value: "colanders, strainers & sifters",
                    label: "Colanders Strainers Sifters",
                    isLeaf: true,
                  },
                  {
                    value: "cooking thermometers",
                    label: "Cooking Thermometers",
                    isLeaf: true,
                  },
                  {
                    value: "cooking torches",
                    label: "Cooking Torches",
                    isLeaf: true,
                  },
                  {
                    value: "cooking utensils",
                    label: "Cooking Utensils",
                    isLeaf: true,
                  },
                  {
                    value: "cutting boards",
                    label: "Cutting Boards",
                    isLeaf: true,
                  },
                  {
                    value: "dish racks",
                    label: "Dish Racks",
                    isLeaf: true,
                  },
                  {
                    value: "food choppers",
                    label: "Food Choppers",
                    isLeaf: true,
                  },
                  {
                    value: "food scoops",
                    label: "Food Scoops",
                    isLeaf: true,
                  },
                  {
                    value: "food steaming bags",
                    label: "Food Steaming Bags",
                    isLeaf: true,
                  },
                  {
                    value: "food sticks & skewers",
                    label: "Food Sticks Skewers",
                    isLeaf: true,
                  },
                  {
                    value: "funnels",
                    label: "Funnels",
                    isLeaf: true,
                  },
                  {
                    value: "garlic presses",
                    label: "Garlic Presses",
                    isLeaf: true,
                  },
                  {
                    value: "graters & zesters",
                    label: "Graters Zesters",
                    isLeaf: true,
                  },
                  {
                    value: "measuring cups & spoons",
                    label: "Measuring Cups Spoons",
                    isLeaf: true,
                  },
                  {
                    value: "mixed kitchen tool sets",
                    label: "Mixed Kitchen Tool Sets",
                    isLeaf: true,
                  },
                  {
                    value: "mixing bowls",
                    label: "Mixing Bowls",
                    isLeaf: true,
                  },
                  {
                    value: "mortar & pestles",
                    label: "Mortar Pestles",
                    isLeaf: true,
                  },
                  {
                    value: "nut & seafood crackers",
                    label: "Nut Seafood Crackers",
                    isLeaf: true,
                  },
                  {
                    value: "oil & vinegar dispensers",
                    label: "Oil Vinegar Dispensers",
                    isLeaf: true,
                  },
                  {
                    value: "oven bags",
                    label: "Oven Bags",
                    isLeaf: true,
                  },
                  {
                    value: "peelers & corers",
                    label: "Peelers Corers",
                    isLeaf: true,
                  },
                  {
                    value: "pizza cutters",
                    label: "Pizza Cutters",
                    isLeaf: true,
                  },
                  {
                    value: "rings & molds",
                    label: "Rings Molds",
                    isLeaf: true,
                  },
                  {
                    value: "salad spinners",
                    label: "Salad Spinners",
                    isLeaf: true,
                  },
                  {
                    value: "scrapers",
                    label: "Scrapers",
                    isLeaf: true,
                  },
                  {
                    value: "spoon rests",
                    label: "Spoon Rests",
                    isLeaf: true,
                  },
                  {
                    value: "stove burner covers",
                    label: "Stove Burner Covers",
                    isLeaf: true,
                  },
                  {
                    value: "sushi mats & bento accessories",
                    label: "Sushi Mats Bento Accessories",
                    isLeaf: true,
                  },
                  {
                    value: "timers",
                    label: "Timers",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "bakeware",
                label: "Bakeware",
                children: [
                  {
                    value: "bakeware sets",
                    label: "Bakeware Sets",
                    isLeaf: true,
                  },
                  {
                    value: "baking & cookie sheets",
                    label: "Baking Cookie Sheets",
                    isLeaf: true,
                  },
                  {
                    value: "bread pans & molds",
                    label: "Bread Pans Molds",
                    isLeaf: true,
                  },
                  {
                    value: "cake pans & molds",
                    label: "Cake Pans Molds",
                    isLeaf: true,
                  },
                  {
                    value: "cooling racks",
                    label: "Cooling Racks",
                    isLeaf: true,
                  },
                  {
                    value: "muffin & pastry pans",
                    label: "Muffin Pastry Pans",
                    isLeaf: true,
                  },
                  {
                    value: "pie & quiche pans",
                    label: "Pie Quiche Pans",
                    isLeaf: true,
                  },
                  {
                    value: "pizza pans & stones",
                    label: "Pizza Pans Stones",
                    isLeaf: true,
                  },
                  {
                    value: "ramekins & soufflé dishes",
                    label: "Ramekins Souffle Dishes",
                    isLeaf: true,
                  },
                  {
                    value: "roasting pans",
                    label: "Roasting Pans",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "drinkware",
                label: "Drinkware",
                children: [
                  {
                    value: "tumblers",
                    label: "Tumblers",
                    isLeaf: true,
                  },
                  {
                    value: "stemware & liquor glasses",
                    label: "Stemware Liquor Glasses",
                    children: [
                      {
                        value: "beer glasses",
                        label: "Beer Glasses",
                        isLeaf: true,
                      },
                    ],
                  },
                  {
                    value: "drinkware sets",
                    label: "Drinkware Sets",
                    isLeaf: true,
                  },
                  {
                    value: "stemware & liquor glasses",
                    label: "Stemware Liquor Glasses",
                    children: [
                      {
                        value: "cocktail & liquor glasses",
                        label: "Cocktail Liquor Glasses",
                        isLeaf: true,
                      },
                      {
                        value: "wine & champagne glasses",
                        label: "Wine Champagne Glasses",
                        isLeaf: true,
                      },
                    ],
                  },
                  {
                    value: "mugs",
                    label: "Mugs",
                    isLeaf: true,
                  },
                  {
                    value: "coffee & tea",
                    label: "Coffee Tea",
                    children: [
                      {
                        value: "coffee & tea cups",
                        label: "Coffee Tea Cups",
                        isLeaf: true,
                      },
                      {
                        value: "coffee & tea sets",
                        label: "Coffee Tea Sets",
                        isLeaf: true,
                      },
                      {
                        value: "teapots",
                        label: "Teapots",
                        isLeaf: true,
                      },
                    ],
                  },
                ],
              },
              {
                value: "cookware",
                label: "Cookware",
                children: [
                  {
                    value: "casserole dishes",
                    label: "Casserole Dishes",
                    isLeaf: true,
                  },
                  {
                    value: "cookware handles, lids & accessories",
                    label: "Cookware Handles Lids Accessories",
                    isLeaf: true,
                  },
                  {
                    value: "cookware sets",
                    label: "Cookware Sets",
                    isLeaf: true,
                  },
                  {
                    value: "crepe & blini pans",
                    label: "Crepe Blini Pans",
                    isLeaf: true,
                  },
                  {
                    value: "double boilers",
                    label: "Double Boilers",
                    isLeaf: true,
                  },
                  {
                    value: "dutch ovens",
                    label: "Dutch Ovens",
                    isLeaf: true,
                  },
                  {
                    value: "fermentation & pickling crocks",
                    label: "Fermentation Pickling Crocks",
                    isLeaf: true,
                  },
                  {
                    value: "griddles & grill pans",
                    label: "Griddles Grill Pans",
                    isLeaf: true,
                  },
                  {
                    value: "grill presses",
                    label: "Grill Presses",
                    isLeaf: true,
                  },
                  {
                    value: "paella pans",
                    label: "Paella Pans",
                    isLeaf: true,
                  },
                  {
                    value: "saucepans",
                    label: "Saucepans",
                    isLeaf: true,
                  },
                  {
                    value: "skillets & frying pans",
                    label: "Skillets Frying Pans",
                    isLeaf: true,
                  },
                  {
                    value: "stock pots",
                    label: "Stock Pots",
                    isLeaf: true,
                  },
                  {
                    value: "tagines & clay cooking pots",
                    label: "Tagines Clay Cooking Pots",
                    isLeaf: true,
                  },
                  {
                    value: "woks",
                    label: "Woks",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "barware",
                label: "Barware",
                children: [
                  {
                    value: "bar ice picks",
                    label: "Bar Ice Picks",
                    isLeaf: true,
                  },
                  {
                    value: "bottle caps",
                    label: "Bottle Caps",
                    isLeaf: true,
                  },
                  {
                    value: "bottle stoppers & corks",
                    label: "Bottle Stoppers Corks",
                    isLeaf: true,
                  },
                  {
                    value: "coaster holders",
                    label: "Coaster Holders",
                    isLeaf: true,
                  },
                  {
                    value: "coasters",
                    label: "Coasters",
                    isLeaf: true,
                  },
                  {
                    value: "cocktail & barware tool sets",
                    label: "Cocktail Barware Tool Sets",
                    isLeaf: true,
                  },
                  {
                    value: "cocktail shakers",
                    label: "Cocktail Shakers",
                    isLeaf: true,
                  },
                  {
                    value: "cocktail strainers",
                    label: "Cocktail Strainers",
                    isLeaf: true,
                  },
                  {
                    value: "corkscrews & bottle openers",
                    label: "Corkscrews Bottle Openers",
                    isLeaf: true,
                  },
                  {
                    value: "decanters",
                    label: "Decanters",
                    isLeaf: true,
                  },
                  {
                    value: "ice buckets & wine coolers",
                    label: "Ice Buckets Wine Coolers",
                    isLeaf: true,
                  },
                  {
                    value: "ice cube trays & molds",
                    label: "Ice Cube Trays Molds",
                    isLeaf: true,
                  },
                  {
                    value: "liquor & beer dispensers",
                    label: "Liquor Beer Dispensers",
                    isLeaf: true,
                  },
                  {
                    value: "muddlers",
                    label: "Muddlers",
                    isLeaf: true,
                  },
                  {
                    value: "wine aerators",
                    label: "Wine Aerators",
                    isLeaf: true,
                  },
                  {
                    value: "wine fridges & cellars",
                    label: "Wine Fridges Cellars",
                    isLeaf: true,
                  },
                  {
                    value: "wine glass charms",
                    label: "Wine Glass Charms",
                    isLeaf: true,
                  },
                  {
                    value: "wine glass holders",
                    label: "Wine Glass Holders",
                    isLeaf: true,
                  },
                  {
                    value: "wine racks & bottle holders",
                    label: "Wine Racks Bottle Holders",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "kitchen organization",
                label: "Kitchen Organization",
                children: [
                  {
                    value: "pot racks",
                    label: "Pot Racks",
                    isLeaf: true,
                  },
                  {
                    value: "paper towel holders",
                    label: "Paper Towel Holders",
                    isLeaf: true,
                  },
                  {
                    value: "flatware storage",
                    label: "Flatware Storage",
                    isLeaf: true,
                  },
                  {
                    value: "drinkware holders",
                    label: "Drinkware Holders",
                    isLeaf: true,
                  },
                  {
                    value: "drawer organizer inserts",
                    label: "Drawer Organizer Inserts",
                    isLeaf: true,
                  },
                  {
                    value: "counter organizers",
                    label: "Counter Organizers",
                    isLeaf: true,
                  },
                  {
                    value: "cabinet organizers",
                    label: "Cabinet Organizers",
                    isLeaf: true,
                  },
                  {
                    value: "can organizers",
                    label: "Can Organizers",
                    isLeaf: true,
                  },
                  {
                    value: "sink caddies",
                    label: "Sink Caddies",
                    isLeaf: true,
                  },
                  {
                    value: "sink mats & grids",
                    label: "Sink Mats Grids",
                    isLeaf: true,
                  },
                  {
                    value: "spice organizers",
                    label: "Spice Organizers",
                    isLeaf: true,
                  },
                  {
                    value: "straw holders & dispensers",
                    label: "Straw Holders Dispensers",
                    isLeaf: true,
                  },
                  {
                    value: "sugar caddies",
                    label: "Sugar Caddies",
                    isLeaf: true,
                  },
                  {
                    value: "toothpick holders & dispensers",
                    label: "Toothpick Holders Dispensers",
                    isLeaf: true,
                  },
                ],
              },
            ],
          },
          {
            value: "bath",
            label: "Bath",
            children: [
              {
                value: "bath storage",
                label: "Bath Storage",
                children: [
                  {
                    value: "bath caddies",
                    label: "Bath Caddies",
                    isLeaf: true,
                  },
                  {
                    value: "medicine cabinets",
                    label: "Medicine Cabinets",
                    isLeaf: true,
                  },
                  {
                    value: "robe & towel hooks",
                    label: "Robe Towel Hooks",
                    isLeaf: true,
                  },
                  {
                    value: "toilet paper holders",
                    label: "Toilet Paper Holders",
                    isLeaf: true,
                  },
                  {
                    value: "toothbrush holders",
                    label: "Toothbrush Holders",
                    isLeaf: true,
                  },
                  {
                    value: "towel racks & holders",
                    label: "Towel Racks Holders",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "towels & bath linens",
                label: "Towels Bath Linens",
                isLeaf: true,
              },
              {
                value: "bath accessories",
                label: "Bath Accessories",
                children: [
                  {
                    value: "shower heads",
                    label: "Shower Heads",
                    isLeaf: true,
                  },
                  {
                    value: "shower curtain hooks & rods",
                    label: "Shower Curtain Hooks Rods",
                    isLeaf: true,
                  },
                  {
                    value: "scales",
                    label: "Scales",
                    isLeaf: true,
                  },
                  {
                    value: "plungers",
                    label: "Plungers",
                    isLeaf: true,
                  },
                  {
                    value: "mirrors",
                    label: "Mirrors",
                    isLeaf: true,
                  },
                  {
                    value: "soap dishes & dispensers",
                    label: "Soap Dishes Dispensers",
                    isLeaf: true,
                  },
                  {
                    value: "tissue box covers",
                    label: "Tissue Box Covers",
                    isLeaf: true,
                  },
                  {
                    value: "toilet brushes",
                    label: "Toilet Brushes",
                    isLeaf: true,
                  },
                  {
                    value: "toilet covers",
                    label: "Toilet Covers",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "bath linens",
                label: "Bath Linens",
                children: [
                  {
                    value: "bath mats & rugs",
                    label: "Bath Mats Rugs",
                    isLeaf: true,
                  },
                  {
                    value: "bath pillows",
                    label: "Bath Pillows",
                    isLeaf: true,
                  },
                  {
                    value: "towels & washcloths",
                    label: "Towels Washcloths",
                    isLeaf: true,
                  },
                ],
              },
            ],
          },
          {
            value: "home decor",
            label: "Home Decor",
            children: [
              {
                value: "holiday & seasonal decor",
                label: "Holiday Seasonal Decor",
                children: [
                  {
                    value: "advent calendars",
                    label: "Advent Calendars",
                    isLeaf: true,
                  },
                  {
                    value: "christmas lights",
                    label: "Christmas Lights",
                    isLeaf: true,
                  },
                  {
                    value: "christmas trees",
                    label: "Christmas Trees",
                    isLeaf: true,
                  },
                  {
                    value: "easter egg decorating kits",
                    label: "Easter Egg Decorating Kits",
                    isLeaf: true,
                  },
                  {
                    value: "holiday ornament hooks & stands",
                    label: "Holiday Ornament Hooks Stands",
                    isLeaf: true,
                  },
                  {
                    value: "holiday ornaments",
                    label: "Holiday Ornaments",
                    isLeaf: true,
                  },
                  {
                    value: "nativity sets",
                    label: "Nativity Sets",
                    isLeaf: true,
                  },
                  {
                    value: "seasonal village sets",
                    label: "Seasonal Village Sets",
                    isLeaf: true,
                  },
                  {
                    value: "snow globes",
                    label: "Snow Globes",
                    isLeaf: true,
                  },
                  {
                    value: "stockings & hangers",
                    label: "Stockings Hangers",
                    isLeaf: true,
                  },
                  {
                    value: "table decor",
                    label: "Table Decor",
                    isLeaf: true,
                  },
                  {
                    value: "tree stands & skirts",
                    label: "Tree Stands Skirts",
                    isLeaf: true,
                  },
                  {
                    value: "wall decor",
                    label: "Wall Decor",
                    isLeaf: true,
                  },
                  {
                    value: "window decorations",
                    label: "Window Decorations",
                    isLeaf: true,
                  },
                  {
                    value: "wreaths, garlands & plants",
                    label: "Wreaths Garlands Plants",
                    isLeaf: true,
                  },
                  {
                    value: "yard decor",
                    label: "Yard Decor",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "rugs & carpets",
                label: "Rugs Carpets",
                children: [
                  {
                    value: "area rugs",
                    label: "Area Rugs",
                    isLeaf: true,
                  },
                  {
                    value: "carpet tiles",
                    label: "Carpet Tiles",
                    isLeaf: true,
                  },
                  {
                    value: "floor mats",
                    label: "Floor Mats",
                    isLeaf: true,
                  },
                  {
                    value: "runners",
                    label: "Runners",
                    isLeaf: true,
                  },
                  {
                    value: "stair treads",
                    label: "Stair Treads",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "window treatments & hardware",
                label: "Window Treatments Hardware",
                children: [
                  {
                    value: "curtain & drape rings",
                    label: "Curtain Drape Rings",
                    isLeaf: true,
                  },
                  {
                    value: "curtain & drape rods",
                    label: "Curtain Drape Rods",
                    isLeaf: true,
                  },
                  {
                    value: "curtain holdbacks & tassels",
                    label: "Curtain Holdbacks Tassels",
                    isLeaf: true,
                  },
                  {
                    value: "curtains & drapes",
                    label: "Curtains Drapes",
                    isLeaf: true,
                  },
                  {
                    value: "shutters",
                    label: "Shutters",
                    isLeaf: true,
                  },
                  {
                    value: "stained glass panels",
                    label: "Stained Glass Panels",
                    isLeaf: true,
                  },
                  {
                    value: "window blinds & shades",
                    label: "Window Blinds Shades",
                    isLeaf: true,
                  },
                  {
                    value: "window films",
                    label: "Window Films",
                    isLeaf: true,
                  },
                  {
                    value: "window magnets",
                    label: "Window Magnets",
                    isLeaf: true,
                  },
                  {
                    value: "window screens",
                    label: "Window Screens",
                    isLeaf: true,
                  },
                  {
                    value: "window treatment replacement parts",
                    label: "Window Treatment Replacement Parts",
                    isLeaf: true,
                  },
                  {
                    value: "window valances & cornices",
                    label: "Window Valances Cornices",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "home fragrances",
                label: "Home Fragrances",
                children: [
                  {
                    value: "air fresheners",
                    label: "Air Fresheners",
                    isLeaf: true,
                  },
                  {
                    value: "candle & oil warmers",
                    label: "Candle Oil Warmers",
                    isLeaf: true,
                  },
                  {
                    value: "candle holders",
                    label: "Candle Holders",
                    isLeaf: true,
                  },
                  {
                    value: "candle snuffers",
                    label: "Candle Snuffers",
                    isLeaf: true,
                  },
                  {
                    value: "candles",
                    label: "Candles",
                    isLeaf: true,
                  },
                  {
                    value: "diffusers & fragrance oil",
                    label: "Diffusers Fragrance Oil",
                    isLeaf: true,
                  },
                  {
                    value: "incense",
                    label: "Incense",
                    isLeaf: true,
                  },
                  {
                    value: "incense holders",
                    label: "Incense Holders",
                    isLeaf: true,
                  },
                  {
                    value: "potpourri",
                    label: "Potpourri",
                    isLeaf: true,
                  },
                  {
                    value: "wax tarts",
                    label: "Wax Tarts",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "clocks",
                label: "Clocks",
                children: [
                  {
                    value: "alarm clocks",
                    label: "Alarm Clocks",
                    isLeaf: true,
                  },
                  {
                    value: "alarm clocks",
                    label: "Alarm Clocks",
                    isLeaf: true,
                  },
                  {
                    value: "clock parts",
                    label: "Clock Parts",
                    isLeaf: true,
                  },
                  {
                    value: "clocks",
                    label: "Clocks",
                    isLeaf: true,
                  },
                  {
                    value: "desk, mantel & shelf clocks",
                    label: "Desk Mantel Shelf Clocks",
                    isLeaf: true,
                  },
                  {
                    value: "grandfather clocks",
                    label: "Grandfather Clocks",
                    isLeaf: true,
                  },
                  {
                    value: "wall clocks",
                    label: "Wall Clocks",
                    isLeaf: true,
                  },
                ],
              },
              {
                value: "decorative accents",
                label: "Decorative Accents",
                children: [
                  {
                    value: "artificial food",
                    label: "Artificial Food",
                    isLeaf: true,
                  },
                  {
                    value: "artificial plants & flowers",
                    label: "Artificial Plants Flowers",
                    isLeaf: true,
                  },
                  {
                    value: "baskets",
                    label: "Baskets",
                    isLeaf: true,
                  },
                  {
                    value: "bells",
                    label: "Bells",
                    isLeaf: true,
                  },
                  {
                    value: "bookends",
                    label: "Bookends",
                    isLeaf: true,
                  },
                  {
                    value: "bottles",
                    label: "Bottles",
                    isLeaf: true,
                  },
                  {
                    value: "boxes, jars & tins",
                    label: "Boxes Jars Tins",
                    isLeaf: true,
                  },
                  {
                    value: "decals, stickers & vinyl art",
                    label: "Decals Stickers Vinyl Art",
                    isLeaf: true,
                  },
                  {
                    value: "decorative plates, bowls & trays",
                    label: "Decorative Plates Bowls Trays",
                    isLeaf: true,
                  },
                  {
                    value: "door decor",
                    label: "Door Decor",
                    isLeaf: true,
                  },
                  {
                    value: "door mats",
                    label: "Door Mats",
                    isLeaf: true,
                  },
                  {
                    value: "doorstops",
                    label: "Doorstops",
                    isLeaf: true,
                  },
                  {
                    value: "dreamcatchers",
                    label: "Dreamcatchers",
                    isLeaf: true,
                  },
                  {
                    value: "dried flowers",
                    label: "Dried Flowers",
                    isLeaf: true,
                  },
                  {
                    value: "figurines",
                    label: "Figurines",
                    isLeaf: true,
                  },
                  {
                    value: "finials",
                    label: "Finials",
                    isLeaf: true,
                  },
                  {
                    value: "frames",
                    label: "Frames",
                    isLeaf: true,
                  },
                  {
                    value: "hourglasses",
                    label: "Hourglasses",
                    isLeaf: true,
                  },
                  {
                    value: "house numbers & letters",
                    label: "House Numbers Letters",
                    isLeaf: true,
                  },
                  {
                    value: "indoor fountains",
                    label: "Indoor Fountains",
                    isLeaf: true,
                  },
                  {
                    value: "key & letter holders",
                    label: "Key Letter Holders",
                    isLeaf: true,
                  },
                  {
                    value: "masks",
                    label: "Masks",
                    isLeaf: true,
                  },
                  {
                    value: "message boards & holders",
                    label: "Message Boards Holders",
                    isLeaf: true,
                  },
                  {
                    value: "mirrors",
                    label: "Mirrors",
                    isLeaf: true,
                  },
                  {
                    value: "music boxes",
                    label: "Music Boxes",
                    isLeaf: true,
                  },
                  {
                    value: "piggy banks & money jars",
                    label: "Piggy Banks Money Jars",
                    isLeaf: true,
                  },
                  {
                    value: "plaques & signs",
                    label: "Plaques Signs",
                    isLeaf: true,
                  },
                  {
                    value: "plate racks & hangers",
                    label: "Plate Racks Hangers",
                    isLeaf: true,
                  },
                  {
                    value: "posters, prints & paintings",
                    label: "Posters Prints Paintings",
                    isLeaf: true,
                  },
                  {
                    value: "refrigerator magnets",
                    label: "Refrigerator Magnets",
                    isLeaf: true,
                  },
                  {
                    value: "sculptures",
                    label: "Sculptures",
                    isLeaf: true,
                  },
                  {
                    value: "suncatchers & mobiles",
                    label: "Suncatchers Mobiles",
                    isLeaf: true,
                  },
                  {
                    value: "tapestries",
                    label: "Tapestries",
                    isLeaf: true,
                  },
                  {
                    value: "throw pillows",
                    label: "Throw Pillows",
                    isLeaf: true,
                  },
                  {
                    value: "tile art",
                    label: "Tile Art",
                    isLeaf: true,
                  },
                  {
                    value: "vase fillers",
                    label: "Vase Fillers",
                    isLeaf: true,
                  },
                  {
                    value: "vases",
                    label: "Vases",
                    isLeaf: true,
                  },
                  {
                    value: "wall pockets",
                    label: "Wall Pockets",
                    isLeaf: true,
                  },
                  {
                    value: "world globes",
                    label: "World Globes",
                    isLeaf: true,
                  },
                  {
                    value: "wreaths & garlands",
                    label: "Wreaths Garlands",
                    isLeaf: true,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },

  {
    value: "others",
    label: "Others",
    isLeaf: true,
  },
];
