import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
} from "@angular/core";

@Directive({
  selector: "[appScrollDetect]",
})
export class ScrollDetectDirective {
  @Output() customScrolled = new EventEmitter();
  constructor(private el: ElementRef) {}
  value: any;

  @HostListener("scroll", ["$event"]) public onScroll(e: any) {
    // console.log(this.el.nativeElement.clientHeight);
    if (
      this.el.nativeElement.scrollHeight -
        this.el.nativeElement.scrollTop -
        100 <=
      this.el.nativeElement.clientHeight
    ) {
      this.value = "at bottom";
    } else {
      this.value = "at top";
    }
    this.customScrolled.emit(this.value);
  }
}
