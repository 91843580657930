import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { CommonApiService } from "src/app/services/common-api.service";
import { NavService } from "src/app/services/nav.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-whatsapp",
  templateUrl: "./whatsapp.component.html",
  styleUrls: ["./whatsapp.component.css"],
})
export class WhatsappComponent implements OnInit {
  selectedTab = "profile";

  profilePreview: any = "../../../../assets/images/logo/logo.png";
  businessProfile: any = "../../../../assets/images/logo/logo.png";
  // "https://www.streetmusclemag.com/image/2015/08/2015-08-26_23-52-59-768x505.jpg";

  verticalValues: any = [
    { value: "AUTO", name: "Automotive" },
    { value: "BEAUTY", name: "Beauty, spa and salon" },
    { value: "APPAREL", name: "Clothing" },
    { value: "EDU", name: "Education" },
    { value: "ENTERTAIN", name: "Entertainment" },
    { value: "EVENT_PLAN", name: "Event planning and service" },
    { value: "FINANCE", name: "Finance and banking" },
    { value: "GROCERY", name: "Food and groceries" },
    { value: "HOTEL", name: "Hotel and lodging" },
    { value: "HEALTH", name: "Medical and health" },
    { value: "PROF_SERVICES", name: "Profession services" },
    { value: "RETAIL", name: "Shopping and retail" },
    { value: "TRAVEL", name: "Travel and transportation" },
    { value: "RESTAURANT", name: "Restaurant" },
    { value: "GOVT", name: "Government" },
    { value: "NONPROFIT", name: "Non-profit organization" },
    { value: "NOT_A_BIZ", name: "NOT_A_BIZ" },
    { value: "OTHER", name: "Other" },
    // { value: "UNDEFINED", name: "" },
  ];

  orderColumn: any = [
    {
      title: "Field",
      width: "60px",
    },
    {
      title: "Mobile Number",
      width: "80px",
    },
    {
      title: "EMail Address",
      width: "100px",
    },
  ];

  membersData: any = [
    {
      name: "Balaji",
      phoneNo: "+91 6926522010",
      email: "Balaji@gmail.com",
    },
    {
      name: "Balaji",
      phoneNo: "+91 6926522010",
      email: "Balaji@gmail.com",
    },
  ];

  addMemberForm!: FormGroup;

  // user data
  userData: any = {};
  requestArr: any = [];

  defaultData: any;

  // form data
  websitePrimary: any = "";
  websiteSecondary: any = "";
  email: any = "";
  description: any = "";
  address: any = "";
  aboutText: any = "";
  vertical: any = "";

  descriptionPreview: any = [];
  loaderValue: any = "";

  targetIndex: any;

  mainUserData: any = {};

  profileUpdating: boolean = false;

  tableHeight: any = "";

  private subscription: Subscription;

  constructor(
    private fb: FormBuilder,
    private apiService: CommonApiService,
    private navService: NavService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.spinner.show();
    this.getProfileData(null);

    this.subscription = this.navService.mainUserData.subscribe(
      (response: any) => {
        if (response) {
          // console.log(response);
          this.mainUserData = response.data.userData;
          if (
            response?.data?.userData?.accessTo?.sandboxId &&
            window.innerWidth < 992
          ) {
            this.tableHeight = "45vh";
          } else {
            this.tableHeight = "50vh";
          }
        }
      }
    );

    // console.log(this.description);
    this.addMemberForm = this.fb.group({
      name: ["", Validators.required],
      phoneNo: ["", Validators.required],
      countryCode: ["+91", Validators.required],
    });

    this.getUserData();
  }

  getProfileData(type: any) {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonGetMethod(environment.url + "waba/get-profile", options)
      .subscribe(
        (response: any) => {
          // console.log(response);
          this.defaultData = response?.data?.profile;
          this.targetIndex = this?.verticalValues?.findIndex(
            (e: any) => e?.value === this.defaultData?.vertical
          );
          this.aboutText = this.defaultData.about;
          this.address = this.defaultData.address;
          this.description = this.defaultData.description;
          this.email = this.defaultData.email;
          this.websitePrimary = this?.defaultData?.websites?.[0];
          this.websiteSecondary = this?.defaultData?.websites?.[1];
          this.vertical = this.defaultData?.vertical;
          this.descriptionPreview =
            this?.defaultData?.description?.match(/.{1,35}/g); // split string by length
          this.descriptionPreview = this?.descriptionPreview?.splice(0, 3); // remove the all items without first 3 items
          // console.log(this.descriptionPreview);
          if (type) {
            this.profileUpdating = false;
            this.loaderValue = "";
            this.removeRequest(type);
          }
          this.spinner.hide();
        },
        (error: any) => {
          this.spinner.hide();
          console.log(error);
        }
      );
  }

  getUserData() {
    this.navService.getMainUserData();
    this.subscription = this.navService.mainUserData.subscribe(
      (response: any) => {
        if (response) {
          // console.log(response);
          this.userData = response.data.userData;
        }
      }
    );
  }

  changeTab(selected: string) {
    this.selectedTab = selected;
  }

  addRequest(selected: any) {
    this.targetIndex = this?.verticalValues?.findIndex(
      (e: any) => e?.value === this.defaultData?.vertical
    );
    this.aboutText = this.defaultData.about;
    this.address = this.defaultData.address;
    this.description = this.defaultData.description;
    this.email = this.defaultData.email;
    this.websitePrimary = this?.defaultData?.websites?.[0];
    this.websiteSecondary = this?.defaultData?.websites?.[1];
    this.vertical = this.defaultData?.vertical;
    if (this.requestArr.indexOf(selected) == -1) {
      this.requestArr.push(selected);
    }
  }

  removeRequest(selected: any) {
    this.loaderValue = "";
    if (this.requestArr.indexOf(selected) > -1) {
      this.requestArr.splice(this.requestArr.indexOf(selected), 1);
    }
  }

  getProfile(event: any) {
    // console.log(event.target.files[0]);
    this.profileUpdating = true;
    var formData = new FormData();
    formData.append("logo", event.target.files?.[0]);
    this.updateData(formData, "profile");

    // const file = event.target.files[0];
    // const reader = new FileReader();
    // reader.onload = (e) => (this.businessProfile = reader.result);
    // reader.readAsDataURL(file);
  }

  checkValue(type: string) {
    var formData = new FormData();
    this.loaderValue = type;
    if (type == "about") {
      formData.append("about", this.aboutText);
    }

    if (type == "businessProfile") {
      formData.append("address", this.address);
    }

    if (type == "description") {
      formData.append("description", this.description);
    }

    if (type == "email") {
      formData.append("businessEmail", this.email);
    }

    if (type == "webPrimary" || type == "webSecondary") {
      formData.append("website", this.websitePrimary);
      formData.append("secondaryWebsite", this.websiteSecondary);
    }

    if (type == "vertical") {
      formData.append("vertical", this.vertical);
    }

    this.updateData(formData, type);
  }

  updateData(data: any, type: any) {
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    this.apiService
      .commonPostMethod(environment.url + "waba/update-profile", data, options)
      .subscribe(
        (response: any) => {
          console.log(response);
          this.getProfileData(type);
          this.toastr.success(response.message, "", {
            timeOut: 2500,
          });
        },
        (error) => {
          this.profileUpdating = false;
          if (error?.error?.message) {
            this.toastr.error(error?.error?.message, "", {
              timeOut: 2500,
            });
          } else {
            this.toastr.error(error?.message, "", {
              timeOut: 2500,
            });
          }
          this.loaderValue = "";
        }
      );
  }

  ngOnDestroy(): void {
    // console.log("api key destroy..");
    this.subscription.unsubscribe();
  }

  // valueChange(value: string) {
  //   if (value == "about") {
  //     if (this.aboutText.length >= 139) {
  //       this.aboutText = this.aboutText.slice(0, 139);
  //     }
  //   }
  // }
}
