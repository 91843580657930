import { Component, Input, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { CommonApiService } from "src/app/services/common-api.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-commerce-messages",
  templateUrl: "./commerce-messages.component.html",
  styleUrls: ["./commerce-messages.component.css"],
})
export class CommerceMessagesComponent implements OnInit {
  @Input() allTemplate: any = [];
  // [
  //   {
  //     category: "UTILITY",
  //     components: [
  //       {
  //         format: "TEXT",
  //         text: "Order Status Update",
  //         type: "HEADER",
  //       },
  //       {
  //         example: ["Dinesh", "2424323", "shipped", "ownchat"],
  //         text: "Hi {{1}}. Your order {{2}} has been {{3}}. If you have any issues feel free to contact us.\nTeam {{4}}.",
  //         type: "BODY",
  //       },
  //       {
  //         text: "Thanks",
  //         type: "FOOTER",
  //       },
  //     ],
  //     language: "en_US",
  //     name: "order_status_update",
  //   },
  // ];

  showPreview: boolean = false;
  submitting: boolean = false;

  requestedTemplate: any;

  constructor(
    private apiService: CommonApiService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    if (this.allTemplate) {
      console.log(this.allTemplate);
    }
  }

  ngOnChanges() {
    // if (this.allTemplate) {
    //   console.log(this.allTemplate);
    // }
  }

  requestApproval() {
    // console.log(this.requestedTemplate);
    this.submitting = true;
    var url = "templates/create";
    // var url = "test";

    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("qwertrewetrt"),
      },
    };

    var formData = new FormData();
    formData.append("category", this.requestedTemplate.category);
    formData.append("language", this.requestedTemplate.language);
    formData.append("name", this.requestedTemplate.name);

    for (let j = 0; j < this.requestedTemplate?.components?.length; j++) {
      if (
        this.requestedTemplate?.components[j]?.type == "BODY" ||
        this.requestedTemplate?.components[j]?.type == "FOOTER"
      ) {
        formData.append(
          "components[" + j + "][text]",
          this.requestedTemplate?.components[j]?.text
        );
        formData.append(
          "components[" + j + "][type]",
          this.requestedTemplate?.components[j]?.type
        );
        if (
          this.requestedTemplate?.components[j]?.type == "BODY" &&
          this.requestedTemplate?.components[j]?.example?.body_text?.length > 0
        ) {
          var temp =
            this.requestedTemplate?.components[j]?.example?.body_text[0];
          // console.log(temp);
          for (let l = 0; l < temp?.length; l++) {
            formData.append(
              "components[" + j + "][example][body_text][0][" + l + "]",
              temp[l]
            );
            // console.log(temp);
          }
        }
      } else if (this.requestedTemplate?.components[j]?.type == "BUTTONS") {
        formData.append("components[" + j + "][type]", "BUTTONS");
        for (
          let s = 0;
          s < this.requestedTemplate?.components[j]?.buttons?.length;
          s++
        ) {
          formData.append(
            "components[" + j + "][buttons][" + s + "][text]",
            this.requestedTemplate.components[j].buttons[s].text
          );
          formData.append(
            "components[" + j + "][buttons][" + s + "][type]",
            this.requestedTemplate.components[j].buttons[s].type
          );
          if (
            this.requestedTemplate.components[j].buttons[s].type ==
            "PHONE_NUMBER"
          ) {
            formData.append(
              "components[" + j + "][buttons][" + s + "][phone_number]",
              this.requestedTemplate.components[j].buttons[s].phone_number
            );
          } else if (
            this.requestedTemplate.components[j].buttons[s].type == "URL"
          ) {
            formData.append(
              "components[" + j + "][buttons][" + s + "][url]",
              this.requestedTemplate.components[j].buttons[s].url
            );
            if (
              this.requestedTemplate?.components[j]?.buttons[s]?.example
                ?.length > 0
            ) {
              var temp1 =
                this.requestedTemplate?.components[j]?.buttons[s]?.example;
              for (let b = 0; b < temp1.length; b++) {
                formData.append(
                  "components[" + j + "][buttons][" + s + "][example][0]",
                  temp1[b]
                );
              }
            }
          }
        }
      } else if (this.requestedTemplate?.components[j]?.type == "HEADER") {
        formData.append(
          "components[" + j + "][format]",
          this.requestedTemplate?.components[j]?.format
        );
        formData.append(
          "components[" + j + "][type]",
          this.requestedTemplate?.components[j]?.type
        );
        if (this.requestedTemplate?.components[j]?.format == "TEXT") {
          formData.append(
            "components[" + j + "][text]",
            this.requestedTemplate?.components[j]?.text
          );
          if (
            this.requestedTemplate?.components[j]?.example?.header_handle
              ?.length > 0
          ) {
            formData.append(
              "components[" + j + "][example][header_text][0]",
              this.requestedTemplate?.components[j]?.example?.header_handle[0]
            );
          }
        }
      }
    }

    this.apiService
      .commonPostMethod(environment.url + url, formData, options)
      .subscribe(
        (response: any) => {
          // console.log(response);
          this.submitting = false;
          this.showPreview = false;
          this.toastr.success(response.message, "", {
            timeOut: 2500,
          });
        },
        (err: any) => {
          console.log(err);
          this.submitting = false;

          var errorMessage = err?.error?.data?.error?.meta?.developer_message;
          if (errorMessage) {
            if (errorMessage.startsWith("Invalid payload")) {
              // Extract the values of "error_user_title" and "error_user_msg" using regular expressions
              const errorUserTitleMatch = errorMessage.match(
                /"error_user_title":"(.*?)"/
              );
              const errorUserMsgMatch = errorMessage.match(
                /"error_user_msg":"(.*?)"/
              );

              if (errorUserTitleMatch && errorUserMsgMatch) {
                const errorUserTitle = errorUserTitleMatch[1];
                const errorUserMsg = errorUserMsgMatch[1];

                this.toastr.error(errorUserMsg, errorUserTitle, {
                  timeOut: 50000,
                  progressBar: true,
                  progressAnimation: "decreasing",
                  enableHtml: true,
                });
              } else {
                this.toastr.error(
                  err?.error?.data?.error?.meta?.developer_message,
                  "",
                  {
                    timeOut: 10000,
                    progressBar: true,
                    progressAnimation: "decreasing",
                  }
                );
              }
            } else {
              this.toastr.error(
                err?.error?.data?.error?.meta?.developer_message,
                "",
                {
                  timeOut: 10000,
                  progressBar: true,
                  progressAnimation: "decreasing",
                }
              );
            }
          } else {
            this.toastr.error(err?.error.data.error, "", {
              timeOut: 10000,
            });
          }
        }
      );
  }

  showTemplatePreview(template: any) {
    // if (template.status == "rejected" || !template.status) {
    this.requestedTemplate = template;
    this.showPreview = true;
    // }
  }

  closeTemplatePreview() {
    this.showPreview = false;
  }
}
