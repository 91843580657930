<!-- new login design -->



<div class="form-body">
  <div class="row">
    <div class="col-lg-6 login_slider">
      <nz-carousel [nzEffect]="'scrollx'" nzAutoPlay>
        <div nz-carousel-content *ngFor="let image of loginBanners" class="slider_main_section">
          <div class="slider_inner_section">
            <div [ngStyle]="{ 'background-image': 'url(' + image + ')' }" class="carousal_container"></div>
            <!-- <img [src]="image" alt=""> -->
          </div>
        </div>
      </nz-carousel>
    </div>

    <div class="form-holder col-lg-6">
      <div class="form-content">
        <div class="form-items">
          <img class="mb-3 own_chat_logo" src="../../../assets/images/logo/ownChat_logo.png" alt="" />
          <h4 class="mb-3">Login <span class="login_span">to Ownchat</span></h4>
          <form [formGroup]="validateForm" nz-form nzLayout="vertical" class="login-form" (ngSubmit)="submitForm()">
            <nz-form-item>
              <nz-form-label nzFor="" [nzSm]="24" [nzXs]="24">
                Email
              </nz-form-label>
              <nz-form-control nzErrorTip="Please Enter your Mail Id!">
                <nz-input-group [nzPrefix]="prefixUser">
                  <input (keyup)="formatMailId()" autocomplete="off" id="mobile" formControlName="mobileNo" nz-input
                    placeholder="Enter Email" type="text" />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>

            <nz-form-item class="relative password-field">
              <nz-form-label nzFor="" [nzSm]="24" [nzXs]="24">
                Password
              </nz-form-label>
              <nz-form-control nzErrorTip="Please Enter your Password!">
                <nz-input-group [nzPrefix]="prefixLock" [nzSuffix]="suffixTemplate">
                  <input autocomplete="off" [type]="passwordVisible ? 'text' : 'password'" id="password"
                    formControlName="password" nz-input placeholder="Password" />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>

            <div class="text-right">
              <a class="forgot_password_text" (click)="showModal()">Forgot password?</a>
            </div>

            <div class="form-button">
              <button [nzLoading]="loginConfirm" nz-button type="submit" class="ibtn w-100">
                Login
              </button>
              <p class="login_link mt-3">
                New User ? <span routerLink="/signup">Register here</span>
              </p>
            </div>
          </form>
          <ng-template #prefixUser><i class="mobile_icon" nz-icon nzType="mail"></i></ng-template>
          <ng-template #prefixLock><i nz-icon nzType="lock"></i></ng-template>
          <ng-template #suffixTemplate>
            <i nz-icon [nzType]="passwordVisible ? 'eye' : 'eye-invisible'"
              (click)="passwordVisible = !passwordVisible"></i>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>



<!-- GET EMAIL ID MODAL STARTS -->

<nz-modal [(nzVisible)]="getMailModal" [nzFooter]="null" nzWidth="350px" nzTitle="Forgot Password" nzCentered
  (nzOnCancel)="closeModal()">
  <ng-container *nzModalContent>
    <form [formGroup]="forgotPasswordForm" nz-form nzLayout="vertical" class="" (ngSubmit)="validateEmail()">
      <nz-form-item>
        <nz-form-control nzErrorTip="Please Enter your Mail Id!">
          <nz-input-group [nzPrefix]="prefixUser">
            <input autocomplete="off" id="mailId" formControlName="email" nz-input placeholder="Enter Mail Id"
              type="mail" />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item class="send_otp_section">
        <nz-form-control>
          <div class="d-flex align-items-center justify-content-center">
            <button nzType="primary" nz-button class="login-form-button">
              Send OTP
            </button>
          </div>
        </nz-form-control>
      </nz-form-item>
    </form>
  </ng-container>
  <!-- <ng-template class="modal_footer" *nzModalFooter></ng-template> -->
</nz-modal>

<!-- GET EMAIL ID MODAL ENDS -->

<!-- GET OTP MODAL STARTS -->

<nz-modal [(nzVisible)]="getOTPModal" [nzFooter]="null" nzWidth="350px" nzTitle="Forgot Password" nzCentered
  (nzOnCancel)="closeOtpModal()">
  <ng-container *nzModalContent>
    <form *ngIf="!OTPverified" nz-form nzLayout="vertical" class="">
      <div class="text-center">
        <p>Enter OTP Received in your Email</p>
      </div>
      <div class="text-center mb-3">
        <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="config">
        </ng-otp-input>
      </div>
      <div class="resend_section text-center">
        <p>Don't receive the code?</p>
        <p class="resend_button">Resend It</p>
      </div>
    </form>

    <!-- GET NEW PASSWORD -->
    <form *ngIf="OTPverified" [formGroup]="confirmPasswordForm" nz-form nzLayout="vertical" class="login-form"
      (ngSubmit)="confirmPassword()">
      <nz-form-item>
        <nz-form-control nzErrorTip="Please Enter your New Password!">
          <nz-input-group [nzPrefix]="prefixLock">
            <input autocomplete="off" [type]="newPasswordVisible ? 'text' : 'password'" id="pass"
              formControlName="enteredPassword" nz-input placeholder="Enter your New Password " />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control nzErrorTip="Please Confirm Your New Password!">
          <nz-input-group [nzPrefix]="prefixLock">
            <input autocomplete="off" [type]="passwordVisible ? 'text' : 'password'" id="newPass"
              formControlName="confirmedPassword" nz-input placeholder="Confirm Your New Password " />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item class="text-center">
        <nz-input-group>
          <button nz-button nzType="primary" class="login-form-button">
            Reset Password
          </button>
        </nz-input-group>
      </nz-form-item>
    </form>

    <ng-template #suffixTemplate>
      <i nz-icon [nzType]="passwordVisible ? 'eye' : 'eye-invisible'" (click)="passwordVisible = !passwordVisible"></i>
    </ng-template>
    <ng-template #newTemplate>
      <i nz-icon [nzType]="newPasswordVisible ? 'eye' : 'eye-invisible'"
        (click)="newPasswordVisible = !newPasswordVisible"></i>
    </ng-template>
    <!-- GET NEW PASSWORD -->
  </ng-container>
  <!-- <ng-template class="modal_footer" *nzModalFooter></ng-template> -->
</nz-modal>