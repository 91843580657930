<div class="row overflow_row_mobile">
    <div class="col-12 main_outer_heading d_mobile_none">
        <h3>Contacts</h3>
    </div>

    <div [ngClass]="{ 'col-0 hide_details': !showFilter }" class="col-3 left_menu contacts_table_column">
        <nz-card class="customer_filter_height mt-2">
            <div class="left_filter_height">
                <div class="mb-3 filter_search_section">
                    <div class="">
                        <h6>Filter Contacts By</h6>
                        <div class="toggle_options mb-3">
                            <nz-input-group class="search_input_section" [nzPrefix]="suffixIconSearch">
                                <input [(ngModel)]="contactsFilter" autocomplete="off" type="text" nz-input
                                    placeholder="Search here.." />
                            </nz-input-group>
                            <ng-template #suffixIconSearch>
                                <span nz-icon nzType="search"></span>
                            </ng-template>
                        </div>
                    </div>
                </div>

                <!-- filter next to search -->
                <div *ngIf="appliedFiltersSample.length > 0" class="filter_checkbox">
                    <ng-container *ngFor="let filter of fieldsForFilter | filter : contactsFilter;
                     let a = index">
                        <ng-container *ngIf="filterApplied && appliedFiltersSample.indexOf(filter.name) > -1" class="">
                            <label [nzChecked]="appliedFilters.indexOf(filter.name) != -1" nz-checkbox
                                (nzCheckedChange)="onFilterChecked(filter, a, $event, 'top')">
                                {{ filter?.label}}</label>
                            <div *ngIf="appliedFilters.indexOf(filter.name) > -1" class="w-100">
                                <nz-form-item>
                                    <nz-form-control [nzSm]="24" [nzXs]="24">
                                        <nz-input-group>
                                            <nz-select nzShowSearch [(ngModel)]="filterReq[a].filterType"
                                                class="w-100 select_dropdown" nzPlaceHolder="Select Condition">
                                                <nz-option *ngFor="let filter of filterSuggestion[filter.dataType]"
                                                    [nzLabel]="filter" [nzValue]="filter">
                                                </nz-option>
                                            </nz-select>

                                            <p class="error_message"
                                                *ngIf="errorObj.filterType.indexOf(filter.name) > -1 && !filterReq[a].filterType">
                                                Please select filter type.
                                            </p>
                                        </nz-input-group>
                                    </nz-form-control>
                                </nz-form-item>

                                <nz-form-item
                                    *ngIf="filterReq[a].filterType != 'is empty' && filterReq[a].filterType != 'is not empty'">
                                    <nz-form-control [nzSm]="24" [nzXs]="24">
                                        <nz-input-group [ngSwitch]="filter?.dataType">
                                            <input *ngSwitchCase="'text'" [(ngModel)]="filterReq[a].values[0]"
                                                [ngModelOptions]="{ standalone: true }" autocomplete="off" nz-input
                                                [placeholder]="'Enter value'" type="text" />

                                            <input *ngSwitchCase="'email'" [(ngModel)]="filterReq[a].values[0]"
                                                [ngModelOptions]="{ standalone: true }" autocomplete="off" nz-input
                                                [placeholder]="'Enter value'" type="email" />

                                            <input *ngSwitchCase="'number'" [(ngModel)]="filterReq[a].values[0]"
                                                [ngModelOptions]="{ standalone: true }" autocomplete="off" nz-input
                                                [placeholder]="'Enter value'" type="number" />

                                            <input *ngSwitchCase="'phone-number'" [(ngModel)]="filterReq[a].values[0]"
                                                [ngModelOptions]="{ standalone: true }" autocomplete="off" nz-input
                                                [placeholder]="'Enter value'" type="number" />

                                            <nz-select nzShowSearch *ngSwitchCase="'select'"
                                                [(ngModel)]="filterReq[a].values[0]"
                                                [ngModelOptions]="{ standalone: true }" [nzPlaceHolder]="'Select value'"
                                                class="w-100">
                                                <nz-option *ngFor="let option of filter?.acceptedValues"
                                                    [nzLabel]="option" [nzValue]="option"></nz-option>
                                            </nz-select>

                                            <nz-select nzShowSearch *ngSwitchCase="'multi-select'"
                                                [(ngModel)]="filterReq[a].values[0]"
                                                [ngModelOptions]="{ standalone: true }" [nzPlaceHolder]="'Select value'"
                                                class="w-100">
                                                <nz-option *ngFor="let option of filter?.acceptedValues"
                                                    [nzLabel]="option" [nzValue]="option"></nz-option>
                                            </nz-select>

                                            <nz-select *ngSwitchCase="'switch'" [(ngModel)]="filterReq[a].values[0]"
                                                [ngModelOptions]="{ standalone: true }" [nzPlaceHolder]="'Select value'"
                                                class="w-100">
                                                <nz-option [nzLabel]="'True'" [nzValue]="'true'"></nz-option>
                                                <nz-option [nzLabel]="'False'" [nzValue]="'false'"></nz-option>
                                            </nz-select>

                                            <nz-select nzShowSearch *ngSwitchCase="'tags'"
                                                [(ngModel)]="filterReq[a].values[0]"
                                                [ngModelOptions]="{ standalone: true }" [nzPlaceHolder]="'Select value'"
                                                class="w-100">
                                                <nz-option *ngFor="let tag of allTags" [nzLabel]="tag.name"
                                                    [nzValue]="tag._id">
                                                </nz-option>
                                            </nz-select>

                                            <nz-date-picker class="w-100" *ngSwitchCase="'date'"
                                                [(ngModel)]="filterReq[a].values[0]"
                                                [ngModelOptions]="{ standalone: true }" nzShowToday
                                                nzFormat="dd-MM-yyyy" [nzRenderExtraFooter]="">
                                            </nz-date-picker>

                                            <p class="error_message"
                                                *ngIf="errorObj.values.indexOf(filter.name) > -1 && !filterReq[a].values[0]">
                                                Please fill filter value.
                                            </p>
                                        </nz-input-group>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>

                <nz-collapse class="contacts_filter_collpase">
                    <nz-collapse-panel [nzHeader]="'System Defined Filters'" [nzActive]="true">
                        <div class="filter_checkbox">
                            <ng-container *ngFor="let filter of segmentsList | filter : contactsFilter">
                                <label *ngIf="filter?.type == 'predefined'"
                                    [nzChecked]="appliedPredefined.indexOf(filter._id) != -1" nz-checkbox
                                    (nzCheckedChange)="applyPredefined(filter, $event)">
                                    {{ filter.name }}
                                </label>
                            </ng-container>
                        </div>
                    </nz-collapse-panel>

                    <nz-collapse-panel [nzHeader]="'Filter By Fields'" [nzActive]="true">
                        <div class="filter_checkbox">
                            <ng-container
                                *ngFor="let filter of fieldsForFilter | filter : contactsFilter; let a = index">
                                <ng-container
                                    *ngIf="!filterApplied || (filterApplied && appliedFiltersSample.indexOf(filter.name) == -1)"
                                    class="">
                                    <label [nzChecked]="appliedFilters.indexOf(filter.name) != -1" nz-checkbox
                                        (nzCheckedChange)="onFilterChecked(filter, a, $event, '')">{{ filter?.label
                                        }}</label>
                                    <div *ngIf="appliedFilters.indexOf(filter.name) > -1" class="w-100">
                                        <nz-form-item>
                                            <nz-form-control [nzSm]="24" [nzXs]="24">
                                                <nz-input-group>
                                                    <nz-select nzShowSearch [(ngModel)]="filterReq[a].filterType"
                                                        class="w-100 select_dropdown" nzPlaceHolder="Select Condition">
                                                        <nz-option
                                                            *ngFor="let filter of filterSuggestion[filter.dataType]"
                                                            [nzLabel]="filter" [nzValue]="filter">
                                                        </nz-option>
                                                    </nz-select>

                                                    <p class="error_message"
                                                        *ngIf="errorObj.filterType.indexOf(filter.name) > -1 && !filterReq[a].filterType">
                                                        Please select filter type.
                                                    </p>
                                                </nz-input-group>
                                            </nz-form-control>
                                        </nz-form-item>

                                        <nz-form-item
                                            *ngIf="filterReq[a].filterType != 'is empty' && filterReq[a].filterType != 'is not empty' ">
                                            <nz-form-control [nzSm]="24" [nzXs]="24">
                                                <nz-input-group [ngSwitch]="filter?.dataType">
                                                    <input *ngSwitchCase="'text'" [(ngModel)]="filterReq[a].values[0]"
                                                        [ngModelOptions]="{ standalone: true }" autocomplete="off"
                                                        nz-input [placeholder]="'Enter value'" type="text" />

                                                    <input *ngSwitchCase="'email'" [(ngModel)]="filterReq[a].values[0]"
                                                        [ngModelOptions]="{ standalone: true }" autocomplete="off"
                                                        nz-input [placeholder]="'Enter value'" type="email" />

                                                    <input *ngSwitchCase="'number'" [(ngModel)]="filterReq[a].values[0]"
                                                        [ngModelOptions]="{ standalone: true }" autocomplete="off"
                                                        nz-input [placeholder]="'Enter value'" type="number" />

                                                    <input *ngSwitchCase="'phone-number'"
                                                        [(ngModel)]="filterReq[a].values[0]"
                                                        [ngModelOptions]="{ standalone: true }" autocomplete="off"
                                                        nz-input [placeholder]="'Enter value'" type="number" />

                                                    <nz-select nzShowSearch *ngSwitchCase="'select'"
                                                        [(ngModel)]="filterReq[a].values[0]"
                                                        [ngModelOptions]="{ standalone: true }"
                                                        [nzPlaceHolder]="'Select value'" class="w-100">
                                                        <nz-option *ngFor="let option of filter?.acceptedValues"
                                                            [nzLabel]="option" [nzValue]="option"></nz-option>
                                                    </nz-select>

                                                    <nz-select nzShowSearch *ngSwitchCase="'multi-select'"
                                                        [(ngModel)]="filterReq[a].values[0]"
                                                        [ngModelOptions]="{ standalone: true }"
                                                        [nzPlaceHolder]="'Select value'" class="w-100">
                                                        <nz-option *ngFor="let option of filter?.acceptedValues"
                                                            [nzLabel]="option" [nzValue]="option"></nz-option>
                                                    </nz-select>

                                                    <nz-select *ngSwitchCase="'switch'"
                                                        [(ngModel)]="filterReq[a].values[0]"
                                                        [ngModelOptions]="{ standalone: true }"
                                                        [nzPlaceHolder]="'Select value'" class="w-100">
                                                        <nz-option [nzLabel]="'True'" [nzValue]="'true'"></nz-option>
                                                        <nz-option [nzLabel]="'False'" [nzValue]="'false'"></nz-option>
                                                    </nz-select>

                                                    <nz-select nzShowSearch *ngSwitchCase="'tags'"
                                                        [(ngModel)]="filterReq[a].values[0]"
                                                        [ngModelOptions]="{ standalone: true }"
                                                        [nzPlaceHolder]="'Select value'" class="w-100">
                                                        <nz-option *ngFor="let tag of allTags" [nzLabel]="tag.name"
                                                            [nzValue]="tag._id">
                                                        </nz-option>
                                                    </nz-select>

                                                    <nz-date-picker class="w-100" *ngSwitchCase="'date'"
                                                        [(ngModel)]="filterReq[a].values[0]"
                                                        [ngModelOptions]="{ standalone: true }" nzShowToday
                                                        nzFormat="dd-MM-yyyy" [nzRenderExtraFooter]="">
                                                    </nz-date-picker>

                                                    <p class="error_message"
                                                        *ngIf="errorObj.values.indexOf(filter.name) > -1 && !filterReq[a].values[0]">
                                                        Please fill filter value.
                                                    </p>
                                                </nz-input-group>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </nz-collapse-panel>
                </nz-collapse>

                <div *ngIf="appliedFilters?.length > 0" class="filter_bottom">
                    <a (click)="clearFilter()" nz-button nzType="default" class="filter_btn_bottom">
                        <span>Clear Filter</span>
                    </a>
                    <a (click)="directApply()" nz-button nzType="primary" class="filter_btn_bottom">
                        <span>Apply Filter</span>
                    </a>
                </div>
            </div>
        </nz-card>
    </div>

    <div [ngClass]="{ 'col-12': !showFilter && !rightMenu ,  'col-9': !showFilter || !rightMenu}"
        class="col-6 customer_right_card customers_table_card contacts_table_column">
        <div class="row">
            <div class="col-12">
                <nz-card class="contacts_top_card contacts_filter_card">
                    <div *ngIf="selectedContacts.length == 0" class="contacts_top_bar">
                        <div class="contacts_top_bar_left">
                            <span [ngClass]="{'filter_active' : showFilter}" (click)="showFilter = !showFilter"
                                class="filter_icon" nz-icon nzType="filter" nzTheme="outline"></span>

                            <button class="segment_btn" nzTrigger="click" nz-button nz-dropdown
                                [nzDropdownMenu]="segmentsMenu" [nzPlacement]="'bottomLeft'">
                                {{ selectedTab?.name }}
                                <span class="d-flex" nz-icon nzType="down"></span>
                                <!-- <span nz-icon nzType="caret-down" nzTheme="outline"></span> -->
                            </button>
                            <nz-dropdown-menu #segmentsMenu="nzDropdownMenu">
                                <ul class="segment_menu" nz-menu>
                                    <li [ngClass]="{selected_segment: selectedTab._id == 'All Contacts'}"
                                        (click)="selectSegment({ name: 'All Contacts', _id: 'All Contacts' })"
                                        nz-menu-item>
                                        All Contacts ({{ allContactsCount }})
                                    </li>
                                    <ng-container *ngFor="let segment of segmentsList">
                                        <li class="list_options" *ngIf="segment?.type != 'predefined'"
                                            [ngClass]="{selected_segment: selectedTab._id == segment._id}"
                                            (click)="selectSegment(segment)" nz-menu-item>
                                            <span>{{ segment?.name }} ({{ segment?.customersCount }})</span>
                                            <div class="edit_icon">
                                                <i [nzTooltipTitle]="'Edit'" nzTooltipPlacement="top" nz-tooltip
                                                    (click)="toEditSegment(segment)" class="edit_btn" nz-icon
                                                    nzType="edit" nzTheme="outline"></i>
                                                <i [nzTooltipTitle]="'Delete'" nzTooltipPlacement="top" nz-tooltip
                                                    (click)="showDeleteAlert($event, segment)" class="" nz-icon
                                                    nzType="delete" nzTheme="outline"></i>
                                            </div>
                                        </li>
                                    </ng-container>
                                    <li class="add_segment_link" (click)="addSegment()" nz-menu-item>
                                        Add new segment
                                    </li>
                                </ul>
                            </nz-dropdown-menu>

                            <div class="toggle_options">
                                <nz-input-group class="search_input_section small_input_sec"
                                    [nzPrefix]="suffixIconSearch">
                                    <input autocomplete="off" [(ngModel)]="searchKey" (keyup)="searchContacts()"
                                        type="text" nz-input placeholder="Search here.." />
                                </nz-input-group>
                                <ng-template #suffixIconSearch>
                                    <span nz-icon nzType="search"></span>
                                </ng-template>
                            </div>
                        </div>

                        <div class="contacts_top_bar_right">
                            <div class="search_add_div">
                                <a [disabled]="mainUserData?.accessTo?.isExpired" nz-button nzTrigger="click"
                                    nzType="primary" class="create-button" nz-dropdown [nzDropdownMenu]="menu">
                                    <i nz-icon nzType="user-add" theme="outline"></i>
                                    <span>Add Customer</span>
                                </a>

                                <span nzTrigger="click" nz-dropdown [nzDropdownMenu]="moreOptions" class="more_options"
                                    nz-icon nzType="setting" nzTheme="outline"></span>

                                <nz-dropdown-menu #moreOptions="nzDropdownMenu">
                                    <ul class="drop_down_menu" nz-menu>
                                        <li routerLink="/contacts/uploads" nz-menu-item>
                                            <i nz-icon nzType="history" theme="outline"></i>
                                            Upload History
                                        </li>
                                        <li routerLink="/tags" nz-menu-item>
                                            <i nz-icon nzType="tag" theme="outline"></i>
                                            Tags
                                        </li>
                                        <li routerLink="/field-collection" nz-menu-item>
                                            <i nz-icon nzType="disconnect" theme="outline"></i>
                                            Field Collection
                                        </li>
                                        <!-- <li (click)="exportContacts()" nz-menu-item>Export contacts</li> -->
                                    </ul>
                                </nz-dropdown-menu>

                                <nz-dropdown-menu #menu="nzDropdownMenu">
                                    <ul class="drop_down_menu" nz-menu>
                                        <li (click)="singleCustomerModal()" nz-menu-item>
                                            <i nz-icon nzType="plus" theme="outline"></i>
                                            New
                                        </li>
                                        <li (click)="openBulkUploadModal()" nz-menu-item>
                                            <i nz-icon nzType="usergroup-add" theme="outline"></i>
                                            Bulk Upload
                                        </li>
                                    </ul>
                                </nz-dropdown-menu>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="selectedContacts.length > 0" class="contacts_top_bar">
                        <div class="search_add_div">
                            <a (click)="showMassUpdate()" nz-button nzType="default"
                                class="new_update_btn create-button">
                                <span>Mass Update</span>
                            </a>
                        </div>
                    </div>
                </nz-card>
            </div>

            <div class="col-12" id="main-section">
                <nz-card *ngIf="!importHistory" class="">
                    <div class="total_records">
                        <h6 *ngIf="selectedContacts.length == 0">
                            Total Records ({{ totalRecords }})
                        </h6>
                        <h6 *ngIf="selectedContacts.length > 0">
                            {{ selectedContacts.length }} Records Selected.
                            <span (click)="onAllChecked(false)" class="table_checkbox_clear">clear</span>
                        </h6>

                        <button class="customized_btn" [ngStyle]="setMyStyles()" nzTrigger="click" nz-dropdown
                            [nzDropdownMenu]="paginationMenu" [nzPlacement]="'bottomLeft'">
                            {{ pageSize + " records per page" }}
                        </button>

                        <nz-dropdown-menu #paginationMenu="nzDropdownMenu">
                            <ul nz-menu>
                                <li (click)="changePageSize((n + 1) * 10)"
                                    *ngFor="let page of [].constructor(5); let n = index" nz-menu-item>
                                    {{ (n + 1) * 10 + " records per page" }}
                                </li>
                            </ul>
                        </nz-dropdown-menu>
                    </div>
                    <!-- USER LIST TABLE STARTS -->
                    <nz-table class="contacts_table main_contacts_table" #userListTable
                        [nzData]="selectedTab._id == 'All Contacts' ? userList : FilteredCustomers"
                        [nzShowPagination]="true"
                        [nzScroll]="{x: tableFilterArr.length * 145 + 80 + 'px',y: tableHeight}"
                        [nzFrontPagination]="false" [nzTotal]="totalCount" [nzPageSize]="pageSize"
                        [nzPageIndex]="pageIndex" (nzQueryParams)="onPageNumberChange('categoryTable', $event)"
                        [nzPaginationPosition]="'top'" [nzSimple]="true" [nzTableLayout]="'auto'">
                        <thead>
                            <tr>
                                <!-- <th [nzLeft]="false" class="checkbox_section" [nzWidth]="'25px'" [(nzChecked)]="checked"
                                    (nzCheckedChange)="onAllChecked($event)"></th> -->

                                <ng-container *ngFor="let column of orderColumn; let s = index">
                                    <th class="" [nzLeft]="false" *ngIf="tableFilterArr.indexOf(column?.label) >= 0"
                                        [nzWidth]="'auto'">
                                        <div class="contacts_heading">
                                            {{ column?.label }}
                                            <div class="sort_options">
                                                <span
                                                    [ngClass]="{'active_sort' : sortType == 'asc' && sortField == column.name}"
                                                    (click)="sortData('asc' , column)" nz-icon nzType="caret-up"
                                                    nzTheme="outline"></span>
                                                <span
                                                    [ngClass]="{'active_sort' : sortType == 'dec' && sortField == column.name}"
                                                    (click)="sortData('dec' , column)" nz-icon nzType="caret-down"
                                                    nzTheme="outline"></span>
                                            </div>
                                        </div>
                                    </th>
                                </ng-container>

                                <th [nzRight]="true" [nzWidth]="'30px'">
                                    <span nzTrigger="click" nz-dropdown [nzDropdownMenu]="filterOptions"
                                        class="select_fields" nz-icon nzType="sliders" nzTheme="outline"></span>

                                    <!-- filter table fields as per user option -->
                                    <nz-dropdown-menu #filterOptions="nzDropdownMenu">
                                        <ul nz-menu class="fields_filter">
                                            <li class="table_filter_options" *ngFor="let order of orderColumn">
                                                <label [ngModel]="tableFilterArr.indexOf(order?.label) >= 0"
                                                    (ngModelChange)="tableFilterChange(order?.label)" nz-checkbox>{{
                                                    order?.name}}
                                                </label>
                                            </li>
                                        </ul>
                                    </nz-dropdown-menu>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr id="categoryTable" *ngFor="let item of selectedTab._id == 'All Contacts'
                                 ? userList : FilteredCustomers; let i = index ">
                                <!-- <td [nzLeft]="false" class="checkbox_section" [nzChecked]="setOfCheckedId.has(item._id)"
                                    (nzCheckedChange)="onItemChecked(item._id, $event)"></td> -->

                                <ng-container *ngFor="let field of orderColumn; let y = index">
                                    <td [nzLeft]="false" *ngIf="tableFilterArr.indexOf(field?.label) >= 0">
                                        <div *ngIf="field?.name == 'name'" class="name_section">
                                            <div (click)="showUserData(item , 'contact-details')" class="edit_link">
                                                {{ item[field?.name] ? item[field?.name] : "-" }}
                                            </div>
                                            <span [nzTooltipTitle]="'Open chat'" nz-tooltip class=""
                                                (click)="openChatDrawer(item)" nz-icon nzType="wechat"
                                                nzTheme="outline"></span>
                                        </div>
                                        <div *ngIf="field?.dataType == 'tags'">
                                            <div class="table_tags"
                                                [ngClass]="{'show_tags' : this.viewMoreArr.indexOf(i) > -1}">
                                                <span *ngFor="let tag of item[field?.name]">
                                                    {{ tag ? tag?.name + ", " : "-" }}
                                                </span>
                                                <span *ngIf="item[field?.name].length == 0">-</span>
                                            </div>
                                            <span class="show_more_span" (click)="viewMore(i)"
                                                *ngIf="item[field?.name].length > 1">
                                                show {{this.viewMoreArr.indexOf(i) > -1 ? 'less' : 'more'}}
                                            </span>
                                        </div>
                                        <div *ngIf="field?.dataType == 'multi-select'">
                                            <span *ngFor="let tag of item[field?.name]">
                                                {{ tag ? tag + ", " : "-" }}
                                            </span>
                                        </div>
                                        <div *ngIf="field?.dataType == 'date'">
                                            {{
                                            item[field?.name]
                                            ? moment(item[field?.name]).format("DD-MM-YYYY")
                                            : "-"
                                            }}
                                        </div>
                                        <div *ngIf="field?.dataType == 'textarea'">
                                            <div class="table_tags"
                                                [ngClass]="{'show_tags' : textAreaArr.indexOf('first' + i + 'second' + y) > -1}">
                                                <span>
                                                    {{ item[field?.name] ? item[field?.name] : "-" }}
                                                </span>
                                            </div>
                                            <span class="show_more_span"
                                                (click)="viewMoreArea('first' + i + 'second' + y)"
                                                *ngIf="item[field?.name]?.length > 1">
                                                show {{textAreaArr.indexOf('first' + i + 'second' + y) > -1 ?
                                                'less' : 'more'}}
                                            </span>
                                        </div>
                                        <div *ngIf="field?.name != 'name' &&field?.dataType != 'tags' &&
                                        field?.dataType != 'multi-select' && field?.dataType != 'date' &&
                                        field?.dataType != 'phone-number' && field?.dataType != 'textarea'" class="">
                                            {{ item[field?.name] ? item[field?.name] : "-" }}
                                        </div>
                                        <div *ngIf="field?.dataType == 'phone-number'" class="whatsapp_icon">
                                            {{ item[field?.name] ? item[field?.name] : "-" }}
                                            <!-- <img *ngIf="item['isContactHasWhatsapp']"
                                                src="../../../../assets/images/chat/whatsapp_icon.png" alt="" /> -->
                                        </div>
                                    </td>
                                </ng-container>

                                <td></td>
                            </tr>
                        </tbody>
                        <ngx-spinner bdColor="rgb(255,255,255)" size="medium" color="#000000"
                            type="ball-clip-rotate-pulse" [fullScreen]="false">
                        </ngx-spinner>
                    </nz-table>
                    <!-- USER LIST TABLE ENDS -->
                </nz-card>

                <div *ngIf="importHistory" class="">
                    <app-import-history (changeTab)="importHistory = !importHistory"></app-import-history>
                </div>
            </div>
        </div>
    </div>

    <div [ngClass]="{ 'col-0 hide_details': !rightMenu }" class="col-3 mt-2 final_column">
        <nz-card id="contact-details" class="customer_details_card">
            <div class="customer_details_div">
                <div class="detail_heading">
                    <h6>
                        <span (click)="hideRightMenu()" nz-icon nzType="arrow-left" nzTheme="outline"></span>
                        User Details
                    </h6>
                    <span (click)="showUpdateForm(updateCustomerData)">Edit</span>
                </div>
                <ng-container *ngFor="let item of fieldData | slice:0:sliceCount">
                    <div class="user_details_div">
                        <p>{{item?.label}} :</p>
                        <!-- <h6>{{getTypeOf(updateCustomerData?.[item?.name])}}</h6> -->
                        <div [ngSwitch]="getTypeOf(updateCustomerData?.[item?.name])">
                            <!-- <h6>{{getTypeOf(updateCustomerData?.[item?.name])}}</h6> -->
                            <h6 *ngIf="item.dataType != 'date' && item.dataType != 'tags'">
                                {{(updateCustomerData?.[item?.name]) ?
                                updateCustomerData?.[item?.name] : '-'}}</h6>
                            <h6 *ngIf="item.dataType == 'date'">{{updateCustomerData?.[item?.name] ?
                                moment(updateCustomerData?.[item?.name]).format('DD-MM-YYYY') : '-'}}</h6>
                            <h6 *ngIf="item.dataType == 'tags'">
                                <span
                                    *ngIf="updateCustomerData?.[item?.name].length == 0 || !updateCustomerData?.[item?.name]">-</span>
                                <span *ngFor="let tag of updateCustomerData?.[item?.name]; let s = index">
                                    {{(tag?.name + ((s+1 == updateCustomerData?.[item?.name].length) ? "." : ", "))}}
                                </span>
                            </h6>
                            <!-- <h6 *ngSwitchCase="'String'">{{item.dataType == 'date' ?
                                moment(updateCustomerData?.[item?.name]).format('DD-MM-YYYY') :
                                (updateCustomerData?.[item?.name] ? updateCustomerData?.[item?.name] : '-')}}</h6>
                            <h6 *ngSwitchCase="'Boolean'">{{updateCustomerData?.[item?.name]}}</h6>
                            <h6 *ngSwitchCase="'Array'">
                                <span
                                    *ngIf="updateCustomerData?.[item?.name].length == 0 || !updateCustomerData?.[item?.name]">-</span>
                                <span *ngFor="let tag of updateCustomerData?.[item?.name]; let s = index">
                                    {{(tag?.name + ((s+1 == updateCustomerData?.[item?.name].length) ? "." : ", "))}}
                                </span>
                            </h6>
                            <h6 *ngSwitchCase="'Undefined'">-</h6> -->
                        </div>
                    </div>
                </ng-container>
                <div class="show_more_div">
                    <span (click)="showAllContactDetails()">show {{(sliceCount == 3) ? 'more' : 'less'}}</span>
                </div>
            </div>
        </nz-card>

        <!-- <nz-card class="customer_events_card"> -->
        <!-- <div class="">
            <nz-collapse class="events_main_collapse" [nzExpandIconPosition]="'right'">
                <nz-collapse-panel class="events_main_collapse_panel" [nzHeader]="'Events'" [nzActive]="true">
                    <ng-container>
                        <div class="events_collapse_section">
                            <nz-collapse [nzExpandIconPosition]="'right'">
                                <nz-collapse-panel class="" *ngFor="let event of customerEvents"
                                    [nzHeader]="event?.name" [nzActive]="false">
                                    <div class="events_details">
                                        <p>{{event.data.time}}</p>
                                        <p>{{event.data.description}}</p>
                                    </div>
                                </nz-collapse-panel>
                            </nz-collapse>
                        </div>
                    </ng-container>
                </nz-collapse-panel>
            </nz-collapse>
        </div> -->
        <!-- </nz-card> -->

    </div>
</div>

<!-- BULK UPLOAD MODAL SECTION STARTS -->

<nz-modal [(nzVisible)]="bulkUploadModal" [nzFooter]="null" nzTitle="Bulk Upload" nzCentered
    (nzOnCancel)="closeOtpModal()">
    <ng-container *nzModalContent>

        <div class="">
            <h5>Upload a .CSV file to bulk upload</h5>
            <ul>
                <li>Max 50 MB file allowed. (upto 500000 contacts)</li>
                <li>
                    CSV should mandatorily contain Phone Number & Country Code in any 2
                    columns.
                </li>
                <li>
                    If 2 (or more) contacts in CSV have the same phone number, they might
                    not be added.
                </li>
                <li>
                    If a contact in the CSV already exists in your account, the existing
                    contact's traits will be updated as per the traits given in the CSV.
                    (matching of contacts is done based on Phone Number)
                </li>
            </ul>
        </div>

        <div class="download_excel">
            <a (click)="downloadSampleFile()">
                <span nz-icon nzType="download" nzTheme="outline"></span> Download
                Sample Excel
            </a>
        </div>

        <div class="drag_drop_container mt-3" appDnd (files)="filesDropped($event)">
            <div class="drag_drop_section">
                <p>Drag & Drop your files here</p>
                <span>OR</span>
                <div class="upload_section mt-3">
                    <div [ngClass]="{'change_btn' : customerUploadFile}" class="upload-button save-button">
                        <label for="input-file">{{customerUploadFile ? 'Change' : 'Upload'}} File</label>
                        <input accept=".csv" (change)="uploadFile($event)" class="upload_input" id="input-file"
                            type="file" />
                    </div>
                    <div class="file_name_section">
                        <img *ngIf="customerUploadFile" src="../../../../assets/images/logo/excel.png" alt="" />
                        <p [nzTooltipTitle]="customerUploadFile.name" nz-tooltip *ngIf="customerUploadFile">{{
                            customerUploadFile.name?.length > 30 ?
                            (customerUploadFile.name?.slice(0,30) + '...') : customerUploadFile.name}}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-3">
            <nz-form-item>
                <nz-form-label class="" nzFor=""> Import Name </nz-form-label>
                <ng-container>
                    <nz-form-control [nzErrorTip]="'Please Enter Import Name'">
                        <nz-input-group>
                            <input [(ngModel)]="importName" autocomplete="off" nz-input
                                [placeholder]="'Enter Import Name'" type="text" />
                        </nz-input-group>
                    </nz-form-control>
                </ng-container>
            </nz-form-item>

            <div class="text-right">
                <a [nzLoading]="excelUploading" [disabled]="!importName || !customerUploadFile"
                    [ngClass]="{ disabled_tag: !importName || !customerUploadFile }" (click)="uploadDirectExcel()"
                    nz-button nzType="primary" class="create-button mt-1">
                    <span>Continue</span>
                </a>
            </div>
        </div>
    </ng-container>
    <!-- <ng-template class="modal_footer" *nzModalFooter></ng-template> -->
</nz-modal>

<!-- BULK UPLOAD MODAL SECTION ENDS -->

<!-- SINGLE CONTACT UPDATE MODAL STARTS -->

<!-- <nz-modal [(nzVisible)]="singleModal" [nzTitle]="editCustomer ? 'Update New Contact' : 'Create New Contact'" nzCentered
    (nzOnCancel)="closeCustomerModal()">
    <ng-container *nzModalContent>

        <form [formGroup]="singleCustomerForm" (ngSubmit)="createCustomer()" nz-form>
            <div class="update_form_div">

                <ng-container *ngFor="let field of fieldData">
                    <nz-form-item *ngIf="field.name != 'phoneNo' || !editCustomer">
                        <nz-form-label [ngClass]="{'full_height' : field.dataType =='switch'}" class="large_label"
                            nzFor="" [nzSm]="field.dataType =='switch' ? '' : 24"
                            [nzXs]="field.dataType =='switch' ? '' : 24">
                            {{field.label}}
                        </nz-form-label>
                        <ng-container>
                            <nz-form-control [nzSm]="24" [nzXs]="24" [nzErrorTip]="'Please Enter ' + field.label">
                                <nz-input-group [ngSwitch]="field.dataType">

                                    <input [formControlName]="field.name" *ngSwitchCase="'text'" autocomplete="off"
                                        nz-input [placeholder]="'Enter ' + field.label" type="text">

                                    <input [formControlName]="field.name" *ngSwitchCase="'email'" autocomplete="off"
                                        nz-input [placeholder]="'Enter ' + field.label" type="email">

                                    <input [formControlName]="field.name" *ngSwitchCase="'number'" autocomplete="off"
                                        nz-input [placeholder]="'Enter ' + field.label" type="number">

                                    <input [formControlName]="field.name" *ngSwitchCase="'phone-number'"
                                        autocomplete="off" nz-input [placeholder]="'Enter ' + field.label"
                                        type="number">

                                    <textarea [formControlName]="field.name" *ngSwitchCase="'textarea'" nz-input
                                        rows="2" [placeholder]="'write ' +  field.label +  ' here'"></textarea>

                                    <nz-select [nzPlaceHolder]="'Select ' + field.name" [formControlName]="field.name"
                                        class="w-100" *ngSwitchCase="'select'">
                                        <nz-option *ngFor="let option of field.acceptedValues" [nzLabel]="option"
                                            [nzValue]="option"></nz-option>
                                    </nz-select>

                                    <nz-select [nzPlaceHolder]="'Select ' + field.name" [formControlName]="field.name"
                                        class="w-100" *ngSwitchCase="'multi-select'" nzMode="multiple">
                                        <nz-option *ngFor="let option of field.acceptedValues" [nzLabel]="option"
                                            [nzValue]="option"></nz-option>
                                    </nz-select>

                                    <nz-select [nzPlaceHolder]="'Select ' + field.name" [formControlName]="field.name"
                                        class="w-100" *ngSwitchCase="'tags'" nzMode="tags">
                                        <nz-option *ngFor="let tag of allTags" [nzLabel]="tag.name"
                                            [nzValue]="tag.name">
                                        </nz-option>

                                    </nz-select>

                                    <nz-date-picker [formControlName]="field.name" class="w-100" *ngSwitchCase="'date'"
                                        nzShowToday nzFormat="dd-MM-yyyy" [nzRenderExtraFooter]="">
                                    </nz-date-picker>

                                    <nz-switch [formControlName]="field.name" *ngSwitchCase="'switch'"></nz-switch>
                                </nz-input-group>
                            </nz-form-control>
                        </ng-container>
                    </nz-form-item>
                </ng-container>

                <nz-form-item class="user_create">
                    <button [nzLoading]="creatingCustomer" type="submit" class="sighup_button" nz-button
                        nzType="primary">
                        {{editCustomer ? 'Update' : 'Create'}}
                    </button>
                </nz-form-item>
            </div>
        </form>

    </ng-container>
    <ng-template class="modal_footer" *nzModalFooter></ng-template>
</nz-modal> -->

<nz-drawer [nzWidth]="drawerWidth" [nzClosable]="true" [nzVisible]="singleModal" [nzPlacement]="'right'"
    [nzTitle]="editCustomer ? 'Update Contact' : 'Create New Contact'" (nzOnClose)="closeCustomerModal()">
    <ng-container *nzDrawerContent>
        <form *ngIf="singleModal" [formGroup]="singleCustomerForm" (ngSubmit)="createCustomer()" nz-form>
            <div class="update_form_div">
                <ng-container *ngFor="let field of fieldData">
                    <nz-form-item>
                        <nz-form-label [nzRequired]="field?.isMandatory"
                            [ngClass]="{ full_height: field.dataType == 'switch' }" class="large_label" nzFor=""
                            [nzSm]="field.dataType == 'switch' ? '' : 24" [nzXs]="field.dataType == 'switch' ? '' : 24">
                            {{ field.label }}
                        </nz-form-label>
                        <ng-container>
                            <nz-form-control [nzSm]="24" [nzXs]="24" [nzErrorTip]="'Please Enter ' + field.label">
                                <nz-input-group [ngSwitch]="field.dataType">
                                    <input [formControlName]="field.name" *ngSwitchCase="'text'" autocomplete="off"
                                        nz-input [placeholder]="'Enter ' + field.label" type="text" />

                                    <input [formControlName]="field.name" *ngSwitchCase="'email'" autocomplete="off"
                                        nz-input [placeholder]="'Enter ' + field.label" type="email" />

                                    <input [formControlName]="field.name" *ngSwitchCase="'number'" autocomplete="off"
                                        nz-input [placeholder]="'Enter ' + field.label" type="number" />

                                    <!-- <input [formControlName]="field.name" *ngSwitchCase="'phone-number'"
                                        autocomplete="off" nz-input [placeholder]="'Enter ' + field.label"
                                        type="number"> -->

                                    <textarea [formControlName]="field.name" *ngSwitchCase="'textarea'" nz-input
                                        rows="2" [placeholder]="'write ' + field.label + ' here'"></textarea>

                                    <nz-select [nzPlaceHolder]="'Select ' + field.name" [formControlName]="field.name"
                                        class="w-100" *ngSwitchCase="'select'">
                                        <nz-option *ngFor="let option of field.acceptedValues" [nzLabel]="option"
                                            [nzValue]="option"></nz-option>
                                    </nz-select>

                                    <nz-select [nzPlaceHolder]="'Select ' + field.name" [formControlName]="field.name"
                                        class="w-100" *ngSwitchCase="'multi-select'" nzMode="multiple">
                                        <nz-option *ngFor="let option of field.acceptedValues" [nzLabel]="option"
                                            [nzValue]="option"></nz-option>
                                    </nz-select>

                                    <nz-select [nzPlaceHolder]="'Select ' + field.name" [formControlName]="field.name"
                                        class="w-100" *ngSwitchCase="'tags'" nzMode="tags">
                                        <nz-option *ngFor="let tag of allTags" [nzLabel]="tag.name"
                                            [nzValue]="tag.name">
                                        </nz-option>
                                    </nz-select>

                                    <nz-date-picker [formControlName]="field.name" class="w-100" *ngSwitchCase="'date'"
                                        nzShowToday nzFormat="dd-MM-yyyy" [nzRenderExtraFooter]="">
                                    </nz-date-picker>

                                    <nz-switch [formControlName]="field.name" *ngSwitchCase="'switch'"></nz-switch>
                                </nz-input-group>

                                <nz-input-group class="d-flex" nzCompact *ngIf="field.dataType == 'phone-number'">
                                    <nz-select *ngIf="field?.fieldType == 'predefined'"
                                        [nzDisabled]="field?.fieldType == 'predefined' && editCustomer"
                                        [ngClass]="{ 'disabled_tag': field?.fieldType == 'predefined' && editCustomer }"
                                        nzShowSearch [(ngModel)]="dialCode" [ngModelOptions]="{ standalone: true }"
                                        style="width: 30%">
                                        <nz-option *ngFor="let code of countryCodes"
                                            [nzLabel]="'+' + code?.countryCodeNormal + '-' + code?.Country"
                                            [nzValue]="code?.countryCodeNormal"></nz-option>
                                        <!-- <nz-option [nzLabel]="'+65'" [nzValue]="'+65'"></nz-option> -->
                                    </nz-select>
                                    <input
                                        [ngClass]="{ 'disabled_tag': field?.fieldType == 'predefined' && editCustomer , 'width_70' : field?.fieldType == 'predefined'}"
                                        [formControlName]="field.name" autocomplete="off" nz-input
                                        [placeholder]="'Enter ' + field.label" type="number" />
                                </nz-input-group>
                            </nz-form-control>
                        </ng-container>
                    </nz-form-item>
                </ng-container>

                <nz-form-item class="user_create">
                    <button [nzLoading]="creatingCustomer" type="submit" class="sighup_button" nz-button
                        nzType="primary">
                        {{ editCustomer ? "Update" : "Create" }}
                    </button>
                </nz-form-item>
            </div>
        </form>
    </ng-container>
</nz-drawer>

<!-- SINGLE CONTACT UPDATE MODAL ENDS -->

<nz-modal [(nzVisible)]="updateModal" [nzFooter]="null" nzTitle="Update contact" nzCentered
    (nzOnCancel)="closeUpdateModal()">
    <ng-container *nzModalContent>
        <form [formGroup]="updateCustomerForm" (ngSubmit)="updateCustomer()" nz-form>
            <div class="update_form_div">
                <nz-form-item>
                    <nz-form-control nzErrorTip="Please Enter customer Name!">
                        <nz-input-group>
                            <input autocomplete="off" formControlName="cusName" type="text" nz-input id="cus_name"
                                placeholder="Enter customer Name" required />
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-control nzErrorTip="Please Enter Mail Id!">
                        <nz-input-group>
                            <input autocomplete="off" formControlName="cusMail" nz-input placeholder="Enter Mail Id"
                                type="email" />
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-control>
                        <nz-input-group>
                            <label nz-checkbox formControlName="cusBroadCast">Allow Broadcast</label>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item class="user_create">
                    <button [nzLoading]="creatingCustomer" type="submit" class="sighup_button" nz-button
                        nzType="primary">
                        Update
                    </button>
                </nz-form-item>
            </div>
        </form>
    </ng-container>
    <!-- <ng-template class="modal_footer" *nzModalFooter></ng-template> -->
</nz-modal>

<nz-modal [(nzVisible)]="updateMassModal" [nzFooter]="null" nzTitle="Mass Update" (nzOnCancel)="cancelUpdateModal()"
    (nzOnOk)="massUpdate()">
    <ng-container *nzModalContent>
        <div class="">
            <div class="catalogue_submit_form" nz-row [nzGutter]="24">
                <div nz-col [nzXs]="12">
                    <nz-form-item>
                        <nz-form-control>
                            <nz-input-group>
                                <nz-select [(ngModel)]="massUpdateField" class="w-100 select_dropdown"
                                    nzPlaceHolder="Select Condition">
                                    <nz-option *ngFor="let filter of fieldsForFilter; let a = index"
                                        [nzLabel]="filter.label" [nzValue]="filter">
                                    </nz-option>
                                </nz-select>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>
                </div>

                <div nz-col [nzXs]="12">
                    <nz-form-item>
                        <nz-form-control [nzSm]="24" [nzXs]="24">
                            <nz-input-group [ngSwitch]="massUpdateField?.dataType">
                                <input *ngSwitchCase="'text'" autocomplete="off" nz-input [placeholder]="'Enter value'"
                                    type="text" />

                                <input *ngSwitchCase="'email'" autocomplete="off" nz-input [placeholder]="'Enter value'"
                                    type="email" />

                                <input *ngSwitchCase="'number'" autocomplete="off" nz-input
                                    [placeholder]="'Enter value'" type="number" />

                                <input *ngSwitchCase="'phone-number'" autocomplete="off" nz-input
                                    [placeholder]="'Enter value'" type="number" />

                                <nz-select *ngSwitchCase="'select'" [nzPlaceHolder]="'Select value'" class="w-100">
                                    <nz-option *ngFor="let option of filter?.acceptedValues" [nzLabel]="option"
                                        [nzValue]="option"></nz-option>
                                </nz-select>

                                <nz-select *ngSwitchCase="'multi-select'" [nzPlaceHolder]="'Select value'"
                                    class="w-100">
                                    <nz-option *ngFor="let option of filter?.acceptedValues" [nzLabel]="option"
                                        [nzValue]="option"></nz-option>
                                </nz-select>

                                <nz-select *ngSwitchCase="'switch'" [nzPlaceHolder]="'Select value'" class="w-100">
                                    <nz-option [nzLabel]="'True'" [nzValue]="'true'"></nz-option>
                                    <nz-option [nzLabel]="'False'" [nzValue]="'false'"></nz-option>
                                </nz-select>

                                <nz-select *ngSwitchCase="'tags'" [nzPlaceHolder]="'Select value'" class="w-100">
                                    <nz-option *ngFor="let tag of allTags" [nzLabel]="tag.name" [nzValue]="tag._id">
                                    </nz-option>
                                </nz-select>

                                <nz-date-picker class="w-100" *ngSwitchCase="'date'" nzShowToday nzFormat="dd-MM-yyyy"
                                    [nzRenderExtraFooter]="">
                                </nz-date-picker>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>
                </div>

                <div nz-col [nzXs]="24">
                    <nz-form-item class="submit_section text-right">
                        <nz-form-control [nzSm]="24" [nzXs]="24">
                            <div class="account_details_add_cancel_right">
                                <button type="button" class="sighup_button mr-3" nz-button
                                    nzType="default">Cancel</button>
                                <button type="submit" class="sighup_button" nz-button nzType="primary">
                                    Update
                                </button>
                            </div>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
        </div>
    </ng-container>
</nz-modal>

<!-- CHAT SCREEN DRAWER INSIDE CONTACTS PAGE -->

<nz-drawer [nzWidth]="'80%'" [nzClosable]="false"
    [nzBodyStyle]="{'padding' : '8px 24px 0px 15px' , 'background' : 'linear-gradient(93deg, #e5f7ff 0%, #f7f9ff 53%, #deeaf6 100%) 0% 0% no-repeat padding-box'}"
    [nzVisible]="chatDrawer" [nzPlacement]="'right'" (nzOnClose)="closeChatDrawer()">
    <ng-container *nzDrawerContent>
        <div class="">
            <h3 class="chat_drawer_back">
                <span class="cursor_pointer" (click)="closeChatDrawer()" nz-icon nzType="left" nzTheme="outline"></span>
                Chat
            </h3>
            <app-chat [chatData]="chatData"></app-chat>
        </div>
    </ng-container>
</nz-drawer>


<!-- *ngIf="field.name != 'phoneNo' || !editCustomer" -->

<!-- ?.includes(',')? item[field?.name]?.split(',') : [item[field?.name]] -->
<!-- [nzShowPagination]="selectedTab._id == 'All Contacts'" -->