import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router, NavigationEnd } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { HttpHeaders } from "@angular/common/http";
import { CommonApiService } from "src/app/services/common-api.service";
import { environment } from "src/environments/environment";
import { NgOtpInputConfig } from "ng-otp-input";
import { DeviceDetectorService } from "ngx-device-detector";

// detect device

@Component({
  selector: "app-login-page",
  templateUrl: "./login-page.component.html",
  styleUrls: ["./login-page.component.css"],
})
export class LoginPageComponent implements OnInit {
  validateForm!: FormGroup;
  forgotPasswordForm!: FormGroup;
  confirmPasswordForm!: FormGroup;
  passwordVisible = false;
  loginConfirm = false;
  getMailModal = false;
  getOTPModal = false;
  OTPverified = false;

  // OPT INPUT CONFIG
  config: NgOtpInputConfig = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: "",
    inputStyles: {
      width: "35px",
      height: "35px",
      fontSize: "22px",
    },
  };

  deviceInfo: any;

  loginBanners: any = [
    "../../../assets/images/login/banner_1.png",
    "../../../assets/images/login/banner_2.png",
    "../../../assets/images/login/banner_3.png",
    "../../../assets/images/login/banner_4.png",
    "../../../assets/images/login/banner_5.png",
    "../../../assets/images/login/banner_6.png",
  ];

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private postService: CommonApiService,
    private deviceService: DeviceDetectorService
  ) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url == "/login") {
          this.router.navigate(["/login"]);
        }
        // else if (event.url == "/forgot-password") {
        //   this.getOTPModal = true;
        // }
      }
    });
  }

  ngOnInit(): void {
    // const userAgent = navigator.userAgent.toLowerCase();
    // alert(userAgent);
    // this.deviceInfo = this.deviceService.getDeviceInfo();
    // console.log(this.deviceInfo);
    if (localStorage.getItem("qwertrewetrt")) {
      this.router.navigate(["/chat"]);
    } else {
      localStorage.removeItem("qwertrewetrt"); // }
    }

    this.validateForm = this.fb.group({
      mobileNo: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required]],
    });

    this.forgotPasswordForm = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
    });

    this.confirmPasswordForm = this.fb.group({
      enteredPassword: ["", [Validators.required]],
      confirmedPassword: ["", [Validators.required]],
    });
  }

  submitForm() {
    let options = {
      // headers: new HttpHeaders().set("Content-Type", "application/json"),
    };
    // localStorage.setItem(
    //   "qwertrewetrt",
    //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImhhamVAaWV5YWxzb2x1dGlvbnMuY29tIiwidXNlcklkIjoiNjMzYmVjNjgwOTY3Mzk1MzlhNjQ5YTUzIiwiaWF0IjoxNjY0ODcxNTI4LCJleHAiOjE2Njc1NDk5Mjh9.uB1xl4tX3yIa1NAqyu2zWPNxvL0AKQZoV2-T84RxcQI"
    // );
    // this.router.navigate(["/campaign"]);

    if (this.validateForm.valid) {
      var body = {
        phoneNoOrEmail: this.validateForm.value.mobileNo,
        // phoneNo: this.validateForm.value.mobileNo,
        password: this.validateForm.value.password,
      };

      this.loginConfirm = true;
      this.postService
        .commonPostMethod(environment.url + "auth/login", body, options)
        .subscribe(
          (data: any) => {
            // console.log(data);
            if (data.status == "success") {
              localStorage.setItem("qwertrewetrt", data.data);
              this.loginConfirm = false;
              if (window.innerWidth > 992) {
                this.router.navigate(["/chat"]);
              } else {
                this.router.navigate(["/chat/mobile", "id", "name"]);
              }
            }
          },
          (err: any) => {
            this.loginConfirm = false;
            if (err?.error?.message) {
              this.toastr.error(err?.error?.message, "", {
                timeOut: 2500,
              });
            } else {
              this.toastr.error(err?.message, "", {
                timeOut: 2500,
              });
            }
            console.log(err);
          }
        );
    } else {
      Object.values(this.validateForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  // FORGOT PASSWORD SECTION

  validateEmail() {
    if (this.forgotPasswordForm.valid) {
      if (this.router.url == "/forgot-password") {
        this.closeModal();
        this.getOTPModal = true;
      } else {
        this.router.navigate(["/forgot-password"]);
      }
    } else {
      Object.values(this.forgotPasswordForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  onOtpChange(event: any) {
    if (event.length == 6) {
      this.OTPverified = true;
    }
  }

  confirmPassword() {
    if (this.confirmPasswordForm.valid) {
      this.getOTPModal = false;
    } else {
      Object.values(this.confirmPasswordForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  // MODAL SECTION STARTS

  showModal() {
    // this.getMailModal = true;
    this.router.navigate(["/forgot-password"]);
  }

  closeModal() {
    this.getMailModal = false;
  }
  closeOtpModal() {
    // this.getOTPModal = false;
    this.router.navigate(["/login"]);
  }

  formatMailId() {
    const value = this.validateForm.value.mobileNo
      .replace(/ /g, "")
      .toLowerCase();
    this.validateForm.get("mobileNo").setValue(value);
  }
}
